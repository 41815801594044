var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.isDesktopPlatform ? "" : "non-white-background" },
    [
      _vm.dataReady
        ? _c(
            "div",
            [
              _vm.isContentAvailable
                ? _c(
                    "ion-grid",
                    {
                      class: _vm.isDesktopPlatform
                        ? "asset-container-desktop"
                        : "asset-container-mobile"
                    },
                    [
                      _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            {
                              staticClass: "ion-no-padding",
                              attrs: {
                                "size-xl": "8.5",
                                "size-lg": "12",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c("div", { staticClass: "back-btn-container" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "back",
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.go(-1)
                                      }
                                    }
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "g-icon--small",
                                      attrs: { "icon-class": "icon-arrow-left" }
                                    }),
                                    _vm._v(" Back ")
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "ion-row",
                                { staticClass: "asset-info ion-no-padding" },
                                [
                                  _vm.isDesktopPlatform
                                    ? _c(
                                        "ion-col",
                                        {
                                          staticClass:
                                            "asset-image-container ion-no-padding"
                                        },
                                        [
                                          _c("img", {
                                            attrs: { src: _vm.imgSrc }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "ion-col",
                                    { staticClass: "ion-no-padding" },
                                    [
                                      _c("div", { staticClass: "category" }, [
                                        _c("div", { staticClass: "format" }, [
                                          _vm._v(_vm._s(_vm.asset.format.name))
                                        ]),
                                        _c("div", { staticClass: "provider" }, [
                                          _vm._v(
                                            _vm._s(_vm.asset.provider.name)
                                          )
                                        ])
                                      ]),
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(_vm._s(_vm.asset.title))
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "1.5rem"
                                          }
                                        },
                                        [
                                          _vm.asset.rating
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "asset-rating",
                                                  on: { click: _vm.onScroll }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "rating" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.asset.rating)
                                                      )
                                                    ]
                                                  ),
                                                  _c("StarRating", {
                                                    staticClass: "star-rating",
                                                    attrs: {
                                                      "inactive-color":
                                                        "#d0cfce",
                                                      "active-color": "#b98032",
                                                      "star-points": [
                                                        23,
                                                        2,
                                                        14,
                                                        17,
                                                        0,
                                                        19,
                                                        10,
                                                        34,
                                                        7,
                                                        50,
                                                        23,
                                                        43,
                                                        38,
                                                        50,
                                                        36,
                                                        34,
                                                        46,
                                                        19,
                                                        31,
                                                        17
                                                      ],
                                                      rating: _vm.asset.rating,
                                                      "read-only": true,
                                                      increment: 0.1,
                                                      "star-size": 14,
                                                      "show-rating": false,
                                                      "round-start-rating": true
                                                    }
                                                  }),
                                                  !_vm.isDesktopPlatform
                                                    ? _c("svg-icon", {
                                                        staticClass:
                                                          "display-rating-mobile",
                                                        attrs: {
                                                          "icon-class":
                                                            "star-filled-desktop"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "rating-count"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.asset.ratingCount
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.asset
                                                              .ratingCount > 1
                                                              ? "ratings"
                                                              : "rating"
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm.asset.disclaimerText.length
                                        ? _c("div", {
                                            staticClass: "term",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$sanitize(
                                                  _vm.asset.disclaimerText
                                                )
                                              )
                                            },
                                            on: { click: _vm.onDisclaimerClick }
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "meta footer" },
                                        [
                                          _c(
                                            "ion-button",
                                            {
                                              staticClass:
                                                "ion-text-capitalize",
                                              class:
                                                _vm.isDesktopPlatform ||
                                                (_vm.asset.assetReference &&
                                                  _vm.asset.assetReference
                                                    .externalAssetLink)
                                                  ? "ion-button-primary-dark"
                                                  : "ion-button-primary-disabled",
                                              attrs: {
                                                shape: "round",
                                                color: "primary",
                                                disabled:
                                                  !_vm.isDesktopPlatform &&
                                                  !_vm.asset.assetReference &&
                                                  !_vm.asset.assetReference
                                                    .externalAssetLink
                                              },
                                              on: { click: _vm.onDownload }
                                            },
                                            [
                                              _vm.asset.applications.length
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: _vm.isDesktopPlatform
                                                        ? "icon-desktop"
                                                        : "icon-mobile"
                                                    },
                                                    _vm._l(
                                                      _vm.asset.applications,
                                                      function(application) {
                                                        return _c("img", {
                                                          key: application.src,
                                                          staticClass:
                                                            "btn-icon-tech",
                                                          attrs: {
                                                            src: application.src.replace(
                                                              ".svg",
                                                              "-bw.svg"
                                                            )
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.downloadBtnText) +
                                                  " "
                                              ),
                                              _vm.asset.assetReference &&
                                              _vm.asset.assetReference
                                                .externalAssetLink
                                                ? _c("svg-icon", {
                                                    staticClass:
                                                      "g-icon--small asset-get-btn",
                                                    attrs: {
                                                      "icon-class": "expand-out"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "bookmark-container"
                                            },
                                            [
                                              _c("Bookmark", {
                                                staticClass: "asset-bookmark",
                                                attrs: {
                                                  assetId: _vm.assetId,
                                                  assetType: "automation",
                                                  meta: _vm.asset.meta
                                                }
                                              }),
                                              _vm.isDesktopPlatform
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "bookmark-tooltip-text"
                                                    },
                                                    [_vm._v("Bookmark")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "share-container" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "social-share",
                                                  attrs: {
                                                    "aria-labelledby":
                                                      "share-btn"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.popoverOn(
                                                        "share"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "click-to-copy",
                                                      "class-name":
                                                        "g-icon--small g-clickable"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.isShareOpen
                                                ? _c("SocialSharePopover", {
                                                    staticClass:
                                                      "social-share-popover",
                                                    attrs: {
                                                      assetId: _vm.assetId,
                                                      routeInfo: _vm.getRouteInfo(),
                                                      router: _vm.$router,
                                                      fromPage: "Asset Detail",
                                                      size: "lg"
                                                    },
                                                    on: {
                                                      "dismiss-share-action-model": function(
                                                        $event
                                                      ) {
                                                        return _vm.popoverOff(
                                                          "share"
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.isShareOpen &&
                                              _vm.isDesktopPlatform
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "share-tooltip-text",
                                                      attrs: { id: "share-btn" }
                                                    },
                                                    [_vm._v("Share")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.showAddToTrack &&
                                          !["asset"].includes(
                                            this.asset.format.name.toLowerCase()
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "more-container"
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "more-action",
                                                      attrs: {
                                                        "aria-labelledby":
                                                          "more-btn"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.popoverOn(
                                                            "more"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          "icon-class":
                                                            "more-action",
                                                          "class-name":
                                                            "g-icon--small g-clickable"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.isMoreOpen
                                                    ? _c("MoreActionPopover", {
                                                        staticClass:
                                                          "more-popover",
                                                        attrs: {
                                                          item: _vm.asset,
                                                          assetId: _vm.assetId,
                                                          routeInfo: _vm.getRouteInfo(),
                                                          showAddToTrack: true,
                                                          showEdit: false,
                                                          showDelete: false
                                                        },
                                                        on: {
                                                          "add-to-track": function(
                                                            $event
                                                          ) {
                                                            return _vm.popoverOff(
                                                              "more"
                                                            )
                                                          },
                                                          "close-more": function(
                                                            $event
                                                          ) {
                                                            return _vm.popoverOff(
                                                              "more"
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  !_vm.isMoreOpen
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "more-tooltip-text",
                                                          attrs: {
                                                            id: "more-btn"
                                                          }
                                                        },
                                                        [_vm._v("More")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm.asset.meta.isCompanyAsset &&
                                      !_vm.isDesktopPlatform
                                        ? _c(
                                            "div",
                                            { staticClass: "author-banner" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.asset.provider.name
                                                ) + " ASSET"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("AssetDetail", {
                        attrs: { asset: _vm.asset, scroll: _vm.scroll },
                        on: { "rating-details": _vm.getRatingDetails }
                      })
                    ],
                    1
                  )
                : _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding",
                      style: _vm.isDesktopPlatform
                        ? ""
                        : "padding: 2rem 10px 6rem;"
                    },
                    [_c("PageNotFound")],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }