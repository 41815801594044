var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.isDesktopPlatform ? "container-md" : "container-mobile" },
    [
      _c(
        "div",
        {
          class: _vm.isDesktopPlatform
            ? "mylearning-own-item-container mylearning-ownitem-empty-container"
            : "mylearning-ownitem-empty-container"
        },
        [
          _c(
            "div",
            { staticClass: "mylearning-ownitem-empty-container-grid" },
            [
              _c("div", [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Let's get started")
                ]),
                _c("div", { staticClass: "sub-title" }, [
                  _vm._v(
                    "Want to add content you found or completed outside of ProEdge? All you need is a URL and some basic details."
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "action-btns" },
                  [
                    _c(
                      "ion-button",
                      {
                        staticClass: "learning-plan-btn",
                        attrs: {
                          shape: "round",
                          color: "primary",
                          title: "Add external learning"
                        },
                        on: { click: _vm.openExternalSidebar }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "add-icon-container" },
                          [
                            _c("svg-icon", {
                              staticStyle: { height: "12px", width: "12px" },
                              attrs: { "icon-class": "add-icon" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" Add ")
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._m(0)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mylearning-ownitem-empty-image" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/my-learning-add.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }