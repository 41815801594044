<template>
  <div>
    <div>
      <ion-row class="ion-align-items-center">
        <ion-col size="10"></ion-col>
        <ion-col :class="$platform !== 'Desktop' ? 'mobile-align-end' : ''">
          <button class="slider-close" aria-label="close side menu" @click="closeTrackBar">
            <svg-icon icon-class="close-icon" class-name="close-button-icon" />
          </button>
        </ion-col>
      </ion-row>
    </div>
    <div class="track-form">
      <ion-row class="track-header">
        {{ isEditTrack ? 'Edit track' : 'Create a track' }}
      </ion-row>
      <ion-row><span class="req-info">*Required information</span></ion-row>
      <div style="height: calc(100vh - 330px); overflow-y: auto; width: 107%">
        <ion-row v-if="$platform === 'Desktop'">
          <div class="track-thumbnail">Thumbnail</div>
        </ion-row>
        <ion-row>
          <div class="thumbnail-msg">
            {{
              $platform === 'Desktop'
                ? `Select a custom image or use the default image for your track.`
                : `You
                      can replace the default image from your desktop or tablet.`
            }}
          </div>
        </ion-row>
        <ion-row v-if="$platform === 'Desktop'">
          <ion-col size="4">
            <div class="thumb-header-default">Default thumbnail image</div>
            <div style="width: 10rem; height: 10rem; margin-top: 2.5rem; border-radius: 20px" class="default-image">
              <button @click.stop="selectImage()" class="accessible-button" aria-label="select default track image">
                <img
                  ref="defaultImg"
                  :class="imageSelected == 'default' ? 'image-border' : ''"
                  style="border-radius: 25px; cursor: pointer; width: 165px; height: 165px"
                  src="@/assets/images/thumbnail-default-track.png"
                  alt="default"
                />
              </button>
            </div>
          </ion-col>
          <ion-col size="1" style="padding-top: 145px"><span style="font: normal normal normal 20px/24px Futura PT Demi">OR</span></ion-col>
          <ion-col size="7">
            <div class="thumb-header-custom">Custom thumbnail image</div>
            <div style="width: 273px; height: 280px" @drop="handleDrop($event)" @dragover="handleDragover" @dragenter="handleDragover">
              <label class="file-upload-wrapper">
                <input
                  id="fileinput"
                  data-cy="asset-file"
                  ref="thumbnailUpload"
                  :key="fileUploadIndex"
                  class="upload-input"
                  type="file"
                  accept="image/png"
                  multiple
                  @change="handleClick($event)"
                />
                <button @click.stop="openImageUploadPopup" class="accessible-button" aria-label="upload custom track image">
                  <div class="imgBg">
                    <img
                      v-if="showPreviewImg"
                      id="previewImage"
                      ref="previewImage"
                      :src="editImageSrc"
                      :class="imageSelected == 'user' ? 'image-border' : ''"
                      style="width: 152px; height: 152px; cursor: pointer; border-radius: 25px"
                      alt="user"
                    />
                  </div>
                </button>
              </label>
            </div>
            <div class="file-error" v-show="fileError != ''">{{ fileError }}</div>
          </ion-col>
        </ion-row>
        <ion-row v-else class="thumbnail-header">
          <ion-col style="text-align: center">
            <div class="thumb-header">Default thumbnail image</div>
          </ion-col>
          <ion-col style="text-align: center">
            <div class="selected-image">
              <img
                ref="defaultImg"
                style="border-radius: 16px; cursor: pointer"
                :class="imageSelected == 'default' ? 'image-border' : ''"
                @click="selectImage()"
                src="@/assets/images/thumbnail-default-track.png"
              />
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="input-row">
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">Name*</div>
            <input
              :value="name"
              maxlength="150"
              type="text"
              placeholder="Enter a name for the track"
              :class="nameError ? 'error-input' : 'valid-input'"
              @input="handleInput($event, 'name')"
            />
            <div class="error-container" v-show="nameError">Error message: The track name field cannot be empty, please provide a track name.</div>
          </ion-col>
        </ion-row>
        <ion-row class="input-row">
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">Description*</div>
            <textarea
              :value="description"
              style="resize: none"
              placeholder="Briefly describe the benefits and impact of this track, including a list of learning outcomes."
              rows="2"
              @input="handleInput($event, 'description')"
              :class="descriptionError ? 'error-input' : 'valid-input'"
            >
            </textarea>
            <div class="error-container" v-show="descriptionError">Error message: The description field cannot be empty, please provide a description.</div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">Skills for this track</div>
            <ion-searchbar
              class="custom searchbar-ug-track"
              show-cancel-button="never"
              ref="searchbar"
              @ionClear="clearSearchBar()"
              placeholder="Add new skills"
              @ionChange="handleSearchInput"
            />
            <div style="box-shadow: 0px 0px 12px #0000001f; border-radius: 20px; height: 200px; overflow-y: auto; margin-right: 8px" v-if="skills.length > 0 && showList">
              <div class="skills-list">
                <ul style="list-style: none; padding-left: 1.5rem">
                  <li
                    @click="addSkill(skill.title)"
                    class="skill-item"
                    style="text-align: left; width: 95%; padding: 10px 10px; cursor: pointer; color: #000000; font: normal normal bold 16px/26px Arial"
                    v-for="(skill, index) in skills"
                    :key="index"
                    v-html="skill.displayText"
                  ></li>
                </ul>
              </div>
            </div>
            <div v-if="selecetedSkills.length > 0" class="selected-skill">
              <ion-chip v-for="(skillName, index) in selecetedSkills" :key="index">
                <ion-label>{{ skillName }}</ion-label>
                <ion-icon name="close" @click="deleteSkill(skillName)"></ion-icon>
              </ion-chip>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <div class="create-track-footer">
      <ion-button
        v-if="!isEditTrack"
        shape="round"
        color="primary"
        class="ion-button-primary-dark create-new-track-btn"
        @click="createTrack"
        :disabled="disableSubmitBtn || (imageSelected == 'user' && image.length == 0) || fileError != ''"
      >
        Create
      </ion-button>
      <ion-button v-else shape="round" color="primary" class="ion-button-primary-dark create-new-track-btn" @click="editTrack" :disabled="disableSubmitBtn || fileError != ''">
        Update
      </ion-button>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage'

export default {
  name: 'AddToTrack',
  props: {
    isEditTrack: {
      type: Boolean,
      required: false,
      default: () => {
        false
      }
    },
    addItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  data() {
    return {
      addedSkills: [],
      skills: [],
      name: '',
      image: '',
      description: '',
      nameError: false,
      descriptionError: false,
      authToken: '',
      requiredFields: ['name', 'description'],
      disableSubmitBtn: true,
      selecetedSkills: [],
      showList: false,
      showPreviewImg: false,
      imageSelected: '',
      editImageSrc: '',
      fileError: '',
      maxFileSize: 10,
      fileUploadIndex: 0,
      itemObj: {
        itemId: '',
        name: '',
        format: '',
        duration: {
          value: 0,
          type: ''
        }
      }
    }
  },
  computed: {},
  watch: {},
  async created() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    if (this.isEditTrack) this.fetchTrackData()
    if (!this.isEditTrack) this.selectImage()
  },
  mounted() {},
  methods: {
    openImageUploadPopup() {
      document.getElementById('fileinput').click()
    },
    async fetchTrackData() {
      const trackResult = await this.$learnAdmin.getUGTrackById({ token: this.authToken, id: this.addItem.guid })
      this.name = trackResult.data.name
      this.description = trackResult.data.description
      this.selecetedSkills = trackResult.data.skills.map((skill) => skill.guid)
      this.editImageSrc = trackResult.data.image.signedRelativeUri
      this.disableSubmitBtn = false
      if (this.editImageSrc) {
        this.showPreviewImg = true
        this.imageSelected = 'user'
      }
    },
    async selectImage() {
      this.fileUploadIndex++
      this.editImageSrc = ''
      this.showPreviewImg = false
      this.imageSelected = 'default'
      this.image = ''
      this.fileError = ''
      this.image = await this.getFileFromUrl(this.$refs.defaultImg.src, 'default-thumbnail')
    },
    addSkill(skill) {
      if (!this.selecetedSkills.includes(skill)) this.selecetedSkills.push(skill)
      this.showList = false
      this.$refs.searchbar.value = ''
    },
    deleteSkill(skill) {
      this.selecetedSkills = this.selecetedSkills.filter((e) => e != skill)
    },
    closeTrackBar() {
      this.$eventBus.$emit(`close-track-bar`)
    },
    async createTrack() {
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const formData = new FormData()
      let payoadImgName = this.image.name.replace(/\s+/g, '')
      formData.append('image', this.image, payoadImgName)
      formData.set('description', this.description)
      formData.set('name', this.name)
      formData.set('skills', this.selecetedSkills)
      formData.set('authorGuid', '3f94ecda-5f58-11ec-b271-42010aa8003c')
      if (this.addItem.externalArtifactGuid) {
        this.itemObj.itemId = this.addItem.artifactId || ''
      } else {
        this.itemObj.itemId = this.addItem.id || ''
      }
      this.itemObj.name = this.addItem.title || ''
      this.itemObj.format = this.addItem.format?.name.toLowerCase() || ''
      const duration = this.addItem?.duration ? this.addItem.duration.toString().split(' ') : [0, 'seconds']
      if (duration.length > 0) {
        this.itemObj.duration.value = parseInt(duration[0])
        this.itemObj.duration.type = 'seconds'
      }
      const payload = {
        token: this.authToken,
        body: formData
      }
      this.$learnAdmin.createTrack(payload).then(
        async (response) => {
          if (response.data.data.guid) {
            const payloadAddItem = {
              token: this.authToken,
              id: response.data.data.guid,
              body: {
                items: [this.itemObj]
              }
            }
            this.$eventBus.$emit(`close-track-bar`)
            this.fileError = ''
            let responseAddItem = await this.$learnAdmin.addItemsToTrack(payloadAddItem)
            if (responseAddItem.data.data.guid) {
              this.$router.push({ name: 'UGTrack', params: { id: response.data.data.guid, pgrTrk: false } })
            }
          }
        },
        () => {
          this.fileError = 'Error with uploaded file, Please try with default thumbnail image'
        }
      )
    },
    async editTrack() {
      const formData = new FormData()
      if (this.image) {
        let payoadImgName = this.image.name.replace(/\s+/g, '')
        formData.append('image', this.image, payoadImgName)
      }
      formData.set('description', this.description)
      formData.set('name', this.name)
      formData.set('skills', this.selecetedSkills)
      formData.set('authorGuid', '3f94ecda-5f58-11ec-b271-42010aa8003c')
      const payload = {
        token: this.authToken,
        id: this.addItem.guid,
        body: formData
      }
      this.$learnAdmin.editTrack(payload).then(
        (response) => {
          if (response.data.data.guid) {
            window.location.reload()
          }
          this.fileError = ''
          this.$eventBus.$emit(`close-track-bar`)
        },
        () => {
          this.fileError = 'Error with uploaded file, Please try with default thumbnail image'
        }
      )
    },
    handleClick(event) {
      const files = event.target.files
      if (!files.length) return
      this.uploadFile(files[0])
    },
    uploadFile(fileObj) {
      const fileSize = Math.floor(fileObj.size / (1024 * 1024), 2)
      if (fileSize > this.maxFileSize) {
        this.fileError = 'Error: File cannot exceed 10MB'
        this.fileUploadIndex++
        this.editImageSrc = ''
        this.showPreviewImg = false
      } else if (['image/png'].includes(fileObj.type)) {
        this.fileError = ''
        this.image = fileObj
        var reader = new FileReader()
        reader.onload = () => {
          var output = this.$refs.previewImage
          output.src = reader.result
        }
        reader.readAsDataURL(fileObj)
        this.showPreviewImg = true
        this.imageSelected = 'user'
      }
    },
    handleDrop(event) {
      event.stopPropagation()
      event.preventDefault()
      const files = event.dataTransfer.files
      if (!files.length) return
      this.uploadFile(files[0])
      event.stopPropagation()
      event.preventDefault()
    },
    handleDragover(event) {
      event.stopPropagation()
      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy'
    },
    handleInput(event, field) {
      const fieldValue = event.target.value.trim()
      this[field] = fieldValue
      this[`${field}Error`] = fieldValue.length == 0 ? true : false
      this.disableSubmitBtn = !this.requiredFields.every((el) => this[el] && this[el].length > 0 && this[`${el}Error`] === false)
      if (this[`${field}Error`]) event.target.blur()
    },
    async handleSearchInput(event) {
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const skill = event.target.value
      if (skill.length > 2) {
        this.skills = []
        const foundSkills = await this.$learnAdmin.searchSkillByName({ token: this.authToken, skill: encodeURIComponent(skill) })
        await foundSkills.map(async (foundSkill) => {
          var regex = event.target.value
          var mask = `<span style="font-weight:normal; font-family: 'Arial'; color: #5C5C5C; top: unset !important">${event.target.value}</span>`
          if (foundSkill) {
            foundSkill.displayText = foundSkill.title.replaceAll(regex, mask)
            this.skills.push(foundSkill)
          }
        })
        this.showList = true
      }
    },
    async clearSearchBar() {
      this.showList = false
      this.$refs.searchbar.value = ''
      this.skills = []
    },
    async getFileFromUrl(url, name, defaultType = 'image/jpeg') {
      const response = await fetch(url)
      const data = await response.blob()
      return new File([data], name, {
        type: data.type || defaultType
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
}
div {
  color: #000000;
}

.mobile-align-end {
  text-align: end;
}

.track-form {
  height: 533px;
  width: 90%;
  padding-left: 61px;
}

.track-form > ion-row {
  padding-left: 0.5rem !important;
}

.slider-close {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  margin: 20px 0px 0px 40px;
}

.create-track-footer {
  width: 100%;
  border-top: 2px solid #dfdede;
  position: absolute;
  text-align: end;
  bottom: 0px;
  max-height: 100px;
  padding-right: 3.5rem;
}

.create-new-track-btn {
  width: 210px;
  height: 39px;
  margin: 30px 16px 33px 0px;
  text-transform: none !important;
  font: normal normal normal 16px/22px Futura PT Demi;
}
.default-image img {
  width: 165px;
  height: 165px;
}

.thumb-header-custom {
  font: normal normal bold 14px/17px Arial;
  text-align: center;
  width: 273px;
  padding-bottom: 9px;
}

.thumb-header-default {
  font: normal normal bold 14px/17px Arial;
  padding-bottom: 9px;
}

@media screen and (max-width: 480px) {
  .track-form {
    width: 85%;
    padding-left: 16px !important;
  }

  .img-style {
    width: 80%;
  }

  .slider-close {
    margin: 20px 0px 0px 0px;
  }

  .create-track-footer {
    width: 100%;
    padding-right: 0px !important;
  }

  .thumbnail-header {
    img {
      width: 88px;
      height: 88px;
    }
  }

  .thumb-header {
    height: 88px;
    font: normal normal bold 14px/17px Arial;
    display: table-cell;
    vertical-align: middle;
  }
}

.close-button-icon {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.track-header {
  margin-top: 1rem;
  font: normal normal normal 30px/34px Futura PT Demi;
  text-align: center;
}

.input-row {
  input {
    width: 100%;
    margin: 8px 0px;
    height: 45px;
    padding-left: 12px;
    background: #ffffff 0% 0% no-repeat padding-box;

    border-radius: 4px;
    color: #000000;
    font: normal normal normal 16px/22px Arial;

    :focus {
      border: 2px solid #90bafe;
    }

    ::placeholder {
      color: #9f9f9f;
    }
  }

  .valid-input {
    border: 1px solid #e0e1e1;
  }

  .error-input {
    border: 1px solid #e0301e;
  }

  textarea {
    width: 100%;
    height: 90px;
    margin: 8px 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #000000;
    font: normal normal normal 16px/22px Arial;
    padding-left: 6px;

    :focus {
      border: 1px solid #90bafe;
    }

    ::placeholder {
      color: #9f9f9f;
    }
  }
}

ion-searchbar {
  --background: #ffffff 0% 0% no-repeat padding-box !important;
  --border-radius: 60px;
  border: 2px solid var(--ion-color-dark-grey);
  --box-shadow: none;
  border-radius: 60px;
  height: 48px;
  text-align: start;
  margin-top: 8px;
}

.track-name {
  width: 63px;
  height: 22px;
  font: normal normal bold 12px/14px Arial;
  background: #e0e1e1 0% 0% no-repeat padding-box;
  padding-left: 11px;
  padding-top: 4px;
  border-radius: 20px;
}

.track-desc {
  font: normal normal normal 24px/34px Futura PT Demi;
  letter-spacing: 0.01px;
  color: #1f3370;
  padding-top: 16px;
}

.track-time {
  margin: 20px 0px 20px 0px;
}

.track-add-btn {
  margin-bottom: 40px;
}

.track-row {
  border-bottom: 2px solid #dfdede;
  margin-bottom: 45px;
}

.track-btn {
  text-transform: none !important;
  font: normal normal normal 16px/22px Futura PT Demi;
}

.track-details-col {
  padding-left: 27px;
}

.icn-add-bg {
  background: #1f3370 0% 0% no-repeat padding-box;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  margin-right: 10px;
}

.g-icon--small {
  width: 8px !important;
  height: 8px !important;
}

.get-started {
  text-align: center;
  margin-bottom: 0px !important;
}

.add-track-header {
  font: normal normal normal 30px/34px Futura PT Demi;
}

.req-info {
  font: normal normal normal 12px/30px Arial;
}

.track-thumbnail {
  font: normal normal normal 20px/24px Futura PT Demi;
  margin-top: 32px;
}

.thumbnail-msg {
  font: normal normal normal 16px/22px Arial;
  color: #5c5c5c;
  margin-top: 10px;
  text-align: left;
}

.skills-list {
  text-align: start;

  li {
    :hover {
      background: var(--ion-color-blue-light) 0% 0% no-repeat padding-box !important;
    }
  }
}

.skill-item {
  padding: 0px 8px;
}

.skill-item:hover {
  background: var(--ion-color-blue-light) !important;
  padding: 0px 8px;
  color: var(--ion-color-tertiary) !important;
}

.section-title {
  font-weight: bold;
  font-family: Arial;
  font-size: 14px;
  text-align: left;
}

.create-track-btn {
  width: 126px;
  height: 39px;
  margin: 10px 56px 33px 0px;
  text-transform: none !important;
  font: normal normal normal 16px/22px Futura PT Demi;
}

.file-upload-wrapper {
  color: var(--ion-color-tertiary);
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 1rem;

  input {
    display: none;
  }
}

.error-container {
  color: var(--ion-color-status);
  margin-bottom: 1rem;
  font: normal normal normal 0.75rem/1.125rem Arial;
  text-align: left;
}

.list-container {
  position: relative;
  padding-top: 10px;
}

.scrollable-content {
  height: 500px;
  padding: 0 30px;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;

    @media (min-width: 600px) {
      width: 9px;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #efefef;
    border: 1px solid #efefef;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 4px;

    @media (min-width: 600px) {
      background: #00d1b0;
    }
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;

    @media (min-width: 600px) {
      background: #00d1b0;
    }
  }
}

.selected-skill {
  ion-chip {
    --font: normal normal bold 11px/12px Arial;
    --color: #1f3370;
    --background: #bcc2d4;
  }

  margin-top: 6px;
  text-align: left;
}

.imgBg {
  width: 273px;
  height: 280px;
  cursor: pointer;
  background-image: url('~@/assets/images/thumbnail-track.png');
  background-size: contain;
  text-align: center;
  padding-top: 42px;
}

.image-border {
  border: 5px solid #1f3370;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bcc2d4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f3370;
}

ion-button {
  --box-shadow: none;
}

.file-error {
  padding-right: 1rem;
  margin-top: 6px;
  color: var(--ion-color-status);
  margin-bottom: 1rem;
  font: normal normal normal 0.75rem/1.125rem Arial;
}
</style>