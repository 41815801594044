export default [
  {
    path: '/help-center',
    name: 'HelpCenter',
    component: () => import('@/views/preference/HelpCenter'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: false,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    },
  },
  {
    path: '/help-center-main',
    name: 'HelpCenterMain',
    component: () => import('@/views/preference/HelpCenter'),
    meta: {
      whitelist: true,
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: false,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/notifications-list',
    name: 'NotificationsList',
    component: () => import('@/views/preference/NotificationsList'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: true,
      jumpBackInVisible: false
    }
  },
]
