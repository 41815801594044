import { render, staticRenderFns } from "./UGTrack.vue?vue&type=template&id=039701fc&scoped=true&"
import script from "./UGTrack.vue?vue&type=script&lang=js&"
export * from "./UGTrack.vue?vue&type=script&lang=js&"
import style0 from "./UGTrack.vue?vue&type=style&index=0&id=039701fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039701fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/vtrinh004/Desktop/YTC/web/proedge-learn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('039701fc')) {
      api.createRecord('039701fc', component.options)
    } else {
      api.reload('039701fc', component.options)
    }
    module.hot.accept("./UGTrack.vue?vue&type=template&id=039701fc&scoped=true&", function () {
      api.rerender('039701fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/collection/UGTrack.vue"
export default component.exports