<template>
    <div class="tracks-modal">
      <div class="tracks-modal__content">
        <ion-row class="tracks-modal__row ion-align-items-center ion-no-padding">
          <ion-col class="ion-text-start">
            <svg-icon icon-class="filter-close" class="g-icon--small close g-clickable tracks-modal__close" @click="dismiss"></svg-icon>
            <p class="tracks-modal__title">{{ options.title }}</p>
            <p class="tracks-modal__subtitle">{{ options.subtitle1 }}</p>
            <p class="tracks-modal__subtitle">{{ options.subtitle2 }}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-end tracks-modal__button-row">
            <ion-button class="ion-text-end tracks-modal__button-one cancel-button" @click="dismiss">{{options.button1}}</ion-button>
            <ion-button class="ion-text-end tracks-modal__button-two" @click="selectTrack(options.button2)">{{options.button2}}</ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </template>
  
  <script>
  import { popoverController } from '@modus/ionic-vue';
  export default {
    name: 'UGTrackOverlayOptions',
    props: {
      options: {
        type: Object,
        required: true,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {}
    },
    computed: {},
    created() {},
    async activated() {},
    methods: {
      selectTrack(option){
        popoverController.dismiss(option)
      },
      dismiss(){
        popoverController.dismiss()
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @include block('tracks-modal') {
    width: 350px;
    padding: 16px;
  
    @include element('header') {
      padding: 20px 20px 10px 25px;
      font-size: 34px;
      font-family: 'Futura PT Demi';
      color: var(--ion-color-black);
    }
  
    @include element('title') {
      font-family: 'Futura PT Demi';
      font-size: 24px;
      color: var(--ion-color-black);
      margin: 0px;
    }

    @include element('subtitle') {
      font-family: 'Arial';
      font-size: 16px;
      color: var(--ion-color-medium);
      margin: 22px 0px 0px 0px;
    }

    @include element('close') {
      float: right;
    }

    @include element('button-row'){
      margin-top: 8px;
    }

    @include element('button-one'){
      color: var(--ion-color-tertiary);
      --background: var(--ion-color-white);
      font-family: 'Futura PT Demi';
      font-size: 16px;
      border: 1.5px solid var(--ion-color-tertiary);
      border-radius: 25px !important;
      --border-radius: 25px !important;
      text-transform: capitalize;
      --box-shadow: none;
    }

    @include element('button-two'){
      color: var(--ion-color-black);
      --background: var(--ion-color-secondary-dark-yellow);
      font-family: 'Futura PT Demi';
      font-size: 16px;
      border-radius: 25px !important;
      --border-radius: 25px !important;
      text-transform: capitalize;
      --box-shadow: none;
    }
    
  }

  .cancel-button:hover{
    --background: var(--ion-color-focus);
  }
  </style>