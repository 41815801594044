import { render, staticRenderFns } from "./RecommendedSkeleton.vue?vue&type=template&id=579ac745&scoped=true&"
import script from "./RecommendedSkeleton.vue?vue&type=script&lang=js&"
export * from "./RecommendedSkeleton.vue?vue&type=script&lang=js&"
import style0 from "./RecommendedSkeleton.vue?vue&type=style&index=0&id=579ac745&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "579ac745",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/vtrinh004/Desktop/YTC/web/proedge-learn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('579ac745')) {
      api.createRecord('579ac745', component.options)
    } else {
      api.reload('579ac745', component.options)
    }
    module.hot.accept("./RecommendedSkeleton.vue?vue&type=template&id=579ac745&scoped=true&", function () {
      api.rerender('579ac745', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/explore/components/RecommendedSkeleton.vue"
export default component.exports