<template>
  <div class="comment-flagged-container">
    <div class="header-content">
      <div class="title-content">
        <svg-icon icon-class="Icon feather-flag-black"></svg-icon>
        <h2 size="8">{{ commentFlaggedTitle }}</h2>
      </div>
      <svg-icon icon-class="filter-close" class="g-icon--small close" @click="onCancel"></svg-icon>
    </div>
    <p>{{ commentFlaggedText }}</p>
  </div>
</template>
    
<script>
import { popoverController } from '@modus/ionic-vue'
export default {
  name: 'CommentFlaggedAlert',
  props: {},
  computed: {},
  data() {
    return {
      commentFlaggedTitle: 'Comment has been flagged',
      commentFlaggedText: 'Thank you! This comment has been reported, and will be hidden.'
    }
  },
  created() {},
  async mounted() {},
  filters: {},
  methods: {
    onCancel() {
      popoverController.dismiss()
    }
  }
}
</script>
    <style lang="scss">
.comment-flag-success {
  .popover-content {
    width: 420px;
    border-radius: 25px !important;
    background: #efefef;
    text-align: left;
    padding: 2rem;
    @media (max-width: 484px) {
      width: 100%;
      left: 0 !important;
    }
  }
}
</style>
  <style lang="scss" scoped>
.comment-flagged-container {
  .header-content {
    display: flex;
    justify-content: space-between;
    .title-content {
      display: flex;
      align-items: baseline;
      svg {
        margin-right: 1rem;
      }
      h2 {
        font: normal normal normal 24px/40px 'Futura PT Demi';
        margin-top: 0;
      }
    }
    .close {
        cursor: pointer;
      }
  }
  p{
    margin-top: 14px ;
    font: normal normal normal 16px/22px Arial;
  }
}
</style>
    