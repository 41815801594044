<template>
  <div class="bar-graph-container">
    <span class="index">{{ index }} Star</span>
    <span class="unrate-bar">
      <span class="rate-bar" ref="rating"></span>
    </span>
    <span class="score">{{ score }}%</span>
  </div>
</template>

<script>
export default {
  name: 'ItemRatingBar',
  components: {},
  props: {
    index: {
      type: Number,
      required: true
    },
    score: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    const bar = this.$refs.rating
    setTimeout(() => {
      bar.style.width = `${this.score}%`
    }, 100)
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.bar-graph-container {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  align-items: center;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: 'Arial';

  .index {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    white-space: nowrap;
  }
  .score {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    white-space: nowrap;
  }
}

.unrate-bar {
  background: #b9b9b96c 0% 0% no-repeat padding-box;
  opacity: 1;
  display: inline-flex;
  height: 15px;
}

.rate-bar {
  transition: 1s ease-in-out;
  background: #b98032 0% 0% no-repeat padding-box;
  opacity: 1;
  display: inline-flex;
  height: 15px;
  width: 0%;
}
</style>
