var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.$platform === "Desktop" && this.$route.path === "/landing"
          ? "edge-cookieNotice signup-page"
          : "edge-cookieNotice signup-page footer"
    },
    [
      _vm.$platform === "Desktop"
        ? _c("div", { staticClass: "d-flex logo" }, [
            (this.$route.meta.isMenuPrivacy !== true &&
              this.$route.path !== "/landing") ||
            (this.$platform === "Mobile" &&
              this.$route.meta.isMenuPrivacy !== true) ||
            (this.$route.path !== "/landing" &&
              this.$route.meta.isMenuPrivacy !== true)
              ? _c(
                  "nav",
                  { staticClass: "d-flex", attrs: { id: "cookieNoticeNav" } },
                  [
                    _c(
                      "div",
                      { staticClass: "logo" },
                      [
                        _c("svg-icon", {
                          style: _vm.isLoggedIn ? "cursor: pointer;" : "",
                          attrs: {
                            "icon-class": "logo",
                            "class-name": "logo--size"
                          },
                          on: { click: _vm.onClickLogo }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.$platform == "Mobile"
        ? _c(
            "button",
            {
              staticClass: "back_arrow",
              staticStyle: { "z-index": "100" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: {
                  "icon-class": "thin-back-arrow",
                  "class-name": "g-icon--small"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { ref: "top", staticClass: "edge-cookieNotice__policy" }, [
        _c("div", { staticClass: "ion-text-start" }, [
          _c(
            "div",
            {
              class:
                _vm.$platform === "Desktop"
                  ? "edge-cookieNotice__wrapper"
                  : "edge-cookieNotice__wrapper-mobile"
            },
            [
              _c(
                "div",
                {
                  class:
                    _vm.$platform === "Desktop"
                      ? this.$route.path === "/landing"
                        ? "scrollable-content landing"
                        : "scrollable-content footer"
                      : "scrollable-content mobile"
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "g-type-xlarge title",
                      staticStyle: { "font-family": "'Futura PT Demi'" }
                    },
                    [_vm._v("Cookie Information")]
                  ),
                  _c(
                    "p",
                    [
                      _vm._v(
                        " This cookie information is relevant for visitors to ProEdge. For more information about how we process your personal information collected through ProEdge, please visit our "
                      ),
                      _vm.$platform === "Mobile"
                        ? _c(
                            "router-link",
                            {
                              staticClass: "privacy",
                              attrs: { to: { name: "OnboardingPrivacyPolicy" } }
                            },
                            [_vm._v("ProEdge privacy statement")]
                          )
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: "./privacy-policy",
                                target: "_blank"
                              }
                            },
                            [_vm._v("ProEdge privacy statement")]
                          ),
                      _vm._v(". ")
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      " Cookies are small text files that are placed on your device by the websites and applications that you visit. They are widely used in order to make websites and apps work, or work more efficiently, as well as to provide information to the owners of the site or app. The use of cookies and similar technologies is now standard for most websites and apps. "
                    )
                  ]),
                  _c("h3", [_vm._v("Managing cookies on your device")]),
                  _c("p", [
                    _vm._v(
                      " You can control and manage cookies using your browser settings as well as certain opt-out tools (see below). Please note that if you are accessing this application using multiple browsers or devices, you will need to manage cookies on each browser and device, as applicable. Please also note that removing or blocking cookies can impact your user experience and some functionality may no longer be available. "
                    )
                  ]),
                  _c("h3", [_vm._v("Using your browser to control cookies")]),
                  _c("p", [
                    _vm._v(
                      " Most browsers allow you to view, manage, delete and block cookies for a website or application. Be aware that if you delete all cookies then any preferences you have set will be lost, including the ability to opt-out from cookies as this function itself requires placement of an opt out cookie on your device. Guidance on how to control cookies for common browsers is linked below. "
                    )
                  ]),
                  _vm._m(0),
                  _c("h3", [_vm._v("Opt-out tools to control cookies")]),
                  _vm._m(1),
                  _c("p", [
                    _vm._v(
                      "You can set your browser to block or alert you about these cookies, but some parts of the application may no longer work."
                    )
                  ]),
                  _c("h3", [_vm._v("Use of cookies")]),
                  _c("p", [
                    _vm._v(
                      " Below is a detailed list of the cookies we use on this application. We classify the use of cookies into the following categories: (1) necessary; and (2) analytical/performance. You can find more information about each category in the relevant sections below. For each cookie listed below, we also note the duration of the cookie and identify whether it is a first party or third party cookie. Please note: "
                    )
                  ]),
                  _vm._m(2),
                  _vm._m(3),
                  _vm._m(4),
                  _c("p", [
                    _vm._v(
                      " These cookies are necessary for the website to operate. Our website cannot function without these cookies and they can only be disabled by changing your browser preferences. "
                    )
                  ]),
                  _c(
                    "table",
                    { class: _vm.$platform === "Mobile" ? "table-mobile" : "" },
                    [
                      _vm._m(5),
                      _vm._m(6),
                      _vm._m(7),
                      _vm._m(8),
                      _vm._m(9),
                      _vm._m(10),
                      _vm._m(11),
                      _vm._m(12),
                      _vm._m(13),
                      _vm._m(14)
                    ]
                  ),
                  _vm._m(15),
                  _c("p", [
                    _vm._v(
                      " These cookies allow us to measure and report on website activity by tracking page visits, visitor locations and how visitors move around the site. The information collected does not directly identify visitors. We drop these cookies to help us analyze the data. "
                    )
                  ]),
                  _c(
                    "table",
                    { class: _vm.$platform === "Mobile" ? "table-mobile" : "" },
                    [_vm._m(16), _vm._m(17), _vm._m(18), _vm._m(19)]
                  ),
                  _c("br")
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "no-bullet" }, [
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en",
              target: "_blank"
            }
          },
          [_vm._v("Google Chrome")]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences",
              target: "_blank"
            }
          },
          [_vm._v("Mozilla Firefox")]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac",
              target: "_blank"
            }
          },
          [_vm._v("MacOS Safari")]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies",
              target: "_blank"
            }
          },
          [_vm._v("Microsoft Internet Explorer")]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies",
              target: "_blank"
            }
          },
          [_vm._v("Microsoft Edge")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        ' Some of the third parties that collect information from or about you on PwC sites or apps may participate in the Digital Advertising Alliance\'s ("DAA") self-regulatory program. To make choices about the use of information from or about you for online behavioral or interest-based advertising on websites (desktop and mobile browsers), please visit the '
      ),
      _c(
        "a",
        { attrs: { href: "https://optout.aboutads.info/", target: "_blank" } },
        [_vm._v("DAA")]
      ),
      _vm._v(
        ", and within mobile applications, you can download the DAA's AppChoices application from your mobile device at "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.aboutads.info/appchoices",
            target: "_blank"
          }
        },
        [_vm._v("www.aboutads.info/appchoices")]
      ),
      _vm._v(" or from your device's app store. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("'First party'")]),
      _vm._v(
        " cookies are directly stored by the website (or domain) you visit. These cookies allow website owners to collect analytics data, remember language settings, and perform other useful functions that provide a good user experience. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("'Third party'")]),
      _vm._v(
        " cookies are created by domains that are not the website (or domain) that you are visiting. These are usually used for analytics and/or online-advertising purposes and placed on a website through scripts or tags. A third-party cookie is accessible on any website that loads the third party server's code. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [_c("strong", [_vm._v("Necessary cookies")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Cookie Name")]),
      _c("th", [_vm._v("Host")]),
      _c("th", [_vm._v("Cookie Type")]),
      _c("th", [_vm._v("Life Span")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("KC_RESTART in our configuration")]),
      _c("td", [_vm._v("pwc.com")]),
      _c("td", [_vm._v("First Party")]),
      _c("td", [_vm._v("Session")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("KEYCLOAK_IDENTITY")]),
      _c("td", [_vm._v("pwc.com")]),
      _c("td", [_vm._v("First Party")]),
      _c("td", [_vm._v("Session")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("KEYCLOAK_IDENTITY_LEGACY")]),
      _c("td", [_vm._v("pwc.com")]),
      _c("td", [_vm._v("First Party")]),
      _c("td", [_vm._v("Session")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("KEYCLOAK_LOCALE")]),
      _c("td", [_vm._v("pwc.com")]),
      _c("td", [_vm._v("First Party")]),
      _c("td", [_vm._v("Session")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("KEYCLOAK_REMEMBER_ME")]),
      _c("td", [_vm._v("pwc.com")]),
      _c("td", [_vm._v("First Party")]),
      _c("td", [_vm._v("Session")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("KEYCLOAK_SESSION")]),
      _c("td", [_vm._v("pwc.com")]),
      _c("td", [_vm._v("First Party")]),
      _c("td", [_vm._v("Session")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("ProAuthSSO")]),
      _c("td", [_vm._v("pwc.com")]),
      _c("td", [_vm._v("First Party")]),
      _c("td", [_vm._v("18 seconds")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("device_id")]),
      _c("td", [_vm._v("everafter.ai")]),
      _c("td", [_vm._v("Third Party")]),
      _c("td", [_vm._v("1 Year")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("access_token")]),
      _c("td", [_vm._v("everafter.ai")]),
      _c("td", [_vm._v("Third Party")]),
      _c("td", [_vm._v("1 Year")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _c("strong", [_vm._v("Analytical / Performance cookies ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Cookie Name")]),
      _c("th", [_vm._v("Host")]),
      _c("th", [_vm._v("Cookie Type")]),
      _c("th", [_vm._v("Life Span")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("_ga")]),
      _c("td", [_vm._v("google.com")]),
      _c("td", [_vm._v("Third Party")]),
      _c("td", [_vm._v("2 years")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("_gid")]),
      _c("td", [_vm._v("google.com")]),
      _c("td", [_vm._v("Third Party")]),
      _c("td", [_vm._v("24 hours")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("_gat_UA-209584847-1")]),
      _c("td", [_vm._v("google.com")]),
      _c("td", [_vm._v("Third Party")]),
      _c("td", [_vm._v("2 years")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }