<template>
  <div>
    <div v-if="isSideFilterActive" @click="openSideFilter"></div>
    <div class="filter-container">
      <div class="filter-list">
        <div class="filter-title">Filter</div>
        <template v-for="filter in filters.slice(0, 5)">
          <div :key="filter.name" v-if="showFilterPills(filter.name) && $platform == 'Desktop'">
            <div :class="filter.optionsVisible || filter.isApply ? 'filter-type filter-type-selected' : 'filter-type'" @click="onFilterType(filter)" :key="filter.name">
              {{ filter.name }}

              <img src="@/assets/images/table-icon.png" alt="Completed date" height="16px" width="17px" v-if="filter.name == 'Completed date'" />
              <svg-icon icon-class="Icon-awesome-chevron-down" v-if="filter.name != 'Completed date'"></svg-icon>
            </div>
            <div v-if="filter.optionsVisible" class="filter-container-backdrop" @click="onCancel(filter)"></div>
            <div v-if="filter.optionsVisible" class="filter-option-container">
              <ion-searchbar
                v-if="filter.options.length > 6"
                search-icon="search-outline"
                ref="searchbar"
                :placeholder="`Search ${filter.name}`"
                :value="searchFilter"
                @ionInput="handleSearchInput($event, filter)"
                @ionClear="handleClearInput(filter)"
              ></ion-searchbar>
              <!-- Checkbox multiple Selection -->
              <div v-if="!['Found in', 'Completed date'].includes(filter.name)">
                <div :class="filter.options.length <= 6 ? 'filter-option-content' : 'filter-option-content filter-option-content-scroll'">
                  <div v-for="option in filter.options" :key="option.name + option.count" :style="option.style ? option.style : 'display: flex'">
                    <div>
                      <ion-checkbox
                        :checked="option.selected"
                        :value="option.name"
                        class="filter-checkbox"
                        color="tertiary"
                        mode="md"
                        :disabled="option.count <= 0 || (option.name === 'Content type' && !option.userAccess)"
                        @ionChange="checkSelections($event, filter)"
                      />
                    </div>
                    <div :class="option.count <= 0 || (option.name === 'Content type' && !option.userAccess) ? 'filter-option-title-disable' : 'filter-option-title'">
                      {{ filterName(option, filter.name) }} ({{ option.count }})
                    </div>
                  </div>
                </div>
              </div>
              <!-- Radio single Selection -->
              <div v-if="filter.name == 'Completed date'">
                <ion-radio-group @ionChange="checkSelectionsRadio($event, filter)" :value="selectedDate" :key="selectedDate">
                  <div :class="filter.options.length <= 6 ? 'filter-option-content' : 'filter-option-content filter-option-content-scroll'">
                    <div v-for="option in filter.options" :key="option.name + option.count + selectedDate" :style="option.style ? option.style : 'display: flex'">
                      <div>
                        <ion-radio :key="selectedDate" :value="option.name" color="tertiary" :disabled="option.count <= 0" slot="start" mode="md" class="filter-radio" />
                      </div>
                      <div :class="option.count <= 0 || (option.name === 'Content type' && !option.userAccess) ? 'filter-option-title-disable' : 'filter-option-title'">
                        {{ filterName(option, filter.name) }}
                      </div>
                    </div>
                  </div>
                </ion-radio-group>
              </div>
              <div class="filter-option-footer">
                <div class="filter-option-footer-cancel" @click="onCancel(filter)">Cancel</div>
                <ion-button shape="round" color="primary" class="filter-option-footer-btn-apply" @click="onShow(filter)">Apply</ion-button>
              </div>
            </div>
          </div>
        </template>
        <div class="filter-type" @click="openSideFilter">
          All filters
          <svg-icon style="--transform: rotate(180deg)" icon-class="icon-filter"></svg-icon>
        </div>
      </div>
      <!-- All filters for mobile and below 1024 resolution -->
      <!-- <div class="all-filter" @click="openSideFilter">
                All filters
                <svg-icon style="--transform: rotate(180deg)" icon-class="icon-filter"></svg-icon>
            </div> -->
      <div class="action-btns" v-if="showAdd">
        <ion-button shape="round" color="primary" class="learning-plan-btn" title="Add external learning" @click="openExternalSidebar">
          <div class="add-icon-container">
            <svg-icon icon-class="add-icon" style="height: 10px; width: 10px; position: relative; top: -0.1em; left: -0.1em" />
          </div>
          Add
        </ion-button>
      </div>
    </div>
    <div v-if="selected.length" class="filter-selected-container">
      <div class="filter-selected-title">Selected</div>
      <div v-for="filter in selected" :key="filter.name">
        <div v-if="filter.type != 'Found in'" class="filter-selected">
          {{ filterName(filter) }}
          <svg-icon icon-class="icon-remove-filter" @click="removeFilter(filter)"></svg-icon>
        </div>
        <div v-else class="filter-selected">
          Items found in "{{ filterName(filter) }}"
          <svg-icon icon-class="icon-remove-filter" @click="removeFilter(filter)"></svg-icon>
        </div>
      </div>
      <div class="filter-selected-clear-all" @click="onClearFilters()">Clear all filters</div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'
import { popoverController } from '@modus/ionic-vue'
import MyLearningFilterSideBar from '@/views/my-learning/components/MyLearningFilterSideBar'

export default {
  name: 'LearningHistoryFilters',
  components: {},
  props: {
    data: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    sideFilterData: {
      type: Boolean,
      require: false,
      default: () => {
        return false
      }
    },
    resultsCount: {
      type: Number,
      require: false,
      default: () => {
        return 0
      }
    },
    onTab: {
      type: String,
      require: true,
      default: () => {
        return 'Recommended'
      }
    }
  },
  data() {
    return {
      mlpFilters: {},
      userAccess: {},
      key: 0,
      authToken: '',
      searchFilter: '',
      filters: [],
      selected: [],
      resultFilters: {},
      showSort: true,
      showAdd: false,
      sortOptionVisible: false,
      isDesktopPlatform: true,
      isSideFilterActive: false,
      selectedSort: 'Best match',
      selectedDate: '',
      selectedFilterType: '',
      durationLabels: ['< 15 mins', '15 mins - 1 hr', '1 - 3 hours', '3 - 5 hours', '5 - 10 hours', '10+ hours'],
      creditHourLabels: ['< 0.5 hr', '0.5 - 1 hr', '1 - 2 hours', '2 - 5 hours', '5 - 10 hours', '10 - 20 hours', '20 - 40 hours', '40+ hours']
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'enabledFeatureFlags'])
  },
  watch: {},
  async mounted() {
    this.$on('clear-all-filters', () => {
      this.onClearFilters()
    })
    this.$on('selected-filters-output', (filter) => {
      this.onShow(filter)
    })
    this.$on('coming-from-side-filter', (data) => {
      this.$emit('side-filter', data)
      popoverController.dismiss()

    })
    this.userAccess = await localforage.getItem('my_user_access')
    this.data.learning.userAccess = this.userAccess
    this.selected = this.data.learning.selectedFilters
    const completedDate = this.selected.find((item) => item.type == 'Completed date')
    this.selectedDate = completedDate ? completedDate.name : ''
    this.selectedSort = 'LPI_COMPLETED_AT'
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    await this.onFetchFilterOptions()
    if (this.sideFilterData) {
      this.isSideFilterActive = true
      this.openSideFilter()
    }
    const closeFilterModal = async () => {
      document.getElementById('contentlayout').style.overflowY = 'scroll'
      this.filters.map(async (filter) => {
        filter.optionsVisible = false
        this.selectedFilterType = ''
        filter.options.map((option) => {
          if (!this.selected.some((selectedFilter) => selectedFilter.name === option.name)) {
            option.selected = false
          }
        })
      })
      this.$emit('close', { filters: this.selected })
    }
    EventBus.$on('search-bar-on-focus', closeFilterModal)
  },
  methods: {
    showFilterPills(flag) {
      const obj = {
        Duration: {
          show: window.innerWidth == 1440 ? false : true
        },
        Provider: {
          show: window.innerWidth == 1440 ? false : true
        },
        'Content type': {
          show: [1440, 1024].includes(window.innerWidth) ? false : true
        }
      }
      return obj[flag] ? obj[flag].show : true
    },
    async onFetchFilterOptions() {
      if (this.selected.length) {
        this.mlpFilters = await localforage.getItem('my-learning-plan-filters')
        this.mlpFilters.completedDate = this.data.learning.filters.completedDate
        this.resultFilters = this.data.learning.filters
      } else {
        this.mlpFilters = this.data.learning.filters
        await localforage.setItem('my-learning-plan-filters', this.mlpFilters)
      }
      Object.keys(this.mlpFilters).map(async (type) => {
        let filter = {}
        if ({}.hasOwnProperty.call(this.resultFilters, type) && this.resultFilters[type].length > 0) {
          if (type === 'progress') {
            this.resultFilters[type] = this.resultFilters[type].filter((filter) => filter.minProgress > -1 && filter.maxProgress > -1)
          } else if (type === 'duration' || type === 'creditHours') {
            this.resultFilters[type] = this.resultFilters[type].filter((filter) => filter.minTime > -1 && filter.maxTime > -1)
          } else {
            this.resultFilters[type] = this.resultFilters[type].filter((filter) => filter.name)
          }
        }
        const isAllowFilterPillProcess = this.selected.length && this.resultFilters[type].length ? this.resultFilters[type].length > 0 : true
        if (type === 'progress') {
          this.mlpFilters[type] = this.mlpFilters[type].filter((filter) => filter.minProgress > -1 && filter.maxProgress > -1)
        } else if (type === 'duration' || type === 'creditHours') {
          this.mlpFilters[type] = this.mlpFilters[type].filter((filter) => filter.minTime > -1 && filter.maxTime > -1)
        } else {
          this.mlpFilters[type] = this.mlpFilters[type].filter((filter) => filter.name)
        }
        if (this.mlpFilters[type].length && isAllowFilterPillProcess) {
          if (type === 'completedDate') {
            filter.name = 'Completed date'
            filter.order = 1
            filter.options = this.mlpFilters[type]
          } else if (type === 'track' || type === 'tracks') {
            filter.name = 'Tracks'
            filter.order = 2
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Found in')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'duration') {
            filter.name = 'Duration'
            filter.order = 3
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.minTime == filter.minTime && selectedFilter.maxTime == filter.maxTime)
              const durationMap = this.setDurationMap(filter)
              filter.id = durationMap.id
              filter.name = durationMap.name
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.minTime === filter.minTime && resultFilter.maxTime == filter.maxTime)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.minTime === filter.minTime && resultFilter.maxTime == filter.maxTime).count
              }
              filter.count = count
            })
          } else if (type === 'provider') {
            filter.name = 'Provider'
            filter.order = 4
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Provider')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'contentType') {
            filter.name = 'Content type'
            filter.order = 5
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Content type')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
              switch (filter.name) {
                case 'credential':
                  filter.userAccess = this.userAccess.licenseType.canAccessCredential
                  break
                case 'course':
                  filter.userAccess = this.userAccess.licenseType.canAccessCourses
                  break
                case 'automation':
                  filter.userAccess = this.userAccess.assetAccess
                  break
                default:
                  filter.userAccess = this.userAccess.licenseType.canAccessRWL
                  break
              }
            })
          } else if (type === 'level') {
            filter.name = 'Level'
            filter.order = 7
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Level')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'accreditation') {
            filter.name = 'Accreditation'
            filter.order = 8
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Accreditation')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'creditType') {
            filter.name = 'Credit type'
            filter.order = 9
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Credit type')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'creditHours') {
            filter.name = 'Credit hours'
            filter.order = 10
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.minTime == filter.minTime && selectedFilter.maxTime == filter.maxTime)
              const creditHourMap = this.setCreditHourMap(filter)
              filter.name = creditHourMap.name
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.minTime === filter.minTime && resultFilter.maxTime == filter.maxTime)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.minTime === filter.minTime && resultFilter.maxTime == filter.maxTime).count
              }
              filter.count = count
            })
          } else if (type === 'tool') {
            filter.name = 'Tool'
            filter.order = 11
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Tool')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'function') {
            filter.name = 'Function'
            filter.order = 12
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Function')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'inventoryType') {
            filter.name = 'Asset type'
            filter.order = 13
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Asset type')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          } else if (type === 'author') {
            filter.name = 'Author'
            filter.order = 14
            this.mlpFilters[type].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Author')
              let count = 0
              if (!{}.hasOwnProperty.call(this.resultFilters, type)) {
                count = filter.count
              } else if (this.resultFilters[type].some((resultFilter) => resultFilter.name === filter.name)) {
                count = this.resultFilters[type].find((resultFilter) => resultFilter.name === filter.name).count
              }
              filter.count = count
            })
          }
          filter.isApply = this.mlpFilters[type].filter((option) => option.selected).length > 0
          if (type === 'duration' || type === 'creditHours') {
            filter.options = this.mlpFilters[type].sort((a, b) => a.minTime - b.minTime)
          } else if (type === 'track' || type === 'tracks') {
            filter.options = this.mlpFilters[type].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          } else if (type === 'completedDate') {
            filter.options = this.mlpFilters[type].sort((a, b) => a.id - b.id)
          } else {
            filter.options = this.mlpFilters[type].sort((a, b) => b.count - a.count)
          }
          filter.optionsVisible = false
          if (filter.name) this.filters.push(filter)
        }
      })
      this.filters.sort((a, b) => a.order - b.order)
    },
    async onCancel(event) {
      this.handleClearInput(event)
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      this.filters.map(async (filter) => {
        if (event.name === filter.name) {
          filter.optionsVisible = false
          this.selectedFilterType = ''
          await filter.options.map((option) => {
            option.selected = this.selected.some((selectedFilter) => selectedFilter.name === option.name)
          })
        }
      })
      this.$emit('close', { filters: this.selected })
    },
    onClearFilters() {
      this.selected = []
      this.$emit('on-clear-filters', { filters: this.selected })
    },
    onShow(event) {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      this.filters.forEach((filter) => {
        if (event.name === filter.name) {
          filter.isApply = filter.options.filter((option) => option.selected).length > 0
          filter.optionsVisible = false
          filter.options.forEach((option) => {
            if (!option.selected) {
              if (this.selected.some((selectedFilter) => selectedFilter.name === option.name)) {
                const index = this.selected.findIndex((selectedFilter) => selectedFilter.name === option.name)
                this.selected.splice(index, 1)
              }
            } else {
              if (!this.selected.some((selectedFilter) => selectedFilter.name === option.name)) {
                option.type = filter.name
                this.selected.push(option)
              }
            }
          })
        }
      })
      this.$emit('on-apply', { filters: this.selected })
    },
    async checkSelections(data, event) {
      let value = data.detail.value
      this.filters.map(async (filter) => {
        if (filter.name === event.name) {
          await filter.options.map((option) => {
            if (option.name === value) option.selected = !option.selected
          })
        }
      })
    },
    async checkSelectionsRadio(data, event) {
      const value = data.target.value
      this.filters.map(async (filter) => {
        if (filter.name === event.name) {
          await filter.options.map((option) => {
            option.selected = value === option.name
          })
        }
      })
    },
    async onFilterType(filterEvent) {
      this.handleClearInput(filterEvent)
      this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name && filterEvent.name === this.selectedFilterType) {
          filter.optionsVisible = false
          this.selectedFilterType = ''
        } else if (filterEvent.name === filter.name) {
          this.selectedFilterType = filterEvent.name
          filter.optionsVisible = true
        } else {
          filter.optionsVisible = false
        }
        if (filter.optionsVisible) {
          const contentLayoutDiv = document.getElementById('contentlayout')
          contentLayoutDiv.style.overflowY = 'hidden'
        } else {
          await filter.options.map((filterOption) => {
            filterOption.selected = this.selected.some((selectedFilter) => selectedFilter.name === filterOption.name)
          })
        }
        if (!this.selectedFilterType) {
          const contentLayoutDiv = document.getElementById('contentlayout')
          contentLayoutDiv.style.overflowY = 'scroll'
        }
      })
      this.$emit('on-open-filters')
      this.sortOptionVisible = false
    },
    async handleClearInput(event) {
      this.searchFilter = ''
      this.filters.map(async (filter) => {
        if (event.name === filter.name) {
          await filter.options.map((option) => {
            option.style = 'display: flex'
          })
        }
      })
    },
    async handleSearchInput(event, value) {
      this.searchFilter = event.target.value.trim()
      this.filters.map(async (filter) => {
        if (value.name === filter.name) {
          await filter.options.map((option) => {
            const find = option.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1
            option.style = find ? 'display: flex' : 'display: none'
          })
        }
      })
    },
    removeFilter(option) {
      if (this.selected.includes(option)) {
        const index = this.selected.findIndex((selectedFilter) => selectedFilter.name === option.name)
        this.selected.splice(index, 1)
      }
      this.filters.forEach((filter) => {
        if (filter.name === option.type) {
          filter.options.forEach((filterOptionObj) => {
            if (filterOptionObj.name === option.name) filterOptionObj.selected = !filterOptionObj.selected
          })
          filter.isApply = filter.options.filter((option) => option.selected).length > 0
        }
      })
      this.$emit('on-apply', { filters: this.selected })
    },
    onSort() {
      this.sortOptionVisible = !this.sortOptionVisible
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = this.sortOptionVisible ? 'hidden' : 'scroll'
      this.filters.map((filter) => {
        if (filter.name === this.selectedFilterType) {
          filter.optionsVisible = false
          this.selectedFilterType = ''
        }
      })
    },
    onApplySort() {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      this.sortOptionVisible = false
      this.$emit('on-apply', { filters: this.selected })
    },
    async openSideFilter() {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      this.isSideFilterActive = !this.isSideFilterActive
      if (!this.isSideFilterActive) {
        this.$emit('side-filter', false)
      }
      this.sortOptionVisible = false
      this.filters.map((filter) => {
        if (filter.name === this.selectedFilterType) {
          filter.optionsVisible = false
          this.selectedFilterType = ''
        }
      })
      let modal = await popoverController.create({
        component: MyLearningFilterSideBar,
        cssClass: 'filter-container-class',
        swipeToClose: false,
        showBackdrop: true,
        backdropDismiss: false,
        componentProps: {
          parent: this,
          propsData: {
            filters: this.filters,
            resultsCount: this.resultsCount
          }
        }
      })
      await modal.present()
    },
    filterName(option, type) {
      const capitalize = (s) => {
        return s.substr(0, 1).toUpperCase() + s.substr(1)
      }
      return type === 'Duration' || option.type === 'Duration' ? option.id : capitalize(option.name)
    },
    setDurationMap(filter) {
      let duration = {}
      const minTime = filter.minTime
      const maxTime = filter.maxTime
      if (minTime == 0 && maxTime == 15) {
        duration.id = this.durationLabels[0]
        duration.name = 'duration1'
      } else if (minTime == 15 && maxTime == 60) {
        duration.id = this.durationLabels[1]
        duration.name = 'duration2'
      } else if (minTime == 60 && maxTime == 180) {
        duration.id = this.durationLabels[2]
        duration.name = 'duration3'
      } else if (minTime == 180 && maxTime == 300) {
        duration.id = this.durationLabels[3]
        duration.name = 'duration4'
      } else if (minTime == 300 && maxTime == 600) {
        duration.id = this.durationLabels[4]
        duration.name = 'duration5'
      } else if (minTime == 600 && maxTime == 99999) {
        duration.id = this.durationLabels[5]
        duration.name = 'duration6'
      }
      return duration
    },
    setProgressMap(filter) {
      let progress = {}
      const minProgress = filter.minProgress
      const maxProgress = filter.maxProgress
      if (minProgress == 1 && maxProgress == 99) {
        progress.name = 'In progress'
      } else if (minProgress == 0 && maxProgress == 0) {
        progress.name = 'Not started'
      }
      return progress
    },
    setCreditHourMap(filter) {
      let creditHour = {}
      const minTime = filter.minTime
      const maxTime = filter.maxTime
      if (minTime == 0 && maxTime == 0.5) {
        creditHour.name = this.creditHourLabels[0]
      } else if (minTime == 0.5 && maxTime == 1) {
        creditHour.name = this.creditHourLabels[1]
      } else if (minTime == 1 && maxTime == 2) {
        creditHour.name = this.creditHourLabels[2]
      } else if (minTime == 2 && maxTime == 5) {
        creditHour.name = this.creditHourLabels[3]
      } else if (minTime == 5 && maxTime == 10) {
        creditHour.name = this.creditHourLabels[4]
      } else if (minTime == 10 && maxTime == 20) {
        creditHour.name = this.creditHourLabels[5]
      } else if (minTime == 20 && maxTime == 40) {
        creditHour.name = this.creditHourLabels[6]
      } else if (minTime == 40 && maxTime == 9999) {
        creditHour.name = this.creditHourLabels[7]
      }
      return creditHour
    },
    openExternalSidebar() {
      this.$emit('on-open-external-sidebar')
    }
  }
}
</script>
  
<style lang="scss">
.filter-option-container {
  ion-searchbar ion-icon {
    top: 9px !important;
  }

  .searchbar-input.sc-ion-searchbar-md {
    box-shadow: none;
    padding: 2px 30px 2px 40px !important;
    border: 2px solid #000000;
    font-size: 14px;
    font-weight: bold;
    color: black;
  }

  ion-searchbar {
    .searchbar-input-container :-moz-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container ::placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container ::-moz-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container ::-webkit-input-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container :-ms-input-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }
  }
}
</style>
  
<style lang="scss" scoped>
.filter-selected {
  .svg-icon {
    width: 9px !important;
    padding: 2px 0 0 0;
    cursor: pointer;
  }
}

.filter-bar {
  position: fixed;
  z-index: 9999;
  right: 0;
  width: 364px;
}

.filter-container-backdrop,
.sort-container-backdrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 2rem;
}

.sort-container-backdrop {
  left: unset;
  right: 0;
}

.filter-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 32px;
}

.filter-list {
  display: flex;
  gap: 20px;
  position: relative;
  flex-wrap: wrap;
}

.filter-title,
.filter-selected-title,
.sort-title {
  font-family: 'Futura PT Demi';
  font-size: 20px;
  color: #000000;
  align-self: center;
}

.filter-selected-title {
  font-size: 1rem;
}

.filter-type,
.sort-type {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px solid #e0e1e1;
  border-radius: 20px;
  font-family: Arial;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  padding: 5px 15px;
  width: max-content;

  &:hover {
    border: 2px solid #000000;
    background: #efefef;
    cursor: pointer;

    .svg-icon {
      transform: var(--transform);
    }
  }
}

.all-filter {
  display: none;
}

.filter-type-selected {
  border: 2px solid #000000;
  background: #efefef;
  cursor: pointer;
}

.filter-option-container,
.sort-option-container {
  position: absolute;
  width: 270px;
  background: #f6f6f6;
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 20px;
  z-index: 2;
}

.sort-option-container {
  margin-top: 30px;
  left: -60px;
}

ion-searchbar {
  margin: unset !important;
  --background: white !important;
  --border-radius: 20px;
  width: 100% !important;
  padding: 20px 20px 0;
  --border: 2px black !important;
}

.filter-checkbox {
  --size: 18px;
  margin-right: 12px;
  --border-radius: 4px;
  --border-color: #bcc2d4;
  --border-width: 2px;
}

.filter-radio {
  margin-right: 12px;
  margin-top: -2.1px;
}

.filter-option-content,
.sort-option-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 20px 15px;
}

.filter-option-content-scroll {
  height: 250px;
  overflow-y: scroll;
  margin-right: 20px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}

.filter-option-title,
.sort-option-title,
.filter-option-title-disable {
  font-family: 'Arial';
  font-size: 14px;
  color: #000000de;
}

.filter-option-title-disable {
  color: #9f9f9f;
}

.filter-option-footer,
.sort-option-footer {
  display: flex;
  gap: 3rem;
  padding: 20px 32px;
  align-items: center;
  border-top: 1px solid #e0e1e1;
}

.filter-option-footer-cancel,
.sort-option-footer-cancel {
  font-family: 'Futura PT Demi';
  font-size: 1rem;
  color: #1f3370;
  cursor: pointer;
}

.filter-option-footer-btn-apply,
.sort-option-footer-btn-apply {
  cursor: pointer;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #feb92e;
  --ion-color-primary-contrast: black;
  --box-shadow: none;
}

.sort-container,
.sort-option {
  display: flex;
  gap: 15px;
  position: relative;
}

.sort-option {
  align-items: center;
}

.filter-selected-container {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;
  padding-left: 4px;
}

.filter-selected {
  display: flex;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
  font-size: 11px;
  font-family: 'Arial';
  padding: 5px 15px;
  color: #ffffff;
  gap: 10px;
}

.filter-selected-clear-all {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 14px;
  color: #1f3370;
  align-self: center;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #1f3370;
  }
}

ion-radio {
  --border-radius: 23px;
  --color: #bcc2d4;
  --color-checked: #1f3370;
}

@media screen and (max-width: 1540px) {
  //   .filter-list,
  //   .sort-container {
  //     gap: 10px;
  //   }

  .filter-type,
  .sort-type {
    padding: 5px 9px;
  }

  .sort-option-container {
    left: -72px;
  }
}

@media screen and (max-width: 1280px) {
  .filter-type,
  .sort-type {
    font-size: 14px;
  }

  .sort-option-container {
    left: -85px;
  }
}

@media screen and (max-width: 1024px) {
  .all-filter {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 2px solid #e0e1e1;
    border-radius: 20px;
    font-family: Arial;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    padding: 5px 9px;

    &:hover {
      border: 2px solid #000000;
      background: #efefef;
      cursor: pointer;

      .svg-icon {
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .sort-title {
    font-size: 14px;
  }

  .sort-option-container {
    left: -109px;
  }

  .filter-type,
  .sort-type {
    font-size: 11px;
  }

  .filter-bar {
    width: 100%;
  }
}

.learning-plan-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  margin-right: 1rem;
  width: 110px !important;
  height: 40px !important;
  --ion-color-primary-contrast: var(--ion-color-tertiary);
  --box-shadow: none;
}

.add-icon-container {
  display: inline-block;
  margin: 0px 10px 0px 0px;
  padding-top: 1px;
  padding-left: 3px;
  text-align: center;
  width: 18px;
  height: 18px;
  color: white;
  background-color: #1f3370;
  border-radius: 50%;
}

.action-btns {
  float: right;
}
</style>
  