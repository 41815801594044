var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "course-about" }, [
    _c("div", { staticClass: "course-description" }, [
      _c("div", { staticClass: "course-title" }, [_vm._v("About")]),
      _c("div", {
        staticClass: "course-subtitle",
        domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.course.synopsis)) }
      })
    ]),
    _c(
      "div",
      { staticClass: "courses-carousel" },
      [
        _c("CourseRelatedCourses", {
          attrs: {
            course: _vm.relatedCourses,
            requiredLearningPlanItems: _vm.requiredLearningPlanItems
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }