<template>
  <div>
    <div class="asset-list-content">
      <div v-if="isDesktopPlatform" class="asset-list-table">
        <div class="asset-list-table-header">
          <h5 class="asset-name-header" @click="sortAssetList('name')">
            <span>Asset</span>
            <span class="sort-container">
              <button :disabled="nameSort == '2'">
                <ion-icon slot="start" name="caret-up-outline"></ion-icon>
              </button>
              <button :disabled="nameSort == '1'">
                <ion-icon slot="start" name="caret-down-outline"></ion-icon>
              </button>
            </span>
          </h5>
          <h5 class="asset-date-header" @click="sortAssetList('createdAt')">
            <span>Date created</span>
            <span class="sort-container">
              <button :disabled="createdAtSort == '2'">
                <ion-icon slot="start" name="caret-up-outline"></ion-icon>
              </button>
              <button :disabled="createdAtSort == '1'">
                <ion-icon slot="start" name="caret-down-outline"></ion-icon>
              </button>
            </span>
          </h5>
          <h5 class="asset-date-header" @click="sortAssetList('updatedAt')">
            <span>Last updated</span>
            <span class="sort-container">
              <button :disabled="updatedAtSort == '2'">
                <ion-icon slot="start" name="caret-up-outline"></ion-icon>
              </button>
              <button :disabled="updatedAtSort == '1'">
                <ion-icon slot="start" name="caret-down-outline"></ion-icon>
              </button>
            </span>
          </h5>
          <h5 class="asset-status-header" @click="sortAssetList('reviewStatus')">
            <span>Status</span>
            <span class="sort-container">
              <button :disabled="reviewStatusSort == '2'">
                <ion-icon slot="start" name="caret-up-outline"></ion-icon>
              </button>
              <button :disabled="reviewStatusSort == '1'">
                <ion-icon slot="start" name="caret-down-outline"></ion-icon>
              </button>
            </span>
          </h5>
        </div>
        <div class="asset-list-table-content">
          <div v-for="asset in filterItems" :key="asset.id" @click="onRowClick(asset.id)" class="asset-list-table-row">
            <div class="table-item asset-name">
              <p style="margin-bottom: 0.5rem">{{ asset.name === 'undefined' ? 'Undefined' : asset.name }}</p>
              <span class="asset-displayId">{{ asset.displayId }} |</span>
              <template v-for="(technology, index) in asset.applications">
                <span :key="technology" class="asset-technology"><template v-if="index > 0 && index != asset.applications.length"> | </template> {{ technology }}</span>
              </template>
            </div>
            <div class="table-item asset-date">{{ asset.createdAt | formatDate }}</div>
            <div class="table-item asset-date">{{ asset.updatedAt | fileFormatDate }}</div>
            <div class="table-item asset-status">{{ asset.reviewStatus }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isDesktopPlatform" class="asset-list-table">
        <div class="asset-list-table-header">
          <h5 class="asset-name-header" @click="sortAssetList('name')">
            Asset
            <span class="sort-container">
              <button :disabled="nameSort == '2'">
                <ion-icon slot="start" name="caret-up-outline"></ion-icon>
              </button>
              <button :disabled="nameSort == '1'">
                <ion-icon slot="start" name="caret-down-outline"></ion-icon>
              </button>
            </span>
          </h5>
          <h5 @click="sortAssetList('reviewStatus')">
            Status
            <span class="sort-container">
              <button :disabled="reviewStatusSort == '2'">
                <ion-icon slot="start" name="caret-up-outline"></ion-icon>
              </button>
              <button :disabled="reviewStatusSort == '1'">
                <ion-icon slot="start" name="caret-down-outline"></ion-icon>
              </button>
            </span>
          </h5>
        </div>
        <div class="asset-list-table-content">
          <div v-for="asset in filterItems" :key="asset.id" @click="onRowClick(asset.id)" class="asset-list-table-row">
            <div class="table-item asset-name">
              <p>{{ asset.name }}</p>
              <div class="technology-content asset-name-margin">
                <span class="asset-displayId">{{ asset.displayId }} |</span>
                <template v-for="(technology, index) in asset.applications">
                  <span :key="technology" class="asset-technology"><template v-if="index > 0 && index != asset.applications.length"> | </template> {{ technology }}</span>
                </template>
              </div>
              <label class="last-updated"><span class="label">Last updated</span>{{ asset.updatedAt | fileFormatDate }}</label>
            </div>
            <div class="table-item asset-status">{{ asset.reviewStatus }}</div>
          </div>
        </div>
      </div>
      <div class="search-result-pagination">
        <paginate
          v-if="assetList.length"
          v-model="currentPage"
          :page-count="pagination.pageCount"
          :page-range="pagination.pageRange"
          :margin-pages="1"
          :click-handler="handlePageClick"
          :container-class="'pagination'"
          :page-class="'pagination-item'"
          :page-link-class="'pagination-item-link'"
          :prev-text="'&lsaquo;'"
          :prev-class="'pagination-nav'"
          :prev-link-class="'pagination-nav-link'"
          :next-text="'&rsaquo;'"
          :next-class="'pagination-nav'"
          :next-link-class="'pagination-nav-link'"
          :hide-prev-next="true"
        />
      </div>
    </div>
    <div v-if="this.$platform !== 'Desktop'" class="notification-message">
      <svg-icon icon-class="mini-icon" class="g-icon--xsmall svg-container" />
      <h2>To submit a new asset, log in to ProEdge on a desktop device.</h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/utils/helper'
export default {
  name: 'AssetList',
  components: {},
  props: {
    assetList: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    pagination: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      isDesktopPlatform: false,
      nameSort: 0,
      createdAtSort: 0,
      updatedAtSort: 0,
      reviewStatusSort: 0,
      reviewStatus: {
        inEdit: { value: 'IN EDIT', message: 'Draft' },
        inDraft: { value: 'IN DRAFT', message: 'Draft' }
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token']),
    filterItems() {
      const start = this.currentPage * this.pagination.pageRange - this.pagination.pageRange
      const end = start + this.pagination.pageRange - 1
      return this.assetList.filter((item, index) => {
        item.reviewStatus = [this.reviewStatus.inDraft.value, this.reviewStatus.inEdit.value].includes(item.reviewStatus.toUpperCase())
          ? this.reviewStatus.inEdit.message
          : item.reviewStatus
        return index >= start && index <= end
      })
    }
  },
  watch: {},
  created() {},
  async mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop' || window.innerWidth >= 450
    await this.setupPagination(true)
  },
  activated() {},
  deactivated() {
    this.resetModel()
  },
  filters: {
    formatDate(d) {
      return util.formatDate(d)
    },
    fileFormatDate(d) {
      return util.formatDateWithTime(d)
    }
  },
  methods: {
    resetModel() {
      this.nameSort = 0
      this.createdAtSort = 0
      this.updatedAtSort = 0
      this.reviewStatusSort = 0
    },
    async handlePageClick(page) {
      this.currentPage = page
    },
    async setupPagination(isAddEvent = true) {
      const elements = document.getElementsByClassName('pagination')[0].childNodes
      if (elements) {
        await elements.forEach((element) => {
          if (element.childNodes.length && element.className.includes('pagination')) {
            isAddEvent ? element.addEventListener('click', this.onPaginationItemClick, { once: true }) : element.removeEventListener('click', this.onPaginationItemClick)
          }
        })
      }
    },
    onPaginationItemClick(e) {
      let page = this.currentPage
      if (e.target.textContent != '…') {
        page = e.target.textContent == '‹' ? page - 1 : e.target.textContent == '›' ? page + 1 : parseInt(e.target.textContent)
        this.handlePageClick(page)
      }
    },
    onRowClick(assetId) {
      this.$router.push({ name: 'AssetSubmissionDetail', params: { id: assetId } }).catch(() => {})
    },
    sortAssetList(key) {
      this[`${key}Sort`]++
      if (this[`${key}Sort`] > 2) {
        this[`${key}Sort`] = 0
      }
      const sortObj = ['nameSort', 'createdAtSort', 'updatedAtSort', 'reviewStatusSort']
      //reset other columns up and down arrow to enabled state
      sortObj.forEach((el) => {
        if (!el.includes(key)) {
          this[el] = 0
        }
      })
      // add empty string for null values to avoid errors for calling toLowerCase function on empty string
      if (this[`${key}Sort`] == 1) {
        this.assetList.sort((a, b) => {
          a[key] = a[key] == null ? '' : a[key]
          b[key] = b[key] == null ? '' : b[key]
          return a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1
        })
      } else if (this[`${key}Sort`] == 2) {
        this.assetList.sort((a, b) => {
          a[key] = a[key] == null ? '' : a[key]
          b[key] = b[key] == null ? '' : b[key]
          return a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1
        })
      } else this.resetList()
      this.currentPage = 1
    },
    resetList() {
      this.assetList.sort((a, b) => {
        a['updatedAt'] = a['updatedAt'] == null ? '' : a['updatedAt']
        b['updatedAt'] = b['updatedAt'] == null ? '' : b['updatedAt']
        return a['updatedAt'].toLowerCase() < b['updatedAt'].toLowerCase() ? 1 : -1
      })
    }
  }
}
</script>

<style lang="scss">
.search-result-pagination {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Futura PT Demi';
  font-size: 0.9rem;
  color: var(--ion-color-role-grey);
  border-top: 2px solid var(--ion-color-light-gray);
  span {
    position: relative;
    font-size: 1rem;
    padding: 0rem 0.5rem;
    cursor: pointer;
    font-family: 'Futura PT Demi';
  }
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.pagination-item {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

li.pagination-nav {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.pagination-item-link {
  color: var(--ion-color-post-gray);
  font-size: 14px !important;
  vertical-align: middle;
}

.pagination-nav-link {
  position: relative;
  color: #5c5c5c;
  font-size: 3rem !important;
  vertical-align: middle;
  font-family: 'Futura PT Book';
}

li.disabled {
  border: none;
  cursor: default;
  .pagination-item-link {
    cursor: default;
  }
}

li.active {
  border: 2px solid #1dd1b0;
  border-radius: 4px;
  background: white;
  .pagination-item-link {
    color: #000000;
  }
}

li:hover {
  background: #e0e1e1;
}

li .tooltiptext {
  visibility: hidden;
  width: max-content;
  height: 30px;
  background-color: #efefef;
  color: #000000de;
  text-align: center;
  padding: 7px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -20px;
  font-size: 12px;
  font-family: 'Futura PT Demi';
}

.li:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 414px) {
  .pagination-item {
    margin: 0 2px;
    width: 32px;
    height: 32px;
  }
  li.pagination-nav {
    width: 32px;
    height: 32px;
  }
  .pagination-item-link {
    font-size: 12px;
  }
}
</style>

<style lang="scss" scoped>
.asset-list-content {
  width: 100%;
  background-color: var(--ion-color-white);
  border-radius: 20px;
  margin-top: 2rem;
  .asset-list-table {
    padding: 0.5rem 1.5rem;
  }
  .asset-list-table-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    h5 {
      flex: 1;
      font-family: 'Arial';
      font-size: 1rem;
      font-weight: bold;
      color: var(--ion-color-black);
      text-align: left;
      padding-right: 0.4rem;
      display: flex;
      &.asset-name-header {
        flex: 2.5;
      }
      &.asset-date-header {
        flex: 2;
      }
      &.asset-name-header span {
        cursor: pointer;
        font: normal normal bold 1rem/1.125rem Arial;
      }
      &.asset-date-header span {
        cursor: pointer;
        font: normal normal bold 1rem/1.125rem Arial;
      }
      &.asset-status-header span {
        cursor: pointer;
        font: normal normal bold 1rem/1.125rem Arial;
      }
      .sort-container {
        margin-top: -0.31rem;
        display: inline-flex;
        flex-direction: column;
        font-size: 0.75rem;
        button {
          width: 1.5rem;
          background: transparent;
          height: 0.75rem;
          ion-icon {
            width: 1rem;
          }
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 0.75rem;
      }
    }
  }
  .asset-list-table-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    align-items: center;
    cursor: pointer;
    border-radius: 0.75rem;
    &:nth-child(odd) {
      background: #efefefbf;
    }
    &:hover {
      background-color: #efefef40;
    }

    .table-item {
      flex: 1;
      text-align: left;
      padding-right: 0.4rem;
      font-family: 'Arial';
      font-size: 0.875rem;
      &.asset-name {
        flex: 2.5;
        p {
          margin: 0;
          word-break: break-word;
          color: var(--ion-color-tertiary);
          font-family: 'Futura PT Demi';
          font-size: 1.25rem;
          @media screen and (max-width: 768px) {
            font-size: 1rem;
            margin: 0 0 0.3rem 0;
          }
          padding-right: 1rem;
        }
      }
      &.asset-date {
        flex: 2;
        text-transform: uppercase;
        font-size: 0.875rem;
        @media screen and (max-width: 768px) {
          font-size: 0.75rem;
        }
      }
      &.asset-status {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.875rem;
        @media screen and (max-width: 768px) {
          font-size: 0.75rem;
        }
      }
      .last-updated {
        .label {
          font-weight: bold;
          margin-right: 5px;
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 0.625rem;
      }
    }
    .asset-name-margin {
      margin-bottom: 0.3rem;
    }
  }
  @media screen and (max-width: 450px) {
    padding: 0 0.5rem;
    margin: 0;
  }
}

.asset-technology,
.asset-displayId {
  color: var(--ion-color-black);
  font-family: 'Arial';
  font-size: 0.75rem;
  @media screen and (max-width: 768px) {
    font-size: 0.625rem;
  }
}

.notification-message {
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0rem;
  width: 100%;
  height: 64px;
  background: var(--ion-color-primary-lighter);
  border-radius: 4px;
  h2 {
    text-align: left;
    font-family: 'Arial';
    font-size: 0.875rem;
    font-weight: normal;
    margin: 1.5rem 0rem;
    @media screen and (max-width: 450px) {
      font-size: 0.75rem;
    }
    @media screen and (max-width: 425px) {
      margin: 1.2rem 0rem;
    }
  }

  .svg-container {
    margin: 1.5rem 0.5rem 1.5rem 1rem;
    @media screen and (max-width: 425px) {
      margin: 1.2rem 0.5rem 1.2rem 1rem;
    }
  }
}
</style>
