export default [
  {
    path: '/onboarding',
    name: 'Onboarding',
    redirect: '/onboarding/privacy-policy',
    component: () => import('@/views/onboarding/Onboarding'),
    meta: {
      whitelist: true,
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: false,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    },
    children: [
      {
        path: 'privacy-policy',
        name: 'OnboardingPrivacyPolicy',
        component: () => import('@/views/onboarding/PrivacyPolicy'),
        meta: {
          whitelist: true,
          moduleName: 'learn',
          desktopSideMenuVisible: false,
          desktopHeaderVisible: false,
          mobileHeaderVisible: false,
          mobileFooterVisible: false,
          searchVisible: false,
          filterVisible: false,
          jumpBackInVisible: false
        }
      },
      {
        path: 'cookie-notice',
        name: 'OnboardingCookieNotice',
        component: () => import('@/views/onboarding/CookieNotice'),
        meta: {
          whitelist: true,
          moduleName: 'learn',
          desktopSideMenuVisible: false,
          desktopHeaderVisible: false,
          mobileHeaderVisible: false,
          mobileFooterVisible: false,
          searchVisible: false,
          filterVisible: false,
          jumpBackInVisible: false
        }
      },
      {
        path: 'terms-and-conditions',
        name: 'OnboardingTerms',
        component: () => import('@/views/onboarding/TermsAndConditions'),
        meta: {
          whitelist: true,
          moduleName: 'learn',
          desktopSideMenuVisible: false,
          desktopHeaderVisible: false,
          mobileHeaderVisible: false,
          mobileFooterVisible: false,
          searchVisible: false,
          filterVisible: false,
          jumpBackInVisible: false
        }
      },
      {
        path: 'create-account',
        name: 'OnboardingCreateAccount',
        component: () => import('@/views/onboarding/CreateAccount'),
        meta: {
          whitelist: true,
          moduleName: 'learn',
          desktopSideMenuVisible: false,
          desktopHeaderVisible: false,
          mobileHeaderVisible: false,
          mobileFooterVisible: false,
          searchVisible: false,
          filterVisible: false,
          jumpBackInVisible: false
        }
      },
      {
        path: 'verify',
        name: 'OnboardingVerify',
        component: () => import('@/views/onboarding/Verify'),
        meta: {
          whitelist: true,
          moduleName: 'learn',
          desktopSideMenuVisible: false,
          desktopHeaderVisible: false,
          mobileHeaderVisible: false,
          mobileFooterVisible: false,
          searchVisible: false,
          filterVisible: false,
          jumpBackInVisible: false
        }
      },
      {
        path: 'welcome',
        name: 'OnboardingWelcome',
        component: () => import('@/views/onboarding/Welcome'),
        meta: {
          whitelist: true,
          moduleName: 'learn',
          desktopSideMenuVisible: false,
          desktopHeaderVisible: false,
          mobileHeaderVisible: false,
          mobileFooterVisible: false,
          searchVisible: false,
          filterVisible: false,
          jumpBackInVisible: false
        }
      }
    ]
  }
]
