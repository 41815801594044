var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manage-skill-modal" }, [
    _c(
      "div",
      { staticClass: "header-content" },
      [
        _c("svg-icon", {
          staticClass: "close",
          attrs: {
            "icon-class": "filter-close",
            "class-name": "g-icon--small"
          },
          on: {
            click: function($event) {
              return _vm.dismissModal()
            }
          }
        }),
        _c("h3", [_vm._v(_vm._s(_vm.headingText))]),
        _c("p", [_vm._v(_vm._s(_vm.descriptionText))])
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "body-content",
        class: { "scrollable-content": _vm.skills.length > 12 }
      },
      _vm._l(_vm.skills, function(skill) {
        return _c(
          "div",
          { key: skill.name, staticClass: "checkbox-container" },
          [
            _c("ion-checkbox", {
              staticClass: "manageskills-modal__checkbox",
              attrs: {
                checked: true,
                value: skill.name,
                color: "tertiary",
                mode: "md"
              },
              on: {
                ionChange: function($event) {
                  return _vm.onDeleteSkillChange($event, skill)
                }
              }
            }),
            _c("span", [_vm._v(_vm._s(skill.name))])
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "footer-content" },
      [
        _c(
          "ion-button",
          {
            staticClass: "ion-button-primary ion-text-capitalize",
            attrs: {
              shape: "round",
              disabled: _vm.skillsListToDel.length == 0,
              color: "primary"
            },
            on: { click: _vm.onSave }
          },
          [_vm._v("Save")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }