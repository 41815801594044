import { render, staticRenderFns } from "./NavBottomTabMobile.vue?vue&type=template&id=cd52874a&scoped=true&"
import script from "./NavBottomTabMobile.vue?vue&type=script&lang=js&"
export * from "./NavBottomTabMobile.vue?vue&type=script&lang=js&"
import style0 from "./NavBottomTabMobile.vue?vue&type=style&index=0&id=cd52874a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd52874a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/vtrinh004/Desktop/YTC/web/proedge-learn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cd52874a')) {
      api.createRecord('cd52874a', component.options)
    } else {
      api.reload('cd52874a', component.options)
    }
    module.hot.accept("./NavBottomTabMobile.vue?vue&type=template&id=cd52874a&scoped=true&", function () {
      api.rerender('cd52874a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/NavBottomTabMobile.vue"
export default component.exports