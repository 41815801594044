<template>
  <div class="accordion" :class="show ? 'expanded' : ''">
    <h2 @click="toggleSlide">
      <slot name="title"></slot>
    </h2>
    <section ref="panel" class="section">
      <slot name="section"></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Collapsible',
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleSlide() {
      if (this.$refs.panel.style.maxHeight) {
        this.$refs.panel.style.maxHeight = null
        this.show = false
      } else {
        this.$refs.panel.style.maxHeight = this.$refs.panel.scrollHeight + 'px'
        this.show = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.accordion {
  text-align: left;
  h2 {
    margin: 12px 0;
    font-size: 0.9rem;
    color: black;
    cursor: pointer;
    font-family: Arial;
    > span {
      margin: 5px 0;
      font-size: 1.4rem;
      color: black;
      font-weight: bold;
    }
    &:after {
      content: url('~@/assets/images/svgs/caret-down.svg');
      vertical-align: baseline;
      padding: 5px;
    }
  }
  &.expanded {
    margin-bottom: 20px;
    h2 {
      &:after {
        content: url('~@/assets/images/svgs/caret-up.svg');
        padding: 5px;
        vertical-align: baseline;
      }
    }
  }
}
.section {
  padding: 0;
  background-color: white;
  font-size: 1.2rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>
