var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "idea-popover ion-padding-horizontal",
      staticStyle: { "background-color": "white" }
    },
    [
      _c(
        "ion-row",
        { staticClass: "g-margin__top--small idea-popover__border-bottom" },
        [
          _c("ion-col", [
            _c("div", { staticClass: "popover-title" }, [
              _vm._v(_vm._s(_vm.content[_vm.index].title))
            ])
          ]),
          _c(
            "ion-col",
            { attrs: { size: "2" } },
            [
              _c("svg-icon", {
                attrs: {
                  "icon-class": "close",
                  "class-name": "g-icon--small g-margin__right--msmall",
                  color: "#182755"
                },
                on: { click: _vm.onCancel }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "popover-text" }, [
        _vm._v(_vm._s(_vm.content[_vm.index].text))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }