var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "collection-about" }, [
    _c(
      "div",
      { staticClass: "collection-desc" },
      [
        _c("div", { staticClass: "collection-title" }, [_vm._v("Description")]),
        _c("div", {
          class:
            _vm.collection.content.length > 0
              ? "collection-subtitle"
              : "collection-subtitle collection-subtitle-margin",
          domProps: {
            innerHTML: _vm._s(_vm.$sanitize(_vm.collection.synopsis))
          }
        }),
        _vm.collection.noOfItemsHidden > 0
          ? _c("div", { staticClass: "collection-disclaimer" }, [
              _c(
                "span",
                {
                  staticStyle: { "padding-right": "8px", "padding-top": "3px" }
                },
                [
                  _c("svg-icon", {
                    staticClass: "g-icon--small",
                    attrs: { "icon-class": "info-icon" }
                  })
                ],
                1
              ),
              _c("span", { staticClass: "collection-disclaimer-content" }, [
                _vm._v(
                  " Some content in this track has different permissions, and account access is limited. " +
                    _vm._s(_vm.collection.noOfItemsHidden) +
                    " limited access items are hidden. If you need assistance, please contact the "
                ),
                _c(
                  "span",
                  {
                    staticClass: "link-btn g-clickable",
                    on: {
                      click: function($event) {
                        _vm.$router
                          .push("/help-center-main")
                          .catch(function() {})
                      }
                    }
                  },
                  [_vm._v("Help Center")]
                ),
                _vm._v(". ")
              ])
            ])
          : _vm._e(),
        _vm.collection.content.length
          ? _c(
              "ion-grid",
              { staticClass: "ion-no-padding" },
              [
                _c(
                  "ion-row",
                  _vm._l(_vm.collection.content, function(item, index) {
                    return _c(
                      "ion-col",
                      {
                        key: index,
                        staticClass: "list-container",
                        attrs: { size: "12" }
                      },
                      [
                        _c("UniversalCard", {
                          attrs: {
                            item: item,
                            index: index,
                            itemsLength: _vm.collection.content.length,
                            tab: _vm.tab
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }