var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "top", class: _vm.isDesktopPlatform ? "" : "non-white-background" },
    [
      _vm.dataReady
        ? _c(
            "div",
            [
              _vm.isContentAvailable
                ? _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding ion-padding-bottom",
                      class: _vm.isDesktopPlatform
                        ? "desktop-external-container"
                        : "mobile-external-container"
                    },
                    [
                      _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            {
                              attrs: {
                                "size-xl": "8.5",
                                "size-lg": "12",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "back",
                                  class:
                                    _vm.$platform === "Desktop"
                                      ? "back-desktop"
                                      : "back-mobile",
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.go(-1)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "button",
                                    { staticClass: "accessible-button" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "g-icon--small",
                                        attrs: {
                                          "icon-class": "icon-arrow-left"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" Back ")
                                ]
                              ),
                              _c(
                                "ion-row",
                                { staticClass: "external-info" },
                                [
                                  _c(
                                    "ion-col",
                                    { staticClass: "external-img-background" },
                                    [
                                      _vm.external.image &&
                                      _vm.external.image.uri
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.external.image.uri
                                            }
                                          })
                                        : _c("img", {
                                            attrs: { src: _vm.defaultImage }
                                          })
                                    ]
                                  ),
                                  _c("ion-col", [
                                    _c("div", { staticClass: "meta" }, [
                                      _c("div", { staticClass: "category" }, [
                                        _c("div", { staticClass: "format" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.external.format &&
                                                _vm.external.format.name
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "provider" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.external.provider &&
                                                _vm.external.provider.name
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm.external.progress
                                        ? _c("div", [
                                            _vm.external.progress > 0 &&
                                            _vm.external.progress < 100
                                              ? _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class": "progress"
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v("In progress")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm.external.completed ||
                                                _vm.external.progress == 100
                                              ? _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        "icon-class":
                                                          "icon-progress-complete"
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v("completed")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e()
                                    ]),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(_vm.external.title))
                                    ]),
                                    _c("div", { staticClass: "meta-info" }, [
                                      _c("div", { staticClass: "duration" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDuration")(
                                              _vm.external.duration
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "visibility" },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "g-icon--small eye",
                                            attrs: { "icon-class": "icon-eye" }
                                          }),
                                          _c("span", [_vm._v("Only You")])
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", { staticClass: "disclaimer" }, [
                                      _vm._v(
                                        "ProEdge is not responsible for any Client-created or linked content, or content that Clients or users source from third parties."
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "meta-action" },
                                      [
                                        _c(
                                          "ion-button",
                                          {
                                            staticClass: "launch-btn",
                                            attrs: {
                                              shape: "round",
                                              color: "primary"
                                            },
                                            on: { click: _vm.onLaunch }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.external.launchBtn) +
                                                " "
                                            ),
                                            _c("ion-icon", {
                                              attrs: {
                                                slot: "end",
                                                name: "open-outline"
                                              },
                                              slot: "end"
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.showAddToTrack
                                          ? _c(
                                              "div",
                                              { staticClass: "more-container" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "more-action",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.popoverOn(
                                                          "more"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class":
                                                          "more-action",
                                                        "class-name":
                                                          "g-icon--small g-clickable"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm.isMoreOpen
                                                  ? _c("MoreActionPopover", {
                                                      staticClass:
                                                        "more-popover",
                                                      attrs: {
                                                        item: _vm.external,
                                                        assetId: _vm.itemId,
                                                        assetType:
                                                          _vm.external.format
                                                            .name,
                                                        routeInfo: _vm.getRouteInfo(),
                                                        router: _vm.$router,
                                                        showDelete:
                                                          _vm.external
                                                            .progress != 100,
                                                        showEdit:
                                                          _vm.external
                                                            .progress != 100,
                                                        showAddToTrack:
                                                          _vm.showAddToTrack,
                                                        size: "lg",
                                                        fromPage:
                                                          "external content"
                                                      },
                                                      on: {
                                                        "add-to-track": function(
                                                          $event
                                                        ) {
                                                          return _vm.popoverOff(
                                                            "more"
                                                          )
                                                        },
                                                        "edit-delete-external-content":
                                                          _vm.onEditDeleteExternalContent,
                                                        "close-more": function(
                                                          $event
                                                        ) {
                                                          return _vm.popoverOff(
                                                            "more"
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                !_vm.isMoreOpen
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "more-tooltip-text"
                                                      },
                                                      [_vm._v("More")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "external-content-progress"
                                          },
                                          [
                                            !_vm.external.completed
                                              ? _c(
                                                  "ion-button",
                                                  {
                                                    staticClass: "complete-btn",
                                                    attrs: {
                                                      shape: "round",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.onMarkCompleteAction
                                                    }
                                                  },
                                                  [_vm._v("Mark complete")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "external-about-container" },
                        [
                          _c(
                            "ion-row",
                            { staticClass: "external-carousel-desktop" },
                            [
                              _c(
                                "ion-col",
                                { attrs: { size: "8.5" } },
                                [
                                  _c("CourseAbout", {
                                    attrs: {
                                      course: _vm.external,
                                      relatedCourses:
                                        _vm.external.relatedCourses
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { size: "3.5" } },
                                    [
                                      _c("ItemMetaData", {
                                        attrs: { item: _vm.external }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "ion-row",
                            { staticClass: "external-carousel-mobile" },
                            [
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c(
                                        "ItemMetaData",
                                        { attrs: { item: _vm.external } },
                                        [
                                          _c("CourseAbout", {
                                            attrs: {
                                              slot: "item-about",
                                              course: _vm.external,
                                              relatedCourses:
                                                _vm.external.relatedCourses
                                            },
                                            slot: "item-about"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c("CourseAbout", {
                                        attrs: {
                                          course: _vm.external,
                                          relatedCourses:
                                            _vm.external.relatedCourses
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showConfirmDelete
                        ? _c("div", [
                            _c("div", {
                              staticClass: "a-overlay",
                              on: {
                                click: function($event) {
                                  return _vm.onConfirmDelete(false)
                                }
                              }
                            }),
                            _c("div", { staticClass: "b-modal confirm-box" }, [
                              _c("img", {
                                staticClass:
                                  "g-icon--small g-margin__right--msmall close-icon",
                                attrs: {
                                  src: require("@/assets/images/close.svg")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onConfirmDelete(false)
                                  }
                                }
                              }),
                              _c("div", { staticClass: "confirm-box-header" }, [
                                _vm._v("Are you sure you want to delete?")
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Deletion is permanent and can't be undone."
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "handler" },
                                [
                                  _c(
                                    "ion-button",
                                    {
                                      staticClass:
                                        "g-button--primary ion-button-primary delete-btn",
                                      attrs: {
                                        "data-cy": "confirm-progress",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onConfirmDelete(true)
                                        }
                                      }
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onConfirmDelete(false)
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding",
                      style: _vm.isDesktopPlatform
                        ? ""
                        : "padding: 2rem 10px 6rem;"
                    },
                    [_c("PageNotFound")],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }