var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-layout" }, [
    _vm.isSideMenuActive
      ? _c("div", {
          staticClass: "nav-overlay",
          on: { click: _vm.toggleSideMenu }
        })
      : _vm._e(),
    _c(
      "div",
      {
        ref: "leftSideNav",
        staticClass: "slidermenu",
        staticStyle: { outline: "none" },
        attrs: { "aria-label": "side navigation" }
      },
      [
        _c(
          "transition",
          { attrs: { name: "slide" } },
          [
            _vm.isSideMenuActive
              ? _c("NavBarMenuDesktop", {
                  attrs: { navBarMenu: _vm.navBarMenu },
                  on: { "toggle-side-menu": _vm.toggleSideMenu }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    this.$route.meta.logoOnly
      ? _c(
          "div",
          { staticClass: "logo-only-layout" },
          [
            _c("svg-icon", {
              attrs: { "icon-class": "logo", "class-name": "logo--size" }
            })
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "contentSection",
        class: _vm.isHeaderVisible
          ? "section-content"
          : "section-content landing",
        attrs: { id: "contentlayout" },
        on: {
          scroll: function($event) {
            return _vm.onScroll($event)
          }
        }
      },
      [
        _vm.isHeaderVisible
          ? _c(
              "nav",
              {
                staticStyle: { height: "62px" },
                attrs: { "aria-label": "top nav" }
              },
              [
                _c("NavBarDesktop", {
                  attrs: {
                    displayFullNavBar: _vm.isHeaderVisible,
                    hideRWLTenant: _vm.hideRWLTenant
                  },
                  on: {
                    "on-search": _vm.onSearch,
                    "toggle-side-menu": _vm.toggleSideMenu
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "main",
          {
            ref: "mainContent",
            class: _vm.isRightSideMenuVisible
              ? "main-content-part"
              : "main-content-part centered",
            staticStyle: { outline: "none" }
          },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _c(
                  "keep-alive",
                  [_c("router-view", { key: _vm.$route.fullPath })],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        this.$route.fullPath !== "/landing" &&
        this.$route.fullPath !== "/help-center-main" &&
        this.$route.fullPath !== "/onboarding/terms-and-conditions" &&
        this.$route.fullPath !== "/onboarding/privacy-policy" &&
        this.$route.fullPath !== "/onboarding/cookie-notice" &&
        this.$route.meta.isMenuPrivacy !== true &&
        this.$route.meta.isMenuTerms !== true
          ? _c(
              "footer",
              {
                staticClass: "footer",
                staticStyle: { "text-align": "left", padding: "0 3rem" }
              },
              [
                _c("span", { staticClass: "copyright-text" }, [
                  _vm._v(
                    " © " +
                      _vm._s(_vm.currentYear) +
                      " PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates. "
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "copyright-link",
                    on: {
                      click: function($event) {
                        return _vm.openPrivacy()
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "unset",
                          "text-decoration": "unset",
                          "background-color": "unset"
                        },
                        attrs: { href: "#" }
                      },
                      [_vm._v("Privacy Policy")]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "copyright-link",
                    on: {
                      click: function($event) {
                        return _vm.openCookieNotice()
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "unset",
                          "text-decoration": "unset",
                          "background-color": "unset"
                        },
                        attrs: { href: "#" }
                      },
                      [_vm._v("Cookie Notice")]
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "copyright-link",
                    on: {
                      click: function($event) {
                        return _vm.openTerms()
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "unset",
                          "text-decoration": "unset",
                          "background-color": "unset"
                        },
                        attrs: { href: "#" }
                      },
                      [_vm._v("Terms and Conditions")]
                    )
                  ]
                )
              ]
            )
          : this.$route.meta.isMenuPrivacy === true ||
            this.$route.meta.isMenuTerms === true
          ? _c("ion-footer")
          : _c(
              "ion-footer",
              { attrs: { id: "footer" } },
              [
                _c(
                  "ion-row",
                  [
                    _c(
                      "ion-col",
                      {
                        staticClass: "d-flex copyright-text",
                        attrs: { size: "7" }
                      },
                      [
                        _vm._v(
                          "© " +
                            _vm._s(_vm.currentYear) +
                            " PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates. "
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { size: "1" },
                        on: {
                          click: function($event) {
                            _vm.$router
                              .push("/help-center-main")
                              .catch(function() {})
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "accessible-link",
                            attrs: { href: "#" }
                          },
                          [_vm._v("Help Center")]
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { size: "1" },
                        on: {
                          click: function($event) {
                            return _vm.openPrivacy()
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "accessible-link",
                            attrs: { href: "#" }
                          },
                          [_vm._v("Privacy Policy")]
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { size: "1" },
                        on: {
                          click: function($event) {
                            return _vm.openCookieNotice()
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "accessible-link",
                            attrs: { href: "#" }
                          },
                          [_vm._v("Cookie Notice")]
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { size: "2" },
                        on: {
                          click: function($event) {
                            return _vm.openTerms()
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "accessible-link",
                            attrs: { href: "#" }
                          },
                          [_vm._v("Terms and Conditions")]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }