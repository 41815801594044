<template>
  <div class="edge-linkedinConnect">
    <button class="back_arrow" v-if="$platform == 'Mobile'" @click="$router.go(-1)">
      <svg-icon icon-class="thin-back-arrow" class-name="g-icon--small" />
    </button>
    <div class="svg-wrapper">
      <svg-icon icon-class="logo" class-name="logo--size" />
    </div>
    <div ref="top" class="edge-linkedinConnect__wrapper">
      <h4 class="ion-text-start edge-linkedinConnect__title" :class="this.$platform === 'Desktop' ? '' : 'g-type-large '">
        Check your email for a 6-digit code and enter it below
      </h4>

      <div class="error" v-if="errorMessage !== ''" aria-label="error message">
        {{ errorMessage }}
      </div>
      <ion-item class="input-section ion-no-padding" mode="md">
        <ion-label class="verify-title" mode="md" position="floating">Verification Code</ion-label>
        <ion-input
          position="floating"
          class="verify-input input-section"
          mode="md"
          data-cy="signup-code-1"
          @ionChange="setVerificationCode($event)"
          :disabled="isInputDisabled"
          ref="verificationCode"
        ></ion-input>
      </ion-item>
      <div class="handle-form">
        <ion-button data-cy="signup-code-verify" ref="verify" @click="onVerify" shape="round" color="primary" class="ion-button-primary verify-btn">Verify</ion-button>
        <br />
        <a href="#" class="lined-link" @click="onResend">Resend 6-digit code</a>
        <div v-if="isCodeResent" style="color: #00a78d; font-size: 1.2rem">Code is resent successfully</div>
      </div>
      <div v-if="$platform === 'Mobile'" class="footer-links">
        <router-link to="/login" class="lined-link" v-if="$platform == 'Mobile'">Back to login</router-link>
        <router-link to="get-help" class="lined-link" v-if="$platform == 'Mobile'">Get help</router-link>
        <div v-else>
          <a href="#" @click="$emit('next', 'OnboardingGetHelp')">Get help</a>
        </div>
      </div>
      <div>
        <ion-spinner v-if="loading" color="#1f3370" class="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import localforage from 'localforage'

export default {
  name: 'Verify',
  props: {
    email: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      verificationData: {
        email: '',
        verificationCode: ''
      },
      learningRecord: {},
      loading: false,
      errorMessage: '',
      isInputDisabled: false,
      clipboard: false,
      isCodeResent: false
    }
  },
  created() {
    !navigator.clipboard ? '' : (this.clipboard = true)
  },
  mounted() {
    analytics.setVarAndTrack({ c: 'onboarding', a: 'loaded', l: 'verification-page' })
    this.$refs.top.scrollIntoView()
    this.verificationData.email = this.$route.params && this.$route.params.email ? this.$route.params.email : this.email
  },
  methods: {
    ...mapActions('user', [
      'setAccessToken',
      'setUserInfo',
      'setTenantInfo',
      'setSSO',
      'setUserOnboarded',
      'setShowShare',
      'setShowIdea',
      'setHideRWL',
      'setTrackingDisabled',
      'setGlobalSearchEnabled',
      'setShowSearchSuggest',
      'setShowSearchLookingFor',
      'setUserAccessTag'
    ]),
    async activeUser() {
      const response = await this.$learnAdmin.activateUser(this.verificationData).catch((error) => error)
      if (response.error || response.code) {
        this.loading = false
        this.errorMessage = response.message
        return
      }
      this.errorMessage = ''
      return response
    },
    async getUserInfo() {
      const token = await localforage.getItem('my_access_token')
      const userInfo = await this.$learnAdmin.getUserInfo({ token: token }).catch((error) => error)
      if (userInfo.error || userInfo.code) {
        return
      }
      return userInfo
    },
    async getUserTenantInfo() {
      const token = await localforage.getItem('my_access_token')
      const tenantInfo = await this.$learnAdmin.getUserTenantInfo({ token: token }).catch((error) => error)
      if (tenantInfo.error || tenantInfo.code) {
        return
      }
      return tenantInfo
    },
    async getUserAccessAssets() {
      const token = await localforage.getItem('my_access_token')
      return await this.$learnAdmin.getUserAccessAssets({ token: token }).catch((error) => error)
    },
    async onVerify() {
      this.loading = true
      this.errorMessage = ''
      if (!this.verificationData.verificationCode.length) {
        this.loading = false
        this.errorMessage = 'Please enter the 6-digit code'
        return
      }
      this.loading = true
      const response = await this.activeUser()
      if (response.access_token) {
        const access_token = response.access_token
        const sso = { token: response.sso_token, bouncer: response.sso_bouncer }
        await this.setAccessToken(access_token)
        await this.setSSO(sso)
        const userInfo = await this.getUserInfo()
        await this.setUserInfo(userInfo).catch((error) => error)
        const tenantInfo = await this.getUserTenantInfo()
        await this.setTenantInfo(tenantInfo.clientCategory)

        const userAccessAssets = await this.getUserAccessAssets()
        if (userAccessAssets.canAccessAssets) {
          await this.setUserAccessTag(userAccessAssets)
        }
        const settings = tenantInfo.settings ? JSON.parse(tenantInfo.settings) : null
        if ({}.hasOwnProperty.call(settings, 'ui-share-display')) {
          await this.setShowShare(settings['ui-share-display'])
        } else {
          await this.setShowShare(true)
        }
        if ({}.hasOwnProperty.call(settings, 'ui-idea-display')) {
          await this.setShowIdea(settings['ui-idea-display'])
        } else {
          await this.setShowIdea(true)
        }
        if ({}.hasOwnProperty.call(settings, 'ui-rwl-display')) {
          await this.setHideRWL(settings['ui-rwl-display'])
        } else {
          await this.setHideRWL(false)
        }
        if ({}.hasOwnProperty.call(settings, 'trackingDisabled')) {
          await this.setTrackingDisabled(settings['trackingDisabled'])
        } else {
          await this.setTrackingDisabled(false)
        }
        if ({}.hasOwnProperty.call(settings, 'globalSearchEnabled')) {
          await this.setGlobalSearchEnabled(settings['globalSearchEnabled'])
        } else {
          await this.setGlobalSearchEnabled(false)
        }
        if ({}.hasOwnProperty.call(settings, 'showSearchSuggest')) {
          await this.setShowSearchSuggest(settings['showSearchSuggest'])
        } else {
          await this.setShowSearchSuggest(false)
        }
        if ({}.hasOwnProperty.call(settings, 'showSearchLookingFor')) {
          await this.setShowSearchLookingFor(settings['showSearchLookingFor'])
        } else {
          await this.setShowSearchLookingFor(false)
        }
        await this.$learnAdmin.updateOnboardingStage({ token: access_token, stage: 'COMPLETED' })
        await this.setUserOnboarded(true)
        await this.logUserEnrollment()
        if (this.$platform == 'Mobile') {
          this.$router.push('/onboarding/welcome')
        } else {
          this.$emit('next', 'Welcome')
        }
        this.loading = false
        analytics.setVarAndTrack({ c: 'onboarding', a: 'click', l: 'Verify' })
      }
    },
    setVerificationCode(event) {
      event.target.value = event.target.value ? event.target.value.replace(/\s+/g, '') : ''
      this.verificationData.verificationCode = event.target.value
    },
    async logUserEnrollment() {
      this.learningRecord = {
        verb: {
          id: window.location.href,
          display: { 'en-US': 'started' }
        },
        object: {
          id: window.location.href,
          definition: {
            name: { 'en-US': 'Learn App Enrollment' }
          }
        },
        result: {
          completion: true,
          success: true,
          score: {
            scaled: 1
          }
        }
      }
      learningAnalytics.setLearningRecordAndSend(this.learningRecord)
    },
    async onResend() {
      const payload = { email: this.verificationData.email }
      await this.$learnAdmin.sendVerificationCode(payload)
      this.isCodeResent = true
    }
  }
}
</script>

<style lang="scss" src="./components/onboarding.scss"></style>
<style lang="scss" scoped>
@include block('edge-linkedinConnect') {
  background-image: url('~@/assets/images/onboard-9.svg');
  background-position: right 25% top 0%;
  background-repeat: no-repeat;
  height: 100%;

  @include element('wrapper') {
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
    top: -5rem;
  }

  @include element('verificationCode-input') {
    margin: 15% 0%;
  }
  @include element('title') {
    margin-top: 20%;
    font-weight: bold;
  }
}

@media screen and (min-device-width: 1200px) {
  @include block('edge-linkedinConnect') {
    margin: 0 auto;
    width: 70%;
    height: 100%;
  }
}
.spinner {
  margin: 8px;
}

.label-floating {
  color: var(--ion-color-tertiary, #1f3370) !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  font-weight: bolder;
  font-size: small;
  letter-spacing: 1px;
}
ion-item {
  padding: 0 !important;
  --border-color: var(--ion-color-tertiary, #1f3370);
  --highlight-color-focused: var(--ion-color-tertiary, #1f3370);
}
ion-label {
  padding-left: 0 !important;
}
.input-section {
  --higlight-background: var(--ion-color-tertiary, #1f3370) !important;
  font-weight: 400;
  padding: 0 !important;
}
.verify-input {
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}

.handle-form {
  margin-top: 10px;
}

.footer-links {
  margin: 30% 0;
}

.lined-link {
  display: block;
  margin: 10px 0;
  color: #1f3370;
  font-size: 1.2rem;
}

.paste-option {
  text-align: right;
}

.paste-btn {
  background: transparent;
  font-size: 1.2rem;
  ion-icon {
    color: var(--ion-color-primary-dark);
  }
}

.error {
  border: 1px solid #ff0000;
  border-radius: 5px;
  background: #ffe6e6;
  margin: 5px 0;
}

.svg-wrapper {
  position: relative;
  top: 15px;
  left: -100px;
  padding: 0%;
  svg {
    width: 30%;
    height: 30%;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.back_arrow {
  z-index: 100;
}

.verify-btn {
  --box-shadow: none;
}
</style>
