var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "asset-detail-container" },
    [
      _c(
        "ion-grid",
        { staticClass: "ion-no-padding" },
        [
          _c(
            "ion-row",
            { staticClass: "ion-no-padding" },
            [
              _c(
                "ion-col",
                {
                  staticClass: "ion-no-padding",
                  attrs: {
                    "size-xl": "8.5",
                    "size-lg": "12",
                    "size-md": "12",
                    "size-sm": "12",
                    "size-xs": "12"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.isDesktopPlatform
                        ? "asset-content-desktop"
                        : "asset-content-mobile"
                    },
                    [
                      _vm.isDesktopPlatform
                        ? _c(
                            "div",
                            { staticClass: "tabs-container" },
                            _vm._l(_vm.tabs, function(tab, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "tab",
                                  class:
                                    _vm.selectedTabIdx === index
                                      ? "selected"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.onTabSelected(index)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(tab.title) + " ")]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "asset-details-mobile-tabs-container"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "selected-tab",
                                  on: { click: _vm.toggleTabs }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.tabs[_vm.selectedTabIdx].title
                                      ) +
                                      " "
                                  ),
                                  _c("i", { staticClass: "dropdown-arrow" })
                                ]
                              )
                            ]
                          ),
                      _vm.tabsOpen
                        ? _c(
                            "div",
                            {
                              staticClass: "asset-details-overlay",
                              on: {
                                click: function($event) {
                                  _vm.tabsOpen = false
                                }
                              }
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "asset-details-tabs-selection" },
                                _vm._l(_vm.tabs, function(tab, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      class:
                                        _vm.selectedTabIdx === index
                                          ? "selected"
                                          : "",
                                      attrs: { value: tab.title },
                                      on: {
                                        click: function($event) {
                                          return _vm.onTabSelected(index)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(tab.title) + " ")]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "asset-desc-list",
                          staticStyle: { "text-align": "left" }
                        },
                        [
                          _vm.selectedTabIdx === 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "asset-short-description" },
                                    [_vm._v(_vm._s(_vm.asset.shortDescription))]
                                  ),
                                  _vm.asset.promoDescription
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$sanitize(
                                              _vm.asset.promoDescription
                                            )
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  {}.hasOwnProperty.call(this.asset, "media")
                                    ? _c(
                                        "CarouselDesktop",
                                        {
                                          staticClass: "media-carousel",
                                          attrs: {
                                            slidesShow: 1,
                                            responsive: false
                                          }
                                        },
                                        _vm._l(_vm.asset.media, function(
                                          media,
                                          index
                                        ) {
                                          return _c("AssetMediaPreview", {
                                            key: index,
                                            attrs: { media: media },
                                            on: {
                                              "media-selected": function(
                                                $event
                                              ) {
                                                return _vm.mediaModalVisible(
                                                  true,
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.asset.fullDescription
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$sanitize(
                                              _vm.asset.fullDescription
                                            )
                                          )
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _vm.isLoading
                                    ? _c("ion-spinner", {
                                        staticStyle: {
                                          "margin-top": "1rem",
                                          "margin-left": "45%"
                                        },
                                        attrs: { color: "#1f3370" }
                                      })
                                    : _vm._e(),
                                  _c("vue-pdf-embed", {
                                    staticStyle: { "margin-top": "1rem" },
                                    attrs: {
                                      source: _vm.asset.howToGuide.link
                                    },
                                    on: { rendered: _vm.onRender }
                                  })
                                ],
                                1
                              )
                        ]
                      )
                    ]
                  ),
                  _c("ItemRating", {
                    attrs: {
                      item: _vm.asset,
                      scroll: _vm.scroll,
                      ratingDetails: _vm.ratingDetails
                    },
                    on: {
                      "update-ratings": function($event) {
                        return _vm.getRatingById()
                      }
                    }
                  }),
                  _vm.relatedContent.length > 0
                    ? _c("RelatedContent", {
                        attrs: { relatedContent: _vm.relatedContent }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "ion-col",
                { staticClass: "ion-no-padding", attrs: { "size-xl": "3.5" } },
                [
                  _c(
                    "div",
                    {
                      class: _vm.isDesktopPlatform
                        ? "asset-info-desktop"
                        : "asset-info-mobile"
                    },
                    [
                      _vm.asset.meta.isCompanyAsset && _vm.isDesktopPlatform
                        ? _c("div", { staticClass: "author-banner" }, [
                            _vm._v(_vm._s(_vm.asset.provider.name) + " ASSET")
                          ])
                        : _vm._e(),
                      _c(
                        "ion-row",
                        { staticClass: "ion-no-padding asset-info-detail" },
                        [
                          _c(
                            "ion-col",
                            { staticClass: "ion-no-padding asset-info-title" },
                            [_vm._v("Required technology")]
                          ),
                          _c(
                            "ion-col",
                            {
                              staticClass:
                                "ion-no-padding application-info-value"
                            },
                            _vm._l(_vm.asset.applications, function(
                              application
                            ) {
                              return _c(
                                "div",
                                {
                                  key: application.name,
                                  staticClass: "application"
                                },
                                [
                                  application.src
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          height: "20px"
                                        },
                                        attrs: { src: application.src }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "asset-info-value",
                                      staticStyle: { "margin-right": "10px" }
                                    },
                                    [_vm._v(_vm._s(application.name))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        1
                      ),
                      _vm.asset.functions && _vm.asset.functions.length
                        ? _c(
                            "ion-row",
                            { staticClass: "ion-no-padding asset-info-detail" },
                            [
                              _c(
                                "ion-col",
                                {
                                  staticClass: "ion-no-padding asset-info-title"
                                },
                                [_vm._v("Function")]
                              ),
                              _vm.asset.functions.length !==
                              _vm.allFunctionsLength
                                ? _c(
                                    "ion-col",
                                    { staticClass: "ion-no-padding" },
                                    _vm._l(_vm.asset.functions, function(
                                      fnctn,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: fnctn,
                                          class:
                                            index ===
                                            _vm.asset.functions.length - 1
                                              ? ""
                                              : "function-value"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "asset-info-value" },
                                            [_vm._v(_vm._s(fnctn))]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm.asset.functions.length ===
                              _vm.allFunctionsLength
                                ? _c(
                                    "ion-col",
                                    { staticClass: "ion-no-padding" },
                                    [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "asset-info-value" },
                                          [_vm._v("All Functions")]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "ion-row",
                        { staticClass: "ion-no-padding asset-info-detail" },
                        [
                          _c(
                            "ion-col",
                            { staticClass: "ion-no-padding asset-info-title" },
                            [_vm._v("Asset type")]
                          ),
                          _c("ion-col", { staticClass: "ion-no-padding" }, [
                            _c("div", [
                              _c("div", { staticClass: "asset-info-value" }, [
                                _vm._v(_vm._s(_vm.asset.type.name))
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm.asset.emsiSkills && _vm.asset.emsiSkills.length
                        ? _c(
                            "ion-row",
                            { staticClass: "ion-no-padding asset-info-detail" },
                            [
                              _c(
                                "ion-col",
                                {
                                  staticClass: "ion-no-padding asset-info-title"
                                },
                                [_vm._v("Skills")]
                              ),
                              _c(
                                "ion-col",
                                {
                                  staticClass:
                                    "skills-padding application-info-value"
                                },
                                _vm._l(_vm.asset.emsiSkills, function(skill) {
                                  return _c("div", { key: skill }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "asset-skill-value",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.onSkillClick(skill)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(skill))]
                                    )
                                  ])
                                }),
                                0
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.howToGuide.link.length ||
                      _vm.asset.templateFile.link.length
                        ? _c(
                            "ion-row",
                            { staticClass: "ion-no-padding asset-info-detail" },
                            [
                              _c(
                                "ion-col",
                                {
                                  staticClass: "ion-no-padding asset-info-title"
                                },
                                [_vm._v("Resources")]
                              ),
                              _c("ion-col", { staticClass: "ion-no-padding" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "asset-info-resource",
                                    on: { click: _vm.onClickHowToGuide }
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "g-icon--small",
                                      staticStyle: { "margin-right": "0.5rem" },
                                      attrs: { "icon-class": "file-pdf" }
                                    }),
                                    _vm._v("How-to guide ")
                                  ],
                                  1
                                ),
                                _vm.asset.templateFile.name.length &&
                                _vm.asset.templateFile.link.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "asset-info-resource",
                                        on: { click: _vm.onClickTemplateFile }
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "g-icon--small",
                                          staticStyle: {
                                            "margin-right": "0.5rem"
                                          },
                                          attrs: {
                                            "icon-class": "download-box"
                                          }
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.asset.templateFile.name) +
                                            " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isMediaModal.visible
        ? _c("AssetMediaModal", {
            attrs: {
              media: _vm.asset.media,
              "selected-media": _vm.isMediaModal.index
            },
            on: {
              close: function($event) {
                return _vm.mediaModalVisible(false, 0)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }