<template>
  <div class="recommend-tabs-container">
    <div class="recommend-label">
      <span class="recommend-label-title">Skills</span
      ><span class="recommend-count" :class="this.$platform === 'Desktop' ? 'recommend-count-desktop' : 'recommend-count-mobile'">{{ recommendation.length }}</span>
      <div class="addskill-icon g-clickable" :class="this.$platform === 'Desktop' ? 'addskill-icon-desktop' : 'addskill-icon-mobile'" @click="openSkillModal()">
        <svg-icon icon-class="add-icon" class="addskill-icon-svg" />
      </div>
    </div>
    <div v-if="this.$platform === 'Desktop'" class="tabs-container">
      <div v-for="tab in visibleTabs" :key="tab.tidx" class="tab" :class="tabIndex === tab.tidx ? 'selected' : ''" @click="updateTabIndex(tab.tidx)">
        {{ tab.title }}
      </div>
    </div>
    <div v-else class="recommend-mobile-tabs-container">
      <div class="selected-tab" @click="toggleTabs">
        {{ tabs[tabIndex].title }}
        <i class="dropdown-arrow"></i>
      </div>
    </div>
    <div v-if="tabsOpen" class="overlay" @click="tabsOpen = false">
      <ul class="tabs-selection">
        <li v-for="tab in visibleTabs" :key="tab.tidx" :class="tabIndex === tab.tidx ? 'selected' : ''" :value="tab.title" @click="updateTabIndex(tab.tidx)">
          {{ tab.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecommendedTab',
  components: {},
  props: {
    tabs: { type: Array, required: true },
    selectedTabIndex: {
      type: Number,
      required: false,
      default: () => {
        return 0
      }
    },
    recommendation: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      visibleTabs: [],
      tabIndex: 0,
      tabsOpen: false
    }
  },
  computed: {},
  watch: {},
  async created() {
    this.visibleTabs = await this.tabs.filter((tab) => !tab.hidden)
    this.tabIndex = this.selectedTabIndex
    document.addEventListener('touchmove', this.handleScroll, true)
  },
  destroyed() {
    document.removeEventListener('touchmove', this.handleScroll, true)
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyPress)
    window.addEventListener('keyup', this.handleKeyPress)
  },
  methods: {
    handleScroll() {
      this.tabsOpen && (this.tabsOpen = !this.tabsOpen)
    },
    handleKeyPress(event) {
      if (event.key == 'ArrowDown' || event.key == 'ArrowUp') this.$emit('close-skill-modal')
    },
    updateTabIndex(index) {
      this.tabIndex = index
      this.$emit('update-tab-index', index)
      if (this.$platform === 'Mobile') {
        this.toggleTabs()
      }
    },
    toggleTabs() {
      this.tabsOpen = !this.tabsOpen
    },
    openSkillModal() {
      this.$emit('open-skill-modal', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem;
  font-size: 18px;
  font-family: 'Futura PT Demi';
  margin-bottom: 8px;
  color: var(--ion-color-black);
}

.recommend-label-title {
  font-size: 24px;
  font-family: 'Futura PT Demi';
}

.recommend-count {
  position: relative;
  margin-left: 0.5rem;
  padding: 4px 17px 0px 17px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-family: 'Arial';
  font-weight: bold;
  background: var(--ion-color-dark-role-grey);
}

.recommend-count-desktop {
  padding: 6px 16px;
}

.recommend-count-mobile {
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recommend-tabs-container .tabs-container {
  display: inline-block;
  position: relative;
  left: 40%;
  transform: translate(-50%, 0);
}

.recommend-tabs-container .tab {
  display: inline-block;
  margin: 0 1rem;
  padding: 0.3rem 0.5rem 0.8rem 0.5rem;
  font-size: 1rem;
  font-family: 'Arial';
  color: var(--ion-color-post-gray);
  cursor: pointer;

  &.selected {
    color: var(--ion-color-black);
    font-weight: bold;
    border-bottom: 3px solid #00d1b0;
  }

  span {
    margin-left: 0.3rem;
    padding: 0rem 0.7rem;
    border-radius: 1rem;
    background: var(--ion-color-light-role-grey);
    font-size: 14px;
    font-family: Arial;
    color: var(--ion-color-black);
  }
}

.recommend-mobile-tabs-container {
  position: relative;
  margin: 0 0rem;
  float: right;

  .selected-tab {
    font-size: 14px;
    color: var(--ion-color-black);
    display: inline-block;
    padding-bottom: 0.5rem;
    font-family: 'Arial';
    font-weight: bold;
    border-bottom: 4px solid var(--ion-color-secondary-lighter);

    span {
      margin-left: 0.3rem;
      padding: 0rem 0.7rem;
      border-radius: 1rem;
      background: var(--ion-color-black);
      font-size: 14px;
      color: var(--ion-color-white);
      font-family: 'Arial';
    }

    .dropdown-arrow {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid var(--ion-color-black);
      display: inline-block;
      margin-left: 0.6rem;
    }
  }
}

.recommend-tabs-container {
  display: flex;
  justify-content: unset;
}

.recommend-tabs-container .overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  z-index: 9;
}

.tabs-selection {
  position: fixed;
  top: 140px;
  right: 5%;
  background: var(--ion-color-white);
  padding: 4px;
  width: 225px;
  z-index: 10;
  border-radius: 4px;
  list-style-image: none;

  li {
    list-style-type: none;
    color: var(--ion-color-dark);
    padding: 0.8rem 0.8rem;
    margin: 0.3rem 0.1rem;
    text-align: start;
    border-radius: 6px;
    font-family: 'Arial';
    font-size: 14px;

    span {
      margin-left: 0.3rem;
      padding: 0rem 0.7rem;
      border-radius: 1rem;
      background: var(--ion-color-light-role-grey);
      font-size: 18px;
      font-family: Arial;
      color: var(--ion-color-black);
    }
    &.selected {
      background-color: #e9ebf1;
      color: var(--ion-color-dark-blue);

      span {
        background: var(--ion-color-black);
        color: var(--ion-color-white);
      }
    }
  }
}

.addskill-icon {
  position: relative;
  background-color: #1f3370;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0px 10px 0px 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .addskill-icon-desktop {}

.addskill-icon-mobile {
  top: 0px;
}

.addskill-icon-svg {
  height: 12px;
  width: 12px;
}

@media screen and (max-width: 1024px) {
  .recommend-tabs-container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1024px) {
  .recommend-label-title {
    font-size: 20px;
    font-family: 'Futura PT Demi';
  }

  .recommend-tabs-container .tabs-container {
    display: inline-block;
    position: relative;
    left: unset;
    transform: none;
  }
}

@media screen and (max-width: 992px) {
  .recommend-tabs-container .tab {
    margin: 0rem;
  }
}

@media screen and (max-width: 760px) {
  .recommend-tabs-container .tab {
    margin: 0rem;
  }
}

// Screens under 480px
@media screen and (max-width: 480px) {
  .recommend-label {
    font-size: 14px;
  }
  .addskill-icon {
    padding-top: 0px;
  }
  .recommend-label-title {
    font-size: 26px;
    font-family: 'Futura PT Demi';
  }
}
</style>
