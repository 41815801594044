<template>
  <div>
    <div class="learning-metrics-container">
      <a
        class="learning-metric-item item-bottom-boarder"
        v-for="(learningItem, index) in dashboardMetricsData.slice(0, 2)"
        :class="dashboardMetricsData.length <= 1 ? 'border-width' : dashboardMetricsData.length <= 2 ? 'no-border' : ''"
        :key="index"
        href="#"
        @click="goToMyLearning(learningItem.redirectTo)"
      >
        <svg-icon v-if="learningItem.id === 'pastDueCount' && learningItem.value !== 0" icon-class="past-due" class-name="g-icon--small past-due-icon" />
        <label>{{ learningItem.name }}</label>
        <span>{{ learningItem.value }}</span>
      </a>
    </div>
    <div class="learning-metrics-container">
      <a
        class="learning-metric-item item-top-boarder"
        v-for="(learningItem, index) in dashboardMetricsData.slice(2, 5)"
        :key="index"
        href="#"
        @click="goToMyLearning(learningItem.redirectTo)"
      >
        <label>{{ learningItem.name }}</label>
        <span>{{ learningItem.value }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeDashboardMetrics',
  components: {},
  props: {
    dashboardMetricsData: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  computed: {
  },
  data() {
    return {}
  },
  watch: {},
  async mounted() {},
  methods: {
    goToMyLearning(tabName) {
      this.$router.push({ name: 'MyLearning', params: { type: tabName, isNotFromDeepLink: false } }).catch(() => {})
    },
  }
}
</script>
    
<style lang="scss" scoped>
.learning-metrics-container {
  display: flex;
  flex-wrap: wrap;
  .learning-metric-item {
    padding-right: 2rem;
    padding-top: 1rem;
    margin-top: 1rem;
    text-decoration: none;

    @media (max-width: 500px) {
      padding-right: 1rem;
    }
    &.item-bottom-boarder {
      &:nth-child(2) {
        @media (max-width: 1122px) {
          padding-right: 0;
        }
        @media screen and (min-width: 300px) and (max-width: 410px) {
          border-bottom: 1px solid #e0e1e1;
          padding-bottom: 1rem;
          margin-top: 0;
          width: 100%;
        }
      }
      @media screen and (min-width: 992px) and (max-width: 1122px) {
        border-bottom: 1px solid #e0e1e1;
        padding-bottom: 1rem;
      }
      @media screen and (min-width: 410px) and (max-width: 500px) {
        border-bottom: 1px solid #e0e1e1;
        padding-bottom: 1rem;
      }
    }
    &.item-top-boarder {
      border-top: 1px solid #e0e1e1;
      @media (max-width: 500px) {
        border-top: unset;
        margin-top: 0;
      }
      @media screen and (min-width: 992px) and (max-width: 1122px) {
        border-top: unset;
        margin-top: 0;
      }
      &:nth-child(3) {
        padding-right: 0;
        @media screen and (min-width: 1023px) and (max-width: 1594px) {
          border-top: unset !important;
          margin-top: 0 !important;
          width: 40%;
        }
      }
      &:nth-child(2) {
        @media screen and (min-width: 1023px) and (max-width: 1594px) {
          padding-right: 0;
        }
        @media (max-width: 500px) {
          padding-right: 0;
        }
      }
    }
    &.no-border {
      border-bottom: none !important;
      @media (max-width: 500px) {
        margin-top: 0;
        padding-top: 1rem;
      }
      &:nth-child(2) {
        @media screen and (min-width: 990px) and (max-width: 1250px) {
          margin-top: 0;
        }
      }
    }
    &.border-width {
      border-bottom: none;
      width: 100%;
    }

    .past-due-icon {
      margin-right: 0.5rem;
    }
    label {
      font: normal normal normal 16px/18px Arial;
      color: var(--ion-color-tertiary);
      padding-right: 0.5rem;
      cursor: pointer;
    }
    span {
      font: normal normal normal 28px/23px 'Futura PT Demi';
      color: var(--ion-color-tertiary);
      cursor: pointer;
    }
  }
}
</style>