<template>
  <div class="fontsize_controller">
    <label for="0">A</label>
    <input v-model="fontSize" type="radio" id="small" value="0" @input="onInputKeyDown" />
    <label for="1">A</label>
    <input v-model="fontSize" type="radio" id="medium" value="1" @input="onInputKeyDown" />
    <label for="2">A</label>
    <input v-model="fontSize" type="radio" id="large" value="2" @input="onInputKeyDown" />
  </div>
</template>

<script>
export default {
  name: 'ContentFontSizeController',
  components: {},
  props: {
    className: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fontSize: 0
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async onInputKeyDown(event) {
      const value = event.target.value
      const size = this.fontSize < value ? (value - this.fontSize) * 2 : -((this.fontSize - value) * 2)
      const elements = document.getElementsByClassName(this.className)[0].childNodes
      if (elements) {
        this.translateStyle(elements, size)
      }
    },
    translateStyle(elements, size) {
      for (let element of elements) {
        if ('style' in element) {
          const fontSize = Number(window.getComputedStyle(element).fontSize.substring(0, 2))
          const textChange = `font-size: ${fontSize + size}px !important;`
          if (element.childNodes.length) {
            for (let childElement of element.childNodes) {
              childElement.style = textChange
            }
          }
          element.style = textChange
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fontsize_controller {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 48px;

  label {
    align-self: center;
    align-items: flex-end;
    justify-content: flex-end;
    color: #5c5c5c;
  }

  label:nth-of-type(1) {
    font: normal normal normal 16px/22px Arial;
  }

  label:nth-of-type(2) {
    font: normal normal normal 18px/22px Arial;
  }

  label:nth-of-type(3) {
    font: normal normal normal 22px/22px Arial;
  }

  input[type='radio'] {
    background-clip: content-box;
    border: 1px solid #cccccc;
    margin: 0px 10px 0px 2.5px;
    display: inline-block;
    border-radius: 50%;
    appearance: none;
    padding: 2px;
    height: 14px;
    width: 14px;
    &:hover {
      cursor: pointer;
    }
  }

  input[type='radio']:checked {
    background-color: #1f3370;
    border-color: #1f3370;
    color: #fff;
  }
}
</style>