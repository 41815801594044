var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-item-container" }, [
    _c("div", { staticClass: "meta" }, [
      _c(
        "div",
        { staticClass: "format" },
        [_c("ion-skeleton-text", { attrs: { animated: "" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "provider" },
        [_c("ion-skeleton-text", { attrs: { animated: "" } })],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "title" },
      [_c("ion-skeleton-text", { attrs: { animated: "" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "subtitle" },
      [_c("ion-skeleton-text", { attrs: { animated: "" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "subtitle" },
      [_c("ion-skeleton-text", { attrs: { animated: "" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "subtitle" },
      [_c("ion-skeleton-text", { attrs: { animated: "" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "meta footer", staticStyle: { "margin-top": "0.4rem" } },
      [
        _c(
          "div",
          { staticClass: "search-bookmark" },
          [_c("ion-skeleton-text", { attrs: { animated: "" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "search-social" },
          [_c("ion-skeleton-text", { attrs: { animated: "" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "duration" },
          [_c("ion-skeleton-text", { attrs: { animated: "" } })],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }