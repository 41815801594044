var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifs-list" },
    [
      _vm.isMobilePlatform
        ? _c("svg-icon", {
            staticClass: "g-icon--small close g-clickable close-icon",
            attrs: { "icon-class": "close" },
            on: { click: _vm.onCancel }
          })
        : _vm._e(),
      _vm.notifs && _vm.notifs.length === 0
        ? _c("div", { staticClass: "no-notifs" }, [
            _c("h3", [_vm._v("You don't have any notifications")])
          ])
        : _c(
            "div",
            [
              _c("div", { staticClass: "list-action" }, [
                _c("a", { attrs: { href: "#" }, on: { click: _vm.readAll } }, [
                  _vm._v("Mark All as Read")
                ]),
                _vm._v(" | "),
                _c(
                  "a",
                  { attrs: { href: "#" }, on: { click: _vm.dismissAll } },
                  [_vm._v("Dismiss All")]
                )
              ]),
              _c(
                "transition-group",
                { attrs: { tag: "ul", name: "note" } },
                _vm._l(_vm.notifs, function(notif) {
                  return _c(
                    "li",
                    { key: notif.id, class: notif.isRead ? "" : "unread" },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.read(notif)
                            }
                          }
                        },
                        [_vm._v(_vm._s(notif.message))]
                      ),
                      _c("ion-icon", {
                        staticClass: "close",
                        attrs: { name: "close-outline" },
                        on: {
                          click: function($event) {
                            return _vm.dismiss(notif.id)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }