var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.componentActivated
    ? _c(
        "div",
        { staticClass: "submission-details-container main-content" },
        [
          _vm.showErrorMessage
            ? _c("div", { staticClass: "error-alert" }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
              ])
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "back",
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [
              _c("svg-icon", {
                staticClass: "g-icon--small",
                attrs: { "icon-class": "icon-arrow-left" }
              }),
              _vm._v(" Back ")
            ],
            1
          ),
          _c("div", { staticClass: "submission-form-content" }, [
            _c("div", { staticClass: "icon-container" }, [
              _c(
                "div",
                { staticClass: "save-tooltip" },
                [
                  _c("svg-icon", {
                    class: _vm.disableDraftButtonFlag()
                      ? "svg-disabled"
                      : "action-icon",
                    attrs: { "icon-class": "icon-save", alt: "save" },
                    on: {
                      click: function($event) {
                        return _vm.submitForm("draft")
                      }
                    }
                  }),
                  _c("span", { staticClass: "save-tooltiptext" }, [
                    _vm._v("Save as draft")
                  ])
                ],
                1
              ),
              _vm.isDraftMode
                ? _c(
                    "div",
                    { staticClass: "submit-tooltip" },
                    [
                      _c("svg-icon", {
                        class: _vm.disableSubmitButtonFlag()
                          ? "svg-disabled"
                          : "action-icon",
                        attrs: { "icon-class": "submit-icon", alt: "submit" },
                        on: {
                          click: function($event) {
                            return _vm.submitForm("submit")
                          }
                        }
                      }),
                      _c("span", { staticClass: "submit-tooltiptext" }, [
                        _vm._v(
                          _vm._s(
                            [
                              _vm.assetStatus.returned.value,
                              _vm.assetStatus.inEdit.value
                            ].includes(_vm.reviewStatus.toUpperCase())
                              ? "Resubmit"
                              : "Submit for review"
                          )
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "submission-form-header" },
              [
                _vm.isDraftMode
                  ? _c("div", { staticClass: "last-saved" }, [
                      _c("span", [_vm._v("ID " + _vm._s(_vm.displayId))]),
                      _c("span", { staticClass: "divider" }),
                      _c("span", [
                        _vm._v(
                          "LAST SAVED " +
                            _vm._s(_vm._f("formatDate")(_vm.lastSaved))
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      [
                        _vm.assetStatus.returned.value,
                        _vm.assetStatus.inEdit.value
                      ].includes(_vm.reviewStatus.toUpperCase())
                        ? "Edit asset details"
                        : "Submit an asset"
                    )
                  )
                ]),
                _c("p", { staticClass: "mandatory-text" }, [
                  _vm._v(
                    "Fields marked with an asterisk (*) are required for submission."
                  )
                ]),
                _vm._m(0),
                _c(
                  "ion-row",
                  { staticClass: "asset-description-form" },
                  [
                    _c(
                      "ion-col",
                      {
                        staticClass: "form-col",
                        attrs: { "size-xs": "12", "size-md": "12" }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            _vm._s(_vm.popoverContent.assetName.title) + "*"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            class: _vm.assetNameError
                              ? "error-input"
                              : "valid-input"
                          },
                          [
                            _c("ion-input", {
                              ref: "assetName",
                              staticClass: "asset-name-in",
                              attrs: {
                                "data-cy": "asset-name",
                                maxlength: "100",
                                placeholder:
                                  _vm.popoverContent.assetName.placeholder,
                                value: _vm.assetName
                              },
                              on: {
                                ionInput: function($event) {
                                  return _vm.handleInput(
                                    $event,
                                    "assetName",
                                    true
                                  )
                                }
                              }
                            }),
                            _vm.assetNameError
                              ? _c("div", { staticClass: "error-container" }, [
                                  _vm._v(
                                    _vm._s(_vm.popoverContent.assetName.error)
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tip-content" },
                          [
                            _c("Collapsible", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [_vm._v("Tip")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "section",
                                    fn: function() {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.popoverContent.assetName.tip
                                            )
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3058516565
                              )
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticClass: "form-col",
                        attrs: { "size-xs": "12", "size-md": "12" }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            _vm._s(_vm.popoverContent.promoHeader.title) + "*"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            class: _vm.promoHeaderError
                              ? "error-input"
                              : "valid-input"
                          },
                          [
                            _c("ion-textarea", {
                              ref: "promoHeader",
                              staticClass: "ion-no-padding form-input-val",
                              attrs: {
                                "data-cy": "asset-header",
                                autofocus: "",
                                rows: "5",
                                required: "",
                                value: _vm.promoHeader,
                                placeholder:
                                  _vm.popoverContent.promoHeader.placeholder
                              },
                              on: {
                                ionInput: function($event) {
                                  return _vm.handleInput(
                                    $event,
                                    "promoHeader",
                                    true
                                  )
                                }
                              }
                            }),
                            _vm.promoHeaderError
                              ? _c("div", { staticClass: "error-container" }, [
                                  _vm._v(
                                    _vm._s(_vm.popoverContent.promoHeader.error)
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tip-content" },
                          [
                            _c("Collapsible", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [_vm._v("Tip")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "section",
                                    fn: function() {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.popoverContent.promoHeader.tip
                                            )
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2810187634
                              )
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticClass: "form-col",
                        attrs: { "size-xs": "12", "size-md": "12" }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            _vm._s(_vm.popoverContent.promoDescription.title) +
                              "*"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            class: _vm.promoDescriptionError
                              ? "error-input"
                              : "valid-input"
                          },
                          [
                            _c("ion-textarea", {
                              ref: "promoDescription",
                              staticClass: "ion-no-padding form-input-val",
                              attrs: {
                                "data-cy": "asset-header",
                                autofocus: "",
                                rows: "5",
                                required: "",
                                value: _vm.promoDescription,
                                placeholder:
                                  _vm.popoverContent.promoDescription
                                    .placeholder
                              },
                              on: {
                                ionInput: function($event) {
                                  return _vm.handleInput(
                                    $event,
                                    "promoDescription",
                                    true
                                  )
                                }
                              }
                            }),
                            _vm.promoDescriptionError
                              ? _c("div", { staticClass: "error-container" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.popoverContent.promoDescription.error
                                    )
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tip-content" },
                          [
                            _c("Collapsible", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [_vm._v("Tip")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "section",
                                    fn: function() {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.popoverContent
                                                .promoDescription.tip
                                            )
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3530651275
                              )
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticClass: "form-col",
                        attrs: { "size-xs": "12", "size-md": "12" }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.popoverContent.fullDescription.title) +
                              " "
                          )
                        ]),
                        _c("ion-textarea", {
                          ref: "fullDescription",
                          staticClass: "ion-no-padding form-input-val",
                          attrs: {
                            "data-cy": "asset-description",
                            autofocus: "",
                            rows: "5",
                            required: "",
                            value: _vm.fullDescription,
                            placeholder:
                              _vm.popoverContent.fullDescription.placeholder
                          },
                          on: {
                            ionInput: function($event) {
                              return _vm.handleInput(
                                $event,
                                "fullDescription",
                                false
                              )
                            }
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "tip-content" },
                          [
                            _c("Collapsible", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [_vm._v("Tip")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "section",
                                    fn: function() {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.popoverContent.fullDescription
                                                .tip
                                            )
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                528264407
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "ion-col",
                      {
                        on: {
                          drop: function($event) {
                            return _vm.handleDrop(
                              $event,
                              "upload-support-input"
                            )
                          },
                          dragover: _vm.handleDragover,
                          dragenter: _vm.handleDragover
                        }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.popoverContent.imageOrVideo.title) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "file-upload-container" },
                          [
                            _c("div", { staticClass: "upload-button" }, [
                              _c(
                                "div",
                                { staticClass: "upload-btn-content" },
                                [
                                  _c("ion-icon", {
                                    staticClass: "ion-icon-size",
                                    attrs: { name: "cloud-upload-outline" }
                                  }),
                                  _c("p", [
                                    _vm._v("Drop files to upload (png) or")
                                  ])
                                ],
                                1
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "file-upload-wrapper",
                                attrs: { tabindex: "0" },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.openEl("upload-support-input")
                                  }
                                }
                              },
                              [
                                !_vm.resetImgEl
                                  ? _c("input", {
                                      ref: "upload-support-input",
                                      staticClass: "upload-input",
                                      attrs: {
                                        "data-cy": "asset-file",
                                        type: "file",
                                        accept: "image/png",
                                        multiple: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleClick(
                                            $event,
                                            "upload-support-input"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" Choose files")
                              ]
                            ),
                            _vm._l(_vm.uploadScreenshots, function(
                              imageFile,
                              index
                            ) {
                              return _c(
                                "ion-row",
                                { key: index, staticClass: "file-details" },
                                [
                                  _c(
                                    "ion-col",
                                    {
                                      staticClass: "file-meta-data",
                                      attrs: { "size-xs": "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "section-subheading" },
                                        [
                                          _c("ion-icon", {
                                            staticClass: "ion-icon-size",
                                            attrs: { name: "image-outline" }
                                          }),
                                          _c("h5", [
                                            _vm._v(_vm._s(imageFile.name))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "section-meta-info" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "section-meta-text"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatSize")(
                                                      imageFile.size
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.isDraftMode && imageFile.signedUrl
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "download-button"
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/images/svgs/icon-download.svg"),
                                                      alt: "download-icon"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.onDownload(
                                                          imageFile
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "download-button" },
                                            [
                                              _c("ion-icon", {
                                                staticClass: "ion-icon-size",
                                                attrs: {
                                                  name: "trash-outline"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteFiles(
                                                      "upload-support-input",
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        ),
                        _vm.popoverContent.imageOrVideo.error
                          ? _c("div", { staticClass: "error-container" }, [
                              _vm._v(
                                _vm._s(_vm.popoverContent.imageOrVideo.error)
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "tip-content" },
                          [
                            _c("Collapsible", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [_vm._v("Tip")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "section",
                                    fn: function() {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.popoverContent.imageOrVideo
                                                .tip
                                            )
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3340257801
                              )
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._m(1),
                _c(
                  "ion-row",
                  { staticClass: "technical-details-form" },
                  [
                    _c(
                      "ion-col",
                      {
                        staticClass: "form-col",
                        attrs: { "size-xs": "12", "size-md": "12" }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            _vm._s(_vm.popoverContent.inventoryType.title) + "*"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            class: _vm.inventoryTypeError
                              ? "error-input"
                              : "valid-input"
                          },
                          [
                            _vm.inventoryTypeList.length > 0
                              ? _c("SingleSelectDropDown", {
                                  attrs: {
                                    listVal: _vm.inventoryType,
                                    list: _vm.inventoryTypeList
                                  },
                                  on: { selected: _vm.getInventory }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.inventoryTypeError
                          ? _c("div", { staticClass: "error-container" }, [
                              _vm._v(
                                _vm._s(_vm.popoverContent.inventoryType.error)
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticClass: "form-col",
                        attrs: { "size-xs": "12", "size-md": "12" }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            _vm._s(_vm.popoverContent.applications.title) + "*"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            class: _vm.applicationsError
                              ? "error-input"
                              : "valid-input"
                          },
                          [
                            _vm.toolsList.length > 0
                              ? _c("MultiSelectDropDown", {
                                  attrs: {
                                    listVal: _vm.applications,
                                    list: _vm.toolsList
                                  },
                                  on: { selected: _vm.getTools }
                                })
                              : _vm._e(),
                            _vm.applicationsError
                              ? _c("div", { staticClass: "error-container" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.popoverContent.applications.error
                                    )
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        staticClass: "form-col",
                        attrs: { "size-xs": "12", "size-md": "12" }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            _vm._s(_vm.popoverContent.functions.title) + "*"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            class: _vm.functionsError
                              ? "error-input"
                              : "valid-input"
                          },
                          [
                            _vm.functionsList.length > 0
                              ? _c("FunctionDropDown", {
                                  attrs: {
                                    listVal: _vm.functions,
                                    list: _vm.functionsList
                                  },
                                  on: { selectedFun: _vm.getFunctions }
                                })
                              : _vm._e(),
                            _vm.functionsError
                              ? _c("div", { staticClass: "error-container" }, [
                                  _vm._v(
                                    _vm._s(_vm.popoverContent.functions.error)
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      {
                        on: {
                          drop: function($event) {
                            return _vm.handleDrop(
                              $event,
                              "upload-documentation-input"
                            )
                          },
                          dragover: _vm.handleDragover,
                          dragenter: _vm.handleDragover
                        }
                      },
                      [
                        _c("div", { staticClass: "section-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.popoverContent.uploadDocumentation.title
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "file-upload-container" },
                          [
                            _c("div", { staticClass: "upload-button" }, [
                              _c(
                                "div",
                                { staticClass: "upload-btn-content" },
                                [
                                  _c("ion-icon", {
                                    staticClass: "ion-icon-size",
                                    attrs: { name: "cloud-upload-outline" }
                                  }),
                                  _c("p", [
                                    _vm._v("Drop file to upload (pdf) or")
                                  ])
                                ],
                                1
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "file-upload-wrapper",
                                attrs: { tabindex: "0" },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.openEl(
                                      "upload-documentation-input"
                                    )
                                  }
                                }
                              },
                              [
                                !_vm.resetPdfEl
                                  ? _c("input", {
                                      ref: "upload-documentation-input",
                                      staticClass: "upload-input",
                                      attrs: {
                                        "data-cy": "asset-file",
                                        type: "file",
                                        accept: "application/pdf"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleClick(
                                            $event,
                                            "upload-documentation-input"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v("Choose files")
                              ]
                            ),
                            _vm._l(_vm.uploadPdfFiles, function(
                              imageFile,
                              index
                            ) {
                              return _c(
                                "ion-row",
                                { key: index, staticClass: "file-details" },
                                [
                                  _c(
                                    "ion-col",
                                    {
                                      staticClass: "file-meta-data",
                                      attrs: { "size-xs": "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "section-subheading" },
                                        [
                                          _c("ion-icon", {
                                            staticClass: "ion-icon-size",
                                            attrs: {
                                              name: "document-text-outline"
                                            }
                                          }),
                                          _vm._v(_vm._s(imageFile.name))
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "section-meta-info" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "section-meta-text"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatSize")(
                                                      imageFile.size
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.isDraftMode && imageFile.signedUrl
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "download-button"
                                                },
                                                [
                                                  _vm.isDraftMode
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/svgs/icon-download.svg"),
                                                          alt: "download-icon"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onDownload(
                                                              imageFile
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "download-button" },
                                            [
                                              _c("ion-icon", {
                                                staticClass: "ion-icon-size",
                                                attrs: {
                                                  name: "trash-outline"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteFiles(
                                                      "upload-documentation-input",
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        ),
                        _vm.popoverContent.uploadDocumentation.error
                          ? _c("div", { staticClass: "error-container" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.popoverContent.uploadDocumentation.error
                                )
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "tip-content" },
                          [
                            _c("Collapsible", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [_vm._v("Tip")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "section",
                                    fn: function() {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.popoverContent
                                                .uploadDocumentation.tip
                                            )
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1745494291
                              )
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _vm._m(2),
                    _c(
                      "ion-row",
                      { staticClass: "asset-link-form" },
                      [
                        _c(
                          "ion-col",
                          {
                            staticClass: "form-col",
                            attrs: { "size-xs": "12", "size-md": "12" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "section-title asset-section-tab"
                              },
                              [
                                ["zip", "both"].includes(
                                  _vm.tenantSettingsForAsset
                                )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "asset-type-tab",
                                        class:
                                          _vm.canUploadAssets &&
                                          ["both"].includes(
                                            _vm.tenantSettingsForAsset
                                          )
                                            ? "selected-asset-tab"
                                            : _vm.externalAssetLink.length >
                                                0 ||
                                              ["link"].includes(
                                                _vm.tenantSettingsForAsset
                                              )
                                            ? "disable-asset-tab"
                                            : "",
                                        on: {
                                          click: function($event) {
                                            return _vm.selectAssetTab(
                                              true,
                                              "zip"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Asset zip file "),
                                        _vm.canUploadAssets
                                          ? _c("span", [_vm._v("*")])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                ["link", "both"].includes(
                                  _vm.tenantSettingsForAsset
                                )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "asset-type-tab",
                                        class:
                                          !_vm.canUploadAssets &&
                                          ["both"].includes(
                                            _vm.tenantSettingsForAsset
                                          )
                                            ? "selected-asset-tab"
                                            : Object.keys(_vm.assetReference)
                                                .length > 0 ||
                                              _vm.fileUploadStatus != "" ||
                                              ["zip"].includes(
                                                _vm.tenantSettingsForAsset
                                              )
                                            ? "disable-asset-tab"
                                            : "",
                                        on: {
                                          click: function($event) {
                                            return _vm.selectAssetTab(
                                              false,
                                              "link"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.popoverContent.externalAssetLink
                                              .title
                                          ) + " "
                                        ),
                                        !_vm.canUploadAssets
                                          ? _c("span", [_vm._v("*")])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            !_vm.canUploadAssets
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.externalAssetLinkError
                                      ? "error-input"
                                      : "valid-input"
                                  },
                                  [
                                    _c("ion-textarea", {
                                      ref: "externalAssetLink",
                                      staticClass:
                                        "ion-no-padding form-input-val",
                                      attrs: {
                                        "data-cy": "asset-link",
                                        rows: "5",
                                        required: "",
                                        placeholder:
                                          _vm.popoverContent.externalAssetLink
                                            .placeholder,
                                        value: _vm.externalAssetLink
                                      },
                                      on: {
                                        ionInput: function($event) {
                                          return _vm.handleInput(
                                            $event,
                                            "externalAssetLink",
                                            true
                                          )
                                        }
                                      }
                                    }),
                                    _vm.externalAssetLinkError
                                      ? _c(
                                          "div",
                                          { staticClass: "error-container" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.popoverContent
                                                  .externalAssetLink.error
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "file-upload-container",
                                    on: {
                                      drop: function($event) {
                                        return _vm.handleDrop(
                                          $event,
                                          "asset-file-zip"
                                        )
                                      },
                                      dragover: _vm.handleDragover,
                                      dragenter: _vm.handleDragover
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "upload-button upload-zip"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "upload-btn-content" },
                                          [
                                            _c("ion-icon", {
                                              staticClass: "ion-icon-size",
                                              attrs: {
                                                name: "cloud-upload-outline"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "zip-text" },
                                              [_vm._v("ZIP (MAX 150 MB)")]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                "Drop file here to upload or"
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "label",
                                      {
                                        ref: "assetFileLabel",
                                        staticClass: "file-upload-wrapper",
                                        attrs: { tabindex: "0" },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.openEl("assetFileZip")
                                          }
                                        }
                                      },
                                      [
                                        !_vm.fileUploadStatus &&
                                        _vm.showZipUpload
                                          ? _c("input", {
                                              ref: "assetFileZip",
                                              staticClass: "upload-input",
                                              attrs: {
                                                id: "assetFileZip",
                                                "data-cy": "asset-file-zip",
                                                type: "file",
                                                accept: "application/zip"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.handleClick(
                                                    $event,
                                                    "asset-file-zip"
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v("Choose a file")
                                      ]
                                    ),
                                    _vm.assetReference.name &&
                                    _vm.fileUploadStatus == "U"
                                      ? _c("ion-progress-bar", {
                                          attrs: {
                                            color: "secondary",
                                            type: "indeterminate"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.assetReference.name
                                      ? _c(
                                          "ion-row",
                                          {
                                            staticClass:
                                              "file-details add-space-div"
                                          },
                                          [
                                            _c(
                                              "ion-col",
                                              {
                                                staticClass: "file-meta-data",
                                                attrs: { "size-xs": "12" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "section-subheading"
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass:
                                                        "g-icon--small",
                                                      attrs: {
                                                        "icon-class":
                                                          "icon-file"
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "asset-zip-name"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.assetReference
                                                              .name
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "section-meta-info"
                                                  },
                                                  [
                                                    _vm.fileUploadStatus == "U"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "section-meta-text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Uploading... "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.fileUploadStatus != "U"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "section-meta-text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSize"
                                                                  )(
                                                                    _vm
                                                                      .assetReference
                                                                      .size
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.isDraftMode &&
                                                    _vm.assetReference
                                                      .virusScanStatus
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "download-button"
                                                          },
                                                          [
                                                            _vm.isDraftMode
                                                              ? _c("img", {
                                                                  attrs: {
                                                                    src: require("@/assets/images/svgs/icon-download.svg"),
                                                                    alt:
                                                                      "download-icon"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.downloadAssetZipFile()
                                                                    }
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.assetReference
                                                      .virusScanStatus
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "download-button"
                                                          },
                                                          [
                                                            _c("ion-icon", {
                                                              staticClass:
                                                                "ion-icon-size",
                                                              attrs: {
                                                                name:
                                                                  "close-outline"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.cancelUploadAsset
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "download-button"
                                                      },
                                                      [
                                                        _vm.assetReference
                                                          .virusScanStatus
                                                          ? _c("ion-icon", {
                                                              staticClass:
                                                                "ion-icon-size",
                                                              attrs: {
                                                                name:
                                                                  "trash-outline"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteFiles(
                                                                    "asset-file-zip"
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                          ]
                        ),
                        _c(
                          "ion-col",
                          {
                            staticClass: "form-col",
                            attrs: { "size-xs": "12", "size-md": "12" }
                          },
                          [
                            _c("div", { staticClass: "section-title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.popoverContent.comment.title) +
                                  " "
                              )
                            ]),
                            _c("ion-textarea", {
                              ref: "comment",
                              staticClass: "ion-no-padding form-input-val",
                              attrs: {
                                "data-cy": "asset-comment",
                                autofocus: "",
                                rows: "5",
                                required: "",
                                placeholder:
                                  _vm.popoverContent.comment.placeholder,
                                value: _vm.comment
                              },
                              on: {
                                ionInput: function($event) {
                                  return _vm.handleInput(
                                    $event,
                                    "comment",
                                    false
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ion-row",
                  [
                    _c(
                      "ion-col",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "ion-button",
                          {
                            staticClass:
                              "ion-text-capitalize ion-button-primary-dark submit-btn",
                            attrs: {
                              shape: "round",
                              disabled: _vm.disableSubmitButtonFlag(),
                              color: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("submit")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  [
                                    _vm.assetStatus.returned.value,
                                    _vm.assetStatus.inEdit.value
                                  ].includes(_vm.reviewStatus.toUpperCase())
                                    ? "Resubmit"
                                    : "Submit"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "ion-button",
                          {
                            staticClass: "draft-btn",
                            attrs: {
                              shape: "round",
                              disabled: _vm.disableDraftButtonFlag(),
                              color: "tertiary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("draft")
                              }
                            }
                          },
                          [_vm._v(" Save as draft ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ion-row",
                  [
                    _c("ion-col", [
                      _c(
                        "div",
                        {
                          class:
                            _vm.fileUploadStatus != ""
                              ? "show-mandatory-message-progress show-mandatory-message"
                              : "show-mandatory-message"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "show-icons" },
                            [
                              _c("ion-icon", {
                                attrs: { name: "information-circle-outline" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "show-message" }, [
                            _vm.fileUploadStatus != ""
                              ? _c("span", [
                                  _vm._v(
                                    "Saving is disabled while your file uploads."
                                  ),
                                  _c("br")
                                ])
                              : _vm._e(),
                            _vm._v(
                              " Fields marked with an asterisk (*) are required for submission. "
                            )
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm.userActivities.length > 0
            ? _c("div", { staticClass: "submission-form-content" }, [
                _c(
                  "div",
                  { staticClass: "comments-activity" },
                  [
                    _c("h2", [_vm._v("Comments & activity")]),
                    _vm._l(_vm.userActivities, function(activity, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "comment-container" },
                        [
                          _c("div", { staticClass: "short-name-container" }, [
                            _vm._v(_vm._s(activity.shortName))
                          ]),
                          _c(
                            "ion-row",
                            {
                              staticClass: "ion-margin-start ion-margin-bottom"
                            },
                            [
                              _c(
                                "ion-col",
                                {
                                  staticClass: "ion-no-padding",
                                  attrs: { "size-xs": "12", "size-md": "12" }
                                },
                                [
                                  activity.nameWithAction
                                    ? _c(
                                        "div",
                                        { staticClass: "activity-column" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "span-container" },
                                            [
                                              _vm._v(
                                                _vm._s(activity.nameWithAction)
                                              )
                                            ]
                                          ),
                                          activity.createdAt
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "date-container"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatLatestDate"
                                                      )(activity.createdAt)
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e(),
                                  activity.userName
                                    ? _c(
                                        "div",
                                        { staticClass: "activity-column" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "span-container" },
                                            [_vm._v(_vm._s(activity.userName))]
                                          ),
                                          activity.activityType
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "span-container"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      activity.activityType
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          activity.createdAt
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "date-container"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatLatestDate"
                                                      )(activity.createdAt)
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "ion-col",
                                {
                                  staticClass: "comment",
                                  attrs: { "size-xs": "12", "size-md": "11" }
                                },
                                [_vm._v(_vm._s(activity.comment))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.loadingMessage.length > 0
            ? _c("div", { staticClass: "custom-toast custom-toast-loading" }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "2rem" } },
                  [
                    _c("ion-progress-bar", {
                      attrs: { color: "secondary", type: "indeterminate" }
                    })
                  ],
                  1
                ),
                _c("div", [
                  _c("span", { staticClass: "status-message-normal" }, [
                    _vm._v(_vm._s(_vm.loadingMessage) + "...")
                  ])
                ]),
                _c("div", { staticClass: "toast-footer" })
              ])
            : _vm._e(),
          _vm.fileUploadStatus === "U" && _vm.uploadPercentage < 100
            ? _c("div", { staticClass: "custom-toast custom-toast-info" }, [
                _c("div", { staticClass: "status-message" }, [
                  _c("span", { staticClass: "status-message-bold" }, [
                    _vm._v("Step 1 of 2")
                  ]),
                  _c("span", { staticClass: "status-message-normal" }, [
                    _vm._v(
                      ": File upload in progress " +
                        _vm._s(_vm.uploadPercentage) +
                        "%"
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "cancel-btn" },
                  [
                    _c(
                      "ion-button",
                      {
                        staticClass: "cancel-btn-text",
                        attrs: {
                          shape: "round",
                          color: "tertiary",
                          size: "small"
                        },
                        on: {
                          click: function($event) {
                            return _vm.cancelUploadAsset()
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "toast-footer" })
              ])
            : _vm.fileUploadStatus === "C"
            ? _c("div", { staticClass: "custom-toast custom-toast-success" }, [
                _c(
                  "div",
                  { staticClass: "toast-close-icon" },
                  [
                    _c("svg-icon", {
                      staticClass: "g-icon--small",
                      attrs: { "icon-class": "close" },
                      on: {
                        click: function($event) {
                          _vm.fileUploadStatus = ""
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "toast-success-icon" },
                  [_c("svg-icon", { attrs: { "icon-class": "icon_success" } })],
                  1
                ),
                _vm._m(3),
                _c("div", { staticClass: "toast-footer" })
              ])
            : _vm.fileUploadStatus === "S" || _vm.uploadPercentage == 100
            ? _c("div", { staticClass: "custom-toast custom-toast-info" }, [
                _vm._m(4),
                _c(
                  "div",
                  { staticClass: "cancel-btn" },
                  [
                    _c(
                      "ion-button",
                      {
                        staticClass: "cancel-btn-text",
                        attrs: {
                          shape: "round",
                          color: "tertiary",
                          size: "small"
                        },
                        on: {
                          click: function($event) {
                            return _vm.cancelUploadAsset()
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "toast-footer" })
              ])
            : _vm.fileUploadStatus === "F"
            ? _c("div", { staticClass: "custom-toast custom-toast-failed" }, [
                _c(
                  "div",
                  { staticClass: "toast-close-icon" },
                  [
                    _c("svg-icon", {
                      staticClass: "g-icon--small",
                      attrs: { "icon-class": "close" },
                      on: {
                        click: function($event) {
                          _vm.fileUploadStatus = ""
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "toast-failed-icon" },
                  [
                    _c("svg-icon", {
                      attrs: { "icon-class": "icon-alert-black" }
                    })
                  ],
                  1
                ),
                _vm._m(5),
                _c(
                  "div",
                  { staticClass: "cancel-btn" },
                  [
                    _c(
                      "ion-button",
                      {
                        staticClass: "cancel-btn-text",
                        attrs: {
                          shape: "round",
                          color: "tertiary",
                          size: "small"
                        },
                        on: {
                          click: function($event) {
                            return _vm.tryAgain()
                          }
                        }
                      },
                      [_vm._v("Try again")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "toast-footer" })
              ])
            : _vm._e(),
          _vm.showAlertsModal && _vm.modalMessageObj.header
            ? _c("AssetAlertModal", {
                attrs: { modalProperties: _vm.modalMessageObj },
                on: {
                  closeAlertsModal: _vm.closeModal,
                  completeUpload: _vm.completeUpload,
                  ignoreUpload: _vm.ignoreUpload
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "margin-top": "2.5rem" } }, [
      _c("span", { staticClass: "dot" }, [_vm._v("1")]),
      _c("span", { staticClass: "heading-title" }, [
        _vm._v("Describe your asset")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "dot" }, [_vm._v("2")]),
      _c("span", { staticClass: "heading-title" }, [
        _vm._v("Provide technical details")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "dot" }, [_vm._v("3")]),
      _c("span", { staticClass: "heading-title" }, [_vm._v("Share your asset")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-message" }, [
      _c("span", { staticClass: "status-message-normal" }, [
        _vm._v("Success! Your file has been uploaded.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-message" }, [
      _c("span", { staticClass: "status-message-bold" }, [
        _vm._v("Step 2 of 2")
      ]),
      _c("span", { staticClass: "status-message-normal" }, [
        _vm._v(": File scan in progress")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-message" }, [
      _c("span", { staticClass: "status-message-normal" }, [
        _vm._v("Your file did not upload successfully")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }