import axios from 'axios'

export default {
    async createTracks(url, payload) {
        const headers = {
            Authorization: `Bearer ${payload.token}`,
            'Content-Type': 'multipart/form-data'
        }
        const response = await axios.post(url, payload.body, { headers: headers });
        return response;
    },
    async editTracks(url, payload) {
        const headers = {
            Authorization: `Bearer ${payload.token}`,
            'Content-Type': 'multipart/form-data'
        }
        const response = await axios.put(url, payload.body, { headers: headers });
        return response;
    },
    async getAllTracks(url, payload) {
        const headers = { Authorization: `Bearer ${payload.token}` }
        const response = await axios.get(url, { headers: headers }).catch(error => {
            return { data: error.response.data }
        })
        return response.data
    },
    async getUGTrackById(url, payload) {
        const headers = {
            Authorization: `Bearer ${payload.token}`,
            responseEncoding: 'utf8',
            withCredentials: true,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
            'Content-Type': 'application/json;charset=UTF-8'
        }
        const response = await axios.get(url, { headers: headers }).catch(error => {
            return { data: error.response.data }
        })
        return response.data
    },
    async deleteTrack(url, payload) {
        const headers = { Authorization: `Bearer ${payload.token}` }
        const response = await axios.delete(url, { headers: headers }).catch(error => {
            return { data: error.response.data }
        })
        return response.data
    },

    async addItemsToTrack(url, payload) {
        const headers = {
            Authorization: `Bearer ${payload.token}`,
            'Content-Type': 'application/json;charset=UTF-8'
        }
        const response = await axios.put(url, payload.body, { headers: headers });
        return response;
    }
}