<template>
  <div>
    <ion-row v-if="dataReady && item" class="ion-no-padding" :class="getItemInfoClass()" @mouseenter="expandCard()">
      <ion-col :class="getImageClass()">
        <div :class="getImageBackgroundClass()">
          <img v-if="isSignedRelativeUriAvailable" :src="item.image.signedRelativeUri" />
          <img v-else-if="{}.hasOwnProperty.call(item, 'image') && {}.hasOwnProperty.call(item.image, 'uri')" :src="item.image.uri" />
          <img v-else :src="defaultImage" />
        </div>
        <div v-if="item.format.name.toLowerCase() === 'track'" class="track-overlay"><svg-icon icon-class="track-overlay-icon" /></div>
      </ion-col>
      <ion-col :class="getColInfoClass()">
        <div :class="tab != 'recommended' && tab != 'relatedCourse' ? 'meta' : 'meta meta-gap'">
          <div class="category">
            <div :class="tab != 'recommended' ? 'format' : 'format font-color-black'">
              {{ item.format && item.format.name }}
            </div>
            <div :class="tab != 'recommended' ? 'provider' : 'provider provider-width font-color-black'">
              <div class="provider-name" :style="getProviderMaxWidth()">{{ item.provider && item.provider.name | formatProviderName }}</div>
              <span v-if="{}.hasOwnProperty.call(item, 'scope') && item.scope.isExternal" title="External content added by you">
                <svg-icon icon-class="icon-link" class="g-icon--small provider-link" @click="onExternalContentLink" />
              </span>
            </div>
          </div>
          <div v-if="{}.hasOwnProperty.call(item, 'dueDate') && item.progress != 100">
            <svg-icon icon-class="due-date" class-name="g-icon--small" /> <span class="dueDate">{{ getDueDate('Due') }}</span>
          </div>
          <div v-if="{}.hasOwnProperty.call(item, 'pastDueDate') && item.progress != 100">
            <svg-icon icon-class="past-due" class-name="g-icon--small" /> <span class="dueDate">{{ getDueDate('Past Due') }}</span>
          </div>
          <div v-if="item.requiredBy && item.progress != 100" class="required-container">
            <svg-icon v-if="!item.isDateExpired" icon-class="status-due" />
            <svg-icon v-else icon-class="status-red" />
            <div class="required-title">{{ isDesktopPlatform ? formatDate('requiredDate') : mobileFormatdate() }}</div>
            <span class="required-tooltip-text">Required by {{ formatDate('notitle') }}</span>
          </div>
          <div v-if="item.progress" style="margin-right: 5px" class="progress-data">
            <div v-if="item.progress > 0 && item.progress < 100" class="progress">
              <radial-progress-bar
                v-if="!item.selfProgressDeclaration && (!{}.hasOwnProperty.call(item, 'scope') || !item.scope.isExternal)"
                :diameter="28"
                :completed-steps="item.progress"
                :total-steps="item.progress == '99' ? 110 : 100"
                :startColor="`#000000`"
                :stopColor="`#000000`"
                :innerStrokeColor="`#e0e1e1`"
                :strokeWidth="4"
                :innerStrokeWidth="5"
              >
              </radial-progress-bar>
              <svg-icon v-else icon-class="progress" />
              <span v-if="!item.selfProgressDeclaration && (!{}.hasOwnProperty.call(item, 'scope') || !item.scope.isExternal)">{{ item.progress }}%</span>
              <span v-else>In progress</span>
              <span class="progress-tooltip-text" v-if="item.provider && item.provider.timeToProgressSyncInHours > 0"
                >Progress updates every {{ item.provider.timeToProgressSyncInHours }} hours</span
              >
            </div>
            <div v-else-if="item.completed || item.progress == 100" class="progress">
              <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
              <span v-if="$platform === 'Desktop'">Completed</span>
              <span class="progress-tooltip-text" v-if="item.provider && item.provider.timeToProgressSyncInHours > 0"
                >Progress updates every {{ item.provider.timeToProgressSyncInHours }} hours</span
              >
            </div>
            <div v-if="(item.completedAt || (item.meta && item.meta.completedAt)) && tab != 'relatedCourse'" style="margin-bottom: 3px;font-family: Arial">{{ getDateFormat(item.completedAt || item.meta.completedAt) }}</div>
          </div>
        </div>
        <div :style="tab === 'relatedCourse' ? 'max-width: 347px' : ''" :class="tab != 'expand-recommend-item' ? 'title ellipsis-lines-2' : 'title ellipsis-lines-3'">
          <a
            :style="tab != 'recommended' && tab != 'relatedCourse' && tab != 'track' ? '' : 'font-size: 24px;'"
            @click="onClick"
            @mouseenter="tab === 'recommended' || tab === 'relatedCourse' ? getEllipsesActive($event) : ''"
            href="#"
            >{{ item.title }}
            <span
              v-if="!$isTablet && isDesktopPlatform && isTitleEllipsesActive"
              :class="tab === 'relatedCourse' ? 'title-tooltip-text title-tooltip-text-bottom' : 'title-tooltip-text'"
              >{{ item.title }}</span
            >
          </a>
        </div>
        <div v-if="tab != 'relatedCourse'" class="meta-info">
          <div v-if="tab != 'recommended' && item.rating > 0" class="item-rating">
            <span class="rating">{{ item.rating }}</span>
            <svg-icon icon-class="star-filled-desktop" />
          </div>
          <div v-if="item.duration && item.format.name.toLowerCase() !== 'asset'" class="duration">{{ item.duration }}</div>
          <div v-if="tab != 'recommended' && item.level" class="level">{{ item.level }}</div>
          <div v-if="tab != 'recommended' && item.publishedAt" class="publish-date">{{ formatDate('publishedDate') }}</div>
          <div v-if="{}.hasOwnProperty.call(item, 'scope') && item.scope.isExternal" class="only-you"><svg-icon icon-class="icon-eye" class="g-icon--small" />Only you</div>
          <div v-if="item.format.name.toLowerCase() === 'asset'" class="asset-type">{{ item.type && item.type.name }}</div>
          <div v-if="tab != 'recommended' && item.format.name.toLowerCase() === 'asset'" class="applications">
            <img v-for="(application, index) in item.applications" :key="application.src + index" :src="application.src.replace('.svg', '-bw.svg')" :title="application.name" />
          </div>
        </div>
        <div v-if="isDesktopPlatform && tab != 'recommended' && tab != 'relatedCourse' && item.format.name.toLowerCase() != 'track' && item.tracks.length" class="track-meta">
          <div class="track-title">
            Track: <a href="#" @click="onClickTrack(item.tracks[0])">{{ item.tracks[0].name }}</a>
          </div>
          <div v-if="item.tracks.length > 1" @click="popoverOn('track')" :class="isTrackListOpen ? 'track-length track-length-active' : 'track-length'">
            <span class="trackLength">+ {{ item.tracks.length - 1 }}</span>
            <div v-if="isTrackListOpen" :class="isDesktopPlatform && tab != 'expand-recommend-item' ? 'track-list' : 'track-list-mobile'">
              <div :class="item.tracks.length > 6 ? 'track-list-scrollbar' : ''">
                <div v-for="(track, index) in item.tracks.slice(1)" :key="index" class="track-list-item" @click="onClickTrack(track)">{{ track.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </ion-col>
      <ion-col
        v-if="tab != 'relatedCourse'"
        :class="tab != 'expand-recommend-item' ? 'ion-col-action ion-no-padding' : 'ion-no-padding item-container-recommend item-action-col-margin'"
      >
        <ion-button v-if="tab != 'expand-recommend-item' && tab != 'recommended'" class="launch-btn" shape="round" color="primary" @click="onLaunch">
          {{ item.launchBtn }}
          <ion-icon v-if="item.launchBtn.toLowerCase() === 'open' || ({}.hasOwnProperty.call(item, 'scope') && item.scope.isExternal)" name="open-outline" slot="end" />
        </ion-button>
        <div :class="tab != 'expand-recommend-item' && tab != 'recommended' ? 'meta-action' : 'meta-action meta-action-recommend meta-action-justify-content'">
          <div v-if="!({}.hasOwnProperty.call(item, 'scope') && item.scope.isExternal)" class="bookmark-container">
            <Bookmark
              v-if="item.format.name.toLowerCase() != 'track'"
              class="item-bookmark"
              :assetId="item.id"
              :assetType="getAssetType(item)"
              :meta="item.meta"
              :key="item.meta.isBookmarked + _uid"
              :itemTitle="item.title + ' bookmark'"
            />
            <span v-if="isDesktopPlatform" class="bookmark-tooltip-text">Bookmark</span>
          </div>
          <div v-if="!({}.hasOwnProperty.call(item, 'scope') && item.scope.isExternal) && tab != 'recommended'" class="share-container">
            <button class="social-share" :aria-label="item.title + ' share'" @click="popoverOn('share')"><svg-icon icon-class="click-to-copy" class-name="g-icon--small g-clickable" /></button>
            <SocialSharePopover
              v-if="isShareOpen"
              :class="isDesktopPlatform && tab != 'expand-recommend-item' ? 'social-share-popover' : 'social-share-popover-mobile'"
              :assetId="itemId"
              :routeInfo="getRouteInfo()"
              :router="$router"
              fromPage="Universal card"
              size="lg"
              @dismiss-share-action-model="popoverOff('share')"
            />
            <span v-if="!isShareOpen && isDesktopPlatform" class="share-tooltip-text">Share</span>
          </div>
          <div class="more-container" :class="{ 'more-carousal-container': cardType == 'carousal' }" v-if="showMoreActionPopover()">
            <button class="more-action" :aria-label="item.title + ' more'" @click="popoverOn('more')"><svg-icon icon-class="more-action" class-name="g-icon--small g-clickable" /></button>
            <MoreActionPopover
              v-if="isMoreOpen"
              :class="isDesktopPlatform && tab != 'expand-recommend-item' ? 'more-popover' : 'more-popover-mobile'"
              :item="item"
              :assetId="item.id"
              :assetType="item.format.name"
              :routeInfo="getRouteInfo()"
              :router="$router"
              :showRemoveFromTrack="$route.path.includes('ugtrack')"
              :showAddToTrack="showAddToTrack && !['track'].includes(this.item.format.name.toLowerCase()) && !$route.path.includes('ugtrack')"
              :showDelete="{}.hasOwnProperty.call(item, 'scope') && item.scope.isExternal && item.progress != 100"
              size="lg"
              fromPage="Universal card"
              @remove-from-track="popoverOff('more', { onClickRemoveOption: true })"
              @add-to-track="popoverOff('more', { onClickTrackOption: true })"
              @edit-delete-external-content="deleteExternalContent"
              @close-more="popoverOff('more')"
            />
            <span v-if="!isMoreOpen" class="more-tooltip-text">More</span>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <div v-if="showConfirmDelete">
      <div class="a-overlay" @click="onConfirmDelete(false)" style="position: fixed; z-index: 1100"></div>
      <div class="b-modal confirm-box">
        <img src="@/assets/images/close.svg" class="g-icon--small g-margin__right--msmall close-icon" @click="onConfirmDelete(false)" />
        <div class="confirm-box-header">Are you sure you want to delete?</div>
        <p>Deletion is permanent and can't be undone.</p>
        <div class="handler">
          <ion-button data-cy="confirm-progress" class="g-button--primary ion-button-primary delete-btn" color="primary" @click="onConfirmDelete(true)">Delete</ion-button>
          <a href="#" @click="onConfirmDelete(false)">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import Bookmark from '@/components/molecules/Bookmark'
import { Plugins } from '@capacitor/core'
import moment from 'moment'
import RadialProgressBar from 'vue-radial-progress'
import MoreActionPopover from '@/components/molecules/MoreActionPopover'
import SocialSharePopover from '@/components/molecules/SocialSharePopover'
import UGTrackOverlayOptions from '@/views/collection/components/UGTrackOverlayOptions'
import { popoverController } from '@modus/ionic-vue'
const { Browser } = Plugins

export default {
  name: 'UniversalCard',
  components: { Bookmark, RadialProgressBar, MoreActionPopover, SocialSharePopover },
  props: {
    itemsLength: {
      type: Number,
      required: true,
      default: () => {
        return false
      }
    },
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      required: true,
      default: () => {
        return 0
      }
    },
    tab: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    isExpandCardAllow: {
      type: Boolean,
      required: false,
      default: () => {
        return true
      }
    },
    isUCTClickAllowed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    cardType: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      authToken: '',
      dataReady: false,
      isDesktopPlatform: true,
      isAdd: false,
      defaultImage: '',
      isTrackListOpen: false,
      isShareOpen: false,
      isMoreOpen: false,
      typeofPopover: '',
      showConfirmDelete: false,
      showAddToTrack: false,
      isSignedRelativeUriAvailable: false,
      isTitleEllipsesActive: false
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'sso_token', 'sso_bouncer', 'user_access'])
  },
  async mounted() {
    document.getElementById('contentlayout').addEventListener('click', this.closeTrackList, true)
    await this.setupItem()
  },
  filters: {
    formatProviderName(s) {
      return s ? (s.toUpperCase() === 'PWC' ? s.replace('PWC', 'PwC') : s) : ''
    }
  },
  methods: {
    showMoreActionPopover() {
      let showPopover = true
      if ({}.hasOwnProperty.call(this.item, 'scope') && this.item.scope.isExternal) showPopover = !['track', 'asset'].includes(this.item.format.name.toLowerCase())
      else showPopover = this.showAddToTrack && !['track', 'asset'].includes(this.item.format.name.toLowerCase())
      return showPopover
    },
    closeTrackList(event) {
      if (
        event.target &&
        event.target._prevClass &&
        event.target._prevClass != 'trackLength' &&
        !event.target._prevClass.includes('track-length') &&
        !event.target._prevClass.includes('tooltip-bottom-text')
      )
        this.popoverOff('track')
    },
    getAssetType: (item) => ('assetType' in item ? item.assetType : 'format' in item && item.format.name === 'asset' ? 'automation' : 'artifacts'),
    getRouteInfo() {
      if ({}.hasOwnProperty.call(this.item, 'scope') && this.item.scope.isExternal) {
        return { name: 'ExternalContent', params: { id: this.item.guid, pgrTrk: false } }
      } else {
        if (['article', 'video', 'audio', 'podcast'].includes(this.item.format.name.toLowerCase())) {
          return { name: 'Content', params: { id: this.item.id, title: this.item.title } }
        } else if (['asset'].includes(this.item.format.name.toLowerCase())) {
          return { name: 'Asset', params: { id: this.item.id, asset: this.item } }
        } else if (['course'].includes(this.item.format.name.toLowerCase())) {
          return { name: 'Course', params: { id: this.item.id, pgrTrk: false } }
        } else if (['track'].includes(this.item.format.name.toLowerCase())) {
          return { name: 'Collection', params: { id: this.item.guid } }
        } else {
          return { name: 'Credential', params: { id: this.item.id, pgrTrk: false } }
        }
      }
    },
    async setupItem() {
      await localforage.removeItem('deep_link_id')
      await localforage.removeItem('deep_link_type')
      this.dataReady = false
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.itemId = this.item.id
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      this.showAddToTrack = await localforage.getItem('show-add-to-track')
      this.defaultImage = this.getDefaultImage()
      this.isSignedRelativeUriAvailable =
        {}.hasOwnProperty.call(this.item, 'meta') &&
        {}.hasOwnProperty.call(this.item.meta, 'cdnEnabled') &&
        this.item.meta.cdnEnabled &&
        {}.hasOwnProperty.call(this.item, 'image') &&
        {}.hasOwnProperty.call(this.item.image, 'signedRelativeUri')
      this.dataReady = true
    },
    async onClick() {
      if (this.tab === 'recommended') {
        this.$emit('recommended-item-click')
      }
      if (this.tab === 'myLearning') {
        this.$emit('learn-item-click')
      }
      if (this.tab === 'expand-recommend-item') {
        this.$emit('close-expand-item-popover')
      }
      this.$router.push(this.getRouteInfo())
      await this.onPrepareGAPayload()
    },
    async onLaunch() {
      if (this.item.format.name.toLowerCase() === 'asset') {
        await this.onDownload()
      } else if (['course'].includes(this.item.format.name.toLowerCase())) {
        const ssoToken = this.sso_token || (await localforage.getItem('my_sso_token'))
        const ssoBouncer = this.sso_bouncer || (await localforage.getItem('my_sso_bouncer'))
        const courseUrl = `${ssoBouncer}?redirect_url=${this.item.content.contentUri}&SSO_tk=${ssoToken}`
        const url = this.item.content && this.item.content.isSelfHosted ? await this.$learnAdmin.fetchCourseUrl({ token: this.authToken, id: this.item.id }) : courseUrl
        await Browser.open({ url: url })
        if (this.item.selfProgressDeclaration) await this.getProgress()
        this.setupClickStreamProgressTracker()
      } else {
        if (this.tab === 'recommended') {
          this.$emit('recommended-item-click')
        }
        if (this.tab === 'myLearning') {
          this.$emit('learn-item-click')
        }
        const isExternal = {}.hasOwnProperty.call(this.item, 'scope') && {}.hasOwnProperty.call(this.item.scope, 'isExternal') && this.item.scope.isExternal
        isExternal ? this.onExternalContentLink() : this.$router.push(this.getRouteInfo())
        await this.onPrepareGAPayload()
      }
    },
    async onExternalContentLink() {
      window.open(this.item.content.contentUri, '_blank')
      await this.getProgress()
    },
    async getProgress() {
      this.dataReady = false
      if (this.item.progress !== 100) {
        const payload = {
          guid: this.item.guid || '',
          id: this.item.id || '',
          token: this.authToken,
          body: {
            completedDate: null,
            isCompleted: false,
            progress: 50,
            startedDate: new Date()
          }
        }
        const response = this.item.source == 'EXTERNAL' ? await this.$learnAdmin.updateExternalContentProgress(payload) : await this.$learnAdmin.updateContentProgressById(payload)
        this.item.progress = response.progress
        this.item.launchBtn = this.item.progress > 0 && this.item.progress < 100 ? 'Resume' : 'Launch'
      }
      this.dataReady = true
    },
    async onDownload() {
      if (this.item.assetReference && this.item.assetReference.externalAssetLink) {
        await Browser.open({ url: this.item.assetReference.externalAssetLink })
      }
      const file = await this.$learnAdmin.downloadAsset({ token: this.authToken, id: this.item.id })
      if (file) {
        file.filename = file.filename.length ? file.filename : `${this.item.id}.zip`
        const a = document.createElement('a')
        a.href = URL.createObjectURL(file.blob)
        a.setAttribute('download', file.filename)
        a.click()
      }
      const payload = await this.onPrepareGAPayload('Asset downloaded')
      await this.GAEventTrack(payload)
    },
    onClickTrack(track) {
      this.isTrackListOpen = false
      if (track.source == 'PLAN') {
        this.$router.push({ name: 'Collection', params: { id: track.guid } })
      } else {
        this.$router.push({ name: 'UGTrack', params: { id: track.guid } })
      }
    },
    expandCard() {
      this.tab === 'recommended' && this.isExpandCardAllow && this.isDesktopPlatform ? this.$emit('expand-card') : null
    },
    onClickMore() {
      this.isMoreOpen = true
    },
    popoverOn(popoverType) {
      if (this.isTrackListOpen) this.popoverOff('track')
      else this.isTrackListOpen = popoverType === 'track'
      this.typeofPopover = popoverType
      this.isShareOpen = popoverType === 'share'
      this.isMoreOpen = popoverType === 'more'
    },
    async popoverOff(popoverType, options) {
      this.isShareOpen = !popoverType === 'share'
      this.isMoreOpen = !popoverType === 'more'
      this.isTrackListOpen = !popoverType === 'track'
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      options && options.onClickRemoveOption ? await this.deleteTrack() : null
      options && options.onClickTrackOption ? this.$emit('popover-off') : null
    },
    async deleteTrack() {
      let modal = await popoverController.create({
        component: UGTrackOverlayOptions,
        cssClass: 'tracks-modal-option',
        mode: 'md',
        arrow: false,
        showBackdrop: true,
        backdropDismiss: true,
        componentProps: {
          parent: this,
          propsData: {
            options: {
              title: 'Are you sure you want to remove this item from your track?',
              button1: 'Cancel',
              button2: 'Remove'
            }
          }
        }
      })
      modal.onDidDismiss().then((option) => {
        if (option.data == 'Remove') {
          this.$eventBus.$emit(`remove-from-track`, this.item)
        }
      })
      await modal.present()
    },
    deleteExternalContent() {
      this.isMoreOpen = false
      this.showConfirmDelete = true
    },
    async onConfirmDelete(isDelete) {
      if (isDelete) {
        const payload = {
          guid: this.item.guid,
          token: this.authToken,
          body: {
            matchedCount: 1,
            modifiedCount: 1,
            upsortedId: null
          }
        }
        const response = await this.$learnAdmin.deleteExternalContent(payload)
        const { error } = response
        if (error) {
          this.showConfirmDelete = false
        } else {
          this.showToast('Item successfully deleted', 'success')
          this.showConfirmDelete = false
          this.$emit('delete-item')
        }
      } else {
        this.showConfirmDelete = false
      }
    },
    showToast(msg, className) {
      this.$toasted.show(msg, {
        containerClass: 'toast-container',
        className: className,
        position: this.isDesktopPlatform ? 'bottom-center' : 'mobile-container',
        iconPack: 'icon-container',
        duration: 5000,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    getDefaultImage() {
      if (['article', 'video', 'audio', 'podcast', 'course', 'track'].includes(this.item.format?.name.toLowerCase())) {
        return require(`@/assets/images/${this.item.format.name.toLowerCase()}.png`)
      } else if (this.item.format?.name.toLowerCase() == 'credential') {
        return require(`@/assets/images/thumbnail-complete-credential.png`)
      }
    },
    setDuration() {
      return this.item.duration < 3600 ? `${Math.round(this.item.duration / 60)} min` : `${Math.round(this.item.duration / 3600)} hrs`
    },
    formatDate(type) {
      let returnDate = ''
      let date = type === 'publishedDate' ? this.item.publishedAt : this.item.requiredBy
      if (date) {
        const expiryDateTitle = this.item.isDateExpired ? 'Past Due' : 'Due'
        date = new Date(date)
        date = date.toDateString().split(' ')
        const requiredDate = `${date[1]} ${date[2]} ${date[3]}`
        returnDate = type === 'notitle' ? `${requiredDate}` : type === 'publishedDate' ? `${date[1]} ${date[3]}` : `${expiryDateTitle} ${requiredDate}`
        return returnDate
      }
    },
    getDateFormat(dateTo) {
      return moment(dateTo).format('MMM DD YYYY')
    },
    mobileFormatdate() {
      return this.item.progress ? (this.item.isDateExpired ? 'Past Due' : 'Due') : ''
    },
    getEllipsesActive(e) {
      const documentQuerySelector = document.querySelectorAll('.title')
      documentQuerySelector.forEach((element) => {
        if (element.innerText === e.target.innerText) {
          this.isTitleEllipsesActive = element.offsetHeight < element.scrollHeight
        }
      })
    },
    getImageClass() {
      let className = 'item-img-container item-img-container-hide ion-no-padding'
      switch (this.tab) {
        case 'track':
          className = 'item-img-container-track-list item-img-container-hide ion-no-padding'
          break
        case 'recommended':
          className = 'item-container-recommend item-container-recommend-image-height ion-no-padding'
          break
        case 'relatedCourse':
          className = 'item-img-container-related-course'
          break
        case 'expand-recommend-item':
          className = 'item-container-recommend ion-no-padding'
          break
        default:
          break
      }
      return className
    },
    getItemInfoClass() {
      return this.tab != 'recommended' && this.tab != 'relatedCourse' && this.tab != 'expand-recommend-item'
        ? this.index === this.itemsLength - 1
          ? 'last-item-info'
          : 'item-info'
        : this.tab === 'relatedCourse'
        ? 'related-course-info'
        : ''
    },
    getColInfoClass() {
      return this.tab != 'expand-recommend-item' && this.tab != 'recommended' && this.tab != 'relatedCourse'
        ? 'ion-no-padding ion-col-info-container'
        : 'ion-no-padding item-container-recommend'
    },
    getImageBackgroundClass() {
      let className = 'item-img-background'
      switch (this.tab) {
        case 'expand-recommend-item':
          className = 'item-img-background item-img-background-expand-item'
          break
        case 'recommended':
          className = 'item-img-background item-img-background-margin'
          break
        default:
          break
      }
      return className
    },
    getProviderMaxWidth() {
      if (this.tab === 'relatedCourse') return 'max-width: 143px;'
      else if (
        this.item.completedAt ||
        (this.item.meta && this.item.meta.completedAt) ||
        {}.hasOwnProperty.call(this.item, 'dueDate') ||
        {}.hasOwnProperty.call(this.item, 'pastDueDate')
      ) {
        return 'max-width: 120px;'
      } else return ''
    },
    getDueDate(type) {
      if (this.tab === 'recommended' || this.tab === 'relatedCourse') {
        return this.item.progress === 0 ? type : ''
      } else {
        return type === 'Due' ? `${type} ${this.item.dueDate}` : `${type} ${this.item.pastDueDate}`
      }
    },
    setupClickStreamProgressTracker() {
      const learningRecord = {
        verb: {
          id: window.location.href,
          display: {
            'en-US': `opened-course`
          }
        },
        object: {
          id: window.location.href,
          definition: {
            name: {
              'en-US': this.item.title
            }
          }
        },
        result: {
          completion: false,
          success: false,
          score: {
            scaled: 0
          }
        }
      }
      learningAnalytics.setLearningRecordAndSend(learningRecord)
    },
    async onPrepareGAPayload() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const contentId = this.item.id
      const contentType = this.item.format.name
      const category = this.currentPageView
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'content-id': contentId,
        'content-type': contentType
      }
      const payloadItem = {
        action: `${this.tab} Item Click`,
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payloadItem)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-info {
  padding: 3rem 0;
  border-bottom: 2px solid #e0e1e1;
}

.dueDate {
  font-family: 'Arial';
  font-size: 14px;
  color: #2d2d2d;
  position: relative;
  top: -3px;
}

.last-item-info {
  padding: 3rem 0;
  border-bottom: unset;
}

.item-img-container {
  flex: 0 0 calc(calc(1.3 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(1.3 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(1.3 / var(--ion-grid-columns, 12)) * 100%);
  min-width: 148px;
}

.item-img-container-track-list {
  flex: 0 0 calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
  min-width: 148px;
}

.item-container-recommend {
  flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
}

.item-container-recommend-image-height {
  max-height: 88px;
  margin-bottom: 1rem;
}

.item-img-container-related-course {
  display: none;
}

.ion-col-info-container {
  flex: 0 0 calc(calc(7 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%);
}

.item-img-background {
  position: relative;
  max-width: 88px;
  width: 88px;
  height: 88px;
  overflow: hidden;
  border-radius: 20px;
  border: 2px solid #e0e1e1;
  img {
    height: 88px;
    width: 88px;
    object-fit: cover;
  }
}

.item-img-background-margin {
  margin-bottom: 1rem;
}

.item-action-col-margin {
  margin-top: 1rem;
}

.item-img-background-expand-item {
  max-width: 188px;
  width: 188px;
  height: 188px;
  margin-bottom: 1rem;
  img {
    height: 188px;
    width: 188px;
    object-fit: cover;
  }
}

.track-overlay {
  position: absolute;
  top: -15px;
  z-index: 1;
  left: 37%;
  .svg-icon {
    width: 40px;
    height: 40px;
  }
}

.meta {
  display: flex;
  letter-spacing: 1.2px;
  gap: 24px;
  align-items: center;
  .category {
    display: flex;
    gap: 8px;
    text-transform: uppercase;
  }
  .format {
    border-radius: 10px;
    padding: 4px 6px;
    font-family: 'Arial';
    font-weight: bold;
    font-size: 14px;
    max-height: 22px;
    background: #e0e1e1;
    color: #2d2d2d;
    text-transform: capitalize;
  }
  .g-button--primary {
    width: 7rem;
    margin-right: 1rem;
    text-transform: capitalize;
  }
  .provider {
    font-family: 'Arial';
    font-size: 14px;
    align-self: center;
    text-align: left;
    font-weight: bold;
    color: #2d2d2d;
    text-transform: capitalize;
    display: flex;

    .provider-name {
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font: normal normal bold 14px/16px Arial;
    }
    .svg-icon {
      height: 14px !important;
      cursor: pointer;
    }
  }

  .font-color-black {
    color: #000000;
  }

  .provider-width {
    max-width: 108px;
  }
  .progress-data {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
  }

  .progress {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
    span {
      font-family: 'Arial';
      font-size: 14px;
      font-weight: normal;
      color: #2d2d2d;
    }
    .progress-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      width: 240px;
    }
  }

  .progress:hover .progress-tooltip-text {
    visibility: visible;
  }

  .required-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    .required-title {
      font-family: Arial;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 1.2px;
      color: #2d2d2d;
      opacity: 1;
    }
    .svg-icon {
      width: 24px !important;
      height: 27px !important;
    }
    .required-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
    }
  }
  .required-container:hover .required-tooltip-text {
    visibility: visible;
  }
}

.meta-gap {
  gap: 16px;
}

.item-about-container {
  margin-top: 2.5rem;
}

.title {
  margin: 1rem 0;
  text-align: left;
  a {
    font-size: 30px;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-dark-blue);
    cursor: pointer;
    text-decoration: none;
    outline: none;
    &:hover {
      text-decoration: underline;
      .title-tooltip-text {
        visibility: visible;
      }
    }
    &:focus {
      text-decoration: underline;
    }
    .title-tooltip-text {
      visibility: hidden;
      text-align: left;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      bottom: 108px;
      color: #000000de;
      left: 0;
    }
    .title-tooltip-text-bottom {
      bottom: 93px;
    }
  }
}

.meta-info {
  display: flex;
  gap: 24px;
  font-size: 14px;
  align-items: center;
  .rating {
    font-family: Arial;
    padding: 0 5px 0 0;
    color: #2d2d2d;
  }
  .duration,
  .level,
  .publish-date,
  .asset-type {
    font-family: Arial;
    font-size: 14px;
    color: #2d2d2d;
  }
  .only-you {
    display: flex;
    gap: 8px;
    font-family: Arial;
    font-size: 14px;
    color: #2d2d2d;
    align-items: center;
    .svg-icon {
      width: 17px !important;
      height: 12px !important;
    }
  }
  .applications {
    position: relative;
    display: flex;
    gap: 8px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.track-meta {
  display: flex;
  margin-top: 24px;
  gap: 1rem;
  align-items: center;
  .track-title {
    font-size: 14px;
    font-family: 'Arial';
    color: #2d2d2d;
    a {
      color: #1f3370;
      font-size: 14px;
      font-family: 'Arial';
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .track-length {
    width: 40px;
    height: 20px;
    background: #e0e1e1;
    border-radius: 23px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: #feb92e;
    }
    span {
      opacity: 1;
      font-size: 14px;
      font-family: 'Arial';
      font-weight: bold;
      color: #000000;
    }
  }

  .track-length-active {
    background: #feb92e;
  }
}

.ion-col-action {
  text-align: right;
}

.meta-action {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  .item-title-hidden {
    display: none;
  }
  .item-bookmark,
  .more-action,
  .social-share {
    width: 40px;
    height: 40px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .svg-icon {
      width: 1.5rem;
    }
  }
  .bookmark-container,
  .more-container,
  .share-container {
    position: relative;
    .bookmark-tooltip-text,
    .more-tooltip-text,
    .share-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      right: 0;
    }
  }

  .bookmark-container {
    background: none;
  }

  .bookmark-container:hover .bookmark-tooltip-text {
    visibility: visible;
  }
  .share-container:hover .share-tooltip-text {
    visibility: visible;
  }
  .more-container:hover .more-tooltip-text {
    visibility: visible;
  }
}

.meta-action-justify-content {
  justify-content: flex-start;
  margin-top: 1rem;
}

.launch-btn {
  font-size: 16px;
  min-width: 135px;
  max-height: 40px;
  margin-bottom: 2rem;
  margin-top: unset;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #e0e1e1;
  --ion-color-primary-contrast: black;
  --box-shadow: unset;
  &:hover {
    --ion-color-primary: #feb92e;
  }
  ion-icon {
    width: 20px;
    height: 20px;
  }
}

.track-list,
.track-list-mobile,
.social-share-popover,
.more-popover,
.more-popover-mobile,
.social-share-popover-mobile {
  position: absolute;
  box-shadow: 0px 0px 12px #0000001f;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px;
  z-index: 10;
  opacity: 0;
  margin-top: 10px;
  animation-name: slidein;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  max-height: 200px;
}

@media screen and (min-width: 750px) {
  .more-popover-mobile {
    right: 0px;
  }
}

.track-list-item {
  padding: 8px;
  text-align: left;
  font-family: 'Arial';
  font-size: 14px;
  color: #1f3370;
  opacity: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.track-list-scrollbar {
  overflow-y: scroll;
  display: block;
  max-height: 170px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 8px;
  }

  // scrollbar track
  &::-webkit-scrollbar-track-piece {
    background: #bcc2d4;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}

.share-list-left-align {
  left: 0;
}

.more-carousal-container {
  .more-popover,
  .more-popover-mobile {
    animation-name: slidecarousalmore !important;
  }
}

.more-popover,
.social-share-popover,
.more-popover-mobile,
.social-share-popover-mobile {
  text-align: left;
  animation-name: slideinmore;
}

.more-popover-mobile,
.social-share-popover-mobile {
  animation-name: slideinmoremobile;
}

.track-list-mobile {
  animation-name: slideinmobile;
}

@keyframes slidein {
  from {
    font-size: 0px;
    width: 0;
    left: 140px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -100px;
  }
}

@keyframes slideinmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -214px;
  }
}

@keyframes slidecarousalmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: 0;
  }
}

@keyframes slideinmobile {
  from {
    font-size: 0px;
    width: 0;
    left: 10%;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: 10%;
  }
}
@keyframes slideinmoremobile {
  from {
    width: 0;
    font-size: 0px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: unset;
  }
}

.chain-link,
.green-checkmark {
  width: 25px !important;
  height: 25px !important;
}

.confirm-box {
  border-radius: 20px;
  width: 420px;
  height: 242px;
  padding: 32px;

  .confirm-box-header {
    width: 264px;
    height: 61px;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-family: 'Futura PT Demi';
    text-align: left;
    color: var(--ion-color-black);
  }

  p {
    width: 356px;
    height: 17px;
    margin-top: 0;
    font-size: 16px;
    color: #5c5c5c;
    margin-bottom: 26px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .handler {
    justify-content: flex-start;

    a {
      margin-left: 2em;
      font-size: 16px;
      font-family: 'Futura PT Demi';
    }

    .delete-btn {
      color: var(--ion-color-black);
      font-size: 16px;
      font-family: 'Futura PT Demi';
    }
  }

  .close-icon {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.green-checkmark {
  margin-left: 8px;
}

@media screen and (max-width: 1920px) {
  .carouselMobile {
    display: none !important;
  }
}

@media screen and (max-width: 1477px) {
  .track-overlay {
    left: 45%;
  }
}

@media screen and (max-width: 1366px) {
  .track-overlay {
    left: 48%;
  }
}

@media screen and (max-width: 1024px) {
  .carouselDesktop {
    display: none !important;
  }
  .carouselMobile {
    display: block !important;
  }
}

@media screen and (max-width: 820px) {
  .related-course-info {
    width: 347px;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .meta {
    .provider {
      .provider-name {
        max-width: 88px;
      }
    }
  }
  .title {
    span {
      font-size: 24px;
    }
  }
  .item-img-container-hide {
    display: none;
  }
  .ion-col-info-container {
    flex: 0 0 calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
  }
  .confirm-box {
    width: 100% !important;
  }
}

@media screen and (max-width: 484px) {
  .title {
    font-size: 1.5rem;
  }
  .item-img-container {
    margin-bottom: 1rem;
  }
  .item-img-container-hide {
    display: none;
  }
  .meta-info {
    margin-bottom: 24px;
    .item-rating {
      display: inline;
    }
  }
  .meta {
    .provider {
      .provider-name {
        max-width: 88px;
      }
    }
  }
  .track-meta {
    display: none;
  }
  .meta-action {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: -72px;
  }
  .meta-action-recommend {
    margin-top: unset;
  }
  .ion-col-info-container {
    flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
  }
  .item-container-recommend {
    height: 160px;
  }
  .share-list {
    left: 0;
  }
  .main-backdrop {
    top: unset;
  }
  @keyframes slidein {
    from {
      font-size: 0px;
      width: 0;
      left: 10%;
    }
    to {
      width: 256px;
      opacity: 1;
      font-size: 14px;
      left: 10%;
    }
  }
  @keyframes slideinmore {
    from {
      width: 0;
      font-size: 0px;
    }
    to {
      width: 256px;
      opacity: 1;
      font-size: 14px;
      left: unset;
    }
  }
}

@media screen and (max-width: 375px) {
  .meta-info {
    gap: 5px;
  }
  .meta {
    .provider {
      .provider-name {
        max-width: 75px;
      }
    }
  }
}
</style>
