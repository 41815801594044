var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isSideFilterActive
      ? _c("div", { on: { click: _vm.openSideFilter } })
      : _vm._e(),
    _c("div", { staticClass: "filter-container" }, [
      _c(
        "div",
        { staticClass: "filter-list" },
        [
          _c("div", { staticClass: "filter-title" }, [_vm._v("Filter")]),
          _vm._l(_vm.filters.slice(0, 5), function(filter) {
            return _c("div", { key: filter.name }, [
              _c(
                "div",
                {
                  key: filter.name,
                  class:
                    filter.optionsVisible || filter.isApply
                      ? "filter-type filter-type-selected"
                      : "filter-type",
                  on: {
                    click: function($event) {
                      return _vm.onFilterType(filter)
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(filter.name) + " "),
                  _c("svg-icon", {
                    attrs: { "icon-class": "Icon-awesome-chevron-down" }
                  })
                ],
                1
              ),
              filter.optionsVisible
                ? _c("div", {
                    staticClass: "filter-container-backdrop",
                    on: {
                      click: function($event) {
                        return _vm.onCancel(filter)
                      }
                    }
                  })
                : _vm._e(),
              filter.optionsVisible
                ? _c(
                    "div",
                    { staticClass: "filter-option-container" },
                    [
                      filter.options.length > 6
                        ? _c("ion-searchbar", {
                            ref: "searchbar",
                            refInFor: true,
                            attrs: {
                              "search-icon": "search-outline",
                              placeholder: "Search " + filter.name,
                              value: _vm.searchFilter
                            },
                            on: {
                              ionInput: function($event) {
                                return _vm.handleSearchInput($event, filter)
                              },
                              ionClear: function($event) {
                                return _vm.handleClearInput(filter)
                              }
                            }
                          })
                        : _vm._e(),
                      filter.name != "Found in"
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                class:
                                  filter.options.length <= 6
                                    ? "filter-option-content"
                                    : "filter-option-content filter-option-content-scroll"
                              },
                              _vm._l(filter.options, function(option) {
                                return _c(
                                  "div",
                                  {
                                    key: option.name + option.count,
                                    style: option.style
                                      ? option.style
                                      : "display: flex"
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("ion-checkbox", {
                                          staticClass: "filter-checkbox",
                                          attrs: {
                                            checked: option.selected,
                                            value: option.name,
                                            color: "tertiary",
                                            mode: "md",
                                            disabled:
                                              option.count <= 0 ||
                                              (option.name === "Content type" &&
                                                !option.userAccess)
                                          },
                                          on: {
                                            ionChange: function($event) {
                                              return _vm.checkSelections(
                                                $event,
                                                filter
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        class:
                                          option.count <= 0 ||
                                          (option.name === "Content type" &&
                                            !option.userAccess)
                                            ? "filter-option-title-disable"
                                            : "filter-option-title"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.filterName(
                                                option,
                                                filter.name
                                              )
                                            ) +
                                            " (" +
                                            _vm._s(option.count) +
                                            ") "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        : _c(
                            "div",
                            [
                              _c(
                                "ion-radio-group",
                                {
                                  key: _vm.selectedTrack,
                                  attrs: { value: _vm.selectedTrack },
                                  on: {
                                    ionChange: function($event) {
                                      return _vm.checkSelectionsRadio(
                                        $event,
                                        filter
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        filter.options.length <= 6
                                          ? "filter-option-content"
                                          : "filter-option-content filter-option-content-scroll"
                                    },
                                    _vm._l(filter.options, function(option) {
                                      return _c(
                                        "div",
                                        {
                                          key:
                                            option.name +
                                            option.count +
                                            _vm.selectedTrack,
                                          style: option.style
                                            ? option.style
                                            : "display: flex"
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("ion-radio", {
                                                key: _vm.selectedTrack,
                                                staticClass: "filter-radio",
                                                attrs: {
                                                  slot: "start",
                                                  value: option.name,
                                                  color: "tertiary",
                                                  mode: "md"
                                                },
                                                slot: "start"
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              class:
                                                option.count <= 0 ||
                                                (option.name ===
                                                  "Content type" &&
                                                  !option.userAccess)
                                                  ? "filter-option-title-disable"
                                                  : "filter-option-title"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.filterName(
                                                      option,
                                                      filter.name
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                      _c(
                        "div",
                        { staticClass: "filter-option-footer" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "filter-option-footer-cancel",
                              on: {
                                click: function($event) {
                                  return _vm.onCancel(filter)
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "ion-button",
                            {
                              staticClass: "filter-option-footer-btn-apply",
                              attrs: { shape: "round", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.onShow(filter)
                                }
                              }
                            },
                            [_vm._v("Apply")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          }),
          _c(
            "div",
            { staticClass: "filter-type", on: { click: _vm.openSideFilter } },
            [
              _vm._v(" All filters "),
              _c("svg-icon", {
                staticStyle: { "--transform": "rotate(180deg)" },
                attrs: { "icon-class": "icon-filter" }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "all-filter", on: { click: _vm.openSideFilter } },
        [
          _vm._v(" All filters "),
          _c("svg-icon", {
            staticStyle: { "--transform": "rotate(180deg)" },
            attrs: { "icon-class": "icon-filter" }
          })
        ],
        1
      ),
      _vm.sortOptionVisible
        ? _c("div", {
            staticClass: "sort-container-backdrop",
            on: { click: _vm.onCancelSort }
          })
        : _vm._e(),
      _vm.showSort
        ? _c("div", { staticClass: "sort-container" }, [
            _c("div", { staticClass: "sort-title" }, [_vm._v("Sort by")]),
            _c(
              "div",
              { staticClass: "sort-type", on: { click: _vm.onSort } },
              [
                _vm._v(" " + _vm._s(_vm.selectedSort) + " "),
                _c("svg-icon", {
                  attrs: { "icon-class": "Icon-awesome-chevron-down" }
                })
              ],
              1
            ),
            _vm.sortOptionVisible
              ? _c(
                  "div",
                  { staticClass: "sort-option-container" },
                  [
                    _c(
                      "ion-radio-group",
                      {
                        staticClass: "sort-option-content",
                        attrs: { value: _vm.selectedSort },
                        on: {
                          ionChange: function($event) {
                            return _vm.handleRadioSelect($event)
                          }
                        }
                      },
                      _vm._l(_vm.sortOptions, function(option) {
                        return _c(
                          "div",
                          { key: option.name, staticClass: "sort-option" },
                          [
                            option.enabled
                              ? _c("ion-radio", {
                                  attrs: {
                                    slot: "start",
                                    color: "tertiary",
                                    value: option.name,
                                    mode: "md"
                                  },
                                  slot: "start"
                                })
                              : _vm._e(),
                            option.enabled
                              ? _c(
                                  "div",
                                  { staticClass: "sort-option-title" },
                                  [_vm._v(_vm._s(option.name))]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "sort-option-footer" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sort-option-footer-cancel",
                            on: { click: _vm.onCancelSort }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "ion-button",
                          {
                            staticClass: "sort-option-footer-btn-apply",
                            attrs: { shape: "round", color: "primary" },
                            on: { click: _vm.onApplySort }
                          },
                          [_vm._v("Apply")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.showAdd
        ? _c(
            "div",
            { staticClass: "action-btns" },
            [
              _c(
                "ion-button",
                {
                  staticClass: "learning-plan-btn",
                  attrs: {
                    shape: "round",
                    color: "primary",
                    title: "Add external learning"
                  },
                  on: { click: _vm.openExternalSidebar }
                },
                [
                  _c(
                    "div",
                    { staticClass: "add-icon-container" },
                    [
                      _c("svg-icon", {
                        staticStyle: {
                          height: "10px",
                          width: "10px",
                          position: "relative",
                          top: "-0.1em",
                          left: "-0.1em"
                        },
                        attrs: { "icon-class": "add-icon" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" Add ")
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _vm.selected.length
      ? _c(
          "div",
          { staticClass: "filter-selected-container" },
          [
            _c("div", { staticClass: "filter-selected-title" }, [
              _vm._v("Selected")
            ]),
            _vm._l(_vm.selected, function(filter) {
              return _c("div", { key: filter.name }, [
                filter.type != "Found in"
                  ? _c(
                      "div",
                      { staticClass: "filter-selected" },
                      [
                        _vm._v(" " + _vm._s(_vm.filterName(filter)) + " "),
                        _c("svg-icon", {
                          attrs: { "icon-class": "icon-remove-filter" },
                          on: {
                            click: function($event) {
                              return _vm.removeFilter(filter)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "filter-selected" },
                      [
                        _vm._v(
                          ' Items found in "' +
                            _vm._s(_vm.filterName(filter)) +
                            '" '
                        ),
                        _c("svg-icon", {
                          attrs: { "icon-class": "icon-remove-filter" },
                          on: {
                            click: function($event) {
                              return _vm.removeFilter(filter)
                            }
                          }
                        })
                      ],
                      1
                    )
              ])
            }),
            _c(
              "div",
              {
                staticClass: "filter-selected-clear-all",
                on: { click: _vm.onClearFilters }
              },
              [_vm._v("Clear all filters")]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }