<template>
  <nav class="edge-menu">
    <ion-row class="ion-align-items-center" style="margin-top: 16px">
      <ion-col class="ion-no-padding">
        <svg-icon icon-class="proedge-mobile-logo" style="height: 18px; width: 40px" />
      </ion-col>
      <ion-col class="ion-no-padding ion-text-end">
        <button @click="onCloseMenu" class="sidebar-close" aria-label="close side menu">
          <svg-icon icon-class="close-icon" class-name="close-button" style="height: 16px; width: 16px" />
        </button>
      </ion-col>
    </ion-row>
    <div v-for="item in navBarMenu.links" :key="item.name">
      <div class="primary-links-container" v-if="item.subLinks.length == 0">
        <router-link @click.native="onCloseMenu" :to="item.link" class="primary-link">{{ item.name }}</router-link>
      </div>
      <div v-else>
        <div class="secondary-links-container">
          <div class="sub-menu-nav-item">
            <a @click="toggleSubmenu()" v-track="`nav,${item.name},click`" class="first-level-link primary-link"
              ><span class="link-name">{{ item.name }}</span></a
            >
            <svg-icon @click="toggleSubmenu()" icon-class="Icon-awesome-chevron-down" :class="subMenuVisible ? 'icon-rotated' : 'icon-normal'" />
          </div>
          <ul class="sub-menu" v-if="subMenuVisible">
            <template v-for="sublink in item.subLinks" >
              <li :key="sublink.name" :class="{ 'selected-link': subLink === sublink.link && $route.path == item.link }" v-if="sublink.show">
                <a @click="navigateTo(sublink.link, item.name)" class-active="active " class="second-level-link">{{ sublink.name }}</a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="utility-links-container">
      <div v-if="tenantAsset.visible" class="utility-link" @click="onSubmitAsset">
        {{ tenantAsset.action }}
        <svg-icon v-if="tenantAsset.submissionLink.length" icon-class="expand-out-icon" class="asset-icon" />
      </div>
      <div class="utility-link" @click="onGetHelp">Help Center</div>
      <div class="utility-link logout" @click="onLogout">Logout</div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import localforage from 'localforage'
import { Plugins } from '@capacitor/core'
const { Browser } = Plugins

export default {
  name: 'NavBarMenuMobile',
  props: {
    navBarMenu: {
      type: Object,
      required: true,
      default: () => {
        return {
          links: []
        }
      }
    }
  },
  components: {},
  data() {
    return {
      authToken: '',
      tenantAsset: {
        available: false,
        enabled: false,
        action: '', // Submit an asset | Manage my submissions
        submissionLink: '',
        visible: false,
        userAccessAsset: false
      },
      subMenuVisible: false,
      subLink: ''
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  created() {},
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.subLink = await localforage.getItem('profile-side-nav-changed')
    await this.determineUserAccess()
  },
  methods: {
    ...mapActions('user', ['logout']),
    async determineUserAccess() {
      const tenantInfo = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(tenantInfo.settings)
      const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
      this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
      this.tenantAsset.available = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
      this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'tenant-assets-submission-enabled') && settings['tenant-assets-submission-enabled']
      this.tenantAsset.submissionLink = {}.hasOwnProperty.call(settings, 'apply-asset-upload-link') ? settings['apply-asset-upload-link'] : ''
      this.tenantAsset.action = this.tenantAsset.enabled && this.tenantAsset.submissionLink.length ? 'Submit an asset' : 'Manage my submissions'
      this.tenantAsset.visible = this.tenantAsset.available && this.tenantAsset.userAccessAsset && this.tenantAsset.enabled
    },
    onCloseMenu() {
      this.$emit('close')
    },
    toggleSubmenu() {
      this.subMenuVisible = !this.subMenuVisible
    },
    async onSubmitAsset() {
      this.onCloseMenu()
      if (this.tenantAsset.action === 'Submit an asset') {
        await Browser.open({ url: this.tenantAsset.submissionLink })
      } else if (this.tenantAsset.action === 'Manage my submissions') {
        this.$router.push('/submit-asset')
      }
    },
    navigateTo(link, itemName) {
      if (itemName === 'My profile') {
        if (this.$route.path !== '/profile')
          this.$router.push({ name: 'Profile', params: { id: link } })
        else
          this.$eventBus.$emit('profile-sub-link-clicked', link)
        this.setSubLink(link)
      } else this.$router.push({ path: link })
      this.onCloseMenu()
    },
    async setSubLink(e) {
      this.subLink = e
      await localforage.setItem('profile-side-nav-changed', e)
    },
    onGetHelp() {
      this.onCloseMenu()
      if (this.$route.path !== '/help-center') {
        this.$router.push('/help-center')
      }
    },
    async onLogout() {
      await localforage.setItem('logoutFromSSO', document.cookie.includes('ProAuthSSO='))
      await this.$learnAdmin.logout({ token: this.authToken })
      await this.logout()
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('edge-menu') {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: map-get($edge-colors, 'white');
  padding: 0 16px;
  // box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
  top: 0;
  text-align: left;
  backdrop-filter: blur(20px);
  opacity: 0.98;

  @include element('list') {
    position: relative;
    border-bottom: 2px solid map-get($edge-colors, 'border-dark');
    list-style: none;
    margin-bottom: 0;
    margin-top: 26px;
    margin-left: 0.8rem;

    li {
      font-size: 1.5rem;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 26px;
      }
    }

    a {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-decoration: none;
      color: map-get($edge-colors, 'black');
    }
  }

  @include element('logo') {
    text-align: left;
    padding-top: 0px;
    padding-left: 2rem;
    border-bottom: 2px solid map-get($edge-colors, 'border-dark');
    cursor: pointer;
    svg.svg-icon.logo--size {
      position: relative;
      margin: 14px 0 14px 6px;
      height: 30px;
      width: 116px;
      padding-left: 3px;
    }
    svg.svg-icon.hamburger--msmall {
      position: relative;
      margin: 0 0.34em 1.4em -1.1em;
      width: 0.8em;
      height: 0.8em;
    }
  }
  ul {
    padding: 0 !important;
  }
}

.logout {
  color: var(--ion-color-tertiary);
  font-weight: bold;
}

.second-level-link {
  margin-left: -0.1rem;
  font-size: 1rem;
  text-decoration: none;
  color: var(--ion-color-tertiary);
  font-family: 'Arial' !important;
  display: block;
}

.sidebar-close {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  margin: 0px 0px;
}
.primary-links-container,
.secondary-links-container {
  margin: 3.5rem 0rem 0rem 3rem;
}

.primary-link {
  display: block;
  font-family: 'Arial';
  color: var(--ion-color-tertiary);
  font-size: 1.5rem;
  text-decoration: none;
  margin-bottom: 1.1rem;
}

.utility-links-container {
  margin: 3rem;
}

.utility-link {
  display: block;
  font-family: 'Arial';
  color: var(--ion-color-tertiary);
  font-size: 1.2rem;
  text-decoration: none;
  margin-bottom: 1.1rem;
}

.asset-icon {
  height: 1.2rem !important;
  width: 1.2rem !important;
  margin-left: 0.5rem;
}

.sub-menu-nav-item {
  display: flex;
  align-items: center;
  a span {
    font: normal normal normal 18px/24px Arial;
  }
  svg {
    width: 13px;
    height: 0.5rem;
    cursor: pointer;
    margin-left: 1rem;
    margin-bottom: 0.75rem;
    &.icon-normal {
      transform: rotate(0deg);
    }
    &.icon-rotated {
      transform: rotate(180deg);
    }
  }
}
.selected-link {
  border-left: 6px solid var(--ion-color-secondary);
}
.sub-menu {
  padding: 0;
  li {
    list-style: none;
    padding-left: 2rem;
    margin-bottom: 1.5rem;
    :hover {
      background: unset;
      cursor: pointer;
    }
    a:hover {
      text-decoration: underline;
      font: normal normal normal 16px/18px Arial;
    }
  }
}
</style>
