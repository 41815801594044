var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-container", on: { click: _vm.dismissModal } },
    [
      _c("svg-icon", {
        staticClass: "g-icon--small g-clickable close-icon",
        attrs: { "icon-class": "filter-close" },
        on: { click: _vm.dismissModal }
      }),
      _vm._m(0),
      _c("div", { staticClass: "modal-content" }, [
        _c("p", [
          _vm._v(
            "You crushed your goal of " +
              _vm._s(_vm.goalDays) +
              " active days in " +
              _vm._s(_vm.currentMonth) +
              ". Here's to your achievement and ongoing success! "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/svgs/icon-success.svg"),
          alt: "icon-success"
        }
      }),
      _c("h2", { staticClass: "title" }, [_vm._v("Congratulations!")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }