var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.skillGroup.length, staticClass: "skill-container" },
    [
      _vm._l(_vm.skillGroup, function(skill, index) {
        return _c(
          "div",
          {
            key: index + skill.name,
            class: [
              skill.recentlyAdded ? "skill-button recent" : "skill-button",
              _vm.displaySkillDetails && _vm.from !== "external-form"
                ? "pointer"
                : ""
            ],
            on: {
              click: function($event) {
                return _vm.searchWithSkill(skill)
              }
            }
          },
          [
            _vm._v(" " + _vm._s(skill.name) + " "),
            _vm.from === "external-form"
              ? _c("svg-icon", {
                  staticClass: "remove-skill-icon g-clickable",
                  attrs: { "icon-class": "icon-remove-xs" },
                  on: {
                    click: function($event) {
                      return _vm.onDeleteSkill(skill)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _vm.skillGroup.length > 0 &&
      _vm.from !== "external-form" &&
      _vm.from !== "welcome-page"
        ? _c("div", { staticClass: "skill-button manage-skills-btn" }, [
            _c("a", { on: { click: _vm.openManageSkillsModal } }, [
              _vm._v("Manage skills")
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }