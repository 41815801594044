<template>
  <div :class="isDesktopPlatform ? 'container-desktop' : 'container-mobile'">
    <ion-spinner :style="isDesktopPlatform ? 'margin-top: 2rem;' : 'margin-top: 1rem;'" v-if="!dataReady" color="#1f3370"></ion-spinner>
    <div v-if="dataReady && items.length">
      <div class="home-item-container">
        <UniversalCard
          v-for="(item, index) in items"
          :key="item.id"
          :item="item"
          :index="index"
          :itemsLength="items.length"
          @delete-item="deleteExternalItem"
          tab="HomeInProgress"
        />
      </div>
      <div v-if="data.totalPages > 0" class="inprogress-pagination">
        <paginate
          v-model="currentPage"
          :page-count="data.totalPages"
          :page-range="3"
          :margin-pages="2"
          :click-handler="onPageClick"
          :container-class="'pagination'"
          :page-class="'pagination-item'"
          :page-link-class="'pagination-item-link'"
          :prev-text="'&lsaquo;'"
          :prev-class="'pagination-nav'"
          :prev-link-class="'pagination-nav-link'"
          :next-text="'&rsaquo;'"
          :next-class="'pagination-nav'"
          :next-link-class="'pagination-nav-link'"
          :hide-prev-next="true"
        />
      </div>
    </div>
    <div
      v-if="dataReady && !items.length"
      :style="isDesktopPlatform ? 'margin-top: 2rem;' : 'margin-top: 1rem;'"
      :class="isDesktopPlatform ? 'home-item-container inprogress-empty-container' : 'inprogress-empty-container'"
    >
      <div class="inprogress-empty-container-grid">
        <div>
          <div class="title">Take the next step toward your learning goals.</div>
          <div class="sub-title">
            Check out your <span class="link-btn g-clickable" @click="goToLearningPlan">learning plan</span> to get started or discover what's next. Or visit the
            <span class="link-btn g-clickable" @click="goToExplore"> Explore </span> section to find more content that interests you.
          </div>
          <div class="action-btns">
            <ion-button shape="round" color="primary" class="learning-plan-btn" @click="goToLearningPlan">Learning Plan</ion-button>
            <ion-button shape="round" color="primary" class="explore-btn" @click="goToExplore">Explore</ion-button>
          </div>
        </div>
      </div>
      <div class="inprogress-empty-image">
        <img src="@/assets/images/home-inprogress-empty.png" />
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/helper'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import UniversalCard from '@/components/organisms/UniversalCard'

export default {
  name: 'HomeInProgress',
  components: { UniversalCard },
  props: {},
  data() {
    return {
      authToken: '',
      data: {
        learning: {},
        external: {},
        totalPages: 0
      },
      items: [],
      currentPage: 1,
      isExternalContentEnabled: false,
      isDesktopPlatform: true,
      dataReady: false
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async mounted() {
    this.currentPage = 1
    await this.getHomeInprogressData()
  },
  async activated() {
    this.currentPage = 1
    await this.getHomeInprogressData()
  },
  methods: {
    async getHomeInprogressData() {
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
      const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(tenant.settings)
      const externalTenantSetting = {}.hasOwnProperty.call(settings, 'add-external-content-enabled') && settings['add-external-content-enabled']
      this.isExternalContentEnabled = externalTenantSetting
      await this.getMyLearningPlanItems(this.currentPage)
      await this.setupPagination(true)
    },
    async getMyLearningPlanItems(page) {
      this.dataReady = false
      this.items = []
      let learningItems = []
      const contentSource = this.isExternalContentEnabled ? [] : ['INTERNAL']
      const learningPayload = {
        token: this.authToken,
        body: {
          pageNo: page,
          includeCourses: true,
          includeCredentials: true,
          includeRwl: true,
          includeSelfLearned: true,
          contentType: [],
          providers: [],
          creditHours: [],
          creditType: [],
          accreditation: [],
          level: [],
          function: [],
          tracks: [],
          completedAt: {},
          includeItemsWithNoDueDate: true,
          includeCompleted: false,
          includeNoProgress: false,
          includeInProgress: true,
          sortBy: "LPI_UPDATED_AT",
          sortByDirection: "DESC",
          trackSource: [],
          contentSource: contentSource,
          pageSize: 4
        }
      }
      this.data.learning = await this.$learnAdmin.getMyLearningPlanItemsWithFilters(learningPayload)
      let ratingDetailsArray = []
      if (this.data && (this.data.learning)) {
        ratingDetailsArray = (await this.determineContentRating(this.data.learning)) || []
      }
      this.data.learning.content.forEach((item, index) => {
        if (item.progress > 0 && item.source == 'INTERNAL') {
          item.id = item.artifactId
          item.scope = { isLearning: true, isExternal: false }
          item.image = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.image : ''
          item.meta = {}.hasOwnProperty.call(item, 'learningItem') && {}.hasOwnProperty.call(item.learningItem, 'meta') ? { ...item.meta, ...item.learningItem.meta } : item.meta
          item.assetType = item.format.name.toLowerCase() === 'credential' ? 'credentials' : item.format.name.toLowerCase() === 'automation' ? 'automation' : 'artifacts'
          item.numComments = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numComments : 0
          item.numLikes = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numLikes : 0
          item.provider = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.provider : item.provider
          item.duration = !{}.hasOwnProperty.call(item, 'learningItem')
            ? null
            : item.learningItem.duration < 3600
            ? `${Math.round(item.learningItem.duration / 60)} min`
            : `${Math.round(item.learningItem.duration / 3600)} hrs`
          item.progressApproximation = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.progressApproximation : null
          item.launchBtn =
            item.assetType != 'automation'
              ? item.progress > 0 && item.progress < 100
                ? 'Resume'
                : 'Launch'
              : item.learningItem.author.toLowerCase() != 'proedge'
              ? 'Open'
              : 'Download'
          item.scope = { isExternal: item.assetType === 'automation' && item.learningItem.author.toLowerCase() != 'proedge' }
          item.publishedAt = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'publishedAt') ? item.learningItem.publishedAt : '') : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'requiredBy') ? item.learningItem.requiredBy : '') : ''
          item.content = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'content') ? item.learningItem.content : '') : ''
          item.rating =
            ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.id)
              ? ratingDetailsArray.filter((rating) => rating.entityId == item.id)[0]?.avgRating
              : 0
          item.selfProgressDeclaration = {}.hasOwnProperty.call(item, 'learningItem')
            ? {}.hasOwnProperty.call(item.learningItem, 'selfProgressDeclaration')
              ? item.learningItem.selfProgressDeclaration
              : false
            : false
          item.level = {}.hasOwnProperty.call(item, 'learningItem')
            ? item.format.name.toLowerCase() === 'credential'
              ? {}.hasOwnProperty.call(item.learningItem, 'level')
                ? item.learningItem.level
                : ''
              : {}.hasOwnProperty.call(item.learningItem, 'courseLevels')
              ? item.learningItem.courseLevels[0]
              : ''
            : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          learningItems[index] = item
        } else if (item.progress > 0 && item.source == 'EXTERNAL') {
          item.id = item.learningItem.id
          item.guid = item.externalArtifactGuid
          item.scope = { isLearning: false, isExternal: true }
          item.image = ''
          item.assetType = 'artifacts'
          item.numComments = item.learningItem.numComments
          item.numLikes = item.learningItem.numLikes
          item.provider = { name: item.learningItem.source }
          item.emsiSkills = []
          item.synopsis = util.encodeDecodeString(item.learningItem.synopsis)
          item.duration = !{}.hasOwnProperty.call(item, 'learningItem')
            ? null
            : parseInt(item.learningItem.duration) < 3600
            ? `${Math.round(parseInt(item.learningItem.duration) / 60)} min`
            : `${Math.round(parseInt(item.learningItem.duration) / 3600)} hrs`
          item.progressApproximation = null
          item.format = item.learningItem.format
          item.provider = { name: {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'source') ? item.learningItem.source : '') : '' }
          item.title = util.encodeDecodeString(item.learningItem.title)
          item.launchBtn = 'Open'
          item.publishedAt = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'publishedAt') ? item.learningItem.publishedAt : '') : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'requiredBy') ? item.learningItem.requiredBy : '') : ''
          item.content = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'content') ? item.learningItem.content : '') : ''
          item.rating =
            ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.guid)
              ? ratingDetailsArray.filter((rating) => rating.entityId == item.guid)[0]?.avgRating
              : 0

          item.selfProgressDeclaration = {}.hasOwnProperty.call(item, 'learningItem')
            ? {}.hasOwnProperty.call(item.learningItem, 'selfProgressDeclaration')
              ? item.learningItem.selfProgressDeclaration
              : false
            : false
          item.level = {}.hasOwnProperty.call(item, 'learningItem')
            ? item.format.name.toLowerCase() === 'credential'
              ? {}.hasOwnProperty.call(item.learningItem, 'level')
                ? item.learningItem.level
                : ''
              : {}.hasOwnProperty.call(item.learningItem, 'courseLevels')
              ? item.learningItem.courseLevels[0]
              : ''
            : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          learningItems[index] = item
        }
      })
      this.items = [...learningItems]
      this.data.totalPages = this.data.learning.totalPages
      this.dataReady = true
    },
    determineContentRating(learningContentValue, externalLearningContentValue) {
      var contentIdList = []
      learningContentValue?.content.map((item) => {
        contentIdList.push(item.artifactId)
      })
      externalLearningContentValue?.content.map((value) => {
        contentIdList.push(value.externalArtifactGuid)
      })
      if (contentIdList && contentIdList.length > 0) {
        const contentPayload = {
          token: this.authToken,
          body: contentIdList
        }
        return this.$learnAdmin.getContentRatingById(contentPayload)
      }
    },
    async onPageClick(page) {
      this.$emit('scroll-to-top')
      this.currentPage = page
      await this.setupPagination(false)
      await this.getMyLearningPlanItems(this.currentPage)
      await this.setupPagination(true)
    },
    deleteExternalItem() {
      this.getHomeInprogressData()
    },
    goToLearningPlan() {
      this.$router.push({ name: 'MyLearning', params: { type: 'required', isNotFromDeepLink: true } }).catch(() => {})
    },
    goToExplore() {
      this.$router.push({ name: 'Explore' }).catch(() => {})
    },
    async setupPagination(isAddEvent = true) {
      if (this.items.length) {
        const elements = document.getElementsByClassName('pagination')[0].childNodes
        if (elements) {
          await elements.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              isAddEvent ? element.addEventListener('click', this.onPaginationItemClick, { once: true }) : element.removeEventListener('click', this.onPaginationItemClick)
            }
          })
        }
      }
    },
    onPaginationItemClick(e) {
      let page = this.currentPage
      if (e.target.textContent != '…') {
        page = e.target.textContent == '‹' ? page - 1 : e.target.textContent == '›' ? page + 1 : parseInt(e.target.textContent)
        this.onPageClick(page)
      }
    }
  }
}
</script>

<style lang="scss">
.container-mobile .inprogress-pagination span {
  font-size: 14px;
  padding: 0px 2px;
}

.inprogress-pagination {
  border-top: 2px solid var(--ion-color-light-gray);
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.pagination-item {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

li.pagination-nav {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.pagination-item-link {
  color: var(--ion-color-post-gray);
  font-size: 14px !important;
  vertical-align: middle;
}

.pagination-nav-link {
  position: relative;
  color: #5c5c5c;
  font-size: 3rem !important;
  vertical-align: middle;
  font-family: 'Futura PT Book';
}

li.disabled {
  border: none;
  cursor: default;
  .pagination-item-link {
    cursor: default;
  }
}

li.active {
  border: 2px solid #1dd1b0;
  border-radius: 4px;
  background: white;
  .pagination-item-link {
    color: #000000;
  }
}

li:hover {
  background: #e0e1e1;
}

li .tooltiptext {
  visibility: hidden;
  width: max-content;
  height: 30px;
  background-color: #efefef;
  color: #000000de;
  text-align: center;
  padding: 7px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -20px;
  font-size: 12px;
  font-family: 'Futura PT Demi';
}

.li:hover .tooltiptext {
  visibility: visible;
}
</style>

<style lang="scss" scoped>
.inprogress-empty-container {
  display: flex;
  align-items: center;
}

.inprogress-empty-container-grid {
  display: grid;
  width: 60%;

  div {
    text-align: left;
  }
}

.title {
  text-align: left;
  font-size: 3rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-tertiary);
}

.sub-title {
  margin-top: 1rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Arial';
  color: var(--ion-color-medium);
}

.link-btn {
  font-size: 1rem;
  font-family: 'Arial';
  text-decoration: none !important;
  color: var(--ion-color-tertiary);
}

.action-btns {
  display: flex;
  margin-top: 1.5rem;
  align-content: left;
}

.learning-plan-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  margin-right: 1rem;
  --ion-color-primary-contrast: var(--ion-color-tertiary);
}

.explore-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: var(--ion-color-tertiary);
  --ion-color-primary-contrast: var(--ion-color-white);
}

.inprogress-empty-image {
  width: 40%;
  img {
    width: auto;
  }
}

ion-button {
  --box-shadow: none;
}

@media screen and (max-width: 1024px) {
  .inprogress-empty-container-grid {
    width: 75%;
  }

  .inprogress-empty-container {
    padding-top: 3rem !important;
  }

  .title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .inprogress-empty-container-grid {
    width: 85%;
  }
}

@media screen and (max-width: 414px) {
  .inprogress-empty-container {
    display: flex;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .inprogress-empty-container-grid {
    width: 100%;
    gap: 2rem;
    margin: 0rem 0.5rem;
  }

  .inprogress-empty-image {
    display: none;
  }
}
</style>
