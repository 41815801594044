// eslint-disable-next-line
import Vue from 'vue'
import localforage from 'localforage'
import axios from 'axios'
import store from '@/store'
// eslint-disable-next-line
const appDetails = require('../../package.json')

class LearningAnalytics {
  constructor() {
    this.learningLockerURI = process.env.VUE_APP_LEARNING_LOCKER_API_BASE_URI
    this.actor = {}
  }

  setActor(name, email) {
    this.actor.name = name
    this.actor.mbox = `mailto:${email}`
  }

  async setActorFromState() {
    this.actor.name = await localforage.getItem('my_name')
    const email = await localforage.getItem('my_username')
    this.actor.mbox = `mailto:${email}`
    return this.actor
  }

  setLearningRecordAndSend = async learningRecord => {
    if (store.learning_locker_enabled) {
      if (this.learningLockerURI !== undefined) this.learningLockerURI = process.env.VUE_APP_LEARNING_LOCKER_API_BASE_URI
      const actor = {}.hasOwnProperty.call(this.actor, 'name') ? this.actor : await this.setActorFromState()

      const learningRecordObject = {
        actor: actor,
        verb: learningRecord.verb,
        object: learningRecord.object,
        result: learningRecord.result
      }

      const headers = { 'Content-Type': 'application/json' }
      const response = await axios.post(`${this.learningLockerURI}clickstream/sendLearnClicks`, learningRecordObject, { headers: headers }).catch(error => {
        return { data: error.response.data }
      })
      return response.data
    }
  }

  install(Vue) {
    Vue.learningAnalytics = learningAnalytics
    window.learningAnalytics = learningAnalytics

    Object.defineProperties(Vue.prototype, {
      learningAnalytics: {
        get() {
          return learningAnalytics
        }
      },
      $learningAnalytics: {
        get() {
          return $learningAnalytics
        }
      }
    })
  }
}

const learningAnalytics = new LearningAnalytics()

export default learningAnalytics
