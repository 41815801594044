var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "track-skeleton" }, [
    _c(
      "div",
      { style: _vm.isDesktopPlatform ? "padding-left: 150px;" : "" },
      [
        _c(
          "ion-grid",
          [
            _c(
              "ion-row",
              [
                _c(
                  "ion-col",
                  {
                    staticClass: "collection-about",
                    attrs: { "size-xl": "8", "size-xs": "12" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "track-item" },
                      [
                        _c(
                          "ion-grid",
                          [
                            _c(
                              "ion-row",
                              { staticClass: "ion-no-padding" },
                              [
                                _c(
                                  "ion-col",
                                  {
                                    staticStyle: {
                                      "max-width": "120px !important",
                                      "min-width": "120px !important"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "track-img" },
                                      [
                                        _c("ion-skeleton-text", {
                                          attrs: { animated: "" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "ion-col",
                                  {
                                    staticClass: "content ion-padding-end",
                                    attrs: { size: "8.5" }
                                  },
                                  [
                                    _c("div", { staticClass: "meta-content" }, [
                                      _c("div", { staticClass: "meta-data" }, [
                                        _c(
                                          "div",
                                          { staticClass: "format" },
                                          [
                                            _c("ion-skeleton-text", {
                                              attrs: { animated: "" }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "provider" },
                                          [
                                            _c("ion-skeleton-text", {
                                              attrs: { animated: "" }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "title" },
                                        [
                                          _c("ion-skeleton-text", {
                                            attrs: { animated: "" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "meta-icon" }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              gap: "0.4rem"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "track-rating" },
                                              [
                                                _c("ion-skeleton-text", {
                                                  attrs: { animated: "" }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "track-duration" },
                                              [
                                                _c("ion-skeleton-text", {
                                                  attrs: { animated: "" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meta-icon",
                                          staticStyle: {
                                            "'display": "flex",
                                            "margin-top": "20px"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "track-bookmark" },
                                            [
                                              _c("ion-skeleton-text", {
                                                attrs: { animated: "" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        class: _vm.isDesktopPlatform ? "container-desktop" : "container-mobile"
      },
      [
        _c(
          "ion-grid",
          [
            _c(
              "ion-row",
              [
                _c(
                  "ion-col",
                  {
                    staticClass: "collection-about",
                    attrs: { "size-xl": "8", "size-xs": "12" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "track-description" },
                      [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                      1
                    ),
                    _c("div", { staticClass: "track-title" }, [
                      _c(
                        "div",
                        { staticClass: "title" },
                        [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "title" },
                        [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                        1
                      )
                    ]),
                    _vm._l(5, function(i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "track-item" },
                        [
                          _c(
                            "ion-grid",
                            [
                              _c(
                                "ion-row",
                                { staticClass: "ion-no-padding" },
                                [
                                  _c(
                                    "ion-col",
                                    {
                                      staticStyle: {
                                        "max-width": "120px !important",
                                        "min-width": "120px !important"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "track-img" },
                                        [
                                          _c("ion-skeleton-text", {
                                            attrs: { animated: "" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "ion-col",
                                    {
                                      staticClass: "content ion-padding-end",
                                      attrs: { size: "8.5", "size-xs": "7" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "meta-content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "meta-data" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "format" },
                                                [
                                                  _c("ion-skeleton-text", {
                                                    attrs: { animated: "" }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "provider" },
                                                [
                                                  _c("ion-skeleton-text", {
                                                    attrs: { animated: "" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "title" },
                                            [
                                              _c("ion-skeleton-text", {
                                                attrs: { animated: "" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "meta-icon" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    gap: "0.4rem"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "track-bookmark"
                                                    },
                                                    [
                                                      _c("ion-skeleton-text", {
                                                        attrs: { animated: "" }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "track-bookmark"
                                                    },
                                                    [
                                                      _c("ion-skeleton-text", {
                                                        attrs: { animated: "" }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }