import analytics from "../plugins/analytics"

export default {
  bind(el, binding) {
    el.addEventListener('click', () => {
      const { value } = binding
      let trackObj = value
      if (typeof value === 'string') {
        const values = value.replace(' ', '').split(',')
        trackObj = { c: values[0], l: values[1], a: values[2] }
      }
      analytics.setVarAndTrack(trackObj)
    })
  }
}
