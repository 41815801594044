// eslint-disable-next-line
import Vue from 'vue'
import FeatureFlag from '@/components/molecules/FeatureFlag'
import LearnAdmin from '@/api/learnAdmin'

const learnAdmin = new LearnAdmin()

const unleashPlugin = {
  FeatureFlag,
  async install(Vue, options) {
    const { host, store, strategyProviders } = options
    if (!host) throw new Error('Please initialize feature flag with an Unleash host.')
    if (!store) throw new Error('Please initialize feature flag with a Vuex store.')
    Vue.config.applicationHostname = 'localhost'
    Vue.component('feature-flag', FeatureFlag)
    const response = await learnAdmin.getFeatureFlags()
    if (response && response.features) {
      await store.dispatch('flag/setFeatureFlags', { featureFlags: response.features, strategyProviders: strategyProviders })
    }
  }
}

export default unleashPlugin

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(unleashPlugin)
}