var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dataReady && _vm.item
        ? _c(
            "ion-row",
            {
              staticClass: "ion-no-padding",
              class: _vm.getItemInfoClass(),
              on: {
                mouseenter: function($event) {
                  return _vm.expandCard()
                }
              }
            },
            [
              _c("ion-col", { class: _vm.getImageClass() }, [
                _c("div", { class: _vm.getImageBackgroundClass() }, [
                  _vm.isSignedRelativeUriAvailable
                    ? _c("img", {
                        attrs: { src: _vm.item.image.signedRelativeUri }
                      })
                    : {}.hasOwnProperty.call(_vm.item, "image") &&
                      {}.hasOwnProperty.call(_vm.item.image, "uri")
                    ? _c("img", { attrs: { src: _vm.item.image.uri } })
                    : _c("img", { attrs: { src: _vm.defaultImage } })
                ]),
                _vm.item.format.name.toLowerCase() === "track"
                  ? _c(
                      "div",
                      { staticClass: "track-overlay" },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "track-overlay-icon" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("ion-col", { class: _vm.getColInfoClass() }, [
                _c(
                  "div",
                  {
                    class:
                      _vm.tab != "recommended" && _vm.tab != "relatedCourse"
                        ? "meta"
                        : "meta meta-gap"
                  },
                  [
                    _c("div", { staticClass: "category" }, [
                      _c(
                        "div",
                        {
                          class:
                            _vm.tab != "recommended"
                              ? "format"
                              : "format font-color-black"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.item.format && _vm.item.format.name) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          class:
                            _vm.tab != "recommended"
                              ? "provider"
                              : "provider provider-width font-color-black"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "provider-name",
                              style: _vm.getProviderMaxWidth()
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatProviderName")(
                                    _vm.item.provider && _vm.item.provider.name
                                  )
                                )
                              )
                            ]
                          ),
                          {}.hasOwnProperty.call(_vm.item, "scope") &&
                          _vm.item.scope.isExternal
                            ? _c(
                                "span",
                                {
                                  attrs: {
                                    title: "External content added by you"
                                  }
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "g-icon--small provider-link",
                                    attrs: { "icon-class": "icon-link" },
                                    on: { click: _vm.onExternalContentLink }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    {}.hasOwnProperty.call(_vm.item, "dueDate") &&
                    _vm.item.progress != 100
                      ? _c(
                          "div",
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "due-date",
                                "class-name": "g-icon--small"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "dueDate" }, [
                              _vm._v(_vm._s(_vm.getDueDate("Due")))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    {}.hasOwnProperty.call(_vm.item, "pastDueDate") &&
                    _vm.item.progress != 100
                      ? _c(
                          "div",
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "past-due",
                                "class-name": "g-icon--small"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "dueDate" }, [
                              _vm._v(_vm._s(_vm.getDueDate("Past Due")))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.item.requiredBy && _vm.item.progress != 100
                      ? _c(
                          "div",
                          { staticClass: "required-container" },
                          [
                            !_vm.item.isDateExpired
                              ? _c("svg-icon", {
                                  attrs: { "icon-class": "status-due" }
                                })
                              : _c("svg-icon", {
                                  attrs: { "icon-class": "status-red" }
                                }),
                            _c("div", { staticClass: "required-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isDesktopPlatform
                                    ? _vm.formatDate("requiredDate")
                                    : _vm.mobileFormatdate()
                                )
                              )
                            ]),
                            _c(
                              "span",
                              { staticClass: "required-tooltip-text" },
                              [
                                _vm._v(
                                  "Required by " +
                                    _vm._s(_vm.formatDate("notitle"))
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.item.progress
                      ? _c(
                          "div",
                          {
                            staticClass: "progress-data",
                            staticStyle: { "margin-right": "5px" }
                          },
                          [
                            _vm.item.progress > 0 && _vm.item.progress < 100
                              ? _c(
                                  "div",
                                  { staticClass: "progress" },
                                  [
                                    !_vm.item.selfProgressDeclaration &&
                                    (!{}.hasOwnProperty.call(
                                      _vm.item,
                                      "scope"
                                    ) ||
                                      !_vm.item.scope.isExternal)
                                      ? _c("radial-progress-bar", {
                                          attrs: {
                                            diameter: 28,
                                            "completed-steps":
                                              _vm.item.progress,
                                            "total-steps":
                                              _vm.item.progress == "99"
                                                ? 110
                                                : 100,
                                            startColor: "#000000",
                                            stopColor: "#000000",
                                            innerStrokeColor: "#e0e1e1",
                                            strokeWidth: 4,
                                            innerStrokeWidth: 5
                                          }
                                        })
                                      : _c("svg-icon", {
                                          attrs: { "icon-class": "progress" }
                                        }),
                                    !_vm.item.selfProgressDeclaration &&
                                    (!{}.hasOwnProperty.call(
                                      _vm.item,
                                      "scope"
                                    ) ||
                                      !_vm.item.scope.isExternal)
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.item.progress) + "%"
                                          )
                                        ])
                                      : _c("span", [_vm._v("In progress")]),
                                    _vm.item.provider &&
                                    _vm.item.provider
                                      .timeToProgressSyncInHours > 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "progress-tooltip-text"
                                          },
                                          [
                                            _vm._v(
                                              "Progress updates every " +
                                                _vm._s(
                                                  _vm.item.provider
                                                    .timeToProgressSyncInHours
                                                ) +
                                                " hours"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm.item.completed || _vm.item.progress == 100
                              ? _c(
                                  "div",
                                  { staticClass: "progress" },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        "icon-class": "icon-progress-complete"
                                      }
                                    }),
                                    _vm.$platform === "Desktop"
                                      ? _c("span", [_vm._v("Completed")])
                                      : _vm._e(),
                                    _vm.item.provider &&
                                    _vm.item.provider
                                      .timeToProgressSyncInHours > 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "progress-tooltip-text"
                                          },
                                          [
                                            _vm._v(
                                              "Progress updates every " +
                                                _vm._s(
                                                  _vm.item.provider
                                                    .timeToProgressSyncInHours
                                                ) +
                                                " hours"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            (_vm.item.completedAt ||
                              (_vm.item.meta && _vm.item.meta.completedAt)) &&
                            _vm.tab != "relatedCourse"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "3px",
                                      "font-family": "Arial"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDateFormat(
                                          _vm.item.completedAt ||
                                            _vm.item.meta.completedAt
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.tab != "expand-recommend-item"
                        ? "title ellipsis-lines-2"
                        : "title ellipsis-lines-3",
                    style: _vm.tab === "relatedCourse" ? "max-width: 347px" : ""
                  },
                  [
                    _c(
                      "a",
                      {
                        style:
                          _vm.tab != "recommended" &&
                          _vm.tab != "relatedCourse" &&
                          _vm.tab != "track"
                            ? ""
                            : "font-size: 24px;",
                        attrs: { href: "#" },
                        on: {
                          click: _vm.onClick,
                          mouseenter: function($event) {
                            _vm.tab === "recommended" ||
                            _vm.tab === "relatedCourse"
                              ? _vm.getEllipsesActive($event)
                              : ""
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.item.title) + " "),
                        !_vm.$isTablet &&
                        _vm.isDesktopPlatform &&
                        _vm.isTitleEllipsesActive
                          ? _c(
                              "span",
                              {
                                class:
                                  _vm.tab === "relatedCourse"
                                    ? "title-tooltip-text title-tooltip-text-bottom"
                                    : "title-tooltip-text"
                              },
                              [_vm._v(_vm._s(_vm.item.title))]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm.tab != "relatedCourse"
                  ? _c("div", { staticClass: "meta-info" }, [
                      _vm.tab != "recommended" && _vm.item.rating > 0
                        ? _c(
                            "div",
                            { staticClass: "item-rating" },
                            [
                              _c("span", { staticClass: "rating" }, [
                                _vm._v(_vm._s(_vm.item.rating))
                              ]),
                              _c("svg-icon", {
                                attrs: { "icon-class": "star-filled-desktop" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.item.duration &&
                      _vm.item.format.name.toLowerCase() !== "asset"
                        ? _c("div", { staticClass: "duration" }, [
                            _vm._v(_vm._s(_vm.item.duration))
                          ])
                        : _vm._e(),
                      _vm.tab != "recommended" && _vm.item.level
                        ? _c("div", { staticClass: "level" }, [
                            _vm._v(_vm._s(_vm.item.level))
                          ])
                        : _vm._e(),
                      _vm.tab != "recommended" && _vm.item.publishedAt
                        ? _c("div", { staticClass: "publish-date" }, [
                            _vm._v(_vm._s(_vm.formatDate("publishedDate")))
                          ])
                        : _vm._e(),
                      {}.hasOwnProperty.call(_vm.item, "scope") &&
                      _vm.item.scope.isExternal
                        ? _c(
                            "div",
                            { staticClass: "only-you" },
                            [
                              _c("svg-icon", {
                                staticClass: "g-icon--small",
                                attrs: { "icon-class": "icon-eye" }
                              }),
                              _vm._v("Only you")
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.item.format.name.toLowerCase() === "asset"
                        ? _c("div", { staticClass: "asset-type" }, [
                            _vm._v(_vm._s(_vm.item.type && _vm.item.type.name))
                          ])
                        : _vm._e(),
                      _vm.tab != "recommended" &&
                      _vm.item.format.name.toLowerCase() === "asset"
                        ? _c(
                            "div",
                            { staticClass: "applications" },
                            _vm._l(_vm.item.applications, function(
                              application,
                              index
                            ) {
                              return _c("img", {
                                key: application.src + index,
                                attrs: {
                                  src: application.src.replace(
                                    ".svg",
                                    "-bw.svg"
                                  ),
                                  title: application.name
                                }
                              })
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.isDesktopPlatform &&
                _vm.tab != "recommended" &&
                _vm.tab != "relatedCourse" &&
                _vm.item.format.name.toLowerCase() != "track" &&
                _vm.item.tracks.length
                  ? _c("div", { staticClass: "track-meta" }, [
                      _c("div", { staticClass: "track-title" }, [
                        _vm._v(" Track: "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.onClickTrack(_vm.item.tracks[0])
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.item.tracks[0].name))]
                        )
                      ]),
                      _vm.item.tracks.length > 1
                        ? _c(
                            "div",
                            {
                              class: _vm.isTrackListOpen
                                ? "track-length track-length-active"
                                : "track-length",
                              on: {
                                click: function($event) {
                                  return _vm.popoverOn("track")
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "trackLength" }, [
                                _vm._v(
                                  "+ " + _vm._s(_vm.item.tracks.length - 1)
                                )
                              ]),
                              _vm.isTrackListOpen
                                ? _c(
                                    "div",
                                    {
                                      class:
                                        _vm.isDesktopPlatform &&
                                        _vm.tab != "expand-recommend-item"
                                          ? "track-list"
                                          : "track-list-mobile"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.item.tracks.length > 6
                                              ? "track-list-scrollbar"
                                              : ""
                                        },
                                        _vm._l(
                                          _vm.item.tracks.slice(1),
                                          function(track, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "track-list-item",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickTrack(
                                                      track
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(track.name))]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
              _vm.tab != "relatedCourse"
                ? _c(
                    "ion-col",
                    {
                      class:
                        _vm.tab != "expand-recommend-item"
                          ? "ion-col-action ion-no-padding"
                          : "ion-no-padding item-container-recommend item-action-col-margin"
                    },
                    [
                      _vm.tab != "expand-recommend-item" &&
                      _vm.tab != "recommended"
                        ? _c(
                            "ion-button",
                            {
                              staticClass: "launch-btn",
                              attrs: { shape: "round", color: "primary" },
                              on: { click: _vm.onLaunch }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.item.launchBtn) + " "),
                              _vm.item.launchBtn.toLowerCase() === "open" ||
                              ({}.hasOwnProperty.call(_vm.item, "scope") &&
                                _vm.item.scope.isExternal)
                                ? _c("ion-icon", {
                                    attrs: {
                                      slot: "end",
                                      name: "open-outline"
                                    },
                                    slot: "end"
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class:
                            _vm.tab != "expand-recommend-item" &&
                            _vm.tab != "recommended"
                              ? "meta-action"
                              : "meta-action meta-action-recommend meta-action-justify-content"
                        },
                        [
                          !(
                            {}.hasOwnProperty.call(_vm.item, "scope") &&
                            _vm.item.scope.isExternal
                          )
                            ? _c(
                                "div",
                                { staticClass: "bookmark-container" },
                                [
                                  _vm.item.format.name.toLowerCase() != "track"
                                    ? _c("Bookmark", {
                                        key:
                                          _vm.item.meta.isBookmarked + _vm._uid,
                                        staticClass: "item-bookmark",
                                        attrs: {
                                          assetId: _vm.item.id,
                                          assetType: _vm.getAssetType(_vm.item),
                                          meta: _vm.item.meta,
                                          itemTitle:
                                            _vm.item.title + " bookmark"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.isDesktopPlatform
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "bookmark-tooltip-text"
                                        },
                                        [_vm._v("Bookmark")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          !(
                            {}.hasOwnProperty.call(_vm.item, "scope") &&
                            _vm.item.scope.isExternal
                          ) && _vm.tab != "recommended"
                            ? _c(
                                "div",
                                { staticClass: "share-container" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "social-share",
                                      attrs: {
                                        "aria-label": _vm.item.title + " share"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.popoverOn("share")
                                        }
                                      }
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "click-to-copy",
                                          "class-name":
                                            "g-icon--small g-clickable"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isShareOpen
                                    ? _c("SocialSharePopover", {
                                        class:
                                          _vm.isDesktopPlatform &&
                                          _vm.tab != "expand-recommend-item"
                                            ? "social-share-popover"
                                            : "social-share-popover-mobile",
                                        attrs: {
                                          assetId: _vm.itemId,
                                          routeInfo: _vm.getRouteInfo(),
                                          router: _vm.$router,
                                          fromPage: "Universal card",
                                          size: "lg"
                                        },
                                        on: {
                                          "dismiss-share-action-model": function(
                                            $event
                                          ) {
                                            return _vm.popoverOff("share")
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  !_vm.isShareOpen && _vm.isDesktopPlatform
                                    ? _c(
                                        "span",
                                        { staticClass: "share-tooltip-text" },
                                        [_vm._v("Share")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showMoreActionPopover()
                            ? _c(
                                "div",
                                {
                                  staticClass: "more-container",
                                  class: {
                                    "more-carousal-container":
                                      _vm.cardType == "carousal"
                                  }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "more-action",
                                      attrs: {
                                        "aria-label": _vm.item.title + " more"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.popoverOn("more")
                                        }
                                      }
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "more-action",
                                          "class-name":
                                            "g-icon--small g-clickable"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isMoreOpen
                                    ? _c("MoreActionPopover", {
                                        class:
                                          _vm.isDesktopPlatform &&
                                          _vm.tab != "expand-recommend-item"
                                            ? "more-popover"
                                            : "more-popover-mobile",
                                        attrs: {
                                          item: _vm.item,
                                          assetId: _vm.item.id,
                                          assetType: _vm.item.format.name,
                                          routeInfo: _vm.getRouteInfo(),
                                          router: _vm.$router,
                                          showRemoveFromTrack: _vm.$route.path.includes(
                                            "ugtrack"
                                          ),
                                          showAddToTrack:
                                            _vm.showAddToTrack &&
                                            !["track"].includes(
                                              this.item.format.name.toLowerCase()
                                            ) &&
                                            !_vm.$route.path.includes(
                                              "ugtrack"
                                            ),
                                          showDelete:
                                            {}.hasOwnProperty.call(
                                              _vm.item,
                                              "scope"
                                            ) &&
                                            _vm.item.scope.isExternal &&
                                            _vm.item.progress != 100,
                                          size: "lg",
                                          fromPage: "Universal card"
                                        },
                                        on: {
                                          "remove-from-track": function(
                                            $event
                                          ) {
                                            return _vm.popoverOff("more", {
                                              onClickRemoveOption: true
                                            })
                                          },
                                          "add-to-track": function($event) {
                                            return _vm.popoverOff("more", {
                                              onClickTrackOption: true
                                            })
                                          },
                                          "edit-delete-external-content":
                                            _vm.deleteExternalContent,
                                          "close-more": function($event) {
                                            return _vm.popoverOff("more")
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  !_vm.isMoreOpen
                                    ? _c(
                                        "span",
                                        { staticClass: "more-tooltip-text" },
                                        [_vm._v("More")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.showConfirmDelete
        ? _c("div", [
            _c("div", {
              staticClass: "a-overlay",
              staticStyle: { position: "fixed", "z-index": "1100" },
              on: {
                click: function($event) {
                  return _vm.onConfirmDelete(false)
                }
              }
            }),
            _c("div", { staticClass: "b-modal confirm-box" }, [
              _c("img", {
                staticClass: "g-icon--small g-margin__right--msmall close-icon",
                attrs: { src: require("@/assets/images/close.svg") },
                on: {
                  click: function($event) {
                    return _vm.onConfirmDelete(false)
                  }
                }
              }),
              _c("div", { staticClass: "confirm-box-header" }, [
                _vm._v("Are you sure you want to delete?")
              ]),
              _c("p", [_vm._v("Deletion is permanent and can't be undone.")]),
              _c(
                "div",
                { staticClass: "handler" },
                [
                  _c(
                    "ion-button",
                    {
                      staticClass:
                        "g-button--primary ion-button-primary delete-btn",
                      attrs: {
                        "data-cy": "confirm-progress",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.onConfirmDelete(true)
                        }
                      }
                    },
                    [_vm._v("Delete")]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.onConfirmDelete(false)
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }