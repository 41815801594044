<template>
  <div>
    <div class="goal-setting-modal__backdrop" />
    <div class="goal-setting-modal">
      <ion-row class="goal-setting-modal-header">
        <ion-col size-lg="8" size-md="8" size-sm="8" size-xs="10"><h2 v-html="isEditMode ? editGoalHeading : newGoalHeading"></h2></ion-col>
        <ion-col size-lg="4" size-md="4" size-sm="4" size-xs="2"
          ><svg-icon icon-class="filter-close" class="g-icon--xsmall cancel-icon-container" @click="onCancel"></svg-icon>
        </ion-col>
      </ion-row>
      <div :class="isDesktopPlatform ? '' : 'scroll-mobile-content'">
        <ion-row class="goal-setting-modal-content">
          <ion-col size-lg="3" size-md="6" size-sm="6" size-xs="6">
            <div class="goal-setting-modal-box" :class="goalSettingType === 'light' ? 'active' : ''" @click="handleDaysSelect('light')">
              <h5>Light</h5>
              <p>2 days</p>
            </div>
          </ion-col>
          <ion-col size-lg="3" size-md="6" size-sm="6" size-xs="6">
            <div class="goal-setting-modal-box" :class="goalSettingType === 'casual' ? 'active' : ''" @click="handleDaysSelect('casual')">
              <h5>Casual</h5>
              <p>4 days</p>
              <div class="goal-setting-modal-recommendation">
                <p :class="goalSettingType === 'casual' ? 'active' : ''">RECOMMENDED TO START</p>
              </div>
            </div>
          </ion-col>
          <ion-col size-lg="3" size-md="6" size-sm="6" size-xs="6">
            <div class="goal-setting-modal-box" :class="goalSettingType === 'focused' ? 'active' : ''" @click="handleDaysSelect('focused')">
              <h5>Focused</h5>
              <p>8 days</p>
            </div>
          </ion-col>
          <ion-col size-lg="3" size-md="6" size-sm="6" size-xs="6">
            <div class="goal-setting-modal-custom-box" :class="goalSettingType === 'custom' ? 'active' : ''">
              <h5>You choose!</h5>
              <div class="goal-setting-modal-select">
                <select ref="goalDays" v-model="customGoalDays" @change="handleDaysSelect('custom')">
                  <option v-for="day in getDaysInThisMonth()" :key="day" :value="day">{{ day }}</option>
                </select>
                <label>days</label>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <div class="goal-setting-modal-tip-content">
          <h5>Tip</h5>
          <p>{{ getTipText() }}</p>
        </div>
        <div class="goal-setting-modal-action-content">
          <a v-if="isEditMode" @click="onClearGoal()">Clear goal</a>
          <ion-button
            shape="round"
            :disabled="goalDurationInDays === 0 || (goalSettingData.goalDays == goalDurationInDays && goalSettingData.goalDescription == goalSettingType)"
            color="primary"
            class="goal-setting-modal-save-btn"
            @click="onSaveClicked()"
            >Save</ion-button
          >
        </div>
      </div>
    </div>
    <HomeAlertModal v-if="showAlertsModal" :modalProperties="clearGoalMsg" @closeAlertsModal="showAlertsModal = false" @confirmClearGoal="onConfirmClearGoal" />
  </div>
</template>
  
  <script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import HomeAlertModal from '@/views/home/components/HomeAlertModal.vue'
import { popoverController } from '@modus/ionic-vue'
import GoalCongratulationsModal from '@/components/molecules/GoalCongratulationsModal'
import EventBus from '@/plugins/eventbus'

export default {
  name: 'HomeGoalSettingModal',
  components: { HomeAlertModal },
  props: {
    goalSettingData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      daysInThisMonth: 0,
      newGoalHeading: 'Set your monthly goal',
      editGoalHeading: 'Edit your monthly goal',
      goalDurationInDays: 0,
      goalSettingType: '',
      toastMsg: 'Your monthly goal is set',
      isEditMode: false,
      showAlertsModal: false,
      clearGoalMsg: {
        header: 'Clear monthly goal',
        message: `You can set a new goal at any point and change it month to month.`,
        primaryButton: 'Confirm',
        secondaryButton: 'Cancel',
        type: 'alert'
      },
      clearGoal: false,
      customGoalDays: 0,
      isDesktopPlatform: true,
      tipText: {
        custom: 'You can set a different goal each month and update it anytime.',
        focused: 'Break up larger learning goals into twice-weekly commitments, or block time to build a lifelong learning habit. Way to grow!',
        light: 'Pick one course from your learning plan to finish this month. Start small!',
        casual: 'Schedule 15-30 minute learning blocks once a week to help you achieve your goal. You can do it!'
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  created() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.isEditMode = {}.hasOwnProperty.call(this.goalSettingData, 'goalDays') && this.goalSettingData.goalDays > 0
    if (this.isEditMode) {
      this.goalDurationInDays = this.goalSettingData.goalDays || 0
      this.goalSettingType = this.goalSettingData.goalDescription || ''
      if (this.goalSettingData.goalDescription === 'custom') this.customGoalDays = this.goalSettingData.goalDays
    }
  },
  methods: {
    async onCancel() {
      this.$emit('closeGoalSettingModal')
    },
    getDaysInThisMonth() {
      const totalDaysArray = []
      const totalDaysCount = 28
      for (let i = 1; i <= totalDaysCount; i++) {
        totalDaysArray.push(i)
      }
      return totalDaysArray
    },
    getTipText() {
      return this.goalSettingType != '' ? this.tipText[this.goalSettingType] : this.tipText['custom']
    },
    handleDaysSelect(type) {
      this.goalSettingType = type
      this.goalDurationInDays = type === 'light' ? 2 : type === 'casual' ? 4 : type === 'focused' ? 8 : this.customGoalDays
      if (this.customGoalDays > 0 && type !== 'custom') this.customGoalDays = 0
    },
    onClearGoal() {
      this.showAlertsModal = true
    },
    onConfirmClearGoal() {
      this.toastMsg = 'Your monthly goal was cleared'
      this.clearGoal = true
      this.goalDurationInDays = 0
      this.onSaveClicked()
    },
    showToast() {
      this.$toasted.show(this.toastMsg, {
        containerClass: 'toast-container',
        className: 'success',
        position: this.isDesktopPlatform ? 'bottom-center' : 'mobile-container',
        iconPack: 'icon-container',
        duration: 5000,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    async onSaveClicked() {
      const authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const body = { goalDays: this.goalDurationInDays, goalDescription: this.goalSettingType }
      const payload = {
        token: authToken,
        body: body
      }
      await this.$learnAdmin.saveHomeGoalSetting(payload).then((res) => {
        this.$emit('saveGoalSettingModal', res.data)
        if (!this.clearGoal) {
          if (res && res.data.displayCongratulationsMessage === 'SHOW') {
            this.showGoalCongratulationsModal(res.data.goalDays)
          }
        }
        this.showToast()
      })
      await this.onPrepareGAPayload()
    },
    async onPrepareGAPayload() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'goal-id': this.goalSettingData.id,
        goalDays: this.goalDurationInDays,
        activeDaysCount: this.goalSettingData.activeDaysCount,
        goalAchieved: this.goalSettingData.goalAchieved,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: this.clearGoal ? 'Clearing a goal' : this.isEditMode ? 'Editing a goal' : 'Setting a goal',
        category: 'Goal Settings',
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    },
    async showGoalCongratulationsModal(goalDays = 0) {
      let modal = await popoverController.create({
        component: GoalCongratulationsModal,
        swipeToClose: true,
        showBackdrop: true,
        backdropDismiss: true,
        cssClass: 'goal-congrats-popover',
        componentProps: {
          parent: this,
          data: {},
          propsData: {
            goalDays: goalDays
          }
        }
      })
      EventBus.$emit('show-celebration', true)
      await modal.present()
      await modal.onDidDismiss().then(async () => {
        const authToken = (await localforage.getItem('my_access_token')) || ''
        const body = { congratulationsMessageStatus: 'RESET' }
        const payload = {
          token: authToken,
          body: body
        }
        await this.$learnAdmin.saveHomeGoalSetting(payload)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cancel-container {
  text-decoration: unset;
  padding: 0 2rem;
  color: var(--ion-color-tertiary);
}

@include block('goal-setting-modal') {
  position: absolute;
  padding: 1.5rem 2rem;
  width: 70%;
  border-radius: 20px;
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-gray);
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  top: 19%;
  .goal-setting-modal-header {
    margin: 2rem 0;
    border-bottom: 1px solid #e0e1e1;
    padding-bottom: 1.5rem;

    .cancel-icon-container {
      float: right;
      cursor: pointer;
    }
    h2 {
      color: #000000;
      font-size: 2rem;
      text-align: left;
      margin: 0;
    }
  }
  .goal-setting-modal-content {
    margin: 0 1rem;
    .goal-setting-modal-box {
      border: 1px solid #e0e1e1;
      cursor: pointer;
      min-height: 10rem;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      &:hover {
        background-color: #efefef;
      }
      &.active {
        background: #feb92e;
        border-color: #feb92e;
      }
      h5 {
        margin: 0 0 0.75rem 0;
        font-size: 1rem;
        font-family: Arial;
        font-weight: bold;
      }
      p {
        margin: 0;
        font-size: 1rem;
        font-family: Arial;
      }
      .goal-setting-modal-recommendation {
        position: absolute;
        bottom: 0.5rem;
        p {
          margin: 0;
          font-size: 10px;
          font-family: Arial;
          font-weight: bold;
          padding: 0.3rem;
          border-radius: 0.75rem;
          &.active {
            background-color: #f2d474;
          }
        }
      }
    }
    .goal-setting-modal-custom-box {
      border: 1px solid #e0e1e1;
      min-height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &.active {
        background: #feb92e;
        border-color: #feb92e;
      }
      h5 {
        margin: 0 0 0.75rem 0;
        font-size: 1rem;
        font-family: Arial;
        font-weight: bold;
      }
      .goal-setting-modal-select {
        display: flex;
        justify-content: center;
        align-items: center;
        select {
          width: 4.5rem;
          height: 2rem;
          padding: 0;
          margin-right: 0.75rem;
          border: 1px solid #e0e1e1;
          background: #fff;
          font-family: Arial;
          font-size: 1rem;
          padding: 0 0.2rem;
        }
        label {
          font-family: Arial;
          font-size: 1rem;
        }
      }
    }
  }
  .goal-setting-modal-tip-content {
    background: #f6f7f9;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    h5 {
      margin: 0 0 0.75rem 0;
      font-size: 1rem;
      font-family: Arial;
      font-weight: bold;
      text-align: left;
    }
    p {
      margin: 0;
      font-size: 1rem;
      font-family: Arial;
      text-align: left;
    }
  }
  .goal-setting-modal-action-content {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 1rem 1rem 1rem;
    align-items: center;
    a {
      font-size: 1rem;
      font-family: 'Futura PT Demi';
      letter-spacing: 0.02px;
      color: #1f3370;
      cursor: pointer;
      margin-right: 2.5rem;
    }
    .goal-setting-modal-save-btn {
      text-transform: none;
      font-family: 'Futura PT Demi';
      --ion-color-primary: #feb92e;
      --ion-color-primary-contrast: black;
      --box-shadow: none;
      font-size: 1rem;
    }
  }
  @include element('backdrop') {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--ion-color-role-grey);
    opacity: 0.5;
    margin-top: 65px;
    z-index: 1;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  @include block('goal-setting-modal') {
    top: 10%;
    height: auto;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  @include block('goal-setting-modal') {
    width: 85%;
    top: 7%;
    height: auto;
    .goal-setting-modal-header {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  @include block('goal-setting-modal') {
    width: 90%;
    .goal-setting-modal-header {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  @include block('goal-setting-modal') {
    width: 100%;
    padding: 1.5rem 1rem;
    height: 100vh;
    top: 0px;
    margin: 0px;
    z-index: 10;
    position: fixed;
    .goal-setting-modal-header {
      margin: 1.5rem 0;
      padding-bottom: 0.5rem;
      h2 {
        font-size: 1.5rem;
      }
    }
    .scroll-mobile-content {
      overflow-y: auto;
      height: calc(100vh - 140px);
    }
    .goal-setting-modal-content {
      margin: 2rem 0 0 0;
    }
    .goal-setting-modal-box {
      margin-bottom: 1rem;
    }
    .goal-setting-modal-tip-content {
      margin: 0.5rem 0;
      h5 {
        font-size: 0.875rem;
      }
      p {
        font-size: 0.875rem;
      }
    }
    .goal-setting-modal-action-content {
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }
    @include element('backdrop') {
      opacity: 0.1;
    }
  }
}

@media screen and (max-width: 414px) and (max-height: 715px) {
  @include block('goal-setting-modal') {
    .goal-setting-modal-header {
      margin: auto;
    }
    .goal-setting-modal-content {
      margin: 1rem 0 0 0;
    }
  }
}
</style>
  