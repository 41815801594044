var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edge-linkedinConnect" }, [
    _vm.$platform == "Mobile"
      ? _c(
          "button",
          {
            staticClass: "back_arrow",
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            }
          },
          [
            _c("svg-icon", {
              attrs: {
                "icon-class": "thin-back-arrow",
                "class-name": "g-icon--small"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "svg-wrapper" },
      [
        _c("svg-icon", {
          attrs: { "icon-class": "logo", "class-name": "logo--size" }
        })
      ],
      1
    ),
    _c(
      "div",
      { ref: "top", staticClass: "edge-linkedinConnect__wrapper" },
      [
        _c(
          "h4",
          {
            staticClass: "ion-text-start edge-linkedinConnect__title",
            class: this.$platform === "Desktop" ? "" : "g-type-large "
          },
          [_vm._v(" Check your email for a 6-digit code and enter it below ")]
        ),
        _vm.errorMessage !== ""
          ? _c(
              "div",
              {
                staticClass: "error",
                attrs: { "aria-label": "error message" }
              },
              [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
            )
          : _vm._e(),
        _c(
          "ion-item",
          {
            staticClass: "input-section ion-no-padding",
            attrs: { mode: "md" }
          },
          [
            _c(
              "ion-label",
              {
                staticClass: "verify-title",
                attrs: { mode: "md", position: "floating" }
              },
              [_vm._v("Verification Code")]
            ),
            _c("ion-input", {
              ref: "verificationCode",
              staticClass: "verify-input input-section",
              attrs: {
                position: "floating",
                mode: "md",
                "data-cy": "signup-code-1",
                disabled: _vm.isInputDisabled
              },
              on: {
                ionChange: function($event) {
                  return _vm.setVerificationCode($event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "handle-form" },
          [
            _c(
              "ion-button",
              {
                ref: "verify",
                staticClass: "ion-button-primary verify-btn",
                attrs: {
                  "data-cy": "signup-code-verify",
                  shape: "round",
                  color: "primary"
                },
                on: { click: _vm.onVerify }
              },
              [_vm._v("Verify")]
            ),
            _c("br"),
            _c(
              "a",
              {
                staticClass: "lined-link",
                attrs: { href: "#" },
                on: { click: _vm.onResend }
              },
              [_vm._v("Resend 6-digit code")]
            ),
            _vm.isCodeResent
              ? _c(
                  "div",
                  { staticStyle: { color: "#00a78d", "font-size": "1.2rem" } },
                  [_vm._v("Code is resent successfully")]
                )
              : _vm._e()
          ],
          1
        ),
        _vm.$platform === "Mobile"
          ? _c(
              "div",
              { staticClass: "footer-links" },
              [
                _vm.$platform == "Mobile"
                  ? _c(
                      "router-link",
                      { staticClass: "lined-link", attrs: { to: "/login" } },
                      [_vm._v("Back to login")]
                    )
                  : _vm._e(),
                _vm.$platform == "Mobile"
                  ? _c(
                      "router-link",
                      { staticClass: "lined-link", attrs: { to: "get-help" } },
                      [_vm._v("Get help")]
                    )
                  : _c("div", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("next", "OnboardingGetHelp")
                            }
                          }
                        },
                        [_vm._v("Get help")]
                      )
                    ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _vm.loading
              ? _c("ion-spinner", {
                  staticClass: "spinner",
                  attrs: { color: "#1f3370" }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }