var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "top",
      staticClass: "mylearning-panel main-content",
      class: _vm.isDesktopPlatform ? "" : "non-white-background"
    },
    [
      _c("div", [
        _vm._m(0),
        _c("img", {
          staticClass: "banner_image",
          attrs: { src: require("@/assets/images/my-learning-hero.png") }
        }),
        _vm.dataReady
          ? _c(
              "div",
              {
                ref: "tab",
                class: _vm.isDesktopPlatform
                  ? "mylearning-panel-desktop"
                  : "mylearning-panel-mobile"
              },
              [
                _c(
                  "MyLearningTab",
                  {
                    key: _vm.selectedTabIndex,
                    attrs: {
                      tabs: _vm.tabs,
                      selectedTabIndex: _vm.selectedTabIndex,
                      showFilter:
                        (_vm.selectedTabIndex === 0 &&
                          _vm.data.required.items.length > 0) ||
                        (_vm.selectedTabIndex === 1 &&
                          _vm.data.learning.items.length > 0) ||
                        (_vm.selectedTabIndex === 2 &&
                          _vm.data.external.items.length > 0)
                    },
                    on: { "update-tab-index": _vm.updateTabIndex }
                  },
                  [
                    _c("MyLearningFilter", {
                      attrs: {
                        slot: "mylearning-item-filter",
                        userAccess: _vm.userAccess,
                        "on-tab": _vm.tabs[_vm.selectedTabIndex].title,
                        data: _vm.data,
                        sideFilterData: _vm.sideFilterData
                      },
                      on: {
                        "on-apply": function($event) {
                          return _vm.onApply($event)
                        },
                        "on-open-filters": _vm.onOpenFilters,
                        "on-clear-filters": function($event) {
                          return _vm.onClearFilters($event)
                        },
                        "side-filter": function($event) {
                          return _vm.onSideFilterData($event)
                        },
                        "on-open-external-sidebar": _vm.openExternalSidebar
                      },
                      slot: "mylearning-item-filter"
                    }),
                    _c("MyLearningItemContainer", {
                      key: _vm.selectedTabIndex,
                      attrs: {
                        slot: "mylearning-item-container",
                        data: _vm.data,
                        selectedTabIndex: _vm.selectedTabIndex,
                        externalSidebarActive: _vm.externalSidebarActive
                      },
                      on: {
                        scrollToElement: _vm.scrollToElement,
                        "update-tab-index": _vm.updateTabIndex,
                        "learning-item-container-click":
                          _vm.onLearningItemContainerClick,
                        "learning-page-click": function($event) {
                          return _vm.onLearningPageClick($event)
                        },
                        "external-page-click": function($event) {
                          return _vm.onExternalPageClick($event)
                        },
                        "required-page-click": function($event) {
                          return _vm.onRequiredPageClick($event)
                        },
                        "on-toggle-external-sidebar": _vm.openExternalSidebar,
                        "on-delete-external-item": _vm.onDeleteExternalItem
                      },
                      slot: "mylearning-item-container"
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.dataReady ? _c("div", [_c("MyLearningSkeleton")], 1) : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Your curated learning")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }