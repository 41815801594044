var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "completed-list-content" }, [
      _c("div", { staticClass: "completed-list-table" }, [
        _c(
          "div",
          { staticClass: "completed-list-table-header" },
          _vm._l(_vm.headers, function(header) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: header.show,
                    expression: "header.show"
                  }
                ],
                key: header.name
              },
              [
                _c(
                  "h5",
                  {
                    class:
                      header.name == "Title"
                        ? "completed-title-header sort-header"
                        : "sort-header",
                    on: {
                      click: function($event) {
                        return _vm.openSortDropdown(header)
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(header.name))]),
                    _c("span", { staticClass: "sort-container" }, [
                      _c(
                        "button",
                        { class: !header.showAscending ? "arrow-disable" : "" },
                        [
                          _c("ion-icon", {
                            attrs: { slot: "start", name: "caret-up-outline" },
                            slot: "start"
                          })
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          class: !header.showDescending ? "arrow-disable" : ""
                        },
                        [
                          _c("ion-icon", {
                            attrs: {
                              slot: "start",
                              name: "caret-down-outline"
                            },
                            slot: "start"
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                header.showSortDropdown
                  ? _c(
                      "ion-grid",
                      { staticClass: "sort-dropdown" },
                      [
                        _c(
                          "ion-row",
                          {
                            staticClass: "sort-row",
                            class: !header.showDescending
                              ? "sort-row-selected"
                              : "",
                            staticStyle: { "margin-top": "8px" },
                            on: {
                              click: function($event) {
                                return _vm.onClickAscending(header)
                              }
                            }
                          },
                          [
                            _c("ion-col", [
                              _c(
                                "p",
                                { staticClass: "sort-text" },
                                [
                                  _c("ion-icon", {
                                    staticClass: "sort-icon",
                                    attrs: { name: "arrow-up-outline" }
                                  }),
                                  _vm._v("Sort Ascending")
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "ion-row",
                          {
                            staticClass: "sort-row",
                            class: !header.showAscending
                              ? "sort-row-selected"
                              : "",
                            staticStyle: { "margin-top": "4px" },
                            on: {
                              click: function($event) {
                                return _vm.onClickDescending(header)
                              }
                            }
                          },
                          [
                            _c("ion-col", [
                              _c(
                                "p",
                                { staticClass: "sort-text" },
                                [
                                  _c("ion-icon", {
                                    staticClass: "sort-icon",
                                    attrs: { name: "arrow-down-outline" }
                                  }),
                                  _vm._v("Sort Descending")
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "completed-list-table-content" },
          _vm._l(_vm.filterItems, function(asset) {
            return _c(
              "div",
              { key: asset.id, staticClass: "completed-list-table-row" },
              [
                _c(
                  "div",
                  {
                    class: asset.isActive
                      ? "table-item completed-name-header open-link open-link-color"
                      : "table-item completed-name-header open-link title-tooltip-container",
                    attrs: { title: asset.isActive ? asset.title : "" },
                    on: {
                      click: function($event) {
                        return _vm.openContent(asset)
                      }
                    }
                  },
                  [
                    asset.isRequired
                      ? _c(
                          "div",
                          { staticClass: "required-icon" },
                          [
                            asset.isRequired
                              ? _c("svg-icon", {
                                  staticStyle: { fill: "black !important" },
                                  attrs: {
                                    "icon-class": "due-date",
                                    "class-name": "g-icon--small g-clickable"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(asset.title) + " "),
                    _vm.$platform === "Desktop" && !asset.isActive
                      ? _c("span", { staticClass: "title-tooltip-text" }, [
                          _vm._v("This content is no longer available")
                        ])
                      : _vm._e()
                  ]
                ),
                _vm.isDesktopPlatform
                  ? _c(
                      "div",
                      { staticClass: "table-item completed-name-header" },
                      [_vm._v(_vm._s(_vm._f("formatDuration")(asset.duration)))]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "table-item completed-name-header" }, [
                  _vm._v(_vm._s(_vm._f("formatDate")(asset.completedAt)))
                ]),
                _vm.isDesktopPlatform && !_vm.isTab
                  ? _c(
                      "div",
                      { staticClass: "table-item completed-name-header" },
                      [
                        _vm._v(
                          _vm._s(_vm._f("stringCamelCase")(asset.formatName))
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.isDesktopPlatform && !_vm.isTab
                  ? _c(
                      "div",
                      { staticClass: "table-item completed-name-header" },
                      [_vm._v(_vm._s(asset.providerName))]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      ]),
      _vm.pagination.pageCount
        ? _c(
            "div",
            { staticClass: "search-result-pagination" },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.pagination.pageCount,
                  "page-range": _vm.pagination.pageRange,
                  "margin-pages": 1,
                  "click-handler": _vm.handlePageClick,
                  "container-class": "pagination",
                  "page-class": "pagination-item",
                  "page-link-class": "pagination-item-link",
                  "prev-text": "&lsaquo;",
                  "prev-class": "pagination-nav",
                  "prev-link-class": "pagination-nav-link",
                  "next-text": "&rsaquo;",
                  "next-class": "pagination-nav",
                  "next-link-class": "pagination-nav-link",
                  "hide-prev-next": true
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }