<template>
  <div :class="isDesktopPlatform ? 'container-desktop' : 'container-mobile'">
    <ion-spinner v-if="!dataReady" :style="isDesktopPlatform ? 'margin-top: 2rem;' : 'margin-top: 1rem;'" color="#1f3370"></ion-spinner>
    <div v-if="dataReady && items.length" class="home-item-container">
      <UniversalCard v-for="(item, index) in items" :key="item.id" :item="item" :index="index" :itemsLength="items.length" tab="HomeInProgress" />
      <!-- <HomeItem v-for="(item, index) in items" :key="item.id" :item="item" :index="index" tab="HomeBookmarked" /> -->
    </div>
    <div
      v-if="dataReady && !items.length"
      :style="isDesktopPlatform ? 'margin-top: 2rem;' : 'margin-top: 1rem;'"
      :class="isDesktopPlatform ? 'home-item-container inprogress-empty-container' : 'inprogress-empty-container'"
    >
      <div class="bookmarked-empty-container-grid">
        <div>
          <div class="title">No bookmarks? No worries.</div>
          <div class="sub-title">You can bookmark any ProEdge content you find along your journey, and it shows up here for easy access.</div>
          <div class="action-btns">
            <a href="#" class="learning-plan-btn" @click="goToLearningPlan">Learning Plan</a>
            <a href="#" class="explore-btn" @click="goToExplore">Explore</a>
          </div>
        </div>
      </div>
      <div class="bookmarked-empty-image">
        <img src="@/assets/images/no-bookmark.png" />
      </div>
    </div>
    <div v-if="dataReady && items.length" class="footer g-clickable">
      <a
        v-if="showViewAllButton"
        href="#"
        @click="goToBookmarkList()"
        :class="isDesktopPlatform ? 'footer-ancr' : ''"
        style="color: unset; text-decoration: unset"
        aria-label="view all bookmarks"
      >
        <span>view all</span>
        <svg-icon style="margin-top: 0rem; margin-left: 0.5rem; margin-bottom: -4px" icon-class="icon-arrow-right" class="g-icon--small"></svg-icon>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
// import HomeItem from '@/views/home/components/Home?Item'
import UniversalCard from '@/components/organisms/UniversalCard'

export default {
  name: 'HomeBookmarked',
  components: { UniversalCard },
  props: {},
  computed: {
    ...mapGetters(['auth_token', 'enabledFeatureFlags'])
  },
  data() {
    return {
      authToken: '',
      items: [],
      signedUriEnabled: false,
      isDesktopPlatform: true,
      dataReady: false,
      assetMediaUrl: '',
      showViewAllButton: false
    }
  },
  async mounted() {
    await this.getHomeBookmarkedData()
  },
  async activated() {
    await this.getHomeBookmarkedData()
  },
  methods: {
    async getHomeBookmarkedData() {
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
      this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      this.signedUriEnabled = enabledFeatureFlags['learn_autographer-uri-enabled'] ? enabledFeatureFlags['learn_autographer-uri-enabled'] : false
      await this.getAllBookmarks()
    },
    async getMedia(uri) {
      if (!this.signedUriEnabled || uri.includes('amazonaws.com')) return uri
      const signedURI = await this.$learnAdmin.getSignedURI({ url: uri, token: this.authToken })
      return signedURI.url ? signedURI.url : uri
    },
    async getAllBookmarks() {
      this.dataReady = false
      this.items = []
      let bookmarks = await this.$learnAdmin.getAllBookmarks({ token: this.authToken, pageNo: 1 })
      if (!{}.hasOwnProperty.call(bookmarks, 'content')) {
        bookmarks.content = []
      }
      this.showViewAllButton = bookmarks.content.length > 3
      bookmarks.content = bookmarks.content.length > 3 ? bookmarks.content.splice(0, 3) : bookmarks.content
      let ratingDetailsArray = []
      if (bookmarks && bookmarks.content) {
        ratingDetailsArray = (await this.determineContentRating(bookmarks.content)) || []
      }
      await Promise.allSettled(
        bookmarks.content.map(async (bookmark, index) => {
          if (bookmark.bookMarkItem) {
            if (bookmark.assetType === 'credentials') {
              bookmark.bookMarkItem.format = { name: 'credential' }
              bookmark.bookMarkItem.provider = { name: 'proedge' }
            } else if (bookmark.assetType === 'track') {
              const item = { ...bookmark.bookMarkItem.track }
              item.meta = bookmark.bookMarkItem.meta
              item.content = bookmark.bookMarkItem.content
              item.format = { name: 'track' }
              item.provider = { name: '' }
              item.title = item.name
              bookmark.bookMarkItem = item
            } else if (bookmark.assetType === 'automation') {
              bookmark.bookMarkItem.id = bookmark.bookMarkItem.automationId
              bookmark.bookMarkItem.format = { name: 'asset' }
              bookmark.bookMarkItem.launchBtn =
                {}.hasOwnProperty.call(bookmark.bookMarkItem, 'assetReference') && bookmark.bookMarkItem.assetReference.externalAssetLink ? 'Open' : 'Download'
              let logo =
                {}.hasOwnProperty.call(bookmark.bookMarkItem, 'provider') &&
                {}.hasOwnProperty.call(bookmark.bookMarkItem.provider, 'logo') &&
                {}.hasOwnProperty.call(bookmark.bookMarkItem.provider.logo, 'uri')
              bookmark.bookMarkItem.provider = {
                guid: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'provider') && bookmark.bookMarkItem.provider.guid ? bookmark.bookMarkItem.provider.guid : '',
                name: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'provider') && bookmark.bookMarkItem.provider.name ? bookmark.bookMarkItem.provider.name : '',
                avatarImage: logo ? bookmark.bookMarkItem.provider.logo.uri : ''
              }
              bookmark.bookMarkItem.type = {
                name: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'inventoryType') ? bookmark.bookMarkItem.inventoryType.name : '',
                src: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'inventoryType') ? `${this.assetMediaUrl}media-container/${bookmark.bookMarkItem.inventoryType.image}` : ''
              }
              bookmark.bookMarkItem.title = bookmark.bookMarkItem.name
              bookmark.bookMarkItem.synopsis = ''
              bookmark.bookMarkItem.applications = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'applications')
                ? await bookmark.bookMarkItem.applications.map(application => {
                    return { name: application.name, src: `${this.assetMediaUrl}media-container/${application.image}` }
                  })
                : []
              bookmark.bookMarkItem.image = {
                uri: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'inventoryType') ? this.fetchAssetThumbnail(bookmark.bookMarkItem.inventoryType.name) : ''
              }
            }
            if (bookmark.bookMarkItem.format.name.toLowerCase() == 'track') {
              bookmark.bookMarkItem.launchBtn = bookmark.bookMarkItem.progress > 0 && bookmark.bookMarkItem.progress < 100 ? 'Resume' : 'Launch'
              bookmark.bookMarkItem.image = { uri: require('@/assets/images/track.png') }
              bookmark.bookMarkItem.duration =
                bookmark.bookMarkItem.track.duration.value < 60
                  ? `${bookmark.bookMarkItem.track.duration.value} min`
                  : `${Math.round(bookmark.bookMarkItem.track.duration.value / 60)} hrs`
            }
            if (bookmark.bookMarkItem.format.name.toLowerCase() != 'asset' && bookmark.bookMarkItem.format.name.toLowerCase() != 'track') {
              bookmark.bookMarkItem.launchBtn = bookmark.bookMarkItem.progress > 0 && bookmark.bookMarkItem.progress < 100 ? 'Resume' : 'Launch'
              bookmark.bookMarkItem.image.uri = bookmark.bookMarkItem.image.uri ? await this.getMedia(bookmark.bookMarkItem.image.uri) : null
              bookmark.bookMarkItem.duration =
                bookmark.bookMarkItem.duration < 3600 ? `${Math.round(bookmark.bookMarkItem.duration / 60)} min` : `${Math.round(bookmark.bookMarkItem.duration / 3600)} hrs`
            }
            bookmark.bookMarkItem.publishedAt = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'publishedAt') ? bookmark.bookMarkItem.publishedAt : ''
            bookmark.bookMarkItem.requiredBy = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'requiredBy') ? bookmark.bookMarkItem.requiredBy : ''
            bookmark.bookMarkItem.content = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'content') ? bookmark.bookMarkItem.content : ''
            bookmark.bookMarkItem.meta = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'meta') ? bookmark.bookMarkItem.meta : ''
            bookmark.bookMarkItem.rating =
              ratingDetailsArray.length > 0 && ratingDetailsArray.some(rating => rating['entityId'] === bookmark.bookMarkItem.id)
                ? ratingDetailsArray.filter(rating => rating.entityId == bookmark.bookMarkItem.id)[0]?.avgRating
                : 0
            bookmark.bookMarkItem.level =
              bookmark.bookMarkItem.format.name.toLowerCase() === 'credential'
                ? {}.hasOwnProperty.call(bookmark.bookMarkItem, 'level')
                  ? bookmark.bookMarkItem.level
                  : ''
                : {}.hasOwnProperty.call(bookmark.bookMarkItem, 'courseLevels')
                ? bookmark.bookMarkItem.courseLevels[0]
                : ''
            bookmark.bookMarkItem.tracks = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'meta')
              ? {}.hasOwnProperty.call(bookmark.bookMarkItem.meta, 'tracks')
                ? bookmark.bookMarkItem.meta.tracks
                : []
              : []
            bookmark.bookMarkItem.selfProgressDeclaration = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'selfProgressDeclaration')
              ? bookmark.bookMarkItem.selfProgressDeclaration
              : false
            bookmark.bookMarkItem.isDateExpired = bookmark.bookMarkItem.requiredBy ? (new Date() - new Date(bookmark.bookMarkItem.requiredBy) > 0 ? true : false) : false
            this.items[index] = bookmark.bookMarkItem
          }
        })
      )
      this.dataReady = true
    },
    determineContentRating(contentFilterValue) {
      var contentIdList = []
      contentFilterValue.map(item => {
        item.bookMarkItem.inventoryCategory?.toLowerCase() === 'asset' ? contentIdList.push(item.assetId) : contentIdList.push(item.bookMarkItem.id)
      })
      if (contentIdList && contentIdList.length > 0) {
        const contentPayload = {
          token: this.authToken,
          body: contentIdList
        }
        return this.$learnAdmin.getContentRatingById(contentPayload)
      }
    },
    goToLearningPlan() {
      this.$router.push({ name: 'MyLearning', params: { type: 'required', isNotFromDeepLink: true } }).catch(() => {})
    },
    goToExplore() {
      this.$router.push({ name: 'Explore' }).catch(() => {})
    },
    goToBookmarkList() {
      this.$router.push({ name: 'LibraryBookmarkList' }).catch(() => {})
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    }
  }
}
</script>

<style lang="scss" scoped>
.bookmarked-empty-container {
  display: flex;
  align-items: center;
  gap: 3.5rem;
}

.bookmarked-empty-container-grid {
  display: grid;
  width: 60%;

  div {
    text-align: left;
  }
}

.title {
  text-align: left;
  font-size: 3rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-tertiary);
}

.sub-title {
  margin-top: 1rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Arial';
  color: var(--ion-color-medium);
}

.link-btn {
  font-size: 1rem;
  font-family: 'Arial';
  text-decoration: none !important;
  color: var(--ion-color-tertiary);
}

.action-btns {
  display: flex;
  margin-top: 1.6rem;
  align-content: left;
  margin-bottom: 0.25rem;
}

.learning-plan-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  margin-right: 1rem;
  --ion-color-primary-contrast: var(--ion-color-tertiary);
  text-decoration: none;
  background-color: var(--ion-color-primary);
  padding: 0.6rem 1.65rem;
  border-radius: 2rem;
  color: var(--ion-color-tertiary);
  letter-spacing: 0.8px;
  &:hover {
    filter: brightness(90%);
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}

.explore-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: var(--ion-color-tertiary);
  --ion-color-primary-contrast: var(--ion-color-white);
  text-decoration: none;
  background-color: var(--ion-color-tertiary);
  padding: 0.6rem 1.65rem;
  border-radius: 2rem;
  color: var(--ion-color-white);
  letter-spacing: 0.8px;
  &:hover {
    filter: brightness(115%);
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}

.bookmarked-empty-image {
  width: 40%;
  img {
    width: 80%;
  }
}

.footer {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  font-family: 'Arial';
  font-weight: bold;
  text-transform: uppercase;
  color: var(--ion-color-medium);
  border-top: 2px solid var(--ion-color-light-gray);
  letter-spacing: 1.4px;
  padding-top: 20px;
}

.footer-ancr {
  width: 19%;
}

ion-button {
  --box-shadow: none;
}

@media screen and (max-width: 1024px) {
  .bookmarked-empty-container-grid {
    width: 75%;
  }

  .bookmarked-empty-image {
    width: 25%;
    img {
      width: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .bookmarked-empty-container-grid {
    width: 85%;
  }

  .bookmarked-empty-image {
    width: 15%;
    img {
      width: auto;
    }
  }
}

@media screen and (max-width: 414px) {
  .bookmarked-empty-container {
    display: flex;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .bookmarked-empty-container-grid {
    width: 100%;
    gap: 2rem;
    margin: 0rem 0.5rem;
  }

  .bookmarked-empty-image {
    display: none;
  }
}
</style>
