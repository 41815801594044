var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "accessible-button",
        attrs: { "aria-label": _vm.itemTitle },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.toggleBookmarked($event)
          }
        }
      },
      [
        _c("svg-icon", {
          attrs: {
            "icon-class": _vm.bookmarked
              ? "bookmark-black-selected"
              : "bookmark-black",
            "class-name": "g-icon--small g-clickable"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }