import store from '@/store'
import localforage from 'localforage'
import LearnAdmin from '@/api/learnAdmin'
import Timer from '@/plugins/timer'
var CrossStorageClient = require('cross-storage').CrossStorageClient

const state = {
  auth_token: '',
  gcs_token: null,
  sso_token: '',
  sso_bouncer: '',
  name: '',
  firstname: '',
  lastname: '',
  username: '',
  user_id: '',
  user_guid: '',
  user_onboarded: false,
  tenant: '',
  show_share: true,
  show_idea: true,
  trackingDisabled: false,
  globalSearchEnabled: false,
  showSearchSuggest: false,
  showSearchLookingFor: false,
  showAddToTrack: false,
  hide_rwl: false,
  userAccessAssets: {
    canAccessAssets: false
  },
  contents: [],
  notifs: [],
  user_access: {
    licenseType: '',
    canAccessRWL: false,
    canAccessCourses: false,
    canAccessCredentials: false
  },
  profile: {
    role: '',
    roleGUID: '',
    companyGUID: '',
    skills: [],
    upskills: []
  },
  navbar: {
    explore: {
      isShown: false,
      headerTitle: 'Explore',
      hasTabs: true,
      hasBackButton: false
    },
    solutions: {
      title: 'Solutions',
      content: '',
      trendingIcon: 'trending',
      subtitle: 'Visualization,',
      subtitleTopic: 'Data Science',
      bookmark: true,
      author: 'Author',
      hasTabs: true
    }
  }
}

const mutations = {
  SET_AUTH_TOKEN: (state, auth_token) => {
    state.auth_token = auth_token
  },
  SET_GCS_TOKEN: (state, gcs_token) => {
    state.gcs_token = gcs_token
  },
  SET_SSO_TOKEN: (state, sso_token) => {
    state.sso_token = sso_token
  },
  SET_SSO_BOUNCER: (state, sso_bouncer) => {
    state.sso_bouncer = sso_bouncer
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_FIRSTNAME: (state, firstname) => {
    state.firstname = firstname
  },
  SET_LASTNAME: (state, lastname) => {
    state.lastname = lastname
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_USER_ID: (state, user_id) => {
    state.user_id = user_id
  },
  SET_USER_GUID: (state, user_guid) => {
    state.user_guid = user_guid
  },
  SET_USER_ONBOARDED: (state, user_onboarded) => {
    state.user_onboarded = user_onboarded
  },
  SET_USER_ACCESS: (state, user_access) => {
    state.user_access = user_access
  },
  SET_TENANT: (state, tenant) => {
    state.tenant = tenant
  },
  SET_SHOW_SHARE: (state, show_share) => {
    state.show_share = show_share
  },
  SET_SHOW_IDEA: (state, show_idea) => {
    state.show_idea = show_idea
  },
  SET_TRACKING_OPTION: (state, trackingOption) => {
    state.trackingDisabled = trackingOption
  },
  SET_GLOBAL_SEARCH_OPTION: (state, globalSearchEnabled) => {
    state.globalSearchEnabled = globalSearchEnabled
  },
  SET_SEARCH_SUGGEST_OPTION: (state, showSearchSuggest) => {
    state.showSearchSuggest = showSearchSuggest
  },
  SET_SEARCH_LOOKING_FOR_OPTION: (state, showSearchLookingFor) => {
    state.showSearchLookingFor = showSearchLookingFor
  },
  SET_ADD_TO_TRACK: (state, showAddToTrack) => {
    state.showAddToTrack = showAddToTrack
  },
  SET_HIDE_RWL: (state, hide_rwl) => {
    state.hide_rwl = hide_rwl
  },
  SET_NAVBAR: (state, navbar) => {
    const tab = Object.keys(navbar)[0]
    state.navbar[tab] = navbar[tab]
  },
  SET_CONTENTS: (state, contents) => {
    state.contents = contents
  },
  SET_PROFILE: (state, profile) => {
    state.profile = profile
  },
  SET_NOTIFICATIONS: (state, notifs) => {
    state.notifs = notifs
  },
  SET_USER_ACCESS_TAG: (state, userAccessAssets) => {
    state.userAccessAssets = userAccessAssets
  }
}

const actions = {
  async setAccessToken({ commit }, token) {
    commit('SET_AUTH_TOKEN', token)
    let storage;
    if (window.location.host.includes('localhost')) {
      storage = new CrossStorageClient('http://' + window.location.host, { timeout: 5000 })
    } else {
      storage = new CrossStorageClient('https://' + window.location.host, { timeout: 5000 })
    }
    storage.onConnect().then(function () {
      return storage.set('my_access_token', token)
    })
    await localforage.setItem('my_access_token', token).then(() => { })
  },

  async setSSO({ commit }, sso) {
    const { token, bouncer } = sso
    commit('SET_SSO_TOKEN', token)
    commit('SET_SSO_BOUNCER', bouncer)
    await localforage.setItem('my_sso_token', token).then(() => { })
    await localforage.setItem('my_sso_bouncer', bouncer).then(() => { })
  },

  async setGCSToken({ commit }, token) {
    commit('SET_GCS_TOKEN', token)
    await localforage.setItem('my_gcs_token', token).then(() => { })
  },

  async setUserProfile({ commit }, profile) {
    commit('SET_PROFILE', profile)
    await localforage.setItem('my_profile', profile).then(() => { })
  },
  async setUserAccessTag({ commit }, userAccessAssets) {
    const userAccessTag = {
      canAccessAssets: userAccessAssets.canAccessAssets
    }
    commit('SET_USER_ACCESS_TAG', userAccessTag)
    await localforage.setItem('my_user_access_tag', userAccessTag).then(() => { })
  },

  async setUserInfo({ commit }, userInfo) {
    const { firstName = '', lastName = '', email, guid, id, tenantGuid } = userInfo
    const userAccess = {
      licenseType: userInfo.licenseType,
      canAccessRWL: userInfo.canAccessRWL,
      canAccessCourses: userInfo.canAccessCourses,
      canAccessCredentials: userInfo.canAccessCredentials
    }
    const name = (firstName + ' ' + lastName).trim()
    commit('SET_NAME', name)
    commit('SET_FIRSTNAME', firstName)
    commit('SET_LASTNAME', lastName)
    commit('SET_USERNAME', email)
    commit('SET_USER_ID', id)
    commit('SET_USER_GUID', guid)
    commit('SET_USER_ACCESS', userAccess)
    await localforage.setItem('my_name', name).then(() => { })
    await localforage.setItem('my_firstname', firstName).then(() => { })
    await localforage.setItem('my_lastname', lastName).then(() => { })
    await localforage.setItem('my_username', email).then(() => { })
    await localforage.setItem('my_user_id', id).then(() => { })
    await localforage.setItem('my_tenant_guid', tenantGuid).then(() => { })
    await localforage.setItem('my_user_guid', guid).then(() => { })
    await localforage.setItem('my_user_access', userAccess).then(() => { })
    learningAnalytics.setActor(name, email)
    Timer.setActiveDate();
  },

  async setUserOnboarded({ commit }, user_onboarded) {
    commit('SET_USER_ONBOARDED', user_onboarded)
    await localforage.setItem('my_user_onboarded', user_onboarded).then(() => { })
  },

  async setTenantInfo({ commit }, tenant) {
    commit('SET_TENANT', tenant)
    await localforage.setItem('my_tenant', tenant).then(() => { })
  },

  async setShowShare({ commit }, show_share) {
    commit('SET_SHOW_SHARE', show_share)
    await localforage.setItem('show_share', show_share).then(() => { })
  },

  async setShowIdea({ commit }, show_idea) {
    commit('SET_SHOW_IDEA', show_idea)
    await localforage.setItem('show_idea', show_idea).then(() => { })
  },

  async setTrackingDisabled({ commit }, trackingOption) {
    commit('SET_TRACKING_OPTION', trackingOption)
    await localforage.setItem('trackingDisabled', trackingOption).then(() => { })
  },

  async setGlobalSearchEnabled({ commit }, globalSearchEnabled) {
    commit('SET_GLOBAL_SEARCH_OPTION', globalSearchEnabled)
    await localforage.setItem('global-search-enabled', globalSearchEnabled).then(() => { })
  },

  async setShowSearchSuggest({ commit }, showSearchSuggest) {
    commit('SET_SEARCH_SUGGEST_OPTION', showSearchSuggest)
    await localforage.setItem('show-search-suggest', showSearchSuggest).then(() => { })
  },

  async setShowSearchLookingFor({ commit }, showSearchLookingFor) {
    commit('SET_SEARCH_LOOKING_FOR_OPTION', showSearchLookingFor)
    await localforage.setItem('show-search-looking-for', showSearchLookingFor).then(() => { })
  },

  async setShowAddToTrack({ commit }, showAddToTrack) {
    commit('SET_ADD_TO_TRACK', showAddToTrack)
    await localforage.setItem('show-add-to-track', showAddToTrack).then(() => { })
  },

  async setHideRWL({ commit }, hide_rwl) {
    commit('SET_HIDE_RWL', hide_rwl)
    await localforage.setItem('hide_rwl', hide_rwl).then(() => { })
  },

  async getNotifs({ dispatch, commit }, frequency = 'once') {
    const learnAdmin = new LearnAdmin()
    const token = await localforage.getItem('my_access_token')
    if (frequency === 'once') {
      const notifs = await learnAdmin.getAppNotifications({ token: token })
      if (notifs.code === 401) {
        dispatch('logout')
      }
      else {
        const appNotifications = notifs.filter(entry => !entry.isDismissed).sort((a, b) => a.showStartingAt - b.showStartingAt)
        commit('SET_NOTIFICATIONS', appNotifications)
      }
    } else {
      setInterval(async () => {
        const notifs = await learnAdmin.getAppNotifications({ token: token })
        if (notifs.code === 401) {
          dispatch('logout')
        }
        else {
          const appNotifications = notifs.filter(entry => !entry.isDismissed).sort((a, b) => a.showStartingAt - b.showStartingAt)
          commit('SET_NOTIFICATIONS', appNotifications)
        }
      }, 30000)
    }
  },

  async logout({ commit }) {
    const removeCookies = async () => {
      var res = document.cookie
      var multiple = res.split(';')
      for (var i = 0; i < multiple.length; i++) {
        var key = multiple[i].split('=')
        document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC'
      }
    }

    const ssoRedirect = (await localforage.getItem('ssoRedirect')) ? new URL(await localforage.getItem('ssoRedirect')) : ''
    if (ssoRedirect && ssoRedirect !== '') {
      await localforage.setItem('ssoRedirect', null)
      await fetch(`${ssoRedirect.origin}/logout/logout.php`)
    }

    await removeCookies()

    const cloudEnvInfo = {
      details: { name: '', mode: '', cloudRegion: '', cloudProvider: '' },
      extras: { proAuthBaseUri: '', proAuthApiKey: '' },
      baseApis: { learnApiBaseUri: '', shareApiBaseUri: '', cmsApiBaseUri: '' },
      cdn: { contentCdn: '', credentialCdn: '', staticCdn: '' },
      microservices: {
        ffApi: '',
        clickstreamApiBaseUri: '',
        learningLockerApiBaseUri: '',
        learningLockerBaseUri: '',
        courseProgressApi: '',
        autographer: '',
        bouncer: ''
      }
    }

    const userConfig = {
      user: { lang: 'en-US' },
      company: { isC1: false, lang: 'en', regions: ['US'], helpdesk: { email: '', phone: '' } },
      flags: { clickstreamEnabled: false, autographerEnabled: false, tierEnabled: false }
    }

    await Promise.allSettled([
      localforage.removeItem('my_access_token'),
      localforage.removeItem('my_gcs_token'),
      localforage.removeItem('my_sso_token'),
      localforage.removeItem('my_sso_bouncer'),
      localforage.removeItem('my_name'),
      localforage.removeItem('my_firstname'),
      localforage.removeItem('my_lastname'),
      localforage.removeItem('my_username'),
      localforage.removeItem('my_user_id'),
      localforage.removeItem('my_user_guid'),
      localforage.removeItem('my_user_onboarded'),
      localforage.removeItem('my_user_access'),
      localforage.removeItem('my_tenant'),
      localforage.removeItem('show_share'),
      localforage.removeItem('show_idea'),
      localforage.removeItem('hide_rwl'),
      localforage.removeItem('my_profile'),
      localforage.removeItem('cloudEnv'),
      localforage.removeItem('cloud_env.details'),
      localforage.removeItem('config.user'),
      localforage.removeItem('config.company'),
      localforage.removeItem('config.flags'),
      localforage.removeItem('ssoRedirect'),
      localforage.removeItem('my_tenant_guid'),
      localforage.removeItem('trackingDisabled'),
      localforage.removeItem('global-search-enabled'),
      localforage.removeItem('show-search-suggest'),
      localforage.removeItem('show-search-looking-for'),
      localforage.removeItem('show-add-to-track'),
      localforage.removeItem('enabled-feature-flags'),
      localforage.removeItem('deep_link_id'),
      localforage.removeItem('deep_link_type'),
      localforage.removeItem('deep_link_search_query'),
      localforage.removeItem('deep_link_search_type'),
      localforage.removeItem('deep_link_my_learning_type'),
      localforage.removeItem('show-search-suggest'),
      localforage.removeItem('my_user_access_tag'),
      localforage.removeItem('lastActiveDate'),
      localforage.removeItem('progress-update-delayed-items'),
      commit('SET_AUTH_TOKEN', ''),
      commit('SET_GCS_TOKEN', null),
      commit('SET_SSO_TOKEN', ''),
      commit('SET_SSO_BOUNCER', ''),
      commit('SET_NAME', ''),
      commit('SET_FIRSTNAME', ''),
      commit('SET_LASTNAME', ''),
      commit('SET_USERNAME', ''),
      commit('SET_USER_ID', ''),
      commit('SET_USER_GUID', ''),
      commit('SET_USER_ONBOARDED', false),
      commit('SET_USER_ACCESS', {}),
      commit('SET_TENANT', ''),
      commit('SET_SHOW_SHARE', true),
      commit('SET_SHOW_IDEA', true),
      commit('SET_HIDE_RWL', false),
      commit('SET_TRACKING_OPTION', false),
      commit('SET_GLOBAL_SEARCH_OPTION', false),
      commit('SET_PROFILE', {}),
      commit('SET_NOTIFICATIONS', []),
      commit('SET_SEARCH_SUGGEST_OPTION', false),
      commit('SET_SEARCH_LOOKING_FOR_OPTION', false),
      commit('SET_ADD_TO_TRACK', false),
      commit('SET_USER_ACCESS_TAG', {}),
      store.dispatch('environment/setCloudEnvInfo', cloudEnvInfo),
      store.dispatch('environment/setDetails', cloudEnvInfo.details),
      store.dispatch('environment/setUserConfig', userConfig.user),
      store.dispatch('environment/setCompanyConfig', userConfig.company),
      store.dispatch('environment/setFlagConfig', userConfig.flags),
      store.dispatch('flag/setFeatureFlags', { featureFlags: [], strategyProviders: [] })
    ])
    window.localStorage.clear()
    let fullPath = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
    window.location.href = `${fullPath}/landing`
  },

  setNavBar({ commit }, navbar) {
    commit('SET_NAVBAR', navbar)
  },

  setContents({ commit }, contents) {
    commit('SET_CONTENTS', contents)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
