import axios from 'axios'

export default {
  async login(url, payload) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async ssoCheck(url) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserInfo(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserTenantInfo(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async logout(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async registerUser(url, payload) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async activateUser(url, payload) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async changePassword(url, payload) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async resetPassword(url, payload) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, { email: payload.email, isSendMail: true }, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async setPassword(url, payload) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async sendVerificationCode(url) {
    const headers = {
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async renewToken(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getGCSToken(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserProfile(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserProfileByGuid(url, payload) {
    const headers = {
      Authorization: 'Bearer ' + payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCompanyLeaderboard(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserRoleHistory(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserSuggestedRole(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async addPastRole(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserSkills(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSkillByName(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async searchSkillByName(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSkillByGuid(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async addUserSkill(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async deleteUserSkill(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { data: payload, headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserExplorativeskills(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },
  async getUserAcquiredSkills(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async addUserExplorativeSkill(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async postUserExplorativeSkills(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload.skills, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserUpskills(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserActivitySummary(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserIdeas(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserFollowers(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserFollowings(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getAllBookmarks(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getContentBookmarks(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async addBookmark(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async removeBookmark(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async updateOnboardingStage(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserConnections(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserConnectionProfile(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async addUserFollowings(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async removeUserFollowings(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserLearningHistory(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserLearningProgress(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUserNotifs(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async updateUserNotifs(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, { notificationIds: payload.body }, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async submitHelpTicket(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'multipart/form-data',
    }
    const response = await axios.post(url, payload.data, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getDashboardMetrics(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },
}