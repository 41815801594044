var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rating-content" }, [
    !_vm.rating.flagged
      ? _c("div", { staticClass: "ratings-container" }, [
          _c("div", { staticClass: "ratings-row-meta" }, [
            _c("span", { staticClass: "meta-container" }, [
              _c(
                "span",
                [
                  _vm._l(Math.ceil(_vm.rating.rating), function(rating) {
                    return _c("svg-icon", {
                      key: rating.date,
                      staticStyle: { "margin-right": "5px" },
                      attrs: { "icon-class": "star-filled-desktop" }
                    })
                  }),
                  _vm._l(_vm.maxRating - Math.ceil(_vm.rating.rating), function(
                    rating
                  ) {
                    return _c("svg-icon", {
                      key: rating.name,
                      staticStyle: { "margin-right": "5px" },
                      attrs: { "icon-class": "star-unfilled" }
                    })
                  })
                ],
                2
              ),
              _c("span", [_vm._v("By " + _vm._s(_vm.rating.userName))]),
              _vm.rating.isCompleted
                ? _c("span", [_vm._v("User has completed content")])
                : _vm._e(),
              _c("span", [
                _vm._v(_vm._s(_vm._f("formatDate")(_vm.rating.createdAt)))
              ])
            ]),
            _vm.rating.userGuid != _vm.userGuid
              ? _c(
                  "span",
                  {
                    staticClass: "flag-container",
                    on: {
                      mouseover: function($event) {
                        _vm.isHovering = true
                      },
                      mouseout: function($event) {
                        _vm.isHovering = false
                      },
                      click: function($event) {
                        _vm.isMoreOpen = true
                        _vm.isFlagClicked = true
                      }
                    }
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class":
                          _vm.isHovering || _vm.isFlagClicked
                            ? "Icon feather-flag-red"
                            : "Icon feather-flag-black",
                        "class-name": "flag-icon"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isMoreOpen
              ? _c(
                  "div",
                  {
                    staticClass: "dropdown-container",
                    attrs: { id: "dropdown-container" },
                    on: {
                      click: function($event) {
                        return _vm.openFlagModal()
                      }
                    }
                  },
                  [_c("span", [_vm._v("Flag as inappropriate")])]
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "ratings-comment" }, [
            _vm._v(_vm._s(_vm.rating.comment))
          ])
        ])
      : _c("div", { staticClass: "flagged-container" }, [
          _c(
            "span",
            [
              _vm._v("This comment has been flagged. "),
              _c("svg-icon", {
                attrs: {
                  "icon-class": "Icon-feather-flag",
                  "class-name": "flag-icon"
                }
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }