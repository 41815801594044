<template>
  <div class="edge-navbar" v-if="showNavbar" role="navigation">
    <div
      v-if="
        searchBar.state.on &&
          showShortTypeahead &&
          ((autoSuggestFlagEnabled && autoSuggestList.length > 0 && currentSearchQuery != '') || (lookingForFlagEnabled && autoSuggestList.length == 0))
      "
      style="position: absolute; background-color: transparent; height: 100vh; width: 100%; opacity: 0.2"
      @click.stop="autoSuggestBackdrop()"
    ></div>
    <button @click.stop="toggleSideMenu" class="menu-button" aria-label="open side menu">
      <svg-icon icon-class="hamburger-menu-icon" class="menu-button-icon" />
    </button>
    <a href="#" @click="goToHome" class="logo-link" aria-label="go to homepage">
      <svg-icon icon-class="logo" class="logo-icon" alt="proedge logo image" />
    </a>
    <div class="topnav-link-container">
      <ion-link role="link" tabindex="0" @click="goToHome" class="topnav-link" :class="$route.path == '/home' ? 'selected-link' : ''"> Home </ion-link>
      <ion-link role="link" tabindex="0" @click="goToMyLearning" class="topnav-link" :class="$route.path.includes('/mylearning') ? 'selected-link' : ''">
        My learning
      </ion-link>
      <ion-link role="link" tabindex="0" v-if="!hideRWLTenant" href="#" @click="goToExplore" class="topnav-link" :class="$route.path.includes('/explore') ? 'selected-link' : ''"
        >Explore</ion-link
      >
    </div>
    <!-- searchbar component -->
    <div v-if="isSearchable" role="search">
      <!-- searchbar for smaller screens: under 1366px -->
      <div class="searchbar">
        <ion-searchbar
          style="right: unset; margin-left: unset !important"
          class="short-searchbar"
          role="searchbox"
          show-cancel-button="never"
          ref="searchbar"
          @keypress.enter="handleSearchInput"
          @focusin="onSearchbarFocus()"
          @ionCancel="onSearchCancel()"
          v-on:keyup="handleSuggestionInput"
          placeholder="Search using titles, topics, and keywords"
          @ionClear="handleSearchClearButton()"
        />
        <!-- short typeahead component -->
        <div v-if="searchBar.state.on && showShortTypeahead" class="short-typeahead">
          <ion-grid
            v-if="autoSuggestFlagEnabled && autoSuggestList.length > 0 && currentSearchQuery != ''"
            class="typeahead-main-component"
            :key="autoSuggestList.length + 'main_typeahead'"
          >
            <div class="typeahead-results-container">
              <ion-row class="ion-margin-vertical typeahead-result-row" v-for="(item, index) in autoSuggestList.slice(0, 10)" :key="item + index">
                <!-- auto suggest for non exact match -->
                <ion-col v-if="!item.exactMatch && item.category != 'Skill'" size="1" @click="onClickTypeAhead(item)" :key="item.text + 'searchicon'">
                  <svg-icon icon-class="icon-search" class="typeahead-search-icon" />
                </ion-col>
                <ion-col
                  v-if="!item.exactMatch && item.category != 'Skill'"
                  class="typeahead-search-result-ellipsis"
                  @click="onClickTypeAhead(item)"
                  :key="item.text + 'searchtext'"
                >
                  <p class="typeahead-search-result" v-html="item.displayText"></p>
                </ion-col>
                <!-- auto suggest for skill -->
                <ion-col v-if="!item.exactMatch && item.category == 'Skill'" size="1" @click="onClickTypeAhead(item)" :key="item.text + 'skillicon'">
                  <svg-icon icon-class="auto-suggest-skill" class="typeahead-search-icon" />
                </ion-col>
                <ion-col
                  v-if="!item.exactMatch && item.category == 'Skill'"
                  class="typeahead-search-result-ellipsis"
                  @click="onClickTypeAhead(item)"
                  :key="item.text + 'skilltext'"
                  style="padding-top: 0px; padding-left: 12px"
                >
                  <p class="typeahead-search-result" v-html="item.displayText"></p>
                  <p class="typeahead-search-result-category">Skill</p>
                </ion-col>
                <!-- auto suggest for exact match -->
                <ion-col v-if="item.exactMatch" size="1" :key="item.text + 'image'" @click="onClickDirectMatch(item)">
                  <div class="home-img-search">
                    <img :src="getImageUri(item)" />
                  </div>
                </ion-col>
                <ion-col v-if="item.exactMatch" class="typeahead-search-result-ellipsis" :key="item.text + 'name'" @click="onClickDirectMatch(item)">
                  <p class="typeahead-search-result typeahead-search-result-ellipsis" style="padding-left: 8px" v-html="item.displayText"></p>
                  <p v-if="item.content.format.name.toLowerCase() !== 'asset'" class="typeahead-search-result-category" style="padding-left: 8px">{{ item.type }}</p>
                  <p v-else class="typeahead-search-result-category" style="padding-left: 8px">{{ item.type }} | {{ item.applications.join(' | ') }}</p>
                </ion-col>
              </ion-row>
            </div>
            <ion-row>
              <ion-col class="ion-text-center">
                <p class="typeahead-see-all" @click="handleSearchSeeAll">See all results for "{{ currentSearchQuery }}"</p>
              </ion-col>
            </ion-row>
          </ion-grid>
          <!-- "looking for" component -->
          <ion-grid class="ion-padding looking-for-component" v-if="lookingForFlagEnabled && autoSuggestList.length == 0" :key="autoSuggestList.length + 'looking_for'">
            <ion-row>
              <ion-col>
                <p class="typeahead-header">Browse by</p>
              </ion-col>
            </ion-row>
            <ion-row v-for="filter in lookingForFilters" :key="filter" class="ion-align-items-center content-type-pill" @click.stop="applyContentTypeFilter(filter)">
              {{ filter }}
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>
    <ion-row class="right-icons-section">
      <ion-col>
        <a href="#" @click="goToBookmarks" :class="!tenantCustomInfo.logoUri.length ? 'bookmark-button' : 'bookmark-button-with-logo'" aria-label="Bookmarks">
          <svg-icon icon-class="icon-bookmark-new" class="bookmark-icon" />
        </a>
      </ion-col>
      <ion-col>
        <div class="profile-dropdown-container" :class="{ 'with-logo': tenantCustomInfo.logoUri.length }">
          <a
            href="#"
            @click="openSubMenu"
            :class="[!tenantCustomInfo.logoUri.length ? 'profile-button' : 'profile-button-with-logo', { 'sub-menu-opened': showSubMenu }]"
            aria-label="Profile"
          >
            <span>{{ shortName }}</span>
          </a>
          <div class="submenu-container" v-if="showSubMenu">
            <ul class="sub-menu-list">
              <template v-for="subMenuItem in subMenuItems">
                <li :key="subMenuItem.link" :value="subMenuItem.name" class="sub-menu-item" v-if="subMenuItem.show">
                  <a href="#" @click="navigateTo(subMenuItem.link)" :class="{ 'bold-text': subMenuItem.name === 'Logout' }">
                    {{ subMenuItem.name }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </ion-col>
      <!--This is left in comment for future use-->
      <!-- <ion-col><NotificationsBell @notification-icon-click="autoSuggestBackdrop()" :isLogoVisible="tenantCustomInfo.logoUri.length > 0" /></ion-col> -->
      <ion-col v-if="tenantCustomInfo.logoUri.length" class="tenant-custom-logo">
        <img :src="tenantCustomInfo.logoUri" />
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters, mapActions } from 'vuex'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'
// import NotificationsBell from '@/components/molecules/NotificationsBell'

export default {
  name: 'NavBarDesktop',
  components: {},
  props: {
    displayFullNavBar: { type: Boolean, default: true },
    hideRWLTenant: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      showNavbar: false,
      authToken: '',
      tenant: {},
      userAccess: {},
      currentSearchQuery: '',
      isSearchable: false,
      showShortTypeahead: false,
      requiredLearningEnabled: false,
      autoSuggestList: [],
      searchBar: {
        state: {
          on: true,
          off: false
        }
      },
      autoSuggestFlagEnabled: false,
      lookingForFlagEnabled: false,
      tenantAsset: {
        enabled: false,
        userAccessAsset: false
      },
      tenantCustomInfo: {
        logoUri: ''
      },
      lookingForFilters: [],
      shortName: '',
      subMenuItems: [],
      showSubMenu: false
    }
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags', 'auth_token', 'firstname', 'lastname'])
  },
  beforeUpdate() {
    this.mountAction()
  },
  activated() {
    this.mountAction()
  },
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    if (![null, ''].includes(this.authToken)) this.setup()
    EventBus.$on('reload-nav-bar-for-sso-user', this.setup)
  },
  methods: {
    async setup() {
      this.showNavbar = true
      this.tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(this.tenant.settings)
      this.userAccess = (await localforage.getItem('my_user_access')) || {}
      const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
      this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
      this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
      this.userAccess.assetAccess = this.tenantAsset.userAccessAsset && this.tenantAsset.enabled
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      this.requiredLearningEnabled =
        enabledFeatureFlags['learn_required_learning_enabled'] && {}.hasOwnProperty.call(settings, 'required-learning') && settings['required-learning']
      this.userAccess.trackAccess = enabledFeatureFlags['learn_track-available'] ? enabledFeatureFlags['learn_track-available'] : false
      this.mountAction()
      this.getShortName()
      this.getSubMenuItems()
    },
    ...mapActions('user', ['logout']),
    autoSuggestBackdrop() {
      if (this.isSearchable) {
        this.$refs.searchbar.value = ''
        this.onSearchCancel()
      }
    },
    async getSubMenuItems() {
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      const showLearningHistory = enabledFeatureFlags['learn_learning_history_enabled'] || false
      this.subMenuItems = [
        { name: 'Learning history', link: 'learningHistory', show: showLearningHistory },
        { name: 'Skills', link: 'skills', show: true },
        { name: 'Profile details', link: 'profileDetails', show: true },
        { name: 'Logout', link: 'logout', show: true }
      ]
    },
    closeProfileSubmenu(event) {
      if (
        this.showSubMenu &&
        event.target &&
        !event.target._prevClass?.includes('submenu-container') &&
        !event.target._prevClass?.includes('sub-menu-list') &&
        !event.target._prevClass?.includes('sub-menu-item')
      ) {
        this.showSubMenu = false
        document.removeEventListener('click', this.closeProfileSubmenu)
      }
    },
    openSubMenu(e) {
      e.stopPropagation()
      this.showSubMenu = !this.showSubMenu
      this.showSubMenu ? document.addEventListener('click', this.closeProfileSubmenu) : document.removeEventListener('click', this.closeProfileSubmenu)
    },
    handleSearchClearButton() {
      this.$refs.searchbar.value = ''
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async getShortName() {
      let firstName = this.firstname || (await localforage.getItem('my_firstname'))
      let lastName = this.lastname || (await localforage.getItem('my_lastname'))
      firstName = firstName ? firstName[0] : ''
      lastName = lastName ? lastName[0] : ''
      this.shortName = `${firstName}${lastName}`.toUpperCase()
    },
    async navigateTo(link) {
      if (link !== 'logout') {
        if (this.$route.path !== '/profile') this.$router.push({ name: 'Profile', params: { id: link } })
        else this.$eventBus.$emit('profile-sub-link-clicked', link)
      } else {
        await localforage.setItem('logoutFromSSO', document.cookie.includes('ProAuthSSO='))
        await this.$learnAdmin.logout({ token: this.authToken })
        await this.logout()
      }
      document.removeEventListener('click', this.closeProfileSubmenu)
      this.showSubMenu = false
    },
    async onClickDirectMatch(item) {
      this.$refs.searchbar.value = ''
      this.showShortTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
      if (['article', 'video', 'audio', 'podcast'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Content', params: { id: item.content.id, title: item.content.title } })
      } else if (['asset'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Asset', params: { id: item.content.id, asset: item.content } })
      } else if (['course'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Course', params: { id: item.content.id, pgrTrk: false } })
      } else if (['track'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Collection', params: { id: item.content.guid, pgrTrk: false } })
      } else {
        this.$router.push({ name: 'Credential', params: { id: item.content.id, pgrTrk: false } })
      }
      const learnerId = await localforage.getItem('my_user_id')
      const value = {
        'learner-id': learnerId,
        'content-id': item.content.id,
        'content-type': item.content.format.name
      }
      const payload = {
        action: 'Search TypeAhead Click',
        category: 'Select Match Item',
        value: JSON.stringify(value)
      }
      await this.GAEventTrack(payload)
    },
    async onClickTypeAhead(item) {
      this.showShortTypeahead = false
      this.autoSuggestList = []
      const query = item.text.trim()
      if (query.length > 0) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const itemGA = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          query: query,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'Global Search',
          category: 'Search Query',
          value: JSON.stringify(itemGA)
        }
        await this.GAEventTrack(payload)
        const rquery = item.category.toLowerCase() === 'skill' ? { q: query, suggestionSkill: item.text } : { q: query }
        this.$router.push({ path: '/search', query: rquery })
        this.$refs.searchbar.value = ''
      }
      this.currentSearchQuery = ''
    },
    async handleSuggestionInput(event) {
      if (event.keyCode != 13 && this.$refs.searchbar && this.$refs.searchbar.value && this.autoSuggestFlagEnabled) {
        this.currentSearchQuery = this.$refs.searchbar.value
        const response = await this.$learnAdmin.searchAutoComplete({
          token: this.authToken,
          query: encodeURIComponent(this.$refs.searchbar.value)
        })
        if (!this.showShortTypeahead) return
        this.autoSuggestList = response

        var reg = new RegExp(this.currentSearchQuery, 'gi')
        if (this.autoSuggestList.length > 0) {
          this.autoSuggestList.forEach(item => {
            item.displayText = item.text.replace(reg, function(str) {
              return '<span style="font-weight:normal; font-family: Arial; color: #5C5C5C; top: unset !important">' + str + '</span>'
            })
            if (item.content?.format.name.toLowerCase() == 'asset') {
              item.imageUri = this.fetchAssetThumbnail(item.content.inventoryType)
              item.applications = item.content.applications && item.content.applications.length ? item.content.applications : []
            }
          })
        }
      } else if (!this.$refs.searchbar.value) {
        this.autoSuggestList = []
        this.currentSearchQuery = ''
      }
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    onSearchCancel() {
      this.showShortTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async onSearchbarFocus() {
      const lookingForFilterList = await this.$learnAdmin.getLookingForFilters({ token: this.authToken })
      this.lookingForFilters = lookingForFilterList
      this.showShortTypeahead = true
      if (!this.userAccess.canAccessCourses) this.lookingForFilters = this.lookingForFilters.filter(v => v !== 'Course')
      if (!this.userAccess.canAccessCredentials) this.lookingForFilters = this.lookingForFilters.filter(v => v !== 'Credential')
      if (!this.userAccess.canAccessRWL) this.lookingForFilters = this.lookingForFilters.filter(v => v !== 'Article' || v !== 'Audio' || v !== 'Video')
      if (!this.userAccess.assetAccess) this.lookingForFilters = this.lookingForFilters.filter(v => v !== 'Asset')
      EventBus.$emit('search-bar-on-focus')
      const learnerId = await localforage.getItem('my_user_id')
      const payload = {
        action: 'Search TypeAhead Click',
        category: 'Learner ID',
        value: learnerId
      }
      await this.GAEventTrack(payload)
    },
    async applyContentTypeFilter(type) {
      const query = ''
      const learnerId = await localforage.getItem('my_user_id')
      const payloadQuery = {
        action: 'I am looking for',
        category: 'Search Query',
        value: query.length ? query : type
      }
      const payloadLID = {
        action: 'I am looking for',
        category: 'Search Query - Learner ID',
        value: learnerId
      }
      await this.GAEventTrack(payloadQuery)
      await this.GAEventTrack(payloadLID)
      this.$router.push({ path: '/search', query: { q: query, type: type } }).catch(() => {
        window.location.reload()
      })
      this.$refs.searchbar.value = ''
      this.showShortTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async mountAction() {
      /*
       * Enable/Disable Global Search's Rule
       * It depends on globalSearchEnabled from tenant settings
       */
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      let globalSearchEnabled = await localforage.getItem('global-search-enabled')
      globalSearchEnabled = globalSearchEnabled == null ? false : globalSearchEnabled
      this.isSearchable = globalSearchEnabled
      /* Enable/Disable Auto Suggest */
      this.autoSuggestFlagEnabled = await localforage.getItem('show-search-suggest')
      this.lookingForFlagEnabled = await localforage.getItem('show-search-looking-for')
      /* Enable/Disable Tenant Logo */
      const isTenantCustomInfoAvailable = enabledFeatureFlags['learn_tenant-custom-configuration'] ? enabledFeatureFlags['learn_tenant-custom-configuration'] : false
      this.tenantCustomInfo.logoUri =
        isTenantCustomInfoAvailable && {}.hasOwnProperty.call(this.tenant, 'logo') && this.tenant.logo && this.tenant.logo.length
          ? `https://mjnovs.proedge.pwc.com/dev/tenant-logo/${this.tenant.logo}`
          : ''
    },
    toggleSideMenu() {
      this.$emit('toggle-side-menu')
    },
    goToHome() {
      if (this.$route.path !== '/home') {
        this.$router.push({ name: 'Home', params: { showInProgress: true } }).catch(() => {})
      }
    },
    goToMyLearning() {
      if (!this.$route.path.includes('/mylearning')) {
        const type = this.requiredLearningEnabled ? 'required' : 'recommended'
        this.$router.push({ name: 'MyLearning', params: { type: type, isNotFromDeepLink: true } }).catch(() => {})
      }
    },
    goToExplore() {
      if (this.$route.path !== '/explore') {
        this.$router.push('/explore').catch(() => {})
      }
    },
    goToBookmarks() {
      this.autoSuggestBackdrop()
      if (this.$route.path !== '/bookmarks') {
        this.$router.push('/bookmarks').catch(() => {})
      }
    },
    getImageUri(item) {
      let imageUri = ''
      if (item.content.format.name.toLowerCase() != 'asset' && item.content.format.name.toLowerCase() != 'track') {
        imageUri = {}.hasOwnProperty.call(item.content, 'image') ? item.content.image.uri : this.getDefaultImage(item)
      } else {
        imageUri = {}.hasOwnProperty.call(item, 'imageUri') ? item.imageUri : this.getDefaultImage(item)
      }
      return imageUri
    },
    getDefaultImage(item) {
      if (['article', 'video', 'audio', 'podcast', 'course', 'track'].includes(item.content.format.name.toLowerCase())) {
        return require(`@/assets/images/${item.content.format.name.toLowerCase()}.png`)
      }
    },
    async handleSearchInput(event) {
      const query = event.target.value.trim()
      if (query.length > 0) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const item = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          query: query,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'Global Search',
          category: 'Search Query',
          value: JSON.stringify(item)
        }
        await this.GAEventTrack(payload)
        this.$router.push({ path: '/search', query: { q: query } }).catch(() => {
          event.target.blur() // removing focus if same query is re-searched
        })
        this.$refs.searchbar.value = ''
      }
      this.showShortTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async handleSearchSeeAll() {
      const query = this.currentSearchQuery
      if (query.length > 0) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const item = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          query: query,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'Global Search',
          category: 'Search Query',
          value: JSON.stringify(item)
        }
        await this.GAEventTrack(payload)
        this.$router.push({ path: '/search', query: { q: query } })
        this.$refs.searchbar.value = ''
      }
      this.showShortTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.edge-navbar {
  ion-searchbar {
    .searchbar-input.sc-ion-searchbar-md {
      font-family: Arial, Helvetica, sans-serif !important;
      padding-inline-end: 35px !important;
    }

    .searchbar-input-container :-moz-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .searchbar-input-container ::placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .searchbar-input-container ::-moz-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .searchbar-input-container ::-webkit-input-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .searchbar-input-container :-ms-input-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.right-icons-section {
  position: absolute;
  right: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.slide-menu-enter,
.slide-menu-leave-to {
  transform: translateX(-300px);
}

.slide-menu-enter-active,
.slide-menu-leave-active {
  transition: all 0.5s ease;
}

@include block('edge-navbar') {
  display: flex;
  position: relative;
  text-align: left;
  height: 61px;
  padding-top: 6px;
  user-select: none;

  .overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    top: 0;
  }

  ion-searchbar {
    @extend .g-searchbar;
    top: 10px;
    right: 0px !important;
    --box-shadow: 0;
    --background: #efefef !important;
    margin-left: auto;
    margin-right: auto;
  }

  @include element('main') {
    position: relative;
    display: flex;
    cursor: pointer;
  }

  .module {
    margin: -4px 110px 0 15px;
    font-size: 1.7rem;
    span {
      margin-right: 25px;
      font-family: 'Futura PT Demi';
      color: var(--ion-color-tertiary);
    }
  }

  .login {
    position: absolute;
    margin-top: 5px;
    right: 140px;
    float: right;
  }
}

ion-searchbar {
  --border-radius: 20px;
}

.full-searchbar {
  display: inline !important;
  top: 0px !important;
  width: 500px !important;
  left: 15% !important;
}

.short-searchbar {
  display: inline !important;
  top: 0px !important;
  width: 405px !important;
  right: unset !important;
}

.searchbar {
  ion-searchbar {
    margin-right: 290px !important;
  }
}

.search-icon {
  margin: 0 !important;
  position: absolute !important;
  right: 132px !important;
  top: 9px !important;
  height: 40px;
  width: 40px;
  border: 1px solid var(--ion-color-dark-grey);
  border-radius: 50%;
  padding-top: 1px;
  color: unset;
  text-decoration: unset;
  background-color: unset;
}

.bookmark-button,
.bookmark-button-with-logo {
  color: unset;
  text-decoration: unset;
  margin: unset;
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 1px solid var(--ion-color-dark-grey);
  margin-right: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookmark-button {
  right: 70px;
}
.profile-dropdown-container {
  color: unset;
  margin: unset;
  position: absolute;
  right: 30px;
  &.with-logo {
    right: 0;
  }
  .profile-button,
  .profile-button-with-logo {
    background: #1f3370;
    text-decoration: none;
    color: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 1px solid var(--ion-color-dark-grey);
    margin-right: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &.sub-menu-opened {
      border: 2px solid #1dd1b0;
    }
    span {
      font: normal normal normal 16px/40px 'Futura PT Demi';
    }
  }

  .submenu-container {
    position: absolute;
    width: 14rem;
    background: #fff;
    color: #1f3370;
    top: 45px;
    right: 10px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
    ul {
      padding: 2rem 3rem;
      margin: 0;
      li {
        list-style: none;
        font: normal normal normal 16px/18px Arial;
        margin-bottom: 2rem;
        cursor: pointer;
        a {
          text-decoration: none;
          font: normal normal normal 16px/18px Arial;
          color: #1f3370;
          &.bold-text {
            font-weight: bold;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          text-decoration: underline;
          background: transparent;
        }
      }
    }
  }
}
.bookmark-button-with-logo {
  right: 40px;
}

.profile-button-with-logo {
  right: 10px;
  background: #1f3370;
  color: #fff;
}
.tenant-custom-logo {
  right: 10px;
  background: transparent !important;
  width: fit-content;
  height: 36px;
  margin: 0 20px;
  img {
    max-width: 119px;
    height: 36px;
  }
}

.menu-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  margin-right: 0px;
  padding-bottom: 3px;
}
.menu-button-icon {
  height: 30px !important;
  width: 30px !important;
  margin: 0px 35px 0px 20px;
}
.logo-link {
  color: unset;
  text-decoration: unset;
  margin: unset;
}
.logo-icon {
  height: 30px !important;
  width: 114px !important;
  margin: 9px 0px 0px 0px;
}
.topnav-link {
  color: unset;
  text-decoration: unset;
  padding: 0 10px;
  margin: 15px 0px 0px 32px;
  font-family: 'Arial';
  color: #5c5c5c;
  font-size: 1rem;
  &:last-child {
    margin: 15px 15px 0px 32px;
  }
}
.selected-link {
  padding: 0 10px;
  padding-bottom: 18px;
  border-bottom: 5px solid var(--ion-color-secondary);
  font-weight: bold;
}
.topnav-link-container {
  margin: 14px 0px 0px 16px;
}
.bookmark-icon {
  height: 18px !important;
  width: 18px !important;
}

.short-typeahead {
  top: 50px;
  border-radius: 20px;
  background-color: var(--ion-color-white);
  position: absolute;
  width: 400px;
  margin-right: 200px !important;
  right: 90px !important;
}

.content-type-pill {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 11px;
  border: 2px solid #1f3370;
  border-radius: 23px;
  display: inline-block;
  height: 30px;
  padding: 6px 10px;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
}

.content-type-pill:hover {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 11px;
  border: 2px solid #1f3370;
  background-color: #1f3370;
  color: white;
  border-radius: 23px;
  display: inline-block;
  height: 30px;
  padding: 6px 10px;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
}

.typeahead-header {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  color: var(--ion-color-black);
}

.typeahead-search-icon {
  display: inline-block;
}

.typeahead-search-result {
  display: inline-block;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  color: black;
  margin: 0px;
}

.typeahead-search-result-category {
  font-family: 'Arial';
  color: #5c5c5c;
  font-size: 12px;
  margin: 4px 0px 0px 0px;
  text-transform: capitalize;
}

.typeahead-search-result-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.typeahead-results-container {
  border-bottom: 1px solid #9f9f9f;
  overflow-y: scroll;
  height: 50vh;
}

.typeahead-see-all {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  color: var(--ion-color-dark-blue);
  cursor: pointer;
}

.typeahead-result-row {
  cursor: pointer;
  padding: 0px 16px;
}

.typeahead-result-row:hover {
  cursor: pointer;
  background-color: var(--ion-color-blue-light);
  padding: 0px 16px;
}

.typeahead-main-component {
  padding: 0px;
}

.home-img-search {
  margin-left: -10px;
  top: 12%;
  left: calc((100% - 80px) / 2);
  width: 40px;
  height: 40px;
  border: 2px solid #e0e1e1;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}

@media screen and (max-width: 830px) {
  .short-searchbar {
    width: 350px !important;
  }
}
@media screen and (max-width: 786px) {
  .short-searchbar {
    width: 290px !important;
  }
}
@media screen and (max-width: 760px) {
  .short-searchbar {
    width: 240px !important;
  }
}

@media screen and (max-width: 1366px) {
  .full-searchbar {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .topnav-link-container {
    display: none !important;
  }
  .tenant-custom-logo {
    margin: 0 20px 0 16px;
  }
}
</style>
