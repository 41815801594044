<template>
  <div ref="top" :class="isDesktopPlatform ? '' : 'non-white-background'">
    <div v-if="showConfirmationProgress" class="a-overlay" @click="onMarkCompleteAction(false)"></div>
    <div class="b-modal confirm-box" v-if="showConfirmationProgress">
      <h3>Are you sure?</h3>
      <p>Are you sure you want to mark the course completed?</p>
      <div class="handler">
        <ion-button data-cy="confirm-progress" class="g-button--primary ion-button-primary" color="primary" @click="onMarkCompleteAction(true)">Confirm</ion-button>
        <a href="javascript:;" @click="onMarkCompleteAction(false)">Cancel</a>
      </div>
    </div>
    <div v-if="dataReady">
      <ion-grid v-if="isContentAvailable" class="ion-no-padding ion-padding-bottom" :class="isDesktopPlatform ? 'desktop-course-container' : 'mobile-course-container'">
        <ion-row>
          <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
            <div class="back-btn-container">
              <button @click="$router.go(-1)" class="accessible-button back" :class="$platform === 'Desktop' ? 'back-desktop' : 'back-mobile'">
                <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
                Back
              </button>
            </div>
            <ion-row class="course-info">
              <ion-col class="course-img-background">
                <img v-if="{}.hasOwnProperty.call(course.meta, 'cdnEnabled') && course.meta.cdnEnabled && course.image.signedRelativeUri" :src="course.image.signedRelativeUri" />
                <img v-else-if="course.image.uri" :src="course.image.uri" />
                <img v-else src="@/assets/images/course.png" />
              </ion-col>
              <ion-col>
                <div class="meta">
                  <div class="category">
                    <div class="format">{{ course.format && course.format.name }}</div>
                    <div class="provider">{{ course.provider && course.provider.name }}</div>
                  </div>
                  <!-- <div v-if="course.progress"> -->

                  <!-- </div> -->
                </div>
                <div class="title" @click="onLaunch">{{ course.title }}</div>
                <div class="meta-info">
                  <div v-if="course.rating" class="course-rating" @click="onScroll">
                    <span :style="isDesktopPlatform ? 'font-family: Arial' : 'font-family: Arial; margin-right: 5px;'">{{ course.rating }}</span>
                    <StarRating
                      inactive-color="#d0cfce"
                      active-color="#b98032"
                      :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                      :rating="course.rating"
                      :read-only="true"
                      :increment="0.1"
                      :star-size="14"
                      :show-rating="false"
                      :round-start-rating="true"
                      class="star-rating"
                    />
                    <svg-icon v-if="!isDesktopPlatform" icon-class="star-filled-desktop" class="display-rating-mobile" />
                    <span class="rating-count">{{ course.ratingCount }} {{ course.ratingCount > 1 ? 'ratings' : 'rating' }}</span>
                  </div>
                  <div class="duration">{{ course.duration | formatDuration }}</div>
                  <div v-if="course.duration" class="level">{{ course.level }}</div>
                  <div v-if="course.publishedAt" class="publish-date">{{ course.publishedAt | formatPublishedDate }}</div>
                </div>
                <div class="meta-action">
                  <ion-button class="launch-btn" shape="round" color="primary" @click="onLaunch">{{ course.launchBtn }}</ion-button>
                  <div class="bookmark-container">
                    <Bookmark class="course-bookmark" :assetId="course.id" assetType="artifacts" :meta="course.meta" />
                    <span v-if="isDesktopPlatform" class="bookmark-tooltip-text">Bookmark</span>
                  </div>
                  <div class="share-container">
                    <button class="social-share" aria-labelledby="share-btn" @click="popoverOn('share')"><svg-icon icon-class="click-to-copy" class-name="g-icon--small g-clickable" /></button>
                    <SocialSharePopover
                      v-if="isShareOpen"
                      class="social-share-popover"
                      :assetId="courseId"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      fromPage="Universal card"
                      size="lg"
                      @dismiss-share-action-model="popoverOff('share')"
                    />
                    <span v-if="!isShareOpen && isDesktopPlatform" id="share-btn" class="share-tooltip-text">Share</span>
                  </div>
                  <div v-if="showAddToTrack" class="more-container">
                    <button class="more-action" aria-labelledby="more-btn" @click="popoverOn('more')"><svg-icon icon-class="more-action" class-name="g-icon--small g-clickable" /></button>
                    <MoreActionPopover
                      v-if="isMoreOpen"
                      class="more-popover"
                      :item="course"
                      :assetId="courseId"
                      :assetType="course.format.name"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      :showEditDelete="false"
                      :showAddToTrack="true"
                      size="lg"
                      fromPage="Universal card"
                      @add-to-track="popoverOff('more')"
                      @close-more="popoverOff('more')"
                    />
                    <span v-if="!isMoreOpen" id="more-btn" class="more-tooltip-text">More</span>
                  </div>
                  <div v-if="course.progress">
                    <div v-if="course.progress > 0 && course.progress < 100" class="progress">
                      <radial-progress-bar
                        v-if="!course.selfProgressDeclaration"
                        :diameter="28"
                        :completed-steps="course.progress"
                        :total-steps="course.progress == '99' ? 110 : 100"
                        :startColor="`#000000`"
                        :stopColor="`#000000`"
                        :innerStrokeColor="`#e0e1e1`"
                        :strokeWidth="4"
                        :innerStrokeWidth="5"
                      >
                      </radial-progress-bar>
                      <svg-icon v-else icon-class="progress" />
                      <span v-if="!course.selfProgressDeclaration"
                        >{{ course.progress }}%
                        <span style="margin: 0 24px 0 16px" v-if="course.provider.timeToProgressSyncInHours > 0">Progress updates delayed</span>
                      </span>
                      <span v-else>In progress</span>
                      <span class="progress-tooltip-text" v-if="course.provider.timeToProgressSyncInHours > 0"
                        >Progress updates every {{ course.provider.timeToProgressSyncInHours }} hours</span
                      >
                    </div>
                    <div v-else-if="course.completed || course.progress == 100" class="progress">
                      <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                      <span>completed</span>
                      <span class="progress-tooltip-text" v-if="course.provider.timeToProgressSyncInHours > 0"
                        >Progress updates every {{ course.provider.timeToProgressSyncInHours }} hours</span
                      >
                    </div>
                  </div>
                  <div v-else-if="(course.showProgressDelayMsg || course.isLaunched) && course.provider.timeToProgressSyncInHours > 0" class="progress">
                    <span class="progress-update">Progress updates delayed</span>
                  </div>
                  <ion-button
                    v-if="course.selfProgressDeclaration && course.isLaunched && course.progress != 100"
                    :class="course.progress === 0 || course.provider.timeToProgressSyncInHours > 0 ? 'complete-btn' : 'complete-btn complete-btn-margin-left'"
                    shape="round"
                    color="primary"
                    @click="onShowMarkComplete"
                    >Mark complete
                  </ion-button>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <div class="course-about-container">
          <ion-row class="course-carousel-desktop ion-no-padding">
            <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
              <CourseAbout :course="course" :relatedCourses="course.relatedCourses" :requiredLearningPlanItems="requiredLearningPlanItems" />
              <ItemRating :item="course" :scroll="scroll" :ratingDetails="ratingDetails" :getUpdatedItemData="getUpdatedCourseData" @update-ratings="getRatingById()" />
              <RelatedContent v-if="relatedContent.length > 0" :relatedContent="relatedContent" />
            </ion-col>
            <ion-col v-if="isMetaDataVisible" size-xl="3.5">
              <ItemMetaData :item="course" />
            </ion-col>
          </ion-row>
          <ion-row class="course-carousel-mobile">
            <ion-col v-if="isMetaDataVisible" size="12">
              <ItemMetaData :item="course">
                <CourseAbout :course="course" :relatedCourses="course.relatedCourses" :requiredLearningPlanItems="requiredLearningPlanItems" slot="item-about" />
                <ItemRating
                  :item="course"
                  :scroll="scroll"
                  :ratingDetails="ratingDetails"
                  :getUpdatedItemData="getUpdatedCourseData"
                  slot="item-rating"
                  @update-ratings="getRatingById()"
                />
                <RelatedContent v-if="relatedContent.length > 0" slot="related-content" :relatedContent="relatedContent" />
              </ItemMetaData>
            </ion-col>
            <ion-col v-else size="12">
              <CourseAbout :course="course" :relatedCourses="course.relatedCourses" :requiredLearningPlanItems="requiredLearningPlanItems" />
              <ItemRating :item="course" :ratingDetails="ratingDetails" :getUpdatedItemData="getUpdatedCourseData" :scroll="scroll" @update-ratings="getRatingById()" />
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
      <ion-grid v-else :style="isDesktopPlatform ? '' : 'padding: 2rem 10px 6rem;'" class="ion-no-padding">
        <PageNotFound />
      </ion-grid>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'
import { mapGetters } from 'vuex'
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import localforage from 'localforage'
import moment from 'moment-timezone'
import SvgIcon from '@/components/molecules/SvgIcon'
import Bookmark from '@/components/molecules/Bookmark'
import CourseAbout from '@/views/course/components/CourseAbout'
import ItemRating from '@/components/molecules/ItemRating'
import ItemMetaData from '@/components/molecules/ItemMetaData'
import RelatedContent from '@/components/molecules/RelatedContent'
import StarRating from 'vue-star-rating'
import { Plugins } from '@capacitor/core'
import RadialProgressBar from 'vue-radial-progress'
import MoreActionPopover from '@/components/molecules/MoreActionPopover'
import SocialSharePopover from '@/components/molecules/SocialSharePopover'
import PageNotFound from '@/components/molecules/PageNotFound'
const { Browser } = Plugins

export default {
  name: 'Course',
  components: { SvgIcon, Bookmark, ItemMetaData, CourseAbout, ItemRating, StarRating, RadialProgressBar, MoreActionPopover, SocialSharePopover, PageNotFound, RelatedContent },
  props: {},
  data() {
    return {
      authToken: '',
      courseId: '',
      course: null,
      scroll: 0,
      maxRating: 5,
      showConfirmationProgress: false,
      isMetaDataVisible: true,
      progressTracking: true,
      isDesktopPlatform: true,
      dataReady: false,
      isShareOpen: false,
      isMoreOpen: false,
      isContentAvailable: false,
      showAddToTrack: false,
      progressUpdateDelayedItems: [],
      ratingDetails: {},
      relatedContent: [],
      requiredLearningPlanItems: {}
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'sso_token', 'sso_bouncer', 'user_access']),
    onCompletedDate: function () {
      const completedAt = {}.hasOwnProperty.call(this.course, 'completedAt') ? this.course.completedAt : this.course.meta.completedAt
      const date = new Date(completedAt)
      const duration = new Date() - date
      const days = Math.ceil(duration / (1000 * 60 * 60 * 24))
      if (days == 1) return 'today'
      else if (days == 2) return 'yesterday'
      else if (days > 2 && days < 7) return `${days} days ago`
      else return date.toLocaleDateString('en-US')
    }
  },
  watch: {
    async $route() {
      document.getElementById('contentlayout').classList.remove('no-scroll')
    }
  },
  created() {},
  async mounted() {
    this.progressTracking = {}.hasOwnProperty.call(this.$route.params, 'pgrTrk') ? this.$route.params.pgrTrk : true
    this.showAddToTrack = await localforage.getItem('show-add-to-track')
    this.progressUpdateDelayedItems = await localforage.getItem('progress-update-delayed-items')
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.isMetaDataVisible = enabledFeatureFlags['learn_course-meta-data-enabled'] ? enabledFeatureFlags['learn_course-meta-data-enabled'] : false
    this.$refs.top.scrollIntoView()
    await this.setupCourse()
    await this.onPrepareGAPayload('Viewed')
  },
  async activated() {
    this.$refs.top.scrollIntoView()
    await this.getRatingById()
    await this.getRequiredLearningPlanItems()
    await this.setupCourse()
  },
  filters: {
    formatDuration(duration) {
      return duration < 3600 ? `${Math.round(duration / 60)} min` : `${Math.round(duration / 3600)} hrs`
    },
    formatPublishedDate(d) {
      if (!d) return ''
      let date = new Date(d)
      date = date.toDateString().split(' ')
      return `${date[1]} ${date[3]}`.toUpperCase()
    }
  },
  methods: {
    async getRatingById() {
      const authToken = this.authToken || (await localforage.getItem('my_access_token'))
      this.$learnAdmin.getRatingById({ token: authToken, id: this.$route.params.id || this.courseId }).then((res) => {
        this.ratingDetails = res || null
        this.course.rating = this.ratingDetails !== null && {}.hasOwnProperty.call(this.ratingDetails, 'averageRating') ? this.ratingDetails.averageRating : 0
        this.course.ratingCount = this.ratingDetails !== null && {}.hasOwnProperty.call(this.ratingDetails, 'totalNumberOfUsers') ? this.ratingDetails.totalNumberOfUsers : 0
      })
    },
    async setupCourse() {
      await localforage.removeItem('deep_link_id')
      await localforage.removeItem('deep_link_type')
      this.dataReady = false
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      this.courseId = this.$route.params.id
      const response = await this.$learnAdmin.getContentById({ token: this.authToken, id: this.courseId })
      this.course = response.data
      const ssoToken = this.sso_token || (await localforage.getItem('my_sso_token'))
      const ssoBouncer = this.sso_bouncer || (await localforage.getItem('my_sso_bouncer'))
      this.isContentAvailable = {}.hasOwnProperty.call(response, 'status') && response.status != 400 && response.status != 404 && response.status != 401
      if (this.isContentAvailable) {
        this.course.isSelfHosted = this.course.content && this.course.content.isSelfHosted
        this.course.url = `${ssoBouncer}?redirect_url=${this.course.content.contentUri}&SSO_tk=${ssoToken}`
        this.course.about = this.course.synopsis ? this.course.synopsis : await this.getText(this.course.content.contentUri)
        this.course.provider.timeToProgressSyncInHours = this.course.provider.timeToProgressSyncInHours ? parseInt(this.course.provider.timeToProgressSyncInHours) : 0
        this.course.level = this.course.courseLevels ? this.course.courseLevels[0] : ''
        this.course.isLaunched = this.course.progress > 0 && this.course.progress < 100
        this.course.launchBtn = this.course.isLaunched ? 'Resume' : 'Launch'
        this.course.showProgressDelayMsg = this.progressUpdateDelayedItems ? this.progressUpdateDelayedItems.indexOf(this.courseId) != -1 : false
        this.course.rating = {}.hasOwnProperty.call(this.ratingDetails, 'averageRating') ? this.ratingDetails.averageRating : 0
        this.course.ratingCount = {}.hasOwnProperty.call(this.ratingDetails, 'totalNumberOfUsers') ? this.ratingDetails.totalNumberOfUsers : 0
        const relatedCourses = await this.$learnAdmin.getRelatedTrackContentById({ token: this.authToken, id: this.courseId })
        const relatedContent = await this.$learnAdmin.getRelatedContentById({ token: this.authToken, id: this.courseId })
        this.relatedContent = relatedContent.contents || []
        this.course.relatedCourses = !relatedCourses?.error ? (relatedCourses ? relatedCourses : []) : []
        if (this.relatedContent !== null && this.relatedContent.length > 0) {
          this.relatedContent.map(async (item) => {
            item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : ''
            if (this.requiredLearningPlanItems[item.id]) await this.getDueDate(item, this.requiredLearningPlanItems[item.id])
          })
        }
      }
      this.dataReady = true
      if (this.progressTracking) {
        this.setupClickStreamProgressTracker()
      }
    },
    async getRequiredLearningPlanItems() {
      const authToken = this.authToken || (await localforage.getItem('my_access_token'))
      this.requiredLearningPlanItems = await this.$learnAdmin.getMyLearningPlanRequiredItems({ token: authToken })
      this.requiredLearningPlanItems = this.requiredLearningPlanItems && this.requiredLearningPlanItems.code === 200 ? this.requiredLearningPlanItems.data : {}
    },
    popoverOn(popoverType) {
      this.isShareOpen = popoverType === 'share'
      this.isMoreOpen = popoverType === 'more'
    },
    popoverOff(popoverType) {
      this.isShareOpen = !popoverType === 'share'
      this.isMoreOpen = !popoverType === 'more'
    },
    setupClickStreamProgressTracker() {
      if (this.$route.path.match(/\/course/) == '/course') {
        this.learningRecord = {
          verb: {
            id: window.location.href,
            display: {
              'en-US': `opened-course`
            }
          },
          object: {
            id: window.location.href,
            definition: {
              name: {
                'en-US': this.course.title
              }
            }
          },
          result: {
            completion: false,
            success: false,
            score: {
              scaled: 0
            }
          }
        }
        learningAnalytics.setLearningRecordAndSend(this.learningRecord)
      }
      this.setClickStreamProgressTracker(0)
    },
    async setClickStreamProgressTracker(val) {
      const action = val === 100 ? 'complete' : 'click'
      const isCompleted = val === 100
      analytics.setVarAndTrack({
        c: 'nav',
        a: action,
        l: { page: 'learnCourse', title: this.course.title, provider: this.course.provider.name, ci: this.$route.params.id, pc: val },
        u: this.userId,
        un: this.userEmail
      })
      await this.$learnAdmin.updateContentProgressById({ token: this.authToken, id: this.courseId, body: { isCompleted: isCompleted, progress: val } })
    },
    async getText(uri) {
      const response = await fetch(uri)
      const content = await response.text()
      return marked.parse(content)
    },
    getRouteInfo() {
      const name = this.course.format.name.toLowerCase() == 'course' ? 'Course' : 'Credential'
      const params = { id: this.course.id, pgrTrk: false }
      return { name: name, params: params }
    },
    async getUpdatedCourseData() {
      this.dataReady = false
      let course = await this.$learnAdmin.getContentById({ token: this.authToken, id: this.courseId })
      course = course.data
      this.course = { ...this.course, ...course }
      this.course.ratingCount = 0
      this.course.ratingCounts = {}.hasOwnProperty.call(this.course, 'ratingCounts') ? this.course.ratingCounts : []
      if (this.course.ratingCounts.length) {
        await this.course.ratingCounts.map((r) => {
          this.course.ratingCount += r.count
        })
      }
      this.dataReady = true
    },
    async onLaunch() {
      this.dataReady = false
      this.course.isLaunched = true
      if (this.course.selfProgressDeclaration) {
        await this.setClickStreamProgressTracker(50)
        this.course.progress = 50
      }
      await this.setUpdateProgressDelay()
      this.course.url = this.course.isSelfHosted ? await this.$learnAdmin.fetchCourseUrl({ token: this.authToken, id: this.courseId }) : this.course.url
      await Browser.open({ url: this.course.url })
      learningAnalytics.setLearningRecordAndSend(this.learningRecord)
      this.dataReady = true
    },
    onScroll() {
      this.scroll = this.scroll + 1
    },
    onShowMarkComplete() {
      this.showConfirmationProgress = true
    },
    async onMarkCompleteAction(isConfirm) {
      if (isConfirm) {
        await this.setClickStreamProgressTracker(100)
        let courseResult = await this.$learnAdmin.getContentById({ token: this.authToken, id: this.courseId })
        courseResult = courseResult.data
        this.course.completedAt = {}.hasOwnProperty.call(courseResult, 'completedAt') ? courseResult.completedAt : courseResult.meta?.completedAt
        this.course.progress = courseResult.progress
        this.course.launchBtn = 'Launch'
        this.course.completed = true
      }
      document.getElementById('contentlayout').classList.remove('no-scroll')
      this.showConfirmationProgress = false
    },
    setUpdateProgressDelay() {
      this.progressUpdateDelayedItems = this.progressUpdateDelayedItems ? this.progressUpdateDelayedItems : []
      if (this.course.provider.timeToProgressSyncInHours > 0 && this.progressUpdateDelayedItems.indexOf(this.courseId) === -1) {
        this.progressUpdateDelayedItems.push(this.courseId)
      }
      localforage.setItem('progress-update-delayed-items', this.progressUpdateDelayedItems)
    },
    async getDueDate(item, dueDate) {
      const formattedToday = moment(dueDate).tz('America/New_York').format('DD MMM YYYY')
      if (item.timeToProgressSyncInHours > 0) {
        moment(dueDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
          ? (item.dueDate = formattedToday)
          : (item.pastDueDate = formattedToday)
      } else {
        moment(dueDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
      }
    },
    async onPrepareGAPayload(category) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'content-id': this.courseId,
        'content-title': this.course.title,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'Course Landing Page',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.non-white-background {
  background: #efefef;
}

.course-carousel-mobile {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .course-carousel-desktop {
    display: none !important;
  }
  .course-carousel-mobile {
    display: block !important;
  }
}

.desktop-course-container {
  padding: 2rem 3.8rem;
  max-width: 1700px;
}

.mobile-course-container {
  padding: 2rem 1rem;
}

.coursera-link {
  padding: 0.5rem 4rem;
  background: var(--ion-color-secondary);
  color: var(--ion-color-tertiary);
  border: 1px solid var(--ion-color-secondary);
  border-radius: 2rem;
  text-decoration: none;
  font-family: 'Futura PT Book', sans-serif;
  font-size: 1rem;
}

.back-btn-container {
  width: 100%;
  text-align: left;
  color: var(--ion-color-medium);
}

.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Arial';
  color: #5c5c5c;
  svg {
    position: relative;
    top: 5px;
  }
}

.back-desktop {
  font-size: 14px;
}

.back-mobile {
  font-size: 12px;
}

.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  padding: unset;
}

.course-info {
  display: flex;
  margin-top: 2rem;
  gap: 60px;
}

.course-img-background {
  position: relative;
  max-width: 165px;
  border: 4px solid var(--ion-color-white);
  overflow: hidden;
  width: 165px;
  height: 165px;
  border: 5px solid var(--ion-color-white);
  background-size: contain;
  border-radius: 20px;
  img {
    width: 165px;
    height: 165px;
    object-fit: cover;
  }
}

.meta {
  display: flex;
  gap: 24px;
  text-transform: uppercase;
  color: var(--ion-color-black);
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
  letter-spacing: 1.2px;
  align-items: center;
  .category {
    display: flex;
    gap: 8px;
  }
  .format {
    padding: 0.32rem 0.5rem;
    border-radius: 10px;
    background: #e0e1e1;
    font-family: 'Arial';
    text-transform: capitalize;
    color: #2d2d2d;
  }
  .g-button--primary {
    width: 7rem;
    margin-right: 1rem;
  }
  .provider {
    align-self: center;
    font-family: 'Arial';
    text-transform: capitalize;
    color: #2d2d2d;
  }
}

.course-about-container {
  margin-top: 3.5rem;
}

.title {
  margin: 1.5rem 0rem;
  text-align: left;
  font-size: 48px;
  font-family: 'Futura PT Demi';
  color: #000000;
  cursor: pointer;
  line-height: 45px;
}

.meta-info {
  display: flex;
  gap: 24px;
  font-size: 14px;
  margin-bottom: 1.5rem;
  align-items: center;
  .course-rating {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #2d2d2d;
  }
  .star-rating {
    position: relative;
    font-family: Arial;
    margin: 0 8px;
    color: #000000;
  }
  .rating-count {
    font-family: Arial;
    color: #2d2d2d;
  }
  .duration,
  .level,
  .publish-date {
    font-family: Arial;
    color: #2d2d2d;
  }
}

.meta-action {
  display: flex;
  gap: 8px;
  max-height: 60px;
  align-items: center;
  flex-wrap: wrap;
  .launch-btn,
  .complete-btn {
    font-size: 15px;
    width: 154px;
    height: 40px;
    margin-right: 1.5rem;
    text-transform: none;
    font-family: 'Futura PT Demi';
    --ion-color-primary: #feb92e;
    --ion-color-primary-contrast: black;
    --box-shadow: unset;
  }
  .complete-btn {
    --ion-color-primary: #1f3370;
    --ion-color-primary-contrast: #ffffff;
    --box-shadow: unset;
  }
  .complete-btn-margin-left {
    margin-left: 1.5rem;
  }
  .course-bookmark,
  .social-share,
  .more-action {
    width: 40px;
    height: 40px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon {
      width: 1.5rem;
    }
  }

  .bookmark-container,
  .more-container,
  .share-container {
    position: relative;
    .bookmark-tooltip-text,
    .more-tooltip-text,
    .share-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      right: 0;
    }
    .bookmark-tooltip-text {
      right: -20px;
    }
  }
  .bookmark-container:hover .bookmark-tooltip-text {
    visibility: visible;
  }
  .share-container:hover .share-tooltip-text {
    visibility: visible;
  }
  .more-container:hover .more-tooltip-text {
    visibility: visible;
  }

  .progress {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
    margin-left: 1.5rem;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
    span {
      font-family: 'Arial';
      font-size: 14px;
      font-weight: normal;
      color: #2d2d2d;
      text-align: left;
      text-transform: capitalize;
    }
    .progress-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      width: 240px;
    }
  }

  .progress:hover .progress-tooltip-text {
    visibility: visible;
  }

  .progress-update {
    text-align: left;
    text-transform: none;
    margin: 0 24px 0 0;
  }
}

.social-share-popover,
.more-popover {
  position: absolute;
  box-shadow: 0px 0px 12px #0000001f;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px;
  z-index: 10;
  opacity: 0;
  margin-top: 10px;
  animation-name: slideinmore;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  text-align: left;
}

@keyframes slideinmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -217px;
  }
}

.display-rating-mobile {
  position: relative;
  top: -2px;
  margin-right: 5px;
  width: 0.8rem;
  height: 0.8rem;
}

@media screen and (max-width: 1366px) {
  .course-img-background {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .meta-action {
    max-height: 100px;
  }
}

@media screen and (max-width: 484px) {
  .title {
    margin: 0.5rem 0rem;
    font-size: 34px;
    line-height: 40px;
  }
  .meta-info {
    margin-bottom: 20px;
    .course-rating {
      display: flex;
      .star-rating {
        display: none;
      }
      display: inline;
    }
  }
  .meta-action {
    flex-wrap: wrap;
    max-height: 150px;
    .complete-btn {
      width: 140px;
    }
    .launch-btn {
      width: 140px;
      margin-right: 1rem;
    }
    .progress {
      margin: 16px 0;
    }
  }
}

@media screen and (max-width: 375px) {
  .meta-info {
    gap: 5px;
    .duration,
    .level,
    .rating-count {
      padding-right: 5px;
    }
  }
}

.mobile-overlay {
  height: 150vh;
}
</style>
