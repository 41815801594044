<template>
  <div v-if="dataReady" class="asset-submission-container main-content">
    <div class="submit-asset-header-content">
      <h2>Your asset submissions</h2>
      <a href="#" v-if="assetList.content && assetList.content.length && isDesktopPlatform" shape="round" color="primary" class="submit-asset-btn" @click="goToCreateAsset"
        >Submit a new asset</a
      >
    </div>
    <div class="asset-list-container">
      <div v-if="assetList.content && assetList.content.length">
        <AssetList :assetList="assetList.content" :pagination="paginationSettings()"></AssetList>
      </div>
      <div class="submit-asset-no-results" v-else>
        <div class="submit-asset-no-results-text-content">
          <h1>Ready to submit your first asset</h1>
          <p>
            How exciting! Get started below and we'll pass along the details to the reviewers in your organization. If approved, your asset will be available to share with your
            colleagues in ProEdge.
          </p>
          <ion-button shape="round" color="primary" v-if="isDesktopPlatform" class="ion-button-primary-dark" @click="goToCreateAsset">Submit a new asset</ion-button>
          <h3 v-else>Logon to the web application to submit your first asset</h3>
        </div>
        <div class="submit-asset-no-results-img-content">
          <img src="@/assets/images/governance-empty.png" class="recommend-footer-image-desktop" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import AssetList from '@/views/asset/components/AssetList'

export default {
  name: 'AssetSubmission',
  components: { AssetList },
  props: {},
  data() {
    return {
      pageCount: 20,
      dataReady: false,
      assetList: {}
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  created() {},
  mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  async activated() {
    await this.getAssetList(1)
  },
  methods: {
    async getAssetList(pageNo) {
      const authToken = (await localforage.getItem('my_access_token')) || this.auth_token
      const payload = {
        token: authToken,
        pageNo: pageNo,
        pageSize: 0
      }
      this.assetList = await this.$learnAdmin.getAssetList(payload)
      this.dataReady = true
      const GAPayload = await this.onPrepareGAPayload(this.assetList.content.length ? 'Asset submission viewed' : 'Asset submission empty')
      await this.GAEventTrack(GAPayload)
    },
    async goToCreateAsset() {
      this.$router.push('/create-asset').catch(() => {})
      const GAPayload = await this.onPrepareGAPayload('New asset submitted')
      await this.GAEventTrack(GAPayload)
    },
    paginationSettings() {
      return {
        pageCount: Math.ceil(this.assetList.content.length / this.pageCount),
        pageRange: this.pageCount
      }
    },
    async onPrepareGAPayload(action) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        action: action
      }
      const payload = {
        action: action,
        category: 'Asset Submission Landing',
        value: JSON.stringify(item)
      }
      return payload
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.asset-submission-container {
  background: #efefef;
  .asset-list-container {
    padding: 0 4rem 0.5rem 4rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 450px) {
      padding: 0 0.5rem 1rem 0.5rem;
    }
  }

  ion-button {
    --box-shadow: none;
  }

  .submit-asset-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem;
    .submit-asset-btn {
      margin-top: 16px;
      text-transform: none;
      font-family: 'Futura PT Demi';
      text-decoration: none;
      background-color: var(--ion-color-primary-dark);
      padding: 0.7rem 1.7rem;
      border-radius: 2rem;
      color: var(--ion-color-black);
      letter-spacing: 0.8px;
      &:hover {
        filter: brightness(90%);
        text-decoration: underline;
      }
      &:focus {
        text-decoration: underline;
      }
    }
    h2 {
      color: var(--ion-color-tertiary);
      font-size: 3rem;
      font-family: 'Futura PT Demi';
      @media screen and (max-width: 768px) {
        font-size: 2.125rem;
      }
      @media screen and (max-width: 450px) {
        font-size: 1.5rem;
      }
    }
    ion-button {
      margin-top: 16px;
      text-transform: none;
      font-family: 'Futura PT Demi';
    }
    @media screen and (max-width: 450px) {
      padding: 1rem 0.5rem;
    }
  }

  .submit-asset-no-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: calc(100vh - 350px);
    align-items: center;
    .submit-asset-no-results-text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
      padding: 0 6rem;
      text-align: left;
      h1 {
        color: var(--ion-color-tertiary);
        font-size: 3rem;
        font-family: 'Futura PT Demi';
      }
      p {
        font-family: 'Arial';
        font-size: 0.9rem;
        color: var(--ion-color-medium);
      }
      ion-button {
        margin-top: 16px;
        text-transform: none;
        font-family: 'Futura PT Demi';
      }
      @media screen and (max-width: 450px) {
        padding: 0 1rem;
      }
    }

    .submit-asset-no-results-img-content {
      flex: 1;
      justify-content: center;
      img {
        width: 400px;
      }
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }
}
</style>
