var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.completed ? "completed" : "" },
    [
      _c(
        "ion-button",
        {
          staticClass: "edge-complete ion-text-capitalize",
          attrs: {
            "data-cy": "content-complete",
            shape: "round",
            color: "primary"
          },
          on: { click: _vm.onComplete }
        },
        [_vm._v("Mark complete")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }