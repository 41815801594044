var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showNavbar
    ? _c(
        "div",
        { staticClass: "edge-navbar", attrs: { role: "navigation" } },
        [
          _vm.searchBar.state.on &&
          _vm.showShortTypeahead &&
          ((_vm.autoSuggestFlagEnabled &&
            _vm.autoSuggestList.length > 0 &&
            _vm.currentSearchQuery != "") ||
            (_vm.lookingForFlagEnabled && _vm.autoSuggestList.length == 0))
            ? _c("div", {
                staticStyle: {
                  position: "absolute",
                  "background-color": "transparent",
                  height: "100vh",
                  width: "100%",
                  opacity: "0.2"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.autoSuggestBackdrop()
                  }
                }
              })
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "menu-button",
              attrs: { "aria-label": "open side menu" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.toggleSideMenu($event)
                }
              }
            },
            [
              _c("svg-icon", {
                staticClass: "menu-button-icon",
                attrs: { "icon-class": "hamburger-menu-icon" }
              })
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "logo-link",
              attrs: { href: "#", "aria-label": "go to homepage" },
              on: { click: _vm.goToHome }
            },
            [
              _c("svg-icon", {
                staticClass: "logo-icon",
                attrs: { "icon-class": "logo", alt: "proedge logo image" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "topnav-link-container" },
            [
              _c(
                "ion-link",
                {
                  staticClass: "topnav-link",
                  class: _vm.$route.path == "/home" ? "selected-link" : "",
                  attrs: { role: "link", tabindex: "0" },
                  on: { click: _vm.goToHome }
                },
                [_vm._v(" Home ")]
              ),
              _c(
                "ion-link",
                {
                  staticClass: "topnav-link",
                  class: _vm.$route.path.includes("/mylearning")
                    ? "selected-link"
                    : "",
                  attrs: { role: "link", tabindex: "0" },
                  on: { click: _vm.goToMyLearning }
                },
                [_vm._v(" My learning ")]
              ),
              !_vm.hideRWLTenant
                ? _c(
                    "ion-link",
                    {
                      staticClass: "topnav-link",
                      class: _vm.$route.path.includes("/explore")
                        ? "selected-link"
                        : "",
                      attrs: { role: "link", tabindex: "0", href: "#" },
                      on: { click: _vm.goToExplore }
                    },
                    [_vm._v("Explore")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.isSearchable
            ? _c("div", { attrs: { role: "search" } }, [
                _c(
                  "div",
                  { staticClass: "searchbar" },
                  [
                    _c("ion-searchbar", {
                      ref: "searchbar",
                      staticClass: "short-searchbar",
                      staticStyle: {
                        right: "unset",
                        "margin-left": "unset !important"
                      },
                      attrs: {
                        role: "searchbox",
                        "show-cancel-button": "never",
                        placeholder: "Search using titles, topics, and keywords"
                      },
                      on: {
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleSearchInput($event)
                        },
                        focusin: function($event) {
                          return _vm.onSearchbarFocus()
                        },
                        ionCancel: function($event) {
                          return _vm.onSearchCancel()
                        },
                        keyup: _vm.handleSuggestionInput,
                        ionClear: function($event) {
                          return _vm.handleSearchClearButton()
                        }
                      }
                    }),
                    _vm.searchBar.state.on && _vm.showShortTypeahead
                      ? _c(
                          "div",
                          { staticClass: "short-typeahead" },
                          [
                            _vm.autoSuggestFlagEnabled &&
                            _vm.autoSuggestList.length > 0 &&
                            _vm.currentSearchQuery != ""
                              ? _c(
                                  "ion-grid",
                                  {
                                    key:
                                      _vm.autoSuggestList.length +
                                      "main_typeahead",
                                    staticClass: "typeahead-main-component"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "typeahead-results-container"
                                      },
                                      _vm._l(
                                        _vm.autoSuggestList.slice(0, 10),
                                        function(item, index) {
                                          return _c(
                                            "ion-row",
                                            {
                                              key: item + index,
                                              staticClass:
                                                "ion-margin-vertical typeahead-result-row"
                                            },
                                            [
                                              !item.exactMatch &&
                                              item.category != "Skill"
                                                ? _c(
                                                    "ion-col",
                                                    {
                                                      key:
                                                        item.text +
                                                        "searchicon",
                                                      attrs: { size: "1" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onClickTypeAhead(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        staticClass:
                                                          "typeahead-search-icon",
                                                        attrs: {
                                                          "icon-class":
                                                            "icon-search"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !item.exactMatch &&
                                              item.category != "Skill"
                                                ? _c(
                                                    "ion-col",
                                                    {
                                                      key:
                                                        item.text +
                                                        "searchtext",
                                                      staticClass:
                                                        "typeahead-search-result-ellipsis",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onClickTypeAhead(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("p", {
                                                        staticClass:
                                                          "typeahead-search-result",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.displayText
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !item.exactMatch &&
                                              item.category == "Skill"
                                                ? _c(
                                                    "ion-col",
                                                    {
                                                      key:
                                                        item.text + "skillicon",
                                                      attrs: { size: "1" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onClickTypeAhead(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        staticClass:
                                                          "typeahead-search-icon",
                                                        attrs: {
                                                          "icon-class":
                                                            "auto-suggest-skill"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !item.exactMatch &&
                                              item.category == "Skill"
                                                ? _c(
                                                    "ion-col",
                                                    {
                                                      key:
                                                        item.text + "skilltext",
                                                      staticClass:
                                                        "typeahead-search-result-ellipsis",
                                                      staticStyle: {
                                                        "padding-top": "0px",
                                                        "padding-left": "12px"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onClickTypeAhead(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("p", {
                                                        staticClass:
                                                          "typeahead-search-result",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.displayText
                                                          )
                                                        }
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "typeahead-search-result-category"
                                                        },
                                                        [_vm._v("Skill")]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.exactMatch
                                                ? _c(
                                                    "ion-col",
                                                    {
                                                      key: item.text + "image",
                                                      attrs: { size: "1" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onClickDirectMatch(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "home-img-search"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: _vm.getImageUri(
                                                                item
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.exactMatch
                                                ? _c(
                                                    "ion-col",
                                                    {
                                                      key: item.text + "name",
                                                      staticClass:
                                                        "typeahead-search-result-ellipsis",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onClickDirectMatch(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("p", {
                                                        staticClass:
                                                          "typeahead-search-result typeahead-search-result-ellipsis",
                                                        staticStyle: {
                                                          "padding-left": "8px"
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.displayText
                                                          )
                                                        }
                                                      }),
                                                      item.content.format.name.toLowerCase() !==
                                                      "asset"
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "typeahead-search-result-category",
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "8px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.type
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "typeahead-search-result-category",
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "8px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.type
                                                                ) +
                                                                  " | " +
                                                                  _vm._s(
                                                                    item.applications.join(
                                                                      " | "
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c(
                                      "ion-row",
                                      [
                                        _c(
                                          "ion-col",
                                          { staticClass: "ion-text-center" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "typeahead-see-all",
                                                on: {
                                                  click: _vm.handleSearchSeeAll
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  'See all results for "' +
                                                    _vm._s(
                                                      _vm.currentSearchQuery
                                                    ) +
                                                    '"'
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.lookingForFlagEnabled &&
                            _vm.autoSuggestList.length == 0
                              ? _c(
                                  "ion-grid",
                                  {
                                    key:
                                      _vm.autoSuggestList.length +
                                      "looking_for",
                                    staticClass:
                                      "ion-padding looking-for-component"
                                  },
                                  [
                                    _c(
                                      "ion-row",
                                      [
                                        _c("ion-col", [
                                          _c(
                                            "p",
                                            { staticClass: "typeahead-header" },
                                            [_vm._v("Browse by")]
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.lookingForFilters, function(
                                      filter
                                    ) {
                                      return _c(
                                        "ion-row",
                                        {
                                          key: filter,
                                          staticClass:
                                            "ion-align-items-center content-type-pill",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.applyContentTypeFilter(
                                                filter
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(filter) + " ")]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "ion-row",
            { staticClass: "right-icons-section" },
            [
              _c("ion-col", [
                _c(
                  "a",
                  {
                    class: !_vm.tenantCustomInfo.logoUri.length
                      ? "bookmark-button"
                      : "bookmark-button-with-logo",
                    attrs: { href: "#", "aria-label": "Bookmarks" },
                    on: { click: _vm.goToBookmarks }
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "bookmark-icon",
                      attrs: { "icon-class": "icon-bookmark-new" }
                    })
                  ],
                  1
                )
              ]),
              _c("ion-col", [
                _c(
                  "div",
                  {
                    staticClass: "profile-dropdown-container",
                    class: { "with-logo": _vm.tenantCustomInfo.logoUri.length }
                  },
                  [
                    _c(
                      "a",
                      {
                        class: [
                          !_vm.tenantCustomInfo.logoUri.length
                            ? "profile-button"
                            : "profile-button-with-logo",
                          { "sub-menu-opened": _vm.showSubMenu }
                        ],
                        attrs: { href: "#", "aria-label": "Profile" },
                        on: { click: _vm.openSubMenu }
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.shortName))])]
                    ),
                    _vm.showSubMenu
                      ? _c("div", { staticClass: "submenu-container" }, [
                          _c(
                            "ul",
                            { staticClass: "sub-menu-list" },
                            [
                              _vm._l(_vm.subMenuItems, function(subMenuItem) {
                                return [
                                  subMenuItem.show
                                    ? _c(
                                        "li",
                                        {
                                          key: subMenuItem.link,
                                          staticClass: "sub-menu-item",
                                          attrs: { value: subMenuItem.name }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              class: {
                                                "bold-text":
                                                  subMenuItem.name === "Logout"
                                              },
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.navigateTo(
                                                    subMenuItem.link
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(subMenuItem.name) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _vm.tenantCustomInfo.logoUri.length
                ? _c("ion-col", { staticClass: "tenant-custom-logo" }, [
                    _c("img", { attrs: { src: _vm.tenantCustomInfo.logoUri } })
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }