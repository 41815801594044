<template>
  <div class="idea-popover ion-padding-horizontal" style="background-color: white">
    <ion-row class="g-margin__top--small idea-popover__border-bottom">
      <ion-col>
        <div class="popover-title">{{ content[index].title }}</div>
      </ion-col>
      <ion-col size="2">
        <svg-icon icon-class="close" class-name="g-icon--small g-margin__right--msmall" color="#182755" @click="onCancel" />
      </ion-col>
    </ion-row>
    <div class="popover-text">{{ content[index].text }}</div>
  </div>
</template>

<script>
import { popoverController } from '@modus/ionic-vue'

export default {
  name: 'SolutionPopover',
  props: {
    index: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      content: [
        { title: 'At a Glance', text: 'Why is this idea awesome' },
        { title: 'Full Details', text: 'Some sort of description of what you are going to do here' },
        {
          title: 'Bot Type',
          text:
            'Please calculate the impact in terms of money mad or money saved by implementing this idea. Example:This will save me 2 hours per day resulting in 10 hours a week or 40 hour per month saved. My hourly rate is 100 per hour so that will save the client 40 x100x 12 = $48000 saved per year.'
        },
        { title: 'Estimated Financial Impact', text: 'How does this impact the financial district nonfinancially' },
        { title: 'Explore Other Ideas', text: 'How does this impact the financial district nonfinancially' },
        { title: 'Explore Similar Widgets', text: 'How does this impact the financial district nonfinancially' },
        { title: 'Relevant Skills', text: 'How does this impact the financial district nonfinancially' },
        { title: 'Teams', text: 'How does this impact the financial district nonfinancially' }
      ]
    }
  },
  created() {},
  mounted() {},
  methods: {
    onCancel() {
      popoverController.dismiss()
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('idea-popover') {
  border: 2px solid var(--ion-color-orange-shade);

  @include element('border-bottom') {
    border-bottom: 1px solid grey;
  }
}

.popover-title {
  color: var(--ion-color-orange-shade);
  text-align: center;
  font-family: 'Futura PT Demi';
  font-size: 1.1rem;
}

.popover-text {
  padding: 1rem 0rem;
  font-style: italic;
}
</style>
