import axios from 'axios'

export default {

    async sendUserActivity(url, payload) {
        const headers = { Authorization: `Bearer ${payload.token}` }
        const response = await axios.put(url, payload.body, { headers: headers });
        return response;
    },
    async getUserActivity(url, payload) {
        const headers = { Authorization: `Bearer ${payload.token}` }
        const response = await axios.get(url, { headers: headers }).catch(error => {
            return { data: error.response.data }
        })
        return response.data
    },
    async saveHomeGoalSetting(url, payload){
        const headers = { Authorization: payload.token}
        const response = await axios.put(url, payload.body, { headers: headers })
        return response
      }
      
}