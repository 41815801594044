var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ion-app",
    [
      this.$route.meta.mobileHeaderVisible
        ? _c(
            "ion-header",
            { attrs: { "no-border": "", mode: "ios" } },
            [
              _c("NavBarMobile", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isNavBarVisible,
                    expression: "isNavBarVisible"
                  }
                ],
                attrs: { isOpen: _vm.isNavBarOpen },
                on: { handleNavBar: _vm.handleNavBarVisibility }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "ion-content",
        {
          ref: "contentSection",
          attrs: { id: "contentlayout", "scroll-events": true },
          on: {
            ionScroll: function($event) {
              return _vm.onScroll($event)
            }
          }
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _c(
                "keep-alive",
                [_c("router-view", { key: _vm.$route.fullPath })],
                1
              )
            ],
            1
          ),
          this.$route.fullPath !== "/landing" &&
          this.$route.fullPath !== "/help-center-main" &&
          this.$route.fullPath !== "/onboarding/terms-and-conditions" &&
          this.$route.fullPath !== "/onboarding/privacy-policy" &&
          this.$route.fullPath !== "/onboarding/cookie-notice" &&
          this.$route.meta.isMenuPrivacy !== true &&
          this.$route.meta.isMenuTerms !== true
            ? _c(
                "footer",
                {
                  staticClass: "footer",
                  staticStyle: { "text-align": "left", padding: "0 1rem" }
                },
                [
                  _c("div", { staticClass: "copyright-text" }, [
                    _vm._v(
                      " © " +
                        _vm._s(_vm.currentYear) +
                        " PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates. "
                    )
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "copyright-link",
                        on: {
                          click: function($event) {
                            return _vm.openPrivacy()
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "unset",
                              "text-decoration": "unset",
                              "background-color": "unset"
                            },
                            attrs: { href: "#" }
                          },
                          [_vm._v("Privacy Policy")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "copyright-link",
                        on: {
                          click: function($event) {
                            return _vm.openCookieNotice()
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "unset",
                              "text-decoration": "unset",
                              "background-color": "unset"
                            },
                            attrs: { href: "#" }
                          },
                          [_vm._v("Cookie Notice")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "copyright-link",
                        on: {
                          click: function($event) {
                            return _vm.openTerms()
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "unset",
                              "text-decoration": "unset",
                              "background-color": "unset"
                            },
                            attrs: { href: "#" }
                          },
                          [_vm._v("Terms and Conditions")]
                        )
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          this.$route.fullPath === "/onboarding/terms-and-conditions" ||
          this.$route.fullPath === "/onboarding/privacy-policy" ||
          this.$route.fullPath === "/onboarding/cookie-notice"
            ? _c(
                "footer",
                {
                  staticClass: "footer",
                  staticStyle: { "text-align": "left", padding: "0 1rem" }
                },
                [
                  _c("div", { staticClass: "copyright-text" }, [
                    _vm._v(
                      " © " +
                        _vm._s(_vm.currentYear) +
                        " PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates. "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      this.$route.meta.mobileFooterVisible
        ? _c(
            "ion-footer",
            { on: { click: _vm.handleNavBarFooter } },
            [_c("NavBottomTabMobile")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }