var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "recommendedTop",
      staticClass: "main-content",
      style: !_vm.isDesktopPlatform
        ? "background: #efefef; margin-top: 2rem; "
        : "padding: 2rem 3.8rem"
    },
    [
      _c("div", { staticClass: "recommend-banner" }, [
        _c("div", { staticClass: "recommend-banner-add-skill-container" }, [
          _c("div", { staticClass: "recommend-banner-title" }, [
            _vm._v("Explore by Skill")
          ]),
          _c("div", { staticClass: "recommend-banner-content" }, [
            _vm._v("Discover new learning content and digital assets")
          ]),
          _c(
            "button",
            {
              staticClass: "recommend-banner-add-skill-button",
              on: {
                click: function($event) {
                  return _vm.openSkillModal(true)
                }
              }
            },
            [_vm._v("Add skills")]
          )
        ])
      ]),
      _vm.dataReady
        ? _c(
            "div",
            { staticClass: "recommend-container" },
            [
              _c(
                "div",
                { staticClass: "recommend-nav" },
                [
                  _c("RecommendedTab", {
                    key: _vm.selectedTabIndex,
                    attrs: {
                      tabs: _vm.tabs,
                      recommendation: _vm.recommendation,
                      selectedTabIndex: _vm.selectedTabIndex
                    },
                    on: {
                      "open-skill-modal": function($event) {
                        return _vm.openSkillModal($event)
                      },
                      "update-tab-index": _vm.updateTabIndex,
                      "close-skill-modal": function($event) {
                        _vm.skillModal = false
                      }
                    }
                  }),
                  _vm.skillModal && !_vm.skillModalBottom
                    ? _c("RecommendedAddSkills", {
                        staticStyle: { "margin-top": "-30px" },
                        attrs: { "is-on-asset-tab": _vm.selectedTabIndex == 4 },
                        on: {
                          closeAddSkillsModal: function($event) {
                            return _vm.onCloseModal($event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.recommendation, function(skill, skIdx) {
                return _c(
                  "div",
                  { key: skIdx, staticClass: "recommend-content" },
                  [
                    skill.loaded
                      ? _c(
                          "div",
                          [
                            !(
                              _vm.selectedTabIndex == 4 &&
                              !skill.recommend.byType.length
                            )
                              ? _c("div", { staticClass: "recommend-label" }, [
                                  _vm._v(" " + _vm._s(skill.name) + " "),
                                  _c(
                                    "span",
                                    { staticClass: "recommend-count" },
                                    [
                                      _vm._v(
                                        _vm._s(skill.recommend.byType.length)
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.isDesktopPlatform &&
                            skill.recommend.byType.length
                              ? _c(
                                  "CarouselDesktop",
                                  {
                                    key:
                                      skill.name +
                                      skill.recommend.byType.length,
                                    staticStyle: { "margin-bottom": "16px" },
                                    style: {
                                      "--bottom": "-22px",
                                      "--skill-items-length":
                                        skill.recommend.byType.length + "px"
                                    },
                                    attrs: {
                                      id: skill.name,
                                      slidesShow: 4,
                                      responsive: true
                                    }
                                  },
                                  _vm._l(skill.recommend.byType, function(
                                    item,
                                    index
                                  ) {
                                    return _c("UniversalCard", {
                                      key: item.id,
                                      staticClass: "carousel-desktop-item",
                                      attrs: {
                                        "data-carousel-name": skill.name,
                                        "data-carousel-index": skIdx,
                                        "data-card-index": index,
                                        "data-carousel-uuid": skill.uuid,
                                        id: item.id,
                                        item: item,
                                        index: index,
                                        itemsLength:
                                          skill.recommend.byType.length,
                                        tab: _vm.tabName,
                                        isExpandCardAllow: false,
                                        cardType: "carousal"
                                      },
                                      on: {
                                        "recommended-item-click": function(
                                          $event
                                        ) {
                                          return _vm.onRecommendedItemClick(
                                            item,
                                            index,
                                            skill.name,
                                            skIdx,
                                            skill.uuid
                                          )
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _vm._e(),
                            !_vm.isDesktopPlatform &&
                            skill.recommend.byType.length
                              ? _c(
                                  "CarouselMobile",
                                  {
                                    key:
                                      skill.name +
                                      skill.recommend.byType.length,
                                    attrs: { id: skill.name }
                                  },
                                  _vm._l(skill.recommend.byType, function(
                                    item,
                                    index
                                  ) {
                                    return _c("UniversalCard", {
                                      key: item.id,
                                      staticClass: "carousel-mobile-item",
                                      attrs: {
                                        "data-carousel-name": skill.name,
                                        "data-carousel-index": skIdx,
                                        "data-card-index": index,
                                        "data-carousel-uuid": skill.uuid,
                                        id: item.id,
                                        item: item,
                                        index: index,
                                        itemsLength:
                                          skill.recommend.byType.length,
                                        tab: _vm.tabName,
                                        cardType: "carousal"
                                      },
                                      on: {
                                        "recommended-item-click": function(
                                          $event
                                        ) {
                                          return _vm.onRecommendedItemClick(
                                            item,
                                            index,
                                            skill.name,
                                            skIdx,
                                            skill.uuid
                                          )
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _vm._e(),
                            !skill.recommend.byType.length &&
                            _vm.selectedTabIndex != 4
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#5c5c5c",
                                      "font-family": "Arial"
                                    }
                                  },
                                  [_vm._v("No content found")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              }),
              _vm.skillModal && _vm.skillModalBottom
                ? _c("RecommendedAddSkills", {
                    staticStyle: { "margin-top": "-250px" },
                    attrs: { "is-on-asset-tab": _vm.selectedTabIndex == 4 },
                    on: {
                      closeAddSkillsModal: function($event) {
                        return _vm.onCloseModal($event)
                      }
                    }
                  })
                : _vm._e(),
              (_vm.selectedTabIndex == 4 && !_vm.isAssetAvailable) ||
              _vm.selectedTabIndex != 4
                ? _c("div", { staticClass: "recommend-footer" }, [
                    _c("img", {
                      staticClass: "recommend-footer-image-desktop",
                      attrs: {
                        src: require("@/assets/images/recommended-skills-footer.png")
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "recommend-footer-copy" },
                      [
                        _c("p", { staticClass: "footer-title" }, [
                          _vm._v("There's more to explore")
                        ]),
                        _vm.selectedTabIndex != 4
                          ? _c("p", { staticClass: "footer-subtitle" }, [
                              _vm._v(
                                "Add skills to browse courses, credentials, videos and more based on your interests."
                              )
                            ])
                          : _vm._e(),
                        _vm.selectedTabIndex != 4
                          ? _c(
                              "ion-button",
                              {
                                staticClass:
                                  "g-button--primary ion-button-primary footer-button",
                                attrs: { color: "primary" },
                                on: { click: _vm.openMobileSkillModal }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "addskill-icon-footer g-clickable",
                                    on: { click: _vm.openMobileSkillModal }
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        height: "12px",
                                        width: "12px"
                                      },
                                      attrs: { "icon-class": "add-icon" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" Add Skills ")
                              ]
                            )
                          : _c("p", { staticClass: "footer-subtitle" }, [
                              _vm._v(
                                "Your selected skills didn't match any asset results. Discover more digital assets below."
                              )
                            ]),
                        _c("img", {
                          staticClass: "recommend-footer-image-mobile",
                          attrs: {
                            src: require("@/assets/images/recommended-skills-footer.png")
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.selectedTabIndex == 4
                ? _c("AssetCategory", {
                    attrs: {
                      "is-tenant-asset-available": _vm.isTenantAssetAvailable
                    }
                  })
                : _vm._e()
            ],
            2
          )
        : _c(
            "div",
            { staticStyle: { "margin-top": "4rem" } },
            _vm._l(5, function(i) {
              return _c(
                "div",
                { key: i },
                [
                  _c(
                    "div",
                    { staticClass: "recommend-label-shimmer" },
                    [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                    1
                  ),
                  _vm.isDesktopPlatform
                    ? _c(
                        "CarouselDesktop",
                        {
                          staticStyle: { "margin-bottom": "1rem" },
                          attrs: { slidesShow: 4, responsive: true }
                        },
                        _vm._l(4, function(i) {
                          return _c("RecommendedSkeleton", {
                            key: i,
                            staticClass: "carousel-desktop-item-shimmer"
                          })
                        }),
                        1
                      )
                    : _c(
                        "CarouselMobile",
                        _vm._l(2, function(i) {
                          return _c("RecommendedSkeleton", {
                            key: i,
                            staticClass: "carousel-mobile-item-shimmer"
                          })
                        }),
                        1
                      )
                ],
                1
              )
            }),
            0
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }