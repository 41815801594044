<template>
  <div class="modal-container" @click="dismissModal">
    <svg-icon icon-class="filter-close" class="g-icon--small g-clickable close-icon" @click="dismissModal"></svg-icon>
    <div class="modal-header">
      <img src="@/assets/images/svgs/icon-success.svg" alt="icon-success" />
      <h2 class="title">Congratulations!</h2>
    </div>
    <div class="modal-content">
      <p>You crushed your goal of {{ goalDays }} active days in {{ currentMonth }}. Here's to your achievement and
        ongoing
        success!
      </p>
    </div>
  </div>
</template>
  
<script>
import { popoverController } from '@modus/ionic-vue'
export default {
  name: 'GoalCongratulationsModal',
  data() {
    return {
      currentMonth: ''
    }
  },
  props: {
    goalDays: {
      type: Number,
      required: false,
      default: () => {
        return 0
      }
    }
  },
  created() {
    this.currentMonth = new Date().toLocaleString('default', { month: 'long' })
  },
  computed: {
  },
  watch: {},
  methods: {
    async dismissModal() {
      popoverController.dismiss('done')
    }
  }
}
</script>
<style lang="scss">
.goal-congrats-popover {
  .popover-content {
    border-radius: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .goal-congrats-popover {
    .popover-content {
      left: 5% !important;
      width: 90%;
    }
  }
}
</style>  

<style lang="scss" scoped>
.modal-container {
  max-width: 25rem;
  position: relative;
  cursor: pointer;

  .close-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .modal-header {
    margin-top: 1.5rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .modal-content {
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
    p {
      font-family: Arial;
      color: #5c5c5c;
      font-size: 1rem;
      margin-left: 2rem;
      text-align: left;
    }
  }
}
</style>
  