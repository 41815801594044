var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isSideFilterActive
      ? _c("div", { on: { click: _vm.openSideFilter } })
      : _vm._e(),
    _c("div", { staticClass: "filter-container" }, [
      _c(
        "div",
        { staticClass: "filter-list" },
        [
          _c("div", { staticClass: "filter-title" }, [_vm._v("Filter")]),
          _vm._l(_vm.filters.slice(0, 5), function(filter, index) {
            return _c("div", { key: filter.name + index }, [
              _c(
                "div",
                {
                  key: filter.name + index,
                  class:
                    filter.showFilterOptions || filter.isApply
                      ? "filter-type filter-type-selected"
                      : "filter-type",
                  on: {
                    click: function($event) {
                      return _vm.onFilterType(filter)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        filter.name == "Tool" ? "Technology" : filter.name
                      ) +
                      " "
                  ),
                  _c("svg-icon", {
                    attrs: { "icon-class": "Icon-awesome-chevron-down" }
                  })
                ],
                1
              ),
              filter.showFilterOptions
                ? _c("div", {
                    staticClass: "filter-container-backdrop",
                    on: {
                      click: function($event) {
                        return _vm.onCancel(filter)
                      }
                    }
                  })
                : _vm._e(),
              filter.showFilterOptions
                ? _c(
                    "div",
                    { staticClass: "filter-option-container" },
                    [
                      filter.filterOptions.length > 6
                        ? _c("ion-searchbar", {
                            ref: "searchbar",
                            refInFor: true,
                            staticStyle: { "padding-bottom": "12px" },
                            attrs: {
                              "search-icon": "search-outline",
                              placeholder:
                                filter.name == "Tool"
                                  ? "Search Technology"
                                  : "Search " + filter.name,
                              value: _vm.searchFilter
                            },
                            on: {
                              ionInput: function($event) {
                                return _vm.handleSearchInput($event, filter)
                              },
                              ionClear: function($event) {
                                return _vm.handleClearInput(filter)
                              }
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class:
                            filter.filterOptions.length <= 6
                              ? "filter-option-content"
                              : "filter-option-content filter-option-content-scroll"
                        },
                        _vm._l(filter.filterOptions, function(
                          filterOption,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: filterOption.name + index,
                              style: filterOption.style
                                ? filterOption.style
                                : "display: flex"
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("ion-checkbox", {
                                    staticClass: "filter-checkbox",
                                    attrs: {
                                      checked: filterOption.selected,
                                      value: filterOption.name,
                                      color: "tertiary",
                                      mode: "md",
                                      disabled:
                                        filterOption.count <= 0 ||
                                        (filterOption.name === "Content type" &&
                                          !filterOption.userAccess)
                                    },
                                    on: {
                                      ionChange: function($event) {
                                        return _vm.checkSelections(
                                          $event,
                                          filter
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class:
                                    filterOption.count <= 0 ||
                                    (filterOption.name === "Content type" &&
                                      !filterOption.userAccess)
                                      ? "filter-option-title-disable"
                                      : "filter-option-title"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.filterName(
                                          filterOption,
                                          filter.name
                                        )
                                      ) +
                                      " (" +
                                      _vm._s(filterOption.count) +
                                      ") "
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "filter-option-footer" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "filter-option-footer-cancel",
                              on: {
                                click: function($event) {
                                  return _vm.onCancel(filter)
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "ion-button",
                            {
                              staticClass: "filter-option-footer-btn-apply",
                              attrs: { shape: "round", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.onShow(filter)
                                }
                              }
                            },
                            [_vm._v("Apply")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          }),
          _c(
            "div",
            { staticClass: "filter-type", on: { click: _vm.openSideFilter } },
            [
              _vm._v(" All filters "),
              _c("svg-icon", {
                staticStyle: { "--transform": "rotate(180deg)" },
                attrs: { "icon-class": "icon-filter" }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "all-filter", on: { click: _vm.openSideFilter } },
        [
          _vm._v(" All filters "),
          _c("svg-icon", {
            staticStyle: { "--transform": "rotate(180deg)" },
            attrs: { "icon-class": "icon-filter" }
          })
        ],
        1
      ),
      _c("div", { staticClass: "sort-container" }, [
        _c("div", { staticClass: "sort-title" }, [_vm._v("Sort by")]),
        _c(
          "div",
          { staticClass: "sort-type", on: { click: _vm.onSort } },
          [
            _vm._v(" " + _vm._s(_vm.selectedSort) + " "),
            _c("svg-icon", {
              attrs: { "icon-class": "Icon-awesome-chevron-down" }
            })
          ],
          1
        ),
        _vm.showSortOption
          ? _c("div", {
              staticClass: "sort-container-backdrop",
              on: { click: _vm.onCancelSort }
            })
          : _vm._e(),
        _vm.showSortOption
          ? _c(
              "div",
              { staticClass: "sort-option-container" },
              [
                _c(
                  "ion-radio-group",
                  {
                    staticClass: "sort-option-content",
                    attrs: { value: _vm.selectedSort },
                    on: {
                      ionChange: function($event) {
                        return _vm.handleRadioSelect($event)
                      }
                    }
                  },
                  _vm._l(_vm.sortOptions, function(sortOption, index) {
                    return _c(
                      "div",
                      {
                        key: sortOption.name + index,
                        staticClass: "sort-option"
                      },
                      [
                        sortOption.enabled
                          ? _c("ion-radio", {
                              attrs: {
                                slot: "start",
                                color: "tertiary",
                                value: sortOption.name,
                                mode: "md"
                              },
                              slot: "start"
                            })
                          : _vm._e(),
                        sortOption.enabled
                          ? _c("div", { staticClass: "sort-option-title" }, [
                              _vm._v(_vm._s(sortOption.name))
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "sort-option-footer" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "sort-option-footer-cancel",
                        on: { click: _vm.onCancelSort }
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "ion-button",
                      {
                        staticClass: "sort-option-footer-btn-apply",
                        attrs: { shape: "round", color: "primary" },
                        on: { click: _vm.onApplySort }
                      },
                      [_vm._v("Apply")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _vm.selected.length
      ? _c(
          "div",
          { staticClass: "filter-selected-container" },
          [
            _c("div", { staticClass: "filter-selected-title" }, [
              _vm._v("Selected")
            ]),
            _vm._l(_vm.selected, function(filter, index) {
              return _c("div", { key: filter.name + index }, [
                _c(
                  "div",
                  { staticClass: "filter-selected" },
                  [
                    _vm._v(" " + _vm._s(_vm.filterName(filter)) + " "),
                    _c("svg-icon", {
                      attrs: { "icon-class": "icon-remove-filter" },
                      on: {
                        click: function($event) {
                          return _vm.removeFilter(filter)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            _c(
              "div",
              {
                staticClass: "filter-selected-clear-all",
                on: { click: _vm.onClearFilters }
              },
              [_vm._v("Clear all filters")]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }