<template>
  <div v-if="dataReady" :style="isDesktopPlatform ? 'margin-top: 150px' : 'margin-top: 60px'">
    <div v-if="tenantAsset.available || tenantAsset.enabled" class="company-assets-container">
      <div class="company-asset-orb-container">
        <img style="width: 100px" src="@/assets/images/recommend-asset-orb.png" />
      </div>
      <div class="company-asset-title">{{ tenant.name }} assets</div>
      <div class="company-asset-btns">
        <ion-button v-if="tenantAsset.available" shape="round" color="primary" class="company-asset-view-all-btn" @click="onViewAllTenantAsset()">View all</ion-button>
        <span v-if="!tenantAsset.available && tenantAsset.submissionLink.length" class="company-asset-txt">Have a digital asset to share ?</span>
        <span v-if="tenantAsset.enabled" style="padding: 0; margin: 0">
          <ion-button shape="round" color="primary" class="company-asset-submit-asset-btn" @click="onSubmitAsset()">
            {{ tenantAsset.action }}
            <span v-if="tenantAsset.submissionLink.length" style="padding-left: 5px"><svg-icon icon-class="expand-out" style="height: 16px; width: 16px"></svg-icon></span>
          </ion-button>
        </span>
      </div>
    </div>

    <div class="technology-asset-container">
      <div class="technology-asset-title">Assets by technology</div>
      <div class="technology-asset-application-container">
        <a href="#" v-for="application in applications" :key="application.name" class="technology-asset-application" @click="onSearchByApplication(application.name)">
          <ion-row class="technology-asset-application-grid">
            <ion-col class="ion-align-items-center technology-asset-application-img-container">
              <img :src="application.image" class="technology-asset-application-img" />
            </ion-col>
            <ion-col class="ion-align-items-center technology-asset-application-text">
              {{ application.name }}
            </ion-col>
          </ion-row>
        </a>
      </div>
    </div>

    <div class="type-asset-container">
      <div class="type-asset-title">Assets by type</div>
      <div class="type-asset-application-container">
        <a v-for="type in types" :key="type.name" href="#" class="type-asset-application" @click="onSearchByType(type.name)">
          <ion-row class="type-asset-application-grid">
            <ion-col size="6" class="ion-align-items-center type-asset-application-text">
              {{ type.name }}
            </ion-col>
            <ion-col size="6" class="ion-align-items-center type-asset-application-img-container ion-no-padding">
              <img :src="type.image" class="type-asset-application-img" />
            </ion-col>
          </ion-row>
        </a>
      </div>
    </div>

    <div class="search-asset-container">
      <div class="search-asset-header">Search assets</div>
      <div class="search-asset">
        <ion-searchbar
          search-icon="search-outline"
          ref="searchbar"
          :class="isDesktopPlatform ? 'searchbar-desktop' : 'searchbar-mobile'"
          :value="query"
          placeholder=""
          @keypress.enter="handleSearchInput"
          @ionClear="handleClearInput"
        ></ion-searchbar>
        <ion-button shape="round" color="primary" class="search-asset-btn" @click="handleSearchInput()">Search</ion-button>
      </div>
      <div class="search-asset-view-all-btn-container">
        <ion-button shape="round" color="primary" class="search-asset-view-all-btn" @click="onViewAllAsset()">View all assets</ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import { Plugins } from '@capacitor/core'
const { Browser } = Plugins

export default {
  name: 'AssetCategory',
  components: {},
  props: {
    isTenantAssetAvailable: {
      type: Boolean,
      required: true,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: true,
      authToken: '',
      searchbar: '',
      query: '',
      applications: [],
      types: [],
      assetMediaUrl: '',
      dataReady: false,
      tenant: null,
      tenantAsset: {
        available: true,
        enabled: false,
        action: '', // Submit an asset | Manage my submissions
        submissionLink: ''
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async mounted() {
    this.dataReady = false
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
    this.tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
    const settings = JSON.parse(this.tenant.settings)
    this.tenantAsset.available = this.isTenantAssetAvailable
    this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'tenant-assets-submission-enabled') && settings['tenant-assets-submission-enabled']
    this.tenantAsset.submissionLink = {}.hasOwnProperty.call(settings, 'apply-asset-upload-link') ? settings['apply-asset-upload-link'] : ''
    this.tenantAsset.action = this.tenantAsset.enabled && this.tenantAsset.submissionLink.length ? 'Submit an asset' : 'Manage my submissions'
    this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
    const byApplicationPromise = this.getAssetByApplications()
    const byTypePromise = this.getAssetByTypes()
    await Promise.allSettled([byApplicationPromise, byTypePromise])
    this.dataReady = true
  },
  methods: {
    async getAssetByApplications() {
      this.applications = await this.$learnAdmin.getAssetByApplications({ token: this.authToken })
      await this.applications.map((application) => {
        application.image = `${this.assetMediaUrl}media-container/${application.image?.replace('.svg', '-in-circle.svg')}`
      })
    },
    async getAssetByTypes() {
      this.types = await this.$learnAdmin.getAssetByTypes({ token: this.authToken })
      await this.types.map((type) => {
        type.image = `${this.assetMediaUrl}media-container/${type.image}`
      })
    },
    async onSubmitAsset() {
      if (this.tenantAsset.action === 'Submit an asset') {
        await Browser.open({ url: this.tenantAsset.submissionLink })
      } else if (this.tenantAsset.action === 'Manage my submissions') {
        this.$router.push('/submit-asset')
      }
      this.onActionSelected(`Button ${this.tenantAsset.action}`)
    },
    async onViewAllAsset() {
      const applications = await this.applications.map((application) => application.name)
      const types = await this.types.map((type) => type.name)
      this.$router.push({ name: 'Search', query: { q: '' }, params: { fromRecommended: true, searchBy: 'all', applications: applications, types: types } }).catch(() => {})
      this.onActionSelected('Button search View All')
    },
    onViewAllTenantAsset() {
      this.$router.push({ name: 'Search', query: { q: '' }, params: { fromRecommended: true, searchBy: 'provider', provider: this.tenant.name } }).catch(() => {})
      this.onActionSelected('Button client View All')
    },
    onSearchByApplication(application) {
      this.$router.push({ name: 'Search', query: { q: '' }, params: { fromRecommended: true, searchBy: 'application', application: application } }).catch(() => {})
      this.onActionSelected(`Button technology ${application}`)
    },
    onSearchByType(type) {
      this.$router.push({ name: 'Search', query: { q: '' }, params: { fromRecommended: true, searchBy: 'type', type: type } }).catch(() => {})
      this.onActionSelected(`Button type ${type}`)
    },
    handleClearInput() {
      this.query = ''
    },
    async handleSearchInput(event) {
      this.query = event ? event.target.value.trim() : this.$refs.searchbar.value.trim()
      if (this.query.length > 0) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const item = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          action: `Search ${this.query}`
        }
        const payload = {
          action: 'Asset',
          category: 'Explore Asset Tab',
          value: JSON.stringify(item)
        }
        await this.GAEventTrack(payload)
        this.$router.push({ name: 'Search', query: { q: this.query }, params: { fromRecommended: true, searchBy: 'all' } }).catch(() => {})
      }
    },
    async onActionSelected(action) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        action: `${action} clicked`
      }
      const payload = {
        action: 'Asset',
        category: 'Explore Asset Tab',
        value: JSON.stringify(item)
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>
<style lang="scss">
.search-asset-container {
  .search-asset {
    .searchbar-input.sc-ion-searchbar-md {
      padding-left: 55px;
      padding-right: 55px;
      padding-top: 6px;
      padding-bottom: 6px;
      background-position: left 8px center;
      height: auto;
      font-size: 16px !important;
      font-weight: 400;
      line-height: 30px;
      font-family: 'Futura PT Book', sans-serif;
    }
    .searchbar-search-icon.sc-ion-searchbar-md {
      left: 16px;
      top: 11px;
      width: 21px;
      height: 21px;
    }
  }
}
</style>
<style lang="scss" scoped>
.company-assets-container {
  padding: 2rem;
  width: 100%;
  min-height: 160px;
  border-radius: 20px;
  background-image: url('~@/assets/images/gradient-asset-path.png');
  background-position: center;
  background-size: cover;
}

.company-asset-title {
  font-size: 2rem;
  font-family: 'Futura PT Demi';
  color: #fff;
}

.company-asset-btns {
  margin-top: 10px;
}
.company-asset-view-all-btn {
  margin-right: 0.5rem;
  height: 30px;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #feb92e;
  --ion-color-primary-contrast: black;
}

.company-asset-submit-asset-btn {
  height: 30px;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #1f3370;
}

.company-asset-txt {
  position: relative;
  top: 0.2rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-white);
}

.company-asset-orb-container {
  position: relative;
  top: -2rem;
  right: 2rem;
  float: right;
  height: 160px;
  overflow: hidden;
}

.technology-asset-container,
.type-asset-container {
  margin-top: 2.5rem;
}

.technology-asset-title,
.type-asset-title {
  margin-bottom: 1.5rem;
}

.technology-asset-application-container,
.type-asset-application-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.technology-asset-application,
.type-asset-application {
  width: 260px;
  height: 100px;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
  background: var(--ion-color-white);
  border-radius: 15px;
  text-decoration: none;  
}

.type-asset-application {
  height: 8.75rem !important;
}

.technology-asset-application-grid,
.type-asset-application-grid {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.technology-asset-application-img-container,
.type-asset-application-img-container {
  display: flex;
  justify-content: space-around;
}

.technology-asset-application-img {
  width: 3.75rem;
  height: 3.75rem;
}

.type-asset-application-img {
  width: 7.5rem;
  height: 7.5rem;
}

.technology-asset-application-text,
.type-asset-application-text {
  font-size: 1.2rem;
  font-family: 'Futura PT Demi';
  align-self: center;
  text-align: left;
  color: #1f3370;
}

.search-asset-container {
  width: 100%;
  margin-top: 2rem;
  border-radius: 10px;
  background: linear-gradient(180deg, #f8f8f8 0%, #f8f8f800 100%);
}

ion-searchbar {
  text-align: left;
  --border-radius: 50px;
  width: 450px;
  --ion-background-color: #fff;
  --box-shadow: none;
}

.sc-ion-searchbar-md-h,
.sc-ion-searchbar-ios-h {
  --background: #fff !important;
}

.search-asset-header,
.technology-asset-title,
.type-asset-title {
  font-size: 1.5rem;
  font-family: 'Futura PT Demi';
  text-align: center;
  color: black;
}

.search-asset-header {
  padding-top: 2rem;
}

.search-asset-btn {
  height: 2.6rem;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #feb92e;
  --ion-color-primary-contrast: black;
}

.search-asset-view-all-btn-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.search-asset-view-all-btn {
  margin-bottom: 1rem;
  height: 30px;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #1f3370;
  --ion-color-primary-contrast: #fff;
}

.search-asset {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  align-items: center;
}

ion-button {
  --box-shadow: none;
}

@media screen and (max-width: 1024px) {
  .technology-asset-application,
  .type-asset-application {
    width: 47%;
  }
  .technology-asset-application-container,
  .type-asset-application-container {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .company-asset-btns {
    align-items: flex-start;
    flex-direction: column;
  }
  .company-assets-container {
    padding: 1rem 2rem;
  }

  .company-asset-title {
    font-size: 1.5rem;
  }

  .company-asset-txt {
    top: -0.2rem;
  }

  .company-asset-orb-container {
    top: -1rem;
    right: -1rem;
  }

  .search-asset-btn {
    display: none; //hiding search button in mobile as per XD
  }

  .type-asset-application-grid {
    padding: 0 !important;
  }

  .technology-asset-application,
  .type-asset-application {
    width: 100%;
  }
  @media screen and (max-width: 415px) {
    .company-assets-container {
      padding: 1rem 1rem;
    }
    .company-asset-submit-asset-btn {
      letter-spacing: 0.001rem;
      width: 11rem;
    }
  }
}
</style>
