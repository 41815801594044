<template>
  <div class="skill-container" :key="skillGroup.length">
    <div
      v-for="(skill, index) in skillGroup"
      :key="index + skill.name"
      v-bind:class="[skill.recentlyAdded ? 'skill-button recent' : 'skill-button', displaySkillDetails && from !== 'external-form' ? 'pointer' : '']"
      @click="searchWithSkill(skill)"
    >
      {{ skill.name }}
      <svg-icon v-if="from === 'external-form'" icon-class="icon-remove-xs" class="remove-skill-icon g-clickable" @click="onDeleteSkill(skill)"></svg-icon>
    </div>
    <div v-if="skillGroup.length > 0 && from !== 'external-form' && from !== 'welcome-page'" class="skill-button manage-skills-btn"><a @click="openManageSkillsModal">Manage skills</a></div>
  </div>
</template>
<script>
import { popoverController } from '@modus/ionic-vue'
import ManageSkillsModal from '@/components/organisms/ManageSkillsModal'

export default {
  name: 'SkillsButtonGroup',
  components: {},
  props: {
    skills: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    isSkillRemoveable: {
      type: Boolean,
      required: false,
      default: true
    },
    displaySkillDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    from: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {},
  data() {
    return {
      skillGroup: [],
      isAddable: false,
      searchedSkills: [],
      addedSkills: []
    }
  },
  async created() {
    await this.skills.map((skill) => {
      const skillObj = typeof skill === 'string' ? { name: skill } : skill
      this.skillGroup.push(skillObj)
    })
  },
  mounted() {},
  methods: {
    async openManageSkillsModal() {
      let modal = await popoverController.create({
        component: ManageSkillsModal,
        cssClass: 'modal-container-class',
        swipeToClose: true,
        showBackdrop: true,
        componentProps: {
          propsData: {
            skills: this.skills
          }
        }
      })
      await modal.present()
    },
    searchWithSkill(skill) {
      if (this.from !== 'external-form') this.$router.push({ name: 'Search', query: { q: skill.name, suggestionSkill: skill.name } }).catch(() => {})
    },
    onDeleteSkill(skill) {
      this.$eventBus.$emit('rm-ext-skill', skill.guid)
    }
  }
}
</script>

<style lang="scss" scoped>
.skill-container {
  @media only screen and (max-width: $breakpoint-med) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  padding-bottom: 1rem;
  user-select: none;
}
.skill-button {
  background: var(--ion-color-white);
  color: var(--ion-color-tertiary);
  font: normal normal bold 12px/12px Arial;
  //min-width: 80px;
  --padding-top: 0.8rem;
  --padding-bottom: 0.8rem;
  --padding-start: 0.8rem;
  --padding-end: 0.8rem;
  // --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  &.manage-skills-btn {
    background: transparent;
    border: none;
    a {
      color: #1f3370;
      font: normal normal bold 14px/22px Arial;
      cursor: pointer;
    }
  }
  .remove-skill-icon {
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-left: 8px;
  }
}
.pointer {
  cursor: pointer;
}

div.skill-button {
  display: inline-block;
  border: 1px solid #ccc;
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 30px;
  margin: 5px 5px 0 0;
  background: #bcc2d4;
  font: normal normal bold 12px/12px Arial;
  &.recent {
    border: 1px solid #00d1b0;
  }
}
</style>
