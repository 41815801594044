<template>
  <ion-toolbar>
    <ion-tabs>
      <template>
        <ion-tab-bar slot="bottom">
          <ion-tab-button>
            <router-link to="/home" v-track="'nav,home,tap'">
              <svg-icon icon-class="icon-home-new" class-name="g-icon--small house" />
              <svg-icon icon-class="icon-home-new-selected" class-name="g-icon--small house-selected" />
            </router-link>
          </ion-tab-button>
          <ion-tab-button>
            <router-link :to="{ name: 'MyLearning', params: { type: 'required', isNotFromDeepLink: true } }" v-track="'nav,journey,tap'">
              <svg-icon icon-class="icon-mylearning-new" class-name="g-icon--small journey" />
              <svg-icon icon-class="icon-mylearning-new-selected" class-name="g-icon--small journey-selected" />
            </router-link>
          </ion-tab-button>
          <ion-tab-button>
            <router-link to="/explore" v-track="'nav,explore,tap'">
              <svg-icon icon-class="icon-explore-new" class-name="g-icon--small explore" />
              <svg-icon icon-class="icon-explore-new-selected" class-name="g-icon--small explore-selected" />
            </router-link>
          </ion-tab-button>
          <ion-tab-button>
            <router-link to="/bookmarks" v-track="'nav,bookmarks,tap'">
              <svg-icon icon-class="icon-bookmark-new" class-name="g-icon--small library" />
              <svg-icon icon-class="icon-bookmark-new-selected" class-name="g-icon--small library-selected" />
            </router-link>
          </ion-tab-button>
        </ion-tab-bar>
      </template>
    </ion-tabs>
  </ion-toolbar>
</template>

<script>
export default {
  name: 'NavBottomTabMobile',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
svg-icon {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

ion-tab-bar {
  padding-top: 2px;
}

@mixin toolbarButtonFix {
  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      left: calc(100vw - (100% - #{($i - 1) * 25 + 9.5}vw));
    }
  }
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP 11 */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 11pro */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP 11max */,
  only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) /* iP 6/6s/7/8 */,
  only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) /* iP 6+/6s+/7+/8+ */,
  only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP X /XS/11 Pro/12 Mini */,
  only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 12/12 Pro */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP XR/11 */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP XS Max/11 Pro Max */,
  only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 12 Pro Max */,
  only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 13/13 Pro */,
  only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Pro Max */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Mini */,
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) /* iPad 1/2/Mini/Air */,
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) /* iPad 3/4/Pro 9.7 */,
  only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) /* iPad Pro 10.5 */,
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) /* iPad Pro 12.9 */ {
  ion-tab-bar {
    padding-top: 20px;
  }
  ion-tab-button {
    position: absolute;
    top: 1px;
  }
  ion-tab-button {
    @include toolbarButtonFix;
  }
}

ion-tab-button {
  --padding-top: 11px;
  a {
    width: 100%;
    height: 100%;
  }
}

.g-icon--small {
  &.house,
  &.journey,
  &.explore,
  &.library,
  &.profile {
    color: var(--ion-color-blue);
    &-selected {
      display: none;
    }
  }
}

.router-link-active {
  color: var(--ion-color-blue);
  .g-icon--small {
    &.house,
    &.journey,
    &.explore,
    &.library,
    &.profile {
      display: none;
      &-selected {
        display: inline-block;
      }
    }
  }
}
</style>
