<template>
  <div class="guide-popover">
    <ion-grid>
      <ion-row>
        <ion-col size="9" class="ion-text-start">
          <p class="ion-no-margin guide-popover__heading">Quick Reference Guide</p>
        </ion-col>
        <ion-col class="ion-text-end"><svg-icon icon-class="close" class="g-icon--small" @click="onClose"></svg-icon></ion-col>
      </ion-row>
    </ion-grid>
    <iframe :src="quickRefLink" class="guide-popover__pdf-frame"></iframe>
  </div>
</template>

<script>
import localforage from 'localforage'

export default {
  name: 'GuidePopover',
  components: {},
  props: {},
  data() {
    return {
      quickRefLink: ''
    }
  },
  async beforeCreate() {
    const { cdn } = await localforage.getItem('cloudEnv')
    this.quickRefLink = cdn.contentCdn + 'vdi/vdi_guide.pdf'
  },
  created() {},
  mounted() {},
  methods: {
    onClose() {
      this.$emit('guide-popover-close', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('guide-popover') {
  height: 100vh;

  @include element('heading') {
    color: var(--ion-color-tertiary);
    font-size: 1.5rem;
    font-family: 'Futura PT Demi';
  }

  @include element('icon') {
    color: var(--ion-color-tertiary);
  }

  @include element('pdf-frame') {
    width: 100%;
    height: 79vh;
    border: none;
  }
}
</style>
