<template>
  <div class="lesson-index">
    <ion-row v-if="isCapstone" class="ion-align-items-center top-border">
      <ion-col class="lesson-index__title ion-no-padding g-margin__right--msmall">{{ lesson.title }}</ion-col>
    </ion-row>
    <ion-row v-else class="ion-align-items-center top-border">
      <ion-col class="lesson-index__title ion-no-padding g-margin__right--msmall">{{ lesson.title }}</ion-col>
      <ion-col size="1" @click="toggleSkillbuilder()">
        <svg-icon :icon-class="'dropdown-transcript'" class-name="g-icon--xsmall" :class="[!showBuilder ? '' : 'lesson-index__caret-transform']" />
      </ion-col>
    </ion-row>
    <div v-if="showBuilder">
      <div v-for="(content, index) in contents" :key="index">
        <ion-row class="lesson-index__subtitle ion-align-items-center" :class="{ 'lesson-index__subtitle light': index % 2 === 0, ' lesson-index__subtitle': index % 2 !== 0 }">
          <ion-col size="1" style="padding-left: 0rem; text-align: right"
            ><Bookmark class="bookmark" :assetId="content.id" assetType="credentialcontents" :meta="content.meta"
          /></ion-col>
          <ion-col style="padding-left: 0.3rem">
            <p class="ion-no-margin">{{ index + 1 }}. {{ content.title }}</p>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import Bookmark from '@/components/molecules/Bookmark'

export default {
  name: 'CredentialLessonIndex',
  components: { Bookmark },
  props: {
    lesson: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    courseId: { type: String, required: true, default: '' },
    sbid: { type: String, required: true, default: '' },
    isCapstone: { type: Boolean, required: false, default: false },
    index: { type: Number, required: false, default: 0 },
    meta: { type: Object, required: false, default: () => {} }
  },
  data() {
    return {
      showBuilder: false,
      contents: []
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async created() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
  },
  mounted() {},
  methods: {
    async toggleSkillbuilder() {
      this.showBuilder = !this.showBuilder
      if (this.contents.length === 0) {
        let response = await this.$learnAdmin.getLessonById({ token: this.authToken, id: this.courseId, sbid: this.sbid, lid: this.lesson.id })
        this.contents = response.lessonContents
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('lesson-index') {
  background-color: var(--ion-color-dark-grey) !important;
  border-radius: 0px !important;

  @include element('caret-transform ') {
    transform: rotate(180deg);
  }
  @include element('index') {
    color: map-get($edge-colors, 'tertiary');
    text-align: left;
    font-size: 3rem;
    font-weight: bolder;
    margin: 0px 5px;
  }
  @include element('title') {
    color: var(--ion-color-blue);
    font-size: 1.2rem;
    text-align: left;
    font-weight: 500;
    padding: 0.5rem 1.2rem;
  }
  @include element('subtitle') {
    font-size: 1.2rem;
    text-align: left;
    padding: 0.1rem 1rem;
    margin: 0px;
  }
  @include element('numlessons') {
    color: var(--ion-color-black);
    font-size: 1.1rem;
    text-align: left;
    padding-left: 0.8rem;
    padding-bottom: 0.5rem;
  }
  @include element('module-builder') {
    margin-bottom: 0.3rem;
  }
}

.light {
  background-color: var(--ion-color-light-gray);
}

.top-border {
  border-top: 1px solid var(--ion-color-role-grey);
}
</style>
