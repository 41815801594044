var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ion-card", { staticClass: "personal-details" }, [
    _c(
      "div",
      {
        class: _vm.isDesktopPlatform
          ? "personal-info-header personal-info-border"
          : "personal-info-header"
      },
      [_c("h3", [_vm._v("Personal info")])]
    ),
    _c("div", { staticClass: "personal-info-content" }, [
      _c("label", [_vm._v("Name")]),
      _c("p", [_vm._v(_vm._s(_vm.profileDetails.name))]),
      _c("label", [
        _vm._v("ProEdge role "),
        _c("span", { staticClass: "typeahead-tooltip" }, [
          _c("img", {
            staticClass: "info-icon",
            attrs: {
              src: require("@/assets/images/svgs/icon-info.svg"),
              alt: "Info"
            }
          }),
          _c("span", { staticClass: "typeahead-tooltiptext" }, [
            _vm._v(_vm._s(_vm.roleTooltipText))
          ])
        ])
      ]),
      _c("p", [_vm._v(_vm._s(_vm.profileDetails.role))]),
      _c("label", [_vm._v("Email")]),
      _c("p", [_vm._v(_vm._s(_vm.profileDetails.email))]),
      _c("label", [_vm._v("Job title")]),
      _c("p", [_vm._v(_vm._s(_vm.profileDetails.jobTitle))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }