<template>
  <div>
    <div v-if="isSideFilterActive" @click="openSideFilter"></div>
    <div class="filter-container">
      <div class="filter-list">
        <div class="filter-title">Filter</div>
        <div v-for="(filter, index) in filters.slice(0, 5)" :key="filter.name + index">
          <div :class="filter.showFilterOptions || filter.isApply ? 'filter-type filter-type-selected' : 'filter-type'" @click="onFilterType(filter)" :key="filter.name + index">
            {{ filter.name == 'Tool' ? 'Technology' : filter.name }}
            <svg-icon icon-class="Icon-awesome-chevron-down"></svg-icon>
          </div>
          <div v-if="filter.showFilterOptions" class="filter-container-backdrop" @click="onCancel(filter)"></div>
          <div v-if="filter.showFilterOptions" class="filter-option-container">
            <ion-searchbar
              style="padding-bottom: 12px"
              v-if="filter.filterOptions.length > 6"
              search-icon="search-outline"
              ref="searchbar"
              :placeholder="filter.name == 'Tool' ? 'Search Technology' : 'Search ' + filter.name"
              :value="searchFilter"
              @ionInput="handleSearchInput($event, filter)"
              @ionClear="handleClearInput(filter)"
            ></ion-searchbar>
            <div :class="filter.filterOptions.length <= 6 ? 'filter-option-content' : 'filter-option-content filter-option-content-scroll'">
              <div v-for="(filterOption, index) in filter.filterOptions" :key="filterOption.name + index" :style="filterOption.style ? filterOption.style : 'display: flex'">
                <div>
                  <ion-checkbox
                    :checked="filterOption.selected"
                    :value="filterOption.name"
                    class="filter-checkbox"
                    color="tertiary"
                    mode="md"
                    :disabled="filterOption.count <= 0 || (filterOption.name === 'Content type' && !filterOption.userAccess)"
                    @ionChange="checkSelections($event, filter)"
                  />
                </div>
                <div :class="filterOption.count <= 0 || (filterOption.name === 'Content type' && !filterOption.userAccess) ? 'filter-option-title-disable' : 'filter-option-title'">
                  {{ filterName(filterOption, filter.name) }} ({{ filterOption.count }})
                </div>
              </div>
            </div>
            <div class="filter-option-footer">
              <div class="filter-option-footer-cancel" @click="onCancel(filter)">Cancel</div>
              <ion-button shape="round" color="primary" class="filter-option-footer-btn-apply" @click="onShow(filter)">Apply</ion-button>
            </div>
          </div>
        </div>
        <div class="filter-type" @click="openSideFilter">
          All filters
          <svg-icon style="--transform: rotate(180deg)" icon-class="icon-filter"></svg-icon>
        </div>
      </div>
      <!-- All filters for mobile and below 1024 resolution -->
      <div class="all-filter" @click="openSideFilter">
        All filters
        <svg-icon style="--transform: rotate(180deg)" icon-class="icon-filter"></svg-icon>
      </div>
      <div class="sort-container">
        <div class="sort-title">Sort by</div>
        <div class="sort-type" @click="onSort">
          {{ selectedSort }}
          <svg-icon icon-class="Icon-awesome-chevron-down"></svg-icon>
        </div>
        <div v-if="showSortOption" class="sort-container-backdrop" @click="onCancelSort"></div>
        <div v-if="showSortOption" class="sort-option-container">
          <ion-radio-group class="sort-option-content" @ionChange="handleRadioSelect($event)" :value="selectedSort">
            <div v-for="(sortOption, index) in sortOptions" :key="sortOption.name + index" class="sort-option">
              <ion-radio v-if="sortOption.enabled" slot="start" color="tertiary" :value="sortOption.name" mode="md"></ion-radio>
              <div v-if="sortOption.enabled" class="sort-option-title">{{ sortOption.name }}</div>
            </div>
          </ion-radio-group>
          <div class="sort-option-footer">
            <div class="sort-option-footer-cancel" @click="onCancelSort">Cancel</div>
            <ion-button shape="round" color="primary" class="sort-option-footer-btn-apply" @click="onApplySort">Apply</ion-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selected.length" class="filter-selected-container">
      <div class="filter-selected-title">Selected</div>
      <div v-for="(filter, index) in selected" :key="filter.name + index">
        <div class="filter-selected">
          {{ filterName(filter) }}
          <svg-icon icon-class="icon-remove-filter" @click="removeFilter(filter)"></svg-icon>
        </div>
      </div>
      <div class="filter-selected-clear-all" @click="onClearFilters">Clear all filters</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { popoverController } from '@modus/ionic-vue'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'
import SearchAllFiltersSideBar from '@/views/search/components/SearchAllFiltersSideBar'

export default {
  name: 'SearchFilter',
  components: {},
  props: {
    filtersObj: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    selectedFilters: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    userAccess: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    sideFilterData: {
      type: Boolean,
      require: false,
      default: () => {
        return false
      }
    },
    resultsCount: {
      type: Number,
      require: false,
      default: () => {
        return 0
      }
    },
    sortBy: {
      type: String,
      require: false,
      default: () => {
        return 'bestmatch'
      }
    }
  },
  data() {
    return {
      key: 0,
      authToken: '',
      searchFilter: '',
      showSortOption: false,
      isDesktopPlatform: true,
      isSideFilterActive: false,
      selectedSort: 'Best match',
      selectedFilterType: '',
      filters: [],
      selected: [],
      authorEnabledFlag: true,
      durationFilterNames: ['< 15 mins', '15 mins - 1 hr', '1 - 3 hours', '3 - 5 hours', '5 - 10 hours', '10+ hours'],
      durationFilterNamesMap: {
        duration1: '< 15 mins',
        duration2: '15 mins - 1 hr',
        duration3: '1 - 3 hours',
        duration4: '3 - 5 hours',
        duration5: '5 - 10 hours',
        duration6: '10+ hours'
      },
      sortOptions: [
        {
          name: 'Best match',
          value: 'bestmatch'
        },
        {
          name: 'Newest',
          value: 'newest'
        },
        {
          name: 'Ratings',
          value: 'ratings'
        },
        {
          name: 'Most Viewed',
          value: 'views'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'enabledFeatureFlags'])
  },
  watch: {},
  async mounted() {
    this.$on('clear-all-filters', () => {
      this.onClearFilters()
    })
    this.$on('selected-filters-output', (filter) => {
      this.onShow(filter)
    })
    this.$on('coming-from-side-filter', (data) => {
      this.$emit('side-filter', data)
      popoverController.dismiss()
    })
    this.selected = this.selectedFilters
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    const isNewestSortDisabled = this.selected.some(
      (selectedFilter) =>
        selectedFilter.name.toLowerCase() === 'credential' ||
        selectedFilter.name.toLowerCase() === 'course' ||
        selectedFilter.name.toLowerCase() === 'asset' ||
        selectedFilter.name.toLowerCase() === 'track'
    )
    await this.onFetchFilterOptions()
    await this.sortOptions.map((sortOption) => {
      sortOption.enabled =
        sortOption.value === 'newest' && isNewestSortDisabled ? false : this.enabledFeatureFlags ? this.enabledFeatureFlags[`learn_sort_${sortOption.value}_enabled`] : false
      if (sortOption.value === this.sortBy) {
        sortOption.selected = !sortOption.selected
        this.selectedSort = sortOption.name
      }
    })
    if (this.sideFilterData) {
      this.isSideFilterActive = true
      this.openSideFilter()
    }
    const closeFilterModal = async () => {
      document.getElementById('contentlayout').style.overflowY = 'scroll'
      await this.filters.map(async (filter) => {
        filter.showFilterOptions = false
        this.selectedFilterType = ''
        await filter.filterOptions.map((filterOption) => {
          if (!this.selected.some((selectedFilter) => selectedFilter.name === filterOption.name)) {
            filterOption.selected = false
          }
        })
      })
      this.$emit('close', { filters: this.selected })
    }
    EventBus.$on('search-page-click', closeFilterModal)
    EventBus.$on('search-bar-on-focus', closeFilterModal)
  },
  methods: {
    async onFetchFilterOptions() {
      await Object.keys(this.filtersObj).map(async (filterType) => {
        let filterTypeObj = {}
        if (this.filtersObj[filterType].length) {
          if (filterType.indexOf('type') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Content type')
              filter.userAccess =
                filter.name === 'credential'
                  ? this.userAccess.licenseType.canAccessCredential
                  : filter.name === 'course'
                  ? this.userAccess.licenseType.canAccessCourses
                  : filter.name === 'automation'
                  ? this.userAccess.assetAccess
                  : this.userAccess.licenseType.canAccessRWL
            })
            filterTypeObj.name = 'Content type'
            filterTypeObj.order = 1
          } else if (filterType.indexOf('level') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Level')
            })
            filterTypeObj.name = 'Level'
            filterTypeObj.order = 4
          } else if (filterType.indexOf('provider') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Provider')
            })
            filterTypeObj.name = 'Provider'
            filterTypeObj.order = 2
          } else if (filterType.indexOf('duration') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Duration')
              filter.id = this.durationFilterNamesMap[filter.name]
            })
            filterTypeObj.name = 'Duration'
            filterTypeObj.order = 3
          } else if (filterType.indexOf('accreditation') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Accreditation')
            })
            filterTypeObj.name = 'Accreditation'
            filterTypeObj.order = 5
          } else if (filterType.indexOf('function') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Function')
            })
            filterTypeObj.name = 'Function'
            filterTypeObj.order = 7
          } else if (filterType.indexOf('tool') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Tool')
            })
            filterTypeObj.name = 'Tool'
            filterTypeObj.order = 6
          } else if (filterType.indexOf('author') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Author')
            })
            filterTypeObj.name = 'Author'
            filterTypeObj.order = 9
          } else if (filterType.indexOf('inventoryType') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Asset type')
            })
            filterTypeObj.name = 'Asset type'
            filterTypeObj.order = 8
          } else if (filterType.indexOf('creditType') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Credit type')
            })
            filterTypeObj.name = 'Credit type'
            filterTypeObj.order = 10
          } else if (filterType.indexOf('creditHours') != -1) {
            await this.filtersObj[filterType].map((filter) => {
              filter.selected = this.selected.some((selectedFilter) => selectedFilter.name === filter.name && selectedFilter.type === 'Credit hours')
            })
            filterTypeObj.name = 'Credit hours'
            filterTypeObj.order = 11
          }
          filterTypeObj.isApply = this.filtersObj[filterType].filter((filterOption) => filterOption.selected).length > 0
          filterTypeObj.filterOptions = this.filtersObj[filterType]
          filterTypeObj.showFilterOptions = false
          this.authorEnabledFlag = filterType.indexOf('author') != -1 ? this.enabledFeatureFlags['learn_author_flag'] : true
          if (filterType.indexOf('skill') < 0 && this.authorEnabledFlag) {
            this.filters.push(filterTypeObj)
          }
        }
      })
      await this.filters.sort((a, b) => a.order - b.order)
    },
    async onCancel(filterEvent) {
      this.handleClearInput(filterEvent)
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      await this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name) {
          filter.showFilterOptions = false
          this.selectedFilterType = ''
          await filter.filterOptions.map((filterOption) => {
            filterOption.selected = this.selected.some((selectedFilter) => selectedFilter.name === filterOption.name)
          })
        }
      })
      this.$emit('close', { filters: this.selected })
    },
    async onClearFilters() {
      this.selected = []
      this.$emit('clear-filters')
    },
    async onShow(filterEvent) {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      await this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name) {
          filter.isApply = filter.filterOptions.filter((filterOption) => filterOption.selected).length > 0
          filter.showFilterOptions = false
          await filter.filterOptions.map((filterOption) => {
            if (!filterOption.selected) {
              filterOption.type = filter.name
              if (this.selected.some((selectedFilter) => selectedFilter.name === filterOption.name && selectedFilter.type === filterOption.type)) {
                const index = this.selected.findIndex((selectedFilter) => selectedFilter.name === filterOption.name && selectedFilter.type === filterOption.type)
                this.selected.splice(index, 1)
              }
            } else {
              if (!this.selected.some((selectedFilter) => selectedFilter.name === filterOption.name && selectedFilter.type === filterOption.type)) {
                filterOption.type = filter.name
                if (this.selected.filter((e) => e.name === filterOption.name && e.type === filterOption.type).length == 0) {
                  this.selected.push(filterOption)
                }
              }
            }
          })
        }
      })
      this.selectedSort = this.sortOptions.filter((sortOption) => sortOption.selected)[0].value
      this.$emit('apply-filter', { filters: this.selected, sortBy: this.selectedSort, filterType: filterEvent.name })
    },
    async checkSelections(data, filterEvent) {
      let value = data.detail.value
      await this.filters.map(async (filter) => {
        if (filter.name === filterEvent.name) {
          await filter.filterOptions.map((filterOption) => {
            if (filterOption.name === value) filterOption.selected = !filterOption.selected
          })
        }
      })
    },
    async onFilterType(filterEvent) {
      this.handleClearInput(filterEvent)
      await this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name && filterEvent.name === this.selectedFilterType) {
          filter.showFilterOptions = false
          this.selectedFilterType = ''
        } else if (filterEvent.name === filter.name) {
          this.selectedFilterType = filterEvent.name
          filter.showFilterOptions = true
        } else {
          filter.showFilterOptions = false
        }
        if (filter.showFilterOptions) {
          const contentLayoutDiv = document.getElementById('contentlayout')
          contentLayoutDiv.style.overflowY = 'hidden'
        } else {
          await filter.filterOptions.map((filterOption) => {
            filterOption.selected = this.selected.some((selectedFilter) => selectedFilter.name === filterOption.name)
          })
        }
        if (!this.selectedFilterType) {
          const contentLayoutDiv = document.getElementById('contentlayout')
          contentLayoutDiv.style.overflowY = 'scroll'
        }
      })
      this.showSortOption = false
    },
    async handleClearInput(filterEvent) {
      this.searchFilter = ''
      await this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name) {
          await filter.filterOptions.map((filterOption) => {
            filterOption.style = 'display: flex'
          })
        }
      })
    },
    async handleSearchInput(event, filterEvent) {
      this.searchFilter = event.target.value.trim()
      await this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name) {
          await filter.filterOptions.map((filterOption) => {
            const find = filterOption.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1
            filterOption.style = find ? 'display: flex' : 'display: none'
          })
        }
      })
    },
    async removeFilter(filterOption) {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      if (this.selected.includes(filterOption)) {
        const index = this.selected.findIndex((selectedFilter) => selectedFilter.name === filterOption.name && selectedFilter.type === filterOption.type)
        this.selected.splice(index, 1)
      }
      await this.filters.map((filter) => {
        if (filter.name === filterOption.type) {
          filter.filterOptions.map((filterOptionObj) => {
            if (filterOptionObj.name === filterOption.name) filterOptionObj.selected = !filterOptionObj.selected
          })
          filter.isApply = filter.filterOptions.filter((filterOption) => filterOption.selected).length > 0
        }
      })
      this.selectedSort = this.sortOptions.filter((sortOption) => sortOption.selected)[0].value
      this.$emit('apply-filter', { filters: this.selected, sortBy: this.selectedSort, filterType: filterOption.type })
    },
    async onSort() {
      this.showSortOption = !this.showSortOption
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = this.showSortOption ? 'hidden' : 'scroll'
      await this.filters.map((filter) => {
        if (filter.name === this.selectedFilterType) {
          filter.showFilterOptions = false
          this.selectedFilterType = ''
        }
      })
    },
    onCancelSort() {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      this.showSortOption = false
    },
    onApplySort() {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      this.showSortOption = false
      this.selectedSort = this.sortOptions.filter((sortOption) => sortOption.selected)[0].value
      this.$emit('apply-sort', { filters: this.selected, sortBy: this.selectedSort, filterType: '' })
    },
    handleRadioSelect(event) {
      const value = event.target.value
      this.sortOptions.map((sortOption) => {
        sortOption.selected = value === sortOption.name
      })
    },
    async openSideFilter() {
      const contentLayoutDiv = document.getElementById('contentlayout')
      contentLayoutDiv.style.overflowY = 'scroll'
      this.isSideFilterActive = !this.isSideFilterActive
      if (!this.isSideFilterActive) {
        this.$emit('side-filter', false)
      }
      this.showSortOption = false
      await this.filters.map((filter) => {
        if (filter.name === this.selectedFilterType) {
          filter.showFilterOptions = false
          this.selectedFilterType = ''
        }
      })
      let modal = await popoverController.create({
        component: SearchAllFiltersSideBar,
        cssClass: 'filter-container-class',
        swipeToClose: false,
        showBackdrop: true,
        backdropDismiss: false,
        componentProps: {
          parent: this,
          propsData: {
            filters: this.filters,
            resultsCount: this.resultsCount
          }
        }
      })
      await modal.present()
    },
    filterName(filterOption, filterType) {
      const capitalize = (s) => {
        return s.substr(0, 1).toUpperCase() + s.substr(1)
      }
      return filterType === 'Duration' || filterOption.type === 'Duration' ? filterOption.id : capitalize(filterOption.name)
    }
  }
}
</script>

<style lang="scss">
.filter-option-container {
  ion-searchbar ion-icon {
    top: 9px !important;
  }
  .searchbar-input.sc-ion-searchbar-md {
    padding: 2px 30px 2px 40px !important;
    border: 2px solid #000000;
    font-size: 14px;
    font-weight: bold;
    color: black;
  }

  ion-searchbar {
    --box-shadow: none;

    .searchbar-input-container :-moz-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container ::placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container ::-moz-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container ::-webkit-input-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }

    .searchbar-input-container :-ms-input-placeholder {
      font-family: Arial, Helvetica, sans-serif !important;
      font-weight: bold !important;
      color: black !important;
      opacity: 1 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.filter-selected {
  .svg-icon {
    width: 9px !important;
    padding: 2px 0 0 0;
    cursor: pointer;
  }
}

.filter-bar {
  position: fixed;
  z-index: 9999;
  right: 0;
  width: 364px;
}

.filter-container-backdrop,
.sort-container-backdrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 2rem;
}

.sort-container-backdrop {
  left: unset;
  right: 0;
}

.filter-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filter-list {
  display: flex;
  gap: 3px;
  position: relative;
}

.filter-title,
.filter-selected-title,
.sort-title {
  font-family: 'Futura PT Demi';
  font-size: 20px;
  color: #000000;
  align-self: center;
}

.filter-selected-title {
  font-size: 1rem;
}

.filter-type,
.sort-type {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px solid #e0e1e1;
  border-radius: 20px;
  font-family: Arial;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  padding: 5px 15px;
  width: max-content;
  &:hover {
    border: 2px solid #000000;
    background: #efefef;
    cursor: pointer;
    .svg-icon {
      transform: var(--transform);
    }
  }
}

.all-filter {
  display: none;
}

.filter-type-selected {
  border: 2px solid #000000;
  background: #efefef;
  cursor: pointer;
}

.filter-option-container,
.sort-option-container {
  position: absolute;
  background: #f6f6f6;
  // box-shadow: 0px 0px 8px #00000029;
  border-radius: 20px;
  z-index: 2;
}

.sort-option-container {
  margin-top: 30px;
  left: -60px;
}

ion-searchbar {
  margin: unset !important;
  --background: white !important;
  --border-radius: 20px;
  width: 100% !important;
  padding: 20px 20px 0;
  --border: 2px black !important;
}

.filter-checkbox {
  --size: 18px;
  margin-right: 12px;
  --border-radius: 4px;
  --border-color: #bcc2d4;
  --border-width: 2px;
}

.filter-option-content,
.sort-option-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 20px 15px;
}

.filter-option-content-scroll {
  height: 250px;
  overflow-y: scroll;
  margin-right: 20px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 8px;
  }

  // scrollbar track
  &::-webkit-scrollbar-track-piece {
    background: #bcc2d4;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}

.filter-option-title,
.sort-option-title,
.filter-option-title-disable {
  font-family: 'Arial';
  font-size: 14px;
  color: #000000;
}

.filter-option-title-disable {
  color: #9f9f9f;
}

.filter-option-footer,
.sort-option-footer {
  display: flex;
  gap: 3rem;
  padding: 10px 32px;
  align-items: center;
  border-top: 1px solid #e0e1e1;
}

.filter-option-footer-cancel,
.sort-option-footer-cancel {
  font-family: 'Futura PT Demi';
  font-size: 1rem;
  color: #1f3370;
  cursor: pointer;
}

.filter-option-footer-btn-apply,
.sort-option-footer-btn-apply {
  cursor: pointer;
  text-transform: none;
  font-size: 16px;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #feb92e;
  --ion-color-primary-contrast: black;
  --box-shadow: none;
}

.sort-container,
.sort-option {
  display: flex;
  gap: 15px;
  position: relative;
}

.sort-option {
  align-items: center;
}

.filter-selected-container {
  display: flex;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-selected {
  display: flex;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
  font-size: 11px;
  font-family: 'Arial';
  padding: 5px 15px;
  color: #ffffff;
  gap: 10px;
}

.filter-selected-clear-all {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 14px;
  color: #1f3370;
  align-self: center;
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #1f3370;
  }
}

ion-radio {
  --border-radius: 23px;
  --color: #bcc2d4;
  --color-checked: #1f3370;
}

@media screen and (max-width: 1380px) {
  .filter-list,
  .sort-container {
    gap: 5px;
  }

  .filter-type,
  .sort-type {
    padding: 5px 5px;
  }

  .sort-option-container {
    left: -72px;
  }
}

@media screen and (max-width: 1280px) {
  .filter-type,
  .sort-type {
    font-size: 14px;
  }

  .sort-option-container {
    left: -85px;
  }
}

@media screen and (max-width: 1200px) {
  .all-filter {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 2px solid #e0e1e1;
    border-radius: 20px;
    font-family: Arial;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    padding: 5px 9px;
    &:hover {
      border: 2px solid #000000;
      background: #efefef;
      cursor: pointer;
      .svg-icon {
        transform: rotate(180deg);
      }
    }
  }

  .filter-list {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .sort-title {
    font-size: 14px;
  }

  .sort-option-container {
    left: -109px;
  }

  .filter-type,
  .sort-type {
    font-size: 11px;
  }

  .filter-bar {
    width: 100%;
  }
}
</style>
