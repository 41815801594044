import { render, staticRenderFns } from "./CommentFlaggedAlert.vue?vue&type=template&id=f890d982&scoped=true&"
import script from "./CommentFlaggedAlert.vue?vue&type=script&lang=js&"
export * from "./CommentFlaggedAlert.vue?vue&type=script&lang=js&"
import style0 from "./CommentFlaggedAlert.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CommentFlaggedAlert.vue?vue&type=style&index=1&id=f890d982&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f890d982",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/vtrinh004/Desktop/YTC/web/proedge-learn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f890d982')) {
      api.createRecord('f890d982', component.options)
    } else {
      api.reload('f890d982', component.options)
    }
    module.hot.accept("./CommentFlaggedAlert.vue?vue&type=template&id=f890d982&scoped=true&", function () {
      api.rerender('f890d982', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/CommentFlaggedAlert.vue"
export default component.exports