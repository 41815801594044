<template>
  <div :class="isDesktopPlatform ? '' : 'non-white-background'" class="mylearning-panel main-content" ref="top">
    <div>
      <div class="banner">
        <div class="title">Your curated learning</div>
      </div>
      <img src="@/assets/images/my-learning-hero.png" class="banner_image" />
      <div v-if="dataReady" ref="tab" :class="isDesktopPlatform ? 'mylearning-panel-desktop' : 'mylearning-panel-mobile'">
        <MyLearningTab
          :tabs="tabs"
          :selectedTabIndex="selectedTabIndex"
          @update-tab-index="updateTabIndex"
          :key="selectedTabIndex"
          :showFilter="
            (selectedTabIndex === 0 && data.required.items.length > 0) ||
            (selectedTabIndex === 1 && data.learning.items.length > 0) ||
            (selectedTabIndex === 2 && data.external.items.length > 0)
          "
        >
          <MyLearningFilter
            :userAccess="userAccess"
            :on-tab="tabs[selectedTabIndex].title"
            :data="data"
            :sideFilterData="sideFilterData"
            @on-apply="onApply($event)"
            @on-open-filters="onOpenFilters"
            @on-clear-filters="onClearFilters($event)"
            @side-filter="onSideFilterData($event)"
            @on-open-external-sidebar="openExternalSidebar"
            slot="mylearning-item-filter"
          />
          <MyLearningItemContainer
            @scrollToElement="scrollToElement"
            :data="data"
            slot="mylearning-item-container"
            @update-tab-index="updateTabIndex"
            @learning-item-container-click="onLearningItemContainerClick"
            @learning-page-click="onLearningPageClick($event)"
            @external-page-click="onExternalPageClick($event)"
            @required-page-click="onRequiredPageClick($event)"
            :selectedTabIndex="selectedTabIndex"
            :key="selectedTabIndex"
            :externalSidebarActive="externalSidebarActive"
            @on-toggle-external-sidebar="openExternalSidebar"
            @on-delete-external-item="onDeleteExternalItem"
          />
        </MyLearningTab>
      </div>
      <div v-if="!dataReady">
        <MyLearningSkeleton />
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import util from '@/utils/helper'
import { bootstrap } from 'vue-gtag'
import localforage from 'localforage'
import moment from 'moment-timezone'
import MyLearningTab from '@/views/my-learning/components/MyLearningTab'
import MyLearningItemContainer from '@/views/my-learning/components/MyLearningItemContainer'
import MyLearningFilter from '@/views/my-learning/components/MyLearningFilter'
import MyLearningSkeleton from '@/views/my-learning/components/MyLearningSkeleton'
import EventBus from '@/plugins/eventbus'

export default {
  name: 'MyLearning',
  components: { MyLearningTab, MyLearningItemContainer, MyLearningFilter, MyLearningSkeleton },
  props: {},
  data() {
    return {
      role: '',
      tenantName: '',
      isDesktopPlatform: true,
      selectedTabIndex: 0,
      data: {
        required: {
          items: [],
          pageCurrent: 1,
          pageCount: 0,
          filters: {},
          selectedFilters: [],
          totalItems: 0,
          sortBy: ''
        },
        learning: {
          items: [],
          pageCurrent: 1,
          pageCount: 0,
          filters: {},
          selectedFilters: [],
          totalItems: 0,
          sortBy: {}
        },
        external: {
          items: [],
          pageCurrent: 1,
          pageCount: 0,
          filters: {},
          selectedFilters: [],
          totalItems: 0,
          sortBy: ''
        }
      },
      tabs: [
        { title: 'Required', displayTitle: 'Required', count: 0, isShow: true },
        { title: 'Recommended', displayTitle: 'Recommended by your company', count: 0, isShow: true },
        { title: 'Added by you', displayTitle: 'Added by you', count: 0, isShow: true }
      ],
      isNewMyLearning: true,
      sideFilterData: false,
      dataReady: false,
      tenantAsset: {
        enabled: false,
        userAccessAsset: false
      },
      externalSidebarActive: false,
      bookmarkedItems: []
    }
  },
  computed: {},
  beforeRouteLeave(to, from, next) {
    if (
      !(
        to.path.includes('course') ||
        to.path.includes('credential') ||
        to.path.includes('content') ||
        to.path.includes('collection') ||
        to.path.includes('external') ||
        to.path.includes('ugtrack')
      )
    ) {
      window.history.pushState({}, '', to.fullPath)
    }
    next()
  },
  watch: {
    async $route(to, from) {
      this.isNewMyLearning = !(
        from.path.includes('course') ||
        from.path.includes('credential') ||
        from.path.includes('content') ||
        from.path.includes('collection') ||
        from.path.includes('external')
      )
      if (
        !from.path.includes('course') &&
        !from.path.includes('credential') &&
        !from.path.includes('content') &&
        !from.path.includes('collection') &&
        !from.path.includes('external') &&
        to.path.includes('mylearning')
      ) {
        this.selectedTabIndex = to.params.type === 'addedbyyou' ? 2 : to.params.type === 'recommended' ? 1 : 0
        this.updateTabIndex(this.selectedTabIndex)
      }
    }
  },
  async activated() {
    EventBus.$on('edit-bookmark', this.editBookmark)
    await localforage.removeItem('deep_link_my_learning_type')
    this.dataReady = false
    this.data.required.items = []
    this.data.learning.items = []
    this.data.external.items = []
    this.data.required.selectedFilters = []
    this.data.learning.selectedFilters = []
    this.data.external.selectedFilters = []
    this.bookmarkedItems = []
    this.data.learning.sortBy = {
      name: 'Best match',
      value: 'TRACK_PRIORITY',
      featureFlagName: 'bestmatch'
    }
    this.data.external.sortBy = 'LPI_COMPLETED_AT'
    this.data.required.sortBy = 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT'
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.$learnAdmin.getUserLearningHistory({ token: this.authToken })
    await this.determineUserAccess()
    const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
    this.tenantName = tenant?.name
    // else {
    //   const summary = await this.$learnAdmin.getUserLearningProgressSummary({ token: this.authToken })
    //   if ({}.hasOwnProperty.call(summary, 'currentRole')) {
    //     this.role = summary.currentRole.name
    //   }
    // }
    let learnPayload = {}
    let externalPayload = {}
    let requiredPayload = {}
    if (!this.isNewMyLearning) {
      const mylearningData = await localforage.getItem('my-learning-data')
      this.selectedTabIndex = mylearningData?.selectedTabIndex
      this.data = mylearningData.data
      learnPayload = {
        sortBy: this.data.learning.sortBy.value,
        filters: this.groupByFilter(this.data.learning.selectedFilters)
      }
      externalPayload = {
        sortBy: 'LPI_COMPLETED_AT',
        filters: this.groupByFilter(this.data.external.selectedFilters)
      }
      requiredPayload = {
        sortBy: 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT',
        filters: this.groupByFilter(this.data.required.selectedFilters)
      }
    }
    if (this.$route.params.type) {
      let type = this.$route.params.type
      const requiredTabAccess = this.tabs[0].isShow
      const addedByYouTabAccess = this.tabs[2].isShow
      let index = type === 'addedbyyou' ? 2 : type === 'recommended' ? 1 : 0
      this.selectedTabIndex = this.tabs[index].isShow
        ? type === 'addedbyyou'
          ? 2
          : type === 'recommended'
          ? 1
          : 0
        : !requiredTabAccess && !addedByYouTabAccess
        ? 1
        : !requiredTabAccess
        ? 1
        : 0
    }
    await this.getMyLearningPlanItems(learnPayload)
    await this.getExternalContentItems(externalPayload)
    await this.getRequiredLearningItems(requiredPayload)
    this.tabs[0].count = this.data.required.totalItems
    this.tabs[1].count = this.data.learning.totalItems
    this.tabs[2].count = this.data.external.totalItems
    this.selectedTabIndex = this.$route.params.isNotFromDeepLink && this.selectedTabIndex === 0 ? (this.tabs[0].count != 0 ? 0 : 1) : this.selectedTabIndex
    this.updateTabIndex(this.selectedTabIndex)
    this.dataReady = true
    if (this.isNewMyLearning) {
      const container = document.querySelector('#contentlayout')
      container.scrollTo(0, 0)
    }
    if (this.$route.params.openExternalPopup) {
      this.openExternalSidebar()
    }
    this.$eventBus.$on('on-toggle-external-sidebar', this.toggleExternalSidebar)
  },
  methods: {
    async determineUserAccess() {
      const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(tenant.settings)
      this.userAccess = await localforage.getItem('my_user_access')
      const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
      this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
      this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
      this.userAccess.assetAccess = this.tenantAsset.userAccessAsset && this.tenantAsset.enabled
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      const externalTenantSetting = {}.hasOwnProperty.call(settings, 'add-external-content-enabled') && settings['add-external-content-enabled']
      this.tabs[0].isShow = enabledFeatureFlags['learn_required_learning_enabled'] && {}.hasOwnProperty.call(settings, 'required-learning') && settings['required-learning']
      this.tabs[2].isShow = externalTenantSetting
    },
    editBookmark(item) {
      let isDuplicateFound = false
      this.bookmarkedItems.forEach((bookmark) => {
        if (bookmark.id == item.id) {
          bookmark.isBookmarked = item.isBookmarked
          isDuplicateFound = true
        }
      })
      if (!isDuplicateFound) this.bookmarkedItems.push(item)
    },
    async updateTabIndex(index) {
      this.selectedTabIndex = index
      const type = index === 2 ? 'addedbyyou' : index === 1 ? 'recommended' : 'required'
      if (this.bookmarkedItems.length > 0 && type != 'addedbyyou') this.updateBookmarkedItems(type)
      await this.GAEventTrackTabSelect(index)
      if (this.$route.path !== '/profile') window.history.replaceState({}, '', `/mylearning/${type}`)
    },
    updateBookmarkedItems(type) {
      const tabName = type === 'addedbyyou' ? 'external' : type === 'recommended' ? 'learning' : 'required'
      this.data[tabName].items.forEach((item) => {
        this.bookmarkedItems.forEach((bookmark) => {
          if (bookmark.id == item.id && item.meta) {
            Object.assign(item.meta, { isBookmarked: bookmark.isBookmarked })
          }
        })
      })
    },
    async onLearningItemContainerClick() {
      const mylearningData = {
        data: this.data,
        selectedTabIndex: this.selectedTabIndex
      }
      await localforage.setItem('my-learning-data', mylearningData)
    },
    scrollToElement(page) {
      this.data[page.name].start = page.start
      this.data[page.name].end = page.end
      this.data[page.name].pageCurrent = page.pageCurrent
      this.$refs.top.scrollIntoView()
    },
    async getMyLearningPlanItems(data) {
      this.data.learning.items = []
      this.dataReady = false
      let payload = {
        token: this.authToken,
        body: {
          pageNo: data && data.pageNumber ? data.pageNumber : 1,
          pageSize: 20,
          includeCompleted: false,
          includeNoProgress: data && data.filters && data.filters['Status'] ? data.filters['Status'].some((x) => x.maxProgress == 0 && x.minProgress == 0) : true,
          includeInProgress: data && data.filters && data.filters['Status'] ? data.filters['Status'].some((x) => x.maxProgress == 99 && x.minProgress == 1) : true,
          includeSelfLearned: false,
          contentAccessType: 'ALL',
          contentType: data && data.filters && data.filters['Content type'] ? data.filters['Content type'] : [],
          providers: data && data.filters && data.filters['Provider'] ? data.filters['Provider'] : [],
          duration: data && data.filters && data.filters['Duration'] ? data.filters['Duration'] : [],
          creditHours: data && data.filters && data.filters['Credit hours'] ? data.filters['Credit hours'] : [],
          creditType: data && data.filters && data.filters['Credit type'] ? data.filters['Credit type'] : [],
          accreditation: data && data.filters && data.filters['Accreditation'] ? data.filters['Accreditation'] : [],
          level: data && data.filters && data.filters['Level'] ? data.filters['Level'] : [],
          function: data && data.filters && data.filters['Function'] ? data.filters['Function'] : [],
          tracks: data && data.filters && data.filters['Tracks'] ? data.filters['Tracks'] : [],
          sortBy: data && data.sortBy ? data.sortBy : this.data.learning.sortBy.value,
          dueDate: 'ONLY_WITH_NO_DUE_DATE',
          trackSource: ['PLAN'],
          contentSource: ['INTERNAL']
        }
      }
      payload.body.duration = this.setDuration(payload.body.duration)
      payload.body.creditHours = this.setCreditHours(payload.body.creditHours)
      this.data.learning.filters = await this.$learnAdmin.getMyLearningPlanFilters(payload)
      if (this.data.learning.filters.track) {
        this.data.learning.filters.track = this.data.learning.filters.track.filter((el) => el.source == 'PLAN')
      }
      const result = await this.$learnAdmin.getMyLearningPlanItemsWithFilters(payload)
      this.data.learning.pageCurrent = result.pageNumber
      this.data.learning.pageCount = result.totalPages
      this.data.learning.totalItems = result.totalContent
      this.tabs[1].count = this.data.learning.totalItems
      let ratingDetailsArray = []
      if (result && result?.content) {
        ratingDetailsArray = (await this.determineContentRating(result.content)) || []
      }
      await result.content.map((item, index) => {
        item.id = item.artifactId
        item.image = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.image : ''
        item.meta = {}.hasOwnProperty.call(item, 'learningItem') && {}.hasOwnProperty.call(item.learningItem, 'meta') ? { ...item.meta, ...item.learningItem.meta } : item.meta
        item.assetType = item.format.name.toLowerCase() === 'credential' ? 'credentials' : item.format.name.toLowerCase() === 'automation' ? 'automation' : 'artifacts'
        item.numComments = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numComments : 0
        item.numLikes = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numLikes : 0
        item.provider = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.provider : item.provider
        item.emsiSkills = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.emsiSkills : []
        item.synopsis = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.synopsis : ''
        item.duration = !{}.hasOwnProperty.call(item, 'learningItem')
          ? null
          : item.learningItem.duration < 3600
          ? `${Math.round(item.learningItem.duration / 60)} min`
          : `${Math.round(item.learningItem.duration / 3600)} hrs`
        item.progressApproximation = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.progressApproximation : null
        item.launchBtn =
          item.assetType != 'automation'
            ? item.progress > 0 && item.progress < 100
              ? 'Resume'
              : 'Launch'
            : item.learningItem.author.toLowerCase() != 'proedge'
            ? 'Open'
            : 'Download'
        item.scope = { isExternal: item.assetType === 'automation' && item.learningItem.author.toLowerCase() != 'proedge' }
        item.publishedAt = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'publishedAt') ? item.learningItem.publishedAt : '') : ''
        item.requiredBy = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'requiredBy') ? item.learningItem.requiredBy : '') : ''
        item.content = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'content') ? item.learningItem.content : '') : ''
        item.rating =
          ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.id)
            ? ratingDetailsArray.filter((rating) => rating.entityId == item.id)[0]?.avgRating
            : 0
        item.selfProgressDeclaration = {}.hasOwnProperty.call(item, 'learningItem')
          ? {}.hasOwnProperty.call(item.learningItem, 'selfProgressDeclaration')
            ? item.learningItem.selfProgressDeclaration
            : false
          : false
        item.level = {}.hasOwnProperty.call(item, 'learningItem')
          ? item.format.name.toLowerCase() === 'credential'
            ? {}.hasOwnProperty.call(item.learningItem, 'level')
              ? item.learningItem.level
              : ''
            : {}.hasOwnProperty.call(item.learningItem, 'courseLevels')
            ? item.learningItem.courseLevels[0]
            : ''
          : ''
        item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
        this.data.learning.items[index] = item
      })
      let payloadResult = result
      payloadResult.timestamp = new Date().getTime()
      const payloadGA = {
        action: 'MyLearning',
        category: 'MyLearning Page Load',
        value: JSON.stringify(payloadResult)
      }
      await this.ClickstreamEventTrack(payloadGA)
      this.dataReady = true
    },
    async getExternalContentItems(data) {
      this.data.external.items = []
      this.dataReady = false
      let payload = {
        token: this.authToken,
        body: {
          pageNo: data && data.pageNumber ? data.pageNumber : 1,
          pageSize: 20,
          includeCompleted: false,
          includeNoProgress: data && data.filters && data.filters['Status'] ? data.filters['Status'].some((x) => x.maxProgress == 0 && x.minProgress == 0) : true,
          includeInProgress: data && data.filters && data.filters['Status'] ? data.filters['Status'].some((x) => x.maxProgress == 99 && x.minProgress == 1) : true,
          includeItemsWithNoDueDate: true,
          contentType: data && data.filters && data.filters['Content type'] ? data.filters['Content type'] : [],
          providers: data && data.filters && data.filters['Provider'] ? data.filters['Provider'] : [],
          duration: data && data.filters && data.filters['Duration'] ? data.filters['Duration'] : [],
          creditHours: data && data.filters && data.filters['Credit hours'] ? data.filters['Credit hours'] : [],
          creditType: data && data.filters && data.filters['Credit type'] ? data.filters['Credit type'] : [],
          accreditation: data && data.filters && data.filters['Accreditation'] ? data.filters['Accreditation'] : [],
          level: data && data.filters && data.filters['Level'] ? data.filters['Level'] : [],
          function: data && data.filters && data.filters['Function'] ? data.filters['Function'] : [],
          tracks: data && data.filters && data.filters['Tracks'] ? data.filters['Tracks'] : [],
          sortBy: 'ARTIFACT_ADDED_TO_TRACK_AND_EXTERNAL_ARTIFACT_CREATED_AT',
          contentSource: ['EXTERNAL'],
          trackSource: ['USER']
        }
      }
      payload.body.duration = this.setDuration(payload.body.duration)
      payload.body.creditHours = this.setCreditHours(payload.body.creditHours)
      this.data.external.filters = await this.$learnAdmin.getExternalLearningPlanFilters(payload)
      const result = await this.$learnAdmin.getExternalContentItems(payload)
      this.data.external.pageCurrent = result.pageNumber
      this.data.external.pageCount = result.totalPages
      this.data.external.totalItems = result.totalContent
      this.tabs[2].count = this.data.external.totalItems
      let externalRatingArray = []
      if (result && result?.content) {
        externalRatingArray = (await this.determineContentRating(result.content)) || []
      }
      await result.content.map((item, index) => {
        item.id = item.learningItem.id ? item.learningItem.id : item.artifactId
        item.guid = item.learningItem.guid
        item.scope = { isLearning: false, isExternal: item.source === 'EXTERNAL' ? true : false }
        item.image = item.source === 'EXTERNAL' ? '' : item.learningItem.image
        item.numComments = item.learningItem.numComments
        item.numLikes = item.learningItem.numLikes
        item.provider = item.source === 'EXTERNAL' ? { name: {}.hasOwnProperty.call(item.learningItem, 'source') ? item.learningItem.source : '' } : item.learningItem.provider
        item.emsiSkills = []
        item.synopsis = util.encodeDecodeString(item.learningItem.synopsis)
        item.duration = !{}.hasOwnProperty.call(item, 'learningItem')
          ? null
          : item.learningItem.duration < 3600
          ? `${Math.round(item.learningItem.duration / 60)} min`
          : `${Math.round(item.learningItem.duration / 3600)} hrs`
        item.progressApproximation = null
        item.format = item.source === 'EXTERNAL' ? item.learningItem.format : item.format
        item.assetType =
          item.source === 'EXTERNAL'
            ? 'artifacts'
            : item.format.name.toLowerCase() === 'credential'
            ? 'credentials'
            : item.format.name.toLowerCase() === 'automation'
            ? 'automation'
            : 'artifacts'
        item.title = util.encodeDecodeString(item.learningItem.title)
        item.launchBtn =
          item.source === 'EXTERNAL'
            ? 'Open'
            : item.assetType != 'automation'
            ? item.progress > 0 && item.progress < 100
              ? 'Resume'
              : 'Launch'
            : item.learningItem.author.toLowerCase() != 'proedge'
            ? 'Open'
            : 'Download'
        item.publishedAt = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'publishedAt') ? item.learningItem.publishedAt : '') : ''
        item.requiredBy = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'requiredBy') ? item.learningItem.requiredBy : '') : ''
        item.content = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'content') ? item.learningItem.content : '') : ''
        item.rating =
          externalRatingArray.length > 0 && externalRatingArray.some((rating) => rating['entityId'] === item.artifactId)
            ? externalRatingArray.filter((rating) => rating.entityId == item.artifactId)[0]?.avgRating
            : 0
        item.selfProgressDeclaration = {}.hasOwnProperty.call(item, 'learningItem')
          ? {}.hasOwnProperty.call(item.learningItem, 'selfProgressDeclaration')
            ? item.learningItem.selfProgressDeclaration
            : false
          : false
        item.level = {}.hasOwnProperty.call(item, 'learningItem')
          ? item.format.name.toLowerCase() === 'credential'
            ? {}.hasOwnProperty.call(item.learningItem, 'level')
              ? item.learningItem.level
              : ''
            : {}.hasOwnProperty.call(item.learningItem, 'courseLevels')
            ? item.learningItem.courseLevels[0]
            : ''
          : ''
        item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
        if (this.tabs[0].isShow) {
          const dueDates = []
          if ({}.hasOwnProperty.call(item, 'tracks')) {
            item.tracks.map((track) => {
              if ({}.hasOwnProperty.call(track, 'dueDate') && track.dueDate != null) {
                dueDates.push(new Date(track.dueDate))
              }
            })
          }
          if (dueDates.length > 0) {
            var minDate = moment(Math.min.apply(null, dueDates))
            const formattedToday = moment(minDate).tz('America/New_York').format('DD MMM YYYY')
            if (item.timeToProgressSyncInHours > 0) {
              moment(minDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
                ? (item.dueDate = formattedToday)
                : (item.pastDueDate = formattedToday)
            } else {
              moment(minDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
            }
          }
        }
        this.data.external.items[index] = item
      })
      this.dataReady = true
    },
    async getRequiredLearningItems(data) {
      this.data.required.items = []
      this.dataReady = false
      let payload = {
        token: this.authToken,
        body: {
          pageNo: data && data.pageNumber ? data.pageNumber : 1,
          pageSize: 20,
          includeCompleted: false,
          includeNoProgress: data && data.filters && data.filters['Status'] ? data.filters['Status'].some((x) => x.maxProgress == 0 && x.minProgress == 0) : true,
          includeInProgress: data && data.filters && data.filters['Status'] ? data.filters['Status'].some((x) => x.maxProgress == 99 && x.minProgress == 1) : true,
          includeItemsWithNoDueDate: false,
          includeSelfLearned: false,
          contentAccessType: 'ALL',
          contentType: data && data.filters && data.filters['Content type'] ? data.filters['Content type'] : [],
          providers: data && data.filters && data.filters['Provider'] ? data.filters['Provider'] : [],
          duration: data && data.filters && data.filters['Duration'] ? data.filters['Duration'] : [],
          creditHours: data && data.filters && data.filters['Credit hours'] ? data.filters['Credit hours'] : [],
          creditType: data && data.filters && data.filters['Credit type'] ? data.filters['Credit type'] : [],
          accreditation: data && data.filters && data.filters['Accreditation'] ? data.filters['Accreditation'] : [],
          level: data && data.filters && data.filters['Level'] ? data.filters['Level'] : [],
          function: data && data.filters && data.filters['Function'] ? data.filters['Function'] : [],
          tracks: data && data.filters && data.filters['Tracks'] ? data.filters['Tracks'] : [],
          sortBy: 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT',
          dueDate: 'ONLY_WITH_DUE_DATE',
          contentSource: ['INTERNAL'],
          trackSource: ['PLAN']
        }
      }
      payload.body.duration = this.setDuration(payload.body.duration)
      payload.body.creditHours = this.setCreditHours(payload.body.creditHours)
      this.data.required.filters = await this.$learnAdmin.getMyLearningPlanFilters(payload)
      if (this.data.required.filters.track) {
        this.data.required.filters.track = this.data.required.filters.track.filter((el) => el.source == 'PLAN')
      }
      const result = await this.$learnAdmin.getMyLearningPlanItemsWithFilters(payload)
      this.data.required.pageCurrent = result.pageNumber
      this.data.required.pageCount = result.totalPages
      this.data.required.totalItems = result.totalContent
      this.tabs[0].count = this.data.required.totalItems
      let ratingDetailsArray = []
      if (result && result?.content) {
        ratingDetailsArray = (await this.determineContentRating(result.content)) || []
      }
      await result.content.map((item, index) => {
        item.id = item.artifactId
        item.image = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.image : ''
        item.meta = {}.hasOwnProperty.call(item, 'learningItem') && {}.hasOwnProperty.call(item.learningItem, 'meta') ? { ...item.meta, ...item.learningItem.meta } : item.meta
        item.assetType = item.format.name.toLowerCase() === 'credential' ? 'credentials' : item.format.name.toLowerCase() === 'automation' ? 'automation' : 'artifacts'
        item.numComments = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numComments : 0
        item.timeToProgressSyncInHours = item.provider.timeToProgressSyncInHours
        item.numLikes = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numLikes : 0
        item.provider = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.provider : item.provider
        item.emsiSkills = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.emsiSkills : []
        item.synopsis = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.synopsis : ''
        item.duration = !{}.hasOwnProperty.call(item, 'learningItem')
          ? null
          : item.learningItem.durationMinutes < 60
          ? `${item.learningItem.durationMinutes} min`
          : `${Math.round(item.learningItem.durationMinutes / 60)} hrs`
        item.progressApproximation = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.progressApproximation : null
        item.launchBtn =
          item.assetType != 'automation'
            ? item.progress > 0 && item.progress < 100
              ? 'Resume'
              : 'Launch'
            : item.learningItem.author.toLowerCase() != 'proedge'
            ? 'Open'
            : 'Download'
        item.scope = { isExternal: item.assetType === 'automation' && item.learningItem.author.toLowerCase() != 'proedge' }
        item.publishedAt = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'publishedAt') ? item.learningItem.publishedAt : '') : ''
        item.requiredBy = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'requiredBy') ? item.learningItem.requiredBy : '') : ''
        item.content = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'content') ? item.learningItem.content : '') : ''
        item.rating =
          ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.id)
            ? ratingDetailsArray.filter((rating) => rating.entityId == item.id)[0]?.avgRating
            : 0
        item.selfProgressDeclaration = {}.hasOwnProperty.call(item, 'learningItem')
          ? {}.hasOwnProperty.call(item.learningItem, 'selfProgressDeclaration')
            ? item.learningItem.selfProgressDeclaration
            : false
          : false
        item.level = {}.hasOwnProperty.call(item, 'learningItem')
          ? item.format.name.toLowerCase() === 'credential'
            ? {}.hasOwnProperty.call(item.learningItem, 'level')
              ? item.learningItem.level
              : ''
            : {}.hasOwnProperty.call(item.learningItem, 'courseLevels')
            ? item.learningItem.courseLevels[0]
            : ''
          : ''
        item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
        const dueDates = []
        if ({}.hasOwnProperty.call(item, 'tracks')) {
          item.tracks.map((track) => {
            if ({}.hasOwnProperty.call(track, 'dueDate') && track.dueDate != null) {
              dueDates.push(new Date(track.dueDate))
            }
          })
        }
        if (dueDates.length > 0) {
          var minDate = moment(Math.min.apply(null, dueDates))
          const formattedToday = moment(minDate).tz('America/New_York').format('DD MMM YYYY')
          if (item.timeToProgressSyncInHours > 0) {
            moment(minDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
              ? (item.dueDate = formattedToday)
              : (item.pastDueDate = formattedToday)
          } else {
            moment(minDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
          }
        }
        if (item.progress < 100) this.data.required.items[index] = item
      })
      let payloadResult = result
      payloadResult.timestamp = new Date().getTime()
      const payloadGA = {
        action: 'MyLearning',
        category: 'Required Page Load',
        device: this.$isTablet ? 'Tablet' : this.$platform,
        value: JSON.stringify(payloadResult)
      }
      await this.ClickstreamEventTrack(payloadGA)
      this.dataReady = true
    },
    determineContentRating(learningContentValue) {
      var contentIdList = []
      learningContentValue.map((item) => {
        contentIdList.push(item.artifactId)
      })
      if (contentIdList && contentIdList.length > 0) {
        const contentPayload = {
          token: this.authToken,
          body: contentIdList
        }
        return this.$learnAdmin.getContentRatingById(contentPayload)
      }
    },
    onApply(data) {
      if (data.onTab === 'Required') {
        this.data.required.selectedFilters = data.filters ? data.filters : []
        this.data.required.sortBy = data.sortBy
        if (!data.filters.length) this.sideFilterData = false
        const payload = {
          sortBy: 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT',
          filters: this.groupByFilter(data.filters)
        }
        this.getRequiredLearningItems(payload)
      } else if (data.onTab === 'Recommended') {
        this.data.learning.selectedFilters = data.filters ? data.filters : []
        this.data.learning.sortBy = data.sortBy
        if (!data.filters.length) this.sideFilterData = false
        const payload = {
          sortBy: data.sortBy.value,
          filters: this.groupByFilter(data.filters)
        }
        this.getMyLearningPlanItems(payload)
      } else {
        this.data.external.selectedFilters = data.filters ? data.filters : []
        this.data.external.sortBy = 'LPI_COMPLETED_AT'
        if (!data.filters.length) this.sideFilterData = false
        const payload = {
          sortBy: 'LPI_COMPLETED_AT',
          filters: this.groupByFilter(data.filters)
        }
        this.getExternalContentItems(payload)
      }
      this.GAEventTrackApplyFilter(data)
    },
    onLearningPageClick(data) {
      this.$refs.top.scrollIntoView()
      this.data.learning.selectedFilters = data.filters ? data.filters : []
      this.data.learning.sortBy = data.sortBy
      this.data.learning.pageCurrent = data.page
      const payload = {
        sortBy: data.sortBy.value,
        filters: this.groupByFilter(data.filters),
        pageNumber: data.page
      }
      this.getMyLearningPlanItems(payload)
    },
    onExternalPageClick(data) {
      this.$refs.top.scrollIntoView()
      this.data.external.selectedFilters = data.filters ? data.filters : []
      this.data.external.sortBy = 'LPI_COMPLETED_AT'
      this.data.external.pageCurrent = data.page
      const payload = {
        sortBy: 'LPI_COMPLETED_AT',
        filters: this.groupByFilter(data.filters),
        pageNumber: data.page
      }
      this.getExternalContentItems(payload)
    },
    onRequiredPageClick(data) {
      this.$refs.top.scrollIntoView()
      this.data.required.selectedFilters = data.filters ? data.filters : []
      this.data.required.sortBy = 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT'
      this.data.required.pageCurrent = data.page
      const payload = {
        sortBy: 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT',
        filters: this.groupByFilter(data.filters),
        pageNumber: data.page
      }
      this.getRequiredLearningItems(payload)
    },
    onOpenFilters() {
      this.$refs.tab.scrollIntoView()
    },
    onSideFilterData(data) {
      if (data) {
        this.sideFilterData = true
      } else if (!data) {
        this.sideFilterData = false
      }
    },
    groupByFilter(selectedFilters) {
      return selectedFilters.reduce((returnFilter, selectedFilter) => {
        let key = selectedFilter['type']
        if (!returnFilter[key]) {
          returnFilter[key] = []
        }
        if (key === 'Duration' || key === 'Credit hours') {
          returnFilter[key].push({ minTime: selectedFilter.minTime, maxTime: selectedFilter.maxTime })
        } else if (key === 'Status') {
          returnFilter[key].push({ minProgress: selectedFilter.minProgress, maxProgress: selectedFilter.maxProgress })
        } else if (key === 'Level') {
          returnFilter[key].push(selectedFilter.name.toUpperCase())
        } else if (key === 'Accreditation' || key === 'Credit type' || key === 'Function') {
          returnFilter[key].push(selectedFilter.name)
        } else if (key === 'Tracks') {
          returnFilter[key].push(selectedFilter.guid)
        } else {
          returnFilter[key].push(selectedFilter.id)
        }
        return returnFilter
      }, {})
    },
    setDuration(selectedDuration) {
      return [
        { minTime: 0, maxTime: 15, filterBy: selectedDuration.some((duration) => duration.minTime === 0 && duration.maxTime === 15) },
        { minTime: 15, maxTime: 60, filterBy: selectedDuration.some((duration) => duration.minTime === 15 && duration.maxTime === 60) },
        { minTime: 60, maxTime: 180, filterBy: selectedDuration.some((duration) => duration.minTime === 60 && duration.maxTime === 180) },
        { minTime: 180, maxTime: 300, filterBy: selectedDuration.some((duration) => duration.minTime === 180 && duration.maxTime === 300) },
        { minTime: 300, maxTime: 600, filterBy: selectedDuration.some((duration) => duration.minTime === 300 && duration.maxTime === 600) },
        { minTime: 600, maxTime: 99999, filterBy: selectedDuration.some((duration) => duration.minTime === 600 && duration.maxTime === 99999) }
      ]
    },
    setCreditHours(selectedCreditHour) {
      return [
        { minTime: 0, maxTime: 0.5, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 0 && creditHour.maxTime === 0.5) },
        { minTime: 0.5, maxTime: 1, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 0.5 && creditHour.maxTime === 1) },
        { minTime: 1, maxTime: 2, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 1 && creditHour.maxTime === 2) },
        { minTime: 2, maxTime: 5, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 2 && creditHour.maxTime === 5) },
        { minTime: 5, maxTime: 10, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 5 && creditHour.maxTime === 10) },
        { minTime: 10, maxTime: 20, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 10 && creditHour.maxTime === 20) },
        { minTime: 20, maxTime: 40, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 20 && creditHour.maxTime === 40) },
        { minTime: 40, maxTime: 9999, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 40 && creditHour.maxTime === 9999) }
      ]
    },
    async GAEventTrackTabSelect(index) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const selectedTab = index == 0 ? 'Required tab' : index == 1 ? 'Recommended tab' : 'Added by you tab'
      const category = index == 0 ? 'Required tab' : index == 1 ? 'Recommended tab' : 'Added by you tab'
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'selected-tab': selectedTab,
        device: this.$isTablet ? 'Tablet' : this.$platform,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'MyLearning Tab Selection',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrackApplyFilter(filter) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const category = this.selectedTabIndex == 0 ? 'Required tab' : this.selectedTabIndex == 1 ? 'Recommended tab' : 'Added by you tab'
      await filter.filters.map(async (f) => {
        const item = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          'filter-type': f.type,
          'filter-option': f.name,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'MyLearning Filter',
          category: category,
          value: JSON.stringify(item),
          csvalue: item
        }
        await this.GAEventTrack(payload)
      })
      const payload = {
        action: 'MyLearning Sort',
        category: category,
        value: {}.hasOwnProperty.call(filter, 'sortBy') && filter.sortBy && {}.hasOwnProperty.call(filter.sortBy, 'name') ? filter.sortBy.name : 'LPI_COMPLETED_AT'
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrackOpenAddExternal() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const category = 'Add external learning'
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'Add external learning button clicked',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    },
    async openExternalSidebar() {
      this.externalSidebarActive = true
      this.$eventBus.$emit('on-open-external-sidebar', this.externalSidebarActive)
      await this.GAEventTrackOpenAddExternal()
    },
    async toggleExternalSidebar() {
      this.externalSidebarActive = !this.externalSidebarActive
      if (!this.externalSidebarActive) {
        this.dataReady = false
        const externalPayload = {
          sortBy: 'LPI_COMPLETED_AT',
          filters: this.groupByFilter(this.data.external.selectedFilters)
        }
        await this.getExternalContentItems(externalPayload)
        this.tabs[2].count = this.data.external.totalItems
        this.updateTabIndex(2)
        this.dataReady = true
      }
    },
    async onDeleteExternalItem() {
      this.dataReady = false
      const externalPayload = {
        sortBy: 'LPI_COMPLETED_AT',
        filters: this.groupByFilter(this.data.external.selectedFilters)
      }
      await this.getExternalContentItems(externalPayload)
      this.tabs[2].count = this.data.external.totalItems
      this.updateTabIndex(2)
      this.dataReady = true
    }
  }
}
</script>

<style lang="scss">
@media screen and (min-width: 1366px) {
  .mylearning-panel-desktop {
    margin: 1.5rem 4%;
    padding: 1rem 4%;
  }
}

@media screen and (max-width: 1366px) {
  .mylearning-panel-desktop {
    margin: 1.5rem 4%;
    padding: 1rem 4%;
  }
}

@media screen and (max-width: 1024px) {
  .mylearning-panel-desktop {
    margin: 1.5rem 4%;
    padding: 1rem 7%;
  }
}

@media screen and (max-width: 768px) {
  .mylearning-panel-desktop {
    margin: 1.5rem 2%;
    padding: 1rem 1%;
  }
}

.mylearning-panel-desktop {
  margin: 0px 5% 1.5rem;
  padding: 0rem 7%;
}
.mylearning-panel-mobile {
  margin: 0px 2% 0.5rem;
  padding: 1% 1%;
}
.mylearning-panel-desktop,
.mylearning-panel-mobile {
  background: var(--ion-color-white);
  text-align: left;
  border-radius: 1rem;
}
.non-white-background {
  padding-bottom: 0.5rem;
  background: var(--ion-color-dark-grey);
}

.banner {
  position: relative;
  padding-top: 2rem;
  left: -16%;
  .title {
    font-size: 48px;
    width: 520px;
    text-align: left;
    font-family: 'Futura PT Demi';
    color: #000000;
    padding-bottom: 10px;
  }
  .content {
    font: normal normal normal 16px/28px Arial;
    width: 425px;
    text-align: left;
    color: #5c5c5c;
  }
}

@media screen and (max-width: 375px) {
  .banner_image {
    width: 100%;
    margin-top: -12%;
    position: relative;
  }
  .banner {
    left: 5%;
    .title {
      font-size: 1.5rem;
      width: 332px;
    }
    .content {
      font: normal normal normal 12px/16px Arial;
      width: 289px;
    }
  }
}

@media screen and (min-width: 375px) {
  .banner_image {
    width: 100%;
    margin-top: -12%;
    position: relative;
  }
  .banner {
    left: 5%;

    .title {
      font-size: 1.5rem;
      width: 332px;
    }
    .content {
      font: normal normal normal 12px/16px Arial;
      width: 289px;
    }
  }
}

@media screen and (min-width: 768px) {
  .banner_image {
    width: 60%;
    margin-top: -12%;
    position: relative;
    left: 10%;
  }
  .banner {
    left: 8%;

    .title {
      font-size: 2rem;
      width: 350px;
    }
    .content {
      font: normal normal normal 16px/28px Arial;
      width: 270px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .banner_image {
    margin-top: -12%;
  }
  .banner {
    .title {
      font-size: 48px;
      width: 470px;
    }
    .content {
      font: normal normal normal 16px/28px Arial;
      width: 389px;
    }
  }
}
@media screen and (min-width: 1366px) {
  .banner_image {
    position: relative;
    margin-top: -12%;
    left: 15%;
  }
  .banner {
    left: 10%;
    .title {
      font-size: 48px;
      width: 520px;
    }
    .content {
      font: normal normal normal 16px/28px Arial;
      width: 425px;
    }
  }
}
</style>
