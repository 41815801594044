var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "ion-row",
          { staticClass: "ion-align-items-center" },
          [
            _c("ion-col", { attrs: { size: "10" } }),
            _c("ion-col", [
              _c(
                "button",
                {
                  staticClass: "slider-close",
                  attrs: { "aria-label": "close side menu" },
                  on: { click: _vm.closeTrackBar }
                },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": "close-icon",
                      "class-name": "close-button-icon"
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "no-tracks-section" },
      [
        _c("ion-row", { staticClass: "track-header" }, [
          _vm._v(" You haven’t created a track yet ")
        ]),
        _c(
          "ion-row",
          [
            _c("ion-col", { staticStyle: { "text-align": "center" } }, [
              _c("img", {
                staticClass: "img-style",
                attrs: { src: require("@/assets/images/illustration.png") }
              })
            ])
          ],
          1
        ),
        _c(
          "ion-row",
          [
            _c("ion-col", [
              _c("h6", { staticClass: "get-started" }, [
                _vm._v("Get started by clicking below")
              ])
            ])
          ],
          1
        ),
        _c(
          "ion-row",
          [
            _c("ion-col", [
              _c(
                "p",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "ion-button",
                    {
                      staticClass: "ion-button-primary-dark track-btn",
                      attrs: { shape: "round", color: "primary" },
                      on: { click: _vm.createTrack }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icn-add-bg" },
                        [
                          _c("svg-icon", {
                            staticClass: "g-icon--small",
                            attrs: { "icon-class": "icon-add" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" Create a track ")
                    ]
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }