<template>
  <div style="margin-top: 0.5rem" :class="showError ? 'error-input-drop v_select_dropdown' : 'v_select_dropdown'">
    <div class="v_select_wrapper">
      <div class="v_select_toggler" @click="addClickForSelectToggler($event)" tabindex="0" @keyup.enter="addClickForSelectToggler($event)">
        <div :class="hasValue ? 'has-value v_select_value' : 'v_select_value'">{{ selectedValue }}</div>
        <div class="v_select_arrow"></div>
      </div>
      <div class="v_select_dropdown_container">
        <div class="v_select_dropdown">
          <ul class="no-bullet">
            <li v-for="(type, index) in inventoryTypeList" :key="index" class="value-list" @click="selectOption($event)">
              <a :class="selectedValue == type.name ? ' v_select_option selected-box' : 'v_select_option'" :data-select-option-value="type.name" href="#">{{ type.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSelectDropDown',
  props: {
    list: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    listVal: {
      type: String,
      required: true,
      default: 'Select'
    }
  },
  data() {
    return {
      inventoryTypeList: [],
      showError: false,
      selectedValue: 'Select one',
      hasValue: false
    }
  },
  mounted() {
    document.addEventListener('click', this.close)
    this.inventoryTypeList = this.list
    this.selectedValue = this.listVal.length > 0 ? this.listVal : 'Select one'
    this.hasValue = this.listVal.length > 0 ? true : false
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  deactivated() {},
  methods: {
    addClickForSelectToggler(event) {
      event.target.closest('.v_select_wrapper').classList.toggle('v_select_open')
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        const drodownSelectors = document.querySelectorAll('.v_select_wrapper')
        drodownSelectors.forEach((selector) => {
          selector.classList.remove('v_select_open')
        })
      }
    },
    selectOption(event) {
      const parentWrapper = event.target.closest('.v_select_wrapper')
      const valueContainer = parentWrapper.querySelector('.v_select_value')
      valueContainer.classList.add('has-value')
      const selectedValue = event.target.getAttribute('data-select-option-value')
      this.selectedValue = selectedValue
      valueContainer.innerHTML = selectedValue
      this.$emit('selected', selectedValue)
      this.showError = selectedValue.length === 0 ? true : false
      this.hasValue = true
      parentWrapper.classList.remove('v_select_open')
    }
  }
}
</script>

<style>
.close-modal-btn {
  margin-left: 26rem;
}
.error-input-drop {
  border: 1px solid red;
}

.v_select_dropdown {
  display: inline-block;
  width: 100%;
}

.v_select_wrapper {
  color: #333;
  display: inline-flex;
  flex-wrap: wrap;
  font-family: sans-serif;
  font-size: 14px;
  position: relative;
  text-align: left;
  width: 100%;
}

.v_select_toggler {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  padding: 7px 30px 7px 10px;
  position: relative;
  width: 100%;
}

.v_select_value:not(.has-value) {
  opacity: 0.5;
}

.v_select_value {
  font: normal normal normal 1rem/1.375rem Arial;
  height: 20px;
  line-height: 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v_select_arrow {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}

.v_select_arrow::after {
  transform: rotate(45deg);
  border: 1px solid transparent;
  border-bottom-color: #111;
  border-right-color: #111;
  content: '';
  height: 8px;
  margin-top: -6px;
  width: 8px;
}

.v_select_dropdown_container {
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  text-align: left;
  flex-wrap: wrap;
  z-index: 11;
  transition: all 0.5s ease;
  transform: translate3d(0px, 10px, 0px) scale(0, 0);
  width: 100%;
  display: none;
  opacity: 0;
}

.v_select_open .v_select_dropdown_container {
  display: inline-block;
  transform: translate3d(0px, 36px, 0px) scale(1, 1);
  opacity: 1;
}

.v_select_dropdown {
  background-color: #fff;
  width: 100%;
}

.v_select_dropdown ul {
  margin: 0;
  padding: 0;
}

.v_select_dropdown ul li {
  list-style-type: none;
}

.v_select_dropdown ul li a {
  font: normal normal normal 0.875rem/1.125rem Arial;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
}

.v_select_dropdown ul li a:hover,
.v_select_dropdown ul li a:focus {
  background: #f6f7f9;
}

.v_select_wrapper .checkbox-icon {
  display: inline-flex;
  height: 13px;
  margin-right: 10px;
  position: relative;
  width: 15px;
  top: 2px;
}

.v_select_wrapper .checkbox-icon::after {
  transition-duration: 0.2s;
  border: 2px solid #888;
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
}

.v_select_wrapper.multiple .v_select_option.selected .checkbox-icon::after {
  transform: rotate(45deg) translate(1px, -4px);
  border-color: #512da8;
  border-left-color: transparent;
  border-top-color: transparent;
  width: 50%;
}

.selected-box {
  background: #e9ebf1;
}
</style>