var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "addskills-modal__backdrop", on: { click: _vm.onCancel } },
      [
        !_vm.dataReady
          ? _c("ion-spinner", {
              staticStyle: { top: "25%", left: "45%" },
              attrs: { color: "#1f3370" }
            })
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "addskills-modal" },
      [
        !_vm.dataReady
          ? _c("ion-spinner", {
              staticStyle: { top: "25%", left: "45%" },
              attrs: { color: "#1f3370" }
            })
          : _vm._e(),
        _vm.dataReady && _vm.showAddSkills
          ? _c(
              "ion-grid",
              { staticClass: "ion-no-padding" },
              [
                _c(
                  "ion-row",
                  { staticClass: "ion-padding" },
                  [
                    _c(
                      "ion-col",
                      [
                        _c(
                          "span",
                          {
                            staticClass: "addskills-modal__header",
                            staticStyle: { float: "left" }
                          },
                          [_vm._v("Skills to explore")]
                        ),
                        _c("svg-icon", {
                          staticClass: "g-icon--small close g-clickable",
                          staticStyle: { float: "right" },
                          attrs: { "icon-class": "filter-close" },
                          on: { click: _vm.onCancel }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("ion-searchbar", {
                  ref: "searchbar",
                  staticStyle: {
                    "text-align": "left",
                    "--border-radius": "50px"
                  },
                  attrs: { "search-icon": "search-outline", value: _vm.query },
                  on: {
                    keypress: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleSearchInput($event)
                    },
                    ionClear: _vm.handleClearInput
                  }
                }),
                !_vm.showSearchResults
                  ? _c(
                      "ion-row",
                      { staticClass: "scrollbar" },
                      [
                        _c(
                          "ion-col",
                          [
                            _c(
                              "ion-row",
                              {
                                staticClass:
                                  "ion-align-items-center ion-padding-horizontal md hydrated",
                                on: { click: _vm.toggleSelectedSkills }
                              },
                              [
                                _c("ion-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ion-no-margin addskills-modal__subheader g-clickable"
                                    },
                                    [
                                      _vm._v(" Selected skills "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "addskills-modal__footer-label"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getSelectedSkillsLength()
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "ion-col",
                                  { staticStyle: { display: "contents" } },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "icon-expand",
                                      style: _vm.showSelectedSkills
                                        ? "transform: rotate(180deg);"
                                        : "",
                                      attrs: {
                                        "icon-class": "down-arrow-expand"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "ion-row",
                              [
                                _vm.showSelectedSkills
                                  ? _c(
                                      "ion-col",
                                      { staticClass: "ion-no-padding" },
                                      [
                                        _vm.selectedSkills.length == 0
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "addskills-modal__subheader ion-padding-horizontal"
                                              },
                                              [
                                                _vm._v(
                                                  "You have no selected skills"
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.selectedSkills.length == 0
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "addskills-modal__no-results ion-padding-horizontal"
                                              },
                                              [
                                                _vm._v(
                                                  " Add skills to browse courses, credentials, videos and more based on your interests. "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._l(_vm.selectedSkills, function(
                                          skill
                                        ) {
                                          return _c(
                                            "ion-row",
                                            {
                                              key: skill.name + "-selected",
                                              staticClass:
                                                "ion-align-items-center ion-no-padding addskills-modal__checkbox-row",
                                              class: skill.selected
                                                ? "selected md hydrated"
                                                : "md hydrated"
                                            },
                                            [
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass: "ion-text-start",
                                                  attrs: { size: "10" }
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "addskills-modal__name"
                                                    },
                                                    [_vm._v(_vm._s(skill.name))]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass:
                                                    "ion-checkbox-col"
                                                },
                                                [
                                                  _c("ion-checkbox", {
                                                    staticClass:
                                                      "addskills-modal__checkbox",
                                                    attrs: {
                                                      checked: skill.selected,
                                                      value: skill.name,
                                                      color: "tertiary",
                                                      mode: "md"
                                                    },
                                                    on: {
                                                      ionChange: function(
                                                        $event
                                                      ) {
                                                        return _vm.onSelectedSkillChange(
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "ion-row",
                              [
                                _c(
                                  "ion-col",
                                  { staticClass: "ion-no-padding" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "addskills-modal__subheader ion-padding-horizontal"
                                      },
                                      [_vm._v("Suggested for you")]
                                    ),
                                    _vm.recommendedSkills.length == 0
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "addskills-modal__no-results ion-padding-horizontal"
                                          },
                                          [
                                            _vm._v(
                                              " There are no recommend skills at this moment. Please try again later. "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._l(_vm.recommendedSkills, function(
                                      skill
                                    ) {
                                      return _c(
                                        "ion-row",
                                        {
                                          key: skill.name + "-recommend",
                                          staticClass:
                                            "ion-align-items-center ion-no-padding addskills-modal__checkbox-row",
                                          class: skill.selected
                                            ? "selected md hydrated"
                                            : "md hydrated"
                                        },
                                        [
                                          _c(
                                            "ion-col",
                                            {
                                              staticClass: "ion-text-start",
                                              attrs: { size: "10" }
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "addskills-modal__name"
                                                },
                                                [_vm._v(_vm._s(skill.name))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "ion-col",
                                            { staticClass: "ion-checkbox-col" },
                                            [
                                              _c("ion-checkbox", {
                                                staticClass:
                                                  "addskills-modal__checkbox",
                                                attrs: {
                                                  checked: skill.selected,
                                                  value: skill.name,
                                                  color: "tertiary",
                                                  mode: "md"
                                                },
                                                on: {
                                                  ionChange: function($event) {
                                                    return _vm.onRecommendedSkillChange(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.showSearchResults
          ? _c(
              "ion-grid",
              { staticClass: "ion-no-padding" },
              [
                _c(
                  "ion-row",
                  {
                    staticClass: "search-skill-scroller",
                    staticStyle: { "overflow-y": "scroll" }
                  },
                  [
                    _c(
                      "ion-col",
                      { staticClass: "ion-no-padding" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "addskills-modal__subheader ion-padding-horizontal"
                          },
                          [
                            _vm._v(
                              "Found " +
                                _vm._s(_vm.searchedSkills.length) +
                                ' skills matching "' +
                                _vm._s(_vm.query) +
                                '"'
                            )
                          ]
                        ),
                        _vm.searchedSkills.length == 0
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "addskills-modal__no-results ion-padding-horizontal"
                              },
                              [
                                _vm._v(
                                  " For better results, check your query for spelling errors, try a broader term or other synonymous terms. "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._l(_vm.searchedSkills, function(skill) {
                          return _c(
                            "ion-row",
                            {
                              key: skill.name + "-searched",
                              staticClass:
                                "ion-align-items-center ion-no-padding addskills-modal__checkbox-row",
                              class: skill.selected
                                ? "selected md hydrated"
                                : "md hydrated"
                            },
                            [
                              _c(
                                "ion-col",
                                {
                                  staticClass: "ion-text-start",
                                  attrs: { size: "10" }
                                },
                                [
                                  _c("p", {
                                    staticClass: "addskills-modal__name",
                                    domProps: {
                                      innerHTML: _vm._s(skill.displayName)
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "ion-col",
                                { staticClass: "ion-checkbox-col" },
                                [
                                  _c("ion-checkbox", {
                                    staticClass: "addskills-modal__checkbox",
                                    attrs: {
                                      checked: skill.selected,
                                      value: skill.name,
                                      color: "tertiary",
                                      mode: "md"
                                    },
                                    on: {
                                      ionChange: function($event) {
                                        return _vm.onSearchedSkillChange($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm.showSearchResults
                  ? _c(
                      "ion-row",
                      {
                        staticClass:
                          "ion-align-items-center ion-padding-horizontal addskills-modal__footer md hydrated"
                      },
                      [
                        _c("ion-col", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "ion-no-margin addskills-modal__footer-header g-clickable",
                              on: { click: _vm.onClickSelectedSkills }
                            },
                            [
                              _vm._v(" See all selected skills "),
                              _c(
                                "div",
                                {
                                  staticClass: "addskills-modal__footer-label"
                                },
                                [_vm._v(_vm._s(_vm.getSelectedSkillsLength()))]
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }