import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import localforage from 'localforage'
import './icons'
import './registerServiceWorker'

import Ionic from '@modus/ionic-vue'
import '@ionic/core/css/ionic.bundle.css'

import './styles/global.scss'

import AnalyticsPlugin from './plugins/analytics.js'
import LearningAnalyticsPlugin from './plugins/learningAnalytics.js'
import VersionPlugin from './plugins/version.js'
import UnleashPlugin from './plugins/unleash.js'
import { getFeatureFlagStrategy } from './utils/flag.js'
import Timer from './plugins/timer.js'

import Tracker from './directives/Tracker.js'
import ExternalLinks from './directives/ExternalLinks.js'
import VueSanitize from 'vue-sanitize'
import { addIcons } from 'ionicons'
import * as allIcons from 'ionicons/icons'

import LearnAdmin from './api/learnAdmin'
import Paginate from 'vuejs-paginate'
import VueGtag from 'vue-gtag'
import Toasted from 'vue-toasted'

const learnAdmin = new LearnAdmin()

addIcons(allIcons)

Vue.use(Ionic)
Vue.use(Timer.userActiveTime())

const currentIcons = Object.keys(allIcons).map(i => {
  const key = i.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
  if (typeof allIcons[i] === 'string') {
    return {
      [key]: allIcons[i]
    }
  }
  return {
    ['ios-' + key]: allIcons[i].ios,
    ['md-' + key]: allIcons[i].md
  }
})

const iconsObject = Object.assign({}, ...currentIcons)
addIcons(iconsObject)

Vue.config.ignoredElements = [/^ion-/]

Vue.directive('track', Tracker)
Vue.directive('links-in-new-tab', ExternalLinks)

Vue.use(AnalyticsPlugin)
Vue.use(LearningAnalyticsPlugin)
Vue.use(VersionPlugin, { store: store })
Vue.use(UnleashPlugin, {
  host: process.env.VUE_APP_ULFF_HOST,
  store,
  strategyProviders: getFeatureFlagStrategy()
})
Vue.use(Toasted)
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_TRACKING_ID,
    params: { send_page_view: false }
  },
  bootstrap: false
}, router)

Vue.component('paginate', Paginate)

let sanitizeOptions = VueSanitize.defaults
sanitizeOptions.allowedTags.push('img')
sanitizeOptions.allowedAttributes = {
  p: ['style'],
  div: ['style'],
  span: ['style'],
  img: ['style', 'src'],
  a: ['href', 'target']
}
Vue.use(VueSanitize, sanitizeOptions)

Vue.config.productionTip = false

// isTablet
// webOS/ipad/
const isTablet = () => {
  const toMatch = [/webOS/i, /iPad/i]
  const isUserAgentTablet = toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
  if (isUserAgentTablet) {
    return isUserAgentTablet
  }
  else {
    // Apple considers iPad as a desktop mode
    // eslint-disable-next-line
    return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android'
  }
}

const isMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]
  const isUserAgentMobile = toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
  if (isUserAgentMobile) {
    return isUserAgentMobile
  }
  else {
    // Apple considers iPad as a desktop mode
    // eslint-disable-next-line
    return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android'
  }
}
const isIos = () => {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i]
  const isUserAgentIos = toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
  if (isUserAgentIos) {
    return isUserAgentIos
  }
  else {
    // Apple considers iPad as a desktop mode
    // eslint-disable-next-line
    return Capacitor.getPlatform() === 'ios'
  }
}

Vue.prototype.$platform = isMobile() ? 'Mobile' : 'Desktop'
Vue.prototype.$device = isIos() ? 'ios' : 'android'
Vue.prototype.$isTablet = isTablet()
Vue.prototype.$pageData = []
Vue.prototype.$learnAdmin = learnAdmin
Vue.prototype.$socialDisabled = true

Vue.prototype.$eventBus = new Vue();
async function initializeApp() {
  const isLoggedIn = await localforage.getItem('my_access_token') && await localforage.getItem('my_user_onboarded') ? true : false
  localStorage.setItem('isLoggedIn', isLoggedIn)
  return
}

initializeApp().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

