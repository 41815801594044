<template>
  <div class="edge-content">
    <ion-card :class="isDesktopPlatform ? 'content-padding-desktop' : 'content-padding-mobile'">
      <ContentScrollToTop />

      <video id="video-content" v-if="media.url && media.type === 'video'" :poster="content.imageUri" controls :class="isDesktopPlatform ? 'media-desktop' : ''">
        <source :src="media.url + '#t=0.2'" :type="media.videoType" />
      </video>

      <audio style="width: -webkit-fill-available" id="audio-content" v-if="media.type === 'audio' || media.type === 'podcast'" controls :src="media.url">
        Your browser does not support the
        <code>audio</code> element.
      </audio>

      <ContentFontSizeController v-if="content.format.name === 'article'" className="g-type-body-1" />
      <ion-card-content v-if="content.text" class="g-type-body-1 ion-text-start text" v-html="$sanitize(content.text)" v-links-in-new-tab></ion-card-content>
      <ContentComplete v-if="!showRating" label="3" @on-complete="completeContent" />
    </ion-card>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import ContentFontSizeController from '@/views/content/components/ContentFontSizeController'
import ContentScrollToTop from '@/views/content/components/ContentScrollToTop'
import ContentComplete from '@/components/molecules/ContentComplete'
import EventBus from '@/plugins/eventbus'
import util from '@/utils/helper'

export default {
  name: 'ContentCardNew',
  components: {
    ContentComplete,
    ContentFontSizeController,
    ContentScrollToTop
  },
  props: {
    media: {
      type: Object,
      default: () => {
        return {}
      }
    },
    content: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    progressTracking: {
      type: Boolean,
      required: false,
      default: true
    },
    skills: {
      type: Array,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showRating: false,
      isDesktopPlatform: false,
      isMoreSkill: false,
      learningRecord: {},
      authToken: '',
      userId: '',
      userEmail: '',
      progressThresholds: {
        one: false,
        fifty: false,
        ninetynine: false,
        hundred: false
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  created() {},
  async mounted() {
    await this.setupContent()
  },
  async activated() {
    await this.setupContent()
  },
  beforeDestroy() {
    EventBus.$off('scroll')
  },
  filters: {
    formatDate(d) {
      if (!d) return ''
      const date = util.formatDate(d)
      return date.toUpperCase()
    },
    formatProviderName(s) {
      return s.toUpperCase().replace('PWC', 'PwC')
    }
  },
  methods: {
    async setupContent() {
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.authToken = await localforage.getItem('my_access_token')
      this.userId = await localforage.getItem('my_user_id')
      this.userEmail = await localforage.getItem('my_username')
      this.showRating = this.content.meta && this.content.meta.isComplete
      if (this.progressTracking) {
        await this.setupClickStreamProgressTracker()
        await this.processProgress(1)
      }
    },
    async setupClickStreamProgressTracker() {
      this.learningRecord = {
        verb: {
          id: window.location.href,
          display: {
            'en-US': `opened-${this.media.type.toLowerCase()}`
          }
        },
        object: {
          id: window.location.href,
          definition: {
            name: {
              'en-US': this.content.title
            }
          }
        },
        result: {
          completion: false,
          success: false,
          score: {
            scaled: 0
          }
        }
      }
      learningAnalytics.setLearningRecordAndSend(this.learningRecord)
      await this.setClickStreamProgressTracker()
    },
    async setClickStreamProgressTracker() {
      EventBus.$off('scroll')

      if (this.media.type === 'article') {
        await this.processArticleScrollData()
      } else if (this.media.type === 'video') {
        await this.processAudioVideoData('video-content')
      } else if (this.media.type === 'audio' || this.media.type === 'podcast') {
        await this.processAudioVideoData('audio-content')
      }
    },
    async processArticleScrollData() {
      EventBus.$on('scroll', async (scrollData) => {
        let scrollPercent = Math.round((scrollData.scrollTop / (scrollData.scrollHeight - scrollData.clientHeight)) * 100)
        scrollPercent = scrollPercent > 99 ? 99 : scrollPercent
        await this.processProgress(scrollPercent)
      })
    },
    async processAudioVideoData(AudioVideoId) {
      var audioVideoElement = document.getElementById(AudioVideoId)
      audioVideoElement.ontimeupdate = async () => {
        let progressPercent = (audioVideoElement.currentTime / audioVideoElement.duration) * 100
        progressPercent = progressPercent > 99 ? 100 : progressPercent
        await this.processProgress(progressPercent)
      }
    },
    async processProgress(progressPercent) {
      const progress = progressPercent < 50 ? 1 : progressPercent >= 50 && progressPercent < 99 ? 50 : progressPercent >= 99 && progressPercent < 100 ? 99 : 100
      if (progress == 1 && !this.progressThresholds.one) {
        this.progressThresholds.one = true
        this.updateProgressProcess(progress)
      } else if (progress == 50 && !this.progressThresholds.fifty) {
        this.progressThresholds.fifty = true
        this.updateProgressProcess(progress)
      } else if (progress === 99 && !this.progressThresholds.ninetynine) {
        this.progressThresholds.ninetynine = true
        this.updateProgressProcess(progress)
      } else if (progress === 100 && !this.progressThresholds.hundred) {
        this.progressThresholds.hundred = true
        this.updateProgressProcess(progress)
      }
    },
    async updateProgressProcess(progress) {
      const action = progress === 100 ? 'complete' : 'scroll'
      const isCompleted = progress === 100
      analytics.setVarAndTrack({
        c: 'nav',
        a: action,
        l: { page: 'learnContent', title: this.content.title, provider: this.content.publication, ci: this.$route.params.id, pc: progress },
        u: this.userId,
        un: this.userEmail
      })
      await this.$learnAdmin.updateContentProgressById({ token: this.authToken, id: this.$route.params.id, body: { isCompleted: isCompleted, progress: progress } })
    },
    async completeContent() {
      this.showRating = true
      const authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      await this.$learnAdmin.completeContentById({ token: authToken, id: this.content.id })
      this.learningRecord.verb = {
        id: window.location.href,
        display: {
          'en-US': `completed-${this.media.type.toLowerCase()}`
        }
      }
      this.learningRecord.result = {
        completion: true,
        success: true,
        score: {
          scaled: 1
        }
      }
      learningAnalytics.setLearningRecordAndSend(this.learningRecord)
      await this.GAEventTrack({ cid: this.content.id })
      await this.processProgress(100)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event('Content', {
            event_category: 'Complete',
            event_label: payload.cid,
            value: payload.cid
          })
        })
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" src="@/views/content/components/content.scss"></style>
<style lang="scss" scoped>
@include block('edge-content') {
  background-color: #fff;
  border-radius: 20px;

  ion-card {
    margin: unset;
    border-radius: 20px;
    box-shadow: none;
    ion-card-content {
      padding: unset;
    }
  }

  @include element('audio-img') {
    height: 350px !important;
    width: 100% !important;
    object-fit: cover !important;
  }

  @include element('audio-card') {
    padding: 0px !important;
  }

  .category-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    a {
      text-decoration: none !important;
      color: #1f3370 !important;
      font: normal normal normal 16px/22px Arial;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }

  a {
    text-decoration: inherit;
    color: var(--ion-color-secondary) !important;
  }

  video,
  img {
    display: flex;
    max-height: 400px;
    min-height: 185px;
    width: 100%;
    height: 100%;
    max-width: 842px;
    min-width: 295px;
    object-fit: cover;
  }

  ion-card-content {
    &.text {
      ::v-deep {
        p {
          font-size: 18px;
          line-height: 20px;
          margin: 15px 0;
        }

        a {
          color: var(--ion-color-secondary);
          font-size: 14px;
        }
      }
    }
  }

  ion-card-header {
    padding: 0px 16px 4px 16px;
  }

  ion-card-title {
    margin-top: 4px;
  }

  @include element('title') {
    .icon {
      height: 36px;
      width: 32px;
      margin: 3px 17px 0 0;
    }

    .title {
      font-weight: normal;
      font-size: 1.3rem;
      padding: 0.5rem 0rem;
    }
  }

  @include element('header') {
    display: flex;
  }

  @include element('body') {
    padding-top: 2px;
    padding-bottom: 6px;
  }

  .text {
    color: rgb(63, 63, 63);
  }
}

.android-mode {
  font-size: 1rem;
}

.content-padding-desktop {
  padding: 3rem 100px;
}

.content-padding-mobile {
  padding: 3rem 1.5rem;
}

@media screen and (max-width: 1366px) {
  .content-padding-desktop {
    padding: 50px;
  }
}

@media screen and (max-width: 484px) {
  .content-padding-desktop {
    padding: 20px 16px 18px;
  }
}
</style>
