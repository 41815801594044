var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "media-modal__backdrop" }),
    _c("div", { staticClass: "media-modal" }, [
      _c(
        "div",
        [
          _vm.item.type === "image"
            ? _c("img", {
                staticClass: "media",
                attrs: { src: _vm.item.src, alt: "" }
              })
            : _vm._e(),
          _vm.item.type === "video"
            ? _c(
                "video",
                {
                  staticClass: "media",
                  attrs: {
                    controls: "",
                    autoplay: _vm.videoPreview,
                    poster: "",
                    preload: "auto"
                  }
                },
                [
                  _c("source", {
                    attrs: { src: _vm.item.src, type: "video/mp4" }
                  })
                ]
              )
            : _vm._e(),
          _c(
            "ion-grid",
            { staticClass: "ion-no-padding img-caption" },
            [
              _c(
                "ion-row",
                { staticClass: "ion-no-padding" },
                [
                  _c(
                    "ion-col",
                    { staticClass: "ion-no-padding", attrs: { size: "8" } },
                    [_vm._v(_vm._s(_vm.item.caption))]
                  ),
                  _c(
                    "ion-col",
                    {
                      staticClass: "ion-no-padding svg-sty",
                      attrs: { size: "4" }
                    },
                    [
                      _vm.media.length > 0 && _vm.mediaCurrent > 1
                        ? _c("svg-icon", {
                            staticClass: "g-icon--xsmall g-clickable",
                            attrs: { "icon-class": "icon-arrow-left-white" },
                            on: {
                              click: function($event) {
                                return _vm.handleItemClick(-1)
                              }
                            }
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "pagination" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.mediaCurrent) +
                            " of " +
                            _vm._s(_vm.media.length) +
                            " "
                        )
                      ]),
                      _vm.mediaCurrent < _vm.media.length
                        ? _c("svg-icon", {
                            staticClass: "g-icon--xsmall g-clickable",
                            attrs: { "icon-class": "icon-arrow-right-white" },
                            on: {
                              click: function($event) {
                                return _vm.handleItemClick(1)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "close-icon" },
        [
          _c("svg-icon", {
            staticClass: "g-icon--msmall g-clickable",
            staticStyle: { transform: "rotate(45deg)" },
            attrs: { "icon-class": "filter-close-white" },
            on: { click: _vm.onCancel }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }