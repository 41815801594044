<template>
  <div ref="carousel" @scroll="scrollCarousel" class="edge-carousel__mobile">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CarouselMobile',
  props: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    // Logic for when end of scroll is reached
    scrollCarousel() {
      const carousel = this.$refs.carousel
      if (carousel.offsetWidth + carousel.scrollLeft == carousel.scrollWidth) {
        this.$emit('endReached', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('edge-carousel') {
  @include element('mobile') {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-right: 30px;
    padding-bottom: 3rem;
  }
}

</style>
