<template>
  <div ref="top" :class="isDesktopPlatform ? '' : 'non-white-background'">
    <div v-if="showContent">
      <ion-grid v-if="isContentAvailable" class="ion-no-padding ion-padding-bottom" :class="isDesktopPlatform ? 'desktop-content-container' : 'mobile-content-container'">
        <ion-row class="ion-no-padding">
          <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
            <div>
              <button class="accessible-button back" @click="$router.go(-1)" :class="$platform === 'Desktop' ? 'back-desktop' : 'back-mobile'">
                <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
                Back
              </button>
            </div>
            <ion-row class="content-info">
              <ion-col class="content-img-background">
                <img v-if="content.imageUri" :src="content.imageUri" />
                <img v-else :src="defaultImage" />
              </ion-col>
              <ion-col>
                <div class="meta">
                  <div class="category">
                    <div class="format">{{ content.format && content.format.name }}</div>
                    <div class="provider">{{ content.provider && content.provider.name }}</div>
                  </div>
                </div>
                <div class="title">{{ content.title }}</div>
                <div class="meta-info">
                  <div v-if="content.rating" class="content-rating" @click="onScroll">
                    <span style="margin-right: 5px; font-family: Arial">{{ content.rating }}</span>
                    <StarRating
                      v-if="isDesktopPlatform"
                      inactive-color="#d0cfce"
                      active-color="#b98032"
                      :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                      :rating="content.rating"
                      :read-only="true"
                      :increment="0.1"
                      :star-size="16"
                      :show-rating="false"
                      :round-start-rating="true"
                      class="star-rating"
                    />
                    <svg-icon v-if="!isDesktopPlatform" icon-class="star-filled-desktop" class="display-rating-mobile" />
                    <span class="rating-count">{{ content.ratingCount }} {{ content.ratingCount > 1 ? 'ratings' : 'rating' }}</span>
                  </div>
                  <div class="duration">{{ content.duration | formatDuration }}</div>
                  <div v-if="content.level" class="level">{{ content.level }}</div>
                  <div class="publish-date">{{ content.publishedAt | formatPublishedDate }}</div>
                </div>
                <div class="meta-action">
                  <div class="bookmark-container">
                    <Bookmark class="content-bookmark" :assetId="content.id" assetType="artifacts" :meta="content.meta" />
                    <span v-if="isDesktopPlatform" class="bookmark-tooltip-text">Bookmark</span>
                  </div>
                  <div class="share-container">
                    <button class="social-share" aria-labelledby="share-btn" @click="popoverOn('share')"><svg-icon icon-class="click-to-copy" class-name="g-icon--small g-clickable" /></button>
                    <SocialSharePopover
                      v-if="isShareOpen"
                      class="social-share-popover"
                      :assetId="contentId"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      fromPage="Universal card"
                      size="lg"
                      @dismiss-share-action-model="popoverOff('share')"
                    />
                    <span v-if="!isShareOpen && isDesktopPlatform" id="share-btn" class="share-tooltip-text">Share</span>
                  </div>
                  <div v-if="showAddToTrack" class="more-container">
                    <button class="more-action" aria-labelledby="more-btn" @click="popoverOn('more')"><svg-icon icon-class="more-action" class-name="g-icon--small g-clickable" /></button>
                    <MoreActionPopover
                      v-if="isMoreOpen"
                      class="more-popover"
                      :item="content"
                      :assetId="contentId"
                      :assetType="content.format.name"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      :showEditDelete="false"
                      :showAddToTrack="true"
                      size="lg"
                      fromPage="Universal card"
                      @add-to-track="popoverOff('more')"
                      @close-more="popoverOff('more')"
                    />
                    <span v-if="!isMoreOpen" id="more-btn" class="more-tooltip-text">More</span>
                  </div>
                  <div v-if="content.progress">
                    <div v-if="content.progress > 0 && content.progress < 100" class="progress">
                      <radial-progress-bar
                        :diameter="28"
                        :completed-steps="content.progress"
                        :total-steps="content.progress == '99' ? 110 : 100"
                        :startColor="`#000000`"
                        :stopColor="`#000000`"
                        :innerStrokeColor="`#e0e1e1`"
                        :strokeWidth="4"
                        :innerStrokeWidth="5"
                      >
                      </radial-progress-bar>
                      <span>{{ content.progress }}%</span>
                    </div>
                    <div v-else-if="content.completed || content.progress == 100" class="progress">
                      <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                      <span>completed</span>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <div class="content-about-container">
          <ion-row>
            <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
              <ContentCard :media="content.media" :content="content" :progress-tracking="progressTracking" :skills="skills" class="content-card" />
              <ItemRating :item="content" :scroll="scroll" :ratingDetails="ratingDetails" :getUpdatedItemData="getUpdatedContentData" @update-ratings="getRatingById()" />
              <RelatedContent v-if="relatedContent !== null & relatedContent.length > 0" :relatedContent="relatedContent" />
            </ion-col>
            <ion-col v-if="isMetaDataVisible" size-xl="3.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
              <ItemMetaData :item="content" />
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
      <ion-grid v-else :style="isDesktopPlatform ? '' : 'padding: 2rem 10px 6rem;'" class="ion-no-padding">
        <PageNotFound />
      </ion-grid>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { marked } from 'marked'
import { mapGetters, mapActions } from 'vuex'
import localforage from 'localforage'
import StarRating from 'vue-star-rating'
import Bookmark from '@/components/molecules/Bookmark'
import ContentCard from '@/views/content/ContentCard'
import ItemRating from '@/components/molecules/ItemRating'
import RelatedContent from '@/components/molecules/RelatedContent'
import ItemMetaData from '@/components/molecules/ItemMetaData'
import RadialProgressBar from 'vue-radial-progress'
import MoreActionPopover from '@/components/molecules/MoreActionPopover'
import SocialSharePopover from '@/components/molecules/SocialSharePopover'
import PageNotFound from '@/components/molecules/PageNotFound'
import util from '@/utils/helper'

export default {
  name: 'Content',
  props: {},
  components: { StarRating, Bookmark, ContentCard, ItemRating, ItemMetaData, RadialProgressBar, MoreActionPopover, SocialSharePopover, PageNotFound, RelatedContent },
  data() {
    return {
      showContent: false,
      authToken: '',
      gcsToken: '',
      content: null,
      contentId: '',
      skills: [],
      signedUriEnabled: false,
      progressTracking: true,
      isDesktopPlatform: true,
      scroll: 0,
      isMetaDataVisible: true,
      defaultImage: '',
      isShareOpen: false,
      isMoreOpen: false,
      isContentAvailable: false,
      showAddToTrack: false,
      ratingDetails: {},
      relatedContent: []
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'gcs_token', 'enabledFeatureFlags'])
  },
  async activated() {
    await localforage.removeItem('deep_link_id')
    await localforage.removeItem('deep_link_type')
    this.setNavBar({
      explore: {
        isShown: true,
        headerTitle: this.$route.params.title,
        hasTabs: false,
        hasBackButton: true
      }
    })
    this.contentId = this.$route.params.id
    this.progressTracking = {}.hasOwnProperty.call(this.$route.params, 'pgrTrk') ? this.$route.params.pgrTrk : true
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.showAddToTrack = await localforage.getItem('show-add-to-track')
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.gcsToken = this.gcs_token || (await localforage.getItem('my_gcs_token'))
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.signedUriEnabled = enabledFeatureFlags['learn_autographer-uri-enabled'] ? enabledFeatureFlags['learn_autographer-uri-enabled'] : false
    this.isMetaDataVisible = enabledFeatureFlags['learn_content-meta-data-enabled'] ? enabledFeatureFlags['learn_content-meta-data-enabled'] : false
    await this.getContentById(this.$route.params.id)
    await this.onPrepareGAPayload('Viewed')
    await this.getRatingById()
    this.showContent = true
  },
  deactivated() {
    this.$destroy()
  },
  filters: {
    formatDuration(duration) {
      return duration < 3600 ? `${Math.round(duration / 60)} min` : `${Math.round(duration / 3600)} hrs`
    },
    formatPublishedDate(d) {
      if (!d) return ''
      return util.formatDate(d)
    }
  },
  methods: {
    ...mapActions('user', ['setNavBar']),
    toCapitalize(skill) {
      return skill.length
        ? skill
            .split(' ')
            .map((s) => s[0].toUpperCase() + s.substring(1))
            .join(' ')
        : ''
    },
    async getRatingById() {
      const authToken = this.authToken || (await localforage.getItem('my_access_token'))
      this.$learnAdmin.getRatingById({ token: authToken, id: this.$route.params.id || this.contentId }).then((res) => {
        this.ratingDetails = res || {}
        this.content.rating = {}.hasOwnProperty.call(this.ratingDetails, 'averageRating') ? this.ratingDetails.averageRating : 0
        this.content.ratingCount = {}.hasOwnProperty.call(this.ratingDetails, 'totalNumberOfUsers') ? this.ratingDetails.totalNumberOfUsers : []
      })
    },
    async getContentById(id) {
      const response = await this.$learnAdmin.getContentById({ token: this.authToken, id: id })
      const relatedContent = await this.$learnAdmin.getRelatedContentById({ token: this.authToken, id: id })
      this.relatedContent = relatedContent.contents || []
      this.content = response.data
      this.isContentAvailable = {}.hasOwnProperty.call(response, 'status') && response.status != 400 && response.status != 404 && response.status != 401
      if (this.isContentAvailable) {
        this.content.imageUri =
          {}.hasOwnProperty.call(this.content.meta, 'cdnEnabled') && this.content.meta.cdnEnabled && {}.hasOwnProperty.call(this.content.image, 'signedRelativeUri')
            ? this.content.image.signedRelativeUri
            : {}.hasOwnProperty.call(this.content.image, 'uri')
            ? this.content.image.uri
            : ''
        this.content.mediaUri =
          {}.hasOwnProperty.call(this.content.meta, 'cdnEnabled') && this.content.meta.cdnEnabled && {}.hasOwnProperty.call(this.content.content, 'signedRelativeUri')
            ? this.content.content.signedRelativeUri
            : {}.hasOwnProperty.call(this.content.content, 'contentUri')
            ? this.content.content.contentUri
            : ''
        const uri = this.content.format.name.toLowerCase() === 'article' ? this.content.imageUri : this.content.mediaUri
        this.content.media = {
          type: this.content.format.name.toLowerCase(),
          url: uri ? ({}.hasOwnProperty.call(this.content.meta, 'cdnEnabled') && this.content.meta.cdnEnabled ? uri : await this.getMedia(uri)) : null,
          videoType:
            !this.content.format || this.content.format.name.toLowerCase() === 'article' ? '' : this.content.format.name.toLowerCase() === 'video' ? 'video/mp4' : 'audio/mpeg'
        }
        this.content.rating = {}.hasOwnProperty.call(this.ratingDetails, 'averageRating') ? this.ratingDetails.averageRating : 0
        this.content.ratingCount = {}.hasOwnProperty.call(this.ratingDetails, 'totalNumberOfUsers') ? this.ratingDetails.totalNumberOfUsers : []
        this.content.level = {}.hasOwnProperty.call(this.content, 'courseLevels') ? this.content.courseLevels[0] : ''
        this.content.text = this.content.format.name.toLowerCase() === 'article' ? await this.getText(this.content.content.relativeUri) : null
        this.content.tags = {}.hasOwnProperty.call(this.content, 'tags') ? this.content.tags : []
        const skills = {}.hasOwnProperty.call(this.content, 'skills') ? this.content.skills : {}.hasOwnProperty.call(this.content, 'emsiSkills') ? this.content.emsiSkills : []
        this.skills = await skills.map((skill) => this.toCapitalize(skill))
        this.defaultImage = this.getDefaultImage()
      }
    },
    popoverOn(popoverType) {
      this.isShareOpen = popoverType === 'share'
      this.isMoreOpen = popoverType === 'more'
    },
    popoverOff(popoverType) {
      this.isShareOpen = !popoverType === 'share'
      this.isMoreOpen = !popoverType === 'more'
    },
    async validateGCSToken() {
      const now = new Date().toUTCString()
      const exp = this.gcsToken && {}.hasOwnProperty.call(this.gcsToken, 'tokenValue') ? new Date(this.gcsToken.expirationTime).toUTCString() : null
      if (!exp || now > exp) {
        await this.$learnAdmin.getGCSToken({ token: this.authToken }).then((token) => {
          this.gcsToken = token
          this.$store.dispatch('user/setGCSToken', token).then(() => {})
        })
      }
    },
    async getMedia(uri) {
      if (!this.signedUriEnabled || uri.includes('amazonaws.com')) return uri
      const signedURI = await this.$learnAdmin.getSignedURI({ url: uri, token: this.authToken })
      return signedURI.url ? signedURI.url : uri
    },
    async getText(relativeUri) {
      const gcpLink = 'https://storage.googleapis.com/download/storage/v1/b/cms-transformed-bucket-dev/o'
      const mjnovsLink = 'https://mjnovs.proedge.pwc.com/dev'
      const cloudEnv = await localforage.getItem('cloudEnv')
      const uri = `${cloudEnv.cdn.contentCdn}${relativeUri}`
      const signedURI = this.signedUriEnabled ? await this.$learnAdmin.getSignedURI({ url: uri, token: this.authToken }) : ''
      const response = this.signedUriEnabled && signedURI.url ? await fetch(signedURI.url) : await fetch(uri)
      const mdData = await response.text()
      let content = marked.parse(mdData)
      if (content.includes(gcpLink)) {
        content = content.replaceAll(gcpLink, mjnovsLink)
      }
      return this.htmlContentWithImageData(content)
    },
    htmlContentWithImageData(content) {
      const srcAtr = 'src="'
      const altAtr = 'alt="'
      let found = content.indexOf('<img')
      while (found > -1) {
        const srcAtrIdx = content.indexOf(srcAtr, found + 1)
        const altAtrIdx = content.indexOf(altAtr, found + 1)
        const imagePath = content.substring(srcAtrIdx + srcAtr.length, altAtrIdx - 2)
        content = content.substring(0, found) + `<img width=100% alt="" src="${imagePath}" ` + content.substring(altAtrIdx)
        found = content.indexOf('<img', found + 1)
      }
      return this.colorAnchorTag(content)
    },
    colorAnchorTag(content) {
      const anchor = /<a /gi
      const style = '<a onclick="return false;" style="color: rgb(63, 63, 63); text-decoration: none; font-family: \'Futura PT Book\', sans-serif;"'
      content = content.replace(anchor, style)
      return this.styleAuthorTag(content)
    },
    getRouteInfo() {
      const name = 'Content'
      const params = { id: this.content.id, pgrTrk: false }
      return { name: name, params: params }
    },
    onScroll() {
      this.scroll = this.scroll + 1
    },
    async getUpdatedContentData() {
      this.showContent = false
      let content = await this.$learnAdmin.getContentById({ token: this.authToken, id: this.contentId })
      content = content.data
      this.content = { ...this.content, ...content }
      this.content.ratingCount = 0
      this.showContent = true
    },
    getDefaultImage() {
      if (['article', 'video', 'audio', 'podcast'].includes(this.content.format.name.toLowerCase())) {
        return require(`@/assets/images/${this.content.format.name.toLowerCase()}.png`)
      }
    },
    styleAuthorTag(content) {
      // const imgTag = '<img'
      // const srcAtr = 'src="'
      // const keyphrase = 'About the Authors'
      // const embdStyle = '<img style="width: auto; height: 120px; object-fit: cover; float: left; margin: 0rem 2rem 2rem 0rem;" '
      // // style author image
      // let found = content.indexOf(keyphrase)
      // while (found > -1) {
      //   const imgTagIdx = content.indexOf(imgTag, found + 1)
      //   const srcAtrIdx = content.indexOf(srcAtr, found + 1)
      //   content = content.substring(0, imgTagIdx) + embdStyle + content.substring(srcAtrIdx)
      //   found = content.indexOf(imgTag, srcAtrIdx + 1)
      //   if (found > -1) found = found - 1
      // }
      // // style author block
      // found = content.indexOf(keyphrase)
      // while (found > -1) {
      //   const opTagIdx = content.indexOf('<p>', found + 1)
      //   const cpTagIdx = content.indexOf('</p>', opTagIdx + 1) + 4
      //   const authorEl = content.substring(opTagIdx, cpTagIdx)
      //   if (authorEl.includes(embdStyle)) {
      //     content = content.substring(0, opTagIdx) + '<div style="min-height: 140px;">' + authorEl + '</div>' + content.substring(cpTagIdx)
      //   }
      //   found = content.indexOf('<p>', cpTagIdx)
      // }
      return content
    },
    async onPrepareGAPayload(category) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'content-id': this.contentId,
        'content-title': this.content.title,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'RWL Landing Page',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.non-white-background {
  background: #efefef;
}

.desktop-content-container {
  padding: 2rem 3.8rem;
  max-width: 1700px;
}

.mobile-content-container {
  padding: 2rem 1rem;
}

.content-carousel-mobile {
  display: none !important;
}

.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Arial';
  color: #5c5c5c;
  display: block;
  padding: 0;
  svg {
    position: relative;
    top: 5px;
  }
}

.back-desktop {
  font-size: 14px;
}

.back-mobile {
  font-size: 12px;
}

.accessible-button {
  text-decoration: unset;
  background-color: unset;
  padding: unset;
}

.content-info {
  display: flex;
  margin-top: 2rem;
  gap: 60px;
}

.content-img-background {
  position: relative;
  max-width: 165px;
  border: 4px solid var(--ion-color-white);
  overflow: hidden;
  width: 165px;
  height: 165px;
  border: 5px solid var(--ion-color-white);
  background-size: contain;
  border-radius: 20px;
  img {
    width: 155px;
    height: 155px;
    object-fit: cover;
  }
}

.meta {
  display: flex;
  gap: 24px;
  text-transform: uppercase;
  color: var(--ion-color-black);
  font-size: 12px;
  font-weight: bold;
  font-family: 'Arial';
  letter-spacing: 1.2px;
  align-items: center;
  .category {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .format {
    padding: 0.32rem 0.5rem;
    border-radius: 10px;
    background: #e0e1e1;
    font-family: 'Arial';
    text-transform: capitalize;
    font-size: 14px;
    color: #2d2d2d;
  }
  .g-button--primary {
    width: 7rem;
    margin-right: 1rem;
    text-transform: capitalize;
  }
  .provider {
    align-self: center;
    font-family: 'Arial';
    font-size: 14px;
    text-transform: capitalize;
    color: #2d2d2d;
  }
}
.title {
  margin: 1.5rem 0rem;
  text-align: left;
  font-size: 48px;
  font-family: 'Futura PT Demi';
  color: #000000;
  line-height: 45px;
}

.meta-info {
  display: flex;
  gap: 24px;
  font-size: 14px;
  margin-bottom: 1.5rem;
  align-items: center;
  .content-rating {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .star-rating {
    position: relative;
    font-family: Arial;
    color: #2d2d2d;
  }
  .rating-count {
    font-family: Arial;
    color: #2d2d2d;
  }
  .duration,
  .level,
  .publish-date {
    font-family: Arial;
    color: #2d2d2d;
  }
}

.meta-action {
  display: flex;
  gap: 8px;
  align-items: center;
  .content-bookmark,
  .more-action,
  .social-share {
    width: 40px;
    height: 40px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon {
      width: 1.5rem;
    }
  }
  .bookmark-container,
  .more-container,
  .share-container {
    position: relative;
    .bookmark-tooltip-text,
    .more-tooltip-text,
    .share-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      right: 0;
    }
    .bookmark-tooltip-text {
      right: -20px;
    }
  }
  .bookmark-container:hover .bookmark-tooltip-text {
    visibility: visible;
  }
  .share-container:hover .share-tooltip-text {
    visibility: visible;
  }
  .more-container:hover .more-tooltip-text {
    visibility: visible;
  }
  .progress {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
    margin-left: 1.5rem;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
    span {
      font-family: 'Arial';
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;
      color: #2d2d2d;
    }
  }
}

.display-rating-mobile {
  position: relative;
  top: -2px;
  margin-right: 5px;
  width: 0.8rem;
  height: 0.8rem;
}

.content-about-container {
  margin-top: 3rem;
}

.social-share-popover,
.more-popover {
  position: absolute;
  box-shadow: 0px 0px 12px #0000001f;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px;
  z-index: 10;
  opacity: 0;
  margin-top: 10px;
  animation-name: slideinmore;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  text-align: left;
}

@keyframes slideinmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -217px;
  }
}

@media screen and (max-width: 1366px) {
  .content-img-background {
    display: none;
  }
  @keyframes slideinmore {
    from {
      width: 0;
      font-size: 0px;
    }
    to {
      width: 256px;
      opacity: 1;
      font-size: 14px;
      left: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .content-carousel-desktop {
    display: none !important;
  }
  .content-carousel-mobile {
    display: block !important;
  }
}

@media screen and (max-width: 484px) {
  .title {
    margin: 24px 0rem;
    font-size: 34px;
    line-height: 40px;
  }
  .meta-info {
    margin-bottom: 20px;
    .content-rating {
      display: flex;
      .star-rating {
        display: none;
      }
      display: inline;
    }
  }
  .meta-action {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 375px) {
  .meta-info {
    gap: 5px;
    .duration,
    .level,
    .rating-count {
      padding-right: 5px;
    }
  }
}
</style>
