var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "celebrate-modal" },
    [
      _c("lottie-player", {
        attrs: {
          src: "/json/confetti.json",
          background: "transparent",
          speed: "1",
          autoplay: ""
        },
        on: { complete: _vm.closeCelebration }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }