var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-change-password" },
    [
      _c(
        "div",
        { staticClass: "edge-change-password__svg-wrapper" },
        [
          _c("svg-icon", {
            class: this.$platform === "Desktop" ? "on-desktop" : "on-mobile",
            attrs: { "icon-class": "small-logo", "class-name": "logo--size" }
          })
        ],
        1
      ),
      _c("div", { staticClass: "edge-change-password__error-message" }, [
        _vm._v(_vm._s(_vm.errorMessage))
      ]),
      _c(
        "div",
        { staticClass: "edge-change-password__input" },
        [
          _c(
            "ion-item",
            { staticClass: "ion-no-padding" },
            [
              _c("ion-input", {
                ref: "email",
                attrs: {
                  placeholder: "Email Address",
                  autofocus: "",
                  disabled: _vm.isInputDisabled
                },
                on: {
                  ionChange: function($event) {
                    _vm.user.email = $event.target.value
                  }
                }
              })
            ],
            1
          ),
          _c(
            "ion-item",
            { staticClass: "edge-linkedinConnect__email-input ion-no-padding" },
            [
              _c("ion-input", {
                ref: "verificationCode",
                attrs: {
                  placeholder: "Verification Code",
                  disabled: _vm.isInputDisabled
                },
                on: {
                  ionChange: function($event) {
                    return _vm.setVerificationCode($event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "ion-item",
            { staticClass: "ion-no-padding" },
            [
              _c("ion-input", {
                ref: "password",
                attrs: {
                  type: _vm.showPwd ? "text" : "password",
                  placeholder: "Password",
                  disabled: _vm.isInputDisabled
                },
                on: {
                  ionChange: function($event) {
                    _vm.user.newPassword = $event.target.value
                    _vm.onPasswordChange()
                  }
                }
              }),
              _c("ion-icon", {
                attrs: { slot: "end", name: _vm.showPwd ? "eye" : "eye-off" },
                on: {
                  click: function() {
                    return (_vm.showPwd = !_vm.showPwd)
                  }
                },
                slot: "end"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "ion-button",
        {
          staticClass: "ion-button-primary",
          staticStyle: {
            "text-transform": "capitalize",
            "--box-shadow": "none"
          },
          attrs: {
            shape: "round",
            color: "primary",
            disabled: _vm.isInputDisabled
          },
          on: { click: _vm.onChangePassword }
        },
        [_vm._v("Change Password")]
      ),
      _c(
        "div",
        {
          staticClass: "g-type-small",
          staticStyle: { "margin-top": "0.5rem" }
        },
        [
          _c(
            "span",
            {
              staticClass: "edge-change-password__link",
              on: { click: _vm.onLogin }
            },
            [_vm._v("Login")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }