var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overlay" }, [
    _c(
      "div",
      { staticClass: "addskills-modal" },
      [
        _c("svg-icon", {
          staticClass: "modal-cancel-icon-style",
          attrs: { "icon-class": "filter-close" },
          on: { click: _vm.onCancel }
        }),
        _c(
          "p",
          { staticClass: "success-icn" },
          [
            _c("svg-icon", {
              class:
                _vm.modalProperties.type == "success" ? "g-icon--small" : "",
              attrs: {
                "icon-class":
                  _vm.modalProperties.type == "success"
                    ? "icon_success"
                    : "alert-icon"
              }
            }),
            _c("span", { staticClass: "modal-header-style" }, [
              _vm._v(_vm._s(_vm.modalProperties.header))
            ])
          ],
          1
        ),
        _c(
          "ion-row",
          [
            _c(
              "ion-col",
              {
                attrs: {
                  "size-xl": "12",
                  "size-lg": "12",
                  "size-md": "12",
                  "size-sm": "12",
                  "size-xs": "12"
                }
              },
              [
                _c("div", {
                  staticClass: "modal-message-style",
                  domProps: { innerHTML: _vm._s(_vm.modalProperties.message) }
                })
              ]
            ),
            _vm.modalProperties.btnText
              ? _c(
                  "ion-col",
                  {
                    staticClass: "text-container ion-no-padding",
                    attrs: {
                      "size-xl": "12",
                      "size-lg": "12",
                      "size-md": "12",
                      "size-sm": "12",
                      "size-xs": "12"
                    }
                  },
                  [
                    _c(
                      "ion-button",
                      {
                        staticClass: "ion-button-primary modal-btn-style",
                        attrs: {
                          size: "small",
                          shape: "round",
                          color: "primary"
                        },
                        on: { click: _vm.onCancel }
                      },
                      [_vm._v(_vm._s(_vm.modalProperties.btnText))]
                    )
                  ],
                  1
                )
              : _vm.modalProperties.primaryButton
              ? _c(
                  "ion-col",
                  {
                    staticClass: "text-container ion-no-padding",
                    attrs: {
                      "size-xl": "12",
                      "size-lg": "12",
                      "size-md": "12",
                      "size-sm": "12",
                      "size-xs": "12"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "link-btn",
                        attrs: { href: "#" },
                        on: { click: _vm.onCancel }
                      },
                      [_vm._v(_vm._s(_vm.modalProperties.secondaryButton))]
                    ),
                    _c(
                      "ion-button",
                      {
                        staticClass: "ion-button-primary modal-btn-style",
                        attrs: { shape: "round", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("confirmClearGoal")
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.modalProperties.primaryButton) + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }