<template>
  <nav class="edge-menu" style="overflow: auto">
    <ion-row class="ion-align-items-center" style="margin-top: 20px">
      <button @click="toggleSideMenu" class="slider-close" aria-label="close side menu">
        <svg-icon icon-class="close-icon" class-name="close-button-icon" />
      </button>
      <a href="#" @click="goToHome" class="logo-link" aria-label="go to homepage">
        <svg-icon icon-class="logo" class="logo-icon" alt="proedge logo image" />
      </a>
    </ion-row>
    <div class="links-container">
      <ul class="edge-menu__list" ref="menuList" :class="showScrollBar ? 'show-scrollbar' : ''">
        <li v-for="first in navBarMenu.links" :key="first.name" class="link-list" :class="$route.path.includes(first.link) && first.subLinks.length == 0 ? 'selected-link' : ''">
          <router-link
            v-if="first.subLinks.length == 0"
            @click.native="toggleSideMenu"
            data-cy="nav-first-level"
            class="first-level-link"
            :to="
              first.name.toLowerCase() === 'home' ? { name: first.name, params: { showInProgress: true } } : first.link.includes('/mylearning') ? getMyLearningUri() : first.link
            "
            v-track="`nav,${first.name},click`"
          >
            <span class="link-name">{{ first.name }}</span>
          </router-link>
          <div v-else>
            <div class="sub-menu-nav-item">
              <a @click="toggleSubmenu()" v-track="`nav,${first.name},click`" class="first-level-link"
                ><span class="link-name">{{ first.name }}</span></a
              >
              <svg-icon @click="toggleSubmenu()" icon-class="Icon-awesome-chevron-down" :class="subMenuVisible ? 'icon-rotated' : 'icon-normal'" />
            </div>
            <ul class="sub-menu" v-if="subMenuVisible">
              <template v-for="sublink in first.subLinks">
                <li :key="sublink.name" :class="{ 'selected-link': subLink === sublink.link && $route.path == first.link }" v-if="sublink.show">
                  <a @click="navigateTo(sublink.link, first.name)" class-active="active " class="second-level-link">{{ sublink.name }}</a>
                </li>
              </template>
            </ul>
          </div>
        </li>
      </ul>
      <div class="menu" style="margin-top: 48px">
        <router-link @click.native="toggleSideMenu" class="menu-link" to="/bookmarks" v-track="`nav,bookmarks<${user_id}>,click`">
          <p class="ion-no-margin menu-text">Bookmarks</p>
        </router-link>
      </div>
      <div v-if="tenantAsset.visible" class="menu">
        <a href="#" @click="onSubmitAsset" class="menu-link" v-track="`nav,submitAsset<${user_id}>,click`">
          <p class="ion-no-margin menu-text">
            {{ tenantAsset.action }}
            <svg-icon v-if="tenantAsset.submissionLink.length" icon-class="expand-out-icon" class="asset-icon" />
          </p>
        </a>
      </div>
      <div class="help">
        <a href="#" class="help-text" @click="onGetHelp" v-track="`nav,user<${user_id}>,help`">
          <p class="ion-no-margin menu-text">Help Center</p>
        </a>
      </div>
      <div class="logout">
        <a href="#" class="logout-text" @click="onLogout" v-track="`nav,user<${user_id}>,logout`">
          <p class="ion-no-margin menu-text">Logout</p>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import localforage from 'localforage'
import { Plugins } from '@capacitor/core'
const { Browser } = Plugins

export default {
  name: 'NavBarMenuDesktop',
  props: {
    navBarMenu: {
      type: Object,
      required: true,
      default: () => {
        return {
          links: []
        }
      }
    }
  },
  components: {},
  data() {
    return {
      authToken: '',
      user_id: '',
      tenantAsset: {
        available: false,
        enabled: false,
        action: '', // Submit an asset | Manage my submissions
        submissionLink: '',
        visible: false,
        userAccessAsset: false
      },
      showScrollBar: false,
      subMenuVisible: false,
      requiredLearningEnabled: false,
      subLink: ''
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  created() {},
  async mounted() {
    this.subLink = await localforage.getItem('profile-side-nav-changed')
    // this.$eventBus.$on('profile-side-nav-changed', this.setSubLink)
    this.user_id = await localforage.getItem('my_user_id')
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.showScrollBar = this.$refs.menuList.offsetHeight > 305
    await this.determineUserAccess()
  },
  methods: {
    ...mapActions('user', ['logout']),
    async determineUserAccess() {
      const tenantInfo = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(tenantInfo.settings)
      const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
      this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
      this.tenantAsset.available = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
      this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'tenant-assets-submission-enabled') && settings['tenant-assets-submission-enabled']
      this.tenantAsset.submissionLink = {}.hasOwnProperty.call(settings, 'apply-asset-upload-link') ? settings['apply-asset-upload-link'] : ''
      this.tenantAsset.action = this.tenantAsset.enabled && this.tenantAsset.submissionLink.length ? 'Submit an asset' : 'Manage my submissions'
      this.tenantAsset.visible = this.tenantAsset.available && this.tenantAsset.userAccessAsset && this.tenantAsset.enabled
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      this.requiredLearningEnabled =
        enabledFeatureFlags['learn_required_learning_enabled'] && {}.hasOwnProperty.call(settings, 'required-learning') && settings['required-learning']
    },
    toggleSubmenu() {
      this.subMenuVisible = !this.subMenuVisible
    },
    navigateTo(link, itemName) {
      if (itemName === 'My profile') {
        if (this.$route.path !== '/profile')
          this.$router.push({ name: 'Profile', params: { id: link } })
        else
          this.$eventBus.$emit('profile-sub-link-clicked', link)
        this.setSubLink(link)
      } else this.$router.push({ path: link })
      this.toggleSideMenu()
    },
    toggleSideMenu() {
      this.$emit('toggle-side-menu')
    },
    goToHome() {
      this.toggleSideMenu()
      if (this.$route.path !== '/home') {
        this.$router.push({ name: 'Home', params: { showInProgress: true } }).catch(() => {})
      }
    },
    async onSubmitAsset() {
      this.toggleSideMenu()
      if (this.tenantAsset.action === 'Submit an asset') {
        await Browser.open({ url: this.tenantAsset.submissionLink })
      } else if (this.tenantAsset.action === 'Manage my submissions') {
        this.$router.push('/submit-asset')
      }
    },
    onGetHelp() {
      this.toggleSideMenu()
      this.$router.push('/help-center')
    },
    async setSubLink(e) {
      this.subLink = e
      await localforage.setItem('profile-side-nav-changed', e)
    },
    async onLogout() {
      await localforage.setItem('logoutFromSSO', document.cookie.includes('ProAuthSSO='))
      await this.$learnAdmin.logout({ token: this.authToken })
      await this.logout()
    },
    getMyLearningUri() {
      const type = window.location.href.includes('/mylearning')
        ? window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
        : this.requiredLearningEnabled
        ? 'required'
        : 'recommended'
      return { name: 'MyLearning', params: { type: type, isNotFromDeepLink: true } }
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('edge-menu') {
  height: 100vh;
  z-index: 1;
  background-color: map-get($edge-colors, 'white');
  padding: 0 0;
  // box-shadow: 1px 0 4px 1px rgba(0, 0, 0, 0.12);
  top: 0;
  text-align: left;

  div {
    position: relative;
    &.menu {
      .menu-link {
        color: var(--ion-color-tertiary);
        font-size: 1rem;
        text-decoration: none;
      }
    }
  }

  @include element('help') {
    padding-left: 2.5rem;
    cursor: pointer;

    p {
      text-decoration: none;
      color: var(--ion-color-tertiary);
      cursor: pointer;
      font-family: 'Arial';
      font-size: 0.93rem;
    }
  }

  @include element('help-text') {
    text-decoration: underline;
  }

  @include element('list') {
    line-height: 2.19rem;
    position: relative;
    list-style: none;
    margin-bottom: 0;
    padding: 0 0;

    &.show-scrollbar {
      max-height: 300px;
      overflow-y: scroll;
    }

    li {
      font-size: 1.5rem;
      padding-left: 3.8rem;
      svg.svg-icon.icon--size {
        position: relative;
        margin: 0 0.8rem 0 0;
        padding-left: 3px;
      }

      .first-level-link {
        font-size: 1.125rem;
        text-decoration: none;
        color: var(--ion-color-tertiary);
        display: block;
        font-family: 'Arial' !important;
        margin-bottom: 12px;
      }
      ul {
        list-style: none;
        li {
          svg.svg-icon.subicon--size {
            position: relative;
            margin: 0 0.8rem 0 -2.4rem;
            padding-left: 3px;
          }
          .second-level-link {
            margin-left: -0.1rem;
            font-size: 1rem;
            text-decoration: none;
            color: var(--ion-color-tertiary);
            font-family: 'Arial' !important;
            display: block;
          }
          ul {
            list-style: none;
            li {
              svg.svg-icon.subsicon--size {
                position: relative;
                margin: 0 0.8rem 0 -4rem;
                padding-left: 3px;
              }
              .third-level-link {
                margin-left: -0.1rem;
                font-size: 1.4rem;
                text-decoration: none;
                color: var(--ion-color-tertiary);
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .sub-menu-nav-item {
    display: flex;
    align-items: center;
    svg {
      width: 13px;
      height: 0.5rem;
      cursor: pointer;
      margin-left: 1rem;
      margin-bottom: 0.75rem;
      &.icon-normal {
        transform: rotate(0deg);
      }
      &.icon-rotated {
        transform: rotate(180deg);
      }
    }
  }
  .sub-menu {
    padding: 0;
    li {
      padding-left: 2rem;
      :hover {
        background: unset;
        cursor: pointer;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  li a {
    :hover {
      text-decoration: underline;
    }
    span {
      display: inline-block;
      padding-left: 10px;
      cursor: pointer;
    }
  }

  .hamburger {
    position: absolute;
    left: 4rem;
    top: 1.4rem;
    margin-left: 107px;
    margin-right: 45px;
  }

  @include element('logo') {
    padding-left: 1.5rem;
    text-align: left;
    cursor: pointer;
    svg.svg-icon.logo--size {
      display: inline-block;
      position: relative;
      margin: auto;
      padding: auto;
      margin: 14px 0 14px 0;
      height: 30px;
      width: 114px;
      padding-left: 3px;
    }
  }
}

.logout {
  padding-left: 2.5rem;
  .logout-text {
    text-decoration: none;
    color: var(--ion-color-tertiary);
    cursor: pointer;
    font-family: 'Arial';
    font-size: 0.9rem;
    font-weight: bold;
  }
}

.help {
  padding-left: 2.5rem;
  margin-bottom: 20px;
  .help-text {
    text-decoration: none;
    color: var(--ion-color-tertiary);
    cursor: pointer;
    font-size: 0.9rem;
    font-family: 'Arial';
  }
}

.menu {
  margin-bottom: 20px;
  padding-left: 2.5rem;
}

.menu-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 2.29rem;
  margin-top: 16px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--ion-color-dark-blue);
  font-family: 'Arial';
  font-size: 0.9rem;
}

.slider-close {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  margin: 0px 24px;
}

.close-button-icon {
  height: 20px !important;
  width: 20px !important;
}
.logo-link {
  color: unset;
  text-decoration: unset;
  margin: unset;
}
.logo-icon {
  height: 34px !important;
  width: 114px !important;
}

.selected-link {
  border-left: 6px solid var(--ion-color-secondary);
}
.link-name {
  font-family: 'Arial' !important;
}
.asset-icon {
  margin-left: 0.5rem !important;
  width: 0.9rem !important;
  height: 0.9rem !important;
}
.link-list:hover {
  background: unset;
}
</style>
