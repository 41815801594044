<template>
  <div ref="top" :class="isDesktopPlatform ? '' : 'non-white-background'">
    <div v-if="dataReady">
      <ion-grid v-if="isContentAvailable" class="ion-no-padding ion-padding-bottom" :class="isDesktopPlatform ? 'desktop-external-container' : 'mobile-external-container'">
        <ion-row>
          <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
            <div class="back" :class="$platform === 'Desktop' ? 'back-desktop' : 'back-mobile'" @click="$router.go(-1)">
              <button class="accessible-button">
                <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
              </button>
              Back
            </div>
            <ion-row class="external-info">
              <ion-col class="external-img-background">
                <img v-if="external.image && external.image.uri" :src="external.image.uri" />
                <img v-else :src="defaultImage" />
              </ion-col>
              <ion-col>
                <div class="meta">
                  <div class="category">
                    <div class="format">{{ external.format && external.format.name }}</div>
                    <div class="provider">{{ external.provider && external.provider.name }}</div>
                  </div>
                  <div v-if="external.progress">
                    <div v-if="external.progress > 0 && external.progress < 100" class="progress">
                      <svg-icon icon-class="progress" />
                      <span>In progress</span>
                    </div>
                    <div v-else-if="external.completed || external.progress == 100" class="progress">
                      <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                      <span>completed</span>
                    </div>
                  </div>
                </div>
                <div class="title">{{ external.title }}</div>
                <div class="meta-info">
                  <div class="duration">{{ external.duration | formatDuration }}</div>
                  <div class="visibility"><svg-icon icon-class="icon-eye" class="g-icon--small eye" /><span>Only You</span></div>
                </div>

                <div class="disclaimer">ProEdge is not responsible for any Client-created or linked content, or content that Clients or users source from third parties.</div>

                <div class="meta-action">
                  <ion-button class="launch-btn" shape="round" color="primary" @click="onLaunch">{{ external.launchBtn }} <ion-icon name="open-outline" slot="end" /></ion-button>
                  <div class="more-container" v-if="showAddToTrack">
                    <div class="more-action" @click="popoverOn('more')"><svg-icon icon-class="more-action" class-name="g-icon--small g-clickable" /></div>
                    <MoreActionPopover
                      v-if="isMoreOpen"
                      class="more-popover"
                      :item="external"
                      :assetId="itemId"
                      :assetType="external.format.name"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      :showDelete="external.progress != 100"
                      :showEdit="external.progress != 100"
                      :showAddToTrack="showAddToTrack"
                      size="lg"
                      fromPage="external content"
                      @add-to-track="popoverOff('more')"
                      @edit-delete-external-content="onEditDeleteExternalContent"
                      @close-more="popoverOff('more')"
                    />
                    <span v-if="!isMoreOpen" class="more-tooltip-text">More</span>
                  </div>
                  <div class="external-content-progress">
                    <ion-button v-if="!external.completed" class="complete-btn" shape="round" color="primary" @click="onMarkCompleteAction">Mark complete</ion-button>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <div class="external-about-container">
          <ion-row class="external-carousel-desktop">
            <ion-col size="8.5">
              <CourseAbout :course="external" :relatedCourses="external.relatedCourses" />
            </ion-col>
            <ion-col v-if="isMetaDataVisible" size="3.5">
              <ItemMetaData :item="external" />
            </ion-col>
          </ion-row>
          <ion-row class="external-carousel-mobile">
            <ion-col v-if="isMetaDataVisible" size="12">
              <ItemMetaData :item="external">
                <CourseAbout :course="external" :relatedCourses="external.relatedCourses" slot="item-about" />
              </ItemMetaData>
            </ion-col>
            <ion-col v-else size="12">
              <CourseAbout :course="external" :relatedCourses="external.relatedCourses" />
            </ion-col>
          </ion-row>
        </div>
        <div v-if="showConfirmDelete">
          <div class="a-overlay" @click="onConfirmDelete(false)"></div>
          <div class="b-modal confirm-box">
            <img src="@/assets/images/close.svg" class="g-icon--small g-margin__right--msmall close-icon" @click="onConfirmDelete(false)" />
            <div class="confirm-box-header">Are you sure you want to delete?</div>
            <p>Deletion is permanent and can't be undone.</p>
            <div class="handler">
              <ion-button data-cy="confirm-progress" class="g-button--primary ion-button-primary delete-btn" color="primary" @click="onConfirmDelete(true)">Delete</ion-button>
              <a href="#" @click="onConfirmDelete(false)">Cancel</a>
            </div>
          </div>
        </div>
      </ion-grid>
      <ion-grid v-else :style="isDesktopPlatform ? '' : 'padding: 2rem 10px 6rem;'" class="ion-no-padding">
        <PageNotFound />
      </ion-grid>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import util from '@/utils/helper'
import localforage from 'localforage'
import CourseAbout from '@/views/course/components/CourseAbout'
import ItemMetaData from '@/components/molecules/ItemMetaData'
import PageNotFound from '@/components/molecules/PageNotFound'
import MoreActionPopover from '@/components/molecules/MoreActionPopover'
import { Plugins } from '@capacitor/core'
const { Browser } = Plugins

export default {
  name: 'ExternalContent',
  components: { ItemMetaData, CourseAbout, MoreActionPopover, PageNotFound },
  props: {},
  data() {
    return {
      authToken: '',
      itemId: '',
      external: null,
      scroll: 0,
      showConfirmationProgress: false,
      isMetaDataVisible: true,
      progressTracking: true,
      isEdit: false,
      isExternalSidebarActive: false,
      showConfirmDelete: false,
      isDesktopPlatform: true,
      isMoreOpen: false,
      dataReady: false,
      isContentAvailable: false,
      showAddToTrack: false
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {
    async $route() {
      document.getElementById('contentlayout').classList.remove('no-scroll')
    }
  },
  created() {},
  async mounted() {
    this.progressTracking = {}.hasOwnProperty.call(this.$route.params, 'pgrTrk') ? this.$route.params.pgrTrk : true
    this.showAddToTrack = await localforage.getItem('show-add-to-track')
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.isMetaDataVisible = enabledFeatureFlags['learn_course-meta-data-enabled'] ? enabledFeatureFlags['learn_course-meta-data-enabled'] : false
  },
  async activated() {
    this.$refs.top.scrollIntoView()
    await this.setupExternalContent()
    this.$eventBus.$on(`external-added-to-track`, this.setupExternalContent)
  },
  deactivated() {
    this.$eventBus.$off(`external-added-to-track`)
  },
  filters: {
    formatDuration(duration) {
      return duration < 3600 ? `${Math.floor(duration / 60)}min` : `${Math.floor(duration / 3600)}hr ${Math.floor((duration - Math.floor(duration / 3600) * 3600) / 60)}min`
    },
    formatPublishedDate(d) {
      if (!d) return ''
      let date = new Date(d)
      date = date.toDateString().split(' ')
      return `${date[1]} ${date[3]}`.toUpperCase()
    },
    onCompletedDate: (d) => {
      const date = new Date(d)
      const duration = new Date() - date
      const days = Math.ceil(duration / (1000 * 60 * 60 * 24))
      if (days == 0 || days == 1) return 'today'
      else if (days == 2) return 'yesterday'
      else if (days > 2 && days < 7) return `${days} days ago`
      else return date.toLocaleDateString('en-US')
    }
  },
  methods: {
    async setupExternalContent() {
      await localforage.removeItem('deep_link_id')
      await localforage.removeItem('deep_link_type')
      this.dataReady = false
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      this.itemId = this.$route.params.id
      const response = await this.$learnAdmin.getExternalContentById({ token: this.authToken, guid: this.itemId })
      this.external = response.data
      this.isContentAvailable = {}.hasOwnProperty.call(response, 'status') && response.status != 400 && response.status != 404 && response.status != 401
      if (this.isContentAvailable) {
        this.external = { ...this.external.learningItem, ...this.external }
        this.external.source = util.encodeDecodeString(this.external.learningItem.source)
        this.external.title = util.encodeDecodeString(this.external.title)
        this.external.provider = { name: this.external.learningItem.source }
        this.external.synopsis = this.external.synopsis ? util.encodeDecodeString(this.external.synopsis) : 'No description provided.'
        this.external.about = this.external.synopsis
        this.external.level = this.external.courseLevels ? this.external.courseLevels[0] : ''
        this.external.isLaunched = this.external.progress > 0 && this.external.progress < 100
        this.external.launchBtn = 'Open'
        this.external.rating = {}.hasOwnProperty.call(this.external, 'rating') ? this.external.rating : 0
        this.external.ratingCount = 0
        this.external.ratingCounts = {}.hasOwnProperty.call(this.external, 'ratingCounts') ? this.external.ratingCounts : []
        this.external.inProgress = this.external.progress > 0 && this.external.progress < 100
        this.external.completed = this.external.progress === 100
        this.external.userTracks = this.external.tracks || []
        this.external.addedBy = ['You']
        this.external.isExternal = true
        this.external.emsiSkills =
          {}.hasOwnProperty.call(this.external, 'learningItem') && this.external.learningItem.skills && this.external.learningItem.skills.length > 0
            ? this.external.learningItem.skills.map((item) => item.name)
            : []
        if (this.external.ratingCounts.length) {
          await this.external.ratingCounts.map((r) => {
            this.external.ratingCount += r.count
          })
        }
        const relatedCourses = []
        this.external.relatedCourses = !relatedCourses.error ? relatedCourses : []
        await this.onPrepareGAPayload('Viewed')
        this.defaultImage = this.getDefaultImage()
      }
      this.dataReady = true
    },
    async onLaunch() {
      this.dataReady = false
      if (!this.external.completed) {
        const payload = {
          guid: this.external.externalArtifactGuid,
          token: this.authToken,
          body: {
            completedDate: null,
            isCompleted: false,
            progress: 50,
            startedDate: new Date()
          }
        }
        const progressResponse = await this.$learnAdmin.updateExternalContentProgress(payload)
        await this.onPrepareGAPayload('Open')
        this.external.inProgress = !this.external.completed
        this.external.progress = progressResponse.progress
      }
      await Browser.open({ url: this.external.content.contentUri })
      this.dataReady = true
    },
    async onMarkCompleteAction() {
      this.dataReady = false
      const payload = {
        guid: this.external.externalArtifactGuid,
        token: this.authToken,
        body: {
          completedDate: new Date(),
          isCompleted: true,
          progress: 100,
          startedDate: null
        }
      }
      await this.$learnAdmin.updateExternalContentProgress(payload)
      await this.onPrepareGAPayload('Mark complete')
      this.external.completed = true
      this.external.inProgress = !this.external.completed
      this.external.completedAt = new Date()
      this.dataReady = true
      this.external.progress = 100
    },
    async onEditDeleteExternalContent(action) {
      this.isMoreOpen = false
      this.isEdit = action === 'edit'
      this.isExternalSidebarActive = action === 'edit'
      if (this.isExternalSidebarActive) this.$eventBus.$emit('on-open-external-sidebar', { isEdit: true, external: this.external })
      this.showConfirmDelete = action === 'delete'
      await this.onPrepareGAPayload(action[0].toUpperCase() + action.substr(1))
    },
    onToggleExternalSidebar() {
      this.isExternalSidebarActive = !this.isExternalSidebarActive
    },
    async onUpdateSuccess() {
      await this.setupExternalContent()
    },
    async onConfirmDelete(isDelete) {
      if (isDelete) {
        const payload = {
          guid: this.external.externalArtifactGuid,
          token: this.authToken,
          body: {
            matchedCount: 1,
            modifiedCount: 1,
            upsortedId: null
          }
        }
        const response = await this.$learnAdmin.deleteExternalContent(payload)
        const { error } = response
        if (error) {
          this.showConfirmDelete = false
        } else {
          this.showToast('Item successfully deleted', 'success')
          this.$router.go(-1)
        }
      } else {
        this.showConfirmDelete = false
      }
    },
    showToast(msg, className) {
      this.$toasted.show(msg, {
        containerClass: 'toast-container',
        className: className,
        position: this.isDesktopPlatform ? 'bottom-center' : 'mobile-container',
        iconPack: 'icon-container',
        duration: 5000,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    getRouteInfo() {
      const name = 'ExternalContent'
      const params = { id: this.itemId, pgrTrk: false }
      return { name: name, params: params }
    },
    popoverOff(popoverType) {
      this.isMoreOpen = !popoverType === 'more'
    },
    popoverOn(popoverType) {
      this.isMoreOpen = popoverType === 'more'
    },
    getDefaultImage() {
      if (['course', 'article', 'video', 'audio', 'podcast'].includes(this.external.format.name.toLowerCase())) {
        return require(`@/assets/images/${this.external.format.name.toLowerCase()}.png`)
      }
    },
    async onPrepareGAPayload(category) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'xcontent-guid': this.external.externalArtifactGuid,
        'xcontent-title': this.external.title,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'External Content Landing Page',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.non-white-background {
  background: #efefef;
}

.external-carousel-mobile {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .external-carousel-desktop {
    display: none !important;
  }

  .external-carousel-mobile {
    display: block !important;
  }
}

.desktop-external-container {
  padding: 2rem 3.8rem;
  max-width: 1700px;
}

.mobile-external-container {
  padding: 2rem 1rem;
}

.coursera-link {
  padding: 0.5rem 4rem;
  background: var(--ion-color-secondary);
  color: var(--ion-color-tertiary);
  border: 1px solid var(--ion-color-secondary);
  border-radius: 2rem;
  text-decoration: none;
  font-family: 'Futura PT Book', sans-serif;
  font-size: 1rem;
}

.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Arial';
  color: #5c5c5c;

  svg {
    position: relative;
    top: 5px;
  }
}

.back-desktop {
  font-size: 14px;
}

.back-mobile {
  font-size: 12px;
}

.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  padding: unset;
}

.external-info {
  display: flex;
  margin-top: 2rem;
  gap: 60px;
}

.external-img-background {
  position: relative;
  max-width: 165px;
  border: 4px solid var(--ion-color-white);
  overflow: hidden;
  width: 165px;
  height: 165px;
  border: 5px solid var(--ion-color-white);
  background-size: contain;
  border-radius: 20px;
  img {
    width: 165px;
    height: 165px;
    object-fit: cover;
  }
}

.meta {
  display: flex;
  gap: 24px;
  text-transform: uppercase;
  color: var(--ion-color-black);
  font-size: 12px;
  font-weight: bold;
  font-family: 'Arial';
  letter-spacing: 1.2px;
  .category {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .format {
    padding: 0.32rem 0.5rem;
    border-radius: 10px;
    background: #e0e1e1;
    font-family: 'Arial';
    color: #2d2d2d;
  }
  .g-button--primary {
    width: 92px;
    margin-right: 1rem;
    text-transform: capitalize;
    --box-shadow: none;
  }
  .provider {
    align-self: center;
    font-family: 'Arial';
    color: #2d2d2d;
  }
  .progress {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
    span {
      font-family: 'Arial';
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;
      color: #2d2d2d;
    }
  }
}

.external-about-container {
  margin-top: 2.5rem;
}

.title {
  margin: 1.5rem 0;
  text-align: left;
  font-size: 48px;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-dark-blue);
  line-height: 45px;
}

.meta-info {
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  .external-rating {
    display: flex;
    cursor: pointer;
    border-right: 2px solid #e0e1e1;
  }

  .star-rating {
    position: relative;
    top: -2px;
    font-weight: bold;
    font-family: Arial;
    padding-left: 5px;
    color: #000000;
  }

  .rating-count {
    font-family: Arial;
    padding: 0 10px 0;
    color: #000000;
  }

  .duration,
  .level,
  .publish-date {
    font-size: 12px !important;
    font-weight: bold;
    font-family: Arial;
    padding-right: 10px;
    border-right: 2px solid #e0e1e1;
    color: #2d2d2d;
  }

  .publish-date {
    border-right: unset;
  }

  .level-border-unset {
    border-right: unset;
  }

  .duration-border-unset {
    border-right: unset;
  }
}

.meta-action {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .launch-btn,
  .complete-btn {
    font-size: 16px;
    width: 154px;
    height: 40px;
    margin-right: 1.5rem;
    text-transform: none;
    font-family: 'Futura PT Demi';
    --ion-color-primary: #feb92e;
    --ion-color-primary-contrast: black;
    --box-shadow: unset;
    margin-left: 16px;
  }

  .complete-btn {
    --ion-color-primary: #1f3370;
    --ion-color-primary-contrast: #ffffff;
    --box-shadow: unset;
    &.gap {
      margin-left: 120px;
    }
  }

  .external-bookmark,
  .social-external {
    width: 40px;
    height: 40px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-gray);
    border-radius: 50%;
    margin: 4px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      width: 1.5rem;
    }
  }
}

.display-rating-mobile {
  position: relative;
  top: -2px;
  margin-left: 4px;
  width: 0.8rem;
  height: 0.8rem;
}

.more-popover {
  position: absolute;
  box-shadow: 0px 0px 12px #0000001f;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px;
  z-index: 10;
  opacity: 0;
  margin-top: 10px;
  animation-name: slideinmore;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  text-align: left;
}

@keyframes slideinmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -217px;
  }
}

@media screen and (max-width: 1366px) {
  .external-img-background {
    display: none;
  }
}

@media screen and (max-width: 484px) {
  .title {
    margin: 24px 0rem;
    font-size: 34px;
    line-height: 40px;
  }

  .meta-info {
    margin-bottom: 20px;

    .external-rating {
      display: flex;

      .star-rating {
        display: none;
      }

      display: inline;
    }
  }

  .meta-action {
    // flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;

    .complete-btn {
      margin: 16px 1rem 0 0;
      width: 140px;
    }

    .launch-btn {
      width: 140px;
      margin-right: 1rem;
    }

    .external-bookmark,
    .external {
      margin: 4px 0px 0px 0px;
    }
  }
}

@media screen and (max-width: 375px) {
  .meta-info {
    gap: 5px;

    .duration,
    .level,
    .rating-count {
      padding-right: 5px;
    }
  }
}

.mobile-overlay {
  height: 150vh;
}

.eye {
  margin-right: 0.5rem;
  width: 20px !important;
  height: 14px !important;
}

.visibility {
  span {
    font-size: 12px !important;
    font-family: 'Arial' !important;
    font-weight: bold !important;
    color: #2d2d2d;
  }
}

.disclaimer {
  margin-bottom: 1rem;
  text-align: left;
  font-family: 'Arial' !important;
  font-size: 16px !important;
  color: #5c5c5c;
}

.more-action {
  width: 40px;
  height: 40px;
  border: 1px solid var(--ion-color-light-gray);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  background: var(--ion-color-white);
}

.more-container {
  position: relative;
  .more-tooltip-text {
    visibility: hidden;
    text-align: center;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000001f;
    border-radius: 4px;
    opacity: 1;
    font-size: 12px;
    font-family: Arial;
    top: -30px;
    color: #000000de;
    right: 0;
  }
}
.more-container:hover .more-tooltip-text {
  visibility: visible;
}
@media screen and (max-device-width: 767px) {
  .icon-badge-wrapper {
    flex: 1;
    text-align: left;
  }

  .confirm-box {
    width: 100% !important;
  }

  .complete-btn {
    margin-left: 0 !important;
    margin-top: 1rem;
  }

  .external-content-progress {
    flex: 1 1 100%;
    text-align: left;
    flex-direction: column;
    align-items: start !important;
  }
}

.external-content-progress {
  display: flex;
  margin-left: 16px;
  align-items: center;
}

.external-content-progress {
  display: flex;
  margin-left: 16px;
  align-items: center;
}

.icon-badge {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #fff;
  color: #000;
  padding: 0.6rem;
}

.external-sidebar-container {
  position: fixed;
  z-index: 9999;
  right: 0;
  width: 640px;
  border-radius: 20px 0 0 20px;
}

.external-sidebar-container-mobile {
  height: 100vh !important;
}

@media screen and (max-device-width: 480px) {
  .external-sidebar-overlay,
  .external-sidebar-container {
    margin-top: 60px;
  }
}

.external-sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000070;
  z-index: 98;
  cursor: pointer;
}

@media screen and (max-device-width: 767px) {
  .external-sidebar-container {
    width: 100% !important;
    border-radius: 10px 0 0 10px !important;
  }
}

.confirm-box {
  border-radius: 20px;
  width: 420px;
  height: 242px;
  padding: 32px;

  .confirm-box-header {
    width: 264px;
    height: 61px;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-family: 'Futura PT Demi';
    text-align: left;
    color: var(--ion-color-black);
  }

  p {
    width: 356px;
    height: 17px;
    margin-top: 0;
    font-size: 16px;
    color: #5c5c5c;
    margin-bottom: 26px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .handler {
    justify-content: flex-start;

    a {
      margin-left: 2em;
      font-size: 16px;
      font-family: 'Futura PT Demi';
    }

    .delete-btn {
      color: var(--ion-color-black);
      font-size: 16px;
      font-family: 'Futura PT Demi';
    }
  }

  .close-icon {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>