<template>
  <div>
    <ion-slides pager>
      <ion-slide v-for="(item, index) in carousel" :key="index">
        <div>
          <div v-if="item.heading" v-html="$sanitize(item.heading)" class="heading"></div>
          <img :src="item.imageUri" class="image" />
        </div>
      </ion-slide>
    </ion-slides>
  </div>
</template>

<script>
export default {
  name: 'CredentialContentCarousel',
  components: {},
  props: {
    carousel: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.heading {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1rem;
}
.image {
  margin-bottom: 2rem;
}

ion-slides {
  --bullet-background: var(--ion-color-post-gray);
  --bullet-background-active: var(--ion-color-tertiary);
}
</style>
