var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "rating", staticClass: "item-rating-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            "border-bottom": "2px solid var(--ion-color-light-gray)"
          }
        },
        [
          _c(
            "div",
            { staticClass: "tabs-container-desktop" },
            _vm._l(_vm.tabs, function(tab) {
              return _c(
                "button",
                {
                  key: tab.tidx,
                  staticClass: "tab accessible-button",
                  class: _vm.selectedTabIndex === tab.tidx ? "selected" : "",
                  attrs: { "aria-label": tab.title },
                  on: {
                    click: function($event) {
                      return _vm.onTabSelected(tab.tidx)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(tab.title) + " ")]
              )
            }),
            0
          )
        ]
      ),
      _c("div", { staticClass: "ratings-content" }, [
        {}.hasOwnProperty.call(_vm.ratingDetails, "totalNumberOfUsers") &&
        _vm.ratingDetails.totalNumberOfUsers > 0
          ? _c("div", [
              _c("div", { staticClass: "ratings-header" }, [
                _c(
                  "div",
                  { staticClass: "leave-a-review" },
                  [
                    _c("span", { staticClass: "average-rating" }, [
                      _c("span", { staticClass: "drop-cap" }, [
                        _vm._v(_vm._s(_vm.ratingDetails.averageRating) + " ")
                      ]),
                      _vm._v(" out of 5")
                    ]),
                    _c(
                      "div",
                      { staticClass: "ratings-total-container" },
                      [
                        _c("StarRating", {
                          staticClass: "rating",
                          attrs: {
                            "inactive-color": "#d0cfce",
                            "active-color": "#B98032",
                            "star-points": [
                              23,
                              2,
                              14,
                              17,
                              0,
                              19,
                              10,
                              34,
                              7,
                              50,
                              23,
                              43,
                              38,
                              50,
                              36,
                              34,
                              46,
                              19,
                              31,
                              17
                            ],
                            rating: _vm.ratingDetails.averageRating,
                            "read-only": true,
                            increment: 0.1,
                            "star-size": 16,
                            "show-rating": false,
                            "round-start-rating": true
                          }
                        }),
                        _c("span", { staticClass: "ratings-total" }, [
                          _vm._v(
                            _vm._s(_vm.ratingDetails.totalNumberOfUsers) +
                              " " +
                              _vm._s(
                                _vm.ratingDetails.totalNumberOfUsers > 1
                                  ? "ratings"
                                  : "rating"
                              )
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "ion-button",
                      {
                        staticClass: "review-btn",
                        attrs: { shape: "round", color: "primary" },
                        on: { click: _vm.onLeaveRating }
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    )
                  ],
                  1
                ),
                {}.hasOwnProperty.call(_vm.ratingDetails, "ratingHistogram") &&
                this.ratingDetails.ratingHistogram.length > 0
                  ? _c(
                      "div",
                      _vm._l(_vm.ratingBarStatus, function(score, index) {
                        return _c(
                          "div",
                          { key: index + Math.random() },
                          [
                            _c("ItemRatingBar", {
                              attrs: {
                                index: _vm.ratingBarStatus.length - index,
                                score: Math.round(
                                  (_vm.ratingBarStatus[
                                    _vm.ratingBarStatus.length - index - 1
                                  ] /
                                    _vm.ratingDetails.totalNumberOfUsers) *
                                    100
                                )
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]),
              _vm.ratingCommentEnabled
                ? _c(
                    "div",
                    _vm._l(_vm.getRatings(), function(rating) {
                      return _c(
                        "div",
                        { key: rating.id, staticClass: "ratings-row" },
                        [
                          _c("CommentListItem", {
                            attrs: { rating: rating },
                            on: {
                              "flag-submitted": function($event) {
                                return _vm.updateRatingAndComment()
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          : _c(
              "div",
              { staticClass: "ratings-header-no-reviews" },
              [
                _c("div", { staticClass: "no-reviews-title" }, [
                  _vm._v("There are currently no ratings.")
                ]),
                _c("div", { staticClass: "no-reviews-content" }, [
                  _vm._v("Share your thoughts with other learners")
                ]),
                _c(
                  "ion-button",
                  {
                    staticClass: "review-btn",
                    attrs: { shape: "round", color: "primary" },
                    on: { click: _vm.onLeaveRating }
                  },
                  [_vm._v(_vm._s(_vm.buttonText))]
                )
              ],
              1
            )
      ]),
      _vm.commentsWithRatings.length
        ? _c("paginate", {
            attrs: {
              "page-count": _vm.pageCount,
              "page-range": _vm.pageRange,
              "margin-pages": 1,
              "click-handler": _vm.handlePageClick,
              "container-class": "pagination",
              "page-class": "pagination-item",
              "page-link-class": "pagination-item-link",
              "prev-text": "&lsaquo;",
              "prev-class": "pagination-nav",
              "prev-link-class": "pagination-nav-link",
              "next-text": "&rsaquo;",
              "next-class": "pagination-nav",
              "next-link-class": "pagination-nav-link",
              "hide-prev-next": true
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }