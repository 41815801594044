var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg-icon", {
    attrs: {
      "icon-class": "awesome-question-circle",
      "class-name": "g-icon--xsmall g-margin__left--small g-clickable",
      color: _vm.color
    },
    on: {
      click: function($event) {
        return _vm.openPopover()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }