var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        [
          _c(
            "ion-row",
            { staticClass: "ion-align-items-center" },
            [
              _c("ion-col", { attrs: { size: "10" } }),
              _c("ion-col", [
                _c(
                  "button",
                  {
                    staticClass: "slider-close",
                    attrs: { "aria-label": "close side menu" },
                    on: { click: _vm.closeTrackBar }
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "close-icon",
                        "class-name": "close-button-icon"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tracks-list" },
        [
          _c("ion-row", { staticClass: "track-header" }, [
            _vm._v(" Add to a track ")
          ]),
          _c(
            "div",
            {
              staticStyle: {
                height: "calc(100vh - 330px)",
                "overflow-y": "auto",
                "scrollbar-color": "#bcc2d4 #1f3370"
              }
            },
            _vm._l(_vm.trackList, function(tracks, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "ion-row",
                    {
                      staticClass: "track-row",
                      class: index == 0 ? "track-row-first" : ""
                    },
                    [
                      _c("ion-col", [
                        tracks.image.signedRelativeUri == null ||
                        tracks.image.signedRelativeUri == ""
                          ? _c("img", {
                              staticClass: "imgCls",
                              attrs: {
                                alt: tracks.name,
                                src: require("@/assets/images/thumbnail-track.png")
                              }
                            })
                          : _c("img", {
                              staticClass: "imgCls",
                              attrs: {
                                alt: tracks.image.signedRelativeUri,
                                src:
                                  tracks.image.signedRelativeUri != null &&
                                  tracks.image.signedRelativeUri != ""
                                    ? tracks.image.signedRelativeUri
                                    : "@/assets/images/thumbnail-track.png"
                              }
                            })
                      ]),
                      _c(
                        "ion-col",
                        {
                          staticClass: "track-details-col",
                          attrs: { size: _vm.$platform === "Desktop" ? 9 : 8 }
                        },
                        [
                          _c("ion-row", [
                            _c("div", { staticClass: "track-name" }, [
                              _vm._v("TRACK")
                            ])
                          ]),
                          _c("ion-row", [
                            _c(
                              "a",
                              {
                                staticClass: "track-desc",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.goToTrack(tracks)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(tracks.name) + " ")]
                            )
                          ]),
                          _c("ion-row", [
                            _c("div", { staticClass: "track-time" }, [
                              _vm._v(
                                "Created by " +
                                  _vm._s(_vm.profile_name) +
                                  ". Updated " +
                                  _vm._s(
                                    _vm._f("formatUpdatedDate")(
                                      tracks.updatedAt
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c(
                            "ion-row",
                            { staticClass: "track-add-btn" },
                            [
                              _c(
                                "ion-button",
                                {
                                  staticClass:
                                    "ion-button-primary-dark track-btn",
                                  attrs: {
                                    disabled: _vm.disableAddButton(tracks),
                                    shape: "round",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addItemToTrack(tracks)
                                    }
                                  }
                                },
                                [_vm._v("Add ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "create-track-footer" },
      [
        _c(
          "ion-button",
          {
            staticClass: "ion-button-primary-dark create-new-track-btn",
            attrs: { shape: "round", color: "primary" },
            on: { click: _vm.createTrack }
          },
          [_vm._v(" Create a new track ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }