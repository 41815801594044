<template>
  <div class="submission-details-container main-content" v-if="componentActivated">
    <div class="error-alert" v-if="showErrorMessage">
      {{ errorMessage }}
    </div>
    <button class="back" @click="goBack()">
      <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
      Back
    </button>
    <div class="submission-form-content">
      <div class="icon-container">
        <div class="save-tooltip">
          <svg-icon @click="submitForm('draft')" icon-class="icon-save" :class="disableDraftButtonFlag() ? 'svg-disabled' : 'action-icon'" alt="save" />
          <span class="save-tooltiptext">Save as draft</span>
        </div>
        <div class="submit-tooltip" v-if="isDraftMode">
          <svg-icon @click="submitForm('submit')" icon-class="submit-icon" :class="disableSubmitButtonFlag() ? 'svg-disabled' : 'action-icon'" alt="submit" />
          <span class="submit-tooltiptext">{{
            [assetStatus.returned.value, assetStatus.inEdit.value].includes(reviewStatus.toUpperCase()) ? 'Resubmit' : 'Submit for review'
          }}</span>
        </div>
      </div>
      <div class="submission-form-header">
        <div class="last-saved" v-if="isDraftMode">
          <span>ID {{ displayId }}</span>
          <span class="divider"></span>
          <span>LAST SAVED {{ lastSaved | formatDate }}</span>
        </div>
        <h2>{{ [assetStatus.returned.value, assetStatus.inEdit.value].includes(reviewStatus.toUpperCase()) ? 'Edit asset details' : 'Submit an asset' }}</h2>
        <p class="mandatory-text">Fields marked with an asterisk (*) are required for submission.</p>
        <p style="margin-top: 2.5rem"><span class="dot">1</span><span class="heading-title">Describe your asset</span></p>
        <ion-row class="asset-description-form">
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">{{ popoverContent.assetName.title }}*</div>
            <div :class="assetNameError ? 'error-input' : 'valid-input'">
              <ion-input
                data-cy="asset-name"
                ref="assetName"
                maxlength="100"
                class="asset-name-in"
                :placeholder="popoverContent.assetName.placeholder"
                :value="assetName"
                @ionInput="handleInput($event, 'assetName', true)"
              ></ion-input>
              <div class="error-container" v-if="assetNameError">{{ popoverContent.assetName.error }}</div>
            </div>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section>
                  <p>{{ popoverContent.assetName.tip }}</p>
                </template>
              </Collapsible>
            </div>
          </ion-col>
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">{{ popoverContent.promoHeader.title }}*</div>
            <div :class="promoHeaderError ? 'error-input' : 'valid-input'">
              <ion-textarea
                data-cy="asset-header"
                class="ion-no-padding form-input-val"
                ref="promoHeader"
                autofocus
                rows="5"
                required
                @ionInput="handleInput($event, 'promoHeader', true)"
                :value="promoHeader"
                :placeholder="popoverContent.promoHeader.placeholder"
              ></ion-textarea>
              <div class="error-container" v-if="promoHeaderError">{{ popoverContent.promoHeader.error }}</div>
            </div>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section
                  ><p>{{ popoverContent.promoHeader.tip }}</p></template
                >
              </Collapsible>
            </div>
          </ion-col>
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">{{ popoverContent.promoDescription.title }}*</div>
            <div :class="promoDescriptionError ? 'error-input' : 'valid-input'">
              <ion-textarea
                data-cy="asset-header"
                class="ion-no-padding form-input-val"
                ref="promoDescription"
                autofocus
                rows="5"
                required
                @ionInput="handleInput($event, 'promoDescription', true)"
                :value="promoDescription"
                :placeholder="popoverContent.promoDescription.placeholder"
              ></ion-textarea>
              <div class="error-container" v-if="promoDescriptionError">{{ popoverContent.promoDescription.error }}</div>
            </div>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section
                  ><p>{{ popoverContent.promoDescription.tip }}</p></template
                >
              </Collapsible>
            </div>
          </ion-col>
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">
              {{ popoverContent.fullDescription.title }}
            </div>
            <ion-textarea
              data-cy="asset-description"
              class="ion-no-padding form-input-val"
              ref="fullDescription"
              autofocus
              rows="5"
              required
              @ionInput="handleInput($event, 'fullDescription', false)"
              :value="fullDescription"
              :placeholder="popoverContent.fullDescription.placeholder"
            ></ion-textarea>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section
                  ><p>{{ popoverContent.fullDescription.tip }}</p></template
                >
              </Collapsible>
            </div>
          </ion-col>
          <ion-col @drop="handleDrop($event, 'upload-support-input')" @dragover="handleDragover" @dragenter="handleDragover">
            <div class="section-title">
              {{ popoverContent.imageOrVideo.title }}
            </div>
            <div class="file-upload-container">
              <div class="upload-button">
                <div class="upload-btn-content">
                  <ion-icon class="ion-icon-size" name="cloud-upload-outline"></ion-icon>
                  <p>Drop files to upload (png) or</p>
                </div>
              </div>
              <label class="file-upload-wrapper" tabindex="0" @keyup.enter="openEl('upload-support-input')">
                <input
                  data-cy="asset-file"
                  ref="upload-support-input"
                  class="upload-input"
                  type="file"
                  accept="image/png"
                  v-if="!resetImgEl"
                  multiple
                  @change="handleClick($event, 'upload-support-input')"
                />
                Choose files</label
              >
              <ion-row class="file-details" v-for="(imageFile, index) in uploadScreenshots" :key="index">
                <ion-col size-xs="12" class="file-meta-data">
                  <div class="section-subheading">
                    <ion-icon class="ion-icon-size" name="image-outline"></ion-icon>
                    <h5>{{ imageFile.name }}</h5>
                  </div>
                  <div class="section-meta-info">
                    <span class="section-meta-text"> {{ imageFile.size | formatSize }} </span>
                    <div class="download-button" v-if="isDraftMode && imageFile.signedUrl">
                      <img src="@/assets/images/svgs/icon-download.svg" @click="onDownload(imageFile)" alt="download-icon" />
                    </div>
                    <div class="download-button">
                      <ion-icon @click="deleteFiles('upload-support-input', index)" class="ion-icon-size" name="trash-outline"></ion-icon>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <div class="error-container" v-if="popoverContent.imageOrVideo.error">{{ popoverContent.imageOrVideo.error }}</div>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section
                  ><p>{{ popoverContent.imageOrVideo.tip }}</p></template
                >
              </Collapsible>
            </div>
          </ion-col>
        </ion-row>
        <p><span class="dot">2</span><span class="heading-title">Provide technical details</span></p>
        <ion-row class="technical-details-form">
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">{{ popoverContent.inventoryType.title }}*</div>
            <div :class="inventoryTypeError ? 'error-input' : 'valid-input'">
              <SingleSelectDropDown @selected="getInventory" :listVal="inventoryType" :list="inventoryTypeList" v-if="inventoryTypeList.length > 0" />
            </div>
            <div class="error-container" v-if="inventoryTypeError">{{ popoverContent.inventoryType.error }}</div>
          </ion-col>
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">{{ popoverContent.applications.title }}*</div>
            <div :class="applicationsError ? 'error-input' : 'valid-input'">
              <MultiSelectDropDown @selected="getTools" :listVal="applications" :list="toolsList" v-if="toolsList.length > 0"></MultiSelectDropDown>
              <div class="error-container" v-if="applicationsError">{{ popoverContent.applications.error }}</div>
            </div>
          </ion-col>
          <ion-col size-xs="12" size-md="12" class="form-col">
            <div class="section-title">{{ popoverContent.functions.title }}*</div>
            <div :class="functionsError ? 'error-input' : 'valid-input'">
              <FunctionDropDown @selectedFun="getFunctions" :listVal="functions" :list="functionsList" v-if="functionsList.length > 0"></FunctionDropDown>
              <div class="error-container" v-if="functionsError">{{ popoverContent.functions.error }}</div>
            </div>
          </ion-col>
          <ion-col @drop="handleDrop($event, 'upload-documentation-input')" @dragover="handleDragover" @dragenter="handleDragover">
            <div class="section-title">
              {{ popoverContent.uploadDocumentation.title }}
            </div>
            <div class="file-upload-container">
              <div class="upload-button">
                <div class="upload-btn-content">
                  <ion-icon class="ion-icon-size" name="cloud-upload-outline"></ion-icon>
                  <p>Drop file to upload (pdf) or</p>
                </div>
              </div>
              <label class="file-upload-wrapper" tabindex="0" @keyup.enter="openEl('upload-documentation-input')">
                <input
                  v-if="!resetPdfEl"
                  data-cy="asset-file"
                  ref="upload-documentation-input"
                  class="upload-input"
                  type="file"
                  accept="application/pdf"
                  @change="handleClick($event, 'upload-documentation-input')"
                />Choose files</label
              >
              <ion-row class="file-details" v-for="(imageFile, index) in uploadPdfFiles" :key="index">
                <ion-col size-xs="12" class="file-meta-data">
                  <div class="section-subheading"><ion-icon class="ion-icon-size" name="document-text-outline"></ion-icon>{{ imageFile.name }}</div>
                  <div class="section-meta-info">
                    <span class="section-meta-text"> {{ imageFile.size | formatSize }} </span>
                    <div class="download-button" v-if="isDraftMode && imageFile.signedUrl">
                      <img src="@/assets/images/svgs/icon-download.svg" @click="onDownload(imageFile)" alt="download-icon" v-if="isDraftMode" />
                    </div>
                    <div class="download-button">
                      <ion-icon class="ion-icon-size" name="trash-outline" @click="deleteFiles('upload-documentation-input', index)"></ion-icon>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <div class="error-container" v-if="popoverContent.uploadDocumentation.error">{{ popoverContent.uploadDocumentation.error }}</div>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section
                  ><p>{{ popoverContent.uploadDocumentation.tip }}</p></template
                >
              </Collapsible>
            </div>
          </ion-col>
        </ion-row>
        <div>
          <p><span class="dot">3</span><span class="heading-title">Share your asset</span></p>
          <ion-row class="asset-link-form">
            <ion-col size-xs="12" size-md="12" class="form-col">
              <div class="section-title asset-section-tab">
                <span v-if="['zip','both'].includes(tenantSettingsForAsset)" class="asset-type-tab" @click="selectAssetTab(true,'zip')" :class="canUploadAssets && ['both'].includes(tenantSettingsForAsset) ? 'selected-asset-tab' : externalAssetLink.length > 0 || ['link'].includes(tenantSettingsForAsset) ? 'disable-asset-tab' : ''">Asset zip file&nbsp; <span v-if="canUploadAssets">*</span></span> 
                <span v-if="['link','both'].includes(tenantSettingsForAsset)" class="asset-type-tab" @click="selectAssetTab(false,'link')" :class="!canUploadAssets && ['both'].includes(tenantSettingsForAsset) ? 'selected-asset-tab' : Object.keys(assetReference).length > 0 || fileUploadStatus !='' || ['zip'].includes(tenantSettingsForAsset) ? 'disable-asset-tab' : ''">{{ popoverContent.externalAssetLink.title }}&nbsp;<span v-if="!canUploadAssets">*</span></span></div>
              <div :class="externalAssetLinkError ? 'error-input' : 'valid-input'" v-if="!canUploadAssets">
                <ion-textarea
                  data-cy="asset-link"
                  class="ion-no-padding form-input-val"
                  ref="externalAssetLink"
                  rows="5"
                  required
                  :placeholder="popoverContent.externalAssetLink.placeholder"
                  @ionInput="handleInput($event, 'externalAssetLink', true)"
                  :value="externalAssetLink"
                ></ion-textarea>
                <div class="error-container" v-if="externalAssetLinkError">{{ popoverContent.externalAssetLink.error }}</div>
              </div>
              <div class="file-upload-container" v-else @drop="handleDrop($event, 'asset-file-zip')" @dragover="handleDragover" @dragenter="handleDragover">
                <div class="upload-button upload-zip">
                  <div class="upload-btn-content">
                    <ion-icon class="ion-icon-size" name="cloud-upload-outline"></ion-icon>
                    <div class="zip-text">ZIP (MAX 150 MB)</div>
                    <p>Drop file here to upload or</p>
                  </div>
                </div>
                <label class="file-upload-wrapper" tabindex="0" @keyup.enter="openEl('assetFileZip')" ref="assetFileLabel">
                  <input
                    id="assetFileZip"
                    data-cy="asset-file-zip"
                    ref="assetFileZip"
                    class="upload-input"
                    type="file"
                    accept="application/zip"
                    v-if="!fileUploadStatus && showZipUpload"
                    @change="handleClick($event, 'asset-file-zip')"
                  />Choose a file</label
                >
                <ion-progress-bar v-if="assetReference.name && fileUploadStatus == 'U'" color="secondary" type="indeterminate"></ion-progress-bar>
                <ion-row class="file-details add-space-div" v-if="assetReference.name">
                  <ion-col size-xs="12" class="file-meta-data">
                    <div class="section-subheading">
                      <svg-icon icon-class="icon-file" class="g-icon--small"></svg-icon><span class="asset-zip-name">{{ assetReference.name }}</span>
                    </div>
                    <div class="section-meta-info">
                      <span class="section-meta-text" v-if="fileUploadStatus == 'U'"> Uploading... </span>
                      <span class="section-meta-text" v-if="fileUploadStatus != 'U'"> {{ assetReference.size | formatSize }} </span>
                      <div class="download-button" v-if="isDraftMode && assetReference.virusScanStatus">
                        <img src="@/assets/images/svgs/icon-download.svg" alt="download-icon" v-if="isDraftMode" @click="downloadAssetZipFile()" />
                      </div>
                      <div class="download-button" v-if="!assetReference.virusScanStatus">
                        <ion-icon class="ion-icon-size" name="close-outline" @click="cancelUploadAsset"></ion-icon>
                      </div>
                      <div class="download-button">
                        <ion-icon v-if="assetReference.virusScanStatus" class="ion-icon-size" name="trash-outline" @click="deleteFiles('asset-file-zip')"></ion-icon>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
            <ion-col size-xs="12" size-md="12" class="form-col">
              <div class="section-title">
                {{ popoverContent.comment.title }}
              </div>
              <ion-textarea
                data-cy="asset-comment"
                class="ion-no-padding form-input-val"
                ref="comment"
                autofocus
                rows="5"
                required
                :placeholder="popoverContent.comment.placeholder"
                @ionInput="handleInput($event, 'comment', false)"
                :value="comment"
              ></ion-textarea>
            </ion-col>
          </ion-row>
        </div>
        <ion-row>
          <ion-col class="col-md-3">
            <ion-button
              shape="round"
              :disabled="disableSubmitButtonFlag()"
              color="primary"
              class="ion-text-capitalize ion-button-primary-dark submit-btn"
              @click="submitForm('submit')"
            >
              {{ [assetStatus.returned.value, assetStatus.inEdit.value].includes(reviewStatus.toUpperCase()) ? 'Resubmit' : 'Submit' }}
            </ion-button>
            <ion-button shape="round" :disabled="disableDraftButtonFlag()" color="tertiary" class="draft-btn" @click="submitForm('draft')"> Save as draft </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div :class="fileUploadStatus != '' ? 'show-mandatory-message-progress show-mandatory-message' : 'show-mandatory-message'">
              <div class="show-icons"><ion-icon name="information-circle-outline"></ion-icon></div>
              <div class="show-message">
                <span v-if="fileUploadStatus != ''">Saving is disabled while your file uploads.<br /></span>
                Fields marked with an asterisk (*) are required for submission.
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <div class="submission-form-content" v-if="userActivities.length > 0">
      <div class="comments-activity">
        <h2>Comments & activity</h2>
        <div class="comment-container" v-for="(activity, index) in userActivities" :key="index">
          <div class="short-name-container">{{ activity.shortName }}</div>
          <ion-row class="ion-margin-start ion-margin-bottom">
            <ion-col size-xs="12" size-md="12" class="ion-no-padding">
              <div v-if="activity.nameWithAction" class="activity-column">
                <span class="span-container">{{ activity.nameWithAction }}</span>
                <div class="date-container" v-if="activity.createdAt">{{ activity.createdAt | formatLatestDate }}</div>
              </div>
              <div v-if="activity.userName" class="activity-column">
                <span class="span-container">{{ activity.userName }}</span>
                <span class="span-container" v-if="activity.activityType">{{ activity.activityType }}</span>
                <div class="date-container" v-if="activity.createdAt">{{ activity.createdAt | formatLatestDate }}</div>
              </div>
            </ion-col>
            <ion-col size-xs="12" size-md="11" class="comment">{{ activity.comment }}</ion-col>
          </ion-row>
        </div>
      </div>
    </div>
    <div class="custom-toast custom-toast-loading" v-if="loadingMessage.length > 0">
      <div style="margin-top: 2rem">
        <ion-progress-bar color="secondary" type="indeterminate"></ion-progress-bar>
      </div>
      <div>
        <span class="status-message-normal">{{ loadingMessage }}...</span>
      </div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-info" v-if="fileUploadStatus === 'U' && uploadPercentage < 100">
      <div class="status-message">
        <span class="status-message-bold">Step 1 of 2</span><span class="status-message-normal">: File upload in progress {{ uploadPercentage }}%</span>
      </div>
      <div class="cancel-btn"><ion-button class="cancel-btn-text" shape="round" color="tertiary" size="small" @click="cancelUploadAsset()">Cancel</ion-button></div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-success" v-else-if="fileUploadStatus === 'C'">
      <div class="toast-close-icon"><svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon></div>
      <div class="toast-success-icon"><svg-icon icon-class="icon_success"></svg-icon></div>
      <div class="status-message"><span class="status-message-normal">Success! Your file has been uploaded.</span></div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-info" v-else-if="fileUploadStatus === 'S' || uploadPercentage == 100">
      <div class="status-message"><span class="status-message-bold">Step 2 of 2</span><span class="status-message-normal">: File scan in progress</span></div>
      <div class="cancel-btn"><ion-button class="cancel-btn-text" shape="round" color="tertiary" size="small" @click="cancelUploadAsset()">Cancel</ion-button></div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-failed" v-else-if="fileUploadStatus === 'F'">
      <div class="toast-close-icon"><svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon></div>
      <div class="toast-failed-icon"><svg-icon icon-class="icon-alert-black"></svg-icon></div>
      <div class="status-message"><span class="status-message-normal">Your file did not upload successfully</span></div>
      <div class="cancel-btn"><ion-button class="cancel-btn-text" shape="round" color="tertiary" size="small" @click="tryAgain()">Try again</ion-button></div>
      <div class="toast-footer"></div>
    </div>
    <AssetAlertModal
      v-if="showAlertsModal && modalMessageObj.header"
      :modalProperties="modalMessageObj"
      @closeAlertsModal="closeModal"
      @completeUpload="completeUpload"
      @ignoreUpload="ignoreUpload"
    />
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import Collapsible from '@/components/molecules/Collapsible'
import SingleSelectDropDown from '@/components/molecules/SingleSelectDropDown'
import MultiSelectDropDown from '@/components/molecules/MultiSelectDropDown'
import AssetAlertModal from '@/views/asset/components/AssetAlertModal'
import FunctionDropDown from '@/views/asset/components/FunctionDropDown'
import util from '@/utils/helper'

export default {
  name: 'AssetSubmissionForm',
  components: { Collapsible, SingleSelectDropDown, MultiSelectDropDown, AssetAlertModal, FunctionDropDown },
  props: {},
  data() {
    return {
      functionsList:[],
      functionsError: false,
      functions:[],
      tenantSettingsForAsset:'both',
      tenantSettings: {},
      showZipUpload: true,
      loadingMessage: '',
      canUploadAssets: true,
      componentActivated: false,
      showFileUploadLabel: true,
      uploadPercentage: 0,
      fileUploadStatus: '',
      axiosController: null,
      assetReference: {},
      showAssetLink: false,
      modalMessageObj: {},
      duplicateFileErrorMsg: {
        header: 'Duplicate file',
        message: '',
        btnText: 'Ok',
        type: 'error'
      },
      exceedFileLimitMsg: {
        header: 'Exceeded file limit',
        message: 'You can upload up to 10 files total. Delete any files you would like to replace.',
        btnText: 'Ok',
        type: 'error'
      },
      pdfFileExceedLimitMsg: {
        header: 'Exceeded file limit',
        message: 'You can only upload one file.',
        btnText: 'Ok',
        type: 'error'
      },
      submitSuccessMsg: {
        header: 'Success!',
        message: `Thanks for submitting your asset. We’ll pass it along to your admin for review and notify you by email of any updates`,
        btnText: 'Ok',
        type: 'success'
      },
      zipFileExceedError: {
        header: 'Exceeded file limit',
        message: 'You can upload only max 150 MB size file',
        btnText: 'Ok',
        type: 'error'
      },
      duplicateAssetMsg: {
        header: 'Duplicate asset',
        message: '',
        btnText: 'Ok',
        type: 'error'
      },
      fileUploadProgressMsg: {
        header: 'File upload in progress',
        message: 'Leaving the page now will cancel any incomplete file uploads.',
        primaryButton: 'Complete upload',
        secondaryButton: 'Ignore upload',
        type: 'error'
      },
      showAlertsModal: true,
      showErrorMessage: false,
      errorMessage: '',
      expanded: false,
      pdfFileSize: 0,
      ImgFileSize: 0,
      lastSaved: '',
      displayId: '',
      uploadPdfFilesClicked: false,
      uploadScreenshotsClicked: false,
      governanceId: 0,
      isDraftMode: false,
      authToken: '',
      assetName: '',
      assetNameError: false,
      promoHeader: '',
      promoHeaderError: false,
      promoDescription: '',
      promoDescriptionError: false,
      disableSubmitBtn: true,
      disableDraftBtn: true,
      fullDescription: '',
      inventoryType: '',
      inventoryTypeClicked: false,
      inventoryTypeError: false,
      externalAssetLink: '',
      externalAssetLinkError: false,
      applications: [],
      applicationsClicked: false,
      applicationsError: false,
      uploadScreenshots: [],
      uploadPdfFiles: [],
      comment: '',
      userActivities: [],
      reviewStatus: '',
      toolsList: [],
      inventoryTypeList: [],
      resetPdfEl: false,
      resetImgEl: false,
      toPath: '',
      requiredFields: ['assetName', 'promoHeader', 'promoDescription', 'inventoryType', 'applications','functions'],
      assetStatus: {
        returned: { value: 'RETURNED', message: 'Returned' },
        inEdit: { value: 'IN EDIT', message: 'Draft' }
      },
      popoverContent: {
        assetName: {
          title: 'Asset name',
          placeholder: 'Name your asset',
          tip: 'To improve scannability and best convey what your asset does, name your asset according to the task it performs, start with a verb, and limit to 40 characters (e.g., Convert Excel to PDF or Consolidate Vendor Transactions for Renewal Review).',
          error: 'Asset name cannot be empty'
        },
        promoHeader: {
          title: 'Header',
          placeholder: "State your asset's key benefit",
          tip: "This will be the header used to promote your asset. Use it to summarize the asset's main benefit. Try to stick to a single sentence — you will get to elaborate in the next section.",
          error: 'Header cannot be empty'
        },
        promoDescription: {
          title: 'Description',
          placeholder: 'Tip: Use the Return / Enter key to create a bulleted list.',
          tip: 'Briefly describe what the asset does, focusing on inputs, outputs, and the benefits you get from using the asset. Each new line will be formatted as a bullet point.',
          error: 'Description cannot be empty'
        },
        inventoryType: {
          title: 'Asset type',
          placeholder: 'Select one',
          tip: '',
          error: 'Asset type cannot be empty'
        },
        applications: {
          title: 'Technology / tools used',
          placeholder: 'Select all that apply',
          tip: '',
          error: 'Technology or tools cannot be empty'
        },
        functions: {
          title: 'Function',
          placeholder: 'Select all that apply',
          tip: '',
          error: 'Function cannot be empty'
        },
        externalAssetLink: {
          title: 'Asset link',
          placeholder: 'Provide a link to your asset',
          tip: '',
          error: 'Asset link cannot be empty'
        },
        comment: {
          title: 'Add a comment',
          placeholder: 'Any additional information you would like to share with those who will be reviewing your asset submission (optional)',
          tip: '',
          error: ''
        },
        fullDescription: {
          title: 'How it works',
          placeholder: '(Optional) Tip: Use the Return / Enter key to create a bulleted list.',
          tip: 'In one or two sentences or in a list, briefly describe the basic steps or how the asset works. Each new line will be formatted as a bullet point.',
          error: ''
        },
        imageOrVideo: {
          title: 'Upload screenshots (optional)',
          placeholder: 'Drop files to upload (png)',
          tip: 'You can upload up to 10 screenshots of your asset. Ideal dimensions are 1280 px wide x 720 px high (16:9 aspect ratio). If the asset interface is smaller or uses different proportions, capture its actual size and do not distort or scale it up. If needed, crop the screenshot image so that the edges line up as cleanly as possible with the edges of the asset interface.',
          error: '',
          fileSizeError: ' Max upload size is 250MB'
        },
        uploadDocumentation: {
          title: 'Upload documentation / user guide (optional)',
          placeholder: 'Drop file to upload (pdf)',
          tip: 'The more documentation you can provide about how to use your asset, the more likely it is that others are able to use the asset without additional assistance.',
          error: '',
          fileSizeError: ' Max upload size is 250MB'
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    const path = ['CreateAsset', 'EditAsset'].some((item) => {
      return to.name.includes(item)
    })
    if (['U', 'S'].includes(this.fileUploadStatus) && !path) {
      this.toPath = to.path
      this.showAlertsModal = true
      this.modalMessageObj = this.fileUploadProgressMsg
      next(false)
    } else {
      this.showAlertsModal = false
      this.modalMessageObj = {}
      next()
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  activated() {
    this.loadDetails()
    this.checkAssetUploadAccess()
  },
  deactivated() {
    this.componentActivated = false
    this.toPath = ''
    this.resetModel()
  },
  updated() {},
  filters: {
    formatDate(d) {
      const date = util.formatDateWithTime(d)
      return date.toUpperCase()
    },
    formatSize(size) {
      if (!size) return '0 bytes'
      else if (size < 1024) return `${size} bytes`
      else if (size >= 1024 && size < Math.pow(1024, 2)) return `${parseInt(size / 1024).toLocaleString()} KB`
      else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) return `${parseInt(size / Math.pow(1024, 2)).toLocaleString()} MB`
      else return `${parseInt(size / Math.pow(1024, 3)).toLocaleString()} GB`
    },
    formatLatestDate(createdAt) {
      const timeOption = { hour: 'numeric', minute: 'numeric' }
      const date = new Date(createdAt)
      var time = date.toLocaleTimeString('en-US', timeOption)
      const duration = new Date() - date
      const days = Math.ceil(duration / (1000 * 60 * 60 * 24))
      if (days == 1) return `Today at ${time}`
      else if (days == 2) return `Yesterday at ${time}`
      else if (days > 2 && days < 7) return `${days} days ago at ${time}`
      else return util.formatDateWithTime(createdAt)
    }
  },
  methods: {
    async checkAssetUploadAccess() {
      this.authToken = await localforage.getItem('my_access_token')
      if (Object.keys(this.tenantSettings).length == 0) {
        const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
        this.tenantSettings = JSON.parse(tenant.settings)
        this.tenantSettingsForAsset = {}.hasOwnProperty.call(this.tenantSettings, 'apply-asset-file-upload-access') ? this.tenantSettings['apply-asset-file-upload-access'] : 'both'
        if(['zip','both'].includes(this.tenantSettingsForAsset)) {
          this.canUploadAssets = true;
          this.requiredFields = this.requiredFields.filter((e)=> e != 'externalAssetLink');
        }
        else {
          this.canUploadAssets = false;
          this.requiredFields.push('externalAssetLink')
        }
      }
    },
    selectAssetTab(flag, type) {
      this.canUploadAssets = flag;
      if(type == 'zip') {
        this.externalAssetLinkError = false;
        this.requiredFields = this.requiredFields.filter((e)=> e != 'externalAssetLink');
      }
      else
        this.requiredFields.push('externalAssetLink')
      this.disableSubmitBtn = !this.requiredFields.every((el) => this[el].length > 0 && this[`${el}Error`] === false)
    },
    hideToastMessage() {
      setTimeout(() => {
        this.fileUploadStatus = ''
      }, 5000)
    },
    openEl(id) {
      if (this.$refs[id]) this.$refs[id].click()
    },
    disableDraftButtonFlag() {
      let disable = false
      disable = this.loadingMessage.length > 0 || this.assetName.length === 0 || this.assetNameError || this.assetNameError || ['U', 'S', 'F'].includes(this.fileUploadStatus)
      return disable
    },
    disableSubmitButtonFlag() {
      let disable = false
      if (this.canUploadAssets)
        disable =
          Object.keys(this.assetReference).length == '0' ||
          this.loadingMessage.length > 0 ||
          this.disableSubmitBtn ||
          this.assetNameError ||
          ['U', 'S', 'F'].includes(this.fileUploadStatus)
      else disable = this.loadingMessage.length > 0 || this.disableSubmitBtn || this.assetNameError || ['U', 'S', 'F'].includes(this.fileUploadStatus)
      return disable
    },
    goBack() {
      let navigateNext = true
      if (['U', 'S'].includes(this.fileUploadStatus)) {
        navigateNext = false
        this.showAlertsModal = true
        this.modalMessageObj = this.fileUploadProgressMsg
      } else {
        if (this.loadingMessage.length > 1) return true
        if (this.governanceId) this.$router.go('-1')
        else navigateNext ? this.$router.push('/submit-asset') : ''
      }
    },
    completeUpload() {
      this.showAlertsModal = false
      this.modalMessageObj = {}
    },
    ignoreUpload(tabNavigation) {
      this.axiosController.abort()
      this.fileUploadStatus = ''
      this.showAlertsModal = false
      this.modalMessageObj = {}
      typeof tabNavigation === 'boolean' && tabNavigation && this.toPath ? this.$router.push({ path: this.toPath }) : this.$router.push('/submit-asset')
    },
    tryAgain() {
      this.fileUploadStatus = ''
    },
    cancelUploadAsset() {
      this.axiosController.abort()
      this.fileUploadStatus = 'F'
      this.assetReference = {}
    },
    onUploadProgress(progressEvent) {
      const { loaded, total } = progressEvent
      this.uploadPercentage = Math.floor((loaded * 100) / total)
    },
    async uploadAsset(file) {
      this.fileUploadStatus = 'U'
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      this.axiosController = new AbortController()
      const formData = new FormData()
      formData.set('governanceItemName', this.assetName ? this.assetName : 'New Asset')
      if (this.governanceId != 0) formData.set('governanceItemId', this.governanceId)      
      formData.append('assetFile', file)
      const payload = {
        token: this.authToken,
        body: formData
      }
      this.$learnAdmin
        .uploadAssets(payload, this.axiosController, this.onUploadProgress)
        .then((res) => {
          if (res.data) {
            if (this.governanceId === 0) this.governanceId = res.data.id
            this.assetReference = res.data.assetReference || {}
            this.fileUploadStatus = 'C'
            this.showAlertsModal = false
          }
          this.uploadPercentage = 0
          this.hideToastMessage()
        })
        .catch(() => {
          this.fileUploadStatus = 'F'
          this.uploadPercentage = 0
          this.hideToastMessage()
          this.assetReference = {}
        })
    },
    closeModal() {
      this.showAlertsModal = false
      if (this.modalMessageObj.type === 'success') this.$router.push('/submit-asset')
    },
    showToast(msg, className) {
      this.$toasted.show(msg, {
        containerClass: 'toast-container',
        className: className,
        position: 'bottom-right',
        iconPack: 'icon-container',
        duration: 5000,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    getTools(value) {
      this.applications = value
      this.applicationsError = this.applications.length == 0 ? true : false
      this.disableSubmitBtn = !this.requiredFields.every((el) => this[el].length > 0 && this[`${el}Error`] === false)
    },
    getFunctions(value) {
      this.functions = value
      this.functionsError = this.functions.length == 0 ? true : false
      this.disableSubmitBtn = !this.requiredFields.every((el) => this[el].length > 0 && this[`${el}Error`] === false)
    },
    getInventory(value) {
      this.inventoryType = value
      this.inventoryTypeError = this.inventoryType.length == 0 ? true : false
      this.disableSubmitBtn = !this.requiredFields.every((el) => this[el].length > 0 && this[`${el}Error`] === false)
    },
    formatSizeFun(size) {
      if (!size) return '0 bytes'
      else if (size < 1024) return `${size} bytes`
      else if (size >= 1024 && size < Math.pow(1024, 2)) return `${parseInt(size / 1024).toLocaleString()} KB`
      else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) return `${parseInt(size / Math.pow(1024, 2)).toLocaleString()} MB`
      else return `${parseInt(size / Math.pow(1024, 3)).toLocaleString()} GB`
    },
    async loadDetails() {
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      this.toolsList = await this.$learnAdmin.getGovernanceTools({ token: this.authToken })
      this.inventoryTypeList = await this.$learnAdmin.getAssetInventoryTypes({ token: this.authToken })
      this.functionsList = await this.$learnAdmin.getGovernanceFunctions({ token: this.authToken })
      if (this.$route.params.editableAsset) {
        this.formInEditMode(this.$route.params.editableAsset)
      } else {
        this.componentActivated = true;
      }
      const payload = await this.onPrepareGAPayload('Asset submission form loaded')
      await this.GAEventTrack(payload)
      
    },
    async formInEditMode(assetObj) {
      this.isDraftMode = true
      this.governanceId = assetObj.id
      this.assetName = assetObj.name || ''
      this.lastSaved = assetObj.updatedAt
      this.displayId = assetObj.displayId || ''
      this.applications = assetObj.applications || []
      this.functions = assetObj.functions || []
      this.assetReference = assetObj.assetReference || {}
      this.externalAssetLink = assetObj.externalAssetLink || ''
      this.fullDescription = assetObj.fullDescription || ''
      this.promoDescription = assetObj.promoDescription || ''
      this.promoHeader = assetObj.promoHeader || ''
      this.inventoryType = assetObj.inventoryType || ''
      this.comment = assetObj.reviewStatus.toUpperCase() == this.assetStatus.returned.value ? '' : assetObj.comment
      if ([this.assetStatus.returned.value, this.assetStatus.inEdit.value].includes(assetObj.reviewStatus.toUpperCase())) {
        this.userActivities = {}.hasOwnProperty.call(assetObj, 'commentActivities') && assetObj.commentActivities.length ? assetObj.commentActivities : []
        this.submitSuccessMsg.message = 'Thanks for resubmitting your asset. We’ll pass it along to your admin for review and notify you by email of any updates.'
      }
      this.mapImagesAndVideos(assetObj.galleryImages)
      this.mapPdfFiles(assetObj.supportingFile)
      this.disableSubmitBtn = !this.requiredFields.every((el) => this[el] && this[el].length > 0 && this[`${el}Error`] === false)
      this.componentActivated = true;
      if(this.tenantSettingsForAsset == 'both') {
        if(this.externalAssetLink.length > 0){
          this.selectAssetTab(false,'link')
        } else if(Object.keys(this.assetReference).length > 0) {
          this.selectAssetTab(true,'zip')
        }
      }
    },
    mapImagesAndVideos(galleryImages) {
      if (galleryImages && galleryImages.length) {
        this.uploadScreenshots = galleryImages.map((img) => {
          return {
            name: img.caption,
            file: img,
            size: img.size,
            dateAdded: img.dateAdded,
            signedUrl: img.signedUrl
          }
        })
      }
    },
    mapPdfFiles(pdfFile) {
      if (pdfFile && pdfFile != null) {
        this.uploadPdfFiles = [
          {
            name: pdfFile.name,
            file: pdfFile,
            size: pdfFile.size,
            dateAdded: pdfFile.dateAdded,
            signedUrl: pdfFile.signedUrl
          }
        ]
      }
    },
    async upload(rawFile, type) {
      const file = {
        name: rawFile.name,
        file: rawFile,
        size: rawFile.size
      }
      let fileSize = Math.floor(rawFile.size / (1024 * 1024), 2)
      if (type == 'asset-file-zip' && rawFile.type.includes('zip') && this.fileUploadStatus == '') {
        if (Object.keys(this.assetReference).length > 0) {
          this.showAlertsModal = true
          this.modalMessageObj = this.pdfFileExceedLimitMsg
        } else if (fileSize > 150) {
          this.showAlertsModal = true
          this.modalMessageObj = this.zipFileExceedError
        } else {
          this.assetReference = rawFile
          this.uploadAsset(rawFile)
        }
      } else if (type == 'upload-documentation-input' && rawFile.type == 'application/pdf') {
        this.uploadPdfFilesClicked = true
        if (fileSize < 250) {
          if (this.uploadPdfFiles.length === 0) {
            this.uploadPdfFiles = [file]
            const payload = await this.onPrepareGAPayload(`How-to guides ${file.name} uploaded`)
            await this.GAEventTrack(payload)
          } else {
            this.showAlertsModal = true
            this.modalMessageObj = this.pdfFileExceedLimitMsg
          }
        }
      } else if (type == 'upload-support-input' && ['image/png'].includes(rawFile.type)) {
        if (this.uploadScreenshots.some((e) => e.name === rawFile.name)) {
          this.showAlertsModal = true
          this.duplicateFileErrorMsg.message = `A file with the name ${rawFile.name} already exists. Delete the duplicate to replace it.`
          this.modalMessageObj = this.duplicateFileErrorMsg
        } else if (this.uploadScreenshots.length >= 10) {
          this.showAlertsModal = true
          this.modalMessageObj = this.exceedFileLimitMsg
        } else {
          this.uploadScreenshotsClicked = true
          let totalFileSize = this.uploadScreenshots.reduce((sum, obj) => sum + obj.size, fileSize)
          totalFileSize = totalFileSize / (1024 * 1024)
          if (totalFileSize < 250 && this.uploadScreenshots.length < 10) {
            this.uploadScreenshots.push(file)
            const payload = await this.onPrepareGAPayload(`Screenshot ${file.name} uploaded`)
            await this.GAEventTrack(payload)
          }
        }
      }
    },
    deleteFiles(type, id) {
      if (this.loadingMessage.length > 0) {
        return true
      }
      if (type == 'asset-file-zip') {
        const payload = {
          token: this.authToken,
          id: this.governanceId
        }
        this.showZipUpload = false
        this.$learnAdmin
          .deleteAssetFile(payload)
          .then(() => {
            this.assetReference = {}
            this.showZipUpload = true
          })
          .catch(() => {
            this.showZipUpload = true
            this.showToast('There was a problem deleting your asset. You can try again later', 'error')
          })
      } else if (type == 'upload-documentation-input') {
        this.resetPdfEl = true
        if (this.uploadPdfFiles[id].signedUrl) {
          const payload = {
            token: this.authToken,
            assetId: this.governanceId,
            imagePointer: this.uploadPdfFiles[id].file.pointer
          }
          this.$learnAdmin.deletePdfFromCreateAsset(payload).then((res) => {
            if (res.status == 200) {
              this.showToast('File deleted successfully', 'success')
              this.uploadPdfFiles = this.uploadPdfFiles.filter((el, index) => index !== id)
              setTimeout(() => {
                this.resetPdfEl = false
              }, 500)
            } else {
              this.showToast('There was a problem deleting your asset. You can try again later', 'error')
            }
          })
        } else {
          this.uploadPdfFiles = this.uploadPdfFiles.filter((el, index) => index !== id)
          setTimeout(() => {
            this.resetPdfEl = false
          }, 500)
        }
      } else {
        this.resetImgEl = true
        if (this.uploadScreenshots[id].signedUrl) {
          const payload = {
            token: this.authToken,
            assetId: this.governanceId,
            imagePointer: this.uploadScreenshots[id].file.pointer
          }
          this.$learnAdmin.deleteImageFromCreateAsset(payload).then(() => {
            this.uploadScreenshots = this.uploadScreenshots.filter((el, index) => index !== id)
            setTimeout(() => {
              this.resetImgEl = false
            }, 500)
          })
        } else {
          this.uploadScreenshots = this.uploadScreenshots.filter((el, index) => index !== id)
          setTimeout(() => {
            this.resetImgEl = false
          }, 500)
        }
      }
    },
    deletePdfDocument() {
      this.uploadPdfFiles = []
    },
    handleClick(event, type) {
      const files = event.target.files
      if (!files.length) return
      files.forEach((file) => {
        this.upload(file, type)
      })
    },
    handleDrop(event, type) {
      event.stopPropagation()
      event.preventDefault()
      const files = event.dataTransfer.files
      files.forEach((file) => {
        this.upload(file, type)
      })
      event.stopPropagation()
      event.preventDefault()
    },
    handleDragover(event) {
      event.stopPropagation()
      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy'
    },
    async submitForm(type = 'draft') {
      if ((this.disableDraftButtonFlag() && type == 'draft') || (type == 'submit' && this.disableSubmitButtonFlag())) {
        return true
      }
      this.fileUploadStatus = ''
      const obj = {
        name: this.assetName,
        promoHeader: this.promoHeader,
        promoDescription: this.promoDescription,
        fullDescription: this.fullDescription,
        applications: this.applications,
        inventoryType: this.inventoryType,
        externalAssetLink: this.externalAssetLink,
        comment: this.comment,
        inventoryCategory: 'asset',
        userAction: type,
        functions:this.functions
      }
      if (type == 'draft') this.loadingMessage = 'Saving Draft'
      else {
        this.loadingMessage = 'Submitting Asset'
      }
      if (this.governanceId !== 0) obj.id = this.governanceId
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const formData = new FormData()
      formData.set('governanceItem', JSON.stringify(obj))
      if (this.uploadPdfFiles.length > 0 && this.uploadPdfFilesClicked) {
        formData.append('howToGuideFile', this.uploadPdfFiles[0].file)
      }
      if (this.uploadScreenshotsClicked) {
        this.uploadScreenshots.forEach((el) => {
          formData.append('mediaFiles', el.file)
        })
      }
      const payload = {
        token: this.authToken,
        body: formData
      }
      this.$learnAdmin
        .submitAsset(payload)
        .then((res) => {
          this.loadingMessage = ''
          if (type == 'submit' && res.data.status == 400 && res.data.message == 'Invalid input Item with same set of name and applications already exist') {
            this.showAlertsModal = true
            this.duplicateAssetMsg.message = `A ${this.applications.join(', ')} asset with the name "${
              this.assetName
            }" already exists. Update the asset name or technology selection and submit again`
            this.modalMessageObj = this.duplicateAssetMsg
          } else if (res.data.status == 400) {
            this.showToast('There was a problem saving your asset. You can try again later or contact support for more assistance.', 'error')
          } else {
            this.resetModel()
            if (type == 'submit') {
              this.showAlertsModal = true
              this.modalMessageObj = this.submitSuccessMsg
            } else {
              this.$router.push('/submit-asset')
              this.showToast('Asset successfully saved. You can still make edits before submitting it.', 'success')
            }
          }
        })
        .catch(() => {
          this.loadingMessage = ''
          this.showToast('There was a problem saving your asset. You can try again later or contact support for more assistance.', 'error')
        })
      const GAPayload = await this.onPrepareGAPayload(type == 'draft' ? 'Save as draft clicked' : 'Submit clicked')
      await this.GAEventTrack(GAPayload)
    },
    handleInput(event, field, required) {
      const fieldValue = event.target.value.trim()
      let errorMsg
      let errorExists
      switch (field) {
        case 'assetName':
          this.assetName = fieldValue
          errorMsg = 'Asset name cannot be empty'
          errorExists = /[^\w\d\s]/.test(fieldValue)
          if (errorExists && this.assetName.length !== 0) errorMsg = 'Asset name can not have special characters'
          this.popoverContent.assetName.error = errorMsg
          this.assetNameError = errorExists || this.assetName.length == 0 ? true : false
          break
        case 'promoHeader':
          this.promoHeader = fieldValue
          this.promoHeaderError = this.promoHeader.length == 0 && required ? true : false
          break
        case 'promoDescription':
          this.promoDescription = fieldValue
          this.promoDescriptionError = this.promoDescription.length == 0 && required ? true : false
          break
        case 'externalAssetLink':
          errorExists = new RegExp(/^((https|http):\/\/)+[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi).test(fieldValue)
          if (errorExists) errorExists = !new RegExp(/[\n]/).test(fieldValue)
          this.externalAssetLink = fieldValue
          errorMsg = 'Asset link cannot be empty'
          if (!errorExists && this.externalAssetLink.length !== 0) errorMsg = fieldValue.indexOf("http://") == 0 || fieldValue.indexOf("https://") == 0 ? `Please provide a valid path for the asset file` : `Please enter a valid URL (should begin with https:// or http://)`
          this.popoverContent.externalAssetLink.error = errorMsg
          this.externalAssetLinkError = !errorExists || (this.externalAssetLink.length == 0 && required) ? true : false
          break
        case 'fullDescription':
          this.fullDescription = fieldValue
          break
        case 'comment':
          this.comment = fieldValue
          break
        default:
          break
      }
      this.disableSubmitBtn = !this.requiredFields.every((el) => this[el].length > 0 && this[`${el}Error`] === false)
    },
    resetModel() {
      this.functionsError = false;
      this.functions = [];
      this.tenantSettings = {}
      this.fileUploadStatus = ''
      this.assetReference = {}
      this.assetName = ''
      this.assetNameError = false
      this.promoHeader = ''
      this.promoHeaderError = false
      this.promoDescription = ''
      this.promoDescriptionError = false
      this.fullDescription = ''
      this.inventoryType = ''
      this.externalAssetLink = ''
      this.externalAssetLinkError = false
      this.applications = []
      this.tools = []
      this.isDraftMode = false
      this.comment = ''
      this.userActivities = []
      this.disableSubmitBtn = true
      this.inventoryTypeClicked = false
      this.applicationsClicked = false
      this.uploadPdfFilesClicked = false
      this.uploadScreenshotsClicked = false
      this.lastSaved = ''
      this.displayId = ''
      this.uploadPdfFiles = []
      this.uploadScreenshots = []
      this.pdfFileSize = 0
      this.imgFileSize = 0
      this.inventoryTypeList = []
      this.toolsList = []
      this.showAlertsModal = false
      this.modalMessageObj = {}
      this.showErrorMessage = false
      this.errorMessage = ''
      this.governanceId = 0
      this.showZipUpload = true
      this.loadingMessage = ''
    },
    async onDownload(imageFile) {
      const data = await fetch(imageFile.signedUrl)
      const blobData = await data.blob()
      this.saveAs(blobData, imageFile.name)
    },
    async downloadAssetZipFile() {
      if (this.loadingMessage.length == 0) {
        this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
        const payload = {
          id: this.governanceId,
          token: this.authToken
        }
        this.loadingMessage = 'Download is in progress'
        const file = await this.$learnAdmin.downloadAssetZipFile(payload)
        this.loadingMessage = ''
        if (file.data) {
          this.saveAs(file.data, this.assetReference.name)
        }
      }
    },
    saveAs(blob, fileName) {
      const url = window.URL.createObjectURL(blob)
      const anchorElem = document.createElement('a')
      anchorElem.style = 'display:none'
      anchorElem.href = url
      anchorElem.download = fileName
      document.body.appendChild(anchorElem)
      anchorElem.click()
      document.body.removeChild(anchorElem)
      setTimeout(function () {
        window.URL.revokeObjectURL(url)
      }, 1000)
    },
    async onPrepareGAPayload(action) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        asset: {
          id: '',
          name: this.assetName,
          author: '',
          technology: this.applications,
          type: this.inventoryType
        }
      }
      const payload = {
        action: action,
        category: 'Asset Submission Form',
        value: JSON.stringify(item)
      }
      return payload
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
ion-textarea.form-input-val {
  .textarea-wrapper {
    font: inherit;
  }
}

.last-saved {
  span {
    font-weight: bold;
    font-family: 'Arial' !important;
    font-size: 0.75rem;
    letter-spacing: 1.2px;
    color: #000000;
  }
  .divider {
    border: 1px solid #e0e1e1;
    margin: 4px;
    height: 8px;
  }
}

.cancel-pdf {
  cursor: pointer;
}

.tip-content {
  h2 {
    color: var(--ion-color-tertiary) !important;
    font-weight: bold;
  }
  .section {
    background: #efefef;
    p {
      color: #5c5c5c;
      font: normal normal normal 16px/22px Arial;
      letter-spacing: 0px;
    }
  }
}
</style>

<style lang="scss" scoped>
.heading-title {
  color: #000000;
  font: normal normal normal 1.5rem/1.875rem Futura PT Demi;
}
.asset-name-in {
  font: normal normal normal 18px/22px Arial;
  letter-spacing: 0.01px;
}
.form-input-val {
  font: normal normal normal 1rem/1.375rem Arial;
  letter-spacing: 0.01px;
}
.dot {
  font-family: 'Futura PT Demi';
  height: 1.5rem;
  width: 1.5rem;
  background-color: #dde0e9;
  border-radius: 50%;
  display: inline-block;
  padding: 0.1rem 0.35rem 0 0.3rem;
  text-align: center;
  margin-right: 0.6rem;
}
.submission-form-header {
  margin-top: 4rem;
  .mandatory-text {
    font-family: Arial;
  }
}
.submission-form-content {
  width: 85%;
  background-color: var(--ion-color-white);
  border-radius: 20px;
  margin: 2rem auto 0 auto;
  padding: 1.5rem 10rem;
  text-align: left;
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
  h2 {
    color: var(--ion-color-tertiary);
    font-size: 2rem;
  }
  h4 {
    font-weight: bold;
    span {
      background: #efefef;
      border-radius: 50%;
      padding: 3px;
      margin-right: 0.7rem;
    }
  }
  .asset-description-form,
  .technical-details-form,
  .asset-link-form {
    ion-col {
      margin-bottom: 2rem;
    }
    .section-title {
      font-weight: bold;
      font-family: Arial;
      font-size: 14px;
    }
    .error-input ion-input {
      border-bottom: 1px solid var(--ion-color-status);
      margin-bottom: 0;
    }
    .error-input ion-textarea,
    .error-input ion-select {
      border: 1px solid var(--ion-color-status);
      margin-bottom: 0;
    }
    ion-input {
      border-bottom: 1px solid #efefef;
      margin-bottom: 1rem;
      padding: 0.4rem;
      :focus {
        border-bottom: 1px solid var(--ion-color-focus);
      }
    }
    ion-textarea {
      border: 1px solid #efefef;
      margin-bottom: 1rem;
      padding: 0.4rem;
      &:focus {
        border: 1px solid var(--ion-color-focus) !important;
      }
    }
    ion-select {
      border: 1px solid #efefef;
      padding: 2px;
      margin-top: 8px;
      padding: 0.4rem;
    }
    .error-container {
      color: var(--ion-color-status);
      margin-bottom: 1rem;
      font: normal normal normal 0.75rem/1.125rem Arial;
    }
    .upload-button {
      height: 4rem;
      min-width: 14rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      .upload-btn-content {
        color: black;
        text-align: center;
        p {
          margin: 1rem 0 0 0;
          font-family: Arial;
          font-size: 0.9rem;
          color: #5c5c5c;
        }
        .zip-text {
          font: normal normal bold 0.75rem/1.375rem Arial;
        }
      }
      &.upload-zip {
        height: 5.3rem !important;
      }
    }
    .file-upload-container {
      border: 1px dashed #efefef;
      padding: 1.5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem 0 1rem 0;
      .file-details {
        width: 100%;
        .file-meta-data {
          background-color: #efefef80;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.4rem;
          padding: 0.5rem;
          .section-subheading {
            color: #000000;
            display: flex;
            align-items: center;
            font: normal normal normal 1rem/1.25rem Arial;
            h5 {
              font: normal normal normal 1rem/1.25rem Arial;
              margin: 0 0 0 0.2rem;
            }
          }
          .section-meta-info {
            display: flex;
            align-items: center;
            .section-meta-text {
              color: #5c5c5c;
              font-size: 0.75rem;
            }
            .cancel-button,
            .download-button {
              display: flex;
              align-items: center;
              margin-left: 0.75rem;
              cursor: pointer;
            }
          }
        }
      }
      .file-upload-wrapper {
        color: var(--ion-color-tertiary);
        cursor: pointer;
        font-weight: bold;
        margin-bottom: 1rem;
        input {
          display: none;
        }
      }
    }
    .tip-content {
      background: #efefef;
      padding: 1px 1rem;
    }
  }
  .submit-btn {
    margin-right: 1rem;
    font-family: 'Futura PT Demi';
    --box-shadow: none;
  }
  .draft-btn {
    text-transform: none;
    font-family: 'Futura PT Demi';
    --box-shadow: none;
  }
  .comments-activity {
    padding: 4rem 0rem;
    @media screen and (max-width: 768px) {
      padding: 2rem;
    }
    .comment-container {
      display: flex;
      justify-content: flex-start;
      .comment {
        font-family: 'Arial';
        font-size: 0.875rem;
      }
    }
    h2 {
      color: #1f3370;
      font-size: 1.5rem;
      margin-bottom: 3rem;
      @media screen and (max-width: 768px) {
        font-size: 1.25rem;
      }
    }
    .span-container {
      font-family: 'Arial';
      font-size: 16px;
      font-weight: bold;
      word-spacing: 5px;
      margin-right: 10px;
    }
    .date-container {
      color: var(--ion-color-medium);
      font-size: 12px;
      padding-top: 3px;
      font-family: 'Arial';
    }
    .short-name-container {
      background: #efefef;
      border-radius: 50%;
      padding: 9px 5px;
      margin-right: 5px;
      font-size: 0.875rem;
      text-align: center;
      height: 2.1rem;
      width: 2.1rem;
      min-width: 2.1rem;
      text-transform: uppercase;
    }
    .activity-column {
      padding-top: 5px;
      display: flex;
      justify-content: flex-start;
    }
  }
}
.submission-details-container {
  margin: 1.5rem 3rem;
}
.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 1%;
  font-family: 'Arial';
  color: #5c5c5c;
  display: block;
  padding: 0;
  svg {
    position: relative;
    top: 5px;
  }
}

.icon-container {
  display: inline-grid;
  margin-top: 4rem;
  margin-right: -6rem;
  float: right;
  @media screen and (max-width: 768px) {
    display: block;
    justify-content: center;
    margin-bottom: 1rem;
    float: unset;
  }
  .action-icon {
    color: unset;
    text-decoration: unset;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid var(--ion-color-dark-grey);
    margin-bottom: 0.8rem;
    @media screen and (max-width: 768px) {
      margin-right: 1rem;
    }
  }
  .svg-disabled {
    opacity: 0.5;
    cursor: auto;
    color: unset;
    text-decoration: unset;
    padding: 4px 8px;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid var(--ion-color-dark-grey);
    margin-bottom: 0.8rem;
    @media screen and (max-width: 768px) {
      margin-right: 1rem;
    }
  }
  .action-icon:hover .tooltiptext {
    visibility: visible;
  }
  .action-icon:hover {
    background: #efefef 0% 0% no-repeat padding-box;
  }
}

.save-tooltip {
  position: relative;
  display: inline-block;
}

.save-tooltip .save-tooltiptext {
  visibility: hidden;
  width: 120px;
  text-align: center;
  padding: 5px 0;
  top: 0.5rem;
  right: 105%;
  position: absolute;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 2px 6px #0000001f;
  border-radius: 4px;
  opacity: 1;
  font-size: 0.88rem;
  font-family: Arial;
}

.save-tooltip:hover .save-tooltiptext {
  visibility: visible;
}

.submit-tooltip {
  position: relative;
  display: inline-block;
}

.submit-tooltip .submit-tooltiptext {
  visibility: hidden;
  width: 120px;
  text-align: center;
  padding: 5px 0;
  top: 6px;
  right: 105%;
  position: absolute;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 2px 6px #0000001f;
  border-radius: 4px;
  opacity: 1;
  font-size: 0.88rem;
  font-family: Arial;
}

.submit-tooltip:hover .submit-tooltiptext {
  visibility: visible;
}

.error-alert {
  width: 332px;
  height: 104px;
  background: #fae5dc 0% 0% no-repeat padding-box;
  // box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  border-radius: 3%;
  right: 2px;
  padding-top: 3rem;
}

.ion-icon-size {
  font-size: 24px;
  cursor: pointer;
}

.custom-toast {
  width: 26.25rem;
  height: 8.5rem;
  // box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  .toast-footer {
    height: 0.5rem;
    /* UI Properties */
    // box-shadow: 0px 0.3125rem 0.625rem #0000003d;
    // width: 100%;
    position: fixed;
    bottom: 0px;
    width: 26.25rem;
  }
  .cancel-btn {
    border: 1p solid red;
    width: 3.625rem;
    height: 1.68rem;
    margin-left: 5.43rem;
    margin-top: 1.05rem;
    .cancel-btn-text {
      font: normal normal normal 0.75rem Futura PT Demi !important;
      text-transform: none !important;
    }
  }
  .status-message {
    margin-top: 36px !important;
    margin-left: 5.43rem !important;
    text-align: left;
    .status-message-bold {
      font: normal normal bold 16px/22px Arial !important;
      color: #000000;
    }
    .status-message-normal {
      font: normal normal normal 16px/22px Arial !important;
      color: #000000;
    }
  }
  .toast-close-icon {
    height: 1.5rem;
    width: 1.5rem;
    float: right;
    margin: 1rem 1rem 0 0;
    cursor: pointer;
    svg {
      width: 1.25rem !important;
      height: 1.25rem !important;
    }
  }
}
.custom-toast-success {
  background: #d9f8f3 0% 0% no-repeat padding-box;
  width: 26.25rem;
  height: 6.5rem;
  .toast-footer {
    background: #00d1b0 0% 0% no-repeat padding-box;
  }
  .toast-success-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
.custom-toast-info {
  background: #fff4df 0% 0% no-repeat padding-box;
  .toast-footer {
    background: #ffb92e 0% 0% no-repeat padding-box;
  }
}
.custom-toast-failed {
  background: #fae5dc 0% 0% no-repeat padding-box;
  // box-shadow: #0000003d;
  .toast-footer {
    background: #d04910 0% 0% no-repeat padding-box;
  }
  .toast-failed-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
.add-space-div {
  margin-top: 0.4rem;
  height: 56px;
}
.asset-zip-name {
  padding-left: 0.5rem;
  font: normal normal normal 1rem/1.25rem Arial;
}

.show-mandatory-message {
  height: 4rem;
  background: #fff4df 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  .show-icons {
    padding-top: 1.5rem;
    margin-left: 1.5rem;
  }
  .show-message {
    padding-top: 1.5rem;
    padding-left: 0.3rem;
    font-family: 'Arial' !important;
    font-size: 14px !important;
    color: #000000;
  }
  .show-message {
    span {
      font-family: 'Arial' !important;
      font-size: 14px !important;
      color: #000000;
    }
  }
}
.show-mandatory-message-progress {
  height: 84px;
}
.custom-toast-loading {
  height: 6rem;
  background: #fff4df 0% 0% no-repeat padding-box;
  .toast-footer {
    background: #ffb92e 0% 0% no-repeat padding-box;
  }
}
.selected-asset-tab {
  border-bottom: 2px solid #00d1b0;
}
.disable-asset-tab {
  pointer-events: none;
  opacity:0.5;
}

.asset-type-tab {
  text-align: center; 
  margin-top:5px;
  font:normal normal bold 16px/22px Arial;
  display: inline-block;
  vertical-align:middle;
  height:2.81rem;
  width:7.5rem;
  cursor:pointer
}

.asset-section-tab {
  border-bottom:2px solid #E0E1E1 !important;
  height: 3.12rem !important;
  margin-bottom:2rem !important;
}
</style>