var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ion-toolbar",
    [
      _c(
        "ion-tabs",
        [
          [
            _c(
              "ion-tab-bar",
              { attrs: { slot: "bottom" }, slot: "bottom" },
              [
                _c(
                  "ion-tab-button",
                  [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "nav,home,tap",
                            expression: "'nav,home,tap'"
                          }
                        ],
                        attrs: { to: "/home" }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-home-new",
                            "class-name": "g-icon--small house"
                          }
                        }),
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-home-new-selected",
                            "class-name": "g-icon--small house-selected"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ion-tab-button",
                  [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "nav,journey,tap",
                            expression: "'nav,journey,tap'"
                          }
                        ],
                        attrs: {
                          to: {
                            name: "MyLearning",
                            params: {
                              type: "required",
                              isNotFromDeepLink: true
                            }
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-mylearning-new",
                            "class-name": "g-icon--small journey"
                          }
                        }),
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-mylearning-new-selected",
                            "class-name": "g-icon--small journey-selected"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ion-tab-button",
                  [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "nav,explore,tap",
                            expression: "'nav,explore,tap'"
                          }
                        ],
                        attrs: { to: "/explore" }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-explore-new",
                            "class-name": "g-icon--small explore"
                          }
                        }),
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-explore-new-selected",
                            "class-name": "g-icon--small explore-selected"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ion-tab-button",
                  [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "nav,bookmarks,tap",
                            expression: "'nav,bookmarks,tap'"
                          }
                        ],
                        attrs: { to: "/bookmarks" }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-bookmark-new",
                            "class-name": "g-icon--small library"
                          }
                        }),
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-bookmark-new-selected",
                            "class-name": "g-icon--small library-selected"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }