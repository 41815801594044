<template>
  <div class="edge-landing g-margin__top--small">
    <ion-slides pager class="landing-mobile">
      <ion-slide v-for="(slide, index) in slides" :key="index">
        <div>
          <img v-bind:src="require(`@/assets/images/${slide.image}`)" class="edge-landing__img" alt="" />
          <div class="edge-landing__text">
            <h4 class="g-type-mlarge edge-landing__text--title">{{ slide.title }}</h4>
            <div class="edge-landing__text--subtitle">{{ slide.subTitle }}</div>
          </div>
        </div>
      </ion-slide>
      <br />
    </ion-slides>
    <div class="error-msg" v-if="message !== ''">
      {{ message }}
    </div>
    <div v-if="appBanner && !isNativeApp" class="edge-landing__banner">
      <ion-grid class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col>
            <svg-icon icon-class="close" class="g-icon--small close g-clickable" style="position: absolute; right: 0" @click="onCancel"></svg-icon>
            <p class="ion-no-margin" style="font-size: 1.4rem">ProEdge is better on the App</p>
          </ion-col>
        </ion-row>
        <ion-row style="padding-right: 13px">
          <ion-col>
            <div style="width: 130px; margin-left: auto; margin-right: 0; padding-top: 9px">
              <a href="https://apps.apple.com/us/app/proedge-learn/id1540658579"> <img src="@/assets/images/apple-store-badge.svg" alt="" /> </a>
            </div>
          </ion-col>
          <ion-col class="ion-text-start">
            <div style="width: 147px">
              <a href="https://play.google.com/store/apps/details?id=com.pwc.gx.mobility.proedgelearn"> <img src="@/assets/images/google-play-badge.png" alt="" /> </a>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <a data-cy="learn-mobile-signup" shape="round" color="primary" class="sign-up" @click="$router.push('/onboarding/create-account')">Register</a>

    <div class="g-type-small g-margin__top--small signup-label">Already registered?</div>
    <ion-button
      data-cy="learn-mobile-login"
      shape="round"
      color="tertiary"
      class="ion-margin-top edge-landing__button ion-button-tertiary g-margin__top--xsmall"
      @click="$router.push('/login')"
      >Login</ion-button
    >
    <div class="edge-landing__footer">
      <a class="privacy" @click="$router.push('/help-center-main').catch(() => {}), $emit('help-center', 'help-center')">Help Center</a>
      <a class="privacy" @click="openPrivacy()">Privacy Policy</a>
    </div>
    <div class="edge-landing__footer">
      <a class="privacy" @click="openCookieNotice()">Cookie Notice</a>
      <a class="terms" @click="openTerms()">Terms &amp; Conditions</a>
    </div>
  </div>
</template>

<script>
import { bootstrap } from 'vue-gtag'

export default {
  name: 'LandingMobile',
  props: {},
  data() {
    return {
      message: '',
      slides: [
        {
          image: 'SplashFlow1.jpg',
          title: 'The future of your career starts here',
          subTitle: 'Match your experience and strengths with a learning path specific to you'
        },
        {
          image: 'SplashFlow2.jpg',
          title: 'Advance your skills then get yourself certified',
          subTitle: 'Earn industry recognized credentials with our world class courses'
        },
        {
          image: 'SplashFlow3.jpg',
          title: 'Learn as your journey adapts to you',
          subTitle: 'Unlock your potential with a real-time automated learning curriculum'
        }
      ],
      appBanner: true,
      isNativeApp: true
    }
  },
  created() {},
  beforeMount() {
    bootstrap().then(() => {
      this.$gtag.optOut()
      this.$gtag.config({ client_storage: 'none' })
    })
    if (this.$route.query.status === 'invalid') {
      this.message = 'Please contact the administrator'
    } else if (this.$route.query.status === 'unauthorized') {
      this.message = 'Unauthorized access. Please contact your administrator'
    }
  },
  watch: {
    $route(to) {
      if (to.query.modal && ['CreateAccount', 'Login'].includes(to.query.modal) && to.query.email) {
        const route = to.query.modal === 'CreateAccount' ? '/onboarding/create-account' : '/login'
        this.$router.push({ path: route, query: { email: to.query.email } })
      }
      if (to.query.modal && ['ResetPassword'].includes(to.query.modal)) {
        this.$router.push('/reset-password')
      }
    }
  },
  mounted() {
    // eslint-disable-next-line
    this.isNativeApp = Capacitor.isNative
  },
  methods: {
    openPrivacy() {
      this.$router.push('/onboarding/privacy-policy')
    },
    openCookieNotice() {
      this.$router.push('/onboarding/cookie-notice')
    },
    openTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use')
    },
    onCancel() {
      this.appBanner = false
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('edge-landing') {
  @include element('img') {
    margin: 0;
    padding: 0;
    width: 85%;
  }

  @include element('banner') {
    position: absolute;
    height: 88px;
    width: 97%;
    z-index: 9;
    border: 2px solid var(--ion-color-dark-blue);
    background-color: var(--ion-color-white);
    margin: 0px 4px 0px 4px;
    border-radius: 5px;
  }

  @include element('footer') {
    margin-top: 10px;

    a {
      font-family: 'Futura PT Demi';
      font-size: 14px;
      color: #1f3370 !important;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }

  @include element('button') {
    font-family: 'Futura PT Demi';
    text-transform: capitalize;
  }

  @include element('text') {
    position: relative;
    padding: 0 5%;
    margin: 0 5%;
    text-align: center;
    padding-bottom: 25px;
    @include modifier('title') {
      color: var(--ion-color-tertiary);
      font-size: 2.2rem;
      font-family: 'Futura PT Demi';
    }
    @include modifier('subtitle') {
    }
    @include modifier('link') {
      color: var(--ion-color-tertiary);
      font-weight: bold;
    }
  }
}

ion-button {
  width: 100px;
  height: 40px !important;
  --box-shadow: none;
}
.sign-up {
  color: var(--ion-color-tertiary);
}
.signup-label {
  color: var(--ion-color-dark-blue);
}
</style>
