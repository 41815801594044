var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-login" },
    [
      _vm.$platform == "Mobile"
        ? _c(
            "button",
            { staticClass: "close_login" },
            [
              _c("svg-icon", {
                staticStyle: {
                  width: "20px !important",
                  height: "20px !important"
                },
                attrs: {
                  "icon-class": "close-icon",
                  "class-name": "g-icon--small"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/landing")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "edge-login__svg-wrapper" },
        [
          _c("svg-icon", {
            class: this.$platform === "Desktop" ? "on-desktop" : "on-mobile",
            attrs: { "icon-class": "small-logo", "class-name": "logo--size" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edge-login__error-message",
          attrs: { "aria-label": "error message" }
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c(
        "div",
        { staticClass: "edge-login__input" },
        [
          _c(
            "ion-item",
            {
              staticClass: "ion-no-padding input-section",
              attrs: { mode: "md" }
            },
            [
              _c("ion-input", {
                ref: "email",
                attrs: {
                  value: _vm.user.email,
                  "data-cy": "login-email",
                  placeholder: "Email Address",
                  autofocus: "",
                  disabled: _vm.isInputDisabled
                },
                on: {
                  ionChange: function($event) {
                    $event.target.value = $event.target.value
                      ? $event.target.value.trim()
                      : ""
                    _vm.user.email = $event.target.value
                    _vm.user.username = $event.target.value
                    _vm.checkSSOEmail()
                  },
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.next()
                  }
                }
              })
            ],
            1
          ),
          _vm.showPasswordField
            ? _c(
                "ion-item",
                {
                  staticClass: "ion-no-padding input-section",
                  attrs: { mode: "md" }
                },
                [
                  _c("ion-input", {
                    ref: "password",
                    attrs: {
                      "data-cy": "login-password",
                      type: _vm.showPwd ? "text" : "password",
                      placeholder: "Password",
                      disabled: _vm.isInputDisabled
                    },
                    on: {
                      ionChange: function($event) {
                        _vm.user.password = $event.target.value
                      },
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.next()
                      }
                    }
                  }),
                  _c("ion-icon", {
                    staticStyle: { color: "#00a78d" },
                    attrs: {
                      slot: "end",
                      name: _vm.showPwd ? "eye" : "eye-off"
                    },
                    on: {
                      click: function() {
                        return (_vm.showPwd = !_vm.showPwd)
                      }
                    },
                    slot: "end"
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.$platform == "Mobile" && !_vm.buttonDisabled
            ? _c(
                "div",
                { staticClass: "remember-me-container" },
                [
                  _c("ion-checkbox", {
                    staticClass: "ion-checkbox-icon",
                    attrs: {
                      mode: "md",
                      color: "tertiary",
                      checked: _vm.rememberMe
                    },
                    on: { ionChange: _vm.onRememberMe }
                  }),
                  _c(
                    "ion-label",
                    { staticClass: "ion-text-wrap g-type-body" },
                    [_vm._v("Remember me")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "ion-button",
        {
          staticClass:
            "edge-login__button ion-button-primary g-margin__top--small",
          attrs: {
            "data-cy": "login-button",
            shape: "round",
            color: "primary",
            disabled: _vm.buttonDisabled
          },
          on: { click: _vm.next }
        },
        [_vm._v("Login")]
      ),
      _vm.$platform == "Mobile"
        ? _c("div", { staticClass: "g-type-small g-margin__top--medium" }, [
            _c(
              "div",
              {
                staticClass: "lined-link",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/onboarding/get-help")
                  }
                }
              },
              [_vm._v("Get help")]
            ),
            _c(
              "div",
              {
                staticClass: "edge-login__link",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/reset-password")
                  }
                }
              },
              [_vm._v("Reset Password")]
            )
          ])
        : _c("div", { staticClass: "g-type-small g-margin__top--medium" }, [
            _c(
              "a",
              {
                staticStyle: { color: "unset", "text-decoration": "unset" },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.$emit("next", "ResetPassword")
                  }
                }
              },
              [
                _c("div", { staticClass: "edge-login__link" }, [
                  _vm._v("Reset Password")
                ])
              ]
            )
          ]),
      _c(
        "p",
        {
          staticClass:
            "disclaimer g-padding__right--small g-padding__left--small"
        },
        [
          _vm._v(
            " The material published on this learning platform does not represent the views of PwC or its affiliates and PwC does not endorse or promote any products, services, statements of opinion or companies represented by or referenced in the content. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }