var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.$platform === "Desktop" && this.$route.path === "/landing"
          ? "edge-privacyPolicy signup-page"
          : "edge-privacyPolicy signup-page footer"
    },
    [
      _vm.$platform === "Desktop"
        ? _c("div", { staticClass: "d-flex logo" }, [
            (this.$route.meta.isMenuPrivacy !== true &&
              this.$route.path !== "/landing") ||
            (this.$platform === "Mobile" &&
              this.$route.meta.isMenuPrivacy !== true) ||
            (this.$route.path !== "/landing" &&
              this.$route.meta.isMenuPrivacy !== true)
              ? _c(
                  "nav",
                  { staticClass: "d-flex", attrs: { id: "privacyNav" } },
                  [
                    _c(
                      "div",
                      { staticClass: "logo" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "logo",
                            "class-name": "logo--size"
                          },
                          on: { click: _vm.onClickLogo }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.$platform == "Mobile"
        ? _c(
            "button",
            {
              staticClass: "back_arrow",
              staticStyle: { "z-index": "100" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: {
                  "icon-class": "thin-back-arrow",
                  "class-name": "g-icon--small"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { ref: "top", staticClass: "edge-privacyPolicy__policy" }, [
        _c("div", { staticClass: "ion-text-start" }, [
          _c(
            "div",
            {
              class:
                _vm.$platform === "Desktop"
                  ? "edge-privacyPolicy__wrapper"
                  : "edge-privacyPolicy__wrapper-mobile"
            },
            [
              _c(
                "div",
                {
                  class:
                    _vm.$platform === "Desktop"
                      ? this.$route.path === "/landing"
                        ? "scrollable-content landing"
                        : "scrollable-content footer"
                      : "scrollable-content mobile"
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "g-type-xlarge title",
                      staticStyle: { "font-family": "'Futura PT Demi'" }
                    },
                    [_vm._v("ProEdge Privacy Statement")]
                  ),
                  _c("div", { staticClass: "g-type-small title" }, [
                    _vm._v("Last updated: April 2022")
                  ]),
                  _c("h3", { staticClass: "introduction" }, [
                    _vm._v("Introduction")
                  ]),
                  _vm._m(0),
                  _c("p", [
                    _vm._v(
                      ' PwC is committed to protecting your privacy. This privacy statement explains our information collection practices with respect to ProEdge (the "Application") and how we use, share, and protect that information. Note, this privacy statement applies solely to the Application and does not apply to other PwC websites or applications. '
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "This Application enables organizations and individuals to assess and grow critical skills needed to be competitive. The Application encompasses several modules:"
                    )
                  ]),
                  _vm._m(1),
                  _c("h3", [_vm._v("Information Collection")]),
                  _c("p", [
                    _vm._v(
                      " When you access or otherwise use our Application, we will collect information about you and how you interact with our Application. We collect information about you through: (1) information you provide; (2) automatic tracking technologies; (3) third parties, namely your employer. "
                    )
                  ]),
                  _c("h3", [_vm._v("Information You Provide")]),
                  _c("p", [
                    _vm._v(
                      " When you access the Application, you may choose to provide information about yourself such as: identifiers and contact information including your name, address, email address, telephone number, fax number, job title, and company name. By way of example, you may choose to provide your information in the following circumstances: "
                    )
                  ]),
                  _vm._m(2),
                  _c("h3", [_vm._v("Automatically Collected Information")]),
                  _c(
                    "p",
                    [
                      _vm._v(
                        ' We may use online identification technologies, such as cookies, web beacons, pixels, tags, localstorage, or similar technologies ("Cookies") on our Application. The information we collect using these technologies includes identifiers and internet activity information such as: IP address, browser type and language, access time, and referring website. We use these technologies to compile aggregated statistics about visitors who interact with PwC online content, to gauge the effectiveness of our ads and to better provide more pertinent information to our visitors. For more information about the Cookies used on the Application, please review our '
                      ),
                      _vm.$platform === "Mobile"
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: { name: "OnboardingCookieNotice" } }
                            },
                            [_vm._v("Cookie Notice")]
                          )
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: "./cookie-notice",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Cookie Notice")]
                          ),
                      _vm._v(". ")
                    ],
                    1
                  ),
                  _c(
                    "p",
                    [
                      _vm._v(
                        " PwC also uses third-party analytics providers, which may set Cookies in your browser or device. Application activity information collected by these analytics providers may be combined with personal information held by PwC. For more information about these third-party tools, please review our "
                      ),
                      _vm.$platform === "Mobile"
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: { name: "OnboardingCookieNotice" } }
                            },
                            [_vm._v("Cookie Notice")]
                          )
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: "./cookie-notice",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Cookie Notice.")]
                          )
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      " When we send you emails, we may use technologies to determine whether the email has been opened and whether the links contained within the message have been clicked on. We may combine information collected through these means with other information we maintain about you in order to measure interest in our specific offerings and email campaigns, improve our offerings to specific target audiences, and tailor our interactions with you. "
                    )
                  ]),
                  _c("h3", [_vm._v("Information from Third Parties")]),
                  _c("p", [
                    _vm._v(
                      "We may also obtain information about you from your employer and from your social media profile should you choose to link it to the Application, such as:"
                    )
                  ]),
                  _vm._m(3),
                  _c("h3", [_vm._v("Information Use")]),
                  _c("p", [
                    _vm._v(
                      " We use the information we collect as described above for various purposes depending on the context in which we collected such information. For example, it may be necessary for us to process your information in order to process a transaction or provide a service you have requested or otherwise in accordance with a contract between us, or we may process your information in accordance with your prior consent (where you have provided such consent). "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "We use the information we collect for the following business purposes:"
                    )
                  ]),
                  _vm._m(4),
                  _c("p", [
                    _vm._v(
                      " We may also use the information we collect to create aggregate or anonymized information, which we may use to build upon and improve the Application and our other products and services as well as for other lawful business purposes. "
                    )
                  ]),
                  _c("h3", [_vm._v("Information Sharing")]),
                  _c("p", [
                    _vm._v(
                      " As we are administering this Application to you on behalf of your Organization, we share the information we collect through the Application with your Organization. Each Organization using the Application is responsible for its handling of information collected by the Application in accordance with its internal policies and applicable law. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " We are part of a global network of firms. In common with other professional service providers, PwC may transfer or disclose the information it collects, including your information, to third-party contractors, subcontractors, subsidiaries, and/or other PwC firms for the purposes for which you have submitted the information and for the administration of our system or Application and/or other internal, administrative purposes. We transfer your information to third-party service providers of identity management, website hosting and management, data analysis, data backup, security, and storage services. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "PwC may also disclose information to third parties under the following circumstances:"
                    )
                  ]),
                  _vm._m(5),
                  _c("p", [
                    _vm._v(
                      " We may also share aggregate information about Application users with third parties for any lawful business purpose. We do not sell personal information collected in connection with the Application nor do we disseminate personal information collected in connection with the Application to outside parties for consumer marketing purposes or host mailings on behalf of third parties. "
                    )
                  ]),
                  _c("h3", [_vm._v("Information Transfer")]),
                  _c("p", [
                    _vm._v(
                      " Your information may be transferred outside of the U.S. or your country of residence. This includes to countries outside the European Economic Area (“EEA”) and to countries that do not have laws that provide the same degree of protection for personal information. In accordance with applicable legal requirements, we take appropriate measures to facilitate adequate protection for any information so transferred. "
                    )
                  ]),
                  _vm._m(6),
                  _vm._m(7),
                  _c("h3", [_vm._v("Information Retention")]),
                  _c("p", [
                    _vm._v(
                      " We will retain the personal information collected by us through the Application for as long as is necessary for the purpose(s) for which it was collected as set out in this privacy statement, provided that personal information may be held for longer periods where extended retention periods are required by law, regulation or professional standards, and in order to establish, exercise or defend our legal rights. "
                    )
                  ]),
                  _c("h3", [_vm._v("Your Choices")]),
                  _vm._m(8),
                  _c("p", [
                    _vm._v(
                      "Should you wish to unsubscribe from our mailing list or any registrations, you may contact us at: us_support_proedge@pwc.com."
                    )
                  ]),
                  _vm._m(9),
                  _c(
                    "p",
                    [
                      _vm._v(
                        " If you are concerned about cookies, most browsers permit individuals to decline cookies. In most cases, you may refuse or delete one or more cookies and still access the Application, but Application functionality may be impaired. After you finish browsing this Application, you may delete Application cookies from your system if you wish. For more information about how to manage your cookie preferences, please review our "
                      ),
                      _vm.$platform === "Mobile"
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: { name: "OnboardingCookieNotice" } }
                            },
                            [_vm._v("Cookie Notice")]
                          )
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: "./cookie-notice",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Cookie Notice")]
                          ),
                      _vm._v(". ")
                    ],
                    1
                  ),
                  _vm._m(10),
                  _vm._m(11),
                  _vm._m(12),
                  _c("p", [
                    _vm._v(
                      " You may review and change your personal information by logging into the Application and visiting your account profile page. If you would like to update your information or request to have outdated information removed, please contact us at: us_support_proedge@pwc.com. "
                    )
                  ]),
                  _vm._m(13),
                  _c("p", [
                    _vm._v(
                      " Depending on the jurisdiction in which you are located, you may have certain rights with respect to your personal information. Subject to applicable law, these rights may include the right to: "
                    )
                  ]),
                  _vm._m(14),
                  _vm._m(15),
                  _c("p", [
                    _vm._v(
                      " The verifiable request must describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. Further, you must provide us with sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative. Please, however, refrain from sending us sensitive personal information. Where permitted by law, you may use an authorized agent to submit a request on your behalf if you have provided the authorized agent signed permission to do so. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " We may not be able to fulfill your request if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable request does not require you to create an account with us. We will only use personal information provided in a verifiable request to verify the requestor's identity or authority to make the request. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " We will respond to your request within a reasonable timeframe in accordance with applicable law. We reserve the right to charge an appropriate fee for complying with a request where permitted by applicable law, and to deny a request where it may be manifestly unfounded, excessive, or unwarranted under applicable law. "
                    )
                  ]),
                  _vm._m(16),
                  _c("h3", [_vm._v("Security")]),
                  _c("p", [
                    _vm._v(
                      " PwC has implemented generally accepted standards of technology and operational security designed to protect personal information from unauthorized access, loss, misuse, alteration, or destruction. Only authorized PwC personnel and the third parties described in this privacy statement are provided access to personal information and these employees and third parties have agreed to maintain the confidentiality of this information. "
                    )
                  ]),
                  _c("h3", [_vm._v("Third-Party Sites")]),
                  _c("p", [
                    _vm._v(
                      " This Application may link to other websites which do not operate under PwC's privacy practices. When you link to other websites, PwC's privacy practices no longer apply. We encourage you to review the privacy statement of each website you visit before disclosing any personal information. "
                    )
                  ]),
                  _c("h3", [_vm._v("Children")]),
                  _c("p", [
                    _vm._v(
                      " PwC understands the importance of protecting children's privacy, especially in an online environment. The Application is not intentionally designed for or directed at children under the age of 13 years. It is PwC's policy never to knowingly collect or maintain personal information about children under the age of 13. "
                    )
                  ]),
                  _c("h3", [_vm._v("Modifications")]),
                  _c("p", [
                    _vm._v(
                      " PwC may update this privacy statement at any time by publishing an updated version here. You can access the most current version of this privacy statement at any time on this Application. "
                    )
                  ]),
                  _c("h3", [_vm._v("Contact Us")]),
                  _c("p", [
                    _vm._v(
                      "If you have questions about this privacy statement or about our privacy practices, please contact us at us_support_proedge@pwc.com"
                    )
                  ]),
                  _c("br")
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        ' ProEdge is an offering of PwC Product Sales LLC ("PwC"), a member of the PricewaterhouseCoopers global network of firms. Each PricewaterhouseCoopers member firm is a separate legal entity. For further details, please see '
      ),
      _c(
        "a",
        { attrs: { href: "https://www.pwc.com/structure", target: "_blank" } },
        [_vm._v("www.pwc.com/structure")]
      ),
      _vm._v(". ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Plan - Enables the organization to identify critical skills related to job and skills data, including benchmarking by industry and location."
        )
      ]),
      _c("li", [
        _vm._v(
          "Learn - Builds a path to success through personalized learning and skill-building activities."
        )
      ]),
      _c("li", [
        _vm._v("Share - Allows users to find and share digital assets.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Contact details (e.g. name, email, address, telephone number)")
      ]),
      _c("li", [_vm._v("Profile photo")]),
      _c("li", [_vm._v("Audio and video enabled by the user")]),
      _c("li", [_vm._v("Education, academic and professional qualifications")]),
      _c("li", [_vm._v("Employment history")]),
      _c("li", [
        _vm._v("Areas of interest and specialized knowledge/subject matter")
      ]),
      _c("li", [
        _vm._v(
          "Other information included in your resume/CV that you choose to submit"
        )
      ]),
      _c("li", [
        _vm._v("Performance evaluation, management and monitoring information")
      ]),
      _c("li", [
        _vm._v("Information re: course transcripts, certifications, assessment")
      ]),
      _c("li", [_vm._v("Learning and development information")]),
      _c("li", [
        _vm._v(
          "Social Media Profile Information, if you choose to provide us with a link to your profile page"
        )
      ]),
      _c("li", [
        _vm._v(
          "\"Share\" information (e.g. friend's or family member's email address)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Contact details (e.g. name, email, address, telephone number)")
      ]),
      _c("li", [
        _vm._v("Education, language, academic and professional qualifications")
      ]),
      _c("li", [_vm._v("Employment history")]),
      _c("li", [
        _vm._v("Performance evaluation, management and monitoring information")
      ]),
      _c("li", [
        _vm._v("Information re: course transcripts, certifications, assessment")
      ]),
      _c("li", [_vm._v("Learning and development information")]),
      _c("li", [
        _vm._v(
          "Social Media Profile Information, if you choose to provide us with a link to your profile page"
        )
      ]),
      _c("li", [_vm._v("Login credentials")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("Registering and authenticating users;")]),
      _c("li", [_vm._v("Operating and improving the Application;")]),
      _c("li", [_vm._v("Understanding how the Application is being used;")]),
      _c("li", [
        _vm._v(
          "Maintaining and improving the safety and security of the Application;"
        )
      ]),
      _c("li", [
        _vm._v(
          "Preventing and enhancing protection against fraud, spam, harassment, intellectual property infringement, crime and security risks;"
        )
      ]),
      _c("li", [_vm._v("Running our operations;")]),
      _c("li", [
        _vm._v(
          "Complying with law and legal obligations as well as professional and industry standards;"
        )
      ]),
      _c("li", [_vm._v("Responding to your inquiries;")]),
      _c("li", [
        _vm._v(
          "Informing organizational workforce, learning & development, and/or digital strategy;"
        )
      ]),
      _c("li", [
        _vm._v(
          "Participation in bulletin boards, discussion, or message forums;"
        )
      ]),
      _c("li", [
        _vm._v("Subscription or ordering newsletters and/or publications;")
      ]),
      _c("li", [_vm._v("Conducting customer satisfaction surveys;")]),
      _c("li", [_vm._v("Customizing the Application for you;")]),
      _c("li", [_vm._v("Exploring ways to develop and grow our business;")]),
      _c("li", [_vm._v("Improving our products and services; and")]),
      _c("li", [
        _vm._v(
          "Sending communications about PwC products, services, and events, if consent is provided by the user."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("When explicitly requested by a visitor;")]),
      _c("li", [
        _vm._v(
          "As needed to deliver publications or reference materials requested by a user;"
        )
      ]),
      _c("li", [_vm._v("For login, authentication, and security purposes;")]),
      _c("li", [
        _vm._v(
          " As necessary to comply with law, rules, regulations, legal obligations, professional and industry standards, as well as respond to and complying with subpoena, search warrant or other legal process, including establishing, exercising, or defending our legal rights; "
        )
      ]),
      _c("li", [
        _vm._v(
          " As necessary in connection with a corporate reorganization, merger, sale, joint venture or other disposition of all or any portion of our business, assets or capital; "
        )
      ]),
      _c("li", [
        _vm._v(
          "To issue digital badges that certify web-enabled credentials and accomplishments."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Where we transfer personal information outside of the EEA to a country or framework not determined by the European Commission as providing an adequate level of protection for personal information, the transfers will be under an agreement which covers European Union requirements for such transfers, such as standard contractual clauses. The European Commission approved standard contractual clauses are available "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en",
            target: "_blank"
          }
        },
        [_vm._v("here")]
      ),
      _vm._v(". ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Although not currently relied upon as an authorized mechanism for cross border transfers, PricewaterhouseCoopers LLP and its affiliated U.S. subsidiaries also adhere to the privacy principles in the EU - U.S. Privacy Shield Framework and the Swiss - U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce. To learn more, see our "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.pwc.com/us/en/site/privacy-shield.html",
            target: "_blank"
          }
        },
        [_vm._v("Privacy Shield Policy")]
      ),
      _vm._v(". ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("i", [_vm._v("Unsubscribe")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("i", [_vm._v("Managing Cookies")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("i", [_vm._v("Do Not Track")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        ' Do Not Track ("DNT") is a privacy preference that you can set in your web browser to send a message to the website operator requesting not to be tracked. Currently, we do not respond to these DNT signals. For more information about DNT, visit '
      ),
      _c(
        "a",
        { attrs: { href: "https://allaboutdnt.com/", target: "_blank" } },
        [_vm._v("https://allaboutdnt.com/")]
      ),
      _vm._v(". ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("i", [_vm._v("Access")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [_c("i", [_vm._v("Rights")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Know what personal information about you we have collected, disclosed, and/or sold, as applicable;"
        )
      ]),
      _c("li", [_vm._v("Obtain a copy of your personal information;")]),
      _c("li", [_vm._v("Delete your personal information;")]),
      _c("li", [
        _vm._v(
          " Direct us not to sell your personal information (but as noted above, we do not sell personal information collected about you in connection with the Application); "
        )
      ]),
      _c("li", [_vm._v("Correct or update your personal information;")]),
      _c("li", [
        _vm._v("Restrict the processing of your personal information;")
      ]),
      _c("li", [
        _vm._v("Object to the processing of your personal information;")
      ]),
      _c("li", [
        _vm._v(
          " Withdraw your consent for processing (where processing is based on your consent). Please note that if you withdraw your consent, we may not be able to provide you the services for which it was necessary to process your information based on your consent; and "
        )
      ]),
      _c("li", [
        _vm._v(
          "Exercise your privacy rights without discriminatory treatment from us."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " To exercise any of the rights described above, please submit a verifiable request to us by either using our "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://privacyportal-eu-cdn.onetrust.com/dsarwebform/f13f2198-97ab-4c25-a5cd-0fca8ada2e21/eb60a416-648e-47a1-914f-4dc7fba2ce27.html",
            target: "_blank"
          }
        },
        [_vm._v("request form")]
      ),
      _vm._v(" or calling us at 1-888-438-4427. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " We hope that you won't ever need to, but if you do want to complain about our use of personal information, please send us a message with the details of your complaint using our "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.pwc.com/us/en/forms/us-privacy-office-contact.html",
            target: "_blank"
          }
        },
        [_vm._v("general contact form")]
      ),
      _vm._v(
        ". You may also contact our EU Representative, PwC Malta, by completing our "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.pwc.com/us/en/forms/contact-eu-representative.html",
            target: "_blank"
          }
        },
        [_vm._v("EU Representative contact form")]
      ),
      _vm._v(
        ". Applicable laws may also give you the right to lodge a complaint with the data protection authority in your country. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }