import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'

const state = {
  cloudEnv: {
    details: {
      name: '',
      mode: '',
      cloudRegion: '',
      cloudProvider: ''
    },
    extras: {
      proAuthBaseUri: '',
      proAuthApiKey: ''
    },
    baseApis: {
      learnApiBaseUri: '',
      shareApiBaseUri: '',
      cmsApiBaseUri: ''
    },
    cdn: {
      contentCdn: '',
      credentialCdn: '',
      staticCdn: ''
    },
    microservices: {
      ffApi: '',
      clickstreamApiBaseUri: '',
      learningLockerApiBaseUri: '',
      learningLockerBaseUri: '',
      courseProgressApi: '',
      autographer: '',
      bouncer: ''
    }
  },
  config: {
    user: {
      lang: 'en-US'
    },
    company: {
      isC1: false,
      lang: 'en',
      regions: ['US'],
      helpdesk: {
        email: '',
        phone: ''
      }
    },
    flags: {
      clickstreamEnabled: false,
      autographerEnabled: false,
      tierEnabled: false
    }
  },
  version: ''
}

const mutations = {
  SET_CLOUDENV: (state, cloudEnv) => {
    state.cloudEnv = cloudEnv
  },
  SET_DETAILS: (state, details) => {
    state.cloudEnv.details = details
  },
  SET_EXTRAS: (state, extras) => {
    state.cloudEnv.extras = extras
  },
  SET_BASE_APIS: (state, baseApis) => {
    state.cloudEnv.baseApis = baseApis
  },
  SET_CDN: (state, cdn) => {
    state.cloudEnv.cdn = cdn
  },
  SET_MICROSERVICES: (state, microservices) => {
    state.cloudEnv.microservices = microservices
  },
  SET_CONFIG_USER: (state, user) => {
    state.config.user = user
  },
  SET_CONFIG_COMPANY: (state, company) => {
    state.config.company = company
  },
  SET_CONFIG_FLAGS: (state, flags) => {
    state.config.flags = flags
  },
  SET_VERSION: (state, version) => {
    state.version = version
  }
}

const actions = {
  async setCloudEnvInfo({ commit }, cloudEnv) {
    commit('SET_CLOUDENV', cloudEnv)
    await localforage.setItem('cloudEnv', cloudEnv)
    EventBus.$emit('config-apm')
  },
  async setDetails({ commit }, details) {
    commit('SET_DETAILS', details)
    await localforage.setItem('cloud_env.details', details).then(() => { })
  },

  async setUserConfig({ commit }, user) {
    commit('SET_CONFIG_USER', user)
    await localforage.setItem('config.user', user).then(() => { })
  },

  async setCompanyConfig({ commit }, company) {
    commit('SET_CONFIG_COMPANY', company)
    await localforage.setItem('config.company', company).then(() => { })
  },

  async setFlagConfig({ commit }, flags) {
    commit('SET_CONFIG_FLAGS', flags)
    await localforage.setItem('config.flags', flags).then(() => { })
  },

  async setLearnVersion({ commit }, version) {
    commit('SET_VERSION', version)
    await localforage.setItem('my_learn_version', version).then(() => { })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
