<template>
  <div class="edge-login">
    <button class="close_login" v-if="$platform == 'Mobile'">
      <svg-icon icon-class="close-icon" class-name="g-icon--small" style="width: 20px !important; height: 20px !important" @click="$router.push('/landing')" />
    </button>
    <div class="edge-login__svg-wrapper">
      <svg-icon icon-class="small-logo" class-name="logo--size" :class="this.$platform === 'Desktop' ? 'on-desktop' : 'on-mobile'" />
    </div>
    <div class="edge-login__error-message" aria-label="error message">{{ errorMessage }}</div>
    <div class="edge-login__input">
      <ion-item class="ion-no-padding input-section" mode="md">
        <ion-input
          :value="user.email"
          data-cy="login-email"
          placeholder="Email Address"
          autofocus
          @ionChange="
            $event.target.value = $event.target.value ? $event.target.value.trim() : ''
            user.email = $event.target.value
            user.username = $event.target.value
            checkSSOEmail()
          "
          :disabled="isInputDisabled"
          ref="email"
          @keypress.enter="next()"
        ></ion-input>
      </ion-item>
      <ion-item class="ion-no-padding input-section" v-if="showPasswordField" mode="md">
        <ion-input
          data-cy="login-password"
          :type="showPwd ? 'text' : 'password'"
          ref="password"
          placeholder="Password"
          :disabled="isInputDisabled"
          @ionChange="user.password = $event.target.value"
          @keypress.enter="next()"
        ></ion-input>
        <ion-icon slot="end" :name="showPwd ? 'eye' : 'eye-off'" @click="() => (showPwd = !showPwd)" style="color: #00a78d"></ion-icon>
      </ion-item>
      <div class="remember-me-container" v-if="$platform == 'Mobile' && !buttonDisabled">
        <ion-checkbox class="ion-checkbox-icon" mode="md" color="tertiary" :checked="rememberMe" @ionChange="onRememberMe"> </ion-checkbox>
        <ion-label class="ion-text-wrap g-type-body">Remember me</ion-label>
      </div>
    </div>
    <ion-button data-cy="login-button" shape="round" color="primary" class="edge-login__button ion-button-primary g-margin__top--small" :disabled="buttonDisabled" @click="next"
      >Login</ion-button
    >
    <div v-if="$platform == 'Mobile'" class="g-type-small g-margin__top--medium">
      <div class="lined-link" @click="$router.push('/onboarding/get-help')">Get help</div>
      <div class="edge-login__link" @click="$router.push('/reset-password')">Reset Password</div>
    </div>
    <div v-else class="g-type-small g-margin__top--medium">
      <a href="#" @click="$emit('next', 'ResetPassword')" style="color: unset; text-decoration: unset">
        <div class="edge-login__link">Reset Password</div>
      </a>
    </div>
    <p class="disclaimer g-padding__right--small g-padding__left--small">
      The material published on this learning platform does not represent the views of PwC or its affiliates and PwC does not endorse or promote any products, services, statements
      of opinion or companies represented by or referenced in the content.
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import localforage from 'localforage'
import { validEmail } from '../../../src/utils/validate.js'
import { InAppBrowser } from '@ionic-native/in-app-browser'
import { Plugins } from '@capacitor/core'
import { getFeatureFlagStrategy } from '@/utils/flag.js'

const { App, Browser } = Plugins

export default {
  name: 'Login',
  props: {},
  data() {
    return {
      rememberMe: false,
      showPwd: false,
      isInputDisabled: false,
      buttonDisabled: true,
      errorMessage: '',
      showPasswordField: false,
      cloudEnv: {},
      isCloudEnvSet: false,
      ssoRedirect: '',
      user: {
        email: '',
        password: '',
        username: ''
      },
      placeholderErrorMessage: 'You have entered an invalid email or password',
      onBoardingState: null,
      incorrectLoginAttempt: 0
    }
  },
  computed: {
    ...mapGetters(['cloud_env', 'config', 'allFeatureFlags'])
  },
  created() {},
  async mounted() {
    if ((await localforage.getItem('userEmail')) != null) {
      this.user.email = await localforage.getItem('userEmail')
      this.rememberMe = true
      this.checkSSOEmail()
    }
    if (this.$route && this.$route.query && this.$route.query.email) {
      this.$refs.email.value = this.$route.query.email
      this.user.email = this.$route.query.email
      this.checkSSOEmail()
    }
  },
  methods: {
    ...mapActions('user', [
      'setAccessToken',
      'setUserInfo',
      'setUserOnboarded',
      'setTenantInfo',
      'setSSO',
      'setShowShare',
      'setShowIdea',
      'setHideRWL',
      'setTrackingDisabled',
      'setGlobalSearchEnabled',
      'setShowSearchSuggest',
      'setShowSearchLookingFor',
      'setShowAddToTrack',
      'setUserAccessTag'
    ]),
    ...mapActions('environment', ['setCloudEnvInfo', 'setDetails', 'setUserConfig', 'setCompanyConfig', 'setFlagConfig']),
    ...mapActions('flag', ['setFeatureFlags']),
    async checkSSOEmail() {
      // eslint-disable-next-line
      const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.user.email = this.user.email.trim()
      this.buttonDisabled = !emailPattern.test(this.user.email)
      const userEmail = await localforage.getItem('userEmail')
      if (userEmail != this.user.email) this.rememberMe = false
      if (emailPattern.test(this.user.email)) {
        this.isCloudEnvSet = await this.setCloudEnvData()
        let response = await this.$learnAdmin.ssoCheck(this.user.email)
        this.showPasswordField = !response['isSSOLogin']
        if (response['isSSOLogin']) {
          this.ssoRedirect = response['sso-url']
          await localforage.setItem('ssoRedirect', this.ssoRedirect)
        }
      }
    },
    async onRememberMe(event) {
      this.rememberMe = event.target.checked
      if (this.rememberMe) await localforage.setItem('userEmail', this.user.email)
      else await localforage.removeItem('userEmail', this.user.email)
    },
    async next() {
      await this.checkSSOEmail()
      if (!this.showPasswordField) {
        this.toggleInputs()
        if (!this.isCloudEnvSet) this.toggleInputs()

        this.cloudEnv = this.cloud_env || (await localforage.getItem('cloudEnv'))
        const redirectUrl = `${this.ssoRedirect}?referring_url=${window.location.origin}/home&env=${this.cloudEnv.details.mode}`

        if (this.$platform === 'Desktop') {
          window.location.href = redirectUrl
        } else {
          this.$platform === 'Mobile' && this.$device === 'android' ? await this.openIonicBrowser(redirectUrl) : await this.openCapacitorBrowser(redirectUrl)
        }
      } else {
        this.toggleInputs()
        this.setErrorMessage()
        if (!this.validateFields()) {
          this.setErrorMessage(this.placeholderErrorMessage)
          this.toggleInputs()
          return
        }

        this.isCloudEnvSet = await this.setCloudEnvData()
        if (!this.isCloudEnvSet) {
          this.setErrorMessage(this.placeholderErrorMessage)
          this.toggleInputs()
          return
        }

        const isLoggedIn = await this.login()
        if (!isLoggedIn) {
          this.incorrectLoginAttempt = this.incorrectLoginAttempt + 1
          this.placeholderErrorMessage =
            this.incorrectLoginAttempt > 3 ? 'You have entered an invalid email or password. Please check your email or reset your password' : this.placeholderErrorMessage
          this.setErrorMessage(this.placeholderErrorMessage)
          this.toggleInputs()
          return
        }
        if (!this.onBoardingState || this.onBoardingState !== 'COMPLETED') {
          this.setErrorMessage(this.placeholderErrorMessage)
          this.toggleInputs()
          return
        }
        const dlId = await localforage.getItem('deep_link_id')
        const dltp = await localforage.getItem('deep_link_type')
        const dlsq = await localforage.getItem('deep_link_search_query')
        const dlst = await localforage.getItem('deep_link_search_type')
        const dlmlt = await localforage.getItem('deep_link_my_learning_type')
        dlId && dlId.length && dltp && dltp.length
          ? this.$router.push(`/${dltp}/${dlId}`)
          : dlsq && dlsq.length
          ? this.$router.push(`/search?q=${dlsq}`)
          : dlst && dlst.length
          ? this.$router.push(`/search?q=&type=${dlst}`)
          : dlmlt && dlmlt.length
          ? this.$router.push({ name: 'MyLearning', params: { type: dlmlt } })
          : this.$router.push('/home')
        this.clearInputs()
        this.toggleInputs()
      }
    },
    async openCapacitorBrowser(redirectUrl) {
      await Browser.open({ url: redirectUrl, windowName: '_self' })
      App.addListener('appUrlOpen', async () => {
        const getLaunchUrl = await App.getLaunchUrl()
        if (getLaunchUrl.url.includes('/home?ProAuthSSO')) {
          const dlId = await localforage.getItem('deep_link_id')
          const dltp = await localforage.getItem('deep_link_type')
          const dlsq = await localforage.getItem('deep_link_search_query')
          const dlst = await localforage.getItem('deep_link_search_type')
          const dlmlt = await localforage.getItem('deep_link_my_learning_type')
          let fixingUrl = getLaunchUrl.url.replace('capacitor://localhost', '')
          fixingUrl =
            dlId && dlId.length && dltp && dltp.length
              ? fixingUrl.replace('/home', `/${dltp}/${dlId}`)
              : dlsq && dlsq.length
              ? fixingUrl.replace('/home', `/search?q=${dlsq}`)
              : dlst && dlst.length
              ? fixingUrl.replace('/home', `/search?q=&type=${dlst}`)
              : dlmlt && dlmlt.length
              ? fixingUrl.replace('/home', `/mylearning/${dlmlt}`)
              : fixingUrl
          await Browser.close()
          this.$router.push(fixingUrl)
        }
      })
    },
    async openIonicBrowser(redirectUrl) {
      let options = {
        zoom: 'no',
        location: 'no',
        toolbar: 'no',
        enableViewportScale: 'no',
        transitionstyle: 'crossdissolve',
        clearcache: 'yes',
        clearsessioncache: 'yes'
      }

      let browser = InAppBrowser.create(redirectUrl, '_self', options)
      browser.on('loadstart').subscribe(async (event) => {
        if (event.url.includes('/home?ProAuthSSO')) {
          browser.close()
          const proAuthSSO = event.url.split('=')
          const response = JSON.parse(atob(proAuthSSO[1]))
          await this.login(response)
          const dlId = await localforage.getItem('deep_link_id')
          const dltp = await localforage.getItem('deep_link_type')
          const dlsq = await localforage.getItem('deep_link_search_query')
          const dlst = await localforage.getItem('deep_link_search_type')
          const dlmlt = await localforage.getItem('deep_link_my_learning_type')
          dlId && dlId.length && dltp && dltp.length
            ? this.$router.push(`/${dltp}/${dlId}?ProAuthSSO=${proAuthSSO[1]}`)
            : dlsq && dlsq.length
            ? this.$router.push(`/search?q=${dlsq}?ProAuthSSO=${proAuthSSO[1]}`)
            : dlst && dlst.length
            ? this.$router.push(`/search?q=&type=${dlst}?ProAuthSSO=${proAuthSSO[1]}`)
            : dlmlt && dlmlt.length
            ? this.$router.push(`/mylearning/${dlmlt}?ProAuthSSO=${proAuthSSO[1]}`)
            : this.$router.push(`/home?ProAuthSSO=${proAuthSSO[1]}`)
        }
      })
    },
    async login(resp) {
      const response = !resp ? await this.$learnAdmin.login(this.user) : resp

      if (!response.access_token) return

      const access_token = response.access_token
      const sso = { token: response.sso_token, bouncer: response.sso_bouncer }
      await this.setAccessToken(access_token)
      await this.setSSO(sso)

      const userInfo = await this.getUserInfo()
      if (!userInfo) {
        return false
      }
      this.onBoardingState = userInfo.onBoardingState
      const userOnboarded = this.onBoardingState && this.onBoardingState === 'COMPLETED'
      if (userOnboarded) {
        await this.setUserInfo(userInfo)
        await this.setUserOnboarded(userOnboarded)
        if (!Object.keys(this.allFeatureFlags).length) {
          const response = await this.$learnAdmin.getBackupFeatureFlags()
          this.setFeatureFlags({ featureFlags: response.features, strategyProviders: getFeatureFlagStrategy() })
        }
      }
      const userAccessAssets = await this.getUserAccessAssets()
      if (userAccessAssets.canAccessAssets) {
        await this.setUserAccessTag(userAccessAssets)
      }

      const tenantInfo = await this.getUserTenantInfo()
      if (!tenantInfo) {
        return false
      }
      await this.setTenantInfo(tenantInfo.clientCategory)
      const settings = tenantInfo.settings ? JSON.parse(tenantInfo.settings) : null
      if ({}.hasOwnProperty.call(settings, 'ui-share-display')) {
        await this.setShowShare(settings['ui-share-display'])
      } else {
        await this.setShowShare(true)
      }
      if ({}.hasOwnProperty.call(settings, 'ui-idea-display')) {
        await this.setShowIdea(settings['ui-idea-display'])
      } else {
        await this.setShowIdea(true)
      }
      if ({}.hasOwnProperty.call(settings, 'ui-rwl-display')) {
        await this.setHideRWL(settings['ui-rwl-display'])
      } else {
        await this.setHideRWL(false)
      }
      if ({}.hasOwnProperty.call(settings, 'trackingDisabled')) {
        await this.setTrackingDisabled(settings['trackingDisabled'])
      } else {
        await this.setTrackingDisabled(false)
      }
      if ({}.hasOwnProperty.call(settings, 'globalSearchEnabled')) {
        await this.setGlobalSearchEnabled(settings['globalSearchEnabled'])
      } else {
        await this.setGlobalSearchEnabled(false)
      }
      if ({}.hasOwnProperty.call(settings, 'showSearchSuggest')) {
        await this.setShowSearchSuggest(settings['showSearchSuggest'])
      } else {
        await this.setShowSearchSuggest(false)
      }
      if ({}.hasOwnProperty.call(settings, 'showSearchLookingFor')) {
        await this.setShowSearchLookingFor(settings['showSearchLookingFor'])
      } else {
        await this.setShowSearchLookingFor(false)
      }
      if ({}.hasOwnProperty.call(settings, 'user-generated-track-enabled')) {
        await this.setShowAddToTrack(settings['user-generated-track-enabled'])
      } else {
        await this.setShowAddToTrack(false)
      }
      analytics.setVarAndTrack({ c: 'cta', l: 'login', a: 'click', un: this.email })
      return true
    },
    async getUserInfo() {
      const token = await localforage.getItem('my_access_token')
      const userInfo = await this.$learnAdmin.getUserInfo({ token: token }).catch((error) => error)
      if (userInfo.error || userInfo.code) {
        return
      }
      return userInfo
    },
    async getUserAccessAssets() {
      const token = await localforage.getItem('my_access_token')
      return await this.$learnAdmin.getUserAccessAssets({ token: token }).catch((error) => error)
    },
    async getUserTenantInfo() {
      const token = await localforage.getItem('my_access_token')
      const tenantInfo = await this.$learnAdmin.getUserTenantInfo({ token: token }).catch((error) => error)
      if (tenantInfo.error || tenantInfo.code) {
        return
      }
      return tenantInfo
    },
    toggleInputs() {
      this.isInputDisabled = !this.isInputDisabled
    },
    clearInputs() {
      this.incorrectLoginAttempt = 0
      this.$refs.email.value = null
      this.$refs.password.value = null
    },
    setErrorMessage(message) {
      this.errorMessage = message
    },
    validateFields() {
      return validEmail(this.user.email)
    },
    async setCloudEnvData() {
      const response =
        process.env.VUE_APP_USE_FFAPI === 'true'
          ? await this.$learnAdmin.getCloudEnvByUserEmailAddress(this.user.email)
          : await this.$learnAdmin.getCloudEnvFromDefaultEnv(this.user.email)
      if (!response.data || !response.data.cloudEnv) {
        return false
      }
      await this.setCloudEnvInfo(response.data.cloudEnv)
      await this.$learnAdmin.updateEnvFromLocalForage()

      this.setUserConfig(response.data.config.user)
      this.setCompanyConfig(response.data.config.company)
      this.setFlagConfig(response.data.config.flags)
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
@include block('edge-login') {
  @include element('svg-wrapper') {
    padding: 8% 8% 8% 8%;
    @include block('svg') {
      width: 100%;
      height: 100%;
    }
  }

  @include element('button') {
    font-size: 1rem;
    font-family: 'Futura PT Demi';
    text-transform: none;
  }

  @include element('input') {
    padding: 0% 8%;
    ion-item {
      margin-bottom: 3%;
    }
  }
  @include element('link') {
    color: var(--ion-color-tertiary);
    font-family: 'Futura PT Demi';
    text-decoration: underline;
    cursor: pointer;
  }
  @include element('error-message') {
    margin: 0 5rem;
    color: var(--ion-color-status);
  }
}

.remember-me-container {
  display: flex;
  align-items: center;
  margin: 32px 0;
  ion-checkbox {
    margin-right: 0.5rem;
    min-width: 1.125rem;
  }
  .ion-checkbox-icon {
    --border-color: #bcc2d4;
  }
  ion-label {
    font-family: Arial;
    font-size: 14px;
    color: #000000de;
    text-align: left;
  }
}

.edge-login {
  .logo--size {
    width: 100%;
    height: 100%;
  }
}

.on-desktop {
  width: 40% !important;
}

.on-mobile {
  width: 80% !important;
}

.disclaimer {
  font-size: 0.8rem;
  border-radius: 8px;
  margin: 1.2rem 1.5rem 0rem 1.5rem;
  padding: 0.8rem 1rem;
  color: var(--ion-color-role-grey);
}

.lined-link {
  display: block;
  margin: 5px 0;
  color: #1f3370;
  font-size: 1rem;
  text-decoration: underline;
}

ion-input[type='password'] {
  font-family: monospace;
  ::placeholder {
    font-family: 'Futura PT Book', sans-serif;
  }
}

.input-section {
  --highlight-background: var(--ion-color-secondary-dark);
}

ion-button {
  --box-shadow: none;
}

.close_login{
  background: transparent;
  position: absolute;
  top: 10px;
  right: 13px;
}
</style>
