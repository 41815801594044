var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ion-card",
    {
      staticClass: "skillbuilder__lesson",
      class: _vm.lesson.isSelect ? "lesson-select" : "",
      on: {
        click: function($event) {
          return _vm.selectLesson()
        }
      }
    },
    [
      _c(
        "ion-grid",
        { staticClass: "ion-no-padding" },
        [
          _c(
            "ion-row",
            { staticClass: "ion-align-items-center" },
            [
              _c(
                "ion-col",
                {
                  staticClass:
                    "ion-text-center g-margin__left--medium g-margin__top--small"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "ion-no-margin g-margin__right--medium skillbuilder__lesson__subtitle ellipsis-lines-2"
                    },
                    [_vm._v(_vm._s(_vm.lesson.title))]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }