<template>
  <ion-content class="edge-landing">
    <nav id="nav" class="d-flex">
      <div class="logo d-flex">
        <svg-icon icon-class="logo" class-name="logo--size" @click="goToLogin"/>
      </div>
      <ion-row v-if="$router.currentRoute.path == '/' || $router.currentRoute.path == '/landing'" class="nav-right-items">
        <a href="#" style="text-decoration: unset" data-cy="learn-desktop-signup" color="primary" class="sign-up" @click="openRegister" v-track="'nav,Register,click'">Register</a>
        <ion-button data-cy="learn-desktop-login" shape="round" color="tertiary" class="ion-button-tertiary button-text" @click="openLoginModal()" v-track="'nav,Login,click'"
          >Login</ion-button
        >
      </ion-row>
    </nav>

    <div class="landing">
      <div class="error-msg" v-if="message !== ''">
        {{ message }}
      </div>
      <div class="landing-body">
        <section class="landing-section">
          <div class="landing-section-img">
            <img :src="require(`@/assets/images/${slides[0].image}`)" alt="" />
          </div>
          <div class="landing-section-text">
            <h1>{{ slides[0].title }}</h1>
            <p>{{ slides[0].subtitle }}</p>
          </div>
        </section>

        <section class="landing-section">
          <div class="landing-section-text">
            <h2>{{ slides[1].title }}</h2>
            <p>{{ slides[1].subtitle }}</p>
          </div>
          <div class="landing-section-img">
            <img :src="require(`@/assets/images/${slides[1].image}`)" class="section-panel-image" alt="" />
          </div>
        </section>

        <section class="landing-section">
          <div class="landing-section-img">
            <img :src="require(`@/assets/images/${slides[2].image}`)" class="section-panel-image" alt="" />
          </div>
          <div class="landing-section-text">
            <h2>{{ slides[2].title }}</h2>
            <p>{{ slides[2].subtitle }}</p>
          </div>
        </section>
      </div>
    </div>
    <ion-footer id="footer" :style="showModals ? 'z-index: 899' : ''">
      <ion-row>
        <ion-col size="7" class="d-flex"
          >© {{ currentYear }} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.
        </ion-col>
        <ion-col size="1" @click="$router.push('/help-center-main').catch(() => {}), $emit('help-center', 'help-center')" style="cursor: pointer">
          <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Help Center</a>
        </ion-col>
        <ion-col size="1" @click="openPrivacy(true)" style="cursor: pointer">
          <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Privacy Policy</a>
        </ion-col>
        <ion-col size="1" @click="openCookieNotice()" style="cursor: pointer">
          <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Cookie Notice</a>
        </ion-col>
        <ion-col size="2" @click="openTerms()" style="cursor: pointer">
          <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Terms and Conditions</a>
        </ion-col>
      </ion-row>
    </ion-footer>
    <div class="overlay" v-show="showModals"></div>
    <div v-show="showModals" class="modal" :key="computedCurrentModal" ref="modal" role="dialog" :aria-label="computedCurrentModal" tabindex="0">
      <a href="#" @click="onClose" style="color: unset; text-decoration: unset" aria-label="close button">
        <svg-icon v-if="computedCurrentModal !== 'Welcome'" icon-class="close" class="g-icon--small close g-clickable"></svg-icon>
      </a>
      <component :is="computedCurrentModal" @next="nextModal" :email="email" @decline="onClose" />
    </div>
  </ion-content>
</template>

<script>
import { bootstrap } from 'vue-gtag'
import Login from '@/views/account/Login'
import Verify from '@/views/onboarding/Verify'
import Welcome from '@/views/onboarding/Welcome'
import PrivacyPolicy from '@/views/onboarding/PrivacyPolicy'
import TermsAndConditions from '@/views/onboarding/TermsAndConditions'
import CreateAccount from '@/views/onboarding/CreateAccount'
import ResetPassword from '@/views/account/ResetPassword'
import ChangePassword from '@/views/account/ChangePassword'

export default {
  name: 'LandingDesktop',
  props: {},
  components: { Login, PrivacyPolicy, TermsAndConditions, CreateAccount, Verify, Welcome, ResetPassword, ChangePassword },
  data() {
    return {
      email: '',
      showModals: false,
      currentModal: '',
      message: '',
      currentYear: '',
      slides: [
        {
          image: 'SplashFlow1.jpg',
          title: 'The future of your career starts here',
          subtitle: 'Match your experience and strengths with a learning path specific to you'
        },
        {
          image: 'SplashFlow2.jpg',
          title: 'Advance your skills then get yourself certified',
          subtitle: 'Earn industry recognized credentials with our world class courses.'
        },
        {
          image: 'SplashFlow3.jpg',
          title: 'Learn through a fun journey that adapts to you',
          subtitle: 'Unlock your potential with a real-time automated learning curriculum'
        }
      ]
    }
  },
  created() {
    const date = new Date()
    this.currentYear = date.getFullYear()
  },
  beforeMount() {
    bootstrap().then(() => {
      this.$gtag.optOut()
      this.$gtag.config({ client_storage: 'none' })
    })
    this.displayStatusMessage()
  },
  mounted() {
    if (this.$route.query.modal && this.$route.query.modal !== '') {
      this.showModals = true
      this.currentModal = this.$route.query.modal
    }
  },
  watch: {
    $route(to) {
      if (to.query.modal && ['Welcome', 'PrivacyPolicy', 'CreateAccount', 'Login', 'ResetPassword'].includes(to.query.modal)) {
        this.showModals = true
        this.currentModal = to.query.modal
      }
      this.displayStatusMessage()
    }
  },
  computed: {
    computedCurrentModal() {
      return this.currentModal
    }
  },
  methods: {
    openLoginModal() {
      this.currentModal = 'Login'
      this.showModals = true
      this.$nextTick(function () {
        this.$refs.modal.focus()
      })
    },
    displayStatusMessage() {
      if (this.$route.query.status === 'invalid') {
        this.message = 'Please contact the administrator'
      } else if (this.$route.query.status === 'unauthorized') {
        this.message = 'Unauthorized access. Please contact your administrator'
      } else {
        this.message = ''
      }
    },
    nextModal(value) {
      if (value.email) {
        this.currentModal = value.page
        this.email = value.email
      } else {
        this.currentModal = value
      }
    },
    onClose() {
      this.showModals = false
      this.currentModal = ''
    },
    goToLogin() {
      this.openLoginModal()
    },
    openRegister() {
      this.currentModal = 'CreateAccount'
      this.showModals = true
      this.$nextTick(function () {
        this.$refs.modal.focus()
      })
    },
    openPrivacy(newPage) {
      if (!newPage) {
        this.currentModal = 'PrivacyPolicy'
        this.showModals = true
      } else {
        window.open('/onboarding/privacy-policy', '_blank')
      }
    },
    openCookieNotice() {
      window.open('/onboarding/cookie-notice', '_blank')
    },
    openTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.landing {
  z-index: 5;
  display: block;
  margin: auto;
  padding: 4rem;
}

.landing-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
  .landing-section-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin: 0 2rem;
    width: 350px;
    h1,
    h2,
    h4 {
      font-size: 34px;
      margin: 1rem 0rem;
      font-family: 'Futura PT Demi';
      color: var(--ion-color-tertiary);
    }
    p {
      font-size: 18px;
    }
  }
  .landing-section-img {
    max-width: 35rem;
  }
}

.d-flex {
  display: flex;
}

#nav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px;
  height: 50px;
  z-index: 800;
  width: 100%;
  background: map-get($edge-colors, 'white');
  // box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;

  .logo {
    width: 160px;
    height: 25px;
    margin: 0.7rem 1rem;
    .logo--size {
      position: relative;
      height: 30px;
      width: 114px;
      cursor: pointer;
    }
  }
}

.nav-right-items {
  position: absolute;
  right: 40px;

  ion-button {
    margin-right: 10px;
  }
}

#footer {
  position: fixed;
  z-index: 3000;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: map-get($edge-colors, 'white');
  color: map-get($edge-colors, 'black');
  padding: 12px 30px;
  font-size: 14px;

  ion-col {
    font-family: 'Futura PT Demi';
    color: #1f3370;
    &.d-flex {
      text-align: left;
    }
  }

  .logo {
    float: left;
    margin-right: 10px;
    height: 20px;
  }
}
.sign-up {
  margin-top: 15px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
  font-weight: normal !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}

.overlay {
  position: fixed;
  background: #000;
  opacity: 0.3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  animation: fadein 1.2s;
}

.modal {
  position: fixed;
  top: 3.5rem;
  left: calc((100% - 800px) / 2);
  width: 800px;
  height: 600px;
  z-index: 1000;
  background: #fff;
}
.close {
  float: right;
  margin: 1rem 1rem 0 0;
  cursor: pointer !important;
  z-index: 1000 !important;
}

.button-text {
  font-size: 1rem;
  text-transform: none;
  font-family: 'Futura PT Demi';
}

ion-button {
  width: 110px;
  --box-shadow: none;
}
</style>
