import Vue from 'vue'
import IdleVue from 'idle-vue'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'
import { popoverController } from '@modus/ionic-vue'
import GoalCongratulationsModal from '@/components/molecules/GoalCongratulationsModal'

const eventsHub = new Vue()


Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 10000
})

export default {
  async sendUserActivity() {
    const token = await localforage.getItem('my_access_token') || ''
    if (token !== '') {
      let response = await Vue.prototype.$learnAdmin.sendUserActivity({ token: token })
      const obj = response.data || {}
      if (Object.keys(obj).length > 0) {
        const todayDate = new Date().toDateString().toLocaleString('en-US', {timeZone: 'America/New_York',}).replaceAll(" ","-")
        await localforage.setItem('lastActiveDate', todayDate)
        if (obj.displayCongratulationsMessage === 'SHOW')
          this.showGoalCongratulationsModal(obj.goalDays)
        EventBus.$emit('active-days-updated', obj)
      }
    }
  },

  async setActiveDate() {
    const lastActive = await localforage.getItem('lastActiveDate') || ''
    const token = await localforage.getItem('my_access_token') || ''
    if (token !== '' && lastActive.length == 0) {
      this.sendUserActivity()
    }
  },

  async showGoalCongratulationsModal(goalDays = 0) {
    let modal = await popoverController.create({
      component: GoalCongratulationsModal,
      swipeToClose: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'goal-congrats-popover',
      componentProps: {
        data: {},
        propsData: {
          goalDays: goalDays
        }
      }
    })
    EventBus.$emit('show-celebration', true)
    await modal.present()
    await modal.onDidDismiss().then(async () => {
      const authToken = await localforage.getItem('my_access_token') || ''
      const body = { congratulationsMessageStatus: 'RESET' }
      const payload = {
        token: authToken,
        body: body
      }
      await Vue.prototype.$learnAdmin.saveHomeGoalSetting(payload)
    })
  },

  userActiveTime() {
    this.sendUserActivity()
    const obj = this
    return new Vue({
      async onActive() {
        const todayDate = new Date().toDateString().toLocaleString('en-US', {timeZone: 'America/New_York',}).replaceAll(" ","-")
        const lastActive = await localforage.getItem('lastActiveDate') || ''
        const token = await localforage.getItem('my_access_token') || ''
        if (token !== '' && todayDate != lastActive) {
          obj.sendUserActivity()
        }
      }
    })
  }
}
