<template>
  <ion-item>
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</template>
<script>
export default {
  name: 'LoadingAnimation',
  props: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
