var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "top", class: _vm.isDesktopPlatform ? "" : "non-white-background" },
    [
      _vm.showContent
        ? _c(
            "div",
            [
              _vm.isContentAvailable
                ? _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding ion-padding-bottom",
                      class: _vm.isDesktopPlatform
                        ? "desktop-content-container"
                        : "mobile-content-container"
                    },
                    [
                      _c(
                        "ion-row",
                        { staticClass: "ion-no-padding" },
                        [
                          _c(
                            "ion-col",
                            {
                              attrs: {
                                "size-xl": "8.5",
                                "size-lg": "12",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "accessible-button back",
                                    class:
                                      _vm.$platform === "Desktop"
                                        ? "back-desktop"
                                        : "back-mobile",
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.go(-1)
                                      }
                                    }
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "g-icon--small",
                                      attrs: { "icon-class": "icon-arrow-left" }
                                    }),
                                    _vm._v(" Back ")
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "ion-row",
                                { staticClass: "content-info" },
                                [
                                  _c(
                                    "ion-col",
                                    { staticClass: "content-img-background" },
                                    [
                                      _vm.content.imageUri
                                        ? _c("img", {
                                            attrs: { src: _vm.content.imageUri }
                                          })
                                        : _c("img", {
                                            attrs: { src: _vm.defaultImage }
                                          })
                                    ]
                                  ),
                                  _c("ion-col", [
                                    _c("div", { staticClass: "meta" }, [
                                      _c("div", { staticClass: "category" }, [
                                        _c("div", { staticClass: "format" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.content.format &&
                                                _vm.content.format.name
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "provider" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.content.provider &&
                                                _vm.content.provider.name
                                            )
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(_vm.content.title))
                                    ]),
                                    _c("div", { staticClass: "meta-info" }, [
                                      _vm.content.rating
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-rating",
                                              on: { click: _vm.onScroll }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                    "font-family": "Arial"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.content.rating)
                                                  )
                                                ]
                                              ),
                                              _vm.isDesktopPlatform
                                                ? _c("StarRating", {
                                                    staticClass: "star-rating",
                                                    attrs: {
                                                      "inactive-color":
                                                        "#d0cfce",
                                                      "active-color": "#b98032",
                                                      "star-points": [
                                                        23,
                                                        2,
                                                        14,
                                                        17,
                                                        0,
                                                        19,
                                                        10,
                                                        34,
                                                        7,
                                                        50,
                                                        23,
                                                        43,
                                                        38,
                                                        50,
                                                        36,
                                                        34,
                                                        46,
                                                        19,
                                                        31,
                                                        17
                                                      ],
                                                      rating:
                                                        _vm.content.rating,
                                                      "read-only": true,
                                                      increment: 0.1,
                                                      "star-size": 16,
                                                      "show-rating": false,
                                                      "round-start-rating": true
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.isDesktopPlatform
                                                ? _c("svg-icon", {
                                                    staticClass:
                                                      "display-rating-mobile",
                                                    attrs: {
                                                      "icon-class":
                                                        "star-filled-desktop"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "rating-count" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.content.ratingCount
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.content
                                                          .ratingCount > 1
                                                          ? "ratings"
                                                          : "rating"
                                                      )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "duration" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDuration")(
                                              _vm.content.duration
                                            )
                                          )
                                        )
                                      ]),
                                      _vm.content.level
                                        ? _c("div", { staticClass: "level" }, [
                                            _vm._v(_vm._s(_vm.content.level))
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "publish-date" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatPublishedDate")(
                                                _vm.content.publishedAt
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "meta-action" }, [
                                      _c(
                                        "div",
                                        { staticClass: "bookmark-container" },
                                        [
                                          _c("Bookmark", {
                                            staticClass: "content-bookmark",
                                            attrs: {
                                              assetId: _vm.content.id,
                                              assetType: "artifacts",
                                              meta: _vm.content.meta
                                            }
                                          }),
                                          _vm.isDesktopPlatform
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "bookmark-tooltip-text"
                                                },
                                                [_vm._v("Bookmark")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "share-container" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "social-share",
                                              attrs: {
                                                "aria-labelledby": "share-btn"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.popoverOn("share")
                                                }
                                              }
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "click-to-copy",
                                                  "class-name":
                                                    "g-icon--small g-clickable"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.isShareOpen
                                            ? _c("SocialSharePopover", {
                                                staticClass:
                                                  "social-share-popover",
                                                attrs: {
                                                  assetId: _vm.contentId,
                                                  routeInfo: _vm.getRouteInfo(),
                                                  router: _vm.$router,
                                                  fromPage: "Universal card",
                                                  size: "lg"
                                                },
                                                on: {
                                                  "dismiss-share-action-model": function(
                                                    $event
                                                  ) {
                                                    return _vm.popoverOff(
                                                      "share"
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          !_vm.isShareOpen &&
                                          _vm.isDesktopPlatform
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "share-tooltip-text",
                                                  attrs: { id: "share-btn" }
                                                },
                                                [_vm._v("Share")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm.showAddToTrack
                                        ? _c(
                                            "div",
                                            { staticClass: "more-container" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "more-action",
                                                  attrs: {
                                                    "aria-labelledby":
                                                      "more-btn"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.popoverOn(
                                                        "more"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "more-action",
                                                      "class-name":
                                                        "g-icon--small g-clickable"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.isMoreOpen
                                                ? _c("MoreActionPopover", {
                                                    staticClass: "more-popover",
                                                    attrs: {
                                                      item: _vm.content,
                                                      assetId: _vm.contentId,
                                                      assetType:
                                                        _vm.content.format.name,
                                                      routeInfo: _vm.getRouteInfo(),
                                                      router: _vm.$router,
                                                      showEditDelete: false,
                                                      showAddToTrack: true,
                                                      size: "lg",
                                                      fromPage: "Universal card"
                                                    },
                                                    on: {
                                                      "add-to-track": function(
                                                        $event
                                                      ) {
                                                        return _vm.popoverOff(
                                                          "more"
                                                        )
                                                      },
                                                      "close-more": function(
                                                        $event
                                                      ) {
                                                        return _vm.popoverOff(
                                                          "more"
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.isMoreOpen
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "more-tooltip-text",
                                                      attrs: { id: "more-btn" }
                                                    },
                                                    [_vm._v("More")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.content.progress
                                        ? _c("div", [
                                            _vm.content.progress > 0 &&
                                            _vm.content.progress < 100
                                              ? _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c("radial-progress-bar", {
                                                      attrs: {
                                                        diameter: 28,
                                                        "completed-steps":
                                                          _vm.content.progress,
                                                        "total-steps":
                                                          _vm.content
                                                            .progress == "99"
                                                            ? 110
                                                            : 100,
                                                        startColor: "#000000",
                                                        stopColor: "#000000",
                                                        innerStrokeColor:
                                                          "#e0e1e1",
                                                        strokeWidth: 4,
                                                        innerStrokeWidth: 5
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.content.progress
                                                        ) + "%"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm.content.completed ||
                                                _vm.content.progress == 100
                                              ? _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        "icon-class":
                                                          "icon-progress-complete"
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v("completed")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e()
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content-about-container" },
                        [
                          _c(
                            "ion-row",
                            [
                              _c(
                                "ion-col",
                                {
                                  attrs: {
                                    "size-xl": "8.5",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c("ContentCard", {
                                    staticClass: "content-card",
                                    attrs: {
                                      media: _vm.content.media,
                                      content: _vm.content,
                                      "progress-tracking": _vm.progressTracking,
                                      skills: _vm.skills
                                    }
                                  }),
                                  _c("ItemRating", {
                                    attrs: {
                                      item: _vm.content,
                                      scroll: _vm.scroll,
                                      ratingDetails: _vm.ratingDetails,
                                      getUpdatedItemData:
                                        _vm.getUpdatedContentData
                                    },
                                    on: {
                                      "update-ratings": function($event) {
                                        return _vm.getRatingById()
                                      }
                                    }
                                  }),
                                  (_vm.relatedContent !== null) &
                                  (_vm.relatedContent.length > 0)
                                    ? _c("RelatedContent", {
                                        attrs: {
                                          relatedContent: _vm.relatedContent
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    {
                                      attrs: {
                                        "size-xl": "3.5",
                                        "size-lg": "12",
                                        "size-md": "12",
                                        "size-sm": "12",
                                        "size-xs": "12"
                                      }
                                    },
                                    [
                                      _c("ItemMetaData", {
                                        attrs: { item: _vm.content }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding",
                      style: _vm.isDesktopPlatform
                        ? ""
                        : "padding: 2rem 10px 6rem;"
                    },
                    [_c("PageNotFound")],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }