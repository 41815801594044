<template>
  <div class="track-footer">
    <ion-grid>
      <ion-row>
        <ion-col class="footer-image" size-md="6" size="12">
          <img src="@/assets/images/my-learning-add.png" />
        </ion-col>
        <ion-col class="footer-text">
          <h3 class="track-title">Add more content</h3>
          <p class="track-subtitle">
            Add ProEdge content from your <a href="#" @click="gotoBookmarks()">bookmarks</a>, <a href="#" @click="gotoExplore()">Explore</a> section or any search results.
            <span v-if="isExternalAllowed">You can also <a href="#" @click="addExternalLearning">add external learning content</a></span>
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>
  
<script>
import localforage from 'localforage'
import { mapGetters } from 'vuex'
export default {
  name: 'UGTrackFooter',
  components: {},
  props: {},
  data() {
    return {
      isExternalAllowed: false,
      authToken: ''
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async created() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
    const settings = JSON.parse(tenant.settings)
    const externalTenantSetting = {}.hasOwnProperty.call(settings, 'add-external-content-enabled') && settings['add-external-content-enabled']
    this.isExternalAllowed = externalTenantSetting
  },
  mounted() {},
  methods: {
    addExternalLearning() {
      // call modal to add external content
      this.$router.push({ name: 'MyLearning', params: { openExternalPopup: true, type: 'addedbyyou' } }).catch(() => {})
    },
    gotoExplore() {
      this.$router.push('/explore')
    },
    gotoBookmarks() {
      this.$router.push('/bookmarks')
    }
  }
}
</script>

<style lang="scss" scoped>
.track-footer {
  margin-top: 2rem;
  padding: 8px 0px;
  background-color: #fff;
  border-radius: 20px;
  .track-title {
    font-family: 'Futura PT Demi';
    font-size: 60px;
    color: var(--ion-color-tertiary);
    text-align: left;
    padding-top: 4%;
  }
  .track-subtitle {
    text-align: left;
    font-family: Arial;
    color: #5c5c5c;
    font-size: 16px;
    line-height: 28px;

    span {
      text-align: left;
      font-family: Arial;
      color: #5c5c5c;
      font-size: 16px;
      line-height: 28px;
    }

    a {
      color: var(--ion-color-tertiary);
      text-decoration: underline;
      font-family: Arial;
      font-size: 16px;
    }
  }
}

.footer-image {
  padding-left: 4%;
}

.footer-text {
  padding: 16px;
}

@media screen and (max-width: 484px) {
}

@media screen and (max-width: 768px) {
  .track-footer {
    .track-title {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1366px) {
}

@media screen and (min-width: 1366px) {
  .footer-text {
    padding-top: 48px;
  }
}
</style>
  