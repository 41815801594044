var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.dataReady
        ? _c("ion-spinner", {
            staticStyle: { "margin-top": "1rem" },
            attrs: { color: "#1f3370" }
          })
        : _vm._e(),
      _vm.dataReady
        ? _c(
            "div",
            {
              staticClass: "asset-container",
              class: _vm.isDesktopPlatform ? "home-asset-container" : ""
            },
            [
              _c("div", { staticClass: "asset-container-grid" }, [
                _c(
                  "div",
                  { staticClass: "asset-grid-item" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("Explore assets")
                    ]),
                    _c("div", { staticClass: "sub-title" }, [
                      _vm._v(
                        "ProEdge offers hundreds of assets to put your new skills into practice."
                      )
                    ]),
                    _c(
                      "ion-button",
                      {
                        staticClass: "asset-explore-btn",
                        attrs: { shape: "round", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.onExplore()
                          }
                        }
                      },
                      [_vm._v("Explore")]
                    )
                  ],
                  1
                ),
                _vm.tenantAsset.available
                  ? _c(
                      "div",
                      { staticClass: "asset-grid-item" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("Your company assets")
                        ]),
                        _c("div", { staticClass: "sub-title" }, [
                          _vm._v("Find assets your colleagues have created.")
                        ]),
                        _c(
                          "ion-button",
                          {
                            staticClass: "asset-discover-btn",
                            attrs: { shape: "round", color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.onDiscover()
                              }
                            }
                          },
                          [_vm._v("Discover")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tenantAsset.enabled
                  ? _c(
                      "div",
                      { staticClass: "asset-grid-item" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("Submit an asset")
                        ]),
                        _c("div", { staticClass: "sub-title" }, [
                          _vm._v(
                            "Share an asset you've created with others in your organization."
                          )
                        ]),
                        _c(
                          "ion-button",
                          {
                            staticClass: "asset-submit-btn",
                            attrs: { shape: "round", color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.onSubmitAsset()
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.tenantAsset.action) + " "),
                            _vm.tenantAsset.submissionLink.length
                              ? _c(
                                  "span",
                                  { staticStyle: { "padding-left": "5px" } },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        height: "16px",
                                        width: "16px"
                                      },
                                      attrs: { "icon-class": "expand-out" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._m(0)
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "asset-orb-image" }, [
      _c("img", { attrs: { src: require("@/assets/images/asset-orb.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }