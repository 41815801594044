var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "track-footer" },
    [
      _c(
        "ion-grid",
        [
          _c(
            "ion-row",
            [
              _c(
                "ion-col",
                {
                  staticClass: "footer-image",
                  attrs: { "size-md": "6", size: "12" }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/my-learning-add.png")
                    }
                  })
                ]
              ),
              _c("ion-col", { staticClass: "footer-text" }, [
                _c("h3", { staticClass: "track-title" }, [
                  _vm._v("Add more content")
                ]),
                _c("p", { staticClass: "track-subtitle" }, [
                  _vm._v(" Add ProEdge content from your "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.gotoBookmarks()
                        }
                      }
                    },
                    [_vm._v("bookmarks")]
                  ),
                  _vm._v(", "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.gotoExplore()
                        }
                      }
                    },
                    [_vm._v("Explore")]
                  ),
                  _vm._v(" section or any search results. "),
                  _vm.isExternalAllowed
                    ? _c("span", [
                        _vm._v("You can also "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.addExternalLearning }
                          },
                          [_vm._v("add external learning content")]
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }