var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "edge-menu", staticStyle: { overflow: "auto" } },
    [
      _c(
        "ion-row",
        {
          staticClass: "ion-align-items-center",
          staticStyle: { "margin-top": "20px" }
        },
        [
          _c(
            "button",
            {
              staticClass: "slider-close",
              attrs: { "aria-label": "close side menu" },
              on: { click: _vm.toggleSideMenu }
            },
            [
              _c("svg-icon", {
                attrs: {
                  "icon-class": "close-icon",
                  "class-name": "close-button-icon"
                }
              })
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "logo-link",
              attrs: { href: "#", "aria-label": "go to homepage" },
              on: { click: _vm.goToHome }
            },
            [
              _c("svg-icon", {
                staticClass: "logo-icon",
                attrs: { "icon-class": "logo", alt: "proedge logo image" }
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "links-container" }, [
        _c(
          "ul",
          {
            ref: "menuList",
            staticClass: "edge-menu__list",
            class: _vm.showScrollBar ? "show-scrollbar" : ""
          },
          _vm._l(_vm.navBarMenu.links, function(first) {
            return _c(
              "li",
              {
                key: first.name,
                staticClass: "link-list",
                class:
                  _vm.$route.path.includes(first.link) &&
                  first.subLinks.length == 0
                    ? "selected-link"
                    : ""
              },
              [
                first.subLinks.length == 0
                  ? _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "nav," + first.name + ",click",
                            expression: "`nav,${first.name},click`"
                          }
                        ],
                        staticClass: "first-level-link",
                        attrs: {
                          "data-cy": "nav-first-level",
                          to:
                            first.name.toLowerCase() === "home"
                              ? {
                                  name: first.name,
                                  params: { showInProgress: true }
                                }
                              : first.link.includes("/mylearning")
                              ? _vm.getMyLearningUri()
                              : first.link
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.toggleSideMenu($event)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "link-name" }, [
                          _vm._v(_vm._s(first.name))
                        ])
                      ]
                    )
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "sub-menu-nav-item" },
                        [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "nav," + first.name + ",click",
                                  expression: "`nav,${first.name},click`"
                                }
                              ],
                              staticClass: "first-level-link",
                              on: {
                                click: function($event) {
                                  return _vm.toggleSubmenu()
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "link-name" }, [
                                _vm._v(_vm._s(first.name))
                              ])
                            ]
                          ),
                          _c("svg-icon", {
                            class: _vm.subMenuVisible
                              ? "icon-rotated"
                              : "icon-normal",
                            attrs: {
                              "icon-class": "Icon-awesome-chevron-down"
                            },
                            on: {
                              click: function($event) {
                                return _vm.toggleSubmenu()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.subMenuVisible
                        ? _c(
                            "ul",
                            { staticClass: "sub-menu" },
                            [
                              _vm._l(first.subLinks, function(sublink) {
                                return [
                                  sublink.show
                                    ? _c(
                                        "li",
                                        {
                                          key: sublink.name,
                                          class: {
                                            "selected-link":
                                              _vm.subLink === sublink.link &&
                                              _vm.$route.path == first.link
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "second-level-link",
                                              attrs: {
                                                "class-active": "active "
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.navigateTo(
                                                    sublink.link,
                                                    first.name
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(sublink.name))]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ])
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "menu", staticStyle: { "margin-top": "48px" } },
          [
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "track",
                    rawName: "v-track",
                    value: "nav,bookmarks<" + _vm.user_id + ">,click",
                    expression: "`nav,bookmarks<${user_id}>,click`"
                  }
                ],
                staticClass: "menu-link",
                attrs: { to: "/bookmarks" },
                nativeOn: {
                  click: function($event) {
                    return _vm.toggleSideMenu($event)
                  }
                }
              },
              [
                _c("p", { staticClass: "ion-no-margin menu-text" }, [
                  _vm._v("Bookmarks")
                ])
              ]
            )
          ],
          1
        ),
        _vm.tenantAsset.visible
          ? _c("div", { staticClass: "menu" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "nav,submitAsset<" + _vm.user_id + ">,click",
                      expression: "`nav,submitAsset<${user_id}>,click`"
                    }
                  ],
                  staticClass: "menu-link",
                  attrs: { href: "#" },
                  on: { click: _vm.onSubmitAsset }
                },
                [
                  _c(
                    "p",
                    { staticClass: "ion-no-margin menu-text" },
                    [
                      _vm._v(" " + _vm._s(_vm.tenantAsset.action) + " "),
                      _vm.tenantAsset.submissionLink.length
                        ? _c("svg-icon", {
                            staticClass: "asset-icon",
                            attrs: { "icon-class": "expand-out-icon" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "help" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "nav,user<" + _vm.user_id + ">,help",
                  expression: "`nav,user<${user_id}>,help`"
                }
              ],
              staticClass: "help-text",
              attrs: { href: "#" },
              on: { click: _vm.onGetHelp }
            },
            [
              _c("p", { staticClass: "ion-no-margin menu-text" }, [
                _vm._v("Help Center")
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "logout" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "nav,user<" + _vm.user_id + ">,logout",
                  expression: "`nav,user<${user_id}>,logout`"
                }
              ],
              staticClass: "logout-text",
              attrs: { href: "#" },
              on: { click: _vm.onLogout }
            },
            [
              _c("p", { staticClass: "ion-no-margin menu-text" }, [
                _vm._v("Logout")
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }