var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-landing g-margin__top--small" },
    [
      _c(
        "ion-slides",
        { staticClass: "landing-mobile", attrs: { pager: "" } },
        [
          _vm._l(_vm.slides, function(slide, index) {
            return _c("ion-slide", { key: index }, [
              _c("div", [
                _c("img", {
                  staticClass: "edge-landing__img",
                  attrs: {
                    src: require("@/assets/images/" + slide.image),
                    alt: ""
                  }
                }),
                _c("div", { staticClass: "edge-landing__text" }, [
                  _c(
                    "h4",
                    { staticClass: "g-type-mlarge edge-landing__text--title" },
                    [_vm._v(_vm._s(slide.title))]
                  ),
                  _c("div", { staticClass: "edge-landing__text--subtitle" }, [
                    _vm._v(_vm._s(slide.subTitle))
                  ])
                ])
              ])
            ])
          }),
          _c("br")
        ],
        2
      ),
      _vm.message !== ""
        ? _c("div", { staticClass: "error-msg" }, [
            _vm._v(" " + _vm._s(_vm.message) + " ")
          ])
        : _vm._e(),
      _vm.appBanner && !_vm.isNativeApp
        ? _c(
            "div",
            { staticClass: "edge-landing__banner" },
            [
              _c(
                "ion-grid",
                { staticClass: "ion-no-padding" },
                [
                  _c(
                    "ion-row",
                    { staticClass: "ion-no-padding" },
                    [
                      _c(
                        "ion-col",
                        [
                          _c("svg-icon", {
                            staticClass: "g-icon--small close g-clickable",
                            staticStyle: { position: "absolute", right: "0" },
                            attrs: { "icon-class": "close" },
                            on: { click: _vm.onCancel }
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "ion-no-margin",
                              staticStyle: { "font-size": "1.4rem" }
                            },
                            [_vm._v("ProEdge is better on the App")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "ion-row",
                    { staticStyle: { "padding-right": "13px" } },
                    [
                      _c("ion-col", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "130px",
                              "margin-left": "auto",
                              "margin-right": "0",
                              "padding-top": "9px"
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://apps.apple.com/us/app/proedge-learn/id1540658579"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/apple-store-badge.svg"),
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]),
                      _c("ion-col", { staticClass: "ion-text-start" }, [
                        _c("div", { staticStyle: { width: "147px" } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://play.google.com/store/apps/details?id=com.pwc.gx.mobility.proedgelearn"
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/google-play-badge.png"),
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a",
        {
          staticClass: "sign-up",
          attrs: {
            "data-cy": "learn-mobile-signup",
            shape: "round",
            color: "primary"
          },
          on: {
            click: function($event) {
              return _vm.$router.push("/onboarding/create-account")
            }
          }
        },
        [_vm._v("Register")]
      ),
      _c(
        "div",
        { staticClass: "g-type-small g-margin__top--small signup-label" },
        [_vm._v("Already registered?")]
      ),
      _c(
        "ion-button",
        {
          staticClass:
            "ion-margin-top edge-landing__button ion-button-tertiary g-margin__top--xsmall",
          attrs: {
            "data-cy": "learn-mobile-login",
            shape: "round",
            color: "tertiary"
          },
          on: {
            click: function($event) {
              return _vm.$router.push("/login")
            }
          }
        },
        [_vm._v("Login")]
      ),
      _c("div", { staticClass: "edge-landing__footer" }, [
        _c(
          "a",
          {
            staticClass: "privacy",
            on: {
              click: function($event) {
                _vm.$router.push("/help-center-main").catch(function() {}),
                  _vm.$emit("help-center", "help-center")
              }
            }
          },
          [_vm._v("Help Center")]
        ),
        _c(
          "a",
          {
            staticClass: "privacy",
            on: {
              click: function($event) {
                return _vm.openPrivacy()
              }
            }
          },
          [_vm._v("Privacy Policy")]
        )
      ]),
      _c("div", { staticClass: "edge-landing__footer" }, [
        _c(
          "a",
          {
            staticClass: "privacy",
            on: {
              click: function($event) {
                return _vm.openCookieNotice()
              }
            }
          },
          [_vm._v("Cookie Notice")]
        ),
        _c(
          "a",
          {
            staticClass: "terms",
            on: {
              click: function($event) {
                return _vm.openTerms()
              }
            }
          },
          [_vm._v("Terms & Conditions")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }