var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rating-container", attrs: { id: "item-rating-modal" } },
    [
      _c(
        "div",
        { staticClass: "rating-header" },
        [
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.ratingCommentEnabled ? "Rate and review" : "Leave a rating"
              )
            )
          ]),
          _c("svg-icon", {
            staticClass: "g-icon--small close g-clickable",
            attrs: { "icon-class": "filter-close" },
            on: { click: _vm.onCancel }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rating-modal-content" },
        [
          _c(
            "ion-row",
            { staticClass: "item-info" },
            [
              _c(
                "ion-col",
                { staticClass: "item-img-background ion-no-padding" },
                [
                  _vm.item.format.name.toLowerCase() !== "asset"
                    ? _c("div", [
                        {}.hasOwnProperty.call(_vm.item, "image") &&
                        {}.hasOwnProperty.call(_vm.item.image, "uri")
                          ? _c("img", { attrs: { src: _vm.item.image.uri } })
                          : _c("img", { attrs: { src: _vm.getDefaultImage() } })
                      ])
                    : _c("div", [
                        _vm.item.format.name.toLowerCase() == "asset"
                          ? _c("img", {
                              attrs: {
                                src: _vm.fetchAssetThumbnailForAsset(
                                  _vm.item.inventoryType.name
                                )
                              }
                            })
                          : _vm._e()
                      ])
                ]
              ),
              _c("ion-col", { staticClass: "ion-no-padding" }, [
                _c("div", { staticClass: "meta" }, [
                  _c(
                    "div",
                    {
                      staticClass: "format",
                      class:
                        _vm.item.format.name.toLowerCase() == "course"
                          ? "course"
                          : _vm.item.format.name.toLowerCase() == "collection"
                          ? "collection"
                          : "artifacts"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.format && _vm.item.format.name) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.item.title))
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "overall-rating-container" },
            [
              _c("div", { staticClass: "overall-rating-text" }, [
                _vm._v("Rating")
              ]),
              _c("StarRating", {
                staticClass: "rating",
                attrs: {
                  increment: 1,
                  "inactive-color": "#d0cfce",
                  "active-color": "#B98032",
                  "star-size": 42,
                  "show-rating": false,
                  "round-start-rating": true,
                  rating: _vm.rating
                },
                on: { "rating-selected": _vm.onRating }
              })
            ],
            1
          ),
          (({}.hasOwnProperty.call(_vm.ratingDetails, "userRating") &&
            !_vm.ratingDetails.userRating.flagged) ||
            !{}.hasOwnProperty.call(_vm.ratingDetails, "userRating")) &&
          _vm.ratingCommentEnabled
            ? _c(
                "div",
                { staticClass: "overall-review-container" },
                [
                  _c("div", { staticClass: "overall-review-text" }, [
                    _vm._v("Add a written review")
                  ]),
                  _c("ion-textarea", {
                    attrs: {
                      rows: _vm.isDesktopPlatform ? 4 : 9,
                      placeholder: _vm.reviewCommentPlaceholder,
                      value: _vm.reviewComment
                    },
                    on: {
                      ionInput: function($event) {
                        return _vm.handleInput($event)
                      }
                    }
                  }),
                  _vm.reviewCommentError
                    ? _c("div", { staticClass: "error-container" }, [
                        _c("p", [
                          _vm._v("You have reached the 1000 character limit")
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "sumbit-btn-container" },
            [
              _c(
                "ion-button",
                {
                  staticClass: "sumbit-btn",
                  staticStyle: { "--box-shadow": "none" },
                  attrs: {
                    shape: "round",
                    color: "primary",
                    disabled: _vm.isSubmitDisabled()
                  },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }