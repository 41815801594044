<template>
  <div :class="isDesktopPlatform ? 'container-md' : 'container-mobile'">
    <div :class="isDesktopPlatform ? 'mylearning-own-item-container mylearning-ownitem-empty-container' : 'mylearning-ownitem-empty-container'">
      <div class="mylearning-ownitem-empty-container-grid">
        <div>
          <div class="title">Let's get started</div>
          <div class="sub-title">Want to add content you found or completed outside of ProEdge? All you need is a URL and some basic details.</div>
          <div class="action-btns">
            <ion-button shape="round" color="primary" class="learning-plan-btn" title="Add external learning" @click="openExternalSidebar">
              <div class="add-icon-container">
                <svg-icon icon-class="add-icon" style="height: 12px; width: 12px" />
              </div>
              Add
            </ion-button>
          </div>
        </div>
      </div>
      <div class="mylearning-ownitem-empty-image">
        <img src="@/assets/images/my-learning-add.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyLearningAddedByYouEmpty',
  components: {},
  props: {},
  data() {
    return {
      isDesktopPlatform: true
    }
  },
  methods: {
    openExternalSidebar() {
      this.$emit('open-external-sidebar')
    }
  }
}
</script>


<style lang="scss">
.container-mobile .completed-pagination span {
  font-size: 14px;
  padding: 0px 2px;
}

.completed-pagination {
  margin-top: 2rem;
  border-top: 2px solid var(--ion-color-light-gray);
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.pagination-item {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

li.pagination-nav {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.pagination-item-link {
  color: var(--ion-color-post-gray);
  font-size: 14px !important;
  vertical-align: middle;
}

.pagination-nav-link {
  position: relative;
  color: #5c5c5c;
  font-size: 3rem !important;
  vertical-align: middle;
  font-family: 'Futura PT Book';
}

li.disabled {
  border: none;
  cursor: default;

  .pagination-item-link {
    cursor: default;
  }
}

li.active {
  border: 2px solid #1dd1b0;
  border-radius: 4px;
  background: white;

  .pagination-item-link {
    color: #000000;
  }
}

li:hover {
  background: #e0e1e1;
}

li .tooltiptext {
  visibility: hidden;
  width: max-content;
  height: 30px;
  background-color: #efefef;
  color: #000000de;
  text-align: center;
  padding: 7px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -20px;
  font-size: 12px;
  font-family: 'Futura PT Demi';
}

.li:hover .tooltiptext {
  visibility: visible;
}
</style>
    
<style lang="scss" scoped>
.container-mobile {
  margin-top: 1rem;
}

.mylearning-ownitem-empty-container {
  display: flex;
  align-items: center;

  @media screen and (max-width: 414px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}

.mylearning-ownitem-empty-container-grid {
  display: grid;
  width: 50%;

  @media screen and (min-width: 1025px) {
    padding: 0em 2em;
  }

  div {
    text-align: left;
  }
}

.title {
  text-align: left;
  width: 420px;
  font-size: 60px;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-tertiary);
}

.sub-title {
  margin-top: 1rem;
  text-align: left;
  width: 420px;
  font-size: 16px;
  line-height: 1.5rem;
  font-family: 'Arial';
  color: var(--ion-color-medium);
}

.link-btn {
  font-size: 1rem;
  font-family: 'Arial';
  text-decoration: none !important;
  color: var(--ion-color-tertiary);
}

.action-btns {
  display: flex;
  margin-top: 1.5rem;
  align-content: left;
}

.learning-plan-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  margin-right: 1rem;
  width: 110px !important;
  height: 50px !important;
  --ion-color-primary-contrast: var(--ion-color-tertiary);
}

.explore-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: var(--ion-color-tertiary);
  --ion-color-primary-contrast: var(--ion-color-white);
}

.filter-bar {
  position: fixed;
  z-index: 9999;
  right: 0;
  width: 364px;
}

.mylearning-ownitem-empty-image {
  width: 40%;
  padding: 0 2em;

  @media screen and (max-width: 414px) {
    width: 100%;
  }

  img {
    width: auto;
  }
}

ion-button {
  --box-shadow: none;
}

@media screen and (max-width: 1024px) {
  .mylearning-ownitem-empty-container-grid {
    width: 50%;
  }

  .title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .mylearning-ownitem-empty-container-grid {
    width: 85%;
  }
}

@media screen and (max-width: 414px) {
  .mylearning-ownitem-empty-container {
    display: flex;
    padding: 0.5rem;
  }

  .mylearning-ownitem-empty-container-grid {
    width: 100%;
    gap: 2rem;
    margin: 0rem 0.5rem;
  }

  .filter-bar {
    width: 100%;
  }

  .title,
  .sub-title {
    width: 100%;
  }
}

.add-icon-container {
  display: inline-block;
  margin: 0px 10px 0px 0px;
  padding-top: 1px;
  padding-left: 3px;
  text-align: center;
  width: 18px;
  height: 18px;
  color: white;
  background-color: #1f3370;
  border-radius: 50%;
}
</style>