var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "external-form-container" },
        [
          _c(
            "ion-grid",
            { staticClass: "ion-no-padding external-content-modal" },
            [
              _c("ion-row", [
                _c("p", { staticClass: "typeahead-header" }, [
                  _vm._v(_vm._s(_vm._f("formatType")(_vm.type)) + " URL *")
                ]),
                _c("input", {
                  ref: "url",
                  staticClass: "url-input form-input",
                  style: [_vm.isUrl.error ? { "border-color": "#e0301e" } : {}],
                  attrs: { type: "url", placeholder: "https://" },
                  domProps: { value: _vm.url },
                  on: {
                    input: _vm.onUrlUpdate,
                    blur: _vm.onFieldValidate,
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onValidateURL($event)
                    }
                  }
                }),
                _vm.isUrl.error
                  ? _c("p", { staticClass: "url-error-message" }, [
                      _vm._v(
                        "Please enter a valid URL (should begin with https:// or http://)"
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "ion-row",
                [
                  _c("ion-col", [
                    !_vm.isUrl.exist
                      ? _c(
                          "div",
                          { staticClass: "action-btns" },
                          [
                            _c(
                              "ion-button",
                              {
                                staticClass: "next-btn",
                                attrs: {
                                  shape: "round",
                                  disabled: _vm.isNextBtnDisabled,
                                  color: "primary"
                                },
                                on: { click: _vm.onValidateURL }
                              },
                              [_vm._v("Next")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm.isUrl.exist
                ? _c(
                    "div",
                    [
                      _c("ion-row", [
                        _c("p", { staticClass: "typeahead-header" }, [
                          _vm._v("Title *")
                        ]),
                        _c("input", {
                          ref: "title",
                          staticClass: "url-input form-input",
                          attrs: {
                            name: "title",
                            type: "text",
                            placeholder: "Title of the Content"
                          },
                          domProps: { value: _vm.fields.title },
                          on: {
                            input: _vm.onFieldUpdate,
                            blur: _vm.onFieldValidate
                          }
                        })
                      ]),
                      _c("ion-row", [
                        _c("p", { staticClass: "typeahead-header" }, [
                          _vm._v("Description")
                        ]),
                        _c("textarea", {
                          ref: "description",
                          staticClass: "form-input",
                          attrs: {
                            name: "synopsis",
                            rows: "4",
                            placeholder: "Description of the content (optional)"
                          },
                          domProps: { value: _vm.fields.synopsis },
                          on: { input: _vm.onFieldUpdate }
                        })
                      ]),
                      _c("ion-row", [
                        _c("p", { staticClass: "typeahead-header" }, [
                          _vm._v(" Source * "),
                          _c("span", { staticClass: "typeahead-tooltip" }, [
                            _c("img", {
                              staticClass: "info-icon",
                              attrs: {
                                src: require("@/assets/images/svgs/icon-info.svg"),
                                alt: "Info"
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "typeahead-tooltiptext" },
                              [
                                _vm._v(
                                  "E.g. TED, YouTube, Medium, LinkedIn Learning"
                                )
                              ]
                            )
                          ])
                        ]),
                        _c("input", {
                          ref: "source",
                          staticClass: "url-input form-input",
                          attrs: {
                            name: "source",
                            type: "text",
                            placeholder: "Name of the content source"
                          },
                          domProps: { value: _vm.fields.source },
                          on: {
                            input: _vm.onFieldUpdate,
                            blur: _vm.onFieldValidate
                          }
                        })
                      ]),
                      _c("ion-row", [
                        _c("p", { staticClass: "typeahead-header" }, [
                          _vm._v(" Duration * "),
                          _c("span", { staticClass: "typeahead-tooltip" }, [
                            _c("img", {
                              staticClass: "info-icon",
                              attrs: {
                                src: require("@/assets/images/svgs/icon-info.svg"),
                                alt: "Info"
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "typeahead-tooltiptext" },
                              [
                                _vm._v(
                                  "A good estimate for articles is 1 minute per 200 words. Provide your best guess for longer courses."
                                )
                              ]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "duration-input-container" }, [
                          _c("input", {
                            ref: "durationHour",
                            staticClass: "duration-input form-input",
                            attrs: {
                              name: "durationHour",
                              type: "number",
                              min: "0"
                            },
                            domProps: { value: _vm.duration.hour },
                            on: {
                              input: _vm.onHourUpdate,
                              blur: _vm.onFieldValidate
                            }
                          }),
                          _vm._v(" Hours "),
                          _c("input", {
                            ref: "durationMinute",
                            staticClass: "duration-input form-input",
                            attrs: {
                              name: "durationMinute",
                              type: "number",
                              min: "0"
                            },
                            domProps: { value: _vm.duration.minute },
                            on: {
                              input: _vm.onMinuteUpdate,
                              blur: _vm.onFieldValidate
                            }
                          }),
                          _vm._v(" Minutes ")
                        ])
                      ]),
                      _c(
                        "ion-row",
                        { staticStyle: { "flex-direction": "column" } },
                        [
                          _c("p", { staticClass: "typeahead-header" }, [
                            _vm._v("Skills")
                          ]),
                          _c(
                            "div",
                            { staticClass: "search-wrapper" },
                            [
                              _c("ion-searchbar", {
                                ref: "searchBar",
                                staticClass: "skills-search",
                                attrs: {
                                  placeholder: "Add new skill",
                                  debounce:
                                    _vm.$platform === "Mobile" ? "500" : "250"
                                },
                                on: { ionChange: _vm.handleSearchInput }
                              }),
                              _c(
                                "div",
                                { staticClass: "skills-list-container" },
                                [
                                  _c(
                                    "ion-scroll",
                                    { staticClass: "scrollable" },
                                    [
                                      _vm.showDropDownList
                                        ? _c(
                                            "ion-list",
                                            {
                                              staticClass: "search-skills-list",
                                              attrs: { lines: "none" }
                                            },
                                            _vm._l(_vm.searchedSkills, function(
                                              skill
                                            ) {
                                              return _c(
                                                "ion-item",
                                                {
                                                  key: skill.name,
                                                  staticClass:
                                                    "search-list-item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addSkills(
                                                        $event,
                                                        skill
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(skill.name))]
                                              )
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "existing-skills-list" },
                                [
                                  _vm.skills && _vm.skills.length
                                    ? _c("SkillsList", {
                                        key: _vm.skills.length,
                                        attrs: {
                                          from: "external-form",
                                          skills: _vm.skills,
                                          isSkillRemoveable: false
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isUrl.exist
        ? _c(
            "ion-grid",
            { staticClass: "ion-no-padding external-content-modal" },
            [
              _c(
                "ion-row",
                { staticClass: "footer-container" },
                [
                  !_vm.editContentData
                    ? _c(
                        "ion-col",
                        { staticClass: "ion-align-self-center" },
                        [
                          _c("ion-checkbox", {
                            staticClass: "complete-checkbox",
                            attrs: {
                              checked: _vm.fields.isComplete,
                              value: _vm.fields.isComplete,
                              color: "tertiary",
                              mode: "md"
                            },
                            on: {
                              ionChange: function($event) {
                                return _vm.onMarkComplete($event)
                              }
                            }
                          }),
                          _c("span", { staticClass: "complete-text" }, [
                            _vm._v("Mark completed")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("ion-col", { staticClass: "ion-align-self-center" }, [
                    _c(
                      "div",
                      { staticClass: "ion-float-end" },
                      [
                        _c(
                          "ion-button",
                          {
                            staticClass:
                              "ion-button-primary save-button-primary",
                            class: _vm.editContentData ? "ion-float-right" : "",
                            attrs: {
                              disabled: _vm.isSaveBtnDisabled,
                              shape: "round",
                              color: "primary"
                            },
                            on: { click: _vm.onSave }
                          },
                          [_vm._v("Save ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }