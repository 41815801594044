<template>
  <div class="profile-fixed-side-nav" v-if="isDesktopPlatform">
    <div class="profile-name-content">
      <div class="profile-short-name">{{ getShortName() }}</div>
      <h3>{{ profileDetails.name }}</h3>
    </div>
    <div class="nav-container">
      <ul>
        <template v-for="nav of sideNavItems">
          <li :key="nav.link" :class="{ selected: nav.link == selectedTab }" v-if="nav.show">
            <a href="#" class="side-profile-nav-link" @click="changeDetailsTab(nav.link)">{{ nav.name }}</a>
          </li>
        </template>
      </ul>
    </div>
  </div>
  <div v-else class="mob-side-nav">
    <div class="mob-name-content">
      <div class="mob-short-name">{{ getShortName() }}</div>
      <h3>{{ profileDetails.name }}</h3>
    </div>
    <div class="mob-tabs-container">
      <div class="selected-tab" @click="toggleTabs">
        {{ getTabName(selectedTab) }}
        <i class="dropdown-arrow"></i>
      </div>
    </div>
    <div v-if="tabsOpen" class="overlay" @click="tabsOpen = false">
      <ul class="tabs-selection">
        <template v-for="tab in sideNavItems">
          <li :key="tab.link" :class="tab.link === selectedTab ? 'selected' : ''" :value="tab.name" @click="changeDetailsTab(tab.link)" v-if="tab.show">
            {{ tab.name }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import localforage from 'localforage'
export default {
  name: 'ProfileSideNav',
  components: {},
  props: {
    profileDetails: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    selectedTab: {
      type: String,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      sideNavItems: [],
      tabsOpen: false,
      isDesktopPlatform: true,
      sideNavHeight: window.innerHeight
    }
  },
  async mounted() {
    this.isDesktopPlatform = window.innerWidth > 1024 ? true : false
    this.resizeHandler()
    this.getSideNavItems()
  },
  methods: {
    async getSideNavItems() {
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      const showLearningHistory = enabledFeatureFlags['learn_learning_history_enabled'] || false
      this.sideNavItems = [
        { name: 'Learning history', link: 'learningHistory', show: showLearningHistory },
        { name: 'Skills', link: 'skills', show: true },
        { name: 'Profile details', link: 'profileDetails', show: true }
      ]
    },
    getShortName() {
      const firstName = this.profileDetails.firstName ? this.profileDetails.firstName[0] : ''
      const lastName = this.profileDetails.lastName ? this.profileDetails.lastName[0] : ''
      return `${firstName}${lastName}`.toUpperCase()
    },
    async changeDetailsTab(tabName) {
      this.$emit('setSelectedTab', tabName)
      await localforage.setItem('profile-side-nav-changed', tabName)
      this.$eventBus.$emit('profile-side-nav-changed', tabName)
    },
    toggleTabs() {
      this.tabsOpen = !this.tabsOpen
    },
    getTabName(item) {
      const navItem = this.sideNavItems.filter((nav) => nav.link == item)
      return navItem.length > 0 ? navItem[0].name : ''
    },
    resizeHandler() {
      this.sideNavHeight = window.innerHeight
      if (this.sideNavHeight < 724 && this.sideNavHeight > 718 && this.isDesktopPlatform) {
        const element = document.getElementsByClassName('profile-name-content')[0]
        element.style.height = '240px'
        element.style.marginBottom = '2.7rem'
        const item = document.getElementsByClassName('nav-container')[0].childNodes[0].childNodes
        item.forEach((ele) => {
          ele.style.paddingBottom = '.6rem'
          ele.style.paddingTop = '.6rem'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-fixed-side-nav {
  background: #efefef;
  width: 100%;
  margin-top: 8rem;
  max-width: 428px;
  position: fixed;

  .profile-name-content {
    width: 380px;
    height: 246px;
    background: #fff;
    position: relative;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;

    .profile-short-name {
      width: 174px;
      height: 174px;
      border-radius: 50%;
      background: #1f3370;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: calc(50% - 87px);
      top: calc(-50% + 43px);
      font: normal normal normal 32px/40px 'Futura PT Demi';
    }

    h3 {
      font: normal normal normal 28px/36px 'Futura PT Demi';
      color: #000000;
    }
  }

  .nav-container {
    ul {
      list-style: none;
      text-align: left;
      padding-left: 0;

      li {
        padding-left: 3rem;
        padding-bottom: 0.875rem;
        padding-top: 0.875rem;

        a {
          font: normal normal normal 18px/18px Arial;
          color: #1f3370;
          text-decoration: none;
          &:focus {
            text-decoration: underline;
          }
          &:hover {
            background: unset;
            text-decoration: underline;
          }
        }

        &.selected {
          border-left: 6px solid #1dd1b0;

          a {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.mob-side-nav {
  @media (max-width: 500px) {
    padding: 0 1rem;
  }

  .mob-name-content {
    display: flex;
    align-items: center;
    padding-top: 2rem;

    .mob-short-name {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #1f3370;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal normal 24px/40px 'Futura PT Demi';
      margin-right: 1rem;
    }

    h3 {
      font: normal normal normal 24px/32px 'Futura PT Demi';
    }
  }

  .mob-tabs-container {
    position: relative;
    margin: 2rem 0 0 0;

    .selected-tab {
      font: normal normal bold 14px/22px Arial;
      color: var(--ion-color-black);
      display: inline-block;
      padding-bottom: 0.5rem;
      border-bottom: 4px solid var(--ion-color-secondary-lighter);

      span {
        margin-left: 0.3rem;
        padding: 0rem 0.7rem;
        border-radius: 1rem;
        background: var(--ion-color-black);
        font-size: 14px;
        color: var(--ion-color-white);
        font-family: 'Arial';
      }

      .dropdown-arrow {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid var(--ion-color-black);
        display: inline-block;
        margin-left: 0.6rem;
      }
    }
  }

  .mob-tabs-container {
    display: flex;
    justify-content: unset;
  }

  .mob-tabs-container .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .tabs-selection {
    position: fixed;
    top: 140px;
    left: 5%;
    background: var(--ion-color-white);
    padding: 4px;
    width: 225px;
    z-index: 10;
    border-radius: 4px;
    list-style-image: none;

    li {
      list-style-type: none;
      color: var(--ion-color-dark);
      padding: 0.8rem 0.8rem;
      margin: 0.3rem 0.1rem;
      text-align: start;
      border-radius: 6px;
      font-family: 'Arial';
      font-size: 14px;

      span {
        margin-left: 0.3rem;
        padding: 0rem 0.7rem;
        border-radius: 1rem;
        background: var(--ion-color-light-role-grey);
        font-size: 18px;
        font-family: Arial;
        color: var(--ion-color-black);
      }

      &.selected {
        background-color: #e9ebf1;
        color: var(--ion-color-dark-blue);

        span {
          background: var(--ion-color-black);
          color: var(--ion-color-white);
        }
      }
    }
  }
}
</style>
  