<template>
  <div class="layout-container">
    <transition name="slide">
      <TrackSideBar v-if="showTrackBar" :addItem="item" :key="item.id"></TrackSideBar>
      <MyLearningAddExternalSideBar
        v-if="isExternalSidebarActive"
        :isEdit="isEdit"
        :externalData="external"
        @on-toggle-external-sidebar="onToggleExternalSidebar"
        class="add-external-sidebar-container"
        :class="$platform === 'Mobile' ? 'add-external-sidebar-container-mobile' : ''"
      />
    </transition>
    <div v-if="showNavOverlay()" class="nav-overlay" @click.stop="closeTrackBar"></div>
    <component :is="component" />
    <HomeGoalSettingModal
      v-if="showGoalSettingModal"
      @closeGoalSettingModal="showGoalSettingModal = false"
      :goalSettingData="goalSettingData"
      @saveGoalSettingModal="onSaveGoalSetting($event)"
    ></HomeGoalSettingModal>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { mapGetters } from 'vuex'
import { bootstrap } from 'vue-gtag'
import LayoutMobile from '@/layouts/LayoutMobile'
import LayoutDesktop from '@/layouts/LayoutDesktop'
import LandingMobile from '@/views/landing/LandingMobile'
import LandingDesktop from '@/views/landing/LandingDesktop'
import TrackSideBar from '@/views/search/components/TrackSideBar'
import HomeGoalSettingModal from '@/views/home/components/HomeGoalSettingModal.vue'
import MyLearningAddExternalSideBar from '@/views/my-learning/components/MyLearningAddExternalSideBar.vue'
import ItemRatingModal from '@/components/molecules/ItemRatingModal'

export default {
  name: 'Layout',
  components: { LayoutMobile, LayoutDesktop, LandingMobile, LandingDesktop, TrackSideBar, HomeGoalSettingModal, MyLearningAddExternalSideBar, ItemRatingModal },
  props: {},
  data() {
    return {
      isLoggedIn: false,
      showTrackBar: false,
      item: {},
      goalSettingData: {},
      showGoalSettingModal: false,
      isExternalSidebarActive: false,
      isEdit: false,
      external: null
    }
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags']),
    component() {
      const landingRoute = (this.$route.path === '/' || this.$route.path === '/landing') && window.location.pathname !== '/home'
      const modal =
        (this.$route.query && this.$route.query.modal === 'Welcome') ||
        (this.$router.history && this.$router.history.pending && this.$router.history.pending.query && this.$router.history.pending.query.modal === 'Welcome')
      if (landingRoute && this.$platform === 'Desktop' && (!this.isLoggedIn || modal)) return 'LandingDesktop'
      if (landingRoute && this.$platform === 'Mobile' && !this.isLoggedIn) return 'LandingMobile'
      return this.$platform === 'Desktop' ? 'LayoutDesktop' : 'LayoutMobile'
    }
  },
  watch: {},
  created() {
    this.$eventBus.$on('on-open-external-sidebar', this.openExternalSideBar)
    this.$eventBus.$on(`add-to-track`, this.addToTrack)
    this.$eventBus.$on(`close-track-bar`, this.closeTrackBar)
    this.$eventBus.$on(`edit-ug-track`, this.addToTrack)
    this.$eventBus.$on('show-goal-settings-modal', this.showGoalSettings)
  },
  beforeMount() {
    this.isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))
  },
  destroyed() {
    this.$eventBus.$off(`add-to-track`)
    this.$eventBus.$off(`close-track-bar`)
    this.$eventBus.$off(`edit-ug-track`)
    this.$eventBus.$off('on-open-external-sidebar')
  },
  async mounted() {
    await this.isGATracking()
  },
  async activated() {
    await this.isGATracking()
  },
  methods: {
    onSaveGoalSetting(data) {
      this.showGoalSettingModal = false
      this.goalSettingData = data
      this.$eventBus.$emit('close-goal-settings-modal', data)
    },
    showGoalSettings(obj) {
      this.goalSettingData = obj
      this.showGoalSettingModal = true
    },
    async isGATracking() {
      const isGAEnabled = await gtag.isGAEnabled()
      bootstrap().then(() => {
        if (isGAEnabled) {
          this.$gtag.config({ cookie_prefix: '_proedge' })
          this.$gtag.optIn()
        } else {
          this.$gtag.optOut()
          this.$gtag.config({ client_storage: 'none' })
        }
      })
    },
    closeTrackBar() {
      this.showTrackBar = false
    },
    addToTrack(item) {
      this.item = item
      this.showTrackBar = true
    },
    showNavOverlay() {
      return this.showTrackBar || this.isExternalSidebarActive
    },
    openExternalSideBar(e) {
      if(e.isEdit){
        this.isEdit = e.isEdit
        this.external = e.external
        this.isExternalSidebarActive = true
      }
      else { 
        this.isExternalSidebarActive = e 
      }
    },
    onToggleExternalSidebar() {
      this.isExternalSidebarActive = !this.isExternalSidebarActive
      this.isEdit = false
      this.external = null
      this.$eventBus.$emit('on-toggle-external-sidebar', this.isExternalSidebarActive)
    }
  }
}
</script>

<style lang="scss">
.layout-container {
  height: 100%;
  ion-content {
    height: 100vh;
  }
  height: 100%;
}

.layout-component {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.nav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000033;
  z-index: 101;
}
.add-external-sidebar-container {
  position: fixed;
  z-index: 9999;
  right: 0;
  width: 640px;
  border-radius: 20px 0 0 20px;
}

.add-external-sidebar-container-mobile {
  height: 100vh !important;
  padding-top: 3.75rem !important;
}

@media screen and (max-device-width: 767px) {
  .add-external-sidebar-container {
    width: 100% !important;
    border-radius: 10px 0 0 10px !important;
  }
}
</style>
