<template>
  <div class="submission-details-container main-content">
    <div v-if="showDownloadProgress" class="custom-toast custom-toast-loading">
      <div style="margin-top: 2rem">
        <ion-progress-bar color="secondary" type="indeterminate"></ion-progress-bar>
      </div>
      <div>
        <span class="status-message-normal">Download is in progress</span>
      </div>
      <div class="toast-footer"></div>
    </div>
    <button class="back" @click="$router.push('/submit-asset')">
      <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
      Back
    </button>
    <div v-if="dataReady" :class="isDesktopPlatform ? 'submission-details-content' : 'submission-details-content-mobile'">
      <div class="submission-details-status" v-if="submittedAsset.reviewStatus">
        {{
          [reviewStatus.inDraft.value, reviewStatus.inEdit.value].includes(submittedAsset.reviewStatus.toUpperCase()) ? reviewStatus.inDraft.message : submittedAsset.reviewStatus
        }}
      </div>
      <div class="submission-details">
        <div class="icon-container">
          <div class="icon-tooltip" v-if="isEditMode && isDesktopPlatform">
            <svg-icon icon-class="edit-icon" class="action-icon" alt="edit-image" @click="onEditClick" />
            <span class="icon-tooltiptext">Edit</span>
          </div>
          <div class="icon-tooltip" v-if="isSubmitMode && isDesktopPlatform">
            <svg-icon icon-class="submit-icon" class="action-icon" alt="submit-image" @click="openModal(submitConfirmationModal)" />
            <span class="icon-tooltiptext">{{ submittedAsset.reviewStatus.toUpperCase() === 'RETURNED' ? 'Resubmit' : 'Submit' }}</span>
          </div>
          <div class="icon-tooltip" v-if="isDeleteMode">
            <svg-icon icon-class="delete-icon" class="action-icon" alt="delete-image" @click="openModal(deleteConfirmationModal)" />
            <span :class="isDesktopPlatform ? 'icon-tooltiptext' : 'icon-tooltiptext tooltip-visibility'">Delete</span>
          </div>
        </div>
        <ion-grid>
          <ion-row>
            <ion-col class="ion-no-padding column-container header">
              <div class="format">ASSET PREVIEW</div>
              <div class="provider">ID {{ submittedAsset.displayId }} <span class="divider">|</span> LAST SAVED {{ submittedAsset.updatedAt | formatDate }}</div>
            </ion-col>
          </ion-row>
          <ion-row class="submission-details-line">
            <ion-col
              v-if="['RETURNED', 'APPROVED', 'REJECTED'].includes(submittedAsset.reviewStatus.toUpperCase()) && submittedAsset.isAdminEdited"
              size-xl="12"
              size-lg="12"
              size-md="12"
              size-sm="12"
              size-xs="12"
              class="column-container"
            >
              <div class="show-mandatory-message" :class="isDesktopPlatform ? 'show-mandatory-message-desktop' : ''">
                <div class="show-icons"><svg-icon class="alert-icon-admin-edit" icon-class="alert-icon"></svg-icon></div>
                <div class="show-message">
                  <span>
                    Your admin made edits to your submission and provided the following comment.&nbsp;<span
                      v-if="submittedAsset.activities.length > 0 && submittedAsset.activities[0]['text'] != ''"
                      >"{{ submittedAsset.activities[0]['text'] }}"
                    </span>
                    <a class="view-all-link" href="#" @click="checkScroll()">View all comments</a>
                  </span>
                </div>
              </div>
            </ion-col>
            <ion-col v-if="!requiredFieldCheck" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="warning-container alert-container">
              <div class="label-container ion-margin-end"><svg-icon icon-class="alert-icon" alt="alert-image" class="g-icon--small" /></div>
              <div class="warning-text">Some inputs required for submission are still missing. Click the edit icon to provide additional details or make changes.</div>
            </ion-col>
            <ion-col v-if="submittedAsset.name" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="column-container">
              <div class="label-container ion-margin-end">name</div>
              <div class="title">{{ submittedAsset.name === 'undefined' ? 'Undefined' : submittedAsset.name }}</div>
            </ion-col>
            <ion-col
              v-if="submittedAsset.externalAssetLink && ['link', 'both'].includes(canUploadAssets)"
              size-xl="12"
              size-lg="12"
              size-md="12"
              size-sm="12"
              size-xs="12"
              class="column-container"
            >
              <div class="label-container ion-margin-end ion-margin-bottom">Asset Link</div>
              <div class="asset-link asset-link-width" @copy="copyExternalAssetLink">
                <span class="link-url">{{ submittedAsset.externalAssetLink }}</span>
                <svg-icon icon-class="expand-out" class="g-icon--xsmall svg-container" @click="onClickAssetLink" />
              </div>
            </ion-col>
            <ion-col
              v-if="submittedAsset.assetReference && ['zip', 'both'].includes(canUploadAssets)"
              size-xl="12"
              size-lg="12"
              size-md="12"
              size-sm="12"
              size-xs="12"
              class="column-container"
            >
              <div class="label-container ion-margin-end ion-margin-bottom" :class="isDesktopPlatform ? '' : 'asset-file-container'">ASSET FILE</div>
              <span class="asset-link-width">
                <div v-if="!this.isDesktopPlatform" class="show-mandatory-message download-alert-container">
                  <div class="download-alert-icon"><svg-icon class="g-icon--small" icon-class="alert-icon"></svg-icon></div>
                  <span class="download-alert">Download available only on desktop.</span>
                </div>
                <div class="asset-link">
                  <svg-icon icon-class="icons-file-zip" class="g-icon--small svg-container svg-margin-right"></svg-icon>
                  <span class="link-url">{{ submittedAsset.assetReference.name }}</span>
                  <span v-if="isDesktopPlatform" class="file-size">{{ submittedAsset.assetReference.size | formatSize }}</span
                  ><svg-icon v-if="isDesktopPlatform" icon-class="download-icon" class="g-icon--small ion-margin-start svg-container" @click="downloadAssetZipFile()" />
                </div>
              </span>
            </ion-col>
            <ion-col v-if="submittedAsset.promoHeader" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="column-container">
              <div class="label-container ion-margin-end">header</div>
              <div class="ion-padding-bottom title header-title">{{ submittedAsset.promoHeader }}</div>
            </ion-col>
            <ion-col v-if="submittedAsset.promoDescription" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="column-container">
              <div class="label-container ion-margin-end html-view-container">description</div>
              <div class="term">
                <ul>
                  <div v-for="(val, index) in submittedAsset.promoDescription.split('\n')" :key="index">
                    <li v-if="val" class="ion-margin-bottom">
                      <span class="desc-href-val" v-html="$options.filters.formattText(val)"></span>
                    </li>
                  </div>
                </ul>
              </div>
            </ion-col>
            <ion-col v-if="submittedAsset.fullDescription" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="column-container">
              <div class="label-container ion-margin-end html-view-container">How it works</div>
              <div class="term">
                <ul>
                  <div v-for="(val, index) in submittedAsset.fullDescription.split('\n')" :key="index">
                    <li v-if="val" class="ion-margin-bottom">
                      <span class="desc-href-val" v-html="$options.filters.formattText(val)"></span>
                    </li>
                  </div>
                </ul>
              </div>
            </ion-col>
            <ion-col
              size-xl="12"
              size-lg="12"
              size-md="12"
              size-sm="12"
              size-xs="12"
              class="column-container alert-container"
              v-if="submittedAsset.galleryImages && submittedAsset.galleryImages.length > 0"
            >
              <div class="label-container ion-margin-end">screenshots</div>
              <div class="row-container img-container">
                <CarouselDesktop :slidesShow="1" :responsive="false" class="media-carousel">
                  <div v-for="(images, index) in submittedAsset.galleryImages" :key="index">
                    <div class="img-cont">
                      <img :key="index" :src="images.signedUrl" alt="" />
                    </div>
                    <div class="caption-container">{{ images.caption }}</div>
                  </div>
                </CarouselDesktop>
              </div>
            </ion-col>
            <ion-col
              v-if="(submittedAsset.applicationsVM && submittedAsset.applicationsVM.length) || submittedAsset.inventoryType || (submittedAsset.functions && submittedAsset.functions.length)"
              size-xs="12"
              size-md="12"
              class="column-container ion-padding-top"
            >
              <div class="label-container ion-margin-end">about</div>
              <div class="row-container">
                <ion-row class="asset-info-detail ion-padding-top" v-if="submittedAsset.inventoryType">
                  <ion-col class="asset-info-title">Asset Type</ion-col>
                  <ion-col class="asset-info-value">
                    <div>
                      <div class="application-name-container no-margin-top">{{ submittedAsset.inventoryType }}</div>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row class="asset-info-detail" v-if="submittedAsset.applicationsVM != null && submittedAsset.applicationsVM.length > 0">
                  <ion-col class="asset-info-title">Required technology</ion-col>
                  <ion-col class="asset-info-value">
                    <div v-for="application in submittedAsset.applicationsVM" :key="application.id">
                      <img :src="application.image" />
                      <div class="application-name-container">{{ application.name }}</div>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row class="asset-info-detail" v-if="submittedAsset.functions != null && submittedAsset.functions.length > 0">
                  <ion-col class="asset-info-title">Function</ion-col>
                  <ion-col class="asset-info-value">
                    <div class="application-name-container">{{ submittedAsset.functions.length === this.functionsList.length ? 'All functions' : submittedAsset.functions.join(', ') }}</div>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
            <ion-col v-if="submittedAsset.supportingFile" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="column-container">
              <div class="label-container ion-margin-end ion-margin-bottom">REFERENCE</div>
              <div class="asset-link asset-link-width">
                <svg-icon icon-class="file-pdf" class="g-icon--small svg-container svg-margin-right"></svg-icon>
                <span class="link-url">{{ submittedAsset.supportingFile.name }}</span>

                <span class="file-size">{{ submittedAsset.supportingFile.size | formatSize }}</span
                ><svg-icon icon-class="download-icon" class="g-icon--small ion-margin-start svg-container" @click="onDownload" />
              </div>
            </ion-col>
            <ion-col
              v-if="submittedAsset.comment && [reviewStatus.inDraft.value, reviewStatus.inEdit.value].includes(submittedAsset.reviewStatus.toUpperCase())"
              size-xl="12"
              size-lg="12"
              size-md="12"
              size-sm="12"
              size-xs="12"
              class="column-container"
            >
              <div class="label-container ion-margin-end html-view-container">comments</div>
              <div class="comment-text">{{ submittedAsset.comment.replace('\n', ' ') }}</div>
            </ion-col>
            <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="column-container">
              <div class="label-container ion-margin-end ion-margin-bottom"></div>
              <div>
                <ion-button v-if="isEditMode && isDesktopPlatform" @click="onEditClick()" color="warning" shape="round" class="ion-text-capitalize preview-btn" style=""
                  >Edit</ion-button
                >
                <ion-button
                  v-if="isSubmitMode && isDesktopPlatform"
                  @click="openModal(submitConfirmationModal)"
                  color="tertiary"
                  shape="round"
                  class="ion-text-capitalize preview-btn"
                  style="font: normal normal normal 16px/22px Futura PT Demi; height: 44px"
                  >{{ submittedAsset.reviewStatus.toUpperCase() === 'RETURNED' ? 'Resubmit' : 'Submit' }}</ion-button
                >
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <div v-if="dataReady && submittedAsset.commentActivities" :class="isDesktopPlatform ? 'submission-details-content' : 'submission-details-content-mobile'">
      <div class="comments-activity" ref="commentsActivity">
        <h2>Comments & activity</h2>
        <div class="comment-container" v-for="(activity, index) in submittedAsset.commentActivities" :key="index">
          <div class="short-name-container">{{ activity.shortName }}</div>
          <ion-row class="ion-margin-start ion-margin-bottom">
            <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="ion-no-padding">
              <div v-if="activity.nameWithAction" class="activity-column">
                <span class="span-container">{{ activity.nameWithAction }}</span>
                <div class="date-container" v-if="activity.createdAt">{{ activity.createdAt | formatLatestDate }}</div>
              </div>
              <div v-if="activity.userName" class="activity-column">
                <span class="span-container">{{ activity.userName }}</span>
                <span class="span-container" v-if="activity.activityType">{{ activity.activityType }}</span>
                <div class="date-container" v-if="activity.createdAt">{{ activity.createdAt | formatLatestDate }}</div>
              </div>
            </ion-col>
            <ion-col size-xl="10" size-lg="10" size-md="8" size-sm="10" size-xs="10" class="ion-no-padding comment">{{ activity.comment }}</ion-col>
          </ion-row>
        </div>
      </div>
    </div>
    <AssetConfirmationModal
      v-if="showConfirmationModal"
      :modalProperties="modalContent"
      @closeConfirmationModal="onCloseModal()"
      @submitAsset="onSubmit($event)"
      @onDeleteClick="onDeleteClick($event)"
    />
    <AssetAlertModal v-if="showAlertsModal" :modalProperties="submitSuccessMsg" @closeAlertsModal="closeSuccessModal" />
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import AssetConfirmationModal from '@/views/asset/components/AssetConfirmationModal'
import CarouselDesktop from '@/components/organisms/CarouselDesktop'
import AssetAlertModal from '@/views/asset/components/AssetAlertModal'
import util from '@/utils/helper'

export default {
  name: 'AssetSubmissionDetail',
  components: { CarouselDesktop, AssetConfirmationModal, AssetAlertModal },
  props: {},
  data() {
    return {
      functionsList:[],
      showDownloadProgress: false,
      canUploadAssets: 'both',
      showAlertsModal: false,
      submitSuccessMsg: {
        header: 'Success!',
        message: `Thanks for submitting your asset. We'll pass it along to your admin for review and notify you by email of any updates`,
        btnText: 'Ok',
        type: 'success'
      },
      assetId: '',
      submittedAssetMediaUrl: '',
      downloadBtnText: '',
      authToken: '',
      isEditMode: false,
      isDeleteMode: false,
      isSubmitMode: false,
      requiredFieldCheck: false,
      dataReady: false,
      isDesktopPlatform: true,
      showConfirmationModal: false,
      modalContent: {},
      submitConfirmationModal: {
        title: 'Submit your asset',
        subtitle: 'Add a comment',
        textAreaPlaceholder: 'Any additional information you would like to share with those who will be reviewing your asset submission (optional)',
        primaryButton: 'Submit now',
        secondaryButton: 'Cancel'
      },
      deleteConfirmationModal: {
        title: 'Confirm deletion',
        text: 'Deletion is permanent and can\'t be undone. Click "Delete draft" to continue or "Cancel" to return to your draft.',
        primaryButton: 'Delete draft',
        secondaryButton: 'Cancel'
      },
      reviewStatus: {
        edited: { value: 'EDITED', message: 'Edited' },
        inEdit: { value: 'IN EDIT', message: 'Draft' },
        inDraft: { value: 'IN DRAFT', message: 'Draft' },
        returned: { value: 'RETURNED', message: 'Returned' },
        approved: { value: 'APPROVED', message: 'Approved' },
        rejected: { value: 'REJECTED', message: 'Rejected' }
      },
      submittedAsset: {},
      userGuid: ''
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'user_guid'])
  },
  watch: {},
  created() {},
  mounted() {
    this.checkAssetUploadAccess()
  },
  async activated() {
    await localforage.removeItem('deep_link_id')
    await localforage.removeItem('deep_link_type')
    this.assetId = this.$route.params.id
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.submittedAssetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
    await this.initialRequest()
  },
  deactivated() {
    this.resetAssetDetails()
  },
  filters: {
    formatDate(d) {
      return util.formatDateWithTime(d)
    },
    formatSize(size) {
      if (!size) return '0 bytes'
      else if (size < 1024) return `${size} bytes`
      else if (size >= 1024 && size < Math.pow(1024, 2)) return `${parseInt(size / 1024).toLocaleString()} KB`
      else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) return `${parseInt(size / Math.pow(1024, 2)).toLocaleString()} MB`
      else return `${parseInt(size / Math.pow(1024, 3)).toLocaleString()} GB`
    },
    formatLatestDate(createdAt) {
      const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
      const timeOption = { hour: 'numeric', minute: 'numeric' }
      const date = new Date(createdAt)
      var time = date.toLocaleTimeString('en-US', timeOption)
      const duration = new Date() - date
      const days = Math.ceil(duration / (1000 * 60 * 60 * 24))
      if (days == 1) return `Today at ${time}`
      else if (days == 2) return `Yesterday at ${time}`
      else if (days > 2 && days < 7) return `${days} days ago at ${time}`
      else return date.toLocaleDateString('en-US', options)
    },
    formattText(inputText) {
      const pattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
      let text = inputText.replace(pattern1, '<a href="$1" target="_blank">$1</a>')
      const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
      text = text.replace(pattern2, '$1<a href="http://$2" target="_blank">$2</a>')
      return text
    }
  },
  methods: {
    checkScroll() {
      this.$refs.commentsActivity.scrollIntoView()
    },
    async checkAssetUploadAccess() {
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(tenant.settings)
      this.canUploadAssets = {}.hasOwnProperty.call(settings, 'apply-asset-file-upload-access') ? settings['apply-asset-file-upload-access'] : 'both'
    },
    async downloadAssetZipFile() {
      if (!this.showDownloadProgress) {
        this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
        const payload = {
          id: this.assetId,
          token: this.authToken
        }
        this.showDownloadProgress = true
        const file = await this.$learnAdmin.downloadAssetZipFile(payload)
        this.showDownloadProgress = false
        if (file.data) {
          this.saveAs(file.data, this.submittedAsset.assetReference.name)
        }
      }
    },
    async initialRequest() {
      this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
      this.userGuid = this.user_guid || (await localforage.getItem('my_user_guid'))
      await this.getAssetItemById()
      this.functionsList = await this.$learnAdmin.getGovernanceFunctions({ token: this.authToken })
      const payload = await this.onPrepareGAPayload('Asset submission detail viewed')
      await this.GAEventTrack(payload)
    },
    async getAssetItemById() {
      this.submittedAsset = await this.$learnAdmin.getAssetItemById({ token: this.authToken, id: this.assetId })
      let checkAssets = false
      if (this.canUploadAssets == 'zip') {
        checkAssets = (this.submittedAsset.assetReference !== null && this.submittedAsset.assetReference.name && this.submittedAsset.assetReference.name.length > 0) || false
      } else if (this.canUploadAssets == 'link') {
        checkAssets = this.isValidAssetLink()
      } else {
        checkAssets =
          this.isValidAssetLink()  ||
          (this.submittedAsset.assetReference !== null && this.submittedAsset.assetReference.name && this.submittedAsset.assetReference.name.length > 0)
      }
      this.requiredFieldCheck =
        this.submittedAsset.name &&
        this.submittedAsset.promoHeader &&
        this.submittedAsset.promoDescription &&
        checkAssets &&
        this.submittedAsset.inventoryType &&
        this.submittedAsset.applicationsVM?.length &&
        this.submittedAsset.functions?.length
      this.isDeleteMode = this.submittedAsset.reviewStatus.toUpperCase() == this.reviewStatus.inDraft.value ? true : false
      this.isEditMode = [this.reviewStatus.inDraft.value, this.reviewStatus.inEdit.value, this.reviewStatus.returned.value].includes(this.submittedAsset.reviewStatus.toUpperCase())
      this.isSubmitMode =
        [this.reviewStatus.inDraft.value, this.reviewStatus.inEdit.value, this.reviewStatus.returned.value].includes(this.submittedAsset.reviewStatus.toUpperCase()) &&
        this.requiredFieldCheck
      if (
        {}.hasOwnProperty.call(this.submittedAsset, 'activities') &&
        this.submittedAsset.activities?.length &&
        this.submittedAsset.reviewStatus.toUpperCase() !== this.reviewStatus.inDraft.value
      ) {
        this.submittedAsset.commentActivities = this.submittedAsset.activities.sort(this.compareDate)
        this.submittedAsset.commentActivities = await this.submittedAsset.commentActivities.map((activity,index) => {
          let isEditActivity = false;
          if(this.submittedAsset.commentActivities[index+1] && this.submittedAsset.commentActivities[index+1].activityType?.toLowerCase() == 'edited' && [this.reviewStatus.returned.value, this.reviewStatus.approved.value, this.reviewStatus.rejected.value].includes(activity.activityType?.toUpperCase())) {
            isEditActivity = true;
          }
          if (activity.userGuid === this.userGuid) {
            return {
              nameWithAction: isEditActivity ? `You ${activity.activityType} the asset with edits` : `You ${activity.activityType} the asset`,
              shortName: activity.userName
                .match(/\b(\w)/g)
                .join('')
                .slice(0, 2),
              createdAt: activity.createdAt,
              comment: activity.text
            }
          } else {
            return {
              userName: activity.userName,
              shortName: activity.userName
                .match(/\b(\w)/g)
                .join('')
                .slice(0, 2),
              activityType: isEditActivity ? ` ${activity.activityType} the asset with edits` : ` ${activity.activityType} the asset`,
              createdAt: activity.createdAt,
              comment: activity.text
            }
          }
        })
      }
      if ({}.hasOwnProperty.call(this.submittedAsset, 'galleryImages') && this.submittedAsset.galleryImages?.length) {
        this.submittedAsset.galleryImages = await this.submittedAsset.galleryImages.map((image) => {
          return { caption: image.caption, signedUrl: image.signedUrl, type: 'image', size: image.size, pointer: image.image }
        })
      }
      this.submittedAsset.supportingFile =
        this.submittedAsset.supportingFile && {}.hasOwnProperty.call(this.submittedAsset, 'supportingFile') && Object.keys(this.submittedAsset.supportingFile)?.length > 0
          ? this.submittedAsset.supportingFile
          : ''
      this.submittedAsset.applicationsVM = await this.submittedAsset.applicationsVM.map((application) => {
        return {}.hasOwnProperty.call(application, 'name')
          ? { name: application?.name, id: application.id, image: `${this.submittedAssetMediaUrl}media-container/${application.image}` }
          : { name: application, image: null }
      })
      this.dataReady = true
      if (this.submittedAsset.reviewStatus.toUpperCase() == 'RETURNED') {
        this.submitConfirmationModal.title = 'Resubmit your asset'
        this.submitConfirmationModal.primaryButton = 'Resubmit'
        this.submitSuccessMsg.message = `Thanks for resubmitting your asset. We’ll pass it along to your admin for review and notify you by email of any updates`
      }
    },
    isValidAssetLink() {
      return this.submittedAsset.externalAssetLink && new RegExp(/^((https|http):\/\/)+[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi).test(this.submittedAsset.externalAssetLink)
    },
    compareDate(dateA, dateB) {
      var aStartedAt = new Date(dateA.createdAt)
      var bStartedAt = new Date(dateB.createdAt)
      if (aStartedAt > bStartedAt) return -1
      if (aStartedAt < bStartedAt) return 1
      return 0
    },
    copyExternalAssetLink(event) {
      event.preventDefault()
      if (event.clipboardData) {
        event.clipboardData.setData('text/plain', this.submittedAsset.externalAssetLink)
      }
    },
    async onDownload() {
      if (!this.isDesktopPlatform) {
        window.open(this.submittedAsset.supportingFile?.signedUrl, '_blank')
      } else {
        const data = await fetch(this.submittedAsset.supportingFile?.signedUrl)
        const blobData = await data.blob()
        this.saveAs(blobData, this.submittedAsset.supportingFile?.name)
      }
    },
    saveAs(blob, fileName) {
      const url = window.URL.createObjectURL(blob)
      const anchorElem = document.createElement('a')
      anchorElem.style = 'display:none'
      anchorElem.href = url
      anchorElem.download = fileName
      document.body.appendChild(anchorElem)
      anchorElem.click()
      document.body.removeChild(anchorElem)
      setTimeout(function () {
        window.URL.revokeObjectURL(url)
      }, 1000)
    },
    onClickAssetLink() {
      this.submittedAsset.externalAssetLink.indexOf("http://") == 0 || this.submittedAsset.externalAssetLink.indexOf("https://") == 0 ? window.open(this.submittedAsset.externalAssetLink, '_blank') : window.open(`https://${this.submittedAsset.externalAssetLink}`, '_blank')
    },
    async onEditClick() {
      this.$router.push({ name: 'EditAsset', params: { id: this.assetId, editableAsset: this.submittedAsset } }).catch(() => {})
      const payload = await this.onPrepareGAPayload('Asset submission detail edited')
      await this.GAEventTrack(payload)
    },
    async onDeleteClick(data) {
      if (data.delete) {
        await this.$learnAdmin.deleteAssetItem({ token: this.authToken, id: this.assetId }).then((res) => {
          if (res.status == 200) {
            this.showToast('Asset successfully deleted.', 'success')
            this.$router.push('/submit-asset').catch(() => {})
          } else this.showToast('There was a problem deleting your asset. You can try again later or contact support for more assistance.', 'error')
        })
        const payload = await this.onPrepareGAPayload('Asset submission detail deleted')
        await this.GAEventTrack(payload)
      }
    },
    openModal(data) {
      this.showConfirmationModal = true
      this.modalContent = data
    },
    async onSubmit(data) {
      const governanceItem = {
        id: this.submittedAsset.id,
        name: this.submittedAsset.name,
        promoHeader: this.submittedAsset.promoHeader,
        promoDescription: this.submittedAsset.promoDescription,
        fullDescription: this.submittedAsset.fullDescription,
        applications: this.submittedAsset.applications,
        inventoryType: this.submittedAsset.inventoryType,
        externalAssetLink: this.submittedAsset.externalAssetLink,
        comment: data.comment ? data.comment : this.submittedAsset.comment ? this.submittedAsset.comment : '',
        inventoryCategory: this.submittedAsset.inventoryCategory,
        functions: this.submittedAsset.functions,
        userAction: 'submit'
      }
      const formData = new FormData()
      formData.set('governanceItem', JSON.stringify(governanceItem))
      if (this.submittedAsset.supportingFile?.length > 0) {
        formData.append('howToGuideFile', this.submittedAsset.supportingFile)
      }
      if (this.submittedAsset.galleryImages?.length > 0) {
        this.submittedAsset.galleryImages.forEach((file) => {
          formData.append('mediaFiles', file)
        })
      }
      const payload = {
        token: this.authToken,
        body: formData
      }
      await this.$learnAdmin.submitAsset(payload).then((res) => {
        if (res.status == 200) {
          this.showConfirmationModal = false
          this.showAlertsModal = true
        } else {
          this.showToast('Asset submission failed', 'error')
        }
      })
      const GAPayload = await this.onPrepareGAPayload('Asset submission detail submitted')
      await this.GAEventTrack(GAPayload)
    },
    showToast(msg, className) {
      this.$toasted.show(msg, {
        containerClass: this.isDesktopPlatform ? 'toast-container' : 'toast-container mobile-container',
        className: className,
        position: 'bottom-right',
        iconPack: 'icon-container',
        duration: 5000,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    async onCloseModal() {
      this.showConfirmationModal = false
    },
    resetAssetDetails() {
      this.submittedAsset = {}
      this.dataReady = false
      this.showConfirmationModal = false
      this.showAlertsModal = false
      this.modalContent = ''
    },
    closeSuccessModal() {
      this.showAlertsModal = false
      this.$router.push('/submit-asset')
    },
    async onPrepareGAPayload(action) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        asset: {
          id: this.submittedAsset.id,
          name: this.submittedAsset.name,
          author: this.submittedAsset.submitUser,
          technology: this.submittedAsset.applications,
          type: this.submittedAsset.inventoryType
        }
      }
      const payload = {
        action: action,
        category: 'Asset Submission Detail',
        value: JSON.stringify(item)
      }
      return payload
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>
<style lang="scss">
.desc-href-val a {
  color: inherit !important;
  font: inherit !important;
  cursor: pointer !important;
}
</style>

<style lang="scss" scoped>
ul {
  list-style-image: url('~@/views/asset/components/images/li-checkmark-16.png');
  li:hover {
    background: #ffffff;
  }
  li span {
    bottom: 0.2rem;
    position: relative;
    font: normal normal normal 1rem/1.25rem Arial;
  }
}
.image-container {
  height: 50vh;
}
.caption-container {
  text-align: left;
  font-size: 0.875rem;
  font-family: 'Arial';
  margin-top: 0.5rem;
}

.icon-container {
  display: inline-grid;
  float: right;
  @media screen and (max-width: 768px) {
    display: block;
    justify-content: center;
    margin-bottom: 1rem;
    float: unset;
  }
  .action-icon {
    color: unset;
    text-decoration: unset;
    cursor: pointer;
    padding: 8px 8px;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid var(--ion-color-dark-grey);
    margin-bottom: 0.8rem;
    @media screen and (max-width: 768px) {
      margin-right: 1rem;
    }
  }
  .icon-tooltip {
    position: relative;
    display: inline-block;
  }
  .icon-tooltip .icon-tooltiptext {
    visibility: hidden;
    width: 120px;
    text-align: center;
    padding: 5px 0;
    top: 0.5rem;
    right: 105%;
    position: absolute;
    z-index: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 2px 6px #0000001f;
    border-radius: 4px;
    opacity: 1;
    font-size: 0.8rem;
  }
  .tooltip-visibility {
    transition: visibility 0s, opacity 0.5s linear;
  }
  .icon-tooltip:hover .tooltip-visibility {
    visibility: visible;
    opacity: 0;
  }

  .icon-tooltip:hover .icon-tooltiptext {
    visibility: visible;
  }
}

.asset-info-detail {
  text-align: left;
  border-bottom: 1px solid #9f9f9f;
  &:last-child {
    border-bottom: none;
  }
}

.asset-info-title {
  text-align: left;
  font-family: 'Arial';
  font-weight: bold;
  color: var(--ion-color-black);
}
.asset-info-value {
  text-align: right;
  color: var(--ion-color-black);
  img {
    width: 20px;
    height: 20px;
  }
  .application-name-container {
    float: right;
    margin-left: 0.3rem;
    font-family: 'Arial';
    @media screen and (max-width: 414px) {
      font-size: 0.75rem;
    }
  }
}
.media-carousel {
  margin: 0px 5px;
  img {
    cursor: pointer;
  }
}

.submission-details-line {
  margin: 5px 0px 10px 15px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  .alert-container {
    margin-bottom: 2rem !important;
  }
  .column-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
      display: block;
      justify-content: unset;
    }
  }
  .warning-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    text-align: left;
    align-items: center;
    .warning-text {
      font-size: 0.875rem;
      font-family: 'Arial';
    }
  }
  .label-container {
    font: normal normal normal 0.875rem Futura PT Demi;
    text-transform: uppercase;
    color: var(--ion-color-blue);
    width: 7rem;
    text-align: right;
    @media screen and (max-width: 768px) {
      text-align: left;
      width: unset;
    }
    &.asset-file-container {
      margin-top: 3.5rem;
      @media screen and (max-width: 768px) {
        margin-top: unset;
      }
    }
  }
  .html-view-container {
    @media screen and (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }
  .title {
    text-align: left;
    font-size: 3rem;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-dark-blue);
    width: 80%;
    @media screen and (max-width: 768px) {
      font-size: 2.125rem;
      width: unset;
    }
  }
  .header-title {
    font-size: 1.875rem;
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  .term {
    text-align: left;
    font-size: 1rem;
    margin: -15px 0px 0px -15px;
    width: 80%;
  }
  .asset-link {
    background: #efefef80 0% 0% no-repeat padding-box;
    border: 1px solid #e0e1e1;
    border-radius: 4px;
    opacity: 1;
    outline: none;
    height: 40px;
    margin: 0rem 0rem 0rem 0.2rem;
    padding: 0px 1.5rem;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    .svg-container {
      cursor: pointer;
    }
    .file-size {
      font-size: 0.875rem;
      width: 5rem;
      font-family: 'Arial';
    }
    .svg-margin-right {
      margin-right: 0.5rem;
    }
  }
  .asset-link-width {
    width: 80%;
    @media screen and (max-width: 768px) {
      width: unset;
    }
  }
  .row-container {
    width: 80%;
  }
  .img-container {
    max-width: 65%;
    cursor: auto;
    .img-cont {
      width: 100%;
      height: 25rem;
      background: #e0e1e1;
      position: relative;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        cursor: auto;
      }
    }
  }
}

.submission-details-content {
  width: 85%;
  background-color: var(--ion-color-white);
  border-radius: 20px;
  margin: 4rem 0rem 0rem 8rem;
}
.comments-activity {
  padding: 4rem 4rem 4rem 14.1rem;
  text-align: left;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 430px) {
    padding: 1.5rem 1rem;
  }
  .comment-container {
    display: flex;
    justify-content: flex-start;
    .comment {
      font-family: 'Arial';
      font-size: 0.875rem;
      word-break: break-word;
    }
  }
  h2 {
    color: #1f3370;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
  .span-container {
    font-family: 'Arial';
    font-size: 16px;
    font-weight: bold;
    word-spacing: 5px;
    margin-right: 10px;
  }
  .date-container {
    color: var(--ion-color-medium);
    font-size: 12px;
    padding-top: 3px;
    font-family: 'Arial';
  }

  .short-name-container {
    background: #efefef;
    border-radius: 50%;
    padding: 9px 5px;
    margin-right: 5px;
    font-size: 0.875rem;
    text-align: center;
    height: 2.1rem;
    width: 2.1rem;
    min-width: 2.1rem;
    text-transform: uppercase;
  }
  .activity-column {
    padding-top: 5px;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: 450px) {
      display: contents;
    }
  }
}

.submission-details-content-mobile {
  width: 100%;
  background-color: var(--ion-color-white);
  border-radius: 20px;
  margin: 3rem 0rem 0rem 0rem;
}
.submission-details-status {
  background-color: #ffc758;
  padding: 10px 3px;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
  .svg-container {
    vertical-align: middle;
  }
}

.submission-details {
  padding: 5rem;
  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
  .header {
    font-weight: bold;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    text-transform: uppercase;
    color: var(--ion-color-black);
    font-size: 0.8rem;
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
      margin-left: 9rem;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
    @media screen and (max-width: 430px) {
      display: grid;
      justify-content: center;
    }
    .format {
      padding: 0.1rem 0.5rem;
      font: normal normal bold 0.875rem/1rem Arial;
      border-radius: 10px;
      background: var(--ion-color-light-pink);
      @media screen and (max-width: 430px) {
        margin: auto;
      }
    }
    .provider {
      padding: 0.1rem 0;
      font: normal normal bold 0.875rem/1rem Arial;
      letter-spacing: 1.4px;
      .divider {
        opacity: 0.5;
      }
    }
  }
}
.submission-details-container {
  padding: 1.5rem 3rem;
  background: #efefef;
  scroll-behavior: smooth;
  @media screen and (max-width: 768px) {
    padding: 1.5rem 0.5rem;
  }
}
.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 1%;
  font-family: 'Arial';
  color: #5c5c5c;
  padding: 0;
  display: block;
  svg {
    position: relative;
    top: 5px;
  }
  @media screen and (max-width: 1366px) {
    margin-left: 0%;
  }
}
.link-url {
  text-align: left;
  width: 80%;
  margin-right: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Arial';
}

.comment-text {
  font-family: 'Arial';
  font-size: 1rem;
}

.custom-toast {
  width: 26.25rem;
  height: 8.5rem;
  // box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  .toast-footer {
    height: 0.5rem;
    /* UI Properties */
    // box-shadow: 0px 0.3125rem 0.625rem #0000003d;
    width: 100%;
    position: fixed;
    bottom: 0px;
    width: 26.25rem;
  }
  .status-message {
    margin-top: 36px !important;
    margin-left: 5.6rem !important;
    text-align: left;
    .status-message-normal {
      font: normal normal normal 16px/22px Arial !important;
      color: #000000;
    }
  }
}
.custom-toast-loading {
  height: 6rem;
  background: #fff4df 0% 0% no-repeat padding-box;
  .toast-footer {
    background: #ffb92e 0% 0% no-repeat padding-box;
  }
}
.asset-file-download {
  width: 80%;
}

.show-mandatory-message {
  text-align: left;
  max-width: 52.6rem;
  min-height: 4rem;
  background: #fff4df 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  .show-icons {
    margin-left: 1.375rem;
    padding: 1.25rem 0.5rem 1rem 1rem !important;
  }
  .download-alert-icon {
    padding: 0.8rem 0rem 0.8rem 1.5rem;
  }
  .show-message {
    padding: 1.5rem 3.5rem 1.5rem 0 !important;
    font: normal normal normal 0.875rem/1.125rem Arial;
    color: #000000;
    span {
      font: normal normal normal 0.875rem/1.125rem Arial;
    }
  }
  .download-alert {
    padding: 1rem 0rem 0.8rem 0.5rem;
    color: #000000;
    font: normal normal normal 0.75rem/1.125rem Arial;
  }
  .alert-icon-admin-edit {
    width: 20px !important;
    height: 20px !important;
  }
  &.download-alert-container {
    margin-bottom: 0.5rem;
    min-height: 2.8rem !important;
    height: 2.8rem;
    max-width: none !important;
    margin-left: 0.2rem;
  }
}
.show-mandatory-message-desktop {
  margin-left: 8rem;
}

.view-all-link {
  color: #1f3370 !important;
}
.preview-btn {
  font: normal normal normal 16px/22px Futura PT Demi;
  padding-right: 2rem;
  height: 44px;
}
</style>
