export default [
  {
    path: '/asset/:id',
    name: 'Asset',
    component: () => import('@/views/asset/Asset'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/submit-asset',
    name: 'SubmitAsset',
    component: () => import('@/views/asset/AssetSubmission'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/submit-asset-details/:id',
    name: 'AssetSubmissionDetail',
    component: () => import('@/views/asset/AssetSubmissionDetail'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/download-asset',
    name: 'DownloadAsset',
    component: () => import('@/views/asset/components/AssetDownload'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/create-asset',
    name: 'CreateAsset',
    component: () => import('@/views/asset/AssetSubmissionForm'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/edit-asset/:id',
    name: 'EditAsset',
    component: () => import('@/views/asset/AssetSubmissionForm'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  }
]
