var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tooltip-bottom", style: { cursor: "pointer" } },
    [
      _vm.showEdit
        ? _c(
            "button",
            {
              staticClass: "tooltip-bottom-text",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onAction("edit")
                }
              }
            },
            [_vm._v("Edit")]
          )
        : _vm._e(),
      _vm.showDelete
        ? _c(
            "button",
            {
              staticClass: "tooltip-bottom-text",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onAction("delete")
                }
              }
            },
            [_vm._v("Delete")]
          )
        : _vm._e(),
      _vm.showAddToTrack
        ? _c(
            "button",
            {
              staticClass: "tooltip-bottom-text",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onAction("add")
                }
              }
            },
            [_vm._v("Add to a track")]
          )
        : _vm._e(),
      _vm.showRemoveFromTrack
        ? _c(
            "button",
            {
              staticClass: "tooltip-bottom-text",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onAction("remove")
                }
              }
            },
            [_vm._v("Remove from track")]
          )
        : _vm._e(),
      _vm.editTrack
        ? _c(
            "button",
            {
              staticClass: "tooltip-bottom-text",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onAction("edit-track")
                }
              }
            },
            [_vm._v("Edit track")]
          )
        : _vm._e(),
      _vm.deleteTrack
        ? _c(
            "button",
            {
              staticClass: "tooltip-bottom-text",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onAction("delete-track")
                }
              }
            },
            [_vm._v("Delete track")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }