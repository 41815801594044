var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-tab-course" },
    [
      _vm._l(_vm.data.items, function(item, index) {
        return _c("UniversalCard", {
          key: item.id,
          attrs: {
            item: item,
            index: index,
            itemsLength: _vm.data.items.length,
            tab: _vm.tabName
          }
        })
      }),
      _vm.data.items && _vm.data.items.length && _vm.data.pageCurrent > 0
        ? _c(
            "div",
            { staticClass: "search-result-pagination" },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.data.pageCount,
                  "page-range": _vm.pageRange,
                  "margin-pages": 1,
                  "click-handler": _vm.handlePageClick,
                  "container-class": "pagination",
                  "page-class": "pagination-item",
                  "page-link-class": "pagination-item-link",
                  "prev-text": "&lsaquo;",
                  "prev-class": "pagination-nav",
                  "prev-link-class": "pagination-nav-link",
                  "next-text": "&rsaquo;",
                  "next-class": "pagination-nav",
                  "next-link-class": "pagination-nav-link",
                  "hide-prev-next": true
                },
                model: {
                  value: _vm.data.pageCurrent,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "pageCurrent", $$v)
                  },
                  expression: "data.pageCurrent"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.dataReady
        ? _c(
            "div",
            {
              class: _vm.isDesktopPlatform
                ? "search-result-panel-desktop"
                : "search-result-panel-mobile"
            },
            _vm._l(5, function(i) {
              return _c("SearchSkeleton", { key: i })
            }),
            1
          )
        : _vm._e(),
      _vm.dataReady && _vm.data.items && _vm.data.items.length === 0
        ? _c(
            "div",
            [
              _c("ion-grid", [
                _vm.isDesktopPlatform
                  ? _c(
                      "div",
                      [
                        _c(
                          "ion-row",
                          [
                            _c("ion-col", [
                              _c(
                                "h3",
                                { staticClass: "desktop-no-results-header" },
                                [
                                  _vm._v(
                                    "0 results found for " + _vm._s(_vm.query)
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "desktop-no-results-subheader" },
                                [
                                  _vm._v(
                                    "Try a different keyword or a more generic search term"
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "ion-row",
                          [
                            _c("ion-col", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "desktop-no-results-section-title"
                                },
                                [_vm._v("Need help finding something?")]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "ion-row",
                          { staticClass: "ion-align-items-center" },
                          [
                            _c("ion-col", { attrs: { size: "6" } }, [
                              _c("img", {
                                staticClass: "desktop-no-results-img-1",
                                staticStyle: { margin: "0 12px 0 0" },
                                attrs: {
                                  src: require("@/assets/images/recommended-skills-footer.png")
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass: "desktop-no-results-subheader",
                                  staticStyle: { margin: "5%" }
                                },
                                [
                                  _vm._v(" Browse our "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "desktop-no-results-links",
                                      attrs: { href: "/explore/recommended" }
                                    },
                                    [_vm._v("Explore section")]
                                  ),
                                  _vm._v(
                                    " to discover content based on your interests. "
                                  )
                                ]
                              )
                            ]),
                            _c("ion-col", { attrs: { size: "6" } }, [
                              _c("img", {
                                staticClass: "desktop-no-results-img-2",
                                staticStyle: { margin: "0 12px 0 0" },
                                attrs: {
                                  src: require("@/assets/images/help-center-image.png")
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass: "desktop-no-results-subheader",
                                  staticStyle: { "margin-top": "5%" }
                                },
                                [
                                  _vm._v(" Visit our "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "desktop-no-results-links",
                                      attrs: { href: "/help-center" }
                                    },
                                    [_vm._v("Help Center")]
                                  ),
                                  _vm._v(
                                    " to submit feedback or content suggestions. "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "ion-align-items-center" },
                      [
                        _c(
                          "ion-row",
                          [
                            _c("ion-col", [
                              _c(
                                "h3",
                                { staticClass: "desktop-no-results-header" },
                                [
                                  _vm._v(
                                    "0 results found for " + _vm._s(_vm.query)
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "desktop-no-results-subheader" },
                                [
                                  _vm._v(
                                    "Try a different keyword or a more generic search term"
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "ion-row",
                          [
                            _c("ion-col", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "desktop-no-results-section-title"
                                },
                                [_vm._v("Need help finding something?")]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "ion-row",
                          { staticClass: "ion-align-items-center" },
                          [
                            _c("ion-col", [
                              _c("img", {
                                staticClass: "desktop-no-results-img-1",
                                staticStyle: { margin: "0 10px 0 0" },
                                attrs: {
                                  src: require("@/assets/images/recommended-skills-footer.png")
                                }
                              }),
                              _c(
                                "p",
                                { staticClass: "desktop-no-results-subheader" },
                                [
                                  _vm._v(" Browse our "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "desktop-no-results-links",
                                      attrs: { href: "/explore/recommended" }
                                    },
                                    [_vm._v("Explore section")]
                                  ),
                                  _vm._v(
                                    " to discover content based on your interests. "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "ion-row",
                          [
                            _c("ion-col", [
                              _c("img", {
                                staticClass: "desktop-no-results-img-2",
                                staticStyle: { margin: "0 10px 0 0" },
                                attrs: {
                                  src: require("@/assets/images/help-center-image.png")
                                }
                              }),
                              _c(
                                "p",
                                { staticClass: "desktop-no-results-subheader" },
                                [
                                  _vm._v(" Visit our "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "desktop-no-results-links",
                                      attrs: { href: "/help-center" }
                                    },
                                    [_vm._v("Help Center")]
                                  ),
                                  _vm._v(
                                    " to submit feedback or content suggestions. "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }