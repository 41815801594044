var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "goal-setting-modal__backdrop" }),
      _c(
        "div",
        { staticClass: "goal-setting-modal" },
        [
          _c(
            "ion-row",
            { staticClass: "goal-setting-modal-header" },
            [
              _c(
                "ion-col",
                {
                  attrs: {
                    "size-lg": "8",
                    "size-md": "8",
                    "size-sm": "8",
                    "size-xs": "10"
                  }
                },
                [
                  _c("h2", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.isEditMode
                          ? _vm.editGoalHeading
                          : _vm.newGoalHeading
                      )
                    }
                  })
                ]
              ),
              _c(
                "ion-col",
                {
                  attrs: {
                    "size-lg": "4",
                    "size-md": "4",
                    "size-sm": "4",
                    "size-xs": "2"
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "g-icon--xsmall cancel-icon-container",
                    attrs: { "icon-class": "filter-close" },
                    on: { click: _vm.onCancel }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { class: _vm.isDesktopPlatform ? "" : "scroll-mobile-content" },
            [
              _c(
                "ion-row",
                { staticClass: "goal-setting-modal-content" },
                [
                  _c(
                    "ion-col",
                    {
                      attrs: {
                        "size-lg": "3",
                        "size-md": "6",
                        "size-sm": "6",
                        "size-xs": "6"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "goal-setting-modal-box",
                          class:
                            _vm.goalSettingType === "light" ? "active" : "",
                          on: {
                            click: function($event) {
                              return _vm.handleDaysSelect("light")
                            }
                          }
                        },
                        [
                          _c("h5", [_vm._v("Light")]),
                          _c("p", [_vm._v("2 days")])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "ion-col",
                    {
                      attrs: {
                        "size-lg": "3",
                        "size-md": "6",
                        "size-sm": "6",
                        "size-xs": "6"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "goal-setting-modal-box",
                          class:
                            _vm.goalSettingType === "casual" ? "active" : "",
                          on: {
                            click: function($event) {
                              return _vm.handleDaysSelect("casual")
                            }
                          }
                        },
                        [
                          _c("h5", [_vm._v("Casual")]),
                          _c("p", [_vm._v("4 days")]),
                          _c(
                            "div",
                            {
                              staticClass: "goal-setting-modal-recommendation"
                            },
                            [
                              _c(
                                "p",
                                {
                                  class:
                                    _vm.goalSettingType === "casual"
                                      ? "active"
                                      : ""
                                },
                                [_vm._v("RECOMMENDED TO START")]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "ion-col",
                    {
                      attrs: {
                        "size-lg": "3",
                        "size-md": "6",
                        "size-sm": "6",
                        "size-xs": "6"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "goal-setting-modal-box",
                          class:
                            _vm.goalSettingType === "focused" ? "active" : "",
                          on: {
                            click: function($event) {
                              return _vm.handleDaysSelect("focused")
                            }
                          }
                        },
                        [
                          _c("h5", [_vm._v("Focused")]),
                          _c("p", [_vm._v("8 days")])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "ion-col",
                    {
                      attrs: {
                        "size-lg": "3",
                        "size-md": "6",
                        "size-sm": "6",
                        "size-xs": "6"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "goal-setting-modal-custom-box",
                          class:
                            _vm.goalSettingType === "custom" ? "active" : ""
                        },
                        [
                          _c("h5", [_vm._v("You choose!")]),
                          _c(
                            "div",
                            { staticClass: "goal-setting-modal-select" },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customGoalDays,
                                      expression: "customGoalDays"
                                    }
                                  ],
                                  ref: "goalDays",
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.customGoalDays = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function($event) {
                                        return _vm.handleDaysSelect("custom")
                                      }
                                    ]
                                  }
                                },
                                _vm._l(_vm.getDaysInThisMonth(), function(day) {
                                  return _c(
                                    "option",
                                    { key: day, domProps: { value: day } },
                                    [_vm._v(_vm._s(day))]
                                  )
                                }),
                                0
                              ),
                              _c("label", [_vm._v("days")])
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "goal-setting-modal-tip-content" }, [
                _c("h5", [_vm._v("Tip")]),
                _c("p", [_vm._v(_vm._s(_vm.getTipText()))])
              ]),
              _c(
                "div",
                { staticClass: "goal-setting-modal-action-content" },
                [
                  _vm.isEditMode
                    ? _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.onClearGoal()
                            }
                          }
                        },
                        [_vm._v("Clear goal")]
                      )
                    : _vm._e(),
                  _c(
                    "ion-button",
                    {
                      staticClass: "goal-setting-modal-save-btn",
                      attrs: {
                        shape: "round",
                        disabled:
                          _vm.goalDurationInDays === 0 ||
                          (_vm.goalSettingData.goalDays ==
                            _vm.goalDurationInDays &&
                            _vm.goalSettingData.goalDescription ==
                              _vm.goalSettingType),
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSaveClicked()
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showAlertsModal
        ? _c("HomeAlertModal", {
            attrs: { modalProperties: _vm.clearGoalMsg },
            on: {
              closeAlertsModal: function($event) {
                _vm.showAlertsModal = false
              },
              confirmClearGoal: _vm.onConfirmClearGoal
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }