<template>
  <div :class="isDesktopPlatform ? '' : 'non-white-background'" class="main-content" ref="top">
    <ion-grid @click="onGridClick">
      <ion-row style="margin: 0% 5%">
        <div class="back-btn-container" size="1" v-if="isDesktopPlatform">
          <button v-if="isDesktopPlatform" class="back" @click="$router.go(-1)" style="display: inline-block; min-width: 70px">
            <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
            Back
          </button>
        </div>
        <div :class="isDesktopPlatform ? 'ion-padding-start' : ''" class="ion-text-start search-results-text">
          <p v-if="resultsCount > 0 && resultsCount == 1" class="results-count-header">
            {{ resultsCount | formatCount }} result
            <span v-if="query" class="results-count-header-query">for {{ query }}</span>
          </p>
          <p v-if="resultsCount > 0 && resultsCount > 1 && resultsCount <= 10" class="results-count-header">
            {{ currentPage * 10 - 9 }}-{{ resultsCount }} of {{ resultsCount | formatCount }} results
            <span v-if="query" class="results-count-header-query">for {{ query }}</span>
          </p>
          <p v-if="resultsCount > 0 && resultsCount > 10 && currentPage * 10 <= resultsCount" class="results-count-header">
            {{ currentPage * 10 - 9 }}-{{ currentPage * 10 }} of {{ resultsCount | formatCount }} results
            <span v-if="query" class="results-count-header-query">for {{ query }}</span>
          </p>
          <p v-if="resultsCount > 0 && resultsCount > 10 && currentPage * 10 > resultsCount" class="results-count-header">
            {{ currentPage * 10 - 9 }}-{{ resultsCount }} of {{ resultsCount | formatCount }} results
            <span v-if="query" class="results-count-header-query">for {{ query }}</span>
          </p>
        </div>
      </ion-row>
    </ion-grid>
    <div v-if="dataReady" :class="isDesktopPlatform ? 'search-result-panel-desktop' : 'search-result-panel-mobile'">
      <div>
        <div :class="isDesktopPlatform ? 'search-filter-desktop' : 'search-filter-mobile'">
          <SearchFilter
            v-if="resultsCount > 0"
            :resultsCount="resultsCount"
            :sideFilterData="sideFilterData"
            @side-filter="onSideFilterData($event)"
            @apply-filter="onApplyFilter($event)"
            @clear-filters="clearFilters"
            @apply-sort="onApplySort($event)"
            :filtersObj="filters"
            :selectedFilters="selectedFilters"
            :sortBy="sortBy"
            :userAccess="userAccess"
          />
        </div>
        <SearchItemContainer
          :query="query"
          :dataReady="searchResultsNew.dataReady"
          :data="searchResultsNew"
          :filters="filters"
          :selectedFilters="selectedFilters"
          :sortBy="sortBy"
          slot="search-item-container"
          @search="onPageClickSearch($event)"
          @scroll-search-top="scrollSearchTop()"
        />
      </div>
    </div>
    <div v-else :class="isDesktopPlatform ? 'search-result-panel-desktop' : 'search-result-panel-mobile'">
      <SearchSkeleton v-for="i in 5" :key="i" />
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'
import SearchSkeleton from '@/views/search/components/SearchSkeleton'
import SearchItemContainer from '@/views/search/components/SearchItemContainer'
import SearchFilter from '@/views/search/components/SearchFilter'

export default {
  name: 'Search',
  components: { SearchSkeleton, SearchItemContainer, SearchFilter },
  props: {},
  data() {
    return {
      authToken: '',
      learnerId: '',
      tenantGuid: '',
      assetMediaUrl: '',
      query: '',
      userAccess: {},
      searchResultsNew: {
        dataReady: true,
        items: [],
        pageCurrent: 0,
        pageCount: 0
      },
      isDesktopPlatform: true,
      filters: {},
      selectedFilters: [],
      creditHoursApiValues: [],
      sortBy: '',
      isNewSearch: true,
      dataReady: false,
      sideFilterData: false,
      resultsCount: 0,
      recentFilterTypeChanged: '',
      currentPage: 1,
      recentSearchSessionId: '',
      tenantAsset: {
        enabled: false,
        userAccessAsset: false
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {
    async $route(to, from) {
      if (
        !to.path.includes('course') &&
        !to.path.includes('credential') &&
        !to.path.includes('content') &&
        !to.path.includes('external') &&
        !to.path.includes('asset') &&
        !to.path.includes('collection') &&
        !to.path.includes('search')
      ) {
        this.recentSearchSessionId = ''
      } else if (to.path.includes('search') && from.path.includes('search')) {
        this.recentSearchSessionId = ''
      }

      this.isNewSearch = !(
        from.path.includes('course') ||
        from.path.includes('credential') ||
        from.path.includes('content') ||
        from.path.includes('external') ||
        from.path.includes('asset') ||
        from.path.includes('collection')
      )
      if (
        !from.path.includes('course') &&
        !from.path.includes('credential') &&
        !from.path.includes('content') &&
        !from.path.includes('external') &&
        !from.path.includes('asset') &&
        !from.path.includes('collection') &&
        to.path.includes('search')
      ) {
        await this.determineUserAccess()
      }
    }
  },
  filters: {
    formatCount(count) {
      return count.toLocaleString()
    }
  },
  created() {},
  deactivated() {},
  async activated() {
    await localforage.removeItem('deep_link_search_query')
    await localforage.removeItem('deep_link_search_type')
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.learnerId = await localforage.getItem('my_user_id')
    this.tenantGuid = await localforage.getItem('my_tenant_guid')
    this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
    await this.determineUserAccess()
    this.query = this.$route.query.q
      this.selectedFilters = []
      this.sortBy = 'bestmatch'
      this.currentPage = 1
      let payload = {}
      if (this.$route.params.fromRecommended || this.$route.params.fromHome) {
        payload = this.onApplyAssetFilter(payload)
      }
      if (this.$route.query.type) {
        payload = this.onApplyContentTypeFilter(payload)
      }
      await this.search(payload)
      this.scrollSearchTop()
  },
  methods: {
    scrollSearchTop() {
      if (this.isDesktopPlatform) {
        const container = document.querySelector('#contentlayout')
        container.scrollTo(0, 0)
      } else {
        this.$refs.top.scrollIntoView()
      }
    },
    async determineUserAccess() {
      const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(tenant.settings)
      this.userAccess = await localforage.getItem('my_user_access')
      const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
      this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
      this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
      this.userAccess.assetAccess = this.tenantAsset.userAccessAsset && this.tenantAsset.enabled
    },
    async onGridClick() {
      EventBus.$emit('search-page-click')
    },
    async handleSearchInput(event) {
      this.query = event.target.value.replace(/[^0-9a-z]/gi, ' ').trim()
      if (this.query.length > 0 && this.query.match(/[\w]$/i)) {
        this.searchResults.autocompletion = []
        const item = {
          'learner-id': this.learnerId,
          'tenant-guid': this.tenantGuid,
          query: this.query,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'Global Search',
          category: 'Search Query',
          value: JSON.stringify(item),
          csvalue: item
        }
        await this.GAEventTrack(payload)
        this.$router.push({ path: '/search', query: { q: this.query } }).catch(() => {})
      }
    },
    handleClearInput() {
      this.query = ''
      this.searchResults.autocompletion = []
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    },
    async search(payload) {
      if (!payload?.page) {
        this.currentPage = 1
      }
      this.dataReady = false
      const payloadData = {
        token: this.authToken,
        query: encodeURIComponent(this.query),
        pageNo: payload && payload.page ? payload.page : 1,
        body: this.getFilterPayload(payload),
        sortBy: payload && payload.sortBy ? payload.sortBy : this.sortBy
      }
      /* Set the filter as skill if searching for a skill */
      if (this.$route.query.suggestionSkill) {
        payloadData.body.skill = [`${this.$route.query.suggestionSkill}`]
      }
      if (this.recentSearchSessionId != '') {
        payloadData.filterValuesSessionId = this.recentSearchSessionId
      }
      /* Set the filter type as recentFilterTypeChanged if it is empty */
      if (payload && payload.filterType != '') {
        this.recentFilterTypeChanged = payload.filterType
      } else if (payload && payload.filterType == '') {
        payload.filterType = this.recentFilterTypeChanged
      }
      if (payload) {
        if (payload.filterType == 'Content type') {
          if (this.filters.typeFilterValues) {
            let selectedFilters_test = this.filters.typeFilterValues.map((item) => item.name)
            payloadData.body.selectedFilter_typeFilterValues = selectedFilters_test
          } else {
            var selectedFilter_temp = []
            if (this.userAccess.canAccessRWL) {
              selectedFilter_temp = ['Article', 'Audio', 'Video']
            }
            if (this.userAccess.canAccessCourses) {
              selectedFilter_temp.push('Course')
            }
            if (this.userAccess.canAccessCredentials) {
              selectedFilter_temp.push('Credential')
            }
            if (this.userAccess.assetAccess) {
              selectedFilter_temp.push('Asset')
            }
            payloadData.body.selectedFilter_typeFilterValues = selectedFilter_temp
          }
        } else if (payload.filterType == 'Duration') {
          let selectedFilters_test = this.filters.durationFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_durationFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Provider') {
          let selectedFilters_test = this.filters.providerFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_providerFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Level') {
          let selectedFilters_test = this.filters.levelFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_levelFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Accreditation') {
          let selectedFilters_test = this.filters.accreditationFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_accreditationFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Function') {
          let selectedFilters_test = this.filters.functionFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_functionFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Tool') {
          let selectedFilters_test = this.filters.toolFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_toolFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Author') {
          let selectedFilters_test = this.filters.authorFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_authorFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Asset type') {
          let selectedFilters_test = this.filters.inventoryTypeFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_inventoryTypeFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Credit type') {
          let selectedFilters_test = this.filters.creditTypeFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_creditTypeFilterValues = selectedFilters_test
        } else if (payload.filterType == 'Credit hours') {
          let selectedFilters_test = this.filters.creditHoursFilterValues.map((item) => item.name)
          payloadData.body.selectedFilter_creditHoursFilterValues = selectedFilters_test
        }
      }
      let response = await this.$learnAdmin.searchAll(payloadData)
      let ratingDetailsArray = []
      if (response && response.results) {
        ratingDetailsArray = (await this.determineContentRating(response.results)) || []
      }
      let assetFilterValue = response.results.filter((option) => option.format.name.toLowerCase() === 'asset')
      let assetMetaDataArray = assetFilterValue?.length > 0 ? await this.determineAssetMetaData(assetFilterValue) : []
      await response.results.map(async (item) => {
        if ({}.hasOwnProperty.call(item, 'inventoryCategory') && item.inventoryCategory === 'asset') {
          item.format = { name: item.inventoryCategory }
          let logo = {}.hasOwnProperty.call(item, 'provider') && {}.hasOwnProperty.call(item.provider, 'logo') && {}.hasOwnProperty.call(item.provider.logo, 'uri')
          item.provider = {
            guid: {}.hasOwnProperty.call(item, 'provider') && item.provider.guid ? item.provider.guid : '',
            name: {}.hasOwnProperty.call(item, 'provider') && item.provider.name ? item.provider.name : '',
            avatarImage: logo ? item.provider.logo.uri : ''
          }
          item.emsiSkills = []
          item.type = {
            name: item.inventoryType,
            src: `${this.assetMediaUrl}media-container/${item.inventoryType.image}`
          }
          item.assetType = 'automation'
          item.title = item.name
          item.synopsis = {}.hasOwnProperty.call(item, 'shortDescription') ? item.shortDescription : ''
          item.image = { uri: this.fetchAssetThumbnail(item.inventoryType) }
          item.applications = await item.applications.map((application) => {
            return { name: application.name, src: `${this.assetMediaUrl}media-container/${application.image}` }
          })
        } else {
          const duration = item.format.name.toLowerCase() === 'track' ? item.duration.value : item.duration
          item.duration = duration < 3600 ? `${Math.round(duration / 60)} min` : `${Math.round(duration / 3600)} hrs`
          item.assetType = item.format.name.toLowerCase() === 'credential' ? 'credentials' : item.format.name.toLowerCase() === 'track' ? 'track' : 'artifacts'
          item.title = item.format.name.toLowerCase() === 'track' ? item.name : item.title
          item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item.publishedAt : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'requiredBy') ? item.requiredBy : ''
          item.level =
            item.format.name.toLowerCase() === 'credential'
              ? {}.hasOwnProperty.call(item, 'level')
                ? item.level
                : ''
              : {}.hasOwnProperty.call(item, 'courseLevels')
              ? item.courseLevels[0]
              : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
        }
        item.tracks = []
        item.launchBtn = ''
        const cdnEnabled = {}.hasOwnProperty.call(item, 'meta') && {}.hasOwnProperty.call(item.meta, 'cdnEnabled') && item.meta.cdnEnabled
        let contentMetadata =
          item.format.name.toLowerCase() === 'asset' && assetMetaDataArray && assetMetaDataArray.length > 0
            ? assetMetaDataArray.filter((asset) => asset.id === item.id)
            : item.format.name.toLowerCase() === 'credential'
            ? await this.$learnAdmin.getCredentialMetaDataById({ id: item.id, token: this.authToken })
            : item.format.name.toLowerCase() === 'track'
            ? await this.$learnAdmin.getCollectionById({ token: this.authToken, id: item.guid })
            : await this.$learnAdmin.getContentMetaDataById({ id: item.id, token: this.authToken })
        contentMetadata = item.format.name.toLowerCase() === 'asset' ? contentMetadata[0] : item.format.name.toLowerCase() === 'track' ? contentMetadata.data : contentMetadata[0]
        item.meta = contentMetadata && {}.hasOwnProperty.call(contentMetadata, 'meta') ? contentMetadata.meta : item.meta
        item.meta.cdnEnabled = cdnEnabled
        item.progress = contentMetadata && {}.hasOwnProperty.call(contentMetadata, 'progress') ? contentMetadata.progress : item.progress
        item.rating =
          ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.id)
            ? ratingDetailsArray.filter((rating) => rating.entityId == item.id)[0]?.avgRating
            : 0
        item.assetCategoryData =
          contentMetadata && {}.hasOwnProperty.call(contentMetadata, 'inventoryCategory') && contentMetadata.inventoryCategory.toLowerCase() === 'asset'
            ? contentMetadata.inventoryCategory
            : item.format.name.toLowerCase() === 'asset'
            ? item.format.name
            : ''
        item.tracks =
          contentMetadata && {}.hasOwnProperty.call(contentMetadata, 'meta') ? ({}.hasOwnProperty.call(contentMetadata.meta, 'tracks') ? contentMetadata.meta.tracks : []) : []
        item.launchBtn = item.assetCategoryData
          ? (contentMetadata && {}.hasOwnProperty.call(contentMetadata, 'assetReference') && contentMetadata.assetReference?.externalAssetLink) ||
            item?.assetReference?.externalAssetLink
            ? 'Open'
            : 'Download'
          : item.progress > 0 && item.progress < 100
          ? 'Resume'
          : 'Launch'
      })
      if (response.filterValuesMap.creditHoursFilterValues) {
        this.creditHoursApiValues = response.filterValuesMap.creditHoursFilterValues
        var creditHoursFilterMap = [
          { name: '< 0.5 hours', count: 0 },
          { name: '0.5 - 1 hours', count: 0 },
          { name: '1 - 2 hours', count: 0 },
          { name: '2 - 5 hours', count: 0 },
          { name: '5 - 10 hours', count: 0 },
          { name: '10 - 20 hours', count: 0 },
          { name: '20 - 40 hours', count: 0 },
          { name: '> 40 hours', count: 0 }
        ]
        response.filterValuesMap.creditHoursFilterValues.forEach((element) => {
          if (element.name < 0.5) {
            creditHoursFilterMap[0].count = creditHoursFilterMap[0].count + element.count
          } else if (element.name >= 0.5 && element.name < 1.0) {
            creditHoursFilterMap[1].count = creditHoursFilterMap[1].count + element.count
          } else if (element.name >= 1 && element.name < 2) {
            creditHoursFilterMap[2].count = creditHoursFilterMap[2].count + element.count
          } else if (element.name >= 2 && element.name < 5) {
            creditHoursFilterMap[3].count = creditHoursFilterMap[3].count + element.count
          } else if (element.name >= 5 && element.name < 10) {
            creditHoursFilterMap[4].count = creditHoursFilterMap[4].count + element.count
          } else if (element.name >= 10 && element.name < 20) {
            creditHoursFilterMap[5].count = creditHoursFilterMap[5].count + element.count
          } else if (element.name >= 20 && element.name < 40) {
            creditHoursFilterMap[6].count = creditHoursFilterMap[6].count + element.count
          } else if (element.name >= 40) {
            creditHoursFilterMap[7].count = creditHoursFilterMap[7].count + element.count
          }
        })
      }
      if (response.filterValuesMap.creditHoursFilterValues && response.filterValuesMap.creditHoursFilterValues.length > 0) {
        response.filterValuesMap.creditHoursFilterValues = creditHoursFilterMap
      }
      this.filters = response.filterValuesMap
      this.searchResultsNew.items = response.results
      this.searchResultsNew.pageCount = response.totalPages
      this.searchResultsNew.pageCurrent = payload && payload.page ? payload.page : 1
      this.resultsCount = response.totalResults
      this.recentSearchSessionId = response.filterValuesSessionId
      const payloadCS = {
        action: 'Global Search',
        category: 'Global Search Page Load',
        value: JSON.stringify(this.searchResultsNew)
      }
      this.ClickstreamEventTrack(payloadCS)
      this.dataReady = true
    },
    async determineAssetMetaData(assetFilterValue) {
      var assetIdList = []
      await assetFilterValue.map((item) => {
        assetIdList.push(item.id)
      })
      if (assetIdList && assetIdList.length > 0) {
        const assetPayload = {
          token: this.authToken,
          id: assetIdList.join(',')
        }
        return this.$learnAdmin.getAssetMetaDataById(assetPayload)
      }
    },
    determineContentRating(assetFilterValue) {
      var assetIdList = []
      assetFilterValue.map((item) => {
        assetIdList.push(item.id)
      })
      if (assetIdList && assetIdList.length > 0) {
        const assetPayload = {
          token: this.authToken,
          body: assetIdList
        }
        return this.$learnAdmin.getContentRatingById(assetPayload)
      }
    },
    async onApplyFilter(data) {
      this.$route.params.fromRecommended = false
      this.$route.params.fromHome = false
      this.selectedFilters = data.filters
      this.sortBy = data.sortBy
      const groupByFilters = this.groupByFilter(this.selectedFilters)
      await this.search({ filters: groupByFilters, sortBy: data.sortBy, filterType: data.filterType })
      await this.GAEventTrackApplyFilter(data)
    },
    async clearFilters() {
      this.sideFilterData = false
      this.selectedFilters = []
      await this.search()
    },
    async onApplySort(data) {
      this.selectedFilters = data.filters
      this.sortBy = data.sortBy
      const groupByFilters = this.groupByFilter(this.selectedFilters)
      await this.search({ filters: groupByFilters, sortBy: data.sortBy, filterType: data.filterType })
    },
    async GAEventTrackApplyFilter(filter) {
      await filter.filters.map(async (f) => {
        const item = {
          'learner-id': this.learnerId,
          'tenant-guid': this.tenantGuid,
          'filter-type': f.type,
          'filter-option': f.name
        }
        const payload = {
          action: 'Global Search',
          category: 'Selected Filter',
          value: JSON.stringify(item),
          csvalue: item
        }
        await this.GAEventTrack(payload)
      })
      const payload = {
        action: 'Global Search',
        category: 'Sort Order',
        value: filter.sortBy
      }
      await this.GAEventTrack(payload)
    },
    async onPageClickSearch(payload) {
      if (payload.page) {
        this.currentPage = payload.page
      }
      this.selectedFilters = payload.selectedFilters
      payload.filters = this.groupByFilter(payload.selectedFilters)
      this.sortBy = payload.sortBy
      await this.search(payload)
    },
    getFilterPayload(payload) {
      var creditHoursPayload = []
      if (payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Credit hours') && payload.filters['Credit hours'].length) {
        if (payload.filters['Credit hours'].includes('< 0.5 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name < 0.5) {
              creditHoursPayload.push(item.name)
            }
          })
        }
        if (payload.filters['Credit hours'].includes('0.5 - 1 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name >= 0.5 && item.name < 1.0) {
              creditHoursPayload.push(item.name)
            }
          })
        }
        if (payload.filters['Credit hours'].includes('1 - 2 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name >= 1 && item.name < 2) {
              creditHoursPayload.push(item.name)
            }
          })
        }
        if (payload.filters['Credit hours'].includes('2 - 5 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name >= 2 && item.name < 5) {
              creditHoursPayload.push(item.name)
            }
          })
        }
        if (payload.filters['Credit hours'].includes('5 - 10 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name >= 5 && item.name < 10) {
              creditHoursPayload.push(item.name)
            }
          })
        }
        if (payload.filters['Credit hours'].includes('10 - 20 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name >= 10 && item.name < 20) {
              creditHoursPayload.push(item.name)
            }
          })
        }
        if (payload.filters['Credit hours'].includes('20 - 40 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name >= 20 && item.name < 40) {
              creditHoursPayload.push(item.name)
            }
          })
        }
        if (payload.filters['Credit hours'].includes('> 40 hours')) {
          this.creditHoursApiValues.forEach((item) => {
            if (item.name >= 40) {
              creditHoursPayload.push(item.name)
            }
          })
        }
      }
      return {
        duration: payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Duration') && payload.filters['Duration'].length ? payload.filters['Duration'] : [],
        provider: payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Provider') && payload.filters['Provider'].length ? payload.filters['Provider'] : [],
        level: payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Level') && payload.filters['Level'].length ? payload.filters['Level'] : [],
        content_type:
          payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Content type') && payload.filters['Content type'].length ? payload.filters['Content type'] : [],
        accreditation:
          payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Accreditation') && payload.filters['Accreditation'].length ? payload.filters['Accreditation'] : [],
        function: payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Function') && payload.filters['Function'].length ? payload.filters['Function'] : [],
        tool: payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Tool') && payload.filters['Tool'].length ? payload.filters['Tool'] : [],
        author: payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Author') && payload.filters['Author'].length ? payload.filters['Author'] : [],
        inventoryType:
          payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Asset type') && payload.filters['Asset type'].length ? payload.filters['Asset type'] : [],
        creditType:
          payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Credit type') && payload.filters['Credit type'].length ? payload.filters['Credit type'] : [],
        creditHours: payload && payload.filters && {}.hasOwnProperty.call(payload.filters, 'Credit hours') && payload.filters['Credit hours'].length ? creditHoursPayload : []
      }
    },
    groupByFilter(selectedFilters) {
      return selectedFilters.reduce((returnFilter, selectedFilter) => {
        let key = selectedFilter['type']
        if (!returnFilter[key]) {
          returnFilter[key] = []
        }
        returnFilter[key].push(selectedFilter.name)
        return returnFilter
      }, {})
    },
    onSideFilterData(data) {
      if (data) {
        this.sideFilterData = true
      } else if (!data) {
        this.sideFilterData = false
      }
    },
    onApplyAssetFilter(payload) {
      const searchBy = this.$route.params.searchBy
      if (searchBy === 'all') {
        payload.filters = { 'Content type': ['Asset'] }
        this.selectedFilters.push({ type: 'Content type', name: 'Asset', selected: true })
      } else if (searchBy === 'provider') {
        payload.filters = { Provider: [this.$route.params.provider], 'Content type': ['Asset'] }
        this.selectedFilters.push({ type: 'Provider', name: this.$route.params.provider, selected: true })
        this.selectedFilters.push({ type: 'Content type', name: 'Asset', selected: true })
      } else if (searchBy === 'application') {
        payload.filters = { Tool: [this.$route.params.application], 'Content type': ['Asset'] }
        this.selectedFilters.push({ type: 'Tool', name: this.$route.params.application, selected: true })
        this.selectedFilters.push({ type: 'Content type', name: 'Asset', selected: true })
      } else if (searchBy === 'type') {
        payload.filters = { 'Asset type': [this.$route.params.type], 'Content type': ['Asset'] }
        this.selectedFilters.push({ type: 'Asset type', name: this.$route.params.type, selected: true })
        this.selectedFilters.push({ type: 'Content type', name: 'Asset', selected: true })
      }
      return payload
    },
    onApplyContentTypeFilter(payload) {
      const type = this.$route.query.type
      payload.filters = { 'Content type': [type] }
      payload.filterType = 'Content type'
      if (this.selectedFilters.filter((e) => e.name === type).length == 0) {
        this.selectedFilters.push({ type: 'Content type', name: type, selected: true })
      }
      return payload
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results-text {
  width: 100%;
  padding: 0px;
  margin-left: 8px;
}
.search-result-pagination {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Futura PT Demi';
  font-size: 0.9rem;
  color: var(--ion-color-role-grey);

  span {
    position: relative;
    font-size: 1rem;
    padding: 0rem 0.5rem;
    cursor: pointer;
    font-family: 'Futura PT Demi';
  }
}

.searchbar-desktop {
  .searchbar-input-container {
    input {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 34px !important;
      font-family: 'Futura PT Demi';
    }
    .searchbar-search-icon {
      top: 15px;
      width: 34px;
      height: 34px;
    }
  }
}

.searchbar-mobile {
  width: 95% !important;
  margin: 0rem 2.5% !important;
  .searchbar-input-container {
    input {
      text-align: left;
      font-family: 'Futura PT Demi';
    }
  }
}

@media screen and (min-width: 1366px) {
  .search-result-panel-desktop {
    margin: 1.5rem 4%;
    padding: 1rem 4%;
  }
}

@media screen and (max-width: 1366px) {
  .back {
    margin-left: 4%;
  }

  ion-searchbar {
    width: 70% !important;
    margin-left: 15% !important;
  }

  .autocomplete-list-desktop {
    width: 65%;
    margin-left: 17%;
  }

  .search-result-panel-desktop {
    margin: 1.5rem 4%;
    padding: 1rem 4%;
  }

  .searchbar-desktop .searchbar-input-container input {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 1024px) {
  .back {
    float: left;
  }

  .search-result-panel-desktop {
    margin: 1.5rem 4%;
    padding: 1rem 7%;
  }
  .searchbar-desktop {
    .searchbar-input-container {
      input {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 24px !important;
        font-family: 'Futura PT Demi';
      }
      .searchbar-search-icon {
        top: 13px;
        width: 24px;
        height: 24px;
      }
    }
  }

  .searchbar-desktop .searchbar-input-container input {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .back {
    float: left;
  }

  .search-result-panel-desktop {
    margin: 1.5rem 2%;
    padding: 1rem 1%;
  }

  .searchbar-desktop .searchbar-input-container input {
    font-size: 20px !important;
  }
}

.non-white-background {
  background: var(--ion-color-dark-grey);
}

.back-btn-container {
  width: 100%;
  text-align: left;
}

.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin: 1rem 0rem 0.5rem 0;
  font-family: 'Arial';
  color: #5c5c5c;
  padding: 0;
  svg {
    position: relative;
    top: 5px;
  }
}

ion-searchbar {
  --background: var(--ion-color-white) !important;
  --border-radius: 20px;
  --color: var(--ion-color-black);
  width: 60%;
  margin-left: 20%;
  --box-shadow: 0;
}

.autocomplete-list-desktop {
  background: var(--ion-color-white) !important;
  border-radius: 20px;
  color: var(--ion-color-black);
  width: 50%;
  margin-left: 25%;
  padding: 0;
}

.autocomplete-list-mobile {
  background: var(--ion-color-white) !important;
  border-radius: 20px;
  color: var(--ion-color-black);
  width: 90% !important;
  margin: 0rem 5% !important;
}

.autocomplete-list-icon {
  margin-right: 0.5rem;
  color: var(--ion-color-tertiary) !important;
}

ion-item {
  cursor: pointer;
  margin: 0px;
}

.ion-label-desktop {
  margin: 0;
  font-size: 22px !important;
  font-family: 'Futura PT Demi' !important;
}

.search-result-panel-desktop {
  margin: 1.5rem 5%;
  padding: 4rem 7%;
}

.search-result-panel-mobile {
  margin: 0.5rem 2%;
  padding: 1% 1rem;
  .search-result-count-mobile {
    padding: 0.5rem 1rem;
  }
}

.search-result-panel-desktop,
.search-result-panel-mobile {
  background: var(--ion-color-white);
  text-align: left;
  border-radius: 1rem;
}

.search-filter-desktop {
  padding: 0 0 1rem 0;
}

.search-filter-mobile {
  padding: 2rem 0 1rem 0;
}

/* backdrop for right filter */
.filter-nav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000070;
  z-index: 98;
  cursor: pointer;
}

.filter-bar {
  position: fixed;
  z-index: 9999;
  right: 0;
  width: 364px;
}

.results-count-header {
  display: inline-block;
  margin-top: 16px;
  font-family: 'Futura PT Demi';
  font-size: 34px;
  color: black;
  text-align: start;
  margin-bottom: 0px;
}

.results-count-header-query {
  font-family: 'Futura PT Demi';
  font-size: 34px;
  color: black;
}

@media screen and (max-width: 1024px) {
  .search-filter-desktop {
    margin: 0 0 1.5rem 0;
  }
}

@media screen and (max-width: 480px) {
  .filter-bar {
    width: 100%;
  }

  .results-count-header {
    font-size: 24px;
  }

  .results-count-header-query {
    font-size: 24px;
  }
}
</style>
