var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-flag-modal" }, [
    _c(
      "div",
      { staticClass: "header-content" },
      [
        _c(
          "h2",
          {
            staticClass: "g-type-header title",
            class:
              _vm.$platform === "Desktop" ? "title-desktop" : "title-mobile",
            attrs: { size: "8" }
          },
          [_vm._v("Why are you flagging this comment?")]
        ),
        _c("svg-icon", {
          staticClass: "g-icon--small close",
          attrs: { "icon-class": "filter-close" },
          on: { click: _vm.onCancel }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "list-container" },
      [
        _c(
          "ion-radio-group",
          {
            attrs: { value: "flaggedReason" },
            on: {
              ionChange: function($event) {
                return _vm.checkSelectionsRadio($event)
              }
            }
          },
          [
            _c("div", { staticClass: "list-item" }, [
              _c(
                "div",
                { staticClass: "checkbox-container" },
                [
                  _c("ion-radio", {
                    attrs: {
                      slot: "start",
                      color: "tertiary",
                      value: "off-topic"
                    },
                    slot: "start"
                  })
                ],
                1
              ),
              _c("div", { staticClass: "label-container" }, [
                _c("h3", [_vm._v("It’s off-topic")]),
                _c("p", [
                  _vm._v(
                    "The comment is not relevant to the content or current discussion."
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "list-item" }, [
              _c(
                "div",
                { staticClass: "checkbox-container" },
                [
                  _c("ion-radio", {
                    attrs: {
                      slot: "start",
                      color: "tertiary",
                      value: "inappropriate"
                    },
                    slot: "start"
                  })
                ],
                1
              ),
              _c("div", { staticClass: "label-container" }, [
                _c("h3", [_vm._v("It’s inappropriate")]),
                _c("p", [
                  _vm._v(
                    "The comment contains content that could be considered offensive, abusive or a violation of community/workplace conduct."
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "list-item" }, [
              _c(
                "div",
                { staticClass: "checkbox-container" },
                [
                  _c("ion-radio", {
                    attrs: { slot: "start", color: "tertiary", value: "other" },
                    slot: "start"
                  })
                ],
                1
              ),
              _c("div", { staticClass: "label-container" }, [
                _c("h3", [_vm._v("Something else")])
              ])
            ])
          ]
        ),
        _vm.flaggedReason === "other"
          ? _c("ion-textarea", {
              staticClass: "other-comment",
              attrs: {
                rows: "4",
                autofocus: "",
                placeholder:
                  "Let us know your specific concerns in less than 250 characters. Please provide any relevant details."
              },
              on: {
                ionChange: function($event) {
                  return _vm.onFlagReasonChange($event, "otherComment")
                }
              }
            })
          : _vm._e(),
        _vm.otherCommentError
          ? _c("div", { staticClass: "error-container" }, [
              _c("p", [_vm._v("You have reached the 250 character limit")])
            ])
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer-container" },
      [
        _c(
          "a",
          {
            staticClass: "cancel-btn",
            attrs: { href: "#" },
            on: { click: _vm.onCancel }
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "ion-button",
          {
            staticClass: "ion-button-primary ion-text-capitalize submit-btn",
            attrs: {
              shape: "round",
              disabled:
                !_vm.flaggedReason ||
                (_vm.flaggedReason == "other" && !_vm.otherComment) ||
                this.otherCommentError,
              color: "primary"
            },
            on: { click: _vm.onSubmit }
          },
          [_vm._v("Submit")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }