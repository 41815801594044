<template>
  <div v-if="dataReady" class="main-content" :style="!isDesktopPlatform ? '' : 'padding: 2rem 3.8rem'" :class="this.$platform === 'Mobile' && this.$route.name === 'HelpCenterMain' ? 'help-center-top' : ''">
    <div v-if="!isLoggedIn" class="d-flex logo">
      <nav id="helpNav" v-if="this.$route.fullPath !== '/landing'" class="d-flex" :class="this.$platform === 'Mobile' ? 'help-nav-mobile' : ''">
        <div>
          <svg-icon icon-class="logo" class-name="logo logo--size" @click="onClickLogo" />
        </div>
        <ion-row>
          <ion-button
            @click="onBack"
            shape="round"
            color="tertiary"
            class="ion-button-tertiary back nav-right-items"
            :class="this.$platform === 'Mobile' ? 'nav-right-items-mobile' : ''"
            >Back</ion-button
          >
        </ion-row>
      </nav>
    </div>
    <ion-card ref="top" :class="[isDesktopPlatform ? 'help-center-container-desktop' : 'help-center-container-mobile', !isLoggedIn ? 'help-center-container-main-top' : '']">
      <svg-icon v-if="isLoggedIn" icon-class="close" class-name="g-icon--xsmall close" color="#182755" @click="onClose" />
      <ion-card-header :class="isDesktopPlatform ? 'header-desktop' : 'header-mobile'" style="padding-top: 20px">
        <ion-card-title v-if="isLoggedIn" class="g-type-subheading2 ion-text-start"> Welcome to the Help Center, {{ firstName }}! </ion-card-title>
        <ion-card-title v-else class="g-type-subheading2 ion-text-start"> Welcome to the Help Center ! </ion-card-title>
        <img v-if="isDesktopPlatform" class="image-desktop" :src="require(`@/assets/images/help-center.gif`)" alt="" />
      </ion-card-header>
      <ion-card-content class="ion-text-start">
        <div>
          We strive to deliver learning experiences that are meaningful and delightful, but there is always room to improve. We've created the Help Center so you have to a place to
          get help, let us know when something breaks, or if you have ideas or feedback on ways we can get better.
        </div>
        <img v-if="!isDesktopPlatform" class="image-mobile" :src="require(`@/assets/images/help-center.gif`)" alt="" />
      </ion-card-content>
    </ion-card>
    <ion-card v-if="isLoggedIn" :class="isDesktopPlatform ? 'help-center-container-desktop' : 'help-center-container-mobile'">
      <ion-card-header :class="isDesktopPlatform ? 'header-desktop' : 'header-mobile'">
        <ion-card-title v-if="isDesktopPlatform" class="g-type-subheading2 ion-text-start">
          Frequently asked questions<span class="downloadable">
            <a aria-label="download FAQ pdf" href="https://static.proedge.pwc.com/docs/ProEdgeLearnFAQ.pdf" target="_blank"
              ><img src="@/assets/images/idea-download.svg" alt=""
            /></a>
          </span>
        </ion-card-title>
        <ion-card-title v-else class="g-type-subheading2 ion-text-start">
          F.A.Qs<span class="downloadable">
            <a href="https://static.proedge.pwc.com/docs/ProEdgeLearnFAQ.pdf" target="_blank"><img src="@/assets/images/idea-download.svg" /></a>
          </span>
        </ion-card-title>
      </ion-card-header>
    </ion-card>
    <ion-card ref="support" :class="[isDesktopPlatform ? 'help-center-container-desktop' : 'help-center-container-mobile', !isLoggedIn ? 'help-center-container-main-bottom' : '']">
      <ion-card-header :class="isDesktopPlatform ? 'header-desktop' : 'header-mobile'">
        <ion-card-title class="g-type-subheading2 ion-text-start"> Contact Support </ion-card-title>
      </ion-card-header>
      <ion-card-content v-if="!isSupportContacted" class="ion-text-start">
        <div id="prompt1" class="content-prompt">1. Pick the topic from the set below and send us your thoughts</div>
        <ion-select
          aria-labelledby="prompt1"
          ref="topics"
          class="topic-select"
          :class="isDesktopPlatform ? 'topic-select-desktop' : 'topic-select-mobile'"
          interface="popover"
          placeholder="Select Topic"
          @ionChange="handleTopicSelect"
        >
          <ion-select-option v-for="(topic, index) in topics" :key="index" :value="topic.value">{{ topic.name }}</ion-select-option>
        </ion-select>
        <div id="prompt2" class="content-prompt">2. Let us know what you experienced, add a screenshot to help paint a clear picture</div>
        <ion-textarea ref="description" rows="5" required @ionChange="handleDescriptionInput" placeholder="Enter your description here"></ion-textarea>
        <div
          class="dashed-border"
          aria-labelledby="prompt2"
          :class="isDesktopPlatform ? 'drop-zone-desktop' : 'drop-zone-mobile'"
          @drop="handleDrop($event, 'upload-img-input', ticket.file)"
          @dragover="handleDragover"
          @dragenter="handleDragover"
        >
          <div class="upload-info">Drop or browse a file (png, jpeg, and jpg) here</div>
          <input ref="upload-img-input" class="upload-input" type="file" @change="handleClick($event, 'upload-img-input', ticket.file)" />
          <ion-button shape="round" color="tertiary" class="upload-btn ion-text-capitalize" :disabled="isSending" @click="handleUpload('upload-img-input')">Browse</ion-button>
          <div v-if="ticket.file.name" class="text-note">
            <svg-icon icon-class="file-alt-outline" class="page-icon"></svg-icon>
            {{ ticket.file.name }}
          </div>
        </div>
        <div v-if="!isLoggedIn" class="content-prompt">3. Please leave your email in case we need to ask any follow up questions</div>
        <ion-input v-if="!isLoggedIn" class="email" ref="email" rows="1" required placeholder="Email" @ionChange="handleEmailInput" v-bind:value="ticket.email"></ion-input>
        <div v-if="notificationMessage.length" class="notification">Error: {{ notificationMessage }}</div>
        <ion-button shape="round" color="primary" class="ion-button-primary ion-text-capitalize next-btn" :disabled="isSending" @click="onSend">Send</ion-button>
      </ion-card-content>
      <ion-card-content v-else class="ion-text-start">
        <div class="confirmation">
          <div>Thank you for contacting support.</div>
          <div>An email with your ticket reference number will be sent to you.</div>
        </div>
        <div class="close-btn-wrapper">
          <ion-button shape="round" color="primary" class="ion-button-primary ion-text-capitalize close-btn" @click="onClose">Close</ion-button>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'

export default {
  name: 'HelpCenter',
  components: {},
  props: {},
  data() {
    return {
      route: '',
      authToken: '',
      isLoggedIn: false,
      firstName: '',
      isSending: false,
      isSupportContacted: false,
      isDesktopPlatform: false,
      topics: [
        { value: 'Issue', name: 'I have an Issue' },
        { value: 'Feedback', name: 'I have Feedback' },
        { value: 'Question', name: 'I have a Question' }
      ],
      ticket: {
        product: 'LRN',
        topic: '',
        name: '',
        description: '',
        email: '',
        file: {
          name: '',
          file: null,
          allowedExtensions: ['.png', '.jpg', '.jpeg']
        },
        reference: ''
      },
      notificationMessage: '',
      dataReady: false
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'username', 'firstname'])
  },
  watch: {},
  created() {},
  async mounted() {
    await this.setup()
    this.$refs.top.scrollIntoView()
  },
  async activated() {
    await this.setup()
    this.$refs.top.scrollIntoView()
  },
  methods: {
    async setup() {
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      this.firstName = this.firstname || (await localforage.getItem('my_firstname'))
      this.ticket.email = this.username || (await localforage.getItem('my_username'))
      this.isLoggedIn = this.firstName != null && this.firstName.length > 0
      this.dataReady = true
    },
    onClose() {
      this.clearInputs()
      this.$router.go(-1)
    },
    onBack() {
      this.clearInputs()
      this.isDesktopPlatform ? this.$router.push('/landing') : this.$router.go(-1)
    },
    async onDownload() {
      const blob = await this.$learnAdmin.getHelpCenterFAQ({ token: this.authToken })
      var a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.setAttribute('download', 'faq.pdf')
      a.click()
    },
    handleTopicSelect(event) {
      this.ticket.topic = event.target.value
      const topics = this.topics.filter((topic) => topic.value === event.target.value)
      this.ticket.name = topics.length ? topics[0].name : ''
    },
    handleDescriptionInput(event) {
      this.ticket.description = event.target.value
    },
    handleEmailInput(event) {
      this.ticket.email = event.target.value
    },
    validInputs() {
      if (!this.ticket.topic) {
        this.notificationMessage = 'Please select a topic'
        return false
      }
      if (!this.ticket.description.length) {
        this.notificationMessage = 'Description cannot be empty'
        return false
      }
      if (!this.ticket.email || !this.ticket.email.length) {
        this.notificationMessage = 'Email cannot be empty'
        return false
      }
      // eslint-disable-next-line
      const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!emailPattern.test(this.ticket.email)) {
        this.notificationMessage = 'Not a valid email address'
        return false
      }
      if (this.ticket.file.name && !this.isValidFileType(this.ticket.file)) {
        this.notificationMessage = 'Cannot read your screenshot as an image'
        return false
      }
      return true
    },
    handleDrop(e, ref, targetField) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      const rawFile = files[0]
      this.upload(rawFile, ref, targetField)
      e.stopPropagation()
      e.preventDefault()
    },
    async upload(rawFile, ref, targetField) {
      targetField.name = rawFile.name
      targetField.file = rawFile
      this.$refs[ref].value = null
    },
    handleClick(e, ref, targetField) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.upload(rawFile, ref, targetField)
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload(ref) {
      this.$refs[ref].click()
    },
    isValidFileType(file) {
      const filename = file.name.toLowerCase()
      return /\.(png)$/.test(filename) || /\.(jpg)$/.test(filename) || /\.(jpeg)$/.test(filename)
    },
    async onSend() {
      this.isSending = true
      this.notificationMessage = ''
      if (!this.validInputs()) {
        this.isSending = false
        return
      }
      const data = new FormData()
      data.append('product', this.ticket.product)
      data.append('topic', this.ticket.topic)
      data.append('name', this.ticket.name)
      data.append('description', this.ticket.description)
      data.append('email', this.ticket.email)
      if (this.ticket.file.name) {
        data.append('file', this.ticket.file.file)
      }
      if (!this.isLoggedIn) {
        await this.$learnAdmin.getCloudEnvFromDefaultEnv(this.ticket.email)
      }
      const response = await this.$learnAdmin.submitHelpTicket({ data: data })
      if (response && response.code === 200) {
        this.ticket.reference = response.data
        this.isSupportContacted = true
        this.$refs.support.scrollIntoView()
      } else {
        this.notificationMessage = 'Cannot submit the ticket. Please contact your admin.'
        this.isSending = false
        return
      }
    },
    clearInputs() {
      this.isSending = false
      this.isSupportContacted = false
      this.ticket = {
        product: 'LRN',
        topic: '',
        name: '',
        description: '',
        email: '',
        file: {
          name: '',
          file: null,
          allowedExtensions: ['.png', '.jpg', '.jpeg']
        },
        reference: ''
      }
      this.notificationMessage = ''
      this.$refs.topics ? (this.$refs.topics.value = '') : ''
      this.$refs.description ? (this.$refs.description.value = '') : ''
    },
    onClickLogo() {
      const componentName = this.isLoggedIn ? 'Home' : 'Landing'
      this.$router.push({ name: componentName }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
ion-input.email {
  --padding-start: 7px;
}

.help-center-top {
  margin-top: 70px;
}

.ion-button-tertiary.back {
  --ion-color-base: #ffc758 !important;
  --ion-color-contrast: #1f3370 !important;
  padding-bottom: 1px;
  --box-shadow: none;
}

.d-flex {
  display: flex;
}

#helpNav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px;
  height: 50px;
  z-index: 800;
  width: 100%;
  background: map-get($edge-colors, 'white');
  // box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;

  .logo {
    width: 160px;
    height: 25px;
    margin: 0.7rem 1rem;
    cursor: pointer;
    .logo--size {
      position: relative;
      height: 30px;
      width: 114px;
    }
  }
}

.nav-right-items {
  position: absolute;
  right: 40px;
  text-transform: capitalize;
  top: 3px;

  font-family: 'Futura PT Demi';
  height: 35px !important;
  width: 100px !important;
}

.help-nav-mobile {
  height: 60px !important;
  padding-top: 10px !important;
}

.nav-right-items-mobile {
  margin-top: 10px !important;
}

.help-center-container-desktop {
  margin: 1% 5%;
  padding: 0.5rem 1rem;
  width: 90%;
  border-radius: 10px;
}

.help-center-container-main-top {
  margin: 80px 5% 0 5%;
}

.help-center-container-main-bottom {
  margin: 20px 5% 80px 5%;
}

.help-center-container-mobile {
  margin: 2% 3% 10% 3%;
  padding: 0.5rem 1rem;
  width: 94%;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--ion-color-white);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 22px;
  z-index: 10;
  cursor: pointer;
}

ion-card-header {
  padding-bottom: 0.5rem;
  ion-card-title {
    color: var(--ion-color-dark-blue);
  }
  .image-desktop {
    position: relative;
    float: right;
    top: -20px;
    margin: 0px 20px 0px 20px;
    width: 250px;
  }
  .image-mobile {
    width: 100%;
  }
}

.header-desktop {
  padding-top: 10px;
}

.header-mobile {
  font-size: 1.5rem;
  .g-type-subheading2 {
    font-weight: normal;
  }
}

ion-card-content {
  padding-top: 10px !important;
  font-size: 1.1rem !important;
}

ion-select {
  color: var(--ion-color-dark-blue);
}

.next-btn {
  float: right;
  font-family: 'Futura PT Demi';
  height: 35px !important;
  width: 100px !important;
  margin-bottom: 1rem;
  --box-shadow: unset;
}

.back-btn {
  font-family: 'Futura PT Demi';
  height: 35px !important;
  width: 100px !important;
  margin-bottom: 1rem;
}

.close-btn-wrapper {
  text-align: center;
  width: 90%;
  margin-bottom: 1rem;
  .close-btn {
    font-family: 'Futura PT Demi';
    height: 35px !important;
    width: 100px !important;
  }
}

ion-button {
  --box-shadow: none;
}

.notification {
  padding: 1rem 0rem;
  font-size: 1rem;
  color: var(--ion-color-status);
}

.topic-select {
  border: 1px solid var(--ion-color-role-grey);
  border-radius: 3px;
  margin-bottom: 12px;
}

.topic-select-desktop {
  width: 40%;
}

.topic-select-mobile {
  width: 100%;
}

.content-prompt {
  padding: 0.5rem 0;
}

.upload-input {
  display: none;
  z-index: -9999;
}
.upload-info {
  font-size: 16px;
}
.upload-btn {
  margin: 12px;
  --box-shadow: unset;
}
.drop-zone-desktop {
  margin: 1rem 20%;
  text-align: center;
  width: 60%;
}
.drop-zone-mobile {
  margin: 1rem 0;
  text-align: center;
  width: 100%;
}

.dashed-border {
  border: 1px dashed #acacac;
  border-radius: 10px;
  padding: 1rem 0;
}
.text-note {
  color: var(--ion-color-role-grey);
  font-size: 0.8rem;
  padding-right: 0.5rem;
}

.confirmation {
  margin: 1rem 0;
  text-align: center;
  color: black;
}

ion-textarea,
ion-input {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  border: 1px solid var(--ion-color-medium);
  border-radius: 3px;
  color: var(--ion-color-dark-blue) !important;
}

.downloadable {
  margin-left: 2rem;
  color: var(--ion-color-dark-blue) !important;
  cursor: pointer;
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP 11 */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 11pro */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP 11max */,
  only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) /* iP 6/6s/7/8 */,
  only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) /* iP 6+/6s+/7+/8+ */,
  only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP X /XS/11 Pro/12 Mini */,
  only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 12/12 Pro */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP XR/11 */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP XS Max/11 Pro Max */,
  only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 12 Pro Max */,
  only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 13/13 Pro */,
  only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Pro Max */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Mini */,
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) /* iPad 1/2/Mini/Air */,
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) /* iPad 3/4/Pro 9.7 */,
  only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) /* iPad Pro 10.5 */,
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) /* iPad Pro 12.9 */ {
  #helpNav {
    top: 0px !important;
  }
}
</style>
