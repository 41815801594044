var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fontsize_controller" }, [
    _c("label", { attrs: { for: "0" } }, [_vm._v("A")]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.fontSize,
          expression: "fontSize"
        }
      ],
      attrs: { type: "radio", id: "small", value: "0" },
      domProps: { checked: _vm._q(_vm.fontSize, "0") },
      on: {
        input: _vm.onInputKeyDown,
        change: function($event) {
          _vm.fontSize = "0"
        }
      }
    }),
    _c("label", { attrs: { for: "1" } }, [_vm._v("A")]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.fontSize,
          expression: "fontSize"
        }
      ],
      attrs: { type: "radio", id: "medium", value: "1" },
      domProps: { checked: _vm._q(_vm.fontSize, "1") },
      on: {
        input: _vm.onInputKeyDown,
        change: function($event) {
          _vm.fontSize = "1"
        }
      }
    }),
    _c("label", { attrs: { for: "2" } }, [_vm._v("A")]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.fontSize,
          expression: "fontSize"
        }
      ],
      attrs: { type: "radio", id: "large", value: "2" },
      domProps: { checked: _vm._q(_vm.fontSize, "2") },
      on: {
        input: _vm.onInputKeyDown,
        change: function($event) {
          _vm.fontSize = "2"
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }