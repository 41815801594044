<template>
  <div class="mylearning-tabs-container">
    <div v-if="this.$platform === 'Desktop'" class="tabs-container">
      <div>
        <button
          v-for="(tab, index) in tabsObject"
          :key="index"
          class="tab accessible-button"
          :class="tabTitle == tab.title ? 'selected' : ''"
          @click="updateTabIndex(tab)"
          :aria-label="tab.title"
        >
          {{ tab.displayTitle }} <span>{{ tab.count }}</span>
        </button>
      </div>
      <a href="#" class="show-completed" @click="showCompletedItems">Show completed learning</a>
    </div>
    <div v-else>
      <div class="mylearning-mobile-tabs-container">
        <div class="selected-tab" @click="toggleTabs">
          {{ tabs[tabIndex].displayTitle }} <span>{{ tabs[tabIndex].count }}</span>
          <i class="dropdown-arrow"></i>
        </div>
        <a href="#" class="show-completed" @click="showCompletedItems">Show completed</a>
      </div>
    </div>
    <div class="mylearning-filter" v-if="showFilter">
      <slot name="mylearning-item-filter" />
    </div>
    <slot name="mylearning-item-container" />
    <div v-if="tabsOpen && tabsObject.length > 1" class="overlay" @click="tabsOpen = false">
      <ul class="tabs-selection">
        <li v-for="(tab, index) in tabsObject" :key="index" :class="tabTitle == tab.title ? 'selected' : ''" :value="tab.title" @click="updateTabIndex(tab)">
          {{ tab.displayTitle }} <span>{{ tab.count }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage'

export default {
  name: 'MyLearningTab',
  components: {},
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    selectedTabIndex: {
      type: Number,
      required: false,
      default: () => {
        return 0
      }
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      tabIndex: 0,
      tabTitle: this.tabs[this.selectedTabIndex].title,
      tabsOpen: false,
      tabsObject: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.tabsObject = this.tabs.filter((tab) => tab.isShow)
    this.tabIndex = this.selectedTabIndex
  },
  mounted() {},

  methods: {
    updateTabIndex(tab) {
      this.tabTitle = tab.title
      this.tabIndex = tab.title == 'Required' ? 0 : tab.title == 'Recommended' ? 1 : tab.title == 'Added by you' ? 2 : null
      this.$emit('update-tab-index', this.tabIndex)
      if (this.$platform === 'Mobile') {
        this.toggleTabs()
      }
    },
    toggleTabs() {
      this.tabsOpen = !this.tabsOpen
    },
    async showCompletedItems() {
      const link = 'learningHistory'
      await localforage.setItem('profile-side-nav-changed', link)
      this.$eventBus.$emit('profile-sub-link-clicked', link)
      this.$router.push({ name: 'Profile', params: { id: link } }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid var(--ion-color-dark-grey);
}
.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  font: normal normal bold 16px/22px Arial;
}
.mylearning-tabs-container .tab {
  display: inline-block;
  padding: 15px 5px 10px !important;
  margin: 0 25px 0 5px;
  font-weight: bold;
  font-size: 1rem;
  color: #000;
  letter-spacing: 0.01px;
  cursor: pointer;

  &.selected {
    border-bottom: 5px solid #00d1b0;

    span {
      background: var(--ion-color-black);
      color: var(--ion-color-white);
    }
  }

  span {
    margin-left: 0.3rem;
    padding: 0rem 0.7rem;
    border-radius: 1rem;
    background: var(--ion-color-light-role-grey);
    font-size: 14px;
    font-family: Arial;
    color: var(--ion-color-black);
  }
}

.show-completed {
  padding: 10px 7px;
  color: #1f3370;
  font: normal normal bold 16px/22px Arial;
  text-decoration: none;
}

.show-completed:hover {
  text-decoration: underline;
}

.mylearning-mobile-tabs-container {
  position: relative;
  margin: 0 1.5rem;
  padding: 1rem 0rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .selected-tab {
    font-size: 14px;
    font-family: 'Arial';
    font-weight: bold;
    color: var(--ion-color-black);
    border-bottom: 5px solid #00d1b0;
    display: inline-block;
    padding-bottom: 0.5rem;

    span {
      margin-left: 0.3rem;
      padding: 0rem 0.7rem;
      border-radius: 1rem;
      background: var(--ion-color-black);
      font-size: 14px;
      color: var(--ion-color-white);
      font-family: 'Arial';
    }

    .dropdown-arrow {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid var(--ion-color-black);
      display: inline-block;
      margin-left: 0.6rem;
    }
  }
}

.mylearning-tabs-container .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

.tabs-selection {
  position: absolute;
  top: 305px;
  background: var(--ion-color-white);
  padding: 4px;
  width: 225px;
  z-index: 10;
  border-radius: 4px;

  li {
    list-style-type: none;
    color: var(--ion-color-dark);
    padding: 0.8rem 0.6rem;
    margin: 0.3rem 0.1rem;
    //border-bottom: 1px solid var(--ion-color-light-role-grey);
    text-align: start;
    border-radius: 6px;
    font-family: 'Arial';
    font-size: 14px;

    span {
      margin-left: 0.3rem;
      padding: 0rem 0.7rem;
      border-radius: 1rem;
      background: var(--ion-color-light-role-grey);
      font-size: 18px;
      font-family: Arial;
      color: var(--ion-color-black);
    }

    &.selected {
      background-color: #e9ebf1;
      color: var(--ion-color-dark-blue);

      span {
        background: var(--ion-color-black);
        color: var(--ion-color-white);
      }
    }
  }
}

.mylearning-filter {
  margin: 2rem 5px 2rem;
  // margin: 2rem 5px 0rem; // DO NOT DELTE: THIS IS FOR SINGLE TRACK
}

@media screen and (max-width: 1024px) {
  .mylearning-tabs-container .tab {
    font-size: 16px;
    margin: 0 12px;
  }
}

@media screen and (max-width: 768px) {
  .mylearning-tabs-container .tab {
    font-size: 14px;
    margin: 0 4px;
    padding-right: 8px;
  }
  .show-completed {
    padding: 10px 0 10px 7px;
  }
}

@media screen and (max-width: 480px) {
  .mylearning-filter {
    margin: 1rem 1rem 2rem;
    // margin: 1rem 1rem 0rem; // DO NOT DELTE: THIS IS FOR SINGLE TRACK
  }

  .mylearning-mobile-tabs-container {
    margin: 0 1rem;
  }
}
</style>
