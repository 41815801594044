var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.isDesktopPlatform ? "container-desktop" : "container-mobile" },
    [
      !_vm.dataReady
        ? _c("ion-spinner", {
            style: _vm.isDesktopPlatform
              ? "margin-top: 2rem;"
              : "margin-top: 1rem;",
            attrs: { color: "#1f3370" }
          })
        : _vm._e(),
      _vm.dataReady && _vm.items.length
        ? _c(
            "div",
            { staticClass: "home-item-container" },
            _vm._l(_vm.items, function(item, index) {
              return _c("UniversalCard", {
                key: item.id,
                attrs: {
                  item: item,
                  index: index,
                  itemsLength: _vm.items.length,
                  tab: "HomeInProgress"
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.dataReady && !_vm.items.length
        ? _c(
            "div",
            {
              class: _vm.isDesktopPlatform
                ? "home-item-container inprogress-empty-container"
                : "inprogress-empty-container",
              style: _vm.isDesktopPlatform
                ? "margin-top: 2rem;"
                : "margin-top: 1rem;"
            },
            [
              _c("div", { staticClass: "bookmarked-empty-container-grid" }, [
                _c("div", [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("No bookmarks? No worries.")
                  ]),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v(
                      "You can bookmark any ProEdge content you find along your journey, and it shows up here for easy access."
                    )
                  ]),
                  _c("div", { staticClass: "action-btns" }, [
                    _c(
                      "a",
                      {
                        staticClass: "learning-plan-btn",
                        attrs: { href: "#" },
                        on: { click: _vm.goToLearningPlan }
                      },
                      [_vm._v("Learning Plan")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "explore-btn",
                        attrs: { href: "#" },
                        on: { click: _vm.goToExplore }
                      },
                      [_vm._v("Explore")]
                    )
                  ])
                ])
              ]),
              _vm._m(0)
            ]
          )
        : _vm._e(),
      _vm.dataReady && _vm.items.length
        ? _c("div", { staticClass: "footer g-clickable" }, [
            _vm.showViewAllButton
              ? _c(
                  "a",
                  {
                    class: _vm.isDesktopPlatform ? "footer-ancr" : "",
                    staticStyle: { color: "unset", "text-decoration": "unset" },
                    attrs: { href: "#", "aria-label": "view all bookmarks" },
                    on: {
                      click: function($event) {
                        return _vm.goToBookmarkList()
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v("view all")]),
                    _c("svg-icon", {
                      staticClass: "g-icon--small",
                      staticStyle: {
                        "margin-top": "0rem",
                        "margin-left": "0.5rem",
                        "margin-bottom": "-4px"
                      },
                      attrs: { "icon-class": "icon-arrow-right" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bookmarked-empty-image" }, [
      _c("img", { attrs: { src: require("@/assets/images/no-bookmark.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }