<template>
  <div class="manage-skill-modal">
    <div class="header-content">
      <svg-icon icon-class="filter-close" class-name="g-icon--small" class="close" @click="dismissModal()" />
      <h3>{{ headingText }}</h3>
      <p>{{ descriptionText }}</p>
    </div>
    <div class="body-content" :class="{ 'scrollable-content': skills.length > 12 }">
      <div class="checkbox-container" v-for="skill in skills" :key="skill.name">
        <ion-checkbox
          @ionChange="onDeleteSkillChange($event, skill)"
          :checked="true"
          :value="skill.name"
          class="manageskills-modal__checkbox"
          color="tertiary"
          mode="md"
        ></ion-checkbox>
        <span>{{ skill.name }}</span>
      </div>
    </div>
    <div class="footer-content">
      <ion-button shape="round" :disabled="skillsListToDel.length == 0" color="primary" class="ion-button-primary ion-text-capitalize" @click="onSave">Save</ion-button>
    </div>
  </div>
</template>
  
  <script>
import localforage from 'localforage'
import { popoverController } from '@modus/ionic-vue'

export default {
  name: 'ManageSkillsModal',
  components: {},
  props: {
    skills: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      authToken: '',
      headingText: 'Manage skills',
      descriptionText: 'Uncheck skills you do not wish to have included in your profile.',
      skillsListToDel: []
    }
  },
  computed: {},
  watch: {},
  async created() {
    this.authToken = await localforage.getItem('my_access_token')
  },
  mounted() {},
  methods: {
    dismissModal() {
      popoverController.dismiss()
    },
    onDeleteSkillChange(e, skill) {
      if (e.detail.checked) {
        this.skillsListToDel = this.skillsListToDel.filter((e) => e !== skill.guid)
      } else {
        this.skillsListToDel.push(skill.guid)
      }
    },
    async onSave() {
      this.skillsListToDel.map(async (skill) => {
        await this.$learnAdmin.deleteUserSkill({ token: this.authToken, guid: skill }).then((res) => {
          this.$eventBus.$emit('skills-deleted', res)
        })
      })
      popoverController.dismiss()
    }
  }
}
</script>
<style lang="scss">
.modal-container-class {
  .popover-content {
    border-radius: 20px;
    @media (max-width: 500px) {
      width: 100%;
      top: 0 !important;
      left: 0 !important;
      border-radius: unset;
    }
  }
}
</style>
  <style lang="scss" scoped>
.ion-button-primary {
    --box-shadow: none;
}
.manage-skill-modal {
  padding: 2rem;
  position: relative;
  border-radius: 60px;
  .header-content {
    .close {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      cursor: pointer;
    }
    h3 {
      font: normal normal normal 24px/34px 'Futura PT Demi';
      margin: 0 0 1.5rem 0;
    }
    p {
      margin: 0 0 2rem 0;
      font: normal normal normal 16px/22px Arial;
    }
  }
  .body-content {
    .checkbox-container {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      ion-checkbox {
        margin-right: 0.5rem;
        min-width: 1.125rem;
      }
      span {
        font: normal normal bold 14px/18px Arial;
        color: #1f3370;
        text-align: left;
      }
    }
  }
  .footer-content {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
  