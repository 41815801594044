<template>
  <div class="edge-change-password">
    <div class="edge-change-password__svg-wrapper">
      <svg-icon icon-class="small-logo" class-name="logo--size" :class="this.$platform === 'Desktop' ? 'on-desktop' : 'on-mobile'" />
    </div>
    <div class="edge-change-password__error-message">{{ errorMessage }}</div>

    <div class="edge-change-password__input">
      <ion-item class="ion-no-padding">
        <ion-input placeholder="Email Address" autofocus @ionChange="user.email = $event.target.value" :disabled="isInputDisabled" ref="email"></ion-input>
      </ion-item>
      <ion-item class="edge-linkedinConnect__email-input ion-no-padding">
        <ion-input placeholder="Verification Code" @ionChange="setVerificationCode($event)" :disabled="isInputDisabled" ref="verificationCode"></ion-input>
      </ion-item>
      <ion-item class="ion-no-padding">
        <ion-input
          :type="showPwd ? 'text' : 'password'"
          placeholder="Password"
          @ionChange="
            user.newPassword = $event.target.value
            onPasswordChange()
          "
          :disabled="isInputDisabled"
          ref="password"
        ></ion-input>
        <ion-icon slot="end" :name="showPwd ? 'eye' : 'eye-off'" @click="() => (showPwd = !showPwd)"></ion-icon>
      </ion-item>
    </div>
    <ion-button
      shape="round"
      color="primary"
      class="ion-button-primary"
      style="text-transform: capitalize; --box-shadow: none"
      @click="onChangePassword"
      :disabled="isInputDisabled"
      >Change Password</ion-button
    >
    <div class="g-type-small" style="margin-top: 0.5rem">
      <span class="edge-change-password__link" @click="onLogin">Login</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { validEmail, validPassword } from '../../../src/utils/validate.js'

export default {
  name: 'ChangePassword',
  props: {},
  data() {
    return {
      showPwd: false,
      isInputDisabled: false,
      errorMessage: '',
      user: {
        email: '',
        verificationCode: '',
        newPassword: ''
      },
      placeholderErrorMessage: 'You have entered an invalid email and/or password',
      pValidator: {
        characterLength: false,
        uppercase: false,
        lowercase: false,
        number: false,
        nameNotPresent: true
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('user', ['setAccessToken']),
    ...mapActions('environment', ['setCloudEnvInfo']),
    async onPasswordChange() {
      this.pValidator.characterLength = this.user.newPassword && this.user.newPassword.length > 7 ? true : false
      this.pValidator.lowercase = /[a-z]/.test(this.user.newPassword) ? true : false
      this.pValidator.uppercase = /[A-Z]/.test(this.user.newPassword) ? true : false
      this.pValidator.number = /[0-9]/.test(this.user.newPassword) ? true : false
      this.pValidator.nameNotPresent = this.user.newPassword ? !this.user.newPassword.toLowerCase().includes(this.user.email.toLowerCase().trim()) : true
    },
    async onChangePassword() {
      this.toggleInputs()
      this.setErrorMessage()
      const isCloudEnvSet = await this.setCloudEnvData()
      if (!isCloudEnvSet) {
        this.setErrorMessage(this.placeholderErrorMessage)
        this.toggleInputs()
        return
      }

      if (!validPassword(this.user.newPassword)) {
        this.setErrorMessage('Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number')
        this.toggleInputs()
        return
      }

      const response = await this.setPassword()

      if (!response || response.error) {
        this.setErrorMessage(response.message)
        this.toggleInputs()
        return
      }
      this.onLogin()
      this.clearInputs()
      this.toggleInputs()
    },
    toggleInputs() {
      this.isInputDisabled = !this.isInputDisabled
    },
    clearInputs() {
      this.$refs.email.value = null
      this.$refs.verificationCode.value = null
      this.$refs.password.value = null
    },
    setErrorMessage(message) {
      this.errorMessage = message
    },
    validateFields() {
      const pValidatorTest = this.pValidator.characterLength && this.pValidator.uppercase && this.pValidator.lowercase && this.pValidator.number && this.pValidator.nameNotPresent
      return pValidatorTest && validEmail(this.user.email) && validPassword(this.user.newPassword)
    },
    async setPassword() {
      return this.$learnAdmin.setPassword(this.user).then((res) => {
        return res
      })
    },
    async setCloudEnvData() {
      const response =
        process.env.VUE_APP_USE_FFAPI === 'true'
          ? await this.$learnAdmin.getCloudEnvByUserEmailAddress(this.user.email)
          : await this.$learnAdmin.getCloudEnvFromDefaultEnv(this.user.email)
      if (!response.data || !response.data.cloudEnv) {
        return false
      }
      await this.setCloudEnvInfo(response.data.cloudEnv)
      await this.$learnAdmin.updateEnvFromLocalForage()
      return true
    },
    setVerificationCode(event) {
      event.target.value = event.target.value ? event.target.value.replace(/\s+/g, '') : ''
      this.user.verificationCode = event.target.value
    },
    onLogin() {
      this.$platform === 'Mobile' ? this.$router.push('/login') : this.$emit('next', 'Login')
    }
  }
}
</script>
<style lang="scss" scoped>
@include block('edge-change-password') {
  @include element('svg-wrapper') {
    padding: 8% 8% 8% 8%;
    @include block('svg') {
      width: 100%;
      height: 100%;
    }
  }
  @include element('input') {
    padding: 0% 8% 8% 8%;
  }
  @include element('link') {
    color: var(--ion-color-tertiary);
    font-weight: bold;
    cursor: pointer;
  }
  @include element('error-message') {
    padding: 0% 8%;
    color: var(--ion-color-status);
  }
}
.edge-change-password {
  .logo--size {
    width: 100%;
    height: 100%;
  }
}

.on-desktop {
  width: 40% !important;
}

.on-mobile {
  width: 80% !important;
}
</style>
