import localforage from 'localforage'
import user from '@/api/user'
import content from '@/api/content'
import role from '@/api/role'
import skill from '@/api/skill'
import group from '@/api/group'
import achievements from '@/api/achievements'
import share from '@/api/share'
import fflag from '@/api/fflag'
import cloud from '@/api/cloud'
import search from '@/api/search'
import autographer from '@/api/autographer'
import tracks from '@/api/tracks'
import goals from '@/api/goals'
import rating from '@/api/rating'

export default class LearnAdmin {
  constructor() {
    this.baseUri = '' // process.env.VUE_APP_LEARN_API_BASE_URI
    this.planUri = '' // process.env.VUE_APP_PLAN_API_BASE_URI
    this.cmsUri = '' // process.env.VUE_APP_CMS_API_BASE_URI
    this.shareUri = '' // process.env.VUE_APP_SHARE_API_BASE_URI
    this.vdiUri = '' // process.env.VUE_APP_VDI_API
    this.applyUri = '' // process.env.VUE_APP_APPLY_API_BASE_URI
    this.ffapi = process.env.VUE_APP_FF_API_BASE_URI
    this.searchUri = '' // process.env.VUE_APP_SEARCH_API_BASE_URI
    this.agUri = '' // process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI
    this.updateEnvFromLocalForage()
    this.trackUri = '' // process.env.VUE_APP_TRACK_API_BASE_URI
    this.SocialUri = '' //process.env.VUE_APP_SOCIAL_API_BASE_URI
  }

  getBaseUri() {
    return this.baseUri
  }
  getPlanUri() {
    return this.planUri
  }
  getCmsUri() {
    return this.cmsUri
  }
  getShareUri() {
    return this.shareUri
  }
  getVdiUri() {
    return this.vdiUri
  }
  getFFApi() {
    return this.ffapi
  }
  getSearchUri() {
    return this.searchUri
  }
  getAGUri() {
    return this.agUri
  }
  getApplyUri() {
    return this.applyUri
  }
  getSocialUri() {
    return this.SocialUri
  }

  setBaseUri(uri) {
    this.baseUri = uri
  }
  setPlanUri(uri) {
    this.planUri = uri
  }
  setCmsUri(uri) {
    this.cmsUri = uri
  }
  setShareUri(uri) {
    this.shareUri = uri
  }
  setVdiUri(uri) {
    this.vdiUri = uri
  }
  setFFApi(uri) {
    this.ffapi = uri
  }
  setSearchUri(uri) {
    this.searchUri = uri
  }
  setAGUri(uri) {
    this.agUri = uri
  }
  setApplyUri(uri) {
    this.applyUri = uri
  }
  setTrackUri(uri) {
    this.trackUri = uri
  }
  setSocialUri(uri) {
    this.SocialUri = uri
  }

  /*
   * Feature Flags
   */

  async getFeatureFlags() {
    return await fflag.getFeatureFlags()
  }

  async getBackupFeatureFlags() {
    return await fflag.getBackupFeatureFlags()
  }

  /*
   * Cloud Enviroment
   */
  async updateEnvFromLocalForage() {
    var cloudEnv = await localforage.getItem('cloudEnv')
    if (!cloudEnv) { return }
    this.baseUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'learnApiBaseUri') && cloudEnv.baseApis.learnApiBaseUri.length
        ? cloudEnv.baseApis.learnApiBaseUri
        : process.env.VUE_APP_LEARN_API_BASE_URI
    this.planUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'planApiBaseUri') && cloudEnv.baseApis.planApiBaseUri.length
        ? cloudEnv.baseApis.planApiBaseUri
        : process.env.VUE_APP_PLAN_API_BASE_URI
    this.cmsUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'cmsApiBaseUri') && cloudEnv.baseApis.cmsApiBaseUri.length ? cloudEnv.baseApis.cmsApiBaseUri : process.env.VUE_APP_CMS_API_BASE_URI
    this.shareUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'shareApiBaseUri') && cloudEnv.baseApis.shareApiBaseUri.length
        ? cloudEnv.baseApis.shareApiBaseUri
        : process.env.VUE_APP_SHARE_API_BASE_URI
    this.vdiUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'vdiApiBaseUri') && cloudEnv.baseApis.vdiApiBaseUri.length ? cloudEnv.baseApis.vdiApiBaseUri : process.env.VUE_APP_VDI_API
    this.applyUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'applyApiBaseUri') && cloudEnv.baseApis.applyApiBaseUri.length
        ? cloudEnv.baseApis.applyApiBaseUri
        : process.env.VUE_APP_APPLY_API_BASE_URI
    this.ffapi = {}.hasOwnProperty.call(cloudEnv.microservices, 'ffApi') && cloudEnv.microservices.ffApi.length ? cloudEnv.microservices.ffApi : process.env.VUE_APP_FF_API_BASE_URI
    this.searchUri =
      {}.hasOwnProperty.call(cloudEnv.microservices, 'search') && cloudEnv.microservices.search.length ? cloudEnv.microservices.search : process.env.VUE_APP_SEARCH_API_BASE_URI
    this.agUri =
      {}.hasOwnProperty.call(cloudEnv.microservices, 'autographer') && cloudEnv.microservices.autographer.length
        ? cloudEnv.microservices.autographer
        : process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI
    this.trackUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'trackApiBaseUri') && cloudEnv.baseApis.trackApiBaseUri.length
        ? cloudEnv.baseApis.trackApiBaseUri
        : process.env.VUE_APP_TRACK_API_BASE_URI
    this.SocialUri =
      {}.hasOwnProperty.call(cloudEnv.baseApis, 'socialApiBaseUri') && cloudEnv.baseApis.socialApiBaseUri.length ? cloudEnv.baseApis.socialApiBaseUri : process.env.VUE_APP_SOCIAL_API_BASE_URI
  }

  async getCloudEnvFromDefaultEnv(email) {
    const response = await this.getCloudEnvByUserEmailAddress(email)
    response.data.cloudEnv.baseApis.learnApiBaseUri = process.env.VUE_APP_LEARN_API_BASE_URI
    response.data.cloudEnv.baseApis.planApiBaseUri = process.env.VUE_APP_PLAN_API_BASE_URI
    response.data.cloudEnv.baseApis.cmsApiBaseUri = process.env.VUE_APP_CMS_API_BASE_URI
    response.data.cloudEnv.baseApis.shareApiBaseUri = process.env.VUE_APP_SHARE_API_BASE_URI
    response.data.cloudEnv.baseApis.vdiApiBaseUri = process.env.VUE_APP_VDI_API
    response.data.cloudEnv.baseApis.applyApiBaseUri = process.env.VUE_APP_APPLY_API_BASE_URI
    response.data.cloudEnv.microservices.ffApi = process.env.VUE_APP_FF_API_BASE_URI
    response.data.cloudEnv.microservices.search = process.env.VUE_APP_SEARCH_API_BASE_URI
    response.data.cloudEnv.microservices.autographer = process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI
    response.data.cloudEnv.baseApis.trackApiBaseUri = process.env.VUE_APP_TRACK_API_BASE_URI
    response.data.cloudEnv.baseApis.socialApiBaseUri = process.env.VUE_APP_SOCIAL_API_BASE_URI
    this.baseUri = process.env.VUE_APP_LEARN_API_BASE_URI
    this.planUri = process.env.VUE_APP_PLAN_API_BASE_URI
    this.cmsUri = process.env.VUE_APP_CMS_API_BASE_URI
    this.shareUri = process.env.VUE_APP_SHARE_API_BASE_URI
    this.vdiUri = process.env.VUE_APP_VDI_API
    this.applyUri = process.env.VUE_APP_APPLY_API_BASE_URI
    this.ffapi = process.env.VUE_APP_FF_API_BASE_URI
    this.searchUri = process.env.VUE_APP_SEARCH_API_BASE_URI
    this.agUri = process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI
    this.trackUri = process.env.VUE_APP_TRACK_API_BASE_URI
    this.SocialUri = process.env.VUE_APP_SOCIAL_API_BASE_URI
    return response
  }

  async getCloudEnvByUserEmailAddress(email) {
    // { "email": "string" }
    return await cloud.getCloudEnvByEmail(`${this.ffapi}user/${email}/cloudEnv`)
  }

  /*
   * Autographer API
   */
  async getSignedURI(payload) {
    return await autographer.getSignedURI(`${this.agUri}`, payload)
  }

  /*
   * User APIs
   */
  async login(payload) {
    // { "email": "string", "password": "string", "username": "string" }
    return await user.login(`${this.baseUri}users/login`, payload)
  }

  async ssoCheck(email) {
    // { "email": "string" }
    return await user.ssoCheck(`${this.baseUri}users/check-sso-login/${email}`)
  }

  async getUserInfo(payload) {
    // { "token": "string" }
    return await user.getUserInfo(`${this.baseUri}users/me`, payload)
  }

  async getUserTenantInfo(payload) {
    // { "token": "string" }
    return await user.getUserTenantInfo(`${this.baseUri}users/me/tenant`, payload)
  }

  async logout(payload) {
    // { "token": "string" }
    return await user.logout(`${this.baseUri}users/logout`, payload)
  }

  async registerUser(payload) {
    // { "email": "string", "password": "string", "username": "string" }
    return await user.registerUser(`${this.baseUri}users/register`, payload)
  }

  async activateUser(payload) {
    // { "email": "string", "verificationCode": "string" }
    return await user.activateUser(`${this.baseUri}users/activate`, payload)
  }

  async changePassword(payload) {
    // { "email": "string", "newPassword": "string", "oldPassword": "string" }
    return await user.changePassword(`${this.baseUri}users/change-password`, payload)
  }

  async resetPassword(payload) {
    // { "email": "string", "isSendMail": true }
    return await user.resetPassword(`${this.baseUri}users/reset-password`, payload)
  }

  async setPassword(payload) {
    // { "email": "string", "newPassword": "string", "verificationCode": "string" }
    return await user.setPassword(`${this.baseUri}users/set-password`, payload)
  }

  async sendVerificationCode(payload) {
    // { "email": "string" }
    return await user.sendVerificationCode(`${this.baseUri}users/${payload.email}/send-verification`)
  }

  async renewToken(payload) {
    // { "token": "string" }
    return await user.renewToken(`${this.baseUri}users/renewToken`, payload)
  }

  async getGCSToken(payload) {
    // { "token": "string" }
    return await user.getGCSToken(`${this.baseUri}auth/gcs-token`, payload)
  }

  async getUserProfile(payload) {
    // { "token": "string" }
    return await user.getUserProfile(`${this.baseUri}users/me/profile`, payload)
  }

  async getUserProfileByGuid(payload) {
    // { "token": "string", "guid" : "string" }
    return await user.getUserProfileByGuid(`${this.planUri}users/guids/${payload.guid}`, payload)
  }

  async getCompanyLeaderboard(payload) {
    // { "token": "string" }
    return await user.getCompanyLeaderboard(`${this.baseUri}users/company/leaderboard`, payload)
  }

  async getUserRoleHistory(payload) {
    // { "token": "string" }
    return await user.getUserRoleHistory(`${this.baseUri}users/me/jobs/history`, payload)
  }

  async getUserSuggestedRole(payload) {
    // { "token": "string" }
    return await user.getUserSuggestedRole(`${this.baseUri}users/me/suggested-roles`, payload)
  }

  async addPastRole(payload) {
    // { "token": "string",   "company": "string", "companyImage": "string", "endedAt": "string", "guid": "string", "location": "string", "name": "string", "startedAt": "string" }
    return await user.addPastRole(`${this.baseUri}users/me/past-roles`, payload)
  }

  async getUserSkills(payload) {
    // { "token": "string" }
    return await user.getUserSkills(`${this.baseUri}users/me/skills`, payload)
  }

  async getSkillByName(payload) {
    // { "token": "string", "skill" : "string" }
    return await user.getSkillByName(`${this.baseUri}skills/names/${payload.skill}`, payload)
  }

  async getSkillByGuid(payload) {
    // { "token": "string", "guid" : "string" }
    return await user.getSkillByGuid(`${this.baseUri}skills/${payload.guid}`, payload)
  }

  async searchSkillByName(payload) {
    // { "token": "string", "skill" : "string" }
    return await user.searchSkillByName(`${this.baseUri}skills/skillbyname?q=${payload.skill}`, payload)
  }

  async addUserSkill(payload) {
    // { "token": "string", "guid": "string" }
    return await user.addUserSkill(`${this.baseUri}users/me/skills/${payload.guid}`, payload)
  }

  async deleteUserSkill(payload) {
    // { "token": "string", "guid": "string" }
    return await user.deleteUserSkill(`${this.baseUri}users/me/skills/${payload.guid}`, payload)
  }

  async getUserExplorativeskills(payload) {
    // { "token": "string" }
    return await user.getUserExplorativeskills(`${this.baseUri}users/me/exploratory-skills`, payload)
  }
  async getUserAcquiredSkills(payload) {
    // { "token": "string" }
    return await user.getUserAcquiredSkills(`${this.baseUri}users/me/acquiredSkills`, payload)
  }

  async addUserExplorativeSkill(payload) {
    // { "token": "string", "guid": "string" }
    return await user.addUserExplorativeSkill(`${this.baseUri}users/me/exploratory-skills/${payload.guid}`, payload)
  }

  async postUserExplorativeSkills(payload) {
    // { "token": "string", "guid": "string" }
    return await user.postUserExplorativeSkills(`${this.baseUri}users/me/exploratory-skills`, payload)
  }

  async getUserUpskills(payload) {
    // { "token": "string", "guid": "string" }
    return await user.getUserUpskills(`${this.baseUri}users/me/upskills`, payload)
  }

  async getUserActivitySummary(payload) {
    // { "token": "string", "guid": "string" }
    return await user.getUserActivitySummary(`${this.baseUri}users/${payload.guid}/activity-summary`, payload)
  }

  async getUserIdeas(payload) {
    // { "token": "string" }
    return await user.getUserIdeas(`${this.baseUri}users/me/ideas`, payload)
  }

  async getUserConnections(payload) {
    // { "token": "string"}
    return await user.getUserConnections(`${this.baseUri}users/me/connections`, payload)
  }

  async getUserConnectionProfile(payload) {
    // { "token": "string", "guid": "string" }
    return await user.getUserConnectionProfile(`${this.baseUri}users/${payload.guid}/profile`, payload)
  }

  async getUserFollowers(payload) {
    // { "token": "string" }
    return await user.getUserFollowers(`${this.baseUri}users/me/followers`, payload)
  }

  async getUserFollowings(payload) {
    // { "token": "string" }
    return await user.getUserFollowings(`${this.baseUri}users/me/followings`, payload)
  }

  async addUserFollowings(payload) {
    // { "token": "string", "guid": "string" }
    return await user.addUserFollowings(`${this.baseUri}users/me/followings/${payload.guid}`, payload)
  }

  async removeUserFollowings(payload) {
    // { "token": "string", "guid": "string" }
    return await user.removeUserFollowings(`${this.baseUri}users/me/followings/${payload.guid}`, payload)
  }

  async getAllBookmarks(payload) {
    // { "token": "string", "pageNo": Number}
    return await user.getAllBookmarks(`${this.baseUri}users/me/all-bookmarks?pageNo=${payload.pageNo}`, payload)
  }

  async getContentBookmarks(payload) {
    // { "token": "string", "pageNo": Number}
    return await user.getContentBookmarks(`${this.baseUri}users/me/bookmarks?pageNo=${payload.pageNo}`, payload)
  }

  async addBookmark(payload) {
    // { "token": "string", body: {"assetId": "string", "assetType": "string" } }
    //   OR
    // { "token": "string", body: {"entityId": "string", "entityType": "string" } }
    const uri = {}.hasOwnProperty.call(payload.body, 'assetId') ? this.baseUri : this.applyUri
    return await user.addBookmark(`${uri}users/me/bookmarks`, payload)
  }

  async removeBookmark(payload) {
    // { "token": "string", "assetId": "string" } OR { "token": "string", "entityId": "string" }
    const uri = {}.hasOwnProperty.call(payload, 'assetId') ? this.baseUri : this.applyUri
    const id = {}.hasOwnProperty.call(payload, 'assetId') ? payload.assetId : payload.entityId
    return await user.removeBookmark(`${uri}users/me/bookmarks/${id}`, payload)
  }

  async updateOnboardingStage(payload) {
    // { "token": "string", "stage": "string" }
    return await user.updateOnboardingStage(`${this.baseUri}users/me/on-boarding?s=${payload.stage}`, payload)
  }

  async getUserLearningHistory(payload, pageNo = 1, pageSize = 10) {
    // { "token": "string"}
    return await user.getUserLearningHistory(`${this.baseUri}users/me/learning-history?pageNo=${pageNo}&pageSize=${pageSize}`, payload)
  }

  async getUserLearningProgressSummary(payload) {
    // { "token": "string"}
    return await user.getUserLearningProgress(`${this.baseUri}users/me/role-learning-progress-summary`, payload)
  }

  async getUserLearningProgressDetails(payload) {
    // { "token": "string"}
    return await user.getUserLearningProgress(`${this.baseUri}users/me/role-learning-progress-details`, payload)
  }

  async submitHelpTicket(payload) {
    // { "data" : "DataForm"}
    return await user.submitHelpTicket(`${this.planUri}help/me`, payload)
  }

  async getAppNotifications(payload) {
    // { "token": "string" }
    return await user.getUserNotifs(`${this.baseUri}users/me/notifications`, payload)
  }

  async updateAppNotificationsDismiss(payload) {
    // { "token": "string", "body" : Array }
    return await user.updateUserNotifs(`${this.baseUri}notifications/mark-dismissed`, payload)
  }

  async updateAppNotificationsRead(payload) {
    // { "token": "string", "body" : Array }
    return await user.updateUserNotifs(`${this.baseUri}notifications/mark-read`, payload)
  }

  /*
   * Content APIs
   */
  async getMyContents(payload) {
    // { "token": "string" }
    return await content.getMyContents(`${this.baseUri}contents/my-contents`, payload)
  }

  async getContentById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getContentDetailsById(`${this.baseUri}contents/${payload.id}`, payload)
  }

  async getContentMetaDataById(payload) {
    // get metadata for courses, video, articles, audio and podcast
    // { "token": "string", "id": "string" }
    return await content.getContentById(`${this.baseUri}contents/ids/${payload.id}`, payload)
  }

  async getAssetMetaDataById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getContentById(`${this.applyUri}automations/list/${payload.id}`, payload)
  }

  async getCredentialMetaDataById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getContentById(`${this.baseUri}credentials/ids/${payload.id}`, payload)
  }

  async getCollectionById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getCollectionById(`${this.baseUri}learning-plan/tracks/${payload.id}/details`, payload)
  }

  async getRelatedTrackContentById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getRelatedTrackContentById(`${this.baseUri}contents/related-tracks/${payload.id}`, payload)
  }
  async getRelatedContentById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getRelatedContentById(`${this.baseUri}contents/related/${payload.id}`, payload)
  }
  async completeContentById(payload) {
    // { "token": "string", "id": "string" }
    return await content.completeContentById(`${this.baseUri}contents/${payload.id}/complete`, payload)
  }

  async updateContentProgressById(payload) {
    // { "token": "string", "id": "string", "body" : { "isCompleted": boolean, "progress": number } }
    return await content.updateContentProgressById(`${this.baseUri}contents/${payload.id}/progress`, payload)
  }

  async likeContent(payload) {
    // { "token": "string", "id": "string" }
    return await content.likeContent(`${this.baseUri}contents/${payload.id}/likes`, payload)
  }

  async dislikeContent(payload) {
    // { "token": "string", "id": "string" }
    return await content.dislikeContent(`${this.baseUri}contents/${payload.id}/likes`, payload)
  }

  async getContentComments(payload) {
    // { "token": "string", "id": "string" }
    return await content.getContentComments(`${this.baseUri}contents/${payload.id}/comments`, payload)
  }

  async postContentComment(payload) {
    // { "token": "string", "id": "string", "body": { "comment" : "string" }}
    return await content.postContentComment(`${this.baseUri}contents/${payload.id}/comments`, payload)
  }

  async getGCSTextContent(url, payload) {
    // { "token": "string" }
    return await content.getGCSTextContent(url, payload)
  }

  async getGCSMediaContent(url, payload) {
    // { "token": "string" }
    return await content.getGCSMediaContent(url, payload)
  }

  async getMyCourses(payload) {
    // { "token": "string" }
    return await content.getMyCourses(`${this.baseUri}contents/my-courses`, payload)
  }

  async getMatchItemsForSkills(payload) {
    // { "token": "string", "body" : { "skills" : ["string"], "contentType" : "string", "includeUserMetaData": "Boolean" }}
    return await content.getMatchItemsForSkills(`${this.baseUri}contents/rec-engine-match-content`, payload)
  }

  async getMatchItemsForSkillsMultipleContentType(payload) {
    // { "token": "string", "body" : { "skills" : ["string"], "contentType" : ["string"], "includeUserMetaData": "Boolean" }}
    return await content.getMatchItemsForSkillsMultipleContentType(`${this.baseUri}contents/rec-engine-match-content/?version=2`, payload)
  }

  async getMatchCoursesForRole(payload) {
    // { "token": "string", "roleGuid" : "string", "type": "string" }}
    return await content.getMatchCoursesForRole(`${this.planUri}jobs/${payload.roleGuid}/suggested-contents/${payload.type}`, payload)
  }

  async searchContentByTitle(payload) {
    // { "token": "string", "title": "string", pageNumber: "string" }
    return await content.searchContentByTitle(`${this.baseUri}contents/search/?q=${payload.title}&pageNo=${payload.pageNumber}&pageSize=10`, payload)
  }

  async searchCourseByTitle(payload) {
    // { "token": "string", "title": "string", pageNumber: "string" }
    return await content.searchCourseByTitle(`${this.baseUri}contents/courses/search/?q=${payload.title}&pageNo=${payload.pageNumber}&pageSize=10`, payload)
  }

  async searchCredentialByTitle(payload) {
    // { "token": "string", "title": "string" }
    return await content.searchCredentialByTitle(`${this.baseUri}credentials/search/?q=${payload.title}`, payload)
  }

  async getUnassignedCourses(payload) {
    // { "token": "string" }
    return await content.getUnassignedCourses(`${this.baseUri}contents/my-unassigned-courses`, payload)
  }

  async selfAssignCourse(payload) {
    // { "token": "string", "id": "string" }
    return await content.selfAssignCourse(`${this.baseUri}contents/courses/${payload.id}/assign`, payload)
  }

  async fetchCourseUrl(payload) {
    // { "token": "string", "id": "string" }
    return await content.fetchCourseUrl(`${this.baseUri}contents/scloud/courselink/${payload.id}`, payload)
  }

  async fetchCredentialUrl(payload) {
    return await content.fetchCredentialUrl(`${this.baseUri}credentials/scloud/credentials/${payload.credentialId}/cId/${payload.childId}`, payload)
  }

  async getMyCredentials(payload) {
    // { "token": "string" }
    return await content.getMyCredentials(`${this.baseUri}contents/my-credentials`, payload)
  }

  async getAllCredentials(payload) {
    // { "token": "string" }
    return await content.getAllCredentials(`${this.baseUri}credentials`, payload)
  }

  async getCredentialById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getCredentialDetailsById(`${this.baseUri}credentials/${payload.id}`, payload)
  }

  async getCredentialSummaryById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getCredentialDetailsById(`${this.baseUri}credentials/${payload.id}/summary`, payload)
  }

  async getSkillBuilderById(payload) {
    // { "token": "string", "id": "string", "sbid": "string" }
    return await content.getCredentialById(`${this.baseUri}credentials/${payload.id}/skill-builders/${payload.sbid}`, payload)
  }

  async getLessonById(payload) {
    // { "token": "string", "id": "string", "sbid": "string", "lid": "string" }
    return await content.getLessonById(`${this.baseUri}credentials/${payload.id}/skill-builders/${payload.sbid}/lessons/${payload.lid}`, payload)
  }

  async uploadLessonKnowlegeCheck(payload) {
    // { "token": "string", "id": "string", "sbid": "string", "lid": "string", "upr": { "qid": string, "answer": string } }
    return await content.uploadLessonKnowlegeCheck(`${this.baseUri}credentials/${payload.id}/skill-builders/${payload.sbid}/lessons/${payload.lid}/kcs`, payload)
  }

  async uploadLessonProgressById(payload) {
    // { "token": "string", "id": "string", "sbid": "string", "lid": "string", "upr": { "isCompleted": Boolean, "progress": Number } }
    return await content.uploadLessonProgressById(`${this.baseUri}credentials/${payload.id}/skill-builders/${payload.sbid}/lessons/${payload.lid}/progress`, payload)
  }

  async getCredentialContent(payload) {
    // { "token": "string", "id": "string" }
    return await content.getCredentialContent(`${this.baseUri}credentials/lesson-contents/${payload.id}`, payload)
  }

  async getCapstoneProjectForCredential(payload) {
    // { "token": "string", "id": "string" }
    return await content.getCapstoneProjectForCredential(`${this.baseUri}credentials/${payload.id}/capstone`, payload)
  }

  async getCredentialAssessments(payload) {
    // { "token": "string", "id" : "string" }
    return await content.getCredentialAssessments(`${this.baseUri}contents/${payload.id}/assessments`, payload)
  }

  async getCredentialAssessmentById(payload) {
    // { "token": "string", "id" : "string" }
    return await content.getCredentialAssessmentById(`${this.baseUri}assessments/${payload.id}`, payload)
  }

  async postCredentialAssessmentAnswers(payload) {
    // { "token": "string", "id" : "string", "body": { userAnswers: [ { "qid": "string", "answer": "string" } ] }}
    return await content.postCredentialAssessmentAnswers(`${this.baseUri}assessments/${payload.id}`, payload)
  }

  async getCapstoneProjectById(payload) {
    // { "token": "string", "id": "string"}
    return await content.getCapstoneProjectById(`${this.baseUri}capstones/${payload.id}`, payload)
  }

  async saveCapstoneProject(payload) {
    // { "token": "string", "myCapstone": { "name": "string", "shortDescription" : "string", "credentialId" : "string"}}
    return await content.saveCapstoneProject(`${this.baseUri}capstones`, payload)
  }

  async editCapstoneProject(payload) {
    // { "token": "string", "id": "string", "myCapstone": { "name": "string", "shortDescription" : "string", "credentialId" : "string"}}
    return await content.editCapstoneProject(`${this.baseUri}capstones/${payload.id}`, payload)
  }
  
  async uploadFileToCapstoneProject(payload, controller, onUploadProgress) {
    // { "token": "string", "id": "string", "file": Object }
    return await content.uploadFileToCapstoneProject(`${this.baseUri}capstones/${payload.id}/attach`, payload, controller, onUploadProgress)
  }

  async submitCapstoneProject(payload) {
    // { "token": "string", "id": "string" }
    return await content.submitCapstoneProject(`${this.baseUri}capstones/${payload.id}/submit`, payload)
  }

  async getFileForCapstoneProject(payload) {
    // { "token": "string", "id": "string" }
    return await content.getFileForCapstoneProject(`${this.baseUri}capstones/${payload.id}/download`, payload)
  }

  async likeCredential(payload) {
    // { "token": "string", "id" : "string" }
    return await content.likeCredential(`${this.baseUri}credentials/${payload.id}/likes`, payload)
  }

  async dislikeCredential(payload) {
    // { "token": "string", "id" : "string" }
    return await content.dislikeCredential(`${this.baseUri}credentials/${payload.id}/likes`, payload)
  }

  async getCredentialComments(payload) {
    // { "token": "string", "id" : "string" }
    return await content.getCredentialComments(`${this.baseUri}credentials/${payload.id}/comments`, payload)
  }

  async postCredentialComment(payload) {
    // { "token": "string", "id" : "string", "body" : Object }
    return await content.postCredentialComment(`${this.baseUri}credentials/${payload.id}/comments`, payload)
  }

  async getWorkspaceAvailability(payload) {
    // { "token": "string", "id" : "string", "body" : Object }
    const { userId } = payload
    return await content.getWorkspaceAvailability(`https://${this.vdiUri}workspaces/availability/user/${userId}`, payload)
  }

  async getMyLearningPlanItems(payload) {
    // Endpoint is deprecated. Only use getMyLearningPlanItemsWithFilters moving forward
    return await content.getMyLearningPlanItems(
      `${this.baseUri}contents/learning-plan-items?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&includeCompleted=${payload.includeCompleted}&includeCourses=${payload.includeCourses}&includeCredentials=${payload.includeCredentials}&includeRwl=${payload.includeRwl}&includeNoProgress=${payload.includeNoProgress}&includeInProgress=${payload.includeInProgress}&includeSelfLearned=${payload.includeSelfLearned}`,
      payload
    )
  }

  async getMyLearningPlanItemsWithFilters(payload) {
    return await content.getMyLearningPlanItemsWithFilters(`${this.baseUri}contents/learning-plan-items`, payload)
  }

  async getMyLearningPlanFilters(payload) {
    return await content.getMyLearningPlanFilters(`${this.baseUri}contents/learning-plan-items-filters`, payload)
  }

  async getExternalLearningPlanFilters(payload) {
    return await content.getExternalLearningPlanFilters(`${this.baseUri}contents/learning-plan-items-filters`, payload)
  }

  async getSelfProgressProviderIDs(payload) {
    return await content.getSelfProgressProviderIDs(`${this.baseUri}providers/selfProgressDeclarationIds`, payload)
  }

  async getExternalContentItems(payload) {
    return await content.getExternalContentItems(`${this.baseUri}contents/learning-plan-items`, payload)
  }

  async getCSVDetails(payload) {
    return await content.getCSVDetails(`${this.baseUri}reports/learner/all-time`, payload)
  }

  async getMyLearningPlanRequiredItems(payload) {
    return await content.getMyLearningPlanRequiredItems(`${this.baseUri}learning-plan/required-content`, payload)
  }

  /*
   * Role APIs
   */
  async getExternalRoleById(payload) {
    // { "token": "string", "id": "string" }
    return await role.getExternalRoleById(`${this.baseUri}jobs/external/${payload.id}`, payload)
  }

  /*
   * Skill APIs
   */
  async getSkills(payload) {
    // { "token": "string" }
    return await skill.getSkills(`${this.baseUri}skills`, payload)
  }

  async getSkillsSuggested(payload) {
    // { "token": "string" }
    return await skill.getSkillsSuggested(`${this.baseUri}skills/suggested`, payload)
  }

  async getSkillById(payload) {
    // { "token": "string", "id": "string" }
    return await skill.getSkillById(`${this.baseUri}skills/${payload.id}`, payload)
  }

  async getSkillByTitle(payload) {
    // { "token": "string", "title": "string" }
    return await skill.getSkillByTitle(`${this.baseUri}skills/search/?q=${payload.title}`, payload)
  }

  async likeSkill(payload) {
    // { "token": "string", "id" : "string" }
    return await skill.likeSkill(`${this.baseUri}skills/${payload.id}/likes`, payload)
  }

  async dislikeSkill(payload) {
    // { "token": "string", "id" : "string" }
    return await skill.dislikeSkill(`${this.baseUri}skills/${payload.id}/likes`, payload)
  }

  async getSkillComments(payload) {
    // { "token": "string", "id" : "string" }
    return await skill.getSkillComments(`${this.baseUri}skills/${payload.id}/comments`, payload)
  }

  async postSkillComment(payload) {
    // { "token": "string", "id" : "string", "body" : Object }
    return await skill.postSkillComment(`${this.baseUri}skills/${payload.id}/comments`, payload)
  }

  /*
   * Achievement APIs
   */
  async getUserAchievements(payload) {
    return await achievements.getUserAchievements(`${this.baseUri}users/${payload.guid}/activity-summary`, payload)
  }

  /*
   * Group APIs
   */
  async getJoinedGroups(payload) {
    // { "token": "string" }
    return await group.getJoinedGroups(`${this.baseUri}groups`, payload)
  }

  /*
   * Search APIs
   */
  async searchAll(payload) {
    // { "token": "string", "query": "string", "pageNo" : "Number", "type": "course/credential/rwl", "filters": {} }
    return await search.searchAll(
      `${this.searchUri}search/all?q=${payload.query}&pageno=${payload.pageNo}&sortby=${payload.sortBy}&filter_values_session_id=${payload.filterValuesSessionId}`,
      payload
    )
  }

  async searchAutoComplete(payload) {
    // { "token": "string", "query": "string" }
    return await search.searchAutoComplete(`${this.searchUri}search/autocomplete?q=${payload.query}`, payload)
  }

  async getLookingForFilters(payload) {
    // { "token": "string", "query": "string" }
    return await search.getLookingForFilters(`${this.searchUri}search/typelist`, payload)
  }

  async getFilterMap(payload) {
    // { "token": "string", "query": "string", "pageNo": "number", "type": "string", "sessionId": "string"}
    return await search.getFilterMap(
      `${this.searchUri}search/filtervalues?q=${payload.query}&pageno=${payload.pageNo}&type=${payload.type}&filterValuesSessionId=${payload.sessionId}`,
      payload
    )
  }

  async getDurationFilter(payload) {
    // { "token": "string" }
    return await search.getDurationFilter(`${this.searchUri}search/duration/filtervalueswithid`, payload)
  }

  /*
   *  Apply APIs
   */
  async addBotBookmark(payload) {
    // { "token": "string", body: {"assetId": "string", "assetType": "automation || idea" } }
    return await share.addBotBookmark(`${this.shareUri}users/me/bookmarks`, payload)
  }

  async removeBotBookmark(payload) {
    // { "token": "string", "assetId": "string" }
    return await share.removeBotBookmark(`${this.shareUri}users/me/bookmarks/${payload.assetId}`, payload)
  }

  async getAssetById(payload) {
    // { "token": "string", "id": "string" }
    return await share.getAssetById(`${this.applyUri}automations/${payload.id}`, payload)
  }

  async getAssetByMatchSkills(payload) {
    // { "token": "string", "body" : {"skills": []} }
    return await share.getAssetByMatchSkills(`${this.applyUri}automations/assets/match-skills`, payload)
  }

  async getAssetByApplications(payload) {
    // { "token": "string" }
    return await share.getAssetByApplications(`${this.applyUri}automations/assets/my-applications`, payload)
  }

  async getAssetByTypes(payload) {
    // { "token": "string" }
    return await share.getAssetByTypes(`${this.applyUri}automations/assets/my-types`, payload)
  }

  async downloadAsset(payload) {
    // { "token": "string", "id": "string" }
    return await share.downloadAsset(`${this.applyUri}automations/${payload.id}/download-asset`, payload)
  }

  async getAssetProviderByGuid(payload) {
    // { "token": "string", "guid": "string" }
    return await share.getAssetProviderByGuid(`${this.applyUri}generic/providers/${payload.guid}`, payload)
  }

  async getAssetList(payload) {
    // { "token": "string" }
    return await share.getAssetList(`${this.applyUri}governance/items?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`, payload)
  }

  async downloadAssetItem(payload) {
    // { "token": "string", "id": "string" }
    return await share.downloadAssetItem(`${this.applyUri}governance/items/${payload.id}`, payload)
  }

  async getAssetItemById(payload) {
    // { "token": "string", "id": "string" }
    return await share.getAssetItemById(`${this.applyUri}governance/items/${payload.id}`, payload)
  }

  async submitAsset(payload) {
    // { "token": "string" }
    return await share.submitAsset(`${this.applyUri}governance/items`, payload)
  }

  async deleteImageFromCreateAsset(payload) {
    return await share.deleteImageFromCreateAsset(`${this.applyUri}governance/items/${payload.assetId}/images/${payload.imagePointer}`, payload)
  }

  async deletePdfFromCreateAsset(payload) {
    return await share.deletePdfFromCreateAsset(`${this.applyUri}governance/items/${payload.assetId}/fileRefs/${payload.pdfPointer}`, payload)
  }

  async getGovernanceTools(payload) {
    // { "token": "string" }
    return await share.getGovernanceTools(`${this.applyUri}generic/tools`, payload)
  }

  async getAssetInventoryTypes(payload) {
    // { "token": "string" }
    return await share.getAssetInventoryTypes(`${this.applyUri}generic/inventory-types`, payload)
  }
  async deleteAssetItem(payload) {
    return await share.deleteAssetItem(`${this.applyUri}governance/items/${payload.id}`, payload)
  }

  async getUserAccessAssets(payload) {
    // { "token": "string" }
    return await share.getUserAccessAssets(`${this.applyUri}users/me`, payload)
  }

  async uploadAssets(payload, controller, onUploadProgress) {
    return await share.uploadAssets(`${this.applyUri}governance/items/uploadAsset`, payload, controller, onUploadProgress)
  }

  async deleteAssetFile(payload) {
    return await share.deleteAssetFile(`${this.applyUri}governance/items/${payload.id}/assetFile`, payload)
  }

  async downloadAssetZipFile(payload) {
    return await share.downloadAssetZipFile(`${this.applyUri}governance/items/${payload.id}/download-assetFile`, payload)
  }

  async getGovernanceFunctions(payload) {
    // { "token": "string" }
    return await share.getAssetFunctions(`${this.applyUri}generic/job-functions`, payload)
  }

  async getShareContentById(payload) {
    // { "token": "string", "id": "string" }
    return await content.getContentById(`${this.baseUri}share/${payload.id}`, payload)
  }

  async getExternalContentById(payload) {
    return await content.getContentDetailsById(`${this.baseUri}external-contents/external-learning-plan-items/${payload.guid}`, payload)
  }

  async getExternalContentFormats(payload) {
    return await content.getExternalContentFormats(`${this.baseUri}external-contents/formats`, payload)
  }

  async addExternalContent(payload) {
    return await content.addExternalContent(`${this.baseUri}external-contents/external-learning-content`, payload)
  }

  async updateExternalContentProgress(payload) {
    return await content.updateExternalContentProgress(`${this.baseUri}external-contents/${payload.guid}/progress`, payload)
  }

  async updateExternalContent(payload) {
    return await content.updateExternalContent(`${this.baseUri}external-contents/external-learning-content/${payload.guid}`, payload)
  }

  async deleteExternalContent(payload) {
    return await content.deleteExternalContent(`${this.baseUri}external-contents/external-learning-plan-items/${payload.guid}`, payload)
  }

  async downloadHowToGuide(payload) {
    // { "token": "string", "id": "string" }
    return await share.downloadHowToGuide(`${this.applyUri}automations/${payload.id}/how-to-guide`, payload)
  }

  async createTrack(payload) {
    return await tracks.createTracks(`${this.trackUri}tracks/learner`, payload)
  }

  async editTrack(payload) {
    return await tracks.editTracks(`${this.trackUri}tracks/learner/${payload.id}`, payload)
  }

  async getAllTracks(payload) {
    return await tracks.getAllTracks(`${this.trackUri}tracks/author/${payload.authorGuid}`, payload)
  }

  async getUGTrackById(payload) {
    // { "token": "string", "id": "string" }
    return await tracks.getUGTrackById(`${this.trackUri}tracks/${payload.id}`, payload)
  }

  async deleteTrack(payload) {
    // { "token": "string", "id": "string" }
    return await tracks.deleteTrack(`${this.trackUri}tracks/learner/${payload.id}`, payload)
  }

  async addItemsToTrack(payload) {
    return await tracks.addItemsToTrack(`${this.trackUri}tracks/${payload.id}`, payload)
  }

  async saveHomeGoalSetting(payload) {
    return await goals.saveHomeGoalSetting(`${this.baseUri}users/user-goals`, payload)
  }

  async sendUserActivity(payload) {
    return await goals.sendUserActivity(`${this.baseUri}users/user-activity-count`, payload)
  }

  async getUserActivity(payload) {
    return await goals.getUserActivity(`${this.baseUri}users/user-activity-data`, payload)
  }
  //rating and comments
  async getRatingById(payload) {
    return await rating.getRatingById(`${this.SocialUri}entity/${payload.id}/ratings`, payload)
  }

  async getComments(payload) {
    return await rating.getComments(`${this.SocialUri}entity/${payload.id}/comment`, payload)
  }

  async rateContentById(payload) {
    // { "token": "string", "id": "string" , body: "object"}
    return await rating.rateContentById(`${this.SocialUri}entity/${payload.id}/ratings`, payload)
  }

  async editRateContentById(payload) {
    // { "token": "string", "ratingId": "string" , body: "object"}
    return await rating.editRateContentById(`${this.SocialUri}ratings/${payload.ratingId}`, payload)
  }

  async flagCommentById(payload) {
    return await rating.flagCommentById(`${this.SocialUri}ratings/${payload.ratingId}/flag`, payload)
  }

  async getContentRatingById(payload) {
    return await rating.getContentRatingById(`${this.SocialUri}entity/ratingsList`, payload)
  }

  async getShortDurationSkill(payload) {
    return await content.getRecommendedContent(`${this.baseUri}contents/rec-engine-match-short-content`, payload)
  }
  async getRecommendationActivity(payload) {
    return await content.getRecommendedContent(`${this.baseUri}contents/rec-engine-match-activity-based-content`, payload)
  }

  async getDashboardMetrics(payload) {
    return await user.getDashboardMetrics(`${this.baseUri}users/learning-dashboard`, payload)
  }

}
