<template>
  <ion-grid class="goal-setting-container">
    <ion-row class="ion-no-padding goal-setting-head">
      <ion-col class="ion-no-padding ion-text-start banner-info" size-lg="6" size-md="6" size-sm="6" size-xs="6">
        <h3>Your {{ currentMonth }} activity</h3>
      </ion-col>
      <ion-col class="ion-text-end" size-lg="6" size-md="6" size-sm="6" size-xs="6">
        <ion-button shape="round" v-if="!editMode" color="primary" class="goal-setting-btn" @click="onSetGoal()">Set a goal</ion-button>
        <button class="goal-setting-edit-btn" v-else href="#" @click="onSetGoal()">Edit goal</button>
      </ion-col>
    </ion-row>
    <ion-row class="goal-setting-info">
      <ion-col size-lg="3" size-md="3" size-sm="3" size-xs="12" class="goal-setting-chart" v-if="goalSettingData && goalSettingData.activeDaysCount">
        <apexchart type="radialBar" height="160" :options="getChartOptions()" :series="getSeriesData()"></apexchart>
        <h5>Days active</h5>
      </ion-col>
      <ion-col class="goal-setting-text-content" size-lg="8" size-md="8" size-sm="8" size-xs="11">
        <h5>Days active</h5>
        <p v-if="editMode" v-html="getGoalDescription()"></p>
        <p v-else>
          Goal setting is the first step in achieving success! <a class="goal-setting-link" @click="onSetGoal()">Set your monthly goal</a> today, and we’ll keep you updated on your
          progress.
        </p>
      </ion-col>
      <ion-col size-lg="1" size-md="1" size-sm="1" size-xs="1">
        <span class="typeahead-tooltip">
          <img src="@/assets/images/svgs/icon-info.svg" class="info-icon" alt="Info" />
          <span class="typeahead-tooltiptext">{{ activeDaysTooltipText }}</span>
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
  
<script>
import { mapGetters } from 'vuex'
import apexchart from 'vue-apexcharts'

export default {
  name: 'HomeGoalSetting',
  components: { apexchart },
  props: {
    goalSettingData: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  data() {
    return {
      authToken: '',
      currentMonth: '',
      showGoalSettingModal: false,
      editMode: false,
      goalPercentage: 0,
      activeDaysTooltipText: 'Any interaction as a logged in user within ProEdge between 12am and 11:59pm EST counts as activity for that day.'
    }
  },
  async mounted() {
    this.currentMonth = new Date().toLocaleString('default', { month: 'long' })
    this.updateGoalSettings();
  },
  methods: {
    updateGoalSettings() {
      this.editMode = {}.hasOwnProperty.call(this.goalSettingData, 'goalDays') && this.goalSettingData.goalDays > 0
      this.goalPercentage = Math.round((this.goalSettingData.activeDaysCount / this.goalSettingData.goalDays) * 100)
    },    
    getChartOptions() {
      return {
        chart: {
          height: 160,
          offsetX: 0,
          offsetY: 0,
          type: 'radialBar'
        },
        colors: ['#00D5AF'],
        stroke: {
          lineCap: {}.hasOwnProperty.call(this.goalSettingData, 'goalDays') && this.goalSettingData.goalDays > 0 ? 'round' : 'butt'
        },
        labels: this.editMode ? [`${this.goalSettingData.activeDaysCount}/${this.goalSettingData.goalDays}`] : [this.goalSettingData.activeDaysCount],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '40%'
            },
            track: {
              background: '#D9F8F3'
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: '18px',
                fontFamily: 'Futura PT Demi',
                color: '#000000',
                offsetY: 5
              },
              value: {
                show: false
              }
            }
          }
        }
      }
    },
    getSeriesData() {
      return this.editMode ? [this.goalPercentage] : [0]
    },
    getGoalDescription() {
      if (this.editMode && this.goalPercentage >= 0 && this.goalPercentage < 50) return 'Setting goals is half the journey. You’re on the road to success — keep going!'
      else if (this.editMode && this.goalPercentage >= 50 && this.goalPercentage < 100) return 'Your journey is just as important as achieving your goals. Keep learning!'
      else if (this.editMode && this.goalPercentage >= 100) return 'Way to go! You met your goal this month. Keep up the momentum, and update your goal if needed.'
    },
    onSetGoal() {
      this.$emit('openGoalSettingModal')
    },
    onCloseModal() {
      this.showGoalSettingModal = false
    }
  }
}
</script>
  
<style lang="scss" scoped>
.typeahead-tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  bottom: 2.5rem;
  left: 1rem;
  img {
    height: 1.125rem;
    width: 1.125rem;
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    left: 0;
  }
  @media (max-width: 768px) {
    left: 1rem;
  }
  @media (max-width: 500px) {
    bottom: 9.5rem;
    left: -0.5rem;
  }
}

.typeahead-tooltip .typeahead-tooltiptext {
  text-align: center;
  font: normal normal normal 0.75rem/1rem Arial;
  color: #000000de;
  width: 16.188rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000001f;
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  opacity: 0;
  top: 1.5rem;
  padding: 8px 12px;
  left: -8rem;
  @media (max-width: 1024px) {
    left: -9rem;
  }
  @media (max-width: 768px) {
    left: -15rem;
  }

}
.typeahead-tooltip:hover .typeahead-tooltiptext {
  visibility: visible;
  opacity: 1;
}
.goal-setting-container {
  .goal-setting-head {
    margin-bottom: 1.25rem;

    h3 {
      font-size: 1.5rem;
      font-family: 'Futura PT Demi';
      color: var(--ion-color-black);
      margin: 0;
      font-weight: 500;
    }

    .goal-setting-btn {
      text-transform: none;
      font-family: 'Futura PT Demi';
      --ion-color-primary: #feb92e;
      --ion-color-primary-contrast: black;
      --box-shadow: none;
      font-size: 1rem;
    }

    .goal-setting-edit-btn {
      font-size: 1rem;
      font-family: 'Futura PT Demi';
      letter-spacing: 0.02px;
      color: #1f3370;
      background: none;
    }
  }
  .goal-setting-info {
    border: 1px solid #e0e1e1;
    border-radius: 1.25rem;
    font-family: Arial;
    padding: 0;
    text-align: left;
    align-items: center;
    .goal-setting-chart {
      display: flex;
      align-items: center;
      h5 {
        font-size: 1rem;
        font-family: Arial;
        color: var(--ion-color-black);
        margin-top: 0;
        font-weight: 500;
        display: none;
        @media (max-width: 500px) {
          display: block;
          min-width: 12.5rem;
        }
      }
    }
    .goal-setting-text-content {
      padding-right: 15px;
      @media (max-width: 500px) {
        padding: 0 0 1.5rem 1.5rem;
      }
      h5 {
        font-size: 1rem;
        font-family: Arial;
        color: var(--ion-color-black);
        margin-top: 0;
        font-weight: bold;
        @media (max-width: 500px) {
          display: none;
        }
      }

      p {
        font-size: 1rem;
        margin: 0;
        font-family: Arial;
        color: #5c5c5c;
      }
      
      .goal-setting-link {
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
        font-family: Arial;
        color: #1f3370;
      }
    }
  }
}
</style>