<template>
  <div v-if="dataReady && courses.length">
    <div class="header">Other courses found in "{{ trackTitle }}"</div>
    <CarouselDesktop v-if="isDesktopPlatform" style="margin: 32px 0 40px" :style="{ '--bottom': '-30px' }" :slidesShow="2" :responsive="false">
      <!-- <div v-for="course in courses" :key="course.id" class="course-item-desktop" @click="onClick(course)">
        <div class="progress">
          <div v-if="course.progress > 0 && course.progress < 100"><svg-icon icon-class="progress-icon" /><span class="progress-text progress-text-desktop">in progress</span></div>
          <div v-if="course.progress == 100"><svg-icon icon-class="icon-progress-complete" /><span class="progress-text progress-text-desktop">completed</span></div>
        </div>
        <div class="meta">
          <span class="format course">{{ course.format.name }}</span>
          <span class="provider">{{ course.provider && course.provider.name }}</span>
        </div>
        <div class="title ellipsis-lines-3">{{ course.title }}</div>
      </div> -->
      <UniversalCard v-for="(item, index) in courses" :key="item.id" :item="item" :index="index" :itemsLength="courses.length" cardType="carousal" :tab="tabName" />
    </CarouselDesktop>

    <CarouselMobile v-else style="margin: 16px 0px">
      <!-- <div v-for="course in courses" :key="course.id" class="course-item-mobile" @click="onClick(course)">
        <div class="progress">
          <div v-if="course.progress > 0 && course.progress < 100"><svg-icon icon-class="progress-icon" /><span class="progress-text progress-text-mobile">in progress</span></div>
          <div v-if="course.progress == 100"><svg-icon icon-class="icon-progress-complete" /><span class="progress-text progress-text-mobile">completed</span></div>
        </div>
        <div class="meta">
          <span class="format course">{{ course.format.name }}</span>
          <span class="provider">{{ course.provider && course.provider.name }}</span>
        </div>
        <div class="title ellipsis-lines-3">{{ course.title }}</div>
      </div> -->
      <UniversalCard v-for="(item, index) in courses" :key="item.id" :item="item" :index="index" :itemsLength="courses.length" cardType="carousal" :tab="tabName" />
    </CarouselMobile>
  </div>
</template>

<script>
import CarouselMobile from '@/components/molecules/CarouselMobile'
import CarouselDesktop from '@/components/organisms/CarouselDesktop'
import UniversalCard from '@/components/organisms/UniversalCard'
import moment from 'moment-timezone'

export default {
  name: 'CourseRelatedCourses',
  components: { CarouselDesktop, CarouselMobile, UniversalCard },
  props: {
    course: {
      required: true,
      type: Array,
      default: () => {
        return []
      }
    },
    requiredLearningPlanItems: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: true,
      trackTitle: '',
      trackType: '',
      courses: [],
      dataReady: false,
      tabName: 'relatedCourse'
    }
  },
  computed: {},
  watch: {},
  async created() {
    this.dataReady = false
    const lpItems = this.course.length ? this.course[0].learningPlanItems : []
    this.trackTitle = this.course.length ? this.course[0].track.name : ''
    this.trackType = !this.course.length
      ? ''
      : !{}.hasOwnProperty.call(this.course[0].track, 'type')
      ? 'collection'
      : this.course[0].track.type
      ? this.course[0].track.type
      : 'collection'
    this.courses = await lpItems.filter((e) => e.format.name == 'course' && this.$route.params.id != e.artifactId)
    this.courses.map(async (item) => {
      item.id = item.artifactId
      item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : ''
      if (this.requiredLearningPlanItems[item.id]) await this.getDueDate(item, this.requiredLearningPlanItems[item.id])
    })
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.dataReady = true
  },
  filters: {},
  methods: {
    onClick(item) {
      let route = {}
      if (['article', 'video', 'audio', 'podcast'].includes(item.format.name.toLowerCase())) {
        route = { name: 'Content', params: { id: item.artifactId, title: item.title } }
      } else if (['course'].includes(item.format.name.toLowerCase())) {
        route = { name: 'Course', params: { id: item.artifactId, pgrTrk: false } }
      } else {
        route = { name: 'Credential', params: { id: item.artifactId, pgrTrk: false } }
      }
      this.$router.push(route).catch(() => {})
    },
    async getDueDate(item, dueDate) {
      const formattedToday = moment(dueDate).tz('America/New_York').format('DD MMM YYYY')
      if (item.timeToProgressSyncInHours > 0) {
        moment(dueDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
          ? (item.dueDate = formattedToday)
          : (item.pastDueDate = formattedToday)
      } else {
        moment(dueDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-family: 'Futura PT Demi';
  font-size: 24px;
  color: black;
  text-align: left;
  border-bottom: 2px solid #e0e1e1;
  padding: 10px 0px 20px 0px;
}
</style>
