var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CredentialContent", {
    attrs: { content: _vm.bookmark, "from-bookmark-list": "" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }