var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.$platform === "Desktop" ? "container-desktop" : "container-mobile"
    },
    [
      _vm.selectedTabIndex == 0
        ? _c(
            "div",
            [
              _c(
                "ion-grid",
                [
                  _vm.isDesktopPlatform
                    ? _c(
                        "div",
                        [
                          _vm._l(
                            _vm.data.required.items.slice(
                              _vm.data.required.start,
                              _vm.data.required.end
                            ),
                            function(item, index) {
                              return _c("UniversalCard", {
                                key: index + item.id,
                                attrs: {
                                  item: item,
                                  index: index,
                                  itemsLength: _vm.data.required.items.slice(
                                    _vm.data.required.start,
                                    _vm.data.required.end
                                  ).length,
                                  tab: _vm.tabName
                                },
                                on: { "learn-item-click": _vm.onLearnItemClick }
                              })
                            }
                          ),
                          _vm.data.required.items.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "border-top": "2px solid #e0e1e1"
                                  }
                                },
                                [
                                  _c("paginate", {
                                    attrs: {
                                      "page-count": _vm.data.required.pageCount,
                                      "page-range": _vm.pageRangeRequired,
                                      "margin-pages": 1,
                                      "click-handler":
                                        _vm.handleRequiredPageClick,
                                      "container-class": "pagination",
                                      "page-class": "pagination-item",
                                      "page-link-class": "pagination-item-link",
                                      "prev-text": "&lsaquo;",
                                      "prev-class": "pagination-nav",
                                      "prev-link-class": "pagination-nav-link",
                                      "next-text": "&rsaquo;",
                                      "next-class": "pagination-nav",
                                      "next-link-class": "pagination-nav-link",
                                      "hide-prev-next": true
                                    },
                                    model: {
                                      value: _vm.data.required.pageCurrent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.required,
                                          "pageCurrent",
                                          $$v
                                        )
                                      },
                                      expression: "data.required.pageCurrent"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.isDesktopPlatform && _vm.data.required.items.length === 0
                    ? _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            { attrs: { "size-xl": "5", size: "6" } },
                            [
                              _c("div", { staticClass: "title title-black" }, [
                                _vm._v("No due dates yet")
                              ]),
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v(
                                  " Any learning required by your company will appear here in the future. For now, check out your "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "link-btn-recommended g-clickable",
                                    on: { click: _vm.goToRecommended }
                                  },
                                  [_vm._v("Recommended")]
                                ),
                                _vm._v(" list for other options. ")
                              ])
                            ]
                          ),
                          _c(
                            "ion-col",
                            { attrs: { "size-xl": "6", size: "6" } },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/my-learning-required.png")
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "ion-row",
                        [
                          !_vm.isDesktopPlatform
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.data.required.items.slice(
                                      _vm.data.required.start,
                                      _vm.data.required.end
                                    ),
                                    function(item, index) {
                                      return _c("UniversalCard", {
                                        key: index + item.id,
                                        attrs: {
                                          item: item,
                                          index: index,
                                          itemsLength: _vm.data.required.items.slice(
                                            _vm.data.required.start,
                                            _vm.data.required.end
                                          ).length,
                                          tab: _vm.tabName,
                                          "current-tab": "Required"
                                        },
                                        on: {
                                          "learn-item-click":
                                            _vm.onLearnItemClick
                                        }
                                      })
                                    }
                                  ),
                                  _vm.data.required.items.length
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "border-top": "2px solid #e0e1e1"
                                          }
                                        },
                                        [
                                          _c("paginate", {
                                            attrs: {
                                              "page-count":
                                                _vm.data.required.pageCount,
                                              "page-range":
                                                _vm.pageRangeRequired,
                                              "margin-pages": 1,
                                              "click-handler":
                                                _vm.handleRequiredPageClick,
                                              "container-class": "pagination",
                                              "page-class": "pagination-item",
                                              "page-link-class":
                                                "pagination-item-link",
                                              "prev-text": "&lsaquo;",
                                              "prev-class": "pagination-nav",
                                              "prev-link-class":
                                                "pagination-nav-link",
                                              "next-text": "&rsaquo;",
                                              "next-class": "pagination-nav",
                                              "next-link-class":
                                                "pagination-nav-link",
                                              "hide-prev-next": true
                                            },
                                            model: {
                                              value:
                                                _vm.data.required.pageCurrent,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.required,
                                                  "pageCurrent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.required.pageCurrent"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.data.required.items.length === 0
                            ? _c("ion-col", { attrs: { size: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "title title-black" },
                                  [_vm._v("No due dates yet")]
                                ),
                                _c("div", { staticClass: "sub-title" }, [
                                  _vm._v(
                                    " Any learning required by your company will appear here in the future. For now, check out your "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "link-btn-recommended g-clickable",
                                      on: { click: _vm.goToRecommended }
                                    },
                                    [_vm._v("Recommended")]
                                  ),
                                  _vm._v(" list for other options. ")
                                ])
                              ])
                            : _vm._e(),
                          _vm.data.required.items.length === 0
                            ? _c("ion-col", { attrs: { size: "12" } }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/my-learning-required.png")
                                  }
                                })
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedTabIndex == 1
        ? _c(
            "div",
            [
              _c(
                "ion-grid",
                [
                  _vm.isDesktopPlatform
                    ? _c(
                        "div",
                        [
                          _vm._l(
                            _vm.data.learning.items.slice(
                              _vm.data.learning.start,
                              _vm.data.learning.end
                            ),
                            function(item, index) {
                              return _c("UniversalCard", {
                                key: index + item.id,
                                attrs: {
                                  item: item,
                                  index: index,
                                  itemsLength: _vm.data.learning.items.slice(
                                    _vm.data.learning.start,
                                    _vm.data.learning.end
                                  ).length,
                                  tab: _vm.tabName
                                },
                                on: { "learn-item-click": _vm.onLearnItemClick }
                              })
                            }
                          ),
                          _vm.data.learning.items.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "border-top": "2px solid #e0e1e1"
                                  }
                                },
                                [
                                  _c("paginate", {
                                    attrs: {
                                      "page-count": _vm.data.learning.pageCount,
                                      "page-range": _vm.pageRangeLearning,
                                      "margin-pages": 1,
                                      "click-handler":
                                        _vm.handleLearningPlanPageClick,
                                      "container-class": "pagination",
                                      "page-class": "pagination-item",
                                      "page-link-class": "pagination-item-link",
                                      "prev-text": "&lsaquo;",
                                      "prev-class": "pagination-nav",
                                      "prev-link-class": "pagination-nav-link",
                                      "next-text": "&rsaquo;",
                                      "next-class": "pagination-nav",
                                      "next-link-class": "pagination-nav-link",
                                      "hide-prev-next": true
                                    },
                                    model: {
                                      value: _vm.data.learning.pageCurrent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.learning,
                                          "pageCurrent",
                                          $$v
                                        )
                                      },
                                      expression: "data.learning.pageCurrent"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.isDesktopPlatform && _vm.data.learning.items.length === 0
                    ? _c(
                        "ion-row",
                        [
                          _c("ion-col", { attrs: { size: "6" } }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("No recommended items yet")
                            ]),
                            _c("div", { staticClass: "sub-title" }, [
                              _vm._v("Browse our "),
                              _c(
                                "span",
                                {
                                  staticClass: "link-btn g-clickable",
                                  on: { click: _vm.goToExplore }
                                },
                                [_vm._v("Explore section")]
                              ),
                              _vm._v(
                                " to discover content based on your interests."
                              )
                            ])
                          ]),
                          _c("ion-col", { attrs: { size: "6" } }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/my-learning-completed.png")
                              }
                            })
                          ])
                        ],
                        1
                      )
                    : _c(
                        "ion-row",
                        { staticClass: "ion-align-items-center" },
                        [
                          !_vm.isDesktopPlatform
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.data.learning.items.slice(
                                      _vm.data.learning.start,
                                      _vm.data.learning.end
                                    ),
                                    function(item, index) {
                                      return _c("UniversalCard", {
                                        key: index + item.id,
                                        attrs: {
                                          item: item,
                                          index: index,
                                          itemsLength: _vm.data.learning.items.slice(
                                            _vm.data.learning.start,
                                            _vm.data.learning.end
                                          ).length,
                                          tab: _vm.tabName,
                                          "current-tab": "Learning plan"
                                        },
                                        on: {
                                          "learn-item-click":
                                            _vm.onLearnItemClick
                                        }
                                      })
                                    }
                                  ),
                                  _vm.data.learning.items.length
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "border-top": "2px solid #e0e1e1"
                                          }
                                        },
                                        [
                                          _c("paginate", {
                                            attrs: {
                                              "page-count":
                                                _vm.data.learning.pageCount,
                                              "page-range":
                                                _vm.pageRangeLearning,
                                              "margin-pages": 1,
                                              "click-handler":
                                                _vm.handleLearningPlanPageClick,
                                              "container-class": "pagination",
                                              "page-class": "pagination-item",
                                              "page-link-class":
                                                "pagination-item-link",
                                              "prev-text": "&lsaquo;",
                                              "prev-class": "pagination-nav",
                                              "prev-link-class":
                                                "pagination-nav-link",
                                              "next-text": "&rsaquo;",
                                              "next-class": "pagination-nav",
                                              "next-link-class":
                                                "pagination-nav-link",
                                              "hide-prev-next": true
                                            },
                                            model: {
                                              value:
                                                _vm.data.learning.pageCurrent,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.learning,
                                                  "pageCurrent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.learning.pageCurrent"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.data.learning.items.length === 0
                            ? _c("ion-col", { attrs: { size: "10" } }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("No recommended items yet")
                                ]),
                                _c("div", { staticClass: "sub-title" }, [
                                  _vm._v("Browse our "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "link-btn g-clickable",
                                      on: { click: _vm.goToExplore }
                                    },
                                    [_vm._v("Explore section")]
                                  ),
                                  _vm._v(
                                    " to discover content based on your interests."
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.data.learning.items.length === 0
                            ? _c("ion-col", { attrs: { size: "12" } }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/my-learning-completed.png")
                                  }
                                })
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedTabIndex == 2
        ? _c(
            "div",
            [
              _c(
                "ion-grid",
                [
                  _vm.isDesktopPlatform
                    ? _c(
                        "div",
                        [
                          _vm._l(
                            _vm.data.external.items.slice(
                              _vm.data.external.start,
                              _vm.data.external.end
                            ),
                            function(item, index) {
                              return _c("UniversalCard", {
                                key: index + item.id,
                                attrs: {
                                  item: item,
                                  index: index,
                                  isUCTClickAllowed: "",
                                  itemsLength: _vm.data.external.items.slice(
                                    _vm.data.external.start,
                                    _vm.data.external.end
                                  ).length,
                                  tab: _vm.tabName,
                                  "current-tab": "Added by you"
                                },
                                on: {
                                  "learn-item-click": _vm.onLearnItemClick,
                                  "delete-item": _vm.deleteExternalItem
                                }
                              })
                            }
                          ),
                          _vm.data.external.items.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "border-top": "2px solid #e0e1e1"
                                  }
                                },
                                [
                                  _c("paginate", {
                                    attrs: {
                                      "page-count": _vm.data.external.pageCount,
                                      "page-range": _vm.pageRangeExternal,
                                      "margin-pages": 1,
                                      "click-handler":
                                        _vm.handleAddedByYouPageClick,
                                      "container-class": "pagination",
                                      "page-class": "pagination-item",
                                      "page-link-class": "pagination-item-link",
                                      "prev-text": "&lsaquo;",
                                      "prev-class": "pagination-nav",
                                      "prev-link-class": "pagination-nav-link",
                                      "next-text": "&rsaquo;",
                                      "next-class": "pagination-nav",
                                      "next-link-class": "pagination-nav-link",
                                      "hide-prev-next": true
                                    },
                                    model: {
                                      value: _vm.data.external.pageCurrent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.external,
                                          "pageCurrent",
                                          $$v
                                        )
                                      },
                                      expression: "data.external.pageCurrent"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.isDesktopPlatform
                    ? _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _vm._l(
                            _vm.data.external.items.slice(
                              _vm.data.external.start,
                              _vm.data.external.end
                            ),
                            function(item, index) {
                              return _c("UniversalCard", {
                                key: index + item.id,
                                attrs: {
                                  item: item,
                                  index: index,
                                  itemsLength: _vm.data.external.items.slice(
                                    _vm.data.external.start,
                                    _vm.data.external.end
                                  ).length,
                                  tab: _vm.tabName,
                                  "current-tab": "Added by you"
                                },
                                on: {
                                  "delete-item": _vm.deleteExternalItem,
                                  "learn-item-click": _vm.onLearnItemClick
                                }
                              })
                            }
                          ),
                          _vm.data.external.items.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "border-top": "2px solid #e0e1e1"
                                  }
                                },
                                [
                                  _c("paginate", {
                                    attrs: {
                                      "page-count": _vm.data.external.pageCount,
                                      "page-range": _vm.pageRangeExternal,
                                      "margin-pages": 1,
                                      "click-handler":
                                        _vm.handleAddedByYouPageClick,
                                      "container-class": "pagination",
                                      "page-class": "pagination-item",
                                      "page-link-class": "pagination-item-link",
                                      "prev-text": "&lsaquo;",
                                      "prev-class": "pagination-nav",
                                      "prev-link-class": "pagination-nav-link",
                                      "next-text": "&rsaquo;",
                                      "next-class": "pagination-nav",
                                      "next-link-class": "pagination-nav-link",
                                      "hide-prev-next": true
                                    },
                                    model: {
                                      value: _vm.data.external.pageCurrent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.external,
                                          "pageCurrent",
                                          $$v
                                        )
                                      },
                                      expression: "data.external.pageCurrent"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.data.external.items.length === 0
                    ? _c(
                        "ion-row",
                        { staticClass: "ion-align-items-center" },
                        [
                          _c(
                            "ion-col",
                            [
                              _c("MyLearningAddedByYouEmpty", {
                                on: {
                                  "open-external-sidebar":
                                    _vm.onToggleExternalSidebar
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }