const state = {
  tier: false,
  learningLockerEnabled: false
}

const mutations = {}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
