var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ion-grid",
        { staticClass: "ion-no-padding" },
        [
          _c(
            "ion-row",
            { staticClass: "ion-no-padding" },
            [
              _c("ion-col", [
                _c(
                  "div",
                  { staticClass: "recommend-image" },
                  [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "ion-row",
            [
              _c("ion-col", [
                _c(
                  "div",
                  { staticClass: "recommend-format" },
                  [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "recommend-title" },
                  [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "recommend-title" },
                  [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "recommend-subtitle" },
                  [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "recommend-subtitle" },
                  [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                  1
                ),
                _c("div", { staticClass: "meta" }, [
                  _c(
                    "div",
                    { staticClass: "recommend-bookmark" },
                    [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "recommend-bookmark" },
                    [_c("ion-skeleton-text", { attrs: { animated: "" } })],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }