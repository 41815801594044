var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataReady
    ? _c(
        "div",
        {
          staticClass: "main-content",
          class:
            this.$platform === "Mobile" && this.$route.name === "HelpCenterMain"
              ? "help-center-top"
              : "",
          style: !_vm.isDesktopPlatform ? "" : "padding: 2rem 3.8rem"
        },
        [
          !_vm.isLoggedIn
            ? _c("div", { staticClass: "d-flex logo" }, [
                this.$route.fullPath !== "/landing"
                  ? _c(
                      "nav",
                      {
                        staticClass: "d-flex",
                        class:
                          this.$platform === "Mobile" ? "help-nav-mobile" : "",
                        attrs: { id: "helpNav" }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "logo",
                                "class-name": "logo logo--size"
                              },
                              on: { click: _vm.onClickLogo }
                            })
                          ],
                          1
                        ),
                        _c(
                          "ion-row",
                          [
                            _c(
                              "ion-button",
                              {
                                staticClass:
                                  "ion-button-tertiary back nav-right-items",
                                class:
                                  this.$platform === "Mobile"
                                    ? "nav-right-items-mobile"
                                    : "",
                                attrs: { shape: "round", color: "tertiary" },
                                on: { click: _vm.onBack }
                              },
                              [_vm._v("Back")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _c(
            "ion-card",
            {
              ref: "top",
              class: [
                _vm.isDesktopPlatform
                  ? "help-center-container-desktop"
                  : "help-center-container-mobile",
                !_vm.isLoggedIn ? "help-center-container-main-top" : ""
              ]
            },
            [
              _vm.isLoggedIn
                ? _c("svg-icon", {
                    attrs: {
                      "icon-class": "close",
                      "class-name": "g-icon--xsmall close",
                      color: "#182755"
                    },
                    on: { click: _vm.onClose }
                  })
                : _vm._e(),
              _c(
                "ion-card-header",
                {
                  class: _vm.isDesktopPlatform
                    ? "header-desktop"
                    : "header-mobile",
                  staticStyle: { "padding-top": "20px" }
                },
                [
                  _vm.isLoggedIn
                    ? _c(
                        "ion-card-title",
                        { staticClass: "g-type-subheading2 ion-text-start" },
                        [
                          _vm._v(
                            " Welcome to the Help Center, " +
                              _vm._s(_vm.firstName) +
                              "! "
                          )
                        ]
                      )
                    : _c(
                        "ion-card-title",
                        { staticClass: "g-type-subheading2 ion-text-start" },
                        [_vm._v(" Welcome to the Help Center ! ")]
                      ),
                  _vm.isDesktopPlatform
                    ? _c("img", {
                        staticClass: "image-desktop",
                        attrs: {
                          src: require("@/assets/images/help-center.gif"),
                          alt: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("ion-card-content", { staticClass: "ion-text-start" }, [
                _c("div", [
                  _vm._v(
                    " We strive to deliver learning experiences that are meaningful and delightful, but there is always room to improve. We've created the Help Center so you have to a place to get help, let us know when something breaks, or if you have ideas or feedback on ways we can get better. "
                  )
                ]),
                !_vm.isDesktopPlatform
                  ? _c("img", {
                      staticClass: "image-mobile",
                      attrs: {
                        src: require("@/assets/images/help-center.gif"),
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm.isLoggedIn
            ? _c(
                "ion-card",
                {
                  class: _vm.isDesktopPlatform
                    ? "help-center-container-desktop"
                    : "help-center-container-mobile"
                },
                [
                  _c(
                    "ion-card-header",
                    {
                      class: _vm.isDesktopPlatform
                        ? "header-desktop"
                        : "header-mobile"
                    },
                    [
                      _vm.isDesktopPlatform
                        ? _c(
                            "ion-card-title",
                            {
                              staticClass: "g-type-subheading2 ion-text-start"
                            },
                            [
                              _vm._v(" Frequently asked questions"),
                              _c("span", { staticClass: "downloadable" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      "aria-label": "download FAQ pdf",
                                      href:
                                        "https://static.proedge.pwc.com/docs/ProEdgeLearnFAQ.pdf",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/idea-download.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        : _c(
                            "ion-card-title",
                            {
                              staticClass: "g-type-subheading2 ion-text-start"
                            },
                            [
                              _vm._v(" F.A.Qs"),
                              _c("span", { staticClass: "downloadable" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://static.proedge.pwc.com/docs/ProEdgeLearnFAQ.pdf",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/idea-download.svg")
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "ion-card",
            {
              ref: "support",
              class: [
                _vm.isDesktopPlatform
                  ? "help-center-container-desktop"
                  : "help-center-container-mobile",
                !_vm.isLoggedIn ? "help-center-container-main-bottom" : ""
              ]
            },
            [
              _c(
                "ion-card-header",
                {
                  class: _vm.isDesktopPlatform
                    ? "header-desktop"
                    : "header-mobile"
                },
                [
                  _c(
                    "ion-card-title",
                    { staticClass: "g-type-subheading2 ion-text-start" },
                    [_vm._v(" Contact Support ")]
                  )
                ],
                1
              ),
              !_vm.isSupportContacted
                ? _c(
                    "ion-card-content",
                    { staticClass: "ion-text-start" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "content-prompt",
                          attrs: { id: "prompt1" }
                        },
                        [
                          _vm._v(
                            "1. Pick the topic from the set below and send us your thoughts"
                          )
                        ]
                      ),
                      _c(
                        "ion-select",
                        {
                          ref: "topics",
                          staticClass: "topic-select",
                          class: _vm.isDesktopPlatform
                            ? "topic-select-desktop"
                            : "topic-select-mobile",
                          attrs: {
                            "aria-labelledby": "prompt1",
                            interface: "popover",
                            placeholder: "Select Topic"
                          },
                          on: { ionChange: _vm.handleTopicSelect }
                        },
                        _vm._l(_vm.topics, function(topic, index) {
                          return _c(
                            "ion-select-option",
                            { key: index, attrs: { value: topic.value } },
                            [_vm._v(_vm._s(topic.name))]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "content-prompt",
                          attrs: { id: "prompt2" }
                        },
                        [
                          _vm._v(
                            "2. Let us know what you experienced, add a screenshot to help paint a clear picture"
                          )
                        ]
                      ),
                      _c("ion-textarea", {
                        ref: "description",
                        attrs: {
                          rows: "5",
                          required: "",
                          placeholder: "Enter your description here"
                        },
                        on: { ionChange: _vm.handleDescriptionInput }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "dashed-border",
                          class: _vm.isDesktopPlatform
                            ? "drop-zone-desktop"
                            : "drop-zone-mobile",
                          attrs: { "aria-labelledby": "prompt2" },
                          on: {
                            drop: function($event) {
                              return _vm.handleDrop(
                                $event,
                                "upload-img-input",
                                _vm.ticket.file
                              )
                            },
                            dragover: _vm.handleDragover,
                            dragenter: _vm.handleDragover
                          }
                        },
                        [
                          _c("div", { staticClass: "upload-info" }, [
                            _vm._v(
                              "Drop or browse a file (png, jpeg, and jpg) here"
                            )
                          ]),
                          _c("input", {
                            ref: "upload-img-input",
                            staticClass: "upload-input",
                            attrs: { type: "file" },
                            on: {
                              change: function($event) {
                                return _vm.handleClick(
                                  $event,
                                  "upload-img-input",
                                  _vm.ticket.file
                                )
                              }
                            }
                          }),
                          _c(
                            "ion-button",
                            {
                              staticClass: "upload-btn ion-text-capitalize",
                              attrs: {
                                shape: "round",
                                color: "tertiary",
                                disabled: _vm.isSending
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleUpload("upload-img-input")
                                }
                              }
                            },
                            [_vm._v("Browse")]
                          ),
                          _vm.ticket.file.name
                            ? _c(
                                "div",
                                { staticClass: "text-note" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "page-icon",
                                    attrs: { "icon-class": "file-alt-outline" }
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.ticket.file.name) + " "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      !_vm.isLoggedIn
                        ? _c("div", { staticClass: "content-prompt" }, [
                            _vm._v(
                              "3. Please leave your email in case we need to ask any follow up questions"
                            )
                          ])
                        : _vm._e(),
                      !_vm.isLoggedIn
                        ? _c("ion-input", {
                            ref: "email",
                            staticClass: "email",
                            attrs: {
                              rows: "1",
                              required: "",
                              placeholder: "Email",
                              value: _vm.ticket.email
                            },
                            on: { ionChange: _vm.handleEmailInput }
                          })
                        : _vm._e(),
                      _vm.notificationMessage.length
                        ? _c("div", { staticClass: "notification" }, [
                            _vm._v("Error: " + _vm._s(_vm.notificationMessage))
                          ])
                        : _vm._e(),
                      _c(
                        "ion-button",
                        {
                          staticClass:
                            "ion-button-primary ion-text-capitalize next-btn",
                          attrs: {
                            shape: "round",
                            color: "primary",
                            disabled: _vm.isSending
                          },
                          on: { click: _vm.onSend }
                        },
                        [_vm._v("Send")]
                      )
                    ],
                    1
                  )
                : _c("ion-card-content", { staticClass: "ion-text-start" }, [
                    _c("div", { staticClass: "confirmation" }, [
                      _c("div", [_vm._v("Thank you for contacting support.")]),
                      _c("div", [
                        _vm._v(
                          "An email with your ticket reference number will be sent to you."
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "close-btn-wrapper" },
                      [
                        _c(
                          "ion-button",
                          {
                            staticClass:
                              "ion-button-primary ion-text-capitalize close-btn",
                            attrs: { shape: "round", color: "primary" },
                            on: { click: _vm.onClose }
                          },
                          [_vm._v("Close")]
                        )
                      ],
                      1
                    )
                  ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }