var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "top",
      staticClass: "courses__wrapper credential-container main-content",
      class: _vm.$platform === "Desktop" ? "credential-container-desktop" : ""
    },
    [
      _vm.isDesktopPlatform && _vm.isContentAvailable
        ? _c(
            "button",
            {
              staticClass: "back",
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [
              _c("svg-icon", {
                staticClass: "g-icon--small",
                attrs: { "icon-class": "icon-arrow-left" }
              }),
              _vm._v(" Back ")
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady
        ? _c(
            "div",
            [
              _vm.isContentAvailable
                ? _c(
                    "div",
                    [
                      _vm.dataReady
                        ? _c("CredentialOverview", {
                            key: _vm.course.progress,
                            attrs: {
                              isScormCloudCredential:
                                _vm.isScormCloudCredential,
                              course: _vm.course,
                              "is-accessible": _vm.isAccessible
                            },
                            on: { "on-select-continue": _vm.onSelectContinue }
                          })
                        : _vm._e(),
                      _vm.dataReady && !_vm.isScormCloudCredential
                        ? _c(
                            "ion-grid",
                            { staticClass: "ion-no-padding" },
                            [
                              _c(
                                "ion-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.searchBarVisible,
                                      expression: "!searchBarVisible"
                                    }
                                  ],
                                  staticClass: "course__tabs"
                                },
                                [
                                  _c(
                                    "ion-col",
                                    {
                                      attrs: {
                                        size: _vm.isDesktopPlatform ? 6 : 10
                                      }
                                    },
                                    [
                                      _c(
                                        "ion-toolbar",
                                        {
                                          staticClass: "ion-no-padding",
                                          staticStyle: {
                                            height: "56px",
                                            "z-index": "0"
                                          }
                                        },
                                        [
                                          _c(
                                            "ion-segment",
                                            {
                                              attrs: {
                                                value: "Skillbuilders",
                                                scrollable: "",
                                                mode: "md",
                                                disabled: !_vm.isAccessible
                                              },
                                              on: {
                                                ionChange: function($event) {
                                                  return _vm.tabChanged($event)
                                                }
                                              }
                                            },
                                            _vm._l(_vm.names, function(name) {
                                              return _c(
                                                "ion-segment-button",
                                                {
                                                  key: name,
                                                  staticClass:
                                                    "ion-text-capitalize g-type-medium",
                                                  attrs: {
                                                    value: name,
                                                    "data-cy": name
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "ion-label",
                                                    {
                                                      attrs: { color: "dark" }
                                                    },
                                                    [_vm._v(_vm._s(name))]
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  !_vm.isDesktopPlatform
                                    ? _c(
                                        "ion-col",
                                        {
                                          staticStyle: {
                                            background: "#fff",
                                            padding: "0.8rem"
                                          },
                                          attrs: { size: "2" }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "search-icon",
                                              on: { click: _vm.showSearchBar }
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "search",
                                                  "class-name": "g-icon--small"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isDesktopPlatform
                                    ? _c(
                                        "ion-col",
                                        {
                                          staticStyle: {
                                            background: "#fff",
                                            "padding-left": "3rem"
                                          },
                                          attrs: { size: "6" }
                                        },
                                        [
                                          _c("ion-searchbar", {
                                            ref: "searchbar",
                                            staticClass: "course__searchbar",
                                            attrs: { placeholder: "Search" },
                                            on: {
                                              keypress: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.handleSearchInput(
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "ion-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.searchBarVisible &&
                                        !_vm.isDesktopPlatform,
                                      expression:
                                        "searchBarVisible && !isDesktopPlatform"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "ion-col",
                                    [
                                      _c("ion-searchbar", {
                                        ref: "searchbar",
                                        staticClass: "course__searchbar",
                                        attrs: { placeholder: "Search" },
                                        on: {
                                          focusout: _vm.hideSearchBar,
                                          keypress: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleSearchInput($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dataReady
                        ? _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "courses__steps ion-no-margin ion-text-start"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.course.content.skillBuilders.length
                                  ) + " steps to success"
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.selectedTab === "Skillbuilders" && _vm.isAccessible
                        ? _c(
                            "div",
                            { staticClass: "Skillbuilders-tab" },
                            [
                              _vm.dataReady
                                ? _c(
                                    "ion-grid",
                                    {
                                      staticClass: "ion-padding-end",
                                      staticStyle: {
                                        "box-shadow":
                                          "inset -80px 0 40px -71px #bcc2d4"
                                      }
                                    },
                                    [
                                      _c("ion-row", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "courses__skillbuilder-section"
                                          },
                                          _vm._l(
                                            _vm.course.content.skillBuilders,
                                            function(skillbuilder) {
                                              return _c(
                                                "CredentialSkillBuilder",
                                                {
                                                  key: skillbuilder.index,
                                                  staticClass:
                                                    "g-padding__right--medium",
                                                  class:
                                                    _vm.selectSkillBuilder
                                                      .id === skillbuilder.id
                                                      ? "select-skillbuilder"
                                                      : "",
                                                  attrs: {
                                                    skillbuilder: skillbuilder
                                                  },
                                                  on: {
                                                    "on-select-skillbuilder":
                                                      _vm.onSelectSkilBuilder,
                                                    "on-submit-capstone": function(
                                                      $event
                                                    ) {
                                                      return _vm.onSubmitScormCapstone(
                                                        skillbuilder
                                                      )
                                                    }
                                                  }
                                                }
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.dataReady &&
                              !_vm.isScormCloudCredential &&
                              !_vm.selectSkillBuilder.isCapstone
                                ? _c("svg-icon", {
                                    staticClass: "g-icon--msmall",
                                    attrs: {
                                      "icon-class": "arrow-down-turquoise"
                                    }
                                  })
                                : _vm._e(),
                              _vm.dataReady && !_vm.isScormCloudCredential
                                ? _c(
                                    "ion-grid",
                                    [
                                      _c("ion-row", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "courses__lesson-section"
                                          },
                                          _vm._l(_vm.lessons, function(lesson) {
                                            return _c("CredentialLesson", {
                                              key: JSON.stringify(lesson),
                                              staticClass:
                                                "g-margin__right--medium",
                                              attrs: { lesson: lesson },
                                              on: {
                                                "on-select-lesson":
                                                  _vm.onSelectLesson
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.selectSkillBuilder.isCapstone &&
                              !_vm.isScormCloudCredential &&
                              !_vm.lessons.length
                                ? _c("div", [_c("LoadingAnimation")], 1)
                                : _vm._e(),
                              _vm.dataReady && !_vm.isScormCloudCredential
                                ? _c("svg-icon", {
                                    staticClass: "g-icon--msmall",
                                    attrs: {
                                      "icon-class": "arrow-down-tertiary"
                                    }
                                  })
                                : _vm._e(),
                              _vm.dataReady &&
                              !_vm.isScormCloudCredential &&
                              !_vm.selectSkillBuilder.isCapstone &&
                              _vm.selectLesson.id !=
                                _vm.selectSkillBuilder.overview.id
                                ? _c("CredentialLessonInfo", {
                                    attrs: { lesson: _vm.selectLesson }
                                  })
                                : _vm._e(),
                              _vm.dataReady && !_vm.isScormCloudCredential
                                ? _c(
                                    "div",
                                    { ref: "contents" },
                                    _vm._l(_vm.contents, function(content) {
                                      return _c("CredentialContent", {
                                        key: JSON.stringify(content),
                                        attrs: {
                                          content: content,
                                          progress: _vm.progress
                                        },
                                        on: {
                                          "on-complete-assessment":
                                            _vm.onCompleteAssessment,
                                          "on-submit-capstone":
                                            _vm.onSubmitCapstone,
                                          "on-submit-kcs": _vm.onSubmitKCS
                                        }
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _c("div", {
                                staticStyle: {
                                  "margin-bottom": "1rem",
                                  height: "1rem"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dataReady &&
                      !_vm.isScormCloudCredential &&
                      _vm.selectedTab === "Index" &&
                      _vm.isAccessible
                        ? _c(
                            "div",
                            { staticClass: "Index-tab" },
                            [
                              _c(
                                "ion-card",
                                [
                                  _c("h2", { staticClass: "tab-title" }, [
                                    _vm._v("Index")
                                  ]),
                                  _c(
                                    "ion-grid",
                                    [
                                      _c(
                                        "ion-row",
                                        [
                                          _c(
                                            "ion-col",
                                            { staticClass: "ion-text-start" },
                                            _vm._l(
                                              _vm.course.content.skillBuilders,
                                              function(skillbuilder) {
                                                return _c(
                                                  "CredentialSkillBuilderIndex",
                                                  {
                                                    key:
                                                      skillbuilder.index +
                                                      skillbuilder.progress,
                                                    staticClass:
                                                      "g-margin__bottom--small",
                                                    attrs: {
                                                      skillbuilder: skillbuilder,
                                                      courseId: _vm.courseId
                                                    }
                                                  }
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedTab === "Bookmarks" &&
                      !_vm.isScormCloudCredential &&
                      _vm.isAccessible
                        ? _c(
                            "div",
                            { staticClass: "Bookmarks-tab" },
                            [
                              _c(
                                "ion-card",
                                [
                                  _c("h2", { staticClass: "tab-title" }, [
                                    _vm._v("Bookmarks")
                                  ]),
                                  _c(
                                    "ion-grid",
                                    {
                                      class: _vm.isDesktopPlatform
                                        ? "bookmark-panel-desktop"
                                        : "bookmark-panel-mobile"
                                    },
                                    [
                                      _c(
                                        "ion-row",
                                        [
                                          _c(
                                            "ion-col",
                                            { staticClass: "ion-text-start" },
                                            [
                                              _vm._l(_vm.bookmarks, function(
                                                bookmark
                                              ) {
                                                return _c(
                                                  "div",
                                                  { key: bookmark.id },
                                                  [
                                                    _c(
                                                      "CredentialSkillBuilderBookmark",
                                                      {
                                                        attrs: {
                                                          bookmark: bookmark
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              }),
                                              _vm.bookmarks.length === 0
                                                ? _c(
                                                    "h3",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "0.5rem"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "There is no bookmark"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding",
                      style: _vm.isDesktopPlatform
                        ? ""
                        : "padding: 2rem 10px 6rem;"
                    },
                    [_c("PageNotFound")],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }