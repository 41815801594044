import localforage from 'localforage'

export default {
  async isGAEnabled() {
    let enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    let isGAFlagEnabled = enabledFeatureFlags && {}.hasOwnProperty.call(enabledFeatureFlags, 'global_google_analytics-enabled') ? enabledFeatureFlags['global_google_analytics-enabled'] : false
    let trackingDisabled = await localforage.getItem('trackingDisabled')
    trackingDisabled = trackingDisabled == null ? true : trackingDisabled
    return !trackingDisabled && isGAFlagEnabled
  }
}