var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "tooltip-bottom",
        style: { cursor: _vm.textCopied ? "not-allowed" : "pointer" },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.onClick($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "tooltip-bottom-copy" },
          [
            _c("svg-icon", {
              staticClass: "chain-link",
              attrs: { "icon-class": "chain-link" }
            }),
            _c(
              "button",
              {
                class: _vm.textCopied
                  ? "tooltip-bottom-text"
                  : "tooltip-bottom-text tooltip-bottom-text-width"
              },
              [_vm._v(_vm._s(_vm.textCopied ? "Link copied" : "Copy link"))]
            )
          ],
          1
        ),
        _c("svg-icon", {
          staticClass: "green-checkmark",
          style: { display: _vm.textCopied ? "inline-flex" : "none" },
          attrs: { "icon-class": "green-checkmark" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }