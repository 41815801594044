var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "top",
      class: _vm.isDesktopPlatform
        ? "desktop-background track-content"
        : "non-white-background"
    },
    [
      !_vm.dataReady ? _c("div", [_c("TrackSkeleton")], 1) : _vm._e(),
      _vm.dataReady
        ? _c(
            "div",
            [
              _vm.isContentAvailable
                ? _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding ion-padding-bottom",
                      class: _vm.isDesktopPlatform
                        ? "desktop-collection-container"
                        : "mobile-collection-container"
                    },
                    [
                      _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            {
                              attrs: {
                                "size-xl": "8.5",
                                "size-lg": "12",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "back accessible-button",
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.go(-1)
                                    }
                                  }
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "g-icon--small",
                                    attrs: { "icon-class": "icon-arrow-left" }
                                  }),
                                  _vm._v(" Back ")
                                ],
                                1
                              ),
                              _c(
                                "ion-row",
                                { staticClass: "collection-info" },
                                [
                                  _c(
                                    "ion-col",
                                    { staticClass: "collection-img-shape" },
                                    [
                                      _vm.collection.image.uri
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.collection.image.uri
                                            }
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/track.png")
                                            }
                                          })
                                    ]
                                  ),
                                  _c("ion-col", [
                                    _c("div", { staticClass: "meta" }, [
                                      _c("div", { staticClass: "format" }, [
                                        _vm._v(
                                          _vm._s(_vm.collection.format.name)
                                        )
                                      ]),
                                      _vm.collection.progress
                                        ? _c("div", [
                                            _vm.collection.progress > 0 &&
                                            _vm.collection.progress < 100
                                              ? _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c("radial-progress-bar", {
                                                      attrs: {
                                                        diameter: 28,
                                                        "completed-steps":
                                                          _vm.collection
                                                            .progress,
                                                        "total-steps":
                                                          _vm.collection
                                                            .progress == "99"
                                                            ? 110
                                                            : 100,
                                                        startColor: "#000000",
                                                        stopColor: "#000000",
                                                        innerStrokeColor:
                                                          "#e0e1e1",
                                                        strokeWidth: 4,
                                                        innerStrokeWidth: 5
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.collection
                                                            .progress
                                                        ) + "%"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm.collection.completed ||
                                                _vm.collection.progress == 100
                                              ? _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        "icon-class":
                                                          "icon-progress-complete"
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v("completed")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e()
                                    ]),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(_vm.collection.title))
                                    ]),
                                    _c("div", { staticClass: "meta-info" }, [
                                      _vm.collection.rating
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "collection-rating",
                                              on: { click: _vm.onScroll }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "rating" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.collection.rating
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("StarRating", {
                                                staticClass: "star-rating",
                                                attrs: {
                                                  "inactive-color": "#d0cfce",
                                                  "active-color": "#b98032",
                                                  "star-points": [
                                                    23,
                                                    2,
                                                    14,
                                                    17,
                                                    0,
                                                    19,
                                                    10,
                                                    34,
                                                    7,
                                                    50,
                                                    23,
                                                    43,
                                                    38,
                                                    50,
                                                    36,
                                                    34,
                                                    46,
                                                    19,
                                                    31,
                                                    17
                                                  ],
                                                  rating: _vm.collection.rating,
                                                  "read-only": true,
                                                  increment: 0.1,
                                                  "star-size": 14,
                                                  "show-rating": false,
                                                  "round-start-rating": true
                                                }
                                              }),
                                              _c("svg-icon", {
                                                staticClass:
                                                  "display-star-mobile",
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                attrs: {
                                                  "icon-class":
                                                    "star-filled-desktop"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "rating-count" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.collection.ratingCount
                                                    ) + " ratings"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.collection.duration
                                        ? _c(
                                            "div",
                                            { staticClass: "duration" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.collection.duration)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.collection.level
                                        ? _c("div", { staticClass: "level" }, [
                                            _vm._v(_vm._s(_vm.collection.level))
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "content-count" },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "track-list"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "content-count-title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collection.content &&
                                                    _vm.collection.content
                                                      .length
                                                ) + " items"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", { staticClass: "meta-action" }, [
                                      _c(
                                        "div",
                                        { staticClass: "share-container" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "social-share",
                                              attrs: {
                                                "aria-labelledby": "share-btn"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.popoverOn("share")
                                                }
                                              }
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "click-to-copy",
                                                  "class-name":
                                                    "g-icon--small g-clickable"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.isShareOpen
                                            ? _c("SocialSharePopover", {
                                                staticClass:
                                                  "social-share-popover",
                                                attrs: {
                                                  assetId: _vm.collectionId,
                                                  routeInfo: _vm.getRouteInfo(),
                                                  router: _vm.$router,
                                                  fromPage: "Universal card",
                                                  size: "lg"
                                                },
                                                on: {
                                                  "dismiss-share-action-model": function(
                                                    $event
                                                  ) {
                                                    return _vm.popoverOff(
                                                      "share"
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          !_vm.isShareOpen &&
                                          _vm.isDesktopPlatform
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "share-tooltip-text",
                                                  attrs: { id: "share-btn" }
                                                },
                                                [_vm._v("Share")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm.isMoreShown
                                        ? _c(
                                            "div",
                                            { staticClass: "more-container" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "more-action",
                                                  attrs: {
                                                    "aria-labelledby":
                                                      "more-btn"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.popoverOn(
                                                        "more"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "more-action",
                                                      "class-name":
                                                        "g-icon--small g-clickable"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.isMoreOpen
                                                ? _c("MoreActionPopover", {
                                                    staticClass: "more-popover",
                                                    attrs: {
                                                      item: _vm.collection,
                                                      assetId: _vm.collectionId,
                                                      assetType:
                                                        _vm.collection.format
                                                          .name,
                                                      routeInfo: _vm.getRouteInfo(),
                                                      router: _vm.$router,
                                                      showEditDelete: true,
                                                      showAddToTrack: false,
                                                      size: "lg",
                                                      fromPage: "Universal card"
                                                    },
                                                    on: {
                                                      "add-to-track": function(
                                                        $event
                                                      ) {
                                                        return _vm.popoverOff(
                                                          "more"
                                                        )
                                                      },
                                                      "close-more": function(
                                                        $event
                                                      ) {
                                                        return _vm.popoverOff(
                                                          "more"
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.isMoreOpen
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "more-tooltip-text",
                                                      attrs: { id: "more-btn" }
                                                    },
                                                    [_vm._v("More")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.completedItems.length > 0 &&
                                      _vm.completedItems.length !=
                                        _vm.totalItems.length
                                        ? _c(
                                            "div",
                                            { staticClass: "flex-container" },
                                            [
                                              _c("radial-progress-bar", {
                                                attrs: {
                                                  diameter: 28,
                                                  "completed-steps":
                                                    _vm.completedItems.length,
                                                  "total-steps":
                                                    _vm.totalItems.length,
                                                  startColor: "#000000",
                                                  stopColor: "#000000",
                                                  innerStrokeColor: "#e0e1e1",
                                                  strokeWidth: 4,
                                                  innerStrokeWidth: 5
                                                }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex-percentage"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.completedItems
                                                        .length +
                                                        " of " +
                                                        _vm.totalItems.length +
                                                        " items completed"
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm.completedItems.length > 0 &&
                                          _vm.completedItems.length ==
                                            _vm.totalItems.length
                                        ? _c(
                                            "div",
                                            { staticClass: "progress-track" },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  "margin-right": "5px"
                                                },
                                                attrs: {
                                                  "icon-class":
                                                    "icon-progress-complete"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    font:
                                                      "normal normal normal 14px/16px Arial",
                                                    color: "#2D2D2D"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.completedItems
                                                        .length +
                                                        " of " +
                                                        _vm.totalItems.length +
                                                        " items completed"
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "collection-about-container" },
                        [
                          _c(
                            "ion-row",
                            { staticClass: "carouselDesktop" },
                            [
                              _c(
                                "ion-col",
                                {
                                  staticStyle: { "margin-right": "2rem" },
                                  attrs: { size: "8.5" }
                                },
                                [
                                  _c("CollectionAbout", {
                                    attrs: { collection: _vm.collection }
                                  }),
                                  _c("ItemRating", {
                                    attrs: {
                                      item: _vm.collection,
                                      scroll: _vm.scroll,
                                      getUpdatedItemData:
                                        _vm.getUpdatedCollectionData,
                                      ratingDetails: _vm.ratingDetails
                                    },
                                    on: {
                                      "update-ratings": function($event) {
                                        return _vm.getRatingById()
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { size: "3" } },
                                    [
                                      _c("ItemMetaData", {
                                        attrs: { item: _vm.collection }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "ion-row",
                            { staticClass: "carouselMobile" },
                            [
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c(
                                        "ItemMetaData",
                                        { attrs: { item: _vm.collection } },
                                        [
                                          _c("CollectionAbout", {
                                            attrs: {
                                              slot: "item-about",
                                              collection: _vm.collection
                                            },
                                            slot: "item-about"
                                          }),
                                          _c("ItemRating", {
                                            attrs: {
                                              slot: "item-rating",
                                              item: _vm.collection,
                                              scroll: _vm.scroll,
                                              getUpdatedItemData:
                                                _vm.getUpdatedCollectionData,
                                              ratingDetails: _vm.ratingDetails
                                            },
                                            on: {
                                              "update-ratings": function(
                                                $event
                                              ) {
                                                return _vm.getRatingById()
                                              }
                                            },
                                            slot: "item-rating"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c("CollectionAbout", {
                                        attrs: { collection: _vm.collection }
                                      }),
                                      _c("ItemRating", {
                                        attrs: {
                                          item: _vm.collection,
                                          scroll: _vm.scroll,
                                          getUpdatedItemData:
                                            _vm.getUpdatedCollectionData,
                                          ratingDetails: _vm.ratingDetails
                                        },
                                        on: {
                                          "update-ratings": function($event) {
                                            return _vm.getRatingById()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding",
                      style: _vm.isDesktopPlatform
                        ? ""
                        : "padding: 2rem 10px 6rem;"
                    },
                    [_c("PageNotFound")],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }