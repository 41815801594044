var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge_bookmark__content" },
    [
      !_vm.dataReady
        ? _c("ion-spinner", { attrs: { color: "#1f3370" } })
        : _vm._e(),
      _vm.dataReady && _vm.bookmarks.length >= 0
        ? _c(
            "div",
            {
              staticClass: "edge_bookmark__library-list main-content",
              class: { "ion-padding-horizontal": this.$platform === "Desktop" }
            },
            [
              this.$platform === "Desktop"
                ? _c(
                    "h1",
                    {
                      staticClass: "desktop-page-name",
                      staticStyle: { "margin-top": "0px" }
                    },
                    [_vm._v("bookmarks")]
                  )
                : _vm._e(),
              _vm.dataReady && _vm.bookmarks.length
                ? _c(
                    "div",
                    {
                      style:
                        this.$platform != "Desktop" ? "padding-top: 1rem;" : ""
                    },
                    [
                      _vm._l(_vm.bookmarks, function(item, index) {
                        return _c("UniversalCard", {
                          key: item.id,
                          attrs: {
                            item: item,
                            index: index,
                            itemsLength: _vm.bookmarks.length,
                            tab: "bookmarks"
                          }
                        })
                      }),
                      _vm.data.totalPages > 0
                        ? _c(
                            "div",
                            { staticClass: "bookmarked-pagination" },
                            [
                              _c("paginate", {
                                attrs: {
                                  "page-count": _vm.data.totalPages,
                                  "page-range": 3,
                                  "margin-pages": 2,
                                  "click-handler": _vm.onPageClick,
                                  "container-class": "pagination",
                                  "page-class": "pagination-item",
                                  "page-link-class": "pagination-item-link",
                                  "prev-text": "&lsaquo;",
                                  "prev-class": "pagination-nav",
                                  "prev-link-class": "pagination-nav-link",
                                  "next-text": "&rsaquo;",
                                  "next-class": "pagination-nav",
                                  "next-link-class": "pagination-nav-link",
                                  "hide-prev-next": true
                                },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm.dataReady && !_vm.bookmarks.length
                ? _c("div", { staticClass: "bookmarked-empty-container" }, [
                    _c(
                      "div",
                      { staticClass: "bookmarked-empty-container-grid" },
                      [
                        _c("div", [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("No bookmarks? No worries.")
                          ]),
                          _c("div", { staticClass: "sub-title" }, [
                            _vm._v(
                              "You can bookmark any ProEdge content you find along your journey, and it shows up here for easy access."
                            )
                          ]),
                          _c("div", { staticClass: "action-btns" }, [
                            _c(
                              "a",
                              {
                                staticClass: "learning-plan-btn",
                                attrs: { href: "#" },
                                on: { click: _vm.goToLearningPlan }
                              },
                              [_vm._v("Learning Plan")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "explore-btn",
                                attrs: { href: "#" },
                                on: { click: _vm.goToExplore }
                              },
                              [_vm._v("Explore")]
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._m(0)
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bookmarked-empty-image" }, [
      _c("img", { attrs: { src: require("@/assets/images/no-bookmark.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }