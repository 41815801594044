var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.extensionPrompt
      ? _c("div", { staticClass: "mini-alert" }, [
          _c("h5", [_vm._v("Still there?")]),
          _c("p", [
            _vm._v(
              "Your session will expire in: " +
                _vm._s(~~((_vm.timeLeft % 3600) / 60)) +
                " minutes " +
                _vm._s(~~_vm.timeLeft % 60) +
                " seconds"
            )
          ]),
          _c(
            "div",
            { staticClass: "handler" },
            [
              _c(
                "ion-button",
                {
                  staticClass: "g-button--primary ion-button-primary",
                  attrs: { "data-cy": "keep-vdi", color: "primary" },
                  on: { click: _vm.extendSession }
                },
                [_vm._v("Keep working")]
              ),
              _c(
                "a",
                {
                  staticClass: "secondary-action",
                  attrs: { href: "#" },
                  on: { click: _vm.endSession }
                },
                [_vm._v("End Session")]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.expired
      ? _c("div", { staticClass: "expired-alert" }, [
          _c("h1", [_vm._v("Your virtual desktop session has ended.")]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/vdi_session.png"),
              alt: "Virtual desktop characters"
            }
          }),
          _c(
            "div",
            { staticClass: "handler" },
            [
              _c(
                "ion-button",
                {
                  staticClass: "g-button--primary ion-button-primary",
                  attrs: { "data-cy": "resume-vdi", color: "primary" },
                  on: { click: _vm.resumeVdi }
                },
                [_vm._v("Resume virtual desktop")]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "center-header" },
      [
        _c("svg-icon", {
          attrs: { "icon-class": "logo", "class-name": "logo" }
        }),
        _c(
          "ion-button",
          {
            directives: [
              {
                name: "track",
                rawName: "v-track",
                value: "vdi,Guide,click",
                expression: "'vdi,Guide,click'"
              }
            ],
            staticClass: "ion-button-primary guide-button",
            attrs: { shape: "round", color: "tertiary" },
            on: { click: _vm.toggleShowGuide }
          },
          [_vm._v("Virtual Desktop Instructions")]
        ),
        _vm.guideIsOpen
          ? _c(
              "div",
              { staticClass: "popover-container arrow-top" },
              [
                _c("GuidePopover", {
                  on: { "guide-popover-close": _vm.toggleShowGuide }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { ref: "viewport" }, [
      _c("div", { ref: "display", attrs: { id: "display" } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }