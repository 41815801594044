<template>
  <div style="display: flex; flex-direction: row">
    <ion-card
      class="skillbuilder"
      @mouseenter="toggleOverlay"
      @mouseleave="toggleOverlay"
      :class="[
        skillbuilder.progress === 100 ? 'complete' : '',
        !skillbuilder.isProjectUploadCard && skillbuilder.isCapstone && skillbuilder.progress < 100 ? 'capstone-yellow' : ''
      ]"
    >
      <div class="course-overlay" v-if="active && skillbuilder.isCourse" @click="selectSkillbuilder">
        <div class="course-overlay__label">
          OPEN IN <span>{{ skillbuilder.providerName || '' }}</span>
        </div>
      </div>
      <ion-grid @click="selectSkillbuilder">
        <ion-row>
          <ion-col>
            <div :class="[!skillbuilder.isProjectUploadCard && skillbuilder.isCapstone ? 'circle-white' : 'circle-yellow']">
              {{ skillbuilder.index }}
            </div>
            <p v-if="!skillbuilder.isCapstone && skillbuilder.index !== 0" class="ion-no-margin skillbuilder__title ellipsis-lines-1">Skillbuilder</p>
            <p v-if="!skillbuilder.isCapstone && skillbuilder.index === 0" class="ion-no-margin skillbuilder__title ellipsis-lines-1">Overview</p>
            <p v-if="skillbuilder.isCapstone" class="ion-no-margin skillbuilder__title ellipsis-lines-1">Capstone Project</p>

            <p class="ion-no-margin skillbuilder__sub-title ellipsis-lines-2">
              {{ skillbuilder.title }}
            </p>
            <div class="synopsis" v-if="!skillbuilder.isCapstone">
              <p class="g-padding__top--small g-margin__padding--small ellipsis-lines-3" v-if="skillbuilder.progress > 0 && skillbuilder.progress < 100">
                {{ skillbuilder.synopsis }}
              </p>
              <p class="g-padding__top--small g-margin__padding--small ellipsis-lines-5" v-if="skillbuilder.progress === 0">
                {{ skillbuilder.synopsis }}
              </p>
              <p class="skillbuilder__alldone" v-if="skillbuilder.progress === 100">All Done</p>
            </div>
            <div class="synopsis" v-else>
              <p class="g-padding__top--small g-margin__padding--small ellipsis-lines-3" v-if="skillbuilder.progress > 0 && skillbuilder.progress < 100">
                {{ skillbuilder.synopsis }}
              </p>
              <p class="g-padding__top--small g-margin__padding--small ellipsis-lines-5" v-if="skillbuilder.progress === 0">
                {{ skillbuilder.synopsis }}
              </p>
              <div class="ion-text-center">
                <ion-button
                  v-if="skillbuilder.isCapstone && isDesktopPlatform && !skillbuilder.projectId && skillbuilder.progress < 100 && skillbuilder.isProjectUploadCard"
                  shape="round"
                  color="primary"
                  class="g-button--primary ion-button-primary skillbuilder__capstone-button"
                  @click.stop="onSubmitCapstone"
                >
                  Upload
                </ion-button>
                <ion-button
                  v-if="skillbuilder.isCapstone && isDesktopPlatform && skillbuilder.projectId && skillbuilder.progress < 100 && skillbuilder.isProjectUploadCard && isModifiable"
                  shape="round"
                  color="primary"
                  class="g-button--primary ion-button-primary skillbuilder__capstone-button"
                  @click.stop="editSubmitCapstone"
                >
                  Edit
                </ion-button>
              </div>
              <p class="skillbuilder__alldone" v-if="skillbuilder.progress === 100">All Done</p>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-footer style="height: 51px; padding: 0px 0px 0px 0px" v-if="skillbuilder.progress > 0 && skillbuilder.progress < 100 && !skillbuilder.isProjectUploadCard">
        <div class="skillbuilder__progress-wrapper">
          <ion-progress-bar :value="skillbuilder.progress / 100" color="secondary" style="--buffer-background: white"></ion-progress-bar>
          <p class="ion-no-margin skillbuilder__percentage">In Progress</p>
        </div>
      </ion-footer>
      <ion-footer style="height: 51px; padding: 0px 0px 0px 0px; text-align: start" v-if="skillbuilder.progress === 100 || skillbuilder.uploadedAt">
        <div style="margin: 15px 0px 0px 10px">
          <svg-icon icon-class="check" class="g-icon--small check g-clickable g-margin__right--small"></svg-icon>
          <span v-if="skillbuilder.completedAt" class="ion-no-margin skillbuilder__percentage">Completed {{ completedAt }}</span>
          <span v-if="!skillbuilder.completedAt && skillbuilder.uploadedAt" class="ion-no-margin skillbuilder__percentage">Uploaded {{ uploadedAt }}</span>
        </div>
      </ion-footer>
    </ion-card>
  </div>
</template>

<script>
export default {
  name: 'CredentialSkillBuilder',
  components: {},
  props: {
    skillbuilder: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    completedAt() {
      return this.skillbuilder.completedAt ? new Date(this.skillbuilder.completedAt).toLocaleDateString('en-US').replaceAll('/', '.') : ''
    },
    uploadedAt() {
      return this.skillbuilder.uploadedAt ? new Date(this.skillbuilder.uploadedAt).toLocaleDateString('en-US').replaceAll('/', '.') : ''
    },
    isModifiable() {
      if (this.skillbuilder.isModifiable === false) {
        return false
      }
      return true
    }
  },
  data() {
    return {
      progressArc: {
        startDegrees: 0,
        endDegrees: 270,
        showPercent: false,
        percent: 100
      },
      isDesktopPlatform: false,
      active: false
    }
  },
  created() {},
  mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  filters: {
    formatDuration(time) {
      return time < 3600 ? `${Math.round(time / 60)} min` : `${Math.round(time / 3600)} hrs`
    }
  },
  methods: {
    selectSkillbuilder() {
      if (!this.skillbuilder.isProjectUploadCard) {
        this.$emit('on-select-skillbuilder', this.skillbuilder)
      }
    },
    onSubmitCapstone() {
      this.$emit('on-submit-capstone', true)
    },
    editSubmitCapstone() {
      this.$emit('on-submit-capstone', false)
    },
    toggleOverlay() {
      this.active = !this.active
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('skillbuilder') {
  height: 230px;
  width: 270px;
  cursor: pointer;
  border: 5px solid white;
  // box-shadow: 4px 4px #e9e9e9;

  @include element('alldone') {
    color: var(--ion-color-white);
    font-size: 2rem;
    font-family: 'Futura PT Md';
    text-align: start;
    margin: 16px 0px;
  }

  @include element('progress-wrapper') {
    background-color: #f0f0f0;
    padding: 10px 5px 0px 5px;
    height: 90%;
  }

  @include element('percentage') {
    font-family: 'Arial';
    font-weight: bold;
    color: var(--ion-color-medium);
    text-align: start;
    margin-top: 10px;
  }

  @include element('capstone-button') {
    width: 40%;
    height: 35px !important;
    font-family: 'Futura PT Demi';
    text-transform: capitalize;
  }

  @include element('arrow') {
    margin-top: 40%;
    margin-left: 0.2rem;
  }

  @include element('dot') {
    height: 8px;
    width: 8px;
    background-color: var(--ion-color-secondary-dark-yellow);
    border-radius: 50%;
    display: inline-block;
  }

  @include element('title') {
    color: map-get($edge-colors, 'black');
    font-family: 'Arial';
    font-size: 0.8rem;
    font-weight: bold;
    text-align: start;
  }

  @include element('sub-title') {
    color: map-get($edge-colors, 'black');
    font-family: 'Futura PT Md';
    font-size: 1.5rem;
    text-align: start;
    height: 62px;
  }
  @include element('progressArc') {
    margin: 0.5rem auto;
    height: 90px;
    width: 90px;
    --color-progress: var(--ion-color-secondary);
    --color-track: var(--ion-color-medium-light);
  }
  @include element('progress') {
    margin: 0.5rem auto;
    width: 90%;
    height: 0.5rem;
    background-color: var(--ion-color-medium-light);
    border-radius: 10px;

    @include modifier('percent') {
      background: var(--ion-color-secondary);
      border-radius: inherit;
      height: inherit;
    }
  }
}

.synopsis {
  height: 64px;
  text-align: left;
  color: var(--ion-color-dark);
  font-family: 'Arial';
  p {
    padding: 0 !important;
  }
}

ion-footer {
  &.footer-md::before {
    background-image: none;
  }
}

ion-button {
  --box-shadow: none;
}

.complete {
  background-color: var(--ion-color-secondary);
}

.capstone-yellow {
  background-color: var(--ion-color-secondary-dark-yellow);
}

.circle-yellow {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--ion-color-secondary-dark-yellow);
  float: right;
  font-family: 'Futura PT Md';
  font-size: 1.5rem;
  padding-top: 2px;
  color: map-get($edge-colors, 'black');
}

.circle-white {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--ion-color-white);
  float: right;
  font-family: 'Futura PT Md';
  font-size: 1.5rem;
  padding-top: 2px;
  color: map-get($edge-colors, 'black');
}

.course-overlay {
  z-index: 11;
  position: absolute;
  padding-top: 35%;
  padding-left: 13%;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
}

.course-overlay__label {
  height: 30px;
  width: 200px;
  border-radius: 10px;
  border: 2px solid var(--ion-color-post-gray);
  background-color: var(--ion-color-white);
  padding-top: 4px;
  font-size: 0.8rem;
  span {
    margin-left: 6px;
    font-size: 0.9rem;
    font-weight: bold;
    color: map-get($edge-colors, 'black');
    font-family: 'Futura PT Md';
  }
}
</style>
