<template>
  <div class="skillbuilder-index">
    <ion-row v-if="skillbuilder.isCapstone" class="ion-align-items-center">
      <ion-col class="skillbuilder-index__title ion-no-padding g-margin__right--msmall">Capstone Project: {{ skillbuilder.title }}</ion-col>
      <ion-col size="1" @click="toggleCapstone()">
        <svg-icon :icon-class="'dropdown-transcript'" class-name="g-icon--xsmall" :class="[!showBuilder ? '' : 'skillbuilder-index__caret-transform']" />
      </ion-col>
    </ion-row>
    <ion-row v-else class="ion-align-items-center">
      <ion-col class="skillbuilder-index__title ion-no-padding g-margin__right--msmall">Skillbuilder {{ skillbuilder.index }}: {{ skillbuilder.title }}</ion-col>
      <ion-col size="1" @click="toggleSkillbuilder()">
        <svg-icon :icon-class="'dropdown-transcript'" class-name="g-icon--xsmall" :class="[!showBuilder ? '' : 'skillbuilder-index__caret-transform']" />
      </ion-col>
    </ion-row>
    <div v-if="showBuilder">
      <CredentialLessonIndex v-for="lesson in lessons" :key="lesson.id" :lesson="lesson" :courseId="courseId" :sbid="skillbuilder.id" />
    </div>
    <div v-if="showBuilder && skillbuilder.isCapstone">
      <CredentialLessonIndex
        v-for="(lesson, index) in capstone"
        :key="lesson.id"
        :lesson="lesson"
        :courseId="courseId"
        :sbid="capstone.id"
        :index="index"
        :meta="lesson.meta"
        :isCapstone="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import CredentialLessonIndex from '@/views/credential/components/CredentialLessonIndex'

export default {
  name: 'CredentialSkillBuilderIndex',
  components: { CredentialLessonIndex },
  props: {
    skillbuilder: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    courseId: { type: String, required: true, default: '' }
  },
  data() {
    return {
      showBuilder: false,
      lessons: [],
      capstone: []
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async created() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
  },
  mounted() {},
  methods: {
    async toggleSkillbuilder() {
      this.showBuilder = !this.showBuilder
      if (this.lessons.length === 0) {
        let response = await this.$learnAdmin.getSkillBuilderById({ token: this.authToken, id: this.courseId, sbid: this.skillbuilder.id })
        this.lessons = response.lessons
      }
    },
    async toggleCapstone() {
      this.showBuilder = !this.showBuilder
      if (this.capstone.length === 0) {
        let capstoneResponse = await this.$learnAdmin.getCapstoneProjectForCredential({ token: this.authToken, id: this.courseId })
        this.capstone = capstoneResponse.overview.lessonContents
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('skillbuilder-index') {
  background-color: var(--ion-color-light-gray);
  border-radius: 10px;
  padding: 0rem 0rem;

  @include element('caret-transform ') {
    transform: rotate(180deg);
  }
  @include element('index') {
    color: map-get($edge-colors, 'tertiary');
    text-align: left;
    font-size: 3rem;
    font-weight: bolder;
    margin: 0px 5px;
  }
  @include element('title') {
    color: var(--ion-color-dark-blue);
    font-size: 1.2rem;
    text-align: left;
    font-family: 'Futura PT Demi';
    padding: 0.5rem 0.5rem;
  }
  @include element('subtitle') {
    font-size: 1.1rem;
    text-align: left;
    padding-left: 0.5rem;
  }
  @include element('numlessons') {
    color: var(--ion-color-black);
    font-size: 1.1rem;
    text-align: left;
    padding-left: 0.8rem;
    padding-bottom: 0.5rem;
  }
  @include element('module-builder') {
    margin-bottom: 0.3rem;
  }
}
</style>
