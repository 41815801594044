const state = {
  shareWelcomeMessageViewed: false
}

const mutations = {
  SET_WELCOME_MESSAGE_VIEWED: state => {
    state.shareWelcomeMessageViewed = true
  }
}

const actions = {
  setWelcomeMessageViewed({ commit }) {
    commit('SET_WELCOME_MESSAGE_VIEWED')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
