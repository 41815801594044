var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tracks-modal" }, [
    _c(
      "div",
      [
        _c("svg-icon", {
          staticClass: "g-icon--small close g-clickable tracks-modal__close",
          attrs: { "icon-class": "filter-close" },
          on: { click: _vm.dismiss }
        }),
        _c(
          "ion-row",
          { staticClass: "ion-align-items-center ion-padding-top" },
          [
            _c(
              "ion-col",
              { attrs: { size: "2" } },
              [
                _c("svg-icon", {
                  staticClass: "g-icon--small",
                  attrs: { "icon-class": "icon-success" }
                })
              ],
              1
            ),
            _vm.options.title != ""
              ? _c("ion-col", [
                  _c("p", { staticClass: "tracks-modal__subtitle" }, [
                    _vm._v(_vm._s(_vm.options.title))
                  ])
                ])
              : _c("ion-col", [
                  _c("p", { staticClass: "tracks-modal__subtitle" }, [
                    _vm._v(" Content successfully added to "),
                    _c(
                      "a",
                      {
                        staticClass: "tracks-modal__link",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goToTrack($event)
                          }
                        }
                      },
                      [_vm._v('"' + _vm._s(_vm.options.track.name) + '"')]
                    )
                  ])
                ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }