<template>
  <div class="search-tab-course">
    <UniversalCard v-for="(item, index) in data.items" :key="item.id" :item="item" :index="index" :itemsLength="data.items.length" :tab="tabName" />
    <div v-if="data.items && data.items.length && data.pageCurrent > 0" class="search-result-pagination">
      <paginate
        v-model="data.pageCurrent"
        :page-count="data.pageCount"
        :page-range="pageRange"
        :margin-pages="1"
        :click-handler="handlePageClick"
        :container-class="'pagination'"
        :page-class="'pagination-item'"
        :page-link-class="'pagination-item-link'"
        :prev-text="'&lsaquo;'"
        :prev-class="'pagination-nav'"
        :prev-link-class="'pagination-nav-link'"
        :next-text="'&rsaquo;'"
        :next-class="'pagination-nav'"
        :next-link-class="'pagination-nav-link'"
        :hide-prev-next="true"
      />
    </div>
    <div v-if="!dataReady" :class="isDesktopPlatform ? 'search-result-panel-desktop' : 'search-result-panel-mobile'">
      <SearchSkeleton v-for="i in 5" :key="i" />
    </div>
    <div v-if="dataReady && data.items && data.items.length === 0">
      <ion-grid>
        <!-- desktop platform no results section -->
        <div v-if="isDesktopPlatform">
          <ion-row>
            <ion-col>
              <h3 class="desktop-no-results-header">0 results found for {{ query }}</h3>
              <p class="desktop-no-results-subheader">Try a different keyword or a more generic search term</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h3 class="desktop-no-results-section-title">Need help finding something?</h3>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col size="6">
              <img src="@/assets/images/recommended-skills-footer.png" style="margin: 0 12px 0 0" class="desktop-no-results-img-1" />
              <p class="desktop-no-results-subheader" style="margin: 5%">
                Browse our <a class="desktop-no-results-links" href="/explore/recommended">Explore section</a> to discover content based on your interests.
              </p>
            </ion-col>
            <ion-col size="6">
              <img src="@/assets/images/help-center-image.png" style="margin: 0 12px 0 0" class="desktop-no-results-img-2" />
              <p class="desktop-no-results-subheader" style="margin-top: 5%">
                Visit our <a class="desktop-no-results-links" href="/help-center">Help Center</a> to submit feedback or content suggestions.
              </p>
            </ion-col>
          </ion-row>
        </div>

        <!-- mobile platform no results section -->
        <div v-else class="ion-align-items-center">
          <ion-row>
            <ion-col>
              <h3 class="desktop-no-results-header">0 results found for {{ query }}</h3>
              <p class="desktop-no-results-subheader">Try a different keyword or a more generic search term</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <h3 class="desktop-no-results-section-title">Need help finding something?</h3>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <img src="@/assets/images/recommended-skills-footer.png" style="margin: 0 10px 0 0" class="desktop-no-results-img-1" />
              <p class="desktop-no-results-subheader">
                Browse our <a class="desktop-no-results-links" href="/explore/recommended">Explore section</a> to discover content based on your interests.
              </p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <img src="@/assets/images/help-center-image.png" style="margin: 0 10px 0 0" class="desktop-no-results-img-2" />
              <p class="desktop-no-results-subheader">
                Visit our <a class="desktop-no-results-links" href="/help-center">Help Center</a> to submit feedback or content suggestions.
              </p>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </div>
  </div>
</template>

<script>
import SearchSkeleton from '@/views/search/components/SearchSkeleton'
import UniversalCard from '@/components/organisms/UniversalCard'

export default {
  name: 'SearchItemContainer',
  components: { SearchSkeleton, UniversalCard },
  props: {
    dataReady: {
      type: Boolean,
      required: true,
      default: () => {
        return false
      }
    },
    data: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    filters: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    selectedFilters: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    sortBy: {
      type: String,
      require: false,
      default: () => {
        return ''
      }
    },
    query: {
      type: String,
      required: false,
      default: () => {
        return 'your query'
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: true,
      pageRange: 1,
      showTrackBar: false,
      tabName: 'search'
    }
  },
  computed: {},
  watch: {},
  created() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.pageRange = this.isDesktopPlatform ? 6 : 4
  },
  async mounted() {
    const elements = document.getElementsByClassName('pagination')[0].childNodes
    if (elements) {
      await elements.forEach((element) => {
        if (element.childNodes.length && element.className.includes('pagination')) {
          element.addEventListener('click', (e) => {
            let page = this.data.pageCurrent
            if (e.target.textContent != '…') {
              page = e.target.textContent == '‹' ? page - 1 : e.target.textContent == '›' ? page + 1 : parseInt(e.target.textContent)
              this.handlePageClick(page)
            }
          })
        }
      })
    }
  },
  updated() {},
  methods: {
    closeTrackBar() {
      this.showTrackBar = false
    },
    addToTrack() {
      this.showTrackBar = true
    },
    async handlePageClick(jump) {
      const payload = {
        page: jump,
        type: this.data.type,
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        sortBy: this.sortBy,
        filterType: ''
      }
      this.$emit('search', payload)
      this.$emit('scroll-search-top')
    }
  }
}
</script>
<style lang="scss">
.search-result-panel-mobile .search-result-pagination span {
  font-size: 14px;
  padding: 0px 2px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.pagination-item {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

li.pagination-nav {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.pagination-item-link {
  color: var(--ion-color-post-gray);
  font-size: 14px !important;
  vertical-align: middle;
}

.pagination-nav-link {
  position: relative;
  color: #5c5c5c;
  font-size: 3rem !important;
  vertical-align: middle;
  font-family: 'Futura PT Book';
}

li.disabled {
  border: none;
  cursor: default;
  .pagination-item-link {
    cursor: default;
  }
}

li.active {
  border: 2px solid #1dd1b0;
  border-radius: 4px;
  background: white;
  .pagination-item-link {
    color: #000000;
  }
}

li:hover {
  background: #e0e1e1;
}

li .tooltiptext {
  visibility: hidden;
  width: max-content;
  height: 30px;
  background-color: #efefef;
  color: #000000de;
  text-align: center;
  padding: 7px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -20px;
  font-size: 12px;
  font-family: 'Futura PT Demi';
}

.li:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 414px) {
  .pagination-item {
    margin: 0 2px;
    width: 32px;
    height: 32px;
  }
  li.pagination-nav {
    width: 32px;
    height: 32px;
  }
  .pagination-item-link {
    font-size: 12px;
  }
}
</style>
<style lang="scss" scoped>
.search-result-pagination {
  border-top: 2px solid #e0e1e1;
}

.ion-no-results-header {
  color: #1f3370;
  font-family: 'Futura PT Demi';
  font-size: 60px;
  letter-spacing: -0.03px;
}

.ion-no-results-sub {
  color: #5c5c5c;
  letter-spacing: 0.01px;
  font-size: 20px;
  font-family: 'Arial';
}

.pagination-icon {
  margin-bottom: -4px;
}

.desktop-no-results-header {
  font-size: 34px !important;
  font-family: 'Futura PT Demi' !important;
  color: black;
}

.desktop-no-results-subheader {
  font-size: 16px !important;
  font-family: 'Arial' !important;
  color: #5c5c5c;
}

.desktop-no-results-section-title {
  font-size: 24px !important;
  font-family: 'Futura PT Demi' !important;
  color: black;
}

.desktop-no-results-img-1 {
  float: left;
  height: 100px;
  width: 90px;
}

.desktop-no-results-img-2 {
  float: left;
  height: 100px;
  width: 90px;
}

.desktop-no-results-links {
  color: var(--ion-color-dark-blue);
  font-size: 16px !important;
  font-family: 'Arial' !important;
  font-weight: bold;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .ion-no-results-header {
    font-size: 40px;
    line-height: 38px;
  }
}
@media screen and (max-width: 767px) {
  .ion-no-results-header {
    font-size: 34px;
    line-height: 32px;
  }

  .ion-no-results-sub {
    font-size: 12px;
  }
}
.nav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000033;
  z-index: 98;
}
</style>
