var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ion-card", { ref: "profileSkills", staticClass: "profile-skills" }, [
        _c("div", { staticClass: "header-title" }, [_vm._v("My skills")]),
        _c("p", { staticClass: "skill-description" }, [
          _vm._v(
            "Skills in which you have some level of experience. These skills can be used by your company administrator to optimize your learning plan."
          )
        ]),
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "existing-skills-list" },
            [
              _vm.dataReady
                ? _c("SkillsList", {
                    key: _vm.skills.length,
                    attrs: { skills: _vm.skills, isSkillRemoveable: false }
                  })
                : _c("ion-spinner", { attrs: { color: "#1f3370" } })
            ],
            1
          ),
          _vm.isMe
            ? _c(
                "div",
                { staticClass: "search-wrapper" },
                [
                  _c("ion-searchbar", {
                    ref: "searchBar",
                    staticClass: "skills-search",
                    attrs: {
                      placeholder: "Add new skill",
                      debounce: _vm.$platform === "Mobile" ? "500" : "250"
                    },
                    on: { ionChange: _vm.handleSearchInput }
                  }),
                  _c(
                    "div",
                    { staticClass: "skills-list-container" },
                    [
                      _c(
                        "ion-scroll",
                        { staticClass: "scrollable" },
                        [
                          _vm.showDropDownList
                            ? _c(
                                "ion-list",
                                {
                                  staticClass: "search-skills-list",
                                  attrs: { lines: "none" }
                                },
                                _vm._l(_vm.searchedSkills, function(skill) {
                                  return _c(
                                    "ion-item",
                                    {
                                      key: skill.name,
                                      staticClass: "search-list-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.addSkillsToProfile(
                                            $event,
                                            skill
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(skill.name))]
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }