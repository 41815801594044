import { render, staticRenderFns } from "./DataTable.vue?vue&type=template&id=9c2f20aa&scoped=true&"
import script from "./DataTable.vue?vue&type=script&lang=js&"
export * from "./DataTable.vue?vue&type=script&lang=js&"
import style0 from "./DataTable.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DataTable.vue?vue&type=style&index=1&id=9c2f20aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c2f20aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/vtrinh004/Desktop/YTC/web/proedge-learn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9c2f20aa')) {
      api.createRecord('9c2f20aa', component.options)
    } else {
      api.reload('9c2f20aa', component.options)
    }
    module.hot.accept("./DataTable.vue?vue&type=template&id=9c2f20aa&scoped=true&", function () {
      api.rerender('9c2f20aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/components/DataTable.vue"
export default component.exports