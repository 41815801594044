const getters = {
  auth_token: state => state.user.auth_token,
  gcs_token: state => state.user.gcs_token,
  sso_token: state => state.user.sso_token,
  sso_bouncer: state => state.user.sso_bouncer,
  name: state => state.user.name,
  firstname: state => state.user.firstname,
  lastname: state => state.user.lastname,
  username: state => state.user.username,
  user_guid: state => state.user.user_guid,
  user_id: state => state.user.user_id,
  user_onboarded: state => state.user.user_onboarded,
  user_access: state => state.user.user_access,
  tenant: state => state.user.tenant,
  show_share: state => state.user.show_share,
  show_idea: state => state.user.show_idea,
  hide_rwl: state => state.user.hide_rwl,
  profile: state => state.user.profile,
  demoCapstoneCompleted: state => state.course.demoCapstoneCompleted,
  shareWelcomeMessageViewed: state => state.share.shareWelcomeMessageViewed,
  feature_tier: state => state.feature.tier,
  cloud_env: state => state.environment.cloud_env,
  config: state => state.environment.config,
  learning_locker_enabled: state => state.feature.learningLockerEnabled,
  allFeatureFlags: stage => stage.flag.features,
  enabledFeatureFlags: state => state.flag.enabled
}
export default getters
