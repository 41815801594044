<template>
  <svg-icon icon-class="awesome-question-circle" class-name="g-icon--xsmall g-margin__left--small g-clickable" :color="color" @click="openPopover()" />
</template>

<script>
import SolutionPopover from '@/components/molecules/SolutionPopover'
import { popoverController } from '@modus/ionic-vue'

export default {
  name: 'InfoPopover',
  components: {},
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {
        return {
          title: '',
          text: ''
        }
      }
    },
    color: {
      type: String,
      required: false,
      default: '#6C63FF'
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    async openPopover() {
      const popover = await popoverController.create({
        component: SolutionPopover,
        cssClass: 'popover-info',
        event: event,
        translucent: true,
        componentProps: {
          propsData: {
            content: this.content,
            color: this.color
          }
        }
      })
      return popover.present()
    }
  }
}
</script>

<style lang="scss" scoped></style>
