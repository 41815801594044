<template>
  <CredentialContent :content="bookmark" from-bookmark-list />
</template>

<script>
import CredentialContent from '@/views/credential/components/CredentialContent'

export default {
  name: 'CredentialSkillBuilderBookmark',
  components: { CredentialContent },
  props: {
    bookmark: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
