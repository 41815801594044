var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mylearning-tabs-container" },
    [
      this.$platform === "Desktop"
        ? _c("div", { staticClass: "tabs-container" }, [
            _c(
              "div",
              _vm._l(_vm.tabsObject, function(tab, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass: "tab accessible-button",
                    class: _vm.tabTitle == tab.title ? "selected" : "",
                    attrs: { "aria-label": tab.title },
                    on: {
                      click: function($event) {
                        return _vm.updateTabIndex(tab)
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(tab.displayTitle) + " "),
                    _c("span", [_vm._v(_vm._s(tab.count))])
                  ]
                )
              }),
              0
            ),
            _c(
              "a",
              {
                staticClass: "show-completed",
                attrs: { href: "#" },
                on: { click: _vm.showCompletedItems }
              },
              [_vm._v("Show completed learning")]
            )
          ])
        : _c("div", [
            _c("div", { staticClass: "mylearning-mobile-tabs-container" }, [
              _c(
                "div",
                { staticClass: "selected-tab", on: { click: _vm.toggleTabs } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.tabs[_vm.tabIndex].displayTitle) + " "
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.tabs[_vm.tabIndex].count))]),
                  _c("i", { staticClass: "dropdown-arrow" })
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "show-completed",
                  attrs: { href: "#" },
                  on: { click: _vm.showCompletedItems }
                },
                [_vm._v("Show completed")]
              )
            ])
          ]),
      _vm.showFilter
        ? _c(
            "div",
            { staticClass: "mylearning-filter" },
            [_vm._t("mylearning-item-filter")],
            2
          )
        : _vm._e(),
      _vm._t("mylearning-item-container"),
      _vm.tabsOpen && _vm.tabsObject.length > 1
        ? _c(
            "div",
            {
              staticClass: "overlay",
              on: {
                click: function($event) {
                  _vm.tabsOpen = false
                }
              }
            },
            [
              _c(
                "ul",
                { staticClass: "tabs-selection" },
                _vm._l(_vm.tabsObject, function(tab, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: _vm.tabTitle == tab.title ? "selected" : "",
                      attrs: { value: tab.title },
                      on: {
                        click: function($event) {
                          return _vm.updateTabIndex(tab)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(tab.displayTitle) + " "),
                      _c("span", [_vm._v(_vm._s(tab.count))])
                    ]
                  )
                }),
                0
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }