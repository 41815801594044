<template>
  <div class="main-layout">
    <!-- Side Menu Navigation Bar -->
    <div v-if="isSideMenuActive" class="nav-overlay" @click="toggleSideMenu"></div>
    <div class="slidermenu" ref="leftSideNav" style="outline: none" aria-label="side navigation">
      <transition name="slide">
        <NavBarMenuDesktop v-if="isSideMenuActive" :navBarMenu="navBarMenu" @toggle-side-menu="toggleSideMenu" />
      </transition>
    </div>

    <div v-if="this.$route.meta.logoOnly" class="logo-only-layout">
      <svg-icon icon-class="logo" class-name="logo--size" />
    </div>

    <div id="contentlayout" :class="isHeaderVisible ? 'section-content' : 'section-content landing'" @scroll="onScroll($event)" ref="contentSection">
      <!-- Top Navigation Bar -->
      <nav v-if="isHeaderVisible" aria-label="top nav" style="height: 62px">
        <NavBarDesktop :displayFullNavBar="isHeaderVisible" :hideRWLTenant="hideRWLTenant" @on-search="onSearch" @toggle-side-menu="toggleSideMenu" />
      </nav>

      <!-- Main Content -->
      <main :class="isRightSideMenuVisible ? 'main-content-part' : 'main-content-part centered'" ref="mainContent" style="outline: none">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <router-view :key="$route.fullPath" />
          </keep-alive>
        </transition>
      </main>

      <!-- Footer -->
      <footer
        class="footer"
        v-if="
          this.$route.fullPath !== '/landing' &&
          this.$route.fullPath !== '/help-center-main' &&
          this.$route.fullPath !== '/onboarding/terms-and-conditions' &&
          this.$route.fullPath !== '/onboarding/privacy-policy' &&
          this.$route.fullPath !== '/onboarding/cookie-notice' &&
          this.$route.meta.isMenuPrivacy !== true &&
          this.$route.meta.isMenuTerms !== true
        "
        style="text-align: left; padding: 0 3rem"
      >
        <span class="copyright-text">
          © {{ currentYear }} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.
        </span>
        <span @click="openPrivacy()" class="copyright-link">
          <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Privacy Policy</a>
        </span>
        <span @click="openCookieNotice()" class="copyright-link">
          <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Cookie Notice</a>
        </span>
        <span @click="openTerms()" class="copyright-link">
          <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Terms and Conditions</a>
        </span>
      </footer>
      <ion-footer v-else-if="this.$route.meta.isMenuPrivacy === true || this.$route.meta.isMenuTerms === true"></ion-footer>
      <ion-footer id="footer" v-else>
        <ion-row>
          <ion-col size="7" class="d-flex copyright-text"
            >© {{ currentYear }} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.
          </ion-col>
          <ion-col size="1" @click="$router.push('/help-center-main').catch(() => {})" style="cursor: pointer">
            <a href="#" class="accessible-link">Help Center</a>
          </ion-col>
          <ion-col size="1" @click="openPrivacy()" style="cursor: pointer">
            <a href="#" class="accessible-link">Privacy Policy</a>
          </ion-col>
          <ion-col size="1" @click="openCookieNotice()" style="cursor: pointer">
            <a href="#" class="accessible-link">Cookie Notice</a>
          </ion-col>
          <ion-col size="2" @click="openTerms()" style="cursor: pointer">
            <a href="#" class="accessible-link">Terms and Conditions</a>
          </ion-col>
        </ion-row>
      </ion-footer>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'
import NavBarDesktop from '@/components/molecules/NavBarDesktop'
import NavBarMenuDesktop from '@/components/organisms/NavBarMenuDesktop'

export default {
  name: 'LayoutDesktop',
  props: {},
  components: {
    NavBarDesktop,
    NavBarMenuDesktop
  },
  data() {
    return {
      vPos: 0,
      hidden: false,
      isSkills: false,
      currentYear: '',
      isSideMenuActive: false,
      isRightSideMenuVisible: false,
      isHeaderVisible: false,
      hideRWLTenant: false,
      navBarMenu: {},
      isNewVersionAvailable: false
    }
  },
  watch: {
    $route(to, from) {
      this.$nextTick(function () {
        this.$refs.mainContent.focus()
      })
      if (to.path.includes('home') && from.path.includes('privacy-policy') || from.path.includes('cookie-notice')) {
        this.menuVisibility()
      }
    }
  },
  computed: {
    ...mapGetters(['name', 'tenant', 'show_share', 'show_idea', 'hide_rwl'])
  },
  beforeMount() {
    const d = new Date()
    this.currentYear = d.getFullYear()
    const landingPath =
      ['/landing'].includes(this.$route.path) || ['/landing'].includes((this.$router.history && this.$router.history.pending && this.$router.history.pending.path) || [])
    if (this.$route.path === '/layout' || (landingPath && JSON.parse(localStorage.getItem('isLoggedIn')))) {
      this.$router.push('/home').catch(() => {})
    }
  },
  async mounted() {
    const tenant = this.tenant || (await localforage.getItem('my_tenant'))
    this.hidden = tenant && tenant.toLowerCase() === 'c1'
    const hideRWL = this.hide_rwl || (await localforage.getItem('hide_rwl'))
    this.getNavBarItems()
    if (hideRWL) {
      this.navBarMenu.links.splice(2, 1)
      this.hideRWLTenant = true
    }
    this.menuVisibility()
    EventBus.$on('new-version', this.onNewVersion)
  },
  methods: {
    async getNavBarItems() {
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      const showLearningHistory = enabledFeatureFlags['learn_learning_history_enabled'] || false
      this.navBarMenu = {
        links: [
          { name: 'Home', link: '/home', subLinks: [] },
          { name: 'My learning', link: '/mylearning', subLinks: [] },
          { name: 'Explore', link: '/explore', subLinks: [] },
          {
            name: 'My profile',
            link: '/profile',
            subLinks: [
              { name: 'Learning history', link: 'learningHistory', show: showLearningHistory },
              { name: 'Skills', link: 'skills', show: true },
              { name: 'Profile details', link: 'profileDetails', show: true }
            ]
          }
        ]
      }
    },
    toggleSideMenu() {
      this.isSideMenuActive = !this.isSideMenuActive
      // focus to side nav if it is activated
      if (this.isSideMenuActive) {
        this.$nextTick(function () {
          this.$refs.leftSideNav.focus()
        })
      }
    },
    menuVisibility() {
      this.isRightSideMenuVisible = this.$route.meta.desktopSideMenuVisible
        ? true
        : this.$router.history.pending && this.$router.history.pending.meta && this.$router.history.pending.meta.desktopSideMenuVisible
        ? true
        : false
      this.isHeaderVisible = this.$route.meta.desktopHeaderVisible
        ? true
        : this.$router.history.pending && this.$router.history.pending.meta && this.$router.history.pending.meta.desktopHeaderVisible
        ? true
        : false
    },
    openPrivacy() {
      window.open('/onboarding/privacy-policy', '_blank')
    },
    openCookieNotice() {
      window.open('/onboarding/cookie-notice', '_blank')
    },
    openTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use', '_blank')
    },
    onScroll(event) {
      this.vPos = event.target.scrollTop
      Vue.prototype.$verticalPosition = event.target.scrollTop === 0 ? Vue.prototype.$verticalPosition : event.target.scrollTop
      const scrollData = {
        scrollTop: this.$refs.contentSection.scrollTop,
        scrollHeight: this.$refs.contentSection.scrollHeight,
        clientHeight: this.$refs.contentSection.clientHeight
      }
      EventBus.$emit('scroll', scrollData)
    },
    onSearch(keyword) {
      this.$route.path.match(/\/idea/) == '/idea' ? this.$router.push({ name: 'IdeaHome', query: { q: keyword } }) : this.$router.push({ name: 'ShareHome', query: { q: keyword } })
    },
    onNewVersion() {
      this.isNewVersionAvailable = true
    }
  }
}
</script>

<style lang="scss" scoped>
NavBarDesktop {
  position: fixed !important;
  top: 0;
}

.main {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;

  .content {
    flex: 4;
    background: map-get($edge-colors, 'disabled');
    height: 100%;
    padding-bottom: 15rem;
    overflow: auto;
  }
  aside {
    background: map-get($edge-colors, 'disabled');
    flex: 1;
  }
}

.router-link {
  color: map-get($edge-colors, 'tertiary');
  font-weight: bold;
}

#footer {
  position: fixed;
  z-index: 3000;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: map-get($edge-colors, 'white');
  color: map-get($edge-colors, 'black');
  padding: 12px 30px;
  font-size: 14px;

  ion-col {
    font-family: 'Futura PT Demi';
    color: #1f3370;
    &.d-flex {
      text-align: left;
    }
  }

  .logo {
    float: left;
    margin-right: 10px;
    height: 20px;
  }
}

.logo-only-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #efefef;
  // box-shadow: 3px 3px 1px #eaeaea;
  text-align: left;
  padding-left: 40px;
  z-index: 100;
}

svg.svg-icon.logo--size {
  display: inline-block;
  position: relative;
  margin: auto;
  padding: auto;
  margin: 14px 0 14px 0;
  height: 30px;
  width: 114px;
  padding-left: 3px;
}

.slide-menu-enter,
.slide-menu-leave-to {
  transform: translateX(-300px);
}

.slide-menu-enter-active,
.slide-menu-leave-active {
  transition: all 0.5s ease;
}

.search-nav {
  z-index: 99999;
}

.main-layout {
  display: flex;
  height: 100%;
  [class*='section'] {
    height: 100%;
    flex: 1 2;
  }
  .section {
    height: 100%;
    &.left-nav {
      min-width: 235px;
      max-width: 235px;
      position: relative;
      min-height: 600px;
    }
  }

  .section-content {
    overflow-y: scroll;
    display: flex;
    background: #efefef;
    flex-wrap: wrap;
    &.landing {
      background: #fff;
    }

    nav {
      position: sticky;
      top: 0;
      width: 100%;
      background: #fff;
      z-index: 95;
      // box-shadow: 1px 3px #e8e8e8;
      border-bottom: 1px solid #ccc;
    }
    .main-content-part {
      min-height: calc(100vh - 120px);
      padding: 0 2%;
      width: 100%;
    }
  }

  footer.footer {
    min-height: 40px;
    background: transparent;
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }

  .section + .section {
    margin-left: 2%;
  }
}

.main-layout .section-content .main-content-part {
  &.centered {
    padding: 3% 0 6%;
    max-width: 100%;
    background: #fff;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.refresh-panel {
  position: fixed;
  padding: 0.2rem 0rem;
  width: 450px;
  right: 3rem;
  bottom: 3rem;
  z-index: 4040;
  border-radius: 12px;
  border: 2px solid var(--ion-color-tertiary);
  text-align: center;
  background: var(--ion-color-white);
}

.prompt {
  margin: auto;
  font-size: 1rem;
  font-weight: 500;
}

.refresh-button {
  font-family: 'Futura PT Demi';
}

.close-refresh-button {
  position: relative;
  top: 12px;
}

.landing,
nav {
  display: block !important;
}

.copyright-text {
  font-family: Arial;
  font-size: 14px;
  color: var(--ion-color-medium);
}

.accessible-link {
  color: unset;
  text-decoration: unset;
  background-color: unset;
}

.slidermenu {
  position: fixed;
  z-index: 102;
  width: 340px;
}

/* animation for left slider nav */
.slide-enter-active,
.slide-leave-active {
  transition: all 400ms ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 300ms ease-in 0s;
}

/* backdrop for left slider nav */
.nav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000033;
  z-index: 101;
  cursor: pointer;
}

.copyright-link {
  font-family: Arial;
  font-size: 16px;
  color: var(--ion-color-tertiary);
  padding: 0 0 0 2rem;
  a {
    font-size: 15px !important;
  }
}
</style>
