var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showConfirmationLaunch || _vm.showConfirmationUnavailable
          ? _c("div", { staticClass: "a-overlay" })
          : _vm._e()
      ]),
      _vm.showConfirmationLaunch
        ? _c("div", { staticClass: "b-modal confirm-box" }, [
            _c("h3", { staticClass: "title" }, [
              _vm._v("Launch 15-Day Virtual Desktop Session?")
            ]),
            _c("p", [
              _vm._v(
                "Your virtual desktop will be available for 15 days from the time that you launch it."
              )
            ]),
            _c(
              "div",
              { staticClass: "handler" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass: "g-button--primary ion-button-primary",
                    attrs: { "data-cy": "confirm-launch", color: "primary" },
                    on: { click: _vm.confirmMyLaunch }
                  },
                  [_vm._v("Launch Virtual Desktop")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function() {
                        this$1.showConfirmationLaunch = false
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.showConfirmationUnavailable
        ? _c("div", { staticClass: "b-modal confirm-box" }, [
            _c("h3", { staticClass: "title" }, [
              _vm._v("No desktops available")
            ]),
            _c("p", [
              _vm._v(
                "It looks like all available desktops are in use, please check back shortly."
              )
            ]),
            _c(
              "div",
              { staticClass: "handler" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass: "g-button--primary ion-button-primary",
                    attrs: { "data-cy": "confirm-launch", color: "primary" },
                    on: {
                      click: function() {
                        this$1.showConfirmationUnavailable = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.isDesktopPlatform
        ? _c(
            "ion-card",
            { staticClass: "ion-no-margin overview" },
            [
              _c(
                "ion-grid",
                { staticClass: "ion-no-padding" },
                [
                  _c(
                    "ion-row",
                    [
                      _c(
                        "ion-col",
                        { staticClass: "ion-no-padding" },
                        [
                          _c("img", {
                            staticClass: "overview__img",
                            attrs: { src: _vm.course.image.uri }
                          }),
                          _c("svg-icon", {
                            attrs: {
                              "icon-class": "close",
                              "class-name": "g-icon--small overview__close",
                              color: "#182755"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$router.go(-1)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "ion-row",
                    {
                      staticClass:
                        "g-padding__right--small g-padding__left--medium g-padding__top--small"
                    },
                    [
                      _c("ion-col", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "ion-text-start ion-no-margin g-type-title overview__course_title g-margin__bottom--small ellipsis-lines-2"
                          },
                          [_vm._v(" " + _vm._s(_vm.course.title) + " ")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "ion-text-start ion-no-margin overview__metadata g-margin__bottom--small"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDuration")(_vm.course.duration)
                                ) +
                                " "
                            ),
                            _vm.cpeWorthy
                              ? _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "icon-green-check",
                                    "class-name": "g-icon--small check-icon"
                                  }
                                })
                              : _vm._e(),
                            _vm.cpeWorthy
                              ? _c("span", [_vm._v("CPE - Worthy")])
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "ion-text-start ion-no-margin overview__subtitle g-margin__bottom--small"
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$sanitize(_vm.course.synopsis)
                                )
                              }
                            }),
                            !_vm.isAccessible
                              ? _c(
                                  "span",
                                  [
                                    _c("InfoPopover", {
                                      attrs: {
                                        content:
                                          _vm.popoverContent
                                            .credentialInaccessible,
                                        color: "#DD5217"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.isScormCloudCredential
                    ? _c(
                        "ion-row",
                        {
                          staticClass:
                            "ion-padding ion-text-start ion-align-items-center"
                        },
                        [
                          _c(
                            "ion-col",
                            [
                              _c(
                                "ion-button",
                                {
                                  staticClass:
                                    "g-button--primary ion-button-primary overview__continue-btn",
                                  attrs: { shape: "round", color: "primary" },
                                  on: { click: _vm.continueLesson }
                                },
                                [_vm._v("Continue ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _c(
            "ion-card",
            [
              _c(
                "ion-grid",
                { staticClass: "ion-no-padding" },
                [
                  _c(
                    "ion-row",
                    {
                      staticClass:
                        "g-padding__right--small g-padding__left--medium g-padding__top--small g-padding__bottom--small"
                    },
                    [
                      _c(
                        "ion-col",
                        {
                          staticClass: "g-padding__right--small",
                          attrs: { size: "7" }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ion-text-start ion-no-margin g-type-title overview__course_title g-margin__bottom--small ellipsis-lines-2"
                            },
                            [_vm._v(" " + _vm._s(_vm.course.title) + " ")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "ion-text-start ion-no-margin overview__metadata g-margin__bottom--small"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDuration")(
                                      _vm.course.duration
                                    )
                                  ) +
                                  " "
                              ),
                              _vm.cpeWorthy
                                ? _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "icon-green-check",
                                      "class-name": "g-icon--small check-icon"
                                    }
                                  })
                                : _vm._e(),
                              _vm.cpeWorthy
                                ? _c("span", [_vm._v("CPE - Worthy")])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "ion-text-start ion-no-margin overview__subtitle g-margin__bottom--small"
                            },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$sanitize(_vm.course.synopsis)
                                  )
                                }
                              }),
                              !_vm.isAccessible
                                ? _c(
                                    "span",
                                    [
                                      _c("InfoPopover", {
                                        attrs: {
                                          content:
                                            _vm.popoverContent
                                              .credentialInaccessible,
                                          color: "#DD5217"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c("ion-col", { attrs: { size: "5" } }, [
                        _vm.course.image.uri
                          ? _c("img", {
                              staticClass: "overview__img-desktop",
                              attrs: { src: _vm.course.image.uri }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/thumbnail-complete-credential.png")
                              }
                            })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "ion-row",
                    {
                      staticClass:
                        "ion-padding ion-text-start ion-align-items-center"
                    },
                    [
                      _c(
                        "ion-col",
                        [
                          _vm.isScormCloudCredential
                            ? _c(
                                "ion-button",
                                {
                                  staticClass:
                                    "g-button--primary ion-button-primary overview__continue-btn",
                                  attrs: { shape: "round", color: "primary" },
                                  on: { click: _vm.continueLesson }
                                },
                                [_vm._v("Continue ")]
                              )
                            : _vm._e(),
                          _vm.course.vdiEnabledForUser &&
                          _vm.capstoneProgress !== 100
                            ? _c(
                                "div",
                                { staticStyle: { display: "inline" } },
                                [
                                  _vm.canLaunchWorkspace
                                    ? _c(
                                        "span",
                                        {
                                          key: _vm.vdiP,
                                          staticClass: "overview__vdi-text",
                                          attrs: {
                                            disabled: _vm.isLaunchingWorkspace
                                          },
                                          on: {
                                            click: _vm.intentLaunchWorkspace
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.launchWorkspaceBtnTxt
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }