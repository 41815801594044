import localforage from 'localforage'

const state = {
  loading: false,
  features: {},
  enabled: {}
}

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading
  },
  SET_FEATURES: (state, features) => {
    state.features = features
  },
  SET_ENABLED: (state, enabled) => {
    state.enabled = enabled
  },
}

const actions = {
  async setFeatureFlags({ commit }, data) {
    commit('SET_LOADING', true)
    try {
      let features = {}
      const enabled = data.featureFlags.reduce((result, feature) => {
        result[feature.name] = feature.enabled
        for (const strategy of feature.strategies) {
          if (!data.strategyProviders[strategy.name]) continue
          if (!data.strategyProviders[strategy.name](strategy.parameters)) result[feature.name] = false
        }
        return result
      }, {})
      for (const feature of data.featureFlags) {
        features[feature.name] = feature
      }
      commit('SET_ENABLED', enabled)
      commit('SET_FEATURES', features)
      await localforage.setItem('enabled-feature-flags', enabled).then(() => { })
    } catch (err) {
      console.error('Unable to get the feature flags: ', err)
    } finally {
      commit('SET_LOADING', false)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
