var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-content" },
    [
      _c(
        "ion-card",
        {
          class: _vm.isDesktopPlatform
            ? "content-padding-desktop"
            : "content-padding-mobile"
        },
        [
          _c("ContentScrollToTop"),
          _vm.media.url && _vm.media.type === "video"
            ? _c(
                "video",
                {
                  class: _vm.isDesktopPlatform ? "media-desktop" : "",
                  attrs: {
                    id: "video-content",
                    poster: _vm.content.imageUri,
                    controls: ""
                  }
                },
                [
                  _c("source", {
                    attrs: {
                      src: _vm.media.url + "#t=0.2",
                      type: _vm.media.videoType
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm.media.type === "audio" || _vm.media.type === "podcast"
            ? _c(
                "audio",
                {
                  staticStyle: { width: "-webkit-fill-available" },
                  attrs: {
                    id: "audio-content",
                    controls: "",
                    src: _vm.media.url
                  }
                },
                [
                  _vm._v(" Your browser does not support the "),
                  _c("code", [_vm._v("audio")]),
                  _vm._v(" element. ")
                ]
              )
            : _vm._e(),
          _vm.content.format.name === "article"
            ? _c("ContentFontSizeController", {
                attrs: { className: "g-type-body-1" }
              })
            : _vm._e(),
          _vm.content.text
            ? _c("ion-card-content", {
                directives: [
                  { name: "links-in-new-tab", rawName: "v-links-in-new-tab" }
                ],
                staticClass: "g-type-body-1 ion-text-start text",
                domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.content.text)) }
              })
            : _vm._e(),
          !_vm.showRating
            ? _c("ContentComplete", {
                attrs: { label: "3" },
                on: { "on-complete": _vm.completeContent }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }