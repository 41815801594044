export default {
  getPicture(width, height) {
    return `https://picsum.photos/seed/${Math.random() * 123456789}/${width}/${height}`
  },

  removeSlug(slug) {
    return slug.replace('-', ' ')
  },

  encodeDecodeString(s) {
    if (!s) return
    s = s.toString().replace(/%/g, '')
    s = s.toString().replace(/&amp;/g, '%26')
    s = s.toString().replace(/amp;/g, '')
    s = s.toString().replace(/&#39;/g, '\'')
    s = s.toString().replace(/&rsquo;/g, '\'')
    return decodeURIComponent(s)
  },
  formatDate(value) {
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: 'numeric' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return `${day} ${month} ${year}`;
  },
  formatDateWithTime(d) {
    const date = new Date(d)
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }
    return date.toLocaleDateString('default', options)
  },

}
