<template>
  <div v-if="dataReady" class="edge-onboarding">
    <img :src="require('@/assets/images/onboarding_welcome.png')" :class="$platform === 'Desktop' ? 'footer-image-desktop' : 'footer-image-mobile'" alt="" />
    <div class="wrapper">
      <h2 class="title g-type-xlarge">Hello {{ user.name }}!</h2>
      <h3 class="g-type-large" style="font-family: 'Futura PT Demi'">Welcome to ProEdge.</h3>
      <p>We've looked at your past experiences, current skills, and market trends to tailor a personalized learning pathway…</p>
      <div class="role">
        <div
          :class="[
            'centered',
            user.role.length <= 10
              ? 'role-short-title'
              : user.role.length <= 22
              ? 'role-medium-title'
              : user.role.length <= 34
              ? 'role-long-title'
              : user.role.length <= 46
              ? 'role-xlong-title'
              : 'role-xxlong-title'
          ]"
        >
          {{ user.role }}
        </div>
        <img :src="require('@/assets/images/onboarding_path_sign.png')" alt="" />
      </div>
      <p>Your target skills:</p>
      <div class="scrollable-content skills-list" v-if="user.skills">
        <SkillsButtonGroup :skills="user.skills" :key="user.skills.length" :from="'welcome-page'" :displaySkillDetails="false"></SkillsButtonGroup>
      </div>
      <p style="font-size: 0.8rem">*You can continue curating your content by editing work experience and skills in your profile</p>
      <div class="footer-wrapper">
        <ion-button
          data-cy="signup-get-started"
          @click="onGetStarted"
          shape="round"
          color="primary"
          class="ion-button-primary"
          style="font-family: 'Futura PT Demi'; text-transform: capitalize; --box-shadow: none"
          >Get Started</ion-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import SkillsButtonGroup from '@/components/organisms/SkillsButtonGroup.vue'
import util from '@/utils/helper'

export default {
  name: 'Welcome',
  components: { SkillsButtonGroup },
  props: {},
  data() {
    return {
      dataReady: false,
      token: '',
      user: {
        name: '',
        role: '',
        skills: []
      }
    }
  },
  computed: {
    ...mapGetters(['firstname', 'auth_token'])
  },
  async created() {
    this.token = this.auth_token || (await localforage.getItem('my_access_token'))
    this.user.name = this.firstname || (await localforage.getItem('my_firstname'))
    await this.getUserOnboardingInfo()
    this.dataReady = true
  },
  mounted() {
    analytics.setVarAndTrack({ c: 'onboarding', a: 'loaded', l: 'welcome' })
  },
  methods: {
    async getUserOnboardingInfo() {
      let profile = await this.$learnAdmin.getUserProfile({ token: this.token })
      let role = await this.$learnAdmin.getExternalRoleById({ token: this.token, id: profile.currentRole.guid })
      this.user.role = role.name ? util.encodeDecodeString(role.name) : util.encodeDecodeString(profile.title)
      this.user.skills = role.skills && role.skills.length > 10 ? role.skills.slice(0, 10) : role.skills
    },
    async onGetStarted() {
      await this.logUserGetStarted()
      analytics.setVarAndTrack({ c: 'onboarding', a: 'click', l: 'get-started' })
      const dlId = await localforage.getItem('deep_link_id')
      const dltp = await localforage.getItem('deep_link_type')
      const dlsq = await localforage.getItem('deep_link_search_query')
      const dlst = await localforage.getItem('deep_link_search_type')
      const dlmlt = await localforage.getItem('deep_link_my_learning_type')
      dlId && dlId.length && dltp && dltp.length
        ? this.$router.push(`/${dltp}/${dlId}`)
        : dlsq && dlsq.length
        ? this.$router.push(`/search?q=${dlsq}`)
        : dlst && dlst.length
        ? this.$router.push(`/search?q=&type=${dlst}`)
        : dlmlt && dlmlt.length
        ? this.$router.push({ name: 'MyLearning', params: { type: dlmlt } })
        : this.$router.push('/home')
    },
    async logUserGetStarted() {
      this.learningRecord = {
        verb: {
          id: window.location.href,
          display: { 'en-US': 'completed' }
        },
        object: {
          id: window.location.href,
          definition: {
            name: { 'en-US': 'Learn App Mobile Onboarding' }
          }
        },
        result: {
          completion: true,
          success: true,
          score: {
            scaled: 1
          }
        }
      }
      learningAnalytics.setLearningRecordAndSend(this.learningRecord)
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('edge-onboarding') {
  background: var(--ion-color-light-gray);
  height: 100%;

  @media only screen and (device-height: 568px) {
    height: calc(100% + 280px);
  }

  @media only screen and (device-height: 667px), only screen and (device-height: 640px) {
    height: calc(100% + 150px);
  }

  .wrapper {
    padding: 0% 8%;
    text-align: left;
    h2 {
      margin: 0rem !important;
      padding-top: 1rem;
    }
    h3 {
      margin: 0rem !important;
      padding: 0.25rem 0rem 0.5rem 0rem;
    }
  }

  .title {
    color: var(--ion-color-tertiary);
    padding-top: 1rem;
  }

  p {
    margin: 0rem !important;
    font-size: 1.2rem;
    color: var(--ion-color-tertiary);
  }

  .role {
    font-size: 1.5rem;
    color: var(--ion-color-tertiary);
    text-align: center;
    font-family: 'Futura PT Demi';
    width: 300px;
    max-height: 200px;
    position: relative;
  }
  .centered {
    text-align: center;
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 84px;
    max-width: 200px;
  }
  .role-desktop {
    top: 215px;
    left: 110px;
  }

  .role-mobile {
    top: 215px;
    left: 70px;

    @media only screen and (device-height: 568px) {
      height: 75px;
      top: 235px;
      left: 60px;
    }
    @media only screen and (device-height: 1024px) {
      top: 200px;
      left: 90px;
    }
    @media only screen and (device-height: 1366px) {
      top: 180px;
      left: 120px;
    }
  }

  .role-short-title {
    font-size: 1.5rem;
  }

  .role-medium-title {
    font-size: 1.4rem;
  }

  .role-long-title {
    font-size: 1.3rem;
  }

  .role-xlong-title {
    font-size: 1.2rem;
  }

  .role-xxlong-title {
    font-size: 1rem;
  }

  .footer-wrapper {
    text-align: center;
    margin: 1rem 0rem;
  }

  .footer-image-mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    object-fit: cover;
    @media only screen and (device-height: 568px) {
      bottom: -280px;
    }

    @media only screen and (device-height: 667px), only screen and (device-height: 640px) {
      bottom: -150px;
    }
  }

  .footer-image-desktop {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 250px;
  }
  .skills-list {
    height: 100px !important;
    margin: 15px 15px 15px 0;
    z-index: 100;
    padding: 0 0 0 20px;
    position: relative;

    @media only screen and (device-height: 568px) {
      height: 100px;
    }

    @media only screen and (device-height: 667px), only screen and (device-height: 640px) {
      height: 100px;
    }
  }
}
</style>
