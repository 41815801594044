<template>
  <div class="carousel-style">
    <VueSlickCarousel v-bind="settings" class="edge-achievements">
      <slot></slot>
      <template #prevArrow="arrowOption">
        <button class="custom-arrow" title="Previous" @mousedown.prevent @click="removeOutline($event)" style="margin-left: -8px">
          <svg-icon icon-class="carousel-left-arrow" style="height: 16px; width: 16px; margin-left: 2px"></svg-icon>
          <span style="display: none;"> {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }} </span>
        </button>
      </template>
      <template #nextArrow="arrowOption">
        <button class="custom-arrow" title="Next" @mousedown.prevent @click="removeOutline($event)" style="margin-right: -8px">
          <svg-icon icon-class="carousel-right-arrow" style="height: 16px; width: 16px; margin-left: 4px"></svg-icon>
          <span style="display: none;"> {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }} </span>
        </button>
      </template>
      <template #customPaging="page">
        <button class="custom-dot" @mousedown.prevent @click="removeOutline($event)" v-bind:aria-label="'Go to slide ' + (page + 1)">
          {{ page }}
        </button>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'CarouselDesktop',
  props: {
    slidesShow: { type: Number, required: false, default: 5 },
    responsive: { type: Boolean, required: false, default: true }
  },
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        arrows: true,
        dotsClass: 'slick-dots',
        infinite: false,
        speed: 500,
        slidesToShow: this.slidesShow,
        slidesToScroll: this.slidesShow,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1680,
            settings: {
              dots: true,
              infinite: false,
              slidesToShow: this.responsive ? 3 : this.slidesShow,
              slidesToScroll: this.responsive ? 3 : this.slidesShow
            }
          },
          {
            breakpoint: 1145,
            settings: {
              dots: true,
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      }
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    //To remove outline when the button is clicked with mouse
    removeOutline(event) {
      if (event.button === 0) {
        event.currentTarget.blur()
      }
    }
  }
}
</script>

<style lang="scss">
.slick-dots {
  bottom: var(--bottom) !important;
  li {
    button::before {
      content: '';
    }
    button:focus {
      outline: 2px solid black;
    }
    width: 10px !important;
    height: 10px !important;
    margin: 0 20px 0 0 !important;
  }
}
.edge-achievements {
  .slick-list {
    padding-bottom: 3rem;
  }
}
</style>

<style lang="scss" scoped>
@include block('edge-achievements') {
  background-color: transparent;

  ion-card {
    outline: none;
    width: unset;
  }

  .custom-arrow {
    background-color: #dddddd;
    height: 90px;
    width: 24px;
    border-radius: 9px;
    &.slick-disabled {
      display: none !important;
    }
  }

  .custom-arrow:hover {
    background-color: var(--ion-color-secondary-dark-yellow);
  }
  .custom-arrow:focus {
    outline: 2px solid black;
  }

  .slick-dots {
    z-index: 1000;
    position: relative !important;

    .custom-dot {
      font-size: 5px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: var(--ion-color-mid-blue);
      color: var(--ion-color-mid-blue);
    }

    .slick-active {
      .custom-dot {
        background: var(--ion-color-tertiary);
        color: var(--ion-color-tertiary);
      }
    }
  }
}

.recommend-content,
.recommend-home-content {
  .carousel-style {
    width: 1566px;
    margin: auto;
    @media screen and (max-width: 1680px) {
      width: 1174px;
      margin: auto;
    }
    @media screen and (max-width: 1145px) {
      width: 784px;
      margin: auto;
    }
  }
}
</style>
