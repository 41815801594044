<template>
  <div ref="top" :class="isDesktopPlatform ? 'desktop-background track-content' : 'non-white-background'">
     <div v-if="!dataReady" >
      <TrackSkeleton/>
    </div>
    <div v-if="dataReady">
      <ion-grid v-if="isContentAvailable" class="ion-no-padding ion-padding-bottom" :class="isDesktopPlatform ? 'desktop-collection-container' : 'mobile-collection-container'">
        <ion-row>
          <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
            <div class="back" @click="$router.go(-1)">
              <button class="accessible-button" aria-label="back button">
                <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
              </button>
              Back
            </div>
            <ion-row class="collection-info">
              <ion-col class="collection-img-shape">
                <img v-if="collection.image.signedRelativeUri" :src="collection.image.signedRelativeUri" />
                <img v-else src="@/assets/images/user-generated-track.png" />
              </ion-col>
              <ion-col>
                <div class="meta">
                  <div class="format">{{ collection.format.name }}</div>
                </div>
                <div class="title">{{ collection.title }}</div>
                <div class="meta-info">
                  <div v-if="collection.duration" class="duration">{{ collection.duration }}</div>
                  <div class="content-count">
                    <svg-icon icon-class="track-list" />
                    <div class="content-count-title">{{ collection.content && collection.content.length }} items</div>
                  </div>
                  <ion-row class="ion-align-items-center ion-no-padding track-content-just-you">
                    <ion-col size="4">
                      <svg-icon icon-class="icon-eye" />
                    </ion-col>
                    <ion-col class="content-text"> JUST YOU </ion-col>
                  </ion-row>
                </div>
                <div class="meta-action">
                  <div class="more-container">
                    <div class="more-action" @click="popoverOn('more')"><svg-icon icon-class="more-action" class-name="g-icon--small g-clickable" /></div>
                    <MoreActionPopover
                      v-if="isMoreOpen"
                      class="more-popover"
                      :item="collection"
                      :assetId="collectionId"
                      :assetType="collection.format.name"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      :editTrack="true"
                      :deleteTrack="true"
                      size="lg"
                      fromPage="Universal card"
                      @edit-delete-track-content="editDeleteTrackContent"
                      @add-to-track="popoverOff('more')"
                      @close-more="popoverOff('more')"
                    />
                    <span v-if="!isMoreOpen" class="more-tooltip-text">More</span>
                  </div>
                  <div v-if="completedItems.length > 0 && completedItems.length != totalItems.length" class="flex-container">
                    <radial-progress-bar
                          :diameter="28"
                          :completed-steps="completedItems.length"
                          :total-steps="totalItems.length"
                          :startColor="`#000000`"
                          :stopColor="`#000000`"
                          :innerStrokeColor="`#e0e1e1`"
                          :strokeWidth="4"
                          :innerStrokeWidth="5"
                        >
                      </radial-progress-bar>
                      <div class="flex-percentage">{{`${completedItems.length} of ${totalItems.length} items completed`}}</div> 
                  </div>
                  <div v-else-if="completedItems.length > 0 && completedItems.length == totalItems.length" class="progress-track">
                    <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                    <span style="font: normal normal normal 14px/16px Arial;color:#2D2D2D;">{{`${completedItems.length} of ${totalItems.length} items completed`}}</span>
                  </div>
                  <!-- <SocialShare class="social-share" :assetId="collectionId" :routeInfo="getRouteInfo()" size="lg" /> -->
                  <div class="desktop-progress">
                    <div v-if="collection.progress > 0 && collection.progress < 100" class="progress">
                      <svg-icon icon-class="progress-icon" />
                      <span
                        >{{ collection.progress }}% completed <span style="font-family: Arial; font-weight: 100">({{ updateTime }})</span></span
                      >
                    </div>
                    <div v-if="collection.completed || collection.progress == 100" class="progress">
                      <svg-icon icon-class="icon-progress-complete" />
                      <span>completed {{ onDate }}</span>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <div class="collection-about-container">
          <ion-row class="carouselDesktop">
            <ion-col size="8.5" style="margin-right: 2rem">
              <UGCollectionAbout :collection="collection" @on-delete-external-item="onDeleteExternalItem" />
              <!-- <ItemRating :item="collection" :scroll="scroll" :getUpdatedItemData="getUpdatedCollectionData" /> -->
              <UGTrackFooter />
            </ion-col>
            <ion-col v-if="isMetaDataVisible" size="3">
              <!-- <ItemMetaData :item="collection" /> -->
              <ion-row v-if="collection.skills && collection.skills.length > 0" class="skills-row">
                <ion-col size="4" style="padding-top: 8px">
                  <span class="skills-title">Skills</span>
                </ion-col>
                <ion-col class="ion-text-end">
                  <div class="meta-skills">
                    <div v-for="skill in collection.skills" :key="skill.guid">
                      <span class="skill-pill ellipsis-lines-1" @click="onSkillClick(skill.guid)">{{ skill.guid }}</span>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row class="carouselMobile">
            <ion-col v-if="isMetaDataVisible" size="12">
              <!-- <ItemMetaData :item="collection">
              <ItemRating :item="collection" :scroll="scroll" :getUpdatedItemData="getUpdatedCollectionData" slot="item-rating" />
            </ItemMetaData> -->
              <UGCollectionAbout :collection="collection" @on-delete-external-item="onDeleteExternalItem" />
              <ion-row v-if="collection.skills && collection.skills.length > 0" class="skills-row" style="margin-top: 32px">
                <ion-col size="4" class="ion-text-start">
                  <span class="skills-title">Skills</span>
                </ion-col>
                <ion-col class="ion-text-end">
                  <div class="meta-skills">
                    <div v-for="skill in collection.skills" :key="skill.guid">
                      <span class="skill-pill ellipsis-lines-1" @click="onSkillClick(skill.guid)">{{ skill.guid }}</span>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
              <UGTrackFooter />
            </ion-col>
            <ion-col v-else size="12">
              <UGCollectionAbout :collection="collection" @on-delete-external-item="onDeleteExternalItem" />
              <!-- <ItemRating :item="collection" :scroll="scroll" :getUpdatedItemData="getUpdatedCollectionData" /> -->
              <UGTrackFooter />
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
      <ion-grid v-else :style="isDesktopPlatform ? '' : 'padding: 2rem 10px 6rem;'" class="ion-no-padding">
        <PageNotFound />
      </ion-grid>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { marked } from 'marked'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import moment from 'moment-timezone'
// import Bookmark from '@/components/molecules/Bookmark'
import UGCollectionAbout from '@/views/collection/components/UGCollectionAbout'
// import UGTrackOptionsModal from '@/views/collection/components/UGTrackOptionsModal'
import TrackSkeleton from '@/views/collection/components/TrackSkeleton'
import UGTrackOverlayOptions from '@/views/collection/components/UGTrackOverlayOptions'
import UGTrackDeleteOverlay from '@/views/collection/components/UGTrackDeleteOverlay'
import { popoverController } from '@modus/ionic-vue'
import UGTrackFooter from '@/views/collection/components/UGTrackFooter'
import MoreActionPopover from '@/components/molecules/MoreActionPopover'
import PageNotFound from '@/components/molecules/PageNotFound'
import RadialProgressBar from 'vue-radial-progress'

export default {
  name: 'UGTrack',
  components: { UGCollectionAbout, UGTrackFooter, MoreActionPopover, PageNotFound, TrackSkeleton, RadialProgressBar },
  props: {},
  data() {
    return {
      collection: null,
      collectionId: '',
      authToken: '',
      isAccessible: false,
      dataReady: false,
      isDesktopPlatform: true,
      isAdd: false,
      scroll: 0,
      showConfirmationProgress: false,
      isMetaDataVisible: true,
      updateTime: 'UPDATES EVERY 24 HOURS',
      signedUriEnabled: false,
      externalContent: {},
      isMoreOpen: false,
      isContentAvailable: false,
      assetMediaUrl: '',
      completedItems: [],
      totalItems: [],
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'sso_token', 'sso_bouncer', 'user_access']),
    onDate: function () {
      const completedAt = {}.hasOwnProperty.call(this.collection, 'completedAt') ? this.collection.completedAt : this.collection.meta.completedAt
      const date = new Date(completedAt)
      const duration = new Date() - date
      const days = Math.ceil(duration / (1000 * 60 * 60 * 24))
      if (days == 1) return 'today'
      else if (days == 2) return 'yesterday'
      else if (days > 2 && days < 7) return `${days} days ago`
      else return date.toLocaleDateString('en-US')
    }
  },
  watch: {},
  created() {},
  async mounted() {
    this.$refs.top.scrollIntoView()
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.isMetaDataVisible = enabledFeatureFlags['learn_collection-meta-data-enabled'] ? enabledFeatureFlags['learn_collection-meta-data-enabled'] : false
    this.signedUriEnabled = enabledFeatureFlags['learn_autographer-uri-enabled'] ? enabledFeatureFlags['learn_autographer-uri-enabled'] : false
    this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
  },
  activated() {
    this.$refs.top.scrollIntoView()
    this.$eventBus.$on(`remove-from-track`, this.removeFromTrack)
    this.setupCollection()
  },
  deactivated() {
    this.$eventBus.$off(`remove-from-track`)
  },
  methods: {
    async getTrackProgress(trackDetails) {
      this.totalItems = []
      for (const items of trackDetails.data.content) {
          if (items && items.id) {
            this.totalItems.push(items.id);
          }
      }

      this.completedItems = []
      for (const content of trackDetails.data.content) {
          if (content &&
          content.meta &&
          content.meta.isComplete === true &&
              this.totalItems.includes(content.id)) {
                  this.completedItems.push(content.id);
          }
      }
    },
    async removeFromTrack(removeItem) {
      let items = this.collection.content || []
      let itemsPayload = items.filter((item) => item.id != removeItem.id)
      let itemsPayloadParsed = []
      itemsPayload.forEach((item) => {
        let itemObj = {
          itemId: '',
          name: '',
          format: '',
          duration: {
            value: 0,
            type: ''
          }
        }
        if (item.learningItem?.id) itemObj.itemId = item.learningItem.id || ''
        else itemObj.itemId = item.id || ''
        itemObj.name = item.title || ''
        itemObj.format = item.format?.name.toLowerCase() || ''
        itemObj.duration.value = item.durationSeconds ? item.durationSeconds : 0
        itemObj.duration.type = 'seconds'
        itemsPayloadParsed.push(itemObj)
      })

      const payload = {
        token: this.authToken,
        id: this.collection.guid,
        body: {
          items: itemsPayloadParsed
        }
      }
      let response = await this.$learnAdmin.addItemsToTrack(payload)
      if (response.data.data.guid) {
        window.location.reload()
      }
    },
    getRouteInfo() {
      const params = { id: this.collectionId }
      return { name: 'Collection', params: params }
    },
    async getMedia(uri) {
      if (!this.signedUriEnabled || uri.includes('amazonaws.com')) return uri
      const signedURI = await this.$learnAdmin.getSignedURI({ url: uri, token: this.authToken })
      return signedURI.url ? signedURI.url : uri
    },
    async setupCollection() {
      await localforage.removeItem('deep_link_id')
      await localforage.removeItem('deep_link_type')
      this.dataReady = false
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.collectionId = this.$route.params.id
      const userAccess = this.user_access || (await localforage.getItem('my_user_access'))
      this.isAccessible = userAccess && userAccess.canAccessCourses
      const response = await this.$learnAdmin.getCollectionById({ token: this.authToken, id: this.collectionId })
      const trackResult = response.data
      let ratingDetailsArray = []
      this.getTrackProgress(trackResult);
      if (trackResult && trackResult.data && trackResult.data.content) {
        ratingDetailsArray = (await this.determineContentRating(trackResult.data.content)) || []
      }
      this.isContentAvailable = {}.hasOwnProperty.call(response, 'status') && response.status != 400 && response.status != 404 && response.status != 401
      if (this.isContentAvailable) {
        this.collection = trackResult.data.track
        this.collection.content = {}.hasOwnProperty.call(trackResult.data, 'content') ? trackResult.data.content : []
        this.collection.synopsis = {}.hasOwnProperty.call(this.collection, 'description') ? this.collection.description : ''
        this.collection.duration = {}.hasOwnProperty.call(this.collection, 'duration') ? this.setDuration() : ''
        this.collection.level = {}.hasOwnProperty.call(this.collection, 'level') ? this.collection.level : 'Beginner'
        this.collection.title = {}.hasOwnProperty.call(this.collection, 'name') ? this.collection.name : ''
        this.collection.image = {}.hasOwnProperty.call(this.collection, 'image') ? this.collection.image : ''
        this.collection.meta = {}.hasOwnProperty.call(trackResult.data, 'meta') ? trackResult.data.meta : ''
        this.collection.publishDate = this.formatDate()
        this.collection.rating = {}.hasOwnProperty.call(this.collection, 'rating') ? this.collection.rating : 0
        this.collection.ratingCount = 0
        this.collection.ratingCounts = {}.hasOwnProperty.call(this.collection, 'ratingCounts') ? this.collection.ratingCounts : []
        this.collection.format = { name: 'TRACK' }
        if (this.collection.ratingCounts.length) {
          await this.collection.ratingCounts.map((r) => {
            this.collection.ratingCount += r.count
          })
        }
        await this.collection.content.map((item) => {
          item.level =
            item.format.name.toLowerCase() === 'credential'
              ? {}.hasOwnProperty.call(item, 'level')
                ? item.level
                : ''
              : {}.hasOwnProperty.call(item, 'courseLevels')
              ? item.courseLevels[0]
              : ''
          item.tracks = []
          item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item.publishedAt : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'requiredBy') ? item.requiredBy : ''
          item.content = {}.hasOwnProperty.call(item, 'content') ? item.content : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          item.scope = { isExternal: {}.hasOwnProperty.call(item, 'author') && item.format.name.toLowerCase() != 'asset' }
          item.launchBtn = item.scope.isExternal ? 'Open' : item.progress > 0 && item.progress < 100 ? 'Resume' : 'Launch'
          item.provider = item.scope.isExternal ? { name: {}.hasOwnProperty.call(item, 'source') ? item.source : '' } : item.provider
          item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : 0
          // we need to maintain a duration field in seconds for the 'delete from track' endpoint
          item.durationSeconds = {}.hasOwnProperty.call(item, 'duration') ? item.duration : 0
          // we are converting duration to a string because that is required for universal card global component
          item.duration = !{}.hasOwnProperty.call(item, 'duration')
            ? null
            : item.duration < 3600
            ? `${Math.round(item.duration / 60)} min`
            : `${Math.round(item.duration / 3600)} hrs`
          item.rating =
            ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.id)
              ? ratingDetailsArray.filter((rating) => rating.entityId == item.id)[0]?.avgRating
              : 0
          if (item.dueDate) {
            const dueDate = item.dueDate
            delete item.dueDate;
            const formattedToday = moment(dueDate).tz('America/New_York').format('DD MMM YYYY')
            if (item.timeToProgressSyncInHours > 0) {
              moment(dueDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
                ? (item.dueDate = formattedToday)
                : (item.pastDueDate = formattedToday)
            } else {
              moment(dueDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
            }
          }
          if (item.format.name.toLowerCase() === 'asset') {
            item.image = { uri: this.fetchAssetThumbnail(item.inventoryType?.name) }
            item.type = { name: item.inventoryType.name }
            item.applications = item.applications
              ? item.applications.map((application) => {
                  return { name: application.name, src: `${this.assetMediaUrl}media-container/${application.image}` }
                })
              : []
          }
        })
      }
      this.dataReady = true
    },
    determineContentRating(CollectionContentValue) {
      var contentIdList = []
      CollectionContentValue?.map((item) => {
        contentIdList.push(item.id)
      })
      if (contentIdList && contentIdList.length > 0) {
        const contentPayload = {
          token: this.authToken,
          body: contentIdList
        }
        return this.$learnAdmin.getContentRatingById(contentPayload)
      }
    },
    onDeleteExternalItem() {
      this.$refs.top.scrollIntoView()
      this.setupCollection()
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    async getUpdatedCollectionData() {
      this.dataReady = false
      const collection = await this.$learnAdmin.getUGTrackById({ token: this.authToken, id: this.collectionId })
      this.collection = { ...this.collection, ...collection }
      this.collection.ratingCount = 0
      this.collection.ratingCounts = {}.hasOwnProperty.call(this.collection, 'ratingCounts') ? this.collection.ratingCounts : []
      if (this.collection.ratingCounts.length) {
        await this.collection.ratingCounts.map((r) => {
          this.collection.ratingCount += r.count
        })
      }
      this.dataReady = true
    },
    onScroll() {
      this.scroll = this.scroll + 1
    },
    popoverOn(popoverType) {
      this.isMoreOpen = popoverType === 'more'
    },
    popoverOff(popoverType) {
      this.isMoreOpen = !popoverType === 'more'
    },
    async getText(uri) {
      const response = await fetch(uri)
      const content = await response.text()
      return marked.parse(content)
    },
    setDuration() {
      this.collection.duration.value = Math.round(this.collection.duration.value)
      return this.collection.duration.value < 3600 ? `${Math.round(this.collection.duration.value / 60)} min` : `${Math.round(this.collection.duration.value / 3600)} hrs`
    },
    formatDate() {
      let returnDate = ''
      if (this.collection.publishedAt) {
        let date = new Date(this.collection.publishedAt)
        date = date.toDateString().split(' ')
        returnDate = `${date[1]} ${date[3]}`.toUpperCase()
        return returnDate
      }
    },
    async deleteTrack(event) {
      let modal = await popoverController.create({
        component: UGTrackOverlayOptions,
        event: event,
        cssClass: 'tracks-modal-option',
        mode: 'md',
        arrow: false,
        showBackdrop: true,
        backdropDismiss: true,
        componentProps: {
          parent: this,
          propsData: {
            options: {
              title: 'Delete this track?',
              subtitle1: `Are you sure you want to permanently delete "${this.collection.title}"?`,
              subtitle2: 'Deleting this track will remove all the learning items included in it.',
              button1: 'Cancel',
              button2: 'Delete'
            }
          }
        }
      })
      modal.onDidDismiss().then((option) => {
        if (option.data == 'Delete') {
          this.$learnAdmin.deleteTrack({ token: this.authToken, id: this.collectionId })
          this.deleteAlert()
        }
      })
      await modal.present()
    },
    async editDeleteTrackContent(action) {
      this.isMoreOpen = false
      if (action == 'delete-track') {
        this.deleteTrack()
        await this.onPrepareGAPayload('Delete')
      } else if (action == 'edit-track') {
        this.editTrack()
        await this.onPrepareGAPayload('Edit')
      }
    },
    async deleteAlert(event) {
      let modal = await popoverController.create({
        component: UGTrackDeleteOverlay,
        event: event,
        cssClass: 'track-alert',
        mode: 'md',
        arrow: false,
        showBackdrop: true,
        backdropDismiss: true,
        alignment: 'end',
        componentProps: {
          parent: this,
          propsData: {
            options: {
              title: 'Track successfully deleted'
            }
          }
        }
      })
      this.$router.go(-1)
      await modal.present()
    },
    editTrack() {
      this.$eventBus.$emit(`edit-ug-track`, this.collection)
    },
    onSkillClick(skill) {
      this.$router.push({ name: 'Search', query: { q: `"${skill}"`, suggestionSkill: skill }, params: { fromUserTrackLandingPage: true } }).catch(() => {})
    },
    async onPrepareGAPayload(category) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'track-id': this.collectionId,
        'track-title': this.collection.title,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'User Created Track Landing Page',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.progress-track {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
    margin-left: 1.5rem;
    margin-top: 10px;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
    span {
      font-family: 'Arial';
      font-size: 14px;
      font-weight: normal;
      color: #2d2d2d;
    }
  }
.flex-container {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 10px;
}

.flex-percentage {
  width: 176px;
  padding-top: 5px;
  text-align: center;
  font: normal normal normal 14px/16px Arial;
  color: #2D2D2D;
}

.track-content {
    max-width: 1700px;
    padding: 2rem 3.8rem;
    margin-left: unset;
    margin-right: unset;
    margin-inline: auto;
 }
 
.non-white-background {
  background: #efefef !important;
}
.desktop-background {
  background: transparent !important;
}

.desktop-collection-container {
  padding: 2rem 3.8rem;
}

.mobile-collection-container {
  padding: 2rem 1rem;
}

.coursera-link {
  padding: 0.5rem 4rem;
  background: var(--ion-color-secondary);
  color: var(--ion-color-tertiary);
  border: 1px solid var(--ion-color-secondary);
  border-radius: 2rem;
  text-decoration: none;
  font-family: 'Futura PT Book', sans-serif;
  font-size: 1rem;
}

.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
  color: #5c5c5c;
  svg {
    position: relative;
    top: 5px;
  }
}

.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  padding: unset;
}

.collection-info {
  display: flex;
  margin-top: 2rem;
  gap: 60px;
}

.collection-img-shape {
  position: relative;
  max-width: 165px;
  border: 4px solid var(--ion-color-white);
  overflow: hidden;
  width: 165px;
  height: 165px;
  border: 5px solid var(--ion-color-white);
  background-size: contain;
  border-radius: 20px;
  img {
    width: 165px;
    height: 165px;
    object-fit: cover;
  }
}

.collection-image {
  border-radius: 28px;
}

.collection-img-background {
  height: 156px;
  //clip-path: polygon(100% 0%, 49% 100%, 0% 0%);
  //background-image: url('~@/assets/images/gradient-collection.png');
  border-radius: 28px;
  img {
    width: 156px;
    height: 156px;
    object-fit: cover;
  }
}

.collection-no-img-background {
  height: 188px;
  width: 188px;
  border-radius: 28px;
  border: 6px solid white;
  img {
    height: 178px;
  }
}

.meta {
  display: flex;
  gap: 0.4rem;
  text-transform: uppercase;
  color: var(--ion-color-black);
  font-size: 0.8rem;
  font-family: Arial;
  font-weight: bold;
  letter-spacing: 1.2px;
  .format {
    padding: 2px 0.5rem;
    border-radius: 10px;
    background: var(--ion-color-dark-grey);
    font-family: Arial;
    font-size: 14px;
  }
  .g-button--primary {
    width: 7rem;
    margin-right: 1rem;
    text-transform: capitalize;
  }
}

.collection-about-container {
  margin-top: 2.5rem;
}

.title {
  margin: 24px 0rem;
  text-align: left;
  font-size: 48px;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-black);
  line-height: 45px;
}

.meta-info {
  display: flex;
  gap: 24px;
  font-size: 14px;
  margin-bottom: 24px;
  align-items: center;
  .collection-rating {
    display: flex;
    cursor: pointer;
    .display-star-mobile {
      display: none;
    }
  }
  .star-rating,
  .rating {
    font-weight: bold;
    font-family: Arial;
    padding-left: 5px;
    color: #2d2d2d;
  }
  .rating-count {
    font-family: Arial;
    padding: 0 10px 0;
    color: #2d2d2d;
  }

  .left-border {
    border-left: 2px solid #e0e1e1;
    padding-left: 10px;
  }
  .duration,
  .content-count-title,
  .level,
  .track-content-just-you {
    font-family: Arial;
    color: #2d2d2d;
    .svg-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    .content-text {
      font-family: Arial;
      color: #2d2d2d;
      font-size: 12px;
    }
  }
  .content-count {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .content-count-right-border {
    width: 20px;
    height: 22px;
    border: 2px solid black;
    border-left: unset;
    border-bottom: unset;
    margin: -6px 0 0 -25px;
  }
  .level-border-unset {
    border-right: unset;
  }
}

.meta-action {
  display: flex;
  .collection-bookmark,
  .social-share,
  .more-action {
    width: 40px;
    height: 40px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-gray);
    border-radius: 50%;
    margin: 4px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon {
      width: 1.5rem;
    }
  }
  .progress {
    position: relative;
    top: 10px;
    left: 1rem;
    font-size: 12px;
    font-family: Arial;
    letter-spacing: 1.2px;
    font-weight: bold;
    svg {
      position: relative !important;
      top: 0.3rem !important;
      width: 1.2rem !important;
      height: 1.2rem !important;
      margin-right: 0.3rem !important;
    }
    span {
      position: relative;
      top: 2px;
      text-transform: uppercase;
    }
  }
  .more-container,
  .share-container {
    position: relative;
    .more-tooltip-text,
    .share-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      right: 0;
    }
  }
  .share-container:hover .share-tooltip-text {
    visibility: visible;
  }
  .more-container:hover .more-tooltip-text {
    visibility: visible;
  }
}

.more-popover {
  position: absolute;
  box-shadow: 0px 0px 12px #0000001f;
  border-radius: 4px;
  background: #ffffff;
  padding: 6px;
  z-index: 10;
  opacity: 0;
  margin-top: 10px;
  animation-name: slideinmore;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  text-align: left;
}

@keyframes slideinmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -217px;
  }
}

.skills-row {
  border-bottom: 2px solid var(--ion-color-dark-grey);
  padding-bottom: 16px;
}
.skills-title {
  font-family: 'Arial';
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.meta-skills {
  max-width: 310px;
  display: inline-flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  color: var(--ion-color-black);
  font-size: 0.7rem;
}
.skill-pill {
  //height: 30px;
  border-radius: 15px;
  font-family: 'Arial';
  font-size: 11px;
  font-weight: bold;
  background: #bcc2d4;
  color: var(--ion-color-tertiary);
  padding: 8px 14px 8px 14px;
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  text-align: start;
}

.track-option-margin {
  margin-left: 8px;
}
.track-options {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: white;
  padding: 6px;
  margin-top: 5px;
  cursor: pointer;
  pointer-events: none;
}

@media screen and (min-width: 1025px) {
  .carouselMobile {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .carouselDesktop {
    display: none !important;
  }
  .carouselMobile {
    display: block !important;
  }

  .collection-img-col {
    display: none;
  }
}

@media screen and (max-width: 1477px) {
  .collection-img-background {
    height: 156px;
    img {
      width: 156px;
      height: 156px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1366px) {
  .title {
    margin: 1.25rem 0;
  }
  .collection-img-shape {
    display: none;
  }
  @keyframes slideinmore {
    from {
      width: 0;
      font-size: 0px;
    }
    to {
      width: 256px;
      opacity: 1;
      font-size: 14px;
      left: 0;
    }
  }
}

@media screen and (max-width: 484px) {
  .title {
    font-size: 34px;
    margin: 0.5rem 0;
  }
  .meta-info {
    margin-bottom: 1rem;
    .collection-rating {
      display: inline;
      .star-rating {
        display: none;
      }
      .display-star-mobile {
        display: inline;
      }
    }
  }
  .meta-action {
    flex-wrap: wrap;
    gap: 1rem;
    .progress {
      left: unset;
      top: unset;
    }
    .collection-bookmark,
    .share {
      margin: 4px 0px 0px 0px;
    }
  }
}

@media screen and (max-width: 375px) {
  .meta-info {
    gap: 5px;
    .duration,
    .level,
    .rating-count {
      padding-right: 5px;
    }
  }
}
</style>
