<template>
  <div>
    <div class="addskills-modal__backdrop" @click="onCancel">
      <ion-spinner v-if="!dataReady" color="#1f3370" style="top: 25%; left: 45%" />
    </div>
    <div class="addskills-modal">
      <ion-spinner v-if="!dataReady" color="#1f3370" style="top: 25%; left: 45%" />
      <ion-grid v-if="dataReady && showAddSkills" class="ion-no-padding">
        <ion-row class="ion-padding">
          <ion-col>
            <span class="addskills-modal__header" style="float: left">Skills to explore</span>
            <svg-icon icon-class="filter-close" class="g-icon--small close g-clickable" style="float: right" @click="onCancel"></svg-icon>
          </ion-col>
        </ion-row>
        <ion-searchbar
          search-icon="search-outline"
          ref="searchbar"
          style="text-align: left; --border-radius: 50px"
          :value="query"
          @keypress.enter="handleSearchInput"
          @ionClear="handleClearInput"
        ></ion-searchbar>
        <ion-row class="scrollbar" v-if="!showSearchResults">
          <ion-col>
            <ion-row class="ion-align-items-center ion-padding-horizontal md hydrated" @click="toggleSelectedSkills">
              <ion-col>
                <div class="ion-no-margin addskills-modal__subheader g-clickable">
                  Selected skills
                  <div class="addskills-modal__footer-label">{{ getSelectedSkillsLength() }}</div>
                </div>
              </ion-col>
              <ion-col style="display: contents">
                <svg-icon :style="showSelectedSkills ? 'transform: rotate(180deg);' : ''" icon-class="down-arrow-expand" class="icon-expand"></svg-icon>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col v-if="showSelectedSkills" class="ion-no-padding">
                <p v-if="selectedSkills.length == 0" class="addskills-modal__subheader ion-padding-horizontal">You have no selected skills</p>
                <p v-if="selectedSkills.length == 0" class="addskills-modal__no-results ion-padding-horizontal">
                  Add skills to browse courses, credentials, videos and more based on your interests.
                </p>
                <ion-row
                  v-for="skill in selectedSkills"
                  :key="skill.name + '-selected'"
                  class="ion-align-items-center ion-no-padding addskills-modal__checkbox-row"
                  :class="skill.selected ? 'selected md hydrated' : 'md hydrated'"
                >
                  <ion-col size="10" class="ion-text-start"
                    ><p class="addskills-modal__name">{{ skill.name }}</p></ion-col
                  >
                  <ion-col class="ion-checkbox-col">
                    <ion-checkbox
                      @ionChange="onSelectedSkillChange($event)"
                      :checked="skill.selected"
                      :value="skill.name"
                      class="addskills-modal__checkbox"
                      color="tertiary"
                      mode="md"
                    ></ion-checkbox>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-no-padding">
                <p class="addskills-modal__subheader ion-padding-horizontal">Suggested for you</p>
                <p v-if="recommendedSkills.length == 0" class="addskills-modal__no-results ion-padding-horizontal">
                  There are no recommend skills at this moment. Please try again later.
                </p>
                <ion-row
                  v-for="skill in recommendedSkills"
                  :key="skill.name + '-recommend'"
                  class="ion-align-items-center ion-no-padding addskills-modal__checkbox-row"
                  :class="skill.selected ? 'selected md hydrated' : 'md hydrated'"
                >
                  <ion-col size="10" class="ion-text-start"
                    ><p class="addskills-modal__name">{{ skill.name }}</p></ion-col
                  >
                  <ion-col class="ion-checkbox-col">
                    <ion-checkbox
                      @ionChange="onRecommendedSkillChange($event)"
                      :checked="skill.selected"
                      :value="skill.name"
                      class="addskills-modal__checkbox"
                      color="tertiary"
                      mode="md"
                    >
                    </ion-checkbox>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="ion-no-padding" v-if="showSearchResults">
        <ion-row style="overflow-y: scroll" class="search-skill-scroller">
          <ion-col class="ion-no-padding">
            <p class="addskills-modal__subheader ion-padding-horizontal">Found {{ searchedSkills.length }} skills matching "{{ query }}"</p>
            <p v-if="searchedSkills.length == 0" class="addskills-modal__no-results ion-padding-horizontal">
              For better results, check your query for spelling errors, try a broader term or other synonymous terms.
            </p>
            <ion-row
              v-for="skill in searchedSkills"
              :key="skill.name + '-searched'"
              class="ion-align-items-center ion-no-padding addskills-modal__checkbox-row"
              :class="skill.selected ? 'selected md hydrated' : 'md hydrated'"
            >
              <ion-col size="10" class="ion-text-start"><p class="addskills-modal__name" v-html="skill.displayName"></p></ion-col>
              <ion-col class="ion-checkbox-col">
                <ion-checkbox @ionChange="onSearchedSkillChange($event)" :checked="skill.selected" :value="skill.name" class="addskills-modal__checkbox" color="tertiary" mode="md">
                </ion-checkbox>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row v-if="showSearchResults" class="ion-align-items-center ion-padding-horizontal addskills-modal__footer md hydrated">
          <ion-col>
            <div class="ion-no-margin addskills-modal__footer-header g-clickable" @click="onClickSelectedSkills">
              See all selected skills
              <div class="addskills-modal__footer-label">{{ getSelectedSkillsLength() }}</div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'

export default {
  name: 'RecommendedAddSkills',
  components: {},
  props: {
    isOnAssetTab: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      authToken: '',
      topSkills: [],
      selected: [],
      recommendedSkills: [],
      providers: [],
      cpeEligibles: [],
      showAddSkills: true,
      showSelectedSkills: true,
      selectedSkills: [],
      isSelectedSkillsUpdated: false,
      query: '',
      searchedSkills: [],
      keyboardVisible: false,
      showSearchResults: false,
      dataReady: false,
      previousSelectedSkillsLength: 0
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async created() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.dataReady = false
    const skills = await this.$learnAdmin.getUserExplorativeskills({ token: this.authToken })
    this.previousSelectedSkillsLength = skills.length
    window.addEventListener('resize', () => {
      this.keyboardVisible = !this.keyboardVisible
      const element = document.getElementsByClassName('scrollbar')[0]
      if (this.keyboardVisible) element.style.height = '20vh'
      else element.style.height = '40vh'
    })
    await skills.map((skill) => {
      skill.selected = true
      this.selectedSkills.push(skill)
    })
    const summary = await this.$learnAdmin.getUserLearningProgressSummary({ token: this.authToken })
    if ({}.hasOwnProperty.call(summary, 'topSkills')) {
      await summary.topSkills.map((skill) => {
        if (!this.selectedSkills.some((s) => s.name === skill.name)) {
          skill.selected = false
          this.recommendedSkills.push(skill)
        }
      })
      this.topSkills = summary.topSkills.length > 6 ? summary.topSkills.splice(0, 6) : summary.topSkills
      await this.GAEventTrackTopSkills()
    }
    this.recommendedSkills = this.recommendedSkills.length > 20 ? this.recommendedSkills.splice(0, 20) : this.recommendedSkills
    this.dataReady = true
  },
  methods: {
    async onCancel() {
      this.selectedSkills = this.selectedSkills.filter((skill) => skill.selected)
      this.$emit('closeAddSkillsModal', {
        selectedSkills: JSON.parse(JSON.stringify(this.selectedSkills)),
        previousSelectedSkillsLength: this.previousSelectedSkillsLength,
        isSelectedSkillsUpdated: this.isSelectedSkillsUpdated
      })
      await this.GAEventTrackAddedSkills()
    },
    async onRecommendedSkillChange(data) {
      this.isSelectedSkillsUpdated = true
      await this.recommendedSkills.map((skill) => {
        if (skill.name === data.detail.value) {
          skill.selected = data.detail.checked ? true : false
          if (skill.selected && !this.selectedSkills.some((e) => e.name === skill.name)) {
            this.selectedSkills.push(skill)
          }
        }
      })
      this.recommendedSkills = await this.recommendedSkills.filter((skill) => skill.name != data.detail.value && !skill.selected)
    },
    async onSelectedSkillChange(data) {
      this.isSelectedSkillsUpdated = true
      await this.selectedSkills.map((skill) => {
        if (skill.name === data.detail.value) {
          skill.selected = data.detail.checked ? true : false
        }
      })
    },
    async onSearchedSkillChange(data) {
      this.isSelectedSkillsUpdated = true
      await this.searchedSkills.map(async (skill) => {
        if (skill.name === data.detail.value) {
          skill.selected = data.detail.checked ? true : false
          if (skill.selected && !this.selectedSkills.some((e) => e.name === skill.name)) {
            this.selectedSkills.push(skill)
          } else if (!skill.selected && this.selectedSkills.some((e) => e.name === skill.name)) {
            const found = await this.selectedSkills.findIndex((e) => e.name === skill.name)
            if (found > -1) this.selectedSkills.splice(found, 1)
          }
        }
      })
    },
    toggleSelectedSkills() {
      this.showSelectedSkills = !this.showSelectedSkills
      this.handleClearInput()
    },
    async onClickSelectedSkills() {
      await this.handleClearInput()
    },
    async handleSearchInput(event) {
      this.query = event.target.value
      if (this.query.length > 0) {
        this.searchedSkills = await this.$learnAdmin.searchSkillByName({ token: this.authToken, skill: encodeURIComponent(this.query) })
        const regex = new RegExp(this.query, 'ig')
        const mask = `<span style="top:0px; font-weight:bold; font-family: 'Arial'" class="ion-text-capitalize">${this.query}</span>`
        if (this.searchedSkills.length > 0) {
          this.searchedSkills.forEach((skill) => {
            skill.displayName = skill.title.replace(regex, mask)
            skill.name = skill.title
            skill.selected = this.selectedSkills.some((e) => e.name === skill.name)
          })
        }
      }
      this.showSearchResults = true
    },
    handleClearInput() {
      this.query = ''
      this.searchedSkills = []
      this.showSearchResults = false
    },
    getSelectedSkillsLength() {
      return this.selectedSkills.filter((skill) => skill.selected).length
    },
    async GAEventTrackTopSkills() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      let topSkills = []
      await this.topSkills.map(async (skill) => {
        topSkills.push({ guid: skill.guid, name: skill.name })
      })
      const payload = {
        action: 'Explore',
        category: 'Top 6 Skills',
        value: JSON.stringify({
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          'top-skills': topSkills
        })
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrackAddedSkills() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      let addSkills = []
      await this.selectedSkills.map(async (skill) => {
        if (skill.selected && !this.topSkills.some((e) => e.name === skill.name)) {
          addSkills.push({ guid: skill.guid, name: skill.name })
        }
      })
      if (addSkills.length) {
        const value = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          'add-skills': addSkills
        }
        const payload = {
          action: !this.isOnAssetTab ? 'Explore' : 'Asset',
          category: !this.isOnAssetTab ? 'Selected Skills' : 'Explore Asset Tab',
          value: JSON.stringify(value)
        }
        analytics.setVarAndTrack({ c: 'Explore', a: 'Add Skill', l: JSON.stringify(value) })
        await this.GAEventTrack(payload)
      }
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.searchbar-input.sc-ion-searchbar-md {
  --box-shadow: none;
}
</style>

<style lang="scss" scoped>
@include block('addskills-modal') {
  position: fixed;
  padding: 0.5rem 1rem;
  top: 120px;
  width: 550px;
  height: 650px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-gray);
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  @include element('header') {
    font-family: 'Futura PT Demi';
    font-size: 1.5rem;
    color: var(--ion-color-black);
    text-align: start;
  }

  @include element('subheader') {
    font-family: 'Arial';
    font-weight: bold;
    font-size: 1rem;
    color: var(--ion-color-black);
    text-align: start;
    margin-bottom: 10px;
  }

  @include element('no-results') {
    font-family: 'Arial';
    font-size: 0.9rem;
    color: var(--ion-color-role-grey);
    text-align: start;
  }

  @include element('footer') {
    border-top: 2px solid var(--ion-color-light-gray);
    height: 70px;
  }

  @include element('footer-header') {
    font-family: 'Arial';
    font-weight: bold;
    font-size: 1rem;
    color: #1f3370;
    text-align: start;
  }

  @include element('footer-label') {
    background-color: #e3e3e3;
    height: 30px;
    width: 40px;
    border-radius: 15px;
    display: inline-block;
    margin: 0px;
    padding: 4px 0px 0px 0px;
    text-align: center;
  }

  @include element('footer-icon') {
    background-color: #1f3370;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 10px 0px 0px;
    text-align: center;
    color: white;
  }

  @include element('checkbox') {
    --size: 18px;
    margin-right: 12px;
  }

  @include element('checkbox-row') {
    height: 40px;
  }

  @include element('checkbox-row-selected') {
    background-color: #e9ebf1;
    border-bottom: 1px solid #ffffff;
  }

  @include element('name') {
    font-family: 'Arial';
    font-size: 0.9rem;
    color: #1f3370;
    padding-left: 17px;
    margin: 0px;
  }

  @include element('backdrop') {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--ion-color-role-grey);
    opacity: 0.5;
    z-index: 1;
  }
}
.selected {
  background-color: #e9ebf1;
  border-bottom: 1px solid var(--ion-color-dark-grey);
  font-weight: bold;
}

.scrollbar {
  height: 420px;
  margin-top: 10px;
  overflow-y: scroll;
}

.search-skill-scroller {
  height: 370px;
}

.icon-expand {
  width: 12px !important;
  height: 12px !important;
}

.ion-checkbox-col {
  display: flex;
  justify-content: flex-end;
}

// Screens under 480px
@media screen and (max-width: 480px) {
  @include block('addskills-modal') {
    width: 100%;
    left: 0px;
    z-index: 1002;
    height: 65%;
    @include element('backdrop') {
      z-index: 1001;
    }
  }
  .scrollbar {
    height: 40vh;
  }
  .search-skill-scroller {
    height: 35vh !important;
  }
}
</style>
