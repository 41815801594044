<template>
  <div>
    <div class="back-btn-container">
      <button class="accessible-button back"  @click="$router.go(-1)">
        <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
        Back
      </button>
    </div>
    <div class="no-content-container">
      <div class="no-content-description-container">
        <div class="no-content-header">The item you’re looking for is not available.</div>
        <div class="no-content-description">Browse our <span @click="goToExplore">Explore section</span> to discover content based on your interests</div>
      </div>
      <div class="no-content-image">
        <img src="@/assets/images/no-content.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {},
  components: {},
  data() {
    return {}
  },
  created() {},
  async mounted() {},
  methods: {
    goToExplore() {
      this.$router.push({ name: 'Explore' }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.no-content-container {
  background-color: #ffffff;
  margin: 50px 3%;
  border-radius: 20px;
  height: 467px;
  display: flex;
  align-items: center;
}

.no-content-image {
  margin-top: 100px;
  img {
    width: 385px;
    height: 455px;
  }
}

.no-content-description-container {
  padding: 0 2% 0 7%;
  width: 63%;
}

.no-content-header {
  text-align: left;
  color: #000000;
  letter-spacing: -0.03px;
  font-size: 60px;
  font-family: 'Futura PT Demi';
}

.no-content-description {
  font-size: 1rem;
  font-family: 'Arial';
  color: #5c5c5c;
  margin: 2rem 0;
  text-align: left;
  span {
    font-family: 'Arial';
    color: #1f3370;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.back-btn-container {
  text-align: left;
  color: var(--ion-color-medium);
}

.back {
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Arial';
  font-size: 14px;
  margin: 16px 0 0 3%;
  svg {
    position: relative;
    top: 5px;
  }
}

.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  padding: unset;
}

@media screen and (max-width: 1366px) {
  .no-content-description-container {
    width: 70%;
  }
  .no-content-container {
    height: 351px;
  }
  .no-content-header {
    font-size: 48px;
  }
  .no-content-image {
    img {
      width: 282px;
      height: 334px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .no-content-description-container {
    width: 72%;
  }
  .no-content-image {
    margin-top: 140px;
    img {
      width: 225px;
      height: 266px;
    }
  }
}

@media screen and (max-width: 768px) {
  .no-content-container {
    display: grid;
    height: 470px;
    padding: 40px 80px 0 40px;
  }
  .no-content-description-container {
    width: 100%;
    padding: unset;
  }
  .no-content-image {
    margin-top: 50px;
  }
}

@media screen and (max-width: 484px) {
  .no-content-container {
    height: 521px;
    padding: 40px 20px 0 16px;
    margin: unset;
  }
  .no-content-header {
    font-size: 34px;
  }
  .no-content-image {
    margin-top: 60px;
  }
  .back {
    margin: 0 0 16px -5px;
    font-size: 12px;
  }
}
</style>
