<template>
  <div>
    <ion-card class="csv-download-details">
      <div class="text-content">
        <h3>Download full transcript (CSV)</h3>
        <p>
          Your transcript is a comprehensive record of your learning activities, including starting or completion of items, related dates and more. It is more detailed than the
          list shown.
        </p>
      </div>
      <div class="dwnld-btn">
        <jsonToCsv :json-data="CSVDetailList" :labels="labelsForCSV" :csv-title="jsonTitle">
          <ion-button shape="round" color="primary" :disabled="!(CSVDetailList && CSVDetailList.length > 0)" class="ion-button-primary ion-text-capitalize download-btn"
            >Download</ion-button
          >
        </jsonToCsv>
      </div>
    </ion-card>
    <ion-card class="personal-details">
      <div class="personal-info-header" v-if="items.length > 0 && responseReceived && filterLoaded">
        <h3>Completed Learning</h3>
        <LearningHistoryFilters
          v-if="filterLoaded"
          :data="data"
          @on-apply="onApply($event)"
          @on-open-filters="onOpenFilters"
          @on-clear-filters="onApply($event)"
          @side-filter="onSideFilterData($event)"
          @on-open-external-sidebar="openExternalSidebar"
        ></LearningHistoryFilters>

        <ion-row v-if="showRequiredLearning && requiredLearningCount > 0" class="required-learning-container">
          <ion-col class="required-sort-text">
            <svg-icon icon-class="due-date" class-name="g-icon--small" style="fill: black !important; margin: 0 12px -4px 0" />
            Required Learning
            <span class="required-checkbox-inline required-sort-text">
              <ion-checkbox
                color="tertiary"
                mode="md"
                :checked="requiredChecked"
                @ionChange="onSelectRequired($event)"
                style="margin: 0 12px -4px 16px; z-index: 1"
                :disabled="requiredLearningCount < 1"
              ></ion-checkbox>
              Show required learning only ({{ requiredLearningCount }})
            </span>
          </ion-col>
        </ion-row>
        <ion-row class="required-checkbox-block">
          <ion-col class="required-sort-text">
            <span class="required-checkbox-block required-sort-text">
              <ion-checkbox color="tertiary" mode="md" :checked="requiredChecked" @ionChange="onSelectRequired($event)" style="margin: 12px 12px -4px 8px"></ion-checkbox>
              Show required learning only ({{ requiredLearningCount }})
            </span>
          </ion-col>
        </ion-row>
        <DataTable :dataList="items" @onDataSort="applyDataSort" :headers="headerList" />
      </div>
      <div class="personal-info-header" v-if="responseReceived && items.length == 0">
        <ion-row>
          <ion-col :size="isDesktopPlatform ? '8' : '12'" class="no-content-text">
            <h3>You don’t have any completed items, yet.</h3>
            <h6 style="font: normal normal normal 16px/22px Arial">
              Check out <a class="topnav-link" href="#" @click="gotoPage('/mylearning/required')">My learning</a> section to get started or visit the
              <a class="topnav-link" href="#" @click="gotoPage('/explore')">Explore</a> section to find more content that interests you. Once you complete a learning item, it’ll
              show up here for you to track.
            </h6>
          </ion-col>
          <ion-col :size="isDesktopPlatform ? '4' : '12'"><img class="no-content-img" :src="noContentImg()" alt="No Content" /></ion-col>
        </ion-row>
      </div>
    </ion-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import DataTable from '@/views/profile/components/DataTable'
import LearningHistoryFilters from '@/views/profile/components/LearningHistoryFilters'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
export default {
  name: 'LearningHistory',
  components: { DataTable, jsonToCsv: VueJsonToCsv, LearningHistoryFilters },
  data() {
    return {
      filterLoaded: false,
      responseReceived: false,
      isDesktopPlatform: true,
      items: [],
      headerList: [],
      sortBy: 'LPI_COMPLETED_AT', // default value for sort
      sortByDirection: 'DESC', // default value for sort direction
      showRequiredLearning: false,
      authToken: '',
      requiredChecked: false,
      requiredLearningCount: 0,
      isTab: false,
      CSVDetailList: [],
      labelsForCSV: {
        orgName: { title: 'Org Name' },
        name: { title: 'Name' },
        email: { title: 'Email' },
        title: { title: 'Job Title' },
        role: { title: 'Role Title' },
        contentTile: { title: 'Content Title' },
        contentType: { title: 'Content Type' },
        provider: { title: 'Provider' },
        duration: { title: 'Content Duration (hrs)' },
        accreditation: { title: 'Accreditation' },
        trackTitle: { title: 'Track Title' },
        learningStatus: { title: 'Learning Status' },
        activityDate: { title: 'Activity Date' }
      },
      data: {
        learning: {
          filters: {},
          selectedFilters: []
        }
      },
      jsonTitle: '',
      currentFilters: {}
    }
  },
  created() {},
  computed: {
    ...mapGetters(['auth_token'])
  },
  async activated() {
    this.isTab = window.innerWidth <= 1440 && window.innerWidth > 480
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
    const settings = JSON.parse(tenant.settings)
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.showRequiredLearning = enabledFeatureFlags['learn_required_learning_enabled'] && {}.hasOwnProperty.call(settings, 'required-learning') && settings['required-learning']
    this.isDesktopPlatform = this.$platform === 'Desktop' || window.innerWidth >= 450
    this.setHeaders()
    this.getRequiredLearningCount()
    this.getCSVDetails()
    this.getLearningItems().then(() => {
      this.getLearningFilters()
    })
  },
  methods: {
    setDuration(selectedDuration = []) {
      return [
        { minTime: 0, maxTime: 15, filterBy: selectedDuration.some((duration) => duration.minTime === 0 && duration.maxTime === 15) },
        { minTime: 15, maxTime: 60, filterBy: selectedDuration.some((duration) => duration.minTime === 15 && duration.maxTime === 60) },
        { minTime: 60, maxTime: 180, filterBy: selectedDuration.some((duration) => duration.minTime === 60 && duration.maxTime === 180) },
        { minTime: 180, maxTime: 300, filterBy: selectedDuration.some((duration) => duration.minTime === 180 && duration.maxTime === 300) },
        { minTime: 300, maxTime: 600, filterBy: selectedDuration.some((duration) => duration.minTime === 300 && duration.maxTime === 600) },
        { minTime: 600, maxTime: 99999, filterBy: selectedDuration.some((duration) => duration.minTime === 600 && duration.maxTime === 99999) }
      ]
    },
    setCreditHours(selectedCreditHour = []) {
      return [
        { minTime: 0, maxTime: 0.5, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 0 && creditHour.maxTime === 0.5) },
        { minTime: 0.5, maxTime: 1, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 0.5 && creditHour.maxTime === 1) },
        { minTime: 1, maxTime: 2, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 1 && creditHour.maxTime === 2) },
        { minTime: 2, maxTime: 5, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 2 && creditHour.maxTime === 5) },
        { minTime: 5, maxTime: 10, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 5 && creditHour.maxTime === 10) },
        { minTime: 10, maxTime: 20, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 10 && creditHour.maxTime === 20) },
        { minTime: 20, maxTime: 40, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 20 && creditHour.maxTime === 40) },
        { minTime: 40, maxTime: 9999, filterBy: selectedCreditHour.some((creditHour) => creditHour.minTime === 40 && creditHour.maxTime === 9999) }
      ]
    },
    groupByFilter(selectedFilters) {
      return selectedFilters.reduce((returnFilter, selectedFilter) => {
        let key = selectedFilter['type']
        if (!returnFilter[key]) {
          returnFilter[key] = []
        }
        if (key === 'Duration' || key === 'Credit hours') {
          returnFilter[key].push({ minTime: selectedFilter.minTime, maxTime: selectedFilter.maxTime })
        } else if (key === 'Status') {
          returnFilter[key].push({ minProgress: selectedFilter.minProgress, maxProgress: selectedFilter.maxProgress })
        } else if (key === 'Level') {
          returnFilter[key].push(selectedFilter.name.toUpperCase())
        } else if (key === 'Accreditation' || key === 'Credit type' || key === 'Function') {
          returnFilter[key].push(selectedFilter.name)
        } else if (key === 'Tracks') {
          returnFilter[key].push(selectedFilter.guid)
        } else {
          returnFilter[key].push(selectedFilter.id)
        }
        return returnFilter
      }, {})
    },
    openExternalSidebar(event) {
      console.log('openExternalSidebar', event)
    },
    onSideFilterData(event) {
      console.log('onSideFilterData', event)
    },
    onClearFilters(event) {
      console.log('onClearFilters', event)
    },
    onOpenFilters(data) {
      console.log('onOpenFilters', data)
    },
    completedDateFilter() {
      const options = [
        {
          id: '1',
          name: 'Last month',
          selected: false,
          userAccess: true,
          count: 0,
          month: 1
        },
        {
          id: '2',
          name: 'Last 3 months',
          selected: false,
          userAccess: true,
          count: 0,
          month: 3
        },
        {
          id: '3',
          name: 'Last 6 months',
          selected: false,
          userAccess: true,
          count: 0,
          month: 6
        },
        {
          id: '4',
          name: 'Last 12 months',
          selected: false,
          userAccess: true,
          count: 0,
          month: 12
        }
      ]
      let result = options.map((el) => {
        const monthAgo = new Date()
        monthAgo.setMonth(monthAgo.getMonth() - el.month)
        el.count = this.items.filter((e) => {
          let date = new Date(e.completedAt)
          return date >= monthAgo && date <= new Date()
        }).length
        return el
      })
      return result
    },
    dateDiff(arr = []) {
      let responseDate = {}
      if (arr.length > 0) {
        const id = arr[0]
        const obj = {
          1: 1,
          2: 3,
          3: 6,
          4: 12
        }
        const monthAgo = new Date()
        monthAgo.setMonth(monthAgo.getMonth() - obj[id])
        responseDate.startDate = moment(monthAgo).format('YYYY-MM-DD')
        responseDate.endDate = moment(new Date()).format('YYYY-MM-DD')
      }
      return responseDate
    },
    applyDateFilter(options, data) {
      options.forEach((el) => {
        const monthAgo = new Date()
        monthAgo.setMonth(monthAgo.getMonth() - el.monthCount)
        el.count = data.filter((e) => {
          let date = new Date(e.completedAt)
          return date >= monthAgo && date <= new Date()
        }).length
      })
    },
    onApply(data) {
      this.filterLoaded = false
      let filters = data.filters
      const payload = {
        filters: this.groupByFilter(filters)
      }
      this.currentFilters = this.groupByFilter(filters)
      this.data.learning.selectedFilters = filters
      this.getLearningItems(payload, filters).then(() => {
        this.getLearningFilters(payload)
      })
      this.getRequiredLearningCount(payload)
    },
    async getLearningFilters(data = {}) {
      this.filterLoaded = false
      let body = {
        pageNo: 1,
        pageSize: 0,
        includeCompleted: true,
        includeNoProgress: false,
        includeInProgress: false,
        contentAccessType: "ALL",
        trackSource: [],
        contentSource: ['EXTERNAL', 'INTERNAL'],
        includeItemsWithNoDueDate: true,
        dueDate: this.requiredChecked ? 'ONLY_WITH_DUE_DATE' : 'ALL',
        sortBy: 'LPI_COMPLETED_AT',
        sortByDirection: 'DESC',
        ...this.generateBody(data)
      }
      let payload = {
        token: this.authToken,
        body: body
      }
      let response = await this.$learnAdmin.getMyLearningPlanFilters(payload)
      this.data.learning['filters'] = response
      this.data.learning['filters']['completedDate'] = this.completedDateFilter()
      this.filterLoaded = true
    },
    setHeaders() {
      this.headerList = [
        {
          name: 'Title',
          show: true,
          showSortDropdown: false,
          showAscending: true,
          showDescending: true
        },
        {
          name: 'Duration',
          show: this.isDesktopPlatform ? true : false,
          showSortDropdown: false,
          showAscending: true,
          showDescending: true
        },
        {
          name: 'Completed',
          show: true,
          showSortDropdown: false,
          showAscending: false,
          showDescending: true
        },
        {
          name: 'Content type',
          show: this.isDesktopPlatform && !this.isTab ? true : false,
          showSortDropdown: false,
          showAscending: true,
          showDescending: true
        },
        {
          name: 'Provider',
          show: this.isDesktopPlatform && !this.isTab ? true : false,
          showSortDropdown: false,
          showAscending: true,
          showDescending: true
        }
      ]
    },
    noContentImg() {
      return require('@/assets/images/mask-group.png')
    },
    gotoPage(flag) {
      this.$router.push(flag).catch(() => {})
    },
    onSelectRequired(e) {
      if (e.detail.checked) {
        this.requiredChecked = true
        this.sortBy = 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT'
        this.headerList.forEach((element) => {
          element.showAscending = true
          element.showDescending = true
        })
        this.getLearningItems({ filters: this.currentFilters }).then(() => {
          this.getLearningFilters({ filters: this.currentFilters })
        })
      } else {
        this.requiredChecked = false
        this.sortBy = 'LPI_COMPLETED_AT'
        this.headerList.forEach((element) => {
          if (element.name == 'Completed') {
            element.showAscending = false
            element.showDescending = true
          } else {
            element.showAscending = true
            element.showDescending = true
          }
        })
        this.getLearningItems({ filters: this.currentFilters }).then(() => {
          this.getLearningFilters({ filters: this.currentFilters })
        })
      }
    },
    async getCSVDetails() {
      const response = await this.$learnAdmin.getCSVDetails({ token: this.authToken })
      if (response.length > 0) {
        this.CSVDetailList = response
        this.jsonTitle = `${response[0].name} Full ProEdge Transcript_${this.getDateForCSV()}`
      }
    },

    getDateForCSV() {
      const now = new moment()
      return now.format('YYYY-MM-DDThh:mmZ')
    },
    // function to change sorting variables
    applyDataSort(e) {
      if (e.direction == 'descending') this.sortByDirection = 'DESC'
      else if (e.direction == 'ascending') this.sortByDirection = 'ASC'
      switch (e.header.name) {
        case 'Title':
          this.sortBy = 'ARTIFACT_TITLE'
          break
        case 'Duration':
          this.sortBy = 'ARTIFACT_DURATION'
          break
        case 'Completed':
          this.sortBy = 'LPI_COMPLETED_AT'
          break
        case 'Content type':
          this.sortBy = 'ARTIFACT_CONTENT_TYPE'
          break
        case 'Provider':
          this.sortBy = 'ARTIFACT_PROVIDER'
          break
        default:
          this.sortBy = 'LPI_COMPLETED_AT'
      }
      this.getLearningItems({ filters: this.currentFilters }).then(() => {
        this.getLearningFilters({ filters: this.currentFilters })
      })
    },
    async getRequiredLearningCount(data = {}) {
      const payload = {
        token: this.authToken,
        body: {
          pageNo: 1,
          pageSize: 0,
          includeCompleted: true,
          includeNoProgress: false,
          includeInProgress: false,
          trackSource: [],
          contentSource: ['EXTERNAL', 'INTERNAL'],
          includeItemsWithNoDueDate: false,
          sortBy: 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT',
          sortByDirection: this.sortByDirection,
          contentAccessType: 'ALL', // ENUM values : ['ALL', 'INACTIVE', 'ACTIVE']
          dueDate: 'ONLY_WITH_DUE_DATE',
          ...this.generateBody(data)
        }
      }
      const responseData = await this.$learnAdmin.getExternalContentItems(payload)
      this.requiredLearningCount = responseData.totalContent ? responseData.totalContent : 0
    },
    generateBody(data) {
      const obj = {
        contentType: data && data.filters && data.filters['Content type'] ? data.filters['Content type'] : [],
        providers: data && data.filters && data.filters['Provider'] ? data.filters['Provider'] : [],
        duration: data && data.filters && data.filters['Duration'] ? this.setDuration(data.filters['Duration']) : this.setDuration(),
        creditHours: data && data.filters && data.filters['Credit hours'] ? data.filters['Credit hours'] : this.setCreditHours(),
        creditType: data && data.filters && data.filters['Credit type'] ? data.filters['Credit type'] : [],
        accreditation: data && data.filters && data.filters['Accreditation'] ? data.filters['Accreditation'] : [],
        level: data && data.filters && data.filters['Level'] ? data.filters['Level'] : [],
        function: data && data.filters && data.filters['Function'] ? data.filters['Function'] : [],
        tracks: data && data.filters && data.filters['Tracks'] ? data.filters['Tracks'] : [],
        completedAt: data && data.filters && data.filters['Completed date'] ? this.dateDiff(data.filters['Completed date']) : {}
      }
      return obj
    },
    async getLearningItems(data = {}) {
      this.responseReceived = false
      this.items = []
      let learningItems = []
      let body = {
        pageNo: 1,
        pageSize: 0,
        includeCompleted: true,
        includeNoProgress: false,
        includeInProgress: false,
        trackSource: [],
        contentSource: ['EXTERNAL', 'INTERNAL'],
        includeItemsWithNoDueDate: !this.requiredChecked,
        dueDate: this.requiredChecked ? 'ONLY_WITH_DUE_DATE' : 'ALL',
        sortBy: this.sortBy,
        contentAccessType: 'ALL', // ENUM values : ['ALL', 'INACTIVE', 'ACTIVE']
        sortByDirection: this.sortByDirection,
        ...this.generateBody(data)
      }
      this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
      const payload = {
        token: this.authToken,
        body: body
      }

      const responseData = await this.$learnAdmin.getExternalContentItems(payload)
      if (responseData.content && responseData.content !== null) {
        responseData.content.map((item, index) => {
          if (item.completed || ({}.hasOwnProperty.call(item, 'completedAt') && item.completedAt.length)) {
            item.duration = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.duration : 0
            item.format = item.learningItem.format ? item.learningItem.format : item.format
            item.isActive = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.isActive : true
            if (item.source === 'EXTERNAL') {
              item.provider = { name: 'Added by you' }
              item.guid = item.externalArtifactGuid
              item.id = item.learningItem.id
            } else {
              item.id = item.artifactId
            }
            item.formatName = item.format.name || ''
            item.providerName = item.provider.name || ''
            learningItems[index] = item
            if ({}.hasOwnProperty.call(item, 'tracks')) {
              item.tracks.map((track) => {
                if ({}.hasOwnProperty.call(track, 'dueDate') && track.dueDate != null) {
                  item.isRequired = true
                }
              })
            }
          }
        })
        this.items = [...learningItems]
      }
      this.responseReceived = true
      return new Promise((resolve) => {
        resolve(this.items)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.csv-download-details {
  display: flex;
  padding: 2.5rem;
  text-align: left;
  border-radius: 20px;

  @media (max-width: 1366px) {
    flex-wrap: wrap;
  }

  @media (max-width: 500px) {
    padding: 1.5rem 1rem;
  }

  .text-content {
    h3 {
      font: normal normal normal 24px/30px 'Futura PT Demi';
      margin: 0 0 0.5rem 0;
      color: #000000;
    }

    p {
      font: normal normal normal 16px/22px Arial;
      color: var(--ion-color-medium);
      margin: 0;
    }
  }

  .download-btn {
    --ion-color-primary: #feb92e;
    --ion-color-primary-contrast: black;
    --box-shadow: none;
    font: normal normal normal 16px/26px 'Futura PT Demi';
    letter-spacing: 0.02px;

    :disabled {
      --ion-color-primary: #e0e1e1;
    }
  }
}

.personal-details {
  padding: 2.5rem;
  text-align: left;
  border-radius: 1.125rem;

  a {
    color: var(--ion-color-tertiary);
    text-decoration: underline;
    font-family: Arial;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    padding: 1.5rem 1rem;
  }

  margin-top: 20px;

  .personal-info-header {
    margin-bottom: 1.5rem;

    .required-learning-container {
      padding: 0;
    }

    h3 {
      font: normal normal normal 24px/30px 'Futura PT Demi';
      margin: 0;
      color: #000000;
    }

    h6 {
      margin: 0;
    }
  }

  .no-content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .no-content-img {
    width: 345px;
    height: 340px;
  }

  .required-sort-text {
    font-family: Arial;
    font-size: 14px;
    color: #000000de;
  }

  .required-checkbox-inline {
    display: inline;
  }

  .required-checkbox-block {
    display: none;
  }

  @media screen and (max-width: 400px) {
    .required-checkbox-inline {
      display: none;
    }

    .required-checkbox-block {
      display: block;
    }
  }
}
</style>
