export default [
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile/Profile'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  }
]
