<template>
  <div>
    <!-- <div> This component is linked out to https://productcentral.products.pwc.com/legal/Terms-of-Use</div> -->
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>