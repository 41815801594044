var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "skillbuilder-index" },
    [
      _vm.skillbuilder.isCapstone
        ? _c(
            "ion-row",
            { staticClass: "ion-align-items-center" },
            [
              _c(
                "ion-col",
                {
                  staticClass:
                    "skillbuilder-index__title ion-no-padding g-margin__right--msmall"
                },
                [_vm._v("Capstone Project: " + _vm._s(_vm.skillbuilder.title))]
              ),
              _c(
                "ion-col",
                {
                  attrs: { size: "1" },
                  on: {
                    click: function($event) {
                      return _vm.toggleCapstone()
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    class: [
                      !_vm.showBuilder
                        ? ""
                        : "skillbuilder-index__caret-transform"
                    ],
                    attrs: {
                      "icon-class": "dropdown-transcript",
                      "class-name": "g-icon--xsmall"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "ion-row",
            { staticClass: "ion-align-items-center" },
            [
              _c(
                "ion-col",
                {
                  staticClass:
                    "skillbuilder-index__title ion-no-padding g-margin__right--msmall"
                },
                [
                  _vm._v(
                    "Skillbuilder " +
                      _vm._s(_vm.skillbuilder.index) +
                      ": " +
                      _vm._s(_vm.skillbuilder.title)
                  )
                ]
              ),
              _c(
                "ion-col",
                {
                  attrs: { size: "1" },
                  on: {
                    click: function($event) {
                      return _vm.toggleSkillbuilder()
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    class: [
                      !_vm.showBuilder
                        ? ""
                        : "skillbuilder-index__caret-transform"
                    ],
                    attrs: {
                      "icon-class": "dropdown-transcript",
                      "class-name": "g-icon--xsmall"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
      _vm.showBuilder
        ? _c(
            "div",
            _vm._l(_vm.lessons, function(lesson) {
              return _c("CredentialLessonIndex", {
                key: lesson.id,
                attrs: {
                  lesson: lesson,
                  courseId: _vm.courseId,
                  sbid: _vm.skillbuilder.id
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.showBuilder && _vm.skillbuilder.isCapstone
        ? _c(
            "div",
            _vm._l(_vm.capstone, function(lesson, index) {
              return _c("CredentialLessonIndex", {
                key: lesson.id,
                attrs: {
                  lesson: lesson,
                  courseId: _vm.courseId,
                  sbid: _vm.capstone.id,
                  index: index,
                  meta: lesson.meta,
                  isCapstone: true
                }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }