<template>
  <div class="collection-about">
    <div class="collection-desc">
      <div class="collection-title">Description</div>
      <div :class="collection.content.length > 0 ? 'collection-subtitle' : 'collection-subtitle collection-subtitle-margin'" v-html="$sanitize(collection.synopsis)"></div>
      <ion-grid v-if="collection.content.length" class="ion-no-padding">
        <ion-row>
          <ion-col class="list-container" size="12" v-for="(item, index) in collection.content" :key="index">
            <UniversalCard v-if="!(item.scope.isExternal && !isExternalContentEnabled)" :item="item" :index="index" :itemsLength="collection.content.length" @delete-item="deleteExternalItem" :tab="`track`" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>

<script>
import UniversalCard from '@/components/organisms/UniversalCard'
import localforage from 'localforage'
import { mapGetters } from 'vuex'
export default {
  name: 'UGCollectionAbout',
  components: { UniversalCard },
  props: {
    collection: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isExternalContentEnabled: false
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'enabledFeatureFlags']),
  },
  watch: {},
  created() {},
  async mounted() {
    const authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    const tenant = await this.$learnAdmin.getUserTenantInfo({ token: authToken })
    const settings = JSON.parse(tenant.settings)
    const externalTenantSetting = {}.hasOwnProperty.call(settings, 'add-external-content-enabled') && settings['add-external-content-enabled']
    this.isExternalContentEnabled = externalTenantSetting
  },
  methods: {
    deleteExternalItem() {
      this.$emit('on-delete-external-item')  
    }
  }
}
</script>
<style lang="scss" scoped>
.collection-about {
  padding: 8px 0px;
  background-color: #fff;
  border-radius: 20px;
  .collection-title {
    font-family: 'Futura PT Demi';
    font-size: 24px;
    color: black;
    text-align: left;
    border-bottom: 2px solid #e0e1e1;
    padding-bottom: 30px;
  }
  .collection-subtitle {
    text-align: left;
    margin-top: 36px;
    font-family: Arial;
    color: #5c5c5c;
    font-size: 16px;
    line-height: 25px;
  }
  .collection-subtitle-margin {
    margin-bottom: 48px;
  }
}

.collection-desc {
  padding: 30px 100px;
}

@media screen and (max-width: 1477px) {
  .collection-desc {
    padding: 30px 60px;
  }
}

@media screen and (max-width: 1366px) {
  .collection-desc {
    padding: 40px;
  }
}

@media screen and (max-width: 484px) {
  .collection-desc {
    padding: 1rem;
  }
}
</style>
