var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "all-tracks", class: _vm.trackMode }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTrackBar,
              expression: "showTrackBar"
            }
          ]
        },
        [
          _vm.trackMode == "track-list"
            ? _c("TrackList", {
                attrs: { trackList: _vm.getTracksList, addItem: _vm.addItem },
                on: { "show-create-track": _vm.setTrackMode }
              })
            : _vm._e(),
          _vm.trackMode == "no-tracks"
            ? _c("NoTrack", {
                key: _vm.trackMode,
                on: { "show-create-track": _vm.setTrackMode }
              })
            : _vm._e(),
          _vm.trackMode == "add-track"
            ? _c("CreateEditTrack", {
                attrs: { isEditTrack: _vm.isEditTrack, addItem: _vm.addItem },
                on: { "show-create-track": _vm.setTrackMode }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }