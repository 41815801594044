<template>
  <div v-if="dataReady" class="home-container main-content" :style="!isDesktopPlatform ? 'background: #e7e5e5 !important' : ''">
    <div v-if="!isDesktopPlatform && mobileTabVisible" class="overlay" @click="mobileTabVisible = false"></div>
    <div v-if="goalSettingEnabled" class="banner-home">
      <ion-grid class="ion-no-padding" ref="top">
        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding ion-text-start banner-info" v-if="metricsDataReady" size-lg="6" size-md="12" size-sm="12" size-xs="12">
            <h1>Hi, {{ name }}!</h1>
            <div class="banner-info-content" v-if="noPendingItems">
              <div class="no-pending-content">
                <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                <p>You have no pending items.</p>
              </div>
              <div class="banner-info-message">{{ message }}</div>
            </div>
            <div v-else>
              <div class="banner-info-message" v-if="!requiredLearningEnabled">{{ message }}</div>
              <HomeDashboardMetrics :dashboardMetricsData="learningMetrics" />
            </div>
          </ion-col>
          <ion-col size-lg="6" size-md="12" size-sm="12" size-xs="12">
            <HomeGoalSetting @openGoalSettingModal="openGoalSettings" :goalSettingData="goalSettingData" :key=" goalSettingData.goalDays ? goalSettingData.goalDays : 50"></HomeGoalSetting>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="banner-home" v-else>
      <ion-grid class="ion-no-padding" ref="top">
        <ion-row v-if="isDesktopPlatform" class="ion-no-padding">
          <ion-col class="ion-no-padding ion-text-start banner-info" v-if="metricsDataReady" size-lg="6" size-md="6" size-sm="7" size-xs="9">
            <h1>Hi, {{ name }}!</h1>
            <div class="banner-info-content" v-if="noPendingItems">
              <div class="no-pending-content">
                <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                <p>You have no pending items.</p>
              </div>
              <div class="banner-info-message">{{ message }}</div>
            </div>
            <div v-else>
              <div class="banner-info-message" v-if="!requiredLearningEnabled">{{ message }}</div>
              <HomeDashboardMetrics :dashboardMetricsData="learningMetrics" />
            </div>
          </ion-col>
          <ion-col class="ion-no-padding ion-text-start" size-lg="6" size-md="6" size-sm="5" size-xs="3">
            <img :src="require('@/assets/images/home-banner-image.png')" alt="" class="banner-image" />
          </ion-col>
        </ion-row>
        <ion-row v-else class="ion-no-padding">
          <ion-col class="ion-no-padding ion-text-start banner-info" v-if="metricsDataReady" size-lg="12" size-md="12" size-sm="12" size-xs="12">
            <h1>Hi, {{ name }}!</h1>
            <div class="banner-info-content" v-if="noPendingItems">
              <div class="no-pending-content">
                <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                <p>You have no pending items.</p>
              </div>
              <div class="banner-info-message">{{ message }}</div>
            </div>
            <div v-else>
              <div class="banner-info-message" v-if="!requiredLearningEnabled">{{ message }}</div>
              <HomeDashboardMetrics :dashboardMetricsData="learningMetrics" />
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div v-if="dueNextEnabled" class="due-next-container">
      <div class="due-next-header">
        <div class="due-next-title">Due next</div>
        <div class="due-next-view-all-required" @click="GoToMyLearning">View all required learning</div>
      </div>
      <div>
        <UniversalCard :key="dueNextData.id" :item="dueNextData" :index="0" :itemsLength="1" :tab="`home`" />
      </div>
    </div>
    <div v-if="tenantCustomInfo.isAvailable" class="tenant-home" ref="topCustom">
      <div v-if="isDesktopPlatform" style="display: flex" :class="!tenantCustomInfo.isCollapse ? 'tenant-custom-panel-expand' : 'tenant-custom-panel-collapse'">
        <div v-if="!tenantCustomInfo.isCollapse">
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
              <ion-col class="ion-no-padding ion-text-start tenant-info" size-lg="7" size-md="12" size-sm="12" size-xs="12" style="visibility: inherit">
                <div style="position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%)">
                  <div class="tenant-info-name">{{ tenantCustomInfo.info.preHeader }}</div>
                  <div class="tenant-info-header">{{ tenantCustomInfo.info.header }}</div>
                  <div class="tenant-info-description">{{ tenantCustomInfo.info.description }}</div>
                  <div class="tenant-info-links">
                    <span v-for="(link, index) in tenantCustomInfo.info.links" :key="index" @click="onLinkClick(link)">
                      <a v-if="!link.isPELink" :href="link.uri" target="_blank" style="color: unset; background-color: unset">{{ link.text }}</a>
                      <router-link v-else :to="link.uri" style="color: unset; background-color: unset">{{ link.text }}</router-link>
                    </span>
                  </div>
                </div>
              </ion-col>
              <ion-col class="ion-no-padding ion-text-start" size-lg="5" size-md="12" size-sm="12" size-xs="12" style="visibility: inherit">
                <div v-if="tenantCustomInfo.info.media && tenantCustomInfo.info.media.type === 'image'" class="tenant-media">
                  <img :src="tenantCustomInfo.info.media.uri" :alt="tenantCustomInfo.info.media.caption" />
                </div>
                <div v-if="tenantCustomInfo.info.media && tenantCustomInfo.info.media.type === 'video'" @click="onVideoPlay">
                  <video controls poster preload="auto" class="tenant-media" aria-label="watch video">
                    <source :src="tenantCustomInfo.info.media.uri" type="video/mp4" />
                  </video>
                </div>
                <audio v-if="tenantCustomInfo.info.media && tenantCustomInfo.info.media.type === 'audio'" controls :src="tenantCustomInfo.info.media.uri">
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div v-if="tenantCustomInfo.isCollapse">
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
              <ion-col class="ion-no-padding ion-text-start tenant-info" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="visibility: inherit">
                <div style="position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%)">
                  <div class="tenant-info-name">{{ tenantCustomInfo.info.preHeader }}</div>
                  <div class="tenant-info-header ellipsis-lines-2">{{ tenantCustomInfo.info.header }}</div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="tenant-icon-container">
          <div class="tenant-icon">
            <svg-icon :icon-class="!tenantCustomInfo.isCollapse ? 'Icon-feather-chevron-right' : 'Icon-awesome-chevron-down'" @click="onToggle" />
          </div>
        </div>
      </div>
      <div v-else style="display: flex" :class="!tenantCustomInfo.isCollapse ? 'tenant-custom-panel-expand-mobile' : 'tenant-custom-panel-collapse-mobile'">
        <div v-if="!tenantCustomInfo.isCollapse">
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
              <ion-col class="ion-no-padding ion-text-start tenant-info" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="visibility: inherit">
                <div class="tenant-info-name-mobile">{{ tenantCustomInfo.info.preHeader }}</div>
                <div class="tenant-info-header-mobile">{{ tenantCustomInfo.info.header }}</div>
                <div class="tenant-info-description-mobile">{{ tenantCustomInfo.info.description }}</div>
                <div class="tenant-info-links">
                  <div v-for="(link, index) in tenantCustomInfo.info.links" :key="index" style="margin-bottom: 0.5rem" @click="onLinkClick(link)">
                    <a v-if="!link.isPELink" :href="link.uri" target="_blank" style="color: unset; background-color: unset">{{ link.text }}</a>
                    <router-link v-else :to="link.uri" style="color: unset; background-color: unset">{{ link.text }}</router-link>
                  </div>
                </div>
                <div v-if="tenantCustomInfo.info.media && tenantCustomInfo.info.media.type === 'image'" class="tenant-media-mobile">
                  <img
                    v-if="tenantCustomInfo.info.media && tenantCustomInfo.info.media.type === 'image'"
                    :src="tenantCustomInfo.info.media.uri"
                    :alt="tenantCustomInfo.info.media.caption"
                  />
                </div>
                <div v-if="tenantCustomInfo.info.media && tenantCustomInfo.info.media.type === 'video'" @click="onVideoPlay">
                  <video controls poster preload="auto" class="tenant-media-mobile" aria-label="watch video">
                    <source :src="tenantCustomInfo.info.media.uri" type="video/mp4" />
                  </video>
                </div>
                <audio v-if="tenantCustomInfo.info.media && tenantCustomInfo.info.media.type === 'audio'" controls :src="tenantCustomInfo.info.media.uri">
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div v-if="tenantCustomInfo.isCollapse">
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
              <ion-col class="ion-no-padding ion-text-start tenant-info" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="visibility: inherit">
                <div class="tenant-info-name-mobile">{{ tenantCustomInfo.info.preHeader }}</div>
                <div class="tenant-info-header-mobile ellipsis-lines-2">{{ tenantCustomInfo.info.header }}</div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div :class="isDesktopPlatform ? 'tenant-icon-container' : 'tenant-icon-container-mobile'">
          <div class="tenant-icon">
            <svg-icon :icon-class="!tenantCustomInfo.isCollapse ? 'Icon-feather-chevron-right' : 'Icon-awesome-chevron-down'" @click="onToggle" />
          </div>
        </div>
      </div>
    </div>
    <div class="home-content">
      <div v-if="isDesktopPlatform" style="border-bottom: 2px solid var(--ion-color-light-gray)">
        <div class="tabs-container-desktop">
          <button
            v-for="tab in tabs"
            :key="tab.tidx"
            class="tab accessible-button"
            :class="selectedTabIndex === tab.tidx ? 'selected' : ''"
            @click="onTabSelected(tab.tidx)"
            :aria-label="tab.title"
          >
            {{ tab.title }}
          </button>
        </div>
      </div>
      <div v-else class="tabs-container-mobile">
        <div class="selected" @click="onTabToggled">
          {{ tabs[selectedTabIndex].title }}
          <i class="dropdown-arrow"></i>
        </div>
        <ul v-if="mobileTabVisible" class="tabs-selection">
          <li v-for="tab in tabs" :key="tab.tidx" :class="selectedTabIndex === tab.tidx ? 'selected' : ''" :value="tab.title" @click="onTabSelected(tab.tidx)">
            {{ tab.title }}
          </li>
        </ul>
      </div>
      <component :is="component" @scroll-to-top="scrollToTop()" />
    </div>
    <Celebration v-if="showCelebration"></Celebration>
    <div class="carousal-container">
      <PersonalizedCarousal :showCarousal="showPersonalizedCarousal" :carousalType="`personalized`" :recommendations="recommendations" />
      <PersonalizedCarousal
        :showCarousal="showBoostSkills"
        :carousalType="`boostSkills`"
        :recommendations="shortDurationSkill"
        :isActivityContentNotEmpty="isActivityContentNotEmpty"
      />
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import localforage from 'localforage'
import HomeInProgress from '@/views/home/components/HomeInProgress'
import HomeBookmarked from '@/views/home/components/HomeBookmarked'
import HomeAsset from '@/views/home/components/HomeAsset'
import PersonalizedCarousal from '@/views/home/components/PersonalizedCarousal'
import HomeGoalSetting from '@/views/home/components/HomeGoalSetting.vue'
import Celebration from '@/components/organisms/Celebration.vue'
import EventBus from '@/plugins/eventbus'
import UniversalCard from '@/components/organisms/UniversalCard'
import HomeDashboardMetrics from '@/views/home/components/HomeDashboardMetrics'

export default {
  name: 'Home',
  components: { HomeInProgress, HomeBookmarked, HomeAsset, HomeGoalSetting, Celebration, PersonalizedCarousal, UniversalCard, HomeDashboardMetrics },
  props: {},
  data() {
    return {
      authToken: '',
      name: '',
      tabs: [
        { title: 'In progress', tidx: 0, message: '' },
        { title: 'Bookmarked', tidx: 1, message: '' },
        { title: 'Assets', tidx: 2, message: '' }
      ],
      selectedTabIndex: 0,
      isDesktopPlatform: true,
      mobileTabVisible: false,
      dataReady: false,
      tenantAsset: {
        enabled: false,
        userAccessAsset: false
      },
      tenantCustomInfo: {
        isAvailable: false,
        isCollapse: false,
        updateDate: '',
        info: {}
      },
      showGoalSettingModal: false,
      goalSettingData: {},
      goalSettingEnabled: false,
      isExternalContentEnabled: false,
      showCelebration: false,
      recommendations: {
        content: [],
        activityTitle: 'Recommendations based on your activity'
      },
      shortDurationSkill: {
        content: [],
        activityTitle: 'Boost your skills in 30 minutes or less'
      },
      showPersonalizedCarousal: false,
      showBoostSkills: false,
      isActivityContentNotEmpty: false,
      dueNextData: {},
      dueNextEnabled: false,
      requiredLearningEnabled: false,
      learningMetrics: [
        { name: 'Due within 30 days', value: 0, show: true, id: 'dueWithin30DaysCount', redirectTo: 'required' },
        { name: 'Past due', value: 0, show: true, id: 'pastDueCount', redirectTo: 'required' },
        { name: 'Required', value: 0, show: true, id: 'requiredItemsCount', redirectTo: 'required' },
        { name: ' Recommended by your company', value: 0, show: true, id: 'recommendedItemsCount', redirectTo: 'recommended' },
        { name: 'Added by you', value: 0, show: true, id: 'addedByYouItemsCount', redirectTo: 'addedbyyou' }
      ],
      noPendingItems: false,
      metricsDataReady: false
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'enabledFeatureFlags']),
    message() {
      return this.tabs[this.selectedTabIndex].message
    },
    component() {
      const components = this.tabs.length == 3 ? ['HomeInProgress', 'HomeBookmarked', 'HomeAsset'] : ['HomeInProgress', 'HomeBookmarked']
      if (components[this.selectedTabIndex] === 'HomeAsset') {
        this.onAssetTabSelected()
      }
      return components[this.selectedTabIndex]
    }
  },
  watch: {
    mobileTabVisible(oldVal, newVal) {
      if (newVal) {
        document.getElementById('contentlayout').classList.remove('no-scroll')
      } else {
        document.getElementById('contentlayout').classList.add('no-scroll')
      }
    },
    async $route(to, from) {
      if (to.path.includes('home') && from.path.includes('mylearning')) {
        await localforage.setItem('selected_tab_index', 0)
      }
    }
  },
  deactivated() {
    this.recommendations.content = []
    this.shortDurationSkill.content = []
    this.showPersonalizedCarousal = false
    this.showBoostSkills = false
    this.mobileTabVisible = false
  },
  created() {
    window.addEventListener('beforeunload', async () => await localforage.setItem('selected_tab_index', 0))
  },
  async beforeMount() {
    const isTenantCustomInfoCollapse = await localStorage.getItem('is_tenant_custom_info_collapse')
    if (isTenantCustomInfoCollapse) {
      const info = isTenantCustomInfoCollapse.split('|')
      this.tenantCustomInfo.isCollapse = info[0] === 'true'
      this.tenantCustomInfo.updateDate = info[1] || ''
    }
    localforage.getItem('ssoRedirect').then((token) => {
      if (![null, ''].includes(token)) {
        EventBus.$emit('reload-nav-bar-for-sso-user');
      }
    })
  },
  async activated() {
    await this.setup()
    this.dataReady = true
    analytics.setVarAndTrack({ c: 'landing', a: 'loaded', l: 'home' })
    if (this.isDesktopPlatform) {
      const container = document.querySelector('#contentlayout')
      container.scrollTo(0, 0)
    } else {
      this.scrollToTop()
    }
    EventBus.$on('active-days-updated', this.updateGoalSettingsObj)
    this.$eventBus.$on('close-goal-settings-modal', this.onSaveGoalSetting)
    await this.getRequiredLearningPlanItems()
    await this.getRecommendedContent()
  },
  methods: {
    async getRequiredLearningPlanItems() {
      this.requiredLearningPlanItems = await this.$learnAdmin.getMyLearningPlanRequiredItems({ token: this.authToken })
      this.requiredLearningPlanItems = this.requiredLearningPlanItems && this.requiredLearningPlanItems.code === 200 ? this.requiredLearningPlanItems.data : {}
    },
    async getRecommendedContent() {
      const personalizedResponse = await this.$learnAdmin.getRecommendationActivity({ token: this.authToken })
      this.showPersonalizedCarousal = true
      if (personalizedResponse.length > 0) {
        this.isActivityContentNotEmpty = true
        this.recommendations.content = await this.getContentArray(personalizedResponse)
      }
      const shortDurationResponse = await this.$learnAdmin.getShortDurationSkill({ token: this.authToken })
      this.showBoostSkills = true
      if (shortDurationResponse.length > 0) {
        this.shortDurationSkill.content = await this.getContentArray(shortDurationResponse)
      }
    },
    async getContentArray(response) {
      const contentArray = []
      response.forEach(async (item) => {
        item.duration = item.duration < 3600 ? `${Math.round(item.duration / 60)} min` : `${Math.round(item.duration / 3600)} hrs`
        item.duration = item.duration.length < 6 ? `${item.duration}` : item.duration
        item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item?.publishedAt : ''
        item.tracks = []
        item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : ''
        if (this.requiredLearningPlanItems[item.id]) await this.getDueDate(item, this.requiredLearningPlanItems[item.id])
        contentArray.push(item)
      })
      return contentArray
    },
    openGoalSettings() {
      this.showGoalSettingModal = true
      this.$eventBus.$emit('show-goal-settings-modal', this.goalSettingData)
    },
    updateGoalSettingsObj(obj) {
      this.goalSettingData = obj
    },
    scrollToTop() {
      this.$nextTick(() => {
        if (this.tenantCustomInfo.isAvailable) {
          this.$refs.topCustom.scrollIntoView()
        } else {
          this.$refs.top.scrollIntoView()
        }
      })
    },
    async setup() {
      this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.selectedTabIndex = this.$route.params.showInProgress ? 0 : this.isDesktopPlatform ? (await localforage.getItem('selected_tab_index')) || 0 : 0
      this.name = await localforage.getItem('my_name')
      this.name = this.name.split(' ')[0]
      this.$learnAdmin.getUserLearningHistory({ token: this.authToken })
      await this.determineUserAccess()
      await this.getGoalSettingData()
      await this.getRequiredLearningContent()
    },
    onSaveGoalSetting(data) {
      this.showGoalSettingModal = false
      this.goalSettingData = data
    },
    async getGoalSettingData() {
      this.$learnAdmin.getUserActivity({ token: this.authToken }).then((res) => {
        this.goalSettingData = res
      })
    },
    async getDashboardMetrics() {
      const res = await this.$learnAdmin.getDashboardMetrics({ token: this.authToken })
      if (res && {}.hasOwnProperty.call(res, 'recommendedItemsCount')) {
        this.learningMetrics.map((item) => {
          if ({}.hasOwnProperty.call(res, item.id)) {
            item.value = res[item.id] === null ? 0 : res[item.id]
          }
          if (
            (!this.requiredLearningEnabled && ['pastDueCount', 'dueWithin30DaysCount', 'requiredItemsCount'].includes(item.id)) ||
            (!this.isExternalContentEnabled && item.id === 'addedByYouItemsCount')
          ) {
            item.show = false
          }
        })
        this.learningMetrics = this.learningMetrics.filter((item) => item.show)
        const result = this.learningMetrics.reduce((acc, item) => {
          acc = acc + item.value
          return acc
        }, 0)
        result > 0 ? (this.noPendingItems = false) : (this.noPendingItems = true)
        this.metricsDataReady = true
      }
    },
    async getRequiredLearningContent() {
      let payload = {
        token: this.authToken,
        body: {
          pageNo: 1,
          pageSize: 1,
          includeCompleted: false,
          includeNoProgress: true,
          includeInProgress: true,
          includeItemsWithNoDueDate: false,
          includeSelfLearned: false,
          contentType: [],
          providers: [],
          duration: [],
          creditHours: [],
          creditType: [],
          accreditation: [],
          level: [],
          function: [],
          tracks: [],
          requiredLearningPlanItems: {},
          sortBy: 'DUE_DATE_THEN_DUE_DATE_ASSIGNED_AT',
          dueDate: 'ONLY_WITH_DUE_DATE',
          contentSource: ['INTERNAL'],
          trackSource: ['PLAN']
        }
      }
      const result = await this.$learnAdmin.getMyLearningPlanItemsWithFilters(payload)
      this.dueNextEnabled = this.dueNextEnabled && result && result?.content && result.content.length
      this.dueNextData = await this.getTransformData(result)
    },
    async determineUserAccess() {
      const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(tenant.settings)
      const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
      this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
      this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      const goalSettingFFlag = enabledFeatureFlags['learn_goal-settings-enabled'] ? enabledFeatureFlags['learn_goal-settings-enabled'] : false
      const externalTenantSetting = {}.hasOwnProperty.call(settings, 'add-external-content-enabled') && settings['add-external-content-enabled']
      this.isExternalContentEnabled = externalTenantSetting
      this.goalSettingEnabled = {}.hasOwnProperty.call(settings, 'goal-settings-enabled') && settings['goal-settings-enabled'] && goalSettingFFlag
      this.dueNextEnabled = enabledFeatureFlags['learn_required_learning_enabled'] && {}.hasOwnProperty.call(settings, 'required-learning') && settings['required-learning']
      this.requiredLearningEnabled =
        enabledFeatureFlags['learn_required_learning_enabled'] && {}.hasOwnProperty.call(settings, 'required-learning') && settings['required-learning']
      await this.getTenantCustomInfo(tenant)
      const message = 'Ready to take your skills to the next level? Pick up where you left off below, or explore all the ways you can move yourself ahead and maximize your impact.'
      this.tabs =
        !this.tenantAsset.userAccessAsset || !this.tenantAsset.enabled
          ? [
              { title: 'In progress', tidx: 0, message: message },
              { title: 'Bookmarked', tidx: 1, message: message }
            ]
          : [
              { title: 'In progress', tidx: 0, message: message },
              { title: 'Bookmarked', tidx: 1, message: message },
              { title: 'Assets', tidx: 2, message: message }
            ]
      await this.getDashboardMetrics()
    },
    async getTenantCustomInfo(tenant) {
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      this.tenantCustomInfo.isAvailable = enabledFeatureFlags['learn_tenant-custom-configuration'] ? enabledFeatureFlags['learn_tenant-custom-configuration'] : false
      this.tenantCustomInfo.isAvailable = this.tenantCustomInfo.isAvailable && {}.hasOwnProperty.call(tenant, 'customHomePageInfo') && tenant.customHomePageInfo
      if (this.tenantCustomInfo.isAvailable) {
        const expire = new Date(tenant.customHomePageInfo.expiresAt)
        const now = new Date()
        this.tenantCustomInfo.isAvailable = !tenant.customHomePageInfo.expiresAt || now <= expire
        if (this.tenantCustomInfo.isAvailable) {
          this.tenantCustomInfo.info = tenant.customHomePageInfo
          if (this.tenantCustomInfo.updateDate.length && this.tenantCustomInfo.updateDate != this.tenantCustomInfo.info.updatedAt) {
            this.tenantCustomInfo.updateDate = this.tenantCustomInfo.info.updatedAt
            this.tenantCustomInfo.isCollapse = false
          }
          this.tenantCustomInfo.info.media.type = tenant.customHomePageInfo.media.uri.includes('.mp4')
            ? 'video'
            : tenant.customHomePageInfo.media.uri.includes('.mp4')
            ? 'audio'
            : 'image'
          this.tenantCustomInfo.info.media.uri = `https://mjnovs.proedge.pwc.com/dev/tenant-configuration/${tenant.customHomePageInfo.media.uri}`
          this.tenantCustomInfo.info.media.onPlay = false
          this.tenantCustomInfo.info.links = await this.tenantCustomInfo.info.links.map((link) => {
            const isPELink = link.uri.includes('learn') && link.uri.includes('proedge.pwc.com')
            if (isPELink) {
              link.uri = link.uri.split('//')[1]
              const params = link.uri.split('/')
              params.shift()
              link.uri = '/' + params.join('/')
            }
            return {
              text: link.text,
              uri: link.uri,
              isPELink: isPELink
            }
          })
        }
      }
    },
    async getTransformData(result) {
      let item = {}
      if (result && result?.content && result.content.length) {
        item = result.content[0]
        const ratingDetailsArray = (await this.$learnAdmin.getContentRatingById({ token: this.authToken, body: [result.content[0].artifactId] })) || []
        item.id = item.artifactId
        item.image = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.image : ''
        item.meta = {}.hasOwnProperty.call(item, 'learningItem') && {}.hasOwnProperty.call(item.learningItem, 'meta') ? { ...item.meta, ...item.learningItem.meta } : item.meta
        item.assetType = item.format.name.toLowerCase() === 'credential' ? 'credentials' : item.format.name.toLowerCase() === 'automation' ? 'automation' : 'artifacts'
        item.numComments = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numComments : 0
        item.timeToProgressSyncInHours = item.provider.timeToProgressSyncInHours
        item.numLikes = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.numLikes : 0
        item.provider = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.provider : item.provider
        item.emsiSkills = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.emsiSkills : []
        item.synopsis = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.synopsis : ''
        item.duration = !{}.hasOwnProperty.call(item, 'learningItem')
          ? null
          : item.learningItem.durationMinutes < 60
          ? `${item.learningItem.durationMinutes} min`
          : `${Math.round(item.learningItem.durationMinutes / 60)} hrs`
        item.progressApproximation = {}.hasOwnProperty.call(item, 'learningItem') ? item.learningItem.progressApproximation : null
        item.launchBtn =
          item.assetType != 'automation'
            ? item.progress > 0 && item.progress < 100
              ? 'Resume'
              : 'Launch'
            : item.learningItem.author.toLowerCase() != 'proedge'
            ? 'Open'
            : 'Download'
        item.scope = { isExternal: item.assetType === 'automation' && item.learningItem.author.toLowerCase() != 'proedge' }
        item.publishedAt = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'publishedAt') ? item.learningItem.publishedAt : '') : ''
        item.requiredBy = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'requiredBy') ? item.learningItem.requiredBy : '') : ''
        item.content = {}.hasOwnProperty.call(item, 'learningItem') ? ({}.hasOwnProperty.call(item.learningItem, 'content') ? item.learningItem.content : '') : ''
        item.rating =
          ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.id)
            ? ratingDetailsArray.filter((rating) => rating.entityId == item.id)[0]?.avgRating
            : 0
        item.selfProgressDeclaration = {}.hasOwnProperty.call(item, 'learningItem')
          ? {}.hasOwnProperty.call(item.learningItem, 'selfProgressDeclaration')
            ? item.learningItem.selfProgressDeclaration
            : false
          : false
        item.level = {}.hasOwnProperty.call(item, 'learningItem')
          ? item.format.name.toLowerCase() === 'credential'
            ? {}.hasOwnProperty.call(item.learningItem, 'level')
              ? item.learningItem.level
              : ''
            : {}.hasOwnProperty.call(item.learningItem, 'courseLevels')
            ? item.learningItem.courseLevels[0]
            : ''
          : ''
        item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
        const dueDates = []
        if ({}.hasOwnProperty.call(item, 'tracks')) {
          item.tracks.map((track) => {
            if ({}.hasOwnProperty.call(track, 'dueDate') && track.dueDate != null) {
              dueDates.push(new Date(track.dueDate))
            }
          })
        }
        if (dueDates.length > 0) {
          const minDate = moment(Math.min.apply(null, dueDates))
          await this.getDueDate(item, minDate)
        }
      }
      return item
    },
    GoToMyLearning() {
      this.$router.push({ name: 'MyLearning', params: { type: 'required', isNotFromDeepLink: true } }).catch(() => {})
    },
    async getDueDate(item, dueDate) {
      const formattedToday = moment(dueDate).tz('America/New_York').format('DD MMM YYYY')
      if (item.timeToProgressSyncInHours > 0) {
        moment(dueDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
          ? (item.dueDate = formattedToday)
          : (item.pastDueDate = formattedToday)
      } else {
        moment(dueDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
      }
    },
    async onTabSelected(index) {
      this.mobileTabVisible = false
      this.selectedTabIndex = index
      await localforage.setItem('selected_tab_index', index)
    },
    async onToggle() {
      this.tenantCustomInfo.isCollapse = !this.tenantCustomInfo.isCollapse
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        action: this.tenantCustomInfo.isCollapse ? 'Collapse' : 'Expand'
      }
      const payload = {
        action: 'Tenant Custom Info',
        category: 'Collapse/Expand Event',
        value: JSON.stringify(item)
      }
      await this.GAEventTrack(payload)
      await localStorage.setItem('is_tenant_custom_info_collapse', this.tenantCustomInfo.isCollapse + '|' + this.tenantCustomInfo.info.updatedAt)
    },
    onTabToggled() {
      this.mobileTabVisible = !this.mobileTabVisible
    },
    async onAssetTabSelected() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid
      }
      const payload = {
        action: 'Asset',
        category: 'Home Asset Tab Click',
        value: JSON.stringify(item)
      }
      await this.GAEventTrack(payload)
    },
    async onLinkClick(link) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'link-text': link.text,
        'link-uri': link.uri
      }
      const payload = {
        action: 'Tenant Custom Info',
        category: 'Link Click',
        value: JSON.stringify(item)
      }
      await this.GAEventTrack(payload)
    },
    async onVideoPlay() {
      this.tenantCustomInfo.info.media.onPlay = !this.tenantCustomInfo.info.media.onPlay
      if (this.tenantCustomInfo.info.media.onPlay) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const item = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          'video-caption': this.tenantCustomInfo.info.media.caption,
          'video-file': this.tenantCustomInfo.info.media.uri
        }
        const payload = {
          action: 'Tenant Custom Info',
          category: 'Video Watch',
          value: JSON.stringify(item)
        }
        await this.GAEventTrack(payload)
      }
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.home-item-container {
  padding: 0 6.5rem !important;
}

.home-asset-container {
  padding: 2.5rem 6.5rem !important;
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .home-item-container {
    padding: 0 5.5rem !important;
  }

  .home-asset-container {
    padding: 2.5rem 5.5rem !important;
  }
}

@media screen and (min-width: 868px) and (max-width: 1024px) {
  .home-item-container {
    padding: 0 4.5rem !important;
  }
  .home-asset-container {
    padding: 2.5rem 4.5rem !important;
  }
}

@media screen and (max-width: 867px) {
  .home-item-container {
    padding: 0 2.5rem !important;
  }
  .home-asset-container {
    padding: 2.5rem 2.5rem !important;
  }
}

@media screen and (max-width: 484px) {
  .home-item-container {
    padding: 0 10px !important;
  }
  .home-asset-container {
    padding: 2.5rem 2.5rem !important;
  }
}
</style>

<style lang="scss" scoped>
.home-container {
  padding: 2rem 3.8rem;
}

.banner-home {
  padding: 2rem 6.5rem;
  font-family: 'Futura PT Demi';
  font-size: 1rem;
  color: var(--ion-color-medium);
  background: var(--ion-color-white);
  border-radius: 20px;
  margin-bottom: 3rem;

  h1 {
    font-family: 'Futura PT Demi';
    font-size: 3rem;
    color: var(--ion-color-black);
    margin: 0;
  }
}

.banner-info {
  padding-right: 3rem;
}

.no-pending-content {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  p {
    margin: 0;
    font: normal normal normal 16px/23px Arial;
    color: #000000;
    @media (max-width: 500px) {
      font: normal normal normal 14px/23px Arial;
    }
  }
}
.banner-info-message {
  font: normal normal normal 16px/22px Arial;
  margin-top: 1.25rem;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tenant-home {
  font-size: 1rem;
}

.home-content {
  padding: 1rem 0;
  width: 100%;
  min-height: 500px;
  background-color: var(--ion-color-white);
  border-radius: 20px;
}

.tenant-custom-panel-expand,
.tenant-custom-panel-collapse {
  margin-bottom: 2rem;
  width: 100%;
  height: 360px;
  padding: 3rem;
  background-color: var(--ion-color-white);
  border-radius: 20px;
}

.tenant-custom-panel-expand {
  height: 360px;
}

.tenant-custom-panel-collapse {
  height: 200px;
}

.tenant-custom-panel-expand-mobile,
.tenant-custom-panel-collapse-mobile {
  margin-bottom: 2rem;
  width: 100%;
  padding: 1rem;
  background-color: var(--ion-color-white);
  border-radius: 20px;
}

.tenant-custom-panel-expand-mobile {
  min-height: 360px;
}

.tenant-custom-panel-collapse-mobile {
  min-height: 150px;
}

.tenant-info {
  padding: 0 3rem;

  .tenant-info-name {
    font-size: 1.5rem;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-black);
  }

  .tenant-info-header {
    font-size: 32px;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-tertiary);
    margin: 1rem 0rem;
  }

  .tenant-info-description {
    margin: 0rem;
    font-size: 16px;
    font-family: 'Arial';
    line-height: 1.4rem;
    color: var(--ion-color-black);
  }

  .tenant-info-links {
    margin: 1rem 0rem;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-tertiary);
    span {
      margin-right: 2rem;

      a {
        text-decoration: unset;
        font-size: 16px !important;
      }

      a:hover,
      a:focus {
        text-decoration: underline;
      }
    }
  }

  .tenant-info-name-mobile {
    font-size: 16px;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-black);
  }

  .tenant-info-header-mobile {
    font-size: 20px;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-tertiary);
    margin: 1rem 0rem;
  }

  .tenant-info-description-mobile {
    margin: 0rem;
    font-size: 12px;
    font-family: 'Arial';
    line-height: 1.4rem;
    color: var(--ion-color-black);
  }
}

.tenant-media {
  height: 270px;
  aspect-ratio: 16 / 9;
  border-radius: 20px;
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}

.tenant-media-mobile {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 20px;
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}

.tenant-icon-container {
  min-width: 2rem;
  margin-left: auto;
  margin-top: -2rem;
  margin-right: -2rem;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .tenant-icon {
    padding-top: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px solid var(--ion-color-dark-grey);
  }

  .tenant-icon:hover {
    background-color: #efefef;
  }
}

.tenant-icon-container-mobile {
  min-width: 0rem;
  margin-top: 0;
  position: relative;
  right: 0.5rem;

  .tenant-icon {
    border: none;
  }
}

.tabs-container-desktop {
  display: flex;
  padding: 0 6.5rem;
  .tab {
    display: inline-block;
    padding: 0.5rem !important;
    color: var(--ion-color-black);
    cursor: pointer;
    font: normal normal bold 16px Arial;
    margin: 0 1.5rem 0 0;
    &.selected {
      border-bottom: 5px solid #00d1b0;
    }
  }
}

.tabs-container-mobile {
  position: relative;
  margin: 0 0.5rem;
  padding: 0rem;
  text-align: left;

  .selected {
    display: inline-block;
    padding-bottom: 0.5rem;
    color: var(--ion-color-black);
    border-bottom: 3px solid #00d1b0;
    font: normal normal bold 16px Arial;

    .dropdown-arrow {
      display: inline-block;
      margin-left: 0.6rem;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid var(--ion-color-black);
    }
  }
}

.tabs-selection {
  position: absolute;
  background: var(--ion-color-white);
  padding: 0;
  left: -8%;
  width: 100vw;
  z-index: 10;
  border-radius: 10px;

  li {
    list-style-type: none;
    color: var(--ion-color-black);
    padding: 0.8rem 0.2rem;
    margin: 1rem 0 0;
    border-bottom: 1px solid var(--ion-color-light-role-grey);
    text-align: center;
    border-radius: 6px;
    width: 100vw;
    font: normal normal bold 16px Arial;

    span {
      margin-left: 0.3rem;
      padding: 0rem 0.7rem;
      border-radius: 1rem;
      background: var(--ion-color-light-role-grey);
      font-size: 18px;
      font-family: Arial;
      color: var(--ion-color-black);
    }

    &.selected {
      background-color: rgba(0, 209, 176, 0.5);

      span {
        background: var(--ion-color-black);
        color: var(--ion-color-white);
      }
    }
  }
}

.overlay {
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 210vh;
  z-index: 5;
}

.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
}

.carousal-container {
  margin-top: 3rem;
}

.due-next-container {
  margin-bottom: 3rem;
  background: #ffffff;
  padding: 2rem 6.5rem 0;
  border-radius: 20px;
}

.due-next-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e0e1e1;
  align-items: center;
}

.due-next-title {
  font-size: 24px;
  font-family: 'Futura PT Demi';
  color: #000000;
}

.due-next-view-all-required {
  font-size: 16px;
  font-family: 'Futura PT Demi';
  color: #1f3370;
  cursor: pointer;
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .tabs-container-desktop {
    padding: 0 5.5rem;
  }

  .home-container {
    padding: 2rem;
  }

  .banner-info {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .banner-image {
    width: 94%;
    height: 100%;
    object-fit: cover;
    margin-left: 2rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1240px) {
  .tenant-info {
    .tenant-info-header {
      font-size: 20px;
      margin: 0.5rem 0rem;
    }
  }

  .tenant-media {
    height: 200px;
    aspect-ratio: 16 / 9;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 868px) and (max-width: 1024px) {
  .tabs-container-desktop {
    padding: 0 4.5rem;
  }

  .home-container {
    padding: 2rem 1.5rem;
  }

  .banner-info {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .banner-image {
    position: relative;
    width: 100%;
    height: 90%;
    top: 20px;
  }
}

@media screen and (max-width: 867px) {
  .tabs-container-desktop {
    padding: 0 2.5rem;
  }

  .home-container {
    padding: 1rem 1rem 3rem;
  }

  .banner-info {
    padding-right: 1rem;
    padding-bottom: 3rem;
  }

  .banner-image {
    position: relative;
    width: 100%;
    height: 75%;
    top: 55px;
  }

  .home-content {
    padding: 1rem 0.2rem;
  }
}

@media screen and (min-width: 414px) and (max-width: 992px) {
  .tenant-custom-panel-expand {
    height: 500px !important;
  }
  .tenant-custom-panel-collapse {
    height: 200px !important;
  }

  .tenant-info {
    .tenant-info-header {
      font-size: 20px;
      margin: 0.5rem 0rem;
    }
  }

  .tenant-media {
    height: 200px;
    aspect-ratio: 16 / 9;
    margin-top: 1rem;
    margin-left: 2.5rem;
  }

  .tenant-icon-container {
    min-width: 0rem;
    .tenant-icon {
      border: none;
    }
  }
}

@media screen and (min-width: 414px) and (max-width: 780px) {
  .banner-home {
    padding: 2rem 1rem;
  }

  .due-next-container {
    padding: 2rem 1rem 0;
  }

  .tenant-info {
    padding: 0rem;
  }

  .tenant-media {
    height: 200px;
    aspect-ratio: 16 / 9;
    margin-top: 1rem;
    margin-left: 2.5rem;
  }

  .tenant-icon-container {
    min-width: 0rem;
    .tenant-icon {
      border: none;
    }
  }
}

@media screen and (max-width: 414px) {
  .banner-home {
    padding: 2rem 1rem;
  }
  .due-next-container {
    padding: 2rem 1rem 0;
  }
  .tenant-info {
    padding: 0rem;
  }
  .tenant-icon-container {
    margin-top: 0;
  }
}
</style>
