<template>
  <div :class="$platform === 'Desktop' ? 'container-desktop' : 'container-mobile'">
    <div v-if="selectedTabIndex == 0">
      <ion-grid>
        <div v-if="isDesktopPlatform">
          <UniversalCard
            v-for="(item, index) in data.required.items.slice(data.required.start, data.required.end)"
            :key="index + item.id"
            :item="item"
            :index="index"
            :itemsLength="data.required.items.slice(data.required.start, data.required.end).length"
            :tab="tabName"
            @learn-item-click="onLearnItemClick"
          />
          <div v-if="data.required.items.length" style="border-top: 2px solid #e0e1e1">
            <paginate
              v-model="data.required.pageCurrent"
              :page-count="data.required.pageCount"
              :page-range="pageRangeRequired"
              :margin-pages="1"
              :click-handler="handleRequiredPageClick"
              :container-class="'pagination'"
              :page-class="'pagination-item'"
              :page-link-class="'pagination-item-link'"
              :prev-text="'&lsaquo;'"
              :prev-class="'pagination-nav'"
              :prev-link-class="'pagination-nav-link'"
              :next-text="'&rsaquo;'"
              :next-class="'pagination-nav'"
              :next-link-class="'pagination-nav-link'"
              :hide-prev-next="true"
            />
          </div>
        </div>
        <ion-row v-if="isDesktopPlatform && data.required.items.length === 0">
          <ion-col size-xl="5" size="6">
            <div class="title title-black">No due dates yet</div>
            <div class="sub-title">
              Any learning required by your company will appear here in the future. For now, check out your
              <span class="link-btn-recommended g-clickable" @click="goToRecommended">Recommended</span> list for other options.
            </div>
          </ion-col>
          <ion-col size-xl="6" size="6">
            <img src="@/assets/images/my-learning-required.png" />
          </ion-col>
        </ion-row>
        <ion-row v-else>
           <div v-if="!isDesktopPlatform">
            <UniversalCard
              v-for="(item, index) in data.required.items.slice(data.required.start, data.required.end)"
              :key="index + item.id"
              :item="item"
              :index="index"
              :itemsLength="data.required.items.slice(data.required.start, data.required.end).length"
              @learn-item-click="onLearnItemClick"
              :tab="tabName"
              current-tab="Required"
            />
            <div v-if="data.required.items.length" style="border-top: 2px solid #e0e1e1">
              <paginate
                v-model="data.required.pageCurrent"
                :page-count="data.required.pageCount"
                :page-range="pageRangeRequired"
                :margin-pages="1"
                :click-handler="handleRequiredPageClick"
                :container-class="'pagination'"
                :page-class="'pagination-item'"
                :page-link-class="'pagination-item-link'"
                :prev-text="'&lsaquo;'"
                :prev-class="'pagination-nav'"
                :prev-link-class="'pagination-nav-link'"
                :next-text="'&rsaquo;'"
                :next-class="'pagination-nav'"
                :next-link-class="'pagination-nav-link'"
                :hide-prev-next="true"
              />
            </div>
          </div>
          <ion-col size="12" v-if="data.required.items.length === 0">
            <div class="title title-black">No due dates yet</div>
            <div class="sub-title">
              Any learning required by your company will appear here in the future. For now, check out your
              <span class="link-btn-recommended g-clickable" @click="goToRecommended">Recommended</span> list for other options.
            </div>
          </ion-col>
          <ion-col size="12" v-if="data.required.items.length === 0">
            <img src="@/assets/images/my-learning-required.png" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div v-if="selectedTabIndex == 1">
      <ion-grid>
        <div v-if="isDesktopPlatform">
          <UniversalCard
            v-for="(item, index) in data.learning.items.slice(data.learning.start, data.learning.end)"
            :key="index + item.id"
            :item="item"
            :index="index"
            :itemsLength="data.learning.items.slice(data.learning.start, data.learning.end).length"
            :tab="tabName"
            @learn-item-click="onLearnItemClick"
          />
          <div v-if="data.learning.items.length" style="border-top: 2px solid #e0e1e1">
            <paginate
              v-model="data.learning.pageCurrent"
              :page-count="data.learning.pageCount"
              :page-range="pageRangeLearning"
              :margin-pages="1"
              :click-handler="handleLearningPlanPageClick"
              :container-class="'pagination'"
              :page-class="'pagination-item'"
              :page-link-class="'pagination-item-link'"
              :prev-text="'&lsaquo;'"
              :prev-class="'pagination-nav'"
              :prev-link-class="'pagination-nav-link'"
              :next-text="'&rsaquo;'"
              :next-class="'pagination-nav'"
              :next-link-class="'pagination-nav-link'"
              :hide-prev-next="true"
            />
          </div>
        </div>
        <ion-row v-if="isDesktopPlatform && data.learning.items.length === 0">
          <ion-col size="6">
            <div class="title">No recommended items yet</div>
            <div class="sub-title">Browse our <span class="link-btn g-clickable" @click="goToExplore">Explore section</span> to discover content based on your interests.</div>
          </ion-col>
          <ion-col size="6">
            <img src="@/assets/images/my-learning-completed.png" />
          </ion-col>
        </ion-row>
        <ion-row v-else class="ion-align-items-center">
          <div v-if="!isDesktopPlatform">
            <UniversalCard
              v-for="(item, index) in data.learning.items.slice(data.learning.start, data.learning.end)"
              :key="index + item.id"
              :item="item"
              :index="index"
              :itemsLength="data.learning.items.slice(data.learning.start, data.learning.end).length"
              @learn-item-click="onLearnItemClick"
              :tab="tabName"
              current-tab="Learning plan"
            />
            <div v-if="data.learning.items.length" style="border-top: 2px solid #e0e1e1">
              <paginate
                v-model="data.learning.pageCurrent"
                :page-count="data.learning.pageCount"
                :page-range="pageRangeLearning"
                :margin-pages="1"
                :click-handler="handleLearningPlanPageClick"
                :container-class="'pagination'"
                :page-class="'pagination-item'"
                :page-link-class="'pagination-item-link'"
                :prev-text="'&lsaquo;'"
                :prev-class="'pagination-nav'"
                :prev-link-class="'pagination-nav-link'"
                :next-text="'&rsaquo;'"
                :next-class="'pagination-nav'"
                :next-link-class="'pagination-nav-link'"
                :hide-prev-next="true"
              />
            </div>
          </div>
          <ion-col size="10" v-if="data.learning.items.length === 0">
            <div class="title">No recommended items yet</div>
            <div class="sub-title">Browse our <span class="link-btn g-clickable" @click="goToExplore">Explore section</span> to discover content based on your interests.</div>
          </ion-col>
          <ion-col size="12" v-if="data.learning.items.length === 0">
            <img src="@/assets/images/my-learning-completed.png" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div v-if="selectedTabIndex == 2">
      <ion-grid>
        <div v-if="isDesktopPlatform">
          <UniversalCard
            v-for="(item, index) in data.external.items.slice(data.external.start, data.external.end)"
            :key="index + item.id"
            :item="item"
            :index="index"
            isUCTClickAllowed
            :itemsLength="data.external.items.slice(data.external.start, data.external.end).length"
            @learn-item-click="onLearnItemClick"
            :tab="tabName"
            @delete-item="deleteExternalItem"
            current-tab="Added by you"
          />
          <div v-if="data.external.items.length" style="border-top: 2px solid #e0e1e1">
            <paginate
              v-model="data.external.pageCurrent"
              :page-count="data.external.pageCount"
              :page-range="pageRangeExternal"
              :margin-pages="1"
              :click-handler="handleAddedByYouPageClick"
              :container-class="'pagination'"
              :page-class="'pagination-item'"
              :page-link-class="'pagination-item-link'"
              :prev-text="'&lsaquo;'"
              :prev-class="'pagination-nav'"
              :prev-link-class="'pagination-nav-link'"
              :next-text="'&rsaquo;'"
              :next-class="'pagination-nav'"
              :next-link-class="'pagination-nav-link'"
              :hide-prev-next="true"
            />
          </div>
        </div>
        <div v-if="!isDesktopPlatform" style="width: 100%">
          <UniversalCard
            v-for="(item, index) in data.external.items.slice(data.external.start, data.external.end)"
            :key="index + item.id"
            :item="item"
            :index="index"
            :itemsLength="data.external.items.slice(data.external.start, data.external.end).length"
            @delete-item="deleteExternalItem"
            :tab="tabName"
            @learn-item-click="onLearnItemClick"
            current-tab="Added by you"
          />
          <div v-if="data.external.items.length" style="border-top: 2px solid #e0e1e1">
            <paginate
              v-model="data.external.pageCurrent"
              :page-count="data.external.pageCount"
              :page-range="pageRangeExternal"
              :margin-pages="1"
              :click-handler="handleAddedByYouPageClick"
              :container-class="'pagination'"
              :page-class="'pagination-item'"
              :page-link-class="'pagination-item-link'"
              :prev-text="'&lsaquo;'"
              :prev-class="'pagination-nav'"
              :prev-link-class="'pagination-nav-link'"
              :next-text="'&rsaquo;'"
              :next-class="'pagination-nav'"
              :next-link-class="'pagination-nav-link'"
              :hide-prev-next="true"
            />
          </div>
        </div>
        <ion-row v-if="data.external.items.length === 0" class="ion-align-items-center">
          <ion-col>
            <MyLearningAddedByYouEmpty @open-external-sidebar="onToggleExternalSidebar" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>

<script>
import EventBus from '@/plugins/eventbus'
import MyLearningAddedByYouEmpty from '@/views/my-learning/components/MyLearningAddedByYouEmpty'
import UniversalCard from '@/components/organisms/UniversalCard'

export default {
  name: 'MyLearningItemContainer',
  components: {
    UniversalCard,
    MyLearningAddedByYouEmpty
  },
  props: {
    selectedTabIndex: {
      type: Number,
      required: true,
      default: () => {
        return 0
      }
    },
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          learning: {},
          external: {}
        }
      }
    },
    externalSidebarActive: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: true,
      isExternalSidebarActive: false,
      tabName: 'myLearning'
    }
  },
  computed: {
    pageRangeLearning() {
      return this.data.learning.pageCount > 6 ? 3 : 6
    },
    pageRangeExternal() {
      return this.data.external.pageCount > 6 ? 3 : 6
    },
    pageRangeRequired() {
       return this.data.required.pageCount > 6 ? 3 : 6
    }
  },
  watch: {
    externalSidebarActive: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) this.isExternalSidebarActive = newValue
      }
    }
  },
  created() {},
  async mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
    const elements = document.getElementsByClassName('pagination')[0]?.childNodes
    if (elements) {
      await elements.forEach((element) => {
        if (element.childNodes.length && element.className.includes('pagination')) {
          element.addEventListener('click', (e) => {
            let page =
              this.selectedTabIndex == 0
                ? this.data.required.pageCurrent
                : this.selectedTabIndex == 1
                ? this.data.learning.pageCurrent
                : this.selectedTabIndex == 2
                ? this.data.external.pageCurrent
                : 0
            if (e.target.textContent != '…') {
              page = e.target.textContent == '‹' ? page - 1 : e.target.textContent == '›' ? page + 1 : parseInt(e.target.textContent)
              this.selectedTabIndex == 0
                ? this.handleRequiredPageClick(page)
                : this.selectedTabIndex == 1
                ? this.handleLearningPlanPageClick(page)
                : this.handleAddedByYouPageClick(page)
            }
          })
        }
      })
    }
  },
  methods: {
    goToRecommended() {
      this.$emit('update-tab-index', 1)
    },
    goToExplore() {
      this.$router.push({ name: 'Explore' }).catch(() => {})
    },
    onLearnItemClick() {
      this.$emit('learning-item-container-click')
    },
    handleLearningPlanPageClick(page) {
      this.$emit('learning-page-click', {
        filters: this.data.learning.selectedFilters,
        sortBy: this.data.learning.sortBy,
        page: page
      })
    },
    handleAddedByYouPageClick(page) {
      this.$emit('external-page-click', {
        filters: this.data.external.selectedFilters,
        sortBy: this.data.external.sortBy,
        page: page
      })
    },
    handleRequiredPageClick(page) {
      this.$emit('required-page-click', {
        filters: this.data.required.selectedFilters,
        sortBy: this.data.required.sortBy,
        page: page
      })
    },
    onToggleExternalSidebar() {
      this.isExternalSidebarActive = !this.isExternalSidebarActive
      this.$emit('on-toggle-external-sidebar')
      EventBus.$emit('on-toggle-external-sidebar', this.isExternalSidebarActive)
    },
    deleteExternalItem() {
      this.$emit('on-delete-external-item')
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.pagination-item {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

li.pagination-nav {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.pagination-item-link {
  color: var(--ion-color-post-gray);
  font-size: 14px !important;
  vertical-align: middle;
}

.pagination-nav-link {
  position: relative;
  color: var(--ion-color-medium);
  font-size: 3rem !important;
  vertical-align: middle;
  font-family: 'Futura PT Book';
}

li.disabled {
  border: none;
  cursor: default;
  .pagination-item-link {
    cursor: default;
  }
}

li.active {
  border: 2px solid #1dd1b0;
  border-radius: 4px;
  background: white;
  .pagination-item-link {
    color: #000000;
  }
}

li:hover {
  background: #e0e1e1;
}

li .tooltiptext {
  visibility: hidden;
  width: max-content;
  height: 30px;
  background-color: #efefef;
  color: #000000de;
  text-align: center;
  padding: 7px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -20px;
  font-size: 12px;
  font-family: 'Futura PT Demi';
}

.li:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 414px) {
  .pagination-item {
    margin: 0 2px;
    width: 32px;
    height: 32px;
  }
  li.pagination-nav {
    width: 32px;
    height: 32px;
  }
  .pagination-item-link {
    font-size: 12px;
  }
  .all-filters {
    &.external-sidebar-container {
      width: 100% !important;
      border-radius: 10px 0 0 10px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.title {
  text-align: left;
  font-size: 3rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-tertiary);
}

.title-black {
  color: #000000;
}

.sub-title {
  margin: 1rem 0rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Arial';
  color: var(--ion-color-medium);
}

.link-btn {
  font-size: 1rem;
  font-family: 'Arial';
  text-decoration: none !important;
  color: var(--ion-color-tertiary);
}

.link-btn-recommended{
  font-size: 1rem;
  font-family: 'Arial';
  text-decoration: underline;
  color: var(--ion-color-tertiary);
}

.continue-btn {
  width: 140px;
  text-transform: none;
  font-family: 'Futura PT Demi';
  margin-right: 1rem;
  --ion-color-primary-contrast: var(--ion-color-tertiary);
}

.ion-no-results-header {
  color: #1f3370;
  font-family: 'Futura PT Demi';
  font-size: 40px;
  letter-spacing: -0.03px;
}

.ion-no-results-sub {
  color: var(--ion-color-medium);
  letter-spacing: 0.01px;
  font-size: 1rem;
}

.desktop-pagination {
  padding: 16px 8% 16px 0px;
  text-align: end;
  font-family: 'Arial';
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-medium);
  border-top: 2px solid #dfdede;
  margin: 10px -10% 0px -10%;
}

.mobile-pagination {
  padding: 16px 10% 16px 0px;
  text-align: end;
  font-family: 'Arial';
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-medium);
  border-top: 2px solid #dfdede;
  margin: 16px -10% 0px -10%;
}

.pagination-icon {
  margin-bottom: -4px;
}

.container-desktop {
  margin-top: 32px;
  // margin-top: 1rem; // DO NOT DELTE: THIS IS FOR SINGLE TRACK
}

.container-mobile {
  margin-top: 16px;
  padding: 0 11px;
}

ion-button {
  --box-shadow: none;
}

@media screen and (max-width: 1024px) {
  .ion-no-results-header {
    font-size: 30px;
    line-height: 38px;
  }
}
@media screen and (max-width: 767px) {
  .ion-no-results-header {
    font-size: 25px;
    line-height: 32px;
  }

  .ion-no-results-sub {
    font-size: 12px;
  }
}

.external-sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000070;
  z-index: 98;
  cursor: pointer;
}

.external-sidebar-container {
  position: fixed;
  z-index: 9999;
  right: 0;
  width: 640px;
  border-radius: 20px 0 0 20px;
}

.external-sidebar-container-mobile {
  height: 100vh !important;
}
</style>
