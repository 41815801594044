var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataReady
    ? _c(
        "div",
        {
          staticClass: "home-container main-content",
          style: !_vm.isDesktopPlatform ? "background: #e7e5e5 !important" : ""
        },
        [
          !_vm.isDesktopPlatform && _vm.mobileTabVisible
            ? _c("div", {
                staticClass: "overlay",
                on: {
                  click: function($event) {
                    _vm.mobileTabVisible = false
                  }
                }
              })
            : _vm._e(),
          _vm.goalSettingEnabled
            ? _c(
                "div",
                { staticClass: "banner-home" },
                [
                  _c(
                    "ion-grid",
                    { ref: "top", staticClass: "ion-no-padding" },
                    [
                      _c(
                        "ion-row",
                        { staticClass: "ion-no-padding" },
                        [
                          _vm.metricsDataReady
                            ? _c(
                                "ion-col",
                                {
                                  staticClass:
                                    "ion-no-padding ion-text-start banner-info",
                                  attrs: {
                                    "size-lg": "6",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c("h1", [
                                    _vm._v("Hi, " + _vm._s(_vm.name) + "!")
                                  ]),
                                  _vm.noPendingItems
                                    ? _c(
                                        "div",
                                        { staticClass: "banner-info-content" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "no-pending-content"
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  "margin-right": "5px"
                                                },
                                                attrs: {
                                                  "icon-class":
                                                    "icon-progress-complete"
                                                }
                                              }),
                                              _c("p", [
                                                _vm._v(
                                                  "You have no pending items."
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "banner-info-message"
                                            },
                                            [_vm._v(_vm._s(_vm.message))]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        [
                                          !_vm.requiredLearningEnabled
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "banner-info-message"
                                                },
                                                [_vm._v(_vm._s(_vm.message))]
                                              )
                                            : _vm._e(),
                                          _c("HomeDashboardMetrics", {
                                            attrs: {
                                              dashboardMetricsData:
                                                _vm.learningMetrics
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "ion-col",
                            {
                              attrs: {
                                "size-lg": "6",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c("HomeGoalSetting", {
                                key: _vm.goalSettingData.goalDays
                                  ? _vm.goalSettingData.goalDays
                                  : 50,
                                attrs: { goalSettingData: _vm.goalSettingData },
                                on: {
                                  openGoalSettingModal: _vm.openGoalSettings
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "banner-home" },
                [
                  _c(
                    "ion-grid",
                    { ref: "top", staticClass: "ion-no-padding" },
                    [
                      _vm.isDesktopPlatform
                        ? _c(
                            "ion-row",
                            { staticClass: "ion-no-padding" },
                            [
                              _vm.metricsDataReady
                                ? _c(
                                    "ion-col",
                                    {
                                      staticClass:
                                        "ion-no-padding ion-text-start banner-info",
                                      attrs: {
                                        "size-lg": "6",
                                        "size-md": "6",
                                        "size-sm": "7",
                                        "size-xs": "9"
                                      }
                                    },
                                    [
                                      _c("h1", [
                                        _vm._v("Hi, " + _vm._s(_vm.name) + "!")
                                      ]),
                                      _vm.noPendingItems
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "banner-info-content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "no-pending-content"
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    staticStyle: {
                                                      "margin-right": "5px"
                                                    },
                                                    attrs: {
                                                      "icon-class":
                                                        "icon-progress-complete"
                                                    }
                                                  }),
                                                  _c("p", [
                                                    _vm._v(
                                                      "You have no pending items."
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "banner-info-message"
                                                },
                                                [_vm._v(_vm._s(_vm.message))]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            [
                                              !_vm.requiredLearningEnabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "banner-info-message"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.message)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("HomeDashboardMetrics", {
                                                attrs: {
                                                  dashboardMetricsData:
                                                    _vm.learningMetrics
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "ion-col",
                                {
                                  staticClass: "ion-no-padding ion-text-start",
                                  attrs: {
                                    "size-lg": "6",
                                    "size-md": "6",
                                    "size-sm": "5",
                                    "size-xs": "3"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "banner-image",
                                    attrs: {
                                      src: require("@/assets/images/home-banner-image.png"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "ion-row",
                            { staticClass: "ion-no-padding" },
                            [
                              _vm.metricsDataReady
                                ? _c(
                                    "ion-col",
                                    {
                                      staticClass:
                                        "ion-no-padding ion-text-start banner-info",
                                      attrs: {
                                        "size-lg": "12",
                                        "size-md": "12",
                                        "size-sm": "12",
                                        "size-xs": "12"
                                      }
                                    },
                                    [
                                      _c("h1", [
                                        _vm._v("Hi, " + _vm._s(_vm.name) + "!")
                                      ]),
                                      _vm.noPendingItems
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "banner-info-content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "no-pending-content"
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    staticStyle: {
                                                      "margin-right": "5px"
                                                    },
                                                    attrs: {
                                                      "icon-class":
                                                        "icon-progress-complete"
                                                    }
                                                  }),
                                                  _c("p", [
                                                    _vm._v(
                                                      "You have no pending items."
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "banner-info-message"
                                                },
                                                [_vm._v(_vm._s(_vm.message))]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            [
                                              !_vm.requiredLearningEnabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "banner-info-message"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.message)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("HomeDashboardMetrics", {
                                                attrs: {
                                                  dashboardMetricsData:
                                                    _vm.learningMetrics
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              ),
          _vm.dueNextEnabled
            ? _c("div", { staticClass: "due-next-container" }, [
                _c("div", { staticClass: "due-next-header" }, [
                  _c("div", { staticClass: "due-next-title" }, [
                    _vm._v("Due next")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "due-next-view-all-required",
                      on: { click: _vm.GoToMyLearning }
                    },
                    [_vm._v("View all required learning")]
                  )
                ]),
                _c(
                  "div",
                  [
                    _c("UniversalCard", {
                      key: _vm.dueNextData.id,
                      attrs: {
                        item: _vm.dueNextData,
                        index: 0,
                        itemsLength: 1,
                        tab: "home"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.tenantCustomInfo.isAvailable
            ? _c("div", { ref: "topCustom", staticClass: "tenant-home" }, [
                _vm.isDesktopPlatform
                  ? _c(
                      "div",
                      {
                        class: !_vm.tenantCustomInfo.isCollapse
                          ? "tenant-custom-panel-expand"
                          : "tenant-custom-panel-collapse",
                        staticStyle: { display: "flex" }
                      },
                      [
                        !_vm.tenantCustomInfo.isCollapse
                          ? _c(
                              "div",
                              [
                                _c(
                                  "ion-grid",
                                  { staticClass: "ion-no-padding" },
                                  [
                                    _c(
                                      "ion-row",
                                      { staticClass: "ion-no-padding" },
                                      [
                                        _c(
                                          "ion-col",
                                          {
                                            staticClass:
                                              "ion-no-padding ion-text-start tenant-info",
                                            staticStyle: {
                                              visibility: "inherit"
                                            },
                                            attrs: {
                                              "size-lg": "7",
                                              "size-md": "12",
                                              "size-sm": "12",
                                              "size-xs": "12"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "50%",
                                                  "-webkit-transform":
                                                    "translateY(-50%)",
                                                  "-ms-transform":
                                                    "translateY(-50%)",
                                                  transform: "translateY(-50%)"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tenant-info-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tenantCustomInfo
                                                          .info.preHeader
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tenant-info-header"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tenantCustomInfo
                                                          .info.header
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tenant-info-description"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tenantCustomInfo
                                                          .info.description
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tenant-info-links"
                                                  },
                                                  _vm._l(
                                                    _vm.tenantCustomInfo.info
                                                      .links,
                                                    function(link, index) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: index,
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onLinkClick(
                                                                link
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          !link.isPELink
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "unset",
                                                                    "background-color":
                                                                      "unset"
                                                                  },
                                                                  attrs: {
                                                                    href:
                                                                      link.uri,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      link.text
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _c(
                                                                "router-link",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "unset",
                                                                    "background-color":
                                                                      "unset"
                                                                  },
                                                                  attrs: {
                                                                    to: link.uri
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      link.text
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "ion-col",
                                          {
                                            staticClass:
                                              "ion-no-padding ion-text-start",
                                            staticStyle: {
                                              visibility: "inherit"
                                            },
                                            attrs: {
                                              "size-lg": "5",
                                              "size-md": "12",
                                              "size-sm": "12",
                                              "size-xs": "12"
                                            }
                                          },
                                          [
                                            _vm.tenantCustomInfo.info.media &&
                                            _vm.tenantCustomInfo.info.media
                                              .type === "image"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "tenant-media"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          _vm.tenantCustomInfo
                                                            .info.media.uri,
                                                        alt:
                                                          _vm.tenantCustomInfo
                                                            .info.media.caption
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.tenantCustomInfo.info.media &&
                                            _vm.tenantCustomInfo.info.media
                                              .type === "video"
                                              ? _c(
                                                  "div",
                                                  {
                                                    on: {
                                                      click: _vm.onVideoPlay
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "video",
                                                      {
                                                        staticClass:
                                                          "tenant-media",
                                                        attrs: {
                                                          controls: "",
                                                          poster: "",
                                                          preload: "auto",
                                                          "aria-label":
                                                            "watch video"
                                                        }
                                                      },
                                                      [
                                                        _c("source", {
                                                          attrs: {
                                                            src:
                                                              _vm
                                                                .tenantCustomInfo
                                                                .info.media.uri,
                                                            type: "video/mp4"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.tenantCustomInfo.info.media &&
                                            _vm.tenantCustomInfo.info.media
                                              .type === "audio"
                                              ? _c(
                                                  "audio",
                                                  {
                                                    attrs: {
                                                      controls: "",
                                                      src:
                                                        _vm.tenantCustomInfo
                                                          .info.media.uri
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Your browser does not support the "
                                                    ),
                                                    _c("code", [
                                                      _vm._v("audio")
                                                    ]),
                                                    _vm._v(" element. ")
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.tenantCustomInfo.isCollapse
                          ? _c(
                              "div",
                              [
                                _c(
                                  "ion-grid",
                                  { staticClass: "ion-no-padding" },
                                  [
                                    _c(
                                      "ion-row",
                                      { staticClass: "ion-no-padding" },
                                      [
                                        _c(
                                          "ion-col",
                                          {
                                            staticClass:
                                              "ion-no-padding ion-text-start tenant-info",
                                            staticStyle: {
                                              visibility: "inherit"
                                            },
                                            attrs: {
                                              "size-lg": "12",
                                              "size-md": "12",
                                              "size-sm": "12",
                                              "size-xs": "12"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "50%",
                                                  "-webkit-transform":
                                                    "translateY(-50%)",
                                                  "-ms-transform":
                                                    "translateY(-50%)",
                                                  transform: "translateY(-50%)"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tenant-info-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tenantCustomInfo
                                                          .info.preHeader
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tenant-info-header ellipsis-lines-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tenantCustomInfo
                                                          .info.header
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "tenant-icon-container" }, [
                          _c(
                            "div",
                            { staticClass: "tenant-icon" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": !_vm.tenantCustomInfo.isCollapse
                                    ? "Icon-feather-chevron-right"
                                    : "Icon-awesome-chevron-down"
                                },
                                on: { click: _vm.onToggle }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _c(
                      "div",
                      {
                        class: !_vm.tenantCustomInfo.isCollapse
                          ? "tenant-custom-panel-expand-mobile"
                          : "tenant-custom-panel-collapse-mobile",
                        staticStyle: { display: "flex" }
                      },
                      [
                        !_vm.tenantCustomInfo.isCollapse
                          ? _c(
                              "div",
                              [
                                _c(
                                  "ion-grid",
                                  { staticClass: "ion-no-padding" },
                                  [
                                    _c(
                                      "ion-row",
                                      { staticClass: "ion-no-padding" },
                                      [
                                        _c(
                                          "ion-col",
                                          {
                                            staticClass:
                                              "ion-no-padding ion-text-start tenant-info",
                                            staticStyle: {
                                              visibility: "inherit"
                                            },
                                            attrs: {
                                              "size-lg": "12",
                                              "size-md": "12",
                                              "size-sm": "12",
                                              "size-xs": "12"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tenant-info-name-mobile"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tenantCustomInfo.info
                                                      .preHeader
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tenant-info-header-mobile"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tenantCustomInfo.info
                                                      .header
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tenant-info-description-mobile"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tenantCustomInfo.info
                                                      .description
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "tenant-info-links"
                                              },
                                              _vm._l(
                                                _vm.tenantCustomInfo.info.links,
                                                function(link, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        "margin-bottom":
                                                          "0.5rem"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onLinkClick(
                                                            link
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      !link.isPELink
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticStyle: {
                                                                color: "unset",
                                                                "background-color":
                                                                  "unset"
                                                              },
                                                              attrs: {
                                                                href: link.uri,
                                                                target: "_blank"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  link.text
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "router-link",
                                                            {
                                                              staticStyle: {
                                                                color: "unset",
                                                                "background-color":
                                                                  "unset"
                                                              },
                                                              attrs: {
                                                                to: link.uri
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  link.text
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm.tenantCustomInfo.info.media &&
                                            _vm.tenantCustomInfo.info.media
                                              .type === "image"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tenant-media-mobile"
                                                  },
                                                  [
                                                    _vm.tenantCustomInfo.info
                                                      .media &&
                                                    _vm.tenantCustomInfo.info
                                                      .media.type === "image"
                                                      ? _c("img", {
                                                          attrs: {
                                                            src:
                                                              _vm
                                                                .tenantCustomInfo
                                                                .info.media.uri,
                                                            alt:
                                                              _vm
                                                                .tenantCustomInfo
                                                                .info.media
                                                                .caption
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.tenantCustomInfo.info.media &&
                                            _vm.tenantCustomInfo.info.media
                                              .type === "video"
                                              ? _c(
                                                  "div",
                                                  {
                                                    on: {
                                                      click: _vm.onVideoPlay
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "video",
                                                      {
                                                        staticClass:
                                                          "tenant-media-mobile",
                                                        attrs: {
                                                          controls: "",
                                                          poster: "",
                                                          preload: "auto",
                                                          "aria-label":
                                                            "watch video"
                                                        }
                                                      },
                                                      [
                                                        _c("source", {
                                                          attrs: {
                                                            src:
                                                              _vm
                                                                .tenantCustomInfo
                                                                .info.media.uri,
                                                            type: "video/mp4"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.tenantCustomInfo.info.media &&
                                            _vm.tenantCustomInfo.info.media
                                              .type === "audio"
                                              ? _c(
                                                  "audio",
                                                  {
                                                    attrs: {
                                                      controls: "",
                                                      src:
                                                        _vm.tenantCustomInfo
                                                          .info.media.uri
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Your browser does not support the "
                                                    ),
                                                    _c("code", [
                                                      _vm._v("audio")
                                                    ]),
                                                    _vm._v(" element. ")
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.tenantCustomInfo.isCollapse
                          ? _c(
                              "div",
                              [
                                _c(
                                  "ion-grid",
                                  { staticClass: "ion-no-padding" },
                                  [
                                    _c(
                                      "ion-row",
                                      { staticClass: "ion-no-padding" },
                                      [
                                        _c(
                                          "ion-col",
                                          {
                                            staticClass:
                                              "ion-no-padding ion-text-start tenant-info",
                                            staticStyle: {
                                              visibility: "inherit"
                                            },
                                            attrs: {
                                              "size-lg": "12",
                                              "size-md": "12",
                                              "size-sm": "12",
                                              "size-xs": "12"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tenant-info-name-mobile"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tenantCustomInfo.info
                                                      .preHeader
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tenant-info-header-mobile ellipsis-lines-2"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tenantCustomInfo.info
                                                      .header
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            class: _vm.isDesktopPlatform
                              ? "tenant-icon-container"
                              : "tenant-icon-container-mobile"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tenant-icon" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": !_vm.tenantCustomInfo
                                      .isCollapse
                                      ? "Icon-feather-chevron-right"
                                      : "Icon-awesome-chevron-down"
                                  },
                                  on: { click: _vm.onToggle }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "home-content" },
            [
              _vm.isDesktopPlatform
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "border-bottom": "2px solid var(--ion-color-light-gray)"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tabs-container-desktop" },
                        _vm._l(_vm.tabs, function(tab) {
                          return _c(
                            "button",
                            {
                              key: tab.tidx,
                              staticClass: "tab accessible-button",
                              class:
                                _vm.selectedTabIndex === tab.tidx
                                  ? "selected"
                                  : "",
                              attrs: { "aria-label": tab.title },
                              on: {
                                click: function($event) {
                                  return _vm.onTabSelected(tab.tidx)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(tab.title) + " ")]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _c("div", { staticClass: "tabs-container-mobile" }, [
                    _c(
                      "div",
                      {
                        staticClass: "selected",
                        on: { click: _vm.onTabToggled }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.tabs[_vm.selectedTabIndex].title) +
                            " "
                        ),
                        _c("i", { staticClass: "dropdown-arrow" })
                      ]
                    ),
                    _vm.mobileTabVisible
                      ? _c(
                          "ul",
                          { staticClass: "tabs-selection" },
                          _vm._l(_vm.tabs, function(tab) {
                            return _c(
                              "li",
                              {
                                key: tab.tidx,
                                class:
                                  _vm.selectedTabIndex === tab.tidx
                                    ? "selected"
                                    : "",
                                attrs: { value: tab.title },
                                on: {
                                  click: function($event) {
                                    return _vm.onTabSelected(tab.tidx)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(tab.title) + " ")]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]),
              _c(_vm.component, {
                tag: "component",
                on: {
                  "scroll-to-top": function($event) {
                    return _vm.scrollToTop()
                  }
                }
              })
            ],
            1
          ),
          _vm.showCelebration ? _c("Celebration") : _vm._e(),
          _c(
            "div",
            { staticClass: "carousal-container" },
            [
              _c("PersonalizedCarousal", {
                attrs: {
                  showCarousal: _vm.showPersonalizedCarousal,
                  carousalType: "personalized",
                  recommendations: _vm.recommendations
                }
              }),
              _c("PersonalizedCarousal", {
                attrs: {
                  showCarousal: _vm.showBoostSkills,
                  carousalType: "boostSkills",
                  recommendations: _vm.shortDurationSkill,
                  isActivityContentNotEmpty: _vm.isActivityContentNotEmpty
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }