var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "learning-metrics-container" },
      _vm._l(_vm.dashboardMetricsData.slice(0, 2), function(
        learningItem,
        index
      ) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "learning-metric-item item-bottom-boarder",
            class:
              _vm.dashboardMetricsData.length <= 1
                ? "border-width"
                : _vm.dashboardMetricsData.length <= 2
                ? "no-border"
                : "",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                return _vm.goToMyLearning(learningItem.redirectTo)
              }
            }
          },
          [
            learningItem.id === "pastDueCount" && learningItem.value !== 0
              ? _c("svg-icon", {
                  attrs: {
                    "icon-class": "past-due",
                    "class-name": "g-icon--small past-due-icon"
                  }
                })
              : _vm._e(),
            _c("label", [_vm._v(_vm._s(learningItem.name))]),
            _c("span", [_vm._v(_vm._s(learningItem.value))])
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "learning-metrics-container" },
      _vm._l(_vm.dashboardMetricsData.slice(2, 5), function(
        learningItem,
        index
      ) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "learning-metric-item item-top-boarder",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                return _vm.goToMyLearning(learningItem.redirectTo)
              }
            }
          },
          [
            _c("label", [_vm._v(_vm._s(learningItem.name))]),
            _c("span", [_vm._v(_vm._s(learningItem.value))])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }