var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "back-btn-container" }, [
      _c(
        "button",
        {
          staticClass: "accessible-button back",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [
          _c("svg-icon", {
            staticClass: "g-icon--small",
            attrs: { "icon-class": "icon-arrow-left" }
          }),
          _vm._v(" Back ")
        ],
        1
      )
    ]),
    _c("div", { staticClass: "no-content-container" }, [
      _c("div", { staticClass: "no-content-description-container" }, [
        _c("div", { staticClass: "no-content-header" }, [
          _vm._v("The item you’re looking for is not available.")
        ]),
        _c("div", { staticClass: "no-content-description" }, [
          _vm._v("Browse our "),
          _c("span", { on: { click: _vm.goToExplore } }, [
            _vm._v("Explore section")
          ]),
          _vm._v(" to discover content based on your interests")
        ])
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-content-image" }, [
      _c("img", { attrs: { src: require("@/assets/images/no-content.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }