<template>
  <div class="main-content"  :style="!isDesktopPlatform ? 'background: #efefef; margin-top: 2rem; ' : 'padding: 2rem 3.8rem'" ref="recommendedTop">
    <div class="recommend-banner">
      <div class="recommend-banner-add-skill-container">
        <div class="recommend-banner-title">Explore by Skill</div>
        <div class="recommend-banner-content">Discover new learning content and digital assets</div>
        <button class="recommend-banner-add-skill-button" @click="openSkillModal(true)">Add skills</button>
      </div>
    </div>
    <div v-if="dataReady" class="recommend-container">
      <div class="recommend-nav">
        <RecommendedTab
          :key="selectedTabIndex"
          :tabs="tabs"
          :recommendation="recommendation"
          :selectedTabIndex="selectedTabIndex"
          @open-skill-modal="openSkillModal($event)"
          @update-tab-index="updateTabIndex"
          @close-skill-modal="skillModal = false"
        >
        </RecommendedTab>
        <RecommendedAddSkills
          v-if="skillModal && !skillModalBottom"
          :is-on-asset-tab="selectedTabIndex == 4"
          @closeAddSkillsModal="onCloseModal($event)"
          style="margin-top: -30px"
        />
      </div>
      <div v-for="(skill, skIdx) in recommendation" :key="skIdx" class="recommend-content">
        <div v-if="skill.loaded">
          <div v-if="!(selectedTabIndex == 4 && !skill.recommend.byType.length)" class="recommend-label">
            {{ skill.name }} <span class="recommend-count">{{ skill.recommend.byType.length }}</span>
          </div>
          <CarouselDesktop
            :id="skill.name"
            style="margin-bottom: 16px"
            :style="{ '--bottom': '-22px', '--skill-items-length': `${skill.recommend.byType.length}px` }"
            v-if="isDesktopPlatform && skill.recommend.byType.length"
            :key="skill.name + skill.recommend.byType.length"
            :slidesShow="4"
            :responsive="true"
          >
            <UniversalCard
              :data-carousel-name="skill.name"
              :data-carousel-index="skIdx"
              :data-card-index="index"
              :data-carousel-uuid="skill.uuid"
              :id="item.id"
              v-for="(item, index) in skill.recommend.byType"
              :key="item.id"
              :item="item"
              :index="index"
              :itemsLength="skill.recommend.byType.length"
              class="carousel-desktop-item"
              :tab="tabName"
              :isExpandCardAllow="false"
              cardType="carousal"
              @recommended-item-click="onRecommendedItemClick(item, index, skill.name, skIdx, skill.uuid)"
            />
            <!-- <UniversalCard
              :data-carousel-name="skill.name"
              :data-carousel-index="skIdx"
              :data-card-index="index"
              :data-carousel-uuid="skill.uuid"
              :id="item.id"
              v-for="(item, index) in skill.recommend.byType"
              :key="item.id"
              :item="item"
              :index="index"
              :itemsLength="skill.recommend.byType.length"
              class="carousel-desktop-item"
              :tab="tabName"
              :isExpandCardAllow="isExpandCardAllow"
              @expand-card="onExpand(item, index, skill.recommend.byType.length,skill.name, skIdx,skill.uuid)"
              @recommended-item-click="onRecommendedItemClick(item, index, skill.name, skIdx,skill.uuid)"
            /> -->
          </CarouselDesktop>
          <CarouselMobile :id="skill.name" v-if="!isDesktopPlatform && skill.recommend.byType.length" :key="skill.name + skill.recommend.byType.length">
            <UniversalCard
              :data-carousel-name="skill.name"
              :data-carousel-index="skIdx"
              :data-card-index="index"
              :data-carousel-uuid="skill.uuid"
              :id="item.id"
              v-for="(item, index) in skill.recommend.byType"
              :key="item.id"
              :item="item"
              :index="index"
              :itemsLength="skill.recommend.byType.length"
              class="carousel-mobile-item"
              :tab="tabName"
              cardType="carousal"
              @recommended-item-click="onRecommendedItemClick(item, index, skill.name, skIdx, skill.uuid)"
            />
          </CarouselMobile>
          <div v-if="!skill.recommend.byType.length && selectedTabIndex != 4" style="color: #5c5c5c; font-family: Arial">No content found</div>
        </div>
      </div>
      <RecommendedAddSkills v-if="skillModal && skillModalBottom" :is-on-asset-tab="selectedTabIndex == 4" @closeAddSkillsModal="onCloseModal($event)" style="margin-top: -250px" />
      <div class="recommend-footer" v-if="(selectedTabIndex == 4 && !isAssetAvailable) || selectedTabIndex != 4">
        <img src="@/assets/images/recommended-skills-footer.png" class="recommend-footer-image-desktop" />
        <div class="recommend-footer-copy">
          <p class="footer-title">There's more to explore</p>
          <p v-if="selectedTabIndex != 4" class="footer-subtitle">Add skills to browse courses, credentials, videos and more based on your interests.</p>
          <ion-button v-if="selectedTabIndex != 4" class="g-button--primary ion-button-primary footer-button" color="primary" @click="openMobileSkillModal">
            <div class="addskill-icon-footer g-clickable" @click="openMobileSkillModal">
              <svg-icon icon-class="add-icon" style="height: 12px; width: 12px" />
            </div>
            Add Skills
          </ion-button>
          <p v-else class="footer-subtitle">Your selected skills didn't match any asset results. Discover more digital assets below.</p>
          <img src="@/assets/images/recommended-skills-footer.png" class="recommend-footer-image-mobile" />
        </div>
      </div>
      <AssetCategory v-if="selectedTabIndex == 4" :is-tenant-asset-available="isTenantAssetAvailable" />
    </div>
    <div v-else style="margin-top: 4rem">
      <div v-for="i in 5" :key="i">
        <div class="recommend-label-shimmer">
          <ion-skeleton-text animated />
        </div>
        <CarouselDesktop style="margin-bottom: 1rem" v-if="isDesktopPlatform" :slidesShow="4" :responsive="true">
          <RecommendedSkeleton v-for="i in 4" :key="i" class="carousel-desktop-item-shimmer" />
        </CarouselDesktop>
        <CarouselMobile v-else>
          <RecommendedSkeleton v-for="i in 2" :key="i" class="carousel-mobile-item-shimmer" />
        </CarouselMobile>
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import { popoverController } from '@modus/ionic-vue'
import moment from 'moment-timezone'
import CarouselDesktop from '@/components/organisms/CarouselDesktop'
import CarouselMobile from '@/components/molecules/CarouselMobile'
import AssetCategory from '@/views/asset/components/AssetCategory'
import RecommendedTab from '@/views/explore/components/RecommendedTab'
import UniversalCard from '@/components/organisms/UniversalCard'
import UniversalCardModal from '@/components/organisms/UniversalCardModal'
import RecommendedAddSkills from '@/views/explore/components/RecommendedAddSkills'
import RecommendedSkeleton from '@/views/explore/components/RecommendedSkeleton'
import EventBus from '@/plugins/eventbus'
import { Plugins } from '@capacitor/core'
const { Network } = Plugins
export default {
  name: 'Recommended',
  components: {
    CarouselDesktop,
    CarouselMobile,
    RecommendedTab,
    UniversalCard,
    RecommendedAddSkills,
    AssetCategory,
    RecommendedSkeleton
  },
  props: {},
  data() {
    return {
      contentTypes: ['ARTICLE', 'COURSE', 'CREDENTIAL'],
      additionalInfoObj: {},
      authToken: '',
      tenant: '',
      learnerId: '',
      tenantGuid: '',
      assetMediaUrl: '',
      recommendation: [],
      dataReady: false,
      skillModal: false,
      skillModalBottom: false,
      tabName: 'recommended',
      isExpandCardAllow: true,
      modal: null,
      bookmarkedItems: [],
      requiredLearningPlanItems: {},
      tabs: [
        { title: 'All', tidx: 0, hidden: false },
        { title: 'Courses', tidx: 1, hidden: false },
        { title: 'Credentials', tidx: 2, hidden: false },
        { title: 'Videos, Audios & Articles', tidx: 3, hidden: false },
        { title: 'Assets', tidx: 4, hidden: false }
      ],
      selectedTabIndex: 0,
      isAssetAvailable: true,
      isTenantAssetAvailable: true,
      isDesktopPlatform: true,
      isNewExplore: true,
      tenantAsset: {
        enabled: false,
        userAccessAsset: false
      },
      progressThresholds: {
        forty: false,
        eighty: false
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {
    async $route(to, from) {
      if (this.skillModal && !this.isDesktopPlatform) {
        document.getElementById('contentlayout').classList.remove('no-scroll')
      }
      this.isNewExplore = !(from.path.includes('course') || from.path.includes('credential') || from.path.includes('content') || from.path.includes('asset'))
      if (
        !from.path.includes('search') &&
        !from.path.includes('course') &&
        !from.path.includes('credential') &&
        !from.path.includes('content') &&
        !from.path.includes('asset') &&
        to.path.includes('recommended')
      ) {
        this.selectedTabIndex = 0
      }
    }
  },
  created() {},
  async mounted() {
    EventBus.$on('edit-bookmark', this.editBookmark)
    this.additionalInfoObj = await this.additionalInfo()
  },
  beforeDestroy() {
    EventBus.$off('scroll')
  },
  async activated() {
    this.dataReady = false
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.learnerId = await localforage.getItem('my_user_id')
    this.tenantGuid = await localforage.getItem('my_tenant_guid')
    this.$refs.recommendedTop.addEventListener('keydown', this.handleKeyPress)
    this.$refs.recommendedTop.addEventListener('keyup', this.handleKeyPress)
    this.skillModal = false
    this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
    await this.getRequiredLearningPlanItems()
    await this.determineUserAccess()
    await this.getTenantAssetCount()
    await this.getRecommendation()
    if (this.isNewExplore) {
      if (this.isDesktopPlatform) {
        const container = document.querySelector('#contentlayout')
        container.scrollTo(0, 0)
      } else {
        this.$refs.recommendedTop.scrollIntoView()
      }
    }
    this.isExpandCardAllow = true
  },
  methods: {
    handleKeyPress(event) {
      if (event.key == 'ArrowDown' || event.key == 'ArrowUp') this.skillModal = false
    },
    async determineUserAccess() {
      const userAccsess = await localforage.getItem('my_user_access')
      this.tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
      const settings = JSON.parse(this.tenant.settings)
      const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
      this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
      this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
      this.tabs[1].hidden = !userAccsess.licenseType.canAccessCourses
      this.tabs[2].hidden = !userAccsess.licenseType.canAccessCredential
      this.tabs[3].hidden = !userAccsess.licenseType.canAccessRWL
      this.tabs[4].hidden = !this.tenantAsset.userAccessAsset || !this.tenantAsset.enabled
    },
    async getTenantAssetCount() {
      const payload = {
        token: this.authToken,
        query: '',
        pageNo: 1,
        sortBy: 'bestmatch',
        body: {
          accreditation: [],
          author: [],
          content_type: [],
          creditHours: [],
          creditType: [],
          duration: [],
          function: [],
          inventoryType: [],
          level: [],
          provider: [this.tenant.name],
          tool: [],
          modal: null
        }
      }
      const assets = await this.$learnAdmin.searchAll(payload)
      if ({}.hasOwnProperty.call(assets, 'totalResults')) {
        this.isTenantAssetAvailable = assets.totalResults > 0
      }
    },
    async additionalInfo() {
      const obj = {
        'screen-width': screen.width,
        'screen-height': screen.height,
        'connection-type': 'none'
      }
      let userAgent = navigator.userAgent.match(/\((.*?)\)/)
      userAgent = userAgent.length > 1 ? userAgent[1].split(';') : []
      obj['device-model'] = userAgent[0].trim()
      obj['os'] = userAgent[1].trim()
      if (this.$platform !== 'Desktop') {
        obj['device-model'] = this.$device == 'android' ? `${userAgent[2].trim()}` : userAgent[0].trim()
        const network = await Network.getStatus()
        obj['connection-type'] = network.connectionType || 'none'
      }
      return new Promise((resolve) => {
        resolve(obj)
      })
    },
    async getRequiredLearningPlanItems() {
      this.requiredLearningPlanItems = await this.$learnAdmin.getMyLearningPlanRequiredItems({ token: this.authToken })
      this.requiredLearningPlanItems = this.requiredLearningPlanItems && this.requiredLearningPlanItems.code === 200 ? this.requiredLearningPlanItems.data : {}
    },
    async getRecommendation() {
      this.additionalInfoObj['time-stamp'] = new Date().toTimeString()
      let skillRcmCnt = 0
      this.dataReady = false
      if (!this.isNewExplore) {
        this.selectedTabIndex = (await localforage.getItem('recommended-tab')) ? await localforage.getItem('recommended-tab') : 0
      }
      await this.getSkills()
      this.additionalInfoObj['time-stamp'] = new Date().toTimeString()
      this.recommendation.forEach(async (rcm, idx) => {
        await this.getRecommendationBySkill(rcm, idx)
        skillRcmCnt++
        if (this.recommendation.length === skillRcmCnt) {
          this.selectedTabIndex = this.$route.params.fromHome ? 4 : this.selectedTabIndex
          await this.updateTabIndex(this.selectedTabIndex)
          this.dataReady = true
          this.$nextTick(() => {
            this.recommendation.forEach((item, indx) => {
              item.index = indx
              item.recommend.byType.forEach((itm) => {
                observer.observe(document.getElementById(itm.id))
              })
            })
          }) // wait for re-render
          this.progressThresholds.forty = false
          this.progressThresholds.eighty = false
          this.$route.params.fromHome = false
        }
      })
      if (!this.recommendation.length) {
        this.dataReady = true
        this.$nextTick(() => {
          this.recommendation.forEach((item, indx) => {
            item.index = indx
            item.recommend.byType.forEach((itm) => {
              observer.observe(document.getElementById(itm.id))
            })
          })
        }) // wait for re-render
      }
      // define observer instance
      var observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // check if element is in view
            if (entry.isIntersecting) {
              const item = {
                'learner-id': this.learnerId,
                'tenant-guid': this.tenantGuid,
                'content-id': entry.target.id,
                'carousel-skill': entry.target.dataset.carouselName,
                'carousel-index': entry.target.dataset.carouselIndex,
                'recommend-index': entry.target.dataset.cardIndex,
                'carousel-uuid': entry.target.dataset.carouselUuid,
                'device-info': this.additionalInfoObj,
                action: 'Explore Carousel Viewed',
                timestamp: new Date().getTime()
              }
              const payload = {
                action: 'Explore',
                category: 'Explore Carousel Viewed',
                value: JSON.stringify(item),
                csvalue: item
              }
              // send GA & CS event
              this.GAEventTrack(payload)
              // remove observer once element is viewed
              observer.unobserve(entry.target)
            }
          })
        },
        {
          root: null, // default is the viewport
          threshold: 0.1 // percentage of target's visible area.
        }
      )
    },
    async getSkills() {
      this.recommendation = []
      const skills = await this.$learnAdmin.getUserExplorativeskills({ token: this.authToken })
      await skills.map((skill) => {
        if (!this.recommendation.some((e) => e.name === skill.name)) {
          this.recommendation.push({
            name: skill.name,
            uuid: skill.guid,
            recommend: {
              all: [],
              byType: []
            },
            loaded: false
          })
        }
      })
    },
    async getRecommendationBySkill(rcm, idx) {
      const __getRecommendationBySkill = async (rcm) => {
        const returnObj = await this.$learnAdmin.getMatchItemsForSkillsMultipleContentType({
          token: this.authToken,
          body: { skills: [rcm.name], contentTypes: this.contentTypes, includeUserMetaData: true }
        })
        let assets = []
        if (!this.tabs[4].hidden) assets = await this.$learnAdmin.getAssetByMatchSkills({ token: this.authToken, body: { skills: [rcm.name] } })
        const recommendation = returnObj.length > 0 ? returnObj[0]?.recommendations : []
        const emptyContent = {
          content: []
        }
        const obj = {
          credtls: Object.create(emptyContent),
          courses: Object.create(emptyContent),
          content: Object.create(emptyContent),
          assets: assets
        }
        recommendation.forEach((e) => {
          if (e.contentType == 'COURSE' && !this.tabs[3].hidden) obj.courses.content = e.contents
          if (e.contentType == 'CREDENTIAL' && !this.tabs[2].hidden) obj.credtls.content = e.contents
          if (e.contentType == 'ARTICLE' && !this.tabs[1].hidden) obj.content.content = e.contents
        })
        return obj
      }
      let { credtls, courses, content, assets } = await __getRecommendationBySkill(rcm)
      credtls.content = Array.isArray(credtls.content) ? credtls.content.filter((c) => c.progress < 100) : []
      courses.content = Array.isArray(courses.content) ? courses.content.filter((c) => c.progress < 100) : []
      content.content = Array.isArray(content.content) ? content.content.filter((c) => c.progress < 100) : []
      const size = Math.max(courses.content.length, credtls.content.length, content.content.length, assets.length)
      for (var i = 0; i < size; i++) {
        let item = null
        if (i < credtls.content.length) {
          item = credtls.content[i]
          item.format = { name: 'credential' }
          item.provider = { name: 'proedge' }
          item.assetType = 'credentials'
          item.duration = item.duration < 3600 ? `${Math.round(item.duration / 60)} min` : `${Math.round(item.duration / 3600)} hrs`
          item.duration = item.duration.length < 6 ? `${item.duration}` : item.duration
          item.launchBtn = item.progress > 0 && item.progress < 100 ? 'Resume' : 'Launch'
          item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item.publishedAt : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'requiredBy') ? item.requiredBy : ''
          item.content = {}.hasOwnProperty.call(item, 'content') ? item.content : ''
          item.level = {}.hasOwnProperty.call(item, 'courseLevels') ? item.courseLevels[0] : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          item.tracks = item.tracks ? item.tracks : []
          item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : ''
          if (this.requiredLearningPlanItems[item.id]) await this.getDueDate(item)
          this.recommendation[idx].recommend.all.push(item)
        }
        if (i < courses.content.length) {
          item = courses.content[i]
          item.assetType = 'artifacts'
          item.duration = item.duration < 3600 ? `${Math.round(item.duration / 60)} min` : `${Math.round(item.duration / 3600)} hrs`
          item.duration = item.duration.length < 6 ? `${item.duration}` : item.duration
          item.launchBtn = item.progress > 0 && item.progress < 100 ? 'Resume' : 'Launch'
          item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item.publishedAt : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'requiredBy') ? item.requiredBy : ''
          item.content = {}.hasOwnProperty.call(item, 'content') ? item.content : ''
          item.level = {}.hasOwnProperty.call(item, 'courseLevels') ? item.courseLevels[0] : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          item.tracks = item.tracks ? item.tracks : []
          item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : ''
          if (this.requiredLearningPlanItems[item.id]) await this.getDueDate(item)
          this.recommendation[idx].recommend.all.push(item)
        }
        if (i < content.content.length) {
          item = content.content[i]
          item.assetType = 'artifacts'
          item.duration = item.duration < 3600 ? `${Math.round(item.duration / 60)} min` : `${Math.round(item.duration / 3600)} hrs`
          item.duration = item.duration.length < 6 ? `${item.duration}` : item.duration
          item.launchBtn = item.progress > 0 && item.progress < 100 ? 'Resume' : 'Launch'
          item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item.publishedAt : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'requiredBy') ? item.requiredBy : ''
          item.content = {}.hasOwnProperty.call(item, 'content') ? item.content : ''
          item.level = {}.hasOwnProperty.call(item, 'courseLevels') ? item.courseLevels[0] : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          item.tracks = item.tracks ? item.tracks : []
          item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : ''
          if (this.requiredLearningPlanItems[item.id]) await this.getDueDate(item)
          this.recommendation[idx].recommend.all.push(item)
        }
        if (i < assets.length) {
          item = assets[i]
          item.assetType = 'automation'
          item.id = item.automationId
          item.format = { name: item.inventoryCategory }
          let logo = {}.hasOwnProperty.call(item, 'provider') && {}.hasOwnProperty.call(item.provider, 'logo') && {}.hasOwnProperty.call(item.provider.logo, 'uri')
          item.provider = {
            guid: {}.hasOwnProperty.call(item, 'provider') && item.provider.guid ? item.provider.guid : '',
            name: {}.hasOwnProperty.call(item, 'provider') && item.provider.name ? item.provider.name : '',
            avatarImage: logo ? item.provider.logo.uri : ''
          }
          item.type = {
            name: item.inventoryType.name,
            src: `${this.assetMediaUrl}media-container/${item.inventoryType.image}`
          }
          item.title = item.name
          item.synopsis = ''
          item.applications = await item.applications.map((application) => {
            return { name: application.name, src: `${this.assetMediaUrl}media-container/${application.image}` }
          })
          item.image = { uri: this.fetchAssetThumbnail(item.inventoryType.name) }
          item.launchBtn = 'Download'
          item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item.publishedAt : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'requiredBy') ? item.requiredBy : ''
          item.content = {}.hasOwnProperty.call(item, 'content') ? item.content : ''
          item.level = {}.hasOwnProperty.call(item, 'courseLevels') ? item.courseLevels[0] : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          item.tracks = item.tracks ? item.tracks : []
          item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : ''
          if (this.requiredLearningPlanItems[item.id]) await this.getDueDate(item)
          this.recommendation[idx].recommend.all.push(item)
        }
      }
      this.recommendation[idx].loaded = true
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    editBookmark(item) {
      let isDuplicateFound = false
      this.bookmarkedItems.forEach((bookmark) => {
        if (bookmark.id == item.id) {
          bookmark.isBookmarked = item.isBookmarked
          isDuplicateFound = true
        }
      })
      if (!isDuplicateFound) this.bookmarkedItems.push(item)
    },
    updateBookmarkedItems() {
      this.recommendation.forEach((skill) => {
        skill.recommend.all.forEach((item) => {
          this.bookmarkedItems.forEach((bookmark) => {
            if (bookmark.id == item.id && item.meta) {
              Object.assign(item.meta, { isBookmarked: bookmark.isBookmarked })
            }
          })
        })
      })
    },
    async updateTabIndex(index) {
      let assets = []
      this.selectedTabIndex = index
      if (this.bookmarkedItems.length > 0) {
        this.updateBookmarkedItems()
      }
      // define observer instance
      var observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // check if element is in view
            if (entry.isIntersecting) {
              const item = {
                'learner-id': this.learnerId,
                'tenant-guid': this.tenantGuid,
                'content-id': entry.target.id,
                'carousel-skill': entry.target.dataset.carouselName,
                'carousel-index': entry.target.dataset.carouselIndex,
                'recommend-index': entry.target.dataset.cardIndex,
                'carousel-uuid': entry.target.dataset.carouselUuid,
                'device-info': this.additionalInfoObj,
                action: 'Explore Carousel Viewed',
                timestamp: new Date().getTime()
              }
              const payload = {
                action: 'Explore',
                category: 'Explore Carousel Viewed',
                value: JSON.stringify(item),
                csvalue: item
              }
              // send GA & CS event
              this.GAEventTrack(payload)
              // remove observer once element is viewed
              observer.unobserve(entry.target)
            }
          })
        },
        {
          root: null, // default is the viewport
          threshold: 0.1 // percentage of target's visible area.
        }
      )
      switch (this.selectedTabIndex) {
        case 0:
          await this.recommendation.map((item) => {
            item.recommend.byType = item.recommend.all
          })
          break
        case 1:
          await this.recommendation.map((item, indx) => {
            item.recommend.byType = item.recommend.all.filter((r) => r.format.name.toLowerCase() === 'course')
            item.index = indx
            // Using observer to observe element
            setTimeout(() => {
              item.recommend.byType.forEach((itm) => {
                observer.observe(document.getElementById(itm.id))
              })
            }, 2000)
          })
          break
        case 2:
          await this.recommendation.map((item, indx) => {
            item.recommend.byType = item.recommend.all.filter((r) => r.format.name.toLowerCase() === 'credential')
            item.index = indx
            // Using observer to observe element
            setTimeout(() => {
              item.recommend.byType.forEach((itm) => {
                observer.observe(document.getElementById(itm.id))
              })
            }, 2000)
          })
          break
        case 3:
          await this.recommendation.map((item, indx) => {
            item.recommend.byType = item.recommend.all.filter((r) => !['course', 'credential', 'asset'].includes(r.format.name.toLowerCase()))
            item.index = indx
            // Using observer to observe element
            setTimeout(() => {
              item.recommend.byType.forEach((itm) => {
                observer.observe(document.getElementById(itm.id))
              })
            }, 2000)
          })
          break
        case 4:
          await Promise.allSettled(
            this.recommendation.map((item) => {
              item.recommend.byType = item.recommend.all.filter((r) => r.format.name.toLowerCase() === 'asset')
              assets = [...assets, ...item.recommend.byType]
            })
          )
          this.onAssetTabSelected()
          this.isAssetAvailable = assets.length > 0
          break
      }
    },
    openSkillModal(data) {
      this.skillModal = data ? data : this.skillModal
      if (!this.isDesktopPlatform) {
        document.getElementById('contentlayout').classList.add('no-scroll')
      }
    },
    openMobileSkillModal() {
      this.$refs.recommendedTop.scrollIntoView()
      this.skillModal = true
      if (!this.isDesktopPlatform) {
        document.getElementById('contentlayout').classList.add('no-scroll')
      }
    },
    async onCloseModal(data) {
      this.skillModal = false
      this.skillModalBottom = false
      if (!this.isDesktopPlatform) {
        document.getElementById('contentlayout').classList.remove('no-scroll')
      }
      let payload = []
      data.selectedSkills.forEach((element) => {
        if (element.guid) payload.push(element.guid)
        else if (element.id) payload.push(element.id)
      })

      if (data.isSelectedSkillsUpdated) {
        this.isNewExplore = true
        if (payload.length > data.previousSelectedSkillsLength) {
          const lastElements = payload.slice(data.previousSelectedSkillsLength, payload.length)
          const firstElements = payload.slice(0, data.previousSelectedSkillsLength)
          payload = [...lastElements, ...firstElements]
        }
        await this.$learnAdmin.postUserExplorativeSkills({ token: this.authToken, skills: payload })
        await this.getRecommendation()
      }
    },
    closeModal(eventType, item, index, skillName, skillIndex, skillUuid) {
      if (eventType == 'onTitle') {
        this.onRecommendedItemClick(item, index, skillName, skillIndex, skillUuid)
      }
      if (this.modal) {
        this.isExpandCardAllow = eventType === 'onTitle' ? false : true
        this.modal.dismiss()
        this.modal = null
        return
      }
    },
    async getDueDate(item) {
      const dueDate = this.requiredLearningPlanItems[item.id]
      const formattedToday = moment(dueDate).tz('America/New_York').format('DD MMM YYYY')
      if (item.timeToProgressSyncInHours > 0) {
        moment(dueDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
          ? (item.dueDate = formattedToday)
          : (item.pastDueDate = formattedToday)
      } else {
        moment(dueDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
      }
    },
    async onExpand(item, index, itemsLength, skillName, skillIndex, skillUuid) {
      if (window.innerWidth > 1024) {
        const perPage = window.innerWidth > 1420 ? 4 : window.innerWidth < 1420 && window.innerWidth > 1145 ? 3 : 2
        const currentPage = Math.floor(index / perPage)
        const itemIndexOnPage = index - perPage * currentPage
        const closeModal = this.closeModal
        const tab = 'expand-recommend-item'
        if (this.modal) {
          this.modal.dismiss()
          this.modal = null
          return
        }
        this.modal = await popoverController.create({
          component: UniversalCardModal,
          showBackdrop: false,
          backdropDismiss: true,
          cssClass: `recommend-item-popover recommend-item-popover-position-${itemIndexOnPage}`,
          componentProps: {
            parent: this,
            data: {},
            propsData: {
              itemsLength,
              item,
              index,
              tab,
              closeModal,
              skillName,
              skillIndex,
              skillUuid
            }
          }
        })
        await this.modal.present()
      } else {
        return null
      }
    },
    async onRecommendedItemClick(recItem, recItemIndex, skillName, skillIndex, skillUuid) {
      await localforage.setItem('recommended-tab', this.selectedTabIndex)
      const item = {
        'learner-id': this.learnerId,
        'tenant-guid': this.tenantGuid,
        'content-id': recItem.id,
        'recommend-index': recItemIndex,
        'carousel-skill': skillName,
        'carousel-index': skillIndex,
        'carousel-uuid': skillUuid,
        action: 'Explore Item Click',
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'Explore',
        category: 'Explore Item Click',
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async onAssetTabSelected() {
      const item = {
        'learner-id': this.learnerId,
        'tenant-guid': this.tenantGuid,
        action: 'Tab clicked'
      }
      const payload = {
        action: 'Asset',
        category: 'Explore Asset Tab',
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.recommend-item-popover-position-0 {
  .popover-content {
    left: 4% !important;
  }
}
.recommend-item-popover-position-1 {
  .popover-content {
    left: 22% !important;
    @media screen and (max-width: 1420px) {
      left: 28% !important;
    }
    @media screen and (max-width: 1145px) {
      left: 38% !important;
    }
  }
}
.recommend-item-popover-position-2 {
  .popover-content {
    left: 45% !important;
    @media screen and (max-width: 1420px) {
      left: 57% !important;
    }
  }
}
.recommend-item-popover-position-3 {
  .popover-content {
    left: 64% !important;
  }
}
</style>

<style lang="scss" scoped>
.recommend-container {
  text-align: left;
  margin: 1rem;
  margin-bottom: 75px;
}

.recommend-nav {
  border-bottom: 1px solid var(--ion-color-dark-role-grey);
  margin-top: 70px;
}

.recommend-content {
  margin-bottom: 1rem;
}

.recommend-label {
  padding: 3rem 0rem 3rem 0rem;
  font-size: 1.75rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-black);
}

.recommend-count {
  position: relative;
  top: -0.12rem;
  margin-left: 0.5rem;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-family: 'Arial';
  font-weight: bold;
  background: var(--ion-color-dark-role-grey);
}

.carousel-desktop-item,
.carousel-desktop-item-shimmer {
  width: 360px !important;
  height: 360px !important;
  border-radius: 20px;
  padding: 2rem;
}

.carousel-desktop-item {
  background: #ffffff;
}

.carousel-desktop-asset-item {
  width: 360px !important;
}

.addskill-icon {
  background-color: #1f3370;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px 0px 10px;
  text-align: center;
  color: white;
}

.recommend-footer {
  border: 2px solid none;
  border-radius: 10px;
  height: 300px;
  background-image: linear-gradient(to bottom, var(--ion-color-white), var(--ion-color-light-gray) 20%);
}

.recommend-footer-image-desktop {
  float: left;
  height: 350px;
  width: 350px;
  margin-top: -25px;
  margin-left: 50px;
}
.recommend-footer-image-mobile {
  display: none;
}

.recommend-footer-copy {
  margin-top: 100px;
  padding-top: 80px;
  padding-left: 20px;
}

.footer-title {
  color: var(--ion-color-dark-blue);
  font-family: 'Futura PT Demi';
  font-size: 2rem;
  margin: 0px;
}

.footer-subtitle {
  font-family: 'Arial';
  color: var(--ion-color-role-grey);
  font-size: 1rem;
  margin-top: 4px;
}

.footer-button {
  width: 140px;
  color: var(--ion-color-black);
  font-family: 'Arial';
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-top: 8px;
}

ion-button {
  --box-shadow: none;
}

.addskill-icon-footer {
  background-color: #1f3370;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 5px 0px 0px;
  padding-top: 1px;
  text-align: center;
  color: white;
}

.carousel-mobile-item,
.carousel-mobile-item-shimmer {
  min-width: 360px !important;
  max-width: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important;
  padding: 2rem;
  border-radius: 20px;
  margin-right: 1rem;
}

.carousel-mobile-item {
  background: #ffffff;
}

@media screen and (max-width: 1024px) {
  .recommend-nav {
    padding-top: 35px;
  }
}

.recommend-banner {
  width: 100%;
  height: 350px;
  // box-shadow: 0px 0px 6px #00000014;
  border: 3px solid #ffffff;
  border-radius: 20px;
  background-image: url('~@/assets/images/explore-banner.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: 20%;
  background-position-y: 60%;
}

.recommend-banner-add-skill-container {
  width: 60%;
  height: 290px;
  border-radius: 20px;
  margin: 75px 45px;
  padding: 1.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.recommend-banner-title {
  text-align: left;
  font: normal normal bold 12px Arial;
  letter-spacing: 1.2px;
  color: #000000;
  text-transform: uppercase;
}

.recommend-banner-content {
  text-align: left;
  font: normal normal normal 48px Futura PT Demi;
  letter-spacing: 0px;
  padding: 10px 0px 40px 0px;
  color: #000000;
}

.recommend-banner-add-skill-button {
  float: right;
  width: 114px;
  height: 50px;
  background: #feb92e 0% 0% no-repeat padding-box;
  border-radius: 25px;
  text-align: center;
  font: normal normal normal 16px Futura PT Demi;
  letter-spacing: 0.02px;
  color: #000000;
}

.recommend-label-shimmer {
  padding: 1rem 0rem 3rem 0rem;
  height: 24px;
  width: 300px;
  border-radius: 0px 12px 12px 0px;
  color: var(--ion-color-black);
}

@media screen and (max-width: 1024px) {
  .recommend-banner {
    height: 292px;
  }
  .recommend-banner-add-skill-container {
    width: 589px;
    height: 248px;
  }
  .recommend-banner-content {
    font: normal normal normal 34px Futura PT Demi;
  }
}

// Screens under 480px
@media screen and (max-width: 480px) {
  .recommend-nav {
    margin-bottom: 2rem;
  }
  .recommend-content {
    margin-bottom: unset;
  }
  .recommend-label {
    padding: 0rem 0rem 3rem 0rem;
  }
  .recommend-footer {
    height: 200px;
    background-image: linear-gradient(to top, var(--ion-color-white), var(--ion-color-light-gray) 20%);
  }
  .recommend-footer-image-desktop {
    display: none;
  }
  .recommend-footer-image-mobile {
    display: block;
    float: right;
    height: 180px;
    width: 170px;
    margin-top: -40px;
    margin-right: 50px;
  }
  .footer-title {
    font-size: 1.2rem;
  }
  .recommend-footer-copy {
    padding-top: 10px;
  }
  .footer-subtitle {
    font-size: 12px;
  }
  .footer-button {
    width: 140px;
    font-size: 0.8rem;
  }
  .addskill-icon-footer {
    padding-top: 2px;
  }

  .recommend-banner {
    height: 225px;
  }
  .recommend-banner-add-skill-container {
    width: 292px;
    height: 165px;
  }
  .recommend-banner-content {
    font: normal normal normal 20px Futura PT Demi;
  }

  .recommend-banner-title {
    font: normal normal bold 9px Arial;
    letter-spacing: 0.45px;
  }

  .recommend-banner-add-skill-button {
    width: 106px;
    height: 40px;
    border-radius: 20px;
  }
}
</style>
