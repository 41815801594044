import axios from 'axios'

export default {
  async getSkills(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSkillsSuggested(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSkillById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSkillByTitle(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async likeSkill(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async dislikeSkill(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSkillComments(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async postSkillComment(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  }
}
