var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-filters" },
    [
      _vm.dataReady
        ? _c(
            "ion-grid",
            { staticClass: "ion-no-padding" },
            [
              _c(
                "ion-row",
                {
                  staticClass: "all-filters__heading-row",
                  class: _vm.$platform === "Desktop" ? "" : "header-mobile"
                },
                [
                  _c("ion-col", [
                    _c("h2", { staticClass: "all-filters__heading" }, [
                      _vm._v("All Filters")
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "all-filters__filter-close",
                        attrs: { "aria-label": "close side menu" },
                        on: { click: _vm.toggleFilterBar }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "close-icon",
                            "class-name": "all-filters__filter-close-svg"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "all-filters__filter-container",
                  class:
                    _vm.isFilterDetailOpen && _vm.$platform != "Desktop"
                      ? "ios-filter-container"
                      : ""
                },
                _vm._l(_vm.filters, function(filter, index) {
                  return _c(
                    "ion-row",
                    {
                      key: filter.name + index,
                      staticClass: "all-filters__filter-row",
                      on: {
                        click: function($event) {
                          return _vm.openFilterDetail(filter.name)
                        }
                      }
                    },
                    [
                      _c(
                        "ion-col",
                        { staticClass: "all-filters__filter-col" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "all-filters__filter-row-title",
                              staticStyle: { display: "inline-block" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  filter.name == "Tool"
                                    ? "Technology"
                                    : filter.name
                                )
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "all-filters__filter-row-selected-options"
                            },
                            [
                              filter.selectedOptionsArray &&
                              filter.selectedOptionsArray.length > 0
                                ? _c(
                                    "div",
                                    {
                                      key: filter.name + index,
                                      staticStyle: { display: "inline-block" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          key: filter.selectedOptionsArray.join(
                                            ","
                                          ),
                                          staticClass:
                                            "all-filters__filter-row-selected-options-name"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              filter.selectedOptionsArray.join(
                                                ", "
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "ion-col",
                        { attrs: { size: "1" } },
                        [
                          _c("svg-icon", {
                            staticClass: "all-filters__filter-row-icon",
                            attrs: { "icon-class": "icon-arrow-right-dark" }
                          })
                        ],
                        1
                      ),
                      _vm.isFilterDetailOpen &&
                      _vm.currentOpenFilter == filter.name
                        ? _c(
                            "div",
                            {
                              key: filter.name + index,
                              staticClass: "all-filters__filter-detail"
                            },
                            [
                              _c(
                                "ion-row",
                                {
                                  staticClass: "all-filters__heading-row",
                                  class:
                                    _vm.$platform === "Desktop"
                                      ? ""
                                      : "header-mobile"
                                },
                                [
                                  _c("ion-col", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "all-filters__filter-close",
                                        staticStyle: { float: "left" },
                                        attrs: {
                                          "aria-label": "close side menu"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.closeFilterDetail(filter)
                                          }
                                        }
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class":
                                              "icon-arrow-left-dark",
                                            "class-name":
                                              "all-filters__filter-close-svg"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "h2",
                                      { staticClass: "all-filters__heading" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            filter.name == "Tool"
                                              ? "Technology"
                                              : filter.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "all-filters__filter-close",
                                        attrs: {
                                          "aria-label": "close side menu"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.closeFilterDetail(filter)
                                          }
                                        }
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "close-icon",
                                            "class-name":
                                              "all-filters__filter-close-svg"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "ion-row",
                                {
                                  staticClass:
                                    "ion-text-center ion-padding-bottom"
                                },
                                [
                                  _c(
                                    "ion-col",
                                    [
                                      filter.filterOptions.length > 12
                                        ? _c("ion-searchbar", {
                                            ref: "searchbar_filter",
                                            refInFor: true,
                                            staticClass: "searchbar-filter",
                                            staticStyle: {
                                              "text-align": "left",
                                              "--border-radius": "50px"
                                            },
                                            attrs: {
                                              "search-icon": "search-outline",
                                              value: _vm.searchFilter,
                                              placeholder:
                                                filter.name == "Tool"
                                                  ? "Search Technology"
                                                  : "Search " + filter.name
                                            },
                                            on: {
                                              ionInput: function($event) {
                                                return _vm.handleFilterSearchInput(
                                                  $event,
                                                  filter
                                                )
                                              },
                                              ionClear: function($event) {
                                                return _vm.handleFilterClearInput(
                                                  filter
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "all-filters__filter-options-scroller",
                                  class:
                                    _vm.$platform === "Desktop"
                                      ? ""
                                      : "options-scroller-mobile"
                                },
                                _vm._l(filter.filterOptions, function(
                                  filterOption,
                                  index
                                ) {
                                  return _c(
                                    "ion-row",
                                    {
                                      key: _vm.filterOptKey + index,
                                      staticClass:
                                        "all-filters__filter-detail-row",
                                      style: filterOption.style
                                        ? filterOption.style
                                        : "display: flex"
                                    },
                                    [
                                      _c(
                                        "ion-col",
                                        { attrs: { size: "1" } },
                                        [
                                          _c("ion-checkbox", {
                                            key:
                                              filterOption.name +
                                              filterOption.selected,
                                            staticClass:
                                              "all-filters__checkbox",
                                            attrs: {
                                              checked: filterOption.selected,
                                              value:
                                                filter.name === "Duration"
                                                  ? filterOption.id
                                                  : filterOption.name,
                                              color: "tertiary",
                                              mode: "md",
                                              disabled: filterOption.count == 0
                                            },
                                            on: {
                                              ionChange: function($event) {
                                                return _vm.onFilterOptionChange(
                                                  $event,
                                                  filter.name
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "ion-col",
                                        {
                                          staticClass:
                                            "all-filters__filter-detail-name",
                                          class:
                                            filterOption.count == 0
                                              ? "filter-detail-name-disabled"
                                              : ""
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.filterName(
                                                filterOption,
                                                filter.name
                                              )
                                            ) +
                                              " (" +
                                              _vm._s(filterOption.count) +
                                              ")"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "ion-row",
                                {
                                  staticClass: "all-filters__filter-footer",
                                  class:
                                    _vm.$platform === "Desktop"
                                      ? ""
                                      : "footer-mobile"
                                },
                                [
                                  _c(
                                    "ion-col",
                                    { staticClass: "ion-text-center" },
                                    [
                                      _c(
                                        "ion-button",
                                        {
                                          staticClass:
                                            "all-filters__clear-button",
                                          attrs: { fill: "clear" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.clearAllFiltersDetail(
                                                filter
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Clear filters")]
                                      ),
                                      _c(
                                        "ion-button",
                                        {
                                          staticClass:
                                            "ion-button-primary all-filters__done-button",
                                          attrs: {
                                            shape: "round",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.applyFilter(filter)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "Apply " +
                                              _vm._s(
                                                filter.name == "Tool"
                                                  ? "technology"
                                                  : filter.name.toLowerCase()
                                              )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              ),
              !_vm.isFilterDetailOpen
                ? _c(
                    "ion-row",
                    {
                      staticClass: "all-filters__filter-footer",
                      class: _vm.$platform === "Desktop" ? "" : "footer-mobile"
                    },
                    [
                      _c(
                        "ion-col",
                        { staticClass: "ion-text-center" },
                        [
                          _c(
                            "ion-button",
                            {
                              staticClass: "all-filters__clear-button",
                              attrs: { fill: "clear" },
                              on: { click: _vm.onClickClearAll }
                            },
                            [_vm._v("Clear all filters")]
                          ),
                          _vm.resultsCount > 0
                            ? _c(
                                "ion-button",
                                {
                                  staticClass:
                                    "ion-button-primary all-filters__done-button",
                                  attrs: { shape: "round", color: "primary" },
                                  on: { click: _vm.onClickDone }
                                },
                                [
                                  _vm._v(
                                    "View " +
                                      _vm._s(
                                        _vm._f("formatCount")(_vm.resultsCount)
                                      ) +
                                      " results"
                                  )
                                ]
                              )
                            : _c(
                                "ion-button",
                                {
                                  staticClass:
                                    "ion-button-primary all-filters__done-button",
                                  attrs: { shape: "round", color: "primary" },
                                  on: { click: _vm.onClickDone }
                                },
                                [_vm._v("Done")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }