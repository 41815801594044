<template>
  <div class="tooltip-bottom" :style="{ cursor: 'pointer' }">
    <button v-if="showEdit" class="tooltip-bottom-text" @click.self="onAction('edit')">Edit</button>
    <button v-if="showDelete" class="tooltip-bottom-text" @click.self="onAction('delete')">Delete</button>
    <button v-if="showAddToTrack" class="tooltip-bottom-text" @click.self="onAction('add')">Add to a track</button>
    <button v-if="showRemoveFromTrack" class="tooltip-bottom-text" @click.self="onAction('remove')">Remove from track</button>
    <button v-if="editTrack" class="tooltip-bottom-text" @click.self="onAction('edit-track')">Edit track</button>
    <button v-if="deleteTrack" class="tooltip-bottom-text" @click.self="onAction('delete-track')">Delete track</button>
  </div>
</template>
  
<script>
import { popoverController } from '@modus/ionic-vue'

export default {
  name: 'MoreActionPopover',
  props: {
    routeInfo: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    router: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    assetId: {
      type: String,
      required: true,
      default: () => {
        return ''
      }
    },
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    showAddToTrack: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    showRemoveFromTrack: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    editTrack: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    deleteTrack: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {}
  },
  mounted() {
    document.addEventListener('click', this.close, true)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close, true)
  },
  methods: {
    onAction(action) {
      if (action === 'add') {
        this.$eventBus.$emit('add-to-track', this.item)
        this.$emit('add-to-track')
      } else if (action == 'remove') {
        popoverController.dismiss({ action: action })
        this.$emit(`remove-from-track`)
      } else {
        popoverController.dismiss({ action: action })
        this.$emit('edit-delete-external-content', action)
        this.$emit('edit-delete-track-content', action)
        this.$eventBus.$emit(`dismiss-more-action-model`)
      }
    },
    close(e) {
      if (!e.target._prevClass?.includes('more-popover') && !e.target._prevClass?.includes('tooltip-bottom-text')) {
        this.$emit('close-more')
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
.chain-link {
  width: 25px;
  height: 25px;
  margin: 0px 8px;
  display: inline-flex;
  align-items: center;
}

.green-checkmark {
  width: 20px;
  height: 20px;
  margin: 0px 10px;
  display: inline-flex;
  align-items: center;
}

.tooltip-bottom {
  .tooltip-bottom-text {
    background: none;
    font-family: 'Arial';
    color: #2d2d2d;
    padding: 5px;
    letter-spacing: 0px;
    display: block;
  }
  .tooltip-bottom-text:hover {
    background: var(--ion-color-blue-light) !important;
  }
}
</style>
  