<template>
  <div :class="completed ? 'completed' : ''">
    <ion-button data-cy="content-complete" class="edge-complete ion-text-capitalize" shape="round" color="primary" @click="onComplete">Mark complete</ion-button>
  </div>
</template>

<script>
export default {
  name: 'ContentComplete',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      completed: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    onComplete() {
      this.completed = true
      this.$emit('on-complete')
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  margin: 48px 0 0;
  display: flex;
}

@include block('edge-complete') {
  --ion-color-primary: #1f3370 !important;
  --ion-color-contrast: #fff !important;
  font-weight: bold;
  width: 140px;
  @include element('label') {
    position: relative;
    right: 40px;
    top: 5px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 5px 10px;
    background: map-get($edge-colors, 'tertiary');
    border: 1px solid map-get($edge-colors, 'tertiary');
    color: map-get($edge-colors, 'white');
    text-align: center;
    margin: 3px;
  }
}

.completed {
  position: relative;
  ion-button {
    display: none;
  }
  ion-label {
    display: block;
    margin: 1rem auto;
    &:before {
      display: block;
      box-sizing: content-box;
      position: absolute;
      padding: 20px;
      border: 3px dashed #ffa51f;
      border-radius: 50%;
      content: '';
      margin: -11px 0px 0 -16px;
    }
    .completed-text {
      font-size: 1rem;
      font-weight: 600;
      color: var(--ion-color-dark-blue);
      display: inline-block;
      margin-left: 2px;
      width: 119px;
      top: 5px;
      position: absolute;
    }
  }
}

ion-button {
  --box-shadow: none;
}
</style>
