import Vue from 'vue'

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/account/Login'),
    meta: {
      whitelist: true,
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: false,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/account/ResetPassword'),
    meta: {
      whitelist: true,
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: false,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    },
    beforeEnter: async (to, from, next) => {
      to.path === '/reset-password' && Vue.prototype.$platform === 'Desktop' ? next('/landing?modal=ResetPassword') : next()
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/account/ChangePassword'),
    meta: {
      whitelist: true,
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: false,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  }
]
