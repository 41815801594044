var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataReady
    ? _c(
        "div",
        {
          style: _vm.isDesktopPlatform
            ? "margin-top: 150px"
            : "margin-top: 60px"
        },
        [
          _vm.tenantAsset.available || _vm.tenantAsset.enabled
            ? _c("div", { staticClass: "company-assets-container" }, [
                _vm._m(0),
                _c("div", { staticClass: "company-asset-title" }, [
                  _vm._v(_vm._s(_vm.tenant.name) + " assets")
                ]),
                _c(
                  "div",
                  { staticClass: "company-asset-btns" },
                  [
                    _vm.tenantAsset.available
                      ? _c(
                          "ion-button",
                          {
                            staticClass: "company-asset-view-all-btn",
                            attrs: { shape: "round", color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.onViewAllTenantAsset()
                              }
                            }
                          },
                          [_vm._v("View all")]
                        )
                      : _vm._e(),
                    !_vm.tenantAsset.available &&
                    _vm.tenantAsset.submissionLink.length
                      ? _c("span", { staticClass: "company-asset-txt" }, [
                          _vm._v("Have a digital asset to share ?")
                        ])
                      : _vm._e(),
                    _vm.tenantAsset.enabled
                      ? _c(
                          "span",
                          { staticStyle: { padding: "0", margin: "0" } },
                          [
                            _c(
                              "ion-button",
                              {
                                staticClass: "company-asset-submit-asset-btn",
                                attrs: { shape: "round", color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.onSubmitAsset()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.tenantAsset.action) + " "
                                ),
                                _vm.tenantAsset.submissionLink.length
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "5px" }
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            height: "16px",
                                            width: "16px"
                                          },
                                          attrs: { "icon-class": "expand-out" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "technology-asset-container" }, [
            _c("div", { staticClass: "technology-asset-title" }, [
              _vm._v("Assets by technology")
            ]),
            _c(
              "div",
              { staticClass: "technology-asset-application-container" },
              _vm._l(_vm.applications, function(application) {
                return _c(
                  "a",
                  {
                    key: application.name,
                    staticClass: "technology-asset-application",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.onSearchByApplication(application.name)
                      }
                    }
                  },
                  [
                    _c(
                      "ion-row",
                      { staticClass: "technology-asset-application-grid" },
                      [
                        _c(
                          "ion-col",
                          {
                            staticClass:
                              "ion-align-items-center technology-asset-application-img-container"
                          },
                          [
                            _c("img", {
                              staticClass: "technology-asset-application-img",
                              attrs: { src: application.image }
                            })
                          ]
                        ),
                        _c(
                          "ion-col",
                          {
                            staticClass:
                              "ion-align-items-center technology-asset-application-text"
                          },
                          [_vm._v(" " + _vm._s(application.name) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("div", { staticClass: "type-asset-container" }, [
            _c("div", { staticClass: "type-asset-title" }, [
              _vm._v("Assets by type")
            ]),
            _c(
              "div",
              { staticClass: "type-asset-application-container" },
              _vm._l(_vm.types, function(type) {
                return _c(
                  "a",
                  {
                    key: type.name,
                    staticClass: "type-asset-application",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.onSearchByType(type.name)
                      }
                    }
                  },
                  [
                    _c(
                      "ion-row",
                      { staticClass: "type-asset-application-grid" },
                      [
                        _c(
                          "ion-col",
                          {
                            staticClass:
                              "ion-align-items-center type-asset-application-text",
                            attrs: { size: "6" }
                          },
                          [_vm._v(" " + _vm._s(type.name) + " ")]
                        ),
                        _c(
                          "ion-col",
                          {
                            staticClass:
                              "ion-align-items-center type-asset-application-img-container ion-no-padding",
                            attrs: { size: "6" }
                          },
                          [
                            _c("img", {
                              staticClass: "type-asset-application-img",
                              attrs: { src: type.image }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("div", { staticClass: "search-asset-container" }, [
            _c("div", { staticClass: "search-asset-header" }, [
              _vm._v("Search assets")
            ]),
            _c(
              "div",
              { staticClass: "search-asset" },
              [
                _c("ion-searchbar", {
                  ref: "searchbar",
                  class: _vm.isDesktopPlatform
                    ? "searchbar-desktop"
                    : "searchbar-mobile",
                  attrs: {
                    "search-icon": "search-outline",
                    value: _vm.query,
                    placeholder: ""
                  },
                  on: {
                    keypress: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleSearchInput($event)
                    },
                    ionClear: _vm.handleClearInput
                  }
                }),
                _c(
                  "ion-button",
                  {
                    staticClass: "search-asset-btn",
                    attrs: { shape: "round", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleSearchInput()
                      }
                    }
                  },
                  [_vm._v("Search")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "search-asset-view-all-btn-container" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass: "search-asset-view-all-btn",
                    attrs: { shape: "round", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.onViewAllAsset()
                      }
                    }
                  },
                  [_vm._v("View all assets")]
                )
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "company-asset-orb-container" }, [
      _c("img", {
        staticStyle: { width: "100px" },
        attrs: { src: require("@/assets/images/recommend-asset-orb.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }