var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShown
    ? _c(
        "div",
        [
          _c(
            "transition",
            { attrs: { name: "slide-menu" } },
            [
              _vm.isNavBarMenuVisible && _vm.isOpen
                ? _c("NavBarMenuMobile", {
                    class: _vm.isNavBarMenuVisible
                      ? "show-nav-menu"
                      : "hide-nav-menu",
                    attrs: { navBarMenu: _vm.navBarMenu },
                    on: {
                      close: function($event) {
                        return _vm.onCloseNavBarMenu()
                      },
                      logoClick: function($event) {
                        return _vm.onLogoClick()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edge-navbar" },
            [
              _vm.searchBar.state.on &&
              _vm.showMobileTypeahead &&
              ((_vm.autoSuggestFlagEnabled &&
                _vm.autoSuggestList.length > 0 &&
                _vm.currentSearchQuery != "") ||
                (_vm.lookingForFlagEnabled && _vm.autoSuggestList.length == 0))
                ? _c("div", {
                    staticStyle: {
                      position: "absolute",
                      "background-color": "transparent",
                      height: "100vh",
                      width: "100%",
                      opacity: "0.2"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.autoSuggestBackdrop()
                      }
                    }
                  })
                : _vm._e(),
              _c("svg-icon", {
                attrs: {
                  "icon-class": "proedge-mobile-logo",
                  "class-name": "mobile-logo"
                },
                on: {
                  click: function($event) {
                    return _vm.onLogoClick()
                  }
                }
              }),
              _c(
                "button",
                { staticClass: "logo" },
                [
                  _c("svg-icon", {
                    staticClass: "hamburger",
                    attrs: { "icon-class": "hamburger-menu-icon" },
                    on: {
                      click: function($event) {
                        return _vm.showNavBarMenu()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.searchBar.state.on
                    ? _c("ion-searchbar", {
                        ref: "searchbar",
                        attrs: {
                          "show-cancel-button": "never",
                          placeholder:
                            "Search using titles, topics, and keywords"
                        },
                        on: {
                          keypress: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearchInput($event)
                          },
                          focusin: function($event) {
                            return _vm.onSearchbarFocus()
                          },
                          ionCancel: function($event) {
                            return _vm.toggleSearchBar(false)
                          },
                          keyup: _vm.handleSuggestionInput,
                          ionClear: function($event) {
                            return _vm.handleSearchClearButton()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.searchBar.state.on
                    ? _c(
                        "p",
                        {
                          staticClass: "searchbar-cancel-button",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.autoSuggestBackdrop()
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.searchBar.state.on && _vm.showMobileTypeahead
                ? _c(
                    "div",
                    { staticClass: "mobile-typeahead" },
                    [
                      _vm.autoSuggestFlagEnabled &&
                      _vm.autoSuggestList.length > 0 &&
                      _vm.currentSearchQuery != ""
                        ? _c(
                            "ion-grid",
                            {
                              key:
                                _vm.autoSuggestList.length + "main_typeahead",
                              staticClass:
                                "ion-padding mobile-typeahead-main-component"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mobile-typeahead-results-container"
                                },
                                _vm._l(
                                  _vm.autoSuggestList.slice(0, 10),
                                  function(item, index) {
                                    return _c(
                                      "ion-row",
                                      {
                                        key: item + index,
                                        staticClass:
                                          "ion-margin-vertical mobile-typeahead-result-row"
                                      },
                                      [
                                        !item.exactMatch &&
                                        item.category != "Skill"
                                          ? _c(
                                              "ion-col",
                                              {
                                                key: item.text + "searchicon",
                                                attrs: { size: "1" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickTypeAhead(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass:
                                                    "mobile-typeahead-search-icon",
                                                  attrs: {
                                                    "icon-class": "icon-search"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !item.exactMatch &&
                                        item.category != "Skill"
                                          ? _c(
                                              "ion-col",
                                              {
                                                key: item.text + "searchtext",
                                                staticClass:
                                                  "mobile-typeahead-search-result-ellipsis",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickTypeAhead(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("p", {
                                                  staticClass:
                                                    "mobile-typeahead-search-result",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.displayText
                                                    )
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        !item.exactMatch &&
                                        item.category == "Skill"
                                          ? _c(
                                              "ion-col",
                                              {
                                                key: item.text + "skillicon",
                                                attrs: { size: "1" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickTypeAhead(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass:
                                                    "mobile-typeahead-search-icon",
                                                  attrs: {
                                                    "icon-class":
                                                      "auto-suggest-skill"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !item.exactMatch &&
                                        item.category == "Skill"
                                          ? _c(
                                              "ion-col",
                                              {
                                                key: item.text + "skilltext",
                                                staticClass:
                                                  "mobile-typeahead-search-result-ellipsis",
                                                staticStyle: {
                                                  "padding-top": "0px",
                                                  "padding-left": "12px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickTypeAhead(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("p", {
                                                  staticClass:
                                                    "mobile-typeahead-search-result",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.displayText
                                                    )
                                                  }
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mobile-typeahead-search-result-category"
                                                  },
                                                  [_vm._v("Skill")]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        item.exactMatch
                                          ? _c(
                                              "ion-col",
                                              {
                                                key: item.text + "image",
                                                attrs: { size: "1" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickDirectMatch(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "home-img-search-mobile"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.getImageUri(
                                                          item
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        item.exactMatch
                                          ? _c(
                                              "ion-col",
                                              {
                                                key: item.text + "name",
                                                staticClass:
                                                  "mobile-typeahead-search-result-ellipsis",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickDirectMatch(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("p", {
                                                  staticClass:
                                                    "mobile-typeahead-search-result mobile-typeahead-search-result-ellipsis",
                                                  staticStyle: {
                                                    "padding-left": "8px"
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.displayText
                                                    )
                                                  }
                                                }),
                                                item.content.format.name.toLowerCase() !==
                                                "asset"
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mobile-typeahead-search-result-category",
                                                        staticStyle: {
                                                          "padding-left": "8px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.type)
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mobile-typeahead-search-result-category",
                                                        staticStyle: {
                                                          "padding-left": "8px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.type) +
                                                            " | " +
                                                            _vm._s(
                                                              item.applications.join(
                                                                " | "
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "ion-row",
                                [
                                  _c(
                                    "ion-col",
                                    { staticClass: "ion-text-center" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mobile-typeahead-see-all",
                                          on: { click: _vm.handleSearchSeeAll }
                                        },
                                        [
                                          _vm._v(
                                            'See all results for "' +
                                              _vm._s(_vm.currentSearchQuery) +
                                              '"'
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.lookingForFlagEnabled &&
                      _vm.autoSuggestList.length == 0
                        ? _c(
                            "ion-grid",
                            {
                              key: _vm.autoSuggestList.length + "looking_for",
                              staticClass:
                                "ion-padding mobile-looking-for-component ion-text-start"
                            },
                            [
                              _c(
                                "ion-row",
                                [
                                  _c("ion-col", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mobile-typeahead-header"
                                      },
                                      [_vm._v("Browse by")]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._l(_vm.lookingForFilters, function(filter) {
                                return _c(
                                  "ion-row",
                                  {
                                    key: filter,
                                    staticClass: "mobile-content-type-pill",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.applyContentTypeFilter(
                                          filter
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(filter) + " ")]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "ion-row",
            { staticClass: "right-icons-section" },
            [
              _c("ion-col", [
                _vm.searchBar.state.off && _vm.isSearchable
                  ? _c(
                      "button",
                      {
                        staticClass: "search-icon",
                        on: {
                          click: function($event) {
                            return _vm.toggleSearchBar(true)
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "icon-search",
                            "class-name": "g-icon--small icon-search-mobile"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm.tenantCustomInfo.logoUri.length
                ? _c(
                    "ion-col",
                    { staticClass: "tenant-custom-logo ion-no-padding" },
                    [
                      _c("img", {
                        attrs: { src: _vm.tenantCustomInfo.logoUri }
                      })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }