import { render, staticRenderFns } from "./Workspace.vue?vue&type=template&id=0c2bd3d1&scoped=true&"
import script from "./Workspace.vue?vue&type=script&lang=js&"
export * from "./Workspace.vue?vue&type=script&lang=js&"
import style0 from "./Workspace.vue?vue&type=style&index=0&id=0c2bd3d1&lang=scss&scoped=true&"
import style1 from "./Workspace.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2bd3d1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/vtrinh004/Desktop/YTC/web/proedge-learn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c2bd3d1')) {
      api.createRecord('0c2bd3d1', component.options)
    } else {
      api.reload('0c2bd3d1', component.options)
    }
    module.hot.accept("./Workspace.vue?vue&type=template&id=0c2bd3d1&scoped=true&", function () {
      api.rerender('0c2bd3d1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/workspace/Workspace.vue"
export default component.exports