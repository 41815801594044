var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "confirmation-modal__backdrop" }),
    _c(
      "div",
      { staticClass: "confirmation-modal" },
      [
        _c("svg-icon", {
          staticClass: "g-icon--xsmall cancel-icon-container",
          attrs: { "icon-class": "filter-close" },
          on: { click: _vm.onCancel }
        }),
        _c("h2", [_vm._v(_vm._s(_vm.modalProperties.title))]),
        _c(
          "ion-row",
          [
            _vm.modalProperties.subtitle
              ? _c(
                  "ion-col",
                  {
                    attrs: {
                      "size-xl": "12",
                      "size-lg": "12",
                      "size-md": "12",
                      "size-sm": "12",
                      "size-xs": "12"
                    }
                  },
                  [
                    _c("div", { staticClass: "text-container" }, [
                      _vm._v(_vm._s(_vm.modalProperties.subtitle))
                    ]),
                    _c("ion-textarea", {
                      staticClass: "placeholder-container",
                      attrs: {
                        autoGrow: "true",
                        rows: "4",
                        autofocus: "",
                        placeholder: _vm.modalProperties.textAreaPlaceholder
                      },
                      on: { ionChange: _vm.handleCommentsInput }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.modalProperties.text
              ? _c(
                  "ion-col",
                  {
                    attrs: {
                      "size-xl": "12",
                      "size-lg": "12",
                      "size-md": "12",
                      "size-sm": "12",
                      "size-xs": "12"
                    }
                  },
                  [
                    _c("div", { staticClass: "placeholder-container" }, [
                      _vm._v(_vm._s(_vm.modalProperties.text))
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "ion-col",
              {
                staticClass: "text-container",
                attrs: {
                  "size-xl": "12",
                  "size-lg": "12",
                  "size-md": "12",
                  "size-sm": "12",
                  "size-xs": "12"
                }
              },
              [
                _vm.modalProperties.primaryButton == "Submit now"
                  ? _c(
                      "ion-button",
                      {
                        staticClass:
                          "ion-text-capitalize ion-button-primary-dark",
                        attrs: { shape: "round", color: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.modalProperties.primaryButton) + " "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.modalProperties.primaryButton == "Resubmit"
                  ? _c(
                      "ion-button",
                      {
                        staticClass:
                          "ion-text-capitalize ion-button-primary-dark",
                        attrs: { shape: "round", color: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.modalProperties.primaryButton) + " "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.modalProperties.primaryButton == "Delete draft"
                  ? _c(
                      "ion-button",
                      {
                        staticClass:
                          "ion-text-capitalize ion-button-primary-dark",
                        attrs: { shape: "round", color: "primary" },
                        on: { click: _vm.onDeleteClick }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.modalProperties.primaryButton) + " "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "cancel-container",
                    attrs: { href: "#" },
                    on: { click: _vm.onCancel }
                  },
                  [_vm._v(_vm._s(_vm.modalProperties.secondaryButton))]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }