<template>
  <div>
    <div class="completed-list-content">
      <div class="completed-list-table">
        <div class="completed-list-table-header">
          <div v-for="header in headers" :key="header.name" v-show="header.show">
            <h5 @click="openSortDropdown(header)" :class="header.name == 'Title' ? 'completed-title-header sort-header' : 'sort-header'">
              <span>{{ header.name }}</span>
              <span class="sort-container">
                <button :class="!header.showAscending ? 'arrow-disable' : ''">
                  <ion-icon slot="start" name="caret-up-outline"></ion-icon>
                </button>
                <button :class="!header.showDescending ? 'arrow-disable' : ''">
                  <ion-icon slot="start" name="caret-down-outline"></ion-icon>
                </button>
              </span>
            </h5>
            <ion-grid class="sort-dropdown" v-if="header.showSortDropdown">
              <ion-row class="sort-row" @click="onClickAscending(header)" style="margin-top: 8px" :class="!header.showDescending ? 'sort-row-selected' : ''">
                <ion-col>
                  <p class="sort-text"><ion-icon name="arrow-up-outline" class="sort-icon"></ion-icon>Sort Ascending</p>
                </ion-col>
              </ion-row>
              <ion-row class="sort-row" @click="onClickDescending(header)" style="margin-top: 4px" :class="!header.showAscending ? 'sort-row-selected' : ''">
                <ion-col>
                  <p class="sort-text"><ion-icon name="arrow-down-outline" class="sort-icon"></ion-icon>Sort Descending</p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <div class="completed-list-table-content">
          <div v-for="asset in filterItems" :key="asset.id" class="completed-list-table-row">
            <div
              :class="asset.isActive ? 'table-item completed-name-header open-link open-link-color' : 'table-item completed-name-header open-link title-tooltip-container'"
              @click="openContent(asset)"
              :title="asset.isActive ? asset.title : ''"
            >
              <div v-if="asset.isRequired" class="required-icon">
                <svg-icon v-if="asset.isRequired" icon-class="due-date" class-name="g-icon--small g-clickable" style="fill: black !important" />
              </div>
              {{ asset.title }}
              <span v-if="$platform === 'Desktop' && !asset.isActive" class="title-tooltip-text">This content is no longer available</span>
            </div>
            <div class="table-item completed-name-header" v-if="isDesktopPlatform">{{ asset.duration | formatDuration }}</div>
            <div class="table-item completed-name-header">{{ asset.completedAt | formatDate }}</div>
            <div class="table-item completed-name-header" v-if="isDesktopPlatform && !isTab">{{ asset.formatName | stringCamelCase }}</div>
            <div class="table-item completed-name-header" v-if="isDesktopPlatform && !isTab">{{ asset.providerName }}</div>
          </div>
        </div>
      </div>
      <div class="search-result-pagination" v-if="pagination.pageCount">
        <paginate
          :page-count="pagination.pageCount"
          :page-range="pagination.pageRange"
          v-model="currentPage"
          :margin-pages="1"
          :click-handler="handlePageClick"
          :container-class="'pagination'"
          :page-class="'pagination-item'"
          :page-link-class="'pagination-item-link'"
          :prev-text="'&lsaquo;'"
          :prev-class="'pagination-nav'"
          :prev-link-class="'pagination-nav-link'"
          :next-text="'&rsaquo;'"
          :next-class="'pagination-nav'"
          :next-link-class="'pagination-nav-link'"
          :hide-prev-next="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/utils/helper'
export default {
  name: 'DataTable',
  components: {},
  props: {
    dataList: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    headers: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      isDesktopPlatform: true,
      nameSort: 0,
      createdAtSort: 0,
      updatedAtSort: 0,
      items: [],
      pageCount: 25,
      data: {
        learning: {},
        external: {}
      },
      pagination: {},
      isTab: false
    }
  },
  computed: {
    ...mapGetters(['auth_token']),
    filterItems() {
      const start = this.currentPage * this.pagination.pageRange - this.pagination.pageRange
      const end = start + this.pagination.pageRange - 1
      return this.dataList.filter((item, index) => {
        return index >= start && index <= end
      })
    }
  },
  watch: {},
  created() {},
  async mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop' || window.innerWidth >= 450
    this.isTab = window.innerWidth <= 1440 && window.innerWidth > 480
    this.pagination = this.paginationSettings()
  },
  activated() {},
  filters: {
    stringCamelCase(name = '') {
      return name.length > 0 ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : ''
    },
    formatDate(d) {
      return util.formatDate(d)
    },
    formatDuration(duration) {
      return duration < 3600 ? `${Math.round(duration / 60)} min` : `${Math.round(duration / 3600)} hrs`
    }
  },
  methods: {
    openSortDropdown(header) {
      this.headers.forEach((element) => {
        if (element.name == header.name) {
          element.showSortDropdown = !element.showSortDropdown
        } else {
          element.showSortDropdown = false
        }
      })
    },
    onClickAscending(header) {
      this.$emit('onDataSort', { direction: 'ascending', header: header })
      header.showSortDropdown = false
      this.headers.forEach((element) => {
        if (element.name == header.name) {
          element.showDescending = false
          element.showAscending = true
        } else {
          element.showAscending = true
          element.showDescending = true
        }
      })
      //header.showDescending = false
    },
    onClickDescending(header) {
      this.$emit('onDataSort', { direction: 'descending', header: header })
      header.showSortDropdown = false
      this.headers.forEach((element) => {
        if (element.name == header.name) {
          element.showAscending = false
          element.showDescending = true
        } else {
          element.showAscending = true
          element.showDescending = true
        }
      })
      //header.showAscending = false
    },
    handlePageClick(page) {
      this.currentPage = page
    },
    paginationSettings() {
      return {
        pageCount: Math.ceil(this.dataList.length / this.pageCount),
        pageRange: this.pageCount
      }
    },
    openContent(item) {
      if (item.isActive) this.$router.push(this.getRouteInfo(item))
    },
    compareDate(dateA, dateB) {
      let aCompletedAt = new Date(dateA.completedAt)
      let bCompletedAt = new Date(dateB.completedAt)
      if (aCompletedAt > bCompletedAt) return -1
      if (aCompletedAt < bCompletedAt) return 1
      return 0
    },
    getRouteInfo(item) {
      if (item.source == 'EXTERNAL') {
        return { name: 'ExternalContent', params: { id: item.guid, pgrTrk: false } }
      } else {
        if (['article', 'video', 'audio', 'podcast'].includes(item.format.name.toLowerCase())) {
          return { name: 'Content', params: { id: item.id, title: item.title } }
        } else if (['asset'].includes(item.format.name.toLowerCase())) {
          return { name: 'Asset', params: { id: item.id, asset: item } }
        } else if (['course'].includes(item.format.name.toLowerCase())) {
          return { name: 'Course', params: { id: item.id, pgrTrk: false } }
        } else if (['track'].includes(item.format.name.toLowerCase())) {
          return { name: 'Collection', params: { id: item.guid } }
        } else {
          return { name: 'Credential', params: { id: item.id, pgrTrk: false } }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.search-result-pagination {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Futura PT Demi';
  font-size: 0.9rem;
  color: var(--ion-color-role-grey);
  border-top: 2px solid var(--ion-color-light-gray);

  span {
    position: relative;
    font-size: 1rem;
    padding: 0rem 0.5rem;
    cursor: pointer;
    font-family: 'Futura PT Demi';
  }
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.pagination-item {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

li.pagination-nav {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.pagination-item-link {
  color: var(--ion-color-post-gray);
  font-size: 14px !important;
  vertical-align: middle;
}

.pagination-nav-link {
  position: relative;
  color: #5c5c5c;
  font-size: 3rem !important;
  vertical-align: middle;
  font-family: 'Futura PT Book';
}

li.disabled {
  border: none;
  cursor: default;

  .pagination-item-link {
    cursor: default;
  }
}

li.active {
  border: 2px solid #1dd1b0;
  border-radius: 4px;
  background: white;

  .pagination-item-link {
    color: #000000;
  }
}

li:hover {
  background: #e0e1e1;
}

li .tooltiptext {
  visibility: hidden;
  width: max-content;
  height: 30px;
  background-color: #efefef;
  color: #000000de;
  text-align: center;
  padding: 7px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -20px;
  font-size: 12px;
  font-family: 'Futura PT Demi';
}

.li:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 414px) {
  .pagination-item {
    margin: 0 2px;
    width: 32px;
    height: 32px;
  }

  li.pagination-nav {
    width: 32px;
    height: 32px;
  }

  .pagination-item-link {
    font-size: 12px;
  }
}
</style>

<style lang="scss" scoped>
.completed-title-header {
  padding-left: 2.5rem !important;
}

.completed-name-header {
  .required-icon {
    position: absolute;
    margin: 0 8px 0 -16px;
    width: 24px;
    height: 24px;
    left: 24px;
  }
}
.completed-list-content {
  width: 100%;
  background-color: var(--ion-color-white);
  border-radius: 20px;
  margin-top: 0;

  .open-link {
    font: normal normal normal 16px/20px Arial;
  }

  .open-link-color {
    color: #1f3370 !important;
  }

  .completed-list-table-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #dfdede;
    // padding: 0 0.5rem;
    div {
      flex: 1;
      &:first-child {
        flex: 2;
      }
    }

    h5 {
      flex: 1;
      font-family: 'Arial';
      font-size: 1rem;
      font-weight: bold;
      color: var(--ion-color-black);
      text-align: left;
      display: flex;
      &:first-child {
        flex: 2;
      }

      &.completed-name-header {
        flex: 2.5;
      }

      &.completed-date-header {
        flex: 2;
      }

      &.completed-name-header span {
        cursor: pointer;
        font: normal normal bold 1rem/1.125rem Arial;
      }

      &.completed-date-header span {
        cursor: pointer;
        font: normal normal bold 1rem/1.125rem Arial;
      }

      &.completed-status-header span {
        cursor: pointer;
        font: normal normal bold 1rem/1.125rem Arial;
      }

      .sort-container {
        margin-top: -0.31rem;
        display: inline-flex;
        flex-direction: column;
        font-size: 0.75rem;

        button {
          width: 1.5rem;
          background: transparent;
          height: 0.75rem;

          ion-icon {
            width: 1rem;
          }
        }
      }

      @media screen and (max-width: 768px) {
        font-size: 0.75rem;
      }
    }
  }

  .completed-list-table-row {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    cursor: pointer;
    border-radius: 0.75rem;
    min-height: 4rem;

    &:nth-child(even) {
      background: #efefefbf;
    }

    &:hover {
      background-color: #efefef40;
    }

    .table-item {
      flex: 1;
      text-align: left;
      // padding: 0 1rem;
      font-family: 'Arial';
      font-size: 1rem;
      color: #000000;
      &:first-child {
        flex: 2;
        padding-left: 2.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 0.5rem;
      }
      &.completed-name {
        flex: 2;

        p {
          margin: 0;
          word-break: break-word;
          color: var(--ion-color-tertiary);
          font-family: 'Futura PT Demi';
          font-size: 1.25rem;

          @media screen and (max-width: 768px) {
            font-size: 1rem;
            margin: 0 0 0.3rem 0;
          }
        }
      }

      &.completed-date {
        flex: 2;
        font-size: 0.875rem;

        @media screen and (max-width: 768px) {
          font-size: 0.75rem;
        }
      }

      &.completed-status {
        font-weight: bold;
        font-size: 0.875rem;

        @media screen and (max-width: 768px) {
          font-size: 0.75rem;
        }
      }

      .last-updated {
        .label {
          font-weight: bold;
          margin-right: 5px;
        }
      }

      @media screen and (max-width: 768px) {
        font-size: 0.625rem;
      }
    }

    .completed-name-margin {
      margin-bottom: 0.3rem;
    }
    .title-tooltip-container {
      color: #adadad !important;
      .title-tooltip-text {
        width: 205px;
        left: 2.2rem;
        visibility: hidden;
        text-align: center;
        padding: 3px 10px;
        position: absolute;
        z-index: 1;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #0000001f;
        border-radius: 4px;
        opacity: 1;
        font-size: 12px;
        font-family: Arial;
        top: -12px;
        color: #000000de;
        right: 0;
      }
    }
    .title-tooltip-container:hover .title-tooltip-text {
      visibility: visible;
    }
  }

  @media screen and (max-width: 450px) {
    padding: 0 0.5rem;
    margin: 0;
  }
}

.completed-technology,
.completed-displayId {
  color: var(--ion-color-black);
  font-family: 'Arial';
  font-size: 0.75rem;

  @media screen and (max-width: 768px) {
    font-size: 0.625rem;
  }
}

.sort-row-selected {
  background-color: #e9ebf2;
}
.arrow-disable {
  color: #e0e1e1;
}

.sort-header:hover {
  background-color: #efefef;
  cursor: pointer;
}

// .sort-header {
//   border-radius: 6px;
//   padding: 4px;
// }
.sort-dropdown {
  height: 82px;
  width: 150px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 12px #0000001f;
  position: absolute;
  z-index: 1;
}

.sort-text {
  font-family: 'Arial';
  font-size: 14px;
  color: #2d2d2d;
  margin: 0;
}

.sort-icon {
  color: black;
  height: 16px;
  width: 16px;
  margin-bottom: -3px;
}

.sort-row:hover {
  background-color: #f6f7f9;
  cursor: pointer;
}

.notification-message {
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0rem;
  width: 100%;
  height: 64px;
  background: var(--ion-color-primary-lighter);
  border-radius: 4px;

  h2 {
    text-align: left;
    font-family: 'Arial';
    font-size: 0.875rem;
    font-weight: normal;
    margin: 1.5rem 0rem;

    @media screen and (max-width: 450px) {
      font-size: 0.75rem;
    }

    @media screen and (max-width: 425px) {
      margin: 1.2rem 0rem;
    }
  }

  .svg-container {
    margin: 1.5rem 0.5rem 1.5rem 1rem;

    @media screen and (max-width: 425px) {
      margin: 1.2rem 0.5rem 1.2rem 1rem;
    }
  }
}
</style>
