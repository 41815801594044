var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ion-card", { staticClass: "csv-download-details" }, [
        _c("div", { staticClass: "text-content" }, [
          _c("h3", [_vm._v("Download full transcript (CSV)")]),
          _c("p", [
            _vm._v(
              " Your transcript is a comprehensive record of your learning activities, including starting or completion of items, related dates and more. It is more detailed than the list shown. "
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "dwnld-btn" },
          [
            _c(
              "jsonToCsv",
              {
                attrs: {
                  "json-data": _vm.CSVDetailList,
                  labels: _vm.labelsForCSV,
                  "csv-title": _vm.jsonTitle
                }
              },
              [
                _c(
                  "ion-button",
                  {
                    staticClass:
                      "ion-button-primary ion-text-capitalize download-btn",
                    attrs: {
                      shape: "round",
                      color: "primary",
                      disabled: !(
                        _vm.CSVDetailList && _vm.CSVDetailList.length > 0
                      )
                    }
                  },
                  [_vm._v("Download")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("ion-card", { staticClass: "personal-details" }, [
        _vm.items.length > 0 && _vm.responseReceived && _vm.filterLoaded
          ? _c(
              "div",
              { staticClass: "personal-info-header" },
              [
                _c("h3", [_vm._v("Completed Learning")]),
                _vm.filterLoaded
                  ? _c("LearningHistoryFilters", {
                      attrs: { data: _vm.data },
                      on: {
                        "on-apply": function($event) {
                          return _vm.onApply($event)
                        },
                        "on-open-filters": _vm.onOpenFilters,
                        "on-clear-filters": function($event) {
                          return _vm.onApply($event)
                        },
                        "side-filter": function($event) {
                          return _vm.onSideFilterData($event)
                        },
                        "on-open-external-sidebar": _vm.openExternalSidebar
                      }
                    })
                  : _vm._e(),
                _vm.showRequiredLearning && _vm.requiredLearningCount > 0
                  ? _c(
                      "ion-row",
                      { staticClass: "required-learning-container" },
                      [
                        _c(
                          "ion-col",
                          { staticClass: "required-sort-text" },
                          [
                            _c("svg-icon", {
                              staticStyle: {
                                fill: "black !important",
                                margin: "0 12px -4px 0"
                              },
                              attrs: {
                                "icon-class": "due-date",
                                "class-name": "g-icon--small"
                              }
                            }),
                            _vm._v(" Required Learning "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "required-checkbox-inline required-sort-text"
                              },
                              [
                                _c("ion-checkbox", {
                                  staticStyle: {
                                    margin: "0 12px -4px 16px",
                                    "z-index": "1"
                                  },
                                  attrs: {
                                    color: "tertiary",
                                    mode: "md",
                                    checked: _vm.requiredChecked,
                                    disabled: _vm.requiredLearningCount < 1
                                  },
                                  on: {
                                    ionChange: function($event) {
                                      return _vm.onSelectRequired($event)
                                    }
                                  }
                                }),
                                _vm._v(
                                  " Show required learning only (" +
                                    _vm._s(_vm.requiredLearningCount) +
                                    ") "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "ion-row",
                  { staticClass: "required-checkbox-block" },
                  [
                    _c("ion-col", { staticClass: "required-sort-text" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "required-checkbox-block required-sort-text"
                        },
                        [
                          _c("ion-checkbox", {
                            staticStyle: { margin: "12px 12px -4px 8px" },
                            attrs: {
                              color: "tertiary",
                              mode: "md",
                              checked: _vm.requiredChecked
                            },
                            on: {
                              ionChange: function($event) {
                                return _vm.onSelectRequired($event)
                              }
                            }
                          }),
                          _vm._v(
                            " Show required learning only (" +
                              _vm._s(_vm.requiredLearningCount) +
                              ") "
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c("DataTable", {
                  attrs: { dataList: _vm.items, headers: _vm.headerList },
                  on: { onDataSort: _vm.applyDataSort }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.responseReceived && _vm.items.length == 0
          ? _c(
              "div",
              { staticClass: "personal-info-header" },
              [
                _c(
                  "ion-row",
                  [
                    _c(
                      "ion-col",
                      {
                        staticClass: "no-content-text",
                        attrs: { size: _vm.isDesktopPlatform ? "8" : "12" }
                      },
                      [
                        _c("h3", [
                          _vm._v("You don’t have any completed items, yet.")
                        ]),
                        _c(
                          "h6",
                          {
                            staticStyle: {
                              font: "normal normal normal 16px/22px Arial"
                            }
                          },
                          [
                            _vm._v(" Check out "),
                            _c(
                              "a",
                              {
                                staticClass: "topnav-link",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.gotoPage("/mylearning/required")
                                  }
                                }
                              },
                              [_vm._v("My learning")]
                            ),
                            _vm._v(" section to get started or visit the "),
                            _c(
                              "a",
                              {
                                staticClass: "topnav-link",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.gotoPage("/explore")
                                  }
                                }
                              },
                              [_vm._v("Explore")]
                            ),
                            _vm._v(
                              " section to find more content that interests you. Once you complete a learning item, it’ll show up here for you to track. "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "ion-col",
                      { attrs: { size: _vm.isDesktopPlatform ? "4" : "12" } },
                      [
                        _c("img", {
                          staticClass: "no-content-img",
                          attrs: { src: _vm.noContentImg(), alt: "No Content" }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }