import localforage from 'localforage'
import axios from 'axios'

export default {
  async getFeatureFlags() {
    const prefix = process.env.VUE_APP_ULFF_PREFIX ? `?namePrefix=${process.env.VUE_APP_ULFF_PREFIX}` : ''
    const headers = {
      esponseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8',
      'UNLEASH-APPNAME': process.env.VUE_APP_ULFF_NAME,
      'UNLEASH-INSTANCEID': process.env.VUE_APP_ULFF_INSTANCE_ID
    }
    const response = await axios.get(`${process.env.VUE_APP_ULFF_HOST}/features${prefix}`, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getBackupFeatureFlags() {
    const token = await localforage.getItem('my_access_token')
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(`${this.ffUri}flags/${process.env.NODE_ENV}`, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  }
}