var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { cursor: "pointer" }, on: { click: _vm.onClick } },
    [
      _vm.media.type === "image"
        ? _c("img", { attrs: { src: _vm.media.src, alt: "" } })
        : _vm._e(),
      _vm.media.type === "video"
        ? _c("div", { staticClass: "video-thumbnail" }, [
            _vm.isDesktopPlatform
              ? _c(
                  "video",
                  {
                    attrs: {
                      id: "video-preview",
                      preload: "auto",
                      height: "100%",
                      width: "100%"
                    }
                  },
                  [
                    _c("source", {
                      attrs: {
                        src: _vm.media.src + "#t=0.5",
                        type: "video/mp4"
                      }
                    })
                  ]
                )
              : _c(
                  "video",
                  {
                    attrs: {
                      id: "video-preview",
                      controls: "",
                      poster: "",
                      preload: "auto",
                      height: "100%",
                      width: "100%",
                      "aria-label": "watch video"
                    }
                  },
                  [
                    _c("source", {
                      attrs: {
                        src: _vm.media.src + "#t=0.5",
                        type: "video/mp4"
                      }
                    })
                  ]
                ),
            _vm.isDesktopPlatform
              ? _c("div", { staticClass: "video-control" })
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }