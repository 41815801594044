var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "skill-container" },
      [
        _c("SkillsButtonGroup", {
          key: _vm.displaySkills.length,
          attrs: {
            skills: _vm.displaySkills,
            from: _vm.from,
            displaySkillDetails: true,
            isSkillRemoveable: _vm.isSkillRemoveable
          },
          on: { "remove-skill": _vm.onRemoveSkill }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }