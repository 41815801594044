var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-direction": "row" } },
    [
      _c(
        "ion-card",
        {
          staticClass: "skillbuilder",
          class: [
            _vm.skillbuilder.progress === 100 ? "complete" : "",
            !_vm.skillbuilder.isProjectUploadCard &&
            _vm.skillbuilder.isCapstone &&
            _vm.skillbuilder.progress < 100
              ? "capstone-yellow"
              : ""
          ],
          on: { mouseenter: _vm.toggleOverlay, mouseleave: _vm.toggleOverlay }
        },
        [
          _vm.active && _vm.skillbuilder.isCourse
            ? _c(
                "div",
                {
                  staticClass: "course-overlay",
                  on: { click: _vm.selectSkillbuilder }
                },
                [
                  _c("div", { staticClass: "course-overlay__label" }, [
                    _vm._v(" OPEN IN "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.skillbuilder.providerName || ""))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "ion-grid",
            { on: { click: _vm.selectSkillbuilder } },
            [
              _c(
                "ion-row",
                [
                  _c("ion-col", [
                    _c(
                      "div",
                      {
                        class: [
                          !_vm.skillbuilder.isProjectUploadCard &&
                          _vm.skillbuilder.isCapstone
                            ? "circle-white"
                            : "circle-yellow"
                        ]
                      },
                      [_vm._v(" " + _vm._s(_vm.skillbuilder.index) + " ")]
                    ),
                    !_vm.skillbuilder.isCapstone && _vm.skillbuilder.index !== 0
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "ion-no-margin skillbuilder__title ellipsis-lines-1"
                          },
                          [_vm._v("Skillbuilder")]
                        )
                      : _vm._e(),
                    !_vm.skillbuilder.isCapstone && _vm.skillbuilder.index === 0
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "ion-no-margin skillbuilder__title ellipsis-lines-1"
                          },
                          [_vm._v("Overview")]
                        )
                      : _vm._e(),
                    _vm.skillbuilder.isCapstone
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "ion-no-margin skillbuilder__title ellipsis-lines-1"
                          },
                          [_vm._v("Capstone Project")]
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      {
                        staticClass:
                          "ion-no-margin skillbuilder__sub-title ellipsis-lines-2"
                      },
                      [_vm._v(" " + _vm._s(_vm.skillbuilder.title) + " ")]
                    ),
                    !_vm.skillbuilder.isCapstone
                      ? _c("div", { staticClass: "synopsis" }, [
                          _vm.skillbuilder.progress > 0 &&
                          _vm.skillbuilder.progress < 100
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "g-padding__top--small g-margin__padding--small ellipsis-lines-3"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.skillbuilder.synopsis) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.skillbuilder.progress === 0
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "g-padding__top--small g-margin__padding--small ellipsis-lines-5"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.skillbuilder.synopsis) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.skillbuilder.progress === 100
                            ? _c(
                                "p",
                                { staticClass: "skillbuilder__alldone" },
                                [_vm._v("All Done")]
                              )
                            : _vm._e()
                        ])
                      : _c("div", { staticClass: "synopsis" }, [
                          _vm.skillbuilder.progress > 0 &&
                          _vm.skillbuilder.progress < 100
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "g-padding__top--small g-margin__padding--small ellipsis-lines-3"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.skillbuilder.synopsis) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.skillbuilder.progress === 0
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "g-padding__top--small g-margin__padding--small ellipsis-lines-5"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.skillbuilder.synopsis) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "ion-text-center" },
                            [
                              _vm.skillbuilder.isCapstone &&
                              _vm.isDesktopPlatform &&
                              !_vm.skillbuilder.projectId &&
                              _vm.skillbuilder.progress < 100 &&
                              _vm.skillbuilder.isProjectUploadCard
                                ? _c(
                                    "ion-button",
                                    {
                                      staticClass:
                                        "g-button--primary ion-button-primary skillbuilder__capstone-button",
                                      attrs: {
                                        shape: "round",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onSubmitCapstone($event)
                                        }
                                      }
                                    },
                                    [_vm._v(" Upload ")]
                                  )
                                : _vm._e(),
                              _vm.skillbuilder.isCapstone &&
                              _vm.isDesktopPlatform &&
                              _vm.skillbuilder.projectId &&
                              _vm.skillbuilder.progress < 100 &&
                              _vm.skillbuilder.isProjectUploadCard &&
                              _vm.isModifiable
                                ? _c(
                                    "ion-button",
                                    {
                                      staticClass:
                                        "g-button--primary ion-button-primary skillbuilder__capstone-button",
                                      attrs: {
                                        shape: "round",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.editSubmitCapstone($event)
                                        }
                                      }
                                    },
                                    [_vm._v(" Edit ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.skillbuilder.progress === 100
                            ? _c(
                                "p",
                                { staticClass: "skillbuilder__alldone" },
                                [_vm._v("All Done")]
                              )
                            : _vm._e()
                        ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.skillbuilder.progress > 0 &&
          _vm.skillbuilder.progress < 100 &&
          !_vm.skillbuilder.isProjectUploadCard
            ? _c(
                "ion-footer",
                { staticStyle: { height: "51px", padding: "0px 0px 0px 0px" } },
                [
                  _c(
                    "div",
                    { staticClass: "skillbuilder__progress-wrapper" },
                    [
                      _c("ion-progress-bar", {
                        staticStyle: { "--buffer-background": "white" },
                        attrs: {
                          value: _vm.skillbuilder.progress / 100,
                          color: "secondary"
                        }
                      }),
                      _c(
                        "p",
                        {
                          staticClass: "ion-no-margin skillbuilder__percentage"
                        },
                        [_vm._v("In Progress")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.skillbuilder.progress === 100 || _vm.skillbuilder.uploadedAt
            ? _c(
                "ion-footer",
                {
                  staticStyle: {
                    height: "51px",
                    padding: "0px 0px 0px 0px",
                    "text-align": "start"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { margin: "15px 0px 0px 10px" } },
                    [
                      _c("svg-icon", {
                        staticClass:
                          "g-icon--small check g-clickable g-margin__right--small",
                        attrs: { "icon-class": "check" }
                      }),
                      _vm.skillbuilder.completedAt
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "ion-no-margin skillbuilder__percentage"
                            },
                            [_vm._v("Completed " + _vm._s(_vm.completedAt))]
                          )
                        : _vm._e(),
                      !_vm.skillbuilder.completedAt &&
                      _vm.skillbuilder.uploadedAt
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "ion-no-margin skillbuilder__percentage"
                            },
                            [_vm._v("Uploaded " + _vm._s(_vm.uploadedAt))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }