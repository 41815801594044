<template>
  <div class="search-item-container">
    <div class="meta">
      <div class="format"><ion-skeleton-text animated /></div>
      <div class="provider"><ion-skeleton-text animated /></div>
    </div>
    <div class="title"><ion-skeleton-text animated /></div>
    <div class="subtitle"><ion-skeleton-text animated /></div>
    <div class="subtitle"><ion-skeleton-text animated /></div>
    <div class="subtitle"><ion-skeleton-text animated /></div>
    <div class="meta footer" style="margin-top: 0.4rem">
      <div class="search-bookmark"><ion-skeleton-text animated /></div>
      <div class="search-social"><ion-skeleton-text animated /></div>
      <div class="duration"><ion-skeleton-text animated /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchSkeleton',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.search-item-container {
  background: var(--ion-color-white);
  padding: 1.5rem 3rem 1.5rem 0rem;
  border-bottom: 2px solid var(--ion-color-light-gray);
}

.title {
  margin: 0.7rem 0rem;
  width: 100%;
  height: 1.25rem;
}

.subtitle {
  width: 100%;
}

.meta {
  display: flex;
  gap: 0.5rem;

  .format,
  .provider {
    width: 40px;
  }

  .search-bookmark,
  .search-social,
  .duration {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .title,
  .subtitle {
    width: 90%;
  }

  .footer {
    float: right;
    flex-direction: column;
    position: relative;
    top: -6rem;
  }
}

@media screen and (max-width: 768px) {
  .search-item-container {
    padding: 1.5rem 1rem;
  }
}
</style>