<template>
  <div ref="top" class="courses__wrapper credential-container main-content" :class="$platform === 'Desktop' ? 'credential-container-desktop' : ''">
    <button v-if="isDesktopPlatform && isContentAvailable" class="back" @click="$router.go(-1)">
      <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
      Back
    </button>
    <div v-if="dataReady">
      <div v-if="isContentAvailable">
        <CredentialOverview
          v-if="dataReady"
          :isScormCloudCredential="isScormCloudCredential"
          :course="course"
          :is-accessible="isAccessible"
          :key="course.progress"
          @on-select-continue="onSelectContinue"
        ></CredentialOverview>
        <ion-grid v-if="dataReady && !isScormCloudCredential" class="ion-no-padding">
          <ion-row v-show="!searchBarVisible" class="course__tabs">
            <ion-col :size="isDesktopPlatform ? 6 : 10">
              <ion-toolbar class="ion-no-padding" style="height: 56px; z-index: 0">
                <ion-segment value="Skillbuilders" scrollable mode="md" @ionChange="tabChanged($event)" :disabled="!isAccessible">
                  <ion-segment-button v-for="name in names" :key="name" :value="name" class="ion-text-capitalize g-type-medium" :data-cy="name">
                    <ion-label color="dark">{{ name }}</ion-label>
                  </ion-segment-button>
                </ion-segment>
              </ion-toolbar>
            </ion-col>
            <ion-col v-if="!isDesktopPlatform" size="2" style="background: #fff; padding: 0.8rem">
              <button class="search-icon" @click="showSearchBar">
                <svg-icon icon-class="search" class-name="g-icon--small" />
              </button>
            </ion-col>
            <ion-col v-if="isDesktopPlatform" size="6" style="background: #fff; padding-left: 3rem">
              <ion-searchbar class="course__searchbar" ref="searchbar" placeholder="Search" @keypress.enter="handleSearchInput"></ion-searchbar>
            </ion-col>
          </ion-row>
          <ion-row v-show="searchBarVisible && !isDesktopPlatform">
            <ion-col>
              <ion-searchbar class="course__searchbar" ref="searchbar" placeholder="Search" @focusout="hideSearchBar" @keypress.enter="handleSearchInput"></ion-searchbar>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div v-if="dataReady">
          <p class="courses__steps ion-no-margin ion-text-start">{{ course.content.skillBuilders.length }} steps to success</p>
        </div>

        <div class="Skillbuilders-tab" v-if="selectedTab === 'Skillbuilders' && isAccessible">
          <ion-grid v-if="dataReady" class="ion-padding-end" style="box-shadow: inset -80px 0 40px -71px #bcc2d4">
            <ion-row>
              <div class="courses__skillbuilder-section">
                <CredentialSkillBuilder
                  v-for="skillbuilder in course.content.skillBuilders"
                  :key="skillbuilder.index"
                  :skillbuilder="skillbuilder"
                  class="g-padding__right--medium"
                  :class="selectSkillBuilder.id === skillbuilder.id ? 'select-skillbuilder' : ''"
                  @on-select-skillbuilder="onSelectSkilBuilder"
                  @on-submit-capstone="onSubmitScormCapstone(skillbuilder)"
                >
                </CredentialSkillBuilder>
              </div>
            </ion-row>
          </ion-grid>

          <svg-icon v-if="dataReady && !isScormCloudCredential && !selectSkillBuilder.isCapstone" icon-class="arrow-down-turquoise" class="g-icon--msmall"></svg-icon>

          <ion-grid v-if="dataReady && !isScormCloudCredential">
            <ion-row>
              <div class="courses__lesson-section">
                <CredentialLesson v-for="lesson in lessons" :key="JSON.stringify(lesson)" :lesson="lesson" @on-select-lesson="onSelectLesson" class="g-margin__right--medium">
                </CredentialLesson>
              </div>
            </ion-row>
          </ion-grid>
          <div v-if="!selectSkillBuilder.isCapstone && !isScormCloudCredential && !lessons.length">
            <LoadingAnimation />
          </div>

          <svg-icon v-if="dataReady && !isScormCloudCredential" icon-class="arrow-down-tertiary" class="g-icon--msmall"></svg-icon>

          <CredentialLessonInfo
            v-if="dataReady && !isScormCloudCredential && !selectSkillBuilder.isCapstone && selectLesson.id != selectSkillBuilder.overview.id"
            :lesson="selectLesson"
          />

          <div ref="contents" v-if="dataReady && !isScormCloudCredential">
            <CredentialContent
              v-for="content in contents"
              :key="JSON.stringify(content)"
              :content="content"
              :progress="progress"
              @on-complete-assessment="onCompleteAssessment"
              @on-submit-capstone="onSubmitCapstone"
              @on-submit-kcs="onSubmitKCS"
            />
          </div>

          <div style="margin-bottom: 1rem; height: 1rem"></div>
        </div>

        <div v-if="dataReady && !isScormCloudCredential && selectedTab === 'Index' && isAccessible" class="Index-tab">
          <ion-card>
            <h2 class="tab-title">Index</h2>
            <ion-grid>
              <ion-row>
                <ion-col class="ion-text-start">
                  <CredentialSkillBuilderIndex
                    v-for="skillbuilder in course.content.skillBuilders"
                    :key="skillbuilder.index + skillbuilder.progress"
                    :skillbuilder="skillbuilder"
                    :courseId="courseId"
                    class="g-margin__bottom--small"
                  />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </div>

        <div class="Bookmarks-tab" v-if="selectedTab === 'Bookmarks' && !isScormCloudCredential && isAccessible">
          <ion-card>
            <h2 class="tab-title">Bookmarks</h2>
            <ion-grid :class="isDesktopPlatform ? 'bookmark-panel-desktop' : 'bookmark-panel-mobile'">
              <ion-row>
                <ion-col class="ion-text-start">
                  <div v-for="bookmark in bookmarks" :key="bookmark.id">
                    <CredentialSkillBuilderBookmark :bookmark="bookmark" />
                  </div>
                  <h3 v-if="bookmarks.length === 0" style="padding-left: 0.5rem">There is no bookmark</h3>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </div>
      </div>
      <ion-grid v-else :style="isDesktopPlatform ? '' : 'padding: 2rem 10px 6rem;'" class="ion-no-padding">
        <PageNotFound />
      </ion-grid>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters, mapActions } from 'vuex'
import localforage from 'localforage'
import CredentialOverview from '@/views/credential/components/CredentialOverview'
import CredentialSkillBuilder from '@/views/credential/components/CredentialSkillBuilder'
import CredentialLessonInfo from '@/views/credential/components/CredentialLessonInfo'
import CredentialLesson from '@/views/credential/components/CredentialLesson'
import CredentialContent from '@/views/credential/components/CredentialContent'
import CredentialSkillBuilderIndex from '@/views/credential/components/CredentialSkillBuilderIndex'
import CredentialSkillBuilderBookmark from '@/views/credential/components/CredentialSkillBuilderBookmark'
import LoadingAnimation from '@/components/molecules/LoadingAnimation'
import PageNotFound from '@/components/molecules/PageNotFound'
import { Plugins } from '@capacitor/core'
const { Browser } = Plugins

export default {
  name: 'Credential',
  components: {
    CredentialOverview,
    CredentialSkillBuilder,
    CredentialLessonInfo,
    CredentialLesson,
    CredentialContent,
    CredentialSkillBuilderIndex,
    CredentialSkillBuilderBookmark,
    LoadingAnimation,
    PageNotFound
  },
  props: {},
  data() {
    return {
      courseId: '',
      course: {},
      selectSkillBuilder: {},
      lessons: [],
      selectLesson: {},
      contents: [],
      progress: {},
      capstone: {},
      authToken: '',
      dataReady: false,
      learningRecord: {},
      isDesktopPlatform: false,
      lessonProgressUpdated: false,
      names: ['Skillbuilders', 'Index', 'Bookmarks'],
      selectedTab: 'Skillbuilders',
      bookmarks: [],
      searchBarVisible: false,
      userAccess: null,
      isAccessible: false,
      isScormCloudCredential: false,
      isContentAvailable: false
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['auth_token', 'user_access'])
  },
  async created() {},
  async destroyed() {},
  async activated() {
    document.addEventListener('scroll', this.onScroll, true)
    await localforage.removeItem('deep_link_id')
    await localforage.removeItem('deep_link_type')
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.ssoToken = this.sso_token || (await localforage.getItem('my_sso_token'))
    this.ssoBouncer = this.sso_bouncer || (await localforage.getItem('my_sso_bouncer'))
    this.userAccess = await localforage.getItem('my_user_access')
    this.isAccessible = this.userAccess && this.userAccess.canAccessCredentials
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.courseId = this.$route.params.id
    await this.getCredentailById()
    this.dataReady = true
    learningAnalytics.setLearningRecordAndSend(this.learningRecord)
    await this.onPrepareGAPayload('Viewed')
    this.$refs.top.scrollIntoView()
  },
  async deactivated() {
    document.removeEventListener('scroll', this.onScroll)
    this.$destroy()
  },
  async mounted() {
    this.learningRecord = {
      verb: {
        id: window.location.href,
        display: {
          'en-US': `opened-credential`
        }
      },
      object: {
        id: window.location.href,
        definition: {
          name: {
            'en-US': this.course.title
          }
        }
      },
      result: {
        completion: false,
        success: false,
        score: {
          scaled: 0
        }
      }
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    hideSearchBar() {
      this.searchBarVisible = false
      this.$refs.searchbar.value = ''
    },
    showSearchBar() {
      this.searchBarVisible = true
      this.$refs.searchbar.setFocus()
    },
    // eslint-disable-next-line
    handleSearchInput(event) {},
    /**
     * @description fetch the details of the credential using its ID then load the data
     */
    async getCredentailById() {
      const payload = { token: this.authToken, id: this.courseId }
      const response = this.isAccessible ? await this.$learnAdmin.getCredentialById(payload) : await this.$learnAdmin.getCredentialSummaryById(payload)
      this.course = response.data
      this.isContentAvailable = {}.hasOwnProperty.call(response, 'status') && response.status != 400 && response.status != 404 && response.status != 401
      if (this.isContentAvailable) {
        if (this.course.code === 401) {
          this.logout()
        }
        /**
         * Check if credential is hosted on scorm cloud.
         * If skillbuilder does not have lessons, then the credential is a newer style one.
         */
        if (!this.course.content.skillBuilders[0].lessons) {
          this.isScormCloudCredential = true
          await this.course.content.skillBuilders.map(async (skillBuilder, index) => {
            skillBuilder.index = index + 1
            skillBuilder.isCapstone = false
          })
          // if there is a capstone
          if (this.course.content.capstone) {
            this.course.content.skillBuilders.push({
              index: this.course.content.skillBuilders.length + 1,
              duration: this.course.content.capstone.duration,
              id: this.course.content.capstone.id,
              progress: this.course.content.capstone.meta?.SCORMCapstoneProgress || 0,
              synopsis: this.course.content.capstone.synopsis,
              title: this.course.content.capstone.title,
              assessmentId: this.course.content.capstone.assessmentId,
              isCapstone: true,
              scormcloudId: this.course.content.capstone.scormcloudId,
              projectId: this.course.content.capstone.projectId,
              completedAt: this.course.content.capstone.meta?.SCORMCapstoneCompletedAt || ''
            })
            // card for project upload
            this.course.content.skillBuilders.push({
              index: this.course.content.skillBuilders.length + 1,
              duration: this.course.content.capstone.duration,
              id: this.course.content.capstone.id,
              progress: this.course.content.capstone.meta?.completedAt ? 100 : 0,
              synopsis: this.isDesktopPlatform
                ? 'After completing your project, submit it here for a review.'
                : 'Uploading of Capstone files is not available on the mobile platform. Please use the desktop version of ProEdge Learn to upload your file(s).',
              title: 'Submit your Project',
              assessmentId: this.course.content.capstone.assessmentId,
              isCapstone: true,
              scormcloudId: this.course.content.capstone.scormcloudId,
              projectId: this.course.content.capstone.projectId,
              completedAt: this.course.content.capstone.meta?.completedAt || '',
              uploadedAt: this.course.content.capstone.meta?.uploadedAt || '',
              isModifiable: this.course.content.capstone.meta?.isModifiable,
              isProjectUploadCard: true
            })
          }
        }
        // for credential not hosted on scorm cloud
        else {
          await this.course.content.skillBuilders.map(async (skillBuilder, index) => {
            skillBuilder.index = index + 1
            skillBuilder.isCapstone = false
          })
          // if there is a capstone
          if (this.course.content.capstone) {
            this.course.content.skillBuilders.push({
              index: this.course.content.skillBuilders.length + 1,
              duration: this.course.content.capstone.duration,
              id: this.course.content.capstone.id,
              progress: this.course.content.capstone.progress || 0,
              synopsis: this.course.content.capstone.synopsis,
              title: this.course.content.capstone.title,
              assessmentId: this.course.content.capstone.assessmentId,
              isCapstone: true,
              completedAt: this.course.content.capstone.completedAt
            })
          }
        }
        if (this.isAccessible && !this.isScormCloudCredential) {
          await this.onSelectSkilBuilder(this.course.content.skillBuilders[0])
        }
      }
    },
    async getContentBookmarks() {
      this.bookmarks = []
      const bookmarks = await this.$learnAdmin.getContentBookmarks({ token: this.authToken })
      await bookmarks.content.map(async (bookmark) => {
        if (bookmark.bookMarkItem && bookmark.assetType === 'credentialcontents') {
          bookmark.bookMarkItem.meta.isBookmarked = true
          this.bookmarks.push(bookmark.bookMarkItem)
        }
      })
    },
    /**
     * @description when a skillbuilder is selected, load the selected skillbuilder in a new tab
     */
    async onSelectSkilBuilder(skillbuilder) {
      /**
       * If the selected skillbuilder is a course and is not hosted on Scorm cloud and
       * Then use the courseUrl to load course in new window
       */
      if (skillbuilder.isCourse && !skillbuilder.scormcloudId) {
        this.selectSkillBuilder = skillbuilder
        let courseUrl = `${this.ssoBouncer}?redirect_url=${skillbuilder.contentURI}&SSO_tk=${this.ssoToken}`
        if (courseUrl) {
          await Browser.open({ url: courseUrl })
        }
      } else if (this.isScormCloudCredential) {
        /**
         * If the selected skillbuilder is hosted on scorm cloud
         * Then fetch the scormUrl and load it in a new window
         */
        this.selectSkillBuilder = skillbuilder
        let scormUrl = await this.$learnAdmin.fetchCredentialUrl({ token: this.authToken, credentialId: this.courseId, childId: skillbuilder.id })
        if (scormUrl) {
          await Browser.open({ url: scormUrl })
        }
      } else {
        /** If the selected skillbuilder is of old style (to be deprecated) */
        this.lessons = []
        this.contents = []
        this.lessonProgressUpdated = false
        // if select a skillbuilder
        if (!skillbuilder.isCapstone) {
          this.selectSkillBuilder = await this.$learnAdmin.getSkillBuilderById({ token: this.authToken, id: this.courseId, sbid: skillbuilder.id })
          await this.selectSkillBuilder.lessons.map((lesson, index) => {
            this.lessons.push({
              id: lesson.id,
              index: index + 1,
              title: lesson.title,
              isSelect: false,
              isOverview: false
            })
          })
          this.lessons.unshift({
            id: this.selectSkillBuilder.overview.id,
            index: 0,
            title: 'Overview',
            isOverview: true,
            isSelect: true,
            contents: this.selectSkillBuilder.overview.lessonContents
          })
          await this.onSelectLesson(this.selectSkillBuilder.overview.id)
        }
        // if select a capstone
        else {
          this.selectSkillBuilder = skillbuilder
          this.capstone = await this.$learnAdmin.getCapstoneProjectForCredential({ token: this.authToken, id: this.courseId })
          if (this.capstone.id) {
            this.contents = this.capstone.overview.lessonContents
            await this.contents.map((content, index) => {
              content.index = index + 1
            })
            const assessement = {
              index: this.contents.length + 1,
              id: this.capstone.assessmentId,
              accordions: null,
              contentHTML: `<p>Before submitting your Capstone Project, take the following self-assessment to evaluate the completeness and accuracy of your project. This assessment will not be considered in determing your ProEdge Credential issuance.<p>`,
              downloads: null,
              knowledgeChecks: null,
              medias: null,
              title: 'Post-Completion Self-Assessment',
              isAssessment: true,
              isComplete: this.capstone.assessmentResult,
              meta: {}
            }
            this.contents.push(assessement)
            if (this.capstone.assessmentResult) {
              this.onCompleteAssessment(this.capstone.assessmentResult)
            }
          }
        }
      }
    },
    async onSelectContinue() {
      const continue_skb = this.course.content.skillBuilders.find((skb) => skb.progress < 100)
      this.onSelectSkilBuilder(continue_skb)
    },
    async onSelectLesson(lid) {
      this.lessonProgressUpdated = false
      await this.lessons.map((lesson) => {
        lesson.isSelect = lesson.id === lid
      })
      if (lid !== this.selectSkillBuilder.overview.id) {
        this.selectLesson = await this.$learnAdmin.getLessonById({ token: this.authToken, id: this.courseId, sbid: this.selectSkillBuilder.id, lid: lid })
        this.contents = this.selectLesson.lessonContents
        this.progress = this.selectLesson.lessonProgress ? this.selectLesson.lessonProgress : {}
      } else {
        this.selectLesson = this.selectSkillBuilder.overview
        this.contents = this.selectSkillBuilder.overview.lessonContents
        this.progress = {}
      }
      await this.contents.map((content, index) => {
        content.index = index + 1
      })
    },
    onCompleteAssessment(result) {
      if (result.totalQuestions) {
        const assessmentResult = {
          accordions: null,
          contentHTML: `
            <p>You have completed the self-assessment</p>
            <ul>
            <li>
              <span>Score:</span>
              <span>${result.score}</span>
            </li>
            <li>
              <span>Number of Correct Answers:</span>
              <span>${result.numCorrect} / ${result.totalQuestions}</span>
            </li>
            </ul>
          `,
          downloads: null,
          knowledgeChecks: null,
          medias: null,
          title: 'Self-Assessment Results',
          projectId: this.capstone.projectId,
          isSubmitCapstone: true,
          meta: {}
        }
        const existingResult = (result) => result.title == 'Self-Assessment Results' && result.isSubmitCapstone
        const found = this.contents.findIndex(existingResult)
        assessmentResult.index = found == -1 ? this.contents.length + 1 : this.contents[found].index
        found == -1 ? this.contents.push(assessmentResult) : this.contents.splice(found, 1, assessmentResult)
      }
    },
    onSubmitCapstone(isSubmit) {
      isSubmit
        ? this.$router.push({ name: 'CredentialSubmitCapstone', params: { id: this.courseId, title: this.capstone.title, editProjectId: null } })
        : this.$router.push({ name: 'CredentialEditCapstone', params: { id: this.courseId, title: this.capstone.title, editProjectId: this.capstone.projectId } })
    },
    onSubmitScormCapstone(skb) {
      !skb.projectId
        ? this.$router.push({ name: 'CredentialSubmitCapstone', params: { id: this.courseId, title: skb.title, editProjectId: null } })
        : this.$router.push({ name: 'CredentialEditCapstone', params: { id: this.courseId, title: skb.title, editProjectId: skb.projectId } })
    },
    onScroll(event) {
      if (event.target.scrollHeight - 300 <= event.target.scrollTop && !this.isScormCloudCredential) {
        this.updateLessonProgress()
      }
    },
    async updateLessonProgress() {
      if (!this.lessonProgressUpdated && this.selectLesson.id != this.selectSkillBuilder.overview.id) {
        this.lessonProgressUpdated = true
        const payload = {
          token: this.authToken,
          id: this.course.id,
          sbid: this.selectSkillBuilder.id,
          lid: this.selectLesson.id,
          upr: {
            isCompleted: true,
            progress: 100
          }
        }
        const update = await this.$learnAdmin.uploadLessonProgressById(payload)
        if (update.progress) {
          let summary = await this.$learnAdmin.getCredentialSummaryById({ token: this.authToken, id: this.courseId })
          summary = summary.data
          this.course.progress = summary.progress
          const index = this.course.content.skillBuilders.findIndex((skb) => skb.id === this.selectSkillBuilder.id)
          const currentProgress = this.course.content.skillBuilders[index].progress
          this.course.content.skillBuilders[index].progress = currentProgress > update.progress ? currentProgress : update.progress
        }
      }
    },
    async onSubmitKCS(answers) {
      await answers.map(async (answer) => {
        const upr = {
          qid: answer.qid,
          answer: Array.isArray(answer.answer) ? answer.answer.join() : answer.answer
        }
        const payload = {
          token: this.authToken,
          id: this.course.id,
          sbid: this.selectSkillBuilder.id,
          lid: this.selectLesson.id,
          upr: upr
        }
        await this.$learnAdmin.uploadLessonKnowlegeCheck(payload)
      })
    },
    async tabChanged(e) {
      this.selectedTab = e.detail.value
      if (this.selectedTab === 'Bookmarks') {
        await this.getContentBookmarks()
      }
    },
    async onPrepareGAPayload(category) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'content-id': this.courseId,
        'content-title': this.course.title,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'Credential Landing Page',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin: 0.8rem 0rem 1.1rem 3%;
  font-family: 'Arial';
  color: #5c5c5c;
  display: block;
  padding: 0;
  svg {
    position: relative;
    top: 5px;
  }
}

@include block('courses') {
  @include element('content') {
    @include element('nav') {
      margin-top: 10px;
      background: map-get($edge-colors, 'white');
    }
  }
  @include element('steps') {
    font-family: 'Futura PT Md';
    font-size: 2rem;
    color: var(--ion-color-hover);
    margin-left: 1rem;
    margin-top: 2rem;
  }
  @include element('searchbar') {
    background-color: var(--ion-color-white) !important;
  }
  @include element('tabs') {
    background-color: var(--ion-color-white) !important;
  }
  @include element('skillbuilder-section') {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }
  @include element('lesson-section') {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }

  @include element('content-section-mobile') {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }

  @include element('content-section-desktop') {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  @include element('progress') {
    width: 90%;
    height: 0.5rem;
    background-color: var(--ion-color-medium-light);
    border-radius: 10px;

    @include modifier('percent') {
      background: var(--ion-color-secondary);
      border-radius: inherit;
      height: inherit;
    }
  }
  @include element('social') {
    display: flex;
    flex-direction: row;
  }
  @include element('course_title') {
    color: map-get($edge-colors, 'black');
    font-weight: 500;
    font-size: 1.5rem;
    @extend .g-type-large;
  }
  @include element('wrapper') {
    background-color: var(--ion-color-dark-grey);
  }
  @include element('section-title') {
    color: var(--ion-color-tertiary);
    font-weight: 500;
    font-size: 1.3rem;
    @extend .g-type-large;
  }
  @include element('skillbuilder') {
    background-color: var(--ion-color-light);
    margin: 0.5rem 0rem;
  }
  @include element('caret-transform ') {
    transform: rotate(180deg);
  }
  @include element('subSection-skill') {
    background: var(--ion-color-white);
    margin-top: 70px;
  }
  @include element('module-builder') {
    margin-bottom: 0.3rem;
  }
  @include element('index') {
    color: map-get($edge-colors, 'tertiary');
    text-align: left;
    font-size: 3rem;
    font-weight: bolder;
    margin: 0px 5px;
  }

  @include element('title') {
    color: var(--ion-color-blue);
    font-size: 1.35rem;
    text-align: left;
    font-weight: 500;
    padding-top: 0.3rem;
    padding-bottom: 0.8rem;
  }

  @include element('subtitle') {
    font-size: 1rem;
    text-align: left;
  }

  @include element('numlessons') {
    color: var(--ion-color-black);
    font-size: 1.1rem;
    text-align: left;
    padding-left: 0.8rem;
    padding-bottom: 0.5rem;
  }

  @include element('capstone') {
    margin-top: 1.5rem;
    background: var(--ion-color-orange-shade);
    padding-top: 0.1rem;
  }
}

.search {
  background: var(--ion-color-white) !important;
  padding-top: 0.8rem;
}

.search-icon {
  background: var(--ion-color-white) !important;
  outline: none;
}

.select-skillbuilder {
  ::v-deep {
    ion-card {
      border: 2px solid var(--ion-color-secondary);
    }
  }
}

h2 {
  color: var(--ion-color-dark);
}
.shadow {
  background-color: transparent;
  // -moz-box-shadow: 1px 1px 1px 1px rgba(204, 203, 203, 0.6);
  // -webkit-box-shadow: 1px 1px 1px 1px rgba(204, 203, 203, 0.6);
  // box-shadow: 1px 1px 1px 1px rgba(204, 203, 203, 0.6);

  zoom: 1.3;
}
.shadow .content {
  position: relative;
  padding: 100px;
  background-color: map-get($edge-colors, 'white');
}
.caret-transform {
  transform: rotate(180deg);
}

.capstone-container {
  padding: 0.4rem !important;
  background: map-get($edge-colors, 'light-pink');
  font-size: 5rem;
  height: 5.5rem;

  .capstone-complete {
    position: relative;
    top: -1.2rem;
    left: -0.5rem;
  }
  .text {
    padding-top: 1rem;
    padding-left: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.credential-container-desktop {
  background-color: map-get($edge-colors, 'disabled');
  .g-non-card-section--more > a {
    color: map-get($edge-colors, 'black');
  }
}

ion-segment {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

ion-segment-button {
  ion-label {
    color: map-get($edge-colors, tertiary);
    font-weight: bolder;
  }
}

ion-segment-button:hover {
  color: map-get($edge-colors, tertiary);
  font-weight: bolder;
}

ion-segment-button.segment-button-checked {
  ion-label {
    color: map-get($edge-colors, secondary);
    font-weight: bolder;
  }
}

ion-searchbar {
  background-color: var(--ion-color-white);
  padding: 7px 8px;
  height: 56px;
}

.tab-title {
  font-family: 'Futura PT Demi';
  padding-left: 1.5rem;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0;
}

.bookmark-panel-desktop {
  border-radius: 10px;
  margin: 1rem;
  background-color: var(--ion-color-light-gray);
}

.bookmark-panel-mobile {
  border-radius: 10px;
  margin: 1rem 0rem;
  background-color: var(--ion-color-light-gray);
}

ion-searchbar {
  --border-radius: 20px;
}

.in-accessible {
  opacity: 0.2;
  cursor: not-allowed;
}
</style>
