<template>
  <div class="all-filters">
    <ion-grid class="ion-no-padding" v-if="dataReady">
      <ion-row class="all-filters__heading-row" :class="$platform === 'Desktop' ? '' : 'header-mobile'">
        <ion-col>
          <h2 class="all-filters__heading">All Filters</h2>
          <button @click="toggleFilterBar" class="all-filters__filter-close" aria-label="close side menu">
            <svg-icon icon-class="close-icon" class-name="all-filters__filter-close-svg" />
          </button>
        </ion-col>
      </ion-row>
      <div class="all-filters__filter-container" :class="isFilterModalVisible && $platform != 'Desktop' ? 'ios-filter-container' : ''">
        <ion-row v-for="(filter, index) in filters" :key="filter.name + index" class="all-filters__filter-row" @click="openFilterDetail(filter.name)">
          <ion-col class="all-filters__filter-col">
            <p style="display: inline-block" class="all-filters__filter-row-title">{{ filter.name }}</p>
            <div class="all-filters__filter-row-selected-options">
              <div style="display: inline-block" v-if="filter.selectedOptions && filter.selectedOptions.length > 0" :key="filter.name + index">
                <p class="all-filters__filter-row-selected-options-name" :key="filter.selectedOptions.join(',')">{{ filter.selectedOptions.join(', ') }}</p>
              </div>
            </div>
          </ion-col>
          <ion-col size="1">
            <svg-icon icon-class="icon-arrow-right-dark" class="all-filters__filter-row-icon" />
          </ion-col>
          <div v-if="isFilterModalVisible && currentFilter == filter.name" :key="filter.name + index" class="all-filters__filter-detail">
            <ion-row class="all-filters__heading-row" :class="$platform === 'Desktop' ? '' : 'header-mobile'">
              <ion-col>
                <button @click.stop="closeFilterDetail(filter)" class="all-filters__filter-close" style="float: left" aria-label="close side menu">
                  <svg-icon icon-class="icon-arrow-left-dark" class-name="all-filters__filter-close-svg" />
                </button>
                <h2 class="all-filters__heading">{{ filter.name }}</h2>
                <button @click.stop="closeFilterDetail(filter)" class="all-filters__filter-close" aria-label="close side menu">
                  <svg-icon icon-class="close-icon" class-name="all-filters__filter-close-svg" />
                </button>
              </ion-col>
            </ion-row>
            <ion-row class="ion-text-center ion-padding-bottom">
              <ion-col>
                <ion-searchbar
                  v-if="filter.options.length > 12"
                  class="searchbar-filter"
                  search-icon="search-outline"
                  ref="searchbar_filter"
                  style="text-align: left; --border-radius: 50px; box-shadow: none"
                  :value="searchFilter"
                  :placeholder="`Search ${filter.name}`"
                  @ionInput="handleFilterSearchInput($event, filter)"
                  @ionClear="handleFilterClearInput(filter)"
                ></ion-searchbar>
              </ion-col>
            </ion-row>
            <div style="overflow: auto; height: 68%" class="all-filters__filter-options-scroller" :class="$platform === 'Desktop' ? '' : 'options-scroller-mobile'">
              <!-- Checkbox multiple Selection -->
              <div v-if="!['Found in', 'Completed date'].includes(filter.name)">
                <ion-row
                  v-for="(option, index) in filter.options"
                  :key="filterOptKey + index"
                  :style="option.style ? option.style : 'display: flex'"
                  class="all-filters__filter-detail-row"
                >
                  <ion-col size="1">
                    <ion-checkbox
                      :key="option.name + option.selected"
                      @ionChange="onFilterOptionChange($event, filter.name)"
                      :checked="option.selected"
                      :value="filter.name === 'Duration' ? option.id : option.name"
                      class="all-filters__checkbox"
                      color="tertiary"
                      mode="md"
                      :disabled="option.count == 0"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col class="all-filters__filter-detail-name" :class="option.count == 0 ? 'filter-detail-name-disabled' : ''"
                    >{{ filterName(option, filter.name) }} ({{ option.count }})</ion-col
                  >
                </ion-row>
              </div>
              <!-- Radio single Selection -->
              <div v-else>
                <ion-radio-group @ionChange="onFilterOptionChangeRadio($event, filter.name)" :value="filter.selectedOptions[0]" :key="filter.name">
                  <ion-row
                    v-for="(option, index) in filter.options"
                    :key="filterOptKey + index"
                    :style="option.style ? option.style : 'display: flex'"
                    class="all-filters__filter-detail-row"
                  >
                    <ion-col size="1">
                      <ion-radio
                        :key="option.name + option.selected"
                        :value="filter.name === 'Duration' ? option.id : option.name"
                        class="all-filters__checkbox"
                        color="tertiary"
                        mode="md"
                      ></ion-radio>
                    </ion-col>
                    <ion-col class="all-filters__filter-detail-name" :class="option.count == 0 ? 'filter-detail-name-disabled' : ''">{{ filterName(option, filter.name) }}</ion-col>
                  </ion-row>
                </ion-radio-group>
              </div>
            </div>
            <ion-row class="all-filters__filter-footer" :class="$platform === 'Desktop' ? '' : 'footer-mobile'">
              <ion-col class="ion-text-center">
                <ion-button fill="clear" class="all-filters__clear-button" @click.stop="clearAllFiltersDetail(filter)">Clear filters</ion-button>
                <ion-button shape="round" color="primary" class="ion-button-primary all-filters__done-button" @click.stop="applyFilter(filter)"
                  >Apply {{ filter.name.toLowerCase() | formatFilterName }}</ion-button
                >
              </ion-col>
            </ion-row>
          </div>
        </ion-row>
      </div>
      <ion-row class="all-filters__filter-footer" v-if="!isFilterModalVisible" :class="$platform === 'Desktop' ? '' : 'footer-mobile'">
        <ion-col class="ion-text-center">
          <ion-button fill="clear" class="all-filters__clear-button" @click="onClickClearAll">Clear all filters</ion-button>
          <ion-button v-if="resultsCount > 0" shape="round" color="primary" class="ion-button-primary all-filters__done-button" @click="onClickDone"
            >View {{ resultsCount }} results</ion-button
          >
          <ion-button v-else shape="round" color="primary" class="ion-button-primary all-filters__done-button" @click="onClickDone">Done</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { popoverController } from '@modus/ionic-vue'
import localforage from 'localforage'

export default {
  name: 'MyLearningFilterSideBar',
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    resultsCount: {
      type: Number,
      require: false,
      default: () => {
        return 0
      }
    }
  },
  components: {},
  data() {
    return {
      authToken: '',
      searchFilter: '',
      dataReady: false,
      currentFilter: '',
      isFilterModalVisible: false,
      totalResults: 0,
      filterOptKey: new Date()
    }
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.searchFilter = ''
    await this.filters.map(async (filter) => {
      await filter.options.map((filterOption) => {
        filterOption.style = 'display: flex'
      })
    })
    const promise = this.filters.map(async (filter) => {
      filter.selectedOptions = []
      await filter.options.forEach((option) => {
        if (option.selected && filter.name === 'Duration') {
          filter.selectedOptions.push(option.id)
        } else if (option.selected && filter.name !== 'Duration') {
          filter.selectedOptions.push(option.name)
        }
      })
    })
    await Promise.allSettled([promise])
    this.dataReady = true
  },
  filters: {
    formatFilterName(name) {
      return name.toLowerCase() === 'found in' ? 'track name' : name
    }
  },
  methods: {
    onFilterOptionChange(event, name) {
      name === 'Duration'
        ? (this.filters.find((filter) => filter.name == name).options.find((option) => option.id == event.detail.value).selected = event.detail.checked)
        : (this.filters.find((filter) => filter.name == name).options.find((option) => option.name == event.detail.value).selected = event.detail.checked)
    },
    async onFilterOptionChangeRadio(data, name) {
      const value = data.target.value
      await this.filters.map(async (filter) => {
        if (filter.name === name) {
          await filter.options.map((option) => {
            option.selected = value === option.name
          })
        }
      })
    },
    toggleFilterBar() {
      popoverController.dismiss()
    },
    onClickDone() {
      this.$parent.$emit('coming-from-side-filter', false)
    },
    onClickClearAll() {
      this.$parent.$emit('clear-all-filters')
      popoverController.dismiss()
    },
    openFilterDetail(filter) {
      this.currentFilter = filter
      this.isFilterModalVisible = true
    },
    closeFilterDetail(filter) {
      if (filter.selectedOptions.length > 0) {
        filter.options.forEach((option) => {
          option.selected = filter.name === 'Duration' ? filter.selectedOptions.includes(option.id) : filter.selectedOptions.includes(option.name)
        })
      } else {
        filter.options.forEach((option) => {
          option.selected = false
        })
      }
      this.isFilterModalVisible = false
      this.currentFilter = ''
    },
    clearAllFiltersDetail(value) {
      this.currentFilter = ''
      this.filterOptKey = new Date()
      this.filters.find((filter) => filter.name == value.name).selectedOptions = []
      this.filters
        .find((filter) => filter.name == value.name)
        .options.forEach((option) => {
          option.selected = false
        })
      this.currentFilter = value.name
    },
    applyFilter(filter) {
      popoverController.dismiss()
      this.isFilterModalVisible = false
      this.currentFilter = ''
      this.$parent.$emit('selected-filters-output', filter)
      this.$parent.$emit('coming-from-side-filter', true)
    },
    filterName(option, type) {
      const capitalize = (s) => {
        return s.substr(0, 1).toUpperCase() + s.substr(1)
      }
      return type === 'Duration' || option.type === 'Duration' ? option.id : capitalize(option.name)
    },
    async handleFilterClearInput(event) {
      this.searchFilter = ''
      await this.filters.map(async (filter) => {
        if (event.name === filter.name) {
          await filter.options.map((option) => {
            option.style = 'display: flex'
          })
        }
      })
    },
    async handleFilterSearchInput(event, value) {
      this.searchFilter = event.target.value.trim()
      await this.filters.map(async (filter) => {
        if (value.name === filter.name) {
          await filter.options.map((option) => {
            const find = option.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1
            option.style = find ? 'display: flex' : 'display: none'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.filter-container-class {
  .popover-content {
    position: relative;
    width: 364px;
    top: 0 !important;
    height: 100vh;
    border-radius: unset !important;
    @media (max-width: 500px) {
      width: 100vw;
      left: 0 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@include block('all-filters') {
  position: relative;
  height: 100vh;
  z-index: 1;
  background-color: var(--ion-color-light);
  text-align: left;
  @include element('filter-container') {
    overflow: auto;
    height: 64vh;
  }

  @include element('heading-row') {
    margin-bottom: 48px;
    margin-right: 24px;
    margin-left: 24px;
  }

  @include element('heading') {
    font-family: 'Futura PT Demi';
    font-size: 20px;
    color: var(--ion-color-black);
    position: absolute;
    width: 80%;
    text-align: center;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @include element('filter-close') {
    color: unset;
    text-decoration: unset;
    background-color: unset;
    margin-top: 18px;
    float: right;
  }

  @include element('filter-close-svg') {
    height: 18px !important;
    width: 18px !important;
  }

  @include element('filter-col') {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 8px;
    cursor: pointer;
  }

  @include element('filter-row') {
    margin: 0px 24px;
    border-bottom: 1px solid var(--ion-color-super-light-gray);
  }

  @include element('filter-row-selected-options') {
    display: inline-block;
    padding: 0px 12px 0px 12px;
  }

  @include element('filter-row-selected-options-name') {
    display: inline-block;
    padding-left: 4px;
    font-family: 'Arial';
    font-size: 12px;
    text-transform: capitalize;
  }

  @include element('filter-row-title') {
    font-family: 'Arial';
    font-weight: bold;
    font-size: 16px;
    color: var(--ion-color-black);

    &:hover {
      color: var(--ion-color-tertiary);
    }
  }

  @include element('filter-row-icon') {
    margin: 16px 0px;
    cursor: pointer;
  }

  @include element('filter-footer') {
    padding: 32px 0px;
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 100%;
    z-index: 2;
  }

  @include element('clear-button') {
    text-transform: none;
    height: 39px !important;
    --box-shadow: none;
    color: var(--ion-color-dark-blue);
    font-family: 'Futura PT Demi';
    font-size: 16px;
    --background-hover: none;
  }

  @include element('done-button') {
    text-transform: none;
    height: 39px !important;
    --box-shadow: none;
    color: var(--ion-color-dark-blue);
    font-family: 'Futura PT Demi';
    font-size: 16px;
  }

  @include element('filter-options-scroller') {
    &::-webkit-scrollbar {
      width: 5px;
    }

    // scrollbar track
    &::-webkit-scrollbar-track-piece {
      background: #bcc2d4;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #4c5c8d;
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4c5c8d;
    }
  }

  @include element('filter-detail') {
    position: absolute;
    width: 364px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: var(--ion-color-light);
    z-index: 2;
  }

  @include element('checkbox') {
    --size: 18px;
    margin-right: 12px;
  }

  @include element('filter-detail-row') {
    margin: 16px 36px;
  }

  @include element('filter-detail-name') {
    font-family: 'Arial';
    font-size: 14px;
    color: #000000de;
    margin-left: 8px;
  }
}

.footer-mobile {
  margin-bottom: 3rem !important;
}

.header-mobile {
  margin-top: 48px !important;
  margin-bottom: 16px !important;
}

.options-scroller-mobile {
  height: 56% !important;
}

.filter-detail-name-disabled {
  color: #9f9f9f !important;
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Pro Max */,
  only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 12 Pro Max */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP XR/11 */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP XS Max/11 Pro Max */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP 11 */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP 11max */,
  only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 12/12 Pro */,
  only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 13/13 Pro */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 11pro */,
  only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP X /XS/11 Pro/12 Mini */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Mini */,
  only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) /* iP 6+/6s+/7+/8+ */,
  only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) /* iP 6/6s/7/8 */ {
  .header-mobile {
    margin-top: 108px !important;
    margin-bottom: 16px !important;
  }

  .options-scroller-mobile {
    height: 56% !important;
  }

  .footer-mobile {
    margin-bottom: 44px !important;
  }

  .ios-filter-container {
    overflow: auto;
    height: 74vh !important;
  }

  @include block('all-filters') {
    @include element('filter-footer') {
      bottom: 48px !important;
    }
  }
}

// for screens under 480px
@media screen and (max-width: 480px) {
  @include block('all-filters') {
    @include element('filter-detail') {
      width: 100%;
    }
    @include element('filter-container') {
      overflow: auto;
      height: 56vh;
    }
  }
}

@media screen and (max-height: 715px) {
  @include block('all-filters') {
    @include element('filter-footer') {
      bottom: 90px !important;
    }
    @include element('filter-container') {
      overflow: auto;
      height: 46vh;
    }
  }
  .options-scroller-mobile {
    height: 36% !important;
  }
}
</style>
