import axios from 'axios'

export default {
  async addBotBookmark(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async removeBotBookmark(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getAssetById(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return error.response.data
    })
    return response
  },

  async getAssetByMatchSkills(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(() => {
      return { data: [] } // return empty array on 504 error response
    })
    return response.data
  },

  async getAssetByApplications(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getAssetByTypes(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async downloadAsset(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: headers }).catch(error => {
      return { data: error.response }
    })
    if (response.status !== 200) {
      return null
    }
    const disposition = {}.hasOwnProperty.call(response.headers, 'content-disposition') ? response.headers['content-disposition'].split(';')[1].trim() : ''
    const filename = disposition.length && disposition.includes('filename') ? JSON.parse(disposition.split('=')[1].trim()) : ''
    return { blob: new Blob([response.data]), filename: filename }
  },

  async getAssetProviderByGuid(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getAssetList(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async downloadAssetItem(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch((error) => {
      console.log('error', error.response.data)
    })
    const data = await fetch(response.data.supportingFile.signedUrl)
    const blob = await data.blob()
    return blob
  },

  async getAssetItemById(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async submitAsset(url, payload) {
    const headers = {
      Authorization: payload.token,
      'Content-Type': 'multipart/form-data'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async deleteImageFromCreateAsset(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async deletePdfFromCreateAsset(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async getGovernanceTools(url, payload) {
    const headers = {
      Authorization: payload.token,
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getAssetInventoryTypes(url, payload) {
    const headers = {
      Authorization: payload.token,
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async deleteAssetItem(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async getUserAccessAssets(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async uploadAssets(url, payload, controller, onUploadProgress) {
    const headers = {
      Authorization: payload.token,
      'Content-Type': 'multipart/form-data'
    }
    const response = await axios.post(url, payload.body, { headers: headers, signal: controller.signal, onUploadProgress: onUploadProgress });
    return response;
  },

  async deleteAssetFile(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async downloadAssetZipFile(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios.get(url, { headers: headers, responseType: 'blob' }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async getAssetFunctions(url, payload) {
    const headers = {
      Authorization: payload.token,
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async downloadHowToGuide(url, payload) {
    const headers = { Authorization: payload.token }
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: headers }).catch(error => {
      return { data: error.response }
    })
    if (response.status !== 200) {
      return null
    }
    const disposition = {}.hasOwnProperty.call(response.headers, 'content-disposition') ? response.headers['content-disposition'].split(';')[1].trim() : ''
    const filename = disposition.length && disposition.includes('filename') ? JSON.parse(disposition.split('=')[1].trim()) : ''
    return { blob: new Blob([response.data]), filename: filename }
  }
}