<template>
  <div class="item-rating-container" ref="rating">
    <div style="border-bottom: 2px solid var(--ion-color-light-gray)">
      <div class="tabs-container-desktop">
        <button
          v-for="tab in tabs"
          :key="tab.tidx"
          class="tab accessible-button"
          :class="selectedTabIndex === tab.tidx ? 'selected' : ''"
          @click="onTabSelected(tab.tidx)"
          :aria-label="tab.title"
        >
          {{ tab.title }}
        </button>
      </div>
    </div>
    <div class="ratings-content">
      <div v-if="{}.hasOwnProperty.call(ratingDetails, 'totalNumberOfUsers') && ratingDetails.totalNumberOfUsers > 0">
        <div class="ratings-header">
          <div class="leave-a-review">
            <span class="average-rating">
              <span class="drop-cap">{{ ratingDetails.averageRating }} </span> out of 5</span
            >
            <div class="ratings-total-container">
              <StarRating
                inactive-color="#d0cfce"
                active-color="#B98032"
                :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                :rating="ratingDetails.averageRating"
                :read-only="true"
                :increment="0.1"
                :star-size="16"
                :show-rating="false"
                :round-start-rating="true"
                class="rating"
              />
              <span class="ratings-total">{{ ratingDetails.totalNumberOfUsers }} {{ ratingDetails.totalNumberOfUsers > 1 ? 'ratings' : 'rating' }}</span>
            </div>
            <ion-button class="review-btn" shape="round" color="primary" @click="onLeaveRating">{{ buttonText }}</ion-button>
          </div>
          <div v-if="{}.hasOwnProperty.call(ratingDetails, 'ratingHistogram') && this.ratingDetails.ratingHistogram.length > 0">
            <div v-for="(score, index) in ratingBarStatus" :key="index + Math.random()">
              <ItemRatingBar
                :index="ratingBarStatus.length - index"
                :score="Math.round((ratingBarStatus[ratingBarStatus.length - index - 1] / ratingDetails.totalNumberOfUsers) * 100)"
              />
            </div>
          </div>
        </div>
        <!--Comments display-->
        <div v-if="ratingCommentEnabled">
          <div v-for="rating in getRatings()" :key="rating.id" class="ratings-row">
            <CommentListItem :rating="rating" @flag-submitted="updateRatingAndComment()"></CommentListItem>
          </div>
        </div>
      </div>

      <div v-else class="ratings-header-no-reviews">
        <div class="no-reviews-title">There are currently no ratings.</div>
        <div class="no-reviews-content">Share your thoughts with other learners</div>
        <ion-button class="review-btn" shape="round" color="primary" @click="onLeaveRating">{{ buttonText }}</ion-button>
      </div>
    </div>

    <paginate
      v-model="currentPage"
      v-if="commentsWithRatings.length"
      :page-count="pageCount"
      :page-range="pageRange"
      :margin-pages="1"
      :click-handler="handlePageClick"
      :container-class="'pagination'"
      :page-class="'pagination-item'"
      :page-link-class="'pagination-item-link'"
      :prev-text="'&lsaquo;'"
      :prev-class="'pagination-nav'"
      :prev-link-class="'pagination-nav-link'"
      :next-text="'&rsaquo;'"
      :next-class="'pagination-nav'"
      :next-link-class="'pagination-nav-link'"
      :hide-prev-next="true"
    />
  </div>
</template>

<script>
import { popoverController } from '@modus/ionic-vue'
import StarRating from 'vue-star-rating'
import ItemRatingBar from '@/components/molecules/ItemRatingBar'
import ItemRatingModal from '@/components/molecules/ItemRatingModal'
import CommentListItem from '@/components/molecules/CommentListItem'
import { mapGetters } from 'vuex'
import localforage from 'localforage'

export default {
  name: 'ItemRating',
  components: { ItemRatingBar, StarRating, CommentListItem },
  props: {
    item: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    },
    ratingDetails: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    },
    scroll: {
      required: true,
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  watch: {
    scroll() {
      this.$refs.rating.scrollIntoView()
    },
    ratingDetails: function () {
      if ({}.hasOwnProperty.call(this.ratingDetails, 'ratingHistogram') && this.ratingDetails.ratingHistogram.length > 0) {
        this.ratingDetails.ratingHistogram.map((e) => {
          this.ratingBarStatus[e.value - 1] = e.count
        })
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      isMoreOpen: false,
      pageRange: 25,
      pageCount: 0,
      commentsWithRatings: [],
      ratingBarStatus: [0, 0, 0, 0, 0],
      tabs: [{ title: 'Reviews', tidx: 0, message: '' }],
      selectedTabIndex: 0,
      flagModalOpen: false,
      ratingCommentEnabled: false,
      buttonText: 'Rate and review'
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'enabledFeatureFlags'])
  },
  mounted() {
    this.$nextTick(() => {
      for (let element of document.getElementsByClassName('vue-star-rating-star')) {
        if (element.nodeName === 'svg') {
          element.style.marginRight = '5px'
        }
      }
    })
  },
  async created() {
    await this.determineUserAccess()
    await this.getComments()
    let ratingBarStatus = [0, 0, 0, 0]
    if (this.ratingDetails && {}.hasOwnProperty.call(this.ratingDetails, 'ratingHistogram') && this.ratingDetails.ratingHistogram.length > 0) {
      this.ratingDetails.ratingHistogram.map((e) => {
        ratingBarStatus[e.value - 1] = e.count
      })
      this.ratingBarStatus = ratingBarStatus
    }
    this.buttonText =
      ({}.hasOwnProperty.call(this.ratingDetails, 'userRating') && this.ratingDetails.userRating.flagged) || !this.ratingCommentEnabled ? 'Leave a rating' : 'Rate and review'
  },
  filters: {},
  methods: {
    async updateRatingAndComment() {
      await this.determineUserAccess()
      await this.getComments()
      this.$emit('update-ratings')
    },
    async getComments() {
      if (this.ratingCommentEnabled) {
        const authToken = this.auth_token || (await localforage.getItem('my_access_token'))
        this.$learnAdmin.getComments({ token: authToken, id: this.item.id }).then((res) => {
          if (res && res.entityRatings) {
            //restricting entities with only rating
            this.commentsWithRatings = res.entityRatings.filter((rating) => {
              if ({}.hasOwnProperty.call(rating, 'comment')) return rating
            })
            if (this.commentsWithRatings.length > this.pageRange) {
              this.pageCount = Math.ceil(this.commentsWithRatings.length / this.pageRange)
              this.setupPagination(true)
            }
          }
        })
      }
    },
    async determineUserAccess() {
      const authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const tenant = await this.$learnAdmin.getUserTenantInfo({ token: authToken })
      const settings = JSON.parse(tenant.settings)
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      const ratingFFlag = enabledFeatureFlags['learn_rating-comment-enabled'] ? enabledFeatureFlags['learn_rating-comment-enabled'] : false
      this.ratingCommentEnabled = {}.hasOwnProperty.call(settings, 'add-rating-comment-enabled') && settings['add-rating-comment-enabled'] && ratingFFlag
    },
    onScroll() {
      this.closeModal()
    },
    async onTabSelected(index) {
      this.selectedTabIndex = index
    },
    async handlePageClick(page) {
      this.currentPage = page
    },
    async setupPagination(isAddEvent = true) {
      const elements = document.getElementsByClassName('pagination')[0].childNodes
      if (elements) {
        elements.forEach((element) => {
          if (element.childNodes.length && element.className.includes('pagination')) {
            isAddEvent ? element.addEventListener('click', this.onPaginationItemClick, { once: true }) : element.removeEventListener('click', this.onPaginationItemClick)
          }
        })
      }
    },
    onPaginationItemClick(e) {
      let page = this.currentPage
      if (e.target.textContent != '…') {
        page = e.target.textContent == '‹' ? page - 1 : e.target.textContent == '›' ? page + 1 : parseInt(e.target.textContent)
        this.handlePageClick(page)
      }
    },
    closeModal() {
      if (this.modal) {
        this.modal.dismiss()
        this.modal = null
        return
      }
    },
    async onLeaveRating() {
      const item = this.item
      const ratingDetails = this.ratingDetails || {}
      const closeModal = this.closeModal
      const ratingCommentEnabled = this.ratingCommentEnabled
      if (this.modal) {
        this.modal.dismiss()
        this.modal = null
        return
      }
      const config = {
        component: ItemRatingModal,
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'item-rating-popover',
        backdropDismiss: true,
        mode: this.$platform === 'Mobile' && this.$device === 'ios' ? 'ios' : 'md',
        componentProps: {
          parent: this,
          propsData: {
            isUserRated: false,
            item,
            ratingDetails,
            ratingCommentEnabled,
            closeModal
          }
        }
      }
      this.modal = await popoverController.create(config)
      this.modal.present()
      this.modal.onDidDismiss().then(({ data }) => {
        this.closeModal()
        if (data && data.changed) {
          this.updateRatingAndComment()
        }
      })
      if (this.$device === 'ios') {
        this.modal.popovers = document.getElementsByClassName('sc-ion-popover-ios ')
        this.modal.popovers.forEach((element) => {
          element.style.marginTop = '-50px'
          if (window.innerWidth < 821 && window.innerWidth > 767) {
            element.style.marginLeft = '0px'
          }
        })
      }
    },
    getRatings() {
      const start = this.currentPage * this.pageRange - this.pageRange
      const end = start + this.pageRange - 1
      return this.commentsWithRatings.filter((item, index) => index >= start && index <= end)
    }
  }
}
</script>

<style lang="scss">
.item-rating-popover {
  @media only screen and (max-width: 821px) {
    left: 0 !important;
  }
}
</style>

<style lang="scss">
.item-rating-container {
  margin: 48px 0px;
  width: 100%;
  background-color: var(--ion-color-white);
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;

  .tabs-container-desktop {
    display: flex;
    margin-top: 20px;
    padding: 0 6.5rem;

    .tab {
      display: inline-block;
      padding: 0.5rem !important;
      color: var(--ion-color-black) !important;
      cursor: pointer;
      font: normal normal bold 16px Arial;
      margin: 0 1.5rem 0 0;
      &.selected {
        border-bottom: 5px solid #00d1b0;
      }
    }
    .accessible-button {
      color: unset;
      text-decoration: unset;
      background-color: unset;
    }
  }

  .tabs-container-mobile {
    position: relative;
    margin: 0 0.5rem;
    padding: 0rem;
    text-align: left;

    .selected {
      display: inline-block;
      padding-bottom: 0.5rem;
      color: var(--ion-color-black);
      border-bottom: 3px solid #00d1b0;
      font: normal normal bold 16px Arial;

      .dropdown-arrow {
        display: inline-block;
        margin-left: 0.6rem;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid var(--ion-color-black);
      }
    }
  }

  .ratings-content {
    display: flex;
    flex-direction: column;
    padding: 0 6.5rem;

    .ratings-header-no-reviews {
      display: flex;
      flex-direction: column;
      margin: 60px 0px;
      min-height: 100px;

      .no-reviews-title {
        text-align: left;
        font: normal normal normal 24px/30px 'Futura PT Demi';
        letter-spacing: 0.01px;
        color: #000000;
      }
      .no-reviews-content {
        font: normal normal normal 16px/22px Arial;
        letter-spacing: 0px;
        color: #5c5c5c;
        text-align: left;
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }

    .ratings-header {
      display: grid;
      grid-template-columns: 175px 1fr;
      margin: 60px 0px;
      min-height: 100px;

      .leave-a-review {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        width: max-content;
      }
      .average-rating {
        font: normal normal bold 12px/22px Arial;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        display: inline-flex;
        align-items: baseline;
        justify-content: center;
        margin-bottom: 24px;
        height: 24px;
        .drop-cap {
          font: normal normal normal 30px/30px 'Futura PT Demi';
          letter-spacing: 0.01px;
          margin-right: 8px;
          color: var(--ion-color-black);
        }
      }
      .ratings-total-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .ratings-total {
          text-align: left;
          font: normal normal normal 12px/14px Arial;
          letter-spacing: 0px;
          color: #000000;
          text-transform: lowercase;
          opacity: 1;
          margin-left: 0.25rem;
        }
      }
    }

    .review-btn {
      margin: 0px;
      margin-top: 24px;
      font-size: 15px;
      width: 154px;
      height: 40px;
      text-transform: none;
      font-family: 'Futura PT Demi';
      --ion-color-primary: #feb92e;
      --ion-color-primary-contrast: black;
      --box-shadow: unset;
    }

    .ratings-row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      border-bottom: 2px solid #dfdede;

      &:nth-of-type(1) {
        border-top: 2px solid #dfdede;
      }

      .ratings-row-meta {
        margin: 24px 0px;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          margin: 0 8px;
        }

        span:nth-child(1) {
          margin-left: 0px;
        }

        span:nth-child(2) {
          font: normal normal bold 14px/11px Arial;
          letter-spacing: 0px;
          color: #5c5c5c;
        }

        span:nth-child(3) {
          font: normal normal normal 14px/11px Arial;
          letter-spacing: 0px;
          color: #5c5c5c;
          margin-right: 0px;
        }

        .flag-container {
          cursor: pointer;
          border: 1px solid #dfdede;
          border-radius: 50%;
          padding: 5px;
          .flag-icon {
            color: #000000;
          }
        }
      }

      .ratings-comment {
        margin-bottom: 24px;
        font: normal normal normal 16px/28px Arial;
        letter-spacing: 0px;
        color: #5c5c5c;
        text-align: left;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin-top: 8rem;
    margin-bottom: 48px;
    padding: 0px;
  }

  .pagination-item {
    margin: 0 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  li.pagination-nav {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    cursor: pointer;
  }

  .pagination-item-link {
    color: var(--ion-color-post-gray);
    font-size: 14px !important;
    vertical-align: middle;
  }

  .pagination-nav-link {
    position: relative;
    color: #5c5c5c;
    font-size: 3rem !important;
    vertical-align: middle;
    font-family: 'Futura PT Book';
  }

  li.disabled {
    border: none;
    cursor: default;
    .pagination-item-link {
      cursor: default;
    }
  }

  li.active {
    border: 2px solid #1dd1b0;
    border-radius: 4px;
    background: white;
    .pagination-item-link {
      color: #000000;
    }
  }

  li:hover {
    background: #e0e1e1;
  }

  li .tooltiptext {
    visibility: hidden;
    width: max-content;
    height: 30px;
    background-color: #efefef;
    color: #000000de;
    text-align: center;
    padding: 7px 10px;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 50%;
    margin-left: -20px;
    font-size: 12px;
    font-family: 'Futura PT Demi';
  }

  .li:hover .tooltiptext {
    visibility: visible;
  }
}

@media only screen and (max-width: 1366px) {
  .item-rating-container {
    .tabs-container-desktop {
      display: flex;
      padding: 0 30px !important;
    }

    .ratings-content {
      padding: 0px 30px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .item-rating-container {
    .bar-graph-container {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  .tab {
    font: normal normal bold 14px Arial !important;
  }
}

@media only screen and (max-width: 375px) {
  .item-rating-container {
    .average-rating {
      margin-bottom: 20px !important;
    }
    .review-btn {
      margin-top: 20px !important;
    }

    .ratings-header {
      margin: 48px 0px !important;
    }

    .ratings-row {
      .ratings-row-meta {
        margin-bottom: 20px !important;
      }
    }
  }
}
</style>
