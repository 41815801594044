<template>
    <div class="celebrate-modal">
        <lottie-player src="/json/confetti.json" background="transparent" speed="1" autoplay
            @complete="closeCelebration"></lottie-player>
    </div>
</template>
<script>
import LottiePlayer from "@lottiefiles/lottie-player";
import EventBus from '@/plugins/eventbus'
export default {
    name: 'Celebration',
    components: { LottiePlayer },
    data() {
        return {}
    },
    methods: {
        closeCelebration() {
            EventBus.$emit('close-celebration', false)
        }
    },
}
</script>
<style lang="scss" scoped>
.celebrate-modal {
    margin: auto;
    width: 80%;
    padding: 10px;
    position: absolute;
    top: 20%;
    left: 10%;
    z-index: 20005;
    height: 80vh;
}

@media screen and (max-width: 480px) {
    .celebrate-modal {
        margin: auto;
        width: 100%;
        position: absolute;
        top: 25%;
        z-index: 20005;
        height: 50vh;
        left: 0%;
    }
}
</style>