<template>
  <div class="comment-flag-modal">
    <div class="header-content">
      <h2 size="8" class="g-type-header title" :class="$platform === 'Desktop' ? 'title-desktop' : 'title-mobile'">Why are you flagging this comment?</h2>
      <svg-icon icon-class="filter-close" class="g-icon--small close" @click="onCancel"></svg-icon>
    </div>
    <div class="list-container">
      <ion-radio-group value="flaggedReason" @ionChange="checkSelectionsRadio($event)">
        <div class="list-item">
          <div class="checkbox-container">
            <ion-radio color="tertiary" slot="start" value="off-topic"> </ion-radio>
          </div>
          <div class="label-container">
            <h3>It’s off-topic</h3>
            <p>The comment is not relevant to the content or current discussion.</p>
          </div>
        </div>
        <div class="list-item">
          <div class="checkbox-container">
            <ion-radio color="tertiary" slot="start" value="inappropriate"> </ion-radio>
          </div>
          <div class="label-container">
            <h3>It’s inappropriate</h3>
            <p>The comment contains content that could be considered offensive, abusive or a violation of community/workplace conduct.</p>
          </div>
        </div>
        <div class="list-item">
          <div class="checkbox-container">
            <ion-radio color="tertiary" slot="start" value="other"> </ion-radio>
          </div>
          <div class="label-container">
            <h3>Something else</h3>
          </div>
        </div>
      </ion-radio-group>
      <ion-textarea
        v-if="flaggedReason === 'other'"
        rows="4"
        class="other-comment"
        autofocus
        @ionChange="onFlagReasonChange($event, 'otherComment')"
        placeholder="Let us know your specific concerns in less than 250 characters. Please provide any relevant details."
      >
      </ion-textarea>
      <div class="error-container" v-if="otherCommentError"><p>You have reached the 250 character limit</p></div>
    </div>
    <div class="footer-container">
      <a href="#" @click="onCancel" class="cancel-btn">Cancel</a>
      <ion-button
        shape="round"
        :disabled="!flaggedReason || (flaggedReason == 'other' && !otherComment) || this.otherCommentError"
        color="primary"
        class="ion-button-primary ion-text-capitalize submit-btn"
        @click="onSubmit"
        >Submit</ion-button
      >
    </div>
  </div>
</template>
  
  <script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import { popoverController } from '@modus/ionic-vue'
import localforage from 'localforage'

export default {
  name: 'CommentFlagModal',
  props: {
    rating: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'user_guid'])
  },
  data() {
    return {
      flaggedReason: '',
      inappropriate: false,
      offTopic: false,
      other: false,
      otherComment: '',
      otherCommentError: false
    }
  },
  created() {},
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.userGuid = this.user_guid || (await localforage.getItem('my_user_guid'))
  },
  beforeDestroy() {},
  filters: {},
  methods: {
    onCancel() {
      popoverController.dismiss()
    },
    checkSelectionsRadio(e) {
      this.flaggedReason = e.target.value
    },
    onFlagReasonChange(e, type) {
      if (type == 'other') this.other = e.target.checked
      else if (type == 'inappropriate') this.inappropriate = e.target.checked
      else if (type == 'offTopic') this.offTopic = e.target.checked
      else if (type == 'otherComment') {
        this.otherComment = e.detail.value
        this.otherCommentError = e.detail.value.length > 250 ? true : false
      }
    },
    async onSubmit() {
      const authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const payload = {
        token: authToken,
        ratingId: this.rating.id,
        body: {
          flaggingReason: [this.flaggedReason]
        }
      }
      this.flaggedReason === 'other' ? (payload.body.comment = this.otherComment) : ''
      await this.$learnAdmin.flagCommentById(payload)
      await this.onPrepareGAPayload()
      popoverController.dismiss({ submit: true })
    },
    async onPrepareGAPayload() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'tenant-guid': tenantGuid,
        'authored-learner-id': this.rating.userGuid,
        'flagged-learner-id': learnerId,
        reason: this.flaggedReason,
        'underlying-comment': this.otherComment
      }
      const payload = {
        action: 'Flagging a comment',
        category: 'Flagged',
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>
  <style lang="scss">
.comment-flag-container {
  .popover-content {
    width: 25rem;
    border-radius: 25px !important;
    background: #efefef;
    text-align: left;
    @media (max-width: 484px) {
      width: 100%;
      left: 0 !important;
      top: 10vh !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.comment-flag-modal {
  padding: 2rem;
  justify-content: flex-start;
  .header-content {
    display: flex;
    h2 {
      font: normal normal normal 24px/34px 'Futura PT Demi';
    }
    .close {
      cursor: pointer;
    }
  }
  .list-container {
    margin-top: 1rem;
    .list-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      .label-container {
        margin-left: 1rem;
        width: 100%;
        h3 {
          font: normal normal bold 14px/18px Arial;
          margin-top: 0;
          margin-bottom: 0.5rem;
        }
        p {
          font: normal normal normal 14px/18px Arial;
          margin: 0;
        }
      }
    }
    .other-comment {
      border: 1px solid #e0e1e1;
      border-radius: 0.3rem;
      background: #fff;
    }
    .error-container p {
      font: normal normal normal 12px/30px Arial;
      letter-spacing: 0px;
      color: #e0301e;
      opacity: 1;
      margin: 0;
    }
  }
  .footer-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.5rem;
    .cancel-btn {
      background: none;
      border: none;
      color: #1f3370;
      text-decoration: none;
      margin-right: 2rem;
    }
    .submit-btn {
      font-weight: bold;
    }
  }
}
</style>
  