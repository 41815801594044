<template>
  <div>
    <transition name="fade">
      <div class="a-overlay" v-if="showConfirmationLaunch || showConfirmationUnavailable"></div>
    </transition>
    <div class="b-modal confirm-box" v-if="showConfirmationLaunch">
      <h3 class="title">Launch 15-Day Virtual Desktop Session?</h3>
      <p>Your virtual desktop will be available for 15 days from the time that you launch it.</p>
      <div class="handler">
        <ion-button data-cy="confirm-launch" class="g-button--primary ion-button-primary" color="primary" @click="confirmMyLaunch">Launch Virtual Desktop</ion-button>
        <a
          href="#"
          @click="
            () => {
              this.showConfirmationLaunch = false
            }
          "
          >Cancel</a
        >
      </div>
    </div>
    <div class="b-modal confirm-box" v-if="showConfirmationUnavailable">
      <h3 class="title">No desktops available</h3>
      <p>It looks like all available desktops are in use, please check back shortly.</p>
      <div class="handler">
        <ion-button
          data-cy="confirm-launch"
          class="g-button--primary ion-button-primary"
          color="primary"
          @click="
            () => {
              this.showConfirmationUnavailable = false
            }
          "
          >Close</ion-button
        >
      </div>
    </div>
    <ion-card v-if="!isDesktopPlatform" class="ion-no-margin overview">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col class="ion-no-padding">
            <img :src="course.image.uri" class="overview__img" />
            <svg-icon icon-class="close" class-name="g-icon--small overview__close" color="#182755" @click="$router.go(-1)" />
          </ion-col>
        </ion-row>
        <ion-row class="g-padding__right--small g-padding__left--medium g-padding__top--small">
          <ion-col>
            <p class="ion-text-start ion-no-margin g-type-title overview__course_title g-margin__bottom--small ellipsis-lines-2">
              {{ course.title }}
            </p>
            <p class="ion-text-start ion-no-margin overview__metadata g-margin__bottom--small">
              {{ course.duration | formatDuration }}
              <svg-icon v-if="cpeWorthy" icon-class="icon-green-check" class-name="g-icon--small check-icon" />
              <span v-if="cpeWorthy">CPE - Worthy</span>
            </p>
            <p class="ion-text-start ion-no-margin overview__subtitle g-margin__bottom--small">
              <span v-html="$sanitize(course.synopsis)"></span><span v-if="!isAccessible"><InfoPopover :content="popoverContent.credentialInaccessible" color="#DD5217" /></span>
            </p>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding ion-text-start ion-align-items-center" v-if="isScormCloudCredential">
          <ion-col>
            <ion-button shape="round" color="primary" class="g-button--primary ion-button-primary overview__continue-btn" @click="continueLesson">Continue </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>

    <ion-card v-else>
      <ion-grid class="ion-no-padding">
        <ion-row class="g-padding__right--small g-padding__left--medium g-padding__top--small g-padding__bottom--small">
          <ion-col size="7" class="g-padding__right--small">
            <p class="ion-text-start ion-no-margin g-type-title overview__course_title g-margin__bottom--small ellipsis-lines-2">
              {{ course.title }}
            </p>
            <p class="ion-text-start ion-no-margin overview__metadata g-margin__bottom--small">
              {{ course.duration | formatDuration }}
              <svg-icon v-if="cpeWorthy" icon-class="icon-green-check" class-name="g-icon--small check-icon" />
              <span v-if="cpeWorthy">CPE - Worthy</span>
            </p>
            <p class="ion-text-start ion-no-margin overview__subtitle g-margin__bottom--small">
              <span v-html="$sanitize(course.synopsis)"></span><span v-if="!isAccessible"><InfoPopover :content="popoverContent.credentialInaccessible" color="#DD5217" /></span>
            </p>
          </ion-col>
          <ion-col size="5">
            <img v-if="course.image.uri" :src="course.image.uri" class="overview__img-desktop" />
            <img v-else src="@/assets/images/thumbnail-complete-credential.png" />
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding ion-text-start ion-align-items-center">
          <ion-col>
            <ion-button v-if="isScormCloudCredential" shape="round" color="primary" class="g-button--primary ion-button-primary overview__continue-btn" @click="continueLesson"
              >Continue
            </ion-button>
            <div v-if="course.vdiEnabledForUser && capstoneProgress !== 100" style="display: inline">
              <span :key="vdiP" v-if="canLaunchWorkspace" class="overview__vdi-text" :disabled="isLaunchingWorkspace" @click="intentLaunchWorkspace">
                {{ launchWorkspaceBtnTxt }}
              </span>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</template>

<script>
import localforage from 'localforage'
import InfoPopover from '@/components/organisms/InfoPopover'

export default {
  name: 'CredentialOverview',
  components: {
    InfoPopover
  },
  props: {
    course: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: 'Default Credential Title'
        }
      }
    },
    isAccessible: {
      type: Boolean,
      required: true,
      default: true
    },
    cpeWorthy: {
      type: Boolean,
      default: false,
      required: false
    },
    isScormCloudCredential: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      isDesktopPlatform: false,
      courseProgress: 0,
      popoverContent: {
        credentialInaccessible: {
          title: 'Credential not available',
          text: 'Please contact your Plan Administrator'
        }
      },
      canLaunchWorkspace: false,
      isLaunchingWorkspace: false,
      launchWorkspaceBtnTxt: 'Launch Virtual Desktop',
      showConfirmationLaunch: false,
      showConfirmationUnavailable: false,
      quickRefLink: '',
      auth_token: '',
      vdiP: false,
      vdiCheck: null,
      capstoneProgress: 0
    }
  },
  async beforeCreate() {
    const { cdn } = await localforage.getItem('cloudEnv')
    this.quickRefLink = cdn.contentCdn + 'dev/vdi/vdi_guide.pdf'
  },
  async beforeMount() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
    const vdiOpened = await localforage.getItem('vdiOpened')
    if (this.isDesktopPlatform && vdiOpened) {
      this.isLaunchingWorkspace = true
      this.launchWorkspaceBtnTxt = 'Virtual Desktop is Open'
    }
  },
  async mounted() {
    this.courseProgress = this.isAccessible ? this.course.progress : 0
    this.capstoneProgress = this.course?.content?.capstone?.progress
    this.canLaunchWorkspace = true
    this.auth_token = await localforage.getItem('my_access_token')
    if (this.isDesktopPlatform) {
      this.vdiCheck = setInterval(async () => {
        this.vdiP = (await localforage.getItem('vdiOpened')) || false
        if (this.vdiP) {
          this.isLaunchingWorkspace = true
          this.launchWorkspaceBtnTxt = 'Virtual Desktop is Open'
        } else {
          this.isLaunchingWorkspace = false
          this.launchWorkspaceBtnTxt = 'Launch Virtual Desktop'
        }
      }, 2000)
    }
  },
  destroyed() {
    this.isDesktopPlatform ? clearInterval(this.vdiCheck) : ''
    this.$destroy()
  },
  filters: {
    formatDuration(time) {
      return time < 3600 ? `${Math.round(time / 60)} min` : `${Math.round(time / 3600)} hrs`
    }
  },
  methods: {
    async intentLaunchWorkspace() {
      const userId = await localforage.getItem('my_user_id')
      const token = this.auth_token

      const payload = { userId, token }
      const response = await this.$learnAdmin.getWorkspaceAvailability(payload)

      await localforage.setItem('vdi_settings', response)

      if (response.status === 'available') {
        this.showConfirmationLaunch = true
      } else if (response.status === 'assigned') {
        this.confirmMyLaunch()
      } else {
        this.showConfirmationUnavailable = true
      }
    },
    confirmMyLaunch() {
      this.isLaunchingWorkspace = true
      this.launchWorkspaceBtnTxt = 'Virtual Desktop is Open'
      this.showConfirmationLaunch = false

      let rout = this.$router.resolve({ path: `/workspace/${this.$route.params.id}` })
      window.open(rout.href, '_blank')
    },
    continueLesson() {
      this.$emit('on-select-continue', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('overview') {
  border-radius: 0px;

  @include element('close') {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: var(--ion-color-white);
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 22px;
    z-index: 100;
  }

  @include element('continue-btn') {
    width: 160px;
    font-family: 'Futura PT Md';
    color: var(--ion-color-tertiary);
    font-size: 1rem;
  }

  @include element('img') {
    height: auto;
    /* height: 200px;
    max-height: 250px; */
    width: 100%;
    object-fit: cover;
  }

  @include element('vdi-text') {
    font-family: 'Futura PT Md';
    color: var(--ion-color-medium);
    font-size: 1rem;
    text-decoration: underline;
    margin-left: 2rem;
    margin-top: 0.5rem;
    cursor: pointer;
  }

  @include element('img-desktop') {
    width: 100%;
    object-fit: cover;
  }

  @include element('course_title') {
    color: var(--ion-color-hover);
    font-family: 'Futura PT Md';
    font-size: 2rem;
    @extend .g-type-large;
  }
  @include element('heading') {
    color: map-get($edge-colors, 'black');
    font-family: 'Futura PT Demi';
    font-size: 1rem;
  }
  @include element('subtitle') {
    font-family: 'Arial';
    color: #333333;
    font-size: 1rem;
    line-height: 1.5;
  }
  @include element('metadata') {
    font-size: 0.8rem;
    text-align: left;
    font-family: 'Arial';
    font-weight: bold;
    color: var(--ion-color-post-gray);
  }
  @include element('progress') {
    width: 90%;
    height: 0.5rem;
    background-color: var(--ion-color-medium-light);
    border-radius: 10px;

    @include modifier('percent') {
      background: var(--ion-color-secondary);
      border-radius: inherit;
      height: inherit;
    }
  }
  @include element('social') {
    display: flex;
    flex-direction: row;
  }
}

.bookmark {
  margin-left: auto;
}

ion-card {
  margin-bottom: 1px;
}

.g-button--primary {
  --box-shadow: none;
  text-transform: none;
  &[disabled] {
    --background: #ccc;
  }
}

.ion-button-primary {
  &:disabled,
  &[disabled] {
    --ion-color-base: var(--ion-color-disabled-btn) !important;
    --ion-color-base-rgb: var(--ion-color-disabled-btn) !important;
    --ion-color-contrast: var(--ion-color-medium) !important;
    --ion-color-contrast-rgb: var(--ion-color-medium) !important;
    opacity: 1;
  }
}

/* ion-button.g-button--primary.button-disabled {
  background: #ccc;
} */

.secondary-link {
  color: var(--ion-color-tertiary);
  &:hover {
    color: var(--ion-color-hover);
    transition: 0.3s color;
  }
}
.pdf {
  background: url('../../../assets/images/pdf.png') 0 -1px no-repeat;
  padding-left: 18px;
}
.ml {
  margin-right: 30px;
}

.mt {
  margin-top: 10px;
}

.b-modal {
  width: 403px;
}

.confirm-box {
  .g-button--primary {
    width: 11rem;
  }
}

.check-icon {
  margin: 0.2rem 0.5rem 0rem 1.2rem;
  padding-top: 5px;
}

.launch-vdi-btn {
  height: 35px !important;
}
</style>
