<template>
  <div class="course-about">
    <div class="course-description">
      <div class="course-title">About</div>
      <div class="course-subtitle" v-html="$sanitize(course.synopsis)"></div>
    </div>
    <div class="courses-carousel">
      <CourseRelatedCourses :course="relatedCourses" :requiredLearningPlanItems="requiredLearningPlanItems" />
    </div>
  </div>
</template>

<script>
import CourseRelatedCourses from '@/views/course/components/CourseRelatedCourses'

export default {
  name: 'CourseAbout',
  components: { CourseRelatedCourses },
  props: {
    course: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    },
    relatedCourses: {
      required: true,
      type: Array,
      default: () => {
        return {}
      }
    },
    requiredLearningPlanItems: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.course-about {
  padding: 8px 0px;
  background-color: #fff;
  border-radius: 20px;
  .course-title {
    font-family: 'Futura PT Demi';
    font-size: 24px;
    color: black;
    text-align: left;
    border-bottom: 2px solid #e0e1e1;
    padding-bottom: 20px;
  }
  .course-subtitle {
    text-align: left;
    margin: 24px 0 0;
    font-family: Arial;
    color: #5c5c5c;
    font-size: 16px;
    line-height: 25px;
  }
}

.course-description {
  padding: 20px 100px;
}
.courses-carousel {
  padding: 0 100px;
  @media (max-width: 1366px) {
    padding: 0px 30px;
  }
  @media (max-width: 484px) {
    padding: 0px 16px;
  }
}

@media screen and (max-width: 1366px) {
  .course-description {
    padding: 20px;
  }
}

@media screen and (max-width: 484px) {
  .course-description {
    padding: 20px 16px 18px;
  }
}
</style>
