<template>
  <div :class="isDesktopPlatform ? '' : 'non-white-background'">
    <div v-if="dataReady">
      <ion-grid v-if="isContentAvailable" :class="isDesktopPlatform ? 'asset-container-desktop' : 'asset-container-mobile'">
        <ion-row>
          <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="ion-no-padding">
            <div class="back-btn-container">
              <button class="back" @click="$router.go(-1)">
                <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
                Back
              </button>
            </div>
            <ion-row class="asset-info ion-no-padding">
              <ion-col v-if="isDesktopPlatform" class="asset-image-container ion-no-padding">
                <img :src="imgSrc" />
              </ion-col>
              <ion-col class="ion-no-padding">
                <div class="category">
                  <div class="format">{{ asset.format.name }}</div>
                  <div class="provider">{{ asset.provider.name }}</div>
                </div>
                <div class="title">{{ asset.title }}</div>
                <div style="margin-bottom: 1.5rem">
                  <div v-if="asset.rating" class="asset-rating" @click="onScroll">
                    <span class="rating">{{ asset.rating }}</span>
                    <StarRating
                      inactive-color="#d0cfce"
                      active-color="#b98032"
                      :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                      :rating="asset.rating"
                      :read-only="true"
                      :increment="0.1"
                      :star-size="14"
                      :show-rating="false"
                      :round-start-rating="true"
                      class="star-rating"
                    />
                    <svg-icon v-if="!isDesktopPlatform" icon-class="star-filled-desktop" class="display-rating-mobile" />
                    <span class="rating-count">{{ asset.ratingCount }} {{ asset.ratingCount > 1 ? 'ratings' : 'rating' }}</span>
                  </div>
                </div>
                <div v-if="asset.disclaimerText.length" class="term" v-html="$sanitize(asset.disclaimerText)" @click="onDisclaimerClick"></div>
                <div class="meta footer">
                  <ion-button
                    shape="round"
                    color="primary"
                    class="ion-text-capitalize"
                    :class="isDesktopPlatform || (asset.assetReference && asset.assetReference.externalAssetLink) ? 'ion-button-primary-dark' : 'ion-button-primary-disabled'"
                    @click="onDownload"
                    :disabled="!isDesktopPlatform && !asset.assetReference && !asset.assetReference.externalAssetLink"
                  >
                    <span v-if="asset.applications.length" :class="isDesktopPlatform ? 'icon-desktop' : 'icon-mobile'">
                      <img v-for="application in asset.applications" :key="application.src" :src="application.src.replace('.svg', '-bw.svg')" class="btn-icon-tech" />
                    </span>
                    {{ downloadBtnText }}
                    <svg-icon v-if="asset.assetReference && asset.assetReference.externalAssetLink" icon-class="expand-out" class="g-icon--small asset-get-btn" />
                  </ion-button>
                  <div class="bookmark-container">
                    <Bookmark class="asset-bookmark" :assetId="assetId" assetType="automation" :meta="asset.meta" />
                    <span class="bookmark-tooltip-text" v-if="isDesktopPlatform">Bookmark</span>
                  </div>
                  <div class="share-container">
                    <button class="social-share" aria-labelledby="share-btn" @click="popoverOn('share')"><svg-icon icon-class="click-to-copy" class-name="g-icon--small g-clickable" /></button>
                    <SocialSharePopover
                      v-if="isShareOpen"
                      class="social-share-popover"
                      :assetId="assetId"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      fromPage="Asset Detail"
                      size="lg"
                      @dismiss-share-action-model="popoverOff('share')"
                    />
                    <span v-if="!isShareOpen && isDesktopPlatform" id="share-btn" class="share-tooltip-text">Share</span>
                  </div>
                  <div v-if="showAddToTrack && !['asset'].includes(this.asset.format.name.toLowerCase())" class="more-container">
                    <button class="more-action" aria-labelledby="more-btn" @click="popoverOn('more')"><svg-icon icon-class="more-action" class-name="g-icon--small g-clickable" /></button>
                    <MoreActionPopover
                      v-if="isMoreOpen"
                      class="more-popover"
                      :item="asset"
                      :assetId="assetId"
                      :routeInfo="getRouteInfo()"
                      :showAddToTrack="true"
                      :showEdit="false"
                      :showDelete="false"
                      @add-to-track="popoverOff('more')"
                      @close-more="popoverOff('more')"
                    />
                    <span v-if="!isMoreOpen" id="more-btn" class="more-tooltip-text">More</span>
                  </div>
                </div>
                <div v-if="asset.meta.isCompanyAsset && !isDesktopPlatform" class="author-banner">{{ asset.provider.name }} ASSET</div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <AssetDetail :asset="asset" :scroll="scroll" @rating-details="getRatingDetails" />
      </ion-grid>
      <ion-grid v-else :style="isDesktopPlatform ? '' : 'padding: 2rem 10px 6rem;'" class="ion-no-padding">
        <PageNotFound />
      </ion-grid>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import Bookmark from '@/components/molecules/Bookmark'
import AssetDetail from '@/views/asset/components/AssetDetail'
import MoreActionPopover from '@/components/molecules/MoreActionPopover'
import SocialSharePopover from '@/components/molecules/SocialSharePopover'
import { Plugins } from '@capacitor/core'
import PageNotFound from '@/components/molecules/PageNotFound'
import StarRating from 'vue-star-rating'
const { Browser } = Plugins

export default {
  name: 'Asset',
  components: { Bookmark, AssetDetail, MoreActionPopover, SocialSharePopover, PageNotFound, StarRating },
  props: {},
  data() {
    return {
      assetId: '',
      authToken: '',
      asset: null,
      assetMediaUrl: '',
      isDesktopPlatform: true,
      imgSrc: '',
      downloadBtnText: '',
      dataReady: false,
      isContentAvailable: false,
      isShareOpen: false,
      isMoreOpen: false,
      showAddToTrack: false,
      scroll: 0
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async activated() {
    this.dataReady = false
    this.showAddToTrack = await localforage.getItem('show-add-to-track')
    await this.initializeComponent()
  },
  methods: {
    async initializeComponent() {
      this.assetId = this.$route.params.id
      this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
      this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
      this.isDesktopPlatform = this.$platform === 'Desktop'
      await this.getAssetById()
      const payload = await this.onPrepareGAPayload('Viewed')
      await this.GAEventTrack(payload)
      this.dataReady = true
    },
    async getAssetById() {
      const response = await this.$learnAdmin.getAssetById({ token: this.authToken, id: this.assetId })
      this.isContentAvailable = {}.hasOwnProperty.call(response, 'status') && response.status != 400 && response.status != 404 && response.status != 401
      if (this.isContentAvailable) {
        this.asset = response.data
        this.asset.assetType = 'automation'
        this.asset.format = { name: this.asset.inventoryCategory }
        let logo = {}.hasOwnProperty.call(this.asset, 'provider') && {}.hasOwnProperty.call(this.asset.provider, 'logo') && {}.hasOwnProperty.call(this.asset.provider.logo, 'uri')
        this.asset.provider = {
          guid: {}.hasOwnProperty.call(this.asset, 'provider') && this.asset.provider.guid ? this.asset.provider.guid : '',
          name: {}.hasOwnProperty.call(this.asset, 'provider') && this.asset.provider.name ? this.asset.provider.name : '',
          avatarImage: logo ? this.asset.provider.logo.uri : ''
        }
        this.asset.type = {}.hasOwnProperty.call(this.asset.inventoryType, 'name')
          ? { name: this.asset.inventoryType.name, src: `${this.assetMediaUrl}media-container/${this.asset.inventoryType.image}` }
          : { name: this.asset.inventoryType, src: null }
        this.asset.title = this.asset.name
        if ({}.hasOwnProperty.call(this.asset, 'galleryImages') && this.asset.galleryImages.length) {
          this.asset.media = await this.asset.galleryImages.map((image) => {
            return { caption: image.caption, src: `${this.assetMediaUrl}featured-images/${image.image}`, type: 'image' }
          })
        }
        if ({}.hasOwnProperty.call(this.asset, 'featuredVideo')) {
          const video = { caption: this.asset.featuredVideo.caption, src: this.asset.featuredVideo.uri, type: 'video' }
          if (!{}.hasOwnProperty.call(this.asset, 'media')) {
            this.asset.media = [video]
          } else {
            this.asset.media.unshift(video)
          }
        }
        this.asset.applications = await this.asset.applications.map((application) => {
          return {}.hasOwnProperty.call(application, 'name')
            ? { name: application.name, src: `${this.assetMediaUrl}media-container/${application.image}` }
            : { name: application, src: null }
        })
        this.asset.version = {}.hasOwnProperty.call(this.asset, 'versionDetails') && this.asset.versionDetails.length ? this.asset.versionDetails[0].versionNumber : '1.0'
        this.imgSrc = this.fetchAssetThumbnail(this.asset.inventoryType.name)
        const provider = await this.$learnAdmin.getAssetProviderByGuid({ token: this.authToken, guid: this.asset.provider.guid })
        this.asset.disclaimerText = {}.hasOwnProperty.call(provider, 'disclaimerText') ? provider.disclaimerText : ''
        this.downloadBtnText =
          this.asset.assetReference && this.asset.assetReference.externalAssetLink ? 'Open' : !this.isDesktopPlatform ? 'Download available on desktop only' : 'Download'
      }
    },
    async onDownload() {
      if (this.asset.assetReference && this.asset.assetReference.externalAssetLink) {
        await Browser.open({ url: this.asset.assetReference.externalAssetLink })
      }
      const file = await this.$learnAdmin.downloadAsset({ token: this.authToken, id: this.assetId })
      if (file) {
        file.filename = file.filename.length ? file.filename : `${this.assetId}.zip`
        const a = document.createElement('a')
        a.href = URL.createObjectURL(file.blob)
        a.setAttribute('download', file.filename)
        a.click()
      }
      const payload = await this.onPrepareGAPayload('Asset downloaded')
      await this.GAEventTrack(payload)
    },
    getRatingDetails(ratingDetails) {
      this.asset.rating = {}.hasOwnProperty.call(ratingDetails, 'averageRating') ? ratingDetails.averageRating : ''
      this.asset.ratingCount = {}.hasOwnProperty.call(ratingDetails, 'totalNumberOfUsers') ? ratingDetails.totalNumberOfUsers : ''
    },
    popoverOn(popoverType) {
      this.isShareOpen = popoverType === 'share'
      this.isMoreOpen = popoverType === 'more'
    },
    popoverOff(popoverType) {
      this.isShareOpen = !popoverType === 'share'
      this.isMoreOpen = !popoverType === 'more'
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    async onDisclaimerClick() {
      const payload = await this.onPrepareGAPayload('Asset disclaimer clicked')
      await this.GAEventTrack(payload)
    },
    getRouteInfo() {
      if (['article', 'video', 'audio', 'podcast'].includes(this.asset.format.name.toLowerCase())) {
        return { name: 'Content', params: { id: this.asset.id, title: this.asset.title, pgrTrk: true } }
      } else if (['asset'].includes(this.asset.format.name.toLowerCase())) {
        return { name: 'Asset', params: { id: this.asset.id, asset: this.asset } }
      } else if (['course'].includes(this.asset.format.name.toLowerCase())) {
        return { name: 'Course', params: { id: this.asset.id, pgrTrk: true } }
      } else if (['track'].includes(this.asset.format.name.toLowerCase())) {
        return { name: 'Collection', params: { id: this.asset.guid, pgrTrk: true } }
      } else {
        return { name: 'Credential', params: { id: this.asset.id, pgrTrk: true } }
      }
    },
    onScroll() {
      this.scroll = this.scroll + 1
    },
    async onPrepareGAPayload(action) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        asset: {
          id: this.asset.id,
          name: this.asset.name,
          author: this.asset.author,
          technology: this.asset.applications,
          type: this.asset.inventoryType
        }
      }
      const payload = {
        action: action,
        category: 'Asset Detail',
        value: JSON.stringify(item),
        csvalue: item
      }
      return payload
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss">
p {
  a {
    color: var(--ion-color-tertiary);
    font-size: 1rem;
    font-family: 'Futura PT Book';
    text-decoration: none;
  }
}

.term {
  p {
    font-family: 'Arial';
    color: var(--ion-color-medium);
    span {
      top: 0;
      font-family: 'Arial';
    }
    a {
      font-family: 'Arial';
    }
  }

  p span {
    top: 0;
    color: var(--ion-color-medium);
  }
}

.star-rating {
  span {
    margin-right: 5px !important;
  }
}
</style>

<style lang="scss" scoped>
.asset-container-desktop {
  padding: 2rem 3.8rem;
  max-width: 1700px;
}

.asset-container-mobile {
  padding: 1.5rem 1rem;
}

.non-white-background {
  background: #efefef;
}

.author-banner {
  padding: 2rem 0rem;
  background: #4c5c8d;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Arial';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  width: calc(100% + 32px);
  margin: 1.25rem -1rem 1.5rem;
}

.icon-desktop {
  margin-right: 0.2rem;
}
.icon-mobile {
  margin-right: 0.8rem;
  display: flex;
  justify-content: center;
}

.asset-image-container {
  position: relative;
  max-width: 165px;
  border: 4px solid var(--ion-color-white);
  overflow: hidden;
  width: 165px;
  height: 165px;
  border: 5px solid var(--ion-color-white);
  background-size: contain;
  border-radius: 20px;
  img {
    width: 165px;
    height: 165px;
    object-fit: cover;
  }
}

.asset-info {
  display: flex;
  gap: 60px;
}

.asset-rating {
  display: flex;
  align-items: center;
  color: #2d2d2d;
  font-size: 14px;
}
.star-rating {
  position: relative;
  font-family: Arial;
  margin: 0 8px;
  color: #000000;
  font-size: 14px;
  cursor: pointer;
}
.rating-count {
  font-family: Arial;
  color: #2d2d2d;
  font-size: 14px;
  cursor: pointer;
}
.display-rating-mobile {
  position: relative;
  top: -2px;
  margin-right: 5px;
  width: 0.8rem;
  height: 0.8rem;
}
.rating {
  font-family: Arial;
  cursor: pointer;
}

.category {
  display: flex;
  gap: 8px;
  text-transform: capitalize;
  .format {
    padding: 4px 8px;
    font-family: 'Arial';
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    background: #e0e1e1;
  }
  .provider {
    font-family: 'Arial';
    font-size: 14px;
    font-weight: bold;
    align-self: center;
  }
}

.meta {
  display: flex;
  gap: 0.4rem;
  color: var(--ion-color-black);
  font-size: 0.8rem;
  letter-spacing: 1.2px;

  .asset-bookmark,
  .social-share,
  .more-action {
    width: 40px;
    height: 40px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon {
      width: 1.5rem;
    }
  }

  .bookmark-container,
  .more-container,
  .share-container {
    position: relative;
    .bookmark-tooltip-text,
    .more-tooltip-text,
    .share-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      right: 0;
    }
  }
  .bookmark-container:hover .bookmark-tooltip-text {
    visibility: visible;
  }
  .share-container:hover .share-tooltip-text {
    visibility: visible;
  }
  .more-container:hover .more-tooltip-text {
    visibility: visible;
  }

  ion-button {
    margin-right: 1rem !important;
    position: relative !important;
    top: -4px !important;
    font-family: 'Futura PT Demi' !important;
    height: 40px !important;
    --box-shadow: none;
    opacity: 1;
    text-align: left;
    font-size: 1rem;
  }
}

.title {
  margin: 1.5rem 0rem;
  text-align: left;
  font-size: 3rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-dark-blue);
}

.btn-icon-tech {
  position: relative;
  margin-right: 0.2rem;
  width: 16px;
  height: 16px;
}

.asset-get-btn {
  margin-left: 5px !important;
  width: 15px !important;
  height: 15px !important;
}

.term {
  text-align: left;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.back-btn-container {
  text-align: left;
}

.back {
  margin-bottom: 2rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: 'Arial';
  color: var(--ion-color-medium);
  padding: 0;

  svg {
    position: relative;
    top: 5px;
  }
  @media screen and (max-width: 830px) {
    font-size: 0.75rem;
  }
}

.social-share-popover,
.more-popover {
  position: absolute;
  box-shadow: 0px 0px 12px #0000001f;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px;
  z-index: 10;
  opacity: 0;
  margin-top: 10px;
  animation-name: slideinmore;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  text-align: left;
}

@keyframes slideinmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -217px;
  }
}

@media screen and (max-width: 1366px) {
  .asset-image-container {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    position: relative;
  }
  .title {
    font-size: 34px;
  }
}

@media screen and (max-width: 484px) {
  .title {
    margin: 20px 0;
  }
  .star-rating {
    display: none !important;
  }
  .rating {
    margin-right: 5px;
  }
}
</style>
