import axios from 'axios'

export default {
  async getCloudEnvByEmail(url) {
    const headers = { 'Content-Type': 'application/json;charset=UTF-8' }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  }
}