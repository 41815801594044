import { render, staticRenderFns } from "./AssetMediaModal.vue?vue&type=template&id=35e31239&scoped=true&"
import script from "./AssetMediaModal.vue?vue&type=script&lang=js&"
export * from "./AssetMediaModal.vue?vue&type=script&lang=js&"
import style0 from "./AssetMediaModal.vue?vue&type=style&index=0&id=35e31239&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e31239",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/vtrinh004/Desktop/YTC/web/proedge-learn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35e31239')) {
      api.createRecord('35e31239', component.options)
    } else {
      api.reload('35e31239', component.options)
    }
    module.hot.accept("./AssetMediaModal.vue?vue&type=template&id=35e31239&scoped=true&", function () {
      api.rerender('35e31239', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/asset/components/AssetMediaModal.vue"
export default component.exports