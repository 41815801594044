export default [
  {
    path: '/workspace/:id',
    name: 'Workspace',
    component: () => import('@/views/workspace/Workspace'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: true,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
]