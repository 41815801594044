var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile main-content",
      class: _vm.isDesktop ? "profile-desktop" : ""
    },
    [
      _c("ProfileSideNav", {
        attrs: { profileDetails: _vm.profile, selectedTab: _vm.selectedTab },
        on: {
          setSelectedTab: function($event) {
            return _vm.setSelectedTab($event)
          }
        }
      }),
      _c(
        "div",
        {
          class: _vm.isDesktop
            ? "profile-content"
            : "profile-content profile-content-tab"
        },
        [
          _vm.isDesktop
            ? _c("div", { staticClass: "profile-tab-header" }, [
                _c("h2", [_vm._v(_vm._s(_vm.tabHeaderTitle[_vm.selectedTab]))])
              ])
            : _vm._e(),
          _vm.selectedTab == "skills" ? _c("ProfileSkills") : _vm._e(),
          _vm.selectedTab == "profileDetails"
            ? _c("ProfileDetails", { attrs: { profileDetails: _vm.profile } })
            : _vm._e(),
          _c(
            "KeepAlive",
            [
              _vm.selectedTab == "learningHistory" && _vm.showLearningHistory
                ? _c("LearningHistory")
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }