var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ion-item",
    [
      _c(
        "ion-avatar",
        { attrs: { slot: "start" }, slot: "start" },
        [_c("ion-skeleton-text", { attrs: { animated: "" } })],
        1
      ),
      _c("ion-label", [
        _c(
          "h3",
          [
            _c("ion-skeleton-text", {
              staticStyle: { width: "90%" },
              attrs: { animated: "" }
            })
          ],
          1
        ),
        _c(
          "p",
          [
            _c("ion-skeleton-text", {
              staticStyle: { width: "80%" },
              attrs: { animated: "" }
            })
          ],
          1
        ),
        _c(
          "p",
          [
            _c("ion-skeleton-text", {
              staticStyle: { width: "70%" },
              attrs: { animated: "" }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }