var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "asset-list-content" }, [
      _vm.isDesktopPlatform
        ? _c("div", { staticClass: "asset-list-table" }, [
            _c("div", { staticClass: "asset-list-table-header" }, [
              _c(
                "h5",
                {
                  staticClass: "asset-name-header",
                  on: {
                    click: function($event) {
                      return _vm.sortAssetList("name")
                    }
                  }
                },
                [
                  _c("span", [_vm._v("Asset")]),
                  _c("span", { staticClass: "sort-container" }, [
                    _c(
                      "button",
                      { attrs: { disabled: _vm.nameSort == "2" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-up-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { attrs: { disabled: _vm.nameSort == "1" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-down-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "h5",
                {
                  staticClass: "asset-date-header",
                  on: {
                    click: function($event) {
                      return _vm.sortAssetList("createdAt")
                    }
                  }
                },
                [
                  _c("span", [_vm._v("Date created")]),
                  _c("span", { staticClass: "sort-container" }, [
                    _c(
                      "button",
                      { attrs: { disabled: _vm.createdAtSort == "2" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-up-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { attrs: { disabled: _vm.createdAtSort == "1" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-down-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "h5",
                {
                  staticClass: "asset-date-header",
                  on: {
                    click: function($event) {
                      return _vm.sortAssetList("updatedAt")
                    }
                  }
                },
                [
                  _c("span", [_vm._v("Last updated")]),
                  _c("span", { staticClass: "sort-container" }, [
                    _c(
                      "button",
                      { attrs: { disabled: _vm.updatedAtSort == "2" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-up-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { attrs: { disabled: _vm.updatedAtSort == "1" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-down-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "h5",
                {
                  staticClass: "asset-status-header",
                  on: {
                    click: function($event) {
                      return _vm.sortAssetList("reviewStatus")
                    }
                  }
                },
                [
                  _c("span", [_vm._v("Status")]),
                  _c("span", { staticClass: "sort-container" }, [
                    _c(
                      "button",
                      { attrs: { disabled: _vm.reviewStatusSort == "2" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-up-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { attrs: { disabled: _vm.reviewStatusSort == "1" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-down-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "asset-list-table-content" },
              _vm._l(_vm.filterItems, function(asset) {
                return _c(
                  "div",
                  {
                    key: asset.id,
                    staticClass: "asset-list-table-row",
                    on: {
                      click: function($event) {
                        return _vm.onRowClick(asset.id)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "table-item asset-name" },
                      [
                        _c(
                          "p",
                          { staticStyle: { "margin-bottom": "0.5rem" } },
                          [
                            _vm._v(
                              _vm._s(
                                asset.name === "undefined"
                                  ? "Undefined"
                                  : asset.name
                              )
                            )
                          ]
                        ),
                        _c("span", { staticClass: "asset-displayId" }, [
                          _vm._v(_vm._s(asset.displayId) + " |")
                        ]),
                        _vm._l(asset.applications, function(technology, index) {
                          return [
                            _c(
                              "span",
                              {
                                key: technology,
                                staticClass: "asset-technology"
                              },
                              [
                                index > 0 && index != asset.applications.length
                                  ? [_vm._v(" | ")]
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(technology))
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    ),
                    _c("div", { staticClass: "table-item asset-date" }, [
                      _vm._v(_vm._s(_vm._f("formatDate")(asset.createdAt)))
                    ]),
                    _c("div", { staticClass: "table-item asset-date" }, [
                      _vm._v(_vm._s(_vm._f("fileFormatDate")(asset.updatedAt)))
                    ]),
                    _c("div", { staticClass: "table-item asset-status" }, [
                      _vm._v(_vm._s(asset.reviewStatus))
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      !_vm.isDesktopPlatform
        ? _c("div", { staticClass: "asset-list-table" }, [
            _c("div", { staticClass: "asset-list-table-header" }, [
              _c(
                "h5",
                {
                  staticClass: "asset-name-header",
                  on: {
                    click: function($event) {
                      return _vm.sortAssetList("name")
                    }
                  }
                },
                [
                  _vm._v(" Asset "),
                  _c("span", { staticClass: "sort-container" }, [
                    _c(
                      "button",
                      { attrs: { disabled: _vm.nameSort == "2" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-up-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { attrs: { disabled: _vm.nameSort == "1" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-down-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "h5",
                {
                  on: {
                    click: function($event) {
                      return _vm.sortAssetList("reviewStatus")
                    }
                  }
                },
                [
                  _vm._v(" Status "),
                  _c("span", { staticClass: "sort-container" }, [
                    _c(
                      "button",
                      { attrs: { disabled: _vm.reviewStatusSort == "2" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-up-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      { attrs: { disabled: _vm.reviewStatusSort == "1" } },
                      [
                        _c("ion-icon", {
                          attrs: { slot: "start", name: "caret-down-outline" },
                          slot: "start"
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "asset-list-table-content" },
              _vm._l(_vm.filterItems, function(asset) {
                return _c(
                  "div",
                  {
                    key: asset.id,
                    staticClass: "asset-list-table-row",
                    on: {
                      click: function($event) {
                        return _vm.onRowClick(asset.id)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "table-item asset-name" }, [
                      _c("p", [_vm._v(_vm._s(asset.name))]),
                      _c(
                        "div",
                        { staticClass: "technology-content asset-name-margin" },
                        [
                          _c("span", { staticClass: "asset-displayId" }, [
                            _vm._v(_vm._s(asset.displayId) + " |")
                          ]),
                          _vm._l(asset.applications, function(
                            technology,
                            index
                          ) {
                            return [
                              _c(
                                "span",
                                {
                                  key: technology,
                                  staticClass: "asset-technology"
                                },
                                [
                                  index > 0 &&
                                  index != asset.applications.length
                                    ? [_vm._v(" | ")]
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(technology))
                                ],
                                2
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _c("label", { staticClass: "last-updated" }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("Last updated")
                        ]),
                        _vm._v(
                          _vm._s(_vm._f("fileFormatDate")(asset.updatedAt))
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "table-item asset-status" }, [
                      _vm._v(_vm._s(asset.reviewStatus))
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "search-result-pagination" },
        [
          _vm.assetList.length
            ? _c("paginate", {
                attrs: {
                  "page-count": _vm.pagination.pageCount,
                  "page-range": _vm.pagination.pageRange,
                  "margin-pages": 1,
                  "click-handler": _vm.handlePageClick,
                  "container-class": "pagination",
                  "page-class": "pagination-item",
                  "page-link-class": "pagination-item-link",
                  "prev-text": "&lsaquo;",
                  "prev-class": "pagination-nav",
                  "prev-link-class": "pagination-nav-link",
                  "next-text": "&rsaquo;",
                  "next-class": "pagination-nav",
                  "next-link-class": "pagination-nav-link",
                  "hide-prev-next": true
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            : _vm._e()
        ],
        1
      )
    ]),
    this.$platform !== "Desktop"
      ? _c(
          "div",
          { staticClass: "notification-message" },
          [
            _c("svg-icon", {
              staticClass: "g-icon--xsmall svg-container",
              attrs: { "icon-class": "mini-icon" }
            }),
            _c("h2", [
              _vm._v(
                "To submit a new asset, log in to ProEdge on a desktop device."
              )
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }