var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "submission-details-container main-content" },
    [
      _vm.showDownloadProgress
        ? _c("div", { staticClass: "custom-toast custom-toast-loading" }, [
            _c(
              "div",
              { staticStyle: { "margin-top": "2rem" } },
              [
                _c("ion-progress-bar", {
                  attrs: { color: "secondary", type: "indeterminate" }
                })
              ],
              1
            ),
            _vm._m(0),
            _c("div", { staticClass: "toast-footer" })
          ])
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "back",
          on: {
            click: function($event) {
              return _vm.$router.push("/submit-asset")
            }
          }
        },
        [
          _c("svg-icon", {
            staticClass: "g-icon--small",
            attrs: { "icon-class": "icon-arrow-left" }
          }),
          _vm._v(" Back ")
        ],
        1
      ),
      _vm.dataReady
        ? _c(
            "div",
            {
              class: _vm.isDesktopPlatform
                ? "submission-details-content"
                : "submission-details-content-mobile"
            },
            [
              _vm.submittedAsset.reviewStatus
                ? _c("div", { staticClass: "submission-details-status" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          [
                            _vm.reviewStatus.inDraft.value,
                            _vm.reviewStatus.inEdit.value
                          ].includes(
                            _vm.submittedAsset.reviewStatus.toUpperCase()
                          )
                            ? _vm.reviewStatus.inDraft.message
                            : _vm.submittedAsset.reviewStatus
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "submission-details" },
                [
                  _c("div", { staticClass: "icon-container" }, [
                    _vm.isEditMode && _vm.isDesktopPlatform
                      ? _c(
                          "div",
                          { staticClass: "icon-tooltip" },
                          [
                            _c("svg-icon", {
                              staticClass: "action-icon",
                              attrs: {
                                "icon-class": "edit-icon",
                                alt: "edit-image"
                              },
                              on: { click: _vm.onEditClick }
                            }),
                            _c("span", { staticClass: "icon-tooltiptext" }, [
                              _vm._v("Edit")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isSubmitMode && _vm.isDesktopPlatform
                      ? _c(
                          "div",
                          { staticClass: "icon-tooltip" },
                          [
                            _c("svg-icon", {
                              staticClass: "action-icon",
                              attrs: {
                                "icon-class": "submit-icon",
                                alt: "submit-image"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openModal(
                                    _vm.submitConfirmationModal
                                  )
                                }
                              }
                            }),
                            _c("span", { staticClass: "icon-tooltiptext" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.submittedAsset.reviewStatus.toUpperCase() ===
                                    "RETURNED"
                                    ? "Resubmit"
                                    : "Submit"
                                )
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isDeleteMode
                      ? _c(
                          "div",
                          { staticClass: "icon-tooltip" },
                          [
                            _c("svg-icon", {
                              staticClass: "action-icon",
                              attrs: {
                                "icon-class": "delete-icon",
                                alt: "delete-image"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openModal(
                                    _vm.deleteConfirmationModal
                                  )
                                }
                              }
                            }),
                            _c(
                              "span",
                              {
                                class: _vm.isDesktopPlatform
                                  ? "icon-tooltiptext"
                                  : "icon-tooltiptext tooltip-visibility"
                              },
                              [_vm._v("Delete")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "ion-grid",
                    [
                      _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            {
                              staticClass:
                                "ion-no-padding column-container header"
                            },
                            [
                              _c("div", { staticClass: "format" }, [
                                _vm._v("ASSET PREVIEW")
                              ]),
                              _c("div", { staticClass: "provider" }, [
                                _vm._v(
                                  "ID " +
                                    _vm._s(_vm.submittedAsset.displayId) +
                                    " "
                                ),
                                _c("span", { staticClass: "divider" }, [
                                  _vm._v("|")
                                ]),
                                _vm._v(
                                  " LAST SAVED " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.submittedAsset.updatedAt
                                      )
                                    )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "ion-row",
                        { staticClass: "submission-details-line" },
                        [
                          ["RETURNED", "APPROVED", "REJECTED"].includes(
                            _vm.submittedAsset.reviewStatus.toUpperCase()
                          ) && _vm.submittedAsset.isAdminEdited
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "show-mandatory-message",
                                      class: _vm.isDesktopPlatform
                                        ? "show-mandatory-message-desktop"
                                        : ""
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "show-icons" },
                                        [
                                          _c("svg-icon", {
                                            staticClass:
                                              "alert-icon-admin-edit",
                                            attrs: {
                                              "icon-class": "alert-icon"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "show-message" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " Your admin made edits to your submission and provided the following comment. "
                                            ),
                                            _vm.submittedAsset.activities
                                              .length > 0 &&
                                            _vm.submittedAsset.activities[0][
                                              "text"
                                            ] != ""
                                              ? _c("span", [
                                                  _vm._v(
                                                    '"' +
                                                      _vm._s(
                                                        _vm.submittedAsset
                                                          .activities[0]["text"]
                                                      ) +
                                                      '" '
                                                  )
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "view-all-link",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.checkScroll()
                                                  }
                                                }
                                              },
                                              [_vm._v("View all comments")]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          !_vm.requiredFieldCheck
                            ? _c(
                                "ion-col",
                                {
                                  staticClass:
                                    "warning-container alert-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end"
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "g-icon--small",
                                        attrs: {
                                          "icon-class": "alert-icon",
                                          alt: "alert-image"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "warning-text" }, [
                                    _vm._v(
                                      "Some inputs required for submission are still missing. Click the edit icon to provide additional details or make changes."
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.name
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end"
                                    },
                                    [_vm._v("name")]
                                  ),
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.submittedAsset.name === "undefined"
                                          ? "Undefined"
                                          : _vm.submittedAsset.name
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.externalAssetLink &&
                          ["link", "both"].includes(_vm.canUploadAssets)
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end ion-margin-bottom"
                                    },
                                    [_vm._v("Asset Link")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "asset-link asset-link-width",
                                      on: { copy: _vm.copyExternalAssetLink }
                                    },
                                    [
                                      _c("span", { staticClass: "link-url" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.submittedAsset.externalAssetLink
                                          )
                                        )
                                      ]),
                                      _c("svg-icon", {
                                        staticClass:
                                          "g-icon--xsmall svg-container",
                                        attrs: { "icon-class": "expand-out" },
                                        on: { click: _vm.onClickAssetLink }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.assetReference &&
                          ["zip", "both"].includes(_vm.canUploadAssets)
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end ion-margin-bottom",
                                      class: _vm.isDesktopPlatform
                                        ? ""
                                        : "asset-file-container"
                                    },
                                    [_vm._v("ASSET FILE")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "asset-link-width" },
                                    [
                                      !this.isDesktopPlatform
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "show-mandatory-message download-alert-container"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "download-alert-icon"
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    staticClass:
                                                      "g-icon--small",
                                                    attrs: {
                                                      "icon-class": "alert-icon"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "download-alert"
                                                },
                                                [
                                                  _vm._v(
                                                    "Download available only on desktop."
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "asset-link" },
                                        [
                                          _c("svg-icon", {
                                            staticClass:
                                              "g-icon--small svg-container svg-margin-right",
                                            attrs: {
                                              "icon-class": "icons-file-zip"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "link-url" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.submittedAsset
                                                    .assetReference.name
                                                )
                                              )
                                            ]
                                          ),
                                          _vm.isDesktopPlatform
                                            ? _c(
                                                "span",
                                                { staticClass: "file-size" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("formatSize")(
                                                        _vm.submittedAsset
                                                          .assetReference.size
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isDesktopPlatform
                                            ? _c("svg-icon", {
                                                staticClass:
                                                  "g-icon--small ion-margin-start svg-container",
                                                attrs: {
                                                  "icon-class": "download-icon"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadAssetZipFile()
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.promoHeader
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end"
                                    },
                                    [_vm._v("header")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ion-padding-bottom title header-title"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.submittedAsset.promoHeader)
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.promoDescription
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end html-view-container"
                                    },
                                    [_vm._v("description")]
                                  ),
                                  _c("div", { staticClass: "term" }, [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.submittedAsset.promoDescription.split(
                                          "\n"
                                        ),
                                        function(val, index) {
                                          return _c("div", { key: index }, [
                                            val
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "ion-margin-bottom"
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "desc-href-val",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$options.filters.formattText(
                                                            val
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.fullDescription
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end html-view-container"
                                    },
                                    [_vm._v("How it works")]
                                  ),
                                  _c("div", { staticClass: "term" }, [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.submittedAsset.fullDescription.split(
                                          "\n"
                                        ),
                                        function(val, index) {
                                          return _c("div", { key: index }, [
                                            val
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "ion-margin-bottom"
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "desc-href-val",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$options.filters.formattText(
                                                            val
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.galleryImages &&
                          _vm.submittedAsset.galleryImages.length > 0
                            ? _c(
                                "ion-col",
                                {
                                  staticClass:
                                    "column-container alert-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end"
                                    },
                                    [_vm._v("screenshots")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row-container img-container"
                                    },
                                    [
                                      _c(
                                        "CarouselDesktop",
                                        {
                                          staticClass: "media-carousel",
                                          attrs: {
                                            slidesShow: 1,
                                            responsive: false
                                          }
                                        },
                                        _vm._l(
                                          _vm.submittedAsset.galleryImages,
                                          function(images, index) {
                                            return _c("div", { key: index }, [
                                              _c(
                                                "div",
                                                { staticClass: "img-cont" },
                                                [
                                                  _c("img", {
                                                    key: index,
                                                    attrs: {
                                                      src: images.signedUrl,
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "caption-container"
                                                },
                                                [_vm._v(_vm._s(images.caption))]
                                              )
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          (_vm.submittedAsset.applicationsVM &&
                            _vm.submittedAsset.applicationsVM.length) ||
                          _vm.submittedAsset.inventoryType ||
                          (_vm.submittedAsset.functions &&
                            _vm.submittedAsset.functions.length)
                            ? _c(
                                "ion-col",
                                {
                                  staticClass:
                                    "column-container ion-padding-top",
                                  attrs: { "size-xs": "12", "size-md": "12" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end"
                                    },
                                    [_vm._v("about")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "row-container" },
                                    [
                                      _vm.submittedAsset.inventoryType
                                        ? _c(
                                            "ion-row",
                                            {
                                              staticClass:
                                                "asset-info-detail ion-padding-top"
                                            },
                                            [
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass:
                                                    "asset-info-title"
                                                },
                                                [_vm._v("Asset Type")]
                                              ),
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass:
                                                    "asset-info-value"
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "application-name-container no-margin-top"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.submittedAsset
                                                              .inventoryType
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.submittedAsset.applicationsVM !=
                                        null &&
                                      _vm.submittedAsset.applicationsVM.length >
                                        0
                                        ? _c(
                                            "ion-row",
                                            {
                                              staticClass: "asset-info-detail"
                                            },
                                            [
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass:
                                                    "asset-info-title"
                                                },
                                                [_vm._v("Required technology")]
                                              ),
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass:
                                                    "asset-info-value"
                                                },
                                                _vm._l(
                                                  _vm.submittedAsset
                                                    .applicationsVM,
                                                  function(application) {
                                                    return _c(
                                                      "div",
                                                      { key: application.id },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              application.image
                                                          }
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "application-name-container"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                application.name
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.submittedAsset.functions != null &&
                                      _vm.submittedAsset.functions.length > 0
                                        ? _c(
                                            "ion-row",
                                            {
                                              staticClass: "asset-info-detail"
                                            },
                                            [
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass:
                                                    "asset-info-title"
                                                },
                                                [_vm._v("Function")]
                                              ),
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass:
                                                    "asset-info-value"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "application-name-container"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.submittedAsset
                                                            .functions
                                                            .length ===
                                                            this.functionsList
                                                              .length
                                                            ? "All functions"
                                                            : _vm.submittedAsset.functions.join(
                                                                ", "
                                                              )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.supportingFile
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end ion-margin-bottom"
                                    },
                                    [_vm._v("REFERENCE")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "asset-link asset-link-width"
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "g-icon--small svg-container svg-margin-right",
                                        attrs: { "icon-class": "file-pdf" }
                                      }),
                                      _c("span", { staticClass: "link-url" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.submittedAsset.supportingFile
                                              .name
                                          )
                                        )
                                      ]),
                                      _c("span", { staticClass: "file-size" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatSize")(
                                              _vm.submittedAsset.supportingFile
                                                .size
                                            )
                                          )
                                        )
                                      ]),
                                      _c("svg-icon", {
                                        staticClass:
                                          "g-icon--small ion-margin-start svg-container",
                                        attrs: {
                                          "icon-class": "download-icon"
                                        },
                                        on: { click: _vm.onDownload }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.submittedAsset.comment &&
                          [
                            _vm.reviewStatus.inDraft.value,
                            _vm.reviewStatus.inEdit.value
                          ].includes(
                            _vm.submittedAsset.reviewStatus.toUpperCase()
                          )
                            ? _c(
                                "ion-col",
                                {
                                  staticClass: "column-container",
                                  attrs: {
                                    "size-xl": "12",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "label-container ion-margin-end html-view-container"
                                    },
                                    [_vm._v("comments")]
                                  ),
                                  _c("div", { staticClass: "comment-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.submittedAsset.comment.replace(
                                          "\n",
                                          " "
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "ion-col",
                            {
                              staticClass: "column-container",
                              attrs: {
                                "size-xl": "12",
                                "size-lg": "12",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "label-container ion-margin-end ion-margin-bottom"
                              }),
                              _c(
                                "div",
                                [
                                  _vm.isEditMode && _vm.isDesktopPlatform
                                    ? _c(
                                        "ion-button",
                                        {
                                          staticClass:
                                            "ion-text-capitalize preview-btn",
                                          attrs: {
                                            color: "warning",
                                            shape: "round"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onEditClick()
                                            }
                                          }
                                        },
                                        [_vm._v("Edit")]
                                      )
                                    : _vm._e(),
                                  _vm.isSubmitMode && _vm.isDesktopPlatform
                                    ? _c(
                                        "ion-button",
                                        {
                                          staticClass:
                                            "ion-text-capitalize preview-btn",
                                          staticStyle: {
                                            font:
                                              "normal normal normal 16px/22px Futura PT Demi",
                                            height: "44px"
                                          },
                                          attrs: {
                                            color: "tertiary",
                                            shape: "round"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openModal(
                                                _vm.submitConfirmationModal
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.submittedAsset.reviewStatus.toUpperCase() ===
                                                "RETURNED"
                                                ? "Resubmit"
                                                : "Submit"
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.dataReady && _vm.submittedAsset.commentActivities
        ? _c(
            "div",
            {
              class: _vm.isDesktopPlatform
                ? "submission-details-content"
                : "submission-details-content-mobile"
            },
            [
              _c(
                "div",
                { ref: "commentsActivity", staticClass: "comments-activity" },
                [
                  _c("h2", [_vm._v("Comments & activity")]),
                  _vm._l(_vm.submittedAsset.commentActivities, function(
                    activity,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: index, staticClass: "comment-container" },
                      [
                        _c("div", { staticClass: "short-name-container" }, [
                          _vm._v(_vm._s(activity.shortName))
                        ]),
                        _c(
                          "ion-row",
                          { staticClass: "ion-margin-start ion-margin-bottom" },
                          [
                            _c(
                              "ion-col",
                              {
                                staticClass: "ion-no-padding",
                                attrs: {
                                  "size-xl": "12",
                                  "size-lg": "12",
                                  "size-md": "12",
                                  "size-sm": "12",
                                  "size-xs": "12"
                                }
                              },
                              [
                                activity.nameWithAction
                                  ? _c(
                                      "div",
                                      { staticClass: "activity-column" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "span-container" },
                                          [
                                            _vm._v(
                                              _vm._s(activity.nameWithAction)
                                            )
                                          ]
                                        ),
                                        activity.createdAt
                                          ? _c(
                                              "div",
                                              { staticClass: "date-container" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatLatestDate")(
                                                      activity.createdAt
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                activity.userName
                                  ? _c(
                                      "div",
                                      { staticClass: "activity-column" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "span-container" },
                                          [_vm._v(_vm._s(activity.userName))]
                                        ),
                                        activity.activityType
                                          ? _c(
                                              "span",
                                              { staticClass: "span-container" },
                                              [
                                                _vm._v(
                                                  _vm._s(activity.activityType)
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        activity.createdAt
                                          ? _c(
                                              "div",
                                              { staticClass: "date-container" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatLatestDate")(
                                                      activity.createdAt
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "ion-col",
                              {
                                staticClass: "ion-no-padding comment",
                                attrs: {
                                  "size-xl": "10",
                                  "size-lg": "10",
                                  "size-md": "8",
                                  "size-sm": "10",
                                  "size-xs": "10"
                                }
                              },
                              [_vm._v(_vm._s(activity.comment))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.showConfirmationModal
        ? _c("AssetConfirmationModal", {
            attrs: { modalProperties: _vm.modalContent },
            on: {
              closeConfirmationModal: function($event) {
                return _vm.onCloseModal()
              },
              submitAsset: function($event) {
                return _vm.onSubmit($event)
              },
              onDeleteClick: function($event) {
                return _vm.onDeleteClick($event)
              }
            }
          })
        : _vm._e(),
      _vm.showAlertsModal
        ? _c("AssetAlertModal", {
            attrs: { modalProperties: _vm.submitSuccessMsg },
            on: { closeAlertsModal: _vm.closeSuccessModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "status-message-normal" }, [
        _vm._v("Download is in progress")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }