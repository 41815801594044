var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-createAccount" },
    [
      _vm.$platform == "Mobile"
        ? _c(
            "button",
            { staticClass: "back_arrow" },
            [
              _c("svg-icon", {
                staticStyle: {
                  width: "20px !important",
                  height: "20px !important"
                },
                attrs: {
                  "icon-class": "thin-back-arrow",
                  "class-name": "g-icon--small"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { ref: "top", staticClass: "edge-createAccount__svg-wrapper" },
        [
          _c("svg-icon", {
            class: this.$platform === "Desktop" ? "on-desktop" : "",
            attrs: { "icon-class": "small-logo", "class-name": "logo--size" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edge-createAccount__input" },
        [
          _c(
            "ion-item",
            {
              staticClass: "ion-no-padding input-section",
              attrs: { mode: "md" }
            },
            [
              _c("ion-input", {
                ref: "username",
                attrs: {
                  "data-cy": "learn-desktop-signup-email",
                  placeholder: "Enter your work email address",
                  autofocus: "",
                  disabled: _vm.isInputDisabled
                },
                on: {
                  ionChange: function($event) {
                    $event.target.value = $event.target.value
                      ? $event.target.value.trim()
                      : ""
                    _vm.user.email = $event.target.value
                    _vm.user.username = $event.target.value
                    _vm.checkSSOEmail()
                  },
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.next()
                  }
                }
              })
            ],
            1
          ),
          _vm.showPasswordField
            ? _c(
                "ion-item",
                {
                  staticClass: "ion-no-padding input-section",
                  attrs: { mode: "md" }
                },
                [
                  _c("ion-input", {
                    attrs: {
                      "data-cy": "learn-desktop-signup-password",
                      type: _vm.showPwd ? "text" : "password",
                      placeholder: "Create a password",
                      disabled: _vm.isInputDisabled
                    },
                    on: {
                      ionChange: function($event) {
                        _vm.user.password = $event.target.value
                        _vm.onPasswordChange()
                      },
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.next()
                      },
                      ionBlur: _vm.verifyPassword
                    }
                  }),
                  _c("ion-icon", {
                    staticStyle: { color: "#00a78d" },
                    attrs: {
                      slot: "end",
                      name: _vm.showPwd ? "eye" : "eye-off"
                    },
                    on: {
                      click: function() {
                        return (_vm.showPwd = !_vm.showPwd)
                      }
                    },
                    slot: "end"
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.showPasswordField && _vm.errorMessage == ""
        ? _c(
            "div",
            { staticClass: "edge-createAccount__placeholder-message" },
            [_vm._v(_vm._s(_vm.placeholderMessage))]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "edge-createAccount__error-message",
          attrs: { "aria-label": "error message" }
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c(
        "div",
        { staticClass: "privacy-terms-container" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.has_accepted_terms,
                expression: "user.has_accepted_terms"
              }
            ],
            staticClass: "privacy-terms-checkbox ion-margin-end",
            attrs: { type: "checkbox", id: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.user.has_accepted_terms)
                ? _vm._i(_vm.user.has_accepted_terms, null) > -1
                : _vm.user.has_accepted_terms
            },
            on: {
              keypress: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.next()
              },
              change: function($event) {
                var $$a = _vm.user.has_accepted_terms,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.user,
                        "has_accepted_terms",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.user,
                        "has_accepted_terms",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.user, "has_accepted_terms", $$c)
                }
              }
            }
          }),
          _c(
            "ion-label",
            { staticClass: "privacy-terms" },
            [
              _vm._v(" I agree to the "),
              _c(
                "router-link",
                {
                  staticClass: "terms",
                  attrs: { to: "terms-and-conditions" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.openTerms($event)
                    }
                  }
                },
                [_vm._v("Terms and Conditions")]
              ),
              _vm._v(" and acknowledge the "),
              _c(
                "router-link",
                {
                  staticClass: "privacy",
                  attrs: { to: "privacy-policy" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.openPrivacy($event)
                    }
                  }
                },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v(" and "),
              _c(
                "router-link",
                {
                  staticClass: "cookie-notice",
                  attrs: { to: "cookie-notice" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.openCookieNotice($event)
                    }
                  }
                },
                [_vm._v("Cookie Notice")]
              )
            ],
            1
          ),
          _c(
            "ion-label",
            { staticClass: "privacy-terms-mobile" },
            [
              _vm._v(" I agree to the "),
              _c(
                "router-link",
                {
                  staticClass: "terms",
                  attrs: { to: "terms-and-conditions" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.openTerms($event)
                    }
                  }
                },
                [_vm._v(" Terms and Conditions")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" and acknowledge the "),
              _c(
                "router-link",
                {
                  staticClass: "privacy",
                  attrs: { to: "privacy-policy" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.openPrivacy($event)
                    }
                  }
                },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" and "),
              _c(
                "router-link",
                {
                  staticClass: "cookie-notice",
                  attrs: { to: "cookie-notice" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.openCookieNotice($event)
                    }
                  }
                },
                [_vm._v("Cookie Notice")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.appBanner && !_vm.isNativeApp && _vm.$platform == "Mobile"
        ? _c(
            "div",
            { staticClass: "banner" },
            [
              _c(
                "ion-grid",
                { staticClass: "ion-no-padding" },
                [
                  _c(
                    "ion-row",
                    { staticClass: "ion-no-padding" },
                    [
                      _c(
                        "ion-col",
                        [
                          _c("svg-icon", {
                            staticClass: "g-icon--small close g-clickable",
                            staticStyle: { position: "absolute", right: "0" },
                            attrs: { "icon-class": "close" },
                            on: { click: _vm.onCancel }
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "ion-no-margin",
                              staticStyle: { "font-size": "1.4rem" }
                            },
                            [_vm._v("ProEdge is better on the App")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "ion-row",
                    { staticStyle: { "padding-right": "13px" } },
                    [
                      _c("ion-col", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "130px",
                              "margin-left": "auto",
                              "margin-right": "0",
                              "padding-top": "9px"
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://apps.apple.com/us/app/proedge-learn/id1540658579"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/apple-store-badge.svg")
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]),
                      _c("ion-col", { staticClass: "ion-text-start" }, [
                        _c("div", { staticStyle: { width: "147px" } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://play.google.com/store/apps/details?id=com.pwc.gx.mobility.proedgelearn"
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/google-play-badge.png")
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "ion-button",
        {
          staticClass: "edge-createAccount__button ion-button-tertiary",
          attrs: {
            disabled: _vm.allowRegistration,
            "data-cy": "learn-desktop-signup-button",
            shape: "round",
            color: "tertiary"
          },
          on: {
            click: function($event) {
              return _vm.next()
            }
          }
        },
        [_vm._v("Register")]
      ),
      _vm.$platform == "Mobile"
        ? _c(
            "router-link",
            { staticClass: "lined-link", attrs: { to: "get-help" } },
            [_vm._v("Get help")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }