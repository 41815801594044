var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ion-slides",
        { attrs: { pager: "" } },
        _vm._l(_vm.carousel, function(item, index) {
          return _c("ion-slide", { key: index }, [
            _c("div", [
              item.heading
                ? _c("div", {
                    staticClass: "heading",
                    domProps: { innerHTML: _vm._s(_vm.$sanitize(item.heading)) }
                  })
                : _vm._e(),
              _c("img", { staticClass: "image", attrs: { src: item.imageUri } })
            ])
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }