<template>
  <ion-card class="skillbuilder__lesson" :class="lesson.isSelect ? 'lesson-select' : ''" @click="selectLesson()">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-align-items-center">
        <ion-col class="ion-text-center g-margin__left--medium g-margin__top--small">
          <p class="ion-no-margin g-margin__right--medium skillbuilder__lesson__subtitle ellipsis-lines-2">{{ lesson.title }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</template>

<script>
export default {
  name: 'CredentialLesson',
  props: {
    lesson: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    selectLesson() {
      this.$emit('on-select-lesson', this.lesson.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('skillbuilder') {
  @include element('lesson') {
    cursor: pointer;
    height: 60px;
    min-width: 200px;
    border-radius: 50px;
    margin-top: 0;

    @include element('title') {
      font-family: 'Futura PT Demi';
      color: var(--ion-color-black);
    }

    @include element('subtitle') {
      text-transform: capitalize;
    }

    @include modifier('circle') {
      height: 60px;
      width: 60px;
      border-radius: 50px;
      color: var(--ion-color-tertiary);
      font-size: 2.5rem;
    }
  }
}

.lesson-select {
  border: 2px solid var(--ion-color-tertiary);
}
</style>
