<template>
  <div v-if="isShown">
    <transition name="slide-menu">
      <NavBarMenuMobile
        v-if="isNavBarMenuVisible && isOpen"
        :navBarMenu="navBarMenu"
        @close="onCloseNavBarMenu()"
        @logoClick="onLogoClick()"
        :class="isNavBarMenuVisible ? 'show-nav-menu' : 'hide-nav-menu'"
      />
    </transition>
    <div class="edge-navbar">
      <div
        v-if="
          searchBar.state.on &&
          showMobileTypeahead &&
          ((autoSuggestFlagEnabled && autoSuggestList.length > 0 && currentSearchQuery != '') || (lookingForFlagEnabled && autoSuggestList.length == 0))
        "
        style="position: absolute; background-color: transparent; height: 100vh; width: 100%; opacity: 0.2"
        @click.stop="autoSuggestBackdrop()"
      ></div>
      <svg-icon icon-class="proedge-mobile-logo" @click="onLogoClick()" class-name="mobile-logo" />
      <button class="logo">
        <svg-icon icon-class="hamburger-menu-icon" @click="showNavBarMenu()" class="hamburger" />
      </button>
      <!-- searchbar for mobile -->
      <div>
        <ion-searchbar
          v-if="searchBar.state.on"
          show-cancel-button="never"
          ref="searchbar"
          @keypress.enter="handleSearchInput"
          @focusin="onSearchbarFocus()"
          @ionCancel="toggleSearchBar(false)"
          v-on:keyup="handleSuggestionInput"
          placeholder="Search using titles, topics, and keywords"
          @ionClear="handleSearchClearButton()"
        />
        <p class="searchbar-cancel-button" v-if="searchBar.state.on" @click.stop="autoSuggestBackdrop()">Cancel</p>
      </div>
      <!-- mobile typeahead component -->
      <div v-if="searchBar.state.on && showMobileTypeahead" class="mobile-typeahead">
        <ion-grid
          v-if="autoSuggestFlagEnabled && autoSuggestList.length > 0 && currentSearchQuery != ''"
          class="ion-padding mobile-typeahead-main-component"
          :key="autoSuggestList.length + 'main_typeahead'"
        >
          <div class="mobile-typeahead-results-container">
            <ion-row class="ion-margin-vertical mobile-typeahead-result-row" v-for="(item, index) in autoSuggestList.slice(0, 10)" :key="item + index">
              <!-- auto suggest for non exact match -->
              <ion-col size="1" v-if="!item.exactMatch && item.category != 'Skill'" @click="onClickTypeAhead(item)" :key="item.text + 'searchicon'">
                <svg-icon icon-class="icon-search" class="mobile-typeahead-search-icon" />
              </ion-col>
              <ion-col
                v-if="!item.exactMatch && item.category != 'Skill'"
                class="mobile-typeahead-search-result-ellipsis"
                @click="onClickTypeAhead(item)"
                :key="item.text + 'searchtext'"
              >
                <p class="mobile-typeahead-search-result" v-html="item.displayText"></p>
              </ion-col>
              <!-- auto suggest for skill -->
              <ion-col v-if="!item.exactMatch && item.category == 'Skill'" size="1" @click="onClickTypeAhead(item)" :key="item.text + 'skillicon'">
                <svg-icon icon-class="auto-suggest-skill" class="mobile-typeahead-search-icon" />
              </ion-col>
              <ion-col
                v-if="!item.exactMatch && item.category == 'Skill'"
                class="mobile-typeahead-search-result-ellipsis"
                @click="onClickTypeAhead(item)"
                :key="item.text + 'skilltext'"
                style="padding-top: 0px; padding-left: 12px"
              >
                <p class="mobile-typeahead-search-result" v-html="item.displayText"></p>
                <p class="mobile-typeahead-search-result-category">Skill</p>
              </ion-col>
              <!-- auto suggest for exact match -->
              <ion-col v-if="item.exactMatch" size="1" :key="item.text + 'image'" @click="onClickDirectMatch(item)">
                <div class="home-img-search-mobile">
                  <img :src="getImageUri(item)" />
                </div>
              </ion-col>
              <ion-col v-if="item.exactMatch" class="mobile-typeahead-search-result-ellipsis" :key="item.text + 'name'" @click="onClickDirectMatch(item)">
                <p class="mobile-typeahead-search-result mobile-typeahead-search-result-ellipsis" style="padding-left: 8px" v-html="item.displayText"></p>
                <p v-if="item.content.format.name.toLowerCase() !== 'asset'" class="mobile-typeahead-search-result-category" style="padding-left: 8px">{{ item.type }}</p>
                <p v-else class="mobile-typeahead-search-result-category" style="padding-left: 8px">{{ item.type }} | {{ item.applications.join(' | ') }}</p>
              </ion-col>
            </ion-row>
          </div>
          <ion-row>
            <ion-col class="ion-text-center">
              <p class="mobile-typeahead-see-all" @click="handleSearchSeeAll">See all results for "{{ currentSearchQuery }}"</p>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- "looking for" component -->
        <ion-grid
          class="ion-padding mobile-looking-for-component ion-text-start"
          v-if="lookingForFlagEnabled && autoSuggestList.length == 0"
          :key="autoSuggestList.length + 'looking_for'"
        >
          <ion-row>
            <ion-col>
              <p class="mobile-typeahead-header">Browse by</p>
            </ion-col>
          </ion-row>
          <ion-row v-for="filter in lookingForFilters" :key="filter" class="mobile-content-type-pill" @click.stop="applyContentTypeFilter(filter)">
            {{ filter }}
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <ion-row class="right-icons-section">
      <ion-col>
        <button v-if="searchBar.state.off && isSearchable" @click="toggleSearchBar(true)" class="search-icon">
          <svg-icon icon-class="icon-search" class-name="g-icon--small icon-search-mobile" />
        </button>
      </ion-col>
      <!--This is left in comment for future use-->
      <!-- <ion-col><NotificationsBell :isLogoVisible="tenantCustomInfo.logoUri.length > 0" /></ion-col> -->
      <ion-col v-if="tenantCustomInfo.logoUri.length" class="tenant-custom-logo ion-no-padding">
        <img :src="tenantCustomInfo.logoUri" />
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import NavBarMenuMobile from '@/components/organisms/NavBarMenuMobile'
//import NotificationsBell from '@/components/molecules/NotificationsBell'

export default {
  name: 'NavBarMobile',
  components: {
    NavBarMenuMobile
  },
  props: {
    isOpen: { type: Boolean, required: false, default: false },
    isShown: { type: Boolean, required: false, default: true },
    search: { type: Boolean, required: false, default: false },
    searchIcon: { type: Boolean, required: false, default: true }
  },
  watch: {
    $route(to) {
      if (to && to.name) {
        this.autoSuggestBackdrop()
      }
    }
  },
  data() {
    return {
      authToken: '',
      userAccess: {},
      currentSearchQuery: '',
      isSearchable: false,
      showMobileTypeahead: false,
      autoSuggestList: [],
      searchBar: {
        state: {
          on: false,
          off: true
        }
      },
      isNavBarMenuVisible: false,
      navBarMenu: {},
      autoSuggestFlagEnabled: false,
      lookingForFlagEnabled: false,
      tenantAsset: {
        enabled: false,
        userAccessAsset: false
      },
      tenantCustomInfo: {
        logoUri: ''
      },
      lookingForFilters: []
    }
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags', 'auth_token'])
  },
  created() {},
  async mounted() {
    /*
     * Enable/Disable Global Search's Rule
     * It depends on globalSearchEnabled from tenant settings
     */
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    let globalSearchEnabled = await localforage.getItem('global-search-enabled')
    globalSearchEnabled = globalSearchEnabled == null ? false : globalSearchEnabled
    this.isSearchable = this.searchIcon && globalSearchEnabled
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    const tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
    const settings = JSON.parse(tenant.settings)
    this.userAccess = await localforage.getItem('my_user_access')
    const userAccessTag = (await localforage.getItem('my_user_access_tag')) || {}
    this.tenantAsset.userAccessAsset = userAccessTag.canAccessAssets || false
    this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'apply-access-enabled') && settings['apply-access-enabled']
    this.userAccess.assetAccess = this.tenantAsset.userAccessAsset && this.tenantAsset.enabled
    const isTenantCustomInfoAvailable = enabledFeatureFlags['learn_tenant-custom-configuration'] ? enabledFeatureFlags['learn_tenant-custom-configuration'] : false
    this.tenantCustomInfo.logoUri =
      isTenantCustomInfoAvailable && {}.hasOwnProperty.call(tenant, 'logo') && tenant.logo && tenant.logo.length
        ? `https://mjnovs.proedge.pwc.com/dev/tenant-logo/${tenant.logo}`
        : ''
    /* Enable/Disable Auto Suggest */
    this.autoSuggestFlagEnabled = await localforage.getItem('show-search-suggest')
    this.lookingForFlagEnabled = await localforage.getItem('show-search-looking-for')
    this.getNavBarItems()
  },
  methods: {
    async getNavBarItems() {
      const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
      const showLearningHistory = enabledFeatureFlags['learn_learning_history_enabled'] || false
      this.navBarMenu = {
        links: [
          {
            name: 'My profile',
            link: '/profile',
            subLinks: [
              { name: 'Learning history', link: 'learningHistory', show: showLearningHistory },
              { name: 'Skills', link: 'skills', show: true },
              { name: 'Profile details', link: 'profileDetails', show: true }
            ]
          }
        ]
      }
    },
    autoSuggestBackdrop() {
      if (this.$refs.searchbar) {
        this.$refs.searchbar.value = ''
      }
      this.toggleSearchBar(false)
    },
    handleSearchClearButton() {
      this.$refs.searchbar.value = ''
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async onClickDirectMatch(item) {
      this.$refs.searchbar.value = ''
      this.showMobileTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
      if (['article', 'video', 'audio', 'podcast'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Content', params: { id: item.content.id, title: item.content.title } })
      } else if (['asset'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Asset', params: { id: item.content.id, asset: item.content } })
      } else if (['course'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Course', params: { id: item.content.id, pgrTrk: false } })
      } else if (['track'].includes(item.content.format.name.toLowerCase())) {
        this.$router.push({ name: 'Collection', params: { id: item.content.guid, pgrTrk: false } })
      } else {
        this.$router.push({ name: 'Credential', params: { id: item.content.id, pgrTrk: false } })
      }
      const learnerId = await localforage.getItem('my_user_id')
      const value = {
        'learner-id': learnerId,
        'content-id': item.content.id,
        'content-type': item.content.format.name
      }
      const payload = {
        action: 'Search TypeAhead Click',
        category: 'Select Match Item',
        value: JSON.stringify(value)
      }
      await this.GAEventTrack(payload)
    },
    async onClickTypeAhead(item) {
      this.showMobileTypeahead = false
      this.autoSuggestList = []
      const query = item.text.trim()
      if (query.length > 0) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const itemGA = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          query: query,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'Global Search',
          category: 'Search Query',
          value: JSON.stringify(itemGA)
        }
        await this.GAEventTrack(payload)
        const rquery = item.category.toLowerCase() === 'skill' ? { q: query, suggestionSkill: item.text } : { q: query }
        this.$router.push({ path: '/search', query: rquery })
        this.$refs.searchbar.value = ''
      }
      this.currentSearchQuery = ''
    },
    async handleSuggestionInput(event) {
      if (event.keyCode != 13 && this.$refs.searchbar && this.$refs.searchbar.value && this.autoSuggestFlagEnabled) {
        this.currentSearchQuery = this.$refs.searchbar.value
        const response = await this.$learnAdmin.searchAutoComplete({
          token: this.authToken,
          query: encodeURIComponent(this.$refs.searchbar.value)
        })
        if (!this.showMobileTypeahead) return
        this.autoSuggestList = response
        var reg = new RegExp(this.currentSearchQuery, 'gi')
        if (this.autoSuggestList.length > 0) {
          this.autoSuggestList.forEach((item) => {
            item.displayText = item.text.replace(reg, function (str) {
              return '<span style="font-weight:normal; font-family: Arial; color: #5C5C5C; top: unset !important">' + str + '</span>'
            })
            if (item.content?.format.name.toLowerCase() == 'asset') {
              item.imageUri = this.fetchAssetThumbnail(item.content.inventoryType)
              item.applications = item.content.applications && item.content.applications.length ? item.content.applications : []
            }
          })
        }
      } else if (!this.$refs.searchbar.value) {
        this.autoSuggestList = []
        this.currentSearchQuery = ''
      }
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    onSearchCancel() {
      this.showMobileTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async onSearchbarFocus() {
      const lookingForFilterList = await this.$learnAdmin.getLookingForFilters({
        token: this.authToken
      })
      this.lookingForFilters = lookingForFilterList
      this.showMobileTypeahead = true
      if (!this.userAccess.canAccessCourses) this.lookingForFilters = this.lookingForFilters.filter((v) => v !== 'Course')
      if (!this.userAccess.canAccessCredentials) this.lookingForFilters = this.lookingForFilters.filter((v) => v !== 'Credential')
      if (!this.userAccess.canAccessRWL) this.lookingForFilters = this.lookingForFilters.filter((v) => v !== 'Article' || v !== 'Audio' || v !== 'Video')
      if (!this.userAccess.assetAccess) this.lookingForFilters = this.lookingForFilters.filter((v) => v !== 'Asset')
      const learnerId = await localforage.getItem('my_user_id')
      const payload = {
        action: 'Search TypeAhead Click',
        category: 'Learner ID',
        value: learnerId
      }
      await this.GAEventTrack(payload)
    },
    async applyContentTypeFilter(type) {
      const query = ''
      const learnerId = await localforage.getItem('my_user_id')
      const payloadQuery = {
        action: 'I am looking for',
        category: 'Search Query',
        value: query.length ? query : type
      }
      const payloadLID = {
        action: 'I am looking for',
        category: 'Search Query - Learner ID',
        value: learnerId
      }
      await this.GAEventTrack(payloadQuery)
      await this.GAEventTrack(payloadLID)
      this.$router.push({ path: '/search', query: { q: query, type: type } }).catch(() => {
        window.location.reload()
      })
      this.$refs.searchbar.value = ''
      this.showMobileTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    onCloseNavBarMenu() {
      this.isNavBarMenuVisible = false
      this.$emit('handleNavBar', { isNavBarMenuVisible: false })
    },
    showNavBarMenu() {
      this.isNavBarMenuVisible = true
      this.$emit('handleNavBar', { isNavBarMenuVisible: true })
    },
    onLogoClick() {
      if (this.$route.path !== '/home') {
        this.$router.push({ name: 'Home', params: { showInProgress: true } }).catch(() => {})
      }
    },
    toggleSearchBar(on) {
      this.searchBar.state.on = on
      this.searchBar.state.off = !on
      if (on && this.$refs.searchbar) this.$refs.searchbar.setFocus()
      if (!on && this.$refs.searchbar) this.$refs.searchbar.value = ''
      if (!on) {
        this.showMobileTypeahead = false
        this.autoSuggestList = []
        this.currentSearchQuery = ''
      }
    },
    getImageUri(item) {
      let imageUri = ''
      if (item.content.format.name.toLowerCase() != 'asset' && item.content.format.name.toLowerCase() != 'track') {
        imageUri = {}.hasOwnProperty.call(item.content, 'image') ? item.content.image.uri : this.getDefaultImage(item)
      } else {
        imageUri = {}.hasOwnProperty.call(item, 'imageUri') ? item.imageUri : this.getDefaultImage(item)
      }
      return imageUri
    },
    getDefaultImage(item) {
      if (['article', 'video', 'audio', 'podcast', 'course', 'track'].includes(item.content.format.name.toLowerCase())) {
        return require(`@/assets/images/${item.content.format.name.toLowerCase()}.png`)
      }
    },
    async handleSearchInput(event) {
      const query = event.target.value.trim()
      if (query.length > 0) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const item = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          query: query,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'Global Search',
          category: 'Search Query',
          value: JSON.stringify(item)
        }
        await this.GAEventTrack(payload)
        this.$router.push({ path: '/search', query: { q: query } })
        this.toggleSearchBar(false)
        this.$refs.searchbar.value = ''
        this.showMobileTypeahead = false
        this.autoSuggestList = []
        this.currentSearchQuery = ''
      }
    },
    async handleSearchSeeAll() {
      const query = this.currentSearchQuery
      if (query.length > 0) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const item = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          query: query,
          timestamp: new Date().getTime()
        }
        const payload = {
          action: 'Global Search',
          category: 'Search Query',
          value: JSON.stringify(item)
        }
        await this.GAEventTrack(payload)
        this.$router.push({ path: '/search', query: { q: query } })
        this.$refs.searchbar.value = ''
      }
      this.showMobileTypeahead = false
      this.autoSuggestList = []
      this.currentSearchQuery = ''
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right-icons-section {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  min-width: 35%;
}

@include block('edge-navbar') {
  display: block;
  background-color: white;
  height: 46px;
  width: 100%;
  min-width: 320px;
  margin-top: 13px;

  &.search {
    height: 57px;
    margin-top: 0;

    ion-searchbar {
      width: calc(100% - 44px);
      left: 25px;
    }

    .back_arrow {
      top: 18px;
      left: 12px;
    }

    &.back-arrow {
      ion-searchbar {
        top: 10px;
      }
    }
  }

  @include element('skills') {
    display: inline-block;
    margin: 4px 0 0 0;
    font-size: 20px;
    color: var(--ion-color-tertiary);
    width: calc(100% - 156px);
    text-align: center;
  }

  @include element('left_title') {
    display: inline-block;
    margin: 4px 0 0 0;
    color: var(--ion-color-tertiary);
    width: 100%;
    text-align: left;

    &.text-center {
      text-align: center;
      padding: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 200px;
    }
  }

  button {
    background-color: map-get($edge-colors, 'white');
    position: absolute;
    outline: none;

    &.logo {
      left: 1.5rem;
      .logo--size {
        height: 20px;
        width: 40px;
        margin-left: 6px;
      }
    }

    &.filter {
      top: 7px;
      right: 55px;
      outline: none;
      &.right {
        top: 6px;
        right: 6px;
        text-align: right;
      }
    }

    &.info {
      right: 6px;
      text-align: right;
      outline: none;
    }

    &.back_arrow {
      left: 12px;
      outline: none;
    }
  }
}

.header-title {
  padding-left: 13px;
  overflow-wrap: break-word;
}

.hamburger {
  margin-right: 0.5rem;
  height: 28px !important;
  width: 26px !important;
}

ion-searchbar {
  --border-radius: 20px;
}

.show-nav-menu {
  display: block;
}

.hide-nav-menu {
  display: none;
}

.search-icon {
  position: relative;
  top: 13px;
  right: 35px;
  background: transparent;
}

.tenant-custom-logo {
  top: 18px;
  right: 10px;
  width: fit-content;
  text-align: right;
  margin: 0 12px 0 8px;
  img {
    max-width: 119px;
    height: 22px;
  }
}

ion-searchbar {
  width: calc(100% - 80px) !important;
  text-align: left;
  --box-shadow: none !important;
  background-color: white;
  top: 28px;
  :placeholder-shown {
    text-overflow: ellipsis;
  }

  &.searchbar-left-arrow {
    left: 50px;
  }
}

.searchbar-cancel-button {
  top: 35px !important;
  right: 0px;
  display: inline-block;
  position: absolute;
  width: 81px;
  background-color: white;
  height: 50px;
  padding-top: 10px;
}

.icon-search-mobile {
  color: var(--ion-color-tertiary) !important;
}

@media screen and (max-width: 280px) {
  ion-searchbar {
    width: 70%;
    left: 15px;
  }
}

.mobile-logo {
  height: 18px !important;
  width: 60px !important;
  position: absolute;
  left: 4.5rem;
  margin-top: 7px;
}

/* animation for left slider nav */
.slide-menu-enter-active,
.slide-menu-leave-active {
  transition: all 400ms ease-out;
}

.slide-menu-enter,
.slide-menu-leave-to {
  transform: translateX(-100%);
  transition: all 300ms ease-in 0s;
}

.mobile-typeahead {
  top: 100px;
  background-color: var(--ion-color-white);
  position: absolute;
  width: 100%;
}

.mobile-content-type-pill {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 11px;
  border: 2px solid #1f3370;
  border-radius: 23px;
  display: inline-block;
  height: 30px;
  padding: 6px 10px;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
}

.mobile-typeahead-header {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  color: var(--ion-color-black);
  text-align: start;
}

.mobile-typeahead-search-icon {
  display: inline-block;
}

.mobile-typeahead-search-result {
  display: inline-block;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  color: black;
  margin: 0px;
}

.mobile-typeahead-search-result-category {
  font-family: 'Arial';
  color: #5c5c5c;
  font-size: 12px;
  margin: 4px 0px 0px 0px;
  text-transform: capitalize;
}

.mobile-typeahead-search-result-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mobile-typeahead-results-container {
  border-bottom: 1px solid #9f9f9f;
  overflow-y: scroll;
  height: 50vh;
}

.mobile-typeahead-see-all {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  color: var(--ion-color-dark-blue);
  cursor: pointer;
}

.mobile-typeahead-result-row {
  cursor: pointer;
  padding: 0px 16px;
}

.mobile-typeahead-main-component {
  padding: 0px;
}

.home-img-search-mobile {
  margin-left: -10px;
  top: 12%;
  left: calc((100% - 80px) / 2);
  width: 40px;
  height: 40px;
  border: 2px solid #e0e1e1;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}
</style>
