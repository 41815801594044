<template>
  <div class="all-filters">
    <ion-grid class="ion-no-padding">
      <ion-row class="all-filters__heading-row" :class="$platform === 'Desktop' ? '' : 'header-mobile'">
        <ion-col>
          <button
            @click="toggleFilterBar(false, true)"
            class="all-filters__filter-close"
            aria-label="close side menu"
            :class="$platform === 'Mobile' ? 'all-filters__filter-close-mobile' : ''"
          >
            <svg-icon icon-class="close-icon" class-name="all-filters__filter-close-svg" />
          </button>
        </ion-col>
      </ion-row>
      <ion-row class="external-content-modal">
        <ion-col v-if="visibility.success && !content.isComplete" size="12">
          <div class="external-content-success-container">
            <svg-icon icon-class="icon-progress-complete" />
            <h2 class="external-content-heading" :class="$platform === 'Desktop' ? 'external-content-heading-desktop' : 'external-content-heading-mobile'">
              Your item was successfully saved to your<button
                @click="toggleFilterBar(false, false)"
                :class="$platform === 'Desktop' ? 'external-content-heading-desktop' : 'external-content-heading-mobile'"
                class="added-by-you"
              >
                Added by you</button
              >section!
            </h2>
          </div>
          <p class="external-content-subheading">To get credit for it, don't forget to mark it as completed.</p>
          <div v-if="showAddToTrack" class="action-btns">
            <ion-button shape="round" color="primary" class="add-to-track-btn" title="Add to a track" @click="onAddToTrack">
              <div class="add-icon-container">
                <svg-icon icon-class="add-icon" style="height: 10px; width: 10px; position: relative; top: -0.1em; left: -0.1em" />
              </div>
              Add to a track
            </ion-button>
          </div>
        </ion-col>
        <ion-col v-else-if="visibility.success && content.isComplete" size="12">
          <div class="external-content-success-container">
            <svg-icon icon-class="icon-progress-complete" />
            <h2 class="external-content-heading" :class="$platform === 'Desktop' ? 'external-content-heading-desktop' : 'external-content-heading-mobile'">
              Your item was successfully saved to your<button
                @click="toggleFilterBar(true, false)"
                :class="$platform === 'Desktop' ? 'external-content-heading-desktop' : 'external-content-heading-mobile'"
                class="added-by-you"
              >
                Completed</button
              >section!
            </h2>
          </div>
          <div v-if="showAddToTrack" class="action-btns">
            <ion-button shape="round" color="primary" class="add-to-track-btn" title="Add to a track" @click="onAddToTrack">
              <div class="add-icon-container">
                <svg-icon icon-class="add-icon" style="height: 10px; width: 10px; position: relative; top: -0.1em; left: -0.1em" />
              </div>
              Add to a track
            </ion-button>
          </div>
        </ion-col>
        <ion-col v-else size="12">
          <h2 class="external-content-heading" :class="$platform === 'Desktop' ? 'external-content-heading-desktop' : 'external-content-heading-mobile'">
            {{ isEdit ? 'Edit external learning details' : 'Add external learning' }}
          </h2>
        </ion-col>
      </ion-row>
      <ion-grid class="ion-no-padding external-content-modal" v-if="visibility.main && !visibility.success">
        <ion-row>
          <div class="typeahead-header">Choose the content type</div>
        </ion-row>
        <ion-row v-for="format in externalFormats" :key="format.id" class="ion-align-items-center content-type-pill" @click="displayContentForm(format.slug, format.id)">
          {{ format.title }}
        </ion-row>
      </ion-grid>
      <div v-if="visibility.contentType && !visibility.success">
        <MyLearningAddExternalForm ref="externalForm" :editContentData="externalData" :type="visibility.contentType" @on-save="onSave" />
      </div>
    </ion-grid>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import localforage from 'localforage'
import MyLearningAddExternalForm from '@/views/my-learning/components/MyLearningAddExternalForm'

export default {
  name: 'MyLearningAddExternalSideBar',
  components: { MyLearningAddExternalForm },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    externalData: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      authToken: '',
      externalFormats: [],
      selectedFormatId: '',
      visibility: {
        main: true,
        success: false,
        contentType: ''
      },
      content: {
        isComplete: false
      },
      showAddToTrack: false,
      addExternalContentResponse: null
    }
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.showAddToTrack = await localforage.getItem('show-add-to-track')
    this.externalFormats = await this.$learnAdmin.getExternalContentFormats({ token: this.authToken })
    this.visibility.contentType = this.isEdit ? this.externalData.format.name.toLowerCase() : this.visibility.contentType
    this.visibility.main = !this.isEdit
  },
  methods: {
    async toggleFilterBar(isCompleted = false, isClosed = false) {
      this.$emit('on-toggle-external-sidebar')
      if (isCompleted) {
        const link = 'learningHistory'
        await localforage.setItem('profile-side-nav-changed', link)
        this.$eventBus.$emit('profile-sub-link-clicked', link)
        this.$router.push({ name: 'Profile', params: { id: link } }).catch(() => {})
      }
      if (isClosed) {
        await this.onPrepareGAPayload('Closed without adding an external content', null)
      }
    },
    displayContentForm(type, id) {
      this.visibility.main = false
      this.visibility.contentType = type
      this.selectedFormatId = id
    },
    async onSave() {
      let data = this.$refs.externalForm.getFormData()
      this.content.isComplete = data.isComplete
      if (Object.keys(data).length) {
        data = {
          ...data,
          formatId: this.selectedFormatId
        }
        const payload = {
          token: this.authToken,
          body: data
        }
        if (this.isEdit) {
          await this.$learnAdmin.updateExternalContent({ ...payload, guid: this.externalData.externalArtifactGuid }).then((response) => {
            if (response.externalArtifact.guid) {
              setTimeout(() => {
                window.location.reload()
              }, 600)
            }
            this.showToast('Edits successfully saved', 'success')
            this.$emit('on-update-success')
            this.$emit('on-toggle-external-sidebar')
          })
        } else {
          this.addExternalContentResponse = await this.$learnAdmin.addExternalContent({ token: this.authToken, body: data })
          await this.onPrepareGAPayload('Successfully added an external content', data)
        }
        this.visibility.success = true
      }
    },
    showToast(msg, className) {
      this.$toasted.show(msg, {
        containerClass: 'toast-container',
        className: className,
        position: this.isDesktopPlatform ? 'bottom-center' : 'mobile-container',
        iconPack: 'icon-container',
        duration: 5000,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    },
    async onPrepareGAPayload(category, xcontent = null) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = xcontent
        ? {
            'learner-id': learnerId,
            'tenant-guid': tenantGuid,
            'xcontent-url': xcontent.url,
            'xcontent-title': xcontent.title,
            'xcontent-completed': xcontent.isComplete,
            timestamp: new Date().getTime()
          }
        : {
            'learner-id': learnerId,
            'tenant-guid': tenantGuid,
            timestamp: new Date().getTime()
          }
      const payload = {
        action: 'Add External Content Sidebar',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    },
    onAddToTrack() {
      this.$emit('on-toggle-external-sidebar')
      this.$eventBus.$emit('add-to-track', this.addExternalContentResponse)
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('all-filters') {
  height: 100vh;
  z-index: 999;
  background-color: var(--ion-color-light);
  padding: 0 0;
  // box-shadow: 1px 0 4px 1px rgba(0, 0, 0, 0.12);
  top: 0;
  text-align: left;

  @include element('filter-container') {
    overflow: auto;
    height: 64vh;
  }

  @include element('heading-row') {
    margin-bottom: 48px;
    margin-right: 24px;
    margin-left: 24px;
  }

  @include element('heading') {
    font-family: 'Futura PT Demi';
    font-size: 20px;
    color: var(--ion-color-black);
    position: absolute;
    width: 80%;
    text-align: center;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @include element('clear-button') {
    text-transform: capitalize;
    height: 39px !important;
    --box-shadow: none;
    color: var(--ion-color-dark-blue);
    font-family: 'Futura PT Demi';
    font-size: 16px;
    --background-hover: none;
  }

  @include element('done-button') {
    text-transform: capitalize;
    width: 114px;
    height: 39px !important;
    --box-shadow: none;
    color: var(--ion-color-dark-blue);
    font-family: 'Futura PT Demi';
    font-size: 16px;
  }

  @include element('filter-detail') {
    position: absolute;
    width: 364px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: var(--ion-color-light);
    z-index: 2;
  }

  @include element('checkbox') {
    --size: 18px;
    margin-right: 12px;
  }

  @include element('filter-detail-row') {
    margin: 16px 36px;
  }

  @include element('filter-detail-name') {
    font-family: 'Arial';
    font-size: 14px;
    color: #000000de;
    margin-left: 8px;
  }
}

.footer-mobile {
  margin-bottom: 16px !important;
}

.header-mobile {
  margin-top: 10px !important;
  margin-bottom: 16px !important;
}

.options-scroller-mobile {
  height: 56% !important;
}

.filter-detail-name-disabled {
  color: #9f9f9f !important;
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Pro Max */,
  only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 12 Pro Max */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP XR/11 */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP XS Max/11 Pro Max */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP 11 */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP 11max */,
  only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 12/12 Pro */,
  only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 13/13 Pro */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 11pro */,
  only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP X /XS/11 Pro/12 Mini */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Mini */,
  only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) /* iP 6+/6s+/7+/8+ */,
  only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) /* iP 6/6s/7/8 */ {
  .header-mobile {
    margin-top: 10px !important;
    margin-bottom: 16px !important;
  }

  .options-scroller-mobile {
    height: 56% !important;
  }

  .footer-mobile {
    padding: 2rem 0rem;
    margin-bottom: 0px !important;
  }

  .ios-filter-container {
    overflow: auto;
    height: 74vh !important;
  }

  @include block('all-filters') {
    @include element('filter-footer') {
      bottom: 0px !important;
    }
  }
}

// for screens under 480px
@media screen and (max-width: 480px) {
  @include block('all-filters') {
    @include element('filter-detail') {
      width: 100%;
    }

    @include element('filter-container') {
      overflow: auto;
      height: 56vh;
    }

    .external-content-modal {
      padding: 0 1.2em !important;
    }

    .all-filters__filter-footer {
      padding: 1em 1.2em;
    }
  }
}

.typeahead-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  color: var(--ion-color-black);
}

.content-type-pill {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 12px;
  border: 2px solid #1f3370;
  border-radius: 23px;
  display: inline-block;
  width: 75px;
  height: 32px;
  padding: 7px 14px;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
}

.external-content-heading {
  font-family: 'Futura PT Demi';
  color: var(--ion-color-black);
  text-align: left;
  margin-top: 0;
}

.external-content-heading-desktop {
  font-size: 30px;
}

.external-content-heading-mobile {
  font-size: 20px;
}

.external-content-subheading {
  font-family: 'Arial';
  font-size: 16px;
}

.external-content-success-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  svg {
    margin-right: 0.5em;
    width: 2rem;
    margin-top: 3px;
  }
}

.all-filters {
  background-color: #ffffff;

  .all-filters__filter-close {
    color: unset;
    text-decoration: unset;
    background-color: unset;
    float: right;
    font-size: 1rem;
  }

  .all-filters__filter-close-mobile {
    position: relative;
    top: 0.8rem;
    z-index: 3000;
  }

  .added-by-you {
    border: none;
    background: none;
    color: #1f3370;
    font-family: 'Futura PT Demi';
    text-decoration: underline;
  }

  .external-content-modal {
    padding: 0 5em;
  }

  .all-filters__filter-footer {
    padding: 1em 5em;
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 100%;
    z-index: 2;
    border-top: 1px solid #eee;
    border-radius: 0 0 0 20px;

    .footer-content-container {
      font-family: 'Arial';
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .all-filters__heading-row {
    margin: 1.5em 0.9em;
  }

  .duration-input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    .duration-input {
      height: 41px;
      width: 60px;
      margin-right: 0.3em;

      &:nth-child(2) {
        margin-left: 1em;
      }
    }
  }

  .addskill-icon-footer {
    background-color: #1f3370;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    padding-top: 1px;
    text-align: center;
    color: white;
    padding-left: 3px;
  }

  .action-btns {
    .add-to-track-btn {
      text-transform: none;
      font-family: 'Futura PT Demi';
      margin-right: 1rem;
      width: 180px !important;
      height: 40px !important;
      --ion-color-primary-contrast: var(--ion-color-tertiary);
      --box-shadow: none;
    }

    .add-icon-container {
      display: inline-block;
      margin: 0px 10px 0px 0px;
      padding-top: 1px;
      padding-left: 3px;
      text-align: center;
      width: 18px;
      height: 18px;
      color: white;
      background-color: #1f3370;
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 480px) {
  .added-by-you {
    padding: 0 0.25rem;
  }
}
.complete-text {
  position: relative;
  top: -0.2rem;
  font-family: 'Arial';
  font-size: 14px;
}

.complete-checkbox {
  --size: 18px;
  margin-right: 12px;
  --border-radius: 4px;
  --border-color: #bcc2d4;
  --border-width: 2px;
}
</style>