<template>
  <div class="rating-content">
    <div class="ratings-container" v-if="!rating.flagged">
      <div class="ratings-row-meta">
        <span class="meta-container">
          <span>
            <svg-icon v-for="rating in Math.ceil(rating.rating)" :key="rating.date" icon-class="star-filled-desktop" style="margin-right: 5px" />
            <svg-icon v-for="rating in maxRating - Math.ceil(rating.rating)" :key="rating.name" icon-class="star-unfilled" style="margin-right: 5px" />
          </span>
          <span>By {{ rating.userName }}</span>
          <span v-if="rating.isCompleted">User has completed content</span>
          <span>{{ rating.createdAt | formatDate }}</span>
        </span>
        <span class="flag-container" @mouseover="isHovering = true" @mouseout="isHovering = false" @click="isMoreOpen = true; isFlagClicked = true" v-if="rating.userGuid != userGuid"><svg-icon :icon-class="isHovering || isFlagClicked ? 'Icon feather-flag-red' :'Icon feather-flag-black'" class-name="flag-icon"></svg-icon></span>
        <div class="dropdown-container" id="dropdown-container" v-if="isMoreOpen" @click="openFlagModal()"><span>Flag as inappropriate</span></div>
      </div>
      <div class="ratings-comment">{{ rating.comment }}</div>
    </div>
    <div v-else class="flagged-container">
      <span>This comment has been flagged. <svg-icon icon-class="Icon-feather-flag" class-name="flag-icon"></svg-icon></span>
    </div>
  </div>
</template>

  <script>
import { mapGetters } from 'vuex'
import { popoverController } from '@modus/ionic-vue'
import localforage from 'localforage'
import CommentFlagModal from '@/components/molecules/CommentFlagModal'
import CommentFlaggedAlert from '@/components/molecules/CommentFlaggedAlert'
import util from '@/utils/helper'
export default {
  name: 'CommentListItem',
  components: {},
  props: {
    rating: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {},
  data() {
    return {
      maxRating: 5,
      isMoreOpen: false,
      userGuid: '',
      isHovering: false,
      isFlagClicked: false
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'user_guid'])
  },
  mounted() {},
  async created() {
    document.addEventListener('click', this.onClick, true)
    this.userGuid = this.user_guid || (await localforage.getItem('my_user_guid'))
  },
  destroyed() {
    document.removeEventListener('click', this.onClick, true)
  },
  filters: {
    formatDate(d) {
      return util.formatDate(d)
    }
  },
  methods: {
    async openFlagModal() {
      const rating = this.rating
      let modal = await popoverController.create({
        component: CommentFlagModal,
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'comment-flag-container',
        backdropDismiss: true,
        componentProps: {
          parent: this,
          propsData: {
            rating
          }
        }
      })
      await modal.present()
      this.isMoreOpen = false
      await modal.onDidDismiss().then(({ data }) => {
        this.isFlagClicked = false
        if(data && data.submit){
          this.$emit('flag-submitted')
          this.openFlagSuccessModal()
        }
      })
    },
    async openFlagSuccessModal() {
      let modal = await popoverController.create({
        component: CommentFlaggedAlert,
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'comment-flag-success',
        backdropDismiss: true,
        componentProps: {
          parent: this,
          propsData: {
          }
        }
      })
      await modal.present()
    },
    onClick(e) {
      const dropdown = document.getElementById('dropdown-container')
      if (dropdown && !dropdown.contains(e.target)) {
        e.preventDefault()
        e.stopPropagation()
        this.isMoreOpen = false
        return
      }
    }
  }
}
</script> 
<style lang="scss" scoped>
.rating-content {
  width: 100%;
  .ratings-container {
    width: 100%;
    .ratings-row-meta {
      margin: 24px 0px;
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      span {
        margin: 0 8px;
      }

      span:nth-child(1) {
        margin-left: 0px;
      }

      span:nth-child(2) {
        font: normal normal bold 14px/11px Arial;
        letter-spacing: 0px;
        color: #5c5c5c;
      }

      span:nth-child(3),
      span:nth-child(4) {
        font: normal normal normal 14px/11px Arial;
        letter-spacing: 0px;
        color: #5c5c5c;
        margin-right: 0px;
      }

      .flag-container {
        cursor: pointer;
        border: 1px solid #dfdede;
        border-radius: 50%;
        padding: 5px;
        position: relative;
        .flag-icon {
          color: #000000;
        }
      }
      .dropdown-container {
        position: absolute;
        cursor: pointer;
        top: 2rem;
        right: -2rem;
        background: #fff;
        padding: 1rem;
        border: 1px solid #dfdede;
        border-radius: 4px;
        box-shadow: 0px 0px 12px #0000001f;
        span {
          background: #efefef;
          margin: 0 !important;
          padding: 0.3rem 0.8rem;
          font: normal normal normal 14px/20px Arial;
        }
      }
    }

    .ratings-comment {
      margin-bottom: 24px;
      font: normal normal normal 16px/28px Arial;
      letter-spacing: 0px;
      color: #5c5c5c;
      text-align: left;
    }
  }
  .flagged-container {
    text-align: left;
    padding: 1.5rem 0;
    font: normal normal bold 14px/11px Arial;
  }
}
</style>
  