<template>
  <div class="rating-container" id="item-rating-modal">
    <div class="rating-header">
      <h1>{{ ratingCommentEnabled ? 'Rate and review' : 'Leave a rating' }}</h1>
      <svg-icon icon-class="filter-close" class="g-icon--small close g-clickable" @click="onCancel"></svg-icon>
    </div>
    <div class="rating-modal-content">
      <ion-row class="item-info">
        <ion-col class="item-img-background ion-no-padding">
          <div v-if="item.format.name.toLowerCase() !== 'asset'">
            <img v-if="{}.hasOwnProperty.call(item, 'image') && {}.hasOwnProperty.call(item.image, 'uri')" :src="item.image.uri" />
            <img v-else :src="getDefaultImage()" />
          </div>
          <div v-else>
            <img v-if="item.format.name.toLowerCase() == 'asset'" :src="fetchAssetThumbnailForAsset(item.inventoryType.name)" />
          </div>
        </ion-col>
        <ion-col class="ion-no-padding">
          <div class="meta">
            <div class="format" :class="item.format.name.toLowerCase() == 'course' ? 'course' : item.format.name.toLowerCase() == 'collection' ? 'collection' : 'artifacts'">
              {{ item.format && item.format.name }}
            </div>
          </div>
          <div class="title">{{ item.title }}</div>
        </ion-col>
      </ion-row>
      <div class="overall-rating-container">
        <div class="overall-rating-text">Rating</div>
        <StarRating
          :increment="1"
          inactive-color="#d0cfce"
          active-color="#B98032"
          :star-size="42"
          :show-rating="false"
          :round-start-rating="true"
          :rating="rating"
          @rating-selected="onRating"
          class="rating"
        />
      </div>
      <div
        class="overall-review-container"
        v-if="
          (({}.hasOwnProperty.call(ratingDetails, 'userRating') && !ratingDetails.userRating.flagged) || !{}.hasOwnProperty.call(ratingDetails, 'userRating')) && ratingCommentEnabled
        "
      >
        <div class="overall-review-text">Add a written review</div>
        <ion-textarea :rows="isDesktopPlatform ? 4 : 9" :placeholder="reviewCommentPlaceholder" @ionInput="handleInput($event)" :value="reviewComment"></ion-textarea>
        <div class="error-container" v-if="reviewCommentError"><p>You have reached the 1000 character limit</p></div>
      </div>
      <div class="sumbit-btn-container">
        <ion-button class="sumbit-btn" shape="round" color="primary" @click="onSubmit" :disabled="isSubmitDisabled()" style="--box-shadow: none">Submit</ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import localforage from 'localforage'
import StarRating from 'vue-star-rating'
import { popoverController } from '@modus/ionic-vue'

export default {
  name: 'ItemRatingModal',
  components: { StarRating },
  props: {
    item: {
      type: Object,
      required: true
    },
    ratingDetails: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    closeModal: {
      type: Function,
      required: true
    },
    ratingCommentEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      authToken: '',
      defaultImage: '',
      reviewComment: '',
      reviewCommentPlaceholder: `Share your feedback in less than 1000 characters. \nPlease do not provide any personal data (such as contact information).`,
      reviewCommentError: false,
      rating: 0,
      isDesktopPlatform: false,
      entityType: {
        course: 'Artifact',
        article: 'Artifact',
        video: 'Artifact',
        audio: 'Artifact',
        track: 'Track',
        asset: 'Asset'
      },
      editMode: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.editMode = {}.hasOwnProperty.call(this.ratingDetails, 'userRating') && this.ratingDetails.userRating.rating > 0
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.reviewComment = {}.hasOwnProperty.call(this.ratingDetails, 'userRating') ? this.ratingDetails.userRating.comment : ''
    this.rating = {}.hasOwnProperty.call(this.ratingDetails, 'userRating') ? this.ratingDetails.userRating.rating : 0
  },
  beforeDestroy() {
    this.$parent.$emit('close-rating-modal', { star: this.rating })
  },
  methods: {
    isSubmitDisabled() {
      if (this.editMode) {
        return (this.rating === this.ratingDetails.userRating.rating && this.reviewComment === this.ratingDetails.userRating.comment) || this.reviewCommentError
      }
      return this.rating === 0 || this.reviewCommentError
    },
    onCancel() {
      this.closeModal()
    },
    onRating(star) {
      this.rating = star
    },
    async onSubmit() {
      if (this.rating) {
        const authToken = this.auth_token || (await localforage.getItem('my_access_token'))
        const payload = {
          token: authToken,
          id: this.item.id,
          ratingId: {}.hasOwnProperty.call(this.ratingDetails, 'userRating') ? this.ratingDetails.userRating.ratingId : '',
          body: {
            rating: this.rating,
            entityType: this.getEntityType(),
            progress: this.item.progress
          }
        }
        this.reviewComment && this.reviewComment.length > 0 ? (payload.body.comment = this.reviewComment) : ''
        this.ratingDetails && {}.hasOwnProperty.call(this.ratingDetails, 'userRating') && this.ratingDetails.userRating.ratingId != null
          ? await this.$learnAdmin.editRateContentById(payload)
          : await this.$learnAdmin.rateContentById(payload)
        await this.onPrepareGAPayload()
        popoverController.dismiss({ changed: true })
      }
    },
    handleInput(e) {
      this.reviewComment = e.target.value
      this.reviewCommentError = e.target.value.length > 1000 ? true : false
    },
    fetchAssetThumbnailForAsset(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    getEntityType() {
      return this.entityType[this.item.format.name.toLowerCase()]
    },
    getDefaultImage() {
      if (['article', 'video', 'audio', 'podcast', 'course', 'track'].includes(this.item.format.name.toLowerCase())) {
        return require(`@/assets/images/${this.item.format.name.toLowerCase()}.png`)
      } else if (this.item.format?.name.toLowerCase() == 'credential') {
        return require(`@/assets/images/thumbnail-complete-credential.png`)
      }
    },
    async onPrepareGAPayload() {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        'content-id': this.item.id,
        progress: this.item.progress,
        rating: this.rating,
        comment: this.reviewComment,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'Rating a content',
        category: 'Rated',
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>
<style lang="scss">
.rating-container {
  .overall-review-container {
    ion-textarea {
      border: 1px solid #e0e1e1;
      margin-bottom: 0.5rem;
      border-radius: 0.25rem;
      padding: 0.4rem;
      text-align: left;
      &:focus {
        border: 1px solid var(--ion-color-focus) !important;
      }
    }
    textarea {
      font-size: 16px !important;
      font-family: Arial !important;
    }
  }
  .sumbit-btn-container .sumbit-btn {
    &.button-disabled {
      --ion-color-primary: #919191;
    }
  }
}
</style>
<style lang="scss" scoped>
.rating-container {
  padding: 25px 35px;
  background: #fff;
  width: -webkit-fill-available;
  .rating-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--ion-color-light-gray);
    padding: 1.3rem 0;
    h1 {
      font: normal normal normal 34px/30px 'Futura PT Demi';
      letter-spacing: 0.01px;
      color: #000000;
      opacity: 1;
      margin: 0;
    }
  }
  .rating-modal-content{
    overflow-y: auto;
    height: calc(100vh - 385px);
    @media (max-width: 484px) {
      height: calc(100vh - 153px);
    }
  }
}

.error-container p {
  font: normal normal normal 12px/30px Arial;
  letter-spacing: 0px;
  color: #e0301e;
  opacity: 1;
  margin: 0;
}

.item-info {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  gap: 45px;
}

.item-img-background {
  position: relative;
  max-width: 150px;
  width: 150px;
  height: 150px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  background: #e0e1e1;
  img {
    height: 150px;
    width: 150px;
    object-fit: fill;
  }
}

.collection-img-shape {
  height: 150px;
  width: 150px;
  clip-path: polygon(103% 0%, 49% 103%, -3% 0%);
  border-radius: 10px;
  background: white;
  padding: 10px;
}

.collection-img-background {
  height: 150px;
  clip-path: polygon(100% 0%, 49% 85%, 0% 0%);
  background-image: url('~@/assets/images/gradient-collection.png');
  img {
    width: 170px;
    height: 80px;
    object-fit: cover;
  }
}

.content-img-background {
  position: relative;
  max-width: 150px;
  border: 4px solid var(--ion-color-white);
  overflow: hidden;
  width: 150px;
  height: 150px;
  border: 5px solid var(--ion-color-white);
  background-size: contain;
  border-radius: 20px;
  img {
    position: relative;
    top: -7px;
    left: -12px;
    max-width: 150px;
    width: 150px;
    height: 150px;
  }
}

.meta {
  display: flex;
  gap: 0.4rem;
  text-transform: uppercase;
  color: var(--ion-color-black);
  font-size: 12px;
  font-weight: bold;
  font-family: 'Arial';
  letter-spacing: 1.2px;
  margin-block: 8px;

  .format {
    padding: 5px 8px;
    max-height: 1.5rem;
    border-radius: 10px;
    font: normal normal bold 14px/14px Arial;
  }
  .course {
    background: #E0E1E1;
  }
  .collection {
    background: #c0e0ca;
  }
  .artifacts {
    background: #e0e1e1;
  }
  .g-button--primary {
    width: 7rem;
    margin-right: 1rem;
    text-transform: capitalize;
  }
  .provider {
    padding: 0.32rem 0.5rem;
    font: normal normal bold 14px/14px Arial;
  }
}

.title {
  text-align: left;
  font: normal normal normal 30px/34px 'Futura PT Demi';
  letter-spacing: 0.02px;
  color: #000000;
  opacity: 1;
}

.overall-rating-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  .overall-rating-text {
    text-align: left;
    font: normal normal bold 14px/24px Arial;
    letter-spacing: 0.01px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.2rem;
  }
  .rating {
    margin: 0px;
    padding: 0px;
  }
}

.overall-review-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 1.8rem;
  .overall-review-text {
    text-align: left;
    font: normal normal bold 14px/24px Arial;
    letter-spacing: 0.01px;
    color: #000000;
    opacity: 1;
  }
}

.sumbit-btn-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  .sumbit-btn {
    margin: 0px;
    margin-top: 24px;
    font-size: 15px;
    width: 154px;
    height: 40px;
    text-transform: none;
    font-family: 'Futura PT Demi';
    --ion-color-primary: #feb92e;
    --ion-color-primary-contrast: black;
    --box-shadow: unset;
  }
}

@media screen and (max-width: 1366px) {
  .item-img-background {
    display: none;
  }
  .meta-info {
    .item-rating {
      display: none;
    }
  }
}

@media (max-width: 484px) {
  .rating-container {
    padding: 40px 40px;
    .rating-header {
      h1 {
        font: normal normal normal 30px/30px 'Futura PT Demi';
        margin: unset;
      }
    }
  }
  .overall-rating-container {
    .overall-rating-text {
      font-size: 16px;
    }
  }
  .title {
    font: normal normal normal 24px/34px 'Futura PT Demi';
  }
}
</style>
