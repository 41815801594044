var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "expand-item-container-popover",
      on: { mouseleave: _vm.onCancel }
    },
    [
      _c("UniversalCard", {
        key: _vm.item.id,
        attrs: {
          item: _vm.item,
          index: _vm.index,
          itemsLength: _vm.itemsLength,
          tab: _vm.tab
        },
        on: {
          "popover-on": function($event) {
            return _vm.popoverOn()
          },
          "popover-off": _vm.popoverOff,
          "close-expand-item-popover": function($event) {
            return _vm.onCancel("onTitle")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }