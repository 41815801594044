<template>
  <div ref="top" :class="isDesktopPlatform ? 'desktop-background track-content' : 'non-white-background'">
    <div v-if="!dataReady" >
      <TrackSkeleton/>
    </div>
    <div v-if="dataReady"> 
      <ion-grid v-if="isContentAvailable" class="ion-no-padding ion-padding-bottom" :class="isDesktopPlatform ? 'desktop-collection-container' : 'mobile-collection-container'">
        <ion-row>
          <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12">
            <button class="back accessible-button" @click="$router.go(-1)">
              <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
              Back
            </button>
            <ion-row class="collection-info">
              <ion-col class="collection-img-shape">
                <img v-if="collection.image.uri" :src="collection.image.uri" />
                <img v-else src="@/assets/images/track.png" />
              </ion-col>
              <ion-col>
                <div class="meta">
                  <div class="format">{{ collection.format.name }}</div>
                  <div v-if="collection.progress">
                    <div v-if="collection.progress > 0 && collection.progress < 100" class="progress">
                      <radial-progress-bar
                        :diameter="28"
                        :completed-steps="collection.progress"
                        :total-steps="collection.progress == '99' ? 110 : 100"
                        :startColor="`#000000`"
                        :stopColor="`#000000`"
                        :innerStrokeColor="`#e0e1e1`"
                        :strokeWidth="4"
                        :innerStrokeWidth="5"
                      >
                      </radial-progress-bar>
                      <span>{{ collection.progress }}%</span>
                    </div>
                    <div v-else-if="collection.completed || collection.progress == 100" class="progress">
                      <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                      <span>completed</span>
                    </div>
                  </div>
                </div>
                <div class="title">{{ collection.title }}</div>
                <div class="meta-info">
                  <div v-if="collection.rating" class="collection-rating" @click="onScroll">
                    <span class="rating">{{ collection.rating }}</span>
                    <StarRating
                      inactive-color="#d0cfce"
                      active-color="#b98032"
                      :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                      :rating="collection.rating"
                      :read-only="true"
                      :increment="0.1"
                      :star-size="14"
                      :show-rating="false"
                      :round-start-rating="true"
                      class="star-rating"
                    />
                    <svg-icon class="display-star-mobile" icon-class="star-filled-desktop" style="margin-left: 10px" />
                    <span class="rating-count">{{ collection.ratingCount }} ratings</span>
                  </div>
                  <div v-if="collection.duration" class="duration">{{ collection.duration }}</div>
                  <div v-if="collection.level" class="level">{{ collection.level }}</div>
                  <div class="content-count">
                    <svg-icon icon-class="track-list" />
                    <div class="content-count-title">{{ collection.content && collection.content.length }} items</div>
                  </div>
                </div>
                <div class="meta-action">
                  <div class="share-container">
                    <button class="social-share" aria-labelledby="share-btn" @click="popoverOn('share')"><svg-icon icon-class="click-to-copy" class-name="g-icon--small g-clickable" /></button>
                    <SocialSharePopover
                      v-if="isShareOpen"
                      class="social-share-popover"
                      :assetId="collectionId"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      fromPage="Universal card"
                      size="lg"
                      @dismiss-share-action-model="popoverOff('share')"
                    />
                    <span v-if="!isShareOpen && isDesktopPlatform" id="share-btn" class="share-tooltip-text">Share</span>
                  </div>
                  <div v-if="isMoreShown" class="more-container">
                    <button class="more-action" aria-labelledby="more-btn" @click="popoverOn('more')"><svg-icon icon-class="more-action" class-name="g-icon--small g-clickable" /></button>
                    <MoreActionPopover
                      v-if="isMoreOpen"
                      class="more-popover"
                      :item="collection"
                      :assetId="collectionId"
                      :assetType="collection.format.name"
                      :routeInfo="getRouteInfo()"
                      :router="$router"
                      :showEditDelete="true"
                      :showAddToTrack="false"
                      size="lg"
                      fromPage="Universal card"
                      @add-to-track="popoverOff('more')"
                      @close-more="popoverOff('more')"
                    />
                    <span v-if="!isMoreOpen" id="more-btn" class="more-tooltip-text">More</span>
                  </div>
                  <div v-if="completedItems.length > 0 && completedItems.length != totalItems.length" class="flex-container">
                    <radial-progress-bar
                          :diameter="28"
                          :completed-steps="completedItems.length"
                          :total-steps="totalItems.length"
                          :startColor="`#000000`"
                          :stopColor="`#000000`"
                          :innerStrokeColor="`#e0e1e1`"
                          :strokeWidth="4"
                          :innerStrokeWidth="5"
                        >
                      </radial-progress-bar>
                      <div class="flex-percentage">{{`${completedItems.length} of ${totalItems.length} items completed`}}</div> 
                  </div>
                  <div v-else-if="completedItems.length > 0 && completedItems.length == totalItems.length" class="progress-track">
                    <svg-icon style="margin-right: 5px" icon-class="icon-progress-complete" />
                    <span style="font: normal normal normal 14px/16px Arial;color:#2D2D2D;">{{`${completedItems.length} of ${totalItems.length} items completed`}}</span>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <div class="collection-about-container">
          <ion-row class="carouselDesktop">
            <ion-col size="8.5" style="margin-right: 2rem">
              <CollectionAbout :collection="collection" />
              <ItemRating :item="collection" :scroll="scroll" :getUpdatedItemData="getUpdatedCollectionData" :ratingDetails="ratingDetails" @update-ratings="getRatingById()" />
            </ion-col>
            <ion-col v-if="isMetaDataVisible" size="3">
              <ItemMetaData :item="collection" />
            </ion-col>
          </ion-row>
          <ion-row class="carouselMobile">
            <ion-col v-if="isMetaDataVisible" size="12">
              <ItemMetaData :item="collection">
                <CollectionAbout :collection="collection" slot="item-about" />
                <ItemRating
                  :item="collection"
                  :scroll="scroll"
                  :getUpdatedItemData="getUpdatedCollectionData"
                  slot="item-rating"
                  :ratingDetails="ratingDetails"
                  @update-ratings="getRatingById()"
                />
              </ItemMetaData>
            </ion-col>
            <ion-col v-else size="12">
              <CollectionAbout :collection="collection" />
              <ItemRating :item="collection" :scroll="scroll" :getUpdatedItemData="getUpdatedCollectionData" :ratingDetails="ratingDetails" @update-ratings="getRatingById()" />
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
      <ion-grid v-else :style="isDesktopPlatform ? '' : 'padding: 2rem 10px 6rem;'" class="ion-no-padding">
        <PageNotFound />
      </ion-grid>
    </div> 
    
  </div>
</template>

<script>
import { marked } from 'marked'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import moment from 'moment-timezone'
import CollectionAbout from '@/views/collection/components/CollectionAbout'
import TrackSkeleton from '@/views/collection/components/TrackSkeleton'
import ItemMetaData from '@/components/molecules/ItemMetaData'
import PageNotFound from '@/components/molecules/PageNotFound'
import StarRating from 'vue-star-rating'
import RadialProgressBar from 'vue-radial-progress'
import MoreActionPopover from '@/components/molecules/MoreActionPopover'
import SocialSharePopover from '@/components/molecules/SocialSharePopover'
import ItemRating from '@/components/molecules/ItemRating'

export default {
  name: 'Collection',
  components: { CollectionAbout, ItemMetaData, StarRating, RadialProgressBar, MoreActionPopover, SocialSharePopover, PageNotFound, ItemRating, TrackSkeleton },
  props: {},
  data() {
    return {
      collection: null,
      collectionId: '',
      authToken: '',
      isAccessible: false,
      dataReady: false,
      isDesktopPlatform: true,
      isAdd: false,
      scroll: 0,
      showConfirmationProgress: false,
      isMetaDataVisible: true,
      isShareOpen: false,
      isMoreOpen: false,
      isContentAvailable: false,
      isMoreShown: false,
      assetMediaUrl: '',
      ratingDetails: {},
      completedItems: [],
      totalItems: [],
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'sso_token', 'sso_bouncer', 'user_access']),
    onDate: function () {
      const completedAt = {}.hasOwnProperty.call(this.collection, 'completedAt') ? this.collection.completedAt : this.collection.meta.completedAt
      const date = new Date(completedAt)
      const duration = new Date() - date
      const days = Math.ceil(duration / (1000 * 60 * 60 * 24))
      if (days == 1) return 'today'
      else if (days == 2) return 'yesterday'
      else if (days > 2 && days < 7) return `${days} days ago`
      else return date.toLocaleDateString('en-US')
    }
  },
  watch: {},
  created() {},
  async mounted() {
    this.$refs.top.scrollIntoView()
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.isMetaDataVisible = enabledFeatureFlags['learn_collection-meta-data-enabled'] ? enabledFeatureFlags['learn_collection-meta-data-enabled'] : false
    this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
    await this.setupCollection()
    await this.getRatingById()
  },
  async activated() {
    this.$refs.top.scrollIntoView()
    await this.setupCollection()
    await this.getRatingById()
  },
  methods: {
    async getTrackProgress(trackDetails) {
      this.totalItems = []
      for (const items of trackDetails.data.content) {
          if (items && items.id) {
            this.totalItems.push(items.id);
          }
      }

      this.completedItems = []
      for (const content of trackDetails.data.content) {
          if (content &&
          content.meta &&
          content.meta.isComplete === true &&
              this.totalItems.includes(content.id)) {
                  this.completedItems.push(content.id);
          }
      }
    },
    async getRatingById() {
      const authToken = this.authToken || (await localforage.getItem('my_access_token'))
      this.$learnAdmin.getRatingById({ token: authToken, id: this.collection.id || '' }).then((res) => {
        this.ratingDetails = res || {}
        this.collection.rating = {}.hasOwnProperty.call(this.ratingDetails, 'averageRating') ? this.ratingDetails.averageRating : 0
        this.collection.ratingCount = {}.hasOwnProperty.call(this.ratingDetails, 'totalNumberOfUsers') ? this.ratingDetails.totalNumberOfUsers : 0
      })
    },
    getRouteInfo() {
      const params = { id: this.collectionId }
      return { name: 'Collection', params: params }
    },
    async setupCollection() {
      await localforage.removeItem('deep_link_id')
      await localforage.removeItem('deep_link_type')
      this.dataReady = false
      this.isDesktopPlatform = this.$platform === 'Desktop'
      this.collectionId = this.$route.params.id
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      const userAccess = this.user_access || (await localforage.getItem('my_user_access'))
      this.isAccessible = userAccess && userAccess.canAccessCourses
      const response = await this.$learnAdmin.getCollectionById({ token: this.authToken, id: this.collectionId })
      const trackResult = response.data
      this.getTrackProgress(trackResult);
      let ratingDetailsArray = []
      if (trackResult && trackResult.data && trackResult.data.content) {
        ratingDetailsArray = (await this.determineContentRating(trackResult.data.content)) || []
      }
      this.isContentAvailable = {}.hasOwnProperty.call(response, 'status') && response.status != 400 && response.status != 404 && response.status != 401
      if (this.isContentAvailable) {
        this.collection = trackResult.data.track
        this.collection.content = {}.hasOwnProperty.call(trackResult.data, 'content') ? trackResult.data.content : []
        this.collection.synopsis = {}.hasOwnProperty.call(this.collection, 'description') ? this.collection.description : ''
        this.collection.duration = {}.hasOwnProperty.call(this.collection, 'duration') ? this.setDuration() : ''
        this.collection.level = {}.hasOwnProperty.call(this.collection, 'collectionLevels') ? this.collection.collectionLevels : ''
        this.collection.title = {}.hasOwnProperty.call(this.collection, 'name') ? this.collection.name : ''
        this.collection.image = {}.hasOwnProperty.call(this.collection, 'image') ? this.collection.name : ''
        this.collection.meta = {}.hasOwnProperty.call(trackResult.data, 'meta') ? trackResult.data.meta : ''
        this.collection.publishDate = this.formatDate()
        this.collection.noOfItemsHidden = this.collection.items.length - this.collection.content.length
        this.collection.rating = {}.hasOwnProperty.call(this.ratingDetails, 'averageRating') ? this.ratingDetails.averageRating : 0
        this.collection.ratingCount = {}.hasOwnProperty.call(this.ratingDetails, 'totalNumberOfUsers') ? this.ratingDetails.totalNumberOfUsers : 0
        this.collection.provider = { timeToProgressSyncInHours: 0 }
        let skills = trackResult.data.skills
        this.collection.emsiSkills = []
        if (skills.length) {
          skills.map((skill) => {
            this.collection.emsiSkills.push(skill.name)
          })
        }
        this.collection.format = { name: 'TRACK' }
        await this.collection.content.map((item) => {
          item.level =
            item.format.name.toLowerCase() === 'credential'
              ? {}.hasOwnProperty.call(item, 'level')
                ? item.level
                : ''
              : {}.hasOwnProperty.call(item, 'courseLevels')
              ? item.courseLevels[0]
              : ''
          item.tracks = []
          item.publishedAt = {}.hasOwnProperty.call(item, 'publishedAt') ? item.publishedAt : ''
          item.requiredBy = {}.hasOwnProperty.call(item, 'requiredBy') ? item.requiredBy : ''
          item.content = {}.hasOwnProperty.call(item, 'content') ? item.content : ''
          item.isDateExpired = item.requiredBy ? (new Date() - new Date(item.requiredBy) > 0 ? true : false) : false
          item.scope = { isExternal: {}.hasOwnProperty.call(item, 'author') && item.format.name.toLowerCase() != 'asset' }
          item.launchBtn = item.scope.isExternal ? 'Open' : item.progress > 0 && item.progress < 100 ? 'Resume' : 'Launch'
          item.provider = item.scope.isExternal ? { name: {}.hasOwnProperty.call(item, 'source') ? item.source : '' } : item.provider
          item.timeToProgressSyncInHours = {}.hasOwnProperty.call(item.provider, 'timeToProgressSyncInHours') ? item.provider.timeToProgressSyncInHours : 0
          item.duration = !{}.hasOwnProperty.call(item, 'duration')
            ? null
            : item.duration < 3600
            ? `${Math.round(item.duration / 60)} min`
            : `${Math.round(item.duration / 3600)} hrs`
          item.rating =
            ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === item.id)
              ? ratingDetailsArray.filter((rating) => rating.entityId == item.id)[0]?.avgRating
              : 0
          if (item.dueDate) {
            const dueDate = item.dueDate
            delete item.dueDate;
            const formattedToday = moment(dueDate).tz('America/New_York').format('DD MMM YYYY')
            if (item.timeToProgressSyncInHours > 0) {
              moment(dueDate).add(item.timeToProgressSyncInHours, 'hours').isAfter(moment().tz('America/New_York')) && item.progress < 100
                ? (item.dueDate = formattedToday)
                : (item.pastDueDate = formattedToday)
            } else {
              moment(dueDate).isAfter(moment().tz('America/New_York')) ? (item.dueDate = formattedToday) : (item.pastDueDate = formattedToday)
            }
          }
          if (item.format.name.toLowerCase() === 'asset') {
            item.image = { uri: this.fetchAssetThumbnail(item.inventoryType?.name) }
            item.type = { name: item.inventoryType.name }
            item.applications = item.applications
              ? item.applications.map((application) => {
                  return { name: application.name, src: `${this.assetMediaUrl}media-container/${application.image}` }
                })
              : []
          }
        })
      }
      this.dataReady = true
    },
    determineContentRating(CollectionContentValue) {
      var contentIdList = []
      CollectionContentValue?.map((item) => {
        contentIdList.push(item.id)
      })
      if (contentIdList && contentIdList.length > 0) {
        const contentPayload = {
          token: this.authToken,
          body: contentIdList
        }
        return this.$learnAdmin.getContentRatingById(contentPayload)
      }
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    async getUpdatedCollectionData() {
      this.dataReady = false
      let collection = await this.$learnAdmin.getContentById({ token: this.authToken, id: this.collectionId })
      collection = collection.data
      this.collection = { ...this.collection, ...collection }
      this.collection.ratingCount = 0
      this.dataReady = true
    },
    onScroll() {
      this.scroll = this.scroll + 1
    },
    popoverOn(popoverType) {
      this.isShareOpen = popoverType === 'share'
      this.isMoreOpen = popoverType === 'more'
    },
    popoverOff(popoverType) {
      this.isShareOpen = !popoverType === 'share'
      this.isMoreOpen = !popoverType === 'more'
    },
    async getText(uri) {
      const response = await fetch(uri)
      const content = await response.text()
      return marked.parse(content)
    },
    setDuration() {
      return this.collection.duration.value < 3600 ? `${Math.round(this.collection.duration.value / 60)} min` : `${Math.round(this.collection.duration.value / 3600)} hrs`
    },
    formatDate() {
      let returnDate = ''
      if (this.collection.publishedAt) {
        let date = new Date(this.collection.publishedAt)
        date = date.toDateString().split(' ')
        returnDate = `${date[1]} ${date[3]}`.toUpperCase()
        return returnDate
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-track {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
    margin-left: 1.5rem;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
    span {
      font-family: 'Arial';
      font-size: 14px;
      font-weight: normal;
      color: #2d2d2d;
    }
  }
.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-percentage {
  width: 176px;
  padding-top: 5px;
  text-align: center;
  font: normal normal normal 14px/16px Arial;
  color: #2D2D2D;
}
.non-white-background {
  background: #efefef !important;
}
.desktop-background {
  background: transparent !important;
}

.desktop-collection-container {
  padding: 2rem 3.8rem;
}

.mobile-collection-container {
  padding: 2rem 1rem;
}

.coursera-link {
  padding: 0.5rem 4rem;
  background: var(--ion-color-secondary);
  color: var(--ion-color-tertiary);
  border: 1px solid var(--ion-color-secondary);
  border-radius: 2rem;
  text-decoration: none;
  font-family: 'Futura PT Book', sans-serif;
  font-size: 1rem;
}

.back {
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Arial';
  color: #5c5c5c;
  display: block;
  text-align: left;
  svg {
    position: relative;
    top: 5px;
  }
}

.accessible-button {
  text-decoration: unset;
  background-color: unset;
  padding: unset;
}

.collection-info {
  display: flex;
  margin-top: 2rem;
  gap: 60px;
}

.collection-img-shape {
  position: relative;
  max-width: 165px;
  border: 4px solid var(--ion-color-white);
  overflow: hidden;
  width: 165px;
  height: 165px;
  border: 5px solid var(--ion-color-white);
  background-size: contain;
  border-radius: 20px;
  img {
    width: 165px;
    height: 165px;
    object-fit: cover;
  }
}

.meta {
  display: flex;
  gap: 24px;
  text-transform: uppercase;
  color: var(--ion-color-black);
  font-size: 0.8rem;
  font-family: Arial;
  font-weight: bold;
  letter-spacing: 1.2px;
  .format {
    padding: 4px 0.5rem;
    border-radius: 10px;
    background: #e0e1e1;
    font-family: Arial;
    font-size: 14px;
    min-height: 22px;
    text-transform: capitalize;
    color: #2d2d2d;
  }
  .g-button--primary {
    width: 7rem;
    margin-right: 1rem;
    text-transform: capitalize;
  }
  .progress {
    position: relative;
    right: 0px;
    display: flex;
    align-items: center;
    max-height: 22px;
    gap: 6px;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
    span {
      font-family: 'Arial';
      font-size: 14px;
      font-weight: normal;
      color: #2d2d2d;
    }
    .progress-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      width: 240px;
    }
  }

  .progress:hover .progress-tooltip-text {
    visibility: visible;
  }
}

.collection-about-container {
  margin-top: 2.5rem;
}

.title {
  margin: 1.5rem 0rem;
  text-align: left;
  font-size: 48px;
  font-family: 'Futura PT Demi';
  color: #000000;
  line-height: 45px;
}

.meta-info {
  display: flex;
  gap: 24px;
  font-size: 14px;
  margin-bottom: 1.5rem;
  align-items: center;
  .collection-rating {
    display: flex;
    cursor: pointer;
    .display-star-mobile {
      display: none;
    }
  }
  .star-rating,
  .rating {
    font-family: Arial;
    padding-left: 5px;
    color: #2d2d2d;
  }
  .rating-count {
    font-family: Arial;
    color: #2d2d2d;
  }
  .duration,
  .level,
  .content-count-title {
    font-family: Arial;
    color: #2d2d2d;
  }
  .content-count-icon {
    border-right: unset;
    padding-right: unset;
    height: 20px;
    min-width: 22px;
    border: 2px solid black;
    border-bottom-left-radius: 8px;
    .svg-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .content-count {
    display: flex;
    gap: 8px;
  }
}

.meta-action {
  display: flex;
  gap: 8px;
  align-items: center;
  .social-share,
  .more-action {
    width: 40px;
    height: 40px;
    background: var(--ion-color-white);
    border: 1px solid var(--ion-color-light-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon {
      width: 1.5rem;
    }
  }
  .more-container,
  .share-container {
    position: relative;
    .more-tooltip-text,
    .share-tooltip-text {
      visibility: hidden;
      text-align: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000001f;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Arial;
      top: -30px;
      color: #000000de;
      right: 0;
    }
  }
  .share-container:hover .share-tooltip-text {
    visibility: visible;
  }
  .more-container:hover .more-tooltip-text {
    visibility: visible;
  }
}

.social-share-popover,
.more-popover {
  position: absolute;
  box-shadow: 0px 0px 12px #0000001f;
  border-radius: 4px;
  background: #ffffff;
  padding: 6px;
  z-index: 10;
  opacity: 0;
  margin-top: 10px;
  animation-name: slideinmore;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  text-align: left;
}
 .track-content {
    max-width: 1700px;
    padding: 2rem 3.8rem;
    margin-left: unset;
    margin-right: unset;
    margin-inline: auto;
 }
@keyframes slideinmore {
  from {
    width: 0;
    font-size: 0px;
    left: 40px;
  }
  to {
    width: 256px;
    opacity: 1;
    font-size: 14px;
    left: -217px;
  }
}

@media screen and (min-width: 1025px) {
  .carouselMobile {
    display: none !important;
  }
}

@media screen and (max-width: 1366px) {
  .title {
    margin: 1.25rem 0;
  }
  .collection-img-shape {
    display: none;
  }
  @keyframes slideinmore {
    from {
      width: 0;
      font-size: 0px;
    }
    to {
      width: 256px;
      opacity: 1;
      font-size: 14px;
      left: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .carouselDesktop {
    display: none !important;
  }
  .carouselMobile {
    display: block !important;
  }
}

@media screen and (max-width: 484px) {
  .title {
    font-size: 34px;
    margin: 0.5rem 0;
  }
  .meta-info {
    margin-bottom: 1rem;
    .collection-rating {
      display: inline;
      .star-rating {
        display: none;
      }
      .display-star-mobile {
        display: inline;
      }
    }
  }
  .meta-action {
    flex-wrap: wrap;
    .progress {
      left: unset;
      top: unset;
    }
  }
}

@media screen and (max-width: 375px) {
  .meta-info {
    gap: 5px;
    .duration,
    .level,
    .rating-count {
      padding-right: 5px;
    }
  }
}
</style>
