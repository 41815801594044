var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edge-capstone-upload-container",
      class: _vm.isDesktopPlatform ? "desktop-padding" : ""
    },
    [
      _c("div", { staticClass: "capstone-page-header" }, [
        _c(
          "div",
          { staticClass: "page-name" },
          [
            _c("svg-icon", {
              attrs: {
                "icon-class": "back_arrow",
                "class-name": "g-icon--small g-margin__right--medium"
              },
              on: { click: _vm.goBack }
            }),
            _vm._v(
              " " +
                _vm._s(!_vm.isEditMode ? "Upload" : "Edit") +
                " Capstone Project "
            )
          ],
          1
        )
      ]),
      _vm.dataReady
        ? _c(
            "ion-card",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errorMessage,
                      expression: "errorMessage"
                    }
                  ],
                  staticClass: "error-msg"
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              ),
              _c(
                "ion-grid",
                [
                  _c(
                    "ion-row",
                    [
                      _c(
                        "ion-col",
                        [
                          _c("div", { staticClass: "section-title required" }, [
                            _vm._v("Capstone Project Name")
                          ]),
                          _c("ion-input", {
                            ref: "name",
                            attrs: { required: "", value: _vm.capstone.name },
                            on: { ionChange: _vm.handleNameInput }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "ion-row",
                    [
                      _c(
                        "ion-col",
                        [
                          _c("div", { staticClass: "section-title" }, [
                            _vm._v("Description of Your Creation")
                          ]),
                          _c("ion-textarea", {
                            ref: "description",
                            attrs: {
                              autofocus: "",
                              rows: "10",
                              required: "",
                              value: _vm.capstone.description,
                              placeholder:
                                "A value proposition is a promise of value to be delivered, communicated, and acknowledged."
                            },
                            on: { ionChange: _vm.handleDescriptionInput }
                          })
                        ],
                        1
                      ),
                      _c("ion-col", [
                        _c("div", { staticClass: "section-title required" }, [
                          _vm._v("Capstone Files")
                        ]),
                        _c("input", {
                          ref: "upload-input",
                          staticClass: "upload-input",
                          attrs: {
                            type: "file",
                            accept: _vm.capstone.file.allowedExtensions
                          },
                          on: { change: _vm.handleClick }
                        }),
                        _c("div", { staticClass: "upload-info" }, [
                          _vm._v(
                            "We encourage you to add files that show users the value and context of your project"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "drop-zone",
                            on: {
                              drop: _vm.handleDrop,
                              dragover: _vm.handleDragover,
                              dragenter: _vm.handleDragover
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "drop-prompt" },
                              [
                                _c("ion-icon", {
                                  staticClass: "ion-icon-size",
                                  attrs: { name: "cloud-upload-outline" }
                                }),
                                _c("div", { staticClass: "drop-prompt-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.capstone.file.allowedExtensions.replaceAll(
                                        ",",
                                        ", "
                                      )
                                    ) + " (Max 100MB)"
                                  )
                                ])
                              ],
                              1
                            ),
                            _c("div", [
                              _c("p", { staticClass: "drop-file-text" }, [
                                _vm._v("Drop file here to upload or")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "upload-btn",
                                  on: { click: _vm.handleUpload }
                                },
                                [_vm._v("Choose a file")]
                              )
                            ]),
                            _vm.capstone.file.name &&
                            _vm.fileUploadStatus == "U"
                              ? _c("ion-progress-bar", {
                                  attrs: {
                                    color: "secondary",
                                    type: "indeterminate"
                                  }
                                })
                              : _vm._e(),
                            _vm.capstone.file.name
                              ? _c(
                                  "ion-row",
                                  { staticClass: "file-details add-space-div" },
                                  [
                                    _c(
                                      "ion-col",
                                      {
                                        staticClass: "file-meta-data",
                                        attrs: { "size-xs": "12" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "section-subheading" },
                                          [
                                            _c("svg-icon", {
                                              staticClass: "g-icon--small",
                                              attrs: {
                                                "icon-class": "icon-file"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "asset-zip-name" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.capstone.file.name)
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "section-meta-info" },
                                          [
                                            _vm.fileUploadStatus == "U"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "section-meta-text"
                                                  },
                                                  [_vm._v(" Uploading... ")]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.notificationMessage.length
                ? _c("div", { staticClass: "notification" }, [
                    _vm._v("Error: " + _vm._s(_vm.notificationMessage))
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "submit" },
                [
                  _c(
                    "ion-button",
                    {
                      staticClass:
                        "ion-button-primary ion-text-capitalize submit-button",
                      attrs: {
                        shape: "round",
                        color: "primary",
                        disabled: _vm.isSubmitting
                      },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.capstone.rejectionReason == null
                            ? "Submit"
                            : "Update"
                        )
                      )
                    ]
                  ),
                  _c(
                    "ion-button",
                    {
                      staticClass: "ion-text-capitalize cancel-button",
                      attrs: { shape: "round", disabled: _vm.isSubmitting },
                      on: { click: _vm.goBack }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.loadingMessage.length > 0
        ? _c("div", { staticClass: "custom-toast custom-toast-loading" }, [
            _c(
              "div",
              { staticStyle: { "margin-top": "2rem" } },
              [
                _c("ion-progress-bar", {
                  attrs: { color: "secondary", type: "indeterminate" }
                })
              ],
              1
            ),
            _c("div", [
              _c("span", { staticClass: "status-message-normal" }, [
                _vm._v(_vm._s(_vm.loadingMessage) + "...")
              ])
            ]),
            _c("div", { staticClass: "toast-footer" })
          ])
        : _vm._e(),
      _vm.fileUploadStatus === "U" && _vm.uploadPercentage < 100
        ? _c("div", { staticClass: "custom-toast custom-toast-info" }, [
            _c("div", { staticClass: "status-message" }, [
              _c("span", { staticClass: "status-message-bold" }, [
                _vm._v("Step 1 of 2")
              ]),
              _c("span", { staticClass: "status-message-normal" }, [
                _vm._v(
                  ": File upload in progress " +
                    _vm._s(_vm.uploadPercentage) +
                    "%"
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "cancel-btn" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass: "cancel-btn-text",
                    attrs: { shape: "round", color: "tertiary", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.cancelUploadAsset()
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "toast-footer" })
          ])
        : _vm.fileUploadStatus === "C"
        ? _c("div", { staticClass: "custom-toast custom-toast-success" }, [
            _c(
              "div",
              { staticClass: "toast-close-icon" },
              [
                _c("svg-icon", {
                  staticClass: "g-icon--small",
                  attrs: { "icon-class": "close" },
                  on: {
                    click: function($event) {
                      _vm.fileUploadStatus = ""
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "toast-success-icon" },
              [_c("svg-icon", { attrs: { "icon-class": "icon_success" } })],
              1
            ),
            _vm._m(0),
            _c("div", { staticClass: "toast-footer" })
          ])
        : _vm.fileUploadStatus === "S" || _vm.uploadPercentage == 100
        ? _c("div", { staticClass: "custom-toast custom-toast-info" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "cancel-btn" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass: "cancel-btn-text",
                    attrs: { shape: "round", color: "tertiary", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.cancelUploadAsset()
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "toast-footer" })
          ])
        : _vm.fileUploadStatus === "F"
        ? _c("div", { staticClass: "custom-toast custom-toast-failed" }, [
            _c(
              "div",
              { staticClass: "toast-close-icon" },
              [
                _c("svg-icon", {
                  staticClass: "g-icon--small",
                  attrs: { "icon-class": "close" },
                  on: {
                    click: function($event) {
                      _vm.fileUploadStatus = ""
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "toast-failed-icon" },
              [_c("svg-icon", { attrs: { "icon-class": "icon-alert-black" } })],
              1
            ),
            _vm._m(2),
            _c(
              "div",
              { staticClass: "cancel-btn" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass: "cancel-btn-text",
                    attrs: { shape: "round", color: "tertiary", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.tryAgain()
                      }
                    }
                  },
                  [_vm._v("Try again")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "toast-footer" })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-message" }, [
      _c("span", { staticClass: "status-message-normal" }, [
        _vm._v("Success! Your file has been uploaded.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-message" }, [
      _c("span", { staticClass: "status-message-bold" }, [
        _vm._v("Step 2 of 2")
      ]),
      _c("span", { staticClass: "status-message-normal" }, [
        _vm._v(": File scan in progress")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status-message" }, [
      _c("span", { staticClass: "status-message-normal" }, [
        _vm._v("Your file did not upload successfully")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }