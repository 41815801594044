<template>
  <div class="profile main-content" :class="isDesktop ? 'profile-desktop' : ''">
    <ProfileSideNav :profileDetails="profile" :selectedTab="selectedTab" @setSelectedTab="setSelectedTab($event)"></ProfileSideNav>
    <div :class="isDesktop ? 'profile-content' : 'profile-content profile-content-tab'">
      <div class="profile-tab-header" v-if="isDesktop">
        <h2>{{ tabHeaderTitle[selectedTab] }}</h2>
      </div>
      <ProfileSkills v-if="selectedTab == 'skills'" />
      <ProfileDetails v-if="selectedTab == 'profileDetails'" :profileDetails="profile" />
      <KeepAlive>
        <LearningHistory v-if="selectedTab == 'learningHistory' && showLearningHistory" />
      </KeepAlive>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import ProfileSideNav from '@/views/profile/components/ProfileSideNav'
import ProfileSkills from '@/views/profile/components/ProfileSkills'
import ProfileDetails from '@/views/profile/components/ProfileDetails'
import LearningHistory from '@/views/profile/components/LearningHistory'

export default {
  name: 'Profile',
  components: { ProfileSideNav, ProfileSkills, ProfileDetails, LearningHistory },
  props: {},
  data() {
    return {
      authToken: '',
      skills: [],
      experiences: [],
      joinedGroups: [],
      groups: [],
      achievements: [
        {
          imgSrc: require('@/assets/images/badge-airplane-yellow.png'),
          badgeTitle: 'Badge Title',
          date: { month: 1, day: 1, year: 2020 }
        }
      ],
      learningStats: {},
      isLarge: true,
      profile: {
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        profileDetails: {},
        avatarImage: require('@/assets/images/no-avatar.png'),
        activeCount: 6,
        dashCount: 6,
        stats: {
          learningPoints: 0,
          followers: 0,
          followings: 0
        },
        licenseType: '',
      },
      selectedTab: 'skills',
      tabHeaderTitle: { learningHistory: 'Learning history', skills: 'Skills', profileDetails: 'Profile details' },
      isDesktop: false,
      showLearningHistory: false
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'name', 'user_guid', 'firstname', 'lastname'])
  },
  created() { },
  async mounted() {
    this.$eventBus.$on('profile-sub-link-clicked', this.setSelectedTab);
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.showLearningHistory = enabledFeatureFlags['learn_learning_history_enabled'] || false
  },
  async activated() {
    if (this.$route.params.id) {
      this.setSelectedTab(this.$route.params.id)
    }
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.profile.name = this.name || (await localforage.getItem('my_name'))
    this.profile.firstName = this.firstname || (await localforage.getItem('my_firstname'))
    this.profile.lastName = this.lastname || (await localforage.getItem('my_lastname'))
    this.profile.email = this.email || (await localforage.getItem('my_username'))
    const summary = await this.$learnAdmin.getUserLearningProgressSummary({ token: this.authToken })
    if ({}.hasOwnProperty.call(summary, 'currentRole')) {
      this.profile.role = summary.currentRole.name
    }
    this.profile.jobTitle = summary.jobTitle || ''
    this.isDesktop = window.innerWidth > 1024 ? true : false
  },
  methods: {
    capitalize(str) {
      return str[0].toUpperCase() + str.slice(1)
    },
    async setSelectedTab(tab = 'skills') {
      if (['profileDetails', 'learningHistory', 'skills'].includes(tab)) {
        this.selectedTab = tab
        await localforage.setItem('profile-side-nav-changed', tab) 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('profile') {
  padding: 0 3.8rem;
  background: #efefef;
  @media (max-width: 1024px) {
    padding: 0 2rem 2rem 2rem;
  }
  @media (max-width: 500px) {
    padding: 0 0.5rem 2rem 0.5rem;
  }
  &.profile-desktop {
    display: flex;
  }

  .profile-content-tab {
    width: 100% !important;
  }
  .profile-content {
    width: calc(100% - 410px);
    margin-top: 3rem;
    margin-left: 428px;
    @media (max-width: 1024px) {
      margin-left: 0;
      margin-top: 0.5rem;
    }
    .profile-tab-header {
      h2 {
        font: normal normal normal 34px/40px 'Futura PT Demi';
        text-align: left;
        margin-bottom: 2rem;
        color: #000000;
      }
    }
  }
}
</style>
