<template>
  <ion-card class="personal-details">
    <div :class="isDesktopPlatform ? 'personal-info-header personal-info-border' : 'personal-info-header'">
      <h3>Personal info</h3>
    </div>
    <div class="personal-info-content">
      <label>Name</label>
      <p>{{ profileDetails.name }}</p>
      <label
        >ProEdge role
        <span class="typeahead-tooltip">
          <img src="@/assets/images/svgs/icon-info.svg" class="info-icon" alt="Info" />
          <span class="typeahead-tooltiptext">{{ roleTooltipText }}</span>
        </span></label
      >
      <p>{{ profileDetails.role }}</p>
      <label>Email</label>
      <p>{{ profileDetails.email }}</p>
      <label>Job title</label>
      <p>{{ profileDetails.jobTitle }}</p>
    </div>
  </ion-card>
</template>
  
  <script>
export default {
  name: 'ProfileDetails',
  props: {
    profileDetails: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  data() {
    return {
      isDesktopPlatform: false,
      roleTooltipText:
        'This is the role assigned to you by your organization. To make changes to your role reach out directly to your Admin or contact ProEdge support via the Help center.'
    }
  },
  created() {},
  computed: {},
  async mounted() {
    this.isDesktopPlatform = this.$platform == 'Desktop'
  },
  methods: {}
}
</script>
  
  <style lang="scss" scoped>
.personal-details {
  padding: 3rem 4rem;
  text-align: left;
  border-radius: 1.125rem;
  @media (max-width: 500px) {
    padding: 1.5rem 2rem;
  }
  @media (max-width: 1024px) {
    margin-top: 1rem;
  }
  .personal-info-header {
    margin-bottom: 1.5rem;
    @media (max-width: 500px) {
      margin-bottom: 0.5rem;
    }
    h3 {
      font: normal normal normal 24px/30px 'Futura PT Demi';
      margin: 0;
      padding-bottom: 1.5rem;
      color: #000000;
    }
    &.personal-info-border {
      border-bottom: 2px solid #efefef;
    }
  }
  .personal-info-content {
    label {
      font: normal normal bold 14px/30px Arial;
      color: #000000;
      .typeahead-tooltip {
        position: relative;
        display: inline-flex;
        align-items: center;
        top: 2px;
        img {
          height: 0.875rem;
          width: 0.875rem;
        }
      }

      .typeahead-tooltip .typeahead-tooltiptext {
        text-align: center;
        font: normal normal normal 0.625rem Arial;
        color: #000000de;
        width: 13rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #0000001f;
        border-radius: 4px;
        visibility: hidden;
        position: absolute;
        z-index: 1;
        opacity: 0;
        top: 1.5rem;
        padding: 5px;
        left: -5rem;
      }

      .typeahead-tooltip:hover .typeahead-tooltiptext {
        visibility: visible;
        opacity: 1;
      }
    }
    p {
      font: normal normal normal 16px/22px Arial;
      margin: 0 0 2rem 0;
    }
  }
}
</style>
  