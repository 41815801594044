<template>
  <div class="edge-login">
    <div class="edge-login__svg-wrapper">
      <svg-icon icon-class="small-logo" class-name="logo--size" :class="this.$platform === 'Desktop' ? 'on-desktop' : 'on-mobile'" />
    </div>
    <div class="edge-login__error-message">{{ errorMessage }}</div>

    <div class="edge-login__input">
      <ion-item class="ion-no-padding">
        <ion-input
          placeholder="Email Address"
          autofocus
          @ionChange="
            $event.target.value = $event.target.value ? $event.target.value.trim() : ''
            user.email = $event.target.value
            user.username = $event.target.value
          "
          :disabled="isInputDisabled"
          ref="email"
        ></ion-input>
      </ion-item>
    </div>
    <ion-button shape="round" color="primary" class="ion-button-primary" style="text-transform: capitalize; --box-shadow: none" @click="onResetPassword" :disabled="isInputDisabled"
      >Reset Password</ion-button
    >
    <div class="g-type-small" style="margin-top: 0.5rem">
      <span class="edge-login__link" @click="onLogin">Login</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { validEmail } from '../../../src/utils/validate.js'

export default {
  name: 'ResetPassword',
  props: {},
  data() {
    return {
      user: {
        email: ''
      },
      isInputDisabled: false,
      errorMessage: '',
      placeholderErrorMessage: 'You have entered an invalid email'
    }
  },
  created() {},
  mounted() {
    analytics.setVarAndTrack({ c: 'onboarding', a: 'loaded', l: 'reset-password' })
  },
  methods: {
    ...mapActions('user', ['setAccessToken']),
    ...mapActions('environment', ['setCloudEnvInfo']),
    async onResetPassword() {
      this.toggleInputs()
      this.setErrorMessage()

      if (this.validateEmailSpamming()) {
        this.setErrorMessage('You have entered multiple emails')
        this.toggleInputs()
        return
      }

      if (!this.validateFields()) {
        this.setErrorMessage(this.placeholderErrorMessage)
        this.toggleInputs()
        return
      }

      const isCloudEnvSet = await this.setCloudEnvData()
      if (!isCloudEnvSet) {
        this.setErrorMessage(this.placeholderErrorMessage)
        this.toggleInputs()
        return
      }

      const response = await this.resetPassword()
      if (!response) {
        this.setErrorMessage(this.placeholderErrorMessage)
        this.toggleInputs()
        return
      }
      this.clearInputs()
      this.toggleInputs()
      this.$platform === 'Mobile' ? this.$router.push('/change-password') : this.$emit('next', 'ChangePassword')
    },
    toggleInputs() {
      this.isInputDisabled = !this.isInputDisabled
    },
    clearInputs() {
      this.$refs.email.value = null
    },
    setErrorMessage(message) {
      this.errorMessage = message
    },
    validateEmailSpamming() {
      const email = this.user.email.trim()
      return email.split(',').length > 1 || email.split(' ').length > 1 || email.split('@').length > 2
    },
    validateFields() {
      return validEmail(this.user.email)
    },
    async resetPassword() {
      return this.$learnAdmin.resetPassword(this.user).then((res) => {
        return res
      })
    },
    onLogin() {
      this.$platform === 'Mobile' ? this.$router.push('/login') : this.$emit('next', 'Login')
    },
    async setCloudEnvData() {
      const response =
        process.env.VUE_APP_USE_FFAPI === 'true'
          ? await this.$learnAdmin.getCloudEnvByUserEmailAddress(this.user.email)
          : await this.$learnAdmin.getCloudEnvFromDefaultEnv(this.user.email)
      if (!response.data.cloudEnv) {
        return false
      }
      await this.setCloudEnvInfo(response.data.cloudEnv)
      await this.$learnAdmin.updateEnvFromLocalForage()
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
@include block('edge-login') {
  @include element('svg-wrapper') {
    padding: 8% 8% 8% 8%;
    @include block('svg') {
      width: 100%;
      height: 100%;
    }
  }

  @include element('input') {
    padding: 0% 8% 8% 8%;
  }
  @include element('link') {
    color: var(--ion-color-tertiary);
    font-weight: bold;
    cursor: pointer;
  }
  @include element('error-message') {
    color: var(--ion-color-status);
  }
}

.edge-login {
  .logo--size {
    width: 100%;
    height: 100%;
  }
}

.on-desktop {
  width: 40% !important;
}

.on-mobile {
  width: 80% !important;
}
</style>
