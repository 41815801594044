<template>
  <div>
    <button @click.stop="toggleBookmarked" :aria-label="itemTitle" class="accessible-button">
      <svg-icon :icon-class="bookmarked ? 'bookmark-black-selected' : 'bookmark-black'" class-name="g-icon--small g-clickable" />
    </button>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'

export default {
  name: 'Bookmark',
  props: {
    isBookmarked: {
      type: Boolean,
      required: false,
      default: false
    },
    parentComponent: {
      type: Object,
      default: () => {
        return {
          name: '{no-title}',
          id: '{no-title}'
        }
      }
    },
    isGAAsset: {
      type: Object,
      required: false,
      default: () => {
        return {
          enabled: false,
          category: ''
        }
      }
    },
    color: {
      type: String,
      default: 'blue'
    },
    assetId: { type: String, required: false, default: '' },
    assetType: { type: String, required: false, default: 'artifacts' },
    meta: { type: Object, required: false, default: () => {} },
    itemTitle: {type: String, required: false, default: "Click to Bookmark"}
  },
  data() {
    return {
      bookmarked: false,
      authToken: ''
    }
  },
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.bookmarked = this.meta && this.meta.isBookmarked ? this.meta.isBookmarked : this.isBookmarked
  },
  computed: {
    ...mapGetters(['auth_token', 'gcs_token'])
  },
  methods: {
    async toggleBookmarked() {
      if (this.assetId && this.assetType) {
        !this.bookmarked ? this.addBookmark() : this.removeBookmark()
        this.bookmarked = !this.bookmarked
        analytics.setVarAndTrack({ c: this.assetType, a: 'bookmark', l: this.assetId })
        await this.GAEventTrack({ bookmarked: this.bookmarked, assetId: this.assetId })
        EventBus.$emit('reload-content', { assetId: this.assetId, isThisBookmarked: this.bookmarked })
      } else {
        console.warn('error in bookmark component')
      }
    },
    async addBookmark() {
      const body = this.assetType === 'automation' ? { entityId: this.assetId, entityType: this.assetType } : { assetId: this.assetId, assetType: this.assetType }
      const payload = { token: this.authToken, body: body }
      if (
        this.assetType === 'artifacts' ||
        this.assetType === 'credentialcontents' ||
        this.assetType === 'credentials' ||
        this.assetType === 'skill' ||
        this.assetType === 'automation' ||
        this.assetType === 'track'
      ) {
        await this.$learnAdmin.addBookmark(payload)
      } else {
        await this.$learnAdmin.addBotBookmark(payload)
      }
       if(this.$route.path.includes('recommended') || this.$route.path.includes('bookmarks') || this.$route.path.includes('mylearning')){
          EventBus.$emit('edit-bookmark',{id:this.assetId,isBookmarked:true})
        }
    },
    async removeBookmark() {
      const payload = this.assetType === 'automation' ? { token: this.authToken, entityId: this.assetId } : { token: this.authToken, assetId: this.assetId }
      if (
        this.assetType === 'artifacts' ||
        this.assetType === 'credentialcontents' ||
        this.assetType === 'credentials' ||
        this.assetType === 'skill' ||
        this.assetType === 'automation' ||
        this.assetType === 'track'
      ) {
        await this.$learnAdmin.removeBookmark(payload)
      } else {
        await this.$learnAdmin.removeBotBookmark(payload)
      }
       if(this.$route.path.includes('recommended') || this.$route.path.includes('bookmarks') || this.$route.path.includes('mylearning')){
          EventBus.$emit('edit-bookmark',{id:this.assetId,isBookmarked:false})
        }
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        const learnerId = await localforage.getItem('my_user_id')
        const tenantGuid = await localforage.getItem('my_tenant_guid')
        const itemInfo = {
          'learner-id': learnerId,
          'tenant-guid': tenantGuid,
          action: `Asset ${payload.bookmarked ? 'bookmark' : 'unbookmark'}`,
          asset: {
            id: payload.assetId
          }
        }
        const event_name = this.isGAAsset.enabled ? 'Asset' : 'Bookmark'
        const event_category = this.isGAAsset.enabled ? this.isGAAsset.category : payload.bookmarked ? 'Bookmark' : 'Unbookmark'
        const event_data = this.isGAAsset.enabled ? JSON.stringify(itemInfo) : payload.assetId
        bootstrap().then(() => {
          this.$gtag.event(event_name, {
            event_category: event_category,
            event_label: event_data,
            value: event_data
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.g-icon--small {
  height: 25px;
  width: 25px;
}

.accessible-button {
  color: unset;
  text-decoration: unset;
  background-color: unset;
}
</style>
