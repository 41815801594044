const state = {
  demoCapstoneCompleted: false
}

const mutations = {
  SET_DEMO_CAPSTONE_COMPLETED: state => {
    state.demoCapstoneCompleted = true
  }
}

const actions = {
  setDemoCapstoneCompleted({ commit }) {
    commit('SET_DEMO_CAPSTONE_COMPLETED')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
