var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.isDesktopPlatform ? "container-desktop" : "container-mobile" },
    [
      !_vm.dataReady
        ? _c("ion-spinner", {
            style: _vm.isDesktopPlatform
              ? "margin-top: 2rem;"
              : "margin-top: 1rem;",
            attrs: { color: "#1f3370" }
          })
        : _vm._e(),
      _vm.dataReady && _vm.items.length
        ? _c("div", [
            _c(
              "div",
              { staticClass: "home-item-container" },
              _vm._l(_vm.items, function(item, index) {
                return _c("UniversalCard", {
                  key: item.id,
                  attrs: {
                    item: item,
                    index: index,
                    itemsLength: _vm.items.length,
                    tab: "HomeInProgress"
                  },
                  on: { "delete-item": _vm.deleteExternalItem }
                })
              }),
              1
            ),
            _vm.data.totalPages > 0
              ? _c(
                  "div",
                  { staticClass: "inprogress-pagination" },
                  [
                    _c("paginate", {
                      attrs: {
                        "page-count": _vm.data.totalPages,
                        "page-range": 3,
                        "margin-pages": 2,
                        "click-handler": _vm.onPageClick,
                        "container-class": "pagination",
                        "page-class": "pagination-item",
                        "page-link-class": "pagination-item-link",
                        "prev-text": "&lsaquo;",
                        "prev-class": "pagination-nav",
                        "prev-link-class": "pagination-nav-link",
                        "next-text": "&rsaquo;",
                        "next-class": "pagination-nav",
                        "next-link-class": "pagination-nav-link",
                        "hide-prev-next": true
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.dataReady && !_vm.items.length
        ? _c(
            "div",
            {
              class: _vm.isDesktopPlatform
                ? "home-item-container inprogress-empty-container"
                : "inprogress-empty-container",
              style: _vm.isDesktopPlatform
                ? "margin-top: 2rem;"
                : "margin-top: 1rem;"
            },
            [
              _c("div", { staticClass: "inprogress-empty-container-grid" }, [
                _c("div", [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("Take the next step toward your learning goals.")
                  ]),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v(" Check out your "),
                    _c(
                      "span",
                      {
                        staticClass: "link-btn g-clickable",
                        on: { click: _vm.goToLearningPlan }
                      },
                      [_vm._v("learning plan")]
                    ),
                    _vm._v(
                      " to get started or discover what's next. Or visit the "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "link-btn g-clickable",
                        on: { click: _vm.goToExplore }
                      },
                      [_vm._v(" Explore ")]
                    ),
                    _vm._v(" section to find more content that interests you. ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "action-btns" },
                    [
                      _c(
                        "ion-button",
                        {
                          staticClass: "learning-plan-btn",
                          attrs: { shape: "round", color: "primary" },
                          on: { click: _vm.goToLearningPlan }
                        },
                        [_vm._v("Learning Plan")]
                      ),
                      _c(
                        "ion-button",
                        {
                          staticClass: "explore-btn",
                          attrs: { shape: "round", color: "primary" },
                          on: { click: _vm.goToExplore }
                        },
                        [_vm._v("Explore")]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._m(0)
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inprogress-empty-image" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/home-inprogress-empty.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }