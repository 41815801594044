<template>
  <div>
    <div class="media-modal__backdrop"></div>
    <div class="media-modal">
      <div>
        <img v-if="item.type === 'image'" :src="item.src" alt="" class="media" />
        <video v-if="item.type === 'video'" controls :autoplay="videoPreview" poster preload="auto" class="media">
          <source :src="item.src" type="video/mp4" />
        </video>
        <ion-grid class="ion-no-padding img-caption">
          <ion-row class="ion-no-padding">
            <ion-col class="ion-no-padding" size="8">{{ item.caption }}</ion-col>
            <ion-col class="ion-no-padding svg-sty" size="4">
              <svg-icon v-if="media.length > 0 && mediaCurrent > 1" @click="handleItemClick(-1)" icon-class="icon-arrow-left-white" class="g-icon--xsmall g-clickable"></svg-icon>
              <span class="pagination"> {{ mediaCurrent }} of {{ media.length }} </span>
              <svg-icon v-if="mediaCurrent < media.length" @click="handleItemClick(1)" icon-class="icon-arrow-right-white" class="g-icon--xsmall g-clickable"></svg-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div class="close-icon">
        <svg-icon icon-class="filter-close-white" class="g-icon--msmall g-clickable" style="transform: rotate(45deg)" @click="onCancel"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssetMediaModal',
  components: {},
  props: {
    media: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    selectedMedia: {
      type: Number,
      required: false,
      default: () => {
        return 1
      }
    }
  },
  data() {
    return {
      videoPreview: false,
      item: null,
      mediaCurrent: 1,
      isDesktopPlatform: true
    }
  },
  watch: {},
  async created() {
    this.videoPreview = true
    this.mediaCurrent = this.selectedMedia
    this.item = this.media.length ? this.media[this.mediaCurrent - 1] : null
  },
  methods: {
    onCancel() {
      this.$emit('close')
    },
    async handleItemClick(jump) {
      this.videoPreview = false
      this.mediaCurrent += jump
      this.item = this.media[this.mediaCurrent - 1]
    }
  }
}
</script>

<style lang="scss" scoped>
@include block('media-modal') {
  position: absolute;
  top: calc(50% - 14rem);
  width: 50rem;
  height: 28rem;
  background-color: #ffffff;
  z-index: 2;
  display: -webkit-box;
  left: calc(50% - 25rem);

  @media screen and (max-width: 850px) {
    width: 80vw;
    height: 60vh;
    left: 10vw;
    top: 20vh;
  }

  .media {
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
  }

  .img-caption {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    text-align: left;
    color: #ffffff;
    background-color: #1f3370;
    width: 50rem;

    @media screen and (max-width: 850px) {
      width: 80vw;
    }
  }

  .close-icon {
    margin-left: 1.25rem;
  }

  .svg-sty {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .pagination {
    padding: 0 1rem;
  }

  @include element('backdrop') {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--ion-color-role-grey);
    opacity: 0.5;
    margin-top: 65px;
    z-index: 1;
  }
}
</style>
