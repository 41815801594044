<template>
  <div>
    <div class="skill-container">
      <SkillsButtonGroup
        :skills="displaySkills"
        :from="from"
        :key="displaySkills.length"
        :displaySkillDetails="true"
        :isSkillRemoveable="isSkillRemoveable"
        @remove-skill="onRemoveSkill"
      ></SkillsButtonGroup>
    </div>
  </div>
</template>
<script>
import SkillsButtonGroup from '@/components/organisms/SkillsButtonGroup.vue'

export default {
  name: 'SkillsList',
  components: { SkillsButtonGroup },
  props: {
    skills: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    showAddButton: {
      type: Boolean,
      required: false,
      default: true
    },
    isSkillRemoveable: {
      type: Boolean,
      required: false,
      default: true
    },
    from: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      displaySkills: [],
      showMore: false,
      moreBtnAvailable: true
    }
  },
  created() {
    if (this.$platform == 'Mobile') {
      this.displaySkills = this.skills
      this.moreBtnAvailable = this.skills.length > 10
    } else {
      this.displaySkills = this.skills
      this.moreBtnAvailable = this.skills.length > 20
    }
  },
  mounted() {},
  methods: {
    onRemoveSkill(skillGuid) {
      const index = this.displaySkills.findIndex((skill) => skill.guid === skillGuid)
      if (index > -1) {
        this.displaySkills.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.skill-container {
  @media only screen and (max-width: $breakpoint-med) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.add-container {
  padding-top: 5%;

  @media only screen and (max-width: $breakpoint-med) {
    display: flex;
    justify-content: flex-start;
  }
}
.skill-button {
  --background: var(--ion-color-white);
  color: var(--ion-color-tertiary);
  cursor: pointer;
  min-width: 80px;
  --padding-top: 0.8rem;
  --padding-bottom: 0.8rem;
  --padding-start: 0.8rem;
  --padding-end: 0.8rem;
  // --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.skill-title {
  color: var(--ion-color-tertiary);
  height: 18px;
}
.progress-bar-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.progress-bar {
  width: 70px;
}
.more-container {
  float: right;
  position: relative;
  top: -2.5rem;
}
.edit {
  color: var(--ion-color-secondary);
}
</style>
