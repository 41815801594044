<template>
  <div>
    <div class="mini-alert" v-if="extensionPrompt">
      <h5>Still there?</h5>
      <p>Your session will expire in: {{ ~~((timeLeft % 3600) / 60) }} minutes {{ ~~timeLeft % 60 }} seconds</p>
      <div class="handler">
        <ion-button data-cy="keep-vdi" class="g-button--primary ion-button-primary" color="primary" @click="extendSession">Keep working</ion-button>
        <a href="#" class="secondary-action" @click="endSession">End Session</a>
      </div>
    </div>
    <div class="expired-alert" v-if="expired">
      <h1>Your virtual desktop session has ended.</h1>
      <img src="@/assets/images/vdi_session.png" alt="Virtual desktop characters" />
      <div class="handler">
        <ion-button data-cy="resume-vdi" class="g-button--primary ion-button-primary" color="primary" @click="resumeVdi">Resume virtual desktop</ion-button>
      </div>
    </div>

    <div class="center-header">
      <svg-icon icon-class="logo" class-name="logo" />
      <ion-button shape="round" color="tertiary" class="ion-button-primary guide-button" @click="toggleShowGuide" v-track="'vdi,Guide,click'"
        >Virtual Desktop Instructions</ion-button
      >
      <div v-if="guideIsOpen" class="popover-container arrow-top">
        <GuidePopover v-on:guide-popover-close="toggleShowGuide" />
      </div>
    </div>
    <!-- Display -->
    <div ref="viewport">
      <div id="display" ref="display"></div>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage'
import Guacamole from 'guacamole-common-js'
import GuidePopover from '@/components/molecules/GuidePopover'

export default {
  name: 'Workspace',
  components: { GuidePopover },
  props: {},
  data() {
    return {
      authToken: '',
      credentialId: '',
      my_user_id: '',
      display: null,
      client: null,
      timer: 0,
      extensionPrompt: false,
      inactivityTime: 0,
      promptTime: 0,
      timeLeft: 0,
      expired: false,
      guideIsOpen: false
    }
  },
  methods: {
    resize() {
      const elm = this.$refs.viewport
      if (!elm || !elm.offsetWidth) {
        // resize is being called on the hidden window
        return
      }
      const height = window.innerHeight - 80
      this.client.sendSize(elm.clientWidth, height)
    },
    resetTimer() {
      clearInterval(this.timer)
      this.currSeconds = 0
      this.timer = setInterval(this.startIdleTimer, 1000)
    },
    startIdleTimer() {
      this.currSeconds++
      if (this.inactivityTime - this.currSeconds <= this.promptTime) {
        this.timeLeft = this.inactivityTime - this.currSeconds
        this.extensionPrompt = true
      }
      if (this.currSeconds >= this.inactivityTime) {
        this.client.disconnect()
        this.$refs.display.innerHTML = ''
        localforage.removeItem('vdiOpened')
        this.extensionPrompt = false
        this.expired = true
        clearInterval(this.timer)
        return
      }
    },
    extendSession() {
      this.extensionPrompt = false
    },
    async endSession() {
      this.extensionPrompt = false
      this.$refs.display.innerHTML = ''
      clearInterval(this.timer)
      await localforage.removeItem('vdi_settings')
      await localforage.removeItem('vdiOpened')
      window.close()
    },
    resumeVdi() {
      location.reload()
    },
    toggleShowGuide() {
      this.guideIsOpen = !this.guideIsOpen
    }
  },
  async beforeMount() {
    const vdiSettings = await localforage.getItem('vdi_settings')
    this.inactivityTime = vdiSettings.inactivityTime * 60
    this.promptTime = vdiSettings.promptTime * 60
  },
  async mounted() {
    this.resetTimer()
    const cloudEnv = await localforage.getItem('cloudEnv')
    const { vdiApiBaseUri } = cloudEnv.baseApis
    localforage.setItem('vdiOpened', true)
    let w = window.innerWidth - 30
    let h = window.innerHeight - 80 || document.documentElement.clientHeight || document.body.clientHeight

    const my_user_id = await localforage.getItem('my_user_id')
    const my_access_token = await localforage.getItem('my_access_token')

    // Get display div from document
    let display = document.getElementById('display')
    const wsProtocol = window.location.protocol === 'http:' ? 'ws' : 'wss'
    this.client = new Guacamole.Client(new Guacamole.WebSocketTunnel(`${wsProtocol}://${vdiApiBaseUri}webSocket`))
    this.display = this.client.getDisplay()

    // Add client to display div
    const element = this.client.getDisplay().getElement()
    const canvasList = element.querySelectorAll('canvas')
    canvasList.forEach((canvasElement) => {
      canvasElement.style.zIndex = 10
    })

    window.addEventListener('resize', this.resize)
    display.appendChild(element)

    // File handler
    this.client.onfile = function (stream, mimetype, filename) {
      stream.sendAck('OK', Guacamole.Status.Code.SUCCESS)
      downloadBlob(stream, mimetype, filename)
    }

    let downloadBlob = (stream, mimetype, filename) => {
      var blob_builder
      blob_builder = new (function () {
        var blobs = []
        this.append = function (data) {
          blobs.push(new Blob([data], { type: mimetype }))
        }
        this.getBlob = function () {
          let receivedBlob = new Blob(blobs, { type: mimetype })
          return receivedBlob
        }
      })()

      stream.onblob = function (data) {
        // Convert to ArrayBuffer
        var binary = window.atob(data)
        var arrayBuffer = new ArrayBuffer(binary.length)
        var bufferView = new Uint8Array(arrayBuffer)
        for (var i = 0; i < binary.length; i++) {
          bufferView[i] = binary.charCodeAt(i)
        }
        blob_builder.append(arrayBuffer)
        stream.sendAck('OK', 0x0000)
      }

      stream.onend = function () {
        var blob_data = blob_builder.getBlob()
        if (mimetype.indexOf('stream-index+json') != -1) {
          var blob_reader = new FileReader()
          blob_reader.readAsBinaryString(blob_data)
        } else {
          var file_arr = filename.split('/')
          var download_file_name = file_arr[file_arr.length - 1]
          downloadFile(blob_data, download_file_name)
        }
      }
    }

    function downloadFile(blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename || 'download'
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
        }, 150)
      }
      a.addEventListener('click', clickHandler, false)
      a.click()
    }

    // Error handler
    // eslint-disable-next-line
    this.client.onerror = function (error) {}

    // Connect to workspace
    this.client.connect(`userId=${my_user_id}&width=${w}px&height=${h}px&t=${my_access_token}`)

    // Disconnect on close
    window.onunload = async function () {
      await localforage.removeItem('vdiOpened')
      this.client.disconnect()
    }

    // Mouse
    let mouse = new Guacamole.Mouse(this.client.getDisplay().getElement())

    mouse.onmousedown =
      mouse.onmouseup =
      mouse.onmousemove =
        (mouseState) => {
          this.client.sendMouseState(mouseState)
          this.resetTimer()
        }

    // Keyboard
    let keyboard = new Guacamole.Keyboard(document)

    keyboard.onkeydown = (keysym) => {
      this.client.sendKeyEvent(1, keysym)
      this.resetTimer()
    }

    keyboard.onkeyup = (keysym) => {
      this.client.sendKeyEvent(0, keysym)
      this.resetTimer
    }
  }
}
</script>

<style lang="scss" scoped>
.center-header {
  min-height: 40px;
}

.guide-button {
  font-size: 1rem;
  text-transform: none;
  font-family: 'Futura PT Demi';
  height: 30px !important;
  float: right;
  margin-right: 45px;
}

.popover-container {
  background-color: white;
  position: fixed;
  top: 50;
  right: 0;
  margin-right: 40px;
  margin-top: 10px;
  width: 40vw;
  height: 85vh;
  z-index: 999;
  border-radius: 10px;
}

.popover-container.arrow-top {
  margin-top: 15px;
}

.popover-container.arrow-top:after {
  content: ' ';
  position: absolute;
  right: 80px;
  top: -10px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid rgb(255, 255, 255);
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 75%; /* 4:3 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.logo {
  margin: 6px 0;
  height: 25px;
  width: 114px;
}

#display {
  display: flex;
  justify-content: center;
  width: calc(100vw - 30px);
}

.message-modal {
  position: absolute;
  top: 30px;
  right: 31px;
  background: #fff;
  width: 22%;
  height: 18vh;
  z-index: 1000;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  top: 50%;
  text-align: left;
  left: 50%;
  padding: 0 15px;
}

.handler {
  margin-right: 10px;
  text-align: right;
  ion-button {
    text-transform: none;
    width: auto;
  }
}

.mini-alert {
  @extend .message-modal;
  .handler {
    @extend .handler;
    ion-button {
      margin-right: 20px;
    }
  }
}

.expired-alert {
  @extend .message-modal;
  width: 33%;
  height: auto;
  // box-shadow: 0px 3px 15px #00000093;
  text-align: center;
  padding-bottom: 15px;
  .handler {
    @extend .handler;
    text-align: center;
  }
}

.secondary-action {
  color: var(--ion-color-tertiary);
  text-decoration: none;
  vertical-align: sub;
}
</style>

<style lang="scss">
#contentlayout > nav,
.landing {
  display: none !important;
}

#display {
  h2 {
    font-size: 1.7rem;
    margin: 30% auto;
  }
}
</style>
