<template>
  <div>
    <div class="confirmation-modal__backdrop" />
    <div class="confirmation-modal">
      <svg-icon icon-class="filter-close" class="g-icon--xsmall cancel-icon-container" @click="onCancel"></svg-icon>
      <h2>{{ modalProperties.title }}</h2>
      <ion-row>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" v-if="modalProperties.subtitle">
          <div class="text-container">{{ modalProperties.subtitle }}</div>
          <ion-textarea class="placeholder-container" autoGrow="true" rows="4" autofocus @ionChange="handleCommentsInput" :placeholder="modalProperties.textAreaPlaceholder">
          </ion-textarea>
        </ion-col>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" v-if="modalProperties.text">
          <div class="placeholder-container">{{ modalProperties.text }}</div>
        </ion-col>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="text-container">
          <ion-button v-if="modalProperties.primaryButton == 'Submit now'" shape="round" color="primary" class="ion-text-capitalize ion-button-primary-dark" @click="onSubmit">
            {{ modalProperties.primaryButton }}
          </ion-button>
          <ion-button v-if="modalProperties.primaryButton == 'Resubmit'" shape="round" color="primary" class="ion-text-capitalize ion-button-primary-dark" @click="onSubmit">
            {{ modalProperties.primaryButton }}
          </ion-button>
          <ion-button
            v-if="modalProperties.primaryButton == 'Delete draft'"
            shape="round"
            color="primary"
            class="ion-text-capitalize ion-button-primary-dark"
            @click="onDeleteClick"
          >
            {{ modalProperties.primaryButton }}
          </ion-button>
          <a href="#" class="cancel-container" @click="onCancel">{{ modalProperties.secondaryButton }}</a>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AssetConfirmationModal',
  components: {},
  props: {
    modalProperties: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      comment: ''
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  created() {},
  methods: {
    handleCommentsInput(event) {
      this.comment = event.target.value
    },
    async onSubmit() {
      this.$emit('submitAsset', {
        comment: this.comment
      })
    },
    async onDeleteClick() {
      this.$emit('onDeleteClick', {
        delete: true
      })
    },
    async onCancel() {
      this.$emit('closeConfirmationModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.cancel-container {
  text-decoration: unset;
  padding: 0 2rem;
  color: var(--ion-color-tertiary);
}

ion-textarea {
  border: 1px solid #efefef;
  margin-bottom: 1rem;
  padding: 0.4rem;
  &:focus {
    border: 1px solid var(--ion-color-focus) !important;
  }
}
@include block('confirmation-modal') {
  position: absolute;
  padding: 1.5rem;
  top: calc(50% - 114px);
  width: 420px;
  height: auto;
  border-radius: 20px;
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-gray);
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  .cancel-icon-container {
    float: right;
    cursor: pointer;
  }
  h2 {
    color: #000000;
    font-size: 1.4rem;
    text-align: left;
  }
  .text-container {
    text-align: left;
    display: flex;
    align-items: center;
  }
  .placeholder-container {
    text-align: left;
    font-family: 'Arial';
    font-size: 1rem;
    color: var(--ion-color-role-grey);
  }
  @include element('backdrop') {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--ion-color-role-grey);
    opacity: 0.5;
    margin-top: 65px;
    z-index: 1;
  }
}

@media screen and (max-width: 480px) {
  @include block('confirmation-modal') {
    width: 100%;
    left: 0px;
    @include element('backdrop') {
      opacity: 0.1;
    }
  }
}
</style>
