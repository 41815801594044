var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataReady
    ? _c("div", { staticClass: "edge-onboarding" }, [
        _c("img", {
          class:
            _vm.$platform === "Desktop"
              ? "footer-image-desktop"
              : "footer-image-mobile",
          attrs: {
            src: require("@/assets/images/onboarding_welcome.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "wrapper" }, [
          _c("h2", { staticClass: "title g-type-xlarge" }, [
            _vm._v("Hello " + _vm._s(_vm.user.name) + "!")
          ]),
          _c(
            "h3",
            {
              staticClass: "g-type-large",
              staticStyle: { "font-family": "'Futura PT Demi'" }
            },
            [_vm._v("Welcome to ProEdge.")]
          ),
          _c("p", [
            _vm._v(
              "We've looked at your past experiences, current skills, and market trends to tailor a personalized learning pathway…"
            )
          ]),
          _c("div", { staticClass: "role" }, [
            _c(
              "div",
              {
                class: [
                  "centered",
                  _vm.user.role.length <= 10
                    ? "role-short-title"
                    : _vm.user.role.length <= 22
                    ? "role-medium-title"
                    : _vm.user.role.length <= 34
                    ? "role-long-title"
                    : _vm.user.role.length <= 46
                    ? "role-xlong-title"
                    : "role-xxlong-title"
                ]
              },
              [_vm._v(" " + _vm._s(_vm.user.role) + " ")]
            ),
            _c("img", {
              attrs: {
                src: require("@/assets/images/onboarding_path_sign.png"),
                alt: ""
              }
            })
          ]),
          _c("p", [_vm._v("Your target skills:")]),
          _vm.user.skills
            ? _c(
                "div",
                { staticClass: "scrollable-content skills-list" },
                [
                  _c("SkillsButtonGroup", {
                    key: _vm.user.skills.length,
                    attrs: {
                      skills: _vm.user.skills,
                      from: "welcome-page",
                      displaySkillDetails: false
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("p", { staticStyle: { "font-size": "0.8rem" } }, [
            _vm._v(
              "*You can continue curating your content by editing work experience and skills in your profile"
            )
          ]),
          _c(
            "div",
            { staticClass: "footer-wrapper" },
            [
              _c(
                "ion-button",
                {
                  staticClass: "ion-button-primary",
                  staticStyle: {
                    "font-family": "'Futura PT Demi'",
                    "text-transform": "capitalize",
                    "--box-shadow": "none"
                  },
                  attrs: {
                    "data-cy": "signup-get-started",
                    shape: "round",
                    color: "primary"
                  },
                  on: { click: _vm.onGetStarted }
                },
                [_vm._v("Get Started")]
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }