var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credential-content" },
    [
      _vm.dataReady
        ? _c(
            "ion-card",
            { class: !_vm.isDesktopPlatform ? "mobile-version" : "" },
            [
              _vm.isDesktopPlatform
                ? _c(
                    "ion-card-header",
                    [
                      _c(
                        "ion-row",
                        { staticClass: "ion-align-items-center" },
                        [
                          _c("ion-col", [
                            _c("h3", [
                              !_vm.fromBookmarkList
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.content.index))
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(_vm.content.title) + " ")
                            ])
                          ]),
                          _c(
                            "ion-col",
                            { attrs: { size: "1" } },
                            [
                              _c("Bookmark", {
                                key: _vm.content.meta.isBookmarked,
                                staticClass: "bookmark",
                                attrs: {
                                  assetId: _vm.content.id,
                                  assetType: "credentialcontents",
                                  meta: _vm.content.meta
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "ion-card-header",
                    [
                      _c(
                        "ion-row",
                        { staticClass: "ion-align-items-center" },
                        [
                          !_vm.fromBookmarkList
                            ? _c("ion-col", { attrs: { size: "2" } }, [
                                _c(
                                  "h3",
                                  { staticClass: "content-index h3-mobile" },
                                  [_vm._v(_vm._s(_vm.content.index))]
                                )
                              ])
                            : _vm._e(),
                          _c("ion-col", [
                            _c("h3", { staticClass: "h3-mobile" }, [
                              _vm._v(_vm._s(_vm.content.title))
                            ])
                          ]),
                          _c(
                            "ion-col",
                            { attrs: { size: "1" } },
                            [
                              _c("Bookmark", {
                                key: _vm.content.meta.isBookmarked,
                                staticClass: "bookmark",
                                attrs: {
                                  assetId: _vm.content.id,
                                  assetType: "credentialcontents",
                                  meta: _vm.content.meta
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "ion-card-content",
                { key: JSON.stringify(_vm.content) },
                [
                  _vm.content.medias &&
                  _vm.content.medias[0].type === "video/mp4"
                    ? _c(
                        "ion-row",
                        [
                          _c("ion-col", [
                            _c(
                              "video",
                              {
                                class: _vm.isDesktopPlatform
                                  ? "video-desktop"
                                  : "video-mobile",
                                attrs: {
                                  poster: "",
                                  controls: "",
                                  preload: "auto"
                                }
                              },
                              [
                                _c("source", {
                                  attrs: {
                                    src:
                                      _vm.content.medias[0].contentUri +
                                      "#t=0.2",
                                    type: "video/mp4"
                                  }
                                })
                              ]
                            ),
                            _vm.content.medias[0].transcript
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "transcript",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleShowTranscript("video")
                                      }
                                    }
                                  },
                                  [
                                    _c("svg-icon", {
                                      class: [
                                        !_vm.showTranscript.video
                                          ? ""
                                          : "caret-transform"
                                      ],
                                      staticStyle: { float: "right" },
                                      attrs: {
                                        "icon-class": "dropdown-transcript",
                                        "class-name": "g-icon--xsmall"
                                      }
                                    }),
                                    _c("p", [_vm._v("Transcript")])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showTranscript.video
                              ? _c("div", {
                                  staticClass: "text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$sanitize(
                                        _vm.content.medias[0].transcript
                                      )
                                    )
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDesktopPlatform
                    ? _c(
                        "ion-row",
                        [
                          _vm.content.contentHTML || _vm.content.knowledgeChecks
                            ? _c(
                                "ion-col",
                                {
                                  attrs: { size: _vm.content.imageUri ? 6 : 12 }
                                },
                                [
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$sanitize(_vm.content.contentHTML)
                                      )
                                    }
                                  }),
                                  _vm._l(_vm.content.knowledgeChecks, function(
                                    knowledgeCheck
                                  ) {
                                    return _c(
                                      "div",
                                      { key: knowledgeCheck.qid },
                                      [
                                        _c("div", {
                                          staticClass: "text",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$sanitize(knowledgeCheck.text)
                                            )
                                          }
                                        }),
                                        knowledgeCheck.type === "TEXT_INPUT"
                                          ? _c("ion-textarea", {
                                              attrs: {
                                                autofocus: "",
                                                rows: "4",
                                                placeholder:
                                                  knowledgeCheck.text,
                                                value: _vm.inputAnswers
                                                  .available
                                                  ? _vm.inputAnswers.answers[
                                                      knowledgeCheck.qid
                                                    ]
                                                  : ""
                                              },
                                              on: {
                                                ionChange: function($event) {
                                                  return _vm.handleTextInput(
                                                    $event,
                                                    knowledgeCheck.qid
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        knowledgeCheck.type === "SINGLE_OPTION"
                                          ? _c(
                                              "ion-list",
                                              {
                                                staticStyle: {
                                                  "margin-left": "1rem"
                                                }
                                              },
                                              [
                                                _c(
                                                  "ion-radio-group",
                                                  {
                                                    attrs: {
                                                      value: _vm.selectedAnswers
                                                        .length
                                                        ? _vm.selectedAnswers[0]
                                                        : ""
                                                    },
                                                    on: {
                                                      ionChange: function(
                                                        $event
                                                      ) {
                                                        return _vm.handleRadioSelect(
                                                          $event,
                                                          knowledgeCheck.qid
                                                        )
                                                      }
                                                    }
                                                  },
                                                  _vm._l(
                                                    knowledgeCheck.options,
                                                    function(option, index) {
                                                      return _c(
                                                        "ion-item",
                                                        {
                                                          key: option.text,
                                                          attrs: {
                                                            lines: "none"
                                                          }
                                                        },
                                                        [
                                                          option.image
                                                            ? _c("img", {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0.5rem 1rem"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    option.image
                                                                }
                                                              })
                                                            : _c(
                                                                "ion-label",
                                                                {
                                                                  staticClass:
                                                                    "ion-text-wrap",
                                                                  staticStyle: {
                                                                    "padding-left":
                                                                      "1rem",
                                                                    "font-family":
                                                                      "'Futura PT Book'"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option.text
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                          _c("ion-radio", {
                                                            attrs: {
                                                              slot: "start",
                                                              color: "tertiary",
                                                              value: index
                                                            },
                                                            slot: "start"
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        knowledgeCheck.type === "MULTI_OPTION"
                                          ? _c(
                                              "ion-list",
                                              {
                                                staticStyle: {
                                                  "margin-left": "1rem"
                                                }
                                              },
                                              _vm._l(
                                                knowledgeCheck.options,
                                                function(option, index) {
                                                  return _c(
                                                    "ion-item",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        lines: "none",
                                                        mode: "md"
                                                      },
                                                      on: {
                                                        ionChange: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleCheckboxSelect(
                                                            $event,
                                                            knowledgeCheck.qid
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      option.image
                                                        ? _c("img", {
                                                            staticStyle: {
                                                              margin:
                                                                "0.5rem 1rem"
                                                            },
                                                            attrs: {
                                                              src: option.image
                                                            }
                                                          })
                                                        : _c(
                                                            "ion-label",
                                                            {
                                                              staticClass:
                                                                "ion-text-wrap",
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "1rem",
                                                                "font-family":
                                                                  "'Futura PT Book'"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  option.text
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                      _c("ion-checkbox", {
                                                        attrs: {
                                                          slot: "start",
                                                          color: "tertiary",
                                                          value: index,
                                                          checked: _vm.selectedAnswers.includes(
                                                            index
                                                          )
                                                        },
                                                        slot: "start"
                                                      })
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.content.knowledgeChecks &&
                                  _vm.knowledgeCheckConfirm.isConfirmed
                                    ? _c("div", {
                                        class:
                                          _vm.knowledgeCheckConfirm.type ===
                                          "error"
                                            ? "kcs_error_msg"
                                            : "kcs_success_msg",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$sanitize(
                                              _vm.knowledgeCheckConfirm.message
                                            )
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        margin: "0.5rem 1rem"
                                      }
                                    },
                                    [
                                      _vm.content.knowledgeChecks
                                        ? _c(
                                            "ion-button",
                                            {
                                              staticClass:
                                                "ion-button-tertiary ion-text-capitalize",
                                              staticStyle: { height: "35px" },
                                              attrs: {
                                                disabled: _vm.isConfirming,
                                                shape: "round",
                                                color: "tertiary"
                                              },
                                              on: { click: _vm.onConfirm }
                                            },
                                            [_vm._v("Confirm")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.content.imageUri
                            ? _c(
                                "ion-col",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: {
                                    size: _vm.content.contentHTML ? 6 : 12
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.content.imageUri }
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isDesktopPlatform
                    ? _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            [
                              _vm.content.contentHTML
                                ? _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$sanitize(_vm.content.contentHTML)
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm.content.imageUri
                                    ? _c("img", {
                                        attrs: { src: _vm.content.imageUri }
                                      })
                                    : _vm._e()
                                ]
                              ),
                              _vm._l(_vm.content.knowledgeChecks, function(
                                knowledgeCheck
                              ) {
                                return _c(
                                  "div",
                                  { key: knowledgeCheck.qid },
                                  [
                                    _c("div", {
                                      staticClass: "text",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$sanitize(knowledgeCheck.text)
                                        )
                                      }
                                    }),
                                    knowledgeCheck.type === "TEXT_INPUT"
                                      ? _c("ion-textarea", {
                                          staticStyle: {
                                            margin: "0.5rem 0rem",
                                            width: "100%"
                                          },
                                          attrs: {
                                            autofocus: "",
                                            rows: "3",
                                            placeholder: knowledgeCheck.text,
                                            value: _vm.inputAnswers.available
                                              ? _vm.inputAnswers.answers[
                                                  knowledgeCheck.qid
                                                ]
                                              : ""
                                          },
                                          on: {
                                            ionChange: function($event) {
                                              return _vm.handleTextInput(
                                                $event,
                                                knowledgeCheck.qid
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    knowledgeCheck.type === "SINGLE_OPTION"
                                      ? _c(
                                          "ion-list",
                                          {
                                            staticStyle: {
                                              "margin-left": "1rem"
                                            }
                                          },
                                          [
                                            _c(
                                              "ion-radio-group",
                                              {
                                                attrs: {
                                                  value: _vm.selectedAnswers
                                                    .length
                                                    ? _vm.selectedAnswers[0]
                                                    : ""
                                                },
                                                on: {
                                                  ionChange: function($event) {
                                                    return _vm.handleRadioSelect(
                                                      $event,
                                                      knowledgeCheck.qid
                                                    )
                                                  }
                                                }
                                              },
                                              _vm._l(
                                                knowledgeCheck.options,
                                                function(option, index) {
                                                  return _c(
                                                    "ion-item",
                                                    {
                                                      key: option.text,
                                                      attrs: { lines: "none" }
                                                    },
                                                    [
                                                      option.image
                                                        ? _c("img", {
                                                            staticStyle: {
                                                              margin:
                                                                "0.5rem 1rem"
                                                            },
                                                            attrs: {
                                                              src: option.image
                                                            }
                                                          })
                                                        : _c(
                                                            "ion-label",
                                                            {
                                                              staticClass:
                                                                "ion-text-wrap",
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "1rem",
                                                                "font-family":
                                                                  "'Futura PT Book'"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  option.text
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                      _c("ion-radio", {
                                                        attrs: {
                                                          slot: "start",
                                                          mode: "md",
                                                          color: "tertiary",
                                                          value: index
                                                        },
                                                        slot: "start"
                                                      })
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    knowledgeCheck.type === "MULTI_OPTION"
                                      ? _c(
                                          "ion-list",
                                          {
                                            staticStyle: {
                                              "margin-left": "1rem"
                                            }
                                          },
                                          _vm._l(
                                            knowledgeCheck.options,
                                            function(option, index) {
                                              return _c(
                                                "ion-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    lines: "none",
                                                    mode: "md"
                                                  },
                                                  on: {
                                                    ionChange: function(
                                                      $event
                                                    ) {
                                                      return _vm.handleCheckboxSelect(
                                                        $event,
                                                        knowledgeCheck.qid
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  option.image
                                                    ? _c("img", {
                                                        staticStyle: {
                                                          margin: "0.5rem 1rem"
                                                        },
                                                        attrs: {
                                                          src: option.image
                                                        }
                                                      })
                                                    : _c(
                                                        "ion-label",
                                                        {
                                                          staticClass:
                                                            "ion-text-wrap",
                                                          staticStyle: {
                                                            "padding-left":
                                                              "1rem",
                                                            "font-family":
                                                              "'Futura PT Book'"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(option.text)
                                                          )
                                                        ]
                                                      ),
                                                  _c("ion-checkbox", {
                                                    attrs: {
                                                      slot: "start",
                                                      color: "tertiary",
                                                      value: index,
                                                      checked: _vm.selectedAnswers.includes(
                                                        index
                                                      )
                                                    },
                                                    slot: "start"
                                                  })
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              _vm.content.knowledgeChecks &&
                              _vm.knowledgeCheckConfirm.isConfirmed
                                ? _c("div", {
                                    class:
                                      _vm.knowledgeCheckConfirm.type === "error"
                                        ? "kcs_error_msg"
                                        : "kcs_success_msg",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$sanitize(
                                          _vm.knowledgeCheckConfirm.message
                                        )
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    float: "right",
                                    margin: "0.5rem"
                                  }
                                },
                                [
                                  _vm.content.knowledgeChecks
                                    ? _c(
                                        "ion-button",
                                        {
                                          staticClass:
                                            "ion-button-tertiary ion-text-capitalize",
                                          attrs: {
                                            disabled: _vm.isConfirming,
                                            shape: "round",
                                            color: "tertiary"
                                          },
                                          on: { click: _vm.onConfirm }
                                        },
                                        [_vm._v("Confirm")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.content.accordions, function(accordion, index) {
                    return _c("CredentialContentAccordion", {
                      key: index,
                      attrs: { accordion: accordion }
                    })
                  }),
                  _vm.content.carousel && _vm.content.carousel.length
                    ? _c("CredentialContentCarousel", {
                        attrs: { carousel: _vm.content.carousel }
                      })
                    : _vm._e(),
                  _vm.content.medias && _vm.content.medias[0].type === "audio"
                    ? _c(
                        "ion-row",
                        [
                          _c("ion-col", [
                            _vm.content.medias[0].imageUri
                              ? _c("img", {
                                  attrs: { src: _vm.content.medias[0].imageUri }
                                })
                              : _vm._e(),
                            _c(
                              "audio",
                              {
                                class: _vm.isDesktopPlatform
                                  ? "audio-desktop"
                                  : "audio-mobile",
                                attrs: {
                                  controls: "",
                                  src: _vm.content.medias[0].contentUri
                                }
                              },
                              [
                                _vm._v(" Your browser does not support the"),
                                _c("code", [_vm._v("audio")]),
                                _vm._v(" element. ")
                              ]
                            ),
                            _vm.content.medias[0].transcript
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "transcript",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleShowTranscript("audio")
                                      }
                                    }
                                  },
                                  [
                                    _c("svg-icon", {
                                      class: [
                                        !_vm.showTranscript.audio
                                          ? ""
                                          : "caret-transform"
                                      ],
                                      staticStyle: { float: "right" },
                                      attrs: {
                                        "icon-class": "dropdown-transcript",
                                        "class-name": "g-icon--xsmall"
                                      }
                                    }),
                                    _c("p", [_vm._v("Transcript")])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showTranscript.audio
                              ? _c("div", {
                                  staticClass: "text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$sanitize(
                                        _vm.content.medias[0].transcript
                                      )
                                    )
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.content.isAssessment
                    ? _c(
                        "ion-row",
                        { staticClass: "ion-no-padding" },
                        [
                          _c(
                            "ion-col",
                            {
                              staticClass: "ion-padding-bottom ion-text-end",
                              attrs: { size: "12" }
                            },
                            [
                              _c(
                                "ion-button",
                                {
                                  staticClass:
                                    "ion-button-tertiary ion-text-capitalize",
                                  attrs: { shape: "round", color: "tertiary" },
                                  on: { click: _vm.startAssessment }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.content.isComplete ? "Begin" : "Redo"
                                    ) + " Assessment"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.content.isSubmitCapstone
                    ? _c(
                        "ion-row",
                        { staticClass: "ion-no-padding" },
                        [
                          _c(
                            "ion-col",
                            {
                              staticClass: "ion-padding-bottom ion-text-end",
                              attrs: { size: "12" }
                            },
                            [
                              !_vm.content.projectId
                                ? _c(
                                    "ion-button",
                                    {
                                      staticClass:
                                        "ion-button-tertiary ion-text-capitalize",
                                      attrs: {
                                        shape: "round",
                                        color: "tertiary"
                                      },
                                      on: { click: _vm.onSubmitCapstone }
                                    },
                                    [_vm._v("Upload Your Capstone")]
                                  )
                                : _c(
                                    "ion-button",
                                    {
                                      staticClass:
                                        "ion-button-tertiary ion-text-capitalize",
                                      attrs: {
                                        shape: "round",
                                        color: "tertiary"
                                      },
                                      on: { click: _vm.editSubmitCapstone }
                                    },
                                    [_vm._v("Edit Your Capstone")]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }