var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "ion-row",
          { staticClass: "ion-align-items-center" },
          [
            _c("ion-col", { attrs: { size: "10" } }),
            _c(
              "ion-col",
              { class: _vm.$platform !== "Desktop" ? "mobile-align-end" : "" },
              [
                _c(
                  "button",
                  {
                    staticClass: "slider-close",
                    attrs: { "aria-label": "close side menu" },
                    on: { click: _vm.closeTrackBar }
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "close-icon",
                        "class-name": "close-button-icon"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "track-form" },
      [
        _c("ion-row", { staticClass: "track-header" }, [
          _vm._v(
            " " +
              _vm._s(_vm.isEditTrack ? "Edit track" : "Create a track") +
              " "
          )
        ]),
        _c("ion-row", [
          _c("span", { staticClass: "req-info" }, [
            _vm._v("*Required information")
          ])
        ]),
        _c(
          "div",
          {
            staticStyle: {
              height: "calc(100vh - 330px)",
              "overflow-y": "auto",
              width: "107%"
            }
          },
          [
            _vm.$platform === "Desktop"
              ? _c("ion-row", [
                  _c("div", { staticClass: "track-thumbnail" }, [
                    _vm._v("Thumbnail")
                  ])
                ])
              : _vm._e(),
            _c("ion-row", [
              _c("div", { staticClass: "thumbnail-msg" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$platform === "Desktop"
                        ? "Select a custom image or use the default image for your track."
                        : "You can replace the default image from your desktop or tablet."
                    ) +
                    " "
                )
              ])
            ]),
            _vm.$platform === "Desktop"
              ? _c(
                  "ion-row",
                  [
                    _c("ion-col", { attrs: { size: "4" } }, [
                      _c("div", { staticClass: "thumb-header-default" }, [
                        _vm._v("Default thumbnail image")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "default-image",
                          staticStyle: {
                            width: "10rem",
                            height: "10rem",
                            "margin-top": "2.5rem",
                            "border-radius": "20px"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "accessible-button",
                              attrs: {
                                "aria-label": "select default track image"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.selectImage()
                                }
                              }
                            },
                            [
                              _c("img", {
                                ref: "defaultImg",
                                class:
                                  _vm.imageSelected == "default"
                                    ? "image-border"
                                    : "",
                                staticStyle: {
                                  "border-radius": "25px",
                                  cursor: "pointer",
                                  width: "165px",
                                  height: "165px"
                                },
                                attrs: {
                                  src: require("@/assets/images/thumbnail-default-track.png"),
                                  alt: "default"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]),
                    _c(
                      "ion-col",
                      {
                        staticStyle: { "padding-top": "145px" },
                        attrs: { size: "1" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              font:
                                "normal normal normal 20px/24px Futura PT Demi"
                            }
                          },
                          [_vm._v("OR")]
                        )
                      ]
                    ),
                    _c("ion-col", { attrs: { size: "7" } }, [
                      _c("div", { staticClass: "thumb-header-custom" }, [
                        _vm._v("Custom thumbnail image")
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "273px", height: "280px" },
                          on: {
                            drop: function($event) {
                              return _vm.handleDrop($event)
                            },
                            dragover: _vm.handleDragover,
                            dragenter: _vm.handleDragover
                          }
                        },
                        [
                          _c("label", { staticClass: "file-upload-wrapper" }, [
                            _c("input", {
                              key: _vm.fileUploadIndex,
                              ref: "thumbnailUpload",
                              staticClass: "upload-input",
                              attrs: {
                                id: "fileinput",
                                "data-cy": "asset-file",
                                type: "file",
                                accept: "image/png",
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleClick($event)
                                }
                              }
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "accessible-button",
                                attrs: {
                                  "aria-label": "upload custom track image"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.openImageUploadPopup($event)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "imgBg" }, [
                                  _vm.showPreviewImg
                                    ? _c("img", {
                                        ref: "previewImage",
                                        class:
                                          _vm.imageSelected == "user"
                                            ? "image-border"
                                            : "",
                                        staticStyle: {
                                          width: "152px",
                                          height: "152px",
                                          cursor: "pointer",
                                          "border-radius": "25px"
                                        },
                                        attrs: {
                                          id: "previewImage",
                                          src: _vm.editImageSrc,
                                          alt: "user"
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.fileError != "",
                              expression: "fileError != ''"
                            }
                          ],
                          staticClass: "file-error"
                        },
                        [_vm._v(_vm._s(_vm.fileError))]
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "ion-row",
                  { staticClass: "thumbnail-header" },
                  [
                    _c("ion-col", { staticStyle: { "text-align": "center" } }, [
                      _c("div", { staticClass: "thumb-header" }, [
                        _vm._v("Default thumbnail image")
                      ])
                    ]),
                    _c("ion-col", { staticStyle: { "text-align": "center" } }, [
                      _c("div", { staticClass: "selected-image" }, [
                        _c("img", {
                          ref: "defaultImg",
                          class:
                            _vm.imageSelected == "default"
                              ? "image-border"
                              : "",
                          staticStyle: {
                            "border-radius": "16px",
                            cursor: "pointer"
                          },
                          attrs: {
                            src: require("@/assets/images/thumbnail-default-track.png")
                          },
                          on: {
                            click: function($event) {
                              return _vm.selectImage()
                            }
                          }
                        })
                      ])
                    ])
                  ],
                  1
                ),
            _c(
              "ion-row",
              { staticClass: "input-row" },
              [
                _c(
                  "ion-col",
                  {
                    staticClass: "form-col",
                    attrs: { "size-xs": "12", "size-md": "12" }
                  },
                  [
                    _c("div", { staticClass: "section-title" }, [
                      _vm._v("Name*")
                    ]),
                    _c("input", {
                      class: _vm.nameError ? "error-input" : "valid-input",
                      attrs: {
                        maxlength: "150",
                        type: "text",
                        placeholder: "Enter a name for the track"
                      },
                      domProps: { value: _vm.name },
                      on: {
                        input: function($event) {
                          return _vm.handleInput($event, "name")
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.nameError,
                            expression: "nameError"
                          }
                        ],
                        staticClass: "error-container"
                      },
                      [
                        _vm._v(
                          "Error message: The track name field cannot be empty, please provide a track name."
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "ion-row",
              { staticClass: "input-row" },
              [
                _c(
                  "ion-col",
                  {
                    staticClass: "form-col",
                    attrs: { "size-xs": "12", "size-md": "12" }
                  },
                  [
                    _c("div", { staticClass: "section-title" }, [
                      _vm._v("Description*")
                    ]),
                    _c("textarea", {
                      class: _vm.descriptionError
                        ? "error-input"
                        : "valid-input",
                      staticStyle: { resize: "none" },
                      attrs: {
                        placeholder:
                          "Briefly describe the benefits and impact of this track, including a list of learning outcomes.",
                        rows: "2"
                      },
                      domProps: { value: _vm.description },
                      on: {
                        input: function($event) {
                          return _vm.handleInput($event, "description")
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.descriptionError,
                            expression: "descriptionError"
                          }
                        ],
                        staticClass: "error-container"
                      },
                      [
                        _vm._v(
                          "Error message: The description field cannot be empty, please provide a description."
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "ion-row",
              [
                _c(
                  "ion-col",
                  {
                    staticClass: "form-col",
                    attrs: { "size-xs": "12", "size-md": "12" }
                  },
                  [
                    _c("div", { staticClass: "section-title" }, [
                      _vm._v("Skills for this track")
                    ]),
                    _c("ion-searchbar", {
                      ref: "searchbar",
                      staticClass: "custom searchbar-ug-track",
                      attrs: {
                        "show-cancel-button": "never",
                        placeholder: "Add new skills"
                      },
                      on: {
                        ionClear: function($event) {
                          return _vm.clearSearchBar()
                        },
                        ionChange: _vm.handleSearchInput
                      }
                    }),
                    _vm.skills.length > 0 && _vm.showList
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "box-shadow": "0px 0px 12px #0000001f",
                              "border-radius": "20px",
                              height: "200px",
                              "overflow-y": "auto",
                              "margin-right": "8px"
                            }
                          },
                          [
                            _c("div", { staticClass: "skills-list" }, [
                              _c(
                                "ul",
                                {
                                  staticStyle: {
                                    "list-style": "none",
                                    "padding-left": "1.5rem"
                                  }
                                },
                                _vm._l(_vm.skills, function(skill, index) {
                                  return _c("li", {
                                    key: index,
                                    staticClass: "skill-item",
                                    staticStyle: {
                                      "text-align": "left",
                                      width: "95%",
                                      padding: "10px 10px",
                                      cursor: "pointer",
                                      color: "#000000",
                                      font: "normal normal bold 16px/26px Arial"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(skill.displayText)
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addSkill(skill.title)
                                      }
                                    }
                                  })
                                }),
                                0
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.selecetedSkills.length > 0
                      ? _c(
                          "div",
                          { staticClass: "selected-skill" },
                          _vm._l(_vm.selecetedSkills, function(
                            skillName,
                            index
                          ) {
                            return _c(
                              "ion-chip",
                              { key: index },
                              [
                                _c("ion-label", [_vm._v(_vm._s(skillName))]),
                                _c("ion-icon", {
                                  attrs: { name: "close" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteSkill(skillName)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "create-track-footer" },
      [
        !_vm.isEditTrack
          ? _c(
              "ion-button",
              {
                staticClass: "ion-button-primary-dark create-new-track-btn",
                attrs: {
                  shape: "round",
                  color: "primary",
                  disabled:
                    _vm.disableSubmitBtn ||
                    (_vm.imageSelected == "user" && _vm.image.length == 0) ||
                    _vm.fileError != ""
                },
                on: { click: _vm.createTrack }
              },
              [_vm._v(" Create ")]
            )
          : _c(
              "ion-button",
              {
                staticClass: "ion-button-primary-dark create-new-track-btn",
                attrs: {
                  shape: "round",
                  color: "primary",
                  disabled: _vm.disableSubmitBtn || _vm.fileError != ""
                },
                on: { click: _vm.editTrack }
              },
              [_vm._v(" Update ")]
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }