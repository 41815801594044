var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ion-grid",
    { staticClass: "goal-setting-container" },
    [
      _c(
        "ion-row",
        { staticClass: "ion-no-padding goal-setting-head" },
        [
          _c(
            "ion-col",
            {
              staticClass: "ion-no-padding ion-text-start banner-info",
              attrs: {
                "size-lg": "6",
                "size-md": "6",
                "size-sm": "6",
                "size-xs": "6"
              }
            },
            [
              _c("h3", [
                _vm._v("Your " + _vm._s(_vm.currentMonth) + " activity")
              ])
            ]
          ),
          _c(
            "ion-col",
            {
              staticClass: "ion-text-end",
              attrs: {
                "size-lg": "6",
                "size-md": "6",
                "size-sm": "6",
                "size-xs": "6"
              }
            },
            [
              !_vm.editMode
                ? _c(
                    "ion-button",
                    {
                      staticClass: "goal-setting-btn",
                      attrs: { shape: "round", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSetGoal()
                        }
                      }
                    },
                    [_vm._v("Set a goal")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "goal-setting-edit-btn",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.onSetGoal()
                        }
                      }
                    },
                    [_vm._v("Edit goal")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "ion-row",
        { staticClass: "goal-setting-info" },
        [
          _vm.goalSettingData && _vm.goalSettingData.activeDaysCount
            ? _c(
                "ion-col",
                {
                  staticClass: "goal-setting-chart",
                  attrs: {
                    "size-lg": "3",
                    "size-md": "3",
                    "size-sm": "3",
                    "size-xs": "12"
                  }
                },
                [
                  _c("apexchart", {
                    attrs: {
                      type: "radialBar",
                      height: "160",
                      options: _vm.getChartOptions(),
                      series: _vm.getSeriesData()
                    }
                  }),
                  _c("h5", [_vm._v("Days active")])
                ],
                1
              )
            : _vm._e(),
          _c(
            "ion-col",
            {
              staticClass: "goal-setting-text-content",
              attrs: {
                "size-lg": "8",
                "size-md": "8",
                "size-sm": "8",
                "size-xs": "11"
              }
            },
            [
              _c("h5", [_vm._v("Days active")]),
              _vm.editMode
                ? _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.getGoalDescription()) }
                  })
                : _c("p", [
                    _vm._v(
                      " Goal setting is the first step in achieving success! "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "goal-setting-link",
                        on: {
                          click: function($event) {
                            return _vm.onSetGoal()
                          }
                        }
                      },
                      [_vm._v("Set your monthly goal")]
                    ),
                    _vm._v(
                      " today, and we’ll keep you updated on your progress. "
                    )
                  ])
            ]
          ),
          _c(
            "ion-col",
            {
              attrs: {
                "size-lg": "1",
                "size-md": "1",
                "size-sm": "1",
                "size-xs": "1"
              }
            },
            [
              _c("span", { staticClass: "typeahead-tooltip" }, [
                _c("img", {
                  staticClass: "info-icon",
                  attrs: {
                    src: require("@/assets/images/svgs/icon-info.svg"),
                    alt: "Info"
                  }
                }),
                _c("span", { staticClass: "typeahead-tooltiptext" }, [
                  _vm._v(_vm._s(_vm.activeDaysTooltipText))
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }