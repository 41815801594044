<template>
  <div class="overlay">
    <div class="addskills-modal">
      <svg-icon icon-class="filter-close" class="modal-cancel-icon-style" @click="onCancel"></svg-icon>
      <p class="success-icn">
        <svg-icon :icon-class="modalProperties.type == 'success' ? 'icon_success' : 'alert-icon'" :class="modalProperties.type == 'success' ? 'g-icon--small' : ''"></svg-icon>
        <span class="modal-header-style">{{ modalProperties.header }}</span>
      </p>
      <ion-row>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
          <div class="modal-message-style" v-html="modalProperties.message"></div>
        </ion-col>
        <ion-col v-if="modalProperties.btnText" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="text-container ion-no-padding"
          ><ion-button size="small" shape="round" color="primary" class="ion-button-primary modal-btn-style" @click="onCancel">{{ modalProperties.btnText }}</ion-button>
        </ion-col>

        <ion-col v-else-if="modalProperties.primaryButton" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="text-container ion-no-padding">
          <ion-button shape="round" color="primary" class="ion-button-primary modal-btn-style button-style" @click="$emit('completeUpload')">
            {{ modalProperties.primaryButton }}
          </ion-button>
          <a href="#" class="ignore-container" @click="$emit('ignoreUpload', true)">{{ modalProperties.secondaryButton }}</a>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssetAlertModal',
  components: {},
  props: {
    modalProperties: {
      type: Object,
      required: true,
      default: () => {
        return {
          header: '',
          message: '',
          btnText: 'Ok',
          type: 'success'
        }
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    onCancel() {
      this.$emit('closeAlertsModal')
    }
  }
}
</script>

<style lang="scss" scoped>
ion-button {
  --box-shadow: none;
}
.modal-cancel-icon-style {
  float: right;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}
.modal-header-style {
  font: normal normal 24px / 40px 'Futura PT Demi';
  opacity: 1;
}
.modal-message-style {
  font: normal normal normal 16px / 22px 'Arial';
  opacity: 1;
  color: #5c5c5c;
  text-align: left;
  word-break: break-word;
}
.modal-btn-style {
  font: normal normal normal 16px/22px 'Futura PT Demi';
  opacity: 1;
  text-align: center;
  width: 4rem;
  text-transform: none;
  &.button-style {
    width: 11.3rem !important;
  }
}
.ignore-container {
  text-decoration: unset;
  padding: 2rem;
  color: var(--ion-color-tertiary) !important;
  font: normal normal normal 16px/22px 'Futura PT Demi' !important;
  text-align: center;
}
.success-icn {
  text-align: left;
  display: flex;
  align-items: center;
  .svg-icon {
    width: 2rem;
    height: 2rem;
    margin: 0 0.4rem 0.12rem 0;
  }
  .success-title {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Futura PT Demi';
  }
}

.close-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  width: 4rem;
  margin-top: 0.6rem;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.cancel-container {
  text-decoration: unset;
  padding: 2rem;
  color: var(--ion-color-tertiary);
}
.text-container {
  text-align: left;
  font-family: Arial;
  font-size: 1rem;
  color: #5c5c5c;
  padding: 1.688rem 0px 0.5rem 0px;
}
ion-textarea {
  border: 1px solid #efefef;
  margin-bottom: 1rem;
  padding: 0.4rem;
  &:focus {
    border: 1px solid var(--ion-color-focus) !important;
  }
}
@include block('addskills-modal') {
  position: absolute;
  padding: 1.5rem;
  width: 25rem;
  height: auto;
  border-radius: 20px;
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-gray);
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 14rem;

  @include element('backdrop') {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--ion-color-role-grey);
    opacity: 0.5;
    margin-top: 65px;
    z-index: 1;
  }
}

// Screens under 480px
@media screen and (max-width: 480px) {
  @include block('addskills-modal') {
    width: 100%;
    left: 0px;
    @include element('backdrop') {
      display: none;
    }
  }
}
</style>
