<template>
  <div class="collection-about">
    <div class="collection-desc">
      <div class="collection-title">Description</div>
      <div :class="collection.content.length > 0 ? 'collection-subtitle' : 'collection-subtitle collection-subtitle-margin'" v-html="$sanitize(collection.synopsis)"></div>
      <div class="collection-disclaimer" v-if="collection.noOfItemsHidden > 0 ">
            <span style="padding-right:8px;padding-top:3px"> <svg-icon icon-class="info-icon" class="g-icon--small"></svg-icon> </span>
            <span class="collection-disclaimer-content">
              Some content in this track has different permissions, and account access is limited. {{ collection.noOfItemsHidden }} limited access items are hidden. If you need
              assistance, please contact the <span class="link-btn g-clickable" @click="$router.push('/help-center-main').catch(() => {})">Help Center</span>.
            </span>
      </div>
      <ion-grid v-if="collection.content.length" class="ion-no-padding">
        <ion-row>
          <ion-col class="list-container" size="12" v-for="(item, index) in collection.content" :key="index">
            <!-- <CollectionItems :itemsLength="collection.content.length" :item="item" :index="index" /> -->
            <UniversalCard :item="item" :index="index" :itemsLength="collection.content.length" :tab="tab" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>

<script>
// import CollectionItems from '@/views/collection/components/CollectionItems'
import UniversalCard from '@/components/organisms/UniversalCard'

export default {
  name: 'CollectionAbout',
  components: { UniversalCard },
  props: {
    collection: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      tab: 'track'
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.collection-about {
  padding: 8px 0px;
  background-color: #fff;
  border-radius: 20px;
  .collection-title {
    font-family: 'Futura PT Demi';
    font-size: 24px;
    color: black;
    text-align: left;
    border-bottom: 2px solid #e0e1e1;
    padding-bottom: 30px;
  }
  .collection-subtitle {
    text-align: left;
    margin-top: 36px;
    font-family: Arial;
    color: #5c5c5c;
    font-size: 16px;
    line-height: 25px;
  }
  .collection-disclaimer {
    padding: 30px;
    margin-top: 48px;
    background-color: #fff4df;
    border-radius: 4px;
    line-height: 25px;
    display: flex;
    .collection-disclaimer-content {
      text-align: left;
      font-family: Arial;
      color: #000000;
      font-size: 14px;
      @media screen and (max-width: 484px) {
        font-size: 12px;
      }
      .link-btn {
        font-size: 14px;
        font-family: 'Arial';
        color: var(--ion-color-tertiary);
        text-decoration: underline;
      }
    }
  }
  .collection-subtitle-margin {
    margin-bottom: 48px;
  }
}

.collection-desc {
  padding: 30px 100px;
}

@media screen and (max-width: 1477px) {
  .collection-desc {
    padding: 30px 60px;
  }
}

@media screen and (max-width: 1366px) {
  .collection-desc {
    padding: 40px;
  }
}

@media screen and (max-width: 484px) {
  .collection-desc {
    padding: 1rem;
  }
}
</style>
