<template>
  <div class="asset-detail-container">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col size-xl="8.5" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="ion-no-padding">
          <div :class="isDesktopPlatform ? 'asset-content-desktop' : 'asset-content-mobile'">
            <div v-if="isDesktopPlatform" class="tabs-container">
              <div v-for="(tab, index) in tabs" :key="index" class="tab" :class="selectedTabIdx === index ? 'selected' : ''" @click="onTabSelected(index)">
                {{ tab.title }}
              </div>
            </div>
            <div v-else class="asset-details-mobile-tabs-container">
              <div class="selected-tab" @click="toggleTabs">
                {{ tabs[selectedTabIdx].title }}
                <i class="dropdown-arrow"></i>
              </div>
            </div>
            <div v-if="tabsOpen" @click="tabsOpen = false" class="asset-details-overlay">
              <ul class="asset-details-tabs-selection">
                <li v-for="(tab, index) in tabs" :key="index" :class="selectedTabIdx === index ? 'selected' : ''" :value="tab.title" @click="onTabSelected(index)">
                  {{ tab.title }}
                </li>
              </ul>
            </div>
            <div style="text-align: left" class="asset-desc-list">
              <div v-if="selectedTabIdx === 0">
                <div class="asset-short-description">{{ asset.shortDescription }}</div>
                <div v-if="asset.promoDescription" v-html="$sanitize(asset.promoDescription)" />
                <CarouselDesktop v-if="{}.hasOwnProperty.call(this.asset, 'media')" :slidesShow="1" :responsive="false" class="media-carousel">
                  <AssetMediaPreview v-for="(media, index) in asset.media" :key="index" :media="media" @media-selected="mediaModalVisible(true, index)" />
                </CarouselDesktop>
                <div v-if="asset.fullDescription" v-html="$sanitize(asset.fullDescription)" />
              </div>
              <div v-else>
                <ion-spinner v-if="isLoading" color="#1f3370" style="margin-top: 1rem; margin-left: 45%"></ion-spinner>
                <vue-pdf-embed :source="asset.howToGuide.link" @rendered="onRender" style="margin-top: 1rem" />
              </div>
            </div>
          </div>
          <ItemRating :item="asset" :scroll="scroll" :ratingDetails="ratingDetails" @update-ratings="getRatingById()" />
          <RelatedContent v-if="relatedContent.length > 0" :relatedContent="relatedContent" />
        </ion-col>
        <ion-col size-xl="3.5" class="ion-no-padding">
          <div :class="isDesktopPlatform ? 'asset-info-desktop' : 'asset-info-mobile'">
            <div v-if="asset.meta.isCompanyAsset && isDesktopPlatform" class="author-banner">{{ asset.provider.name }} ASSET</div>
            <ion-row class="ion-no-padding asset-info-detail">
              <ion-col class="ion-no-padding asset-info-title">Required technology</ion-col>
              <ion-col class="ion-no-padding application-info-value">
                <div class="application" v-for="application in asset.applications" :key="application.name">
                  <img style="width: 20px; height: 20px" v-if="application.src" :src="application.src" />
                  <div style="margin-right: 10px" class="asset-info-value">{{ application.name }}</div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row v-if="asset.functions && asset.functions.length" class="ion-no-padding asset-info-detail">
              <ion-col class="ion-no-padding asset-info-title">Function</ion-col>
              <ion-col v-if="asset.functions.length !== allFunctionsLength" class="ion-no-padding">
                <div v-for="(fnctn, index) in asset.functions" :key="fnctn" :class="index === asset.functions.length - 1 ? '' : 'function-value'">
                  <div class="asset-info-value">{{ fnctn }}</div>
                </div>
              </ion-col>
              <ion-col v-if="asset.functions.length === allFunctionsLength" class="ion-no-padding">
                <div>
                  <div class="asset-info-value">All Functions</div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row class="ion-no-padding asset-info-detail">
              <ion-col class="ion-no-padding asset-info-title">Asset type</ion-col>
              <ion-col class="ion-no-padding">
                <div>
                  <div class="asset-info-value">{{ asset.type.name }}</div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row class="ion-no-padding asset-info-detail" v-if="asset.emsiSkills && asset.emsiSkills.length">
              <ion-col class="ion-no-padding asset-info-title">Skills</ion-col>
              <ion-col class="skills-padding application-info-value">
                <div v-for="skill in asset.emsiSkills" :key="skill">
                  <a class="asset-skill-value" href="#" @click="onSkillClick(skill)">{{ skill }}</a>
                </div>
              </ion-col>
            </ion-row>
            <ion-row v-if="asset.howToGuide.link.length || asset.templateFile.link.length" class="ion-no-padding asset-info-detail">
              <ion-col class="ion-no-padding asset-info-title">Resources</ion-col>
              <ion-col class="ion-no-padding">
                <div class="asset-info-resource" @click="onClickHowToGuide">
                  <svg-icon icon-class="file-pdf" style="margin-right: 0.5rem" class="g-icon--small"></svg-icon>How-to guide
                </div>
                <div v-if="asset.templateFile.name.length && asset.templateFile.link.length" class="asset-info-resource" @click="onClickTemplateFile">
                  <svg-icon icon-class="download-box" style="margin-right: 0.5rem" class="g-icon--small"></svg-icon>{{ asset.templateFile.name }}
                </div>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <AssetMediaModal v-if="isMediaModal.visible" @close="mediaModalVisible(false, 0)" :media="asset.media" :selected-media="isMediaModal.index"></AssetMediaModal>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import localforage from 'localforage'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import ItemRating from '@/components/molecules/ItemRating'
import RelatedContent from '@/components/molecules/RelatedContent'
import CarouselDesktop from '@/components/organisms/CarouselDesktop'
import AssetMediaModal from '@/views/asset/components/AssetMediaModal'
import AssetMediaPreview from '@/views/asset/components/AssetMediaPreview'
import { mapGetters } from 'vuex'

export default {
  name: 'AssetDetail',
  components: { VuePdfEmbed, CarouselDesktop, AssetMediaModal, AssetMediaPreview, ItemRating, RelatedContent },
  props: {
    asset: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    scroll: {
      type: Number,
      required: false,
      default: () => {
        return 0
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: true,
      selectedTabIdx: 0,
      tabsOpen: false,
      tabs: [],
      assetMediaUrl: '',
      isLoading: true,
      isMediaModal: {
        visible: false,
        index: 0
      },
      allFunctionsLength: 19,
      authToken: '',
      ratingDetails: {},
      relatedContent: []
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async created() {
    this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
    this.asset.howToGuide = {
      name: this.asset.supportingFile && this.asset.supportingFile.name ? this.asset.supportingFile.name : '',
      link: this.asset.supportingFile && this.asset.supportingFile.pointer ? `${this.assetMediaUrl}how-to-guides/${this.asset.supportingFile.pointer}` : ''
    }
    this.asset.templateFile = {
      name: this.asset.inputTemplateFile && this.asset.inputTemplateFile.name ? this.asset.inputTemplateFile.name : '',
      link: this.asset.inputTemplateFile && this.asset.inputTemplateFile.pointer ? `${this.assetMediaUrl}asset-templates/${this.asset.inputTemplateFile.pointer}` : ''
    }
    this.tabs = !this.asset.howToGuide.link.length ? [{ title: 'Overview' }] : [{ title: 'Overview' }, { title: 'How-to guide' }]
    this.getRatingById()
  },
  mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  filters: {},
  methods: {
    async getRatingById() {
      const authToken = this.authToken || (await localforage.getItem('my_access_token'))
      this.$learnAdmin.getRatingById({ token: authToken, id: this.$route.params.id || this.asset.id }).then((res) => {
        this.ratingDetails = res || {}
        this.$emit('rating-details', this.ratingDetails)
      })
      const relatedContent = await this.$learnAdmin.getRelatedContentById({ token: authToken, id: this.$route.params.id || this.asset.id })
      this.relatedContent = relatedContent.contents || []
    },
    saveAs(blob, fileName) {
      const url = window.URL.createObjectURL(blob)
      const anchorElem = document.createElement('a')
      anchorElem.style = 'display:none'
      anchorElem.href = url
      anchorElem.download = fileName
      document.body.appendChild(anchorElem)
      anchorElem.click()
      document.body.removeChild(anchorElem)
      setTimeout(function () {
        window.URL.revokeObjectURL(url)
      }, 1000)
    },
    onTabSelected(index) {
      this.selectedTabIdx = index
      if (this.$platform === 'Mobile') {
        this.toggleTabs()
      }
    },
    toggleTabs() {
      this.tabsOpen = !this.tabsOpen
    },
    async onClickHowToGuide() {
      if (!this.isDesktopPlatform) {
        window.open(this.asset.howToGuide?.link, '_blank')
      } else {
        this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
        const request = {
          id: this.asset.id,
          token: this.authToken
        }
        const file = await this.$learnAdmin.downloadHowToGuide(request)
        if (file && file.blob) {
          this.saveAs(file.blob, file.filename)
        }
      }
      const payload = await this.onPrepareGAPayload('How-to guide viewed')
      await this.GAEventTrack(payload)
    },
    async onClickTemplateFile() {
      window.open(this.asset.templateFile.link, '_blank')
      const payload = await this.onPrepareGAPayload('How-to guide downloaded')
      await this.GAEventTrack(payload)
    },
    onRender() {
      this.isLoading = false
    },
    async mediaModalVisible(visible, index) {
      this.isMediaModal.visible = this.isDesktopPlatform && visible
      this.isMediaModal.index = index + 1
      const action = `Asset media ${this.asset.media[index + 1].caption} viewed`
      const payload = await this.onPrepareGAPayload(action)
      await this.GAEventTrack(payload)
    },
    onSkillClick(skill) {
      this.$router.push({ name: 'Search', query: { q: skill, suggestionSkill: skill }, params: { fromAssetLandingPage: true } }).catch(() => {})
    },
    async onPrepareGAPayload(action) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        asset: {
          id: this.asset.id,
          name: this.asset.name,
          author: this.asset.author,
          technology: this.asset.applications,
          type: this.asset.inventoryType
        }
      }
      const payload = {
        action: action,
        category: 'Asset Detail',
        value: JSON.stringify(item)
      }
      return payload
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.asset-detail-container {
  margin-top: 1.5rem;
}
h3,
h4 {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-family: 'Futura PT Demi';
  color: #000000;
}

h4 {
  span {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-family: 'Futura PT Demi';
    color: var(--ion-color-dark-blue);
  }
}

h6 {
  font-family: 'Futura PT Demi';
  color: var(--ion-color-medium);
}

h6,
p {
  span {
    position: relative;
    top: -3px;
  }
}

.asset-desc-list {
  padding: 0rem 3rem;
  ul {
    list-style-image: url('~@/views/asset/components/images/li-bullet.png');
    padding: 0 0 0 13px;
    margin: 2px 0;
    li {
      font: normal normal normal 1rem Arial;
      padding: 0 0 0 5px;
      margin-bottom: 20px;
      color: #5c5c5c;
      span {
        font: normal normal normal 1rem Arial;
        top: 0;
      }
      h6,
      p {
        font: normal normal normal 1rem Arial;
        margin: 0;
        color: #5c5c5c;
        span {
          font: normal normal normal 1rem Arial;
          top: 0;
          color: #5c5c5c;
        }
      }
    }
    @media screen and (max-width: 484px) {
      padding: 0 0 0 16px;
    }
  }
}
</style>

<style lang="scss" scoped>
.asset-details-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  z-index: 9;
}
.asset-details-tabs-selection {
  margin-top: 3rem;
  margin-left: 1rem;
  background: var(--ion-color-white);
  padding: 4px;
  width: 225px;
  z-index: 10;
  border-radius: 4px;
  li {
    list-style: none;
    color: var(--ion-color-dark);
    padding: 0.8rem 0.8rem;
    margin: 0.3rem 0.1rem;
    text-align: start;
    border-radius: 6px;
    font-family: 'Arial';
    font-size: 14px;
    span {
      margin-left: 0.3rem;
      padding: 0rem 0.7rem;
      border-radius: 1rem;
      background: var(--ion-color-light-role-grey);
      font-size: 18px;
      font-family: Arial;
      color: var(--ion-color-black);
    }
    &.selected {
      background-color: #e9ebf1;
      color: var(--ion-color-dark-blue);

      span {
        background: var(--ion-color-black);
        color: var(--ion-color-white);
      }
    }
  }
}
.asset-details-mobile-tabs-container {
  position: relative;
  margin: 0 0rem;
  justify-content: flex-start;
  display: flex;
  border-bottom: 2px solid var(--ion-color-light-gray);
  .selected-tab {
    font-size: 14px;
    color: var(--ion-color-black);
    display: inline-block;
    padding-bottom: 0.5rem;
    font-family: 'Arial';
    font-weight: bold;
    border-bottom: 4px solid var(--ion-color-secondary-lighter);
    span {
      margin-left: 0.3rem;
      padding: 0rem 0.7rem;
      border-radius: 1rem;
      background: var(--ion-color-black);
      font-size: 14px;
      color: var(--ion-color-white);
      font-family: 'Arial';
    }
    .dropdown-arrow {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid var(--ion-color-black);
      display: inline-block;
      margin-left: 0.6rem;
    }
  }
}

.asset-content-desktop,
.asset-content-mobile {
  width: 100%;
  background-color: var(--ion-color-white);
  border-radius: 20px;
}

.asset-content-desktop {
  padding: 1rem 0rem;
}

.asset-content-mobile {
  position: relative;
  padding: 1rem;
}

.tabs-container {
  padding: 0rem 3rem;
  display: flex;
  border-bottom: 2px solid var(--ion-color-light-gray);

  .tab {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: var(--ion-color-black);
    font: normal normal bold 1rem Arial;
    cursor: pointer;

    &.selected {
      border-bottom: 0.313rem solid #00d1b0;
    }
  }
}

.asset-info-desktop {
  margin-top: 0rem;
  margin-left: 2rem;
}

.asset-info-mobile {
  margin-top: 1rem;
  margin-left: 0rem;
}

.asset-info-detail {
  text-align: left;
  padding: 1.25rem 0 10px 1rem;
  border-bottom: 2px solid #e0e1e1;

  &:last-child {
    border-bottom: none;
  }
}

.application {
  display: flex;
  gap: 8px;
}

.application-info-value {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.asset-info-title {
  text-align: left;
  font-size: 16px;
  font-family: 'Arial';
  font-weight: bold;
  color: var(--ion-color-black);
  margin-bottom: 10px;
  min-width: 180px;
}

.asset-info-value {
  text-align: left;
  font-size: 16px;
  font-family: 'Arial';
  font-weight: normal;
  color: var(--ion-color-black);
}

.asset-skill-value {
  text-align: left;
  font: normal normal bold 0.75rem Arial;
  color: var(--ion-color-tertiary);
  background: var(--ion-color-mid-blue) 0% 0% no-repeat padding-box;
  border-radius: 0.94rem;
  padding: 0.375rem 0.56rem;
  text-decoration: none;
}
.skills-padding {
  padding-bottom: 0.625rem;
}

.function-value {
  margin-bottom: 0.75rem;
}

.asset-info-resource {
  margin-bottom: 0.5rem;
  cursor: pointer;
  font: normal normal normal 1rem Arial;
  color: var(--ion-color-tertiary);
}

.author-banner {
  padding: 10px;
  background: #000000;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Arial';
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5rem;
  border-radius: 4px;
}

.asset-short-description {
  margin: 3rem 0 1.5rem;
  font-size: 2rem;
  font-family: 'Futura PT Demi';
  color: black;
  @media screen and (max-width: 830px) {
    font-size: 1.5rem;
  }
}

.media-carousel {
  margin: 3rem 0rem;
  img {
    cursor: pointer;
  }
}

@media screen and (max-width: 1366px) {
  .asset-info-detail {
    display: grid;
  }
  .asset-info-value {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .asset-info-desktop {
    margin-left: unset;
    margin-top: 3rem;
  }
  .asset-info-detail {
    display: flex;
  }
}

@media screen and (max-width: 484px) {
  .asset-short-description {
    margin: 40px 0 3rem;
    font-size: 1.5rem;
  }
  .asset-info-detail {
    display: grid;
  }
  .media-carousel {
    margin: 1.5rem 0;
  }
}
</style>
