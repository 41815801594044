<template>
    <div>
        <div>
            <ion-row class="ion-align-items-center">
                <ion-col size="10"></ion-col>
                <ion-col>
                    <button class="slider-close" aria-label="close side menu" @click="closeTrackBar">
                        <svg-icon icon-class="close-icon" class-name="close-button-icon" />
                    </button>
                </ion-col>
            </ion-row>
        </div>
        <div class="no-tracks-section">
            <ion-row class="track-header">
                You haven’t created a track yet
            </ion-row>
            <ion-row>
                <ion-col style="text-align: center">
                    <img class="img-style" src="@/assets/images/illustration.png" />
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <h6 class="get-started">Get started by clicking below</h6>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <p style="text-align: center">
                        <ion-button shape="round" color="primary" class="ion-button-primary-dark track-btn"
                            @click="createTrack">
                            <span class="icn-add-bg">
                                <svg-icon icon-class="icon-add" class="g-icon--small"></svg-icon>
                            </span>
                            &nbsp;Create a track
                        </ion-button>
                    </p>
                </ion-col>
            </ion-row>
        </div>

        <!-- <ion-grid class="no-track-margin">
            <ion-row class="ion-align-items-center">
                <button class="slider-close" aria-label="close side menu" @click="closeTrackBar">
                    <svg-icon icon-class="close-icon" class-name="close-button-icon" />
                </button>
            </ion-row>
            <div>
                <ion-row class="track-header">
                    You haven’t created a track yet
                </ion-row>
                <ion-row>
                    <ion-col style="text-align: center">
                        <img style="width: 361px; height: 295px" src="@/assets/images/illustration.png" />
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <h6 class="get-started">Get started by clicking below</h6>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <p style="text-align: center">
                            <ion-button shape="round" color="primary" class="ion-button-primary-dark track-btn"
                                @click="createTrack">
                                <span class="icn-add-bg">
                                    <svg-icon icon-class="icon-add" class="g-icon--small"></svg-icon>
                                </span>
                                &nbsp;Create a track
                            </ion-button>
                        </p>
                    </ion-col>
                </ion-row>
            </div>
        </ion-grid> -->
    </div>
</template>

<script>
export default {
    name: 'NoTrack',
    props: {},
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        closeTrackBar() {
            this.$eventBus.$emit(`close-track-bar`);
        },
        createTrack() {
            this.$emit('show-create-track', 'add-track');
        }
    }
}
</script>
  
<style lang="scss" scoped>
.no-tracks-section {
    height: 533px;
    width: 509px;
}

.slider-close {
    color: unset;
    text-decoration: unset;
    background-color: unset;
    margin: 20px 0px 0px 40px;
}

@media screen and (max-width: 480px) {
    .no-tracks-section {
        width: 85%;
    }

    .img-style {
        width: 80%;
    }

    .slider-close {
        margin: 20px 0px 0px 0px !important;
    }
}

.close-button-icon {
    height: 1.25rem !important;
    width: 1.25rem !important;
}

.track-header {
    margin-top: 1rem;
    font: normal normal normal 30px/34px Futura PT Demi;
    margin-bottom: 3rem;
    text-align: center;
    padding-left: 2.7rem;
}

.track-btn {
    text-transform: none !important;
    font: normal normal normal 16px/22px Futura PT Demi;
}

.icn-add-bg {
    background: #1F3370 0% 0% no-repeat padding-box;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 20px;
    margin-right: 0.5rem;
}

.g-icon--small {
    width: 0.5rem !important;
    height: 0.5rem !important;
}

.get-started {
    text-align: center;
    margin-bottom: 0px !important;
}

.img-style {
    width: 361px;
    height: 295px;
}

ion-button {
  --box-shadow: none;
}
</style>