<template>
  <div>
    <ion-spinner v-if="!dataReady" color="#1f3370" style="margin-top: 1rem"></ion-spinner>
    <div v-if="dataReady" class="asset-container" :class="isDesktopPlatform ? 'home-asset-container' : ''">
      <div class="asset-container-grid">
        <div class="asset-grid-item">
          <div class="title">Explore assets</div>
          <div class="sub-title">ProEdge offers hundreds of assets to put your new skills into practice.</div>
          <ion-button shape="round" color="primary" class="asset-explore-btn" @click="onExplore()">Explore</ion-button>
        </div>
        <div v-if="tenantAsset.available" class="asset-grid-item">
          <div class="title">Your company assets</div>
          <div class="sub-title">Find assets your colleagues have created.</div>
          <ion-button shape="round" color="primary" class="asset-discover-btn" @click="onDiscover()">Discover</ion-button>
        </div>
        <div v-if="tenantAsset.enabled" class="asset-grid-item">
          <div class="title">Submit an asset</div>
          <div class="sub-title">Share an asset you've created with others in your organization.</div>
          <ion-button shape="round" color="primary" class="asset-submit-btn" @click="onSubmitAsset()">
            {{ tenantAsset.action }}
            <span v-if="tenantAsset.submissionLink.length" style="padding-left: 5px"><svg-icon icon-class="expand-out" style="height: 16px; width: 16px"></svg-icon></span>
          </ion-button>
        </div>
      </div>
      <div class="asset-orb-image">
        <img src="@/assets/images/asset-orb.png" />
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import { Plugins } from '@capacitor/core'
const { Browser } = Plugins

export default {
  name: 'HomeAsset',
  props: {},
  components: {},
  data() {
    return {
      authToken: '',
      tenant: null,
      tenantAsset: {
        available: true,
        enabled: false,
        action: '', // Submit an asset | Manage my submissions
        submissionLink: ''
      },
      dataReady: false,
      isDesktopPlatform: true
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  created() {},
  async mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = (await localforage.getItem('my_access_token')) || this.auth_token
    this.tenant = await this.$learnAdmin.getUserTenantInfo({ token: this.authToken })
    await this.getTenantAssetCount()
    const settings = JSON.parse(this.tenant.settings)
    this.tenantAsset.enabled = {}.hasOwnProperty.call(settings, 'tenant-assets-submission-enabled') && settings['tenant-assets-submission-enabled']
    this.tenantAsset.submissionLink = {}.hasOwnProperty.call(settings, 'apply-asset-upload-link') ? settings['apply-asset-upload-link'] : ''
    this.tenantAsset.action = this.tenantAsset.enabled && this.tenantAsset.submissionLink.length ? 'Submit an asset' : 'Manage my submissions'
    this.dataReady = true
  },
  methods: {
    onExplore() {
      this.$router.push({ name: 'Recommended', params: { fromHome: true } }).catch(() => {})
      this.onActionSelected('Explore')
    },
    async onDiscover() {
      this.$router.push({ name: 'Search', query: { q: '' }, params: { fromHome: true, searchBy: 'provider', provider: this.tenant.name } }).catch(() => {})
      this.onActionSelected('Discover')
    },
    async onSubmitAsset() {
      if (this.tenantAsset.action === 'Submit an asset') {
        await Browser.open({ url: this.tenantAsset.submissionLink })
      } else if (this.tenantAsset.action === 'Manage my submissions') {
        this.$router.push('/submit-asset')
      }
      this.onActionSelected(this.tenantAsset.action)
    },
    async getTenantAssetCount() {
      const payload = {
        token: this.authToken,
        query: '',
        pageNo: 1,
        sortBy: 'bestmatch',
        body: {
          accreditation: [],
          author: [],
          content_type: [],
          creditHours: [],
          creditType: [],
          duration: [],
          function: [],
          inventoryType: [],
          level: [],
          provider: [this.tenant.name],
          tool: []
        }
      }
      const assets = await this.$learnAdmin.searchAll(payload)
      if ({}.hasOwnProperty.call(assets, 'totalResults')) {
        this.tenantAsset.available = assets.totalResults > 0
      }
    },
    async onActionSelected(action) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        action: `Button ${action} clicked`
      }
      const payload = {
        action: action,
        category: 'Home Asset Tab',
        value: JSON.stringify(item)
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.asset-container {
  display: flex;
  padding-top: 64px !important;
}

.asset-container-grid {
  display: grid;
  width: 65%;
  height: fit-content;

  div {
    text-align: left;
  }
  .asset-grid-item {
    margin-bottom: 56px;
  }
}

.title {
  text-align: left;
  font-size: 1.6rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-tertiary);
}

.sub-title {
  margin-top: 0.5rem;
  text-align: left;
  font-size: 1rem;
  font-family: 'Arial';
  color: var(--ion-color-medium);
}

.asset-explore-btn,
.asset-discover-btn {
  margin-top: 1.5rem;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #feb92e;
  --ion-color-primary-contrast: black;
}

ion-button {
  --box-shadow: none;
}

.asset-submit-btn {
  margin-top: 1.5rem;
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: #1f3370;
}

.asset-orb-image {
  img {
    width: auto;
    height: 80%;
  }
}

@media screen and (max-width: 1366px) {
  .asset-orb-image {
    img {
      width: auto;
      height: 70%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .asset-container-grid {
    width: 75%;
  }

  .asset-orb-image {
    img {
      width: auto;
      height: 60%;
      margin-top: 20%;
    }
  }
}

@media screen and (max-width: 768px) {
  .asset-container-grid {
    width: 85%;
  }

  .asset-orb-image {
    img {
      width: auto;
      margin-top: 30%;
    }
  }
}

@media screen and (max-width: 414px) {
  .asset-container-grid {
    width: 100%;
    gap: 2rem;
    margin: 0rem 0.5rem;
  }

  .asset-orb-image {
    display: none;
  }
}
</style>
