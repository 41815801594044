var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel-style" },
    [
      _c(
        "VueSlickCarousel",
        _vm._b(
          {
            staticClass: "edge-achievements",
            scopedSlots: _vm._u([
              {
                key: "prevArrow",
                fn: function(arrowOption) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "custom-arrow",
                        staticStyle: { "margin-left": "-8px" },
                        attrs: { title: "Previous" },
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                          },
                          click: function($event) {
                            return _vm.removeOutline($event)
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          staticStyle: {
                            height: "16px",
                            width: "16px",
                            "margin-left": "2px"
                          },
                          attrs: { "icon-class": "carousel-left-arrow" }
                        }),
                        _c("span", { staticStyle: { display: "none" } }, [
                          _vm._v(
                            " " +
                              _vm._s(arrowOption.currentSlide) +
                              "/" +
                              _vm._s(arrowOption.slideCount) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "nextArrow",
                fn: function(arrowOption) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "custom-arrow",
                        staticStyle: { "margin-right": "-8px" },
                        attrs: { title: "Next" },
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                          },
                          click: function($event) {
                            return _vm.removeOutline($event)
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          staticStyle: {
                            height: "16px",
                            width: "16px",
                            "margin-left": "4px"
                          },
                          attrs: { "icon-class": "carousel-right-arrow" }
                        }),
                        _c("span", { staticStyle: { display: "none" } }, [
                          _vm._v(
                            " " +
                              _vm._s(arrowOption.currentSlide) +
                              "/" +
                              _vm._s(arrowOption.slideCount) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "customPaging",
                fn: function(page) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "custom-dot",
                        attrs: { "aria-label": "Go to slide " + (page + 1) },
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                          },
                          click: function($event) {
                            return _vm.removeOutline($event)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(page) + " ")]
                    )
                  ]
                }
              }
            ])
          },
          "VueSlickCarousel",
          _vm.settings,
          false
        ),
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }