<template>
  <div v-if="showCarousal" class="recommend-home-content">
    <div v-if="carousalType === 'personalized' || (carousalType === 'boostSkills' && recommendations.content.length)">
      <div
        :style="!recommendations.content.length ? 'padding: 0 0 2rem' : isActivityContentNotEmpty && isDesktopPlatform ? 'padding: 3rem 0' : 'padding: 0 0 3rem 0'"
        class="recommend-title"
      >
        <div class="recommend-label">{{ recommendations.activityTitle }}</div>
        <div class="recommend-count">{{ recommendations.content.length }}</div>
      </div>
      <div v-if="dataReady && recommendations.content.length">
        <CarouselDesktop v-if="isDesktopPlatform" :style="{ '--bottom': '-11px' }" :slidesShow="4" :responsive="true">
          <UniversalCard
            :id="item.id"
            v-for="(item, index) in recommendations.content"
            :key="item.id"
            :item="item"
            :index="index"
            :itemsLength="recommendations.content.length"
            class="carousel-desktop-item"
            :tab="tabName"
            cardType="carousal"
            :isExpandCardAllow="false"
            @recommended-item-click="onRecommendedItemClick(item, index)"
          />
        </CarouselDesktop>
        <CarouselMobile v-if="!isDesktopPlatform">
          <UniversalCard
            :id="item.id"
            v-for="(item, index) in recommendations.content"
            :key="item.id"
            :item="item"
            :index="index"
            :itemsLength="recommendations.content.length"
            class="carousel-mobile-item"
            :tab="tabName"
            cardType="carousal"
            :isExpandCardAllow="false"
            @recommended-item-click="onRecommendedItemClick(item, index)"
          />
        </CarouselMobile>
      </div>
      <div v-else class="empty-carousal-container">
        <div class="empty-carousal-description">
          <div class="empty-carousal-title">Not enough activity…yet. Here’s how to get started!</div>
          <div class="empty-carousal-section">
            <svg-icon icon-class="icon-search" />
            <div class="empty-carousal-title"><span @click="goToExplore()">Explore</span> learning that interests you</div>
          </div>
          <div class="empty-carousal-section">
            <svg-icon style="width: 24px !important; height: 24px !important" icon-class="icon-progress-complete" />
            <div class="empty-carousal-title">Complete learning you found</div>
          </div>
        </div>
        <div class="empty-carousal-image">
          <img src="@/assets/images/no-content.png" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="recommend-label-shimmer">
      <ion-skeleton-text animated />
    </div>
    <CarouselDesktop style="margin-bottom: 1rem" v-if="isDesktopPlatform" :slidesShow="4" :responsive="true">
      <RecommendedSkeleton v-for="i in 4" :key="i" class="carousel-desktop-item-shimmer" />
    </CarouselDesktop>
    <CarouselMobile v-else>
      <RecommendedSkeleton v-for="i in 2" :key="i" class="carousel-mobile-item-shimmer" />
    </CarouselMobile>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import { Plugins } from '@capacitor/core'
const { Network } = Plugins
import UniversalCard from '@/components/organisms/UniversalCard'
import CarouselDesktop from '@/components/organisms/CarouselDesktop'
import CarouselMobile from '@/components/molecules/CarouselMobile'
import RecommendedSkeleton from '@/views/explore/components/RecommendedSkeleton'

export default {
  name: 'PersonalizedCarousal',
  components: { UniversalCard, CarouselDesktop, CarouselMobile, RecommendedSkeleton },
  props: {
    recommendations: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    showCarousal: {
      type: Boolean,
      required: true,
      default: () => {
        return false
      }
    },
    carousalType: {
      type: String,
      required: true,
      default: () => {
        return ''
      }
    },
    isActivityContentNotEmpty: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  data() {
    return {
      learnerId: '',
      tenantGuid: '',
      tabName: 'recommended',
      additionalInfoObj: {},
      isDesktopPlatform: true,
      dataReady: false
    }
  },
  watch: {},
  async mounted() {
    this.dataReady = false
    this.learnerId = await localforage.getItem('my_user_id')
    this.tenantGuid = await localforage.getItem('my_tenant_guid')
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.additionalInfoObj = await this.additionalInfo()
    await this.getRecommendations()
    this.dataReady = true
  },
  methods: {
    async getRecommendations() {
      this.additionalInfoObj['time-stamp'] = new Date().toTimeString()
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // check if element is in view
            if (entry.isIntersecting) {
              const item = {
                'learner-id': this.learnerId,
                'tenant-guid': this.tenantGuid,
                'content-id': entry.target.id,
                'recommend-index': entry.target.dataset.cardIndex,
                'device-info': this.additionalInfoObj,
                action: 'Personalized Carousal Viewed',
                timestamp: new Date().getTime()
              }
              const payload = {
                action: 'Explore',
                category: 'Personalized Carousal Viewed',
                value: JSON.stringify(item),
                csvalue: item
              }
              // send GA & CS event
              this.GAEventTrack(payload)
              // remove observer once element is viewed
              observer.unobserve(entry.target)
            }
          })
        },
        {
          root: null, // default is the viewport
          threshold: 0.5 // percentage of target's visible area.
        }
      )
      setTimeout(() => {
        this.recommendations.content.forEach((item) => {
          observer.observe(document.getElementById(item.id))
        })
      }, 2000)
    },
    async onRecommendedItemClick(recItem, recItemIndex) {
      const item = {
        'learner-id': this.learnerId,
        'tenant-guid': this.tenantGuid,
        'content-id': recItem.id,
        'recommend-index': recItemIndex,
        action: 'Personalized Carousal Item Click',
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'Home',
        category: 'Personalized Carousal Item Click',
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async goToExplore() {
      this.$router.push({ name: 'Explore' }).catch(() => {})
    },
    async additionalInfo() {
      const obj = {
        'screen-width': screen.width,
        'screen-height': screen.height,
        'connection-type': 'none'
      }
      let userAgent = navigator.userAgent.match(/\((.*?)\)/)
      userAgent = userAgent.length > 1 ? userAgent[1].split(';') : []
      obj['device-model'] = userAgent[0].trim()
      obj['os'] = userAgent[1].trim()
      if (this.$platform !== 'Desktop') {
        obj['device-model'] = this.$device == 'android' ? `${userAgent[2].trim()}` : userAgent[0].trim()
        const network = await Network.getStatus()
        obj['connection-type'] = network.connectionType || 'none'
      }
      return new Promise((resolve) => {
        resolve(obj)
      })
    },
    async GAEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend-home-content {
  .recommend-title {
    display: flex;
    gap: 24px;
    align-items: center;
    .recommend-count {
      width: 40px;
      height: 30px;
      border-radius: 15px;
      font-size: 14px;
      font-family: 'Arial';
      font-weight: bold;
      background: #e3e3e3;
      padding: 7px;
      @media screen and (max-width: 484px) {
        width: 51px;
        align-self: flex-start;
        margin-top: 6px;
      }
    }
    .recommend-label {
      font: normal normal normal 1.5rem/1.875rem Futura PT Demi;
      color: #000000;
      text-align: left;
    }
  }
  .carousel-desktop-item {
    width: 360px !important;
    height: 360px !important;
    border-radius: 20px;
    padding: 2rem;
    background-color: #ffffff;
  }
  .carousel-mobile-item {
    min-width: 360px !important;
    max-width: 360px !important;
    min-height: 360px !important;
    max-height: 360px !important;
    padding: 2rem;
    border-radius: 20px;
    margin-right: 1rem;
    background-color: #ffffff;
  }
  .empty-carousal-container {
    display: flex;
    height: 360px;
    background: #ffffff;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 3rem;
    .empty-carousal-description {
      align-self: center;
      padding-left: 8.8%;
      width: 50%;
      text-align: left;
      @media (max-width: 1366px) {
        width: 60%;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
      @media (max-width: 484px) {
        padding-left: 3.8%;
      }
      .empty-carousal-title {
        color: #000000;
        font-family: 'Futura PT Demi';
        font-size: 28px;
        width: 360px;
      }
      .empty-carousal-section {
        display: flex;
        width: 360px;
        gap: 1rem;
        margin-top: 32px;
        align-items: center;
        .svg-icon {
          height: 19px !important;
          width: 19px !important;
        }
        .empty-carousal-title {
          font-size: 1rem;
          font-family: Arial;
          color: #5c5c5c;
          span {
            color: #1f3370;
            text-decoration: underline;
            font-size: 1rem;
            font-family: Arial;
            cursor: pointer;
          }
        }
      }
    }
    .empty-carousal-image {
      width: 278px;
      height: 328px;
      align-self: flex-end;
      @media (max-width: 768px) {
        width: 220px;
        height: 260px;
        margin-left: 60px;
      }
      @media (max-width: 484px) {
        display: none;
      }
    }
  }
}

.recommend-label-shimmer {
  padding: 1rem 0rem 3rem 0rem;
  height: 24px;
  width: 300px;
  border-radius: 0px 12px 12px 0px;
  color: var(--ion-color-black);
}
.carousel-desktop-item-shimmer {
  width: 360px !important;
  height: 360px !important;
  border-radius: 20px;
  padding: 2rem;
}
.carousel-mobile-item-shimmer {
  min-width: 360px !important;
  max-width: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important;
  padding: 2rem;
  border-radius: 20px;
  margin-right: 1rem;
}
</style>