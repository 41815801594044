<template>
  <div class="credential-lesson-info">
    <ion-card :class="!isDesktopPlatform ? 'mobile-version' : ''">
      <ion-card-header>
        <h3 :class="!isDesktopPlatform ? 'h3-mobile' : ''">{{ lesson.title }}</h3>
      </ion-card-header>
      <ion-card-content>
        <div v-if="lesson.subtitle" v-html="$sanitize(lesson.subtitle)" />
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
export default {
  name: 'CredentialLessonInfo',
  components: {},
  props: {
    lesson: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: false
    }
  },
  computed: {},
  watch: {},
  created() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.credential-lesson-info {
  ion-card {
    text-align: left;
    padding: 1rem;
    ion-card-header {
      padding: 0 1rem;
    }
    h3 {
      font-family: 'Futura PT Demi';
      font-size: 1.8rem;
      color: map-get($edge-colors, 'tertiary');
    }
    p {
      font-size: 1.1rem;
      color: map-get($edge-colors, 'black');
    }
  }
  .mobile-version {
    padding: 0rem;
  }
  .h3-mobile {
    font-size: 1.3rem;
  }
}
</style>
