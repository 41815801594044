var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "top", class: _vm.isDesktopPlatform ? "" : "non-white-background" },
    [
      _vm.showConfirmationProgress
        ? _c("div", {
            staticClass: "a-overlay",
            on: {
              click: function($event) {
                return _vm.onMarkCompleteAction(false)
              }
            }
          })
        : _vm._e(),
      _vm.showConfirmationProgress
        ? _c("div", { staticClass: "b-modal confirm-box" }, [
            _c("h3", [_vm._v("Are you sure?")]),
            _c("p", [
              _vm._v("Are you sure you want to mark the course completed?")
            ]),
            _c(
              "div",
              { staticClass: "handler" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass: "g-button--primary ion-button-primary",
                    attrs: { "data-cy": "confirm-progress", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.onMarkCompleteAction(true)
                      }
                    }
                  },
                  [_vm._v("Confirm")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.onMarkCompleteAction(false)
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.dataReady
        ? _c(
            "div",
            [
              _vm.isContentAvailable
                ? _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding ion-padding-bottom",
                      class: _vm.isDesktopPlatform
                        ? "desktop-course-container"
                        : "mobile-course-container"
                    },
                    [
                      _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            {
                              attrs: {
                                "size-xl": "8.5",
                                "size-lg": "12",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c("div", { staticClass: "back-btn-container" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "accessible-button back",
                                    class:
                                      _vm.$platform === "Desktop"
                                        ? "back-desktop"
                                        : "back-mobile",
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.go(-1)
                                      }
                                    }
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "g-icon--small",
                                      attrs: { "icon-class": "icon-arrow-left" }
                                    }),
                                    _vm._v(" Back ")
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "ion-row",
                                { staticClass: "course-info" },
                                [
                                  _c(
                                    "ion-col",
                                    { staticClass: "course-img-background" },
                                    [
                                      {}.hasOwnProperty.call(
                                        _vm.course.meta,
                                        "cdnEnabled"
                                      ) &&
                                      _vm.course.meta.cdnEnabled &&
                                      _vm.course.image.signedRelativeUri
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.course.image
                                                  .signedRelativeUri
                                            }
                                          })
                                        : _vm.course.image.uri
                                        ? _c("img", {
                                            attrs: { src: _vm.course.image.uri }
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/course.png")
                                            }
                                          })
                                    ]
                                  ),
                                  _c("ion-col", [
                                    _c("div", { staticClass: "meta" }, [
                                      _c("div", { staticClass: "category" }, [
                                        _c("div", { staticClass: "format" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.course.format &&
                                                _vm.course.format.name
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "provider" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.course.provider &&
                                                _vm.course.provider.name
                                            )
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title",
                                        on: { click: _vm.onLaunch }
                                      },
                                      [_vm._v(_vm._s(_vm.course.title))]
                                    ),
                                    _c("div", { staticClass: "meta-info" }, [
                                      _vm.course.rating
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "course-rating",
                                              on: { click: _vm.onScroll }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  style: _vm.isDesktopPlatform
                                                    ? "font-family: Arial"
                                                    : "font-family: Arial; margin-right: 5px;"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.course.rating)
                                                  )
                                                ]
                                              ),
                                              _c("StarRating", {
                                                staticClass: "star-rating",
                                                attrs: {
                                                  "inactive-color": "#d0cfce",
                                                  "active-color": "#b98032",
                                                  "star-points": [
                                                    23,
                                                    2,
                                                    14,
                                                    17,
                                                    0,
                                                    19,
                                                    10,
                                                    34,
                                                    7,
                                                    50,
                                                    23,
                                                    43,
                                                    38,
                                                    50,
                                                    36,
                                                    34,
                                                    46,
                                                    19,
                                                    31,
                                                    17
                                                  ],
                                                  rating: _vm.course.rating,
                                                  "read-only": true,
                                                  increment: 0.1,
                                                  "star-size": 14,
                                                  "show-rating": false,
                                                  "round-start-rating": true
                                                }
                                              }),
                                              !_vm.isDesktopPlatform
                                                ? _c("svg-icon", {
                                                    staticClass:
                                                      "display-rating-mobile",
                                                    attrs: {
                                                      "icon-class":
                                                        "star-filled-desktop"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "rating-count" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.course.ratingCount
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.course.ratingCount >
                                                          1
                                                          ? "ratings"
                                                          : "rating"
                                                      )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "duration" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDuration")(
                                              _vm.course.duration
                                            )
                                          )
                                        )
                                      ]),
                                      _vm.course.duration
                                        ? _c("div", { staticClass: "level" }, [
                                            _vm._v(_vm._s(_vm.course.level))
                                          ])
                                        : _vm._e(),
                                      _vm.course.publishedAt
                                        ? _c(
                                            "div",
                                            { staticClass: "publish-date" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatPublishedDate")(
                                                    _vm.course.publishedAt
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "meta-action" },
                                      [
                                        _c(
                                          "ion-button",
                                          {
                                            staticClass: "launch-btn",
                                            attrs: {
                                              shape: "round",
                                              color: "primary"
                                            },
                                            on: { click: _vm.onLaunch }
                                          },
                                          [_vm._v(_vm._s(_vm.course.launchBtn))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "bookmark-container" },
                                          [
                                            _c("Bookmark", {
                                              staticClass: "course-bookmark",
                                              attrs: {
                                                assetId: _vm.course.id,
                                                assetType: "artifacts",
                                                meta: _vm.course.meta
                                              }
                                            }),
                                            _vm.isDesktopPlatform
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bookmark-tooltip-text"
                                                  },
                                                  [_vm._v("Bookmark")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "share-container" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "social-share",
                                                attrs: {
                                                  "aria-labelledby": "share-btn"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.popoverOn(
                                                      "share"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class":
                                                      "click-to-copy",
                                                    "class-name":
                                                      "g-icon--small g-clickable"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm.isShareOpen
                                              ? _c("SocialSharePopover", {
                                                  staticClass:
                                                    "social-share-popover",
                                                  attrs: {
                                                    assetId: _vm.courseId,
                                                    routeInfo: _vm.getRouteInfo(),
                                                    router: _vm.$router,
                                                    fromPage: "Universal card",
                                                    size: "lg"
                                                  },
                                                  on: {
                                                    "dismiss-share-action-model": function(
                                                      $event
                                                    ) {
                                                      return _vm.popoverOff(
                                                        "share"
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e(),
                                            !_vm.isShareOpen &&
                                            _vm.isDesktopPlatform
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "share-tooltip-text",
                                                    attrs: { id: "share-btn" }
                                                  },
                                                  [_vm._v("Share")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm.showAddToTrack
                                          ? _c(
                                              "div",
                                              { staticClass: "more-container" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "more-action",
                                                    attrs: {
                                                      "aria-labelledby":
                                                        "more-btn"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.popoverOn(
                                                          "more"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class":
                                                          "more-action",
                                                        "class-name":
                                                          "g-icon--small g-clickable"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm.isMoreOpen
                                                  ? _c("MoreActionPopover", {
                                                      staticClass:
                                                        "more-popover",
                                                      attrs: {
                                                        item: _vm.course,
                                                        assetId: _vm.courseId,
                                                        assetType:
                                                          _vm.course.format
                                                            .name,
                                                        routeInfo: _vm.getRouteInfo(),
                                                        router: _vm.$router,
                                                        showEditDelete: false,
                                                        showAddToTrack: true,
                                                        size: "lg",
                                                        fromPage:
                                                          "Universal card"
                                                      },
                                                      on: {
                                                        "add-to-track": function(
                                                          $event
                                                        ) {
                                                          return _vm.popoverOff(
                                                            "more"
                                                          )
                                                        },
                                                        "close-more": function(
                                                          $event
                                                        ) {
                                                          return _vm.popoverOff(
                                                            "more"
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                !_vm.isMoreOpen
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "more-tooltip-text",
                                                        attrs: {
                                                          id: "more-btn"
                                                        }
                                                      },
                                                      [_vm._v("More")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.course.progress
                                          ? _c("div", [
                                              _vm.course.progress > 0 &&
                                              _vm.course.progress < 100
                                                ? _c(
                                                    "div",
                                                    { staticClass: "progress" },
                                                    [
                                                      !_vm.course
                                                        .selfProgressDeclaration
                                                        ? _c(
                                                            "radial-progress-bar",
                                                            {
                                                              attrs: {
                                                                diameter: 28,
                                                                "completed-steps":
                                                                  _vm.course
                                                                    .progress,
                                                                "total-steps":
                                                                  _vm.course
                                                                    .progress ==
                                                                  "99"
                                                                    ? 110
                                                                    : 100,
                                                                startColor:
                                                                  "#000000",
                                                                stopColor:
                                                                  "#000000",
                                                                innerStrokeColor:
                                                                  "#e0e1e1",
                                                                strokeWidth: 4,
                                                                innerStrokeWidth: 5
                                                              }
                                                            }
                                                          )
                                                        : _c("svg-icon", {
                                                            attrs: {
                                                              "icon-class":
                                                                "progress"
                                                            }
                                                          }),
                                                      !_vm.course
                                                        .selfProgressDeclaration
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.course
                                                                  .progress
                                                              ) + "% "
                                                            ),
                                                            _vm.course.provider
                                                              .timeToProgressSyncInHours >
                                                            0
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      margin:
                                                                        "0 24px 0 16px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Progress updates delayed"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              "In progress"
                                                            )
                                                          ]),
                                                      _vm.course.provider
                                                        .timeToProgressSyncInHours >
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "progress-tooltip-text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Progress updates every " +
                                                                  _vm._s(
                                                                    _vm.course
                                                                      .provider
                                                                      .timeToProgressSyncInHours
                                                                  ) +
                                                                  " hours"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm.course.completed ||
                                                  _vm.course.progress == 100
                                                ? _c(
                                                    "div",
                                                    { staticClass: "progress" },
                                                    [
                                                      _c("svg-icon", {
                                                        staticStyle: {
                                                          "margin-right": "5px"
                                                        },
                                                        attrs: {
                                                          "icon-class":
                                                            "icon-progress-complete"
                                                        }
                                                      }),
                                                      _c("span", [
                                                        _vm._v("completed")
                                                      ]),
                                                      _vm.course.provider
                                                        .timeToProgressSyncInHours >
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "progress-tooltip-text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Progress updates every " +
                                                                  _vm._s(
                                                                    _vm.course
                                                                      .provider
                                                                      .timeToProgressSyncInHours
                                                                  ) +
                                                                  " hours"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ])
                                          : (_vm.course.showProgressDelayMsg ||
                                              _vm.course.isLaunched) &&
                                            _vm.course.provider
                                              .timeToProgressSyncInHours > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "progress" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "progress-update"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Progress updates delayed"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.course.selfProgressDeclaration &&
                                        _vm.course.isLaunched &&
                                        _vm.course.progress != 100
                                          ? _c(
                                              "ion-button",
                                              {
                                                class:
                                                  _vm.course.progress === 0 ||
                                                  _vm.course.provider
                                                    .timeToProgressSyncInHours >
                                                    0
                                                    ? "complete-btn"
                                                    : "complete-btn complete-btn-margin-left",
                                                attrs: {
                                                  shape: "round",
                                                  color: "primary"
                                                },
                                                on: {
                                                  click: _vm.onShowMarkComplete
                                                }
                                              },
                                              [_vm._v("Mark complete ")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "course-about-container" },
                        [
                          _c(
                            "ion-row",
                            {
                              staticClass:
                                "course-carousel-desktop ion-no-padding"
                            },
                            [
                              _c(
                                "ion-col",
                                {
                                  attrs: {
                                    "size-xl": "8.5",
                                    "size-lg": "12",
                                    "size-md": "12",
                                    "size-sm": "12",
                                    "size-xs": "12"
                                  }
                                },
                                [
                                  _c("CourseAbout", {
                                    attrs: {
                                      course: _vm.course,
                                      relatedCourses: _vm.course.relatedCourses,
                                      requiredLearningPlanItems:
                                        _vm.requiredLearningPlanItems
                                    }
                                  }),
                                  _c("ItemRating", {
                                    attrs: {
                                      item: _vm.course,
                                      scroll: _vm.scroll,
                                      ratingDetails: _vm.ratingDetails,
                                      getUpdatedItemData:
                                        _vm.getUpdatedCourseData
                                    },
                                    on: {
                                      "update-ratings": function($event) {
                                        return _vm.getRatingById()
                                      }
                                    }
                                  }),
                                  _vm.relatedContent.length > 0
                                    ? _c("RelatedContent", {
                                        attrs: {
                                          relatedContent: _vm.relatedContent
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { "size-xl": "3.5" } },
                                    [
                                      _c("ItemMetaData", {
                                        attrs: { item: _vm.course }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "ion-row",
                            { staticClass: "course-carousel-mobile" },
                            [
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c(
                                        "ItemMetaData",
                                        { attrs: { item: _vm.course } },
                                        [
                                          _c("CourseAbout", {
                                            attrs: {
                                              slot: "item-about",
                                              course: _vm.course,
                                              relatedCourses:
                                                _vm.course.relatedCourses,
                                              requiredLearningPlanItems:
                                                _vm.requiredLearningPlanItems
                                            },
                                            slot: "item-about"
                                          }),
                                          _c("ItemRating", {
                                            attrs: {
                                              slot: "item-rating",
                                              item: _vm.course,
                                              scroll: _vm.scroll,
                                              ratingDetails: _vm.ratingDetails,
                                              getUpdatedItemData:
                                                _vm.getUpdatedCourseData
                                            },
                                            on: {
                                              "update-ratings": function(
                                                $event
                                              ) {
                                                return _vm.getRatingById()
                                              }
                                            },
                                            slot: "item-rating"
                                          }),
                                          _vm.relatedContent.length > 0
                                            ? _c("RelatedContent", {
                                                attrs: {
                                                  slot: "related-content",
                                                  relatedContent:
                                                    _vm.relatedContent
                                                },
                                                slot: "related-content"
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c("CourseAbout", {
                                        attrs: {
                                          course: _vm.course,
                                          relatedCourses:
                                            _vm.course.relatedCourses,
                                          requiredLearningPlanItems:
                                            _vm.requiredLearningPlanItems
                                        }
                                      }),
                                      _c("ItemRating", {
                                        attrs: {
                                          item: _vm.course,
                                          ratingDetails: _vm.ratingDetails,
                                          getUpdatedItemData:
                                            _vm.getUpdatedCourseData,
                                          scroll: _vm.scroll
                                        },
                                        on: {
                                          "update-ratings": function($event) {
                                            return _vm.getRatingById()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding",
                      style: _vm.isDesktopPlatform
                        ? ""
                        : "padding: 2rem 10px 6rem;"
                    },
                    [_c("PageNotFound")],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }