<template>
  <div class="notifs-list">
    <svg-icon v-if="isMobilePlatform" icon-class="close" class="g-icon--small close g-clickable close-icon" @click="onCancel"></svg-icon>
    <div class="no-notifs" v-if="notifs && notifs.length === 0">
      <h3>You don't have any notifications</h3>
    </div>
    <div v-else>
      <div class="list-action">
        <a href="#" @click="readAll">Mark All as Read</a> |
        <a href="#" @click="dismissAll">Dismiss All</a>
      </div>
      <transition-group tag="ul" name="note">
        <li v-for="notif in notifs" :key="notif.id" :class="notif.isRead ? '' : 'unread'">
          <div @click="read(notif)">{{ notif.message }}</div>
          <ion-icon class="close" name="close-outline" @click="dismiss(notif.id)"></ion-icon>
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage'
import { mapState, mapMutations } from 'vuex'
import { modalController } from '@modus/ionic-vue'

export default {
  name: 'NotificationsList',

  data() {
    return {
      authToken: '',
      isMobilePlatform: false
    }
  },
  computed: {
    ...mapState('user', ['notifs'])
  },
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    if (this.$platform === 'Mobile') {
      this.isMobilePlatform = true
    }
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATIONS']),
    async dismissAll() {
      const allIds = this.notifs.map((notifs) => notifs.id)
      await this.$learnAdmin.updateAppNotificationsDismiss({ token: this.authToken, body: allIds })
      this.SET_NOTIFICATIONS([])
    },
    async dismiss(id) {
      this.$learnAdmin.updateAppNotificationsDismiss({ token: this.authToken, body: [id] }).then(() => {
        const currentNotifs = this.notifs.filter((notif) => notif.id !== id)
        this.SET_NOTIFICATIONS(currentNotifs)
      })
    },
    async readAll() {
      const allIds = this.notifs.map((notifs) => notifs.id)
      this.$learnAdmin.updateAppNotificationsRead({ token: this.authToken, body: allIds }).then(() => {
        const currentNotifs = this.notifs.map((notification) => ({ ...notification, isRead: true }))
        this.SET_NOTIFICATIONS(currentNotifs)
      })
    },
    async read(notif) {
      this.$learnAdmin.updateAppNotificationsRead({ token: this.authToken, body: [notif.id] }).then(() => {
        notif.isRead = true
      })
    },
    // function to dismiss the modal
    async onCancel() {
      modalController.dismiss()
    }
  }
}
</script>
<style lang="scss" scoped>
.notifs-list {
  min-height: 83vh;
}

.no-notifs {
  padding: 30px 0;
}

.list-action {
  text-align: right;
  padding: 10px 40px 10px 20px;
}

ul {
  padding: 0;
  margin: 0;
}
li {
  position: relative;
  list-style-type: none;
  min-height: 30px;
  border-top: 1px solid #ccc;
  text-align: left;
  padding: 5px 40px 5px 10px;
  > div {
    padding: 5px 0;
    cursor: pointer;
  }
  &.unread {
    background: #eaeaea;
  }
  .close {
    position: absolute;
    top: 35%;
    right: 15px;
    cursor: pointer;
  }
  &:last-child {
    border-bottom: 1px solid #ccc;
  }
}

.note-enter, .note-leave-to
/* .note-leave-active for <2.1.8 */ {
  opacity: 0;
}
.note-enter-to {
  opacity: 1;
  transition: all 0.5s;
}

.note-leave-active {
  opacity: 0;
  transition: all 0.5s;
}

.close-icon {
  margin: 10px;
  right: 0;
  position: absolute;
  z-index: 2;
}
</style>
