<template>
  <div :class="isDesktopPlatform ? 'mylearning-panel-desktop' : 'mylearning-panel-mobile'">
    <div :style="isDesktopPlatform ? '' : 'display: none;'" class="meta">
      <div class="filter"><ion-skeleton-text animated /></div>
      <div class="filter"><ion-skeleton-text animated /></div>
      <div class="filter"><ion-skeleton-text animated /></div>
    </div>
    <div :class="isDesktopPlatform ? 'container-desktop' : 'container-mobile'">
      <ion-grid>
        <div v-for="i in 5" :key="i" class="recommend-item">
          <ion-grid>
            <ion-row class="ion-no-padding">
              <ion-col style="max-width: 120px !important; min-width: 120px !important">
                <div class="recommend-img"><ion-skeleton-text animated /></div>
              </ion-col>
              <ion-col size-xs="7" class="content ion-padding-end">
                <div class="meta-content">
                  <div class="format"><ion-skeleton-text animated /></div>
                  <div class="provider"><ion-skeleton-text animated /></div>
                  <div>
                    <div class="title"><ion-skeleton-text animated /></div>
                    <div class="title"><ion-skeleton-text animated /></div>
                    <div :style="!isDesktopPlatform ? '' : 'display: none;'" class="title"><ion-skeleton-text animated /></div>
                  </div>
                  <div class="meta-icon">
                    <div style="display: flex; gap: 0.4rem">
                      <div class="recommend-bookmark"><ion-skeleton-text animated /></div>
                      <div class="recommend-bookmark"><ion-skeleton-text animated /></div>
                    </div>
                    <div :style="isDesktopPlatform ? 'display: flex; gap: 0.4rem' : 'display: none;'">
                      <div class="recommend-skill"><ion-skeleton-text animated /></div>
                      <div class="recommend-skill"><ion-skeleton-text animated /></div>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row :style="!isDesktopPlatform ? 'display:flex; margin-top: 40px;' : 'display: none;'">
              <ion-col style="max-width: 120px !important; min-width: 120px !important">
                <div class="recommend-btn"><ion-skeleton-text animated /></div>
              </ion-col>
              <ion-col>
                <div class="recommend-title"><ion-skeleton-text animated /></div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-grid>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyLearningSkeleton',
  components: {},
  props: {},
  data() {
    return {
      isDesktopPlatform: true
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.meta {
  display: flex;
  gap: 15px;
  padding: 4rem 70px 1rem;
}

.filter {
  width: 148px;
  height: 36px;
  background: #00000080 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 0.15;
}

.recommend-item {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
  text-align: left;
  border-radius: 20px;
}

.recommend-img {
  position: relative;
  top: 15%;
  width: 90px;
  height: 80px;
  border: 4px solid var(--ion-color-white);
  border-radius: 80%;
  img {
    width: 80px;
    height: 50px;
    object-fit: cover;
  }
}

.container-desktop {
  margin-top: 32px;
  // margin-top: 1rem; // DO NOT DELTE: THIS IS FOR SINGLE TRACK
}

.container-mobile {
  margin-top: 16px;
}

.meta-content {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 16px;
  .format {
    width: 101px;
    height: 24px;
    border-radius: 12px;
    opacity: 1;
  }

  .provider {
    width: 120%;
    height: 30px;
    opacity: 1;
  }

  .title {
    width: 150%;
    height: 16px;
    opacity: 1;
  }
}

.meta-icon {
  display: flex;
  margin-top: 0.3rem;
  width: 150%;
  justify-content: space-between;

  .recommend-bookmark {
    width: 35px;
    height: 35px;
    background: var(--ion-color-grey);
    border-radius: 50%;
  }
  .recommend-skill {
    width: 100px;
    height: 30px;
    border-radius: 15px;
  }
}

.recommend-btn {
  width: 100px;
  height: 40px;
  border-radius: 20px;
}

@media screen and (max-width: 490px) {
  .meta-content {
    .format {
      width: 70px;
      height: 20px;
    }
    .provider {
      width: 100%;
      height: 20px;
    }
    .title {
      width: 120%;
      height: 14px;
    }
  }

  .recommend-title {
    width: 105%;
    height: 14px;
    padding-top: 10px;
  }
  .recommend-item {
    padding: 0 1rem 0 0.5rem;
  }
}
</style>

