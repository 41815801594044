<template>
  <div>
    <div class="all-tracks" :class="trackMode">
      <div v-show="showTrackBar">
        <TrackList v-if="trackMode == 'track-list'" :trackList="getTracksList" :addItem="addItem" @show-create-track="setTrackMode"> </TrackList>
        <NoTrack v-if="trackMode == 'no-tracks'" @show-create-track="setTrackMode" :key="trackMode"></NoTrack>
        <CreateEditTrack v-if="trackMode == 'add-track'" @show-create-track="setTrackMode" :isEditTrack="isEditTrack" :addItem="addItem"></CreateEditTrack>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import NoTrack from '@/views/search/components/NoTrack'
import CreateEditTrack from '@/views/search/components/CreateEditTrack'
import TrackList from '@/views/search/components/TrackList'
export default {
  name: 'TrackSideBar',
  props: {
    addItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { NoTrack, CreateEditTrack, TrackList },
  data() {
    return {
      trackMode: 'no-tracks',
      authToken: '',
      getTracksList: [],
      showTrackBar: false,
      userGuid: '',
      isEditTrack: false
    }
  },
  mounted() {
    if (this.addItem.format.name == 'TRACK') {
      this.setTrackMode('add-track')
      this.isEditTrack = true
      this.showTrackBar = true
    } else {
      this.getUserCreatedTracksList()
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'user_guid'])
  },
  methods: {
    setTrackMode(mode) {
      this.trackMode = mode
    },
    async getUserCreatedTracksList() {
      this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
      this.userGuid = this.user_guid || (await localforage.getItem('my_user_guid'))
      const payload = {
        token: this.authToken,
        authorGuid: this.userGuid
      }
      const response = await this.$learnAdmin.getAllTracks(payload)
      this.showTrackBar = true
      this.getTracksList = response?.data || []
      if (this.getTracksList.length > 0 && this.addItem.format.name != 'TRACK') {
        this.trackMode = 'track-list'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.all-tracks {
  width: 640px;
  position: fixed;
  z-index: 100000;
  top: 0px;
  right: 0px;
  height: 100vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  //border: 1px solid #707070;
  border-radius: 20px 0px 0px 20px;
  opacity: 1;
  text-transform: none !important;
}

.no-tracks {
  padding-left: 61px;
}

// .track-list {
//   padding-left: 88px;
// }

// .add-track {
//   padding-left: 61px;
// }

@media screen and (max-width: 480px) {
  .all-tracks {
    width: 100%;
    //padding-left: 21px;
    padding-top: 30px;
  }
}
</style>>