var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ion-content",
    { staticClass: "edge-landing" },
    [
      _c(
        "nav",
        { staticClass: "d-flex", attrs: { id: "nav" } },
        [
          _c(
            "div",
            { staticClass: "logo d-flex" },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "logo", "class-name": "logo--size" },
                on: { click: _vm.goToLogin }
              })
            ],
            1
          ),
          _vm.$router.currentRoute.path == "/" ||
          _vm.$router.currentRoute.path == "/landing"
            ? _c(
                "ion-row",
                { staticClass: "nav-right-items" },
                [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "nav,Register,click",
                          expression: "'nav,Register,click'"
                        }
                      ],
                      staticClass: "sign-up",
                      staticStyle: { "text-decoration": "unset" },
                      attrs: {
                        href: "#",
                        "data-cy": "learn-desktop-signup",
                        color: "primary"
                      },
                      on: { click: _vm.openRegister }
                    },
                    [_vm._v("Register")]
                  ),
                  _c(
                    "ion-button",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "nav,Login,click",
                          expression: "'nav,Login,click'"
                        }
                      ],
                      staticClass: "ion-button-tertiary button-text",
                      attrs: {
                        "data-cy": "learn-desktop-login",
                        shape: "round",
                        color: "tertiary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.openLoginModal()
                        }
                      }
                    },
                    [_vm._v("Login")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "landing" }, [
        _vm.message !== ""
          ? _c("div", { staticClass: "error-msg" }, [
              _vm._v(" " + _vm._s(_vm.message) + " ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "landing-body" }, [
          _c("section", { staticClass: "landing-section" }, [
            _c("div", { staticClass: "landing-section-img" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/" + _vm.slides[0].image),
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "landing-section-text" }, [
              _c("h1", [_vm._v(_vm._s(_vm.slides[0].title))]),
              _c("p", [_vm._v(_vm._s(_vm.slides[0].subtitle))])
            ])
          ]),
          _c("section", { staticClass: "landing-section" }, [
            _c("div", { staticClass: "landing-section-text" }, [
              _c("h2", [_vm._v(_vm._s(_vm.slides[1].title))]),
              _c("p", [_vm._v(_vm._s(_vm.slides[1].subtitle))])
            ]),
            _c("div", { staticClass: "landing-section-img" }, [
              _c("img", {
                staticClass: "section-panel-image",
                attrs: {
                  src: require("@/assets/images/" + _vm.slides[1].image),
                  alt: ""
                }
              })
            ])
          ]),
          _c("section", { staticClass: "landing-section" }, [
            _c("div", { staticClass: "landing-section-img" }, [
              _c("img", {
                staticClass: "section-panel-image",
                attrs: {
                  src: require("@/assets/images/" + _vm.slides[2].image),
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "landing-section-text" }, [
              _c("h2", [_vm._v(_vm._s(_vm.slides[2].title))]),
              _c("p", [_vm._v(_vm._s(_vm.slides[2].subtitle))])
            ])
          ])
        ])
      ]),
      _c(
        "ion-footer",
        {
          style: _vm.showModals ? "z-index: 899" : "",
          attrs: { id: "footer" }
        },
        [
          _c(
            "ion-row",
            [
              _c("ion-col", { staticClass: "d-flex", attrs: { size: "7" } }, [
                _vm._v(
                  "© " +
                    _vm._s(_vm.currentYear) +
                    " PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates. "
                )
              ]),
              _c(
                "ion-col",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { size: "1" },
                  on: {
                    click: function($event) {
                      _vm.$router
                        .push("/help-center-main")
                        .catch(function() {}),
                        _vm.$emit("help-center", "help-center")
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        color: "unset",
                        "text-decoration": "unset",
                        "background-color": "unset"
                      },
                      attrs: { href: "#" }
                    },
                    [_vm._v("Help Center")]
                  )
                ]
              ),
              _c(
                "ion-col",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { size: "1" },
                  on: {
                    click: function($event) {
                      return _vm.openPrivacy(true)
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        color: "unset",
                        "text-decoration": "unset",
                        "background-color": "unset"
                      },
                      attrs: { href: "#" }
                    },
                    [_vm._v("Privacy Policy")]
                  )
                ]
              ),
              _c(
                "ion-col",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { size: "1" },
                  on: {
                    click: function($event) {
                      return _vm.openCookieNotice()
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        color: "unset",
                        "text-decoration": "unset",
                        "background-color": "unset"
                      },
                      attrs: { href: "#" }
                    },
                    [_vm._v("Cookie Notice")]
                  )
                ]
              ),
              _c(
                "ion-col",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { size: "2" },
                  on: {
                    click: function($event) {
                      return _vm.openTerms()
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        color: "unset",
                        "text-decoration": "unset",
                        "background-color": "unset"
                      },
                      attrs: { href: "#" }
                    },
                    [_vm._v("Terms and Conditions")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showModals,
            expression: "showModals"
          }
        ],
        staticClass: "overlay"
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showModals,
              expression: "showModals"
            }
          ],
          key: _vm.computedCurrentModal,
          ref: "modal",
          staticClass: "modal",
          attrs: {
            role: "dialog",
            "aria-label": _vm.computedCurrentModal,
            tabindex: "0"
          }
        },
        [
          _c(
            "a",
            {
              staticStyle: { color: "unset", "text-decoration": "unset" },
              attrs: { href: "#", "aria-label": "close button" },
              on: { click: _vm.onClose }
            },
            [
              _vm.computedCurrentModal !== "Welcome"
                ? _c("svg-icon", {
                    staticClass: "g-icon--small close g-clickable",
                    attrs: { "icon-class": "close" }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(_vm.computedCurrentModal, {
            tag: "component",
            attrs: { email: _vm.email },
            on: { next: _vm.nextModal, decline: _vm.onClose }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }