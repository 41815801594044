var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.$platform == "Desktop"
          ? "bookmark-credential-container-desktop"
          : ""
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "left", "margin-left": "20px" } },
        [
          _c("svg-icon", {
            attrs: {
              "icon-class": "back_arrow",
              "class-name": "g-icon--small back-icon"
            },
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            }
          })
        ],
        1
      ),
      _c("CredentialContent", {
        attrs: { content: _vm.bookmark, "from-bookmark-list": "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }