var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isDesktopPlatform
        ? "mylearning-panel-desktop"
        : "mylearning-panel-mobile"
    },
    [
      _c(
        "div",
        {
          staticClass: "meta",
          style: _vm.isDesktopPlatform ? "" : "display: none;"
        },
        [
          _c(
            "div",
            { staticClass: "filter" },
            [_c("ion-skeleton-text", { attrs: { animated: "" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "filter" },
            [_c("ion-skeleton-text", { attrs: { animated: "" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "filter" },
            [_c("ion-skeleton-text", { attrs: { animated: "" } })],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          class: _vm.isDesktopPlatform
            ? "container-desktop"
            : "container-mobile"
        },
        [
          _c(
            "ion-grid",
            _vm._l(5, function(i) {
              return _c(
                "div",
                { key: i, staticClass: "recommend-item" },
                [
                  _c(
                    "ion-grid",
                    [
                      _c(
                        "ion-row",
                        { staticClass: "ion-no-padding" },
                        [
                          _c(
                            "ion-col",
                            {
                              staticStyle: {
                                "max-width": "120px !important",
                                "min-width": "120px !important"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "recommend-img" },
                                [
                                  _c("ion-skeleton-text", {
                                    attrs: { animated: "" }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "ion-col",
                            {
                              staticClass: "content ion-padding-end",
                              attrs: { "size-xs": "7" }
                            },
                            [
                              _c("div", { staticClass: "meta-content" }, [
                                _c(
                                  "div",
                                  { staticClass: "format" },
                                  [
                                    _c("ion-skeleton-text", {
                                      attrs: { animated: "" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "provider" },
                                  [
                                    _c("ion-skeleton-text", {
                                      attrs: { animated: "" }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "title" },
                                    [
                                      _c("ion-skeleton-text", {
                                        attrs: { animated: "" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "title" },
                                    [
                                      _c("ion-skeleton-text", {
                                        attrs: { animated: "" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      style: !_vm.isDesktopPlatform
                                        ? ""
                                        : "display: none;"
                                    },
                                    [
                                      _c("ion-skeleton-text", {
                                        attrs: { animated: "" }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _c("div", { staticClass: "meta-icon" }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        gap: "0.4rem"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "recommend-bookmark" },
                                        [
                                          _c("ion-skeleton-text", {
                                            attrs: { animated: "" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "recommend-bookmark" },
                                        [
                                          _c("ion-skeleton-text", {
                                            attrs: { animated: "" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      style: _vm.isDesktopPlatform
                                        ? "display: flex; gap: 0.4rem"
                                        : "display: none;"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "recommend-skill" },
                                        [
                                          _c("ion-skeleton-text", {
                                            attrs: { animated: "" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "recommend-skill" },
                                        [
                                          _c("ion-skeleton-text", {
                                            attrs: { animated: "" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "ion-row",
                        {
                          style: !_vm.isDesktopPlatform
                            ? "display:flex; margin-top: 40px;"
                            : "display: none;"
                        },
                        [
                          _c(
                            "ion-col",
                            {
                              staticStyle: {
                                "max-width": "120px !important",
                                "min-width": "120px !important"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "recommend-btn" },
                                [
                                  _c("ion-skeleton-text", {
                                    attrs: { animated: "" }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c("ion-col", [
                            _c(
                              "div",
                              { staticClass: "recommend-title" },
                              [
                                _c("ion-skeleton-text", {
                                  attrs: { animated: "" }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }