var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "top",
      class: _vm.isDesktopPlatform
        ? "desktop-background track-content"
        : "non-white-background"
    },
    [
      !_vm.dataReady ? _c("div", [_c("TrackSkeleton")], 1) : _vm._e(),
      _vm.dataReady
        ? _c(
            "div",
            [
              _vm.isContentAvailable
                ? _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding ion-padding-bottom",
                      class: _vm.isDesktopPlatform
                        ? "desktop-collection-container"
                        : "mobile-collection-container"
                    },
                    [
                      _c(
                        "ion-row",
                        [
                          _c(
                            "ion-col",
                            {
                              attrs: {
                                "size-xl": "8.5",
                                "size-lg": "12",
                                "size-md": "12",
                                "size-sm": "12",
                                "size-xs": "12"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "back",
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.go(-1)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "accessible-button",
                                      attrs: { "aria-label": "back button" }
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "g-icon--small",
                                        attrs: {
                                          "icon-class": "icon-arrow-left"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" Back ")
                                ]
                              ),
                              _c(
                                "ion-row",
                                { staticClass: "collection-info" },
                                [
                                  _c(
                                    "ion-col",
                                    { staticClass: "collection-img-shape" },
                                    [
                                      _vm.collection.image.signedRelativeUri
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.collection.image
                                                  .signedRelativeUri
                                            }
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/user-generated-track.png")
                                            }
                                          })
                                    ]
                                  ),
                                  _c("ion-col", [
                                    _c("div", { staticClass: "meta" }, [
                                      _c("div", { staticClass: "format" }, [
                                        _vm._v(
                                          _vm._s(_vm.collection.format.name)
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(_vm.collection.title))
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "meta-info" },
                                      [
                                        _vm.collection.duration
                                          ? _c(
                                              "div",
                                              { staticClass: "duration" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.collection.duration
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "content-count" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "track-list"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-count-title"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.collection.content &&
                                                      _vm.collection.content
                                                        .length
                                                  ) + " items"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "ion-row",
                                          {
                                            staticClass:
                                              "ion-align-items-center ion-no-padding track-content-just-you"
                                          },
                                          [
                                            _c(
                                              "ion-col",
                                              { attrs: { size: "4" } },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "icon-eye"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "ion-col",
                                              { staticClass: "content-text" },
                                              [_vm._v(" JUST YOU ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "meta-action" }, [
                                      _c(
                                        "div",
                                        { staticClass: "more-container" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "more-action",
                                              on: {
                                                click: function($event) {
                                                  return _vm.popoverOn("more")
                                                }
                                              }
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "more-action",
                                                  "class-name":
                                                    "g-icon--small g-clickable"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.isMoreOpen
                                            ? _c("MoreActionPopover", {
                                                staticClass: "more-popover",
                                                attrs: {
                                                  item: _vm.collection,
                                                  assetId: _vm.collectionId,
                                                  assetType:
                                                    _vm.collection.format.name,
                                                  routeInfo: _vm.getRouteInfo(),
                                                  router: _vm.$router,
                                                  editTrack: true,
                                                  deleteTrack: true,
                                                  size: "lg",
                                                  fromPage: "Universal card"
                                                },
                                                on: {
                                                  "edit-delete-track-content":
                                                    _vm.editDeleteTrackContent,
                                                  "add-to-track": function(
                                                    $event
                                                  ) {
                                                    return _vm.popoverOff(
                                                      "more"
                                                    )
                                                  },
                                                  "close-more": function(
                                                    $event
                                                  ) {
                                                    return _vm.popoverOff(
                                                      "more"
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          !_vm.isMoreOpen
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "more-tooltip-text"
                                                },
                                                [_vm._v("More")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm.completedItems.length > 0 &&
                                      _vm.completedItems.length !=
                                        _vm.totalItems.length
                                        ? _c(
                                            "div",
                                            { staticClass: "flex-container" },
                                            [
                                              _c("radial-progress-bar", {
                                                attrs: {
                                                  diameter: 28,
                                                  "completed-steps":
                                                    _vm.completedItems.length,
                                                  "total-steps":
                                                    _vm.totalItems.length,
                                                  startColor: "#000000",
                                                  stopColor: "#000000",
                                                  innerStrokeColor: "#e0e1e1",
                                                  strokeWidth: 4,
                                                  innerStrokeWidth: 5
                                                }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex-percentage"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.completedItems
                                                        .length +
                                                        " of " +
                                                        _vm.totalItems.length +
                                                        " items completed"
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm.completedItems.length > 0 &&
                                          _vm.completedItems.length ==
                                            _vm.totalItems.length
                                        ? _c(
                                            "div",
                                            { staticClass: "progress-track" },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  "margin-right": "5px"
                                                },
                                                attrs: {
                                                  "icon-class":
                                                    "icon-progress-complete"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    font:
                                                      "normal normal normal 14px/16px Arial",
                                                    color: "#2D2D2D"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.completedItems
                                                        .length +
                                                        " of " +
                                                        _vm.totalItems.length +
                                                        " items completed"
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "desktop-progress" },
                                        [
                                          _vm.collection.progress > 0 &&
                                          _vm.collection.progress < 100
                                            ? _c(
                                                "div",
                                                { staticClass: "progress" },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "progress-icon"
                                                    }
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.collection.progress
                                                      ) + "% completed "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-family":
                                                            "Arial",
                                                          "font-weight": "100"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm.updateTime
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.collection.completed ||
                                          _vm.collection.progress == 100
                                            ? _c(
                                                "div",
                                                { staticClass: "progress" },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "icon-progress-complete"
                                                    }
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      "completed " +
                                                        _vm._s(_vm.onDate)
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "collection-about-container" },
                        [
                          _c(
                            "ion-row",
                            { staticClass: "carouselDesktop" },
                            [
                              _c(
                                "ion-col",
                                {
                                  staticStyle: { "margin-right": "2rem" },
                                  attrs: { size: "8.5" }
                                },
                                [
                                  _c("UGCollectionAbout", {
                                    attrs: { collection: _vm.collection },
                                    on: {
                                      "on-delete-external-item":
                                        _vm.onDeleteExternalItem
                                    }
                                  }),
                                  _c("UGTrackFooter")
                                ],
                                1
                              ),
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { size: "3" } },
                                    [
                                      _vm.collection.skills &&
                                      _vm.collection.skills.length > 0
                                        ? _c(
                                            "ion-row",
                                            { staticClass: "skills-row" },
                                            [
                                              _c(
                                                "ion-col",
                                                {
                                                  staticStyle: {
                                                    "padding-top": "8px"
                                                  },
                                                  attrs: { size: "4" }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "skills-title"
                                                    },
                                                    [_vm._v("Skills")]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "ion-col",
                                                { staticClass: "ion-text-end" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "meta-skills"
                                                    },
                                                    _vm._l(
                                                      _vm.collection.skills,
                                                      function(skill) {
                                                        return _c(
                                                          "div",
                                                          { key: skill.guid },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "skill-pill ellipsis-lines-1",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.onSkillClick(
                                                                      skill.guid
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    skill.guid
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "ion-row",
                            { staticClass: "carouselMobile" },
                            [
                              _vm.isMetaDataVisible
                                ? _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c("UGCollectionAbout", {
                                        attrs: { collection: _vm.collection },
                                        on: {
                                          "on-delete-external-item":
                                            _vm.onDeleteExternalItem
                                        }
                                      }),
                                      _vm.collection.skills &&
                                      _vm.collection.skills.length > 0
                                        ? _c(
                                            "ion-row",
                                            {
                                              staticClass: "skills-row",
                                              staticStyle: {
                                                "margin-top": "32px"
                                              }
                                            },
                                            [
                                              _c(
                                                "ion-col",
                                                {
                                                  staticClass: "ion-text-start",
                                                  attrs: { size: "4" }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "skills-title"
                                                    },
                                                    [_vm._v("Skills")]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "ion-col",
                                                { staticClass: "ion-text-end" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "meta-skills"
                                                    },
                                                    _vm._l(
                                                      _vm.collection.skills,
                                                      function(skill) {
                                                        return _c(
                                                          "div",
                                                          { key: skill.guid },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "skill-pill ellipsis-lines-1",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.onSkillClick(
                                                                      skill.guid
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    skill.guid
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("UGTrackFooter")
                                    ],
                                    1
                                  )
                                : _c(
                                    "ion-col",
                                    { attrs: { size: "12" } },
                                    [
                                      _c("UGCollectionAbout", {
                                        attrs: { collection: _vm.collection },
                                        on: {
                                          "on-delete-external-item":
                                            _vm.onDeleteExternalItem
                                        }
                                      }),
                                      _c("UGTrackFooter")
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "ion-grid",
                    {
                      staticClass: "ion-no-padding",
                      style: _vm.isDesktopPlatform
                        ? ""
                        : "padding: 2rem 10px 6rem;"
                    },
                    [_c("PageNotFound")],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }