<template>
  <div>
    <div class="tooltip-bottom" @click.stop="onClick" :style="{ cursor: textCopied ? 'not-allowed' : 'pointer' }">
      <div class="tooltip-bottom-copy">
        <svg-icon icon-class="chain-link" class="chain-link"></svg-icon>
        <button :class="textCopied ? 'tooltip-bottom-text' : 'tooltip-bottom-text tooltip-bottom-text-width'">{{ textCopied ? 'Link copied' : 'Copy link' }}</button>
      </div>
      <svg-icon icon-class="green-checkmark" class="green-checkmark" :style="{ display: textCopied ? 'inline-flex' : 'none' }"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialSharePopover',
  props: {
    routeInfo: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    router: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      textCopied: false
    }
  },
  mounted() {
    document.addEventListener('click', this.close, true)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close, true)
  },
  methods: {
    async onClick() {
      const route = this.router.resolve({ name: this.routeInfo.name, params: this.routeInfo.params })
      const location = window.location.origin + route.href
      if (!this.textCopied) {
        navigator.clipboard.writeText(location)
        this.textCopied = true
        setTimeout(() => (this.textCopied = false), 5000)
      }
      this.$eventBus.$emit(`dismiss-share-action-model`)
    },
    close(e) {
      if (!e.target._prevClass.includes('social-share-popover') && !e.target._prevClass.includes('tooltip-bottom')) {
        this.$emit('dismiss-share-action-model')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chain-link {
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
}

.green-checkmark {
  width: 20px;
  height: 20px;
  margin: 0px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.tooltip-bottom-copy {
  display: flex;
  align-items: center;
}

.tooltip-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tooltip-bottom-text {
    background: none;
    font-family: 'Arial';
    color: #2d2d2d;
    letter-spacing: 0px;
    text-align: left;
  }
  .tooltip-bottom-text-width {
    min-width: 200px;
  }
  &:hover {
    background: var(--ion-color-blue-light) !important;
  }
}
</style>
