<template>
  <div>
    <h1>Download</h1>
    <svg-icon icon-class="download-box" class="g-icon--medium" @click="onDownload" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'

export default {
  name: 'AssetDownload',
  components: {},
  props: {},
  data() {
    return {
      id: '627a072601fdce7873f53842'
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async onDownload() {
      const token = (await localforage.getItem('my_access_token')) || this.auth_token
      const blob = this.$learnAdmin.downloadAssetItem({ token: token, id: this.id })
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.setAttribute('download', 'file.pdf')
      a.click()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
