<template>
  <div class="all-filters">
    <ion-grid class="ion-no-padding" v-if="dataReady">
      <ion-row class="all-filters__heading-row" :class="$platform === 'Desktop' ? '' : 'header-mobile'">
        <ion-col>
          <h2 class="all-filters__heading">All Filters</h2>
          <button @click="toggleFilterBar" class="all-filters__filter-close" aria-label="close side menu">
            <svg-icon icon-class="close-icon" class-name="all-filters__filter-close-svg" />
          </button>
        </ion-col>
      </ion-row>
      <div class="all-filters__filter-container" :class="isFilterDetailOpen && $platform != 'Desktop' ? 'ios-filter-container' : ''">
        <ion-row v-for="(filter, index) in filters" :key="filter.name + index" class="all-filters__filter-row" @click="openFilterDetail(filter.name)">
          <ion-col class="all-filters__filter-col">
            <p style="display: inline-block" class="all-filters__filter-row-title">{{ filter.name == 'Tool' ? 'Technology' : filter.name }}</p>
            <div class="all-filters__filter-row-selected-options">
              <div style="display: inline-block" v-if="filter.selectedOptionsArray && filter.selectedOptionsArray.length > 0" :key="filter.name + index">
                <p class="all-filters__filter-row-selected-options-name" :key="filter.selectedOptionsArray.join(',')">{{ filter.selectedOptionsArray.join(', ') }}</p>
              </div>
            </div>
          </ion-col>
          <ion-col size="1">
            <svg-icon icon-class="icon-arrow-right-dark" class="all-filters__filter-row-icon" />
          </ion-col>
          <div v-if="isFilterDetailOpen && currentOpenFilter == filter.name" :key="filter.name + index" class="all-filters__filter-detail">
            <ion-row class="all-filters__heading-row" :class="$platform === 'Desktop' ? '' : 'header-mobile'">
              <ion-col>
                <button @click.stop="closeFilterDetail(filter)" class="all-filters__filter-close" style="float: left" aria-label="close side menu">
                  <svg-icon icon-class="icon-arrow-left-dark" class-name="all-filters__filter-close-svg" />
                </button>
                <h2 class="all-filters__heading">{{ filter.name == 'Tool' ? 'Technology' : filter.name }}</h2>
                <button @click.stop="closeFilterDetail(filter)" class="all-filters__filter-close" aria-label="close side menu">
                  <svg-icon icon-class="close-icon" class-name="all-filters__filter-close-svg" />
                </button>
              </ion-col>
            </ion-row>
            <ion-row class="ion-text-center ion-padding-bottom">
              <ion-col>
                <ion-searchbar
                  v-if="filter.filterOptions.length > 12"
                  class="searchbar-filter"
                  search-icon="search-outline"
                  ref="searchbar_filter"
                  style="text-align: left; --border-radius: 50px"
                  :value="searchFilter"
                  :placeholder="filter.name == 'Tool' ? 'Search Technology' : 'Search ' + filter.name"
                  @ionInput="handleFilterSearchInput($event, filter)"
                  @ionClear="handleFilterClearInput(filter)"
                ></ion-searchbar>
              </ion-col>
            </ion-row>
            <div class="all-filters__filter-options-scroller" :class="$platform === 'Desktop' ? '' : 'options-scroller-mobile'">
              <ion-row
                v-for="(filterOption, index) in filter.filterOptions"
                :key="filterOptKey + index"
                :style="filterOption.style ? filterOption.style : 'display: flex'"
                class="all-filters__filter-detail-row"
              >
                <ion-col size="1">
                  <ion-checkbox
                    :key="filterOption.name + filterOption.selected"
                    @ionChange="onFilterOptionChange($event, filter.name)"
                    :checked="filterOption.selected"
                    :value="filter.name === 'Duration' ? filterOption.id : filterOption.name"
                    class="all-filters__checkbox"
                    color="tertiary"
                    mode="md"
                    :disabled="filterOption.count == 0"
                  ></ion-checkbox>
                </ion-col>
                <ion-col class="all-filters__filter-detail-name" :class="filterOption.count == 0 ? 'filter-detail-name-disabled' : ''"
                  >{{ filterName(filterOption, filter.name) }} ({{ filterOption.count }})</ion-col
                >
              </ion-row>
            </div>
            <ion-row class="all-filters__filter-footer" :class="$platform === 'Desktop' ? '' : 'footer-mobile'">
              <ion-col class="ion-text-center">
                <ion-button fill="clear" class="all-filters__clear-button" @click.stop="clearAllFiltersDetail(filter)">Clear filters</ion-button>
                <ion-button shape="round" color="primary" class="ion-button-primary all-filters__done-button" @click.stop="applyFilter(filter)"
                  >Apply {{ filter.name == 'Tool' ? 'technology' : filter.name.toLowerCase() }}</ion-button
                >
              </ion-col>
            </ion-row>
          </div>
        </ion-row>
      </div>
      <ion-row class="all-filters__filter-footer" v-if="!isFilterDetailOpen" :class="$platform === 'Desktop' ? '' : 'footer-mobile'">
        <ion-col class="ion-text-center">
          <ion-button fill="clear" class="all-filters__clear-button" @click="onClickClearAll">Clear all filters</ion-button>
          <ion-button v-if="resultsCount > 0" shape="round" color="primary" class="ion-button-primary all-filters__done-button" @click="onClickDone"
            >View {{ resultsCount | formatCount }} results</ion-button
          >
          <ion-button v-else shape="round" color="primary" class="ion-button-primary all-filters__done-button" @click="onClickDone">Done</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { popoverController } from '@modus/ionic-vue'
import localforage from 'localforage'

export default {
  name: 'SearchAllFiltersSideBar',
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    resultsCount: {
      type: Number,
      require: false,
      default: () => {
        return 0
      }
    }
  },
  components: {},
  data() {
    return {
      authToken: '',
      isFilterDetailOpen: false,
      currentOpenFilter: '',
      searchFilter: '',
      dataReady: false,
      selectedFiltersArray: [],
      totalResults: 0,
      filterOptKey: new Date()
    }
  },
  computed: {},
  watch: {},
  created() {},
  filters: {
    formatCount(count) {
      return count.toLocaleString()
    }
  },
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.searchFilter = ''
    await this.filters.map(async (filter) => {
      await filter.filterOptions.map((filterOption) => {
        filterOption.style = 'display: flex'
      })
    })
    this.filters.forEach((filter) => {
      filter.selectedOptionsArray = []
      filter.filterOptions.forEach((filterOption) => {
        if (filterOption.selected && filter.name === 'Duration' && filterOption.count != 0) {
          filter.selectedOptionsArray.push(filterOption.id)
        } else if (filterOption.selected && filter.name !== 'Duration' && filterOption.count != 0) {
          filter.selectedOptionsArray.push(filterOption.name)
        }
      })
    })
    this.dataReady = true
  },
  methods: {
    onFilterOptionChange(event, name) {
      name === 'Duration'
        ? (this.filters.find((filter) => filter.name == name).filterOptions.find((filterOption) => filterOption.id == event.detail.value).selected = event.detail.checked)
        : (this.filters.find((filter) => filter.name == name).filterOptions.find((filterOption) => filterOption.name == event.detail.value).selected = event.detail.checked)
    },
    toggleFilterBar() {
      popoverController.dismiss()
    },
    onClickDone() {
      this.$parent.$emit('coming-from-side-filter', false)
    },
    onClickClearAll() {
      this.$parent.$emit('clear-all-filters')
      popoverController.dismiss()
    },
    openFilterDetail(filter) {
      this.currentOpenFilter = filter
      this.isFilterDetailOpen = true
    },
    closeFilterDetail(filter) {
      if (filter.selectedOptionsArray.length > 0) {
        filter.filterOptions.forEach((option) => {
          option.selected = filter.name === 'Duration' ? filter.selectedOptionsArray.includes(option.id) : filter.selectedOptionsArray.includes(option.name)
        })
      } else {
        filter.filterOptions.forEach((option) => {
          option.selected = false
        })
      }
      this.isFilterDetailOpen = false
      this.currentOpenFilter = ''
    },
    clearAllFiltersDetail(filterData) {
      this.currentOpenFilter = ''
      this.filterOptKey = new Date()
      this.filters.find((filter) => filter.name == filterData.name).selectedOptionsArray = []
      this.filters
        .find((filter) => filter.name == filterData.name)
        .filterOptions.forEach((option) => {
          option.selected = false
        })
      this.currentOpenFilter = filterData.name
    },
    applyFilter(filter) {
      this.isFilterDetailOpen = false
      this.currentOpenFilter = ''
      this.$parent.$emit('selected-filters-output', filter)
      this.$parent.$emit('coming-from-side-filter', true)
    },
    filterName(filterOption, filterType) {
      const capitalize = (s) => {
        return s.substr(0, 1).toUpperCase() + s.substr(1)
      }
      return filterType === 'Duration' || filterOption.type === 'Duration' ? filterOption.id : capitalize(filterOption.name)
    },
    async handleFilterClearInput(filterEvent) {
      this.searchFilter = ''
      await this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name) {
          await filter.filterOptions.map((filterOption) => {
            filterOption.style = 'display: flex'
          })
        }
      })
    },
    async handleFilterSearchInput(event, filterEvent) {
      this.searchFilter = event.target.value.trim()

      await this.filters.map(async (filter) => {
        if (filterEvent.name === filter.name) {
          await filter.filterOptions.map((filterOption) => {
            const find = filterOption.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1
            filterOption.style = find ? 'display: flex' : 'display: none'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.filter-container-class {
  .popover-content {
    position: relative;
    width: 364px;
    top: 0 !important;
    height: 100vh;
    border-radius: unset !important;
    @media (max-width: 500px) {
      width: 100vw;
      left: 0 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@include block('all-filters') {
  position: relative;
  z-index: 1;
  height: 100vh;
  background-color: var(--ion-color-light);
  padding: 0 0; 
  text-align: left;
  @include element('filter-container') {
    overflow: auto;
    height: 64vh;
  }
  @include element('heading-row') {
    margin-bottom: 48px;
    margin-right: 24px;
    margin-left: 24px;
  }
  @include element('heading') {
    font-family: 'Futura PT Demi';
    font-size: 20px;
    color: var(--ion-color-black);
    position: absolute;
    width: 80%;
    text-align: center;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @include element('filter-close') {
    color: unset;
    text-decoration: unset;
    background-color: unset;
    margin-top: 18px;
    float: right;
  }
  @include element('filter-close-svg') {
    height: 18px !important;
    width: 18px !important;
  }
  @include element('filter-col') {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 8px;
    cursor: pointer;
  }
  @include element('filter-row') {
    margin: 0px 24px;
    border-bottom: 1px solid var(--ion-color-super-light-gray);
  }
  @include element('filter-row-selected-options') {
    display: inline-block;
    padding: 0px 12px 0px 12px;
  }
  @include element('filter-row-selected-options-name') {
    display: inline-block;
    padding-left: 4px;
    font-family: 'Arial';
    font-size: 12px;
    text-transform: capitalize;
  }
  @include element('filter-row-title') {
    font-family: 'Arial';
    font-weight: bold;
    font-size: 16px;
    color: var(--ion-color-black);
    &:hover {
      color: var(--ion-color-tertiary);
    }
  }
  @include element('filter-row-icon') {
    margin: 16px 0px;
    cursor: pointer;
  }
  @include element('filter-footer') {
    padding: 32px 0px;
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 100%;
    z-index: 2;
  }
  @include element('clear-button') {
    text-transform: none;
    height: 39px !important;
    --box-shadow: none;
    color: var(--ion-color-dark-blue);
    font-family: 'Futura PT Demi';
    font-size: 16px;
    --background-hover: none;
  }
  @include element('done-button') {
    text-transform: none;
    height: 39px !important;
    --box-shadow: none;
    color: var(--ion-color-dark-blue);
    font-family: 'Futura PT Demi';
    font-size: 16px;
  }
  @include element('filter-options-scroller') {
    &::-webkit-scrollbar {
      width: 5px;
    } // scrollbar track
    &::-webkit-scrollbar-track-piece {
      background: #bcc2d4;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #4c5c8d;
      border-radius: 8px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4c5c8d;
    }
  }
  @include element('filter-detail') {
    position: absolute;
    width: 364px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: var(--ion-color-light);
    z-index: 2;
  }
  @include element('checkbox') {
    --size: 18px;
    margin-right: 12px;
  }
  @include element('filter-detail-row') {
    margin: 16px 36px;
  }
  @include element('filter-detail-name') {
    font-family: 'Arial';
    font-size: 14px;
    color: #000000de;
    margin-left: 8px;
  }
}

.footer-mobile {
  padding-bottom: 82px !important;
}

.header-mobile {
  margin-top: 38px !important;
  margin-bottom: 16px !important;
}

.options-scroller-mobile {
  height: 50% !important;
}
.all-filters__filter-options-scroller {
  overflow: auto;
  height: calc(100% - 16.4rem);
}

.filter-detail-name-disabled {
  color: #9f9f9f !important;
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Pro Max */,
  only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 12 Pro Max */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP XR/11 */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP XS Max/11 Pro Max */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP 11 */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP 11max */,
  only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 12/12 Pro */,
  only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 13/13 Pro */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 11pro */,
  only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP X /XS/11 Pro/12 Mini */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Mini */,
  only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) /* iP 6+/6s+/7+/8+ */,
  only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) /* iP 6/6s/7/8 */ {
  .header-mobile {
    margin-top: 98px !important;
    margin-bottom: 16px !important;
  }
  .options-scroller-mobile {
    height: 50% !important;
  }
  .footer-mobile {
    padding-bottom: 82px !important;
  }
  .ios-filter-container {
    overflow: auto;
    height: 74vh !important;
  }
  @include block('all-filters') {
    @include element('filter-footer') {
      bottom: 48px !important;
    }
  }
}

// for screens under 480px
@media screen and (max-width: 480px) {
  @include block('all-filters') {
    @include element('filter-detail') {
      width: 100%;
    }
    @include element('filter-container') {
      overflow: auto;
      height: 56vh;
    }
  }
}

@media screen and (max-height: 715px) {
  @include block('all-filters') {
    @include element('filter-footer') {
      bottom: 90px !important;
    }
    @include element('filter-container') {
      overflow: auto;
      height: 46vh;
    }
  }
  .options-scroller-mobile {
    height: 36% !important;
  }
}
</style>
