var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCarousal
    ? _c("div", { staticClass: "recommend-home-content" }, [
        _vm.carousalType === "personalized" ||
        (_vm.carousalType === "boostSkills" &&
          _vm.recommendations.content.length)
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "recommend-title",
                  style: !_vm.recommendations.content.length
                    ? "padding: 0 0 2rem"
                    : _vm.isActivityContentNotEmpty && _vm.isDesktopPlatform
                    ? "padding: 3rem 0"
                    : "padding: 0 0 3rem 0"
                },
                [
                  _c("div", { staticClass: "recommend-label" }, [
                    _vm._v(_vm._s(_vm.recommendations.activityTitle))
                  ]),
                  _c("div", { staticClass: "recommend-count" }, [
                    _vm._v(_vm._s(_vm.recommendations.content.length))
                  ])
                ]
              ),
              _vm.dataReady && _vm.recommendations.content.length
                ? _c(
                    "div",
                    [
                      _vm.isDesktopPlatform
                        ? _c(
                            "CarouselDesktop",
                            {
                              style: { "--bottom": "-11px" },
                              attrs: { slidesShow: 4, responsive: true }
                            },
                            _vm._l(_vm.recommendations.content, function(
                              item,
                              index
                            ) {
                              return _c("UniversalCard", {
                                key: item.id,
                                staticClass: "carousel-desktop-item",
                                attrs: {
                                  id: item.id,
                                  item: item,
                                  index: index,
                                  itemsLength:
                                    _vm.recommendations.content.length,
                                  tab: _vm.tabName,
                                  cardType: "carousal",
                                  isExpandCardAllow: false
                                },
                                on: {
                                  "recommended-item-click": function($event) {
                                    return _vm.onRecommendedItemClick(
                                      item,
                                      index
                                    )
                                  }
                                }
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      !_vm.isDesktopPlatform
                        ? _c(
                            "CarouselMobile",
                            _vm._l(_vm.recommendations.content, function(
                              item,
                              index
                            ) {
                              return _c("UniversalCard", {
                                key: item.id,
                                staticClass: "carousel-mobile-item",
                                attrs: {
                                  id: item.id,
                                  item: item,
                                  index: index,
                                  itemsLength:
                                    _vm.recommendations.content.length,
                                  tab: _vm.tabName,
                                  cardType: "carousal",
                                  isExpandCardAllow: false
                                },
                                on: {
                                  "recommended-item-click": function($event) {
                                    return _vm.onRecommendedItemClick(
                                      item,
                                      index
                                    )
                                  }
                                }
                              })
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c("div", { staticClass: "empty-carousal-container" }, [
                    _c("div", { staticClass: "empty-carousal-description" }, [
                      _c("div", { staticClass: "empty-carousal-title" }, [
                        _vm._v(
                          "Not enough activity…yet. Here’s how to get started!"
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "empty-carousal-section" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "icon-search" }
                          }),
                          _c("div", { staticClass: "empty-carousal-title" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.goToExplore()
                                  }
                                }
                              },
                              [_vm._v("Explore")]
                            ),
                            _vm._v(" learning that interests you")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "empty-carousal-section" },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "24px !important",
                              height: "24px !important"
                            },
                            attrs: { "icon-class": "icon-progress-complete" }
                          }),
                          _c("div", { staticClass: "empty-carousal-title" }, [
                            _vm._v("Complete learning you found")
                          ])
                        ],
                        1
                      )
                    ]),
                    _vm._m(0)
                  ])
            ])
          : _vm._e()
      ])
    : _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "recommend-label-shimmer" },
            [_c("ion-skeleton-text", { attrs: { animated: "" } })],
            1
          ),
          _vm.isDesktopPlatform
            ? _c(
                "CarouselDesktop",
                {
                  staticStyle: { "margin-bottom": "1rem" },
                  attrs: { slidesShow: 4, responsive: true }
                },
                _vm._l(4, function(i) {
                  return _c("RecommendedSkeleton", {
                    key: i,
                    staticClass: "carousel-desktop-item-shimmer"
                  })
                }),
                1
              )
            : _c(
                "CarouselMobile",
                _vm._l(2, function(i) {
                  return _c("RecommendedSkeleton", {
                    key: i,
                    staticClass: "carousel-mobile-item-shimmer"
                  })
                }),
                1
              )
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-carousal-image" }, [
      _c("img", { attrs: { src: require("@/assets/images/no-content.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }