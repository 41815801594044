<template>
  <div class="credential-content">
    <ion-card v-if="dataReady" :class="!isDesktopPlatform ? 'mobile-version' : ''">
      <ion-card-header v-if="isDesktopPlatform">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <h3>
              <span v-if="!fromBookmarkList">{{ content.index }}</span> {{ content.title }}
            </h3>
          </ion-col>
          <ion-col size="1">
            <Bookmark :assetId="content.id" assetType="credentialcontents" :meta="content.meta" class="bookmark" :key="content.meta.isBookmarked" />
          </ion-col>
        </ion-row>
      </ion-card-header>
      <ion-card-header v-else>
        <ion-row class="ion-align-items-center">
          <ion-col v-if="!fromBookmarkList" size="2"
            ><h3 class="content-index h3-mobile">{{ content.index }}</h3></ion-col
          >
          <ion-col
            ><h3 class="h3-mobile">{{ content.title }}</h3></ion-col
          >
          <ion-col size="1">
            <Bookmark :assetId="content.id" assetType="credentialcontents" :meta="content.meta" class="bookmark" :key="content.meta.isBookmarked" />
          </ion-col>
        </ion-row>
      </ion-card-header>

      <ion-card-content :key="JSON.stringify(content)">
        <!-- there is video -->
        <ion-row v-if="content.medias && content.medias[0].type === 'video/mp4'">
          <ion-col>
            <video :class="isDesktopPlatform ? 'video-desktop' : 'video-mobile'" poster controls preload="auto">
              <source :src="content.medias[0].contentUri + '#t=0.2'" type="video/mp4" />
            </video>
            <div v-if="content.medias[0].transcript" @click="toggleShowTranscript('video')" class="transcript">
              <svg-icon :icon-class="'dropdown-transcript'" class-name="g-icon--xsmall" :class="[!showTranscript.video ? '' : 'caret-transform']" style="float: right" />
              <p>Transcript</p>
            </div>
            <div v-if="showTranscript.video" v-html="$sanitize(content.medias[0].transcript)" class="text"></div>
          </ion-col>
        </ion-row>

        <!-- there is text/image -->
        <ion-row v-if="isDesktopPlatform">
          <ion-col v-if="content.contentHTML || content.knowledgeChecks" :size="content.imageUri ? 6 : 12">
            <div v-html="$sanitize(content.contentHTML)" class="text"></div>
            <div v-for="knowledgeCheck in content.knowledgeChecks" :key="knowledgeCheck.qid">
              <div v-html="$sanitize(knowledgeCheck.text)" class="text" />
              <ion-textarea
                v-if="knowledgeCheck.type === 'TEXT_INPUT'"
                autofocus
                rows="4"
                @ionChange="handleTextInput($event, knowledgeCheck.qid)"
                :placeholder="knowledgeCheck.text"
                :value="inputAnswers.available ? inputAnswers.answers[knowledgeCheck.qid] : ''"
              ></ion-textarea>
              <ion-list v-if="knowledgeCheck.type === 'SINGLE_OPTION'" style="margin-left: 1rem">
                <ion-radio-group @ionChange="handleRadioSelect($event, knowledgeCheck.qid)" :value="selectedAnswers.length ? selectedAnswers[0] : ''">
                  <ion-item v-for="(option, index) in knowledgeCheck.options" :key="option.text" lines="none">
                    <img v-if="option.image" :src="option.image" style="margin: 0.5rem 1rem" />
                    <ion-label v-else class="ion-text-wrap" style="padding-left: 1rem; font-family: 'Futura PT Book'">{{ option.text }}</ion-label>
                    <ion-radio color="tertiary" slot="start" :value="index"></ion-radio>
                  </ion-item>
                </ion-radio-group>
              </ion-list>
              <ion-list v-if="knowledgeCheck.type === 'MULTI_OPTION'" style="margin-left: 1rem">
                <ion-item v-for="(option, index) in knowledgeCheck.options" :key="index" lines="none" mode="md" @ionChange="handleCheckboxSelect($event, knowledgeCheck.qid)">
                  <img v-if="option.image" :src="option.image" style="margin: 0.5rem 1rem" />
                  <ion-label v-else class="ion-text-wrap" style="padding-left: 1rem; font-family: 'Futura PT Book'">{{ option.text }}</ion-label>
                  <ion-checkbox color="tertiary" slot="start" :value="index" :checked="selectedAnswers.includes(index)"></ion-checkbox>
                </ion-item>
              </ion-list>
            </div>
            <div
              v-if="content.knowledgeChecks && knowledgeCheckConfirm.isConfirmed"
              :class="knowledgeCheckConfirm.type === 'error' ? 'kcs_error_msg' : 'kcs_success_msg'"
              v-html="$sanitize(knowledgeCheckConfirm.message)"
            ></div>
            <div style="float: right; margin: 0.5rem 1rem">
              <ion-button
                v-if="content.knowledgeChecks"
                :disabled="isConfirming"
                style="height: 35px"
                shape="round"
                color="tertiary"
                class="ion-button-tertiary ion-text-capitalize"
                @click="onConfirm"
                >Confirm</ion-button
              >
            </div>
          </ion-col>
          <ion-col v-if="content.imageUri" :size="content.contentHTML ? 6 : 12" style="text-align: center">
            <img :src="content.imageUri" />
          </ion-col>
        </ion-row>
        <ion-row v-if="!isDesktopPlatform">
          <ion-col>
            <div v-if="content.contentHTML" v-html="$sanitize(content.contentHTML)" class="text"></div>
            <div style="text-align: center">
              <img v-if="content.imageUri" :src="content.imageUri" />
            </div>
            <div v-for="knowledgeCheck in content.knowledgeChecks" :key="knowledgeCheck.qid">
              <div v-html="$sanitize(knowledgeCheck.text)" class="text" />
              <ion-textarea
                v-if="knowledgeCheck.type === 'TEXT_INPUT'"
                autofocus
                rows="3"
                @ionChange="handleTextInput($event, knowledgeCheck.qid)"
                :placeholder="knowledgeCheck.text"
                :value="inputAnswers.available ? inputAnswers.answers[knowledgeCheck.qid] : ''"
                style="margin: 0.5rem 0rem; width: 100%"
              ></ion-textarea>
              <ion-list v-if="knowledgeCheck.type === 'SINGLE_OPTION'" style="margin-left: 1rem">
                <ion-radio-group @ionChange="handleRadioSelect($event, knowledgeCheck.qid)" :value="selectedAnswers.length ? selectedAnswers[0] : ''">
                  <ion-item v-for="(option, index) in knowledgeCheck.options" :key="option.text" lines="none">
                    <img v-if="option.image" :src="option.image" style="margin: 0.5rem 1rem" />
                    <ion-label v-else class="ion-text-wrap" style="padding-left: 1rem; font-family: 'Futura PT Book'">{{ option.text }}</ion-label>
                    <ion-radio mode="md" color="tertiary" slot="start" :value="index"></ion-radio>
                  </ion-item>
                </ion-radio-group>
              </ion-list>
              <ion-list v-if="knowledgeCheck.type === 'MULTI_OPTION'" style="margin-left: 1rem">
                <ion-item v-for="(option, index) in knowledgeCheck.options" :key="index" lines="none" mode="md" @ionChange="handleCheckboxSelect($event, knowledgeCheck.qid)">
                  <img v-if="option.image" :src="option.image" style="margin: 0.5rem 1rem" />
                  <ion-label v-else class="ion-text-wrap" style="padding-left: 1rem; font-family: 'Futura PT Book'">{{ option.text }}</ion-label>
                  <ion-checkbox color="tertiary" slot="start" :value="index" :checked="selectedAnswers.includes(index)"> </ion-checkbox>
                </ion-item>
              </ion-list>
            </div>
            <div
              v-if="content.knowledgeChecks && knowledgeCheckConfirm.isConfirmed"
              :class="knowledgeCheckConfirm.type === 'error' ? 'kcs_error_msg' : 'kcs_success_msg'"
              v-html="$sanitize(knowledgeCheckConfirm.message)"
            ></div>
            <div style="float: right; margin: 0.5rem">
              <ion-button v-if="content.knowledgeChecks" :disabled="isConfirming" shape="round" color="tertiary" class="ion-button-tertiary ion-text-capitalize" @click="onConfirm"
                >Confirm</ion-button
              >
            </div>
          </ion-col>
        </ion-row>

        <!-- there is accordions -->
        <CredentialContentAccordion v-for="(accordion, index) in content.accordions" :key="index" :accordion="accordion"></CredentialContentAccordion>

        <!-- there is carousel -->
        <CredentialContentCarousel v-if="content.carousel && content.carousel.length" :carousel="content.carousel" />

        <!-- there is audio -->
        <ion-row v-if="content.medias && content.medias[0].type === 'audio'">
          <ion-col>
            <img v-if="content.medias[0].imageUri" :src="content.medias[0].imageUri" />
            <audio :class="isDesktopPlatform ? 'audio-desktop' : 'audio-mobile'" controls :src="content.medias[0].contentUri">
              Your browser does not support the<code>audio</code> element.
            </audio>
            <div v-if="content.medias[0].transcript" @click="toggleShowTranscript('audio')" class="transcript">
              <svg-icon :icon-class="'dropdown-transcript'" class-name="g-icon--xsmall" :class="[!showTranscript.audio ? '' : 'caret-transform']" style="float: right" />
              <p>Transcript</p>
            </div>
            <div v-if="showTranscript.audio" v-html="$sanitize(content.medias[0].transcript)" class="text"></div>
          </ion-col>
        </ion-row>

        <!-- there is assessment -->
        <ion-row v-if="content.isAssessment" class="ion-no-padding">
          <ion-col size="12" class="ion-padding-bottom ion-text-end">
            <ion-button shape="round" color="tertiary" class="ion-button-tertiary ion-text-capitalize" @click="startAssessment"
              >{{ !content.isComplete ? 'Begin' : 'Redo' }} Assessment</ion-button
            >
          </ion-col>
        </ion-row>

        <!-- submit capstone -->
        <ion-row v-if="content.isSubmitCapstone" class="ion-no-padding">
          <ion-col size="12" class="ion-padding-bottom ion-text-end">
            <ion-button v-if="!content.projectId" shape="round" color="tertiary" class="ion-button-tertiary ion-text-capitalize" @click="onSubmitCapstone"
              >Upload Your Capstone</ion-button
            >
            <ion-button v-else shape="round" color="tertiary" class="ion-button-tertiary ion-text-capitalize" @click="editSubmitCapstone">Edit Your Capstone</ion-button>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import { popoverController } from '@modus/ionic-vue'
import Bookmark from '@/components/molecules/Bookmark'
import AssessmentModal from '@/components/molecules/AssessmentModal'
import CredentialContentAccordion from '@/views/credential/components/CredentialContentAccordion'
import CredentialContentCarousel from '@/views/credential/components/CredentialContentCarousel'

export default {
  name: 'CredentialContent',
  props: {
    content: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    progress: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    fromBookmarkList: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { CredentialContentAccordion, CredentialContentCarousel, Bookmark },
  data() {
    return {
      authToken: '',
      isDesktopPlatform: false,
      showTranscript: {
        video: false,
        audio: false
      },
      isConfirming: false,
      knowledgeChecks: [],
      knowledgeCheckConfirm: {
        isConfirmed: false,
        type: '',
        message: ''
      },
      assessment: [],
      assessmentResults: {
        isCompleted: false,
        results: {}
      },
      selectedAnswers: [],
      inputAnswers: {
        available: false,
        answers: {}
      },
      dataReady: false
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  created() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  async mounted() {
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    await this.fetchLessonProgress()
    this.$on('close-assessment-modal', (data) => {
      this.onCloseAssessmentModal(data)
    })
    this.dataReady = true
  },
  methods: {
    toggleShowTranscript(type) {
      this.showTranscript[type] = !this.showTranscript[type]
    },
    fetchLessonProgress() {
      const knowledgeCheck = this.content.knowledgeChecks ? this.content.knowledgeChecks[0] : null
      if (knowledgeCheck && knowledgeCheck.type === 'TEXT_INPUT') {
        this.inputAnswers.available = knowledgeCheck && this.progress.answers
        this.inputAnswers.answers = this.progress.answers ? this.progress.answers : {}
        for (const [qid, answer] of Object.entries(this.inputAnswers.answers)) {
          this.knowledgeChecks.push({ qid: qid, answer: answer })
        }
        this.onConfirm(false)
      } else if (knowledgeCheck && knowledgeCheck.type != 'TEXT_INPUT') {
        const answers = knowledgeCheck && this.progress.answers ? this.progress.answers[knowledgeCheck.qid] : null
        this.selectedAnswers = answers ? answers.split(',').map((v) => parseInt(v)) : []
        if (this.selectedAnswers.length) {
          const answer = knowledgeCheck.type === 'SINGLE_OPTION' ? this.selectedAnswers[0].toString() : this.selectedAnswers.map((v) => v.toString())
          this.knowledgeChecks.push({ qid: knowledgeCheck.qid, answer: answer })
          this.onConfirm(false)
        }
      }
    },
    async startAssessment() {
      this.assessment = await this.$learnAdmin.getCredentialAssessmentById({ token: this.authToken, id: this.content.id })
      let modal = await popoverController.create({
        component: AssessmentModal,
        swipeToClose: false,
        showBackdrop: true,
        backdropDismiss: false,
        componentProps: {
          parent: this,
          propsData: {
            assessment: this.assessment
          }
        }
      })
      await modal.present()
    },
    async onCloseAssessmentModal(assessment) {
      let userAnswers = []
      if (assessment.completed) {
        for (const qid in assessment.answers) {
          userAnswers.push({ qid: qid, answer: assessment.answers[qid].join(',') })
        }
        this.assessmentResults.results = await this.$learnAdmin.postCredentialAssessmentAnswers({ token: this.authToken, id: assessment.id, body: { userAnswers: userAnswers } })
        this.assessmentResults.isCompleted = true
      }
      this.$emit('on-complete-assessment', this.assessmentResults.results)
    },
    onSubmitCapstone() {
      this.$emit('on-submit-capstone', true)
    },
    editSubmitCapstone() {
      this.$emit('on-submit-capstone', false)
    },
    handleTextInput(event, qid) {
      const kcs = { qid: qid, answer: event.target.value }
      const found = this.knowledgeChecks.findIndex((kcs) => kcs.qid === qid)
      found > -1 ? this.knowledgeChecks.splice(found, 1, kcs) : this.knowledgeChecks.push(kcs)
    },
    handleRadioSelect(event, qid) {
      const kcs = { qid: qid, answer: event.target.value }
      const found = this.knowledgeChecks.findIndex((kcs) => kcs.qid === qid)
      found > -1 ? this.knowledgeChecks.splice(found, 1, kcs) : this.knowledgeChecks.push(kcs)
    },
    handleCheckboxSelect(event, qid) {
      const value = event.target.value
      const kcs = { qid: qid, answer: [value] }
      const found = this.knowledgeChecks.findIndex((kcs) => kcs.qid === qid)
      if (found > -1) {
        const exist = this.knowledgeChecks[found].answer.indexOf(value)
        exist > -1 ? this.knowledgeChecks[found].answer.splice(exist, 1) : this.knowledgeChecks[found].answer.push(value)
      } else {
        this.knowledgeChecks.push(kcs)
      }
    },
    onConfirm(emit = true) {
      let correctAnswer = false
      this.isConfirming = true
      if (this.knowledgeChecks.length != this.content.knowledgeChecks.length) {
        this.knowledgeCheckConfirm = {
          isConfirmed: true,
          type: 'error',
          message: 'Error! Please provide your response.'
        }
        this.isConfirming = false
        return
      }
      if (this.content.answers) {
        /* muliple choice check your knowledge */
        const answer = this.content.answers[0]
        const isMultiOpt = answer.answer.includes(',')
        const result = isMultiOpt ? answer.answer.split(',').sort().join(',') : parseInt(answer.answer)
        const choice = isMultiOpt ? this.knowledgeChecks[0].answer.sort().join(',') : parseInt(this.knowledgeChecks[0].answer)
        let explanation = ''
        if (isMultiOpt) {
          const resultVal = result.split(',').map((e) => parseInt(e))
          const choiceVal = choice.split(',').map((e) => parseInt(e))
          const resultText = this.content.knowledgeChecks[0].options.filter((opt, index) => resultVal.includes(index))
          const choiceText = this.content.knowledgeChecks[0].options.filter((opt, index) => choiceVal.includes(index))
          explanation = '<div>Your Choices:</div><ul>'
          choiceText.map((result) => {
            explanation += result.text.length ? `<li>${result.text}</li>` : ''
          })
          explanation += '</ul><br/><div>Correct Choices:</div><ul>'
          resultText.map((result) => {
            explanation += result.text.length ? `<li>${result.text}</li>` : ''
          })
          explanation += '</ul>'
          explanation = explanation.includes('<li>') ? explanation : ''
        } else {
          explanation = answer.explanation ? `<div>${answer.explanation}</div>` : ''
        }
        this.knowledgeCheckConfirm = {
          isConfirmed: true,
          type: result === choice ? 'success' : 'error',
          message: result === choice ? `<strong>Correct!</strong>` : `<strong>Incorrect!</strong> ${explanation}Please try again.`
        }
        correctAnswer = result === choice
      } else {
        /* free response check your knowledge */
        this.knowledgeCheckConfirm = {
          isConfirmed: true,
          type: 'success',
          message: 'Your answer has been recorded'
        }
      }
      this.isConfirming = correctAnswer || false
      if (emit) {
        this.$emit('on-submit-kcs', this.knowledgeChecks)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.credential-content {
  ion-card {
    padding: 1rem;
    ion-card-header {
      padding: 0 1rem;
    }
    .content-index {
      background: map-get($edge-colors, 'tertiary');
      color: map-get($edge-colors, 'white');
      padding: 0 0.5rem;
      margin-right: 0.5rem;
      text-align: center;
    }
    h3 {
      font-family: 'Futura PT Demi';
      font-size: 1.8rem;
      color: map-get($edge-colors, 'tertiary');
      span {
        background: map-get($edge-colors, 'tertiary');
        color: map-get($edge-colors, 'white');
        padding: 0 0.5rem;
        margin-right: 0.5rem;
      }
    }
    p {
      font-size: 1.1rem;
      color: map-get($edge-colors, 'black');
    }
    .transcript {
      cursor: pointer;
    }
    .text {
      margin-top: 0.5rem;
      padding: 0 1rem;
      color: map-get($edge-colors, 'black');
    }
    text-align: left;

    video {
      margin: 1rem auto;
      display: block;
      outline: none;
    }
    audio {
      margin: auto;
      display: block;
      outline: none;
    }

    .caret-transform {
      transform: rotate(180deg);
    }

    ion-textarea {
      background: var(--ion-color-medium-light);
      padding: 0.5rem 0.5rem;
      border-radius: 3px;
      margin-left: 1rem;
      width: 90%;
    }
  }

  .mobile-version {
    padding: 0rem;
  }

  .video-desktop {
    width: 600px;
    height: 300px;
  }

  .audio-desktop {
    width: 600px;
  }

  .video-mobile,
  .audio-mobile {
    width: 100%;
  }
  .h3-mobile {
    font-size: 1.3rem;
  }
}

.kcs_error_msg {
  float: left;
  margin: 0.5rem 1rem;
  color: var(--ion-color-orange-shade);
}

.kcs_success_msg {
  float: left;
  margin: 0.5rem 1rem;
  color: var(--ion-color-secondary-dark);
}
</style>
