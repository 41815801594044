<template>
  <div>
    <ion-row class="accordion">
      <ion-col>
        <div style="cursor: pointer" @click="onToggle">
          <svg-icon
            :icon-class="accordion.hint ? 'dropdown-transcript' : 'dropdown-transcript-white'"
            class-name="g-icon--xsmall"
            :class="[!expand ? '' : 'caret-transform']"
            style="float: right; margin-right: 0.5rem; margin-top: 0.25rem"
          />
          <div class="accordion-title" :class="accordion.hint ? 'accordion-hint' : ''">{{ accordion.title }}</div>
        </div>
        <div v-if="expand" class="accordion-content" v-html="$sanitize(accordion.contentHTML)"></div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
export default {
  name: 'CredentialContentAccordion',
  components: {},
  props: {
    accordion: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      expand: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onToggle() {
      this.expand = !this.expand
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  margin: 0 1rem 0.2rem 1rem;
  .accordion-title {
    padding-left: 1rem;
    font-size: 1.2rem;
    font-family: 'Futura PT Demi';
    color: map-get($edge-colors, 'white');
    background: map-get($edge-colors, 'tertiary');
  }
  .accordion-hint {
    color: map-get($edge-colors, 'tertiary') !important;
    background: map-get($edge-colors, 'primary') !important;
  }
  .accordion-content {
    padding: 0rem 1rem 0rem 1rem;
  }
}
.caret-transform {
  transform: rotate(180deg);
}
</style>
