<template>
  <div class="edge-createAccount">
    <button class="back_arrow" v-if="$platform == 'Mobile'">
      <svg-icon icon-class="thin-back-arrow" class-name="g-icon--small" style="width: 20px !important; height: 20px !important" @click="$router.go(-1)" />
    </button>
    <div ref="top" class="edge-createAccount__svg-wrapper">
      <svg-icon icon-class="small-logo" class-name="logo--size" :class="this.$platform === 'Desktop' ? 'on-desktop' : ''" />
    </div>
    <div class="edge-createAccount__input">
      <ion-item class="ion-no-padding input-section" mode="md">
        <ion-input
          data-cy="learn-desktop-signup-email"
          placeholder="Enter your work email address"
          autofocus
          ref="username"
          @ionChange="
            $event.target.value = $event.target.value ? $event.target.value.trim() : ''
            user.email = $event.target.value
            user.username = $event.target.value
            checkSSOEmail()
          "
          :disabled="isInputDisabled"
          @keypress.enter="next()"
        ></ion-input>
      </ion-item>
      <ion-item class="ion-no-padding input-section" v-if="showPasswordField" mode="md">
        <ion-input
          data-cy="learn-desktop-signup-password"
          :type="showPwd ? 'text' : 'password'"
          placeholder="Create a password"
          @ionChange="
            user.password = $event.target.value
            onPasswordChange()
          "
          :disabled="isInputDisabled"
          @keypress.enter="next()"
          @ionBlur="verifyPassword"
        ></ion-input>
        <ion-icon slot="end" :name="showPwd ? 'eye' : 'eye-off'" @click="() => (showPwd = !showPwd)" style="color: #00a78d"></ion-icon>
      </ion-item>
    </div>
    <div v-if="showPasswordField && errorMessage == ''" class="edge-createAccount__placeholder-message">{{ placeholderMessage }}</div>
    <div class="edge-createAccount__error-message" aria-label="error message">{{ errorMessage }}</div>
    <div class="privacy-terms-container">
      <input type="checkbox" id="checkbox" v-model="user.has_accepted_terms" class="privacy-terms-checkbox ion-margin-end" @keypress.enter="next()" />
      <ion-label class="privacy-terms">
        I agree to the <router-link class="terms" to="terms-and-conditions" @click.native="openTerms">Terms and Conditions</router-link>
        and acknowledge the
        <router-link class="privacy" to="privacy-policy" @click.native="openPrivacy">Privacy Policy</router-link> and
        <router-link class="cookie-notice" to="cookie-notice" @click.native="openCookieNotice">Cookie Notice</router-link>
      </ion-label>
      <ion-label class="privacy-terms-mobile">
        I agree to the <router-link class="terms" to="terms-and-conditions" @click.native="openTerms"> Terms and Conditions</router-link> <br />
        and acknowledge the
        <router-link class="privacy" to="privacy-policy" @click.native="openPrivacy">Privacy Policy</router-link> <br />
        and
        <router-link class="cookie-notice" to="cookie-notice" @click.native="openCookieNotice">Cookie Notice</router-link>
      </ion-label>
    </div>
    <div v-if="appBanner && !isNativeApp && $platform == 'Mobile'" class="banner">
      <ion-grid class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col>
            <svg-icon icon-class="close" class="g-icon--small close g-clickable" style="position: absolute; right: 0" @click="onCancel"></svg-icon>
            <p class="ion-no-margin" style="font-size: 1.4rem">ProEdge is better on the App</p>
          </ion-col>
        </ion-row>
        <ion-row style="padding-right: 13px">
          <ion-col>
            <div style="width: 130px; margin-left: auto; margin-right: 0; padding-top: 9px">
              <a href="https://apps.apple.com/us/app/proedge-learn/id1540658579"> <img src="@/assets/images/apple-store-badge.svg" /> </a>
            </div>
          </ion-col>
          <ion-col class="ion-text-start">
            <div style="width: 147px">
              <a href="https://play.google.com/store/apps/details?id=com.pwc.gx.mobility.proedgelearn"> <img src="@/assets/images/google-play-badge.png" /> </a>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ion-button
      :disabled="allowRegistration"
      data-cy="learn-desktop-signup-button"
      shape="round"
      color="tertiary"
      class="edge-createAccount__button ion-button-tertiary"
      @click="next()"
      >Register</ion-button
    >
    <router-link to="get-help" class="lined-link" v-if="$platform == 'Mobile'">Get help</router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import localforage from 'localforage'
import { validEmail, validPassword } from '@/utils/validate'
import { Plugins } from '@capacitor/core'
import { InAppBrowser } from '@ionic-native/in-app-browser'

const { App, Browser } = Plugins

export default {
  name: 'CreateAccount',
  props: {},
  data() {
    return {
      showPwd: false,
      isInputDisabled: false,
      errorMessage: '',
      showPasswordField: false,
      cloudEnv: {},
      isCloudEnvSet: false,
      ssoRedirect: '',
      user: {
        email: '',
        password: '',
        username: '',
        has_accepted_terms: false
      },
      placeholderMessage: 'Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number',
      placeholderErrorMessage: 'You have entered an invalid email or password',
      onBoardingState: null,
      pValidator: {
        characterLength: false,
        uppercase: false,
        lowercase: false,
        number: false,
        nameNotPresent: true
      },
      appBanner: true,
      isNativeApp: true
    }
  },
  computed: {
    ...mapGetters(['cloud_env']),
    allowRegistration: function () {
      return !this.validateFields()
    }
  },
  created() {},
  mounted() {
    analytics.setVarAndTrack({ c: 'onboarding', a: 'loaded', l: 'create-account' })
    this.$refs.top.scrollIntoView()
    if (this.$route && this.$route.query && this.$route.query.email) {
      this.$refs.username.value = this.$route.query.email
      this.user.email = this.$route.query.email
      this.checkSSOEmail()
    }
    // eslint-disable-next-line
    this.isNativeApp = Capacitor.isNative
  },
  methods: {
    ...mapActions('user', [
      'setAccessToken',
      'setUserInfo',
      'setUserOnboarded',
      'setTenantInfo',
      'setSSO',
      'setShowShare',
      'setShowIdea',
      'setHideRWL',
      'setTrackingDisabled',
      'setGlobalSearchEnabled',
      'setShowSearchSuggest',
      'setShowSearchLookingFor',
      'setUserAccessTag'
    ]),
    ...mapActions('environment', ['setCloudEnvInfo', 'setUserConfig', 'setCompanyConfig', 'setFlagConfig']),
    async onPasswordChange() {
      this.pValidator.characterLength = this.user.password.length > 7 ? true : false
      this.pValidator.lowercase = /[a-z]/.test(this.user.password) ? true : false
      this.pValidator.uppercase = /[A-Z]/.test(this.user.password) ? true : false
      this.pValidator.number = /[0-9]/.test(this.user.password) ? true : false
      this.pValidator.nameNotPresent = !this.user.password.toLowerCase().includes(this.user.email.toLowerCase().trim())
    },
    onCancel() {
      this.appBanner = false
    },
    verifyPassword() {
      if (!this.validatorCheck()) {
        this.setErrorMessage('Invalid password - Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number')
      } else {
        this.setErrorMessage('')
      }
    },
    validatorCheck() {
      return this.pValidator.characterLength && this.pValidator.lowercase && this.pValidator.uppercase && this.pValidator.nameNotPresent && this.pValidator.number
    },
    async openPrivacy() {
      if (this.$platform === 'Desktop') {
        this.currentModal = 'PrivacyPolicy'
        this.showModals = true
        let routeData = this.$router.resolve({ path: 'onboarding/privacy-policy', params: { isDesktopPrivacy: true } })
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push('/onboarding/privacy-policy').catch(() => {})
      }
    },
    async openCookieNotice() {
      if (this.$platform === 'Desktop') {
        let routeData = this.$router.resolve({ path: 'onboarding/cookie-notice', params: { isDesktopTerms: true } })
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push('/onboarding/cookie-notice').catch(() => {})
      }
    },
    async openTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use', '_blank')
    },
    accept(e) {
      this.user.has_accepted_terms = e.target.checked
    },
    async checkSSOEmail() {
      // eslint-disable-next-line
      const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.user.email = this.user.email.trim()

      if (emailPattern.test(this.user.email)) {
        this.isCloudEnvSet = await this.setCloudEnvData()
        let response = await this.$learnAdmin.ssoCheck(this.user.email)
        this.showPasswordField = !response['isSSOLogin']
        if (response['isSSOLogin']) {
          this.ssoRedirect = response['sso-url']
          await localforage.setItem('ssoRedirect', this.ssoRedirect)
        }
      }
    },
    async next() {
      await this.checkSSOEmail()
      if (!this.showPasswordField) {
        this.toggleInputs()
        if (!this.isCloudEnvSet) this.toggleInputs()

        this.cloudEnv = this.cloud_env || (await localforage.getItem('cloudEnv'))
        const redirectUrl = `${this.ssoRedirect}?referring_url=${window.location.origin}/landing&env=${this.cloudEnv.details.mode}`

        if (this.$platform === 'Desktop') {
          window.location.href = redirectUrl
        } else {
          this.$platform === 'Mobile' && this.$device === 'android' ? await this.openIonicBrowser(redirectUrl) : await this.openCapacitorBrowser(redirectUrl)
        }
      } else {
        this.toggleInputs()

        if (!validEmail(this.user.email)) {
          this.setErrorMessage('Please enter a valid email address')
          this.toggleInputs()
          return
        }
        this.isCloudEnvSet = await this.setCloudEnvData()
        if (!this.isCloudEnvSet) {
          this.setErrorMessage('Unknown Email. Please use your work email')
          this.toggleInputs()
          return
        }

        if (!validPassword(this.user.password)) {
          this.setErrorMessage('Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number')
          this.toggleInputs()
          return
        }

        // Only allow registration if terms are accepted
        if (!this.allowRegistration) {
          const response = await this.$learnAdmin.registerUser(this.user).catch((error) => error)

          if (response.error || response.code) {
            this.setErrorMessage(response.message)
            this.toggleInputs()
            return
          }
          if (this.$platform === 'Mobile') {
            this.$router.push({ name: 'OnboardingVerify', params: { email: this.user.email } })
          } else {
            this.$emit('next', { page: 'Verify', email: this.user.email })
          }
        } else {
          this.isInputDisabled = false
        }
      }
    },
    async openCapacitorBrowser(redirectUrl) {
      await Browser.open({ url: redirectUrl, windowName: '_self' })
      App.addListener('appUrlOpen', async () => {
        const getLaunchUrl = await App.getLaunchUrl()
        if (getLaunchUrl.url.includes('/landing?ProAuthSSO')) {
          const fixingUrl = getLaunchUrl.url.replace('capacitor://localhost', '')
          await Browser.close()
          this.$router.push(fixingUrl)
        }
      })
    },
    async openIonicBrowser(redirectUrl) {
      let options = {
        zoom: 'no',
        location: 'no',
        toolbar: 'no',
        enableViewportScale: 'no',
        transitionstyle: 'crossdissolve',
        clearcache: 'yes',
        clearsessioncache: 'yes'
      }

      let browser = InAppBrowser.create(redirectUrl, '_self', options)
      browser.on('loadstart').subscribe(async (event) => {
        if (event.url.includes('/landing')) {
          browser.close()
          const proAuthSSO = event.url.split('=')
          const response = JSON.parse(atob(proAuthSSO[1]))
          await this.login(response)
          this.$router.push(`/landing?ProAuthSSO=${proAuthSSO[1]}`)
        }
      })
    },
    async login(resp) {
      const response = !resp ? await this.$learnAdmin.login(this.user) : resp

      if (!response.access_token) return

      const access_token = response.access_token
      const sso = { token: response.sso_token, bouncer: response.sso_bouncer }
      await this.setAccessToken(access_token)
      await this.setSSO(sso)

      const userInfo = await this.getUserInfo()
      if (!userInfo) {
        return false
      }
      this.onBoardingState = userInfo.onBoardingState
      const userOnboarded = this.onBoardingState && this.onBoardingState === 'COMPLETED'
      await this.setUserInfo(userInfo)
      await this.setUserOnboarded(userOnboarded)

      const userAccessAssets = await this.getUserAccessAssets()
      if (userAccessAssets.canAccessAssets) {
        await this.setUserAccessTag(userAccessAssets)
      }

      const tenantInfo = await this.getUserTenantInfo()
      if (!tenantInfo) {
        return false
      }
      await this.setTenantInfo(tenantInfo.clientCategory)
      const settings = tenantInfo.settings ? JSON.parse(tenantInfo.settings) : null
      if ({}.hasOwnProperty.call(settings, 'ui-share-display')) {
        await this.setShowShare(settings['ui-share-display'])
      } else {
        await this.setShowShare(true)
      }
      if ({}.hasOwnProperty.call(settings, 'ui-idea-display')) {
        await this.setShowIdea(settings['ui-idea-display'])
      } else {
        await this.setShowIdea(true)
      }
      if ({}.hasOwnProperty.call(settings, 'ui-rwl-display')) {
        await this.setHideRWL(settings['ui-rwl-display'])
      } else {
        await this.setHideRWL(false)
      }
      if ({}.hasOwnProperty.call(settings, 'trackingDisabled')) {
        await this.setTrackingDisabled(settings['trackingDisabled'])
      } else {
        await this.setTrackingDisabled(false)
      }
      if ({}.hasOwnProperty.call(settings, 'globalSearchEnabled')) {
        await this.setGlobalSearchEnabled(settings['globalSearchEnabled'])
      } else {
        await this.setGlobalSearchEnabled(false)
      }
      if ({}.hasOwnProperty.call(settings, 'showSearchSuggest')) {
        await this.setShowSearchSuggest(settings['showSearchSuggest'])
      } else {
        await this.setShowSearchSuggest(false)
      }
      if ({}.hasOwnProperty.call(settings, 'showSearchLookingFor')) {
        await this.setShowSearchLookingFor(settings['showSearchLookingFor'])
      } else {
        await this.setShowSearchLookingFor(false)
      }
      analytics.setVarAndTrack({ c: 'cta', l: 'login', a: 'click', un: this.email })
      return true
    },
    async getUserInfo() {
      const token = await localforage.getItem('my_access_token')
      const userInfo = await this.$learnAdmin.getUserInfo({ token: token }).catch((error) => error)
      if (userInfo.error || userInfo.code) {
        return
      }
      return userInfo
    },
    async getUserAccessAssets() {
      const token = await localforage.getItem('my_access_token')
      return await this.$learnAdmin.getUserAccessAssets({ token: token }).catch((error) => error)
    },
    async getUserTenantInfo() {
      const token = await localforage.getItem('my_access_token')
      const tenantInfo = await this.$learnAdmin.getUserTenantInfo({ token: token }).catch((error) => error)
      if (tenantInfo.error || tenantInfo.code) {
        return
      }
      return tenantInfo
    },
    toggleInputs() {
      this.isInputDisabled = !this.isInputDisabled
    },
    setErrorMessage(message) {
      this.errorMessage = message
    },
    validateFields() {
      if (this.showPasswordField) {
        return this.validatorCheck() && validEmail(this.user.email) && validPassword(this.user.password) && this.user.has_accepted_terms
      } else {
        return validEmail(this.user.email) && this.user.has_accepted_terms
      }
    },
    async setCloudEnvData() {
      const response =
        process.env.VUE_APP_USE_FFAPI === 'true'
          ? await this.$learnAdmin.getCloudEnvByUserEmailAddress(this.user.email)
          : await this.$learnAdmin.getCloudEnvFromDefaultEnv(this.user.email)
      if (!response.data || !response.data.cloudEnv) {
        return false
      }
      await this.setCloudEnvInfo(response.data.cloudEnv)
      await this.$learnAdmin.updateEnvFromLocalForage()

      this.setUserConfig(response.data.config.user)
      this.setCompanyConfig(response.data.config.company)
      this.setFlagConfig(response.data.config.flags)
      return true
    }
  }
}
</script>
<style lang="scss">
.native-input.sc-ion-input-md {
  font-size: 16px !important;
  font-family: Arial !important;
  color: #5c5c5c;
}
</style>
<style lang="scss" scoped>
@include block('edge-createAccount') {
  @include element('svg-wrapper') {
    padding: 8% 8% 8% 8%;
    @include block('svg') {
      width: 100%;
      height: 100%;
    }
  }
  @include element('button') {
    font-size: 1rem;
    font-family: 'Futura PT Demi';
    text-transform: none;
    --box-shadow: none;
  }
  @include element('input') {
    padding: 0% 8%;
  }
  @include element('error-message') {
    font-family: Arial;
    font-size: 12px;
    text-align: left;
    padding: 2% 8%;
    width: 95%;
    color: var(--ion-color-status);
  }
  @include element('placeholder-message') {
    font-family: Arial;
    font-size: 12px;
    text-align: left;
    padding: 2% 8%;
    width: fit-content;
    color: #5c5c5c;
  }
}

.lined-link {
  display: block;
  margin: 10px 0;
  color: #1f3370;
  font-size: 1rem;
  text-decoration: underline;
}

.edge-createAccount {
  .logo--size {
    width: 80%;
    height: 80%;
  }
}

.on-desktop {
  width: 40% !important;
}

ion-input[type='password'] {
  font-family: monospace;
  ::placeholder {
    font-family: 'Futura PT Book', sans-serif;
  }
}

.input-section {
  --highlight-background: var(--ion-color-secondary-dark);
}
a.privacy,
a.cookie-notice {
  position: relative;
  font-family: Arial;
  font-weight: normal;
  font-size: 14px;
}

a.terms {
  position: relative;
  font-family: Arial;
  font-weight: normal;
  font-size: 14px;
}
.privacy-terms-container {
  text-align: left;
  font-family: Arial;
  font-size: 14px;
  padding: 0 8%;
  margin: 15px 0 35px 0;
  display: flex;

  .privacy-terms {
    display: inline;
    font-family: Arial;
    font-size: 14px;
    flex: 1;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
}

.privacy-terms-mobile {
  display: inline;
  font-family: Arial;
  font-size: 14px;
  flex: 1;
  @media screen and (min-width: 480px) {
    display: none;
  }
}

.privacy-terms-checkbox {
  margin-top: 3px;
}

.banner {
  position: absolute;
  margin: 0px 4px 0px 4px;
  width: 97%;
  height: 88px;
  z-index: 9;
  border-radius: 5px;
  border: 2px solid var(--ion-color-dark-blue);
  background-color: var(--ion-color-white);
}
</style>
