var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "collection-about" }, [
    _c(
      "div",
      { staticClass: "collection-desc" },
      [
        _c("div", { staticClass: "collection-title" }, [_vm._v("Description")]),
        _c("div", {
          class:
            _vm.collection.content.length > 0
              ? "collection-subtitle"
              : "collection-subtitle collection-subtitle-margin",
          domProps: {
            innerHTML: _vm._s(_vm.$sanitize(_vm.collection.synopsis))
          }
        }),
        _vm.collection.content.length
          ? _c(
              "ion-grid",
              { staticClass: "ion-no-padding" },
              [
                _c(
                  "ion-row",
                  _vm._l(_vm.collection.content, function(item, index) {
                    return _c(
                      "ion-col",
                      {
                        key: index,
                        staticClass: "list-container",
                        attrs: { size: "12" }
                      },
                      [
                        !(
                          item.scope.isExternal && !_vm.isExternalContentEnabled
                        )
                          ? _c("UniversalCard", {
                              attrs: {
                                item: item,
                                index: index,
                                itemsLength: _vm.collection.content.length,
                                tab: "track"
                              },
                              on: { "delete-item": _vm.deleteExternalItem }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }