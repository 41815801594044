<template>
    <div :class="showError ? 'error-input-drop v_function_multi_dropdown' : 'v_function_multi_dropdown'">
      <div class="v_function_multi_wrapper multiple">
        <div class="v_function_multi_toggler" @click="openDropDown($event)" tabindex="0" @keyup.enter="openDropDown($event)">
          <div :class="hasValue ? 'has-value v_function_multi_value' : 'v_function_multi_value'">{{ selectedValue.length === list.length ? 'All functions' : selectedValue.join(', ') }}</div>
          <div class="v_function_multi_arrow"></div>
        </div>
        <div class="v_function_multi_dropdown_container">
          <div class="v_function_multi_dropdown">
            <ul>
              <li style="border-bottom:1px solid #E0E1E1;background:none !important;" class="v_function_action-container">
                <a href="#" style="cursor:auto;background:none !important;" @click="selectAll()">
                    <span class="fun-select-all">Select all</span>
                </a>
                <span class="fun-pipe-elem">|</span>
                <a href="#" style="cursor:auto;background:none !important;" @click="clearAll()"> 
                    <span class="fun-clear-all">Clear</span>
                </a>
              </li>
              <li v-for="(type, index) in functionsList" :key="index" :id="'multi-list-fun-' + index">
                <a
                  :data-select-option-value="type.name"
                  href="#"
                  :class="selectedValue.includes(type.name) ? ' v_function_multi_option selected-box' : 'v_function_multi_option'"
                  @click="selectOption(type.name)"
                >
                  <input
                    :ref="'fun-checkbox-select-' + index"
                    :id="'fun-checkbox-select-' + index"
                    :checked="selectedValue.includes(type.name) ? true : false"
                    :value="index"
                    type="checkbox"
                  />
                  <label :for="'fun-checkbox-select-' + index" @click="selectOption(type.name)" class="checkbox-label">&nbsp;{{ type.name }}</label>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FunctionDropDown',
    props: {
      list: {
        type: Array,
        required: true,
        default: () => {
          return []
        }
      },
      listVal: {
        type: Array,
        required: true,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        functionsList: [],
        showError: false,
        defaultVal: [],
        selectedValue: ['Select all that apply'],
        valueIndex: [],
        hasValue: false
      }
    },
    beforeDestroy () {
      document.removeEventListener('click',this.close)
    },
    mounted() {
      document.addEventListener('click', this.close)
      this.functionsList = this.list
      this.showError = false
      this.checkBoxFlag = this.checkBoxRequired
      this.hasValue = false
      this.checkListVal();
    },
    methods: {
      selectAll() {
        if(this.selectedValue.length < this.list.length) {
          this.selectedValue = this.selectedValue.filter((e) => e !== 'Select all that apply');
          this.showError = false;
          this.selectedValue = this.list.map((e) => e.name);
          this.hasValue = true;
          this.$emit('selectedFun', this.selectedValue)
        }
      },
      clearAll() {
        if(this.selectedValue.length > 0) {
          this.selectedValue = [];
          this.showError = true;
          this.hasValue = false;
          this.selectedValue.push('Select all that apply')
          this.$emit('selectedFun', []);
        }
      },
      checkListVal() {
        if (this.listVal.length > 0) {
          this.hasValue = true
          this.selectedValue = this.listVal
        } else {
          this.hasValue = false
          this.selectedValue = ['Select all that apply']
        }
      },
      selectOption(value) {
        if (this.selectedValue.includes(value)) {
          this.selectedValue = this.selectedValue.filter((e) => e !== value)
        } else {
          this.selectedValue.push(value)
        }
        if (this.selectedValue.length == 0) {
          this.selectedValue.push('Select all that apply')
          this.showError = true
        } else if (this.selectedValue.includes('Select all that apply')) {
          this.showError = false
          this.selectedValue = this.selectedValue.filter((e) => e !== 'Select all that apply')
        }
        const emitValue = this.selectedValue.includes('Select all that apply') ? [] : this.selectedValue
        this.hasValue = emitValue.length > 0 ? true : false
        this.$emit('selectedFun', emitValue)
      },
      closeDropDown(event) {
        event.target.closest('.v_function_multi_wrapper').classList.remove('v_function_multi_open')
      },
      close (e) {
        if (!this.$el.contains(e.target)) {
          const drodownSelectors = document.querySelectorAll('.v_function_multi_wrapper');
          drodownSelectors.forEach(selector => {
            selector.classList.remove('v_function_multi_open');
          });
        }
      },
      openDropDown(event) {
        event.target.closest('.v_function_multi_wrapper').classList.toggle('v_function_multi_open')
      }
    }
  }
  </script>
  
  <style>
  .checkbox-label {
    cursor: pointer;
  }
  .v_function_multi_dropdown {
    display: inline-block;
    margin-top: 0.5rem;
    width: 100%;
  }

  .v_function_action-container {
    display: flex;
    align-items: center;
  }

  .close-modal-btn {
    margin: 0rem 0rem 1rem 0.15rem;
  }
  .v_function_multi_wrapper {
    color: #333;
    display: inline-flex;
    flex-wrap: wrap;
    font-family: sans-serif;
    font-size: 14px;
    position: relative;
    text-align: left;
    width: 100%;
  }
  
  .v_function_multi_toggler {
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    padding: 7px 30px 7px 10px;
    position: relative;
    width: 100%;
  }
  
  .v_function_multi_value:not(.has-value) {
    opacity: 0.5;
  }
  
  .v_function_multi_value {
    font: normal normal normal 1rem/1.375rem Arial;
    height: 20px;
    line-height: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .v_function_multi_arrow {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
  }
  
  .v_function_multi_arrow::after {
    transform: rotate(45deg);
    border: 1px solid transparent;
    border-bottom-color: #111;
    border-right-color: #111;
    content: '';
    height: 8px;
    margin-top: -6px;
    width: 8px;
  }
  
  .v_function_multi_dropdown_container {
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    text-align: left;
    flex-wrap: wrap;
    z-index: 11;
    transition: all 0.5s ease;
    transform: translate3d(0px, 10px, 0px) scale(0, 0);
    width: 100%;
    display: none;
    opacity: 0;
    cursor: pointer;
  }
  
  .v_function_multi_open .v_function_multi_dropdown_container {
    display: inline-block;
    transform: translate3d(0px, 36px, 0px) scale(1, 1);
    opacity: 1;
  }
  
  .v_function_multi_dropdown {
    background-color: #fff;
    width: 100%;
  }
  
  .v_function_multi_dropdown ul {
    margin: 0;
    padding: 0;
  }
  
  .v_function_multi_dropdown ul li {
    list-style-type: none;
  }
  
  .v_function_multi_dropdown ul li a {
    font: normal normal normal 0.875rem/1.125rem Arial;
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
  }
  
  .v_function_multi_dropdown ul li a:hover,
  .v_function_multi_dropdown ul li a:focus {
    background: #f6f7f9;
  }
  
  .v_function_multi_wrapper .checkbox-icon {
    display: inline-flex;
    height: 13px;
    margin-right: 10px;
    position: relative;
    width: 15px;
    top: 2px;
  }
  
  .v_function_multi_wrapper .checkbox-icon::after {
    transition-duration: 0.2s;
    border: 2px solid #888;
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
  }
  
  .v_function_multi_wrapper.multiple .v_function_multi_option.selected .checkbox-icon::after {
    transform: rotate(45deg) translate(1px, -4px);
    border-color: #512da8;
    border-left-color: transparent;
    border-top-color: transparent;
    width: 50%;
  }
  .selected-box {
    background: #e9ebf1;
  }
  .fun-select-all {
    color:#1F3370;
    font-weight: bold;
    cursor: pointer;
    font-family: Arial;
  }

  .fun-pipe-elem {
    color:#E0E1E1;
    padding-right:0.5rem;
  }

  .fun-clear-all {
    color:#1F3370;
    font-weight: bold;
    cursor: pointer;
    font-family: Arial;
  }
  </style>