<template>
  <div class="expand-item-container-popover" @mouseleave="onCancel">
    <!-- <div class="close" @click="onCancel">
      <svg-icon icon-class="filter-close" class="g-icon--small"></svg-icon>
    </div> -->
    <UniversalCard
      :key="item.id"
      :item="item"
      :index="index"
      :itemsLength="itemsLength"
      :tab="tab"
      @popover-on="popoverOn()"
      @popover-off="popoverOff"
      @close-expand-item-popover="onCancel('onTitle')"
    />
  </div>
</template>

<script>
import UniversalCard from '@/components/organisms/UniversalCard'

export default {
  name: 'UniversalCardModal',
  components: { UniversalCard },
  props: {
    itemsLength: {
      type: Number,
      required: true,
      default: () => {
        return false
      }
    },
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      required: true,
      default: () => {
        return 0
      }
    },
    skillIndex: {
      type: Number,
      required: false,
      default: () => {
        return 0
      }
    },
    skillName: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    skillUuid: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    tab: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    closeModal: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      isPopoverOpen: false
    }
  },
  async mounted() {},
  filters: {},
  methods: {
    onCancel(eventType) {
      this.closeModal(eventType,this.item,this.index,this.skillName,this.skillIndex,this.skillUuid)
    },
    popoverOn() {
      this.isPopoverOpen = true
    },
    popoverOff(type) {
      this.isPopoverOpen = false
      type.onClickTrackOption ? this.closeModal() : null
    }
  }
}
</script>

<style lang="scss" scoped>
.expand-item-container-popover {
  width: 550px;
  height: 550px;
  border-radius: 20px;
  padding: 32px 48px;
}
.close {
  width: 40px;
  height: 40px;
  background: #f6f7f9;
  border: 1px solid #e5e5e5;
  float: right;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .svg-icon {
    width: 20px !important;
    height: 20px !important;
  }
}
</style>
