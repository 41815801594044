<template>
  <div class="edge-capstone-upload-container" :class="isDesktopPlatform ? 'desktop-padding' : ''">
    <div class="capstone-page-header">
      <div class="page-name">
        <svg-icon icon-class="back_arrow" class-name="g-icon--small g-margin__right--medium" @click="goBack" />
        {{ !isEditMode ? 'Upload' : 'Edit' }} Capstone Project
      </div>
    </div>
    <ion-card v-if="dataReady">
      <div class="error-msg" v-show="errorMessage">
        {{ errorMessage }}
      </div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="section-title required">Capstone Project Name</div>
            <ion-input ref="name" required @ionChange="handleNameInput" v-bind:value="capstone.name"></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="section-title">Description of Your Creation</div>
            <ion-textarea
              autofocus
              rows="10"
              ref="description"
              required
              v-bind:value="capstone.description"
              @ionChange="handleDescriptionInput"
              placeholder="A value proposition is a promise of value to be delivered, communicated, and acknowledged."
            ></ion-textarea>
          </ion-col>
          <ion-col>
            <div class="section-title required">Capstone Files</div>
            <input ref="upload-input" class="upload-input" type="file" :accept="capstone.file.allowedExtensions" @change="handleClick" />
            <div class="upload-info">We encourage you to add files that show users the value and context of your project</div>
            <div @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover" class="drop-zone">
              <div class="drop-prompt">
                <ion-icon class="ion-icon-size" name="cloud-upload-outline"></ion-icon>
                <div class="drop-prompt-text">{{ capstone.file.allowedExtensions.replaceAll(',', ', ') }} (Max 100MB)</div>
              </div>
              <div>
                <p class="drop-file-text">Drop file here to upload or</p>
                <div class="upload-btn" @click="handleUpload">Choose a file</div>
              </div>
              <ion-progress-bar v-if="capstone.file.name && fileUploadStatus == 'U'" color="secondary" type="indeterminate"></ion-progress-bar>
              <ion-row class="file-details add-space-div" v-if="capstone.file.name">
                <ion-col size-xs="12" class="file-meta-data">
                  <div class="section-subheading">
                    <svg-icon icon-class="icon-file" class="g-icon--small"></svg-icon><span class="asset-zip-name">{{ capstone.file.name }}</span>
                  </div>
                  <div class="section-meta-info">
                    <span class="section-meta-text" v-if="fileUploadStatus == 'U'"> Uploading... </span>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div v-if="notificationMessage.length" class="notification">Error: {{ notificationMessage }}</div>
      <div class="submit">
        <ion-button shape="round" color="primary" class="ion-button-primary ion-text-capitalize submit-button" :disabled="isSubmitting" @click="onSubmit">{{
          capstone.rejectionReason == null ? 'Submit' : 'Update'
        }}</ion-button>
        <ion-button shape="round" class="ion-text-capitalize cancel-button" :disabled="isSubmitting" @click="goBack"> Cancel </ion-button>
      </div>
    </ion-card>
    <div class="custom-toast custom-toast-loading" v-if="loadingMessage.length > 0">
      <div style="margin-top: 2rem">
        <ion-progress-bar color="secondary" type="indeterminate"></ion-progress-bar>
      </div>
      <div>
        <span class="status-message-normal">{{ loadingMessage }}...</span>
      </div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-info" v-if="fileUploadStatus === 'U' && uploadPercentage < 100">
      <div class="status-message">
        <span class="status-message-bold">Step 1 of 2</span><span class="status-message-normal">: File upload in progress {{ uploadPercentage }}%</span>
      </div>
      <div class="cancel-btn"><ion-button class="cancel-btn-text" shape="round" color="tertiary" size="small" @click="cancelUploadAsset()">Cancel</ion-button></div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-success" v-else-if="fileUploadStatus === 'C'">
      <div class="toast-close-icon"><svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon></div>
      <div class="toast-success-icon"><svg-icon icon-class="icon_success"></svg-icon></div>
      <div class="status-message"><span class="status-message-normal">Success! Your file has been uploaded.</span></div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-info" v-else-if="fileUploadStatus === 'S' || uploadPercentage == 100">
      <div class="status-message"><span class="status-message-bold">Step 2 of 2</span><span class="status-message-normal">: File scan in progress</span></div>
      <div class="cancel-btn"><ion-button class="cancel-btn-text" shape="round" color="tertiary" size="small" @click="cancelUploadAsset()">Cancel</ion-button></div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-failed" v-else-if="fileUploadStatus === 'F'">
      <div class="toast-close-icon"><svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon></div>
      <div class="toast-failed-icon"><svg-icon icon-class="icon-alert-black"></svg-icon></div>
      <div class="status-message"><span class="status-message-normal">Your file did not upload successfully</span></div>
      <div class="cancel-btn"><ion-button class="cancel-btn-text" shape="round" color="tertiary" size="small" @click="tryAgain()">Try again</ion-button></div>
      <div class="toast-footer"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'

export default {
  name: 'CredentialCreateCapstone',
  props: {},
  data() {
    return {
      isEditMode: false,
      errorMessage: '',
      authToken: '',
      capstone: {
        name: '',
        author: '',
        description: '',
        rejectionReason: null,
        file: {
          name: '',
          file: null,
          allowedExtensions: '.zip,.gzip,.7z,.gz'
        }
      },
      uploadPercentage: 0,
      fileUploadStatus: '',
      axiosController: null,
      notificationMessage: '',
      isSubmitting: false,
      isDesktopPlatform: false,
      dataReady: false,
      loadingMessage: ''
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'name'])
  },
  created() {},
  async mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.capstone.author = this.name || (await localforage.getItem('my_name'))
    this.capstone.name = this.$route.params.title
    this.capstone.id = this.$route.params.editProjectId || await localforage.getItem('create-capstone-id') || null
    await this.setEditMode()
    this.dataReady = true
  },
  deactivated() {
    this.$destroy()
    localforage.removeItem('create-capstone-id')
  },
  methods: {
    handleNameInput(event) {
      this.capstone.name = event.target.value
    },
    handleDescriptionInput(event) {
      this.capstone.description = event.target.value
    },
    async setEditMode() {
      if (this.capstone.id) {
        this.isEditMode = true
        const existingCapstone = await this.$learnAdmin.getCapstoneProjectById({ token: this.authToken, id: this.capstone.id })
        this.capstone.id = existingCapstone.id
        this.capstone.name = existingCapstone.name
        this.capstone.author = existingCapstone.creatorUser.firstName
        this.capstone.description = existingCapstone.shortDescription
        this.capstone.rejectionReason = {}.hasOwnProperty.call(existingCapstone, 'rejectReason') && existingCapstone.rejectReason !== null && existingCapstone.rejectReason.length > 0  ? existingCapstone.rejectReason : null
        if (existingCapstone.supportingFile) {
          this.capstone.file.name = existingCapstone.supportingFile
          const blob = await this.$learnAdmin.getFileForCapstoneProject({ token: this.authToken, id: this.capstone.id })
          let file = new File([blob], existingCapstone.supportingFile, { lastModified: new Date().getTime(), type: 'application/zip' })
          this.capstone.file.file = file
        }
      }
    },
    tryAgain() {
      this.fileUploadStatus = ''
      this.capstone.file.name = ''
      this.capstone.file.file = null
    },
    async saveCapstoneProject() {
      const myCapstone = {
        name: this.capstone.name,
        shortDescription: this.capstone.description,
        credentialId: this.$route.params.id
      }
      return await this.$learnAdmin.saveCapstoneProject({ token: this.authToken, myCapstone: myCapstone })
    },
    async editCapstoneProject() {
      const myCapstone = {
        name: this.capstone.name,
        shortDescription: this.capstone.description,
        credentialId: this.$route.params.id
      }
      return await this.$learnAdmin.editCapstoneProject({ token: this.authToken, id: this.capstone.id, myCapstone: myCapstone })
    },
    async uploadFileToCapstoneProject(id) {
      this.fileUploadStatus = 'U'
      this.axiosController = new AbortController()
      const file = new FormData()
      file.append('file', this.capstone.file.file)
      const payload = { token: this.authToken, id: id, file: file }
      return await this.$learnAdmin
        .uploadFileToCapstoneProject(payload, this.axiosController, this.onUploadProgress)
        .then((res) => {
          if (res.data) {
            if (!this.capstone.id) this.capstone.id = res.data.id
            localforage.setItem('create-capstone-id', res.data.id)
            this.fileUploadStatus = 'C'
          }
          this.uploadPercentage = 0
          this.hideToastMessage()
        })
        .catch(() => {
          this.fileUploadStatus = 'F'
          this.uploadPercentage = 0
          this.hideToastMessage()
        })
    },
    hideToastMessage() {
      setTimeout(() => {
        this.fileUploadStatus = ''
      }, 5000)
    },
    async onSubmit() {
      this.isSubmitting = true
      if (this.capstone.name === '' || this.capstone.file.name === '') {
        this.isSubmitting = false
        this.errorMessage = 'Please correct missing fields'
        return
      }
      if (Math.ceil(this.capstone.file.file.size / (1024 * 1024)) > 100) {
        this.notificationMessage = 'File size exceeds maximum limit. Maximum allowed file size is 100MB'
        return
      }
      const payload = { token: this.authToken, id: this.capstone.id }
      await this.$learnAdmin.submitCapstoneProject(payload)
      this.clearInputs()
      this.goBack()
    },
    clearInputs() {
      this.$refs.name.value = ''
      this.$refs.description.value = ''
      this.capstone = {
        name: '',
        author: '',
        description: '',
        file: {
          name: '',
          file: null,
          allowedExtensions: '.zip,.gzip,.7z,.gz'
        }
      }
    },
    onUploadProgress(progressEvent) {
      const { loaded, total } = progressEvent
      this.uploadPercentage = Math.floor((loaded * 100) / total)
    },
    cancelUploadAsset() {
      this.axiosController.abort()
      this.fileUploadStatus = 'F'
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      const rawFile = files[0]
      this.checkFileFormat(rawFile)
      e.stopPropagation()
      e.preventDefault()
    },
    checkFileFormat(rawFile) {
      const filenameExt = '.' + rawFile.name.split('.').pop().toLowerCase()
      // if the file has an allowed extension, clear possible previous error messages and proceed
      if (this.capstone.file.allowedExtensions.split(',').includes(filenameExt)) {
        this.notificationMessage = ``
        this.upload(rawFile)
        this.isSubmitting = false
      }
      // else notify the user and give them another chance disable the submit button in the meantime
      else {
        this.capstone.file.file = null
        this.capstone.file.name = ''
        this.notificationMessage = `Incorrect file format. Please upload a file in one of the formats listed above.`
        this.isSubmitting = true
      }
    },
    async upload(rawFile) {
      this.capstone.file.name = rawFile.name
      this.capstone.file.file = rawFile
      if (Math.ceil(this.capstone.file.file.size / (1024 * 1024)) > 100) {
        this.notificationMessage = 'File size exceeds maximum limit. Maximum allowed file size is 100MB'
        return
      }
      this.$refs['upload-input'].value = null
      let capstone = this.isEditMode || this.capstone.id ? await this.editCapstoneProject() : await this.saveCapstoneProject()
      if (!capstone || capstone.error) {
        this.isSubmitting = false
        this.notificationMessage = `Cannot submit the capstone: ${capstone.message}`
        return
      }
      this.uploadFileToCapstoneProject(capstone.id)
    },
    handleClick(e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.checkFileFormat(rawFile)
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload() {
      this.$refs['upload-input'].click()
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.edge-capstone-upload-container {
  h3 {
    font-size: 2rem;
  }
  padding: 0.5rem 1rem;
  text-align: left;
  ion-card {
    padding: 1rem;
    color: var(--ion-color-black);
  }
  ion-row {
    margin-bottom: 1rem;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0rem;
    }
  }
  ion-col {
    padding-right: 20px;
  }
  ion-textarea,
  ion-input {
    padding: 0.5rem;
    background: var(--ion-color-medium-light);
    border-radius: 3px;
    margin-bottom: 0.5rem;
  }
}

.capstone-page-header {
  margin: 0.5rem 0;
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--ion-color-post-gray);
  .page-icon {
    width: 35px;
    height: 35px;
  }
  .page-name {
    font-size: 25px;
    font-weight: bold;
  }
}

.section-title {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Futura PT Demi';
  margin-bottom: 0.2rem;
}

.section-subtitle {
  font-size: 1rem;
  margin: 0.5rem 0rem;
}

.submit {
  text-align: right;
}

.upload-input {
  display: none;
  z-index: -9999;
}
.upload-info {
  font-size: 16px;
}
.drop-prompt {
  .drop-prompt-text {
    font: normal normal bold 0.75rem/1.375rem Arial;
  }
}
.drop-file-text {
  font: normal normal normal 14px/22px Arial;
  padding: 0;
  margin: 0;
  color: #5c5c5c;
}
.upload-btn {
  color: #1f3370;
  font: normal normal normal 16px/22px 'Futura PT Demi';
  text-decoration: none;
  cursor: pointer;
}

.ion-icon-size {
  font-size: 24px;
  cursor: pointer;
}
.drop-zone {
  text-align: center;
  border: 1px dashed #efefef;
  padding: 1.5rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.notification {
  padding-left: 2rem;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--ion-color-status);
}

.text-note {
  color: var(--ion-color-role-grey);
  text-align: right;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.page-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.2rem;
}

.desktop-padding {
  padding: 0.5rem 2rem;
}

.submit-button {
  font-family: 'Futura PT Demi';
  --color: var(--ion-color-tertiary);
}

ion-button {
  --box-shadow: none;
}

.cancel-button {
  --background: var(--ion-color-white);
  --color: var(--ion-color-tertiary);
  --box-shadow: none;
  font-family: 'Futura PT Demi';
}
.custom-toast {
  width: 26.25rem;
  height: 8.5rem;
  // box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  .toast-footer {
    height: 0.5rem;
    /* UI Properties */
    // box-shadow: 0px 0.3125rem 0.625rem #0000003d;
    // width: 100%;
    position: fixed;
    bottom: 0px;
    width: 26.25rem;
  }
  .cancel-btn {
    border: 1p solid red;
    width: 3.625rem;
    height: 1.68rem;
    margin-left: 5.43rem;
    margin-top: 1.05rem;
    .cancel-btn-text {
      font: normal normal normal 0.75rem Futura PT Demi !important;
      text-transform: none !important;
    }
  }
  .status-message {
    margin-top: 36px !important;
    margin-left: 5.43rem !important;
    text-align: left;
    .status-message-bold {
      font: normal normal bold 16px/22px Arial !important;
      color: #000000;
    }
    .status-message-normal {
      font: normal normal normal 16px/22px Arial !important;
      color: #000000;
    }
  }
  .toast-close-icon {
    height: 1.5rem;
    width: 1.5rem;
    float: right;
    margin: 1rem 1rem 0 0;
    cursor: pointer;
    svg {
      width: 1.25rem !important;
      height: 1.25rem !important;
    }
  }
}
.custom-toast-success {
  background: #d9f8f3 0% 0% no-repeat padding-box;
  width: 26.25rem;
  height: 6.5rem;
  .toast-footer {
    background: #00d1b0 0% 0% no-repeat padding-box;
  }
  .toast-success-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
.custom-toast-info {
  background: #fff4df 0% 0% no-repeat padding-box;
  .toast-footer {
    background: #ffb92e 0% 0% no-repeat padding-box;
  }
}
.custom-toast-failed {
  background: #fae5dc 0% 0% no-repeat padding-box;
  // box-shadow: #0000003d;
  .toast-footer {
    background: #d04910 0% 0% no-repeat padding-box;
  }
  .toast-failed-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
.asset-zip-name {
  padding-left: 0.5rem;
  font: normal normal normal 1rem/1.25rem Arial;
}
.file-details {
  width: 100%;
  .file-meta-data {
    background-color: #efefef80;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4rem;
    padding: 0.5rem;
    .section-subheading {
      color: #000000;
      display: flex;
      align-items: center;
      font: normal normal normal 1rem/1.25rem Arial;
      h5 {
        font: normal normal normal 1rem/1.25rem Arial;
        margin: 0 0 0 0.2rem;
      }
    }
    .section-meta-info {
      display: flex;
      align-items: center;
      .section-meta-text {
        color: #5c5c5c;
        font-size: 0.75rem;
      }
      .cancel-button,
      .download-button {
        display: flex;
        align-items: center;
        margin-left: 0.75rem;
        cursor: pointer;
      }
    }
  }
}
</style>
