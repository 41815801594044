<template>
  <div class="tracks-modal">
    <div>
      <svg-icon icon-class="filter-close" class="g-icon--small close g-clickable tracks-modal__close" @click="dismiss"></svg-icon>
      <ion-row class="ion-align-items-center ion-padding-top">
        <ion-col size="2">
          <svg-icon icon-class="icon-success" class="g-icon--small"></svg-icon>
        </ion-col>
        <ion-col v-if="options.title != ''">
          <p class="tracks-modal__subtitle">{{ options.title }}</p>
        </ion-col>
        <ion-col v-else>
          <p class="tracks-modal__subtitle">
            Content successfully added to <a @click.stop="goToTrack" class="tracks-modal__link">"{{ options.track.name }}"</a>
          </p>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>
  
  <script>
import { popoverController } from '@modus/ionic-vue'
export default {
  name: 'UGTrackDeleteOverlay',
  props: {
    options: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  async activated() {},
  methods: {
    selectTrack(option) {
      popoverController.dismiss(option)
    },
    dismiss() {
      popoverController.dismiss()
    },
    goToTrack() {
      const route = { name: 'UGTrack', params: { id: this.options.track.guid, pgrTrk: true } }
      this.dismiss()
      this.$router.push(route)
    }
  }
}
</script>
  
  <style lang="scss" scoped>
@include block('tracks-modal') {
  width: 350px;
  padding: 16px;

  @include element('subtitle') {
    font-family: 'Arial';
    font-size: 16px;
    color: var(--ion-color-black);
    margin: 0px 0px 0px 0px;
  }
  @include element('link') {
    color: var(--ion-color-tertiary);
    text-decoration: underline;
    font-family: 'Arial';
    font-size: 16px;
    cursor: pointer;
  }

  @include element('close') {
    float: right;
  }
}
</style>