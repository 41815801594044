<template>
  <div class="edge_bookmark__content">
    <ion-spinner v-if="!dataReady" color="#1f3370"></ion-spinner>
    <div v-if="dataReady && bookmarks.length >= 0" class="edge_bookmark__library-list main-content" :class="{ 'ion-padding-horizontal': this.$platform === 'Desktop' }">
      <h1 v-if="this.$platform === 'Desktop'" class="desktop-page-name" style="margin-top: 0px">bookmarks</h1>
      <div v-if="dataReady && bookmarks.length" :style="this.$platform != 'Desktop' ? 'padding-top: 1rem;' : ''">
        <UniversalCard v-for="(item, index) in bookmarks" :key="item.id" :item="item" :index="index" :itemsLength="bookmarks.length" tab="bookmarks" />
        <div v-if="data.totalPages > 0" class="bookmarked-pagination">
          <paginate
            v-model="currentPage"
            :page-count="data.totalPages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="onPageClick"
            :container-class="'pagination'"
            :page-class="'pagination-item'"
            :page-link-class="'pagination-item-link'"
            :prev-text="'&lsaquo;'"
            :prev-class="'pagination-nav'"
            :prev-link-class="'pagination-nav-link'"
            :next-text="'&rsaquo;'"
            :next-class="'pagination-nav'"
            :next-link-class="'pagination-nav-link'"
            :hide-prev-next="true"
          />
        </div>
      </div>
      <div v-if="dataReady && !bookmarks.length" class="bookmarked-empty-container">
        <div class="bookmarked-empty-container-grid">
          <div>
            <div class="title">No bookmarks? No worries.</div>
            <div class="sub-title">You can bookmark any ProEdge content you find along your journey, and it shows up here for easy access.</div>
            <div class="action-btns">
              <a href="#" class="learning-plan-btn" @click="goToLearningPlan">Learning Plan</a>
              <a href="#" class="explore-btn" @click="goToExplore">Explore</a>
            </div>
          </div>
        </div>
        <div class="bookmarked-empty-image">
          <img src="@/assets/images/no-bookmark.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import localforage from 'localforage'
import UniversalCard from '@/components/organisms/UniversalCard'

export default {
  name: 'BookmarkList',
  components: {
    UniversalCard
  },
  computed: {
    ...mapGetters(['auth_token', 'gcs_token', 'enabledFeatureFlags'])
  },
  data() {
    return {
      data: {},
      bookmarks: [],
      authToken: '',
      assetMediaUrl: '',
      gcsToken: null,
      currentPage: 1,
      dataReady: false,
      signedUriEnabled: false
    }
  },
  async activated() {
    this.assetMediaUrl = process.env.VUE_APP_APPLY_MEDIA_BASE_URI
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
    this.gcsToken = this.gcs_token || (await localforage.getItem('my_gcs_token'))
    const enabledFeatureFlags = await localforage.getItem('enabled-feature-flags')
    this.signedUriEnabled = enabledFeatureFlags['learn_autographer-uri-enabled'] ? enabledFeatureFlags['learn_autographer-uri-enabled'] : false
    this.currentPage = 1
    await this.getAllBookmarks()
    this.setNavBar({
      explore: {
        isShown: true,
        headerTitle: 'Library',
        hasTabs: true,
        hasBackButton: false
      }
    })
    await this.setupPagination(true)
  },
  methods: {
    ...mapActions('user', ['setNavBar']),
    async validateGCSToken() {
      const now = new Date().toUTCString()
      const exp = this.gcsToken && {}.hasOwnProperty.call(this.gcsToken, 'tokenValue') ? new Date(this.gcsToken.expirationTime).toUTCString() : null
      if (!exp || now > exp) {
        await this.$learnAdmin.getGCSToken({ token: this.authToken }).then((token) => {
          this.gcsToken = token
          this.$store.dispatch('user/setGCSToken', token).then(() => {})
        })
      }
    },
    async getMedia(uri) {
      if (!this.signedUriEnabled || uri.includes('amazonaws.com')) return uri
      const signedURI = await this.$learnAdmin.getSignedURI({ url: uri, token: this.authToken })
      return signedURI.url ? signedURI.url : uri
    },
    goToLearningPlan() {
      this.$router.push({ name: 'MyLearning', params: { type: 'required', isNotFromDeepLink: true } }).catch(() => {})
    },
    goToExplore() {
      this.$router.push({ name: 'Explore' }).catch(() => {})
    },
    async onPageClick(page) {
      this.currentPage = page
      await this.setupPagination(false)
      await this.getAllBookmarks()
      await this.setupPagination(true)
    },
    async getAllBookmarks() {
      this.dataReady = false
      this.bookmarks = []
      this.data = await this.$learnAdmin.getAllBookmarks({ token: this.authToken, pageNo: this.currentPage })
      if (!{}.hasOwnProperty.call(this.data, 'content')) {
        this.data.content = []
      }
      let ratingDetailsArray = []
      if (this.data && this.data.content) {
        ratingDetailsArray = (await this.determineContentRating(this.data.content)) || []
      }
      await Promise.allSettled(
        this.data.content.map(async (bookmark, index) => {
          if (bookmark.bookMarkItem) {
            if (bookmark.assetType === 'credentials') {
              bookmark.bookMarkItem.format = { name: 'credential' }
              bookmark.bookMarkItem.provider = { name: 'proedge' }
            } else if (bookmark.assetType === 'track') {
              const item = { ...bookmark.bookMarkItem.track }
              item.meta = bookmark.bookMarkItem.meta
              item.content = bookmark.bookMarkItem.content
              item.format = { name: 'track' }
              item.provider = { name: '' }
              item.title = item.name
              bookmark.bookMarkItem = item
            } else if (bookmark.assetType === 'automation') {
              bookmark.bookMarkItem.id = bookmark.bookMarkItem.automationId
              bookmark.bookMarkItem.format = { name: 'asset' }
              bookmark.bookMarkItem.launchBtn =
                {}.hasOwnProperty.call(bookmark.bookMarkItem, 'assetReference') && bookmark.bookMarkItem.assetReference.externalAssetLink ? 'Open' : 'Download'
              let logo =
                {}.hasOwnProperty.call(bookmark.bookMarkItem, 'provider') &&
                {}.hasOwnProperty.call(bookmark.bookMarkItem.provider, 'logo') &&
                {}.hasOwnProperty.call(bookmark.bookMarkItem.provider.logo, 'uri')
              bookmark.bookMarkItem.provider = {
                guid: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'provider') && bookmark.bookMarkItem.provider.guid ? bookmark.bookMarkItem.provider.guid : '',
                name: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'provider') && bookmark.bookMarkItem.provider.name ? bookmark.bookMarkItem.provider.name : '',
                avatarImage: logo ? bookmark.bookMarkItem.provider.logo.uri : ''
              }
              bookmark.bookMarkItem.type = {
                name: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'inventoryType') ? bookmark.bookMarkItem.inventoryType.name : '',
                src: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'inventoryType') ? `${this.assetMediaUrl}media-container/${bookmark.bookMarkItem.inventoryType.image}` : ''
              }
              bookmark.bookMarkItem.title = bookmark.bookMarkItem.name
              bookmark.bookMarkItem.synopsis = ''
              bookmark.bookMarkItem.applications = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'applications')
                ? await bookmark.bookMarkItem.applications.map((application) => {
                    return { name: application.name, src: `${this.assetMediaUrl}media-container/${application.image}` }
                  })
                : []
              bookmark.bookMarkItem.image = {
                uri: {}.hasOwnProperty.call(bookmark.bookMarkItem, 'inventoryType') ? this.fetchAssetThumbnail(bookmark.bookMarkItem.inventoryType.name) : ''
              }
            }
            if (bookmark.bookMarkItem.format.name.toLowerCase() == 'track') {
              bookmark.bookMarkItem.launchBtn = bookmark.bookMarkItem.progress > 0 && bookmark.bookMarkItem.progress < 100 ? 'Resume' : 'Launch'
              bookmark.bookMarkItem.image = { uri: require('@/assets/images/track.png') }
              bookmark.bookMarkItem.duration =
                bookmark.bookMarkItem.track.duration.value < 60
                  ? `${bookmark.bookMarkItem.track.duration.value} min`
                  : `${Math.round(bookmark.bookMarkItem.track.duration.value / 60)} hrs`
            }
            if (bookmark.bookMarkItem.format.name.toLowerCase() != 'asset' && bookmark.bookMarkItem.format.name.toLowerCase() != 'track') {
              bookmark.bookMarkItem.launchBtn = bookmark.bookMarkItem.progress > 0 && bookmark.bookMarkItem.progress < 100 ? 'Resume' : 'Launch'
              bookmark.bookMarkItem.image.uri = bookmark.bookMarkItem.image.uri ? await this.getMedia(bookmark.bookMarkItem.image.uri) : null
              bookmark.bookMarkItem.duration =
                bookmark.bookMarkItem.duration < 3600 ? `${Math.round(bookmark.bookMarkItem.duration / 60)} min` : `${Math.round(bookmark.bookMarkItem.duration / 3600)} hrs`
            }
            bookmark.bookMarkItem.publishedAt = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'publishedAt') ? bookmark.bookMarkItem.publishedAt : ''
            bookmark.bookMarkItem.requiredBy = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'requiredBy') ? bookmark.bookMarkItem.requiredBy : ''
            bookmark.bookMarkItem.content = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'content') ? bookmark.bookMarkItem.content : ''
            bookmark.bookMarkItem.meta = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'meta') ? bookmark.bookMarkItem.meta : ''
            bookmark.bookMarkItem.rating =
              ratingDetailsArray.length > 0 && ratingDetailsArray.some((rating) => rating['entityId'] === bookmark.bookMarkItem.id)
                ? ratingDetailsArray.filter((rating) => rating.entityId == bookmark.bookMarkItem.id)[0]?.avgRating
                : 0
            bookmark.bookMarkItem.level =
              bookmark.bookMarkItem.format.name.toLowerCase() === 'credential'
                ? {}.hasOwnProperty.call(bookmark.bookMarkItem, 'level')
                  ? bookmark.bookMarkItem.level
                  : ''
                : {}.hasOwnProperty.call(bookmark.bookMarkItem, 'courseLevels')
                ? bookmark.bookMarkItem.courseLevels[0]
                : ''
            bookmark.bookMarkItem.tracks = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'meta')
              ? {}.hasOwnProperty.call(bookmark.bookMarkItem.meta, 'tracks')
                ? bookmark.bookMarkItem.meta.tracks
                : []
              : []
            bookmark.bookMarkItem.selfProgressDeclaration = {}.hasOwnProperty.call(bookmark.bookMarkItem, 'selfProgressDeclaration')
              ? bookmark.bookMarkItem.selfProgressDeclaration
              : false
            bookmark.bookMarkItem.isDateExpired = bookmark.bookMarkItem.requiredBy ? (new Date() - new Date(bookmark.bookMarkItem.requiredBy) > 0 ? true : false) : false
            this.bookmarks[index] = bookmark.bookMarkItem
          }
        })
      )
      this.dataReady = true
    },
    determineContentRating(contentFilterValue) {
      var contentIdList = []
      contentFilterValue.map((item) => {
        item.bookMarkItem.inventoryCategory?.toLowerCase() === 'asset' ? contentIdList.push(item.assetId) : contentIdList.push(item.bookMarkItem.id)
      })
      if (contentIdList && contentIdList.length > 0) {
        const contentPayload = {
          token: this.authToken,
          body: contentIdList
        }
        return this.$learnAdmin.getContentRatingById(contentPayload)
      }
    },
    fetchAssetThumbnail(type) {
      const img =
        type.toLowerCase() === 'bot'
          ? 'bot'
          : type.toLowerCase() === 'microbot'
          ? 'microbot'
          : type.toLowerCase() === 'visualization'
          ? 'visualization'
          : type.toLowerCase() === 'workflow'
          ? 'workflow'
          : type.toLowerCase() === 'template'
          ? 'template'
          : type.toLowerCase() === 'connector'
          ? 'connector'
          : 'application'
      return require(`@/views/asset/components/images/thumb-asset-${img}.png`)
    },
    async setupPagination(isAddEvent = true) {
      const elements = document.getElementsByClassName('pagination').length ? document.getElementsByClassName('pagination')[0].childNodes : []
      if (elements) {
        await elements.forEach((element) => {
          if (element.childNodes.length && element.className.includes('pagination')) {
            isAddEvent ? element.addEventListener('click', this.onPaginationItemClick, { once: true }) : element.removeEventListener('click', this.onPaginationItemClick)
          }
        })
      }
    },
    onPaginationItemClick(e) {
      let page = this.currentPage
      if (e.target.textContent != '…') {
        page = e.target.textContent == '‹' ? page - 1 : e.target.textContent == '›' ? page + 1 : parseInt(e.target.textContent)
        this.onPageClick(page)
      }
    }
  }
}
</script>

<style lang="scss">
.bookmarked-pagination span {
  font-size: 14px;
  padding: 0px 2px;
}

.bookmarked-pagination {
  border-top: 2px solid #e0e1e1;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.pagination-item {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

li.pagination-nav {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}

.pagination-item-link {
  color: var(--ion-color-post-gray);
  font-size: 14px !important;
  vertical-align: middle;
}

.pagination-nav-link {
  position: relative;
  color: #5c5c5c;
  font-size: 3rem !important;
  vertical-align: middle;
  font-family: 'Futura PT Book';
}

li.disabled {
  border: none;
  cursor: default;
  .pagination-item-link {
    cursor: default;
  }
}

li.active {
  border: 2px solid #1dd1b0;
  border-radius: 4px;
  background: white;
  .pagination-item-link {
    color: #000000;
  }
}

li:hover {
  background: #e0e1e1;
}

li .tooltiptext {
  visibility: hidden;
  width: max-content;
  height: 30px;
  background-color: #efefef;
  color: #000000de;
  text-align: center;
  padding: 7px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -20px;
  font-size: 12px;
  font-family: 'Futura PT Demi';
}

.li:hover .tooltiptext {
  visibility: visible;
}
</style>

<style lang="scss" scoped>
@include block('edge_bookmark') {
  @include element('no-content') {
    padding: 15% 0 0;
  }
  @include element('library-list') {
    padding: 2rem 3.8rem;
    background: inherit;

    img {
      width: 125px;
    }
  }

  @include element('none-available') {
    text-align: left;
    padding: 0 2rem;
  }

  @include element('content') {
    background-color: map-get($edge-colors, 'disabled');

    ion-spinner {
      margin-left: 0%;
      margin-top: 50px;
    }

    ion-card {
      margin: 10px 0;
    }

    ion-label {
      padding: 0 30px;
    }
  }
}

ion-button {
  --box-shadow: none;
}

.bookmark-item {
  background: var(--ion-color-white) !important;
  margin-bottom: 0.5rem !important;
}

.bookmarked-empty-container {
  display: flex;
  padding: 3rem 0rem 0rem 1rem;
}

.bookmarked-empty-container-grid {
  display: grid;
  width: 60%;

  div {
    text-align: left;
  }
}

.bookmarked-empty-image {
  width: 40%;
  img {
    width: auto;
  }
}

.title {
  text-align: left;
  font-size: 3rem;
  font-family: 'Futura PT Demi';
  color: var(--ion-color-tertiary);
}

.sub-title {
  margin-top: 1rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Arial';
  color: var(--ion-color-medium);
}

.action-btns {
  display: flex;
  margin-top: 1.5rem;
  align-content: left;
}

.learning-plan-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  margin-right: 1rem;
  --ion-color-primary-contrast: var(--ion-color-tertiary);
  text-decoration: none;
  background-color: var(--ion-color-primary);
  padding: 0.6rem 1.65rem;
  border-radius: 2rem;
  color: var(--ion-color-tertiary);
  letter-spacing: 0.8px;
  &:hover {
    filter: brightness(90%);
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}

.explore-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  --ion-color-primary: var(--ion-color-tertiary);
  --ion-color-primary-contrast: var(--ion-color-white);
  text-decoration: none;
  background-color: var(--ion-color-tertiary);
  padding: 0.6rem 1.65rem;
  border-radius: 2rem;
  color: var(--ion-color-white);
  letter-spacing: 0.8px;
  &:hover {
    filter: brightness(115%);
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1024px) {
  .bookmarked-empty-container-grid {
    width: 75%;
  }

  .bookmarked-empty-image {
    width: 25%;
    img {
      width: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .bookmarked-empty-container-grid {
    width: 85%;
  }

  .bookmarked-empty-image {
    width: 15%;
    img {
      width: auto;
    }
  }
}

@media screen and (max-width: 484px) {
  @include block('edge_bookmark') {
    @include element('library-list') {
      padding: 0 1rem 1rem;
    }
  }
}

@media screen and (max-width: 414px) {
  .bookmarked-empty-container {
    display: flex;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .bookmarked-empty-container-grid {
    width: 100%;
    gap: 2rem;
    margin: 0rem 0.5rem;
  }

  .bookmarked-empty-image {
    display: none;
  }
}
</style>
