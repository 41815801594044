<template>
  <div @click="onClick" style="cursor: pointer">
    <img v-if="media.type === 'image'" :src="media.src" alt="" />
    <div v-if="media.type === 'video'" class="video-thumbnail">
      <video id="video-preview" v-if="isDesktopPlatform" preload="auto" height="100%" width="100%">
        <source :src="media.src + '#t=0.5'" type="video/mp4" />
      </video>
      <video id="video-preview" v-else controls poster preload="auto" height="100%" width="100%"  aria-label="watch video">
        <source :src="media.src + '#t=0.5'" type="video/mp4" />
      </video>
      <div v-if="isDesktopPlatform" class="video-control"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssetMediaPreview',
  components: {},
  props: {
    media: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  methods: {
    onClick() {
      this.$emit('media-selected')
    }
  }
}
</script>

<style lang="scss" scoped>
.video-thumbnail {
  position: relative;
  display: table;
  width: auto;
  width: 100%;
}

.video-control {
  background-image: url('~@/assets/images/media-play-button.png');
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}
</style>