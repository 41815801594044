var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recommend-tabs-container" }, [
    _c("div", { staticClass: "recommend-label" }, [
      _c("span", { staticClass: "recommend-label-title" }, [_vm._v("Skills")]),
      _c(
        "span",
        {
          staticClass: "recommend-count",
          class:
            this.$platform === "Desktop"
              ? "recommend-count-desktop"
              : "recommend-count-mobile"
        },
        [_vm._v(_vm._s(_vm.recommendation.length))]
      ),
      _c(
        "div",
        {
          staticClass: "addskill-icon g-clickable",
          class:
            this.$platform === "Desktop"
              ? "addskill-icon-desktop"
              : "addskill-icon-mobile",
          on: {
            click: function($event) {
              return _vm.openSkillModal()
            }
          }
        },
        [
          _c("svg-icon", {
            staticClass: "addskill-icon-svg",
            attrs: { "icon-class": "add-icon" }
          })
        ],
        1
      )
    ]),
    this.$platform === "Desktop"
      ? _c(
          "div",
          { staticClass: "tabs-container" },
          _vm._l(_vm.visibleTabs, function(tab) {
            return _c(
              "div",
              {
                key: tab.tidx,
                staticClass: "tab",
                class: _vm.tabIndex === tab.tidx ? "selected" : "",
                on: {
                  click: function($event) {
                    return _vm.updateTabIndex(tab.tidx)
                  }
                }
              },
              [_vm._v(" " + _vm._s(tab.title) + " ")]
            )
          }),
          0
        )
      : _c("div", { staticClass: "recommend-mobile-tabs-container" }, [
          _c(
            "div",
            { staticClass: "selected-tab", on: { click: _vm.toggleTabs } },
            [
              _vm._v(" " + _vm._s(_vm.tabs[_vm.tabIndex].title) + " "),
              _c("i", { staticClass: "dropdown-arrow" })
            ]
          )
        ]),
    _vm.tabsOpen
      ? _c(
          "div",
          {
            staticClass: "overlay",
            on: {
              click: function($event) {
                _vm.tabsOpen = false
              }
            }
          },
          [
            _c(
              "ul",
              { staticClass: "tabs-selection" },
              _vm._l(_vm.visibleTabs, function(tab) {
                return _c(
                  "li",
                  {
                    key: tab.tidx,
                    class: _vm.tabIndex === tab.tidx ? "selected" : "",
                    attrs: { value: tab.title },
                    on: {
                      click: function($event) {
                        return _vm.updateTabIndex(tab.tidx)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(tab.title) + " ")]
                )
              }),
              0
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }