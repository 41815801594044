<template>
  <div id="app">
    <Layout />
    <Celebration v-if="showCelebration" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import localforage from 'localforage'
import Layout from '@/layouts/Layout'
import Celebration from '@/components/organisms/Celebration.vue'
import { Plugins } from '@capacitor/core'
import EventBus from '@/plugins/eventbus'
const { App } = Plugins
var CrossStorageHub = require('cross-storage').CrossStorageHub
import { init as initApm } from '@elastic/apm-rum'

export default {
  name: 'App',
  components: { Layout, Celebration },
  data() {
    return {
      initialX: '',
      initialTime: '',
      showCelebration: false
    }
  },
  computed: {
    ...mapGetters(['cloud_env', 'config', 'enabledFeatureFlags'])
  },
  methods: {
    ...mapActions('environment', ['setDetails', 'setUserConfig', 'setCompanyConfig', 'setFlagConfig']),
    AndroidBackBtnPressed() {
      this.$router.go(-1)
    },
    touchStart(e) {
      if (this.$router.history.current.path === '/home') return
      this.initialX = e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientX
      this.initialTime = new Date().getTime()
    },
    touchEnd(e) {
      if (this.$router.history.current.path === '/home') return
      const finalX = e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientX
      const finalTime = new Date().getTime()
      const distanceTravelled = finalX - this.initialX
      const delay = finalTime - this.initialTime
      if (distanceTravelled > 180 && delay < 500) {
        this.$router.go(-1)
      }
    },
    toggleCelebration(flag) {
      this.showCelebration = flag
    },
    //APM Integration
    async apmConfig() {
      const flags = await this.$learnAdmin.getFeatureFlags()
      let apmEnable = flags.features.filter((flag) => flag.name === 'learn_apm_enable')
      const envData = await localforage.getItem('cloudEnv')
      apmEnable = apmEnable.length ? apmEnable[0].enabled : false
      if (apmEnable && envData) {
        const apm = initApm({
          serviceName: `proedge-learn-frontend-${envData.details.mode}`,
          serverUrl: 'https://apm.npsre.proedge.pwc.com',
          serviceVersion: '',
          environment: envData.details.mode,
          distributedTracing: true,
          distributedTracingOrigins: [
            process.env.VUE_APP_LEARN_API_BASE_URI,
            process.env.VUE_APP_PLAN_API_BASE_URI,
            process.env.VUE_APP_SHARE_API_BASE_URI,
            process.env.VUE_APP_APPLY_API_BASE_URI,
            process.env.VUE_APP_SEARCH_API_BASE_URI,
            process.env.VUE_APP_TRACK_API_BASE_URI
          ]
        })
        apm.observe('transaction:start', () => {})
        apm.observe('transaction:end', () => {})
      }
    }
  },
  created() {
    CrossStorageHub.init([
      { origin: new RegExp(`${window.location.host}$`, ''), allow: ['set'] },
      { origin: /[chrome-extension://odoabpfjgbdhadhdgffknpgdomplagdm$]/, allow: ['get'] }
    ])

    let details = {
      name: 'default',
      mode: process.env.ENV,
      cloudRegion: 'default',
      cloudProvider: 'default'
    }
    this.setDetails(details)
    this.setUserConfig({})
    this.setCompanyConfig({})
    this.setFlagConfig({})

    // Event listener for Deep links
    App.addListener('appUrlOpen', async () => {
      const getLaunchUrl = await App.getLaunchUrl()
      if (getLaunchUrl.url.includes('/landing')) {
        this.$router.push('/landing')
      } else if (getLaunchUrl.url.includes('/login')) {
        this.$router.push('/login')
      } else if (
        getLaunchUrl.url.includes('course') ||
        getLaunchUrl.url.includes('credential') ||
        getLaunchUrl.url.includes('content') ||
        getLaunchUrl.url.includes('external') ||
        getLaunchUrl.url.includes('collection') ||
        getLaunchUrl.url.includes('search') ||
        getLaunchUrl.url.includes('mylearning')
      ) {
        this.$router.push(getLaunchUrl.url.replace('capacitor://localhost', ''))
      } else {
        console.log('No match found for deep link url')
      }
    })
  },
  async mounted() {
    const envData = await localforage.getItem('cloudEnv')
    if (envData) this.apmConfig()
    if (this.$platform === 'Mobile' && this.$device === 'android') {
      document.addEventListener('backbutton', this.AndroidBackBtnPressed, false)
    }
    if (this.$platform === 'Mobile' && this.$device === 'ios') {
      document.addEventListener('touchstart', this.touchStart)
      document.addEventListener('touchend', this.touchEnd)
    }
    EventBus.$on('show-celebration', this.toggleCelebration)
    EventBus.$on('close-celebration', this.toggleCelebration)
    EventBus.$on('config-apm', this.apmConfig)
  },
  beforeDestroy() {
    if (this.$platform === 'Mobile' && this.$device === 'android') {
      document.removeEventListener('backbutton', this.AndroidBackBtnPressed)
    }
    if (this.$platform === 'Mobile' && this.$device === 'ios') {
      document.removeEventListener('touchstart', this.touchStart)
      document.removeEventListener('touchend', this.touchEnd)
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

ion-card {
  margin: 10px 0;
}
</style>
