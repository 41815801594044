import axios from 'axios'

export default {
  async searchAll(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async searchAutoComplete(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getLookingForFilters(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getFilterMap(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getDurationFilter(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  }
}