var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataReady
    ? _c("div", { staticClass: "asset-submission-container main-content" }, [
        _c("div", { staticClass: "submit-asset-header-content" }, [
          _c("h2", [_vm._v("Your asset submissions")]),
          _vm.assetList.content &&
          _vm.assetList.content.length &&
          _vm.isDesktopPlatform
            ? _c(
                "a",
                {
                  staticClass: "submit-asset-btn",
                  attrs: { href: "#", shape: "round", color: "primary" },
                  on: { click: _vm.goToCreateAsset }
                },
                [_vm._v("Submit a new asset")]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "asset-list-container" }, [
          _vm.assetList.content && _vm.assetList.content.length
            ? _c(
                "div",
                [
                  _c("AssetList", {
                    attrs: {
                      assetList: _vm.assetList.content,
                      pagination: _vm.paginationSettings()
                    }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "submit-asset-no-results" }, [
                _c(
                  "div",
                  { staticClass: "submit-asset-no-results-text-content" },
                  [
                    _c("h1", [_vm._v("Ready to submit your first asset")]),
                    _c("p", [
                      _vm._v(
                        " How exciting! Get started below and we'll pass along the details to the reviewers in your organization. If approved, your asset will be available to share with your colleagues in ProEdge. "
                      )
                    ]),
                    _vm.isDesktopPlatform
                      ? _c(
                          "ion-button",
                          {
                            staticClass: "ion-button-primary-dark",
                            attrs: { shape: "round", color: "primary" },
                            on: { click: _vm.goToCreateAsset }
                          },
                          [_vm._v("Submit a new asset")]
                        )
                      : _c("h3", [
                          _vm._v(
                            "Logon to the web application to submit your first asset"
                          )
                        ])
                  ],
                  1
                ),
                _vm._m(0)
              ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "submit-asset-no-results-img-content" }, [
      _c("img", {
        staticClass: "recommend-footer-image-desktop",
        attrs: { src: require("@/assets/images/governance-empty.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }