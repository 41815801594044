var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "top",
      staticClass: "main-content",
      class: _vm.isDesktopPlatform ? "" : "non-white-background"
    },
    [
      _c(
        "ion-grid",
        { on: { click: _vm.onGridClick } },
        [
          _c("ion-row", { staticStyle: { margin: "0% 5%" } }, [
            _vm.isDesktopPlatform
              ? _c(
                  "div",
                  { staticClass: "back-btn-container", attrs: { size: "1" } },
                  [
                    _vm.isDesktopPlatform
                      ? _c(
                          "button",
                          {
                            staticClass: "back",
                            staticStyle: {
                              display: "inline-block",
                              "min-width": "70px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$router.go(-1)
                              }
                            }
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "g-icon--small",
                              attrs: { "icon-class": "icon-arrow-left" }
                            }),
                            _vm._v(" Back ")
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ion-text-start search-results-text",
                class: _vm.isDesktopPlatform ? "ion-padding-start" : ""
              },
              [
                _vm.resultsCount > 0 && _vm.resultsCount == 1
                  ? _c("p", { staticClass: "results-count-header" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatCount")(_vm.resultsCount)) +
                          " result "
                      ),
                      _vm.query
                        ? _c(
                            "span",
                            { staticClass: "results-count-header-query" },
                            [_vm._v("for " + _vm._s(_vm.query))]
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.resultsCount > 0 &&
                _vm.resultsCount > 1 &&
                _vm.resultsCount <= 10
                  ? _c("p", { staticClass: "results-count-header" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentPage * 10 - 9) +
                          "-" +
                          _vm._s(_vm.resultsCount) +
                          " of " +
                          _vm._s(_vm._f("formatCount")(_vm.resultsCount)) +
                          " results "
                      ),
                      _vm.query
                        ? _c(
                            "span",
                            { staticClass: "results-count-header-query" },
                            [_vm._v("for " + _vm._s(_vm.query))]
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.resultsCount > 0 &&
                _vm.resultsCount > 10 &&
                _vm.currentPage * 10 <= _vm.resultsCount
                  ? _c("p", { staticClass: "results-count-header" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentPage * 10 - 9) +
                          "-" +
                          _vm._s(_vm.currentPage * 10) +
                          " of " +
                          _vm._s(_vm._f("formatCount")(_vm.resultsCount)) +
                          " results "
                      ),
                      _vm.query
                        ? _c(
                            "span",
                            { staticClass: "results-count-header-query" },
                            [_vm._v("for " + _vm._s(_vm.query))]
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.resultsCount > 0 &&
                _vm.resultsCount > 10 &&
                _vm.currentPage * 10 > _vm.resultsCount
                  ? _c("p", { staticClass: "results-count-header" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentPage * 10 - 9) +
                          "-" +
                          _vm._s(_vm.resultsCount) +
                          " of " +
                          _vm._s(_vm._f("formatCount")(_vm.resultsCount)) +
                          " results "
                      ),
                      _vm.query
                        ? _c(
                            "span",
                            { staticClass: "results-count-header-query" },
                            [_vm._v("for " + _vm._s(_vm.query))]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _vm.dataReady
        ? _c(
            "div",
            {
              class: _vm.isDesktopPlatform
                ? "search-result-panel-desktop"
                : "search-result-panel-mobile"
            },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      class: _vm.isDesktopPlatform
                        ? "search-filter-desktop"
                        : "search-filter-mobile"
                    },
                    [
                      _vm.resultsCount > 0
                        ? _c("SearchFilter", {
                            attrs: {
                              resultsCount: _vm.resultsCount,
                              sideFilterData: _vm.sideFilterData,
                              filtersObj: _vm.filters,
                              selectedFilters: _vm.selectedFilters,
                              sortBy: _vm.sortBy,
                              userAccess: _vm.userAccess
                            },
                            on: {
                              "side-filter": function($event) {
                                return _vm.onSideFilterData($event)
                              },
                              "apply-filter": function($event) {
                                return _vm.onApplyFilter($event)
                              },
                              "clear-filters": _vm.clearFilters,
                              "apply-sort": function($event) {
                                return _vm.onApplySort($event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("SearchItemContainer", {
                    attrs: {
                      slot: "search-item-container",
                      query: _vm.query,
                      dataReady: _vm.searchResultsNew.dataReady,
                      data: _vm.searchResultsNew,
                      filters: _vm.filters,
                      selectedFilters: _vm.selectedFilters,
                      sortBy: _vm.sortBy
                    },
                    on: {
                      search: function($event) {
                        return _vm.onPageClickSearch($event)
                      },
                      "scroll-search-top": function($event) {
                        return _vm.scrollSearchTop()
                      }
                    },
                    slot: "search-item-container"
                  })
                ],
                1
              )
            ]
          )
        : _c(
            "div",
            {
              class: _vm.isDesktopPlatform
                ? "search-result-panel-desktop"
                : "search-result-panel-mobile"
            },
            _vm._l(5, function(i) {
              return _c("SearchSkeleton", { key: i })
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }