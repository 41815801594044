var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-filters" },
    [
      _c(
        "ion-grid",
        { staticClass: "ion-no-padding" },
        [
          _c(
            "ion-row",
            {
              staticClass: "all-filters__heading-row",
              class: _vm.$platform === "Desktop" ? "" : "header-mobile"
            },
            [
              _c("ion-col", [
                _c(
                  "button",
                  {
                    staticClass: "all-filters__filter-close",
                    class:
                      _vm.$platform === "Mobile"
                        ? "all-filters__filter-close-mobile"
                        : "",
                    attrs: { "aria-label": "close side menu" },
                    on: {
                      click: function($event) {
                        return _vm.toggleFilterBar(false, true)
                      }
                    }
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "close-icon",
                        "class-name": "all-filters__filter-close-svg"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "ion-row",
            { staticClass: "external-content-modal" },
            [
              _vm.visibility.success && !_vm.content.isComplete
                ? _c("ion-col", { attrs: { size: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "external-content-success-container" },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "icon-progress-complete" }
                        }),
                        _c(
                          "h2",
                          {
                            staticClass: "external-content-heading",
                            class:
                              _vm.$platform === "Desktop"
                                ? "external-content-heading-desktop"
                                : "external-content-heading-mobile"
                          },
                          [
                            _vm._v(" Your item was successfully saved to your"),
                            _c(
                              "button",
                              {
                                staticClass: "added-by-you",
                                class:
                                  _vm.$platform === "Desktop"
                                    ? "external-content-heading-desktop"
                                    : "external-content-heading-mobile",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleFilterBar(false, false)
                                  }
                                }
                              },
                              [_vm._v(" Added by you")]
                            ),
                            _vm._v("section! ")
                          ]
                        )
                      ],
                      1
                    ),
                    _c("p", { staticClass: "external-content-subheading" }, [
                      _vm._v(
                        "To get credit for it, don't forget to mark it as completed."
                      )
                    ]),
                    _vm.showAddToTrack
                      ? _c(
                          "div",
                          { staticClass: "action-btns" },
                          [
                            _c(
                              "ion-button",
                              {
                                staticClass: "add-to-track-btn",
                                attrs: {
                                  shape: "round",
                                  color: "primary",
                                  title: "Add to a track"
                                },
                                on: { click: _vm.onAddToTrack }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "add-icon-container" },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        height: "10px",
                                        width: "10px",
                                        position: "relative",
                                        top: "-0.1em",
                                        left: "-0.1em"
                                      },
                                      attrs: { "icon-class": "add-icon" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" Add to a track ")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm.visibility.success && _vm.content.isComplete
                ? _c("ion-col", { attrs: { size: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "external-content-success-container" },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "icon-progress-complete" }
                        }),
                        _c(
                          "h2",
                          {
                            staticClass: "external-content-heading",
                            class:
                              _vm.$platform === "Desktop"
                                ? "external-content-heading-desktop"
                                : "external-content-heading-mobile"
                          },
                          [
                            _vm._v(" Your item was successfully saved to your"),
                            _c(
                              "button",
                              {
                                staticClass: "added-by-you",
                                class:
                                  _vm.$platform === "Desktop"
                                    ? "external-content-heading-desktop"
                                    : "external-content-heading-mobile",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleFilterBar(true, false)
                                  }
                                }
                              },
                              [_vm._v(" Completed")]
                            ),
                            _vm._v("section! ")
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.showAddToTrack
                      ? _c(
                          "div",
                          { staticClass: "action-btns" },
                          [
                            _c(
                              "ion-button",
                              {
                                staticClass: "add-to-track-btn",
                                attrs: {
                                  shape: "round",
                                  color: "primary",
                                  title: "Add to a track"
                                },
                                on: { click: _vm.onAddToTrack }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "add-icon-container" },
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        height: "10px",
                                        width: "10px",
                                        position: "relative",
                                        top: "-0.1em",
                                        left: "-0.1em"
                                      },
                                      attrs: { "icon-class": "add-icon" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" Add to a track ")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _c("ion-col", { attrs: { size: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass: "external-content-heading",
                        class:
                          _vm.$platform === "Desktop"
                            ? "external-content-heading-desktop"
                            : "external-content-heading-mobile"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isEdit
                                ? "Edit external learning details"
                                : "Add external learning"
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
            ],
            1
          ),
          _vm.visibility.main && !_vm.visibility.success
            ? _c(
                "ion-grid",
                { staticClass: "ion-no-padding external-content-modal" },
                [
                  _c("ion-row", [
                    _c("div", { staticClass: "typeahead-header" }, [
                      _vm._v("Choose the content type")
                    ])
                  ]),
                  _vm._l(_vm.externalFormats, function(format) {
                    return _c(
                      "ion-row",
                      {
                        key: format.id,
                        staticClass: "ion-align-items-center content-type-pill",
                        on: {
                          click: function($event) {
                            return _vm.displayContentForm(
                              format.slug,
                              format.id
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(format.title) + " ")]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.visibility.contentType && !_vm.visibility.success
            ? _c(
                "div",
                [
                  _c("MyLearningAddExternalForm", {
                    ref: "externalForm",
                    attrs: {
                      editContentData: _vm.externalData,
                      type: _vm.visibility.contentType
                    },
                    on: { "on-save": _vm.onSave }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }