var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "related-content-container" },
    [
      _c("div", { staticClass: "content-title" }, [_vm._v("Related Content")]),
      _vm.isDesktopPlatform
        ? _c(
            "CarouselDesktop",
            {
              staticStyle: { margin: "48px 0 40px" },
              style: { "--bottom": "-30px" },
              attrs: { slidesShow: 2, responsive: false }
            },
            _vm._l(_vm.relatedContent, function(item, index) {
              return _c("UniversalCard", {
                key: item.id,
                attrs: {
                  item: item,
                  index: index,
                  itemsLength: _vm.relatedContent.length,
                  tab: _vm.tabName,
                  cardType: "carousal"
                }
              })
            }),
            1
          )
        : _c(
            "CarouselMobile",
            { staticStyle: { margin: "16px 0px" } },
            _vm._l(_vm.relatedContent, function(item, index) {
              return _c("UniversalCard", {
                key: item.id,
                attrs: {
                  item: item,
                  index: index,
                  itemsLength: _vm.relatedContent.length,
                  tab: _vm.tabName,
                  cardType: "carousal"
                }
              })
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }