<template>
  <div :class="$platform === 'Desktop' && this.$route.path === '/landing' ? 'edge-cookieNotice signup-page' : 'edge-cookieNotice signup-page footer'">
    <div v-if="$platform === 'Desktop'" class="d-flex logo">
      <nav
        id="cookieNoticeNav"
        v-if="
          (this.$route.meta.isMenuPrivacy !== true && this.$route.path !== '/landing') ||
          (this.$platform === 'Mobile' && this.$route.meta.isMenuPrivacy !== true) ||
          (this.$route.path !== '/landing' && this.$route.meta.isMenuPrivacy !== true)
        "
        class="d-flex"
      >
        <div class="logo">
          <svg-icon :style="isLoggedIn ? 'cursor: pointer;' : ''" icon-class="logo" class-name="logo--size" @click="onClickLogo" />
        </div>
      </nav>
    </div>
    <button v-if="$platform == 'Mobile'" class="back_arrow" style="z-index: 100" @click="$router.go(-1)">
      <svg-icon icon-class="thin-back-arrow" class-name="g-icon--small" />
    </button>
    <div ref="top" class="edge-cookieNotice__policy">
      <div class="ion-text-start">
        <div :class="$platform === 'Desktop' ? 'edge-cookieNotice__wrapper' : 'edge-cookieNotice__wrapper-mobile'">
          <div :class="$platform === 'Desktop' ? (this.$route.path === '/landing' ? 'scrollable-content landing' : 'scrollable-content footer') : 'scrollable-content mobile'">
            <h1 class="g-type-xlarge title" style="font-family: 'Futura PT Demi'">Cookie Information</h1>
            <p>
              This cookie information is relevant for visitors to ProEdge. For more information about how we process your personal information collected through ProEdge, please
              visit our <router-link v-if="$platform === 'Mobile'" class="privacy" :to="{ name: 'OnboardingPrivacyPolicy' }">ProEdge privacy statement</router-link
              ><a v-else href="./privacy-policy" target="_blank">ProEdge privacy statement</a>.
            </p>
            <p>
              Cookies are small text files that are placed on your device by the websites and applications that you visit. They are widely used in order to make websites and apps
              work, or work more efficiently, as well as to provide information to the owners of the site or app. The use of cookies and similar technologies is now standard for
              most websites and apps.
            </p>

            <h3>Managing cookies on your device</h3>
            <p>
              You can control and manage cookies using your browser settings as well as certain opt-out tools (see below). Please note that if you are accessing this application
              using multiple browsers or devices, you will need to manage cookies on each browser and device, as applicable. Please also note that removing or blocking cookies can
              impact your user experience and some functionality may no longer be available.
            </p>

            <h3>Using your browser to control cookies</h3>
            <p>
              Most browsers allow you to view, manage, delete and block cookies for a website or application. Be aware that if you delete all cookies then any preferences you have
              set will be lost, including the ability to opt-out from cookies as this function itself requires placement of an opt out cookie on your device. Guidance on how to
              control cookies for common browsers is linked below.
            </p>

            <ul class="no-bullet">
              <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Google Chrome</a></li>
              <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Mozilla Firefox</a></li>
              <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">MacOS Safari</a></li>
              <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Microsoft Internet Explorer</a></li>
              <li><a href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies" target="_blank">Microsoft Edge</a></li>
            </ul>

            <h3>Opt-out tools to control cookies</h3>
            <p>
              Some of the third parties that collect information from or about you on PwC sites or apps may participate in the Digital Advertising Alliance's ("DAA")
              self-regulatory program. To make choices about the use of information from or about you for online behavioral or interest-based advertising on websites (desktop and
              mobile browsers), please visit the <a href="https://optout.aboutads.info/" target="_blank">DAA</a>, and within mobile applications, you can download the DAA's
              AppChoices application from your mobile device at <a href="http://www.aboutads.info/appchoices" target="_blank">www.aboutads.info/appchoices</a> or from your device's
              app store.
            </p>
            <p>You can set your browser to block or alert you about these cookies, but some parts of the application may no longer work.</p>

            <h3>Use of cookies</h3>
            <p>
              Below is a detailed list of the cookies we use on this application. We classify the use of cookies into the following categories: (1) necessary; and (2)
              analytical/performance. You can find more information about each category in the relevant sections below. For each cookie listed below, we also note the duration of
              the cookie and identify whether it is a first party or third party cookie. Please note:
            </p>
            <p>
              <strong>'First party'</strong> cookies are directly stored by the website (or domain) you visit. These cookies allow website owners to collect analytics data,
              remember language settings, and perform other useful functions that provide a good user experience.
            </p>
            <p>
              <strong>'Third party'</strong> cookies are created by domains that are not the website (or domain) that you are visiting. These are usually used for analytics and/or
              online-advertising purposes and placed on a website through scripts or tags. A third-party cookie is accessible on any website that loads the third party server's
              code.
            </p>
            <h6><strong>Necessary cookies</strong></h6>
            <p>
              These cookies are necessary for the website to operate. Our website cannot function without these cookies and they can only be disabled by changing your browser
              preferences.
            </p>
            <table :class="$platform === 'Mobile' ? 'table-mobile' : ''">
              <tr>
                <th>Cookie Name</th>
                <th>Host</th>
                <th>Cookie Type</th>
                <th>Life Span</th>
              </tr>
              <tr>
                <td>KC_RESTART in our configuration</td>
                <td>pwc.com</td>
                <td>First Party</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>KEYCLOAK_IDENTITY</td>
                <td>pwc.com</td>
                <td>First Party</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>KEYCLOAK_IDENTITY_LEGACY</td>
                <td>pwc.com</td>
                <td>First Party</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>KEYCLOAK_LOCALE</td>
                <td>pwc.com</td>
                <td>First Party</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>KEYCLOAK_REMEMBER_ME</td>
                <td>pwc.com</td>
                <td>First Party</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>KEYCLOAK_SESSION</td>
                <td>pwc.com</td>
                <td>First Party</td>
                <td>Session</td>
              </tr>
              <tr>
                <td>ProAuthSSO</td>
                <td>pwc.com</td>
                <td>First Party</td>
                <td>18 seconds</td>
              </tr>
              <tr>
                <td>device_id</td>
                <td>everafter.ai</td>
                <td>Third Party</td>
                <td>1 Year</td>
              </tr>
              <tr>
                <td>access_token</td>
                <td>everafter.ai</td>
                <td>Third Party</td>
                <td>1 Year</td>
              </tr>
            </table>

            <h6><strong>Analytical / Performance cookies </strong></h6>
            <p>
              These cookies allow us to measure and report on website activity by tracking page visits, visitor locations and how visitors move around the site. The information
              collected does not directly identify visitors. We drop these cookies to help us analyze the data.
            </p>
            <table :class="$platform === 'Mobile' ? 'table-mobile' : ''">
              <tr>
                <th>Cookie Name</th>
                <th>Host</th>
                <th>Cookie Type</th>
                <th>Life Span</th>
              </tr>
              <tr>
                <td>_ga</td>
                <td>google.com</td>
                <td>Third Party</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>google.com</td>
                <td>Third Party</td>
                <td>24 hours</td>
              </tr>
              <tr>
                <td>_gat_UA-209584847-1</td>
                <td>google.com</td>
                <td>Third Party</td>
                <td>2 years</td>
              </tr>
            </table>

            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
export default {
  name: 'CookieNotice',
  props: {},
  data() {
    return {
      isLoggedIn: false
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  created() {},
  async mounted() {
    this.isLoggedIn = (JSON.parse(localStorage.getItem('isLoggedIn')) && (await localforage.getItem('my_access_token'))) || this.auth_token
    analytics.setVarAndTrack({ c: 'onboarding', a: 'loaded', l: 'cookie-notice' })
    this.$refs.top.scrollIntoView()
  },
  methods: {
    onClickLogo() {
      const componentName = this.isLoggedIn ? 'Home' : 'Landing'
      this.$router.push({ name: componentName }).catch(() => {})
    }
  },
  watch: {}
}
</script>

<style lang="scss" src="./components/onboarding.scss"></style>

<style lang="scss" scoped>
@include block('edge-cookieNotice') {
  .ion-button-tertiary.back {
    --ion-color-base: #ffc758 !important;
    --ion-color-contrast: #1f3370 !important;
    padding-bottom: 1px;
  }

  .scrollable-content {
    margin-top: 1rem;
    padding-top: 20px;
    overflow-y: inherit;

    a {
      color: #1f3370;
    }

    h1 {
      margin: 0 0 27px 0;
      color: #1f3370;
      font-family: 'Futura PT Demi';
    }

    h3 {
      font-family: 'Futura PT Demi';
      margin-bottom: 0px;
    }

    p {
      margin-top: 0;
    }

    div.title {
      font-size: 20px;
      font-weight: 400;
      font-family: 'Futura PT Demi';
    }

    .introduction {
      margin: 25px 0 0 0;
      padding: 0;
    }

    &.landing {
      overflow-y: scroll;
      @media screen and (max-height: 640px) {
        height: 56vh;
      }
    }

    &.footer {
      background: #fff;
      margin: 20px auto;
      padding: 20px 27px;
      height: 100% !important;
      filter: drop-shadow(0px 3px 6px #00000029);
      border-radius: 10px;
    }

    &.mobile {
      h1 {
        margin: 0 0 11px 0;
      }

      div.title {
        font-size: 16px;
        margin: 0;
      }

      .introduction {
        margin: 7px 0 0 0;
        padding: 0;
      }

      background: #fff;
      margin: 20px auto;
      padding: 20px 27px;
      height: 100% !important;
      filter: drop-shadow(0px 3px 6px #00000029);
      border-radius: 10px;
      @media screen and (min-device-width: 1200px) {
        height: 100% !important;
      }
    }
  }

  @include element('img') {
    position: absolute;
    left: 135px;
    top: -75px;
    transform: scale(0.5);
  }
  @include element('wrapper') {
    padding: 5%;
  }
  @include element('wrapper-mobile') {
    padding: 5%;
    padding-top: 32px;
    background-color: #efefef;
  }
  @include element('top-images') {
    height: 20%;
  }
  @include element('policy') {
    height: 55%;
    padding-bottom: 100px;
  }
}
@media screen and (min-device-width: 1200px) {
  @include block('edge-cookieNotice') {
    @include element('top-images') {
      margin-top: 1rem;
      margin-left: 25%;
      height: 50%;
    }

    @include element('img') {
      padding: 0;
      margin: 0;
      float: right;
      position: relative;
      left: -150px;
      width: 300px;
    }
  }
  .privacy-height {
    height: 430px;
  }
}
.d-flex {
  display: flex;
}
#cookieNoticeNav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px;
  height: 50px;
  z-index: 800;
  width: 100%;
  background: map-get($edge-colors, 'white');
  // box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  .logo {
    width: 160px;
    height: 25px;
    margin: 0.7rem 1rem;
    .logo--size {
      position: relative;
      height: 30px;
      width: 114px;
      cursor: pointer;
    }
  }
}
.nav-right-items {
  position: absolute;
  right: 40px;
  text-transform: capitalize;
  top: 3px;

  font-family: 'Futura PT Demi';
  height: 35px !important;
  width: 100px !important;
}

.privacyTitle {
  font-size: 25px;
  margin-top: 15%;
  color: var(--ion-color-tertiary) !important;
}
#footer {
  position: fixed;
  z-index: 3000;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: map-get($edge-colors, 'white');
  color: map-get($edge-colors, 'black');
  padding: 12px 30px;
  font-size: 14px;

  .logo {
    display: flex;
    float: left;
    margin-right: 10px;
    height: 20px;
  }
  .main-layout footer.footer {
    visibility: hidden !important;
  }
}

ul.no-bullet {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  width: 50%;
}

td,
th {
  border: 1px solid var(--ion-color-black);
  text-align: left;
  padding: 0.3rem 0.5rem;
}

table.table-mobile {
  tr {
    td,
    th {
      max-width: 120px;
    }
  }
}
</style>
