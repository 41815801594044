var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-index" },
    [
      _vm.isCapstone
        ? _c(
            "ion-row",
            { staticClass: "ion-align-items-center top-border" },
            [
              _c(
                "ion-col",
                {
                  staticClass:
                    "lesson-index__title ion-no-padding g-margin__right--msmall"
                },
                [_vm._v(_vm._s(_vm.lesson.title))]
              )
            ],
            1
          )
        : _c(
            "ion-row",
            { staticClass: "ion-align-items-center top-border" },
            [
              _c(
                "ion-col",
                {
                  staticClass:
                    "lesson-index__title ion-no-padding g-margin__right--msmall"
                },
                [_vm._v(_vm._s(_vm.lesson.title))]
              ),
              _c(
                "ion-col",
                {
                  attrs: { size: "1" },
                  on: {
                    click: function($event) {
                      return _vm.toggleSkillbuilder()
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    class: [
                      !_vm.showBuilder ? "" : "lesson-index__caret-transform"
                    ],
                    attrs: {
                      "icon-class": "dropdown-transcript",
                      "class-name": "g-icon--xsmall"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
      _vm.showBuilder
        ? _c(
            "div",
            _vm._l(_vm.contents, function(content, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "ion-row",
                    {
                      staticClass:
                        "lesson-index__subtitle ion-align-items-center",
                      class: {
                        "lesson-index__subtitle light": index % 2 === 0,
                        " lesson-index__subtitle": index % 2 !== 0
                      }
                    },
                    [
                      _c(
                        "ion-col",
                        {
                          staticStyle: {
                            "padding-left": "0rem",
                            "text-align": "right"
                          },
                          attrs: { size: "1" }
                        },
                        [
                          _c("Bookmark", {
                            staticClass: "bookmark",
                            attrs: {
                              assetId: content.id,
                              assetType: "credentialcontents",
                              meta: content.meta
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "ion-col",
                        { staticStyle: { "padding-left": "0.3rem" } },
                        [
                          _c("p", { staticClass: "ion-no-margin" }, [
                            _vm._v(
                              _vm._s(index + 1) + ". " + _vm._s(content.title)
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }