export default [
  {
    path: '/explore',
    name: 'Explore',
    redirect: { name: 'Recommended' },
    component: () => import('@/views/explore/Recommended'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: false,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    },
    children: [
      {
        path: 'recommended',
        name: 'Recommended',
        component: () => import('@/views/explore/Recommended'),
        meta: {
          whitelist: false,
          moduleName: 'learn',
          desktopSideMenuVisible: true,
          desktopHeaderVisible: true,
          mobileHeaderVisible: true,
          mobileFooterVisible: true,
          searchVisible: true,
          filterVisible: false,
          jumpBackInVisible: false
        }
      }
    ]
  }
]
