export default [
  {
    path: '/collection/:id',
    name: 'Collection',
    component: () => import('@/views/collection/Collection'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/ugtrack/:id',
    name: 'UGTrack',
    component: () => import('@/views/collection/UGTrack'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  }
]