<template>
  <div class="related-content-container">
    <div class="content-title">Related Content</div>
    <CarouselDesktop v-if="isDesktopPlatform" style="margin: 48px 0 40px" :style="{ '--bottom': '-30px' }" :slidesShow="2" :responsive="false">
      <UniversalCard v-for="(item, index) in relatedContent" :key="item.id" :item="item" :index="index" :itemsLength="relatedContent.length" :tab="tabName" cardType="carousal" />
    </CarouselDesktop>

    <CarouselMobile v-else style="margin: 16px 0px">
      <UniversalCard v-for="(item, index) in relatedContent" :key="item.id" :item="item" :index="index" :itemsLength="relatedContent.length" :tab="tabName" cardType="carousal" />
    </CarouselMobile>
  </div>
</template>

<script>
import CarouselMobile from '@/components/molecules/CarouselMobile'
import CarouselDesktop from '@/components/organisms/CarouselDesktop'
import UniversalCard from '@/components/organisms/UniversalCard'
export default {
  name: 'RelatedContent',
  components: { CarouselDesktop, CarouselMobile, UniversalCard },
  props: {
    relatedContent: {
      required: true,
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      isDesktopPlatform: true,
      tabName: 'relatedCourse'
    }
  },
  async created() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  async mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
   methods: {
    onClick(item) {
      let route = {}
      if (['article', 'video', 'audio', 'podcast'].includes(item.format.name.toLowerCase())) {
        route = { name: 'Content', params: { id: item.artifactId, title: item.title } }
      } else if (['course'].includes(item.format.name.toLowerCase())) {
        route = { name: 'Course', params: { id: item.artifactId, pgrTrk: false } }
      } else {
        route = { name: 'Credential', params: { id: item.artifactId, pgrTrk: false } }
      }
      this.$router.push(route).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.related-content-container {
  padding: 48px 65px;
  width: 100%;
  background-color: var(--ion-color-white);
  border-radius: 20px;

  .content-title {
    font-family: 'Futura PT Demi';
    font-size: 24px;
    color: black;
    text-align: left;
    padding-bottom: 24px;
    border-bottom: 2px solid #e0e1e1;
  }
  @media (max-width: 484px) {
    padding: 48px 20px;
  }
}
</style>