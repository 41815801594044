<template>
  <div>
    <div class="external-form-container">
      <ion-grid class="ion-no-padding external-content-modal">
        <ion-row>
          <p class="typeahead-header">{{ type | formatType }} URL *</p>
          <input
            ref="url"
            :value="url"
            :style="[isUrl.error ? { 'border-color': '#e0301e' } : {}]"
            class="url-input form-input"
            type="url"
            placeholder="https://"
            @input="onUrlUpdate"
            @blur="onFieldValidate"
            @keyup.enter="onValidateURL($event)"
          />
          <p v-if="isUrl.error" class="url-error-message">Please enter a valid URL (should begin with https:// or http://)</p>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="action-btns" v-if="!isUrl.exist">
              <ion-button shape="round" :disabled="isNextBtnDisabled" color="primary" class="next-btn" @click="onValidateURL">Next</ion-button>
            </div>
          </ion-col>
        </ion-row>
        <div v-if="isUrl.exist">
          <ion-row>
            <p class="typeahead-header">Title *</p>
            <input
              ref="title"
              :value="fields.title"
              name="title"
              class="url-input form-input"
              type="text"
              placeholder="Title of the Content"
              @input="onFieldUpdate"
              @blur="onFieldValidate"
            />
          </ion-row>
          <ion-row>
            <p class="typeahead-header">Description</p>
            <textarea
              ref="description"
              :value="fields.synopsis"
              name="synopsis"
              rows="4"
              class="form-input"
              placeholder="Description of the content (optional)"
              @input="onFieldUpdate"
            ></textarea>
          </ion-row>
          <ion-row>
            <p class="typeahead-header">
              Source *
              <span class="typeahead-tooltip">
                <img src="@/assets/images/svgs/icon-info.svg" class="info-icon" alt="Info" />
                <span class="typeahead-tooltiptext">E.g. TED, YouTube, Medium, LinkedIn Learning</span>
              </span>
            </p>
            <input
              ref="source"
              :value="fields.source"
              name="source"
              class="url-input form-input"
              type="text"
              placeholder="Name of the content source"
              @input="onFieldUpdate"
              @blur="onFieldValidate"
            />
          </ion-row>
          <ion-row>
            <p class="typeahead-header">
              Duration *
              <span class="typeahead-tooltip">
                <img src="@/assets/images/svgs/icon-info.svg" class="info-icon" alt="Info" />
                <span class="typeahead-tooltiptext">A good estimate for articles is 1 minute per 200 words. Provide your best guess for longer courses.</span>
              </span>
            </p>
            <div class="duration-input-container">
              <input
                ref="durationHour"
                :value="duration.hour"
                name="durationHour"
                class="duration-input form-input"
                type="number"
                min="0"
                @input="onHourUpdate"
                @blur="onFieldValidate"
              />
              Hours
              <input
                ref="durationMinute"
                :value="duration.minute"
                name="durationMinute"
                class="duration-input form-input"
                type="number"
                min="0"
                @input="onMinuteUpdate"
                @blur="onFieldValidate"
              />
              Minutes
            </div>
          </ion-row>
          <ion-row style="flex-direction: column">
            <p class="typeahead-header">Skills</p>
            <div class="search-wrapper">
              <ion-searchbar
                placeholder="Add new skill"
                class="skills-search"
                ref="searchBar"
                @ionChange="handleSearchInput"
                :debounce="$platform === 'Mobile' ? '500' : '250'"
              ></ion-searchbar>
              <div class="skills-list-container">
                <ion-scroll class="scrollable">
                  <ion-list v-if="showDropDownList" class="search-skills-list" lines="none">
                    <ion-item v-for="skill of searchedSkills" class="search-list-item" :key="skill.name" @click="addSkills($event, skill)">{{ skill.name }}</ion-item>
                  </ion-list>
                </ion-scroll>
              </div>
              <div class="existing-skills-list">
                <SkillsList v-if="skills && skills.length" :from="'external-form'" :skills="skills" :isSkillRemoveable="false" :key="skills.length"></SkillsList>
              </div>
            </div>
          </ion-row>
        </div>
      </ion-grid>
    </div>
    <ion-grid v-if="isUrl.exist" class="ion-no-padding external-content-modal">
      <ion-row class="footer-container">
        <ion-col v-if="!editContentData" class="ion-align-self-center">
          <ion-checkbox :checked="fields.isComplete" :value="fields.isComplete" class="complete-checkbox" color="tertiary" mode="md" @ionChange="onMarkComplete($event)" />
          <span class="complete-text">Mark completed</span>
        </ion-col>
        <ion-col class="ion-align-self-center">
          <div class="ion-float-end">
            <ion-button
              :disabled="isSaveBtnDisabled"
              shape="round"
              color="primary"
              class="ion-button-primary save-button-primary"
              :class="editContentData ? 'ion-float-right' : ''"
              @click="onSave"
              >Save
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { validURL } from '@/utils/validate.js'
import localforage from 'localforage'
import SkillsList from '@/components/organisms/SkillsList'
export default {
  name: 'MyLearningAddExternalForm',
  components: { SkillsList },
  props: {
    type: {
      type: String,
      required: true,
      default: () => {
        return ''
      }
    },
    editContentData: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      url: '',
      isNextBtnDisabled: true,
      isSaveBtnDisabled: true,
      isUrl: {
        exist: false,
        error: false
      },
      fields: {
        title: '',
        synopsis: '',
        source: '',
        duration: null,
        isComplete: false
      },
      duration: {
        hour: 0,
        minute: 0
      },
      showDropDownList: false,
      searchedSkills: [],
      authToken: '',
      skills: []
    }
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    this.$eventBus.$on('rm-ext-skill', this.deleteExtSkill)
    document.addEventListener('click', this.clearSearch)
    if (this.editContentData) {
      const { durationInHours, durationInMinutes } = this.formatDuration(this.editContentData.duration)
      const { content, title, synopsis, source, skills } = this.editContentData
      this.isUrl.exist = true
      this.url = content.contentUri
      this.fields.title = title
      this.fields.synopsis = synopsis === 'No description provided.' ? '' : synopsis
      this.fields.source = source
      this.duration.hour = parseInt(durationInHours)
      this.duration.minute = parseInt(durationInMinutes)
      ;(this.isSaveBtnDisabled = false), (this.skills = skills || [])
    }
    this.authToken = await localforage.getItem('my_access_token')
  },
  filters: {
    formatType(t) {
      return t[0].toUpperCase() + t.substr(1)
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clearSearch)
  },
  methods: {
    onUrlUpdate($event) {
      this.url = $event.target.value
      this.isNextBtnDisabled = !validURL(this.url)
      this.isUrl.error = !validURL(this.url)
      this.onFieldValidate($event)
    },
    onValidateURL(e) {
      if (!this.isNextBtnDisabled) {
        e.preventDefault()
        this.isUrl.exist = true
        this.$emit('show-form-footer', true)
      }
    },
    deleteExtSkill(e) {
      this.skills = this.skills.filter((skill) => skill.guid !== e)
    },
    clearSearch(event) {
      if (
        event.target &&
        !event.target._prevClass?.includes('search-list-item') &&
        !event.target._prevClass?.includes('skills-list-container') &&
        !event.target._prevClass?.includes('search-skills-list') &&
        !event.target.className?.includes('searchbar-input')
      ) {
        this.closeList()
      }
    },
    async handleSearchInput(event) {
      const skill = event.target.value
      if (skill.length > 2) {
        const foundSkills = await this.$learnAdmin.searchSkillByName({ token: this.authToken, skill: encodeURIComponent(skill) })
        this.searchedSkills = []
        this.searchedSkills = foundSkills.map((foundSkill) => {
          if (!this.skills.some((x) => x.guid === foundSkill.id)) {
            return {
              name: foundSkill.title,
              guid: foundSkill.id
            }
          }
        })
        this.searchedSkills = this.searchedSkills.filter((e) => e)
        this.showDropDownList = this.searchedSkills.length > 0 ? true : false
      }
      if (skill.length === 0) {
        this.closeList()
      }
    },
    async addSkills(e, skill) {
      e.preventDefault()
      var xSkillIndex = this.skills.findIndex((xSkill) => {
        return skill.guid == xSkill.guid
      })
      if (xSkillIndex < 0) {
        this.skills.unshift(skill)
      }
    },
    closeList() {
      this.searchedSkills = []
      if (this.$refs.searchBar) this.$refs.searchBar.value = ''
      this.showDropDownList = false
    },
    onFieldUpdate($event) {
      const { name, value } = $event.target
      this.fields[name] = value
      this.onFieldValidate($event)
    },
    onFieldValidate($event) {
      const { name, value } = $event.target
      const isError = name === 'durationHour' || name === 'durationMinute' ? value < 0 : !value || value === ''
      isError ? this.$refs[name]?.classList.add('form-input-error') : this.$refs[name]?.classList.remove('form-input-error')
      this.isSaveBtnDisabled = !this.isFormValid()
    },
    onHourUpdate($event) {
      this.duration.hour = $event.target.value
      this.onFieldValidate($event)
    },
    onMinuteUpdate($event) {
      this.duration.minute = $event.target.value
      this.onFieldValidate($event)
    },
    onMarkComplete($event) {
      this.fields.isComplete = $event.detail.checked
      this.onFieldValidate($event)
    },
    isFormValid() {
      if (!this.url || this.url === '' || this.isUrl.error) return false
      if (!this.fields.title || this.fields.title === '') return false
      if (!this.fields.source || this.fields.source === '') return false
      if (this.duration.hour < 0 || this.duration.minute < 0 || this.duration.hour + this.duration.minute <= 0) return false
      return true
    },
    getFormData() {
      return this.isFormValid()
        ? {
            ...this.fields,
            url: this.url,
            duration: this.duration.hour * 60 * 60 + this.duration.minute * 60,
            skills: this.skills
          }
        : {}
    },
    onSave() {
      if (!this.isSaveBtnDisabled) {
        this.$emit('on-save')
      }
      this.isSaveBtnDisabled = true
    },
    formatDuration(duration) {
      let durationInMinutes = 0
      let durationInHours = 0
      if (duration < 3600) durationInMinutes = Math.floor(duration / 60)
      else durationInHours = Math.floor(duration / 3600)
      durationInMinutes = Math.floor((duration - Math.floor(duration / 3600) * 3600) / 60)
      return {
        durationInHours,
        durationInMinutes
      }
    }
  }
}
</script>
<style lang="scss">
.skills-search {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e1e1;
  border-radius: 22px;
  font-family: 'Arial';
  font-size: 16px;
  padding: 0;
  margin-bottom: 1rem;
  .searchbar-input {
    background: #fff;
    border-radius: 22px;
  }
}
</style>
<style lang="scss" scoped>
.save-button-primary {
  font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
  letter-spacing: 0.02px;
  color: #000000;
  --box-shadow: none;
  text-transform: none;
}
.external-content-modal {
  padding: 0 5em !important;
}

.url-error-message {
  color: #e0301e;
  font-size: 12px;
  margin-top: 0;
}

.typeahead-header {
  margin-top: 1.25rem;
  font-family: 'Arial';
  font-weight: bold;
  font-size: 14px;
  color: var(--ion-color-black);
}

.typeahead-tooltip {
  position: relative;
  display: inline-block;
}

.typeahead-tooltip .typeahead-tooltiptext {
  text-align: center;
  font: normal normal normal 0.75rem Arial;
  color: #000000de;
  width: 11.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000001f;
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  opacity: 0;
  bottom: 1.5rem;
  padding: 5px;
  left: -5rem;
}

.typeahead-tooltip:hover .typeahead-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.url-input {
  height: 41px;
}

.action-btns {
  text-align: end;
  margin-top: 1.5rem;
  width: 100%;
}

.next-btn {
  font-family: 'Futura PT Demi';
  text-align: center;
  font-size: 16px;
  text-transform: none;
  --ion-color-primary-contrast: var(--ion-color-tertiary);
  --box-shadow: none;
}

.duration-input-container {
  font-family: 'Arial';
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  .duration-input {
    height: 41px;
    width: 64px;
    margin-right: 0.3em;

    &:nth-child(2) {
      margin-left: 1em;
    }
  }
}

.form-input {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e1e1;
  border-radius: 4px;
  padding: 0.5em;
  font-family: 'Arial';
  font-size: 16px;
}

.info-icon {
  width: 1.2em;
  height: 1.2em;
  vertical-align: text-top;
  cursor: pointer;
}

.form-input-error {
  border: 1px solid var(--ion-color-status);
}

textarea {
  resize: none;
}

.external-form-container {
  height: calc(100vh - 200px);
  overflow-y: auto;
  padding-bottom: 7rem;
}

.search-wrapper {
  position: relative;
  background: var(--ion-color-white);
  .scrollable {
    width: 390px;
    height: 255px;
    .search-skills-list {
      height: 255px;
      width: 350px;
      border-radius: 1.125rem;
      margin: 0;
      overflow-y: auto;
      box-shadow: 0px 0px 12px #0000001f;
      border: 20px solid #fff;
      @media (max-width: 500px) {
        width: 100%;
        position: relative;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #4c5c8d;
        border-radius: 8px;
      }

      // scrollbar track
      &::-webkit-scrollbar-track-piece {
        background: #bcc2d4;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #4c5c8d;
      }
      .search-list-item {
        font: normal normal normal 16px/26px Arial;
        cursor: pointer;
        margin: 0 1.5rem;
        padding-bottom: 0.5rem;
        &:hover {
          color: #1f3370;
          --background: #bcc2d4;
        }
        @media (max-width: 500px) {
          margin: 0;
        }
      }
    }
  }
}

.footer-container {
  padding: 1em 0em;
  position: absolute;
  bottom: 0;
  background-color: white;
  width: calc(100% - 10em);
  z-index: 2;
  border-top: 1px solid #eee;
  border-radius: 0 0 0 20px;
  max-height: 100px;
  .complete-checkbox {
    --size: 1.125rem;
    margin-right: 0.75rem;
    --border-radius: 0.25rem;
    --border-color: #bcc2d4;
    --border-width: 0.125rem;
  }
  .complete-text {
    position: relative;
    top: -0.2rem;
    font: normal normal normal 0.875rem/1.125rem Arial;
    color: #000000de;
  }
  .footer {
    font-family: 'Arial';
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .complete-save-btn {
      text-transform: capitalize;
      width: 114px;
      height: 39px !important;
      --box-shadow: none;
      color: var(--ion-color-dark-blue);
      font-family: 'Futura PT Demi';
      font-size: 16px;
    }

    .complete-save-btn-right-align {
      position: relative;
      left: 75%;
    }
  }
}

@media screen and (max-device-width: 480px) {
  .external-content-modal {
    padding: 0 1.2em !important;
  }

  .footer-container {
    padding: 0.5em 0em;
    width: calc(100% - 2.4em);
    height: 90px;
    bottom: 24px;
  }
}
</style>