// eslint-disable-next-line
import Vue from 'vue'
import localforage from 'localforage'
import axios from 'axios'
const appDetails = require('../../package.json')

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class Analytics {
  constructor() {
    setTimeout(() => {
      this.clickStreamUri = process.env.VUE_APP_CLICKSTREAM
    }, 100)
  }

  setVarAndTrack = async (...args) => {
    await sleep(1000)
    const cla = args.length > 0 ? args[0] : { c: '', l: '', a: '' }
    const userIdPromise = await localforage.getItem('my_user_id')
    const usernamePromise = await localforage.getItem('my_username')

    let clickstreamData = {}
    clickstreamData['app'] = appDetails.name
    clickstreamData['c'] = cla.c
    clickstreamData['l'] = cla.l
    clickstreamData['a'] = cla.a
    clickstreamData['u'] = userIdPromise ? userIdPromise : ''
    clickstreamData['un'] = usernamePromise ? usernamePromise : ''

    const headers = { 'Content-Type': 'application/json' }
    const response = await axios.post(`${this.clickStreamUri}clickstream`, clickstreamData, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  }

  install(Vue) {
    Vue.analytics = analytics
    window.analytics = analytics

    Object.defineProperties(Vue.prototype, {
      analytics: {
        get() {
          return analytics
        }
      },
      $analytics: {
        get() {
          return $analytics
        }
      }
    })
  }
}

const analytics = new Analytics()

export default analytics
