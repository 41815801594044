<template>
  <div :class="isDesktopPlatform ? 'item-info-desktop' : 'item-info-mobile'">
    <ion-row v-if="metaData.dueDate" class="due-date item-info ion-no-padding" style="padding-bottom: 30px">
      <ion-col size="5" class="ion-no-padding due-date-info"> Due Date </ion-col>
      <ion-col  size="7" class="due-date-info"> Required By {{ metaData.dueDate }} </ion-col>
    </ion-row>
    <ion-row v-if="metaData.pastDueDate" class="past-due-date item-info ion-no-padding" style="padding-bottom: 30px">
      <ion-col  size="5" class="ion-no-padding due-date-info">Past Due Date </ion-col>
      <ion-col  size="7" class="due-date-info"> Required By {{ metaData.pastDueDate }} </ion-col>
    </ion-row>
    <ion-row v-if="metaData.nextRequiredItem" class="due-date item-info ion-no-padding">
      <ion-col class="next-item-title">Next required item:</ion-col>
      <ion-col class="next-item-title">Required By {{ metaData.nextRequiredItem.dueDate }}</ion-col>
      <ion-col class="next-item-title">{{ metaData.nextRequiredItem.title }}</ion-col>
    </ion-row>
    <!-- <ion-col :style="metaData.dueDate ? 'margin-top: 25px;' : ''" v-if="metaData.nextRequiredItem" class="ion-no-padding">
      <ion-col class="next-item-title">Next required item:</ion-col>
      <ion-col class="next-item-title">Required By {{ metaData.nextRequiredItem.dueDate }}</ion-col>
      <ion-col class="next-item-title">{{ metaData.nextRequiredItem.title }}</ion-col>
    </ion-col> -->
    <slot name="item-about" class="item-track" />
    <slot name="item-rating" class="item-track" />
    <slot name="related-content" class="item-track" />

    <ion-row v-if="metaData.accreditation.length > 0" class="item-info ion-no-padding">
      <ion-col class="item-info-title ion-no-padding">Accreditation</ion-col>
      <ion-col class="item-info-data ion-no-padding">
        <div style="font-family: Arial; font-size: 16px" v-for="(item, index) in metaData.accreditation" :key="item.type" :style="index > 0 ? 'margin-top: 10px' : ''">
          <span class="item-info-title" style="margin-right: 5px">{{ item.org }}</span>
          issues
          <span class="item-info-title" style="margin-right: 5px; margin-left: 5px">{{ item.creditHours }} hours/{{ item.creditType }}</span>
          Credit
        </div>
      </ion-col>
    </ion-row>

    <ion-row v-if="metaData.fieldOfStudy.length > 0" class="item-info ion-no-padding">
      <ion-col class="item-info-title ion-no-padding">Field of study</ion-col>
      <ion-col class="item-info-data ion-no-padding">
        <div style="font-family: 'Arial'">{{ metaData.fieldOfStudy.join(',') }}</div>
      </ion-col>
    </ion-row>

    <ion-row v-if="metaData.certification.length > 0" class="item-info ion-no-padding">
      <ion-col class="item-info-title ion-no-padding">Certification</ion-col>
      <ion-col class="item-info-data item-clickable ion-no-padding">
        {{ metaData.certification }}
        <svg-icon style="margin-left: 8px" icon-class="certifcation-icon" />
      </ion-col>
    </ion-row>

    <ion-row v-if="metaData.foundIn.length > 0 && item.format.name.toLowerCase() != 'collection'" class="item-info ion-no-padding">
      <ion-col class="item-info-title ion-no-padding">Found in</ion-col>
      <ion-col class="item-info-data ion-no-padding">
        <div v-for="(item, index) in metaData.foundIn" :key="item.name" :style="index > 0 ? 'margin-top: 10px' : ''">
          <a class="item-info-data item-clickable" href="#" @click="gotoTrack(item)">{{ item.name }}</a>
        </div>
      </ion-col>
    </ion-row>

    <ion-row v-if="metaData.addedBy.length > 0" class="item-info ion-no-padding">
      <ion-col class="item-info-title ion-no-padding">Added by</ion-col>
      <ion-col class="item-info-data ion-no-padding">{{ metaData.addedBy.join(',') }}</ion-col>
    </ion-row>

    <ion-row v-if="metaData.skills.length > 0" class="item-info ion-no-padding">
      <ion-col class="item-info-title ion-no-padding">{{ item.format.name.toLowerCase() != 'course' ? 'Skills' : 'Skills gained' }}</ion-col>
      <ion-col class="meta-skills ion-no-padding">
        <div v-for="skill in metaData.skills" :key="skill">
          <a class="skill-pill" href="#" @click="onSkillClick(skill)">{{ skill }}</a>
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import gtag from '@/utils/ga.js'
import { bootstrap } from 'vue-gtag'
import localforage from 'localforage'
import moment from 'moment-timezone'
import SvgIcon from '@/components/molecules/SvgIcon'

export default {
  name: 'ItemMetaData',
  components: { SvgIcon },
  props: {
    item: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      metaData: {
        accreditation: [],
        fieldOfStudy: [],
        certification: '',
        foundIn: [],
        skills: [],
        addedBy: [],
        userTracks: []
      },
      isDesktopPlatform: true
    }
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
    this.metaData.accreditation = {}.hasOwnProperty.call(this.item, 'accreditation') ? this.item.accreditation : []
    this.metaData.fieldOfStudy = {}.hasOwnProperty.call(this.item, 'fieldOfStudy') ? this.item.fieldOfStudy : []
    this.metaData.certification = {}.hasOwnProperty.call(this.item, 'certification') ? this.item.certification : ''
    // Filter out User Created Tracks from Tracks dropdown
    // They will be enabled again when User Created Tracks are made public
    this.metaData.foundIn = {}.hasOwnProperty.call(this.item, 'meta')
      ? {}.hasOwnProperty.call(this.item.meta, 'tracks')
        ? this.item.meta.tracks.filter((track) => track.source == 'PLAN')
        : []
      : []
    this.metaData.skills = {}.hasOwnProperty.call(this.item, 'emsiSkills') ? this.item.emsiSkills : []
    this.metaData.nextRequiredItem = {}.hasOwnProperty.call(this.item, 'nextRequiredItem') ? this.item.nextRequiredItem : ''
    this.metaData.addedBy = {}.hasOwnProperty.call(this.item, 'addedBy') ? this.item.addedBy : []
    this.metaData.userTracks = {}.hasOwnProperty.call(this.item, 'userTracks') ? this.item.userTracks : []
    const dueDates = []
    if ({}.hasOwnProperty.call(this.item.meta, 'tracks')) {
      this.item.meta.tracks.map((track) => {
        if ({}.hasOwnProperty.call(track, 'dueDate') && track.dueDate != null) {
          dueDates.push(new Date(track.dueDate))
        }
      })
    }
    var formattedToday
    if (dueDates.length > 0) {
      var minDate = moment(Math.min.apply(null, dueDates))
      formattedToday = moment(minDate).tz('America/New_York').format('DD MMM YYYY')
    }
    if ({}.hasOwnProperty.call(this.item, 'isExternal') && this.item.isExternal) {
      this.metaData.dueDate = null
      this.metaData.pastDueDate = null
    } else if (this.item.provider.timeToProgressSyncInHours > 0) {
      moment(minDate).add(this.item.provider.timeToProgressSyncInHours, 'hours').isAfter(new Date()) && this.item.progress < 100
        ? (this.metaData.dueDate = formattedToday)
        : (this.metaData.pastDueDate = formattedToday)
    } else {
      moment(minDate).isAfter(new Date()) ? (this.metaData.dueDate = formattedToday) : (this.metaData.pastDueDate = formattedToday)
    }
  },
  filters: {},
  methods: {
    async onSkillClick(skill) {
      await this.onPrepareGAPayload('Skill Clicked', skill)
      const query = { q: skill, suggestionSkill: skill }
      this.$router.push({ name: 'Search', query: query, params: { fromCourseLandingPage: true } }).catch(() => {})
    },
    async gotoTrack(track, type = 'Collection') {
      const route = { name: type, params: { id: track.guid, pgrTrk: true } }
      await this.onPrepareGAPayload('Track Clicked', track.name)
      this.$router.push(route)
    },
    async onPrepareGAPayload(category, title) {
      const learnerId = await localforage.getItem('my_user_id')
      const tenantGuid = await localforage.getItem('my_tenant_guid')
      const item = {
        'learner-id': learnerId,
        'tenant-guid': tenantGuid,
        title: title,
        timestamp: new Date().getTime()
      }
      const payload = {
        action: 'Meta Data Clicked',
        category: category,
        value: JSON.stringify(item),
        csvalue: item
      }
      await this.GAEventTrack(payload)
    },
    async GAEventTrack(payload) {
      const isGAEnabled = await gtag.isGAEnabled()
      if (isGAEnabled) {
        bootstrap().then(() => {
          this.$gtag.event(payload.action, {
            event_category: payload.category,
            event_label: payload.value,
            value: payload.value
          })
        })
      }
      this.ClickstreamEventTrack(payload)
    },
    async ClickstreamEventTrack(payload) {
      analytics.setVarAndTrack({
        c: payload.category,
        a: payload.action,
        l: payload.csvalue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-info-desktop {
  margin-top: 0rem;
  margin-left: 2rem;
}

.item-info-mobile {
  margin-top: 1rem;
  margin-left: 0rem;
}

.due-date {
  background-color: black;
  border-radius: 5px;
  padding: 12px 20px;
  height: 45px;
  .due-date-info,
  .next-item-title {
    font-family: Arial;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }

  @media (max-width: 1477px) {
    height: unset;
  }
  @media (max-width: 1024px) {
    margin-bottom: 48px;
    height: 45px;
  }
  @media (max-width: 375px) {
    height: unset;
  }
  .next-item-title {
    font-weight: normal;
    text-align: left;
  }
}
.past-due-date {
  background-color: #e0301e;
  border-radius: 5px;
  padding: 12px 20px;
  height: 45px;
  .due-date-info,
  .next-item-title {
    font-family: Arial;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }

  @media (max-width: 1477px) {
    height: unset;
  }
  @media (max-width: 1024px) {
    margin-bottom: 48px;
    height: 45px;
  }
  @media (max-width: 375px) {
    height: unset;
  }
  .next-item-title {
    font-weight: normal;
    text-align: left;
  }
}

.item-track {
  @media (max-width: 1023px) {
    display: none !important;
  }
}

.item-info {
  text-align: left;
  padding: 1.25rem 0 1.25rem 1rem;
  border-bottom: 2px solid #e0e1e1;
  .item-info-title,
  .item-info-data {
    font-family: Arial;
    font-weight: bold;
    color: var(--ion-color-black);
    font-size: 16px;
    text-align: left;
    text-decoration: none;
  }
  .item-info-title {
    width: 180px;
  }
  .item-info-data {
    font-weight: normal;
    max-width: unset;
  }
  .meta-skills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .skill-pill {
    min-height: 30px;
    border-radius: 15px;
    font-family: 'Arial';
    font-size: 11px;
    font-weight: bold;
    background: #bcc2d4;
    color: var(--ion-color-tertiary);
    padding: 8px 14px;
    text-decoration: none;
    display: block;
  }
  .item-clickable {
    color: #1f3370;
    opacity: 1;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 1024px) {
    padding: 20px 50px;
  }
  @media (max-width: 484px) {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 1366px) {
  .item-info {
    display: grid;
    gap: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .item-info {
    display: flex;
    gap: unset;
  }

  .item-info-desktop {
    margin-left: unset;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 484px) {
  .item-info {
    display: grid;
  }
  .item-info-title {
    margin-bottom: 16px;
  }
}
</style>