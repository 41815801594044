var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.isDesktopPlatform ? "item-info-desktop" : "item-info-mobile" },
    [
      _vm.metaData.dueDate
        ? _c(
            "ion-row",
            {
              staticClass: "due-date item-info ion-no-padding",
              staticStyle: { "padding-bottom": "30px" }
            },
            [
              _c(
                "ion-col",
                {
                  staticClass: "ion-no-padding due-date-info",
                  attrs: { size: "5" }
                },
                [_vm._v(" Due Date ")]
              ),
              _c(
                "ion-col",
                { staticClass: "due-date-info", attrs: { size: "7" } },
                [_vm._v(" Required By " + _vm._s(_vm.metaData.dueDate) + " ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.metaData.pastDueDate
        ? _c(
            "ion-row",
            {
              staticClass: "past-due-date item-info ion-no-padding",
              staticStyle: { "padding-bottom": "30px" }
            },
            [
              _c(
                "ion-col",
                {
                  staticClass: "ion-no-padding due-date-info",
                  attrs: { size: "5" }
                },
                [_vm._v("Past Due Date ")]
              ),
              _c(
                "ion-col",
                { staticClass: "due-date-info", attrs: { size: "7" } },
                [
                  _vm._v(
                    " Required By " + _vm._s(_vm.metaData.pastDueDate) + " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.metaData.nextRequiredItem
        ? _c(
            "ion-row",
            { staticClass: "due-date item-info ion-no-padding" },
            [
              _c("ion-col", { staticClass: "next-item-title" }, [
                _vm._v("Next required item:")
              ]),
              _c("ion-col", { staticClass: "next-item-title" }, [
                _vm._v(
                  "Required By " + _vm._s(_vm.metaData.nextRequiredItem.dueDate)
                )
              ]),
              _c("ion-col", { staticClass: "next-item-title" }, [
                _vm._v(_vm._s(_vm.metaData.nextRequiredItem.title))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._t("item-about"),
      _vm._t("item-rating"),
      _vm._t("related-content"),
      _vm.metaData.accreditation.length > 0
        ? _c(
            "ion-row",
            { staticClass: "item-info ion-no-padding" },
            [
              _c("ion-col", { staticClass: "item-info-title ion-no-padding" }, [
                _vm._v("Accreditation")
              ]),
              _c(
                "ion-col",
                { staticClass: "item-info-data ion-no-padding" },
                _vm._l(_vm.metaData.accreditation, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.type,
                      staticStyle: {
                        "font-family": "Arial",
                        "font-size": "16px"
                      },
                      style: index > 0 ? "margin-top: 10px" : ""
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "item-info-title",
                          staticStyle: { "margin-right": "5px" }
                        },
                        [_vm._v(_vm._s(item.org))]
                      ),
                      _vm._v(" issues "),
                      _c(
                        "span",
                        {
                          staticClass: "item-info-title",
                          staticStyle: {
                            "margin-right": "5px",
                            "margin-left": "5px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(item.creditHours) +
                              " hours/" +
                              _vm._s(item.creditType)
                          )
                        ]
                      ),
                      _vm._v(" Credit ")
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.metaData.fieldOfStudy.length > 0
        ? _c(
            "ion-row",
            { staticClass: "item-info ion-no-padding" },
            [
              _c("ion-col", { staticClass: "item-info-title ion-no-padding" }, [
                _vm._v("Field of study")
              ]),
              _c("ion-col", { staticClass: "item-info-data ion-no-padding" }, [
                _c("div", { staticStyle: { "font-family": "'Arial'" } }, [
                  _vm._v(_vm._s(_vm.metaData.fieldOfStudy.join(",")))
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.metaData.certification.length > 0
        ? _c(
            "ion-row",
            { staticClass: "item-info ion-no-padding" },
            [
              _c("ion-col", { staticClass: "item-info-title ion-no-padding" }, [
                _vm._v("Certification")
              ]),
              _c(
                "ion-col",
                { staticClass: "item-info-data item-clickable ion-no-padding" },
                [
                  _vm._v(" " + _vm._s(_vm.metaData.certification) + " "),
                  _c("svg-icon", {
                    staticStyle: { "margin-left": "8px" },
                    attrs: { "icon-class": "certifcation-icon" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.metaData.foundIn.length > 0 &&
      _vm.item.format.name.toLowerCase() != "collection"
        ? _c(
            "ion-row",
            { staticClass: "item-info ion-no-padding" },
            [
              _c("ion-col", { staticClass: "item-info-title ion-no-padding" }, [
                _vm._v("Found in")
              ]),
              _c(
                "ion-col",
                { staticClass: "item-info-data ion-no-padding" },
                _vm._l(_vm.metaData.foundIn, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      style: index > 0 ? "margin-top: 10px" : ""
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "item-info-data item-clickable",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.gotoTrack(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.metaData.addedBy.length > 0
        ? _c(
            "ion-row",
            { staticClass: "item-info ion-no-padding" },
            [
              _c("ion-col", { staticClass: "item-info-title ion-no-padding" }, [
                _vm._v("Added by")
              ]),
              _c("ion-col", { staticClass: "item-info-data ion-no-padding" }, [
                _vm._v(_vm._s(_vm.metaData.addedBy.join(",")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.metaData.skills.length > 0
        ? _c(
            "ion-row",
            { staticClass: "item-info ion-no-padding" },
            [
              _c("ion-col", { staticClass: "item-info-title ion-no-padding" }, [
                _vm._v(
                  _vm._s(
                    _vm.item.format.name.toLowerCase() != "course"
                      ? "Skills"
                      : "Skills gained"
                  )
                )
              ]),
              _c(
                "ion-col",
                { staticClass: "meta-skills ion-no-padding" },
                _vm._l(_vm.metaData.skills, function(skill) {
                  return _c("div", { key: skill }, [
                    _c(
                      "a",
                      {
                        staticClass: "skill-pill",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.onSkillClick(skill)
                          }
                        }
                      },
                      [_vm._v(_vm._s(skill))]
                    )
                  ])
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }