export default [
  {
    path: '/content/:id',
    name: 'Content',
    component: () => import('@/views/content/Content'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/course/:id',
    name: 'Course',
    component: () => import('@/views/course/Course'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/credential/:id',
    name: 'Credential',
    component: () => import('@/views/credential/Credential'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/internal-course/:id',
    name: 'Internal Course',
    redirect: '/credential/:id',
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/submit-capstone/:id',
    name: 'CredentialSubmitCapstone',
    component: () => import('@/views/credential/components/CredentialCreateCapstone'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/edit-capstone/:id&:editProjectId',
    name: 'CredentialEditCapstone',
    component: () => import('@/views/credential/components/CredentialCreateCapstone'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/credential-content/:id',
    name: 'CredentialContentBookmark',
    component: () => import('@/views/credential/CredentialContentBookmark'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/external/:id',
    name: 'ExternalContent',
    component: () => import('@/views/external/ExternalContent'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
]