import axios from 'axios'

export default {
  async getSignedURI(url, payload) {
    const headers = { 'Content-Type': 'application/json;charset=UTF-8', Authorization: payload.token }
    const response = await axios.post(url, { url: payload.url }, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  }
}
