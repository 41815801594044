<template>
  <div>
    <button @click="onScrollToTop" title="Go to top" id="scrollToTopButton">
      <img src="@/assets/images/svgs/up-arrow.svg" alt="Go to Top" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'ContentScrollToTop',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {
    window.addEventListener('scroll', this.scrollFunction, true)
  },
  mounted() {},
  destroyed() {
    window.removeEventListener('scroll', this.scrollFunction, true)
  },
  methods: {
    scrollFunction() {
      const { scrollTop } = document.getElementById('contentlayout')
      const scrollToTopButton = document.getElementById('scrollToTopButton')
      scrollToTopButton.style.display = scrollTop > 20 ? 'block' : 'none'
    },
    onScrollToTop() {
      const app = document.getElementById('contentlayout')
      app.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100% !important;
  height: auto !important;
  transform: rotate(90deg);
  min-width: unset !important;
  min-height: unset !important;
}

#scrollToTopButton {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 33%;
  z-index: 99;
  outline: none;
  background-color: #f2f2f2;
  color: #5c5c5c;
  cursor: pointer;
  padding: 15px;
  border-radius: 100px;
  transition: 1s ease-in;

  &:hover {
    background-color: rgba(242, 242, 242, 0.5);
  }
}

@media screen and (max-width: 1200px) {
  #scrollToTopButton {
    right: 10%;
  }
}
</style>
