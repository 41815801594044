var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion", class: _vm.show ? "expanded" : "" },
    [
      _c("h2", { on: { click: _vm.toggleSlide } }, [_vm._t("title")], 2),
      _c(
        "section",
        { ref: "panel", staticClass: "section" },
        [_vm._t("section")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }