var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "assessment-modal" },
    [
      _c(
        "ion-header",
        [
          _c("svg-icon", {
            staticClass: "g-icon--small close",
            attrs: { "icon-class": "close" },
            on: { click: _vm.onClose }
          }),
          _c("h2", { staticClass: "g-type-header title" }, [
            _vm._v(_vm._s(_vm.assessment.name))
          ])
        ],
        1
      ),
      _vm.assessment &&
      _vm.assessment.questions &&
      _vm.assessment.questions.length > 0
        ? _c("div", { staticClass: "question" }, [
            _c("div", { staticClass: "question-prompt" }, [
              _vm._v(
                _vm._s(_vm.assessment.questions[_vm.qIdx].qid) +
                  ". " +
                  _vm._s(_vm.assessment.questions[_vm.qIdx].text)
              )
            ]),
            _c(
              "div",
              {
                staticStyle: { "max-height": "300px", "overflow-y": "scroll" }
              },
              [
                _vm.assessment.questions[_vm.qIdx].type === "SINGLE_OPTION"
                  ? _c(
                      "ion-list",
                      { key: _vm.qIdx },
                      _vm._l(
                        _vm.assessment.questions[_vm.qIdx].options,
                        function(option, index) {
                          return _c(
                            "ion-item",
                            { key: index, attrs: { lines: "none" } },
                            [
                              _c(
                                "ion-label",
                                {
                                  staticClass: "ion-text-wrap g-type-body",
                                  staticStyle: { "padding-left": "1rem" }
                                },
                                [_vm._v(_vm._s(option.text))]
                              ),
                              _c("input", {
                                attrs: {
                                  slot: "start",
                                  type: "radio",
                                  name: "question"
                                },
                                domProps: { value: index },
                                on: {
                                  change: function($event) {
                                    return _vm.handleRadioSelect(
                                      $event,
                                      _vm.assessment.questions[_vm.qIdx].qid
                                    )
                                  }
                                },
                                slot: "start"
                              })
                            ],
                            1
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
                _vm.assessment.questions[_vm.qIdx].type === "MULTI_OPTION"
                  ? _c(
                      "ion-list",
                      { key: _vm.qIdx },
                      _vm._l(
                        _vm.assessment.questions[_vm.qIdx].options,
                        function(option, index) {
                          return _c(
                            "ion-item",
                            {
                              key: index,
                              attrs: { mode: "md" },
                              on: {
                                ionChange: function($event) {
                                  return _vm.handleCheckboxSelect(
                                    $event,
                                    _vm.assessment.questions[_vm.qIdx].qid
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "ion-label",
                                {
                                  staticClass: "ion-text-wrap g-type-body",
                                  staticStyle: { "padding-left": "1rem" }
                                },
                                [_vm._v(_vm._s(option.text))]
                              ),
                              _c("ion-checkbox", {
                                attrs: { slot: "start", value: index },
                                slot: "start"
                              })
                            ],
                            1
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.error != ""
        ? _c("span", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
      _c(
        "ion-row",
        { staticClass: "ion-no-padding" },
        [
          _c(
            "ion-col",
            {
              staticClass: "ion-padding-bottom ion-text-center",
              attrs: { size: "12" }
            },
            [
              _vm.assessment &&
              _vm.assessment.questions &&
              _vm.qIdx == _vm.assessment.questions.length - 1
                ? _c(
                    "ion-button",
                    {
                      staticClass: "ion-button-primary ion-text-capitalize",
                      attrs: { shape: "round", color: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v("Submit")]
                  )
                : _vm._e(),
              _vm.assessment &&
              _vm.assessment.questions &&
              _vm.qIdx < _vm.assessment.questions.length - 1
                ? _c(
                    "ion-button",
                    {
                      staticClass: "ion-button-primary ion-text-capitalize",
                      attrs: { shape: "round", color: "primary" },
                      on: { click: _vm.onNext }
                    },
                    [_vm._v("Next")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }