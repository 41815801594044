export default [
  {
    path: '/bookmarks',
    name: 'LibraryBookmarkList',
    component: () => import('@/components/organisms/BookmarkList'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: false,
      jumpBackInVisible: false
    }
  }
]
