<template>
  <div class="assessment-modal">
    <ion-header>
      <svg-icon icon-class="close" class="g-icon--small close" @click="onClose"></svg-icon>
      <h2 class="g-type-header title">{{ assessment.name }}</h2>
    </ion-header>
    <div class="question" v-if="assessment && assessment.questions && assessment.questions.length > 0">
      <div class="question-prompt">{{ assessment.questions[qIdx].qid }}. {{ assessment.questions[qIdx].text }}</div>
      <div style="max-height: 300px; overflow-y: scroll">
        <ion-list v-if="assessment.questions[qIdx].type === 'SINGLE_OPTION'" :key="qIdx">
          <ion-item v-for="(option, index) in assessment.questions[qIdx].options" :key="index" lines="none">
            <ion-label class="ion-text-wrap g-type-body" style="padding-left: 1rem">{{ option.text }}</ion-label>
            <input slot="start" type="radio" name="question" :value="index" @change="handleRadioSelect($event, assessment.questions[qIdx].qid)" />
          </ion-item>
        </ion-list>
        <ion-list v-if="assessment.questions[qIdx].type === 'MULTI_OPTION'" :key="qIdx">
          <ion-item v-for="(option, index) in assessment.questions[qIdx].options" :key="index" mode="md" @ionChange="handleCheckboxSelect($event, assessment.questions[qIdx].qid)">
            <ion-label class="ion-text-wrap g-type-body" style="padding-left: 1rem">{{ option.text }}</ion-label>
            <ion-checkbox slot="start" :value="index"> </ion-checkbox>
          </ion-item>
        </ion-list>
      </div>
    </div>

    <span v-if="error != ''" class="error">{{ error }}</span>

    <ion-row class="ion-no-padding">
      <ion-col size="12" class="ion-padding-bottom ion-text-center">
        <ion-button
          v-if="assessment && assessment.questions && qIdx == assessment.questions.length - 1"
          shape="round"
          color="primary"
          class="ion-button-primary ion-text-capitalize"
          @click="onSubmit"
          >Submit</ion-button
        >
        <ion-button
          v-if="assessment && assessment.questions && qIdx < assessment.questions.length - 1"
          shape="round"
          color="primary"
          class="ion-button-primary ion-text-capitalize"
          @click="onNext"
          >Next</ion-button
        >
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { popoverController } from '@modus/ionic-vue'

export default {
  name: 'AssessmentModal',
  components: {},
  props: {
    assessment: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      answers: {},
      completed: false,
      qIdx: 0,
      answerSelected: false,
      error: ''
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {
    this.$parent.$emit('close-assessment-modal', { answers: this.answers, id: this.assessment.id, completed: this.completed })
  },
  methods: {
    handleRadioSelect(event, index) {
      this.answers[index] = [event.target.value]
      this.answerSelected = true
    },
    handleCheckboxSelect(event, index) {
      const value = event.target.value
      if ({}.hasOwnProperty.call(this.answers, index)) {
        const found = this.answers[index].indexOf(value)
        found > -1 ? this.answers[index].splice(found, 1) : this.answers[index].push(value)
      } else {
        this.answers[index] = [value]
      }
      this.answerSelected = true
    },
    onClose() {
      popoverController.dismiss()
    },
    onSubmit() {
      if (!this.validateSelection()) return
      this.completed = true
      this.onClose()
    },
    onNext() {
      if (!this.validateSelection()) return
      this.qIdx += 1
      this.answerSelected = false
    },
    validateSelection() {
      this.error = !this.answerSelected ? 'Please select an answer to proceed' : ''
      return this.answerSelected
    }
  }
}
</script>

<style lang="scss" scoped>
.assessment-modal {
  height: 100%;
  width: 100%;
}

.title {
  padding-bottom: 0.325rem;
  border-bottom: 2px solid;
  border-color: var(--ion-color-dark-grey);
}

h2 {
  padding: 0rem 1.5rem;
}
.close {
  position: absolute;
  top: 0.1rem;
  right: 0.5rem;
}

.question {
  padding: 0.5rem 1rem;
  text-align: left;
  font-size: 1.2rem;
  .question-prompt {
    font-family: 'Futura PT Demi';
  }
  ion-radio,
  ion-checkbox {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
}

ion-button {
  margin: 1rem auto;
  min-width: 150px;
}
.error {
  color: var(--ion-color-status);
  padding-left: 1rem;
}
</style>
