import axios from 'axios'

export default {
  async getMyContents(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getContentById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getContentDetailsById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async getCollectionById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async completeContentById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async updateContentProgressById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getContentComments(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async postContentComment(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getRelatedTrackContentById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },
 
  async getRelatedContentById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },
  async getWorkspaceAvailability(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getGCSTextContent(url, payload) {
    const headers = {
      Authorization: 'Bearer ' + payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getGCSMediaContent(url, payload) {
    const headers = {
      Authorization: 'Bearer ' + payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return new Blob([response.data])
  },

  async getMyCourses(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    const data = response.data
    const content = data.content.filter(c => c.isActive)
    return { ...data, content: content }
  },

  async getMatchItemsForSkills(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch( () => {
      // return empty content array on 504 error response
      return { data: {
          content: []
        } }
       })
    return response.data
  },

  async getMatchItemsForSkillsMultipleContentType(url, payload) {
    const generateRandomToken = Math.floor((Math.random() * 10000000));
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Tracing-Token': generateRandomToken
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch( () => {
      // return empty content array on 504 error response
      return { data: {
          content: []
        } }
    })
    return response.data
  },

  async getRecommendedContent(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8',
    }
    const response = await axios.get(url, { headers: headers }).catch(() => {
      // return empty content array on 504 error response
      return {
        data: {
          content: []
        }
      }
    })
    return response.data
  },

  async getMatchCoursesForRole(url, payload) {
    const headers = {
      Authorization: 'Bearer ' + payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async searchContentByTitle(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async searchCourseByTitle(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async searchCredentialByTitle(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getUnassignedCourses(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async selfAssignCourse(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async fetchCourseUrl(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async fetchCredentialUrl(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async likeContent(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async dislikeContent(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getMyCredentials(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getAllCredentials(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCredentialById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCredentialDetailsById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response
  },

  async getExternalContentFormats(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSkillBuilderById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getLessonById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async uploadLessonKnowlegeCheck(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload.upr, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async uploadLessonProgressById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload.upr, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCapstoneProjectForCredential(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCredentialContent(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCredentialAssessments(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCredentialAssessmentById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async postCredentialAssessmentAnswers(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCapstoneProjectById(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async saveCapstoneProject(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.myCapstone, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async editCapstoneProject(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload.myCapstone, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async uploadFileToCapstoneProject(url,  payload, controller, onUploadProgress) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'multipart/form-data'
    }
    const response = await axios.post(url, payload.file, { headers: headers, signal: controller.signal, onUploadProgress: onUploadProgress });
    return response
  },

  async submitCapstoneProject(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getFileForCapstoneProject(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return new Blob([response.data])
  },

  async likeCredential(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, {}, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async dislikeCredential(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCredentialComments(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async postCredentialComment(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getMyLearningPlanItems(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getMyLearningPlanRequiredItems(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getMyLearningPlanItemsWithFilters(url, payload) {
    const headers = {
      Authorization: payload.token,
      'Content-Type': 'application/json'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getExternalLearningPlanFilters(url, payload) {
    const headers = {
      Authorization: payload.token,
      'Content-Type': 'application/json'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getExternalContentItems(url, payload) {
    const headers = {
      Authorization: payload.token,
      'Content-Type': 'application/json'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getMyLearningPlanFilters(url, payload) {
    const headers = {
      Authorization: payload.token,
      'Content-Type': 'application/json'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getSelfProgressProviderIDs(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async addExternalContent(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.post(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async updateExternalContent(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async deleteExternalContent(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.delete(url, { headers: headers, body: payload.body }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async updateExternalContentProgress(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      withCredentials: true,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.put(url, payload.body, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  },

  async getCSVDetails(url, payload) {
    const headers = {
      Authorization: payload.token,
      responseEncoding: 'utf8',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Content-Type': 'application/json;charset=UTF-8'
    }
    const response = await axios.get(url, { headers: headers }).catch(error => {
      return { data: error.response.data }
    })
    return response.data
  }
}
