<template>
  <div>
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col>
          <div class="recommend-image"><ion-skeleton-text animated /></div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="recommend-format">
            <ion-skeleton-text animated />
          </div>
          <div class="recommend-title"><ion-skeleton-text animated /></div>
          <div class="recommend-title"><ion-skeleton-text animated /></div>
          <div class="recommend-subtitle"><ion-skeleton-text animated /></div>
          <div class="recommend-subtitle"><ion-skeleton-text animated /></div>
          <div class="meta">
            <div class="recommend-bookmark"><ion-skeleton-text animated /></div>
            <div class="recommend-bookmark"><ion-skeleton-text animated /></div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
export default {
  name: 'RecommendedSkeleton',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.recommend-image {
  margin:1rem 0;
  height: 88px;
  width: 88px;
  border-radius: 20px;
}

.recommend-format {
  border-radius: 10px;
  height: 20px;
  width: 100px;
}

.recommend-title {
  margin: 0.2rem 0rem;
  width: 85%;
  height: 30px;
  color: var(--ion-color-tertiary);
}

.recommend-subtitle {
  margin: 0.2rem 0rem;
  width: 85%;
  height: 16px;
  color: var(--ion-color-role-grey);
}

.meta {
  display: flex;
  gap: 0.4rem;
  margin-top: 0.3rem;
  height: 40px;

  .recommend-bookmark {
    width: 25px;
    height: 25px;
    background: var(--ion-color-grey);
    border-radius: 50%;
  }
}
</style>
