<template>
  <div class="track-skeleton">
    <div :style="isDesktopPlatform ? 'padding-left: 150px;' : ''">
      <ion-grid>
        <ion-row>
          <ion-col size-xl="8" size-xs="12"   class="collection-about">
            <div class="track-item">
              <ion-grid>
                <ion-row class="ion-no-padding">
                  <ion-col style="max-width: 120px !important; min-width: 120px !important">
                    <div class="track-img"><ion-skeleton-text animated /></div>
                  </ion-col>
                  <ion-col size="8.5" class="content ion-padding-end">
                    <div class="meta-content">
                      <div class="meta-data">
                        <div class="format"><ion-skeleton-text animated /></div>
                        <div class="provider"><ion-skeleton-text animated /></div>
                      </div>
                      <div class="title"><ion-skeleton-text animated /></div>
                      <div class="meta-icon">
                        <div style="display: flex; gap: 0.4rem">
                          <div class="track-rating"><ion-skeleton-text animated /></div>
                          <div class="track-duration"><ion-skeleton-text animated /></div>
                        </div>
                      </div>
                      <div class="meta-icon" style="'display:flex; margin-top: 20px;'">
                        <div class="track-bookmark"><ion-skeleton-text animated /></div>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div :class="isDesktopPlatform ? 'container-desktop' : 'container-mobile'">
      <ion-grid>
        <ion-row>
          <ion-col size-xl="8" size-xs="12" class="collection-about">
            <div class="track-description">
              <ion-skeleton-text animated />
            </div>
            <div class="track-title">
              <div class="title"><ion-skeleton-text animated /></div>
              <div class="title"><ion-skeleton-text animated /></div>
            </div>
            <div v-for="i in 5" :key="i" class="track-item">
              <ion-grid>
                <ion-row class="ion-no-padding">
                  <ion-col style="max-width: 120px !important; min-width: 120px !important">
                    <div class="track-img"><ion-skeleton-text animated /></div>
                  </ion-col>
                  <ion-col size="8.5" size-xs="7" class="content ion-padding-end">
                    <div class="meta-content">
                      <div class="meta-data">
                        <div class="format"><ion-skeleton-text animated /></div>
                        <div class="provider"><ion-skeleton-text animated /></div>
                      </div>
                      <div class="title"><ion-skeleton-text animated /></div>
                      <div class="meta-icon">
                        <div style="display: flex; gap: 0.4rem">
                          <div class="track-bookmark"><ion-skeleton-text animated /></div>
                          <div class="track-bookmark"><ion-skeleton-text animated /></div>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrackSkeleton',
  components: {},
  props: {},
  data() {
    return {
      isDesktopPlatform: true
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.isDesktopPlatform = this.$platform === 'Desktop'
  },
  methods: {}
}
</script>
<style lang="scss">
.track-skeleton {
  .meta {
    display: flex;
    gap: 15px;
    padding: 4rem 70px 1rem;
  }

  .filter {
    width: 148px;
    height: 36px;
    background: #00000080 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 0.15;
  }

  .track-item {
    padding: 1.5rem 1rem 1.5rem 0.5rem;
    text-align: left;
    border-radius: 20px;
  }

  .track-img {
    position: relative;
    top: 15%;
    width: 90px;
    height: 80px;
    border-radius: 80%;
    img {
      width: 80px;
      height: 50px;
      object-fit: cover;
    }
  }
  .track-description {
    margin-bottom: 50px;
    width: 200px;
    height: 30px;
    border-radius: 15px;
    opacity: 1;
  }
  .container-desktop {
    margin-top: 32px;
    margin-left: 70px;
    .collection-about {
      padding: 60px 120px;
      background-color: #fff;
      border-radius: 20px;
    }
  }

  .container-mobile {
    margin-top: 16px;
    .collection-about {
      padding: 8px 0px;
      background-color: #fff;
      border-radius: 20px;
    }
  }

  .meta-content {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-top: 16px;

    .meta-data {
      display: flex;
      flex-direction: row;
      gap: 0.7rem;
      margin-top: 16px;
      .format {
        width: 101px;
        height: 24px;
        border-radius: 12px;
        opacity: 1;
      }

      .provider {
        width: 120px;
        height: 30px;
        border-radius: 15px;
        opacity: 1;
      }
    }

    .format {
      width: 101px;
      height: 24px;
      border-radius: 12px;
      opacity: 1;
    }

    .provider {
      width: 120%;
      height: 30px;
      opacity: 1;
    }

    .title {
      width: 100%;
      height: 16px;
      opacity: 1;
    }
  }

  .meta-icon {
    display: flex;
    margin-top: 0.3rem;
    width: 150%;
    justify-content: space-between;

    .track-bookmark {
      width: 35px;
      height: 35px;
      background: var(--ion-color-grey);
      border-radius: 17px;
    }
    .track-rating {
      width: 120px;
      height: 34px;
      background: var(--ion-color-grey);
      border-radius: 17px;
    }
    .track-duration {
      width: 70px;
      height: 34px;
      background: var(--ion-color-grey);
      border-radius: 35%;
    }
    .track-skill {
      width: 100px;
      height: 30px;
      border-radius: 15px;
    }
  }

  .track-btn {
    width: 100px;
    height: 40px;
    border-radius: 20px;
  }

  @media screen and (max-width: 490px) {
    .meta-content {
      .format {
        width: 70px;
        height: 20px;
      }
      .provider {
        width: 100%;
        height: 20px;
      }
      .title {
        width: 120%;
        height: 14px;
      }
    }
    .track-description {
      margin-left: 30px;
      margin-top: 20px;
    }

    .track-title {
      width: 86%;
      padding-left: 30px;
    }
    .track-item {
      padding: 0 1rem 0 0.5rem;
    }
  }
}
</style>

