var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tracks-modal" }, [
    _c(
      "div",
      { staticClass: "tracks-modal__content" },
      [
        _c(
          "ion-row",
          {
            staticClass:
              "tracks-modal__row ion-align-items-center ion-no-padding"
          },
          [
            _c(
              "ion-col",
              { staticClass: "ion-text-start" },
              [
                _c("svg-icon", {
                  staticClass:
                    "g-icon--small close g-clickable tracks-modal__close",
                  attrs: { "icon-class": "filter-close" },
                  on: { click: _vm.dismiss }
                }),
                _c("p", { staticClass: "tracks-modal__title" }, [
                  _vm._v(_vm._s(_vm.options.title))
                ]),
                _c("p", { staticClass: "tracks-modal__subtitle" }, [
                  _vm._v(_vm._s(_vm.options.subtitle1))
                ]),
                _c("p", { staticClass: "tracks-modal__subtitle" }, [
                  _vm._v(_vm._s(_vm.options.subtitle2))
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "ion-row",
          [
            _c(
              "ion-col",
              { staticClass: "ion-text-end tracks-modal__button-row" },
              [
                _c(
                  "ion-button",
                  {
                    staticClass:
                      "ion-text-end tracks-modal__button-one cancel-button",
                    on: { click: _vm.dismiss }
                  },
                  [_vm._v(_vm._s(_vm.options.button1))]
                ),
                _c(
                  "ion-button",
                  {
                    staticClass: "ion-text-end tracks-modal__button-two",
                    on: {
                      click: function($event) {
                        return _vm.selectTrack(_vm.options.button2)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.options.button2))]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }