<template>
  <ion-app>
    <!-- Top Navigation Bar -->
    <ion-header no-border v-if="this.$route.meta.mobileHeaderVisible" mode="ios">
      <NavBarMobile v-show="isNavBarVisible" @handleNavBar="handleNavBarVisibility" :isOpen="isNavBarOpen" />
    </ion-header>

    <ion-content id="contentlayout" :scroll-events="true" @ionScroll="onScroll($event)" ref="contentSection">
      <!-- Main Content -->
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view :key="$route.fullPath" />
        </keep-alive>
      </transition>

      <!-- Footer -->
      <footer
        class="footer"
        v-if="
          this.$route.fullPath !== '/landing' &&
          this.$route.fullPath !== '/help-center-main' &&
          this.$route.fullPath !== '/onboarding/terms-and-conditions' &&
          this.$route.fullPath !== '/onboarding/privacy-policy' &&
          this.$route.fullPath !== '/onboarding/cookie-notice' &&
          this.$route.meta.isMenuPrivacy !== true &&
          this.$route.meta.isMenuTerms !== true
        "
        style="text-align: left; padding: 0 1rem"
      >
        <div class="copyright-text">
          © {{ currentYear }} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.
        </div>
        <div>
          <span @click="openPrivacy()" class="copyright-link">
            <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Privacy Policy</a>
          </span>
          <span @click="openCookieNotice()" class="copyright-link">
            <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Cookie Notice</a>
          </span>
          <span @click="openTerms()" class="copyright-link">
            <a href="#" style="color: unset; text-decoration: unset; background-color: unset">Terms and Conditions</a>
          </span>
        </div>
      </footer>
      <footer
        class="footer"
        v-if="
          this.$route.fullPath === '/onboarding/terms-and-conditions' ||
          this.$route.fullPath === '/onboarding/privacy-policy' ||
          this.$route.fullPath === '/onboarding/cookie-notice'
        "
        style="text-align: left; padding: 0 1rem"
      >
        <div class="copyright-text">
          © {{ currentYear }} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.
        </div>
      </footer>
    </ion-content>

    <!-- Tab Navigation Bar -->
    <ion-footer v-if="this.$route.meta.mobileFooterVisible" @click="handleNavBarFooter">
      <NavBottomTabMobile />
    </ion-footer>
  </ion-app>
</template>

<script>
import Vue from 'vue'
import NavBarMobile from '@/components/molecules/NavBarMobile.vue'
import NavBottomTabMobile from '@/components/molecules/NavBottomTabMobile.vue'
import EventBus from '@/plugins/eventbus'

export default {
  name: 'LayoutMobile',
  props: {},
  components: {
    NavBarMobile,
    NavBottomTabMobile
  },
  data() {
    return {
      vPos: 0,
      isNavBarOpen: false,
      isNavBarVisible: true
    }
  },
  created() {},
  beforeMount() {
    if (this.$route.path === '/layout' || (['/landing'].includes(this.$route.path) && JSON.parse(localStorage.getItem('isLoggedIn')))) {
      this.$router.push('/home')
    }
  },
  computed: {
    currentYear() {
      const d = new Date()
      return d.getFullYear()
    }
  },
  watch: {},
  methods: {
    handleNavBarVisibility({ isNavBarMenuVisible }) {
      this.isNavBarOpen = isNavBarMenuVisible
    },
    handleNavBarFooter() {
      this.isNavBarOpen && (this.isNavBarOpen = false)
    },
    async onScroll(event) {
      this.vPos = event.detail.scrollTop
      Vue.prototype.$verticalPosition = event.detail.scrollTop === 0 ? Vue.prototype.$verticalPosition : event.detail.scrollTop
      const sensitivity = 150
      if (event.detail.scrollTop < 300 || event.detail.deltaY < -1 * sensitivity) {
        this.isNavBarVisible = true
      } else if (event.detail.deltaY > sensitivity) {
        this.isNavBarVisible = false
      }
      const scrollElement = await this.$refs.contentSection.getScrollElement()
      const scrollData = {
        scrollTop: Vue.prototype.$verticalPosition,
        scrollHeight: scrollElement.scrollHeight,
        clientHeight: scrollElement.clientHeight
      }
      EventBus.$emit('scroll', scrollData)
      EventBus.$on('on-toggle-external-sidebar', (isExternalSidebarActive) => {
        this.isNavBarVisible = !isExternalSidebarActive
      })
    },
    openPrivacy() {
      this.$router.push({ name: 'OnboardingPrivacyPolicy' })
    },
    openCookieNotice() {
      this.$router.push({ name: 'OnboardingCookieNotice' })
    },
    openTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use')
    }
  }
}
</script>

<style lang="scss" scoped>
.copyright-text {
  margin: 1rem 0rem;
  font-family: Arial;
  font-size: 14px;
  color: var(--ion-color-medium);
}

.copyright-link {
  font-family: Arial;
  font-size: 16px;
  color: var(--ion-color-tertiary);
  padding: 0 1rem 1rem 0;
  a {
    font-size: 15px !important;
  }
}

@media (orientation: portrait) {
  ion-footer {
    padding: 0 0 18px;
    margin-bottom: constant(safe-area-inset-bottom); /* iOS Safe Area */
    margin-bottom: env(safe-area-inset-bottom); /* iOS Safe Area */
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP 11 */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 11pro */,
  only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP 11max */,
  only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) /* iP 6/6s/7/8 */,
  only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) /* iP 6+/6s+/7+/8+ */,
  only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP X /XS/11 Pro/12 Mini */,
  only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 12/12 Pro */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) /* iP XR/11 */,
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) /* iP XS Max/11 Pro Max */,
  only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 12 Pro Max */,
  only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) /* iP 13/13 Pro */,
  only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Pro Max */,
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) /* iP 13 Mini */,
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) /* iPad 1/2/Mini/Air */,
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) /* iPad 3/4/Pro 9.7 */,
  only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) /* iPad Pro 10.5 */,
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) /* iPad Pro 12.9 */ {
  ion-footer {
    margin-bottom: 20px;
  }
}
ion-header,
ion-footer {
  z-index: 1000;
}
</style>
