var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "edge-menu" },
    [
      _c(
        "ion-row",
        {
          staticClass: "ion-align-items-center",
          staticStyle: { "margin-top": "16px" }
        },
        [
          _c(
            "ion-col",
            { staticClass: "ion-no-padding" },
            [
              _c("svg-icon", {
                staticStyle: { height: "18px", width: "40px" },
                attrs: { "icon-class": "proedge-mobile-logo" }
              })
            ],
            1
          ),
          _c("ion-col", { staticClass: "ion-no-padding ion-text-end" }, [
            _c(
              "button",
              {
                staticClass: "sidebar-close",
                attrs: { "aria-label": "close side menu" },
                on: { click: _vm.onCloseMenu }
              },
              [
                _c("svg-icon", {
                  staticStyle: { height: "16px", width: "16px" },
                  attrs: {
                    "icon-class": "close-icon",
                    "class-name": "close-button"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._l(_vm.navBarMenu.links, function(item) {
        return _c("div", { key: item.name }, [
          item.subLinks.length == 0
            ? _c(
                "div",
                { staticClass: "primary-links-container" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "primary-link",
                      attrs: { to: item.link },
                      nativeOn: {
                        click: function($event) {
                          return _vm.onCloseMenu($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                ],
                1
              )
            : _c("div", [
                _c("div", { staticClass: "secondary-links-container" }, [
                  _c(
                    "div",
                    { staticClass: "sub-menu-nav-item" },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "nav," + item.name + ",click",
                              expression: "`nav,${item.name},click`"
                            }
                          ],
                          staticClass: "first-level-link primary-link",
                          on: {
                            click: function($event) {
                              return _vm.toggleSubmenu()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "link-name" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      ),
                      _c("svg-icon", {
                        class: _vm.subMenuVisible
                          ? "icon-rotated"
                          : "icon-normal",
                        attrs: { "icon-class": "Icon-awesome-chevron-down" },
                        on: {
                          click: function($event) {
                            return _vm.toggleSubmenu()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.subMenuVisible
                    ? _c(
                        "ul",
                        { staticClass: "sub-menu" },
                        [
                          _vm._l(item.subLinks, function(sublink) {
                            return [
                              sublink.show
                                ? _c(
                                    "li",
                                    {
                                      key: sublink.name,
                                      class: {
                                        "selected-link":
                                          _vm.subLink === sublink.link &&
                                          _vm.$route.path == item.link
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "second-level-link",
                                          attrs: { "class-active": "active " },
                                          on: {
                                            click: function($event) {
                                              return _vm.navigateTo(
                                                sublink.link,
                                                item.name
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(sublink.name))]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ])
        ])
      }),
      _c("div", { staticClass: "utility-links-container" }, [
        _vm.tenantAsset.visible
          ? _c(
              "div",
              { staticClass: "utility-link", on: { click: _vm.onSubmitAsset } },
              [
                _vm._v(" " + _vm._s(_vm.tenantAsset.action) + " "),
                _vm.tenantAsset.submissionLink.length
                  ? _c("svg-icon", {
                      staticClass: "asset-icon",
                      attrs: { "icon-class": "expand-out-icon" }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "utility-link", on: { click: _vm.onGetHelp } },
          [_vm._v("Help Center")]
        ),
        _c(
          "div",
          { staticClass: "utility-link logout", on: { click: _vm.onLogout } },
          [_vm._v("Logout")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }