var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-container" },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showTrackBar
            ? _c("TrackSideBar", {
                key: _vm.item.id,
                attrs: { addItem: _vm.item }
              })
            : _vm._e(),
          _vm.isExternalSidebarActive
            ? _c("MyLearningAddExternalSideBar", {
                staticClass: "add-external-sidebar-container",
                class:
                  _vm.$platform === "Mobile"
                    ? "add-external-sidebar-container-mobile"
                    : "",
                attrs: { isEdit: _vm.isEdit, externalData: _vm.external },
                on: {
                  "on-toggle-external-sidebar": _vm.onToggleExternalSidebar
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showNavOverlay()
        ? _c("div", {
            staticClass: "nav-overlay",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.closeTrackBar($event)
              }
            }
          })
        : _vm._e(),
      _c(_vm.component, { tag: "component" }),
      _vm.showGoalSettingModal
        ? _c("HomeGoalSettingModal", {
            attrs: { goalSettingData: _vm.goalSettingData },
            on: {
              closeGoalSettingModal: function($event) {
                _vm.showGoalSettingModal = false
              },
              saveGoalSettingModal: function($event) {
                return _vm.onSaveGoalSetting($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }