var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        attrs: { title: "Go to top", id: "scrollToTopButton" },
        on: { click: _vm.onScrollToTop }
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/svgs/up-arrow.svg"),
            alt: "Go to Top"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }