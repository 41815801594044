<template>
  <div>
    <div>
      <div>
        <ion-row class="ion-align-items-center">
          <ion-col size="10"></ion-col>
          <ion-col>
            <button class="slider-close" aria-label="close side menu" @click="closeTrackBar">
              <svg-icon icon-class="close-icon" class-name="close-button-icon" />
            </button>
          </ion-col>
        </ion-row>
      </div>
      <div class="tracks-list">
        <ion-row class="track-header"> Add to a track </ion-row>
        <div style="height: calc(100vh - 330px); overflow-y: auto; scrollbar-color: #bcc2d4 #1f3370">
          <div v-for="(tracks, index) in trackList" :key="index">
            <ion-row class="track-row" :class="index == 0 ? 'track-row-first' : ''">
              <ion-col>
                <img
                  :alt="tracks.name"
                  v-if="tracks.image.signedRelativeUri == null || tracks.image.signedRelativeUri == ''"
                  class="imgCls"
                  src="@/assets/images/thumbnail-track.png"
                />
                <img
                  v-else
                  class="imgCls"
                  :alt="tracks.image.signedRelativeUri"
                  :src="tracks.image.signedRelativeUri != null && tracks.image.signedRelativeUri != '' ? tracks.image.signedRelativeUri : '@/assets/images/thumbnail-track.png'"
                />
              </ion-col>

              <ion-col :size="$platform === 'Desktop' ? 9 : 8" class="track-details-col">
                <ion-row>
                  <div class="track-name">TRACK</div>
                </ion-row>
                <ion-row>
                  <a href="#" class="track-desc" @click="goToTrack(tracks)">
                    {{ tracks.name }}
                  </a>
                </ion-row>
                <ion-row>
                  <div class="track-time">Created by {{ profile_name }}. Updated {{ tracks.updatedAt | formatUpdatedDate }}</div>
                </ion-row>
                <ion-row class="track-add-btn">
                  <ion-button :disabled="disableAddButton(tracks)" shape="round" color="primary" class="ion-button-primary-dark track-btn" @click="addItemToTrack(tracks)"
                    >Add
                  </ion-button>
                </ion-row>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>
    </div>
    <div class="create-track-footer">
      <ion-button shape="round" color="primary" class="ion-button-primary-dark create-new-track-btn" @click="createTrack"> Create a new track </ion-button>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage'
import { mapGetters } from 'vuex'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import UGTrackDeleteOverlay from '@/views/collection/components/UGTrackDeleteOverlay'
import { popoverController } from '@modus/ionic-vue'
TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')
export default {
  name: 'TrackList',
  props: {
    trackList: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    addItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  data() {
    return {
      addedSkills: [],
      skills: [],
      profile_name: '',
      showEditForm: false,
      trackMode: 'add-track',
      nameError: false,
      descriptionError: false,
      authToken: '',
      itemObj: {
        itemId: '',
        name: '',
        format: '',
        duration: {
          value: 0,
          type: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['auth_token', 'name'])
  },
  watch: {},
  filters: {
    formatUpdatedDate(d) {
      return timeAgo.format(new Date(d))
    }
  },
  created() {},
  async mounted() {
    this.profile_name = this.name || (await localforage.getItem('my_name'))
    this.authToken = this.auth_token || (await localforage.getItem('my_access_token'))
  },
  methods: {
    goToTrack(track) {
      this.closeTrackBar()
      const route = { name: 'UGTrack', params: { id: track.guid, pgrTrk: true } }
      this.$router.push(route)
    },
    closeTrackBar() {
      this.$eventBus.$emit(`close-track-bar`)
    },
    createTrack() {
      this.$emit('show-create-track', 'add-track')
    },
    async addItemToTrack(track) {
      if (this.addItem.externalArtifactGuid) {
        this.itemObj.itemId = this.addItem.artifactId || ''
      } else {
        this.itemObj.itemId = this.addItem.id || ''
      }
      this.itemObj.name = this.addItem.title || ''
      this.itemObj.format = this.addItem.format?.name.toLowerCase() || ''
      const duration = this.addItem?.duration ? this.addItem.duration.toString().split(' ') : [0, 'seconds']
      if (duration.length > 0) {
        this.itemObj.duration.value = parseInt(duration[0])
        this.itemObj.duration.type = 'seconds'
      }
      const items = track.items || []
      items.push(this.itemObj)
      const payload = {
        token: this.authToken,
        id: track.guid,
        body: {
          items: items
        }
      }
      let response = await this.$learnAdmin.addItemsToTrack(payload)
      if (response.data.data.guid) {
        this.$eventBus.$emit(`close-track-bar`)
        this.addAlert(track)
        if (this.$route.name == 'ExternalContent') {
          this.$eventBus.$emit(`external-added-to-track`)
        }
      }
    },
    async addAlert(event) {
      let modal = await popoverController.create({
        component: UGTrackDeleteOverlay,
        event: event,
        cssClass: this.$platform === 'Mobile' && this.$device === 'ios' ? 'track-alert-ios' : 'track-alert',
        mode: this.$platform === 'Mobile' && this.$device === 'ios' ? 'ios' : 'md',
        arrow: false,
        showBackdrop: true,
        backdropDismiss: true,
        alignment: 'end',
        componentProps: {
          parent: this,
          propsData: {
            options: {
              title: '',
              track: event
            }
          }
        }
      })
      await modal.present()
      setTimeout(async () => {
        await modal.dismiss()
      }, 5000)
    },
    //disable add button if item already exists in track
    disableAddButton(track) {
      return track.items.some((item) => item.itemId == this.addItem.id || item.itemId == this.addItem.artifactId)
    }
  }
}
</script>
  


<style lang="scss" scoped>
.tracks-list {
  //width: 509px;
  padding-left: 82px;
  padding-right: 16px;
}

.slider-close {
  color: unset;
  text-decoration: unset;
  background-color: unset;
  margin: 20px 0px 0px 40px;
}

.create-track-footer {
  width: 100%;
  border-top: 2px solid #dfdede;
  position: absolute;
  text-align: end;
  bottom: 0px;
  max-height: 6.25rem;
}

.create-new-track-btn {
  width: 210px;
  height: 3.5rem;
  margin: 0.625rem 56px 33px 0px;
  text-transform: none !important;
  font: normal normal normal 16px/22px Futura PT Demi;
  margin-top: 30px !important;
}

.track-desc {
  font: normal normal normal 24px/34px Futura PT Demi;
  letter-spacing: 0.01px;
  color: #1f3370;
  padding-top: 0.625rem;
  word-break: break-word;
  text-align: left;
  text-decoration: none;
}

@media screen and (max-width: 480px) {
  .tracks-list {
    width: 95%;
    padding-left: 12px;
    padding-right: 0px;
  }

  .img-style {
    width: 80%;
  }

  .slider-close {
    margin: 20px 0px 0px 0px;
  }

  .create-track-footer {
    width: 100%;
  }

  .track-desc {
    font: normal normal normal 18px/24px Futura PT Demi;
  }
}

.close-button-icon {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.track-header {
  margin-top: 1rem;
  font: normal normal normal 30px/34px Futura PT Demi;
  margin-bottom: 2.5rem;
  text-align: center;
  padding-left: 1rem;
}

.track-row {
  border-top: 2px solid #dfdede;
  padding-top: 1.25rem;
  margin-right: 12px;
}
.track-row-first {
  border-top: none !important;
}

.track-name {
  min-width: 6rem;
  min-height: 1.625rem;
  font: normal normal bold 12px/14px Arial;
  background: #e0e1e1 0% 0% no-repeat padding-box;
  padding-top: 6px;
  border-radius: 1.25rem;
  text-align: center;
  word-break: break-word;
}

.track-time {
  margin: 0.625rem 0px 0.625rem 0px;
  font: normal normal normal 16px/22px Arial;
  letter-spacing: 0px;
  color: #5c5c5c;
  word-break: break-word;
  text-align: left;
}

.track-add-btn {
  margin-bottom: 1.25rem;
  max-width: 110px;
  max-height: 39px;
}

.track-btn {
  text-transform: none !important;
  font: normal normal normal 16px/22px Futura PT Demi;
}

.imgCls {
  border-radius: 20px;
  width: 88px;
  height: 88px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bcc2d4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f3370;
}

ion-button {
  --box-shadow: none;
}
</style>
  