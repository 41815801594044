<template>
  <div :class="$platform == 'Desktop' ? 'bookmark-credential-container-desktop' : ''">
    <div style="text-align: left; margin-left: 20px">
      <svg-icon icon-class="back_arrow" class-name="g-icon--small back-icon" @click="$router.go(-1)" />
    </div>
    <CredentialContent :content="bookmark" from-bookmark-list />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import CredentialContent from '@/views/credential/components/CredentialContent'

export default {
  name: 'CredentialContentBookmark',
  components: { CredentialContent },
  props: {},
  data() {
    return {
      bookmark: {}
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  async activated() {
    const token = this.auth_token || (await localforage.getItem('my_access_token'))
    this.bookmark = await this.$learnAdmin.getCredentialContent({ token: token, id: this.$route.params.id })
    this.bookmark.meta.isBookmarked = true
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.bookmark-credential-container-desktop {
  width: 100%;
  margin-top: 2%;
  margin-left: 1rem;
}
</style>
