var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataReady && _vm.courses.length
    ? _c(
        "div",
        [
          _c("div", { staticClass: "header" }, [
            _vm._v('Other courses found in "' + _vm._s(_vm.trackTitle) + '"')
          ]),
          _vm.isDesktopPlatform
            ? _c(
                "CarouselDesktop",
                {
                  staticStyle: { margin: "32px 0 40px" },
                  style: { "--bottom": "-30px" },
                  attrs: { slidesShow: 2, responsive: false }
                },
                _vm._l(_vm.courses, function(item, index) {
                  return _c("UniversalCard", {
                    key: item.id,
                    attrs: {
                      item: item,
                      index: index,
                      itemsLength: _vm.courses.length,
                      cardType: "carousal",
                      tab: _vm.tabName
                    }
                  })
                }),
                1
              )
            : _c(
                "CarouselMobile",
                { staticStyle: { margin: "16px 0px" } },
                _vm._l(_vm.courses, function(item, index) {
                  return _c("UniversalCard", {
                    key: item.id,
                    attrs: {
                      item: item,
                      index: index,
                      itemsLength: _vm.courses.length,
                      cardType: "carousal",
                      tab: _vm.tabName
                    }
                  })
                }),
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }