var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-flagged-container" }, [
    _c(
      "div",
      { staticClass: "header-content" },
      [
        _c(
          "div",
          { staticClass: "title-content" },
          [
            _c("svg-icon", {
              attrs: { "icon-class": "Icon feather-flag-black" }
            }),
            _c("h2", { attrs: { size: "8" } }, [
              _vm._v(_vm._s(_vm.commentFlaggedTitle))
            ])
          ],
          1
        ),
        _c("svg-icon", {
          staticClass: "g-icon--small close",
          attrs: { "icon-class": "filter-close" },
          on: { click: _vm.onCancel }
        })
      ],
      1
    ),
    _c("p", [_vm._v(_vm._s(_vm.commentFlaggedText))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }