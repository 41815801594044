var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guide-popover" },
    [
      _c(
        "ion-grid",
        [
          _c(
            "ion-row",
            [
              _c(
                "ion-col",
                { staticClass: "ion-text-start", attrs: { size: "9" } },
                [
                  _c(
                    "p",
                    { staticClass: "ion-no-margin guide-popover__heading" },
                    [_vm._v("Quick Reference Guide")]
                  )
                ]
              ),
              _c(
                "ion-col",
                { staticClass: "ion-text-end" },
                [
                  _c("svg-icon", {
                    staticClass: "g-icon--small",
                    attrs: { "icon-class": "close" },
                    on: { click: _vm.onClose }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("iframe", {
        staticClass: "guide-popover__pdf-frame",
        attrs: { src: _vm.quickRefLink }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }