// eslint-disable-next-line
import Vue from 'vue'
import localforage from 'localforage'
import EventBus from '@/plugins/eventbus'
import { getFeatureFlagStrategy } from '@/utils/flag.js'

export default {
  install(Vue, options) {
    const { store } = options
    let currentVersion = ''

    Vue.prototype.$checkVersion = checkVersion
    Vue.prototype.$isNewVersion = isNewVersion
    Vue.prototype.$loadVersion = loadVersion

    async function checkVersion() {
      if (isNewVersion()) {
        EventBus.$emit('new-version')
      }
    }

    async function isNewVersion() {
      const storeVersion = await localforage.getItem('my_learn_version')
      currentVersion = process.env.VUE_APP_VERSION
      return storeVersion && storeVersion != currentVersion ? true : false;
    }

    async function loadVersion() {
      await store.dispatch('environment/setLearnVersion', currentVersion)

      const token = await localforage.getItem('my_access_token')
      const userInfo = await Vue.prototype.$learnAdmin.getUserInfo({ token: token })
      const tenantInfo = await Vue.prototype.$learnAdmin.getUserTenantInfo({ token: token })
      const userAccessAssets = await Vue.prototype.$learnAdmin.getUserAccessAssets({ token: token })
      await store.dispatch('user/setUserInfo', userInfo)
      await store.dispatch('user/setUserOnboarded', true)
      await store.dispatch('user/setTenantInfo', tenantInfo.clientCategory)
      await store.dispatch('user/setUserAccessTag', userAccessAssets)

      const settings = tenantInfo.settings ? JSON.parse(tenantInfo.settings) : null
      const showShare = {}.hasOwnProperty.call(settings, 'ui-share-display') ? settings['ui-share-display'] : true
      const showIdea = {}.hasOwnProperty.call(settings, 'ui-idea-display') ? settings['ui-idea-display'] : true
      const hideRWL = {}.hasOwnProperty.call(settings, 'ui-rwl-display') ? settings['ui-rwl-display'] : false
      const trackingDisabled = {}.hasOwnProperty.call(settings, 'trackingDisabled') ? settings['trackingDisabled'] : false
      const globalSearchEnabled = {}.hasOwnProperty.call(settings, 'globalSearchEnabled') ? settings['globalSearchEnabled'] : false
      const showSearchSuggest = {}.hasOwnProperty.call(settings, 'showSearchSuggest') ? settings['showSearchSuggest'] : false
      const showSearchLookingFor = {}.hasOwnProperty.call(settings, 'showSearchLookingFor') ? settings['showSearchLookingFor'] : false
      await store.dispatch('user/setShowShare', showShare)
      await store.dispatch('user/setShowIdea', showIdea)
      await store.dispatch('user/setHideRWL', hideRWL)
      await store.dispatch('user/setTrackingDisabled', trackingDisabled)
      await store.dispatch('user/setGlobalSearchEnabled', globalSearchEnabled)
      await store.dispatch('user/setShowSearchSuggest', showSearchSuggest)
      await store.dispatch('user/setShowSearchLookingFor', showSearchLookingFor)

      if (!Object.keys(store.state.flag.features).length) {
        const response = await Vue.prototype.$learnAdmin.getBackupFeatureFlags()
        await store.dispatch('flag/setFeatureFlags', { featureFlags: response.features, strategyProviders: getFeatureFlagStrategy() })
      }
    }
  }
}
