var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ion-row",
        { staticClass: "accordion" },
        [
          _c("ion-col", [
            _c(
              "div",
              {
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.onToggle }
              },
              [
                _c("svg-icon", {
                  class: [!_vm.expand ? "" : "caret-transform"],
                  staticStyle: {
                    float: "right",
                    "margin-right": "0.5rem",
                    "margin-top": "0.25rem"
                  },
                  attrs: {
                    "icon-class": _vm.accordion.hint
                      ? "dropdown-transcript"
                      : "dropdown-transcript-white",
                    "class-name": "g-icon--xsmall"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "accordion-title",
                    class: _vm.accordion.hint ? "accordion-hint" : ""
                  },
                  [_vm._v(_vm._s(_vm.accordion.title))]
                )
              ],
              1
            ),
            _vm.expand
              ? _c("div", {
                  staticClass: "accordion-content",
                  domProps: {
                    innerHTML: _vm._s(_vm.$sanitize(_vm.accordion.contentHTML))
                  }
                })
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }