<template>
  <div>
    <ion-card class="profile-skills" ref="profileSkills">
      <div class="header-title">My skills</div>
      <p class="skill-description">Skills in which you have some level of experience. These skills can be used by your company administrator to optimize your learning plan.</p>
      <div class="wrapper">
        <div class="existing-skills-list">
          <SkillsList v-if="dataReady" :skills="skills" :isSkillRemoveable="false" :key="skills.length"></SkillsList>
          <ion-spinner v-else color="#1f3370"></ion-spinner>
        </div>
        <div v-if="isMe" class="search-wrapper">
          <ion-searchbar
            placeholder="Add new skill"
            class="skills-search"
            ref="searchBar"
            @ionChange="handleSearchInput"
            :debounce="$platform === 'Mobile' ? '500' : '250'"
          ></ion-searchbar>
          <div class="skills-list-container">
            <ion-scroll class="scrollable">
              <ion-list v-if="showDropDownList" class="search-skills-list" lines="none">
                <ion-item v-for="skill of searchedSkills" class="search-list-item" :key="skill.name" @click="addSkillsToProfile($event, skill)">{{ skill.name }}</ion-item>
              </ion-list>
            </ion-scroll>
          </div>
        </div>
      </div>
    </ion-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import localforage from 'localforage'
import SkillsList from '@/components/organisms/SkillsList'

export default {
  name: 'ProfileSkills',
  components: { SkillsList },
  props: {
    guid: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    isSkillRemoveable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      skills: [],
      dataReady: false,
      isDesktopPlatform: false,
      addedSkills: [],
      searchedSkills: [],
      isMe: true,
      errorMessage: '',
      showDropDownList: false
    }
  },
  computed: {
    ...mapGetters(['auth_token'])
  },
  watch: {},
  created() {},
  activated() {
    this.closeList()
  },
  async mounted() {
    document.addEventListener('click', this.clearSearch)
    this.authToken = await localforage.getItem('my_access_token')
    await this.getSkills()
    this.isDesktopPlatform = this.$platform == 'Desktop'
    this.$eventBus.$on(`skills-deleted`, this.setSkills)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clearSearch)
  },
  filters: {
    formatAddedSkills(count) {
      return count > 1 ? `${count} Skills Added!` : `${count} Skill Added!`
    }
  },
  methods: {
    closeList() {
      this.searchedSkills = []
      this.addedSkills = []
      this.$refs.searchBar.value = ''
      this.errorMessage = ''
      this.showDropDownList = false
    },
    clearSearch(event) {
      if (
        event.target &&
        !event.target._prevClass?.includes('search-list-item') &&
        !event.target._prevClass?.includes('skills-list-container') &&
        !event.target._prevClass?.includes('search-skills-list') &&
        !event.target.className?.includes('searchbar-input')
      ) {
        this.closeList()
      }
    },

    setSkills(skills) {
      this.dataReady = false
      this.skills = skills
      this.dataReady = true
    },
    async getSkills() {
      this.dataReady = false
      const token = this.auth_token || (await localforage.getItem('my_access_token'))
      if (this.guid.length) {
        const profile = await this.$learnAdmin.getUserConnectionProfile({ token: token, guid: this.guid })
        this.skills = profile.skills
        this.isMe = false
      } else {
        this.skills = await this.$learnAdmin.getUserSkills({ token: token })
      }
      this.skills.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
      this.dataReady = true
    },
    async addSkillsToProfile(e, skill) {
      await this.addToList(skill)
      e.preventDefault()
      this.addedSkills.forEach(async (xSkill) => {
        var xSkillIndex = this.skills.findIndex((skill) => {
          return skill.guid == xSkill.guid
        })
        if (xSkillIndex < 0) {
          await this.$learnAdmin.addUserSkill({ token: this.authToken, guid: xSkill.guid }).then(() => {
            xSkill.recentlyAdded = true
            this.skills.unshift(xSkill)
          })
        }
      })
    },
    async handleSearchInput(event) {
      const skill = event.target.value
      if (skill.length > 2) {
        const foundSkills = await this.$learnAdmin.searchSkillByName({ token: this.authToken, skill: encodeURIComponent(skill) })
        this.searchedSkills = []
        this.searchedSkills = foundSkills.map((foundSkill) => {
          if (!this.skills.some((x) => x.guid === foundSkill.id)) {
            return {
              name: foundSkill?.title,
              guid: foundSkill?.id
            }
          }
        })
        this.searchedSkills = this.searchedSkills.filter((e) => e)
        this.showDropDownList = this.searchedSkills.length > 0 ? true : false
        analytics.setVarAndTrack({ c: 'profile-skills', a: 'search skills', l: skill })
      }
      if (skill.length === 0) {
        this.closeList()
      }
    },
    addToList(skill) {
      this.errorMessage = ''
      if (this.addedSkills.includes(skill)) {
        var popSkillIndex = this.addedSkills.findIndex((addedSkill) => {
          return addedSkill.guid == skill.guid
        })
        this.addedSkills.splice(popSkillIndex, 1)
      } else if (!this.addedSkills.some((x) => x.name === skill.name) && !this.skills.some((x) => x.name === skill.name)) {
        this.addedSkills.push(skill)
        analytics.setVarAndTrack({ c: 'profile-skills', a: 'add searched skill', l: skill.guid })
      } else {
        this.errorMessage = 'Skill already exists in your list.'
        this.$refs.skillList.scrollTop = 0
        return
      }
    }
  }
}
</script>

<style lang="scss" src="@/styles/common-organisims.scss"></style>
<style lang="scss" scoped>
@include block('edge-skills') {
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-device-width: 600px) {
    &:before {
      content: '';
      background-image: radial-gradient(#eee 10%, #fff 20%);
      background-position: 0 0;
      background-size: 30px 30px;
      height: 200px;
      width: 40%;
      position: absolute;
      top: 0;
      left: 100px;
      z-index: 0;
    }
  }
}

.profile-skills {
  border-radius: 1.125rem;
  padding: 2rem 3rem;
  margin: 0;
  @media (max-width: 500px) {
    padding: 1rem 1.5rem;
  }
}

.wrapper {
  text-align: left;
  padding: 0;
}
img {
  margin-left: 40px;
  height: 60px;
}

ion-spinner {
  margin-top: 2rem;
  margin-left: 8rem;
}

.existing-skills-list {
  padding: 0 0px;
  max-height: 650px;
}

.search-wrapper {
  position: relative;
  background: var(--ion-color-white);
  margin-top: 1rem;
  position: relative;
  .scrollable {
    width: 390px;
    height: 255px;
    .search-skills-list {
      height: 255px;
      width: 350px;
      border-radius: 1.125rem;
      margin: 0;
      overflow-y: auto;
      box-shadow: 0px 0px 12px #0000001f;
      border: 20px solid #fff;
      @media (max-width: 500px) {
        width: 100%;
        position: relative;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #4c5c8d;
        border-radius: 8px;
      }

      // scrollbar track
      &::-webkit-scrollbar-track-piece {
        background: #bcc2d4;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #4c5c8d;
      }
      .search-list-item {
        font: normal normal normal 16px/26px Arial;
        cursor: pointer;
        margin: 0 1.5rem;
        padding-bottom: 0.5rem;
        &:hover {
          color: #1f3370;
          --background: #bcc2d4;
        }
        @media (max-width: 500px) {
          margin: 0;
        }
      }
    }
  }
}

ion-button-tertiary {
  all: unset !important;
}
ion-button {
  --box-shadow: none !important;
}

.skills-search {
  @media screen and (min-device-width: 600px) {
    width: 480px;
  }
  --box-shadow: none;
  border: 2px solid #e0e1e1;
  margin: 0;
  border-radius: 60px;
  --border-radius: 60px;
  padding: 0;
  --background: #fff !important;
  --color: #5c5c5c;
  --placeholder-color: #5c5c5c;
}

.header-title {
  text-align: left;
  font: normal normal normal 24px/30px 'Futura PT Demi';
  color: #000000;
}
.skill-description {
  font: normal normal normal 16px/22px Arial;
  color: #5c5c5c;
  text-align: left;
  margin: 1rem 0 2rem 0;
}
</style>
