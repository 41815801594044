import Vue from 'vue'
import VueRouter from 'vue-router'
import localforage from 'localforage'
import apply from './routes/apply'
import authentication from './routes/authentication'
import bookmarks from './routes/bookmarks'
import content from './routes/content'
import explore from './routes/explore'
import onboarding from './routes/onboarding'
import collection from './routes/collection'
import preference from './routes/preference'
import profile from './routes/profile'
import workspace from './routes/workspace'
import store from '@/store'
import { getFeatureFlagStrategy } from '@/utils/flag.js'

Vue.use(VueRouter)

const home = [
  {
    path: '/',
    redirect: '/layout',
    meta: {
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: true,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    }
  },
  {
    path: '/layout',
    name: 'Layout',
    component: () => import('@/layouts/Layout'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: true,
      filterVisible: true,
      jumpBackInVisible: false
    }
  },
  {
    path: '/landing',
    name: 'LandingMobile',
    component: () => import('@/views/landing/LandingMobile'),
    meta: {
      whitelist: true,
      moduleName: 'learn',
      desktopSideMenuVisible: false,
      desktopHeaderVisible: true,
      mobileHeaderVisible: false,
      mobileFooterVisible: false,
      searchVisible: false,
      filterVisible: false,
      jumpBackInVisible: false
    },
    beforeEnter: async (to, from, next) => {
      let sso = null
      let isProAuthSSO = false
      const logoutSSO = await localforage.getItem('logoutFromSSO')
      await localforage.setItem('logoutFromSSO', false)
      if (document.cookie !== '' && document.cookie.includes('ProAuthSSO=') && !to.query.status && !logoutSSO) {
        let cookie = document.cookie
          .replace(/; /g, ';')
          .split(';')
          .find(row => row.startsWith('ProAuthSSO='))
        cookie = cookie ? cookie.split('=')[1] : null
        sso = cookie ? JSON.parse(decodeURIComponent(cookie)) : null
        isProAuthSSO = true
      } else if (to.query.ProAuthSSO && !to.query.status) {
        sso = JSON.parse(decodeURIComponent(escape(window.atob(to.query.ProAuthSSO))))
        isProAuthSSO = true
      } else {
        next()
      }

      // route redirect if SSO
      if (isProAuthSSO && sso && sso.access_token) {
        const userInfo = await storeToken(sso, true)
        if (userInfo && userInfo.code && userInfo.code === 401) {
          next('/landing?status=unauthorized')
          return;
        }

        const ssoRedirect = await localforage.getItem('ssoRedirect')
        if (ssoRedirect && ssoRedirect.length) {
          Vue.prototype.$platform === 'Mobile' ? next('/onboarding/welcome') : to.query.modal && to.query.modal === 'Welcome' ? next() : next('/landing?modal=Welcome')
        } else {
          next()
        }
      } else if (isProAuthSSO && sso && sso.status === 401) {
        next('/landing?status=invalid')
      } else if (isProAuthSSO && sso && sso.status === 400) {
        next('/landing?status=unauthorized')
      } else if (isProAuthSSO) {
        next('/landing')
      }
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/Home'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: true,
      jumpBackInVisible: false
    },
    beforeEnter: async (to, from, next) => {
      let sso = null
      let isProAuthSSO = false

      if (document.cookie !== '' && document.cookie.includes('ProAuthSSO=')) {
        let cookie = document.cookie
          .replace(/; /g, ';')
          .split(';')
          .find(row => row.startsWith('ProAuthSSO='))
        cookie = cookie ? cookie.split('=')[1] : null
        sso = cookie ? JSON.parse(decodeURIComponent(cookie)) : null
        isProAuthSSO = true
      } else if (to.query.ProAuthSSO) {
        sso = JSON.parse(decodeURIComponent(escape(window.atob(to.query.ProAuthSSO))))
        isProAuthSSO = true
      } else {
        next()
      }

      // route redirect if SSO
      if (isProAuthSSO && sso && sso.access_token) {
        const userInfo = await storeToken(sso, false)
        if (userInfo && userInfo.code && userInfo.code === 401) {
          next('/landing?status=invalid')
          return;
        }
        if (!userInfo) {
          next('/landing?status=unauthorized')
          return;
        }
        else {
          const dlId = await localforage.getItem('deep_link_id')
          const dltp = await localforage.getItem('deep_link_type')
          const dlsq = await localforage.getItem('deep_link_search_query')
          const dlst = await localforage.getItem('deep_link_search_type')
          const dlmlt = await localforage.getItem('deep_link_my_learning_type')
          dlId && dlId.length && dltp && dltp.length ? next(`/${dltp}/${dlId}`) :
            dlsq && dlsq.length ? next(`/search?q=${dlsq}`) :
              dlst && dlst.length ? next(`/search?q=&type=${dlst}`) :
                dlmlt && dlmlt.length ? next(`/mylearning/${dlmlt}`) : next()
        }
      } else if (isProAuthSSO && sso && sso.status === 401) {
        next('/landing?status=invalid')
      } else if (isProAuthSSO && sso && sso.status === 400) {
        next('/landing?status=unauthorized')
      } else if (isProAuthSSO) {
        next('/landing')
      }
    }
  },
  {
    path: '/mylearning/:type',
    name: 'MyLearning',
    component: () => import('@/views/my-learning/MyLearning'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: true,
      jumpBackInVisible: false
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/Search'),
    meta: {
      whitelist: false,
      moduleName: 'learn',
      desktopSideMenuVisible: true,
      desktopHeaderVisible: true,
      mobileHeaderVisible: true,
      mobileFooterVisible: true,
      searchVisible: true,
      filterVisible: true,
      jumpBackInVisible: false
    }
  },
]

const routes = [
  ...home,
  ...apply,
  ...authentication,
  ...bookmarks,
  ...content,
  ...explore,
  ...onboarding,
  ...collection,
  ...preference,
  ...profile,
  ...workspace
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

const router = createRouter()

router.beforeEach(async (to, from, next) => {
  Vue.prototype.$checkVersion()
  let token = await localforage.getItem('my_access_token')
  let userOnboarded = await localforage.getItem('my_user_onboarded')

  // CCCAT deep link - route info memorization for after-login
  if (to.path.includes('course') || to.path.includes('credential') || to.path.includes('content') || to.path.includes('external') || to.path.includes('asset') || to.path.includes('collection')) {
    const spltPath = to.path.split('/')
    const id = spltPath[spltPath.length - 1]
    const type = spltPath[spltPath.length - 2]
    await localforage.setItem('deep_link_id', id)
    await localforage.setItem('deep_link_type', type)
  }
  // Search deep link - route info memorization for after-login
  else if (to.path.includes('search')) {
    await localforage.setItem('deep_link_search_query', to.query.q)
    await localforage.setItem('deep_link_search_type', to.query.type)
  }

  //my-learning deeplink
  if (to.path.includes('mylearning')) {
    if ({}.hasOwnProperty.call(to, 'params') && {}.hasOwnProperty.call(to.params, 'type')) {
      await localforage.setItem('deep_link_my_learning_type', to.params.type)
    } else {
      next({ name: 'MyLearning', params: { type: 'required' } })
    }
  }
  // CCCAT deep link - back to home page if 'back' from CCCAT deep link
  if ((to.path === '/' || to.path === '/landing' || to.path === '/onboarding/welcome') && (from.path.includes('course') || from.path.includes('credential') || from.path.includes('content') || from.path.includes('external') || from.path.includes('asset') || from.path.includes('collection') || from.path.includes('search') || from.path.includes('mylearning')) && token) {
    next('/home')
  }

  // normal route proceed
  if (
    ((to.path === '/home' && from.path === '/') || (to.path === '/home' && from.path === '/login') || (to.path === '/home' && from.path === '/onboarding/create-account')) &&
    ((document.cookie !== '' && document.cookie.includes('ProAuthSSO=')) || to.query.ProAuthSSO) &&
    !token
  ) {
    next()
  } else if ((!to.meta.whitelist && !userOnboarded) || (!to.meta.whitelist && !token)) {
    Vue.prototype.$device === 'android' && to.path === '/home' && from.path === '/login' ? next('/landing?status=invalid') : next('/landing')
  } else {
    next()
  }
})

router.afterEach(async () => {
  const isAutoRefresh = await store.getters.enabledFeatureFlags['learn_refresh_automatic-reload']
  const isNewVersion = await Vue.prototype.$isNewVersion()
  if (isNewVersion && isAutoRefresh) {
    const token = await localforage.getItem('my_access_token')
    await localforage.setItem('logoutFromSSO', document.cookie.includes('ProAuthSSO='))
    await Vue.prototype.$learnAdmin.logout({ token: token })
    await store.dispatch('user/logout')
    Vue.prototype.$loadVersion()
    window.location.reload()
  }
  const contentLayout = document.getElementById('contentlayout');
  if (contentLayout != null)
    contentLayout.style.overflowY = 'scroll'
})

async function storeToken(sso, isRegistering) {
  const user = sso.access_token ? parseJWT(sso.access_token) : null
  await localforage.setItem('my_access_token', sso.access_token)
  await setCloudEnvData(user.sub)
  await store.dispatch('user/setAccessToken', sso.access_token)
  await store.dispatch('user/setSSO', { token: sso.sso_token, bouncer: sso.sso_bouncer })
  const userInfo = await Vue.prototype.$learnAdmin.getUserInfo({ token: sso.access_token })
  const userOnboarded = isRegistering || (userInfo.onBoardingState && userInfo.onBoardingState === 'COMPLETED')
  if (userOnboarded) {
    await store.dispatch('user/setUserInfo', userInfo)
    await store.dispatch('user/setUserOnboarded', userOnboarded)
    if (isRegistering) {
      await Vue.prototype.$learnAdmin.updateOnboardingStage({ token: sso.access_token, stage: 'COMPLETED' })
    }
    const userAccessAssets = await Vue.prototype.$learnAdmin.getUserAccessAssets({ token: sso.access_token })
    await store.dispatch('user/setUserAccessTag', userAccessAssets)
    const tenantInfo = await Vue.prototype.$learnAdmin.getUserTenantInfo({ token: sso.access_token })
    await store.dispatch('user/setTenantInfo', tenantInfo.clientCategory)
    await setTenantSettings(tenantInfo)
    if (!Object.keys(store.state.flag.features).length) {
      const response = await Vue.prototype.$learnAdmin.getBackupFeatureFlags()
      await store.dispatch('flag/setFeatureFlags', { featureFlags: response.features, strategyProviders: getFeatureFlagStrategy() })
    }
  } else {
    userInfo.code = 401
  }
  return userInfo
}

async function setTenantSettings(tenantInfo) {
  const settings = tenantInfo.settings ? JSON.parse(tenantInfo.settings) : null
  if ({}.hasOwnProperty.call(settings, 'ui-share-display')) {
    await store.dispatch('user/setShowShare', settings['ui-share-display'])
  } else {
    await store.dispatch('user/setShowShare', true)
  }
  if ({}.hasOwnProperty.call(settings, 'ui-idea-display')) {
    await store.dispatch('user/setShowIdea', settings['ui-idea-display'])
  } else {
    await store.dispatch('user/setShowIdea', true)
  }
  if ({}.hasOwnProperty.call(settings, 'ui-rwl-display')) {
    await store.dispatch('user/setHideRWL', settings['ui-rwl-display'])
  } else {
    await store.dispatch('user/setHideRWL', false)
  }
  if ({}.hasOwnProperty.call(settings, 'trackingDisabled')) {
    await store.dispatch('user/setTrackingDisabled', settings['trackingDisabled'])
  } else {
    await store.dispatch('user/setTrackingDisabled', false)
  }
  if ({}.hasOwnProperty.call(settings, 'globalSearchEnabled')) {
    await store.dispatch('user/setGlobalSearchEnabled', settings['globalSearchEnabled'])
  } else {
    await store.dispatch('user/setGlobalSearchEnabled', false)
  }
  if ({}.hasOwnProperty.call(settings, 'showSearchSuggest')) {
    await store.dispatch('user/setShowSearchSuggest', settings['showSearchSuggest'])
  } else {
    await store.dispatch('user/setShowSearchSuggest', false)
  }
  if ({}.hasOwnProperty.call(settings, 'showSearchLookingFor')) {
    await store.dispatch('user/setShowSearchLookingFor', settings['showSearchLookingFor'])
  } else {
    await store.dispatch('user/setShowSearchLookingFor', false)
  }
  if ({}.hasOwnProperty.call(settings, 'user-generated-track-enabled')) {
    await store.dispatch('user/setShowAddToTrack', settings['user-generated-track-enabled'])
  } else {
    await store.dispatch('user/setShowAddToTrack', false)
  }
}

function parseJWT(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var payload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(payload)
}

async function setCloudEnvData(userEmail) {
  const response =
    process.env.VUE_APP_USE_FFAPI === 'true' ?
      await Vue.prototype.$learnAdmin.getCloudEnvByUserEmailAddress(userEmail) :
      await Vue.prototype.$learnAdmin.getCloudEnvFromDefaultEnv(userEmail)
  await store.dispatch('environment/setCloudEnvInfo', response.data.cloudEnv)
  await store.dispatch('environment/setUserConfig', response.data.config.user)
  await store.dispatch('environment/setCompanyConfig', response.data.config.company)
  await store.dispatch('environment/setFlagConfig', response.data.config.flags)
  await Vue.prototype.$learnAdmin.updateEnvFromLocalForage()
}

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router