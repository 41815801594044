var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credential-lesson-info" },
    [
      _c(
        "ion-card",
        { class: !_vm.isDesktopPlatform ? "mobile-version" : "" },
        [
          _c("ion-card-header", [
            _c("h3", { class: !_vm.isDesktopPlatform ? "h3-mobile" : "" }, [
              _vm._v(_vm._s(_vm.lesson.title))
            ])
          ]),
          _c("ion-card-content", [
            _vm.lesson.subtitle
              ? _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$sanitize(_vm.lesson.subtitle))
                  }
                })
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }