var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDesktopPlatform
    ? _c("div", { staticClass: "profile-fixed-side-nav" }, [
        _c("div", { staticClass: "profile-name-content" }, [
          _c("div", { staticClass: "profile-short-name" }, [
            _vm._v(_vm._s(_vm.getShortName()))
          ]),
          _c("h3", [_vm._v(_vm._s(_vm.profileDetails.name))])
        ]),
        _c("div", { staticClass: "nav-container" }, [
          _c(
            "ul",
            [
              _vm._l(_vm.sideNavItems, function(nav) {
                return [
                  nav.show
                    ? _c(
                        "li",
                        {
                          key: nav.link,
                          class: { selected: nav.link == _vm.selectedTab }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "side-profile-nav-link",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.changeDetailsTab(nav.link)
                                }
                              }
                            },
                            [_vm._v(_vm._s(nav.name))]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ])
      ])
    : _c("div", { staticClass: "mob-side-nav" }, [
        _c("div", { staticClass: "mob-name-content" }, [
          _c("div", { staticClass: "mob-short-name" }, [
            _vm._v(_vm._s(_vm.getShortName()))
          ]),
          _c("h3", [_vm._v(_vm._s(_vm.profileDetails.name))])
        ]),
        _c("div", { staticClass: "mob-tabs-container" }, [
          _c(
            "div",
            { staticClass: "selected-tab", on: { click: _vm.toggleTabs } },
            [
              _vm._v(" " + _vm._s(_vm.getTabName(_vm.selectedTab)) + " "),
              _c("i", { staticClass: "dropdown-arrow" })
            ]
          )
        ]),
        _vm.tabsOpen
          ? _c(
              "div",
              {
                staticClass: "overlay",
                on: {
                  click: function($event) {
                    _vm.tabsOpen = false
                  }
                }
              },
              [
                _c(
                  "ul",
                  { staticClass: "tabs-selection" },
                  [
                    _vm._l(_vm.sideNavItems, function(tab) {
                      return [
                        tab.show
                          ? _c(
                              "li",
                              {
                                key: tab.link,
                                class:
                                  tab.link === _vm.selectedTab
                                    ? "selected"
                                    : "",
                                attrs: { value: tab.name },
                                on: {
                                  click: function($event) {
                                    return _vm.changeDetailsTab(tab.link)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(tab.name) + " ")]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ]
            )
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }